import { jsonRequest } from 'core/http';

import { api } from 'acadly/api';

export interface IPublishAnnouncementRequest {
  title: string;
  description: string;
  attachments: IRequestAttachment[];
}
export interface IPublishAnnouncementResponse {
  _id: string;
  num: number;
  createdOn: number;
  createdBy: ICreatedByWithRole;
  dueDateTime: number;
  stats: {
    numSeenBy: 0;
  };
}
export const publishAnnouncement = (data: IPublishAnnouncementRequest) =>
  jsonRequest<IPublishAnnouncementResponse>(api().announcementCreate, {
    method: 'POST',
    data,
  });

export interface IAnalyticsSetRequest {
  announcementId: string;
  firstAccess: 0 | 1;
  localTime: string;
}
export const analyticsSet = (data: IAnalyticsSetRequest) =>
  jsonRequest(api().announcementAnalyticsSet, {
    method: 'POST',
    data,
  });
