import { h, IComponent } from 'core';

import * as BulbIcon from 'assets/bulb.svg';

import QuickStartTips from 'acadly/common/QuickStartTips';
import SvgIcon from 'acadly/common/SvgIcon';
import { getStore } from 'acadly/store';
import { style } from 'acadly/styles';

class ActivitiesStartTips extends IComponent<never, never> {
  public render() {
    return QuickStartTips({
      qstKey: 'qst-class-activities',
      children: ({ wrapper }) => wrapper(this.body()),
    });
  }

  private body = () => {
    const classData = getStore().getState().class.data;
    console.log('ActivitiesStartTips::body');

    if (!classData) return null;

    return h('div.quick-start-tips__body', [
      h('div.quick-start-tips__section-heading', 'Activities'),
      h('p.quick-start-tips__section-content', [
        'This is the Class page - here you can add relevant activities like real ',
        'time Quizzes, Polls, Discussions, and Learning Resources that students ',
        'should access.',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'Pre-class and in-class activities',
      ]),
      h('p.quick-start-tips__section-content', [
        'Instructors can add activities as either Pre-class activities, ',
        'ones that students should attempt before attending a lecture, ',
        'or as In-class activities, ones that students should attempt ',
        'during the lecture. Activities can be added to a class by clicking on the ',
        h('i.fa.fa-plus-circle', style(['x-large', 'blue', { transform: 'translateY(2px)' }])),
        ' button on the bottom right',
      ]),
      classData.isOnlineMeeting
        ? h('div.quick-start-tips__section-heading', [
            SvgIcon({
              noFill: true,
              icon: BulbIcon,
              className: 'quick-start-tips__icon sm',
              style: { marginRight: '0.5rem' },
            }),
            'online meetings',
          ])
        : null,
      classData.isOnlineMeeting
        ? h('p.quick-start-tips__section-content', [
            'If your lecture is online, you’ll be able to create a Zoom ',
            'meeting at least 15 minutes before the scheduled start time. ',
            'Plenty of time for you and the students to resolve any issues ',
            'before the lecture begins. Further, students join the meeting by ',
            'simply logging into Acadly',
          ])
        : null,
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'plan ahead',
      ]),
      h('p.quick-start-tips__section-content', [
        'Since students will be able to access activities only after they ',
        'are published. You can create the activities in advance and publish ',
        'them according to your lecture plan.',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'easy rescheduling',
      ]),
      h('p.quick-start-tips__section-content', [
        'You can easily change the class date, class time, and the venue using ',
        'the “Schedule” section. You can also “Cancel” a class and plan for a ',
        'make-up class by editing the class date',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'class team',
      ]),
      h('p.quick-start-tips__section-content', [
        'Each class on Acadly can have its own In-charge and Assistant. A Class ',
        'In-charge controls all the aspects of a Class including the schedule and ',
        'the activity plan. Assistants can help record attendance and award Class ',
        'Participation points to students',
      ]),
    ]);
  };
}

export default () => h(ActivitiesStartTips);
