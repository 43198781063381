import { h, IComponent } from 'core';
import { jsonRequest } from 'core/http';

import { api as urls } from 'acadly/api';
import DatePicker from 'acadly/common/DatePicker';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import PhoneInput from 'acadly/common/PhoneInput';
import Select, { Option, SelectType } from 'acadly/common/Select';
import icons from 'acadly/icons';
import { mb, style } from 'acadly/styles';

const COURSE_COUNT_OPTIONS = [
  { id: '1', title: '1' },
  { id: '2', title: '2' },
  { id: '3', title: '3' },
  { id: '4', title: '4' },
  { id: '5', title: '5' },
  { id: '6', title: '6' },
  { id: '7', title: '7' },
  { id: '8', title: '8' },
  { id: '9', title: '9' },
  { id: '10', title: '10' },
  { id: '10+', title: '10+' },
];

const STUDENT_COUNT_OPTIONS = [
  { id: '0-25', title: '0-25' },
  { id: '25-50', title: '25-50' },
  { id: '50-75', title: '50-75' },
  { id: '75-100', title: '75-100' },
  { id: '100-125', title: '100-125' },
  { id: '125-150', title: '125-150' },
  { id: '150-175', title: '150-175' },
  { id: '175-200', title: '175-200' },
  { id: '200-225', title: '200-225' },
  { id: '225-250', title: '225-250' },
  { id: '250-275', title: '250-275' },
  { id: '275-300', title: '275-300' },
  { id: '300-325', title: '300-325' },
  { id: '325-350', title: '325-350' },
  { id: '350-375', title: '350-375' },
  { id: '375-400', title: '375-400' },
  { id: '400-425', title: '400-425' },
  { id: '425-450', title: '425-450' },
  { id: '450-475', title: '450-475' },
  { id: '475-500', title: '475-500' },
  { id: '500+', title: '500+' },
];

interface IInstructorPaysStepProps {
  getInstance?: (ref: InstructorPaysStep) => any;
  onBack?: () => any;
  onNext?: () => any;
}

interface IInstructorPaysStepState {
  isSelectCourseCountOpen: boolean;
  courseCount: Option<string> | undefined;
  courseCountError: boolean;
  isSelectStudentCountOpen: boolean;
  studentCount: Option<string> | undefined;
  studentCountError: boolean;
  courseStartDate: Date | undefined;
  courseStartDateError: boolean;
  phone: string;
  dialCode: string;
}

export class InstructorPaysStep extends IComponent<
  IInstructorPaysStepProps,
  IInstructorPaysStepState
> {
  public static getTitle(): string {
    return 'Send pricing inquiry';
  }

  private async init() {
    const initialState: IInstructorPaysStepState = {
      isSelectCourseCountOpen: false,
      courseCount: undefined,
      courseCountError: false,
      isSelectStudentCountOpen: false,
      studentCount: undefined,
      studentCountError: false,
      courseStartDate: undefined,
      courseStartDateError: false,
      phone: '',
      dialCode: '',
    };
    this.setState(initialState);
  }

  public componentWillMount() {
    const { getInstance } = this.getProps();
    getInstance?.(this);
    this.init();
  }

  public handleBack = () => {
    const { onBack } = this.getProps();
    onBack();
  };

  public handleNext = async () => {
    const { onNext } = this.getProps();
    const { courseCount, courseStartDate, dialCode, studentCount, phone } = this.getState();

    let hasError = false;

    if (!courseCount) {
      hasError = true;
      this.setState({ courseCountError: true });
    }

    if (!studentCount) {
      hasError = true;
      this.setState({ studentCountError: true });
    }

    if (!courseStartDate) {
      hasError = true;
      this.setState({ courseStartDateError: true });
    }

    if (hasError) return;

    await jsonRequest(urls().feedback, {
      method: 'POST',
      data: {
        subject: '[System generated] Pricing inquiry',
        body: JSON.stringify({
          courseCount: courseCount.id,
          studentCount: studentCount.id,
          courseStartDate,
          phone: dialCode + phone,
        }),
      },
    });
    onNext();
  };

  public render() {
    const {
      isSelectCourseCountOpen,
      courseCount,
      courseCountError,
      isSelectStudentCountOpen,
      studentCount,
      studentCountError,
      courseStartDate,
      courseStartDateError,
      phone,
    } = this.getState();

    return h('form', [
      h('div', style([mb('0.5rem')]), [
        'How many courses are you teaching in Spring 2023?',
        courseCountError ? h('span', style(['errorRed', 'bold']), ' * Required') : null,
      ]),
      Select({
        type: SelectType.SINGLE,
        noFloatingLabel: true,
        isOpen: isSelectCourseCountOpen,
        value: courseCount,
        placeholder: ' ',
        options: COURSE_COUNT_OPTIONS,
        style: { width: '80px', marginBottom: '1rem' },
        onToogleDropDown: (isOpen) => {
          this.setState({ isSelectCourseCountOpen: isOpen });
        },
        onChange: (value) => {
          this.setState({
            courseCount: value,
            courseCountError: false,
          });
        },
      }),
      h('div', style([mb('0.5rem')]), [
        'How many students will be enrolled in your courses in Spring 2023 (total)?',
        studentCountError ? h('span', style(['errorRed', 'bold']), ' * Required') : null,
      ]),
      Select({
        type: SelectType.SINGLE,
        noFloatingLabel: true,
        isOpen: isSelectStudentCountOpen,
        value: studentCount,
        placeholder: ' ',
        options: STUDENT_COUNT_OPTIONS,
        style: { width: '120px', marginBottom: '1rem' },
        onToogleDropDown: (isOpen) => {
          this.setState({ isSelectStudentCountOpen: isOpen });
        },
        onChange: (value) => {
          this.setState({
            studentCount: value,
            studentCountError: false,
          });
        },
      }),
      h('div', style([mb('0.5rem')]), [
        'When do you teach your first class?',
        courseStartDateError ? h('span', style(['errorRed', 'bold']), ' * Required') : null,
      ]),
      DatePicker({
        minDate: new Date(),
        icon: Icon(icons.calendar, style(['flex', 'alignCenter', 'large', 'grey'])),
        value: courseStartDate,
        style: {
          marginBottom: '1rem',
          width: '140px',
          height: '42px',
          gap: '1rem',
          borderBottom: '2px solid #aaa',
        },
        onchange: (date) => {
          this.setState({
            courseStartDate: date,
            courseStartDateError: false,
          });
        },
      }),
      h('div', style([mb('0.5rem')]), 'What is your phone number?'),
      PhoneInput({
        value: phone,
        placeholder: 'Phone',
        style: {
          marginBottom: '1rem',
        },
        onChange: (phone, dialCode) => {
          this.setState({ phone, dialCode });
        },
      }),
      h('div', style(['flex', 'spaceBetween']), [
        FlatButton('Go back', {
          onclick: this.handleBack,
        }),
        FlatButton('Continue', {
          onclick: this.handleNext,
        }),
      ]),
    ]);
  }
}
