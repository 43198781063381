import history from 'core/history';
import matchPath from 'core/matchPath';
import { BaseMeta, IRoute, p, r } from 'core/router';
import { sessionStorage } from 'core/storage';

import appService from 'acadly/app/service';
import { toTitleCase, values } from 'acadly/utils';

import { Actions } from './app/actions';
import { getStore } from './store';

const getin = r(['/'], {
  meta: {
    context: 'getin',
    pageTitle: 'Acadly - Web App',
  },
});

const login = r(['/login'], {
  meta: {
    context: 'getin',
    pageTitle: 'Acadly - Login',
  },
});

const signup = r(['/signup'], {
  meta: {
    context: 'getin',
    pageTitle: 'Acadly - Signup',
  },
});

const signupStudent = r(['/signup/student'], {
  meta: {
    context: 'getin',
    pageTitle: 'Acadly - Student Signup',
  },
});

const signupTeacher = r(['/signup/teacher'], {
  meta: {
    context: 'getin',
    pageTitle: 'Acadly - Teacher Signup',
  },
});

// const getinEmail = r(
//     ["/getin/email"],
//     { meta: { context: "getin"} }
// );

// const getinPassword = r(
//     ["/getin/getpassword"],
//     { meta: { context: "getin" } }
// );
// const getinForgotPassword = r(
//     ["/getin/forgotpassword"],
//     { meta: { context: "getin" } }
// );

// const getinSetPassword = r(
//     ["/getin/setpassword"],
//     { meta: { context: "getin" } }
// );

// const getinTempPassword = r(
//     ["/getin/temppassword"],
//     { meta: { context: "getin" } }
// );

// const getinRole = r(
//     ["/getin/role"],
//     { meta: { context: "getin" } }
// );

// const getinVerificationAwaited = r(
//     ["/getin/verificationawaited"],
//     { meta: { context: "getin" } }
// );

// const getinSetName = r(
//     ["/getin/setname"],
//     { meta: { context: "getin" } }
// );

// const getinSetProfile = r(
//     ["/getin/setprofile"],
//     { meta: { context: "getin" } }
// );

const home = r([`/home`], {
  meta: {
    context: 'home',
    pageTitle: 'Acadly - Home',
  },
});

const refer = r([`/refer`], {
  meta: {
    context: 'home',
    pageTitle: 'Acadly - Referral',
  },
  parent: () => home.navigate({}),
});

const settings = r([`/settings`], {
  meta: {
    context: 'home',
    pageTitle: 'Acadly - Settings',
  },
});

const zoomInstallSettings = r(['/settings/applications/zoom/install'], {
  meta: {
    context: 'home',
    pageTitle: 'Acadly - Zoom Installation',
  },
});

const archivedCourses = r(['/', p('univSlug'), `/archived`], {
  meta: {
    context: 'home',
    pageTitle: 'Acadly - Archived Courses',
  },
  parent: () => home.navigate({}),
});

import courseService from 'acadly/course/service';

const course = r(['/', p('univSlug'), `/courses/`, p('courseShortId')], {
  meta: {
    context: 'course',
    pageTitle: 'Acadly - Course Page',
  },
  parent: courseGoBack,
});

function courseGoBack() {
  const course = courseService.getCurrentCourse();
  if (course && course.isArchived) {
    archivedCourses.navigate({
      univSlug: appService.getUniversitySlug(),
    });
  } else {
    home.navigate({});
  }
}

const courseTimeline = r(['/', p('univSlug'), `/courses/`, p('courseShortId'), '/timeline'], {
  meta: {
    context: 'course',
    pageTitle: 'Acadly - Course Timeline',
  },
  parent: courseGoBack,
});

const courseSyllabus = r(['/', p('univSlug'), `/courses/`, p('courseShortId'), '/syllabus'], {
  meta: {
    context: 'course',
    pageTitle: 'Acadly - Course Syllabus',
  },
  parent: courseGoBack,
});
const courseInfo = r(['/', p('univSlug'), `/courses/`, p('courseShortId'), '/info'], {
  meta: {
    context: 'course',
    pageTitle: 'Acadly - Course Info',
  },
  parent: courseGoBack,
});

const newAnnouncement = r(
  ['/', p('univSlug'), `/courses/`, p('courseShortId'), '/timeline/new-announcement'],
  {
    meta: {
      context: 'course',
      pageTitle: 'Acadly - Create Announcement',
    },
    parent: (params) => {
      courseTimeline.navigate({
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const courseAnnouncement = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/announcements/',
    p('announcementShortId'),
  ],
  {
    meta: {
      context: 'course',
      pageTitle: 'Acadly - Course Announcement',
    },
    parent: (params) => {
      courseTimeline.navigate({
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const courseAssignment = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/assignments/',
    p('assignmentShortId'),
  ],
  {
    meta: {
      context: 'assignment',
      pageTitle: 'Acadly - Course Assignment',
    },
    parent: (params) => {
      courseTimeline.navigate({
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const cls = r(
  ['/', p('univSlug'), `/courses/`, p('courseShortId'), '/timeline/classes/', p('classShortId')],
  {
    meta: {
      context: 'class',
      pageTitle: 'Acadly - Class Page',
    },
    parent: (params) => {
      courseTimeline.navigate({
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);
const classActivities = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities',
  ],
  {
    meta: {
      context: 'class',
      pageTitle: 'Acadly - Class Activities',
    },
    parent: (params) => {
      courseTimeline.navigate({
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const classAttendance = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/attendance',
  ],
  {
    meta: {
      context: 'class',
      pageTitle: 'Acadly - Class Attendance',
    },
    parent: (params) => {
      courseTimeline.navigate({
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const classAgenda = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/agenda',
  ],
  {
    meta: {
      context: 'class',
      pageTitle: 'Acadly - Class Agenda',
    },
    parent: (params) => {
      courseTimeline.navigate({
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const classQuiz = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities',
    '/quizzes/',
    p('quizShortId'),
  ],
  {
    meta: {
      context: 'quiz',
      pageTitle: 'Acadly - Class Quiz',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const classPoll = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities',
    '/polls/',
    p('pollShortId'),
  ],
  {
    meta: {
      context: 'poll',
      pageTitle: 'Acadly - Class Poll',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const classDiscussion = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities',
    '/discussions/',
    p('discussionShortId'),
  ],
  {
    meta: {
      context: 'discussion',
      pageTitle: 'Acadly - Class Discussion',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const classResource = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities',
    '/resources/',
    p('resourceShortId'),
  ],
  {
    meta: {
      context: 'resource',
      pageTitle: 'Acadly - Class Resources',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const newQuery = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities',
    '/queries/create/',
    p('isAnon'),
  ],
  {
    meta: {
      context: 'class', // class comments should appear when creating query
      pageTitle: 'Acadly - Create Query',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const classQuery = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities',
    '/queries/',
    p('queryShortId'),
  ],
  {
    meta: {
      context: 'query',
      pageTitle: 'Acadly - Class Queries',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const filterActivities = r(
  ['/', p('univSlug'), `/courses/`, p('courseShortId'), '/timeline/activities/', p('type')],
  {
    meta: {
      context: 'course',
      pageTitle: (params) => `Acadly - Filter ${toTitleCase(params.type)}`,
    },
    parent: (params) => {
      courseTimeline.navigate({
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const filteredAnnouncement = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/activities/announcements/',
    p('announcementShortId'),
  ],
  {
    meta: {
      context: 'course',
      pageTitle: 'Acadly - Course Announcement',
    },
    parent: (params) => {
      filterActivities.navigate({
        courseShortId: params.courseShortId,
        type: 'announcements',
        univSlug: params.univSlug,
      });
    },
  }
);

const filteredAssignment = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/activities/assignments/',
    p('assignmentShortId'),
  ],
  {
    meta: {
      context: 'assignment',
      pageTitle: 'Acadly - Course Assignments',
    },
    parent: (params) => {
      filterActivities.navigate({
        courseShortId: params.courseShortId,
        type: 'assignments',
        univSlug: params.univSlug,
      });
    },
  }
);

const filteredQuiz = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities/quizzes/',
    p('quizShortId'),
  ],
  {
    meta: {
      context: 'quiz',
      pageTitle: 'Acadly - Class Quiz',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const filteredPoll = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities/polls/',
    p('pollShortId'),
  ],
  {
    meta: {
      context: 'poll',
      pageTitle: 'Acadly - Class Poll',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
    // parent: (params) => filterActivities.navigate({
    //     courseShortId: params.courseShortId,
    //     type: "polls"
    // })
  }
);

const filteredResource = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities/resources/',
    p('resourceShortId'),
  ],
  {
    meta: {
      context: 'resource',
      pageTitle: 'Acadly - Class Resources',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const filteredDiscussion = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities/discussions/',
    p('discussionShortId'),
  ],
  {
    meta: {
      context: 'discussion',
      pageTitle: 'Acadly - Class Discussion',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

const filteredQuery = r(
  [
    '/',
    p('univSlug'),
    `/courses/`,
    p('courseShortId'),
    '/timeline/classes/',
    p('classShortId'),
    '/activities/queries/',
    p('queryShortId'),
  ],
  {
    meta: {
      context: 'query',
      pageTitle: 'Acadly - Class Query',
    },
    parent: (params) => {
      classActivities.navigate({
        classShortId: params.classShortId,
        courseShortId: params.courseShortId,
        univSlug: params.univSlug,
      });
    },
  }
);

export const Routes = {
  getin,
  login,
  signup,
  signupStudent,
  signupTeacher,
  // getinEmail, getinPassword, getinForgotPassword,
  // getinSetPassword, getinRole, getinVerificationAwaited,
  // getinTempPassword, getinSetName, getinSetProfile,
  home,
  refer,
  archivedCourses,
  settings,
  zoomInstallSettings,
  course,
  courseTimeline,
  courseInfo,
  courseSyllabus,
  newAnnouncement,
  courseAnnouncement,
  courseAssignment,
  class: cls,
  classActivities,
  classAttendance,
  classAgenda,
  classQuiz,
  classPoll,
  classDiscussion,
  classResource,
  newQuery,
  classQuery,
  filterActivities,
  filteredAnnouncement,
  filteredAssignment,
  filteredQuiz,
  filteredPoll,
  filteredResource,
  filteredQuery,
  filteredDiscussion,
};

export function goBack() {
  const active = getActiveRoute();
  if (active) {
    active.route.goBack();
  } else {
    throw new Error(
      `URL ${history.location.pathname} does not match any routes` + ' defined in routes.ts'
    );
  }
}

history.listen(() => {
  setRouteContext();
});

export function setRouteContext() {
  const active = getActiveRoute();
  if (active) {
    if (typeof active.route.meta.pageTitle === 'function') {
      document.title = active.route.meta.pageTitle(active.params);
    } else {
      document.title = active.route.meta.pageTitle;
    }

    persistRoute(active);

    const currentContext = getStore().getState().app.context;

    if (currentContext !== active.route.meta.context) {
      getStore().dispatch(Actions.setContext(active.route.meta.context as IAppContext));
    }
  }
}
interface IActiveRoute<Params, Meta extends BaseMeta<Params>> {
  route: IRoute<Params, Meta>;
  params: Params;
}

export function getActiveRoute<
  Params = any,
  Meta extends BaseMeta<Params> = BaseMeta<Params>
>(): IActiveRoute<Params, Meta> | null {
  for (const name of Object.keys(Routes)) {
    const route = (Routes as any)[name];
    if (route.isActive()) {
      const match = matchPath(history.location.pathname, {
        path: route.path,
        exact: true,
      });
      if (match) {
        return {
          route,
          params: match.params,
        };
      }
    }
  }
  return null;
}

// (function goToSavedRoute() {
//     const route = getSavedRoute();
//     if (route) {
//         route.route.navigate(route.params);
//         persistRoute(route);
//     }
// })();

function persistRoute<Params, Meta extends BaseMeta<Params>>(route: IActiveRoute<Params, Meta>) {
  sessionStorage.setItem(
    'route',
    JSON.stringify({
      path: route.route.path,
      params: route.params,
    })
  );
}

// function getSavedRoute() {
//     try {
//         const currentPath = JSON.parse(sessionStorage.getItem("route")!).path;
//         const route = getRouteForPath(currentPath);

//         if (route) {
//             const match = matchPath(history.location.pathname, {
//                 path: route.path
//             });
//             if (match) {
//                 return {
//                     route,
//                     params: match.params
//                 };
//             } else {
//                 throw new Error(
//                     `Couldn't match location ${history.location.pathname} with ` +
//                     `path from store ${currentPath}. Session store might be corrupt ` +
//                     `or location is not being correctly set on app initialization`
//                 );
//             }
//         } else {
//             return null;
//         }
//     } catch (e) {
//         return null;
//     }
// }

export function getRouteForPath(path: string) {
  return values(Routes).filter((route) => route.path === path)[0];
}
