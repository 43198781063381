import { Class } from 'acadly/class/functions';
import { IRootAction, IRootActionObject } from 'acadly/IRootAction';
import { IRootState } from 'acadly/IRootState';
import { update } from 'acadly/utils';
import * as utils from 'acadly/utils';

import { findIndex, mapValues } from '../../utils';
import {
  IClassActivityUserDataResponse,
  IFetchTimelineResponse,
  IMyCoursesResponseCourse,
  IMyCoursesResponseUserData,
  IOfficeHoursSaveRequest,
  IPublishScheduleResponse,
  IResponseCourseClassUserData,
} from '../api';
import * as actions from '../ICourseActionMap';
import { ICourseState, ICourseUserData } from '../ICourseState';
import { initialState } from '../initialState';

export function reducer(state: IRootState, action: IRootAction): IRootState {
  if (action.type === '@course/CLOSE_COURSE_PAGE') {
    return {
      ...state,
      courses: closeCoursePage(state.courses),
      comments: {
        ...state.comments,
        comments: undefined,
      },
    };
  }

  return {
    ...state,
    courses: coursesSliceReducer(state.courses, action),
  };
}

export function coursesSliceReducer(state: ICourseState, action: IRootAction): ICourseState {
  switch (action.type) {
    case '@course/SET_COURSES_LOADING':
      return setCoursesLoading(state, action.payload);
    case '@course/FETCH_MY_COURSES_SUCCESS':
      return fetchMyCoursesSuccess(state, action);
    case '@course/JOIN_COURSE_SUCCESS':
      return joinCourseSuccess(state, action);
    case '@course/info/OFFICE_HOURS_SAVE_SUCCESS':
      return officeHoursSaveSuccess(state, action.payload);
    case '@course/CREATE_SUCCESS':
      return createSuccess(state, action);
    case '@course/INITIALIZE_SUCCESS':
      return initializeSuccess(state, action);
    case '@course/SET_CURRENT_COURSE':
      return setCurrentCourse(state, action);
    case '@course/FETCH_TIMELINE_SUCCESS':
      return fetchTimelineSuccess(state, action);
    case '@course/info/LINK_ADD_SUCCESS':
      return infoLinkAddSuccess(state, action.payload);
    case '@course/info/LINK_REMOVE_SUCCESS':
      return infoLinkRemoveSuccess(state, action.payload);
    case '@course/SET_TIMEZONE_SUCCESS': {
      if (!state.currentCourseId) return state;
      const currentCourse = state.courses[state.currentCourseId];
      if (!currentCourse) return state;
      const currentCourseStatus = currentCourse.status;
      return {
        ...state,
        courses: {
          ...state.courses,
          [state.currentCourseId]: {
            ...currentCourse,
            status: {
              ...currentCourseStatus,
              timezoneSet: 1,
            },
            courseTimezone: action.payload,
          },
        },
      };
    }
    case '@course/info/FETCH_SUCCESS': {
      if (!state.currentCourseId) return state;
      const currentCourse = state.courses[state.currentCourseId];
      if (!currentCourse) return state;

      return {
        ...state,
        courses: {
          ...state.courses,
          [state.currentCourseId]: {
            ...currentCourse,
            status: action.payload.status,
            team: action.payload.team,
          },
        },
        info: {
          description: action.payload.description,
          readingList: action.payload.readingList,
          officeHours: action.payload.officeHours || [],
        },
      };
    }
    case '@course/info/SET_DESCRIPTION_SUCCESS': {
      if (!state.info) return state;
      return {
        ...state,
        info: {
          ...state.info,
          description: action.payload,
        },
      };
    }
    case '@course/info/ADD_TEAM_MEMBER_SUCCESS': {
      if (!state.currentCourseId) return state;
      const currentCourse = state.courses[state.currentCourseId];
      if (!currentCourse) return state;
      return {
        ...state,
        courses: {
          ...state.courses,
          [state.currentCourseId]: {
            ...currentCourse,
            team: [...currentCourse.team, action.payload],
          },
        },
      };
    }
    case '@course/info/REMOVE_TEAM_MEMBER_SUCCESS': {
      if (!state.currentCourseId) return state;
      const currentCourse = state.courses[state.currentCourseId];
      if (!currentCourse) return state;
      return {
        ...state,
        courses: {
          ...state.courses,
          [currentCourse._id]: {
            ...currentCourse,
            team: currentCourse.team.filter((member) => member.userId !== action.payload),
          },
        },
      };
    }
    case '@course/info/ADD_BOOK_SUCCESS': {
      return {
        ...state,
        info: {
          ...state.info,
          readingList: {
            ...state.info.readingList,
            books: [...state.info.readingList.books, action.payload],
          },
        },
      };
    }
    case '@course/info/REMOVE_BOOK_SUCCESS': {
      if (!state.info) return state;
      const info = state.info;
      const readingList = info.readingList;
      const books = info.readingList.books;

      return {
        ...state,
        info: {
          ...info,
          readingList: {
            ...readingList,
            books: books.filter((book) => book._id !== action.payload),
          },
        },
      };
    }
    case '@course/info/EDIT_BOOK_SUCCESS': {
      const info = state.info!;
      const readingList = info.readingList;
      const books = info.readingList.books;
      const index = findIndex(books, (book) => book._id === action.payload._id);
      if (index === null) return state;
      return {
        ...state,
        info: {
          ...info,
          readingList: {
            ...readingList,
            books: [...books.slice(0, index), action.payload, ...books.slice(index + 1)],
          },
        },
      };
    }
    case '@course/info/PUBLISH_SUCCESS': {
      if (!state.currentCourseId) return state;
      const currentCourseId = state.currentCourseId;
      const currentCourse = state.courses[currentCourseId];
      if (!currentCourse) return state;
      return {
        ...state,
        courses: {
          ...state.courses,
          [currentCourse._id]: {
            ...currentCourse,
            status: {
              ...currentCourse.status,
              infoPublished: 1,
            },
          },
        },
      };
    }
    case '@course/SET_START_DATE_SUCCESS': {
      const timeline = state.timeline;
      if (!timeline) return state;
      if (!state.currentCourseId) return state;
      const course = state.courses[state.currentCourseId];
      return {
        ...state,
        timeline: {
          ...timeline,
          courseDates: {
            ...timeline.courseDates,
            startDate: action.payload,
          },
        },
        courses: {
          ...state.courses,
          [state.currentCourseId]: {
            ...course,
            dates: {
              endDate: 0,
              startDateL: '',
              endDateL: '',
              ...course.dates,
              startDate: action.payload,
            },
          },
        },
      };
    }
    case '@course/SET_END_DATE_SUCCESS':
      return setEndDateSuccess(state, action.payload);
    case '@course/SCHEDULE_PUBLISH_SUCCESS':
      return schedulePublishSuccess(state, action.payload);
    case '@course/GO_LIVE_SUCCESS':
      return goLiveSuccess(state, action.payload.joinCode);
    case '@course/analytics/FETCH_STUDENTS_SUCCESS':
      return analyticsFetchStudentsSuccess(state, action);
    case '@course/ADD_STUDENTS_SUCCESS':
      return addStudentsSuccess(state, action.payload);
    case '@course/REINSTATE_STUDENT_SUCCESS':
      return reinstateStudent(state, action.payload);
    case '@course/REMOVE_STUDENT_SUCCESS':
      return removeStudentSuccess(state, action.payload);
    case '@course/analytics/CLEAR_STUDENTS':
      return clearCourseStudentsAnalytics(state);
    case '@course/ARCHIVE_SUCCESS':
      return courseArchiveSuccess(state, action.payload);
    case '@course/FETCH_ARCHIVED_SUCCESS':
      return fetchArchivedSuccess(state, action.payload);
    case '@course/DELETE_SUCCESS':
      return deleteSuccess(state, action.payload);

    case '@course/info/ADD_INFO_FILE_SUCCESS':
      return addFileSuccess(state, action.payload);
    case '@course/info/REMOVE_INFO_FILE_SUCCESS':
      return removeFileSuccess(state, action.payload);
    case '@course/STUDENT_PURCHASE_SUCCESSFUL':
      return studentPurchaseSuccessful(state, action.payload);
    default:
      return state;
  }
}

function studentPurchaseSuccessful(
  state: ICourseState,
  payload: IRootActionObject<'@course/STUDENT_PURCHASE_SUCCESSFUL'>['payload']
): ICourseState {
  return {
    ...state,
    userData: {
      ...state.userData,
      [payload.courseId]: {
        ...state.userData[payload.courseId],
        hasPaid: 1,
        payTill: 0,
      },
    },
  };
}

function infoLinkAddSuccess(
  state: ICourseState,
  payload: IRootActionObject<'@course/info/LINK_ADD_SUCCESS'>['payload']
): ICourseState {
  return {
    ...state,
    info: {
      ...state.info,
      readingList: {
        ...state.info.readingList,
        links: [
          ...state.info.readingList.links,
          {
            _id: payload.linkId,
            title: payload.title,
            url: payload.url,
          },
        ],
      },
    },
  };
}

function infoLinkRemoveSuccess(
  state: ICourseState,
  payload: IRootActionObject<'@course/info/LINK_REMOVE_SUCCESS'>['payload']
): ICourseState {
  return {
    ...state,
    info: {
      ...state.info,
      readingList: {
        ...state.info.readingList,
        links: state.info.readingList.links.filter((link) => link._id !== payload.linkId),
      },
    },
  };
}

export function addFileSuccess(
  state: ICourseState,
  payload: actions.IInfoFileUploadSuccessPayload
): ICourseState {
  return {
    ...state,
    info: {
      ...state.info,
      readingList: {
        ...state.info.readingList,
        files: [...state.info.readingList.files, payload],
      },
    },
  };
}

export function removeFileSuccess(state: ICourseState, fileId: string): ICourseState {
  return {
    ...state,
    info: {
      ...state.info,
      readingList: {
        ...state.info.readingList,

        files: state.info.readingList.files.filter((f) => f._id !== fileId),
      },
    },
  };
}

function setCurrentCourse(
  state: ICourseState,
  action: IRootActionObject<'@course/SET_CURRENT_COURSE'>
): ICourseState {
  return {
    ...state,
    currentCourseId: action.payload,
    topics: [],
  };
}

function createSuccess(
  state: ICourseState,
  action: IRootActionObject<'@course/CREATE_SUCCESS'>
): ICourseState {
  return {
    ...state,
    courses: {
      ...state.courses,
      [action.payload.courseId]: {
        _id: action.payload.courseId,
        activities: {
          ...action.payload.activities,
          numCommentsTotal: 0,
        },
        dates: {
          endDate: 0,
          endDateL: '',
          startDate: 0,
          startDateL: '',
        },
        details: action.payload.details,
        status: action.payload.status,
        totalActivities: 0,
        team: action.payload.team,
        lastUpdated: action.payload.timestamp,
        isArchived: 0,
        canStream: action.payload.canStream,
        canProxy: action.payload.canProxy,
        isPro: action.payload.isPro,
        enrolmentType: 'enrolmentInvite',
        isDemo: 0,
        cost: 0,
      },
    },
    userData: {
      ...state.userData,
      [action.payload.courseId]: {
        activitiesSeen: 0,
        numCommentsSeen: 0,
        role: 'admin',
        queriesSeen: 0,
        courseStatus: 'active',
        subscribed: 0,
        hasPaid: 0,
        payTill: -1,
      },
    },
  };
}

function initializeSuccess(
  state: ICourseState,
  action: IRootActionObject<'@course/INITIALIZE_SUCCESS'>
): ICourseState {
  const course = state.courses[action.payload.courseId];
  return {
    ...state,
    courses: {
      ...state.courses,
      [course._id]: {
        ...course,
        status: {
          ...course.status,
          initialized: action.payload.initialized,
        },
      },
    },
  };
}

function schedulePublishSuccess(state: ICourseState, data: IPublishScheduleResponse): ICourseState {
  if (!state.currentCourseId) return state;
  const course = state.courses[state.currentCourseId];
  if (!course) return state;
  return update(state, {
    courses: {
      [course._id]: {
        joinCode: data.joinCode,
        enrolmentType: data.enrolmentType,
        status: {
          schedulePublished: 1,
        },
      },
    },
  });
}

function goLiveSuccess(state: ICourseState, joinCode: string): ICourseState {
  if (!state.currentCourseId) return state;
  const course = state.courses[state.currentCourseId];
  if (!course) return state;
  return update(state, {
    courses: {
      [course._id]: {
        joinCode,
        status: {
          courseLive: 1,
        },
      },
    },
  });
}

function fetchMyCoursesSuccess(
  state: ICourseState,
  action: IRootActionObject<'@course/FETCH_MY_COURSES_SUCCESS'>
): ICourseState {
  const courses: ObjectMap<ICourse> = {};
  for (const course of action.payload.courseData) {
    courses[course._id] = responseCourseToCourse(course);
  }

  return {
    ...state,
    courses: {
      ...state.courses,
      ...courses,
    },
    userData: mapValues(action.payload.userData.courses || {}, responseUserDataToUserData),
  };
}

function joinCourseSuccess(
  state: ICourseState,
  action: IRootActionObject<'@course/JOIN_COURSE_SUCCESS'>
): ICourseState {
  const courses: ObjectMap<ICourse> = {};
  for (const course of action.payload.courseData) {
    courses[course._id] = responseCourseToCourse(course);
  }

  return {
    ...state,
    courses: {
      ...state.courses,
      ...courses,
    },
    userData: {
      ...state.userData,
      ...mapValues(action.payload.userData.courses || {}, responseUserDataToUserData),
    },
  };
}

function analyticsFetchStudentsSuccess(
  state: ICourseState,
  action: IRootActionObject<'@course/analytics/FETCH_STUDENTS_SUCCESS'>
): ICourseState {
  return {
    ...state,
    analytics: {
      ...state.analytics,
      students: action.payload,
    },
  };
}

function fetchTimelineSuccess(
  state: ICourseState,
  action: IRootActionObject<'@course/FETCH_TIMELINE_SUCCESS'>
): ICourseState {
  // const courseUserData = state.currentCourseId
  //     ? state.userData[state.currentCourseId]
  //     : undefined;
  const numCommentsSeen = action.payload.timelineResponse.userData
    ? action.payload.timelineResponse.userData.numCommentsSeen || 0
    : 0;
  const subscribed = action.payload.timelineResponse.userData
    ? action.payload.timelineResponse.userData.subscribed || 0
    : 0;
  const course = state.courses[state.currentCourseId!];
  const allParticipationLabels = new Set<string>();
  const courseData = action.payload.timelineResponse.courseData;

  if (courseData) {
    courseData.forEach((item) => {
      if (item.nodeType === 'class' && item.details.type === 'lecture') {
        item.participationLabels.map((l) => allParticipationLabels.add(l));
      }
    });
  }

  return {
    ...state,
    courses: {
      ...state.courses,
      [course._id]: {
        ...course,
        joinCode: action.payload.joinCode,
        enrolmentType: action.payload.enrolmentType,
      },
    },
    allParticipationLabels: Array.from(allParticipationLabels),
    timeline: {
      courseDates: {
        startDate: action.payload.timelineResponse.courseDates.startDate,
        endDate: action.payload.timelineResponse.courseDates.endDate,
      },
      schedule: action.payload.timelineResponse.courseDates.schedule,
      items: (action.payload.timelineResponse.courseData || [])
        .map(
          processTimelineItem(
            action.payload.userId,
            action.payload.timelineResponse,
            action.payload.timeStamp
          )
        )
        .sort((a, b) => a.details.dueDateTime - b.details.dueDateTime),
      userData: {
        ...state.userData,
        numCommentsSeen: numCommentsSeen,
        subscribed: subscribed,
      },
    },
    // userData: state.currentCourseId
    //     ? {
    //         ...state.courses.userData,
    //         [state.currentCourseId]: courseUserData
    //             ? {
    //                 ...courseUserData,
    //                 numCommentsSeen: numCommentsSeen
    //             }
    //             : courseUserData
    //     }
    //     : {
    //         ...state.userData
    //     }
  };
}

function setEndDateSuccess(state: ICourseState, payload: UnixTimestamp): ICourseState {
  const timeline = state.timeline;
  if (!timeline) return state;
  if (!state.currentCourseId) return state;
  const course = state.courses[state.currentCourseId];
  return {
    ...state,
    timeline: {
      ...timeline,
      courseDates: {
        ...timeline.courseDates,
        endDate: payload,
      },
    },
    courses: {
      ...state.courses,
      [state.currentCourseId]: {
        ...course,
        dates: {
          startDateL: '',
          endDateL: '',
          startDate: 0,
          ...course.dates,
          endDate: payload,
        },
      },
    },
  };
}

export function deleteSuccess(state: ICourseState, courseId: string): ICourseState {
  return {
    ...state,
    courses: utils.removeKey(state.courses, courseId),
    userData: utils.removeKey(state.userData, courseId),
  };
}

export function fetchArchivedSuccess(
  state: ICourseState,
  payload: actions.IArchivedFetchSuccessPayload
): ICourseState {
  const courses: ICourse[] = payload.courseData.map(
    (data): ICourse => ({
      _id: data._id,
      details: data.details,
      courseTimezone: data.courseTimezone,
      team: data.team,
      status: {
        initialized: 1,
        courseLive: 1,
        infoPublished: 1,
        schedulePublished: 1,
        studentsEnrolled: 1,
        timezoneSet: 1,
      },
      lastUpdated: 0,
      activities: {
        ...data.activities,
        numCommentsTotal: data.activities.numCommentsTotal || 0,
      },
      totalActivities: data.totalActivities,
      isArchived: 1,
      canStream: 0,
      canProxy: 0,
      isDemo: 0,
      cost: 0,
      dates: {
        startDate: data.dates.startDate,
        startDateL: '',
        endDate: data.dates.endDate,
        endDateL: '',
      },
    })
  );

  return {
    ...state,
    courses: {
      ...state.courses,
      ...utils.makeObjectWithKey(courses, '_id'),
    },
  };
}

export function courseArchiveSuccess(state: ICourseState, courseId: string): ICourseState {
  if (!state.courses[courseId]) return state;
  return {
    ...state,
    courses: {
      ...state.courses,
      [courseId]: {
        ...state.courses[courseId],
        isArchived: 1,
      },
    },
  };
}

export function officeHoursSaveSuccess(state: ICourseState, payload: IOfficeHoursSaveRequest) {
  return {
    ...state,
    info: {
      ...state.info,
      officeHours: payload.officeHours,
    },
  };
}

export function addStudentsSuccess(
  state: ICourseState,
  payload: actions.IAddStudentsSuccessPayload
): ICourseState {
  if (!state.analytics.students) return state;
  const currentCourseId = state.currentCourseId;
  if (!currentCourseId) return state;
  const currentCourse = state.courses[currentCourseId];
  const students = state.analytics.students;
  return {
    ...state,
    courses: {
      ...state.courses,
      [currentCourseId]: {
        ...currentCourse,
        status: {
          ...currentCourse.status,
          studentsEnrolled: 1,
        },
      },
    },
    analytics: {
      ...state.analytics,
      students: {
        ...students,
        enrolledStudents: [
          ...students.enrolledStudents,
          ...payload.added.map((s) => ({
            courseStatus: s.status,
            identifiers: {
              userId: s.userId,
              name: s.name,
              avatar: s.avatar,
              emailId: s.emailId,
              role: s.role,
            },
            joinedOn: payload.currentTimestamp,
            lastActive: undefined,
          })),
        ],
        pendingStudents: [
          ...students.pendingStudents,
          ...payload.invited.map((s) => ({
            _id: s.userId,
            emailId: s.emailId,
          })),
        ],
      },
    },
  };
}

function clearCourseStudentsAnalytics(state: ICourseState): ICourseState {
  return {
    ...state,
    analytics: {
      ...state.analytics,
      students: initialState.analytics.students,
    },
  };
}

function reinstateStudent(state: ICourseState, userId: string): ICourseState {
  const analytics = state.analytics;
  if (!analytics) return state;
  const students = analytics.students;
  if (!students) return state;
  const student = students.removedStudents.find((s) => s.identifiers.userId === userId);
  if (!student) return state;
  return utils.update(state, {
    analytics: {
      students: {
        removedStudents: students.removedStudents.filter((s) => s.identifiers.userId !== userId),
        enrolledStudents: students.enrolledStudents.concat({
          ...student,
          courseStatus: 'active',
        }),
      },
    },
  });
}

function removeStudentSuccess(
  state: ICourseState,
  payload: actions.IRemoveStudentSuccessPayload
): ICourseState {
  const { userId, isPending } = payload;
  const studentId = userId;
  const analytics = state.analytics;
  if (!analytics) return state;
  const students = analytics.students;
  if (!students) return state;
  if (isPending) {
    return {
      ...state,
      analytics: {
        ...analytics,
        students: {
          ...students,
          pendingStudents: students.pendingStudents.filter((s) => s._id !== userId),
        },
      },
    };
  }
  const student = students.enrolledStudents.find((s) => s.identifiers.userId === studentId);
  if (!student) return state;
  return {
    ...state,
    analytics: {
      ...analytics,
      students: {
        ...students,
        enrolledStudents: students.enrolledStudents.filter(
          (s) => s.identifiers.userId !== studentId
        ),
        removedStudents: [
          ...students.removedStudents,
          {
            ...student,
            courseStatus: 'removed',
          },
        ],
      },
    },
  };
}

function closeCoursePage(state: ICourseState): ICourseState {
  return {
    ...state,
    timeline: undefined,
    currentCourseId: undefined,
  };
}

export function setCoursesLoading(state: ICourseState, payload: boolean): ICourseState {
  return {
    ...state,
    isLoadingCourses: payload,
  };
}

/**
 * Takes currentUserId and timeline response object (returned when fetching
 * timeline data) and returns a function that converts a response timeline
 * item (ICourseTimelineItem) to a timeline item that we can save in the
 * store (ICourseTimelineItem). The difference between the two is that
 * a store timeline item has user data inside it and also, some fields
 * that are optional in the response are initialized to sane defaults so
 * that unnecessary null/undefined checking is not required all over the
 * application.
 */
function processTimelineItem(
  currentUserId: string,
  timelineResponse: IFetchTimelineResponse,
  timeStamp: UnixTimestamp
) {
  return (item: ICourseTimelineItemResponse): ICourseTimelineItem => {
    const isTeamMember =
      timelineResponse.courseTeam.filter((member) => member.userId === currentUserId).length > 0;
    if (item.nodeType === 'assignment') {
      return {
        ...item,
        details: {
          ...item.details,
          title: item.details.title || '',
          lastEditedOn: item.details.lastEditedOn || undefined,
        },
        userData: getAssignmentUserData(item, timelineResponse, isTeamMember),
      };
    } else if (item.nodeType === 'announcement') {
      const isCreator = item.details.createdBy.userId === currentUserId;
      if (
        timelineResponse.userData &&
        timelineResponse.userData.announcements &&
        timelineResponse.userData.announcements[item._id]
      ) {
        const responseUD = timelineResponse.userData.announcements[item._id];
        if (responseUD) {
          return {
            ...item,
            details: {
              ...item.details,
              attachments: item.details.attachments || [],
            },
            userData: {
              firstAccessedOn: isCreator ? item.details.createdOn : responseUD.firstAccessedOn,
              timesFetched: isCreator ? responseUD.timesFetched || 1 : responseUD.timesFetched || 0,
            },
          };
        }
      }
      return {
        ...item,
        details: {
          ...item.details,
          attachments: item.details.attachments || [],
        },
        userData: {
          firstAccessedOn: isCreator ? item.details.createdOn : undefined,
          timesFetched: isCreator ? 1 : 0,
        },
      };
    } else {
      // nodeType === "class"
      let userData = initialClassUserData();
      let classActivities =
        item.details.type === 'lecture' && item.activities
          ? item.activities
          : initialClassActivities();

      // Total activity count for students should be number of activities
      // published because students can't see unpublished activities
      if (!isTeamMember) {
        classActivities = update(classActivities, {
          preClass: {
            quizzes: {
              numTotal: classActivities.preClass.quizzes.numPublished,
            },
            polls: {
              numTotal: classActivities.preClass.polls.numPublished,
            },
            resources: {
              numTotal: classActivities.preClass.resources.numPublished,
            },
            discussions: {
              numTotal: classActivities.preClass.discussions.numPublished,
            },
          },
          inClass: {
            quizzes: {
              numTotal: classActivities.inClass.quizzes.numPublished,
            },
            polls: {
              numTotal: classActivities.inClass.polls.numPublished,
            },
            resources: {
              numTotal: classActivities.inClass.resources.numPublished,
            },
            discussions: {
              numTotal: classActivities.inClass.discussions.numPublished,
            },
          },
        });
      }
      if (timelineResponse.userData && timelineResponse.userData.classes) {
        const optionalResponseUD = timelineResponse.userData.classes[item._id];
        if (optionalResponseUD) {
          userData = responseClassUserDataToClassUserData(optionalResponseUD, isTeamMember, {
            ...item,
            activities: classActivities,
          });
        }
      }
      const result = {
        ...item,
        activities: classActivities,
        details: {
          ...item.details,
          title: item.details.title || '',
          status: Class.status(item, timeStamp),
        },
        summaryUpdatedOn: item.summaryUpdatedOn ? item.summaryUpdatedOn : undefined,
        userData,
      };
      return result;
    }
  };
}

function getAssignmentUserData(
  assignment: IAssignmentResponse,
  timelineResponse: IFetchTimelineResponse,
  isTeamMember: boolean
): IAssignmentUserData {
  const empty = {
    numCommentsSeen: 0,
    numCommentsSeenPreSub: 0,
    numCommentsSeenPostSub: 0,
  };
  let timelineAssignmentUserData;
  let lastAccessedOn;
  if (timelineResponse.userData && timelineResponse.userData.assignments) {
    timelineAssignmentUserData = timelineResponse.userData.assignments[assignment._id];
    lastAccessedOn = timelineAssignmentUserData
      ? timelineAssignmentUserData.lastAccessedOn
      : undefined;
  }
  const courseUserData = timelineResponse.userData;
  if (!courseUserData || !courseUserData.assignments) {
    return empty;
  }
  const assignmentUserData = courseUserData.assignments[assignment._id];
  if (!assignmentUserData) return empty;

  const userData: IAssignmentUserData = {
    numCommentsSeen: assignmentUserData.numCommentsSeen || 0,
    numCommentsSeenPreSub: assignmentUserData.numCommentsSeenPreSub || 0,
    numCommentsSeenPostSub: assignmentUserData.numCommentsSeenPostSub || 0,
    subscribed: assignmentUserData.subscribed || 0,
  };
  if (isTeamMember) {
    return userData;
  } else {
    return {
      ...userData,
      studentUserData: {
        status: assignmentUserData.status || 'inProgress',
        graded: assignmentUserData.graded,
        retracted: assignmentUserData.retracted || 0,
        timesStarted: assignmentUserData.timesStarted || 0,
        submittedOn: assignmentUserData.submittedOn,
        lastAccessedOn: lastAccessedOn,
      },
    };
  }
}

export function initialClassActivities(): IClassActivities {
  const inOrPreClass = {
    discussions: {
      numTotal: 0,
      numPublished: 0,
      numCommentsTotal: 0,
    },
    polls: {
      numTotal: 0,
      numPublished: 0,
      numCommentsTotal: 0,
    },
    queries: {
      numTotal: 0,
      numClosed: 0,
      numCommentsTotal: 0,
    },
    quizzes: {
      numTotal: 0,
      numPublished: 0,
      numCommentsTotal: 0,
    },
    resources: {
      numTotal: 0,
      numPublished: 0,
      numCommentsTotal: 0,
    },
  };
  return {
    numCommentsTotal: 0,
    inClass: inOrPreClass,
    preClass: inOrPreClass,
    reviewQueries: {
      numTotal: 0,
      numClosed: 0,
      numCommentsTotal: 0,
    },
  };
}

function responseUserDataToUserData(responseUserData: IMyCoursesResponseUserData): ICourseUserData {
  return {
    role: responseUserData.role,
    activitiesSeen: responseUserData.activitiesSeen ? responseUserData.activitiesSeen : 0,
    courseStatus: responseUserData.courseStatus ? responseUserData.courseStatus : 'active',
    lastActive: responseUserData.lastActive,
    numCommentsSeen: responseUserData.numCommentsSeen ? responseUserData.numCommentsSeen : 0,
    queriesSeen: responseUserData.queriesSeen ? responseUserData.queriesSeen : 0,
    subscribed: responseUserData.subscribed ? responseUserData.subscribed : 0,
    hasPaid: responseUserData.hasPaid ? responseUserData.hasPaid : 0,
    payTill: responseUserData.payTill ? responseUserData.payTill : 0,
  };
}

function responseCourseToCourse(responseCourse: IMyCoursesResponseCourse): ICourse {
  return {
    ...responseCourse,
    isArchived: 0,
    activities: {
      ...responseCourse.activities,
      numCommentsTotal: responseCourse.activities.numCommentsTotal || 0,
    },
  };
}

export function initialClassUserData(): IClassUserData {
  const empty = {
    discussions: {
      numCommentsSeen: 0,
      numSeen: 0,
    },
    polls: {
      numCommentsSeen: 0,
      numSeen: 0,
      numCompleted: 0,
    },
    queries: {
      numCommentsSeen: 0,
      numCompleted: 0,
      numSeen: 0,
    },
    quizzes: {
      numCommentsSeen: 0,
      numSeen: 0,
      numCompleted: 0,
    },
    resources: {
      numCommentsSeen: 0,
      numSeen: 0,
    },
  };
  return {
    numCommentsSeen: 0,
    inClass: empty,
    preClass: empty,
    reviewQueries: {
      numCompleted: 0,
      numSeen: 0,
      numCommentsSeen: 0,
    },
    subscribed: 0,
  };
}

function responseClassUserDataToClassUserData(
  responseClassUD: IResponseCourseClassUserData,
  isTeamMember: boolean,
  cls: IClassResponse & { activities: IClassActivities }
): IClassUserData {
  const inClassQueries =
    responseClassUD.inClass && responseClassUD.inClass.queries
      ? {
          numSeen: responseClassUD.inClass.queries.numSeen || 0,
          numCompleted: responseClassUD.inClass.queries.numCompleted || 0,
          numCommentsSeen: responseClassUD.inClass.queries.numCommentsSeen || 0,
        }
      : {
          numSeen: 0,
          numCompleted: 0,
          numCommentsSeen: 0,
        };
  const preClassQueries =
    responseClassUD.preClass && responseClassUD.preClass.queries
      ? {
          numSeen: responseClassUD.preClass.queries.numSeen || 0,
          numCompleted: responseClassUD.preClass.queries.numCompleted || 0,
          numCommentsSeen: responseClassUD.preClass.queries.numCommentsSeen || 0,
        }
      : {
          numSeen: 0,
          numCompleted: 0,
          numCommentsSeen: 0,
        };

  const responseActivityData = function (
    type: 'quizzes' | 'polls' | 'discussions' | 'resources',
    toBeDone: 'preClass' | 'inClass',
    preOrInClass?: IClassActivityUserDataResponse
  ) {
    if (!preOrInClass || !preOrInClass[type]) {
      return {
        numSeen: isTeamMember ? cls.activities[toBeDone][type].numTotal : 0,
        numCommentsSeen: 0,
        numCompleted: 0,
      };
    } else {
      const act = preOrInClass[type]!;
      if (type === 'quizzes' || type === 'polls') {
        const act = preOrInClass[type]!;
        return {
          numSeen: isTeamMember ? cls.activities[toBeDone][type].numTotal : act.numSeen || 0,
          numCommentsSeen: act.numCommentsSeen || 0,
          numCompleted: act.numCompleted || 0,
        };
      } else {
        return {
          numSeen: isTeamMember ? cls.activities[toBeDone][type].numTotal : act.numSeen || 0,
          numCommentsSeen: act.numCommentsSeen || 0,
          numCompleted: 0,
        };
      }
    }
  };
  console.log('1-summary-classud', responseClassUD.summaryAccessedOn);
  return {
    numCommentsSeen: responseClassUD.numCommentsSeen || 0,
    subscribed: responseClassUD.subscribed || 0,
    summaryAccessedOn: responseClassUD.summaryAccessedOn || undefined,
    inClass: {
      discussions: responseActivityData('discussions', 'inClass', responseClassUD.inClass),
      polls: responseActivityData('polls', 'inClass', responseClassUD.inClass),
      queries: inClassQueries,
      quizzes: responseActivityData('quizzes', 'inClass', responseClassUD.inClass),
      resources: responseActivityData('resources', 'inClass', responseClassUD.inClass),
    },
    preClass: {
      discussions: responseActivityData('discussions', 'preClass', responseClassUD.preClass),
      polls: responseActivityData('polls', 'preClass', responseClassUD.preClass),
      queries: preClassQueries,
      quizzes: responseActivityData('quizzes', 'preClass', responseClassUD.preClass),
      resources: responseActivityData('resources', 'preClass', responseClassUD.preClass),
    },
    reviewQueries: responseClassUD.reviewQueries
      ? {
          ...responseClassUD.reviewQueries,
          numCommentsSeen: responseClassUD.reviewQueries.numCommentsSeen || 0,
        }
      : {
          numCompleted: 0,
          numSeen: 0,
          numCommentsSeen: 0,
        },
    attendance: responseClassUD.attendance
      ? {
          status: responseClassUD.attendance.status,
          visibleStatus: responseClassUD.attendance.visibleStatus,
          isCheckedIn: responseClassUD.attendance.isCheckedIn,
          checkInTime: responseClassUD.attendance.checkInTime,
        }
      : undefined,
  };
}
