import { h, IComponent } from 'core';

import { api as urls } from 'acadly/api';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import { AWARD_ICON, AWARD_ICON_ACTIVE, PLACEHOLDER_URL } from 'acadly/common/images';
import { dispatch, getStore } from 'acadly/store';
import { getHTMLTagSelector, getImageSizeFromUrl } from 'acadly/utils';

import Avatar from '../common/Avatar';
import Hover from '../common/Hover';
import * as datetime from '../datetime';
import Viewer from '../rich-text/Viewer';
import { colors, mr, pad, style } from '../styles';
import { Actions } from './actions';
import { getCanRateComment, getCanShowAwardsInComment } from './functions';

export interface ICommentProps {
  comment: IComment;
  currentUser: string;
  courseRole: ICourseRole;
  context: ICommentsContext;
  subContext: ICommentsSubContext;
  contextId: string;
  hideActions?: boolean;
  onblur?: () => any;
  onClick?: () => any;
  onfocus?: () => any;
  onmouseOut?: (v?: boolean) => any;
  onLoad?(src: string): any;
  isImageVisible: boolean;
  onClickLikes?: () => any;
  maxImageWidth?: string;
  onClickImage?(src: string, size: number): any;
  onClickDelete: () => any;
  analyticsSubContext: any;
  hideUserInfo?: boolean;
  activeComment?: string;
}
export interface ICommentState {
  thumbImageUrl: string;
  isImageClicked: boolean;
  imageSize: number; // in bytes
}

const CONTEXT_MAP = {
  course: 'course',
  classes: 'class',
  assignments: 'assignment',
  quizzes: 'quiz',
  polls: 'poll',
  resources: 'resource',
  queries: 'query',
  discussions: 'discussion',
};

const AWARD_ICON_IMG = h('img.comment__award__action', {
  src: AWARD_ICON,
});
const AWARD_ICON_IMG_ACTIVE = h('img.comment__award__action', {
  src: AWARD_ICON_ACTIVE,
});

const COMMENT_MARKS_COUNTS_STYLE = style([
  'flex',
  'alignCenter',
  'blue',
  'borderBox',
  mr('auto'),
  pad('0em 1em 0em 0.5em'),
  {
    borderBottom: '1px solid transparent',
  },
]).style;
const COMMENT_MESSAGE_STYLE = style([{ overflow: 'wrap' }]).style;
const REMOVED_COMMENT_MESSAGE_STYLE = style(['lightGrey', 'italic']).style;
export class CommentC extends IComponent<ICommentProps, ICommentState> {
  public componentWillMount() {
    const comment = this.getProps().comment;
    const thumbImageUrl =
      comment.details.hasAttachment && comment.details.attachmentType === 'image'
        ? this.getThumbImageUrl(comment.details.imageUrl)
        : undefined;
    const url =
      comment.details.hasAttachment &&
      comment.details.attachmentType === 'image' &&
      comment.details.imageUrl;
    if (url) {
      const sizePromise = getImageSizeFromUrl(url);
      sizePromise.then(async (data) => {
        await this.setState({
          imageSize: data,
        });
      });
    }
    this.setState({
      thumbImageUrl: thumbImageUrl,
      isImageClicked: false,
    });
  }

  public componentWillReceiveProps(nextProps: ICommentProps) {
    if (this.getProps().comment._id !== nextProps.comment._id) {
      const comment = nextProps.comment;
      const thumbImageUrl =
        comment.details.hasAttachment && comment.details.attachmentType === 'image'
          ? this.getThumbImageUrl(comment.details.imageUrl)
          : undefined;
      this.setState({
        thumbImageUrl: thumbImageUrl,
      });
    }
  }

  public componentWillUnmount() {}

  private tag(text: string) {
    return h('div.comment__tag', [h('div.comment__tag__text', text)]);
  }

  private getThumbImageUrl(url: string) {
    const decodedUrl = decodeURIComponent(url);
    const urlStringInParts = decodedUrl.split('/i/');
    return `${urlStringInParts[0]}/i/comm/th/${urlStringInParts[1]}`;
  }

  private async markComment(
    mark: 'helpful' | 'unhelpful' | 'starred' | 'unstarred',
    appContext: IAppContext,
    event: Event
  ) {
    event.stopPropagation();
    const { comment, context, subContext, contextId, analyticsSubContext } = this.getProps();
    await dispatch(
      Actions.mark(
        {
          commentId: comment._id,
          contextId: contextId,
          context: context,
          subContext: subContext,
          marked: mark,
        },
        appContext,
        analyticsSubContext
      )
    );
  }

  public render() {
    const isMobile = getStore().getState().app.isMobile;
    const props = this.getProps();
    const { comment, currentUser, courseRole, onClick } = props;

    const commentAwardsPoints = this.getProps().comment.stats.awards.map((a) => a.points);
    const totalPoints = commentAwardsPoints && commentAwardsPoints.reduce((t, n) => t + n, 0);
    const isImageClicked = this.getState().isImageClicked;
    const thumbImageUrl = this.getState().thumbImageUrl;
    const appContext: IAppContext = CONTEXT_MAP[props.context] as any;

    const avatar: string | { props: any; state: any; key?: string | undefined } | null = Avatar(
      comment.details.createdBy.avatar,
      comment.details.createdBy.name,
      {
        className: 'comment__avatar',
      }
    );

    const createdBy: string | { props: any; state: any; key?: string | undefined } | null = h(
      'div.comment__creator',
      [
        h('div.comment__creator__name', comment.details.createdBy.name),
        comment.details.createdBy.role === 'admin'
          ? this.tag('Admin')
          : comment.details.createdBy.role === 'ta'
          ? this.tag('TA')
          : comment.details.createdBy.role === 'instructor'
          ? this.tag('Instructor')
          : null,
      ]
    );

    const roleAndDate: string | { props: any; state: any; key?: string | undefined } | null = h(
      'div.comment__date',
      datetime.fromNow(datetime.fromUnix(comment.details.createdOn))
    );

    const imageSize = this.getState().imageSize;
    const canRemoveComment =
      (courseRole === 'admin' ||
        courseRole === 'instructor' ||
        currentUser === comment.details.createdBy.userId) &&
      !comment.removed;

    const canShowAwardsInComment = getCanShowAwardsInComment({
      context: props.context,
      contextId: props.contextId,
      state: getStore().getState(),
    });

    const canRateComment = getCanRateComment({
      comment,
      courseRole,
      context: props.context,
      contextId: props.contextId,
      state: getStore().getState(),
    });

    const isCommentRatedByCurrentUser = comment.stats.awards.find(
      (a) => a.awardedBy.userId === currentUser
    );
    const doesCommentHaveImage =
      comment.details.hasAttachment &&
      comment.details.attachmentType === 'image' &&
      !comment.removed;
    return Hover(
      {
        key: comment._id,
      },
      h(
        getHTMLTagSelector('div', [
          'comment',
          props.activeComment === comment._id ? 'active' : '',
          props.hideUserInfo ? '' : 'has-user-info',
          comment.removed ? 'disabled' : '',
        ]),
        {
          onfocus: props.onfocus,
          tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
          onclick: !comment.removed && !isImageClicked ? onClick : undefined,
        },
        [
          !comment.removed && !isMobile && !props.hideActions
            ? h('.comment__actions', {}, [
                canRateComment
                  ? h('span.comment__award', [
                      isCommentRatedByCurrentUser ? AWARD_ICON_IMG_ACTIVE : AWARD_ICON_IMG,
                    ])
                  : null,

                ...(comment.userData.helpful
                  ? []
                  : [
                      // thumb up/starred
                      comment.userData.starred
                        ? h(
                            'span.unhelpful',
                            {
                              'aria-label': 'Unlike this comment',
                              tabIndex:
                                getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                              role: 'button',
                              onclick: (e) => this.markComment('unstarred', appContext, e),
                            },
                            []
                          )
                        : h(
                            'span.helpful',
                            {
                              'aria-label': 'Like this comment',
                              tabIndex:
                                getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                              role: 'button',
                              onclick: (e) => this.markComment('starred', appContext, e),
                            },
                            []
                          ),
                    ]),

                ...(comment.userData.starred
                  ? []
                  : [
                      // gift icon
                      comment.userData.helpful
                        ? h(
                            'span.unthank',
                            {
                              'aria-label': 'Unthank this comment author',
                              tabIndex:
                                getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                              role: 'button',
                              onclick: (e) => this.markComment('unhelpful', appContext, e),
                            },
                            []
                          )
                        : h(
                            'span.thank',
                            {
                              'aria-label': 'Thank this comment author',
                              tabIndex:
                                getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                              role: 'button',
                              onclick: (e) => this.markComment('helpful', appContext, e),
                            },
                            []
                          ),
                    ]),
                canRemoveComment
                  ? h(
                      'span.comment__delete-button',
                      {
                        onclick: (e) => {
                          e.stopPropagation();
                          props.onClickDelete();
                        },
                        tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                        role: 'button',
                        onblur: props.onblur,
                        'aria-label': 'Delete this comment button',
                      },
                      [h('i.fa.fa-trash.comment__delete-button__icon')]
                    )
                  : null,
              ])
            : null,
          props.hideUserInfo
            ? null
            : h(
                'div.comment__user-info',
                {
                  key: comment.details.createdBy.userId,
                },
                [avatar, h('div.comment__user-info__content', [createdBy, roleAndDate])]
              ),
          Viewer(comment.details.message, {
            style: !comment.removed ? COMMENT_MESSAGE_STYLE : REMOVED_COMMENT_MESSAGE_STYLE,
          }),
          !comment.removed ? Attachment(comment) : null,
          doesCommentHaveImage
            ? h('div.image-container', {}, [
                h('img.comment__image-thumb', {
                  src: thumbImageUrl,
                  onError: () => this.onErrorHandler(),
                  onclick: props.onClickImage
                    ? async () => {
                        if (
                          comment.details.hasAttachment &&
                          comment.details.attachmentType === 'image' &&
                          props.onClickImage
                        ) {
                          await this.setState({
                            isImageClicked: true,
                          });
                          await props.onClickImage(comment.details.imageUrl, imageSize);
                        }
                      }
                    : undefined,
                  style: {
                    maxWidth: props.maxImageWidth,
                  },
                  alt: `Comment Image added by ${comment.details.createdBy.name}`,
                }),
              ])
            : null,
          !comment.removed &&
          (comment.stats.numHelps > 0 ||
            comment.stats.numStars > 0 ||
            commentAwardsPoints.length > 0)
            ? Hover(
                {
                  borderBottom: `1px solid ${colors.darkBlue}`,
                },
                h(
                  'div.comment-marks-counts',
                  {
                    style: COMMENT_MARKS_COUNTS_STYLE,
                    tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                    onclick: (event: Event) => {
                      event.stopPropagation();
                      props.onClickLikes && props.onClickLikes();
                    },
                  },
                  [
                    // stat details
                    h('span.unhelpful', {}),
                    h('span', comment.stats.numStars.toString()),

                    h('span.unthank', {}),
                    h('span', comment.stats.numHelps.toString()),

                    ...(canShowAwardsInComment
                      ? [
                          h('span.approved', { style: {}, 'aria-label': 'Rating Icon' }),
                          h('span', totalPoints.toString()),
                        ]
                      : []),
                  ]
                )
              )
            : null,
        ]
      ),
      props.onmouseOut
    );
  }

  private async onErrorHandler() {
    const comment = this.getProps().comment;

    if (comment.details.hasAttachment && comment.details.attachmentType === 'image') {
      await this.setState({
        thumbImageUrl: PLACEHOLDER_URL,
      });
    }
  }
}

export class CommentAttachmentViewer extends IComponent<{ comment: IComment }, never> {
  public render() {
    const props = this.getProps();
    const color = props.comment.details.createdBy.role === 'student' ? colors.green : colors.orange;
    if (!(props.comment.details.hasAttachment && props.comment.details.attachmentType === 'file')) {
      return null;
    }
    const attachment: IAttachment = props.comment.details.attachments[0];
    return AttachmentViewer({
      attachment,
      color,
      downloadUrl: urls().downloadCommentAttachment,
      requestMethod: 'GET',
      downloadRequest: {
        commentId: props.comment._id,
        fileName: attachment.name,
      },
      style: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        width: '17em',
        border: `1px solid ${colors.lighterGrey}`,
      },
    });
  }
}

export default (props: ICommentProps) =>
  h(CommentC, {
    ...props,
    key: props.comment._id,
  });

export const Attachment = (comment: IComment) => {
  if (comment.details.hasAttachment && comment.details.attachmentType === 'file') {
    const props: { comment: IComment } = {
      comment: comment,
    };
    return h(CommentAttachmentViewer, props);
  } else {
    return null;
  }
};
