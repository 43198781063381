import { h, IComponent, View } from 'core';
import { Route, Switch } from 'core/router';

import * as ZoomUsIcon from 'assets/zoomus-icon-square.svg';

import { Actions as AppActions, IAccUpdateRequest, ITipsUpdateRequest } from 'acadly/app/actions';
import {
  authorizeZoomInstall,
  INotificationPreference,
  INotificationPreferenceName,
  INotificationPreferencesFetchResponse,
  newFeatures,
  notificationPreferencesFetch,
  notificationPreferencesSave,
} from 'acadly/app/api';
import NewFeaturesDialog, { FeatureUpdate } from 'acadly/app/NewFeaturesDialog';
import Alert from 'acadly/common/Alert';
import Avatar from 'acadly/common/Avatar';
import ContentView from 'acadly/common/ContentView';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import _Icon from 'acadly/common/Icon';
import Icon from 'acadly/common/Icon';
import ImageCropper from 'acadly/common/ImageCropper';
import { Loader } from 'acadly/common/Loader';
import Paper from 'acadly/common/Paper';
import SvgIcon from 'acadly/common/SvgIcon';
import TextField from 'acadly/common/TextField';
import Toggle from 'acadly/common/Toggle';
import UploadButton from 'acadly/common/UploadButton';
import { Actions as CourseActions } from 'acadly/course/actions';
import { adminCoursesFetch, IAdminCoursesFetchResponse } from 'acadly/course/api';
import { Actions as getinActions } from 'acadly/getin/actions';
import * as getinApi from 'acadly/getin/api';
import icons from 'acadly/icons';
import { pusherService } from 'acadly/pusher';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { colors, mb, ml, mr, mt, pad, pb, pt, style } from 'acadly/styles';
import * as u from 'acadly/utils';
import { getPasswordError } from 'acadly/utils/password';

import FeedbackDialog from './FeedbackDialog';

export interface ISettingsItem {
  label: string;
  icon: View;
  view?: View;
  color?: string;
  onclick: ((e: Event) => any) | string;
  hideOnMobile?: boolean;
}

interface ISettingScreenState {
  isFeedbackDialogOpen: boolean;
  isAccessibilityTurnedOff: boolean;
  isTipsOn: boolean;
  passwordDialog: null | {
    old: string;
    oldIsVisible: boolean;
    new: string;
    newIsVisible: boolean;
    repeat: string;
  };
  isPasswordChangedConfirmationOpen: boolean;
  avatarDialog: null | {
    uploadedFile: File | null;
    uploadedDataURI: string;
    croppedAvatar: string; // data URI
    selectedAvatar: number | null;
    skinToneSelected: number;
    maxSizeError?: string;
  };
  isNameChangedConfirmationOpen: boolean;
  nameDialog: null | {
    firstName: string;
    middleName: string;
    lastName: string;
    firstNameError: string | null;
    lastNameError: string | null;
    isMiddleNameEmpty: boolean;
    timesRemaining: number;
  };
  notificationDialog: null | {
    form: null | INotificationPreferencesFetchResponse['notifPref'];
  };
  deleteCoursesDialog: null | {
    courses: null | IAdminCoursesFetchResponse['courseData'];
  };
  isDeleteCourseConfirmationDialogOpenFor: null | string;
  isFeatureUpdatesDialogOpen: boolean;
  allFeatureUpdates: FeatureUpdate[];
  isFetchingFeatureUpdates: boolean;
}

export class SettingsScreen extends IComponent<never, ISettingScreenState> {
  public componentWillMount() {
    const reduxState = getStore().getState();
    const isTurnedOff = reduxState.app.updatedTipObject.turnOff;
    const isAccessibilityTurnedOff = reduxState.app.acc.web.turnOff === 1;

    const initialState: ISettingScreenState = {
      isFeedbackDialogOpen: false,
      passwordDialog: null,
      avatarDialog: null,
      isPasswordChangedConfirmationOpen: false,
      notificationDialog: null,
      deleteCoursesDialog: null,
      isDeleteCourseConfirmationDialogOpenFor: null,
      isAccessibilityTurnedOff: isAccessibilityTurnedOff,
      isTipsOn: isTurnedOff === 1,
      isFeatureUpdatesDialogOpen: false,
      allFeatureUpdates: [],
      isFetchingFeatureUpdates: false,
      isNameChangedConfirmationOpen: false,
      nameDialog: null,
    };

    this.setState(initialState);
  }

  private lastFocusedElement: Element | null = null;

  public render() {
    return ContentView(
      h(
        'div.settings',
        style([
          'flex',
          'alignCenter',
          'column',
          {
            paddingBottom: '2em',
          },
        ]),
        [
          Switch(
            [
              Route(Routes.zoomInstallSettings, () =>
                ZoomInstallDialog({
                  show: true,
                  onClose: () => {
                    if (this.lastFocusedElement) {
                      u.resetTabIndices();
                      (this.lastFocusedElement as any).focus();
                    }
                    Routes.settings.navigate({});
                  },
                })
              ),
            ],
            {
              defaultCallback: () => {
                if (!Routes.settings.isActive(true)) {
                  Routes.settings.navigate({});
                }
              },
            }
          ),
          h('div', style(['xx-large', mt('0.5em')]), 'Settings'),
          this.userDetails(),
          ...this.sections().map((section) => {
            return h(
              'div.settings-section',
              style(
                ['fullWidth'],
                {},
                {
                  key: section.title,
                  'aria-label': section.title,
                }
              ),
              [
                h(
                  'div',
                  style(['lightGrey', pad('0.5rem 1rem'), 'fullWidth', 'borderBox']),
                  section.title
                ),
                ...section.items.map((s) => this.renderItem(s)),
              ]
            );
          }),
          this.feedbackDialog(),
          this.changeNameDialog(),
          this.changeNameConfirmationAlert(),
          this.passwordDialog(),
          this.passwordChangedDialog(),
          this.avatarDialog(),
          this.notificationDialog(),
          this.deleteCoursesDialog(),
        ]
      )
    );
    // ...section.items.map(s => )
  }

  private renderItem(item: ISettingsItem) {
    const attrs = style(
      [
        mb('1px'),
        'flex',
        'alignCenter',
        'fullWidth',
        pad('0.5rem 1rem'),
        'borderBox',
        'pointer',
        {
          color: item.color || 'black',
          height: '4em',
          backgroundColor: 'white',
        },
      ],
      {},
      {
        key: item.label,
        tabIndex: 0,
        onclick: typeof item.onclick !== 'string' ? item.onclick : undefined,
      }
    );
    const wrapper =
      typeof item.onclick === 'string'
        ? h.bind(this, 'a.setting-item.ripple', {
            ...attrs,
            href: item.onclick,
            target: '_blank',
            style: {
              textDecoration: 'none',
              ...attrs.style,
              backgroundColor: 'white',
            },
          })
        : h.bind(this, 'div.setting-item.ripple', attrs);
    if (item.hideOnMobile && getStore().getState().app.isMobile) {
      return null;
    }
    return wrapper([
      h(
        'div.setting-icon',
        style([
          'flex',
          'alignCenter',
          {
            width: '4em',
            height: '100%',
            color: item.color || colors.grey,
          },
        ]),
        [item.icon]
      ),
      h('span', item.label),
      item.view,
    ]);
  }

  private async deleteCourse(courseId: string) {
    await dispatch(CourseActions.delete(courseId));
    const { deleteCoursesDialog } = this.getState();
    await this.setState({
      isDeleteCourseConfirmationDialogOpenFor: null,
      deleteCoursesDialog:
        deleteCoursesDialog && deleteCoursesDialog.courses
          ? {
              courses: deleteCoursesDialog.courses.filter((c) => c._id !== courseId),
            }
          : null,
    });
  }

  private deleteCoursesDialog() {
    const { deleteCoursesDialog, isDeleteCourseConfirmationDialogOpenFor } = this.getState();
    return Dialog(
      {
        title: 'Delete courses',
        open: !!deleteCoursesDialog,
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-arrow-left', []),
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElement as any).focus();
            this.setState({
              deleteCoursesDialog: null,
            });
          },
        },
        thinHeader: true,
        style: {
          width: '35em',
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0.5rem',
        },
      },
      deleteCoursesDialog && deleteCoursesDialog.courses !== null
        ? [
            h(
              'div',
              style(
                ['lightGrey', pad('0.5rem 1rem'), mr('auto')],
                {},
                {
                  tabIndex: 0,
                }
              ),
              ['Courses where you are an admin']
            ),
            deleteCoursesDialog.courses.length === 0
              ? h(
                  'div',
                  style(
                    ['large', 'fullWidth', 'borderBox', pad('1rem'), mt('2em'), 'lightGrey'],
                    {},
                    {
                      tabIndex: 0,
                    }
                  ),
                  [
                    'You have not created any active courses yet. ' +
                      'Once you create a course, it will show up here.',
                  ]
                )
              : null,
            ...deleteCoursesDialog.courses.map((course, index) =>
              Paper(
                `.settings-screen__paper${index === 0 ? '.first' : ''}`,
                {
                  tabIndex: 0,
                },
                [
                  h('div', style(['large', 'flex', 'spaceBetween', pad('0.5rem 1rem')]), [
                    h('span', course.details.code),
                    h(
                      'span',
                      style(['lightGrey', 'small']),
                      `Total ${course.totalActivities} ${
                        course.totalActivities === 1 ? 'activity' : 'activities'
                      }`
                    ),
                  ]),
                  h('div', style([pad('0.5rem 1rem')]), course.details.title),
                  h(
                    'div',
                    style([
                      mt('0.5em'),
                      'flex',
                      'alignCenter',
                      pad('0.5rem 1rem'),
                      'white',
                      'alignCenter',
                      {
                        backgroundColor: colors.red,
                      },
                    ]),
                    isDeleteCourseConfirmationDialogOpenFor !== course._id
                      ? [
                          FlatButton('DELETE', {
                            onclick: () =>
                              this.setState({
                                isDeleteCourseConfirmationDialogOpenFor: course._id,
                              }),
                            style: style([ml('auto'), 'white']).style,
                          }),
                        ]
                      : [
                          h('span', 'A deleted course cannot be recovered. Are you sure?'),
                          FlatButton('NO', {
                            style: {
                              marginLeft: 'auto',
                              color: 'white',
                            },
                            onclick: () =>
                              this.setState({
                                isDeleteCourseConfirmationDialogOpenFor: null,
                              }),
                          }),
                          FlatButton('YES', {
                            style: style(['white']).style,
                            onclick: () => this.deleteCourse(course._id),
                          }),
                        ]
                  ),
                ]
              )
            ),
          ]
        : [h('div', style(['flex', 'fullWidth', pad('1em'), 'justifyCenter']), [Loader()])]
    );
  }

  private async saveNotificationPreferences() {
    const { notificationDialog } = this.getState();
    if (!notificationDialog) return;
    if (!notificationDialog.form) return;
    await notificationPreferencesSave(notificationDialog.form);
    await this.setState({
      notificationDialog: null,
    });
  }

  private notificationDialog() {
    const { notificationDialog } = this.getState();
    return Dialog(
      {
        open: !!notificationDialog,
        title: 'Notifications',
        thinHeader: true,
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.saveNotificationPreferences(),
        },
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-arrow-left', []),
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElement as any).focus();
            this.setState({
              notificationDialog: null,
            });
          },
        },
        bodyStyle: {
          backgroundColor: colors.backgroundColor,
          padding: '0em',
        },
      },
      notificationDialog !== null
        ? notificationDialog.form !== null
          ? [
              h('div', style(['lightGrey', pad('1rem')]), ['Set Notification Preferences']),
              ...u
                .objectKeys(notificationDialog.form)
                .map((k) => ({
                  key: k,
                  label: {
                    newActivity: 'New class activity published',
                    newAnnouncement: 'New announcement made',
                    newAssignment: 'New assignment published',
                    newQuery: 'New query raised',
                    queryResponse: 'New response to your query',
                    scheduleUpdate: 'Course schedule updated',
                  }[k],
                  preference: notificationDialog.form![k],
                }))
                .map((pref) => this.renderNotificationSettingRow(pref)),
            ]
          : [h('div', style(['flex', 'alignCenter', 'justifyCenter', pad('1em')]), [Loader()])]
        : []
    );
  }

  private renderNotificationSettingRow(pref: {
    key: INotificationPreferenceName;
    label: string;
    preference: INotificationPreference;
  }) {
    const { notificationDialog } = this.getState();
    if (!notificationDialog) {
      return null;
    }
    if (!notificationDialog.form) return null;
    return h(
      'div',
      style(
        [
          mb('1px'),
          pad('0.5em 1em'),
          'borderBox',
          'fullWidth',
          'flex',
          'spaceBetween',
          'alignCenter',
          {
            backgroundColor: 'white',
          },
        ],
        {},
        {
          tabIndex: 0,
        }
      ),
      [
        h('span', pref.label),
        h('span', [
          _Icon(
            icons.mobile,
            style(
              [
                mr('1em'),
                'pointer',
                'xx-large',
                {
                  color: pref.preference.cloud ? colors.blue : colors.lighterGrey,
                },
              ],
              {},
              {
                ariaLabel: `${pref.label} notification on Mobile
                            ${pref.preference.cloud ? `is ON` : `is OFF`}`,
                tabIndex: 0,
                onclick: () =>
                  this.setState({
                    notificationDialog: {
                      ...notificationDialog,
                      form: {
                        ...notificationDialog.form!,
                        [pref.key]: {
                          ...notificationDialog.form![pref.key],
                          cloud: notificationDialog.form![pref.key].cloud ? 0 : 1,
                        },
                      },
                    },
                  }),
              }
            )
          ),
          _Icon(
            icons.email,
            style(
              [
                'pointer',
                'xx-large',
                {
                  color: pref.preference.mail ? colors.blue : colors.lighterGrey,
                },
              ],
              {},
              {
                tabIndex: 0,
                ariaLabel: `${pref.label} notification over Email
                            ${pref.preference.mail ? `is ON` : `is OFF`}`,
                onclick: () =>
                  this.setState({
                    notificationDialog: {
                      ...notificationDialog,
                      form: {
                        ...notificationDialog.form!,
                        [pref.key]: {
                          ...notificationDialog.form![pref.key],
                          mail: notificationDialog.form![pref.key].mail ? 0 : 1,
                        },
                      },
                    },
                  }),
              }
            )
          ),
        ]),
      ]
    );
  }

  private feedbackDialog() {
    return FeedbackDialog({
      open: this.getState().isFeedbackDialogOpen,
      close: () => {
        u.resetTabIndices();
        (this.lastFocusedElement as any).focus();
        this.setState({
          isFeedbackDialogOpen: false,
        });
      },
    });
  }

  private async changeNameRequest(newName: string) {
    await getinApi.changeName(newName);
  }

  private changeNameConfirmationAlert() {
    const nameDialog = this.getState().nameDialog;
    if (!nameDialog) return null;
    const oldName = getStore().getState().getIn.session!.name;
    const newName = [nameDialog.firstName, nameDialog.middleName, nameDialog.lastName]
      .filter(Boolean)
      .map((n) => n.trim())
      .join(' ');
    return Alert(
      {
        open: this.getState().isNameChangedConfirmationOpen,
        style: {
          width: '25em',
        },
        actions: [
          FlatButton('yes', {
            onclick: async () => {
              await this.changeNameRequest(newName).then(() => dispatch(getinActions.logout(true)));
            },
          }),
          FlatButton('cancel', {
            onclick: () => {
              u.resetTabIndices(document.getElementById('dialog-box'));
              this.setState({
                isNameChangedConfirmationOpen: false,
              });
            },
          }),
        ],
      },
      [
        h(
          'div',
          {
            style: {
              whiteSpace: 'pre-line',
            },
          },
          `Are you sure you want to change your name` +
            ` from ${oldName} to ${newName}? \n` +
            `\nYou will be logged out of Acadly from all your devices. The` +
            ` change would reflect upon logging back in.`
        ),
      ]
    );
  }

  private async changeNameSaveClickHandler() {
    const nameError = this.nameError();
    if (nameError !== null) {
      console.log('here');
      return null;
    } else {
      console.log('there');
      u.unsetTabIndices();
      await this.setState({
        isNameChangedConfirmationOpen: true,
      });
      return null;
    }
  }

  private changeNameDialog() {
    const nameDialog = this.getState().nameDialog;
    const name = getStore().getState().getIn.session!.name;
    if (!nameDialog) return null;
    const timesRemaining =
      nameDialog.timesRemaining > 1
        ? `${nameDialog.timesRemaining} times`
        : `${nameDialog.timesRemaining} time`;
    return Dialog(
      {
        open: nameDialog !== null,
        title: 'Changing Name',
        thinHeader: true,
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          // disabled: this.nameError(),
          // onclick:  async () => await this.changeNameSaveClickHandler()
          onclick: () => this.changeNameSaveClickHandler(),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              nameDialog: null,
            }).then(() => {
              u.resetTabIndices();
              (this.lastFocusedElement as any).focus();
            }),
        },
        bodyStyle: {
          padding: '0.5rem',
        },
        style: {
          width: '40em',
        },
      },
      [
        h('div', style(['flex', 'spaceBetween']), [
          h('span', style([pb('0.5rem')]), 'Current Name:'),
          h('span', style(['small', 'lightGrey', pb('0.5rem')]), name),
        ]),
        h('div', style([mt('1em')]), 'Please enter your full name.'),
        TextField({
          value: nameDialog.firstName,
          oninput: (e) =>
            this.setState({
              nameDialog: {
                ...nameDialog,
                firstName: e.target.value,
                firstNameError: null,
              },
            }),
          errorText: nameDialog.firstNameError,
          center: true,
          placeholder: 'First name',
          floatingLabelText: 'First name',
          onenter: () => this.changeNameSaveClickHandler(),
          style: { marginTop: '0.5em' },
        }),
        TextField({
          value: nameDialog.middleName,
          oninput: (e) =>
            this.setState({
              nameDialog: {
                ...nameDialog,
                middleName: e.target.value,
                isMiddleNameEmpty: false,
              },
            }),
          center: true,
          placeholder: 'Middle name (optional)',
          floatingLabelText: 'Middle name (optional)',
          style: style([mt('1em')]).style,
          onenter: () => this.changeNameSaveClickHandler(),
        }),
        TextField({
          value: nameDialog.lastName,
          oninput: (e) =>
            this.setState({
              nameDialog: {
                ...nameDialog,
                lastName: e.target.value,
                lastNameError: null,
              },
            }),
          errorText: nameDialog.lastNameError,
          center: true,
          placeholder: 'Last name',
          floatingLabelText: 'Last name',
          style: style([mt('1em')]).style,
          onenter: () => this.changeNameSaveClickHandler(),
        }),
        h('div.attempts', style(['lightGrey', pad('0.5rem 0')]), [
          `You can change your name ${timesRemaining}`,
        ]),
      ]
    );
  }

  private async savePassword() {
    const { passwordDialog } = this.getState();
    if (!passwordDialog) return;
    const session = getStore().getState().getIn.session;
    if (!session) return;
    await getinApi.changePassword(passwordDialog.old, passwordDialog.new);
    await this.setState({
      passwordDialog: null,
      isPasswordChangedConfirmationOpen: true,
    });
  }

  private passwordError() {
    const state = this.getState();
    if (!state.passwordDialog) return null;

    const passwordError =
      getPasswordError(state.passwordDialog.new) || getPasswordError(state.passwordDialog.old);
    if (passwordError !== null) {
      return passwordError;
    }
    if (state.passwordDialog.new !== state.passwordDialog.repeat) {
      return "Passwords don't match";
    }
    return null;
  }

  private nameError() {
    const state = this.getState();
    const nameDialog = state.nameDialog;
    if (!nameDialog) return null;

    if (nameDialog.firstName.length < 1) {
      this.setState({
        nameDialog: {
          ...nameDialog,
          firstNameError: 'Please enter first name',
        },
      });
      return '';
    } else if (nameDialog.middleName.length < 1) {
      this.setState({
        nameDialog: {
          ...nameDialog,
          isMiddleNameEmpty: true,
        },
      });
      return null;
    }

    return null;
  }

  private passwordDialog() {
    const { passwordDialog } = this.getState();
    return Dialog(
      {
        open: passwordDialog !== null,
        title: 'Changing password',
        thinHeader: true,
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          disabled:
            this.passwordError() !== null || (!!passwordDialog && passwordDialog.old.length < 1),
          onclick: () => this.savePassword(),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              passwordDialog: null,
            }).then(() => {
              u.resetTabIndices();
              (this.lastFocusedElement as any).focus();
            }),
        },
        bodyStyle: {
          padding: '0.5rem',
        },
        style: {
          width: '40em',
        },
      },
      passwordDialog
        ? [
            h(
              'div',
              style([
                'flex',
                'alignCenter',
                {
                  flexWrap: 'wrap',
                },
              ]),
              [
                h('div.oldpassword-container', style(['flex', 'fullWidth']), [
                  TextField({
                    value: passwordDialog.old,
                    type: passwordDialog.oldIsVisible ? undefined : 'password',
                    placeholder: 'Your current password',
                    floatingLabelText: 'Your current password',
                    oninput: (event) =>
                      this.setState({
                        passwordDialog: {
                          ...passwordDialog,
                          old: event.target.value,
                        },
                      }),
                    style: {
                      flex: '1.5',
                    },
                    onenter:
                      this.passwordError() !== null ||
                      (!!passwordDialog && passwordDialog.old.length < 1)
                        ? undefined
                        : () => this.savePassword(),
                  }),
                  h(
                    'div.icon-visiblity',
                    {
                      style: {
                        fontSize: '1.5rem',
                        marginTop: 'auto',
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        color: colors.blue,
                      },
                      onclick: () => this.toggleOldIsVisible(),
                    },
                    [Icon(passwordDialog.oldIsVisible ? icons.eyeShut : icons.eyeOpen)]
                  ),
                ]),
              ]
            ),
            h('div.newpassword-container', style(['flex', 'fullWidth', mt('1em')]), [
              TextField({
                value: passwordDialog.new,
                type: passwordDialog.newIsVisible ? undefined : 'password',
                placeholder: 'New password',
                floatingLabelText: 'New password',
                oninput: (event) =>
                  this.setState({
                    passwordDialog: {
                      ...passwordDialog,
                      new: event.target.value,
                    },
                  }),
                style: {
                  flex: '1.5',
                },
                onenter:
                  this.passwordError() !== null ||
                  (!!passwordDialog && passwordDialog.old.length < 1)
                    ? undefined
                    : () => this.savePassword(),
              }),
              h(
                'div.icon-visiblity',
                {
                  style: {
                    fontSize: '1.5rem',
                    marginTop: 'auto',
                    marginLeft: 'auto',
                    cursor: 'pointer',
                    color: colors.blue,
                  },
                  onclick: () => this.toggleNewIsVisible(),
                },
                [Icon(passwordDialog.newIsVisible ? icons.eyeShut : icons.eyeOpen)]
              ),
            ]),
            h('div.repeatpassword-container', style(['flex', 'fullWidth', mt('1em')]), [
              TextField({
                value: passwordDialog.repeat,
                type: passwordDialog.newIsVisible ? undefined : 'password',
                floatingLabelText: 'Confirm new password',
                placeholder: 'Confirm new password',
                oninput: (event) =>
                  this.setState({
                    passwordDialog: {
                      ...passwordDialog,
                      repeat: event.target.value,
                    },
                  }),
                style: {
                  flex: '1.5',
                },
                onenter:
                  this.passwordError() !== null ||
                  (!!passwordDialog && passwordDialog.old.length < 1)
                    ? undefined
                    : () => this.savePassword(),
              }),
              h(
                'div.icon-visiblity',
                {
                  style: {
                    fontSize: '1.5rem',
                    marginTop: 'auto',
                    marginLeft: 'auto',
                    cursor: 'pointer',
                    color: colors.blue,
                  },
                  onclick: () => this.toggleNewIsVisible(),
                },
                [Icon(passwordDialog.newIsVisible ? icons.eyeShut : icons.eyeOpen)]
              ),
            ]),
            // h("div", style(["flex", "alignCenter", mt("1em")]), [
            //     TextField({
            //         value: passwordDialog.repeat,
            //         type: "password",
            //         floatingLabelText: "Confirm new password",
            //         placeholder: "Confirm new password",
            //         oninput: (event) => this.setState({
            //             passwordDialog: {
            //                 ...passwordDialog,
            //                 repeat: event.target.value
            //             }
            //         }),
            //         style: {
            //             flex: "1.5"
            //         },
            //         onenter: () => this.savePassword()
            //     })
            // ]),
            h('div', style([mt('1em'), 'textCenter', 'red']), [
              passwordDialog.new.length > 0 ? this.passwordError() : null,
            ]),
          ]
        : []
    );
  }

  private passwordChangedDialog() {
    return Alert(
      {
        open: this.getState().isPasswordChangedConfirmationOpen,
        actions: [
          FlatButton('OK', {
            onclick: () => {
              dispatch(getinActions.logout(true));
            },
          }),
        ],
      },
      ['Your password has been changed. Kindly log in again to continue.']
    );
  }

  private async toggleOldIsVisible() {
    const passwordDialog = this.getState().passwordDialog;
    if (passwordDialog !== null) {
      const isVisible = passwordDialog.oldIsVisible;
      await this.setState({
        passwordDialog: {
          ...passwordDialog,
          oldIsVisible: !isVisible,
        },
      });
    }
  }

  private async toggleNewIsVisible() {
    const passwordDialog = this.getState().passwordDialog;
    if (passwordDialog !== null) {
      const isVisible = passwordDialog.newIsVisible;
      await this.setState({
        passwordDialog: {
          ...passwordDialog,
          newIsVisible: !isVisible,
        },
      });
    }
  }

  private sections(): { title: string; items: ISettingsItem[] }[] {
    const session = getStore().getState().getIn.session;
    if (!session) return [];
    const Icon = (i: string, ariaLabel: string) =>
      _Icon(i, {
        style: {
          fontSize: '2em',
        },
        ariaLabel: ariaLabel,
      });
    return [
      {
        title: 'Account Settings',
        items: [
          {
            label: 'Change password',
            icon: Icon(icons.key, 'Change Password'),
            onclick: () => this.openPasswordDialog(),
          },
          {
            label: 'Change name',
            icon: Icon(icons.name, 'Change Name'),
            onclick: () => this.openChangeNameDialog(),
          },
          {
            label: 'Change avatar',
            icon: Avatar(session.avatar, 'user', {
              className: 'settings-screen__avatar',
            }),
            onclick: () => {
              this.lastFocusedElement = document.activeElement;
              u.unsetTabIndices();
              this.setState({
                avatarDialog: {
                  selectedAvatar: null,
                  uploadedFile: null,
                  uploadedDataURI: '',
                  croppedAvatar: '',
                  skinToneSelected: 0,
                },
              });
            },
          },
          {
            label: 'Manage Notifications',
            icon: Icon(icons.bellLight, 'Manage Notifications'),
            onclick: () => this.openNotificationDialog(),
          },
          {
            label: 'Feature updates',
            icon: h('i.fa.fa-bullhorn', style([{ fontSize: '2em' }])),
            onclick: () => this.showFeatureUpdatesDialog(),
            view: h('div', style([{ marginLeft: 'auto' }]), [
              this.getState().isFetchingFeatureUpdates ? Loader() : null,
              NewFeaturesDialog({
                features: this.getState().allFeatureUpdates,
                title: 'All feature updates',
                open: this.getState().isFeatureUpdatesDialogOpen,
                onClose: () => {
                  this.setState({
                    isFeatureUpdatesDialogOpen: false,
                  });
                },
              }),
            ]),
          },
          {
            label: 'Help Tips',
            icon: Icon(icons.help, 'Help Tips'),
            onclick: () => {},
            view: h(
              'div',
              {
                style: {
                  marginLeft: 'auto',
                },
              },
              [
                Toggle({
                  isAccessible: true,
                  selected: !this.getState().isTipsOn,
                  ontoggle: () => this.onToggleHandler(),
                }),
              ]
            ),
          },
          {
            label: 'Accessibility Features',
            icon: Icon(icons.help, 'Accessibility Features'),
            hideOnMobile: true,
            onclick: () => {},
            view: h(
              'div',
              {
                style: {
                  marginLeft: 'auto',
                },
              },
              [
                Toggle({
                  isAccessible: true,
                  selected: !this.getState().isAccessibilityTurnedOff,
                  ontoggle: () => this.accessibilityToggleHandler(),
                }),
              ]
            ),
          },
        ],
      },
      {
        title: 'Integrations',
        items: [
          {
            label: 'Zoom Integration',
            icon: SvgIcon({
              noFill: true,
              icon: ZoomUsIcon,
              className: 'settings-screen__zoomus-icon',
            }),
            onclick: () => {
              this.lastFocusedElement = document.activeElement;
              u.unsetTabIndices();
              Routes.zoomInstallSettings.navigate({});
            },
          },
        ],
      },
      {
        title: 'Course Settings',
        items: [
          {
            label: 'Remove courses',
            icon: h(
              'i.fa.fa-trash',
              style([
                {
                  fontSize: '2em',
                  marginLeft: '0.2rem',
                },
              ])
            ),
            color: colors.red,
            onclick: () => this.openDeleteCoursesDialog(),
          },
        ],
      },
      {
        title: 'Legal',
        items: [
          {
            label: 'Terms of use',
            icon: Icon(icons.file, 'Terms of use'),
            onclick: 'https://www.acadly.com/terms',
          },
          {
            label: 'Privacy policy',
            icon: Icon(icons.file, 'Privacy Policy'),
            onclick: 'https://www.acadly.com/privacy',
          },
        ],
      },
      {
        title: 'Contact',
        items: [
          {
            label: 'Feedback',
            icon: Icon(icons.feedback, 'Feedback'),
            onclick: () => {
              this.lastFocusedElement = document.activeElement;
              u.unsetTabIndices();
              this.setState({
                isFeedbackDialogOpen: true,
              });
            },
          },
          {
            label: 'Share the goodness',
            icon: Icon(icons.share, 'Referral'),
            color: colors.green,
            onclick: () => Routes.refer.navigate({}),
          },
        ],
      },
    ];
  }

  private async showFeatureUpdatesDialog() {
    this.setState({ isFetchingFeatureUpdates: true });
    const response = await newFeatures();

    if (!response.data.allFeatures) {
      this.setState({ isFetchingFeatureUpdates: false });
      return;
    }

    const featureMap = response.data.featureList;
    const allFeatureUpdates = Object.keys(featureMap)
      .map((key) => ({ ...featureMap[key], key }))
      .sort((f) => -f.releasedOn);

    this.setState({
      allFeatureUpdates,
      isFetchingFeatureUpdates: false,
      isFeatureUpdatesDialogOpen: true,
    });
  }

  private async onToggleHandler() {
    const tipObject = getStore().getState().app.updatedTipObject;
    const updatedTipObject: ITipsUpdateRequest = {
      ...tipObject,
      turnOff: tipObject.turnOff === 1 ? (0 as const) : (1 as const),
      agent: 'web' as const,
    };
    await this.setState({
      isTipsOn: !this.getState().isTipsOn,
    });
    await dispatch(AppActions.updateTip(updatedTipObject));
  }

  private async accessibilityToggleHandler() {
    await this.setState({
      isAccessibilityTurnedOff: !this.getState().isAccessibilityTurnedOff,
    });
    const updatedAccObject: IAccUpdateRequest = {
      agent: 'web',
      turnOff: getStore().getState().app.acc.web.turnOff === 0 ? 1 : 0,
    };
    await dispatch(AppActions.updateAccessibility(updatedAccObject));
  }

  private async openDeleteCoursesDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      deleteCoursesDialog: {
        courses: null,
      },
    });
    const response = await adminCoursesFetch();
    await this.setState({
      deleteCoursesDialog: {
        courses: response.data.courseData,
      },
    });
  }

  private async openNotificationDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      notificationDialog: {
        form: null,
      },
    });
    const response = await notificationPreferencesFetch();
    await this.setState({
      notificationDialog: {
        form: response.data.notifPref,
      },
    });
  }

  private async openChangeNameDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    const response = await getinApi.changeNameStatus();
    await this.setState({
      nameDialog: {
        firstName: '',
        middleName: '',
        lastName: '',
        firstNameError: null,
        lastNameError: null,
        isMiddleNameEmpty: true,
        timesRemaining: response.data.timesRemaining,
      },
    });
  }

  private async openPasswordDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      passwordDialog: {
        old: '',
        oldIsVisible: false,
        new: '',
        newIsVisible: false,
        repeat: '',
      },
    });
  }

  private skinToneSelected() {
    const state = this.getState();
    if (state.avatarDialog) {
      return state.avatarDialog.skinToneSelected;
    } else {
      return 0;
    }
  }

  private getDefaultAvatars() {
    const skinTone = ['', 'p-', 't-', 'd-'][this.skinToneSelected() || 0] || '';
    return [
      `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-01.png`,
      `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-02.png`,
      `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-03.png`,
      `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-04.png`,
      `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-05.png`,
      `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-06.png`,
      `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-07.png`,
      `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-08.png`,
      'https://s3.amazonaws.com/acadly-test/i/public/default.png',
      'https://s3.amazonaws.com/acadly-test/i/public/default-09.png',
      'https://s3.amazonaws.com/acadly-test/i/public/default-10.png',
      'https://s3.amazonaws.com/acadly-test/i/public/default-11.png',
    ];
  }

  private getSkinToneUrls() {
    return [
      'https://s3.amazonaws.com/acadly-test/i/public/tone.png',
      'https://s3.amazonaws.com/acadly-test/i/public/tone-p.png',
      'https://s3.amazonaws.com/acadly-test/i/public/tone-t.png',
      'https://s3.amazonaws.com/acadly-test/i/public/tone-d.png',
    ];
  }

  private userDetails() {
    const s = getStore().getState().getIn.session;
    if (!s) return null;
    return h(
      'div',
      style(
        [
          'flex',
          mb('1em'),
          mt('1em'),
          pad('0.5rem 1rem'),
          'fullWidth',
          'borderBox',
          { backgroundColor: 'white', borderRadius: '0.3em' },
        ],
        {},
        {
          tabIndex: 0,
          key: s.userId,
        }
      ),
      [
        Avatar(s.avatar, s.name, {
          className: 'settings-screen__avatar--md',
        }),
        h('div', style(['flex', 'column', 'spaceBetween']), [
          h('span', style([pt('0.2rem'), pb('0.5rem')]), s.name),
          h('span', style(['small', 'grey', pb('0.5rem')]), s.email),
        ]),
      ]
    );
  }

  private avatarDialog() {
    const { avatarDialog } = this.getState();
    const session = getStore().getState().getIn.session;
    if (!session) return null;

    return Dialog(
      {
        open: avatarDialog !== null,
        title: 'Set avatar',
        style: {
          width: '35em',
        },
        primaryAction: {
          label: 'SAVE',
          disabled:
            !avatarDialog ||
            (avatarDialog.selectedAvatar === null && avatarDialog.uploadedFile === null),
          onclick: () => this.saveAvatar(),
        },
        secondaryAction: {
          label: 'CANCEL',
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElement as any).focus();
            this.setState({
              avatarDialog: null,
            });
          },
        },
        bodyStyle: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
      avatarDialog
        ? [
            h(
              'div',
              style(
                ['bold'],
                {},
                {
                  tabIndex: 0,
                }
              ),
              'Your current avatar'
            ),
            avatarDialog.selectedAvatar === null && avatarDialog.uploadedFile === null
              ? Avatar(session.avatar, 'user', {
                  className: 'settings-screen__avatar--lg',
                })
              : !avatarDialog.uploadedFile
              ? h('img', {
                  src: this.getDefaultAvatars()[avatarDialog.selectedAvatar!],
                  style: {
                    width: '4em',
                    height: '4em',
                    borderRadius: '0.3em',
                  },
                })
              : h('div', style(['textCenter']), [
                  h('img', {
                    src: avatarDialog.croppedAvatar,
                    style: {
                      width: '4em',
                      height: '4em',
                      borderRadius: '0.3em',
                      marginTop: '0.5em',
                      marginBottom: '0.5em',
                    },
                  }),
                  avatarDialog.uploadedDataURI
                    ? ImageCropper({
                        url: avatarDialog.uploadedDataURI,
                        onupdate: (dataURI) => {
                          this.setState({
                            avatarDialog: {
                              ...avatarDialog,
                              croppedAvatar: dataURI,
                            },
                          });
                        },
                      })
                    : null,
                ]),
            avatarDialog.maxSizeError
              ? h(
                  'div',
                  {
                    style: {
                      color: colors.red,
                      marginTop: '1em',
                    },
                  },
                  avatarDialog.maxSizeError
                )
              : null,
            UploadButton({
              view: h('div', style(['blue', mt('1em'), 'pointer']), ['CHOOSE FROM SYSTEM']),
              ariaLabel: 'Choose from system button',
              accept: 'image/png,image/jpg,image/jpeg',
              upload: (file) => {
                return {
                  promise: u.fileToDataURI(file).then((dataURI) => {
                    return this.setState({
                      avatarDialog: {
                        ...avatarDialog,
                        uploadedFile: file,
                        uploadedDataURI: dataURI,
                        croppedAvatar: dataURI,
                      },
                    });
                  }),
                };
              },
            }),
            h('div', style([mt('1em')]), 'or'),
            h(
              'div',
              style(
                [mt('1em'), 'blue'],
                {},
                {
                  tabIndex: 0,
                  'aria-label': 'Choose from default avatars',
                  role: 'button',
                }
              ),
              ['Choose from default avatars']
            ),
            h('div', style(['flex', mt('1em'), 'alignCenter']), [
              h('span', 'Select skin tone'),
              ...this.getSkinToneUrls().map((url, index) =>
                h('img', {
                  src: url,
                  tabIndex: 0,
                  'aria-label': 'Select Skintone',
                  style: {
                    width: '2.5em',
                    cursor: 'pointer',
                    height: '2.5em',
                    marginLeft: '1em',
                    boxSizing: 'border-box',
                    padding: '0.2em',
                    border: `1px dotted ${
                      index === avatarDialog.skinToneSelected ? colors.darkBlue : 'transparent'
                    }`,
                  },
                  onclick: () =>
                    this.setState({
                      avatarDialog: {
                        ...avatarDialog,
                        skinToneSelected: index,
                      },
                    }),
                })
              ),
            ]),
            h(
              'div.default-avatars',
              style([
                'flex',
                'alignCenter',
                'justifyCenter',
                pad('0em 1em'),
                {
                  flexWrap: 'wrap',
                },
              ]),
              [
                ...this.getDefaultAvatars().map((url, index) =>
                  h(
                    'div',
                    style([
                      pad('1em'),
                      {
                        width: '4.5em',
                        height: '4.5em',
                      },
                    ]),
                    [
                      h('img', {
                        src: url,
                        tabIndex: 0,
                        style: {
                          width: '100%',
                          height: '100%',
                          borderRadius: '0.3em',
                        },
                        onclick: () =>
                          this.setState({
                            avatarDialog: {
                              ...avatarDialog,
                              uploadedFile: null,
                              selectedAvatar: index,
                            },
                          }),
                      }),
                    ]
                  )
                ),
              ]
            ),
          ]
        : []
    );
  }

  private async saveAvatar() {
    const { avatarDialog } = this.getState();
    if (!avatarDialog) return;
    if (avatarDialog.uploadedFile === null && avatarDialog.selectedAvatar !== null) {
      await getinApi.setAvatar({
        imageUploaded: false,
        fileName: this.getDefaultAvatars()[avatarDialog.selectedAvatar].replace(
          'https://s3.amazonaws.com/acadly-test/i/public/',
          ''
        ),
        fileType: 'png',
      });
      await this.setState({
        avatarDialog: null,
      });
    } else if (avatarDialog.uploadedFile !== null) {
      const file = u.dataURIToFile(avatarDialog.uploadedFile.name, avatarDialog.croppedAvatar);
      if (file.size < 3 * 1024 * 1024) {
        await getinApi.setAvatar({
          imageUploaded: true,
          file: file,
          fileName: file.name,
          fileType: u.splitFileName(file.name).extension,
        });
        await this.setState({
          avatarDialog: null,
        });
      } else {
        await this.setState({
          avatarDialog: {
            ...avatarDialog,
            selectedAvatar: null,
            uploadedFile: null,
            maxSizeError: 'The image size is too large',
          },
        });
      }
    }

    // reload avatar
    dispatch(
      getinActions.fetchAvatars([getStore().getState().getIn.session!.avatar], undefined, {
        update: true,
      })
    );
  }
}
export default () => h(SettingsScreen);

interface ZoomInstallDialogProps {
  show: boolean;
  onClose: () => any;
}

interface ZoomInstallDialogState {
  isLoading: boolean;
  isAllowed: 0 | 1;
  notAllowedMessage: string; // non zero length only if isAllowed === 0
  isAuthorized: 0 | 1;
  authorizationUrl: string; // non zero length only if isAllowed === 1 && isAuthorized === 0
  revokeUrl: string;
  hasProCourses: 0 | 1;
}

class ZoomInstallDialogC extends IComponent<ZoomInstallDialogProps, ZoomInstallDialogState> {
  private handleSubmit() {
    let tab: Window | null = null;
    const { isAllowed, isAuthorized, authorizationUrl, revokeUrl } = this.getState();

    if (!isAllowed) return;

    if (isAuthorized) {
      window.open(revokeUrl, '_blank', 'noopner noreferrer');
      return;
    }

    const subscription = pusherService.events.subscribe((e) => {
      const userId = getStore().getState().getIn.session!.userId;
      if (e.event === 'zoomAuthenticated' && userId === e.payload.userId) {
        subscription.unsubscribe();
        this.setState({ isAuthorized: 1 });
        if (tab) {
          tab.close();
        }
      }
    });

    tab = window.open(authorizationUrl, '_blank');
  }

  public async componentWillMount() {
    const initialState: ZoomInstallDialogState = {
      isLoading: true,
      isAllowed: 0,
      notAllowedMessage: '',
      isAuthorized: 0,
      authorizationUrl: '',
      revokeUrl: '',
      hasProCourses: 0,
    };
    this.setState(initialState);

    const response = await authorizeZoomInstall();
    const {
      isAllowed,
      notAllowedMessage,
      isAuthorized,
      authorizationUrl,
      revokeUrl,
      hasProCourses,
    } = response.data;

    this.setState({
      isLoading: false,
      isAllowed,
      notAllowedMessage,
      isAuthorized,
      authorizationUrl,
      revokeUrl,
      hasProCourses,
    });
  }

  private listItem(text: string | string[]) {
    return h('div.zoom-settings__list-item', [h('div.bullet', '-'), h('div.text', text)]);
  }

  private learnMoreAboutZoomIntegration() {
    return [
      h('p', [
        "Acadly's Plugin for Zoom is an Acadly Pro feature that enables ",
        'seamless online, hybrid, and HyFlex instruction using just the ',
        'Acadly interface and your existing Zoom account',
      ]),
      h('p.fw-bold', [
        'To integrate Zoom with Acadly you must create a Free Trial Course ',
        'to take Acadly Pro for a test run. Click on the button below to learn more',
      ]),
    ];
  }

  private needInstructorAccount() {
    return [
      h('p', [
        "Acadly's Plugin for Zoom is an Acadly Pro feature that is available for ",
        'verified instructors only',
      ]),
      h('p.fw-bold', [
        'To be a verified Acadly instructor, please send a request to ',
        h('a', { href: 'mailto:support@acadly.com', target: '_blank' }, 'support@acadly.com.'),
        ' We will get back to you within 24 hours.',
      ]),
    ];
  }

  private renderAddToZoom() {
    return [
      h('img', {
        className: 'zoom-settings__logo',
        src: 'https://s3.amazonaws.com/static.acad.ly/img/zoom-icon.png',
      }),
      h('div.zoom-settings__header.zoom-settings__header--title', 'Authorize Acadly to use Zoom'),
      h('div.zoom-settings__header', 'Once added, Acadly will be able to'),
      this.listItem('Create and manage Zoom meetings for your classes'),
      this.listItem('Manage student participation in the created Zoom meetings'),
      h('div.zoom-settings__header', 'Privacy Policy'),
      this.listItem([
        'Acadly uses and stores your Zoom account details to create ',
        'meetings on your behalf AND capture user events for these ',
        'meetings to maintain the integrity of the meeting',
      ]),
      this.listItem('Acadly ONLY captures events for the meetings that are created using Acadly'),
      this.listItem([
        'When Acadly is uninstalled from your Zoom account, all your Zoom ',
        'account details are deleted irrevocably from Acadly’s servers',
      ]),
      h('img', {
        className: 'zoom-settings__add-button',
        src: 'https://s3.amazonaws.com/static.acad.ly/img/add_to_zoom.png',
        onclick: () => this.handleSubmit(),
      }),
    ];
  }

  private renderUninstallFromZoom() {
    return [
      h('img', {
        className: 'zoom-settings__logo',
        src: 'https://s3.amazonaws.com/static.acad.ly/img/zoom-icon.png',
      }),
      h('div.zoom-settings__header.zoom-settings__header--title', 'Uninstall Acadly from Zoom'),
      h('p', [
        'You can deauthorize Acadly by navigating to the Zoom Marketplace ',
        'and uninstalling manually. Once deauthorized, Acadly will remove ',
        'all the Zoom data associated with your user account',
      ]),
      FlatButton('UNINSTALL AT ZOOM MARKETPLACE', {
        type: 'primary',
        onclick: () => this.handleSubmit(),
      }),
    ];
  }

  public render() {
    const { show, onClose } = this.getProps();
    const { isLoading, isAllowed, isAuthorized, hasProCourses } = this.getState();

    return Dialog(
      {
        title: 'Zoom Integration',
        open: show,
        secondaryAction: {
          label: 'CLOSE',
          mobileLabel: h('i.fa.fa-arrow-left', []),
          onclick: onClose,
        },
        thinHeader: true,
        style: {
          width: '35em',
          backgroundColor: colors.backgroundColor,
          padding: '0',
        },
        bodyStyle: {
          padding: '0',
        },
      },
      [
        h(
          'div.zoom-settings',
          isLoading
            ? h('div', style(['flex', 'alignCenter', 'justifyCenter', pad('1em')]), [Loader()])
            : isAuthorized
            ? this.renderUninstallFromZoom()
            : hasProCourses
            ? this.renderAddToZoom()
            : isAllowed
            ? this.learnMoreAboutZoomIntegration()
            : this.needInstructorAccount()
        ),
      ]
    );
  }
}

const ZoomInstallDialog = (props: ZoomInstallDialogProps) => h(ZoomInstallDialogC, props);
