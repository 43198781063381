import { h } from 'core';

import { getHTMLTagSelector } from 'acadly/utils';

interface IToggleProps {
  scale?: number;
  className?: string;
  disabled?: boolean;
  selected: boolean;
  ontoggle?: (value: boolean) => void;
  ariaLabel?: string;
  isAccessible?: boolean;
}
export default ({
  scale = 1,
  className,
  disabled,
  selected,
  ontoggle,
  ariaLabel,
  isAccessible,
}: IToggleProps) => {
  const width = `${scale * 2.5}rem`;
  const height = `${scale * 1.5}rem`;

  return h(
    getHTMLTagSelector('div', ['toggle', selected ? 'selected' : '', disabled ? 'disabled' : '']),
    {
      className,
      style: {
        width: width,
        height: height,
      },
    },
    [
      h(
        'div.toggle__slider',
        {
          style: {
            borderRadius: `${scale * 3}rem`,
          },
          tabIndex: isAccessible ? 0 : undefined,
          role: 'switch',
          ariaLabel: ariaLabel ? ariaLabel : undefined,
          'aria-checked': selected ? 'true' : 'false',
          onclick: !disabled && ontoggle ? () => ontoggle(!selected) : undefined,
        },
        [
          h('div.toggle__button', {
            style: {
              width: height,
              height: height,
              left: selected ? `${scale * 1}rem` : '0rem',
            },
          }),
        ]
      ),
    ]
  );
};
