import { clone } from 'lodash';

import { VCallStatus } from 'core/enums';

import { IAppState } from 'acadly/app/IAppState';
import { getCourseRole } from 'acadly/course/functions';
import {
  IUpdateActivityAssignmentPayload,
  IUpdateActivityBasePayload,
  IUpdateActivityDiscussionPayload,
  IUpdateActivityPollPayload,
  IUpdateActivityQuizPayload,
  IUpdateActivityResourcePayload,
  // IUpdateActivityPayload
} from 'acadly/course/ICourseActionMap';
import { createReducer } from 'acadly/createReducer';
import { responseDiscussionToDiscussion } from 'acadly/discussion/functions';
import { IRootState } from 'acadly/IRootState';
import { responsePollToPoll } from 'acadly/poll/functions';
import { responseQueryToQuery } from 'acadly/query/functions';
import { responseQuizToQuiz } from 'acadly/quiz/functions';
import { responseResourceToResource } from 'acadly/resource/functions';
import * as utils from 'acadly/utils';

import * as actions from './actions';
import * as api from './api';
import {
  Class,
  isInCharge,
  updateClassInRootState,
  updateTotalClassActivitiesCount,
} from './functions';
import { IClassState, IZoomRecordingState } from './IClassState';

export const reducer = createReducer({
  '@class/FETCH_ACTIVITIES_SUCCESS': fetchClassActivitiesSuccess,
  '@class/CLOSE_CLASS_SCREEN': closeClassScreen,
  '@class/MOVE_ACTIVITY_SUCCESS': moveClassActivitySuccess,
  '@class/INCREMENT_SEEN_ACTIVITIES': incrementClassSeenActivities,
  '@class/CHECK_IN_SUCCESS': classCheckInSuccess,
  '@class/ADD_WEEKLY_CLASS_SUCCESS': addWeeklyClassSuccess,
  '@class/REMOVE_WEEKLY_CLASS_SUCCESS': removeWeeklyClassSuccess,
  '@class/DELETE_SUCCESS': deleteSuccess,
  '@class/SET_INCHARGE_SUCCESS': setClassIncharge,
  '@class/START': startClass,
  '@class/CANCEL_SUCCESS': cancelSuccess,
  '@class/TITLE_EDIT_SUCCESS': titleEditSuccess,
  '@class/VENUE_EDIT_SUCCESS': venueEditSuccess,
  '@class/AGENDA_EDIT_SUCCESS': agendaEditSuccess,
  // "@class/ASSISTANTS_EDIT_SUCCESS":
  //     assistantsEditSuccess,
  '@class/TOPICS_EDIT_SUCCESS': topicsEditSuccess,
  '@class/ATTENDANCE_FETCH_SUCCESS': attendanceFetchSuccess,
  '@class/ATTENDANCE_CLEAR': attendanceClear,
  '@class/ATTENDANCE_SET_SUCCESS': attendanceSetSuccess,
  '@class/ATTENDANCE_STARTED': attendanceStarted,
  '@class/ATTENDANCE_STOPPED': attendanceStopped,
  '@class/ATTENDANCE_DIALOG_DISMISS': attendanceStopped,
  '@class/attendance/ATTENDEE_AVAILABLE': attendeeAvailable,
  '@class/attendance/ATTENDEE_FAILURE': attendeeFailure,
  '@class/attendance/ATTENDANCE_MARKED': attendanceMarked,
  '@class/attendance/SET_FAILURES_LAST_SYNCED_AT': setFailuresLastSyncedAt,
  '@class/attendance/SET_RESPONDERS': setAttendanceResponders,
  '@class/pusher/INCHARGE_SET': pusherInChargeSet,
  '@class/ATTENDANCE_FETCH_MINE_SUCCESS': attendanceFetchMineSuccess,
  '@class/pusher/CHECK_IN': pusherCheckIn,
  '@class/pusher/ACTIVITY_UPDATED': pusherActivityUpdated,
  '@class/GET_CLASS_PARTICIPATION_STATS': participationGetSuccess,
  '@class/SET_CLASS_PARTICIPATION_SCORES': participationSetSuccess,
  '@class/SET_SUMMARY_ACCESSED_ON': setSummaryAccessedOn,
  '@class/SET_SORT_STUDENT_ORDER': setSortStudentOrder,
  '@class/UPDATE_CLASS_TEAM': updateClassTeam,
  '@class/FETCH_ALL_SUGGESTED_ACTIVITIES_SUCCESS': updateAllSuggestedActivities,
  '@class/USE_SUGGESTED_ACTIVITY_SUCCESS': useSuggestedActivity,
  '@class/HIDE_SUGGESTED_ACTIVITY_SUCCESS': hideSuggestedActivity,
  '@class/ONLINE_MEETING_STARTED': onlineMeetingStarted,
  '@class/ONLINE_MEETING_ENDED': onlineMeetingEnded,
  '@class/GET_ONLINE_DETAILS_SUCCESS': updateOnlineDetails,
  '@class/UPDATE_AUTO_CLOUD_RECORD': udpateAutoCloudRecord,
  '@class/ONLINE_MEETING_BROADCASTING': onlineMeetingBroadcasting,
  '@class/ONLINE_MEETING_BROADCAST_ENDED': onlineMeetingBroadcastEnded,
  '@class/ONLINE_MEETING_DESTROYED': onlineMeetingDestroyed,
  '@class/GET_ZOOM_RECORDINGS_SUCCESS': getZoomRecordingsSuccess,
  '@class/PUBLISH_ZOOM_RECORDINGS_SUCCESS': publishZoomRecordingsSuccess,
  '@class/DELETE_ZOOM_RECORDINGS_SUCCESS': deleteZoomRecordingsSuccess,
  '@pusher/ZOOM_RECORDINGS_PUBLISHED': zoomRecordingsPublished,
  '@class/UPDATE_ACTIVITY_PUBLISH_PREFS': updateActivityPublishPrefs,
  '@class/SCHEDULE_ATTENDANCE_SUCCESS': scheduleAttendanceSuccess,
  '@class/EDIT_SCHEDULE_ATTENDANCE_SUCCESS': editScheduleAttendanceSuccess,
});

function scheduleAttendanceSuccess(
  state: IRootState,
  payload: actions.IScheduleAttendanceSuccessPayload
): IRootState {
  const { classId, autoSchedule } = payload;

  if (state.class.data?.classId !== classId) return state;

  return updateClassInRootState(state, payload.classId, (cls: IClass) =>
    utils.update(cls, { autoSchedule })
  );
}

function editScheduleAttendanceSuccess(
  state: IRootState,
  payload: actions.IEditScheduleAttendanceSuccessPayload
): IRootState {
  const { classId, ...response } = payload;

  if (state.class.data?.classId !== classId) return state;

  return updateClassInRootState(state, payload.classId, (cls: IClass) =>
    utils.update(cls, {
      autoSchedule: response.isScheduled ? response : { isScheduled: 0 },
    })
  );
}

function updateActivityPublishPrefs(
  state: IRootState,
  payload: api.IActivityPublishPrefs
): IRootState {
  if (payload.activityType === 'quiz') {
    return utils.update(state, {
      quizzes: {
        byId: {
          [payload.activityId]: {
            details: {
              scoring: payload.quizPref.scoring,
            },
          },
        },
        publishDefaults: {
          ...payload.quizPref,
          saved: 1,
        },
      },
    });
  }

  if (payload.activityType === 'poll') {
    return utils.update(state, {
      polls: {
        byId: {
          [payload.activityId]: {
            details: {
              isAnon: payload.pollPref.isAnon,
            },
          },
        },
        publishDefaults: {
          ...payload.pollPref,
          saved: 1,
        },
      },
    });
  }

  if (payload.activityType === 'discussion') {
    return utils.update(state, {
      discussions: {
        byId: {
          [payload.activityId]: {
            details: {
              anonymity: payload.discussionPref.anonymity,
              anonymize: payload.discussionPref.anonymize,
              hideAwards: payload.discussionPref.hideAwards,
            },
          },
        },
        publishDefaults: {
          ...payload.discussionPref,
          saved: 1,
        },
      },
    });
  }

  return state;
}

function zoomRecordingsPublished(
  state: IRootState,
  payload: actions.IPusherZoomRecordingPublishedPayload
): IRootState {
  const classData = state.class.data;

  if (!classData || classData.classId !== payload.classId) {
    return state;
  }

  const recordings: IZoomRecordingState = {
    isNew: true,
    files: {},
    published: payload.published,
    recordingsAvailable: payload.recordingsAvailable,
    status: payload.status,
  };

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        recordings,
      },
    },
  };
}

function getZoomRecordingsSuccess(
  state: IRootState,
  payload: actions.IGetZoomRecordingsResult
): IRootState {
  const classData = state.class.data;

  if (!classData || !classData.recordings || classData.classId !== payload.classId) {
    return state;
  }

  const files = utils.makeObjectWithKey(payload.recordings, 'recordingId');

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        recordings: {
          ...classData.recordings,
          isNew: false,
          files,
        },
      },
    },
  };
}

function publishZoomRecordingsSuccess(
  state: IRootState,
  payload: actions.IPublishZoomRecordings
): IRootState {
  const classData = state.class.data;

  if (!classData || !classData.recordings || classData.classId !== payload.classId) {
    return state;
  }

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        recordings: {
          ...classData.recordings,
          published: 1,
        },
      },
    },
  };
}

function deleteZoomRecordingsSuccess(
  state: IRootState,
  payload: api.IDeleteZoomRecordingPayload
): IRootState {
  const classData = state.class.data;

  if (!classData || !classData.recordings || classData.classId !== payload.classId) {
    return state;
  }

  // remove whole recording object
  const { [payload.recordingId]: recording, ...files } = classData.recordings.files || {};

  if (!recording) return state;

  recording.recordingFiles = recording.recordingFiles.filter(
    (f) => !payload.fileNamesToDelete.includes(f.name)
  );

  if (!recording.recordingFiles.length && !Object.keys(files).length) {
    // no recordings left
    return {
      ...state,
      class: {
        ...state.class,
        data: {
          ...classData,
          recordings: undefined,
        },
      },
    };
  }

  if (recording.recordingFiles.length) {
    // add back to files if recordingFiles contains any file
    files[recording.recordingId] = recording;
  }

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        recordings: {
          ...classData.recordings,
          files,
        },
      },
    },
  };
}

function onlineMeetingBroadcasting(
  state: IRootState,
  payload: actions.IPusherOnlineMeetingStartedPayload
): IRootState {
  const classData = state.class.data;

  if (!classData || !classData.onlineDetails) return state;
  if (classData.classId !== payload.classId) return state;

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        onlineDetails: {
          ...classData.onlineDetails,
          meetingInProgress: VCallStatus.IN_PROGESS,
          beingBroadcast: 1,
        },
      },
    },
  };
}

function onlineMeetingBroadcastEnded(
  state: IRootState,
  payload: actions.IPusherOnlineMeetingEndedPayload
): IRootState {
  const classData = state.class.data;

  if (!classData || !classData.onlineDetails) return state;
  if (classData.classId !== payload.classId) return state;

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        onlineDetails: {
          ...classData.onlineDetails,
          meetingInProgress: VCallStatus.ENDED,
          beingBroadcast: 0,
        },
      },
    },
  };
}

function onlineMeetingDestroyed(
  state: IRootState,
  payload: actions.IPusherOnlineMeetingEndedPayload
): IRootState {
  const classData = state.class.data;

  if (!classData || !classData.onlineDetails) return state;
  if (classData.classId !== payload.classId) return state;

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        onlineDetails: {
          ...classData.onlineDetails,
          meetingId: 0,
          beingBroadcast: 0,
          meetingInProgress: VCallStatus.ENDED,
        },
      },
    },
  };
}

function onlineMeetingStarted(
  state: IRootState,
  payload: actions.IPusherOnlineMeetingStartedPayload
): IRootState {
  const classData = state.class.data;

  if (!classData || !classData.onlineDetails) return state;
  if (classData.classId !== payload.classId) return state;
  if (isInCharge(state, payload.classId)) return state;

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        onlineDetails: {
          ...classData.onlineDetails,
          meetingInProgress: VCallStatus.IN_PROGESS,
        },
      },
    },
  };
}

function onlineMeetingEnded(
  state: IRootState,
  payload: actions.IPusherOnlineMeetingEndedPayload
): IRootState {
  const classData = state.class.data;

  if (!classData || !classData.onlineDetails) return state;
  if (classData.classId !== payload.classId) return state;
  if (isInCharge(state, payload.classId)) return state;

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        onlineDetails: {
          ...classData.onlineDetails,
          meetingId: 0,
          meetingInProgress: VCallStatus.ENDED,
        },
      },
    },
  };
}

function udpateAutoCloudRecord(
  state: IRootState,
  payload: Partial<api.IFetchClassActivitiesResponse['autoRecordDefaults']>
): IRootState {
  const classData = state.class.data;

  if (!payload || !classData || !classData.autoRecordDefaults) return state;

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        autoRecordDefaults: {
          ...classData.autoRecordDefaults,
          ...payload,
        },
      },
    },
  };
}

function updateOnlineDetails(
  state: IRootState,
  payload: api.IGetOnlineDetailsResponse
): IRootState {
  const classData = state.class.data;
  if (!classData) return state;

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        onlineDetails: {
          authUrl: payload.authUrl,
          isUserAuthenticated: payload.isUserAuthenticated,
          meetingId: payload.meetingId,
          meetingInProgress: payload.meetingInProgress,
          meetingPassword: payload.meetingPassword,
          meetingType: payload.meetingType,
          joinId: payload.joinId,
          beingBroadcast: payload.beingBroadcast,
        },
      },
    },
  };
}

export function updateAllSuggestedActivities(
  state: IRootState,
  payload: ISuggestedActivity[]
): IRootState {
  return {
    ...state,
    class: {
      ...state.class,
      allSuggestedActivities: payload,
    },
  };
}

export function useSuggestedActivity(
  state: IRootState,
  payload: api.IUseSuggestedActivityRequest
): IRootState {
  const courseId = state.courses.currentCourseId;

  if (payload.activityType === 'assignments') {
    return state;
  }

  const updatedCourseSlice =
    courseId && state.courses.timeline
      ? updateTotalClassActivitiesCount(state.courses, {
          activityType: payload.activityType,
          classId: payload.copyToClass,
          toBeDone: payload.toBeDone,
          key: 'numTotal',
          num: 1,
        })
      : state.courses;

  return {
    ...state,
    courses: updatedCourseSlice,
    class: {
      ...state.class,
      allSuggestedActivities: state.class.allSuggestedActivities.map((a) => {
        if (a._id === payload.activityId) {
          return {
            ...a,
            hidden: 0 as const,
            used: 1 as const,
          };
        }
        return a;
      }),
    },
  };
}

export function hideSuggestedActivity(
  state: IRootState,
  payload: api.IHideSuggestedActivityRequest
): IRootState {
  const classData = state.class.data;

  if (!classData) return state;

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...classData,
        suggestedActivities: classData.suggestedActivities.filter(
          (a) => a._id !== payload.activityId
        ),
      },
      allSuggestedActivities: state.class.allSuggestedActivities.map((a) => {
        if (a._id === payload.activityId) {
          return {
            ...a,
            hidden: 1 as const,
          };
        }
        return a;
      }),
    },
  };
}

export function updateClassTeam(
  state: IRootState,
  payload: actions.IUpdateClassTeamPayload
): IRootState {
  return updateClassInRootState(state, payload.classId, (c) => ({
    ...c,
    info: {
      ...c.info,
      team: payload.team,
    },
  }));
}

export function setSortStudentOrder(state: IRootState, payload: StudentSortBy): IRootState {
  return {
    ...state,
    class: {
      ...state.class,
      sortStudentBy: payload,
    },
  };
}

export function setSummaryAccessedOn(
  state: IRootState,
  payload: actions.ISetSummaryAccessedOnPayload
): IRootState {
  return updateClassInRootState(state, payload.classId, (c) => ({
    ...c,
    activities: {
      ...c.activities,
    },
    userData: {
      ...c.userData,
      summaryAccessedOn: payload.time,
    },
  }));
}

function attendeeAvailable(
  state: IRootState,
  payload: actions.IPusherAttendeeAvailablePayload
): IRootState {
  const attendanceData = state.app.attendanceData;
  if (!attendanceData) return state;
  if (attendanceData.classId !== payload.classId) return;
  if (attendanceData.attendanceTime !== payload.attendanceTime) return state;
  return utils.update(state, {
    app: {
      attendanceData: {
        numAvailable: Math.max(attendanceData.numAvailable + 1, payload.count),
      },
    },
  });
}

function attendeeFailure(
  state: IRootState,
  payload: actions.IPusherAttendeeFailurePayload
): IRootState {
  const attendanceData = state.app.attendanceData;
  if (!attendanceData) return state;
  if (attendanceData.classId !== payload.classId) return;
  if (attendanceData.attendanceTime !== payload.attendanceTime) return state;

  const { userId, name, avatar, role, failureCode } = payload.failedAttendee;

  return utils.update(state, {
    app: {
      attendanceData: {
        failures: [
          {
            type: 'attendee_failure',
            failedOn: payload.timestamp,
            failureCode,
            attendee: { userId, name, avatar, role },
          },
          ...attendanceData.failures,
        ],
      },
    },
  });
}

function attendanceMarked(
  state: IRootState,
  payload: actions.IPusherAttendanceMarkedPayload
): IRootState {
  const attendanceData = state.app.attendanceData;
  if (!attendanceData) return state;
  if (attendanceData.classId !== payload.classId) return;
  if (attendanceData.attendanceTime !== payload.attendanceTime) return state;
  return utils.update(state, {
    app: {
      attendanceData: {
        numPresent: Math.max(attendanceData.numPresent + 1, payload.count),
        numMarkedPresentNow: attendanceData.numMarkedPresentNow + 1,
        failures: attendanceData.failures.filter((item) => {
          if (item.type === 'unresponsive_attendees_failure') return true;
          return item.attendee.userId !== payload.attendee;
        }),
      },
    },
  });
}

function setFailuresLastSyncedAt(
  state: IRootState,
  payload: actions.ISetFailuresLastSyncedAtPayload
): IRootState {
  const attendanceData = state.app.attendanceData;
  if (!attendanceData) return state;
  if (attendanceData.classId !== payload.classId) return;

  return utils.update(state, {
    app: {
      attendanceData: {
        failuresLastSyncedAt: payload.timestamp,
      },
    },
  });
}

function setAttendanceResponders(
  state: IRootState,
  payload: actions.ISetAttendanceResponders
): IRootState {
  const attendanceData = state.app.attendanceData;
  if (!attendanceData) return state;
  if (attendanceData.classId !== payload.classId) return;

  return utils.update(state, {
    app: {
      attendanceData: {
        failuresLastSyncedAt: payload.timestamp,
        failures:
          payload.numUnresponsive > 0
            ? [
                {
                  type: 'unresponsive_attendees_failure',
                  failedOn: payload.timestamp,
                  unresponsiveAttendees: payload.numUnresponsive,
                },
                ...attendanceData.failures,
              ]
            : attendanceData.failures,
      },
    },
  });
}

export function attendanceStopped(
  state: IRootState,
  payload: actions.IAttendanceIdentifiers
): IRootState {
  const updatedAppSlice: IAppState = {
    ...state.app,
    attendanceData: undefined,
  };

  let updatedClassSlice: IClassState = state.class;

  if (state.class.data?.classId === payload.classId) {
    updatedClassSlice = {
      ...state.class,
      data: {
        ...state.class.data,
        attendance: undefined,
      },
    };
  }

  return {
    ...state,
    app: updatedAppSlice,
    class: updatedClassSlice,
  };
}

export function attendanceStarted(
  state: IRootState,
  payload: actions.IAttendanceStartedPayload
): IRootState {
  const classData = state.class.data;

  const updatedAppSlice: IAppState = {
    ...state.app,
    attendanceData: {
      attendanceTime: payload.attendanceTime,
      scheStartTime: payload.scheStartTime,
      classId: payload.classId,
      courseId: state.courses.currentCourseId!,
      isProxy: payload.isProxy,
      numAvailable: payload.numAvailable,
      numEnrolled: payload.numEnrolled,
      numPresent: payload.numPresent,
      numMarkedPresentNow: 0, // reset to zero
      failures: [], // reset to empty list
      failuresLastSyncedAt: -1, // reset to -1, meaning not synced yet
      taker: payload.taker,
    },
  };

  let updatedClassSlice: IClassState = state.class;

  if (classData?.classId === payload.classId) {
    updatedClassSlice = {
      ...state.class,
      attendance: {
        checkedIn: [],
        classId: payload.classId,
        remoteAuto: 0,
        remoteAutoInstances: 0,
        ...state.class.attendance,
        inPersonAuto: 1,
      },
      data: {
        ...classData,
        attendance: {
          isProxy: payload.isProxy,
          attendanceTime: payload.attendanceTime,
          inProgress: 1,
          coarse: 0,
          fine: 0,
          taker: payload.taker,
        },
      },
    };
  }

  const updatedState = {
    ...state,
    app: updatedAppSlice,
    class: updatedClassSlice,
  };

  if (!payload.classAutoStarted) updatedState;

  return updateClassInRootState(updatedState, payload.classId, (cls) =>
    utils.update(cls, {
      details: {
        status: 'inSession',
        actStartTime: payload.classStartedAt,
      },
    })
  );
}

export function participationGetSuccess(
  state: IRootState,
  payload: actions.IGetClassParticipationPayload
): IRootState {
  return {
    ...state,
    class: {
      ...state.class,
      participationData: payload,
    },
  };
}

export function participationSetSuccess(
  state: IRootState,
  payload: actions.ISetClassParticipationPayload
): IRootState {
  const students = payload.request.studentIds;
  const studentData: IParticipationStudentData[] = [];
  const participationData = state.class.participationData;

  if (!participationData) return state;

  for (const student of participationData) {
    if (students.find((id) => id === student.userId)) {
      const tempObject: typeof student = {
        ...student,
        participation: [
          ...student.participation,
          {
            points: payload.request.points,
            label: payload.request.label,
            awardedBy: {
              ...payload.instructor,
            },
          },
        ],
      };
      studentData.push(tempObject);
    } else {
      studentData.push(student);
    }
  }

  let timeline = state.courses.timeline;
  let allParticipationLabels = state.courses.allParticipationLabels;

  if (payload.addNewLabel && timeline) {
    // add new label to class participationLabels and allParticipationLabels
    const classData = state.class.data;

    allParticipationLabels = Array.from(
      new Set([...allParticipationLabels, payload.request.label])
    );

    if (classData) {
      timeline = {
        ...timeline,
        items: timeline.items.map((item) => {
          return item._id === classData.classId && item.nodeType === 'class'
            ? {
                ...item,
                participationLabels: [...item.participationLabels, payload.request.label],
              }
            : item;
        }),
      };
    }
  }

  return {
    ...state,
    courses: {
      ...state.courses,
      timeline: timeline,
      allParticipationLabels,
    },
    class: {
      ...state.class,
      participationData: studentData,
    },
  };
}

export function attendanceSetSuccess(
  state: IRootState,
  payload: actions.IAttendanceSetSuccessPayload
): IRootState {
  const attendance = state.class.attendance;
  if (attendance && attendance.classId === payload.classId) {
    const attendanceByStudentId: ObjectMap<IAttendanceTabStudent['finalStatus']> = {};

    payload.present.forEach((studentId) => {
      attendanceByStudentId[studentId] = 'present';
    });

    payload.absent.forEach((studentId) => {
      attendanceByStudentId[studentId] = 'absent';
    });

    payload.late.forEach((studentId) => {
      attendanceByStudentId[studentId] = 'late';
    });

    payload.excused.forEach((studentId) => {
      attendanceByStudentId[studentId] = 'excused';
    });

    return {
      ...state,
      class: {
        ...state.class,
        attendance: {
          ...attendance,
          checkedIn: attendance.checkedIn.map((s) => {
            const finalStatus = attendanceByStudentId[s.userId] || s.finalStatus;
            const isEdited = s.edited || finalStatus !== s.finalStatus;
            return {
              ...s,
              finalStatus,
              edited: isEdited ? (1 as const) : (0 as const),
            };
          }),
        },
      },
    };
  } else {
    return state;
  }
}

export function attendanceClear(state: IRootState) {
  return {
    ...state,
    class: {
      ...state.class,
      attendance: null,
    },
  };
}

export function attendanceFetchSuccess(
  state: IRootState,
  payload: actions.IAttendanceFetchSuccessPayload
): IRootState {
  return {
    ...state,
    class: {
      ...state.class,
      attendance: {
        classId: payload.classId,
        inPersonAuto: payload.inPersonAuto,
        remoteAuto: payload.remoteAuto,
        remoteAutoInstances: payload.remoteAutoInstances,
        checkedIn: payload.attendance.checkedIn,
      },
    },
  };
}

export function topicsEditSuccess(
  state: IRootState,
  payload: actions.ITopicsEditSuccessPayload
): IRootState {
  return updateClassInRootState(state, payload.classId, (c) => ({
    ...c,
    info: {
      ...c.info,
      topicIds: payload.topicIds,
    },
  }));
}

// export function assistantEditSuccess(
//     state: IRootState,
//     payload: actions.IEditClassTeamSuccessPayload
// ): IRootState {
//     const updatedClassIds: ObjectMap<boolean> = {};
//     for (const id of payload.classIds) {
//         updatedClassIds[id] = true;
//     }
//     return updateClassesInRootState({
//         in: state,
//         where: c => updatedClassIds[c._id],
//         update: c => ({
//             ...c,
//             info: {
//                 ...c.info,
//                 team: {
//                     ...c.info.team,
//                     assistants: payload.assistants
//                 }
//             }
//         })
//     });
// }

// export function assistantsEditSuccess(
//     state: IRootState,
//     payload: actions.IEditClassTeamSuccessPayload
// ): IRootState {
//     const updatedClassIds: ObjectMap<boolean> = {};
//     for (const id of payload.classIds) {
//         updatedClassIds[id] = true;
//     }
//     return updateClassesInRootState({
//         in: state,
//         where: c => updatedClassIds[c._id],
//         update: c => ({
//             ...c,
//             info: {
//                 ...c.info,
//                 team: {
//                     ...c.info.team,
//                     assistants: payload.assistants
//                 }
//             }
//         })
//     });
//     // return updateClassInRootState(
//     //     state, payload.classId,
//     //     c => ({
//     //         ...c,
//     //         info: {
//     //             ...c.info,
//     //             team: {
//     //                 ...c.info.team,
//     //                 assistants: payload.assistants
//     //             }
//     //         }
//     //     })
//     // );
// }

export function agendaEditSuccess(
  state: IRootState,
  payload: actions.IAgendaEditSuccessPayload
): IRootState {
  return updateClassInRootState(state, payload.classId, (c) => ({
    ...c,
    info: {
      ...c.info,
      agenda: payload.agenda,
    },
  }));
}

export function titleEditSuccess(
  state: IRootState,
  payload: actions.IClassTitleEDitSuccessPayload
): IRootState {
  return updateClassInRootState(state, payload.classId, (c) => ({
    ...c,
    details: {
      ...c.details,
      title: payload.title,
    },
  }));
}

export function venueEditSuccess(
  state: IRootState,
  payload: actions.IVenueEditSuccessPayload
): IRootState {
  const updatedClassIds: ObjectMap<boolean> = {};
  for (const id of payload.changedClassIds) {
    updatedClassIds[id] = true;
  }
  return updateClassesInRootState({
    in: state,
    where: (c) => updatedClassIds[c._id],
    update: (c) => ({
      ...c,
      details: {
        ...c.details,
        venue: payload.newVenue,
      },
    }),
  });
}

export function updateClassesInRootState(obj: {
  in: IRootState;
  where: (c: IClass) => boolean;
  update: (c: IClass) => IClass;
}): IRootState {
  const state = obj.in;
  if (!state.courses.timeline) return state;
  return {
    ...state,
    courses: {
      ...state.courses,
      timeline: {
        ...state.courses.timeline,
        items: utils.replaceWhere(state.courses.timeline.items, obj.update, obj.where),
      },
    },
  };
}

export function cancelSuccess(
  state: IRootState,
  payload: actions.ICancelSuccessPayload
): IRootState {
  return updateClassInRootState(state, payload.classId, (c) => ({
    ...c,
    details: {
      ...c.details,
      status: payload.mark,
    },
  }));
}

export function addWeeklyClassSuccess(
  state: IRootState,
  payload: actions.IAddWeeklyClassSuccessPayload
): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const schedule = timeline.schedule;
  const newAddedSchedule: ICourseScheduleItem[] = [] as any;
  payload.day.forEach((day) => {
    newAddedSchedule.push({
      itemType: 'scheduled',
      type: payload.type,
      day: day,
      startTime: payload.startTime,
      endTime: payload.endTime,
      venue: payload.venue,
      isOnlineMeeting: payload.isOnlineMeeting,
    });
  });
  const updatedCoursesSlice = {
    ...state.courses,
    timeline: {
      ...timeline,
      schedule: [...schedule, ...newAddedSchedule],
    },
  };
  return {
    ...state,
    courses: updatedCoursesSlice,
  };
}

export function closeClassScreen(state: IRootState): IRootState {
  return {
    ...state,
    comments: {
      ...state.comments,
      comments: undefined,
    },
    class: {
      data: null,
      attendance: null,
      searchField: '',
      sortStudentBy: state.app.sortStudentBy,
      allSuggestedActivities: [],
    },
    quizzes: {
      ...state.quizzes,
      byId: {},
    },
    polls: {
      ...state.polls,
      byId: {},
    },
    discussions: {
      ...state.discussions,
      byId: {},
    },
    resources: {
      ...state.resources,
      byId: {},
    },
    queries: {
      ...state.queries,
      byId: {},
    },
  };
}

export function fetchClassActivitiesSuccess(
  state: IRootState,
  payload: actions.IFetchActivitiesSuccessPayload
): IRootState {
  const courseRole = getCourseRole(state);
  const quizzes = payload.response.quizzes.map((q) =>
    responseQuizToQuiz(payload.response.userData.quizzes[q._id], payload.classId, q)
  );
  const quizIds = quizzes.map((q) => q._id);
  const quizzesById = utils.makeObjectWithKey(quizzes, '_id');

  const polls = payload.response.polls.map((p) =>
    responsePollToPoll(payload.response.userData.polls[p._id], payload.classId, p)
  );
  const pollIds = polls.map((p) => p._id);
  const pollsById = utils.makeObjectWithKey(polls, '_id');

  const discussions = payload.response.discussions.map((d) =>
    responseDiscussionToDiscussion(
      payload.response.userData.discussions[d._id],
      payload.classId,
      courseRole,
      d
    )
  );
  const discussionIds = discussions.map((d) => d._id);
  const discussionsById = utils.makeObjectWithKey(discussions, '_id');

  const resources = payload.response.resources.map((r) =>
    responseResourceToResource(payload.response.userData.resources[r._id], payload.classId, r)
  );
  const resourceIds = resources.map((r) => r._id);
  const resourcesById = utils.makeObjectWithKey(resources, '_id');

  const queries = payload.response.queries.map((q) =>
    responseQueryToQuery(payload.response.userData.queries[q._id], payload.classId, q)
  );
  const queryIds = queries.map((q) => q._id);
  const queriesById = utils.makeObjectWithKey(queries, '_id');

  let recordings: IZoomRecordingState = undefined;

  if (payload.response.recordings) {
    const userData = payload.response.userData;
    recordings = {
      ...payload.response.recordings,
      isNew: !userData.recordings || userData.recordings[payload.classId] !== 'viewed',
      files: {},
    };
  }

  let updatedAppSlice: IAppState = state.app;
  const attendance = payload.response.attendance;

  if (attendance.inProgress === 1) {
    let numMarkedPresentNow = 0;
    let failuresLastSyncedAt = -1;
    let failures: AttendanceProcessFailure[] = [];

    if (state.app.attendanceData?.classId === payload.classId) {
      numMarkedPresentNow = state.app.attendanceData.numMarkedPresentNow || 0;
      failures = state.app.attendanceData.failures || [];
      failuresLastSyncedAt = state.app.attendanceData.failuresLastSyncedAt || -1;
    }

    updatedAppSlice = {
      ...state.app,
      attendanceData: {
        attendanceTime: attendance.attendanceTime,
        scheStartTime: payload.response.details.scheStartTime,
        classId: payload.classId,
        courseId: state.courses.currentCourseId!,
        isProxy: attendance.isProxy,
        numAvailable: payload.response.attendance?.numAvailable || 0,
        numEnrolled: payload.response.attendance?.numEnrolled || 0,
        numMarkedPresentNow,
        failures,
        failuresLastSyncedAt,
        numPresent: payload.response.attendance?.numPresent || 0,
        taker: attendance.taker!,
      },
    };
  }

  const updatedClassSlice: IClassState = {
    ...state.class,
    data: {
      classId: payload.classId,
      attendance: payload.response.attendance,
      suggestedActivities: payload.response.suggestedActivities,
      showSuggestedActivities: payload.response.showSuggestedActivities,
      activities: {
        quizzes: quizIds,
        polls: pollIds,
        discussions: discussionIds,
        resources: resourceIds,
        queries: queryIds,
      },
      userData: {},
      isOnlineMeeting: payload.response.details.isOnlineMeeting,
      onlineDetails: payload.response.onlineDetails,
      recordings,
      autoRecordDefaults: payload.response.autoRecordDefaults,
    },
  };

  const timeline = state.courses.timeline;
  const updatedCoursesSlice = timeline
    ? utils.update(state.courses, {
        timeline: {
          items: utils.replaceWhere(
            timeline.items,
            (cls: IClass) => {
              const { currentTime, response } = payload;
              return utils.update(cls, {
                details: {
                  ...response.details,
                  status: Class.status(response, currentTime),
                },
                info: response.info,
                autoSchedule: response.autoSchedule,
              });
            },
            (item) => item._id === payload.classId
          ),
        },
      })
    : state.courses;
  const updatedQuizSlice = {
    ...state.quizzes,
    byId: quizzesById,
  };
  const updatedPollsSlice = {
    ...state.polls,
    byId: pollsById,
  };
  const updatedResourceSlice = {
    ...state.resources,
    byId: resourcesById,
  };
  const updatedDiscussionSlice = {
    ...state.discussions,
    byId: discussionsById,
  };
  const updatedQuerySlice = {
    ...state.queries,
    byId: queriesById,
  };
  return {
    ...state,
    app: updatedAppSlice,
    class: updatedClassSlice,
    courses: updatedCoursesSlice,
    quizzes: updatedQuizSlice,
    polls: updatedPollsSlice,
    resources: updatedResourceSlice,
    discussions: updatedDiscussionSlice,
    queries: updatedQuerySlice,
  };
}

export function moveClassActivitySuccess(
  state: IRootState,
  payload: actions.IMoveActivitySuccessPayload
): IRootState {
  if (!state.class.data) return state;
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const keyMapping: ObjectMap<keyof IClass['activities'][typeof payload.subType]> = {
    quiz: 'quizzes',
    resource: 'resources',
    poll: 'polls',
    discussion: 'discussions',
  };
  const key = keyMapping[payload.type];
  const updatedClassSlice = utils.update(state.class, {
    data: {
      activities: {
        [key]: state.class.data.activities[key].filter((id) => id !== payload.activityId),
      },
    },
  });
  const oldClass = timeline.items.find((cls) => cls._id === payload.classId);
  const newClass = timeline.items.find((cls) => cls._id === payload.newClassId);
  if (!oldClass || !newClass) return state;

  const toBeDone = payload.subType;
  const newToBeDone = payload.newSubType;
  const itemsWithUpdatedOldClass = utils.replaceWhere(
    timeline.items,
    (cls: IClass) => {
      const oldCount = cls.activities[toBeDone][key].numTotal;
      return utils.update(cls, {
        activities: {
          [toBeDone]: {
            [key]: {
              numTotal: oldCount - 1,
            },
          },
        },
      });
    },
    (cls) => cls._id === payload.classId
  );

  const itemsWithUpdatedNewClass = utils.replaceWhere(
    itemsWithUpdatedOldClass,
    (cls: IClass) => {
      const oldCount = cls.activities[payload.newSubType][key].numTotal;
      return utils.update(cls, {
        activities: {
          [newToBeDone]: {
            [key]: {
              numTotal: oldCount + 1,
            },
          },
        },
      });
    },
    (cls) => cls._id === payload.newClassId
  );
  const updatedCoursesSlice = {
    ...state.courses,
    timeline: {
      ...timeline,
      items: itemsWithUpdatedNewClass,
    },
  };
  const byId: TotalMapping<string, IClassActivity | undefined> = state[key].byId;
  const newActivities = utils.mapValues(byId, (a) =>
    a
      ? {
          ...a,
          identifiers: {
            classId: payload.newClassId,
          },
          details: {
            ...a.details,
            toBeDone: payload.newSubType,
          },
        }
      : undefined
  );
  return {
    ...state,
    class: updatedClassSlice,
    courses: updatedCoursesSlice,
    [key]: {
      ...state[key],
      byId: newActivities,
    },
  };
}

function incrementClassSeenActivities(
  state: IRootState,
  payload: actions.IIncrementSeenActivitiesPayload
): IRootState {
  if (!state.courses.timeline) return state;
  const toBeDone = payload.toBeDone;
  const activityKey = payload.activityKey;
  const courseId = state.courses.currentCourseId;
  if (!courseId) return state;
  const courseUserData = state.courses.userData[courseId];
  const updatedCoursesSlice = utils.update(state.courses, {
    timeline: {
      items: utils.replaceWhere(
        state.courses.timeline.items,
        (c: IClass) =>
          utils.update(c, {
            userData: {
              [toBeDone]: {
                [activityKey]: {
                  numSeen: c.userData[toBeDone][activityKey].numSeen + 1,
                },
              },
            },
          }),
        (item) => item._id === payload.classId
      ),
    },
    userData: {
      [courseId]: courseUserData
        ? {
            activitiesSeen: courseUserData.activitiesSeen + 1,
          }
        : courseUserData,
    },
  });
  return {
    ...state,
    courses: updatedCoursesSlice,
  };
}

export function startClass(state: IRootState, payload: actions.IStartClassPayload): IRootState {
  return updateClassInRootState(state, payload.classId, (cls: IClass) =>
    utils.update(cls, {
      details: {
        status: cls.details.status === 'open' ? 'inSession' : cls.details.status,
      },
    })
  );
}

export function setClassIncharge(
  state: IRootState,
  payload: actions.ISetClassInchargeResponse
): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const updatedClassIds: ObjectMap<boolean> = {};
  for (const id of payload.classIds) {
    updatedClassIds[id] = true;
  }
  return updateClassesInRootState({
    in: state,
    where: (c) => updatedClassIds[c._id],
    update: (c) => ({
      ...c,
      details: {
        ...c.details,
        classInchargeSet: 1,
      },
      info: {
        ...c.info,
        team: {
          ...c.info.team,
          inCharge: payload.inCharge,
        },
      },
    }),
  });
  // const updatedCoursesSlice = utils.update(state.courses, {
  //     timeline: {
  //         items: utils.replaceWhere(
  //             timeline.items,
  //             (cls: IClass) => utils.update(cls, {
  //                 details: {
  //                     classInchargeSet: 1
  //                 },
  //                 info: {
  //                     team: {
  //                         inCharge: payload.inCharge
  //                     }
  //                 }
  //             }),
  //             item => item._id === payload.classId
  //         )
  //     }
  // });

  // return {
  //     ...state,
  //     courses: updatedCoursesSlice
  // };
}

export function classCheckInSuccess(
  state: IRootState,
  payload: actions.ICheckInSuccessPayload
): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const updatedCoursesSlice = utils.update(state.courses, {
    timeline: {
      items: utils.replaceWhere(
        timeline.items,
        (c: IClass) => ({
          ...c,
          userData: {
            ...c.userData,
            attendance: {
              status: 'checkedIn' as const,
              isCheckedIn: 1 as const,
              visibleStatus: undefined,
              ...c.userData.attendance,
              checkInTime: payload.checkInTime,
            },
          },
        }),
        (c) => c._id === payload.classId
      ),
    },
  });
  return {
    ...state,
    courses: updatedCoursesSlice,
  };
}

export function removeWeeklyClassSuccess(
  state: IRootState,
  payload: actions.IRemoveWeeklyClassSuccessPayload
): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const schedule = timeline.schedule;
  const updatedCoursesSlice = {
    ...state.courses,
    timeline: {
      ...timeline,
      schedule: [...schedule.slice(0, payload), ...schedule.slice(payload + 1)],
    },
  };
  return {
    ...state,
    courses: updatedCoursesSlice,
  };
}

export function deleteSuccess(
  state: IRootState,
  payload: actions.IDeleteSuccessPayload
): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const index = utils.findIndex(timeline.items, (item) => item._id === payload.classId);
  if (index === null) return state;
  const before = timeline.items.slice(0, index);
  const after = timeline.items.slice(index + 1);
  const updatedCoursesSlice = {
    ...state.courses,
    timeline: {
      ...timeline,
      items: [
        ...before,
        ...after.map((item) =>
          item.nodeType === 'class' &&
          item.details.type === payload.type &&
          item.details.subType === payload.subType
            ? {
                ...item,
                details: {
                  ...item.details,
                  typeNum: item.details.typeNum - 1,
                },
              }
            : item
        ),
      ],
    },
  };
  return {
    ...state,
    courses: updatedCoursesSlice,
  };
}

export function pusherInChargeSet(state: IRootState, payload: actions.IPusherInChargeSetPayload) {
  return updateClassInRootState(state, payload.classId, (cls) => ({
    ...cls,
    info: {
      ...cls.info,
      team: {
        ...cls.info.team,
        inCharge: payload.inCharge,
      },
    },
  }));
}

export function pusherActivityUpdated(
  state: IRootState,
  payload: IUpdateActivityBasePayload // TODO: Incorrect type defined.
): IRootState {
  if (state.class.data && payload.activityId && payload.activityType === 'poll') {
    const activityPayload = payload as IUpdateActivityPollPayload;

    const currentPoll = state.polls.byId[activityPayload.activityId];
    if (currentPoll) {
      currentPoll.details.lastEditedOn = activityPayload.timestamp;
      currentPoll.details.title = activityPayload.poll.title;
    }

    const question = state.polls.question;
    let areQuestionsEdited = false;
    if (question && activityPayload.activityId === question.pollId) {
      question.details.description.text = activityPayload.poll.description.text;
      areQuestionsEdited = !!activityPayload.poll.areQuestionsEdited;
    }

    return {
      ...state,
      polls: {
        ...state.polls,
        areQuestionsEdited,
        question,
        byId: {
          ...state.polls.byId,
          [activityPayload.activityId]: currentPoll,
        },
      },
    };
  }
  if (state.class.data && payload.activityId && payload.activityType === 'quiz') {
    const activityPayload = payload as IUpdateActivityQuizPayload;

    const currentQuiz = clone(state.quizzes.byId[activityPayload.activityId]);
    if (currentQuiz) {
      currentQuiz.details.lastEditedOn = activityPayload.timestamp;
      currentQuiz.details.timesEdited = currentQuiz.details.timesEdited + 1;
      currentQuiz.details.title = activityPayload.quiz.title;
      currentQuiz.details.instructions = activityPayload.quiz.instructions;
      currentQuiz.details.attachments = activityPayload.quiz.attachments;
    }

    const current = clone(state.quizzes.current);
    if (current && activityPayload.activityId === current._id) {
      current.areQuestionsEdited = !!activityPayload.quiz.areQuestionsEdited;
    }

    return {
      ...state,
      quizzes: {
        ...state.quizzes,
        current,
        byId: {
          ...state.quizzes.byId,
          [activityPayload.activityId]: currentQuiz,
        },
      },
    };
  }
  if (state.class.data && payload.activityId && payload.activityType === 'resource') {
    const activityPayload = payload as IUpdateActivityResourcePayload;

    const currentResource = state.resources.byId[activityPayload.activityId];
    if (currentResource) {
      currentResource.details.lastEditedOn = activityPayload.timestamp;
      currentResource.details.description = activityPayload.resource.description;
      currentResource.details.fileName = activityPayload.resource.fileName;
      currentResource.details.originalFileName = activityPayload.resource.originalFileName;
      currentResource.details.resourceType = activityPayload.resource.resourceType;
      currentResource.details.title = activityPayload.resource.title;
      currentResource.details.url = activityPayload.resource.url;
      currentResource.details.videoId = activityPayload.resource.videoId;
    }
    return {
      ...state,
      resources: {
        ...state.resources,
        byId: {
          ...state.resources.byId,
          [activityPayload.activityId]: currentResource,
        },
      },
    };
  }

  if (state.class.data && payload.activityId && payload.activityType === 'discussion') {
    const activityPayload = payload as IUpdateActivityDiscussionPayload;

    const currentDiscussion = state.discussions.byId[activityPayload.activityId];
    if (currentDiscussion) {
      currentDiscussion.details.lastEditedOn = activityPayload.timestamp;
      currentDiscussion.details.title = activityPayload.discussion.title;
      currentDiscussion.details.description = activityPayload.discussion.description;
      currentDiscussion.details.attachments = activityPayload.discussion.attachments;
      currentDiscussion.details.timesEdited = (currentDiscussion.details.timesEdited || 0) + 1;
    }
    return {
      ...state,
      discussions: {
        ...state.discussions,
        byId: {
          ...state.discussions.byId,
          [activityPayload.activityId]: currentDiscussion,
        },
      },
    };
  }

  if (state.courses.timeline && payload.activityId && payload.activityType === 'assignment') {
    const activityPayload = payload as IUpdateActivityAssignmentPayload;

    const areQuestionsEdited = !!activityPayload.assignment.areQuestionsEdited;
    const newItems = state.courses.timeline.items.map((item) => {
      if (item.nodeType === 'assignment' && item._id === activityPayload.activityId) {
        item.details.lastEditedOn = activityPayload.timestamp;
        item.details.title = activityPayload.assignment.title;
        item.details.attachments = activityPayload.assignment.attachments;
        item.details.dueDateTime = activityPayload.assignment.dueDateTime;
        item.details.instructions = activityPayload.assignment.instructions;
        item.details.areQuestionsEdited = !!activityPayload.assignment.areQuestionsEdited;
      }
      return item;
    });
    return {
      ...state,
      assignment: {
        ...state.assignment,
        areQuestionsEdited,
      },
      courses: {
        ...state.courses,
        timeline: {
          ...state.courses.timeline,
          items: [...newItems],
        },
      },
    };
  }

  return state;
}

export function pusherCheckIn(
  state: IRootState,
  payload: actions.IPusherCheckInPayload
): IRootState {
  if (!state.class.attendance) {
    return state;
  }
  return {
    ...state,
    class: {
      ...state.class,
      attendance: {
        ...state.class.attendance,
        classId: payload.classId,
        checkedIn: utils.replaceWhere(
          state.class.attendance.checkedIn,
          (s) => ({
            ...s,
            isCheckedIn: <const>1,
            checkInTime: payload.checkInTime,
            isLate: payload.isLate,
            status: 'checkedIn' as const,
            visibleStatus: 'checkedIn' as const,
          }),
          (s) => s.userId === payload.sender.userId
        ),
      },
    },
  };
}

export function attendanceFetchMineSuccess(
  state: IRootState,
  payload: actions.IAttendanceFetchMineSuccessPayload
): IRootState {
  let newStatus: 'present' | 'absent' | 'checkedIn' | null = null;
  if (payload.response.attendance === 1) {
    if (payload.response.status === 'present') {
      newStatus = 'present';
    } else if (payload.response.status === 'absent') {
      newStatus = 'absent';
    } else if (payload.response.status === 'notPresent') {
      if (payload.response.checkedIn) {
        newStatus = 'checkedIn';
      } else {
        newStatus = null;
      }
    }
  } else {
    if (payload.response.checkedIn) {
      newStatus = 'checkedIn';
    } else {
      newStatus = null;
    }
  }
  if (newStatus === null) {
    return state;
  } else {
    return updateClassInRootState(state, payload.classId, (cls) => ({
      ...cls,
      userData: {
        ...cls.userData,
        attendance: {
          ...cls.userData.attendance,
          status: newStatus as any,
          visibleStatus: payload.response.visibleStatus as any,
        },
      },
    }));
  }
}
