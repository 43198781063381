import { CSS, h } from 'core';

export default (props: { newComments: number; style?: CSS; className?: string }) => {
  if (props.newComments < 1) return null;
  return h(
    'div.new-comments-bubble',
    {
      style: props.style,
      className: props.className,
    },
    [
      h('div.new-comments-bubble__wrapper', [
        h('i.fa.fa-comment.new-comments-bubble__icon', {
          'aria-hidden': true,
        }),
        h(
          'div.new-comments-bubble__count',
          props.newComments > 99 ? '99+' : props.newComments.toString()
        ),
      ]),
    ]
  );
};
