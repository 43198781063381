import { JSONPromise, jsonRequest, upload } from 'core/http';

import { api } from 'acadly/api';
import { splitFileName } from 'acadly/utils';

export interface ICreateRequest {
  classId: string;
  toBeDone: 'preClass' | 'inClass';
}
export type ICreateResponse = IResponseResource;

export const create = (data: ICreateRequest) =>
  jsonRequest<ICreateResponse>(api().resourceCreate, {
    method: 'POST',
    data,
  });

export interface IDeleteRequest {
  classId: string;
  resourceId: string;
  toBeDone: 'preClass' | 'inClass';
}
export const deleteResource = (data: IDeleteRequest) =>
  jsonRequest(api().resourceRemove, {
    method: 'DELETE',
    data,
  });

export interface IUploadFileRequest {
  file: File;
  classId: string;
  resourceId: string;
}

export type IUploadFileResponse = {
  fileName: string;
};
export const uploadFile = (data: IUploadFileRequest) => {
  const { name, extension } = splitFileName(data.file.name);
  const { promise, progress$ } = upload(api().resourceFileUpload, {
    method: 'POST',
    data: {
      classId: data.classId,
      activityId: data.resourceId,
      activityType: 'resources',
      originalFileName: name,
      fileType: extension,
    },
  });
  return {
    promise: <JSONPromise<IUploadFileResponse>>promise.then((response) => ({
      ...response,
      data: JSON.parse(response.data),
    })),
    progress$,
  };
};

export type IEditRequest = {
  classId: string;
  resourceId: string;
  title: string;
  description: string;
  resourceType: 'link' | 'video' | 'text' | 'file';
  url: string;
  videoId: string;
  originalFileName: string;
  fileName: string;
};

export const edit = (data: IEditRequest) =>
  jsonRequest(api().resourceEdit, {
    method: 'POST',
    data,
  });

export interface IPublishRequest {
  classId: string;
  resourceId: string;
  toBeDone: 'preClass' | 'inClass';
}

export interface IPublishPublishedResourceRequest {
  classId: string;
  resourceId: string;
  title: string;
  description: string;
  resourceType: 'link' | 'video' | 'text' | 'file';
  toNotify: 0 | 1;
  url?: string;
  videoId?: string;
  originalFileName?: string;
  fileName?: string;
}

export interface IPublishResourceRequest {
  classId: string;
  toBeDone: 'preClass' | 'inClass';
  resourceId: string;
  title: string;
  description: string;
  resourceType: 'link' | 'video' | 'text' | 'file';
  url: string;
  videoId: string;
  originalFileName: string;
  fileName: string;
  subscribeToComments: 0 | 1;
}

export interface IPublishResponse {
  publishedOn: UnixTimestamp;
  trueNum: number;
  resourceNum: number;
}

export const publish = (data: IPublishResourceRequest) =>
  jsonRequest<IPublishResponse>(api().resourcePublishV2, {
    method: 'POST',
    data,
  });

export const publishPublishedResource = (data: IPublishPublishedResourceRequest) =>
  jsonRequest<IPublishResponse>(api().resourceUpdatePublished, {
    method: 'PUT',
    data,
  });

export interface IAnalyticsSetRequest {
  resourceId: string;
  firstAccess: 0 | 1;
  localTime: string; // YYYYMMDDTHH:mm
}

export const analyticsSet = (data: IAnalyticsSetRequest) =>
  jsonRequest(api().analyticsResourceSet, {
    method: 'POST',
    data,
  });

export interface IAnalyticsFetchRequest {
  resourceId: string;
}
export interface IAnalyticsFetchResponse {
  accessedBy: IResourceAnalytics[];
}

export const analyticsResourceFetch = (resourceId: string) =>
  jsonRequest<IResourceAnalytics>(api().analyticsResourceGet + resourceId, {
    method: 'GET',
  });

export interface IFetchAllResponse {
  activityData: IResponseResourceWithClassId[];
  userData: {
    resources: ObjectMap<IResponseResourceUserData | undefined>;
  };
}
export const fetchAll = () =>
  jsonRequest<IFetchAllResponse>(api().archiveGet + '/resources', {
    method: 'GET',
  });
