import { CSS, h, View } from 'core';

import Hover from 'acadly/common/Hover';
import Icon from 'acadly/common/Icon';
import UploadButton from 'acadly/common/UploadButton';
import icons from 'acadly/icons';
import { getStore } from 'acadly/store';
import { colors, pad, style } from 'acadly/styles';

import { IRawEditorProps, RawEditor } from './RawEditor';

export default (props: IEditorProps) => h(Editor, props);
export interface IEditorProps extends IRawEditorProps {
  title?: string;
  titleStyle?: CSS;
  attachmentView?: View;
  errorText?: string;
  optionField?: boolean;
}

export class Editor extends RawEditor<IEditorProps> {
  public render() {
    const props = this.getProps();
    const isMobile = getStore().getState().app.isMobile;
    const ICON_COLOR = 'rgb(6, 94, 140)';
    const buttons = [
      ...(this.getProps().enableTextFormatting
        ? [
            {
              type: 'BOLD',
              icon: icons.bold,
              onclick: (_: any) => this.clickBold(),
              isSelected: this.isBold(),
              isSquare: true,
              text: '',
              tabIndex: this.isAccessible ? 0 : undefined,
              position: 'left',
            },
            {
              type: 'ITALIC',
              icon: icons.italic,
              onclick: (_: any) => this.clickItalic(),
              isSelected: this.isItalic(),
              isSquare: true,
              text: '',
              tabIndex: this.isAccessible ? 0 : undefined,
              position: 'left',
            },
            {
              type: 'UNDERLINE',
              icon: icons.underline,
              onclick: (_: any) => this.clickUnderline(),
              isSelected: this.isUnderlined(),
              isSquare: true,
              text: '',
              tabIndex: this.isAccessible ? 0 : undefined,
              position: 'left',
            },
          ]
        : []),

      this.getProps().enableFormulaInput
        ? {
            type: 'FORMULA',
            icon: icons.formula,
            onclick: (_: any) => this.showFormulaDialog(),
            isSelected: false,
            isSquare: true,
            text: '',
            position: 'left',
            tabIndex: this.isAccessible ? 0 : undefined,
          }
        : null,

      this.getProps().enableImageInput
        ? {
            type: 'IMAGE',
            icon: icons.image,
            onclick: (_: any) => this.showImageDialog(),
            isSelected: false,
            isSquare: props.optionField ? false : true,
            text: props.optionField ? 'Upload an image' : '',
            tabIndex: this.isAccessible ? 0 : undefined,
            position: props.optionField ? 'right' : 'left',
          }
        : null,

      this.getProps().enableFileAttachments
        ? {
            type: 'ATTACHMENT',
            icon: icons.attachment,
            onclick: () => {},
            isSelected: false,
            isSquare: true,
            text: '',
            tabIndex: this.isAccessible ? 0 : undefined,
            position: 'left',
          }
        : null,
    ].map((btn) => {
      if (!btn) return null;
      return Hover(
        {
          key: btn.type,
          border: !btn.isSelected ? `1px solid ${colors.grey}` : '1px solid transparent',
        },
        btn.type === 'ATTACHMENT'
          ? h(
              'div',
              style(
                [
                  {
                    border: '1px solid transparent',
                  },
                ],
                {},
                {}
              ),
              [
                UploadButton({
                  upload: (file) => this.uploadFile(file),
                  view: h(
                    'button',
                    {
                      style: {
                        backgroundColor: btn.isSelected ? ICON_COLOR : 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: btn.isSquare ? '1.3em' : undefined,
                        height: '1.3em',
                        fontSize: '1.5em',
                        cursor: 'pointer',
                        border: 'none',
                        color: btn.isSelected ? 'white' : ICON_COLOR,
                        marginRight: '0.3rem',
                        marginLeft: '0.5rem',
                      },
                    },
                    [Icon(btn.icon)]
                  ),
                }),
              ]
            )
          : h(
              'span',
              {
                'aria-label': `${btn.type}`,
                key: `editor-button-${btn.type}`,
                tabIndex: this.isAccessible ? 0 : undefined,
                style: {
                  borderRadius: '0em',
                  backgroundColor: btn.isSelected ? ICON_COLOR : 'white',
                  border: '1px solid transparent',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: btn.isSquare ? '1.3em' : undefined,
                  height: '1.3em',
                  fontSize: '1.5em',
                  cursor: 'pointer',
                  marginRight: '0.3rem',
                  color: btn.isSelected ? 'white' : ICON_COLOR,
                  marginLeft: '0.5rem',
                },
                onclick: (event: Event) => {
                  event.preventDefault();
                  btn.onclick(event);
                },
              },
              [
                Icon(btn.icon),
                btn.text.length > 0
                  ? h(
                      'span',
                      style([
                        {
                          fontSize: '0.8rem',
                        },
                      ]),
                      btn.text
                    )
                  : null,
              ]
            )
      );
    });
    return h(
      'div.rich-text-editor',
      style([
        {
          backgroundColor: 'white',
        },
      ]),
      [
        isMobile && !props.optionField
          ? h(
              'div.rich-editor-toolbar',
              style([
                'flex',
                {
                  padding: '0.5em 0em',
                  marginBottom: '0.5em',
                  borderTop: `1px solid ${colors.lightGrey}`,
                  borderBottom: `1px solid ${colors.lightGrey}`,
                },
              ]),
              buttons
            )
          : null,
        h('div', style([pad('0.5em')]), [
          super.render(),
          props.attachmentView ? props.attachmentView : null,
        ]),
        isMobile && props.optionField && !this.getState().isFocused
          ? null
          : h('div.rich-editor-bottom-border', { style: { display: 'flex' } }, [
              h('div', {
                style: {
                  width: this.getState().isFocused || this.getProps().errorText ? '100%' : '0',
                  height: '2px',
                  backgroundColor: this.getProps().errorText ? colors.errorRed : colors.blue,
                  transition: 'width 0.2s',
                },
              }),
              h('div', {
                style: {
                  width: !this.getState().isFocused && !this.getProps().errorText ? '100%' : '0',
                  height: '2px',
                  backgroundColor: colors.lightGrey,
                  transition: 'width 0.2s',
                },
              }),
            ]),
        this.getProps().errorText
          ? h(
              'div',
              {
                style: {
                  color: colors.red,
                },
              },
              this.getProps().errorText
            )
          : null,
        !isMobile || (props.optionField && this.getState().isFocused)
          ? h('div.rich-editor-toolbar', style(['flex', { marginTop: '0.3em' }]), buttons)
          : null,
      ]
    );
  }
}

export const BasicEditor = (props: IEditorProps) => h(Editor, props);
