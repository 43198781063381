import { appVersion } from 'acadly/appVersion';
import { range } from 'acadly/utils';
import loggly from 'acadly/utils/loggly';

export function generateMarkup(node: Node): string {
  const div = document.createElement('div');

  for (const i of range(node.childNodes.length)) {
    const child = node.childNodes[i];
    const result = traverse(child);
    if (Array.isArray(result)) {
      result.forEach(function (child) {
        div.appendChild(child);
      });
    } else {
      div.appendChild(result);
    }
  }
  return div.innerHTML.replace(/^(<br>)*/g, '').replace(/(<br>)*$/g, '');
}

export function parseMarkup(
  source: string,
  opts: {
    onClickImage?(src: string): any;
    onClickImageContainer?(event: MouseEvent): void;
  } = {}
): HTMLElement | null {
  if (source === '') return null;
  const element = document.createElement('div');
  element.innerHTML = source;
  const formulae = element.getElementsByTagName('formula');
  for (let i = 0; i < formulae.length; i++) {
    const formula = formulae[i];
    formula.setAttribute('contenteditable', 'false');
    const display = formula.getAttribute('display');
    const format = formula.getAttribute('format');
    const text = formula.innerHTML;
    if (format === 'mml') {
      formula.children[0].setAttribute('display', display!);
    } else if (format === 'am') {
      if (display === 'block') {
        formula.innerHTML =
          "<span style='width: 100%; display: flex; justify-content: center'>`" +
          formula.innerHTML +
          '`</span>';
      } else {
        formula.innerHTML = '`' + formula.innerHTML + '`';
      }
    } else {
      if (display === 'block') {
        formula.innerHTML = '$$' + text + '$$';
      } else {
        formula.innerHTML = '\\(' + text + '\\)';
      }
    }
  }

  const images = element.getElementsByTagName('img');
  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    const src = image.getAttribute('src')!;
    const div = document.createElement('span');
    div.className = 'acadly-flex';
    div.classList.add('acadly-img-container');
    div.setAttribute('contenteditable', 'false');
    Object.assign(div.style, {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    });
    Object.assign(image.style, {
      maxWidth: '100%',
    });
    image.parentElement!.replaceChild(div, image);
    div.appendChild(image);
    if (opts.onClickImage) {
      image.addEventListener('click', () => {
        if (opts.onClickImage) {
          opts.onClickImage(src);
        }
      });
    }

    if (opts.onClickImageContainer) {
      div.addEventListener('click', opts.onClickImageContainer);
    }
  }
  return element;
}

function traverse(node: Node): Node | Node[] {
  if (node.nodeName === '#text') {
    return node.cloneNode(true);
  } else if (node.nodeName.toUpperCase() === 'FORMULA' && node instanceof Element) {
    const elem = document.createElement('formula');
    elem.setAttribute('format', node.getAttribute('format')!);
    elem.setAttribute('display', node.getAttribute('display')!);
    elem.setAttribute('id', node.getAttribute('id')!);
    elem.setAttribute('ach', node.getAttribute('ach')!);
    elem.setAttribute('acw', node.getAttribute('acw')!);
    elem.setAttribute('url', node.getAttribute('url')!);
    const script = node.getElementsByTagName('script')[0];
    if (!script) {
      elem.innerHTML = '';
    } else {
      elem.innerHTML = script.innerHTML;
    }
    return elem;
  } else if (node.nodeName.toUpperCase() === 'A' && node instanceof HTMLAnchorElement) {
    const elem = document.createElement('a');
    elem.setAttribute('href', node.getAttribute('href')!);
    elem.innerText = node.innerText;
    return elem;
  } else if (node.nodeName.toUpperCase() === 'IMG' && node instanceof HTMLImageElement) {
    const elem = document.createElement('img');
    elem.setAttribute('src', node.getAttribute('src')!);
    elem.setAttribute('acw', node.getAttribute('acw')!);
    elem.setAttribute('ach', node.getAttribute('ach')!);
    // elem.setAttribute("style", "margin-top: 1em; margin-bottom: 1em; max-width: 100%");
    return elem;
  } else if (node.nodeName.toUpperCase() === 'DIV') {
    if (node.childNodes.length === 0) {
      return [];
    } else if (node.childNodes.length === 1 && node.childNodes[0].nodeName === 'BR') {
      return document.createElement('br');
    } else {
      // let result: Node[] = [];
      let result: Node[] = [];
      if (node.childNodes[0].nodeName.toUpperCase() !== 'DIV') {
        const br = document.createElement('br');
        result.push(br);
      }
      for (const i of range(node.childNodes.length)) {
        const child = node.childNodes[i];
        const traversed = traverse(child);
        if (traversed instanceof Array) {
          result = result.concat(traversed);
        } else {
          result.push(traversed);
        }
      }
      return result;
    }
  } else if (node.nodeName.toUpperCase() === 'SCRIPT' || node.nodeName.toUpperCase() === 'NOBR') {
    return document.createTextNode('');
  } else if (node.nodeName.toUpperCase() === 'SPAN') {
    let result: Node[] = [];
    for (const i of range(node.childNodes.length)) {
      const child = node.childNodes[i];
      const traversed = traverse(child);
      if (traversed instanceof Array) {
        result = result.concat(traversed);
      } else {
        result.push(traversed);
      }
    }
    return result;
  } else if (node.nodeName.toUpperCase() === 'MATH') {
    const formula = document.createElement('formula');
    formula.appendChild(node.cloneNode(true));
    return formula;
  } else if (
    node.nodeName.toUpperCase() === 'B' ||
    node.nodeName.toUpperCase() === 'I' ||
    node.nodeName.toUpperCase() === 'U'
  ) {
    const result = document.createElement(node.nodeName);
    for (const i of range(node.childNodes.length)) {
      const child = node.childNodes[i];
      const traversed = traverse(child);
      if (Array.isArray(traversed)) {
        traversed.forEach(function (child) {
          result.appendChild(child);
        });
      } else {
        result.appendChild(traversed);
      }
    }
    return result;
  } else if (node.nodeName.toUpperCase() === 'BR') {
    return document.createElement('br');
  } else if (node instanceof HTMLParagraphElement) {
    let result: Node[] = [];
    for (const i of range(node.children.length)) {
      const child = node.children[i];
      const traversed = traverse(child);
      if (traversed instanceof Array) {
        result = result.concat(traversed);
      } else {
        result.push(traversed);
      }
    }
    result.push(document.createElement('br'));
    return result;
  } else if (node instanceof HTMLStyleElement) {
    return [];
  } else if (node instanceof Element) {
    let result: Node[] = [];
    for (const i of range(node.childNodes.length)) {
      const child = node.childNodes[i];
      const traversed = traverse(child);
      if (traversed instanceof Array) {
        result = result.concat(traversed);
      } else {
        result.push(traversed);
      }
    }
    return result;
  } else if (node instanceof Comment) {
    return [];
  } else {
    let readable = node.toString();
    if (node instanceof Element) {
      readable = node.textContent || '';
    }
    const log = {
      type: 'Unhandled error',
      message: 'Unhandled node in rich-editor',
      node: readable,
      instanceofElement: node instanceof Element,
      userAgent: window.navigator.userAgent,
      version: appVersion,
    };
    console.error(log);
    loggly.push(log);
    return node.cloneNode(true);
  }
}
