import { h, IComponent } from 'core';

import { Actions as ClassActions } from 'acadly/class/actions';
import ClassService from 'acadly/class/service';
import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import { dispatch, getStore } from 'acadly/store';
import { mt, style } from 'acadly/styles';

import * as datetime from '../datetime';

const ATTENDANCE_TIMEOUT = 150; // in seconds

interface AttendanceAlertProps {
  courseId: string;
}

interface AttendanceAlertState {
  secondsLeft: number;
}

export default class AttendanceAlert extends IComponent<
  AttendanceAlertProps,
  AttendanceAlertState
> {
  private attendanceTimer?: NodeJS.Timer;

  private initialize() {
    const secondsLeft = this.getAttendanceTimeLeft();
    this.setState({ secondsLeft });
    this.startAttendanceTimer(secondsLeft);
  }

  public componentWillMount() {
    this.initialize();
  }

  public componentWillReceiveProps() {
    this.initialize();
  }

  public componentWillUnmount() {
    this.clearTimer();
  }

  private handleDismiss = () => {
    const rootState = getStore().getState();
    const rootAttendanceData = rootState.app.attendanceData;
    const classData = rootState.class.data;

    const { courseId } = this.getProps();
    const classId = rootAttendanceData.classId || classData.classId;
    const attendanceTime =
      rootAttendanceData.attendanceTime ?? classData.attendance?.attendanceTime ?? 0;

    dispatch(ClassActions.attendanceDialogDismiss({ courseId, classId, attendanceTime }));
  };

  public render() {
    const { secondsLeft } = this.getState();
    const taker = this.getAttendaceTaker();
    const scheStartTime = this.getScheStartTime();

    return Alert(
      {
        open: this.isAttendanceAlertVisible(),
        title: h('div.fc-orange', 'Attendance in progress'),
        style: {
          width: '35rem',
          fontSize: '1rem',
        },
        actions: [
          FlatButton('DISMISS', {
            type: 'secondary',
            onclick: this.handleDismiss,
          }),
        ],
      },
      [
        h('div.attendance-gif'),
        h('div.fc-orange.fw-bold', style(['textCenter']), `${secondsLeft} sec`),
        h('p', style([mt('1rem')]), [
          `Prof. ${taker?.name} is recording automatic attendance for `,
          `the lecture that began at ${datetime.format(scheStartTime, 'hh:mm A')}. `,
          `If you're physically present at the lecture venue, please open `,
          h(
            'a.no-break',
            {
              target: '_blank',
              href: 'https://itunes.apple.com/us/app/acadly/id1161073387?mt=8',
            },
            `Acadly's iOS`
          ),
          ' or ',
          h(
            'a.no-break',
            {
              target: '_blank',
              href: 'https://play.google.com/store/apps/details?id=co.acetone.acadly',
            },
            `Android app`
          ),
          ` to be marked present.`,
        ]),
        h('p', style([mt('1rem')]), [
          'Attendance will ',
          h('span', style(['bold']), 'NOT'),
          ' be marked if you are logged-in only through the web browser',
        ]),
      ]
    );
  }

  private isAttendanceAlertVisible(): boolean {
    const { courseId } = this.getProps();
    const { secondsLeft } = this.getState();

    const rootState = getStore().getState();
    const { attendanceData, vCallFrame } = rootState.app;
    const classData = rootState.class.data;

    // don't show this alert if zoom meeting is in-progress
    if (vCallFrame.isVisible) return false;

    if (classData?.attendance?.inProgress === 1) return secondsLeft > 0;

    return courseId === attendanceData?.courseId && secondsLeft > 0;
  }

  private clearTimer() {
    if (this.attendanceTimer) {
      clearInterval(this.attendanceTimer);
      this.attendanceTimer = undefined;
    }
  }

  private getAttendanceTimeLeft() {
    const rootState = getStore().getState();
    const { attendanceData } = rootState.app;
    const classData = rootState.class.data;

    const attendanceTime =
      classData?.attendance?.inProgress === 1
        ? classData.attendance.attendanceTime
        : attendanceData?.attendanceTime ?? 0;

    return attendanceTime + ATTENDANCE_TIMEOUT - datetime.toUnix(new Date());
  }

  private getAttendaceTaker() {
    const rootState = getStore().getState();
    const { attendanceData } = rootState.app;
    const classData = rootState.class.data;

    return classData?.attendance?.inProgress === 1
      ? classData.attendance.taker
      : attendanceData?.taker;
  }

  private getScheStartTime() {
    const cls = ClassService.getCurrentClass();
    const rootState = getStore().getState();
    const { attendanceData } = rootState.app;
    const classData = rootState.class.data;

    return classData?.attendance?.inProgress === 1
      ? cls?.details.scheStartTime
      : attendanceData?.scheStartTime;
  }

  private startAttendanceTimer(secondsLeft: number) {
    this.clearTimer();

    if (secondsLeft <= 0) return;

    this.attendanceTimer = setInterval(() => {
      const secondsLeft = this.getAttendanceTimeLeft();
      this.setState({ secondsLeft });
      if (this.attendanceTimer && secondsLeft < 1) return this.clearTimer();
    }, 1000);
  }
}
