import { h } from 'core';

import DiscussionWidget from 'acadly/discussion/DiscussionWidget';
import PollWidget from 'acadly/poll/PollWidget';
import QueryWidget from 'acadly/query/QueryWidget';
import QuizWidget from 'acadly/quiz/QuizWidget';
import ResourceWidget from 'acadly/resource/ResourceWidget';

export interface IActivityWidgetProps {
  activity: IClassActivity;
  cls: IClass;
  courseRole: ICourseRole;
  onclick?: (event: Event) => any;
  key?: string;
}
export default (props: IActivityWidgetProps) => {
  const activity = props.activity;
  return h(
    'div.class-activity-widget',
    {
      style: {
        height: '5em',
      },
      key: props.key,
      onclick: props.onclick,
    },
    [
      (() => {
        switch (activity.nodeType) {
          case 'quiz':
            return QuizWidget({
              quiz: activity,
              courseRole: props.courseRole,
            });
          case 'poll':
            return PollWidget({
              poll: activity,
              courseRole: props.courseRole,
            });
          case 'discussion':
            return DiscussionWidget({
              discussion: activity,
              courseRole: props.courseRole,
            });
          case 'resource':
            return ResourceWidget({
              resource: activity,
              cls: props.cls,
              courseRole: props.courseRole,
            });
          case 'query':
            return QueryWidget({
              query: activity,
              courseRole: props.courseRole,
            });
          default:
            return null;
        }
      })(),
    ]
  );
};
