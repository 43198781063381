interface Icons {
  [key: string]: string;
}

const icons: Icons = {
  home: String.fromCharCode(0xe900),
  user: String.fromCharCode(0xe901),
  settings: String.fromCharCode(0xe902),
  power: String.fromCharCode(0xe903),
  clock: String.fromCharCode(0xe904),
  clipboard: String.fromCharCode(0xe905),
  cube: String.fromCharCode(0xe906),
  info: String.fromCharCode(0xe907),
  analytics: String.fromCharCode(0xe908),
  comments: String.fromCharCode(0xe909),
  poll: String.fromCharCode(0xe90a),
  quiz: String.fromCharCode(0xe90b),
  resourceURL: String.fromCharCode(0xe90c),
  resourceFile: String.fromCharCode(0xe90d),
  resourceVideo: String.fromCharCode(0xe90e),
  discussion: String.fromCharCode(0xe90f),
  query: String.fromCharCode(0xe910),
  location: String.fromCharCode(0xe911),
  bellDark: String.fromCharCode(0xe912),
  bellLight: String.fromCharCode(0xe913),
  circleArrow: String.fromCharCode(0xe914),
  cross: String.fromCharCode(0xe915),
  plus: String.fromCharCode(0xe916),
  trash: String.fromCharCode(0xe917),
  plusFilled: String.fromCharCode(0xe918),
  eyeShut: String.fromCharCode(0xe919),
  eyeOpen: String.fromCharCode(0xe91a),
  clipboardPencil: String.fromCharCode(0xe91b),
  feedback: String.fromCharCode(0xe91c),
  file: String.fromCharCode(0xe91d),
  key: String.fromCharCode(0xe91e),
  files: String.fromCharCode(0xe91f),
  bold: String.fromCharCode(0xe920),
  italic: String.fromCharCode(0xe921),
  underline: String.fromCharCode(0xe922),
  image: String.fromCharCode(0xe923),
  formula: String.fromCharCode(0xe924),
  attachment: String.fromCharCode(0xe925),
  richText: String.fromCharCode(0xe926),
  square: String.fromCharCode(0xe927),
  squareFilled: String.fromCharCode(0xe928),
  commentsExpand: String.fromCharCode(0xe929),
  commentsContract: String.fromCharCode(0xe92a),
  plusOutline: String.fromCharCode(0xe92b),
  commentSend: String.fromCharCode(0xe92c),
  notification: String.fromCharCode(0xe92d),
  mobile: String.fromCharCode(0xe92e),
  email: String.fromCharCode(0xe92f),
  archive: String.fromCharCode(0xe930),
  filter: String.fromCharCode(0xe931),
  resourceText: String.fromCharCode(0xe932),
  arrowRight: String.fromCharCode(0xe933),
  arrowUp: String.fromCharCode(0xe934),
  arrowDown: String.fromCharCode(0xe935),
  locationCheck: String.fromCharCode(0xe936),
  eyeShut1: String.fromCharCode(0xe937),
  approve: String.fromCharCode(0xe938),
  lock: String.fromCharCode(0xe939),
  calendar: String.fromCharCode(0xe93a),
  download: String.fromCharCode(0xe93b),
  publish: String.fromCharCode(0xe93c),
  upload: String.fromCodePoint(0xe93c),
  help: String.fromCharCode(0xe93d),
  pencil: String.fromCharCode(0xe93e),
  floppy: String.fromCharCode(0xe93f),
  copy: String.fromCharCode(0xe944),
  move: String.fromCharCode(0xe945),
  export: String.fromCharCode(0xe946),
  export2: String.fromCharCode(0xe947),
  subscribe: String.fromCharCode(0xe948),
  unsubscribe: String.fromCharCode(0xe949),
  name: String.fromCharCode(0xe94a),
  share: String.fromCharCode(0xe94b),
  done: String.fromCharCode(0xe940),
};
export default icons;
