import { h, IComponent } from 'core';

import { Actions as AppActions } from 'acadly/app/actions';
import { cancelFacultyAccountVerification } from 'acadly/app/api';
import NewFeaturesDialog from 'acadly/app/NewFeaturesDialog';
import appService from 'acadly/app/service';
import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import FloatingActionButton from 'acadly/common/FloatingActionButton';
import FloatingMenu from 'acadly/common/FloatingMenu';
import Icon from 'acadly/common/Icon';
import RaisedButton from 'acadly/common/RaisedButton';
import TextField from 'acadly/common/TextField';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import User from 'acadly/common/User';
import { Actions as CourseActions } from 'acadly/course/actions';
import { Actions as GetInActions } from 'acadly/getin/actions';
import * as getInAPI from 'acadly/getin/api';
import icons from 'acadly/icons';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { getHeaderHeight, mb, ml, mr, mt, pad, style } from 'acadly/styles';
import * as u from 'acadly/utils';
import { CreateCoursePermission } from 'acadly/utils/constants';
import UserName from 'acadly/utils/UserName';

import CourseWidget from '../CourseWidget';
import CreateCourseScreen from '../CreateCourseScreen';
import PreCreateCourse from '../PreCreateCourse';
import CreateDemoCourseDialog, { DemoCourseType } from './CreateDemoCourseDialog';
import FeedbackForm from './FeedbackForm';
import * as css from './styles';

export default function () {
  return h(Home);
}

interface IHomeState {
  isFloatingMenuOpen: boolean;
  isPreCreateCourseDialogOpen: boolean;
  isCreateCourseDialogOpen: boolean;
  isCreatingProCourse: boolean;
  isJoinCourseDialogOpen: boolean;
  isJoinCourseConfirmationDialogOpen: boolean;
  isCourseNotPublishedDialogOpen: boolean;
  currentCourseId?: string;
  joinCode: string;
  joinCodeError: string | null;
  courseInfo: {
    code: string;
    title: string;
    university: string;
    admin: string;
  };
  isNewFeatureDialogOpen: boolean;
  isVerificationPendingAlertOpen: boolean;
  createDemoCourseType: DemoCourseType;
  isCreateDemoCourseDialogOpen: boolean;
  isCreateLinkedCourseAlertOpen: boolean;
  canShowFeedbackForm: boolean;
}

export class Home extends IComponent<never, IHomeState> {
  private lastFocusedElement: HTMLElement | null = null;

  private initializeState() {
    const initialState: IHomeState = {
      isFloatingMenuOpen: false,
      isPreCreateCourseDialogOpen: false,
      isCreateCourseDialogOpen: false,
      isCreatingProCourse: false,
      isJoinCourseDialogOpen: false,
      isJoinCourseConfirmationDialogOpen: false,
      isCourseNotPublishedDialogOpen: false,
      currentCourseId: undefined,
      joinCode: '',
      joinCodeError: null,
      courseInfo: {
        code: '',
        title: '',
        university: '',
        admin: '',
      },
      isNewFeatureDialogOpen: false,
      isVerificationPendingAlertOpen: false,
      createDemoCourseType: DemoCourseType.BASIC,
      isCreateDemoCourseDialogOpen: false,
      isCreateLinkedCourseAlertOpen: false,
      canShowFeedbackForm: true,
    };
    this.setState(initialState);
  }

  public componentDidMount() {
    const firstFocus = document.getElementById('home');
    if (firstFocus) {
      firstFocus.focus();
    }
  }

  private get isAccessible() {
    return getStore().getState().app.acc.web.turnOff === 0;
  }

  public componentWillMount() {
    this.initializeState();
  }

  private archivedCoursesTip =
    'Click here to access the Archived Courses in which you ' +
    'were an Admin or an Instructor. You can copy activities from' +
    ' Archived courses to your Current Courses easily';
  private addCourseTip =
    `To begin, you can create a course by tapping the "Add" button. If ` +
    `you're trying Acadly for the first time it is advisable that you ` +
    ` create a demo course. Courses can be deleted any time through "Settings"`;

  private hasArchivedCourses() {
    const myCourses = getStore().getState().courses.courses;
    return u.objectValues(myCourses).filter((c) => c.isArchived).length > 0;
  }

  public render() {
    const state = getStore().getState();
    const myCourses = state.courses.courses;
    const userData = state.courses.userData;
    const session = state.getIn.session!;
    const referralsAllowed = state.app.referralsAllowed;
    const isMobile = state.app.isMobile;
    const { hasEnterpriseAccount } = state.app;

    const hasArchivedCourses = this.hasArchivedCourses();

    const hasNewFeatureUpdates =
      state.app.newFeaturesLastUpdatedAt > state.app.newFeaturesSeenAt &&
      Object.keys(state.app.newFeaturesAvailable).length;

    const {
      isFloatingMenuOpen,
      isPreCreateCourseDialogOpen,
      isCreateCourseDialogOpen,
      isCreatingProCourse,
      currentCourseId,
    } = this.getState();

    return h('main.wrapper', { style: css.expanded }, [
      PreCreateCourse({
        open: isPreCreateCourseDialogOpen,
        onClose: (data) => {
          if (!data?.courseType) {
            this.setState({ isPreCreateCourseDialogOpen: false });
            return;
          }
          this.setState({
            isPreCreateCourseDialogOpen: false,
            isCreateCourseDialogOpen: true,
            isCreatingProCourse: data.courseType === 'pro',
          });
        },
      }),
      CreateCourseScreen({
        open: isCreateCourseDialogOpen,
        isProCourse: isCreatingProCourse,
        close: () => this.closeCreateCourseDialog(),
        courseId: currentCourseId,
      }),
      h(
        'main.home#home.no-focus',
        {
          style: css.homeNoFocus,
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          hasNewFeatureUpdates
            ? h(
                'span.ribbon',
                style(
                  ['fixed'],
                  {
                    right: state.app.narrowScreen ? '10px' : '385px',
                    top: `calc(${getHeaderHeight()} - 0.375rem)`,
                    zIndex: 4,
                  },
                  {
                    onclick: () => {
                      dispatch(AppActions.updateNewFeaturesLastAccesed());
                      this.setState({
                        isNewFeatureDialogOpen: true,
                      });
                    },
                  }
                ),
                [h('span', `New Updates`)]
              )
            : null,
          !isMobile && referralsAllowed
            ? h(
                'div.home__referral',
                {
                  onclick: () => Routes.refer.navigate({}),
                },
                'If you like Acadly, click here to help us grow'
              )
            : null,
          User({
            classNames: ['home__user-widget'],
            title:
              session.canCreateCourses === CreateCoursePermission.PROVISIONAL
                ? h('span', [
                    `${UserName.trancate(session.name)}'s Courses`,
                    h('span.tag.orange', 'TRIAL ACCOUNT'),
                  ])
                : `${UserName.trancate(session.name)}'s Courses`,
            titleClassNames: ['home__course-header'],
            subtitle: session.university.name,
            subtitleClassNames: ['fc-light-grey'],
            avatar: {
              url: session.avatar,
              creator: session.name,
            },
          }),
          session.canCreateCourses !== CreateCoursePermission.PROVISIONAL
            ? FloatingActionButton(
                {
                  id: 'create-courses-button',
                  position: 'bottom-right',
                  ariaLabel: 'Add courses Button',
                  tabIndex: this.isAccessible ? 0 : undefined,
                  style: { zIndex: 1 },
                  onclick: async () => await this.toggleFloatingMenu(),
                },
                [
                  Icon(icons.plus, {
                    className: `home__fab-icon ${isFloatingMenuOpen ? 'open' : ''}`,
                  }),
                ]
              )
            : null,
          this.canCreateCourse()
            ? TipOverlayWrapper({
                tipKey: 'courseHomeFloatingButton',
                targetElement: 'create-courses-button',
                tip: {
                  tipPosition: 'left',
                  tipText: this.addCourseTip,
                },
                isNextAvailable: false,
              })
            : null,
          session.canCreateCourses === CreateCoursePermission.PROVISIONAL
            ? h('div.home__provisional-warning', style([mb('1rem')]), [
                h('div.home__provisional-warning__text', [
                  'We are in the process of verifying you as a faculty ',
                  `member at ${session.university.name}`,
                ]),
                FlatButton('MORE', {
                  type: 'primary',
                  tabIndex: 0,
                  labelStyle: { color: '#fff' },
                  className: 'home__provisional-warning__more',
                  onclick: () => {
                    this.saveLastFocusElement();
                    this.setState({
                      isVerificationPendingAlertOpen: true,
                    });
                  },
                }),
                this.verificationPendingAlert(),
              ])
            : null,
          hasArchivedCourses
            ? TipOverlayWrapper({
                tipKey: 'courseHomeArchive',
                targetElement: 'archived-courses-button',
                tip: {
                  tipText: this.archivedCoursesTip,
                  tipPosition: 'bottom',
                },
                isNextAvailable: false,
              })
            : null,
          hasArchivedCourses
            ? h('div.home__archived-button-container', [
                RaisedButton('VIEW ARCHIVED COURSES', {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  id: 'archived-courses-button',
                  classNames: ['home__raised-button'],
                  ariaLabel: 'View Archived Courses',
                  onclick: () =>
                    Routes.archivedCourses.navigate({
                      univSlug: appService.getUniversitySlug(),
                    }),
                }),
              ])
            : null,
          h('div.home__course-list-container', [
            ...u
              .objectValues(myCourses)
              .filter((c) => !c.isArchived)
              .map((course) =>
                CourseWidget({
                  course: course,
                  userData: userData[course._id],
                  onShowCreateCourseDialog: () => {
                    this.setState({ currentCourseId: course._id });
                    this.createCourseClickHandler(course.isPro === 1);
                  },
                  onShowCourseNotPublishedDialog: () => {
                    this.setState({
                      isCourseNotPublishedDialogOpen: true,
                    });
                  },
                })
              ),
          ]),

          Object.keys(myCourses).length === 0 ? this.emptyCourseView() : null,

          this.demoCourseWidgets(),

          FloatingMenu(
            {
              isOpen: isFloatingMenuOpen,
              toggleHandler: async () => await this.toggleFloatingMenu(),
            },
            this.canCreateCourse()
              ? [
                  {
                    label: 'Create a new course',
                    onClick: async () => {
                      if (hasEnterpriseAccount) {
                        this.showCreateLinkedCourseAlert();
                      } else {
                        this.setState({
                          isPreCreateCourseDialogOpen: true,
                        });
                      }
                    },
                  },
                  {
                    label: 'Join an existing course',
                    onClick: async () => await this.showJoinCourseDialog(),
                  },
                ]
              : [
                  {
                    label: 'Join an existing course',
                    onClick: async () => await this.showJoinCourseDialog(),
                  },
                ]
          ),
        ]
      ),
      this.joinCourseDialog(),
      this.joinCourseConfirmationDialog(),
      this.courseNotPublishedDialog(),
      this.newFeatureUpdateDialog(),
      this.createLinkedCourseAlert(),
      this.feedbackForm(),
    ]);
  }

  private hideFeedbackForm = () => {
    this.setState({
      canShowFeedbackForm: false,
    });
  };

  private feedbackForm() {
    const { canShowFeedbackForm } = this.getState();

    return FeedbackForm({
      open: canShowFeedbackForm,
      onClose: this.hideFeedbackForm,
    });
  }

  private saveLastFocusElement() {
    this.lastFocusedElement = document.activeElement as HTMLElement;
    u.unsetTabIndices();
  }

  private focusLastActiveElement() {
    u.resetTabIndices();
    if (this.lastFocusedElement) {
      this.lastFocusedElement.focus();
    }
  }

  private closeVerificationPendingAlert = () => {
    this.setState({ isVerificationPendingAlertOpen: false });
    this.focusLastActiveElement();
  };

  private cancelAccountVerification = async () => {
    await cancelFacultyAccountVerification();
    dispatch(GetInActions.changeCourseCreationPermission(CreateCoursePermission.NOT_ALLOWED));
    dispatch(CourseActions.fetchMyCourses());
    this.closeVerificationPendingAlert();
  };

  private verificationPendingAlert() {
    const { isVerificationPendingAlertOpen } = this.getState();

    return Alert(
      {
        isAccessible: this.isAccessible,
        open: isVerificationPendingAlertOpen,
        title: 'Verification Pending',
        style: {
          width: '30em',
        },
        actions: [
          FlatButton('BACK', {
            tabIndex: 0,
            type: 'secondary',
            onclick: this.closeVerificationPendingAlert,
          }),
          FlatButton('I’M A STUDENT', {
            tabIndex: 0,
            type: 'primary',
            onclick: this.cancelAccountVerification,
          }),
        ],
      },
      [
        h('p', [
          `Since Acadly deals with student data, to meet with the data
                security compliance requirements, we ensure that every instructor
                account actually belongs to a university faculty member, or a
                university staff, or a TA.`,
        ]),
        h('p', [
          `This verification process takes at most 24 hours and till then, users
                are provided with a trial account to try Acadly out with demo courses.`,
        ]),
        h('p', [
          `In case you’re a student who has accidentally signed up for an
                instructor account, please click on “I’m a student” button. We’ll
                remove you from the verification list and you’ll be able to continue
                using Acadly as a student.`,
        ]),
      ]
    );
  }

  private showCreateDemoCourseDialog = (courseType: DemoCourseType) => () => {
    this.saveLastFocusElement();
    this.setState({
      createDemoCourseType: courseType,
      isCreateDemoCourseDialogOpen: true,
    });
  };

  private closeCreateDemoCourseDialog = () => {
    this.setState({ isCreateDemoCourseDialogOpen: false });
    this.focusLastActiveElement();
  };

  private demoCourseWidgets() {
    const session = getStore().getState().getIn.session!;
    const myCourses = getStore().getState().courses.courses;

    const { isCreateDemoCourseDialogOpen, createDemoCourseType } = this.getState();

    if (session.canCreateCourses !== CreateCoursePermission.PROVISIONAL) return null;

    const hasProCourse = Boolean(
      u.objectValues(myCourses).find((c) => Boolean(c.canStream && c.isDemo))
    );

    const hasBasicCourse = Boolean(
      u.objectValues(myCourses).find((c) => Boolean(!c.canStream && c.isDemo))
    );

    if (hasProCourse && hasBasicCourse) return null;

    return h('div', style(['fullWidth', pad('0 0.75rem')]), [
      h(
        'div',
        style(['bold', 'large', 'textCenter', mb('1rem')]),
        'Create a demo course, take Acadly for a spin'
      ),
      !hasBasicCourse
        ? h('div.demo-course-widget', [
            h('div.demo-course-widget__title', 'Acadly Basic Course'),
            h('div.demo-course-widget__section-header', 'Best for'),
            h('ul.demo-course-widget__list', [h('li', 'In-person classrooms')]),
            h('div.demo-course-widget__section-header', 'Features'),
            h('ul.demo-course-widget__list', [
              h('li', 'Automate classroom attendance'),
              h('li', 'Share live polls, quizzes, discussions'),
              h('li', 'Collaborate with co-instructors and TAs'),
              h('li', 'Export data in CSV filess'),
            ]),
            h(
              'button.ripple.demo-course-widget__create',
              { onclick: this.showCreateDemoCourseDialog(DemoCourseType.BASIC) },
              'CREATE BASIC DEMO COURSE'
            ),
          ])
        : null,
      !hasProCourse
        ? h('div.demo-course-widget', [
            h('div.demo-course-widget__title', 'Acadly Pro Course - with Zoom integration'),
            h('div.demo-course-widget__section-header', 'Best for'),
            h('ul.demo-course-widget__list', [h('li', 'Remote classrooms')]),
            h('div.demo-course-widget__section-header', 'Features'),
            h('ul.demo-course-widget__list', [
              h('li', 'Automate remote attendance'),
              h('li', 'Breakout rooms'),
              h('li', 'LMS importable CSV exports'),
              h('li', 'Every Basic course feature'),
            ]),
            h(
              'button.ripple.demo-course-widget__create',
              { onclick: this.showCreateDemoCourseDialog(DemoCourseType.PRO) },
              'CREATE ACADLY PRO DEMO COURSE'
            ),
          ])
        : null,
      CreateDemoCourseDialog({
        open: isCreateDemoCourseDialogOpen,
        courseType: createDemoCourseType,
        onClose: this.closeCreateDemoCourseDialog,
      }),
    ]);
  }

  private emptyCourseView() {
    const session = getStore().getState().getIn.session!;

    switch (session.canCreateCourses) {
      case CreateCoursePermission.NOT_ALLOWED:
        return h(
          'div',
          style([
            'large',
            'textCenter',
            mt('0.5em'),
            mb('1.5em'),
            ml('1em'),
            mr('1em'),
            pad('1em'),
            'mainPanel',
            'grey',
            {
              flexShrink: '0',
            },
          ]),
          [
            "You don't have any live courses yet. ",
            this.hasArchivedCourses()
              ? h('p', 'You can view your previously archived courses.')
              : null,
          ]
        );
      case CreateCoursePermission.ALLOWED:
        return h(
          'div',
          style([
            'large',
            'textCenter',
            mt('0.5em'),
            mb('1.5em'),
            ml('1em'),
            mr('1em'),
            pad('1em'),
            'mainPanel',
            'lightGrey',
            {
              flexShrink: '0',
            },
          ]),
          [
            "You don't have any live courses yet. Tap on the ",
            h('i.fa.fa-plus-circle', style(['blue', 'large'])),
            ' button at the bottom right to create a course.',
            this.hasArchivedCourses()
              ? h('p', 'You can view your previously archived courses.')
              : null,
          ]
        );
      default:
        return null;
    }
  }

  private newFeatureUpdateDialog() {
    const appState = getStore().getState().app;
    if (!appState.newFeaturesAvailable) return null;
    const features = Object.keys(appState.newFeaturesAvailable)
      .map((key) => {
        return {
          ...appState.newFeaturesAvailable[key],
          key,
        };
      })
      .sort((f) => -f.releasedOn);

    return NewFeaturesDialog({
      features,
      title: 'While you were away',
      open: this.getState().isNewFeatureDialogOpen,
      onClose: () => {
        this.setState({
          isNewFeatureDialogOpen: false,
        });
      },
    });
  }

  private showCreateLinkedCourseAlert = () => {
    this.saveLastFocusElement();
    this.setState({
      isCreateLinkedCourseAlertOpen: true,
    });
  };

  private hideCreateLinkedCourseAlert = () => {
    this.setState({
      isCreateLinkedCourseAlertOpen: false,
      isPreCreateCourseDialogOpen: true,
    });
    this.focusLastActiveElement();
  };

  private createLinkedCourseAlert() {
    const storeState = getStore().getState();
    const { integratedWith, hasEnterpriseAccount } = storeState.app;
    const { university } = storeState.getIn.session!;
    const { isCreateLinkedCourseAlertOpen } = this.getState();

    if (!hasEnterpriseAccount) return null;

    return Alert(
      {
        isAccessible: this.isAccessible,
        open: isCreateLinkedCourseAlertOpen,
        title: 'Information',
        titleClassName: 'fc-orange',
        style: {
          width: '30em',
          lineHeight: '1.5',
        },
        actions: [
          FlatButton('CANCEL', {
            tabIndex: 0,
            type: 'primary',
            onclick: this.hideCreateLinkedCourseAlert,
          }),
          FlatButton('CONTINUE', {
            tabIndex: 0,
            type: 'secondary',
            onclick: () => {
              this.hideCreateLinkedCourseAlert();
            },
          }),
        ],
      },
      [
        h('p', [
          `${university.name} is an Enterprise customer of Acadly, which means you can `,
          `import your course from ${integratedWith} using the Acadly LTI Plugin.`,
        ]),
        h('div', 'Courses created using the Acadly LTI Plugin allow'),
        h('ul', [
          h('li', 'Enrollment syncing'),
          h('li', `Exporting grades from Acadly to ${integratedWith}`),
          h('li', [
            'Access to all the features available with Acadly Pro, ',
            'including Zoom integration for remote lectures',
          ]),
        ]),
        h('div', [
          'This was for your information. If you want to create a course that is not ',
          'linked, please click on “Continue”',
        ]),
      ]
    );
  }

  private joinCourseDialog() {
    const joinCode = this.getState().joinCode;
    const joinCodeError = this.getState().joinCodeError;

    const hasError = joinCodeError != null;
    const getError = () => {
      if (joinCodeError) return [h('span', style(['red']), joinCodeError)];
      return 'Some error occured!';
    };

    return Alert(
      {
        open: this.getState().isJoinCourseDialogOpen,
        style: {
          width: '30em',
        },
        actions: [
          FlatButton('CANCEL', {
            type: 'secondary',
            onclick: () => this.closeJoinCourseDialog(),
          }),
          FlatButton('CONTINUE', {
            onclick: () => this.handleJoinCodeSubmit(),
          }),
        ],
      },
      [
        h('div.join-code-form', [
          h('div', style(['orange', 'x-large', mb('1em')]), 'Enter Join Code'),
          h(
            'div',
            style(['darkBlue', 'large', mb('0.5em')]),
            `Enter the course Join Code to enrol in the course as a student:`
          ),
          TextField({
            hasError,
            value: joinCode,
            focusOnMount: true,
            center: true,
            placeholder: '6 character Join Code',
            floatingLabelText: 'Course Join Code',
            oninput: (event) =>
              this.setState({
                joinCode: event.target.value,
                joinCodeError: null,
              }),
            onenter: async () => await this.handleJoinCodeSubmit(),
          }),
          h(
            'div',
            style([mt('1em')], {
              visibility: hasError ? 'visible' : 'hidden',
            }),
            getError()
          ),
        ]),
      ]
    );
  }

  private joinCourseConfirmationDialog() {
    const courseInfo = this.getState().courseInfo;
    return Alert(
      {
        open: this.getState().isJoinCourseConfirmationDialogOpen,
        style: {
          width: '30em',
        },
        actions: [
          FlatButton('BACK', {
            type: 'secondary',
            onclick: () =>
              this.setState({
                isJoinCourseDialogOpen: true,
                isJoinCourseConfirmationDialogOpen: false,
              }),
          }),
          FlatButton('CONTINUE', {
            onclick: () => this.joinStudentToCourse(),
          }),
        ],
      },
      [
        h(
          'div',
          style(['orange', 'large', mb('2em')]),
          'You will be enroled as a student in the following course:'
        ),
        h('div', style(['bold', mb('0.5em')]), `${courseInfo.code}: ${courseInfo.title}`),
        h('div', style(['grey']), courseInfo.university),
        h('div', style(['grey']), courseInfo.admin),
        h('div', style([mt('1em')]), 'Please press continue to join this course'),
      ]
    );
  }

  private courseNotPublishedDialog() {
    return Alert(
      {
        open: this.getState().isCourseNotPublishedDialogOpen,
        style: {
          width: '30em',
        },
        title: h('div', style(['orange']), 'Course Not Published'),
        actions: [
          FlatButton('OKAY', {
            onclick: () =>
              this.setState({
                isCourseNotPublishedDialogOpen: false,
              }),
          }),
        ],
      },
      [
        h(
          'div',
          `
                This course has not been published yet. You will be notified
                via email once the course is published.
            `
        ),
      ]
    );
  }

  private canCreateCourse(): boolean {
    return getStore().getState().getIn.session!.canCreateCourses === CreateCoursePermission.ALLOWED;
  }

  private async toggleFloatingMenu() {
    const isMenuOpen = this.getState().isFloatingMenuOpen;
    await this.setState({
      isFloatingMenuOpen: !isMenuOpen,
    });
    if (this.isAccessible) {
      const createCourebtn = document.getElementById('create-courses-button');
      if (!isMenuOpen) {
        u.unsetTabIndices();
        const elem = document.getElementById('item-container');
        elem &&
          elem
            .querySelectorAll('div')
            .forEach((n) => (elem.contains(n) ? n.setAttribute('tabIndex', '1') : null));
        const firstChild = elem && elem.firstChild;
        if (firstChild) {
          (firstChild as any).focus();
        }
      } else {
        u.resetTabIndices();
        createCourebtn ? createCourebtn.focus() : null;
      }
    }
  }

  private createCourseClickHandler(isCreatingProCourse: boolean) {
    setTimeout(() => {
      u.unsetTabIndices();
      this.setState({
        isCreatingProCourse,
        isCreateCourseDialogOpen: true,
      });
    }, 0);
  }

  private closeCreateCourseDialog() {
    this.setState({
      isCreateCourseDialogOpen: false,
      isFloatingMenuOpen: false,
      currentCourseId: undefined,
    });
    u.resetTabIndices();
    const createCourebtn = document.getElementById('create-courses-button');
    createCourebtn ? createCourebtn.focus() : null;
  }

  private showJoinCourseDialog() {
    this.setState({
      joinCode: '',
      joinCodeError: null,
      isJoinCourseDialogOpen: true,
    });
  }

  private closeJoinCourseDialog() {
    this.setState({
      isJoinCourseDialogOpen: false,
    });
  }

  private async handleJoinCodeSubmit() {
    const joinCode = this.getState().joinCode;

    if (joinCode.length !== 6) {
      return await this.setState({
        joinCodeError: 'Invalid Join Code',
      });
    }

    const response = await getInAPI.getCourseByCode(joinCode);
    const { title, courseCode: code, universityName: university, admin } = response.data;
    return this.setState({
      isJoinCourseDialogOpen: false,
      isJoinCourseConfirmationDialogOpen: true,
      courseInfo: { code, title, university, admin: admin.name },
    });
  }

  private async joinStudentToCourse() {
    const joinCode = this.getState().joinCode;
    await dispatch(CourseActions.joinCourse(joinCode));
    return this.setState({
      isJoinCourseConfirmationDialogOpen: false,
    });
  }
}
