import { h } from 'core';

import { getHTMLTagSelector, padStart } from 'acadly/utils';

interface ISuggestedAssignmentWidgetProps {
  key?: string;
  isAccessible?: boolean;
  assignment: ISuggestedAssignment;
  onclick?: (event: Event) => any;
}

export default (props: ISuggestedAssignmentWidgetProps) => {
  const { assignment } = props;
  return h(
    getHTMLTagSelector('div', ['suggested-assignment-widget', assignment.used ? 'used' : '']),
    {
      key: props.key,
      tabIndex: props.isAccessible ? 0 : undefined,
      onclick: props.onclick,
    },
    [
      h('div.suggested-assignment-widget__header', [
        h('div', [
          h('span', `Assignment ${padStart(assignment.details.num.toString(), 2, '0')}`),
          assignment.used ? h(getHTMLTagSelector('span', ['tag', 'uppercase']), 'used') : null,
        ]),
      ]),
      h('div.suggested-assignment-widget__title', assignment.details.title || 'Untitled'),
      h(
        'div.suggested-assignment-widget__published-on',
        `Published on: ${assignment.details.publishedOnDate}`
      ),
    ]
  );
};
