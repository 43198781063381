import { jsonRequest } from 'core/http';

import { api } from 'acadly/api';

export interface IReferralSendRequest {
  referees: Array<string>;
}

export const sendReferral = (data: IReferralSendRequest) =>
  jsonRequest(api().refer, {
    method: 'POST',
    data: { ...data, source: 'settings', agent: 'web' },
  });
