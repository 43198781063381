import { h } from 'core';

import ContentView from 'acadly/common/ContentView';
import { getStore } from 'acadly/store';
import { style } from 'acadly/styles';
import * as u from 'acadly/utils';

import CourseWidget from './CourseWidget';

export default () => {
  const courses = u.objectValues(getStore().getState().courses.courses).filter((c) => c.isArchived);
  return ContentView(
    h(
      'div.archived-screen',
      style([
        {
          paddingTop: '1rem',
          margin: 'auto',
          paddingBottom: '1em',
          width: '30em',
          maxWidth: '95vw',
        },
      ]),
      courses.map((c) =>
        CourseWidget({
          course: c,
        })
      )
    )
  );
};
