import { h } from 'core';

import { ml, style } from 'acadly/styles';

export default (scheme: 'incentivizing' | 'penalizing' | 'neutral') => {
  switch (scheme) {
    case 'incentivizing':
      return h('div', style(['lightGrey']), [
        h('div', style([ml('0.5rem')]), '+1 for marking a correct option'),
        h('div', style([ml('0.5rem')]), '+1 for not marking an incorrect option'),
      ]);
    case 'penalizing':
      return h('div', style(['lightGrey']), [
        h('div', style([ml('0.5rem')]), '+4 for marking all correct options'),
        h('div', style([ml('0.5rem')]), '-1.25 for marking an incorrect option'),
      ]);
    case 'neutral':
      return h('div', style(['lightGrey']), [
        h('div', style([ml('0.5rem')]), '+4 for marking all correct options'),
        h('div', style([ml('0.5rem')]), '0 in any other case'),
      ]);
  }
};
