import { colors } from 'acadly/styles';

export const expanded = {
  height: '100%',
  width: '100%',
};

export const homeNoFocus = {
  boxSizing: 'borderBox',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  overflowY: 'scroll',
  marginLeft: 'auto',
  marginRight: 'auto',
};

export const coursesHeader = (isMobile: boolean) => {
  return {
    fontSize: isMobile ? undefined : '1.5em',
    fontWeight: isMobile ? 'bold' : undefined,
    flexShrink: '0',
    marginTop: '0.5em',
    marginLeft: isMobile ? '1em' : undefined,
    marginRight: isMobile ? 'auto' : undefined,
  };
};

export const universityName = (isMobile: boolean) => {
  return {
    color: colors.lightGrey,
    flexShrink: '0',
    marginRight: isMobile ? 'auto' : undefined,
    marginLeft: isMobile ? '1em' : undefined,
    marginBottom: '1em',
  };
};

export const referralsButton = {
  backgroundColor: colors.green,
  color: colors.white,
  padding: '1em 2em',
  width: '30em',
  maxWidth: '100%',
  textAlign: 'center',
  fontWeight: 600,
  letterSpacing: '0.25px',
  marginBottom: '40px',
  userSelect: 'none',
  msUserSelect: 'none',
  cursor: 'pointer',
};
