import 'rxjs/add/operator/debounceTime';

import cn from 'classnames';
import { Subject, Subscription } from 'rxjs';

import { CSS, h, HTMLAttrs, IComponent } from 'core';

import * as CaretIcon from 'assets/caret.svg';

import portal from 'acadly/common/Portal';
import SvgIcon from 'acadly/common/SvgIcon';
import { randomString } from 'acadly/utils';

export interface Option<T extends string = string> {
  id: T;
  title: string;
}

interface BaseProps<T extends string> extends HTMLAttrs {
  isOpen: boolean;
  isSearchable?: boolean;
  options: Option<T>[];
  hasError?: boolean;
  errorText?: string;
  placeholder?: string;
  leftAligned?: boolean;
  noFloatingLabel?: boolean;
  classes?: {
    select?: string;
    label?: string;
    optionContainer?: string;
  };
  onToogleDropDown: (isOpen: boolean) => any;
  /**
   * if searchable and onSearch() not provided
   * then search will be done on option.title
   */
  onSearch?: (searchText: string) => Option<T>[];
}

export enum SelectType {
  SINGLE = 'single',
  MULTI = 'multi',
}

interface SingleOptionProps<T extends string> extends BaseProps<T> {
  type: SelectType.SINGLE;
  value?: Option<T>;
  onChange: (value: Option<T>) => any;
}

interface MultiOptionProps<T extends string> extends BaseProps<T> {
  type: SelectType.MULTI;
  value?: Option<T>[];
  onChange: (value: Option<T>[]) => any;
}

type SelectProps<T extends string> = SingleOptionProps<T> | MultiOptionProps<T>;

type SelectOptionsProps<T extends string> = SelectProps<T> & {
  selectId: string;
  selectRef: HTMLDivElement | null;
};

interface SelectOptionsState<T extends string> {
  activeOptionId: string | undefined;
  lastSelectedOptionId: string | undefined;
  searchText: string;
  options: Option<T>[];
  optionMap: ObjectMap<
    Option<T> & {
      order: number;
      isSelected: boolean;
    }
  >;
}

enum Attachment {
  ABOVE,
  BELOW,
}

@portal(document.getElementById('dialog-container'))
class SelectOptionsC<T extends string> extends IComponent<
  SelectOptionsProps<T>,
  SelectOptionsState<T>
> {
  private optionContainerRef: HTMLUListElement | null = null;

  public static MAX_HEIGHT = 320;

  public static getOptionContainerId(selectId: string) {
    return `option-container-${selectId}`;
  }

  public static getOptionId(selectId: string, optionId: string) {
    return `option-${selectId}-${optionId}`;
  }

  private getAttachmentPosition(): Attachment {
    const { isOpen, selectRef } = this.getProps();

    if (!isOpen) return Attachment.BELOW;

    const { height, top } = selectRef.getBoundingClientRect();
    const windowHeight = document.documentElement.clientHeight;
    const bottomSpace = windowHeight - top - height;

    /**
     * First check if dropdown can be attached in bottom
     * if not then find larger space to render dropdown
     */
    if (bottomSpace > SelectOptionsC.MAX_HEIGHT || bottomSpace > top) {
      return Attachment.BELOW;
    }

    return Attachment.ABOVE;
  }

  private getOptionContainerStyle(): CSS {
    const { isOpen, selectRef } = this.getProps();

    if (!isOpen || !selectRef) {
      return {
        display: 'none',
      };
    }

    const attachment = this.getAttachmentPosition();
    const { width, height, top, left } = selectRef.getBoundingClientRect();
    const windowHeight = document.documentElement.clientHeight;
    const bottomSpace = windowHeight - top - height;

    const styles: CSS = {
      display: 'block',
      width: width,
      left: left,
    };

    if (attachment === Attachment.BELOW) {
      styles.top = top;
      styles.transformOrigin = 'top center';
      styles.maxHeight = Math.min(SelectOptionsC.MAX_HEIGHT, bottomSpace);
    } else {
      styles.bottom = bottomSpace;
      styles.transformOrigin = 'bottom center';
      styles.maxHeight = Math.min(SelectOptionsC.MAX_HEIGHT, top);
    }

    return styles;
  }

  private updateDimensions = () => {
    let ticking = false;
    const { isOpen } = this.getProps();

    if (!isOpen || !this.optionContainerRef) return;
    const optionContainerRef = this.optionContainerRef;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        // update select option-container co-ordinates and size
        const style = this.getOptionContainerStyle();

        optionContainerRef.style.top = style.top ? `${style.top}px` : '';
        optionContainerRef.style.bottom = style.bottom ? `${style.bottom}px` : '';
        optionContainerRef.style.transformOrigin = style.transformOrigin
          ? style.transformOrigin.toString()
          : '';
        optionContainerRef.style.width = style.width ? `${style.width}px` : '';
        optionContainerRef.style.left = style.left ? `${style.left}px` : '';

        ticking = false;
      });
      ticking = true;
    }
  };

  private onKeyDown = (e: KeyboardEvent) => {
    const { isOpen, isSearchable, type, value, onToogleDropDown } = this.getProps();
    const { options } = this.getState();

    if (!isOpen || e.defaultPrevented) {
      return; // Do nothing if event already handled
    }

    let handled = false;
    const { optionMap, activeOptionId } = this.getState();

    switch (e.code) {
      case 'ArrowDown':
        // Moves focus to the next option
        if (!activeOptionId) {
          const firstOption = options[0];
          this.onActiveOptionChange(firstOption.id);
        } else {
          const index = options.findIndex((o) => o.id === activeOptionId);
          const nextOption = options[Math.min(index + 1, options.length - 1)];
          this.onActiveOptionChange(nextOption ? nextOption.id : activeOptionId);
        }
        handled = true;
        break;
      case 'ArrowUp':
        // Moves focus to the previous option
        if (!activeOptionId) {
          const lastOption = options[options.length - 1];
          this.onActiveOptionChange(lastOption.id);
        } else {
          const index = options.findIndex((o) => o.id === activeOptionId);
          const prevOption = options[Math.max(index - 1, 0)];
          this.onActiveOptionChange(prevOption ? prevOption.id : activeOptionId);
        }
        handled = true;
        break;
      case 'Space':
      case 'Enter':
        // changes the selection state of the focused option
        if (activeOptionId) {
          this.onOptionClick(optionMap[activeOptionId]);
        }
        handled = true;
        break;
      case 'Tab':
        if (isSearchable) break;
      case 'Escape':
        // closes dropdown
        onToogleDropDown(false);
        if (type === SelectType.MULTI) {
          this.onActiveOptionChange(undefined);
        } else if (value) {
          this.onActiveOptionChange((value as Option).id);
        }
        handled = true;
        break;
    }

    if (handled) {
      // Consume the event so it doesn't get handled twice
      e.preventDefault();
    }
  };

  private getOptionMap(options: Option<T>[]): SelectOptionsState<T>['optionMap'] {
    return options.reduce<SelectOptionsState<T>['optionMap']>((result, option, i) => {
      result[option.id] = { ...option, order: i, isSelected: false };
      return result;
    }, {});
  }

  private addEventListeners() {
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('orientationchange', this.updateDimensions);
    window.addEventListener('scroll', this.updateDimensions);
    window.addEventListener('keydown', this.onKeyDown);
  }

  private removeEventListeners() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('orientationchange', this.updateDimensions);
    window.removeEventListener('scroll', this.updateDimensions);
    window.removeEventListener('keydown', this.onKeyDown);
  }

  private searchText$ = new Subject<string>();
  private searchTextSub: Subscription;

  public componentWillMount() {
    const props = this.getProps();

    const initialState: SelectOptionsState<T> = {
      searchText: '',
      options: props.options,
      optionMap: this.getOptionMap(props.options),
      lastSelectedOptionId: undefined,
      activeOptionId: undefined,
    };

    this.setState(initialState);

    this.searchTextSub = this.searchText$.debounceTime(100).subscribe((searchText) => {
      const { onSearch, options } = this.getProps();

      if (onSearch) {
        this.setState({
          options: onSearch(searchText),
        });
        return;
      }

      // overwrite for case insensitive search
      searchText = searchText.toLowerCase();

      this.setState({
        options: options.filter((option) => {
          const title = option.title.toLowerCase();
          return title.includes(searchText);
        }),
      });

      this.dockSearchBar();
    });
  }

  public componentWillUnmount() {
    this.searchTextSub.unsubscribe();
  }

  private serializeOptions(props: SelectOptionsProps<T>) {
    return props.options.map((v) => v.id).join('');
  }

  public componentWillReceiveProps(nextProps: SelectOptionsProps<T>) {
    const props = this.getProps();
    const oldValue = this.serializeOptions(props);
    const newValue = this.serializeOptions(nextProps);

    if (oldValue !== newValue) {
      this.setState({
        options: nextProps.options,
        optionMap: this.getOptionMap(nextProps.options),
      });
    }
  }

  public componentDidUpdate(prevProps: SelectOptionsProps<T>) {
    const props = this.getProps();

    if (!prevProps.isOpen && props.isOpen) {
      // opening
      this.addEventListeners();

      const optionContainer = document.getElementById(
        SelectOptionsC.getOptionContainerId(props.selectId)
      );

      if (optionContainer) {
        optionContainer.focus();
      }

      if (props.isSearchable) {
        this.dockSearchBar();
      } else if (props.type === SelectType.SINGLE && props.value) {
        this.scrollOptionIntoView(props.value.id);
      }
    } else if (prevProps.isOpen && !props.isOpen) {
      // closing
      this.removeEventListeners();

      const selectToggleButton = document.getElementById(Select.getSelectButtonId(props.selectId));

      if (selectToggleButton) {
        selectToggleButton.focus();
      }
    }
  }

  private isOptionSelected(option: Option) {
    const op = this.getState().optionMap[option.id];
    return op ? op.isSelected : false;
  }

  private handleSearch = (searchText: string) => {
    this.setState({ searchText });
    this.searchText$.next(searchText);
  };

  public render() {
    const { selectId, isOpen, isSearchable, type, onToogleDropDown, classes } = this.getProps();
    const { activeOptionId, searchText, options } = this.getState();

    const OPTION_CONTAINER_ID = SelectOptionsC.getOptionContainerId(selectId);

    if (!isOpen) return null;

    let body = options.map((o) => {
      const isSelected = this.isOptionSelected(o);
      const classNames = ['select__option'];
      let ariaSelected: boolean | undefined = undefined;

      if (isSelected) {
        classNames.push('select__option--selected');
      }

      if (activeOptionId === o.id) {
        classNames.push('select__option--active');
      }

      if (type === SelectType.MULTI) {
        ariaSelected = isSelected;
      } else if (isSelected) {
        // for single select set this attibute for selected option only
        ariaSelected = true;
      }

      return h(
        'li',
        {
          role: 'option',
          id: SelectOptionsC.getOptionId(selectId, o.id),
          key: SelectOptionsC.getOptionId(selectId, o.id),
          'aria-selected': ariaSelected,
          className: classNames.join(' '),
          onclick: () => this.onOptionClick(o),
        },
        o.title
      );
    });

    const searchInput = h('input.select__search', {
      key: `${OPTION_CONTAINER_ID}-search`,
      value: searchText,
      oninput: (e: any) => this.handleSearch(e.target.value),
      placeholder: 'Search',
      'aria-autocomplete': 'list',
      'aria-owns': OPTION_CONTAINER_ID,
      'aria-activedescendant': activeOptionId
        ? SelectOptionsC.getOptionId(selectId, activeOptionId)
        : undefined,
    });

    const attachment = this.getAttachmentPosition();

    if (isSearchable && attachment === Attachment.ABOVE) {
      body = [...body, searchInput];
    } else if (isSearchable) {
      body = [searchInput, ...body];
    }

    return h('div.select__options-overlay', { role: 'presentation' }, [
      h('div.select__backdrop', {
        'aria-hidden': true,
        onclick: () => onToogleDropDown(false),
      }),
      h(
        'ul.select__option-container',
        {
          id: OPTION_CONTAINER_ID,
          key: OPTION_CONTAINER_ID,
          tabIndex: -1,
          role: 'combobox',
          'aria-expanded': isOpen,
          'aria-multiselectable': type === SelectType.MULTI,
          'aria-activedescendant': activeOptionId
            ? SelectOptionsC.getOptionId(selectId, activeOptionId)
            : undefined,
          ref: (e) => {
            this.optionContainerRef = e as HTMLUListElement;
          },
          style: this.getOptionContainerStyle(),
          className: classes && classes.optionContainer,
        },
        body
      ),
    ]);
  }

  private getValue(): Option<T> {
    const { optionMap } = this.getState();

    const selectedOptionId = Object.keys(optionMap).find((id) => optionMap[id].isSelected);

    if (!selectedOptionId) {
      console.warn(`selectedOptionId: ${selectedOptionId} not found`);
    }

    const selectedOption = optionMap[selectedOptionId!];

    return {
      id: selectedOption.id,
      title: selectedOption.title,
    };
  }

  private getValues(): Option<T>[] {
    const { optionMap } = this.getState();

    return Object.keys(optionMap)
      .filter((id) => optionMap[id].isSelected)
      .sort((a, b) => optionMap[a].order - optionMap[b].order)
      .map((id) => {
        const option = optionMap[id];
        return {
          id: option.id,
          title: option.title,
        };
      });
  }

  private async onOptionClick(option: Option) {
    const props = this.getProps();
    const { optionMap, lastSelectedOptionId } = this.getState();

    if (props.type === SelectType.MULTI) {
      await this.setState({
        optionMap: {
          ...optionMap,
          [option.id]: {
            ...optionMap[option.id],
            isSelected: !optionMap[option.id].isSelected,
          },
        },
      });
      props.onChange(this.getValues());
      return;
    }

    const newOptionMap: SelectOptionsState<T>['optionMap'] = {
      ...optionMap,
      [option.id]: {
        ...optionMap[option.id],
        isSelected: true,
      },
    };

    if (lastSelectedOptionId && lastSelectedOptionId !== option.id) {
      newOptionMap[lastSelectedOptionId] = {
        ...optionMap[lastSelectedOptionId],
        isSelected: false,
      };
    }

    await this.setState({
      lastSelectedOptionId: option.id,
      optionMap: newOptionMap,
    });

    const value = this.getValue();
    this.onActiveOptionChange(value.id);
    props.onChange(value);
    props.onToogleDropDown(false);
  }

  private onActiveOptionChange(optionId: string | undefined) {
    const { selectId } = this.getProps();

    this.setState({
      activeOptionId: optionId,
    });

    if (!optionId) return;

    const option = document.getElementById(SelectOptionsC.getOptionId(selectId, optionId));

    if (option) {
      // scroll element to visible area
      this.scrollOptionIntoView(optionId);
    }
  }

  private elementScrollPosition(childEl: HTMLElement, parentEl: HTMLElement) {
    const child = {
      top: childEl.offsetTop,
      bottom: childEl.offsetTop + childEl.offsetHeight,
    };

    const visibleWindow = {
      top: parentEl.scrollTop,
      bottom: parentEl.scrollTop + parentEl.clientHeight,
    };

    const position = {
      top: child.top - parentEl.scrollTop,
      bottom: child.bottom - visibleWindow.bottom,
    };

    return {
      isPartiallyInView:
        (position.top > 0 && position.bottom > 0) || (position.top < 0 && position.bottom < 0),
      isInView: position.top >= 0 && position.bottom <= 0,
      ...position,
    };
  }

  private dockSearchBar() {
    const { selectId } = this.getProps();
    const OPTION_CONTAINER_ID = SelectOptionsC.getOptionContainerId(selectId);
    const parent = document.getElementById(OPTION_CONTAINER_ID);

    if (!parent) return;

    const attachment = this.getAttachmentPosition();

    if (attachment === Attachment.ABOVE) {
      parent.scrollTop = parent.scrollHeight;
    } else {
      parent.scrollTop = 0;
    }
  }

  private scrollOptionIntoView(optionId: string) {
    const { selectId } = this.getProps();
    const el = document.getElementById(SelectOptionsC.getOptionId(selectId, optionId));

    if (!el) return;

    const parent = el.offsetParent as HTMLUListElement;
    const scrollPosition = this.elementScrollPosition(el, parent);

    if (scrollPosition.isInView) return;

    if (Math.abs(scrollPosition.top) < Math.abs(scrollPosition.bottom)) {
      parent.scrollTop = el.offsetTop;
    } else {
      parent.scrollTop = el.offsetTop + el.offsetHeight - parent.clientHeight;
    }
  }
}

const SelectOptions = <T extends string>(props: SelectOptionsProps<T>) => h(SelectOptionsC, props);

interface State {
  selectId: string;
  isFocused: boolean;
}

class Select<T extends string> extends IComponent<SelectProps<T>, State> {
  private ref: HTMLDivElement | null = null;

  private static DEFAULT_PLACEHOLDER = 'Select an option';

  public static getSelectButtonId(selectId: string) {
    return `select-button-${selectId}`;
  }

  public static getSelectLabelId(selectId: string) {
    return `select-label-${selectId}`;
  }

  public componentWillMount() {
    const initialState: State = {
      isFocused: false,
      selectId: randomString(),
    };
    this.setState(initialState);
  }

  private getSelectedTitle() {
    const props = this.getProps();

    if (props.type === SelectType.MULTI) {
      if (props.value && props.value.length) {
        return props.value.map((v) => v.title).join(', ');
      }

      return props.placeholder || Select.DEFAULT_PLACEHOLDER;
    }

    if (props.value) {
      return props.value.title;
    }

    return null;
  }

  private hasValue() {
    const props = this.getProps();

    if (props.type === SelectType.MULTI) {
      return Boolean(props.value && props.value.length);
    }

    return Boolean(props.value);
  }

  public render() {
    const props = this.getProps();
    const { selectId, isFocused } = this.getState();

    const SELECT_LABEL_ID = Select.getSelectLabelId(selectId);
    const SELECT_BUTTON_ID = Select.getSelectButtonId(selectId);
    const OPTION_CONTAINER_ID = SelectOptionsC.getOptionContainerId(selectId);

    const classNames = ['select'];

    if (this.hasValue()) {
      classNames.push('select--has-value');
    }

    if (isFocused && !props.noFloatingLabel) {
      classNames.push('select--focused');
    }

    if (props.noFloatingLabel) {
      classNames.push('select--no-floating-label');
    }

    if (props.leftAligned) {
      classNames.push('select--left-aligned');
    }

    if (props.hasError) {
      classNames.push('select--has-error');
    }

    return h(
      'div',
      {
        className: cn(classNames, props.className, props.classes && props.classes.select),
        style: props.style,
      },
      [
        h(
          'label.select__label',
          {
            id: SELECT_LABEL_ID,
            key: SELECT_LABEL_ID,
          },
          props.errorText || props.placeholder || Select.DEFAULT_PLACEHOLDER
        ),
        h(
          'button.select__button.no-focus',
          {
            id: SELECT_BUTTON_ID,
            key: SELECT_BUTTON_ID,
            tabIndex: 0,
            type: 'button',
            className: props.isOpen ? 'select--opened' : undefined,
            'aria-haspopup': 'listbox',
            'aria-controls': OPTION_CONTAINER_ID,
            'aria-labelledby': `${SELECT_LABEL_ID} ${SELECT_BUTTON_ID}`,
            ref: (e) => {
              this.ref = e as HTMLDivElement;
            },
            onclick: () => props.onToogleDropDown(!props.isOpen),
            onfocus: () => {
              this.setState({ isFocused: true });
            },
            onblur: () => {
              this.setState({ isFocused: false });
            },
            onkeydown: (e) => {
              switch (e.code) {
                case 'Enter':
                  props.onToogleDropDown(!props.isOpen);
                  e.preventDefault();
                  break;
                case 'ArrowUp':
                case 'ArrowDown':
                  if (!props.isOpen) {
                    props.onToogleDropDown(true);
                    e.preventDefault();
                  }
                default:
                  break;
              }
            },
          },
          [
            h('span.select__value', this.getSelectedTitle()),
            SvgIcon({
              icon: CaretIcon,
              'aria-hidden': true,
              className: 'select__caret',
            }),
            SelectOptions({
              ...props,
              selectId,
              selectRef: this.ref,
              onToogleDropDown: (isOpen: boolean) => {
                props.onToogleDropDown(isOpen);
              },
            }),
          ]
        ),
      ]
    );
  }
}

/**
 * Single/Multi select drop-down with accessibility support. Please see
 * [this link](https://www.w3.org/TR/wai-aria-practices-1.1/examples/combobox/aria1.1pattern/listbox-combo.html)
 * for accessibility standards being followed
 */
export default <T extends string>(props: SelectProps<T>) => h(Select, props);
