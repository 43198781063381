import { h, IComponent } from 'core';

import { Actions as appActions } from 'acadly/app/actions';
import Avatar from 'acadly/common/Avatar';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import { Loader } from 'acadly/common/Loader';
import UploadButton from 'acadly/common/UploadButton';
import { Actions as CourseActions } from 'acadly/course/actions';
import { IAddStudentsResponse, IAddStudentsResponseStudent } from 'acadly/course/api';
import * as datetime from 'acadly/datetime';
import icons from 'acadly/icons';
import { dispatch, getStore } from 'acadly/store';
import { backgroundColor, colors, margin, mb, mr, pad, style } from 'acadly/styles';
import { filterDuplicates, validateEmail } from 'acadly/utils';

import * as css from './styles';

export type AddStudentSteps = 'addStudent' | 'addedStudent' | 'joinCode';

export interface IAddStudentsProps {
  open: boolean;
  close: () => any;
  course: ICourse;
}
export interface IAddStudentsState {
  isSaving: boolean;
  step: AddStudentSteps;
  isNextDisabled: boolean;
  addStudentsEmailsField: string;
  addStudentResponse: IAddStudentsResponse;
}

interface StepData {
  title: string;
  next: AddStudentSteps | null;
  nextTitle: string;
}

export class AddStudents extends IComponent<IAddStudentsProps, IAddStudentsState> {
  private stepDataMap: Record<AddStudentSteps, StepData> = {
    addStudent: {
      next: 'addedStudent',
      nextTitle: 'ADD AND CONTINUE',
      title: 'Add students by email',
    },
    addedStudent: {
      next: null,
      nextTitle: 'DONE',
      title: 'Added students',
    },
    joinCode: {
      next: null,
      nextTitle: 'DONE',
      title: 'Students enrol with a Join Code',
    },
  };

  private initialState: IAddStudentsState = {
    isSaving: false,
    step: 'addStudent',
    addStudentsEmailsField: '',
    isNextDisabled: false,
    addStudentResponse: {
      added: [],
      invited: [],
      preEnrolled: [],
      removed: [],
      invalidEmails: {
        message: '',
        emailIds: [],
      },
    },
  };

  private async setFirstStep(course: ICourse) {
    const isJoinCode = course.enrolmentType === 'enrolmentCode';
    await this.setState({
      step: isJoinCode ? 'joinCode' : 'addStudent',
      isNextDisabled: !isJoinCode,
    });
  }

  private async initialize(props: IAddStudentsProps) {
    await this.setState(this.initialState);
    await this.setFirstStep(props.course);
  }

  private formatDate(date: number) {
    return datetime.format(date, 'MMMM Do, YYYY');
  }

  private getTypedInStudentEmails() {
    const input = this.getState().addStudentsEmailsField;
    return filterDuplicates(
      input
        .split(/[\s,;]+/g)
        .filter((s) => s.length > 0)
        .filter(validateEmail)
    );
  }

  private getJoinCodeMailLink() {
    const course = this.getProps().course;
    const subject = encodeURIComponent(`Please join the course ${course.details.code} on Acadly`);
    const body = encodeURIComponent(
      `Please use the following course Join Code to access the course` +
        ` ${course.details.code}:${course.details.title} on Acadly.\n\n${course.joinCode}` +
        `\n\nIf you don’t have an Acadly account, you can sign up using this Join Code.` +
        ` However, if you already have an Acadly account, you can log in to Acadly and join` +
        ` the course using this Join Code.`
    );
    return `mailto:?subject=${subject}&body=${body}`;
  }

  private async removeEmail(email: string) {
    const emails = this.getTypedInStudentEmails();
    const newEmails = emails.filter((e) => e !== email);
    await this.setState({
      addStudentsEmailsField: newEmails.reduce((before, current) => before + current + '\n', ''),
    });
  }

  private getNextButtonTitle() {
    const step = this.getState().step;
    return this.stepDataMap[step].nextTitle;
  }

  public async componentWillMount() {
    await this.initialize(this.getProps());
  }

  public async componentWillReceiveProps(newProps: IAddStudentsProps) {
    if (this.getProps().open !== newProps.open) {
      await this.initialize(newProps);
    }
  }

  public render() {
    const props = this.getProps();
    const isMobile = getStore().getState().app.isMobile;
    if (isMobile) {
      return h(
        'div.add-students-screen',
        style([
          'fullWidth',
          {
            zIndex: '1000',
            height: '100%',
            backgroundColor,
            position: 'fixed',
            top: props.open ? '0' : '100%',
            transition: 'all 0.3s cubic-bezier(.23,-0.3,.24,1.2)',
          },
        ]),
        !props.open
          ? []
          : [
              h(
                'div.add-students-header',
                {
                  style: {
                    background: colors.darkBlue,
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '20px',
                    height: css.headerHeight,
                  },
                },
                [
                  Icon(icons.cross, style([pad(css.contentPadding)], {}, { onclick: props.close })),
                  h('span.title', 'Add Students'),
                ]
              ),
              h(
                'div.add-students-body',
                style(['flex', 'column'], {
                  height: `calc(100% - ${css.headerHeight})`,
                }),
                this.body()
              ),
            ]
      );
    }

    const step = this.getState().step;
    const isNextDisabled = this.getState().isNextDisabled;

    return Dialog(
      {
        title: 'Add Students',
        open: this.getProps().open,
        key: 'add-students-screen',
        fullScreen: isMobile,
        style: {
          width: '45em',
          height: '45em',
        },
        bodyStyle: {
          padding: `0 ${css.contentPadding}`,
          backgroundColor,
          height: '100%',
        },
        secondaryAction:
          step === 'addStudent'
            ? {
                label: 'Later',
                onclick: () => this.cancelClickHandler(),
              }
            : undefined,
        primaryAction: {
          disabled: isNextDisabled,
          label: this.getNextButtonTitle(),
          onclick: () => this.submitHandler(),
        },
      },
      props.open ? this.body() : []
    );
  }

  private async cancelClickHandler() {
    await this.getProps().close();
    await dispatch(appActions.startTip(true));
  }

  private async addStudentSubmitHandler() {
    const detectedEmails = this.getTypedInStudentEmails();
    if (!detectedEmails.length) return;
    try {
      await dispatch(CourseActions.addStudents(detectedEmails, 'typed')).then((response) => {
        return this.setState({
          addStudentResponse: response,
          step: 'addedStudent',
        });
      });
    } finally {
      await this.setState({ isSaving: false });
    }
    return;
  }

  private async submitHandler() {
    const step = this.getState().step;
    switch (step) {
      case 'joinCode':
      case 'addedStudent':
        return this.cancelClickHandler();
      case 'addStudent':
        return this.addStudentSubmitHandler();
      default:
        break;
    }
  }

  private joinCodeStepScreen() {
    const course = this.getProps().course;
    if (!course) return [];

    return [
      h(
        'div.course-details',
        { style: css.courseDetailStyle },
        `${course.details.code}: ${course.details.title}`
      ),
      h(
        'div.course-details',
        { style: css.courseDetailStyle },
        `${this.formatDate(course.dates!.startDate)} - ${this.formatDate(course.dates!.endDate)}`
      ),
      h('div.course-code', style(['textCenter', margin('2.5em 0')]), [
        h('div', style(['bold', 'blue', 'xx-large']), course.joinCode),
        h(
          'a',
          style(
            ['pointer', 'blue', margin('0.5em 0')],
            { display: 'inline-block' },
            {
              target: '_blank',
              href: this.getJoinCodeMailLink(),
            }
          ),
          'Email This Code'
        ),
      ]),
      h('div.note', style(['textCenter', 'lightGrey', margin(`1.5em ${css.contentPadding}`)]), [
        h('span', style([mr('0.25em')]), 'This code will be available on the'),
        Icon(icons.analytics, style(['blue', mr('0.25em')])),
        h('span', 'Course Analytics section on the course page for easy access'),
      ]),
    ];
  }

  private addStudentStepScreen() {
    const course = this.getProps().course;
    if (!course) return [];

    const detectedEmails = this.getTypedInStudentEmails();
    const emptyListMessage = h(
      'div.note',
      style(['textCenter', 'lightGrey', margin(`${css.contentPadding}`)]),
      `
              You haven't added any email addresses yet. You can always
              add students later by navigating to the "Course Analytics"
              section of the Course Page.
            `
    );

    const renderEmails = (email: string) =>
      h('div', style([css.row]), [
        email,
        Icon(
          icons.cross,
          style(
            ['red', 'pointer', 'large'],
            {},
            {
              onclick: () => this.removeEmail(email),
            }
          )
        ),
      ]);

    const emailList = detectedEmails.length
      ? h('div.email-list', detectedEmails.map(renderEmails))
      : emptyListMessage;

    return [
      h(
        'div.course-details',
        { style: css.courseDetailStyle },
        `${course.details.code}: ${course.details.title}`
      ),
      h(
        'div.course-details',
        { style: css.courseDetailStyle },
        `${this.formatDate(course.dates!.startDate)} - ${this.formatDate(course.dates!.endDate)}`
      ),
      h('div.form-control-wrapper', style([css.row]), [
        h('div', style(['fullWidth']), [
          h(
            'div.form-label',
            style([css.formLabelStyle, 'blue', pad('0')]),
            "Students' email addresses"
          ),
          h('textarea', {
            maxLines: 6,
            rows: 4,
            value: this.getState().addStudentsEmailsField,
            style: {
              marginTop: '0.5em',
              resize: 'none',
              padding: '0.5em',
              boxSizing: 'border-box',
              outline: 'none',
              width: '100%',
              height: '5em',
              color: 'rgba(0, 0, 0, 0.5)',
              border: `1px solid ${colors.lighterGrey}`,
            },
            oninput: (event: any) => {
              const value = event.target.value.toLowerCase();
              this.setState({
                isNextDisabled: !value,
                addStudentsEmailsField: value,
              });
            },
          }),
          h('div.note', style([css.formLabelStyle, pad('0.5em 0 0')]), [
            'Addresses should be separated by',
            h('span', style([css.separator]), ','),
            'or',
            h('span', style([css.separator]), ';'),
            'or',
            h('span', style([css.separator]), 'space'),
          ]),
        ]),
      ]),
      h('div.form-label', style(['uppercase'], css.formLabelStyle), 'OR'),
      UploadButton({
        ariaLabel: 'Upload a .txt or .csv file containing Email Ids button',
        accept: '.csv, text/plain',
        upload: (file) => {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            if (event.target.result) {
              const emailField = `${this.getState().addStudentsEmailsField}\n${
                event.target.result
              }`;
              this.setState({
                isNextDisabled: !emailField,
                addStudentsEmailsField: emailField,
              });
            }
          };
          reader.readAsText(file);
          return {};
        },
        view: h('div.form-control-wrapper', style(['pointer', css.row]), [
          'Upload a CSV file of email addresses',
          h('span.fa.fa-cloud-upload', style(['blue', 'x-large'])),
        ]),
      }),
      h('div.emails-count', style([css.formLabelStyle, 'flex', 'spaceBetween']), [
        h('span', 'STUDENTS TO ADD'),
        h('span', `${detectedEmails.length} Students`),
      ]),
      emailList,
    ];
  }

  private addedStudentStepScreen() {
    const course = this.getProps().course;
    if (!course) return [];

    const addStudentResponse = this.getState().addStudentResponse;
    const invalidEmails = addStudentResponse.invalidEmails;

    const renderInvalidEmail = (email: string) => h('div', style([css.row, 'red']), email);

    const invalidEmailList = [
      h('div.form-label', style([css.formLabelStyle, 'uppercase']), 'Invalid Email Addresses'),
      h(
        'div.error-message',
        style(['red', pad(`12px ${css.contentPadding}`)]),
        invalidEmails.message
      ),
      ...invalidEmails.emailIds.map(renderInvalidEmail),
    ];

    const addedStudents = addStudentResponse.added;

    const renderAddedStudent = (student: IAddStudentsResponseStudent<'active'>) => {
      return h('div', style([css.row], { justifyContent: 'flex-start' }), [
        Avatar(student.avatar, student.name, {
          className: 'add-students__avatar',
        }),
        h('div', [
          h('div', style(['green', mb('4px')]), student.name),
          h('div', style(['grey', 'capitalize', 'x-small']), student.status),
        ]),
      ]);
    };

    const addedStudentList = [
      h('div.form-label', style([css.formLabelStyle, 'uppercase']), 'STUDENTS ADDED AND NOTIFIED'),
      ...addedStudents.map(renderAddedStudent),
    ];

    const invitedStudents = addStudentResponse.invited;

    const renderInvitedStudent = (student: IAddStudentsResponseStudent<'invited'>) => {
      return h('div', style([css.row], { justifyContent: 'flex-start' }), [
        Avatar('default', 'default', {
          className: 'add-students__avatar',
        }),
        h('div', [
          h('div', style(['green', mb('4px')]), student.emailId),
          h('div', style(['grey', 'capitalize', 'x-small']), 'Yet to sign up'),
        ]),
      ]);
    };

    const invitedStudentList = [
      h('div.form-label', style([css.formLabelStyle, 'uppercase']), 'STUDENTS INVITED'),
      ...invitedStudents.map(renderInvitedStudent),
    ];

    return [
      h(
        'div.course-details',
        { style: css.courseDetailStyle },
        `${course.details.code}: ${course.details.title}`
      ),
      h(
        'div.course-details',
        { style: css.courseDetailStyle },
        `${this.formatDate(course.dates!.startDate)} - ${this.formatDate(course.dates!.endDate)}`
      ),
      invalidEmails.emailIds.length ? h('div', invalidEmailList) : null,
      addedStudents.length ? h('div', addedStudentList) : null,
      invitedStudents.length ? h('div', invitedStudentList) : null,
    ];
  }

  private body() {
    const step = this.getState().step;
    const isSaving = this.getState().isSaving;
    const isNextDisabled = this.getState().isNextDisabled;

    const isMobile = getStore().getState().app.isMobile;

    let stepContent: any;
    const actionButtonStyle = { padding: '1em' };

    switch (step) {
      case 'joinCode':
        stepContent = this.joinCodeStepScreen();
        break;
      case 'addStudent':
        stepContent = this.addStudentStepScreen();
        break;
      case 'addedStudent':
        stepContent = this.addedStudentStepScreen();
        break;
      default:
        stepContent = [];
        break;
    }

    return [
      h(
        'div.step-instructions',
        {
          style: {
            padding: '10px 20px',
            backgroundColor: colors.darkBlue,
            color: 'white',
            flexShrink: '0',
            margin: isMobile ? '0' : `${css.contentPadding} 0`,
          },
        },
        this.stepDataMap[step].title
      ),
      h('div.content-wrapper', { style: { overflow: 'auto', flex: '1' } }, stepContent),
      isMobile
        ? h(
            'div.add-students-footer',
            {
              style: {
                display: 'flex',
                alignItems: 'center',
                flexShrink: '0',
                backgroundColor: 'white',
                height: css.headerHeight,
              },
            },
            [
              step !== 'joinCode'
                ? FlatButton(h('span', 'LATER'), {
                    style: actionButtonStyle,
                    onclick: () => this.cancelClickHandler(),
                  })
                : null,
              h('div.spacer', { style: { flex: '1' } }),
              FlatButton(
                isSaving
                  ? Loader({ width: '1em', height: '1em' })
                  : [
                      h('span', style(isNextDisabled ? ['grey'] : []), this.getNextButtonTitle()),
                      Icon(
                        this.stepDataMap[step].nextTitle === 'DONE' ? icons.done : icons.arrowRight,
                        {
                          style: {
                            fontSize: '10px',
                            marginLeft: '4px',
                          },
                        }
                      ),
                    ],
                {
                  disabled: isNextDisabled,
                  style: actionButtonStyle,
                  onclick: () => this.submitHandler(),
                }
              ),
            ]
          )
        : null,
    ];
  }
}

export default (props: IAddStudentsProps) => h(AddStudents, props);
