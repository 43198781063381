import { Dispatch } from 'redux';
import { Observable } from 'rxjs/Observable';

import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import { assignmentService } from 'acadly/assignment/service';
import * as classApi from 'acadly/class/api';
import { Actions as CourseActions } from 'acadly/course/actions';
import { createAction, Thunk, ValueThunk } from 'acadly/createAction';
import { Actions as GetInActions } from 'acadly/getin/actions';
import { IPusherPayload } from 'acadly/pusher';

import * as dt from '../datetime';
import * as api from './api';

export type IAssignmentActionMap = {
  '@assignment/FETCH_DATA_REQUEST': void;
  '@assignment/FETCH_DATA_SUCCESS': IFetchDataSuccessPayload;
  '@assignment/question/ADD_SUCCESS': IQuestionAddSuccessPayload;
  '@assignment/question/EDIT_SUCCESS': IQuestionEditSuccessPayload;
  '@assignment/LEAVE_ASSIGNMENT_PAGE': undefined;
  '@assignment/question/DELETE_SUCCESS': IQuestionDeleteSuccessPayload;
  '@assignment/PUBLISH_SUCCESS': IPublishAssignmentSuccessPayload;
  '@assignment/UPDATE_PUBLISHED_ASSIGNMENT_SUCCESS': api.IUpdateAssignmentRequestLocalData;
  '@assignment/CREATE_SUCCESS': ICreateSuccessPayload;
  '@assignment/DELETE_SUCCESS': IDeleteSuccessPayload;
  '@assignment/EDIT_SUCCESS': IEditSuccessPayload;
  '@assignment/SUBMISSION_UPLOAD_SUCCESS': ISubmissionUploadSuccessPayload;
  '@assignment/SUBMIT_SUCCESS': ISubmitSuccessPayload;
  '@assignment/RETRACT_SUCCESS': IRetractSuccessPayload;
  '@assignment/analytics/team/FETCH_SUCCESS': ITeamAnalyticsFetchSuccess;
  '@assignment/grades/SAVE_SUCCESS': IGradesSavePayload;
  '@assignment/grades/SUBMIT_SUCCESS': IGradesSubmitPayload;
  '@assignment/pusher/PUBLISH': IPusherPublishPayload;
  '@assignment/SUBMISSION_URL_SAVE_SUCCESS': ISubmissionURLSaveSuccess;
  '@assignment/pusher/SUBMITTED': IPusherSubmittedPayload;
  '@assignment/pusher/RETRACTED': IPusherRetractedPayload;
};

export type ISubmissionURLSaveSuccess = {
  request: api.ISubmissionURLSaveRequest;
  response: api.ISubmissionURLSaveResponse;
};

export type IUpdateAssignmentRequest = api.IUpdateAssignmentRequest;

export type IUpdateAssignmentRequestLocalData = api.IUpdateAssignmentRequestLocalData;

export type IPusherPublishPayload = IPusherPayload<{
  assignmentId: string;
  num: number;
  dueDateTime: UnixTimestamp;
  dueDateTimeL: string;
  title: string;
}>;

export type IGradesSavePayload = api.IGradesSaveRequest;
export type IGradesSubmitPayload = IGradesSavePayload & {
  timestamp: UnixTimestamp;
};

export type ITeamAnalyticsFetchSuccess = ITeamAssignmentAnalytics;

export type IRetractSuccessPayload = string; // assignment id

export type ISubmitSuccessPayload = {
  request: api.ISubmitRequest;
  response: api.ISubmitResponse;
};

export type ISubmissionUploadSuccessPayload = {
  request: api.IUploadQuestionSubmissionArg;
  response: api.IUploadQuestionSubmissionQuestionResponse & { url: string };
};
export type ICommentsSetSeenPayload = {
  assignmentId: string;
  subContext: 'preSub' | 'postSub';
};

export type IEditSuccessPayload = api.IEditAssignmentRequest;

export type IFetchDataSuccessPayload = {
  questions: IAssignmentQuestion[];
  submission?: IAssignmentSubmission;
  assignmentId: string;
  firstAttempt: boolean;
};
export interface ICreateSuccessPayload {
  response: api.ICreateAssignmentResponse;
  courseId: string;
}

export interface IDeleteSuccessPayload {
  assignmentId: string;
}
export interface IQuestionDeleteSuccessPayload {
  questionId: string;
  assignmentId: string;
}

export interface IPublishAssignmentSuccessPayload {
  assignmentId: string;
  dueDateTime: number;
  publishedOn: number;
  allowLate: boolean;
  num: number;
  subscribed: 0 | 1;
}
export type IQuestionEditSuccessPayload = api.IEditAssignmentQuestionRequest;

export interface IQuestionAddSuccessPayload {
  assignmentId: string;
  question: IAssignmentQuestion;
}

export type IPusherSubmittedPayload = IPusherPayload<{
  assignmentId: string;
}>;

export type IPusherRetractedPayload = IPusherPayload<{
  assignmentId: string;
}>;

export const fetchAssignmentDataRequest = createAction('@assignment/FETCH_DATA_REQUEST');
export const fetchAssignmentDataSuccess = createAction('@assignment/FETCH_DATA_SUCCESS');
export const fetchAssignmentData =
  (assignment: IAssignment, firstAccess: boolean) => async (dispatch: any) => {
    dispatch(fetchAssignmentDataRequest(undefined));
    const assignmentId = assignment._id;
    if (assignment.details.numQuestions < 1) {
      dispatch(
        fetchAssignmentDataSuccess({
          questions: [],
          firstAttempt: false,
          assignmentId,
        })
      );
      return;
    }
    const response = await api.fetchAssignmentData(assignmentId, firstAccess ? '1' : '0');
    dispatch(
      fetchAssignmentDataSuccess({
        questions: response.data.questions,
        submission: response.data.submission,
        firstAttempt: firstAccess,
        assignmentId,
      })
    );
  };

export const addAssignmentQuestionSuccess = createAction('@assignment/question/ADD_SUCCESS');
export const addAssignmentQuestion =
  (assignmentId: string, data: api.IAddAssignmentQuestionRequest, isEditingAssignment?: boolean) =>
  async (dispatch: any) => {
    const response = await api.addAssignmentQuestion(data);
    if (isEditingAssignment) {
      return response.data;
    } else {
      dispatch(
        addAssignmentQuestionSuccess({
          assignmentId,
          question: response.data,
        })
      );
      return;
    }
  };

export const editAssignmentQuestionSuccess = createAction('@assignment/question/EDIT_SUCCESS');
export const editAssignmentQuestion =
  (data: api.IEditAssignmentQuestionRequest) => async (dispatch: any) => {
    await api.editAssignmentQuestion(data);
    dispatch(editAssignmentQuestionSuccess(data));
  };

export const leaveAssignmentPage = createAction('@assignment/LEAVE_ASSIGNMENT_PAGE');

export const deleteAssignmentQuestionSuccess = createAction('@assignment/question/DELETE_SUCCESS');
export const deleteAssignmentQuestion =
  (questionId: string, assignmentId: string) => async (dispatch: any) => {
    await api.deleteAssignmentQuestion(questionId, assignmentId);
    return dispatch(deleteAssignmentQuestionSuccess({ questionId, assignmentId }));
  };

export const publishAssignmentSuccess = createAction('@assignment/PUBLISH_SUCCESS');

export const publishAssignment =
  (data: {
    assignmentId: string;
    dueDateTime: number;
    allowLate: boolean;
    subscribeToComments: 0 | 1;
  }): Thunk<void> =>
  async (dispatch: any) => {
    const response = await api.publishAssignment(
      data.assignmentId,
      data.dueDateTime,
      data.allowLate,
      data.subscribeToComments
    );
    googleAnalytics.activityPublished('assignment', 'course');
    return await dispatch(
      publishAssignmentSuccess({
        assignmentId: data.assignmentId,
        allowLate: data.allowLate,
        dueDateTime: response.data.dueDateTime,
        num: response.data.num,
        publishedOn: dt.toUnix(dt.now()),
        subscribed: data.subscribeToComments,
      })
    );
  };

export const updateAssignmentSuccess = createAction(
  '@assignment/UPDATE_PUBLISHED_ASSIGNMENT_SUCCESS'
);

export const updateAssignment =
  (
    data: api.IUpdateAssignmentRequest,
    localData: api.IUpdateAssignmentRequestLocalData
  ): Thunk<void> =>
  async (dispatch: any) => {
    await api.updateAssignment(data);
    // googleAnalytics.activityPublished("assignment", "course");
    return await dispatch(updateAssignmentSuccess(localData));
  };

export const createAssignmentSuccess = createAction('@assignment/CREATE_SUCCESS');

export const createAssignment =
  (courseId: string, createFromScratch = false) =>
  async (dispatch: Dispatch<any>) => {
    const response = await api.createAssignment({
      isNewFromScratch: createFromScratch ? 1 : 0,
    });

    if (response.data.hasSuggestedAssignments === 0) {
      googleAnalytics.activityCreated('assignment', 'course');
      dispatch(
        createAssignmentSuccess({
          response: response.data,
          courseId,
        })
      );
    }

    return response.data;
  };

export const useSuggestedAssignment =
  (params: classApi.IUseSuggestedActivityRequest) => async (dispatch: Dispatch<any>) => {
    await classApi.useSuggestedActivity(params);
    await dispatch(CourseActions.fetchTimeline());
  };

export const deleteAssignmentSuccess = createAction('@assignment/DELETE_SUCCESS');
export const deleteAssignment = (assignmentId: string) => async (dispatch: Dispatch<any>) => {
  await api.deleteAssignment(assignmentId);
  dispatch(deleteAssignmentSuccess({ assignmentId }));
};

export const editAssignmentSuccess = createAction('@assignment/EDIT_SUCCESS');
export const editAssignment =
  (updates: api.IEditAssignmentRequest) => async (dispatch: Dispatch<any>) => {
    await api.editAssignment(updates);
    dispatch(editAssignmentSuccess(updates));
  };

export const submissionUploadSuccess = createAction('@assignment/SUBMISSION_UPLOAD_SUCCESS');

// thunk action that uploads a given file and returns
// a stream of numeric values that represent the progress of file upload
// from 0 to 1
export const submissionUpload =
  (data: api.IUploadQuestionSubmissionArg): ValueThunk<Observable<ProgressEvent>> =>
  (dispatch) => {
    const { promise, progress$ } = api.uploadQuestionSubmission(data);
    promise.then((result) =>
      dispatch(
        submissionUploadSuccess({
          response: result,
          request: data,
        })
      )
    );
    return progress$;
  };

export const submitSuccess = createAction('@assignment/SUBMIT_SUCCESS');
export const submit =
  (data: api.ISubmitRequest, beforeDispatch?: () => any): Thunk<void> =>
  async (dispatch) => {
    const response = await api.submit(data);
    if (beforeDispatch) {
      await beforeDispatch();
    }
    dispatch(
      submitSuccess({
        request: data,
        response: response.data,
      })
    );
  };

export const retractSuccess = createAction('@assignment/RETRACT_SUCCESS');
export const retract =
  (assignmentId: string): Thunk<void> =>
  async (dispatch) => {
    await api.retract(assignmentId);
    dispatch(retractSuccess(assignmentId));
  };

export const teamAnalyticsFetchSuccess = createAction('@assignment/analytics/team/FETCH_SUCCESS');
export const teamAnalyticsFetch =
  (assignmentId: string): Thunk<void> =>
  async (dispatch) => {
    const response = await api.fetchAnalyticsForTeam(assignmentId);
    dispatch(GetInActions.fetchAvatars(response.data.submittedBy.map((s) => s.identifiers.avatar)));
    dispatch(teamAnalyticsFetchSuccess(response.data));
  };

export const gradesSaveSuccess = createAction('@assignment/grades/SAVE_SUCCESS');
export const gradesSave =
  (data: api.IGradesSaveRequest): Thunk<void> =>
  async () => {
    await api.gradesSave(data);
  };

export const gradesSubmitSuccess = createAction('@assignment/grades/SUBMIT_SUCCESS');
export const gradesSubmit =
  (data: api.IGradesSaveRequest): Thunk<void> =>
  async (dispatch) => {
    await api.gradesSubmit(data);
    dispatch(
      gradesSubmitSuccess({
        ...data,
        timestamp: dt.unix(),
      })
    );
  };

export const pusherPublish =
  (data: IPusherPublishPayload): Thunk<void> =>
  async (dispatch, getState) => {
    const timeline = getState().courses.timeline;
    if (timeline) {
      const assignment = timeline.items.find((a) => a._id === data.assignmentId) as IAssignment;
      if (assignment && assignment.details.published) {
        return;
      }
    }
    dispatch(CourseActions.fetchTimeline(getState().getIn.session!.userId));
  };

export const pusherUpdateAssigmentSuccess =
  (data: IPusherPublishPayload): Thunk<void> =>
  async (dispatch, getState) => {
    const timeline = getState().courses.timeline;
    if (timeline) {
      const assignment = timeline.items.find((a) => a._id === data.assignmentId) as IAssignment;
      if (assignment && assignment.details.published) {
        return;
      }
    }
    dispatch(CourseActions.fetchTimeline(getState().getIn.session!.userId));
  };

export const submissionURLSaveSuccess = createAction('@assignment/SUBMISSION_URL_SAVE_SUCCESS');
export const submissionURLSave =
  (data: api.ISubmissionURLSaveRequest): Thunk<void> =>
  async (dispatch) => {
    const response = await api.submissionURLSave(data);
    dispatch(
      submissionURLSaveSuccess({
        request: data,
        response: response.data,
      })
    );
  };
export const incrementSubmissionCount = createAction('@assignment/pusher/SUBMITTED');
export const pusherSubmitted =
  (data: IPusherSubmittedPayload): Thunk<void> =>
  async (dispatch) => {
    dispatch(incrementSubmissionCount(data));
    const currentAssignmentId = assignmentService.getCurrentAssignmentId();
    if (currentAssignmentId === data.assignmentId) {
      await dispatch(teamAnalyticsFetch(data.assignmentId));
    }
  };

export const pusherRetracted = createAction('@assignment/pusher/RETRACTED');
