import { h, IComponent } from 'core';

import { Actions as appActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import MoveActivityDialog from 'acadly/class/MoveActivityDialog';
import ActionBar, { isIpad } from 'acadly/common/ActionBar';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Avatar from 'acadly/common/Avatar';
import { BottomFloatingBar } from 'acadly/common/BottomFloatingBar';
import ContentView from 'acadly/common/ContentView';
import Dialog from 'acadly/common/Dialog';
import DivButton from 'acadly/common/DivButton';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import { fullScreenLoader, Loader } from 'acadly/common/Loader';
import Paper from 'acadly/common/Paper';
import ProgressBar from 'acadly/common/ProgressBar';
import QuestionOptionEditor from 'acadly/common/QuestionOptionEditor';
import RadioButton from 'acadly/common/RadioButton';
import RaisedButton from 'acadly/common/RaisedButton';
import TextField from 'acadly/common/TextField';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import Toggle from 'acadly/common/Toggle';
import CopyActivityDialog from 'acadly/course/CopyActivityDialog';
import { validateActivityPublish, validateActivitySubmit } from 'acadly/course/validations';
import * as datetime from 'acadly/datetime';
import icons from 'acadly/icons';
import { requiresRole } from 'acadly/permissions';
import Editor from 'acadly/rich-text/Editor';
import Viewer from 'acadly/rich-text/Viewer';
import { goBack } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { backgroundColor, colors, mb, mr, mt, pad, pt, style } from 'acadly/styles';
import * as u from 'acadly/utils';
import * as utils from 'acadly/utils';

import { api as urls } from '../api';
import { Actions } from './actions';
import lodash = require('lodash');
import { Subscription } from 'rxjs/Subscription';

import AutoSubmitDialog from 'acadly/common/AutoSubmitDialog';
import FloatingActionBar from 'acadly/common/FloatingActionBar';
import PollPrefsDialog from 'acadly/poll/PollPrefsDialog';
import { PusherEvent, pusherService } from 'acadly/pusher';

import ResponseTypeMenu from './ResponseTypeMenu';

type ActivityStoppedEvent = PusherEvent<
  'activityStopped',
  {
    courseId: string;
    classId: string;
    activityId: string;
    activityType: 'poll' | 'quiz';
    dueDateTime: number;
    dueDateTimeL: string;
  }
>;

export interface IPollProps {
  poll: IPoll;
  class: IClass;
  course: ICourse;
  courseRole: ICourseRole;
  isClassIncharge: boolean;
}

export interface IPollState {
  isSubmitDialogOpen: boolean;

  isCopyDialogOpen: boolean;
  isMoveDialogOpen: boolean;
  isDeleteDialogOpen: boolean;

  isEditingTitle: boolean;
  editTitleField: string;
  editTitleFieldError: boolean;

  isPublishDialogOpen: boolean;
  isPublishPrefsDialogVisible: boolean;

  isStopDialogOpen: boolean;

  isEditingQuestion: boolean;
  editQuestionDescriptionField: string;
  editQuestionDescriptionError: boolean;
  editQuestionAttachmentsField: IAttachment[];
  editQuestionOptionsFields: {
    value: string;
    id: number;
    hasError: boolean;
  }[];

  isResponseTypeMenyOpen: boolean;
  responseTypeField: 0 | 1;

  attemptOptionNum: number | null;

  // this is required because the editor for each option
  // must have a unique key, otherwise it won't update
  // properly on removing options
  // When an option is added, this count is incremented
  // and the editor for the new field is assigned a new key
  nextOptionId: number;
  timerText: string;
  timerTextHumanized: string;
  highlightTimer: boolean;
  showAutoSubmitDialog: boolean;

  // Edit poll related temp state
  iseditPollDialogOpen: boolean;
  editPollTitle: string;
  editPollQuestion: IPollQuestion | null;
  editPollQuestionOptionsFields: {
    value: string;
    id: number;
    hasError: boolean;
  }[];
  editPollQuestionAttachments: IAttachment[];
  editPollDialog: {
    isEditingTitle: boolean;
    editTitleField: string;
    editTitleFieldError: boolean;
    isEditingQuestion: boolean;
    editQuestionAttachmentsField: IAttachment[];
    editQuestionDescriptionField: string;
    editQuestionDescriptionError: boolean;
    editQuestionOptionsFields: {
      value: string;
      id: number;
      hasError: boolean;
    }[];
    nextOptionId: number;
    toNotify: 0 | 1;
  };

  isEditPollPublishDialogOpen: boolean;
  isMaxOptionLimitsReachedOpen: boolean;
}
export const defaultInstructions =
  'There are no special instructions. Just ' + 'attempt the questions. Best of luck!';

export class Poll extends IComponent<IPollProps, IPollState> {
  private pusherEventSub: Subscription;

  public componentWillMount() {
    const { poll } = this.getProps();
    const initialState: IPollState = {
      isSubmitDialogOpen: false,

      isCopyDialogOpen: false,
      isMoveDialogOpen: false,
      isDeleteDialogOpen: false,

      isEditingTitle: false,
      editTitleField: poll.details.title,
      editTitleFieldError: false,

      isPublishDialogOpen: false,
      isPublishPrefsDialogVisible: false,

      isStopDialogOpen: false,

      isEditingQuestion: false,
      editQuestionDescriptionField: '',
      editQuestionDescriptionError: false,
      editQuestionAttachmentsField: [],
      editQuestionOptionsFields: [
        { id: 0, value: '', hasError: false },
        { id: 1, value: '', hasError: false },
      ],
      nextOptionId: 2,

      isResponseTypeMenyOpen: false,
      responseTypeField: 0,
      attemptOptionNum: null,
      timerText: 'Loading...',
      timerTextHumanized: 'Loading...',
      highlightTimer: false,
      showAutoSubmitDialog: false,

      isEditPollPublishDialogOpen: false,
      editPollTitle: '',
      editPollQuestionOptionsFields: [
        { id: 0, value: '', hasError: false },
        { id: 1, value: '', hasError: false },
      ],
      editPollQuestionAttachments: [],
      iseditPollDialogOpen: false,
      editPollQuestion: null,
      editPollDialog: {
        isEditingTitle: false,
        editTitleField: '',
        editTitleFieldError: false,
        isEditingQuestion: false,
        editQuestionAttachmentsField: [],
        editQuestionDescriptionField: '',
        editQuestionDescriptionError: false,
        editQuestionOptionsFields: [
          { id: 0, value: '', hasError: false },
          { id: 1, value: '', hasError: false },
        ],
        nextOptionId: 2,
        toNotify: 1,
      },

      isMaxOptionLimitsReachedOpen: false,
    };
    this.setState(initialState).then(() => {
      const { poll, courseRole, class: cls } = this.getProps();
      dispatch(Actions.fetch(poll._id, courseRole, cls._id)).then(() => {
        const question = getStore().getState().polls.question;
        if (question) {
          this.setState({
            editPollTitle: poll.details.title,
            editPollQuestion: question,
            editPollQuestionOptionsFields: question.details.options.map((option) => ({
              id: option.num,
              value: option.text,
              hasError: false,
            })),
            editPollQuestionAttachments: poll.details.attachments,
            editPollDialog: {
              isEditingTitle: false,
              editTitleField: poll.details.title,
              editTitleFieldError: false,
              isEditingQuestion: false,
              editQuestionAttachmentsField: poll.details.attachments,
              editQuestionDescriptionField: question ? question.details.description.text : '',
              editQuestionDescriptionError: false,
              editQuestionOptionsFields: [],
              nextOptionId: 2,
              toNotify: 1,
            },
          });
        }
      });
    });

    if (poll.details.dueDateType !== 'manual') {
      this.initializeTimer(poll.details.dueDateTime);
    }

    dispatch(appActions.setContext('poll'));
    dispatch(appActions.startTip(true));
    setTimeout(() => {
      const actionbar = document.getElementById('action-bar');
      actionbar ? actionbar.focus() : null;
    }, 0);

    this.pusherEventSub = pusherService.events.subscribe((e: ActivityStoppedEvent) => {
      const data = e.payload;
      const { poll, courseRole, class: cls } = this.getProps();
      if (
        e.event === 'activityStopped' &&
        data.activityType === 'poll' &&
        data.activityId === poll._id
      ) {
        this.showAutoSubmitDialog();
        dispatch(Actions.fetch(poll._id, courseRole, cls._id));
      }
    });
  }

  public componentWillUnmount() {
    if (this.runningInterval !== undefined) {
      clearInterval(this.runningInterval);
      this.runningInterval = undefined;
    }
    dispatch(Actions.clearData(undefined));
    this.pusherEventSub.unsubscribe();
  }

  public componentWillReceiveProps(nextProps: IPollProps) {
    const lastProps = this.getProps();
    if (!this.canSeeResult(lastProps) && this.canSeeResult(nextProps)) {
      dispatch(Actions.clearData(undefined));
      dispatch(Actions.fetch(nextProps.poll._id, nextProps.courseRole, nextProps.class._id));
    }
  }

  private canSeeResult(props: IPollProps = this.getProps()) {
    const { poll, courseRole } = props;
    return (
      courseRole !== 'student' ||
      this.isSubmitted(props) ||
      (!poll.details.allowLate && datetime.unix() > poll.details.dueDateTime)
    );
  }

  private isSubmitted(props: IPollProps = this.getProps()) {
    const { poll } = props;
    return (
      poll.userData && ['submitted', 'late'].includes(<'submitted' | 'late'>poll.userData.status)
    );
  }
  private isAccessible: boolean | undefined = false;

  public render() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const isMobile = getStore().getState().app.isMobile;
    return h('div.activity__wrapper', [
      isMobile || isIpad ? this.actionBar() : null,
      ContentView(
        h(
          utils.getHTMLTagSelector('div', [
            'activity',
            this.isActionBarVisible() ? 'with-action-bar' : '',
          ]),
          [
            h('div.activity__content', [
              isMobile || isIpad ? null : this.actionBar(),
              this.header(),
              this.isPreClassWarningVisible() ? this.preClassTimeWarning() : null,
              this.detailsSection(),
              this.creator(),
              this.warnings(),
              ...this.dialogs(),
              this.submitButton(),
              this.submitButtonTip(),
            ]),
            this.submissionStatus(),
          ]
        )
      ),
    ]);
  }

  private submissionStatus() {
    const { courseRole, poll } = this.getProps();
    if (courseRole !== 'student') {
      return null;
    } else {
      if (poll.userData && poll.userData.submittedOn) {
        return this.bottomFloatingBar(
          `Submitted on ${datetime.format(
            poll.userData.submittedOn,
            'MMM DD, YYYY [at] hh:mm A'
          )} ${poll.userData.status === 'late' ? '(Late)' : ''}`,
          poll.userData.status === 'late' ? colors.orange : colors.green
        );
      } else if (!this.isSubmitted() && this.isPastDue()) {
        return this.bottomFloatingBar('Unsubmitted. You missed the deadline.', colors.red);
      } else {
        return null;
      }
    }
  }

  private isPastDue() {
    const { poll, courseRole } = this.getProps();
    return (
      courseRole === 'student' &&
      !poll.details.allowLate &&
      ((poll.details.dueDateType === 'manual' && poll.details.dueDateTime !== -1) ||
        (poll.details.dueDateType !== 'manual' && datetime.unix() > poll.details.dueDateTime))
    );
  }

  private bottomFloatingBar(text: string, color: string) {
    return BottomFloatingBar({
      text,
      color,
    });
  }

  private actionBar() {
    if (!this.isActionBarVisible()) return null;
    return ActionBar(
      [
        {
          label: 'Delete',
          onclick: () => this.openDeleteDialog(),
          classNames: ['red'],
          icon: {
            type: 'view',
            view: Icon(icons.trash),
          },
          disabled: !this.isDeleteEnabled(),
        },
        {
          label: 'Copy',
          onclick: () => this.openCopyDialog(),
          classNames: ['blue'],
          icon: {
            type: 'view',
            view: Icon(icons.copy),
          },
          disabled: !this.isCopyEnabled(),
        },
        {
          label: 'Move',
          onclick: () => this.openMoveDialog(),
          classNames: ['blue'],
          icon: {
            type: 'view',
            view: Icon(icons.move),
          },
          disabled: !this.isMoveEnabled(),
        },
        {
          label: this.canEditPoll() ? 'Edit' : 'Publish',
          onclick: () =>
            this.canEditPoll() ? this.openEditPollDialog() : this.openPublishDialog(),
          classNames: ['orange'],
          icon: {
            type: 'view',
            view: this.canEditPoll() ? Icon(icons.pencil) : Icon(icons.publish),
          },
          disabled: !(this.isPublishButtonEnabled() || this.canEditPoll()),
        },
      ],
      this.isAccessible
    );
  }

  private canEditPoll() {
    const { course, isClassIncharge, poll } = this.getProps();
    return !course.isArchived && poll.details.published === 1 && isClassIncharge;
  }

  private isPublishButtonEnabled() {
    const { course, isClassIncharge, poll } = this.getProps();
    return !course.isArchived && !poll.details.published && isClassIncharge;
  }

  private header() {
    const { poll } = this.getProps();
    const cls = this.getProps().class;
    const toBeDone = {
      preClass: 'Pre-class',
      inClass: 'In-class',
    }[poll.details.toBeDone];

    return h(
      'div.cell',
      {
        tabIndex: this.getTabIndex(),
      },
      [
        h(
          'span.cell__label',
          `${toBeDone} poll for the class on ${datetime.format(
            cls.details.scheStartTime,
            'MMM DD, YYYY [at] hh:mm A'
          )}`
        ),
      ]
    );
  }
  private preClassTimeWarning() {
    const cls = this.getProps().class;
    const timeDiff = datetime.format(
      cls.details.scheStartTime - 30 * 60,
      'MMM DD, YYYY [at] hh:mm A'
    );
    return h('div', style([pad('0.5rem 1rem'), 'mediumGrey', 'thick']), [
      `You must publish this activity by ${timeDiff}` +
        ' for students to attempt it before the class',
    ]);
  }

  private isPreClassWarningVisible() {
    const cls = this.getProps().class;
    const poll = this.getProps().poll;
    return (
      datetime.diff(datetime.fromUnix(cls.details.scheStartTime), datetime.now(), 'minutes') < 60 &&
      poll.details.toBeDone === 'preClass' &&
      poll.details.published === 0
    );
  }

  private canStopManually() {
    const { poll, isClassIncharge } = this.getProps();
    return (
      isClassIncharge && poll.details.dueDateType === 'manual' && poll.details.dueDateTime === -1
    );
  }

  private detailsSection() {
    const question = this.getQuestion();
    const { poll } = this.getProps();

    if (this.isEditEnabled()) {
      return h('div.poll-details-section', [
        this.editResponseTypeCard(),
        this.publishPrefsEditView(),
        this.editTitleCard(),
        this.editQuestionCard(),
        !(poll.details.questionSet && !question)
          ? TipOverlayWrapper({
              targetElement: 'poll-question',
              tip: {
                tipPosition: 'top',
                tipText:
                  'Use this button to add the poll question. You can add' +
                  ' images too as poll options. As of now, each poll can' +
                  ' have only one question.',
              },
              tipKey: 'pollMainFloatingButton',
              isNextAvailable: false,
            })
          : null,
      ]);
    } else {
      const { poll, courseRole } = this.getProps();
      const question = this.getQuestion();
      const { isMobile } = getStore().getState().app;
      const isOptionNotSelected = this.getState().attemptOptionNum === null;
      const hasAttachments = question && question.details.description.attachments.length > 0;

      const isOpen =
        (poll.details.dueDateType === 'manual' && poll.details.dueDateTime === -1) ||
        poll.details.dueDateTime > datetime.unix();
      const publishDiff = datetime.fromNow(datetime.fromUnix(poll.details.publishedOn));

      const infoCell = (label: string, value: string) =>
        h(
          'div.cell',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [h('span.cell__label', label), h('span.cell__value', value)]
        );

      const body = h('div.activity__section', [
        infoCell("Students' responses type", poll.details.isAnon ? 'Anonymous' : 'Not anonymous'),
        this.canStopManually()
          ? h('div.poll__manual-stop__wrapper', [
              h(
                'div.ripple.poll__manual-stop',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  onClick: () =>
                    this.setState({
                      isStopDialogOpen: true,
                    }),
                },
                [h('span', 'Poll is currently open'), h('span.poll__manual-stop__button', 'CLOSE')]
              ),
              h(
                'div.poll__manual-stop__note',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                },
                `Published ${publishDiff}`
              ),
            ])
          : infoCell(
              isOpen ? 'Poll closes' : 'Poll closed',
              poll.details.dueDateType === 'manual' && isOpen
                ? 'when instructor closes'
                : `at ${datetime.format(poll.details.dueDateTime, 'hh:mm A, MMM DD, YYYY')}`
            ),
        infoCell('Late submissions', poll.details.allowLate ? 'Allowed' : 'Not allowed'),

        h(
          'div.activity__title',
          {
            tabIndex: this.getTabIndex(),
            'aria-label': `Poll Title : ${poll.details.title || `Untitled`}`,
          },
          poll.details.title || 'Untitled'
        ),
        this.question(),

        hasAttachments
          ? h('div.activity__attachments', [this.headings('Attachments'), this.attachments()])
          : null,

        h('div.panel', [
          h(
            'div.panel__header',
            {
              tabIndex: this.getTabIndex(),
            },
            poll.details.published &&
              !this.isAttempting() &&
              !(
                courseRole === 'student' &&
                poll.details.deadlineFirst &&
                datetime.unix() < poll.details.dueDateTime
              )
              ? 'Options'
              : poll.details.deadlineFirst
              ? 'Options (Results will appear after deadline)'
              : 'Options (Results will appear after response)'
          ),
          this.optionsView(),
        ]),
      ]);

      if (isMobile && courseRole === 'student') {
        return Dialog(
          {
            open: true,
            title: `Poll ${poll.details.num}`,
            thinHeader: true,
            style: {
              backgroundColor,
            },
            bodyStyle: {
              height: '100%',
              padding: '0 16px',
            },
            primaryAction: {
              id: 'poll-submit-button',
              label: 'SUBMIT',
              mobileLabel: h('i.fa.fa-check', []),
              disabled: isOptionNotSelected,
              onclick: () => this.submitHandler(),
            },
            secondaryAction: {
              id: 'poll-back-button',
              label: 'CANCEL',
              mobileLabel: h('i.fa.fa-arrow-left', []),
              onclick: goBack,
            },
          },
          [h('div.activity', [body]), this.submitButton()]
        );
      }

      return body;
    }
  }

  private contentsHaveBeenEditedByInstructorDialog() {
    const areQuestionsEdited = getStore().getState().polls.areQuestionsEdited;
    if (!areQuestionsEdited) return null;
    return Alert(
      {
        open: true,
        center: true,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('Okay', {
            onclick: async () => {
              const { poll, courseRole, class: cls } = this.getProps();
              await dispatch(Actions.fetch(poll._id, courseRole, cls._id));
            },
          }),
        ],
      },
      ['This poll has been updated. Click Okay to reload the changes.']
    );
  }

  private runningInterval?: NodeJS.Timer;

  private initializeTimer(endTime: number) {
    // always clear any existing timer before starting
    // a new one
    if (this.runningInterval !== undefined) {
      clearInterval(this.runningInterval);
      this.runningInterval = undefined;
    }
    this.runningInterval = setInterval(() => {
      const diff: number = endTime - datetime.unix();

      let hours: number | string = (diff / (60 * 60)) | 0;
      let minutes: number | string = (diff / 60) | 0;
      let seconds: number | string = diff % 60 | 0;

      if (minutes > 60) {
        minutes = minutes - hours * 60;
      }

      const highlightTimer = hours === 0 && minutes === 0 && seconds <= 10 && seconds >= 0;

      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      const textContentHumanized = `Poll closes in another ${
        hours > 0 ? (hours === 1 ? `${hours} hour and` : `${hours} hours and`) : ''
      } ${
        minutes > 0 ? (minutes === 1 ? `${minutes}  minute and` : `${minutes} minutes and`) : ''
      } ${seconds} seconds`;

      if (diff < 1 || this.runningInterval === undefined) {
        const textContent = 'Deadline over';
        this.setTimerText(textContent, 'DeadLine Over');

        if (highlightTimer) {
          this.showAutoSubmitDialog();
        }

        if (this.runningInterval !== undefined) {
          clearInterval(this.runningInterval);
          this.runningInterval = undefined;
        }
      } else {
        const textContent = `Poll closes in ${hours}:${minutes}:${seconds}`;
        this.setTimerText(textContent, textContentHumanized, highlightTimer);
      }
    }, 1000);
  }

  private async setTimerText(
    timerText: string,
    timerTextHumanized: string,
    highlightTimer = false
  ) {
    await this.setState({
      timerText,
      timerTextHumanized,
      highlightTimer,
    });
  }

  private showAutoSubmitDialog() {
    const { poll, courseRole } = this.getProps();

    if (!document.hasFocus()) return;
    if (courseRole !== 'student') return;
    if (poll.userData && poll.userData.status !== 'inProgress') return;

    this.setState({ showAutoSubmitDialog: true });
  }

  private autoSubmitDialog() {
    const { showAutoSubmitDialog, attemptOptionNum } = this.getState();
    const { poll, courseRole } = this.getProps();
    const hasAttempted = !(attemptOptionNum === null || attemptOptionNum === 0);

    if (courseRole !== 'student') return null;
    if (poll.userData && poll.userData.status !== 'inProgress') return null;
    if (!showAutoSubmitDialog) return null;

    return AutoSubmitDialog({
      initialCounter: 5,
      activityType: 'poll',
      open: true,
      hasAttempted: hasAttempted,
      allowLate: poll.details.allowLate,
      onCancel: () => this.setState({ showAutoSubmitDialog: false }),
      onSubmit: () => this.submit('auto'),
    });
  }

  private elem?: HTMLElement;
  private onRef = (elem?: HTMLElement) => {
    if (elem === this.elem) return;
    if (!elem) return;
    if (this.elem === elem) {
      return;
    }
    this.elem = elem;
  };

  private editResponseTypeCard() {
    const { poll } = this.getProps();
    const responseType = poll.details.isAnon ? 'Anonymous' : 'Not anonymous';
    return h(
      'div.panel.panel--inline',
      {
        tabIndex: this.getTabIndex(),
        onclick: () => this.openEditAnonymityDialog(),
      },
      [
        h('div.panel__header', [
          h('span', 'Student responses'),
          h('span.fs-md.fc-light-grey', [h('span', responseType), h('i.fa.fa-pencil')]),
        ]),
      ]
    );
  }

  private showPublishPrefsDialog(isPublishing: boolean) {
    this.lastFocusedElement = document.activeElement;
    utils.unsetTabIndices();
    this.setState({
      isPublishDialogOpen: isPublishing,
      isPublishPrefsDialogVisible: true,
    });
  }

  private publishPrefsEditView() {
    const { poll } = this.getProps();

    return h('div', [
      h(
        'div.panel.panel--inline',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => this.showPublishPrefsDialog(false),
        },
        [
          h('div.panel__header', [
            h('span', 'Publish preferences'),
            h('span.pointer.fs-sm.fc-light-grey', [h('i.fa.fa-pencil')]),
          ]),
        ]
      ),
      poll.details.toBeDone === 'inClass'
        ? h('div.fc-orange', style([pad('0 1rem'), mt('-0.5rem'), mb('1rem')]), [
            `Saving "Publish preferences" right now helps you `,
            `save precious time during the lecture`,
          ])
        : null,
    ]);
  }

  private editPollTitleSave() {
    if (this.getState().editPollDialog.editTitleField.trim() === '') {
      this.setState({
        editPollDialog: {
          ...this.getState().editPollDialog,
          editTitleFieldError: true,
        },
      });
      return;
    }

    this.setState({
      editPollTitle: this.getState().editPollDialog.editTitleField,
      editPollDialog: {
        ...this.getState().editPollDialog,
        isEditingTitle: false,
        editTitleFieldError: false,
      },
    });
  }

  private editPollEditTitleDialog() {
    const { isEditingTitle, editTitleField, editTitleFieldError } = this.getState().editPollDialog;
    if (!isEditingTitle) return null;
    return Alert(
      {
        style: {
          width: '35em',
        },
        title: 'Poll Title',
        open: true,
        hasInput: true,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.getTabIndex(),
            type: 'secondary',
            onclick: () =>
              this.setState({
                editPollDialog: {
                  ...this.getState().editPollDialog,
                  isEditingTitle: false,
                },
              }),
            // .then(() => {
            //     u.resetTabIndices();
            //     let btn = document.getElementById("edit-title");
            //     btn ? btn.focus() : null;
            // })
          }),
          FlatButton('Save', {
            tabIndex: this.getTabIndex(),
            onclick: () => this.editPollTitleSave(),
          }),
        ],
      },
      [
        h('div.textfield-container', style(['flex', 'alignCenter', pad('0.5rem')]), [
          TextField({
            value: editTitleField,
            focusOnMount: true,
            errorText: editTitleFieldError ? 'Please enter a title' : undefined,
            placeholder: 'Enter title here',
            maxLength: 140,
            oninput: (e) =>
              this.setState({
                editPollDialog: {
                  ...this.getState().editPollDialog,
                  editTitleField: e.target.value,
                  editTitleFieldError: false,
                },
              }),
            onenter: () => this.editPollTitleSave(),
          }),
        ]),
      ]
    );
  }

  private editTitleDialog() {
    const { isEditingTitle, editTitleField, editTitleFieldError } = this.getState();
    if (!isEditingTitle) return null;
    return Alert(
      {
        style: {
          width: '35em',
        },
        title: 'Poll Title',
        open: true,
        hasInput: true,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.getTabIndex(),
            type: 'secondary',
            onclick: () =>
              this.setState({
                isEditingTitle: false,
              }).then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-title');
                btn ? btn.focus() : null;
              }),
          }),
          FlatButton('Save', {
            tabIndex: this.getTabIndex(),
            onclick: () => this.saveTitleHandler(),
          }),
        ],
      },
      [
        h('div.textfield-container', style(['flex', 'alignCenter', pad('0.5rem')]), [
          TextField({
            value: editTitleField,
            focusOnMount: true,
            errorText: editTitleFieldError ? 'Please enter a title' : undefined,
            placeholder: 'Enter title here',
            maxLength: 140,
            oninput: (e) =>
              this.setState({
                editTitleField: e.target.value,
                editTitleFieldError: false,
              }),
            onenter: () => this.saveTitleHandler(),
          }),
        ]),
      ]
    );
  }

  private editTitleCard() {
    const { poll } = this.getProps();

    return h('div.panel', this.editableCardAttrs(), [
      h('div.panel__header', [
        h('span', 'Title'),
        h('i.fa.fa-pencil#edit-title', {
          tabIndex: this.getTabIndex(),
          onclick: () => this.editTitleHandler(),
        }),
      ]),
      this.editTitleDialog(),
      h(
        utils.getHTMLTagSelector('div', [
          'panel__content',
          poll.details.title.trim() ? '' : 'fs-sm',
          poll.details.title.trim() ? '' : 'fc-light-grey',
        ]),
        poll.details.title.trim() || [
          "This poll doesn't have a title yet. Click on the ",
          h('i.fa.fa-pencil'),
          ' icon to add a title.',
        ]
      ),
    ]);
  }

  private editPollEditTitleCard() {
    const { editPollTitle } = this.getState();
    return h('div.panel', this.editableCardAttrs(), [
      h('div.panel__header', [
        h('span', 'Title'),
        h('i.fa.fa-pencil#edit-title', {
          tabIndex: this.getTabIndex(),
          onclick: () =>
            this.setState({
              editPollDialog: {
                ...this.getState().editPollDialog,
                isEditingTitle: true,
              },
            }),
        }),
      ]),
      this.editPollEditTitleDialog(),
      h(
        utils.getHTMLTagSelector('div', [
          'panel__content',
          editPollTitle.trim() ? '' : 'fs-sm',
          editPollTitle.trim() ? '' : 'fc-light-grey',
        ]),
        editPollTitle.trim() || [
          "This poll doesn't have a title yet. Click on the ",
          h('i.fa.fa-pencil'),
          ' icon to add a title.',
        ]
      ),
    ]);
  }

  private editQuestionCard() {
    const question = this.getQuestion();
    const { poll } = this.getProps();
    const hasAttachments = question && question.details.description.attachments.length > 0;

    if (poll.details.published) return null;

    if (poll.details.questionSet && !question) {
      return h('div.poll__question-loader', [fullScreenLoader]);
    }

    return h('div.panel#poll-question', this.editableCardAttrs(), [
      h('div.panel__header', [
        h('span', 'Poll question'),
        h('i.fa.fa-pencil', {
          tabIndex: this.getTabIndex(),
          onclick: () => this.showEditQuestionScreen(),
        }),
      ]),
      question
        ? h('div.panel__content.question', [
            this.question(),
            hasAttachments
              ? h('div.activity__attachments', [this.headings('Attachments'), this.attachments()])
              : null,
            this.optionsEditView(),
          ])
        : h('div.panel__content.fs-sm.fc-light-grey', [
            "This poll doesn't have a question yet. Tap on the ",
            h('i.fa.fa-pencil'),
            ' icon to edit the question details and options.',
          ]),
    ]);
  }

  private editPollQuestionCard() {
    const question = this.getState().editPollQuestion;
    const { poll } = this.getProps();
    const hasAttachments = question && question.details.description.attachments.length > 0;
    if (poll.details.questionSet && !question) {
      return h(
        'div',
        style([
          'fullWidth',
          'flex',
          'alignCenter',
          'justifyCenter',
          {
            marginTop: '1.5rem',
            height: '4em',
          },
        ]),
        [fullScreenLoader]
      );
    }
    return h('div.panel#poll-question', this.editableCardAttrs(), [
      h('div.panel__header', [
        h('span', 'Poll question'),
        h('i.fa.fa-pencil', {
          tabIndex: this.getTabIndex(),
          onclick: () => this.showEditPollEditQuestionScreen(),
        }),
      ]),
      h('div.panel__content.question', [
        question
          ? Viewer(question.details.description.text, {
              style: {
                marginBottom: '0.5em',
              },
            })
          : null,
        hasAttachments
          ? h('div.activity__attachments', [
              this.headings('Attachments'),
              this.editPollAttachments(),
            ])
          : null,
        this.editPollOptionsEditView(),
      ]),
    ]);
  }

  private creator() {
    const { poll } = this.getProps();
    const status = poll.details.published ? 'Published' : 'Created';
    const time = poll.details.published ? poll.details.publishedOn : poll.details.createdOn;
    return h('div.user', [
      Avatar(poll.details.createdBy.avatar, poll.details.createdBy.name, {
        className: 'user__avatar',
      }),
      h('div.user__details.fc-light-grey', [
        h('div', `${status} by ${poll.details.createdBy.name}`),
        h('div', datetime.format(time, 'MMM DD, YYYY [at] hh:mm A')),
      ]),
    ]);
  }

  private warnings() {
    const { poll } = this.getProps();
    if (poll.details.published) return null;
    return h('div', style(['orange', mt('1em'), 'small', pad('0.5rem 1rem')]), [
      h(
        'div',
        'The activity must be published by the class-incharge for ' +
          'the students to be able to access it.'
      ),
    ]);
  }

  private getQuestion() {
    return getStore().getState().polls.question;
  }

  private editableCardAttrs() {
    return { tabIndex: this.getTabIndex() };
  }

  private submitButtonTip() {
    const { poll, courseRole } = this.getProps();
    if (courseRole !== 'student' || (poll.userData && poll.userData.status !== 'inProgress'))
      return null;
    if (poll.details.dueDateTime < datetime.unix() && !poll.details.allowLate) {
      return null;
    }

    return TipOverlayWrapper({
      targetElement: 'poll-submit-button',
      tip: {
        tipPosition: 'top',
        tipText:
          'Once you have selected an option, use the ' + 'Submit button to submit your response',
      },
      tipKey: 'pollMainSubmit',
      isNextAvailable: false,
    });
  }

  private submitButton() {
    const { isMobile, scrollbarWidth } = getStore().getState().app;
    const { timerText, timerTextHumanized } = this.getState();
    const { poll, courseRole } = this.getProps();

    if (courseRole !== 'student' || this.isSubmitted() || this.isPastDue()) return null;

    const isOptionNotSelected = this.getState().attemptOptionNum === null;

    const selector = ['.poll__attempt-navigation'];

    if (isMobile) {
      selector.push('mobile');
    }

    if (isIpad) {
      selector.push('ipad');
    }

    if (this.getState().highlightTimer) {
      selector.push('highlight-submit');
    }

    return h('div', [
      !isMobile
        ? FloatingActionBar(
            {
              id: 'poll-submit-button',
              ariaLabel: 'Submit',
              tabIndex: this.isAccessible ? 0 : undefined,
              position: 'bottom-right',
              disabled: isOptionNotSelected,
              onclick: () => this.submitHandler(),
            },
            [h('div', { style: { fontSize: '0.85rem' } }, 'SUBMIT')]
          )
        : null,
      poll.details.dueDateType !== 'manual'
        ? Paper(selector.join('.'), {}, [
            h(
              'span.timer',
              {
                tabIndex: this.getTabIndex(),
                'aria-label': timerTextHumanized,
                style: {
                  transform: `translateX(-${scrollbarWidth}px)`,
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  color: 'inherit',
                },
                ref: this.onRef,
              },
              [timerText]
            ),
          ])
        : null,
      RaisedButton('Submit my response', {
        classNames: ['poll__raised-button'],
      }), // hidden button for padding
    ]);
  }

  private headings(label: string) {
    return h(
      'div',
      style([
        'mediumGrey',
        'thick',
        'x-small',
        {
          marginBottom: '.57em',
          textTransform: 'uppercase',
        },
      ]),
      label
    );
  }

  private async submitHandler() {
    const { poll } = this.getProps();
    if (
      !validateActivitySubmit({
        allowLate: poll.details.allowLate,
        dueDateTime: poll.details.dueDateTime,
        dueDateType: poll.details.dueDateType,
        serverUnix: datetime.unix(),
      })
    ) {
      return;
    }
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isSubmitDialogOpen: true,
    });
  }

  private editQuestionDialog() {
    const {
      isEditingQuestion,
      editQuestionOptionsFields,
      editQuestionDescriptionField,
      editQuestionDescriptionError,
      editQuestionAttachmentsField,
    } = this.getState();
    const isMobile = getStore().getState().app.isMobile;
    if (!isEditingQuestion) return null;
    return Dialog(
      {
        open: true,
        title: 'Poll question',
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.saveQuestion(),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              isEditingQuestion: false,
            }).then(() => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
            }),
        },
        style: {
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          padding: isMobile ? '0' : '0 0.75rem',
        },
      },
      isEditingQuestion
        ? [
            h('div.poll__add-question__details', [
              Editor({
                tabIndex: this.getTabIndex(),
                value: editQuestionDescriptionField,
                title: 'Description',
                subContext: 'question',
                errorText: editQuestionDescriptionError ? 'Please fill this field' : undefined,
                enableTextFormatting: true,
                enableFormulaInput: true,
                enableFileAttachments: true,
                enableImageInput: true,
                attachments: {
                  files: editQuestionAttachmentsField,
                  uploadUrl: urls().pollFileUpload,
                  activityId: this.getProps().poll._id,
                  activityType: 'polls',
                },
                oninput: (value, attachments) =>
                  this.setState({
                    editQuestionDescriptionField: value,
                    editQuestionDescriptionError: false,
                    editQuestionAttachmentsField: attachments,
                  }),
              }),
            ]),
            h('div.poll__add-question__title', 'OPTIONS'),
            h('div.poll__add-question__options', [
              ...editQuestionOptionsFields.map(({ value, id, hasError }, i) =>
                h(
                  'div.option-editor-container',
                  {
                    tabIndex: this.getTabIndex(),
                    key: id.toString(),
                  },
                  [
                    QuestionOptionEditor({
                      selected: false,
                      value,
                      index: i,
                      tabIndex: this.getTabIndex(),
                      subContext: 'question',
                      error: hasError,
                      deleteAction:
                        editQuestionOptionsFields.length > 2 ? () => this.removeOption(i) : null,
                      oninput: (text) =>
                        this.setState({
                          editQuestionOptionsFields: utils.replaceIndex(
                            editQuestionOptionsFields,
                            (o) => ({
                              ...o,
                              value: text,
                              hasError: false,
                            }),
                            i
                          ),
                        }),
                    }),
                  ]
                )
              ),
              DivButton({
                tabIndex: this.getTabIndex(),
                onclick: () => this.addOption(),
                classNames: ['poll__add-question__button'],
                children: [h('i.fa.fa-plus'), h('span', 'Add another option')],
              }),
            ]),
          ]
        : []
    );
  }

  private editPollEditQuestionDialog() {
    const {
      isEditingQuestion,
      editQuestionOptionsFields,
      editQuestionDescriptionField,
      editQuestionDescriptionError,
      editQuestionAttachmentsField,
    } = this.getState().editPollDialog;
    const isMobile = getStore().getState().app.isMobile;
    if (!isEditingQuestion) return null;
    return Dialog(
      {
        open: true,
        title: 'Poll question',
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.editPollSaveQuestion(),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              editPollDialog: {
                ...this.getState().editPollDialog,
                isEditingQuestion: false,
              },
            }),
        },
        style: {
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          padding: isMobile ? '0' : '0 0.75rem',
        },
      },
      [
        h('div.poll__add-question__details', [
          Editor({
            tabIndex: this.getTabIndex(),
            value: editQuestionDescriptionField,
            title: 'Description',
            subContext: 'question',
            errorText: editQuestionDescriptionError ? 'Please fill this field' : undefined,
            enableTextFormatting: true,
            enableFormulaInput: true,
            enableFileAttachments: true,
            enableImageInput: true,
            attachments: {
              files: editQuestionAttachmentsField,
              uploadUrl: urls().pollFileUpload,
              activityId: this.getProps().poll._id,
              activityType: 'polls',
            },
            oninput: (value, attachments) =>
              this.setState({
                editPollDialog: {
                  ...this.getState().editPollDialog,
                  editQuestionDescriptionField: value,
                  editQuestionDescriptionError: false,
                  editQuestionAttachmentsField: attachments,
                },
              }),
          }),
        ]),
        h('div.poll__add-question__title', 'OPTIONS'),
        h('div.poll__add-question__options', [
          ...editQuestionOptionsFields.map(({ value, id, hasError }, i) =>
            h(
              'div.option-editor-container',
              style(
                [],
                {},
                {
                  tabIndex: this.getTabIndex(),
                  key: id.toString(),
                }
              ),
              [
                QuestionOptionEditor({
                  selected: false,
                  value,
                  index: i,
                  tabIndex: this.getTabIndex(),
                  subContext: 'question',
                  error: hasError,
                  deleteAction:
                    editQuestionOptionsFields.length > 2
                      ? () => this.editPollRemoveOption(i)
                      : null,
                  oninput: (text) =>
                    this.setState({
                      editPollDialog: {
                        ...this.getState().editPollDialog,
                        editQuestionOptionsFields: utils.replaceIndex(
                          editQuestionOptionsFields,
                          (o) => ({
                            ...o,
                            value: text,
                            hasError: false,
                          }),
                          i
                        ),
                      },
                    }),
                }),
              ]
            )
          ),
          DivButton({
            tabIndex: this.getTabIndex(),
            classNames: ['poll__add-question__button'],
            onclick: () => this.editPollAddOption(),
            children: [h('i.fa.fa-plus'), h('span', 'Add another option')],
          }),
        ]),
      ]
    );
  }

  private async saveQuestion() {
    const {
      editQuestionAttachmentsField,
      editQuestionOptionsFields,
      editQuestionDescriptionField,
    } = this.getState();
    const errorState: Partial<IPollState> = {};
    let hasError = false;
    if (editQuestionDescriptionField.length === 0) {
      hasError = true;
      errorState.editQuestionDescriptionError = true;
    }
    const emptyOptionIds = editQuestionOptionsFields
      .filter(({ value }) => value.length === 0)
      .map(({ id }) => id);
    if (emptyOptionIds.length > 0) {
      hasError = true;
      errorState.editQuestionOptionsFields = utils.replaceWhere(
        editQuestionOptionsFields,
        (field) => ({ ...field, hasError: true }),
        (field) => emptyOptionIds.includes(field.id)
      );
    }
    if (hasError) {
      await this.setState(errorState);
      return;
    }
    const { poll } = this.getProps();
    if (poll.details.questionSet === 1) {
      const question = getStore().getState().polls.question;
      if (!question) return;
      await dispatch(
        Actions.questionEdit({
          classId: this.getProps().class._id,
          activityId: poll._id,
          activityType: 'polls',
          description: {
            text: editQuestionDescriptionField,
            attachments: editQuestionAttachmentsField,
          },
          options: editQuestionOptionsFields.map((o) => ({
            text: o.value,
            type: 'text' as const,
          })),
          questionId: question._id,
        })
      );
    } else {
      await dispatch(
        Actions.questionAdd({
          classId: this.getProps().class._id,
          activityId: poll._id,
          activityType: 'polls',
          description: {
            text: editQuestionDescriptionField,
            attachments: editQuestionAttachmentsField,
          },
          options: editQuestionOptionsFields.map((o) => ({
            text: o.value,
            type: 'text' as const,
          })),
        })
      );
      googleAnalytics.questionsAdded('poll', 'mcq');
    }
    await this.setState({
      isEditingQuestion: false,
    }).then(() => {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    });
  }

  private async editPollSaveQuestion() {
    const {
      editQuestionAttachmentsField,
      editQuestionOptionsFields,
      editQuestionDescriptionField,
    } = this.getState().editPollDialog;
    const errorState: any = {};
    let hasError = false;
    if (editQuestionDescriptionField.length === 0) {
      hasError = true;
      errorState.editQuestionDescriptionError = true;
    }
    const emptyOptionIds = editQuestionOptionsFields
      .filter(({ value }) => value.length === 0)
      .map(({ id }) => id);
    if (emptyOptionIds.length > 0) {
      hasError = true;
      errorState.editQuestionOptionsFields = utils.replaceWhere(
        editQuestionOptionsFields,
        (field) => ({ ...field, hasError: true }),
        (field) => emptyOptionIds.includes(field.id)
      );
    }
    if (hasError) {
      await this.setState(errorState);
      return;
    }
    const question = this.getState().editPollQuestion;
    if (question) {
      this.setState({
        ...this.getState(),
        editPollDialog: {
          ...this.getState().editPollDialog,
          isEditingQuestion: false,
        },
        editPollQuestionOptionsFields: editQuestionOptionsFields,
        editPollQuestionAttachments: editQuestionAttachmentsField,
        editPollQuestion: {
          ...question,
          details: {
            ...question.details,
            description: {
              ...question.details.description,
              text: editQuestionDescriptionField,
              attachments: editQuestionAttachmentsField,
            },
            options: editQuestionOptionsFields.map((o, i) => ({
              num: i + 1,
              text: o.value,
              type: 'text' as const,
            })),
          },
        },
      });
    }
  }

  private maxOptionLimitsReachedAlert() {
    const { isMaxOptionLimitsReachedOpen } = this.getState();

    if (!isMaxOptionLimitsReachedOpen) return null;

    return Alert(
      {
        open: true,
        style: { width: '35em' },
        actions: [
          FlatButton('Okay', {
            onclick: () => {
              this.setState({
                isMaxOptionLimitsReachedOpen: false,
              });
            },
          }),
        ],
      },
      ['A poll can have at most 26 options.']
    );
  }

  private async addOption() {
    const { nextOptionId: nextId, editQuestionOptionsFields } = this.getState();

    if (editQuestionOptionsFields.length >= 26) {
      this.setState({
        isMaxOptionLimitsReachedOpen: true,
      });
      return;
    }

    await this.setState({
      editQuestionOptionsFields: this.getState().editQuestionOptionsFields.concat([
        {
          id: nextId,
          hasError: false,
          value: '',
        },
      ]),
      nextOptionId: nextId + 1,
    });
  }

  private async editPollAddOption() {
    const nextId = this.getState().editPollDialog.nextOptionId;
    await this.setState({
      editPollDialog: {
        ...this.getState().editPollDialog,
        editQuestionOptionsFields: this.getState().editPollDialog.editQuestionOptionsFields.concat([
          {
            id: nextId,
            hasError: false,
            value: '',
          },
        ]),
        nextOptionId: nextId + 1,
      },
    });
  }

  private async removeOption(index: number) {
    await this.setState({
      editQuestionOptionsFields: utils.removeIndex(
        this.getState().editQuestionOptionsFields,
        index
      ),
    });
  }

  private async editPollRemoveOption(index: number) {
    await this.setState({
      editPollDialog: {
        ...this.getState().editPollDialog,
        editQuestionOptionsFields: utils.removeIndex(
          this.getState().editPollDialog.editQuestionOptionsFields,
          index
        ),
      },
    });
  }

  private question() {
    const poll = this.getProps().poll;
    if (poll.details.questionSet === 0) {
      return null;
    }
    const question = getStore().getState().polls.question;
    return h(
      'div.activity__description',
      {
        tabIndex: this.getTabIndex(),
        'aria-label': `Poll Question:
            ${question !== null ? question.details.description.text : ``}`,
      },
      question === null
        ? [
            Loader({
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '0.5em',
              marginBottom: '0.5em',
            }),
          ]
        : [
            Viewer(question.details.description.text, {
              style: {
                marginBottom: '0.5em',
              },
            }),
          ]
    );
  }

  private isAttempting() {
    const { courseRole, poll } = this.getProps();
    return (
      courseRole === 'student' &&
      poll.userData &&
      poll.userData.status === 'inProgress' &&
      (poll.details.allowLate === 1 ||
        (poll.details.dueDateType === 'manual' && poll.details.dueDateTime === -1) ||
        datetime.unix() < poll.details.dueDateTime)
    );
  }

  private optionsEditView() {
    const question = this.getQuestion();
    if (!question) return null;

    const isAttempting = this.isAttempting();
    return h(
      'div.poll__options',
      question.details.options.map((o) => {
        const optionSelected =
          (isAttempting && this.getState().attemptOptionNum === o.num) ||
          getStore().getState().polls.optionSelected === o.num;

        return h(
          u.getHTMLTagSelector('div', [
            'poll__option',
            isAttempting ? 'pointer' : '',
            optionSelected ? 'selected' : '',
          ]),
          {
            key: o.num.toString(),
            onclick: isAttempting ? () => this.selectOption(o.num) : undefined,
          },
          [
            h('div.poll__option__header', [
              h('div.poll__option__title', [
                isAttempting
                  ? RadioButton({
                      selected: optionSelected,
                      style: {
                        marginRight: '0.5rem',
                      },
                    })
                  : null,
                h('span', `OPTION ${utils.indexToAlphabet(o.num - 1)}`),
              ]),
            ]),
            Viewer(o.text),
          ]
        );
      })
    );
  }

  private editPollOptionsEditView() {
    const question = this.getState().editPollQuestion;
    if (!question) return null;

    const isAttempting = this.isAttempting();
    return h(
      'div.poll__options',
      question.details.options.map((o) => {
        const optionSelected =
          (isAttempting && this.getState().attemptOptionNum === o.num) ||
          getStore().getState().polls.optionSelected === o.num;

        return h(
          u.getHTMLTagSelector('div', [
            'poll__option',
            isAttempting ? 'pointer' : '',
            optionSelected ? 'selected' : '',
          ]),
          {
            key: o.num.toString(),
            onclick: isAttempting ? () => this.selectOption(o.num) : undefined,
          },
          [
            h('div.poll__option__header', [
              h('div.poll__option__title', [
                isAttempting
                  ? RadioButton({
                      selected: optionSelected,
                      style: {
                        marginRight: '0.5rem',
                      },
                    })
                  : null,
                h('span', `OPTION ${utils.indexToAlphabet(o.num - 1)}`),
              ]),
            ]),
            Viewer(o.text),
          ]
        );
      })
    );
  }

  private optionsView() {
    const { poll, courseRole } = this.getProps();
    const question = this.getQuestion();
    if (!question) return null;

    const isAttempting = this.isAttempting();
    const deadlineNotPast =
      (poll.details.dueDateType === 'manual' && poll.details.dueDateTime === -1) ||
      (poll.details.dueDateType !== 'manual' && datetime.unix() < poll.details.dueDateTime);

    return h(
      'div.panel__content',
      question.details.options.map((o) => {
        const numSelected = question.stats
          ? question.stats.optionSelection[o.num - 1].numSelected
          : 0;
        const numAttempted = question.stats ? question.stats.numAttempted : 0;
        const percentageSelected = numAttempted > 0 ? (numSelected * 100) / numAttempted : 0;

        const optionSelected =
          (isAttempting && this.getState().attemptOptionNum === o.num) ||
          getStore().getState().polls.optionSelected === o.num;

        return h(
          u.getHTMLTagSelector('div', [
            'poll__option',
            isAttempting ? 'pointer' : '',
            optionSelected ? 'selected' : '',
          ]),
          {
            tabIndex: this.getTabIndex(),
            'aria-label': `OPTION ${utils.indexToAlphabet(o.num - 1)} ${o.text}`,
            key: o.num.toString(),
            onclick: isAttempting ? () => this.selectOption(o.num) : undefined,
          },
          [
            h('div.poll__option__header', [
              h('div.poll__option__title', [
                isAttempting
                  ? RadioButton({
                      selected: optionSelected,
                      style: {
                        marginRight: '0.5rem',
                      },
                    })
                  : null,
                h('span', `OPTION ${utils.indexToAlphabet(o.num - 1)}`),
              ]),
              ...(poll.details.published &&
              !isAttempting &&
              !(courseRole === 'student' && poll.details.deadlineFirst && deadlineNotPast)
                ? [
                    ProgressBar(percentageSelected, {
                      className: 'poll__option__progress',
                    }),
                    h('span.poll__option__progress-metrics', [
                      `${numSelected}/${numAttempted}`,
                      ` (${Math.floor(percentageSelected)}%)`,
                    ]),
                  ]
                : []),
            ]),
            Viewer(o.text),
          ]
        );
      })
    );
  }

  private attachments() {
    const poll = this.getProps();
    const question = this.getQuestion();
    if (!question || question.details.description.attachments.length < 1) return null;
    return h('div.attachments', style([mt('0.5rem')]), [
      ...question.details.description.attachments.map((a) =>
        AttachmentViewer({
          key: a.name,
          attachment: a,
          downloadUrl: urls().pollFileDownload,
          downloadRequest: {
            pollId: poll.poll._id,
            fileName: a.name,
          },
        })
      ),
    ]);
  }
  private editPollAttachments() {
    const poll = this.getProps();
    const attachments = this.getState().editPollQuestionAttachments;
    return h('div.attachments', style([mt('0.5rem')]), [
      ...attachments.map((a) =>
        AttachmentViewer({
          key: a.name,
          attachment: a,
          downloadUrl: urls().pollFileDownload,
          hideDownloadIcon: true,
          downloadRequest: {
            pollId: poll.poll._id,
            fileName: a.name,
          },
        })
      ),
    ]);
  }

  private async selectOption(num: number) {
    await this.setState({
      attemptOptionNum: num,
    });
  }

  private async openEditAnonymityDialog() {
    const poll = this.getProps().poll;
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isResponseTypeMenyOpen: true,
      responseTypeField: poll.details.isAnon,
    });
  }

  private changeAnonymity = async (isAnon: 0 | 1) => {
    const { responseTypeField } = this.getState();
    if (responseTypeField !== isAnon) {
      await this.editPoll({ isAnon });
    }
    this.closeEditAnonymityMenu();
  };

  private closeEditAnonymityMenu = async () => {
    const poll = this.getProps().poll;
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isResponseTypeMenyOpen: false,
      responseTypeField: poll.details.isAnon,
    });
  };

  private async showEditPollEditQuestionScreen() {
    const question = this.getState().editPollQuestion;
    this.setState({
      editPollDialog: {
        ...this.getState().editPollDialog,
        isEditingQuestion: true,
        editQuestionAttachmentsField: question ? question.details.description.attachments : [],
        editQuestionDescriptionField: question ? question.details.description.text : '',
        editQuestionDescriptionError: false,
        editQuestionOptionsFields: question
          ? question.details.options.map((o, index) => ({
              value: o.text,
              hasError: false,
              id: index,
            }))
          : [
              {
                value: '',
                hasError: false,
                id: 0,
              },
              {
                value: '',
                hasError: false,
                id: 1,
              },
            ],
        nextOptionId: question ? question.details.options.length : 3,
      },
    });
  }

  private async showEditQuestionScreen() {
    const question = getStore().getState().polls.question;
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isEditingQuestion: true,
      editQuestionAttachmentsField: question ? question.details.description.attachments : [],
      editQuestionDescriptionField: question ? question.details.description.text : '',
      editQuestionDescriptionError: false,
      editQuestionOptionsFields: question
        ? question.details.options.map((o, index) => ({
            value: o.text,
            hasError: false,
            id: index,
          }))
        : [
            {
              value: '',
              hasError: false,
              id: 0,
            },
            {
              value: '',
              hasError: false,
              id: 1,
            },
          ],
      nextOptionId: question ? question.details.options.length : 3,
    });
  }

  private dialogs() {
    return [
      this.isDeleteEnabled() ? this.deleteDialog() : null,
      this.isMoveEnabled() ? this.moveDialog() : null,
      this.isEditEnabled() ? this.anonymityDialog() : null,
      this.canStopManually() ? this.stopPollDialog() : null,
      this.isEditEnabled() ? this.editQuestionDialog() : null,
      this.canEditPoll() ? this.editPollDialog() : null,
      this.isCopyEnabled() ? this.copyDialog() : null,
      this.isEditEnabled() || this.isPublishEnabled() ? this.publishPrefsDialog() : null,

      this.contentsHaveBeenEditedByInstructorDialog(),
      this.submitDialog(),
      this.autoSubmitDialog(),
      this.maxOptionLimitsReachedAlert(),
    ];
  }

  private copyDialog() {
    const props = this.getProps();
    const { isCopyDialogOpen } = this.getState();
    if (!isCopyDialogOpen) return null;
    return CopyActivityDialog({
      open: true,
      classId: props.class._id,
      activityId: props.poll._id,
      activityType: 'polls',
      onDone: () => this.closeCopyDialog(),
      onCancel: () => this.closeCopyDialog(),
    });
  }

  private async closeCopyDialog() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isCopyDialogOpen: false,
    });
  }

  private submitDialog() {
    const { poll, courseRole } = this.getProps();
    const { isSubmitDialogOpen } = this.getState();

    if (courseRole !== 'student') return null;
    if (poll.userData && poll.userData.status !== 'inProgress') return null;
    if (!isSubmitDialogOpen) return null;

    return Alert(
      {
        open: true,
        ariaLabel: 'Are you sure you want to submit this poll?',
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayGreen,
        },
        bodyStyle: {
          paddingBottom: '0',
        },
        actions: [
          FlatButton('CANCEL', {
            type: 'secondary',
            ariaLabel: 'cancel button',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              this.setState({
                isSubmitDialogOpen: false,
              });
            },
          }),
          FlatButton('SUBMIT', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.submit('manual'),
          }),
        ],
      },
      [
        h(
          'div',
          style(
            ['large', mb('1em')],
            {},
            {
              tabIndex: this.getTabIndex(),
            }
          ),
          'Are you sure you want to submit this poll?'
        ),
      ]
    );
  }

  private getTabIndex() {
    return this.isAccessible ? 0 : undefined;
  }

  @requiresRole('student')
  private async submit(submissionType: 'auto' | 'manual') {
    u.resetTabIndices();
    const { poll } = this.getProps();
    const cls = this.getProps().class;
    const optionNum = this.getState().attemptOptionNum;

    if (optionNum === null) return;

    const question = getStore().getState().polls.question;

    if (!question) return;
    if (optionNum === 0) return;

    console.log('poll due time:', poll.details.dueDateTime);
    console.log('request sent at:', datetime.unix());
    await dispatch(
      Actions.submit(
        {
          pollId: poll._id,
          classId: cls._id,
          submission: [
            {
              questionId: question._id,
              optionSelected: optionNum,
            },
          ],
          localTime: datetime.format(datetime.now(), 'YYYYMMDDTHH:mm'),
          submissionType,
        },
        poll.details.toBeDone
      )
    );

    await this.setState({
      isSubmitDialogOpen: false,
      attemptOptionNum: null,
    });
  }

  private isPublishDisabled() {
    const originalQuestion = this.getQuestion();
    const updatedQuestion = this.getState().editPollQuestion;
    return (
      this.getState().editPollTitle === this.getProps().poll.details.title &&
      lodash.isEqual(originalQuestion, updatedQuestion)
    );
  }

  private editPollDialog() {
    const { iseditPollDialogOpen } = this.getState();
    if (!iseditPollDialogOpen) return null;
    return Dialog(
      {
        open: true,
        title: 'Editing Poll',
        style: {
          maxWidth: '40rem',
          padding: '0em',
          paddingBottom: '1em',
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          padding: '0em',
          paddingLeft: '0em',
          paddingRight: '0em',
        },
        primaryAction: {
          label: 'UPDATE',
          disabled: this.isPublishDisabled(),
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.editPollonClickPublishHandler(),
        },
        secondaryAction: {
          label: 'Cancel',
          onclick: () => this.setState({ iseditPollDialogOpen: false }),
          mobileLabel: h('i.fa.fa-times', []),
        },
      },
      [
        h('div.poll-details-section', style([pad('1rem')]), [
          this.editPollEditTitleCard(),
          this.getProps().poll.stats.numSubmitted === 0
            ? this.editPollQuestionCard()
            : h('div', style(['orange', mt('1em'), 'flex', 'small', pad('0.5rem 1rem')]), [
                h('i.fa.fa-exclamation-triangle', style([mr('0.5rem')])),
                h(
                  'div',
                  'You cannot edit the poll question and options ' +
                    'after students have responded to the poll'
                ),
              ]),
          this.editPollEditQuestionDialog(),
          this.editPollPublishDialog(),
        ]),
      ]
    );
  }

  private closePublishPrefsDialog = () => {
    this.setState({
      isPublishDialogOpen: false,
      isPublishPrefsDialogVisible: false,
    });
    utils.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
  };

  private afterPublish = () => {
    const { poll } = this.getProps();
    const question = getStore().getState().polls.question;

    if (question) {
      this.setState({
        editPollTitle: poll.details.title,
        editPollQuestion: question,
        editPollQuestionOptionsFields: question.details.options.map((option) => ({
          id: option.num,
          value: option.text,
          hasError: false,
        })),
        editPollQuestionAttachments: poll.details.attachments,
        editPollDialog: {
          isEditingTitle: false,
          editTitleField: poll.details.title,
          editTitleFieldError: false,
          isEditingQuestion: false,
          editQuestionAttachmentsField: poll.details.attachments,
          editQuestionDescriptionField: question ? question.details.description.text : '',
          editQuestionDescriptionError: false,
          editQuestionOptionsFields: [],
          nextOptionId: 2,
          toNotify: 1,
        },
      });
    }
    this.closePublishPrefsDialog();
  };

  private publishPrefsDialog() {
    const { class: cls, poll } = this.getProps();
    const { isPublishPrefsDialogVisible, isPublishDialogOpen } = this.getState();
    if (!isPublishDialogOpen && !isPublishPrefsDialogVisible) return null;
    return PollPrefsDialog({
      cls,
      poll,
      isPublishing: isPublishDialogOpen,
      open: true,
      onPublish: this.afterPublish,
      onClose: this.closePublishPrefsDialog,
    });
  }

  private stopPollDialog() {
    const { isStopDialogOpen } = this.getState();
    const { poll, class: cls } = this.getProps();
    if (!isStopDialogOpen) return null;
    return Alert(
      {
        open: true,
        title: h('div.fc-orange', 'Stop Activity'),
        actions: [
          FlatButton('Cancel', {
            type: 'primary',
            onclick: () =>
              this.setState({
                isStopDialogOpen: false,
              }),
          }),
          FlatButton('Stop', {
            type: 'secondary',
            onclick: () => {
              dispatch(
                Actions.stopPoll({
                  activityId: poll._id,
                  classId: cls._id,
                  activityType: 'polls',
                  localTime: datetime.format(new Date(), 'YYYYMMDDTHH:mm'),
                })
              );
              this.setState({
                isStopDialogOpen: false,
              });
            },
          }),
        ],
      },
      [
        h('div', [
          'To avoid confusion and surprised groans, ',
          'please announce to the class that you’re going to stop accepting responses',
        ]),
      ]
    );
  }

  private editPollPublishDialog() {
    const { isEditPollPublishDialogOpen } = this.getState();
    if (!isEditPollPublishDialogOpen) return null;
    return Alert(
      {
        open: true,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('NO', {
            tabIndex: this.getTabIndex(),
            type: 'secondary',
            onclick: () =>
              this.setState({
                isEditPollPublishDialogOpen: false,
              }),
          }),
          FlatButton('YES', {
            tabIndex: this.getTabIndex(),
            onclick: () => this.editPollPublish(),
          }),
        ],
      },
      [
        h('div', [
          h('p', 'Are you sure you want to publish these changes?'),
          h('div', style(['flex', 'fullWidth', pt('1rem'), 'whiteBackground', 'spaceBetween']), [
            h('span', 'Send notifications to students'),
            Toggle({
              selected: this.getState().editPollDialog.toNotify === 1,
              ontoggle: () =>
                this.setState({
                  editPollDialog: {
                    ...this.getState().editPollDialog,
                    toNotify: this.getState().editPollDialog.toNotify === 0 ? 1 : 0,
                  },
                }),
            }),
          ]),
        ]),
      ]
    );
  }

  private async editPollonClickPublishHandler() {
    this.setState({
      isEditPollPublishDialogOpen: true,
    });
    // await this.updatePublishedPoll();
  }

  private async editPollPublish() {
    const { editPollQuestion } = this.getState();
    const questionId = editPollQuestion ? editPollQuestion._id : '';
    if (this.getProps().poll.stats.numSubmitted === 0) {
      await dispatch(
        Actions.updatePublishedPoll({
          classId: this.getProps().class._id,
          pollId: this.getProps().poll._id,
          title: this.getState().editPollTitle,
          questionId: questionId,
          description: {
            text: editPollQuestion ? editPollQuestion.details.description.text : '',
            attachments: this.getState().editPollQuestionAttachments,
          },
          options: this.getState().editPollQuestionOptionsFields.map((o) => ({
            text: o.value,
            type: 'text' as const,
          })),
          toNotify: this.getState().editPollDialog.toNotify,
        })
      ).then(() => {
        this.setState({
          isEditPollPublishDialogOpen: false,
          iseditPollDialogOpen: false,
        });
      });
    } else {
      await dispatch(
        Actions.updatePublishedPoll({
          classId: this.getProps().class._id,
          pollId: this.getProps().poll._id,
          title: this.getState().editPollTitle,
          questionId: questionId,
          toNotify: this.getState().editPollDialog.toNotify,
        })
      ).then(() => {
        this.setState({
          isEditPollPublishDialogOpen: false,
          iseditPollDialogOpen: false,
        });
      });
    }
  }

  private anonymityDialog() {
    const { isResponseTypeMenyOpen, responseTypeField } = this.getState();
    if (!isResponseTypeMenyOpen) return null;
    return ResponseTypeMenu({
      open: true,
      initialIsAnon: responseTypeField,
      onCancel: this.closeEditAnonymityMenu,
      onSelect: this.changeAnonymity,
    });
  }

  private deleteDialog() {
    const { isDeleteDialogOpen } = this.getState();
    if (!isDeleteDialogOpen) return null;
    return Alert(
      {
        open: true,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('NO', {
            tabIndex: this.getTabIndex(),
            type: 'secondary',
            onclick: () =>
              this.setState({
                isDeleteDialogOpen: false,
              }).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              }),
          }),
          FlatButton('YES', {
            tabIndex: this.getTabIndex(),
            onclick: () =>
              this.deletePoll().then(() => {
                u.resetTabIndices();
              }),
          }),
        ],
      },
      ['Are you sure you want to delete this poll?']
    );
  }

  private async deletePoll() {
    const poll = this.getProps().poll;
    const cls = this.getProps().class;
    await dispatch(
      Actions.deletePoll(
        {
          classId: cls._id,
          pollId: poll._id,
          toBeDone: poll.details.toBeDone,
        },
        () => {
          goBack();
        }
      )
    );
    googleAnalytics.activityDeleted(
      'poll',
      poll.details.toBeDone === 'preClass' ? 'pre-class' : 'in-class'
    );
    await this.setState({
      isDeleteDialogOpen: false,
    });
  }

  private moveDialog() {
    const { isMoveDialogOpen } = this.getState();
    if (!isMoveDialogOpen) return null;
    return MoveActivityDialog({
      open: true,
      class: this.getProps().class,
      activity: this.getProps().poll,
      oncancel: () => this.closeMoveDialog(),
    });
  }

  private async closeMoveDialog() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isMoveDialogOpen: false,
    });
  }

  private async saveTitleHandler() {
    const { editTitleField } = this.getState();
    if (editTitleField.length < 1) {
      await this.setState({
        editTitleFieldError: true,
      });
      return;
    }

    await this.editPoll({
      title: editTitleField,
    });
    await this.setState({
      isEditingTitle: false,
    }).then(() => {
      u.resetTabIndices();
      const btn = document.getElementById('edit-title');
      btn ? btn.focus() : null;
    });
  }

  private async editPoll(
    data: Partial<{
      title: string;
      isAnon: 0 | 1;
    }>
  ) {
    const poll = this.getProps().poll;
    await dispatch(
      Actions.editPoll({
        classId: this.getProps().class._id,
        pollId: poll._id,
        isAnon: data.isAnon !== undefined ? data.isAnon : poll.details.isAnon,
        title: data.title || poll.details.title,
      })
    );
  }
  private lastFocusedElement: null | Element = null;
  private async openDeleteDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isDeleteDialogOpen: true,
    });
  }

  private isEditEnabled() {
    const { isClassIncharge } = this.getProps();
    return (
      isClassIncharge &&
      !this.getProps().course.isArchived &&
      this.getProps().poll.details.published === 0
    );
  }

  private async openEditPollDialog() {
    const poll = this.getProps().poll;
    const question = getStore().getState().polls.question;
    if (question) {
      this.setState({
        iseditPollDialogOpen: true,
        editPollTitle: poll.details.title,
        editPollQuestion: question,
        editPollQuestionOptionsFields: question.details.options.map((option) => ({
          id: option.num,
          value: option.text,
          hasError: false,
        })),
        editPollQuestionAttachments: question.details.description.attachments,
        editPollDialog: {
          isEditingTitle: false,
          editTitleField: poll.details.title,
          editTitleFieldError: false,
          isEditingQuestion: false,
          editQuestionAttachmentsField: poll.details.attachments,
          editQuestionDescriptionField: question ? question.details.description.text : '',
          editQuestionDescriptionError: false,
          editQuestionOptionsFields: [],
          nextOptionId: 2,
          toNotify: 1,
        },
      });
    }
  }

  private async openPublishDialog() {
    const { poll, course } = this.getProps();

    const valid = validateActivityPublish({
      course,
      activityType: 'class',
      cls: this.getProps().class,
      activity: poll,
    });

    if (!valid) return;
    this.showPublishPrefsDialog(true);
  }

  private async editTitleHandler() {
    const { poll } = this.getProps();
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    this.setState({
      isEditingTitle: true,
      editTitleField: poll.details.title,
      editTitleFieldError: false,
    });
  }

  private async openMoveDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isMoveDialogOpen: true,
    });
  }

  private async openCopyDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isCopyDialogOpen: true,
    });
  }

  private isActionBarVisible() {
    return (
      this.isPublishEnabled() ||
      this.isDeleteEnabled() ||
      this.isMoveEnabled() ||
      this.isCopyEnabled()
    );
  }

  private isPublishEnabled() {
    const { poll, course, isClassIncharge } = this.getProps();
    const cls = this.getProps().class;
    if (poll.details.published) return false;
    if (course.isArchived) return false;
    if (!isClassIncharge) return false;
    if (!poll.details.questionSet) return false;
    if (poll.details.toBeDone === 'preClass') {
      return (
        datetime.diff(datetime.fromUnix(cls.details.scheStartTime), datetime.now(), 'minutes') >= 30
      );
    } else {
      return cls.details.status === 'inSession';
    }
  }

  private isMoveEnabled() {
    const { course, poll, isClassIncharge } = this.getProps();
    return !course.isArchived && !poll.details.published && isClassIncharge;
  }

  private isCopyEnabled() {
    const { courseRole } = this.getProps();
    return courseRole === 'admin' || courseRole === 'instructor';
  }

  private isDeleteEnabled() {
    return this.isEditEnabled();
  }
}

export default (props: IPollProps) => h(Poll, props);
