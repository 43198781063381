import 'rxjs/add/operator/debounceTime';

import { Subject, Subscription } from 'rxjs';

import { h, IComponent } from 'core';

import * as DragHandleIcon from 'assets/drag-handle.svg';

import SvgIcon from 'acadly/common/SvgIcon';
import Viewer from 'acadly/rich-text/Viewer';
import { getStore } from 'acadly/store';
import { style } from 'acadly/styles';

const SCROLL_STEP = 5;
export const DRAGGABLE_QUESTION_HEIGHT = 100;

const ZZ_BORDER_HEIGHT = 7;
export const DRAGGABLE_QUESTION_BOTTOM_MARGIN = 16 + ZZ_BORDER_HEIGHT;

const PADDING = 12;
const MAX_CONTENT_HEIGHT = DRAGGABLE_QUESTION_HEIGHT - 2 * PADDING;

export enum DragEvent {
  START,
  MOVE,
  END,
}

export interface QuestionDragUpdate {
  question: IQuizQuestion;
  type: DragEvent;
}

interface DraggableQuestionProps {
  drag$: Subject<QuestionDragUpdate>;
  getContainerRef(): HTMLDivElement | null;
  index: number;
  onRef(el: HTMLDivElement): void;
  question: IQuizQuestion;
}

class DraggableQuestion extends IComponent<DraggableQuestionProps, never> {
  private ref: HTMLDivElement | null = null;

  private dragSub: Subscription;

  private get container() {
    const props = this.getProps();
    return props.getContainerRef();
  }

  private startY: number;
  private deltaY = 0;

  private raf: number | null;

  private disableScroll = (e: TouchEvent) => {
    e.preventDefault();
  };

  private moveFrame = () => {
    const { drag$, question } = this.getProps();

    drag$.next({ question, type: DragEvent.MOVE });
    this.ref.style.transform = `translate3d(0px, ${this.deltaY}px, 8px)`;

    /**
     * once the paint job is done we 'release' animation
     * frame variable to allow next paint job
     */
    this.raf = null;
  };

  private cancelAutoScroll: null | (() => void) = null;

  private autoScroll(target: HTMLElement, direction: 'up' | 'down') {
    let raf: number;

    const step = () => {
      const delta = direction === 'up' ? -SCROLL_STEP : SCROLL_STEP;
      target.scrollBy(0, delta);
      raf = window.requestAnimationFrame(step);
    };

    raf = window.requestAnimationFrame(step);

    return () => window.cancelAnimationFrame(raf);
  }

  private handleDrag = (event: PointerEvent) => {
    /**
     * if no previous request for animation frame - we allow js
     * to proccess 'move' event
     */
    if (!this.raf) {
      this.deltaY = event.clientY - this.startY;
      this.raf = window.requestAnimationFrame(this.moveFrame);
    }

    /**
     * auto scroll to bottom or top when mouse
     * pointer goes in a region of 0-20% or 80%-100%
     * in vertical direction
     */
    const body = this.container.parentElement;

    const { y: startY, height } = body.getBoundingClientRect();
    const mouseRelativePosition = event.clientY - startY;
    const fraction = mouseRelativePosition / height;

    if (!this.cancelAutoScroll && fraction > 0.8) {
      this.cancelAutoScroll = this.autoScroll(body, 'down');
    } else if (!this.cancelAutoScroll && fraction < 0.2) {
      this.cancelAutoScroll = this.autoScroll(body, 'up');
    } else if (this.cancelAutoScroll && fraction < 0.8 && fraction > 0.2) {
      this.cancelAutoScroll();
      this.cancelAutoScroll = null;
    }
  };

  private handeDragEnd = () => {
    document.removeEventListener('touchmove', this.disableScroll);
    document.removeEventListener('pointermove', this.handleDrag);
    document.removeEventListener('pointerup', this.handeDragEnd);
    document.removeEventListener('pointercancel', this.handeDragEnd);

    /**
     * if animation frame was scheduled but the user already stopped interaction
     * we cancel the scheduled frame
     */
    if (this.raf) {
      window.cancelAnimationFrame(this.raf);
      this.raf = null;
    }

    if (this.cancelAutoScroll) {
      this.cancelAutoScroll();
      this.cancelAutoScroll = null;
    }

    this.ref.style.position = 'absolute';
    this.ref.style.transform = '';
    this.ref.style.left = '';
    this.ref.style.zIndex = '';

    this.deltaY = 0;

    const { drag$, question } = this.getProps();

    drag$.next({ question, type: DragEvent.END });
  };

  private handleDragStart = (e: PointerEvent) => {
    const { drag$, question } = this.getProps();

    this.startY = e.clientY;

    this.ref.style.zIndex = '1';
    const { top, left } = this.ref.getBoundingClientRect();
    this.ref.style.top = `${top}px`;
    this.ref.style.left = `${left}px`;
    this.ref.style.transform = 'translateX(0)';
    this.ref.style.position = 'fixed';
    this.ref.classList.add('dragging');

    // disable scrolling in mobile while dragging
    document.addEventListener('touchmove', this.disableScroll, { passive: false });

    document.addEventListener('pointermove', this.handleDrag, { passive: true });
    document.addEventListener('pointerup', this.handeDragEnd, { passive: true });
    document.addEventListener('pointercancel', this.handeDragEnd, { passive: true });

    drag$.next({ question, type: DragEvent.START });
  };

  private pointerDownTarget: HTMLDivElement | HTMLButtonElement | null = null;

  public componentDidMount() {
    if (!this.ref) {
      console.warn('ref not defined!');
      return;
    }

    this.pointerDownTarget = this.ref;

    const { isMobile } = getStore().getState().app;

    if (isMobile) {
      const dragHandle = this.ref.querySelector<HTMLButtonElement>('.drag-handle');
      this.pointerDownTarget = dragHandle || this.ref;
    }

    this.pointerDownTarget.addEventListener('pointerdown', this.handleDragStart, {
      passive: true,
    });
  }

  public componentWillUnmount() {
    this.pointerDownTarget.removeEventListener('pointerdown', this.handleDragStart);
  }

  public render() {
    const { index, question } = this.getProps();
    return h(
      'div.draggable-question', // do NOT change class name as this is used for pointerevent delegation in ReorderQuestionsDialog
      {
        ref: (el: HTMLDivElement) => {
          this.ref = el;
          this.getProps().onRef(el);
        },
        style: {
          top: (DRAGGABLE_QUESTION_HEIGHT + DRAGGABLE_QUESTION_BOTTOM_MARGIN) * index,
        },
      },
      [
        h('div.draggable-question__content', style([{ height: MAX_CONTENT_HEIGHT }]), [
          h('div.draggable-question__title', [
            h('span', `Question ${index + 1}`),
            SvgIcon({
              className: 'drag-handle icon-24px',
              icon: DragHandleIcon,
            }),
          ]),
          Viewer(question.details.description.text, {
            style: { pointerEvents: 'none' },
          }),
        ]),
      ]
    );
  }
}

export default (props: DraggableQuestionProps) => h(DraggableQuestion, props);
