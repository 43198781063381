import { CSS, h, IComponent } from 'core';

import { CALENDAR_DATE_WIDTH, CalendarWeekLabels } from 'acadly/common/Calendar';
import * as dt from 'acadly/datetime';
import { colors, mb, ml, mr, pad, style } from 'acadly/styles';
import * as u from 'acadly/utils';

export interface IMultiDatePickerProps {
  minDate: Date;
  maxDate: Date;
  clashes: Date[];
  selected: Date[];
  onSelect?: (date: Date, selection: 'select' | 'unselect') => any;
  hideMonthHeaders?: boolean;
  style?: CSS;
}
const DATE_WIDTH = CALENDAR_DATE_WIDTH;
export class MultiDatePicker extends IComponent<IMultiDatePickerProps, never> {
  public render() {
    const { minDate, maxDate } = this.getProps();
    const props = this.getProps();
    const minMonth = dt.startOfMonth(minDate);
    const maxMonth = dt.startOfMonth(maxDate);
    const months: Date[] = [];
    let month = minMonth;
    while (month <= maxMonth) {
      months.push(month);
      month = dt.addMonths(month, 1);
    }
    return h(
      'div.multi-date-picker',
      {
        style: {
          width: '40em',
          maxWidth: '100%',
          height: '100%',
          overflowY: 'scroll',
          backgroundColor: 'white',
          ...props.style,
        },
      },
      [...months.map((d) => this.renderMonth(d))]
    );
  }

  private renderMonth(month: Date) {
    const dateInCurrentMonth = (d: Date) => dt.isSameMonth(d, month);
    const props = this.getProps();
    const selected = props.selected.map(dt.startOfDay).filter(dateInCurrentMonth);
    const clashes = props.clashes
      .map(dt.startOfDay)
      .filter(dateInCurrentMonth)
      // filter out dates that aren't selected
      .filter((clash) => selected.find((selected) => dt.isSameDay(clash, selected)));
    const numClashes = clashes.length;
    const numDatesSelected = selected.length;
    const days = Array.from(u.range(1, dt.getDaysInMonth(month) + 1)).map((day) =>
      dt.addDays(month, day - 1)
    );

    /**
     * Array of arrays of dates of the month.
     * Each element of the array represents a week.
     * A week's array contains dates in that week.
     * undefined if the month doesn't contain that date.
     * For eg.
     * for calendar,
     *   S M T W T F S
     *     1 2 3 4 5 6
     *   7 8 9
     * weeks array should be
     * [
     *   [undefined, {1}, {2}, {3}, {4}, {5}, {6}],
     *   [{7}, {8}, {9}, undefined, undefined, undefined, undefined]
     * ]
     * {1} here means a date object whose date is 1 and month is `month`
     */
    const weeks: (Date | undefined)[][] = [];

    // Populate weeks array
    {
      // starting a block here because dayIndex and
      // weekIndex should not be used after this.
      let dayIndex = month.getDay();
      let weekIndex = 0;
      for (const date of days) {
        if (!weeks[weekIndex]) {
          weeks[weekIndex] = [];
        }
        weeks[weekIndex][dayIndex] = date;
        if (dayIndex === 6) {
          dayIndex = 0;
          weekIndex++;
        } else {
          dayIndex++;
        }
      }
    }
    const minDate = dt.startOfDay(props.minDate);
    const maxDate = dt.endOfDay(props.maxDate);
    const monthLabel = dt.format(month, 'MMM');
    return h(
      'div.month-view',
      style(
        [mb('1rem')],
        {},
        {
          key: monthLabel,
        }
      ),
      [
        h('div', style(['flex', pad('0.5rem 1rem')]), [
          h('div.month-header', [
            h(
              'div',
              style([
                mb('0.3rem'),
                'flex',
                {
                  alignItems: 'flex-end',
                },
              ]),
              [
                h('div', style(['x-large', mr('0.5rem')]), monthLabel),
                numDatesSelected > 0
                  ? h(
                      'div',
                      style(['small', 'blue']),
                      `${numDatesSelected} date${numDatesSelected === 1 ? '' : 's'}`
                    )
                  : null,
                numClashes > 0
                  ? h(
                      'div',
                      style([ml('0.5em'), 'small', 'red']),
                      `${numClashes} clash${numClashes === 1 ? '' : 'es'}`
                    )
                  : null,
              ]
            ),
            h('div', style(['small', 'lightGrey']), dt.format(month, 'YYYY')),
          ]),
        ]),
        props.hideMonthHeaders ? null : this.weekDaysHeader(),
        ...weeks.map((weekDates, weekIndex) =>
          h(
            'div.week-row',
            style(
              ['flex', 'spaceAround'],
              {},
              {
                key: `week-${weekIndex}`,
              }
            ),
            Array.from(u.range(0, 7)).map((dateIndex) => {
              const date = weekDates[dateIndex];
              const isOutOfRange = !date || date < minDate || date > maxDate;
              const isClashing = date && clashes.find((clash) => dt.isSameDay(clash, date));
              const isSelected = date && selected.find((selDate) => dt.isSameDay(selDate, date));

              const isSunday = date && date.getDay() === 0;

              let color = colors.black;
              let bgColor = colors.white;

              if (isOutOfRange) {
                color = colors.lightGrey;
              } else if (isClashing) {
                color = colors.white;
                bgColor = colors.red;
              } else if (isSelected) {
                color = colors.white;
                bgColor = colors.blue;
              } else if (isSunday) {
                color = colors.orange;
              }
              return h(
                'div.calendar-date',
                style(
                  [
                    'flex',
                    'alignCenter',
                    'justifyCenter',
                    {
                      width: DATE_WIDTH,
                      height: DATE_WIDTH,
                      color: color,
                      backgroundColor: bgColor,
                      cursor: !isOutOfRange ? 'pointer' : undefined,
                    },
                  ],
                  {},
                  {
                    key: `day-${dateIndex}`,
                    onclick: async () => {
                      if (!date) return;
                      if (isOutOfRange) return;
                      if (!props.onSelect) return;
                      await props.onSelect(date, isSelected ? 'unselect' : 'select');
                    },
                  }
                ),
                date ? dt.format(date, 'D') : ''
              );
            })
          )
        ),
      ]
    );
  }

  private weekDaysHeader() {
    return CalendarWeekLabels();
  }
}

export default (props: IMultiDatePickerProps) => h(MultiDatePicker, props);
