import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/repeat';
import 'rxjs/add/operator/skipUntil';
import 'rxjs/add/operator/takeUntil';

import { Observable } from 'rxjs/Observable';

import loggly from './loggly';

export interface Position {
  x: number;
  y: number;
}

export function getMousePosition(e: MouseEvent): Position {
  return {
    x: e.clientX,
    y: e.clientY,
  };
}

export function getTouchPosition(e: TouchEvent): Position {
  const touch = e.touches[0] || e.changedTouches[0];
  if (!touch) {
    loggly.push({
      type: 'Unhandled touch event',
      message: 'dragEvents; No touches found!',
    });
    return { x: 0, y: 0 };
  }
  return {
    x: touch.clientX,
    y: touch.clientY,
  };
}

function disableScroll(position: Position) {
  document.body.classList.add('disable-scroll');
  return position;
}

function enableScroll(position: Position) {
  document.body.classList.remove('disable-scroll');
  return position;
}

export function dragEvents(ele: Element) {
  const mouseDown$ = Observable.fromEvent(ele, 'mousedown').map(getMousePosition);
  const mouseUp$ = Observable.fromEvent(document, 'mouseup').map(getMousePosition);

  const touchStart$ = Observable.fromEvent(ele, 'touchstart').map(getTouchPosition);
  const touchEnd$ = Observable.fromEvent(document, 'touchend').map(getTouchPosition);

  const mouseMove$ = Observable.fromEvent(document, 'mousemove').map(getMousePosition);
  const touchMove$ = Observable.fromEvent(document, 'touchmove').map(getTouchPosition);

  const move$ = mouseMove$.merge(touchMove$);

  const dragStart$ = mouseDown$.merge(touchStart$).map(disableScroll);

  const dragEnd$ = mouseUp$
    .merge(touchEnd$)
    .skipUntil(dragStart$)
    .take(1)
    .map(enableScroll)
    .repeat();

  const drag$ = move$.skipUntil(dragStart$).takeUntil(dragEnd$).repeat();

  return { dragStart$, drag$, dragEnd$ };
}
