import { Actions } from 'acadly/course/actions';
import * as dt from 'acadly/datetime';
import { logger } from 'acadly/logger';
import { dispatch } from 'acadly/store';
import { ViewModel } from 'acadly/ViewModel';

export interface ICourseEndDialogVMProps {
  courseId: string;
  startDate: Date;
  endDate: Date;
  onArchiveSuccess?(): Promise<void>;
  onExtendSuccess?(): Promise<void>;
}

export const TEXT = {
  HEADING_PARAGRAPHS: [
    'The course is over. You cannot add any more activities to ' +
      'it because it is past the course end date.',
    'You can either "Archive" this course or "Extend the End Date."',
  ],
  ARCHIVE_HEADING: 'If you archive the course',
  ARCHIVE_BULLETS: [
    "Students and TA won't be able to access this course.",

    'You and co-instructors will be able to access the course through ' + '"Archived courses"',

    'All course data (including responses, comments and grades) ' + 'will be preserved',

    'You will be able to copy existing activities and content to ' + 'your new courses',
  ],
  ARCHIVE_WARNING_BULLET: 'You cannot "Undo" the course archive process.',

  ARCHIVE_BUTTON_LABEL: 'Archive this course (irreversible)',

  EXTEND_HEADING: 'If you extend the course end date',
  EXTEND_BULLETS: ['The course will continue as it is, till the new course end date.'],
  EXTEND_BUTTON_LABEL: 'Set new course end date',
  EXTEND_WARNING:
    "You won't be able to add classes, activities, announcements etc" +
    'after the course end date; it is advised that you keep a buffer of ' +
    'a few days after the last class date.',
};

export class CourseEndDialogVM extends ViewModel<ICourseEndDialogVMProps> {
  private _isArchiveAlertOpen = false;
  private _isChangeEndDateDialogOpen = false;
  private _isExtendLoaderVisible = false;

  public readonly dispatch = dispatch;

  public get isArchiveAlertOpen() {
    return this._isArchiveAlertOpen;
  }

  public get isChangeEndDateDialogOpen() {
    return this._isChangeEndDateDialogOpen;
  }

  public get isExtendLoaderVisible() {
    return this._isExtendLoaderVisible;
  }

  public get minDate() {
    return dt.startOfDay(dt.addMinutes(dt.endOfDay(this.props.endDate), 10));
  }

  public onClickArchive = () =>
    this.update(async () => {
      this._isArchiveAlertOpen = true;
    });

  public onClickArchiveCancel = () =>
    this.update(async () => {
      this._isArchiveAlertOpen = false;
    });

  public onClickArchiveOk = () =>
    this.update(async () => {
      await this.dispatch(Actions.courseArchive(this.props.courseId));
      this._isArchiveAlertOpen = false;
      if (this.props.onArchiveSuccess) {
        await this.props.onArchiveSuccess();
      }
    });

  public onClickExtend = () =>
    this.update(async () => {
      this._isChangeEndDateDialogOpen = true;
    });

  public onClickExtendCancel = () =>
    this.update(async () => {
      this._isChangeEndDateDialogOpen = false;
    });

  public onSelectNewEndDate = (date: Date) =>
    this.update(async () => {
      logger.log('New end date selected: ', date);
      await this.update(async () => {
        this._isExtendLoaderVisible = true;
      });
      logger.log('Dispatching set end date action');
      await this.dispatch(Actions.setEndDate(dt.format(date, 'YYYY-MM-DD')));
      this._isChangeEndDateDialogOpen = false;
      await this.update(async () => {
        this._isExtendLoaderVisible = false;
      });
      if (this.props.onExtendSuccess) {
        await this.props.onExtendSuccess();
      }
    });

  public get endDate() {
    return this.props.endDate;
  }

  public get endDateFormatted() {
    return dt.format(this.props.endDate, 'MMM DD, YYYY');
  }

  public get startDateFormatted() {
    return dt.format(this.props.startDate, 'MMM DD, YYYY');
  }

  public get title() {
    return 'Editing course dates';
  }

  public get mainText() {
    return TEXT;
  }
}
