import { h, IComponent } from 'core';

import * as BulbIcon from 'assets/bulb.svg';

import Icon from 'acadly/common/Icon';
import QuickStartTips from 'acadly/common/QuickStartTips';
import SvgIcon from 'acadly/common/SvgIcon';
import icons from 'acadly/icons';
import { margin, style } from 'acadly/styles';

class TimelineStartTips extends IComponent<never, never> {
  public render() {
    return QuickStartTips({
      qstKey: 'qst-timeline',
      children: ({ wrapper }) => wrapper(this.body()),
    });
  }

  private body = () => {
    return h('div.quick-start-tips__body', [
      h('div.quick-start-tips__section-heading', 'Timeline'),
      h('p.quick-start-tips__section-content', [
        'This is your Course Timeline - a collection of Classes (lectures/sessions) ',
        'that will be conducted during the course. We have already added two classes ',
        'to this demo course for your convenience.',
      ]),
      h('p.quick-start-tips__section-content', [
        'Activities like Quizzes, Polls, Discussions, and Resources are linked to a ',
        'Class and can be created by navigating to a Class via the Course Timeline.',
      ]),
      h('div.quick-start-tips__section-heading', ['Scheduling a new class']),
      h('p.quick-start-tips__section-content', [
        'To schedule a new Class on the Timeline, use the ',
        h('i.fa.fa-plus-circle', style(['x-large', 'blue', { transform: 'translateY(2px)' }])),
        ' button on the bottom right. The functionality also ',
        'allows you to add multiple Classes at once.',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'Scheduling a new class',
      ]),
      h('p.quick-start-tips__section-content', [
        'While creating a course, the Course Timeline can be ',
        'automatically populated with the help of a weekly schedule, ',
        'so you don’t have to manually add the Classes later',
      ]),
      h('div.quick-start-tips__section-heading', ['Managing enrollments']),
      h('p.quick-start-tips__section-content', [
        'To add students to this course, please click on the ',
        Icon(icons.analytics, style(['blue', 'large', margin('0.25rem')])),
        'icon on the top right of this section. For this particular ',
        'demo course, you can invite upto 5 students via email',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'ENROLLMENT VIA JOIN CODE',
      ]),
      h('p.quick-start-tips__section-content', [
        'While creating a course, Course Admins have the option to enable ',
        'student enrollment via a “Join Code” too',
      ]),
    ]);
  };
}

export default () => h(TimelineStartTips);
