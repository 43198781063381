import cn from 'classnames';

import { CSS, h, View } from 'core';

import { Actions as appActions } from 'acadly/app/actions';
import { dispatch } from 'acadly/store';

import { getStore } from '../store';
import DivButton from './DivButton';
import Tooltip from './Tooltip';

interface FloatingMenuProps {
  isOpen: boolean;
  toggleHandler: (event: Event) => void;
  style?: CSS;
  classNames?: string[];
  overlayStyle?: CSS;
  dontCloseOnSelection?: boolean;
  title?: string;
  ariaLabel?: string;
}

interface IFloatingMenuItem {
  label: View;
  key?: string;
  disabled?: boolean;
  tooltip?: string;
  onClick?(): any;
  tabIndex?: number;
}

export default function (
  props: FloatingMenuProps,
  items: (IFloatingMenuItem | null | undefined)[]
) {
  const isAccessible = getStore().getState().app.acc.web.turnOff === 0;

  return h(
    'div',
    {
      className: cn('floating-menu', {
        open: props.isOpen,
      }),
    },
    [
      h('div.floating-menu__overlay', {
        role: 'menu',
        'aria-labelledby': 'floating-menu-title',
        style: props.overlayStyle,
        onclick: props.toggleHandler,
      }),
      h(
        'div',
        {
          tabIndex: isAccessible && props.isOpen ? 0 : undefined,
          'aria-label': props.ariaLabel ? props.ariaLabel : undefined,
          style: props.style,
          id: 'item-container',
          className: cn('floating-menu__container', ...(props.classNames || []), {
            open: props.isOpen,
          }),
        },
        [
          props.title ? h('div.floating-menu__item.title#floating-menu-title', props.title) : null,
          ...items.map((item) => {
            if (!item) return null;

            const button = DivButton({
              ariaLabel: typeof item.label === 'string' ? item.label : '',
              key: typeof item.label === 'string' ? item.label : item.key,
              disabled: item.disabled,
              onclick:
                !item.disabled && item.onClick
                  ? async (event) => {
                      if (!item.onClick) {
                        return;
                      }
                      await item.onClick();
                      if (!props.dontCloseOnSelection) {
                        await props.toggleHandler(event);
                      }
                    }
                  : () => {},
              classNames: ['floating-menu__item'],
              children: [item.label],
              tabIndex: item.tabIndex !== undefined && props.isOpen ? item.tabIndex : undefined,
              // selector: typeof(item.label) === "string" ? item.label : "",
            });

            if (!item.tooltip) return button;

            return Tooltip({
              styles: {
                margin: '0 4px',
              },
              text: item.tooltip,
              targetElement: button,
            });
          }),
          h(
            'div.ripple.floating-menu__item.fc-blue#cancel-button',
            {
              tabIndex: isAccessible && props.isOpen ? 0 : undefined,
              'aria-label': 'Cancel',
              onclick: async (event: Event) => {
                await props.toggleHandler(event);
                await dispatch(appActions.startTip(true));
              },
            },
            'Cancel'
          ),
        ]
      ),
    ]
  );
}
