import { h, IComponent } from 'core';

import * as BulbIcon from 'assets/bulb.svg';

import Icon from 'acadly/common/Icon';
import QuickStartTips from 'acadly/common/QuickStartTips';
import SvgIcon from 'acadly/common/SvgIcon';
import icons from 'acadly/icons';
import { margin, style } from 'acadly/styles';

class CourseAnalyticsStartTips extends IComponent<never, never> {
  public render() {
    return QuickStartTips({
      qstKey: 'qst-course-analytics',
      children: ({ wrapper }) => wrapper(this.body()),
    });
  }

  private body = () => {
    return h('div.quick-start-tips__body', [
      h('div.quick-start-tips__section-heading', 'Course Analytics'),
      h('p.quick-start-tips__section-content', [
        'Course Analytics section is where you can manage enrollments while ',
        'also getting a bird’s eye view of how the students are doing in the course.',
      ]),
      h('p.quick-start-tips__section-content', [
        'Once you’ve added students, they’ll show up here. You will then be ',
        'able to look at each student’s performance and attendance throughout ',
        'the course by clicking on their name.',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'Contextual analytics',
      ]),
      h('p.quick-start-tips__section-content', [
        'Every section on Acadly has the ',
        Icon(icons.analytics, style(['blue', 'large', margin('0.25rem')])),
        ' on the top right. By clicking on it, you’ll get analytics of that ',
        'particular section. Since this is the  “Course Home Page”, the analytics ',
        'section contains the consolidated data for the course.',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'CSV EXports',
      ]),
      h('p.quick-start-tips__section-content', [
        'Each Analytics section allows you to export the relevant data in CSV files ',
        'that can be easily imported into MS Excel or Numbers (for Mac OS users)',
      ]),
    ]);
  };
}

export default () => h(CourseAnalyticsStartTips);
