import {
  addNewClassActivity,
  deleteClassActivity,
  incrementNumSeen,
  pusherPublish as classPusherPublish,
  updateTotalClassActivitiesCount,
} from 'acadly/class/functions';
import { createReducer } from 'acadly/createReducer';
import * as datetime from 'acadly/datetime';
import { IRootState } from 'acadly/IRootState';
import * as utils from 'acadly/utils';

import * as actions from './actions';
import { responseResourceToResource, updateResource } from './functions';

export const reducer = createReducer({
  '@resource/CREATE_SUCCESS': createSuccess,
  '@resource/DELETE_SUCCESS': deleteSuccess,
  '@resource/EDIT_SUCCESS': editSuccess,
  '@resource/PUBLISH_SUCCESS': publishSuccess,
  '@resource/ANALYTICS_SET_SUCCESS': analyticsSetSuccess,
  '@resource/all/FETCH_SUCCESS': fetchAllSuccess,
  '@resource/pusher/PUBLISH': pusherPublish,
  '@resource/analytics/FETCH_SUCCESS': resourceAnalyticsFetchSuccess,
  '@resource/EDIT_PUBLISHED_RESOURCE_SUCCESS': updatePublishedResourceSuccess,
});

export function updatePublishedResourceSuccess(
  state: IRootState,
  payload: actions.IPublishPublishedResourceRequest
): IRootState {
  const currentResource = state.resources.byId[payload.resourceId];
  if (currentResource) {
    const updatedResource = {
      ...currentResource,
      details: {
        ...currentResource.details,
        title: payload.title,
        description: payload.description,
        fileName: payload.fileName || '',
        originalFileName: payload.originalFileName || '',
        videoId: payload.videoId || '',
        url: payload.url || '',
      },
    };
    return {
      ...state,
      resources: {
        ...state.resources,
        byId: {
          ...state.resources.byId,
          [payload.resourceId]: updatedResource,
        },
      },
    };
  }
  return state;
}

export function pusherPublish(
  state: IRootState,
  payload: actions.IPusherPublishPayload
): IRootState {
  const resource: IResource = {
    _id: payload.resourceId,
    nodeType: 'resource',
    identifiers: {
      classId: payload.classId,
    },
    details: payload.details,
    activities: payload.activities,
    stats: payload.stats,
  };
  return classPusherPublish(state, 'resources', {
    senderId: payload.sender.userId,
    activity: resource,
  });
}

export function publishSuccess(
  state: IRootState,
  payload: actions.IPublishSuccessPayload
): IRootState {
  const courseId = state.courses.currentCourseId;
  const updatedCourseSlice =
    courseId && state.courses.timeline
      ? updateTotalClassActivitiesCount(state.courses, {
          activityType: 'resources',
          classId: payload.classId,
          toBeDone: payload.toBeDone,
          key: 'numPublished',
          num: 1,
        })
      : state.courses;
  const updatedResourceSlice = updateResource(state.resources, payload.resourceId, (r) =>
    utils.update(r, {
      details: {
        published: 1,
        num: payload.num,
        trueNum: payload.trueNum,
        publishedOn: payload.publishedOn,
      },
    })
  );
  return {
    ...state,
    courses: updatedCourseSlice,
    resources: updatedResourceSlice,
  };
}

export function createSuccess(
  state: IRootState,
  payload: actions.ICreateSuccessPayload
): IRootState {
  return addNewClassActivity(state, {
    type: 'resources',
    classId: payload.classId,
    activity: {
      ...payload.resource,
      identifiers: { classId: payload.classId },
    },
  });
}

export function deleteSuccess(
  state: IRootState,
  payload: actions.IDeleteSuccessPayload
): IRootState {
  return deleteClassActivity(state, 'resources', {
    activityId: payload.resourceId,
    classId: payload.classId,
    toBeDone: payload.toBeDone,
  });
}

export function editSuccess(state: IRootState, payload: actions.IEditSuccessPayload): IRootState {
  if (!state.class.data) return state;
  const updatedResourceSlice = updateResource(state.resources, payload.resourceId, (r) => {
    const withTitleAndDescr = utils.update(r, {
      details: {
        resourceType: payload.resourceType,
        title: payload.title,
        description: payload.description,
      },
    });
    if (payload.resourceType === 'link') {
      return utils.update(withTitleAndDescr, {
        details: {
          url: payload.url,
        },
      });
    } else if (payload.resourceType === 'video') {
      return utils.update(withTitleAndDescr, {
        details: {
          videoId: payload.videoId,
        },
      });
    } else if (payload.resourceType === 'file') {
      return utils.update(withTitleAndDescr, {
        details: {
          originalFileName: payload.originalFileName,
          fileName: payload.fileName,
        },
      });
    } else {
      return withTitleAndDescr;
    }
  });
  return {
    ...state,
    resources: updatedResourceSlice,
  };
}

export function analyticsSetSuccess(
  state: IRootState,
  payload: actions.IAnalyticsSetSuccessPayload
): IRootState {
  const updatedResourceSlice = updateResource(state.resources, payload.resourceId, (r) => ({
    ...r,
    userData: {
      firstAccessedOn: payload.firstAccessedOn,
      numCommentsSeen: 0,
      subscribed: 0,
      lastAccessedOn: datetime.unix(),
    },
  }));
  const updatedCourseSlice = incrementNumSeen(state, 'resources', {
    classId: payload.classId,
    toBeDone: payload.toBeDone,
  }).courses;
  return {
    ...state,
    resources: updatedResourceSlice,
    courses: updatedCourseSlice,
  };
}

function resourceAnalyticsFetchSuccess(
  state: IRootState,
  payload: actions.IResourceAnalyticsFetchSuccess
): IRootState {
  return {
    ...state,
    resources: {
      byId: state.resources.byId,
      analytics: payload,
    },
  };
}

export function fetchAllSuccess(
  state: IRootState,
  payload: actions.IFetchAllSuccessPayload
): IRootState {
  const resources = payload.activityData.map((r) =>
    responseResourceToResource(payload.userData.resources[r._id], r.identifiers.classId, r)
  );
  const byId = utils.makeObjectWithKey(resources, '_id');
  return {
    ...state,
    resources: {
      ...state.resources,
      byId: byId,
    },
  };
}
