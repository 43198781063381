import { h, IComponent } from 'core';

import AnnouncementWidget from 'acadly/announcement/AnnouncementWidget';
import AssignmentWidget from 'acadly/assignment/AssignmentWidget';
import ClassWidget from 'acadly/class/ClassWidget';
import { getStore } from 'acadly/store';

interface ITimelineWidgetProps {
  item: ICourseTimelineItem;
  tag: IClassTeamRole;
  key: string;
  courseTeam: ICourseTeamMember[];
  courseStatus: ICourseStatus;
  isCourseArchived?: boolean;
  courseRole: ICourseRole | null;
  onclick?: (event: Event) => any;
  disableUpdates?: boolean;
  deleteAction?(e: Event): any;
}

export class TimelineWidget extends IComponent<ITimelineWidgetProps, never> {
  public render() {
    return h('div.timeline-widget', [this.getRow()]);
  }

  private getRow() {
    const props = this.getProps();
    const { item, tag } = props;
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    switch (item.nodeType) {
      case 'class':
        return ClassWidget({
          tag,
          cls: item,
          onclick: props.courseStatus.courseLive === 1 ? props.onclick : undefined,
          courseRole: props.courseRole,
          isCourseArchived: props.isCourseArchived,
          courseHasInstructors:
            props.courseTeam
              .filter((m) => m.role === 'instructor')
              .filter((m) => m.status === 'active').length > 0,
          deleteAction: props.deleteAction,
          tabIndex: isAccessible ? 0 : undefined,
        });
      case 'announcement':
        return AnnouncementWidget(item, {
          onclick: props.onclick,
        });
      case 'assignment':
        return AssignmentWidget(item, {
          onclick: props.onclick,
        });
    }
  }
}
export default (props: ITimelineWidgetProps) => h(TimelineWidget, props);
