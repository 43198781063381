import { IRootState } from '../IRootState';
import { flatten } from '../utils';
import { ICourseState } from './ICourseState';

export function getTopicById(state: ICourseState, topicId: string): ITopicChild | null {
  const topics = flatten(state.topics.map((topic) => topic.topics));
  return topics.find((t) => t.topicId === topicId) || null;
}

export function getTeamMember(state: IRootState) {
  const session = state.getIn.session;
  if (!session) return null;
  const currentCourseId = state.courses.currentCourseId;
  const courses = state.courses.courses;
  if (!currentCourseId) return null;
  const course = courses[currentCourseId];
  const team = course.team;
  return team.find((member) => member.userId === session.userId) || null;
}

export function getCourseRole(state: IRootState) {
  const teamMember = getTeamMember(state);
  if (!teamMember) return 'student';
  return teamMember.role;
}

export function updateNumActivities(
  course: ICourse,
  activityType: 'assignments' | 'quizzes' | 'polls' | 'announcements',
  key: 'numTotal' | 'numPublished',
  num: number
): ICourse {
  return {
    ...course,
    activities: {
      ...course.activities,
      [activityType]: {
        ...course.activities[activityType],
        [key]: course.activities[activityType][key] + num,
      },
    },
  };
}

export function getCurrentCourse(state: IRootState): ICourse | null {
  const courses = state.courses.courses;
  const currentCourseId = state.courses.currentCourseId;
  if (!currentCourseId) return null;
  return courses[currentCourseId];
}
