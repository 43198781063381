import cn from 'classnames';

import { CSS, h, View } from 'core';

import { Loader } from 'acadly/common/Loader';

import { BasicButton, IBasicButtonProps, IBasicButtonState } from './BasicButton';

interface IFlatButtonProps extends IBasicButtonProps {
  type?: 'primary' | 'secondary';
  tooltip?: string;
  labelStyle?: CSS;
  role?: string;
  ariaLabel?: string;
  /** @deprecated */
  tabIndex?: number;
}

export default (label: View | View[], props: IFlatButtonProps) => {
  return h(FlatButton, { ...props, label });
};

export class FlatButton extends BasicButton<
  IFlatButtonProps & { label: View | View[] },
  IBasicButtonState & { isHovered: boolean }
> {
  private size = 0;
  public componentWillMount() {
    this.setState({
      isLoading: false,
      isHovered: false,
    });
  }

  public render() {
    const props = this.getProps();

    const styles = this.isDisabled()
      ? {
          ...this.getProps().style,
          ...this.getProps().disabledStyle,
        }
      : this.getProps().style;

    return h(
      'div',
      {
        'aria-label': this.getAriaText(),
        id: props.id,
        className: cn(
          'flat-button',
          props.type || 'primary',
          {
            disabled: this.isDisabled(),
            ripple: !this.isDisabled(),
          },
          props.className,
          ...(props.classNames || [])
        ),
        tabIndex: this.isAccessible && !props.disabled ? 0 : undefined,
        role: this.getProps().role || 'button',
        style: styles,
        ref: (elem?: HTMLElement) => {
          // We only need size to render the loader
          // so, we can bail out if loader isn't visible.
          // This avoids forced style reflows.
          if (this.isLoading() && elem) {
            const size = Math.min(elem.offsetWidth, elem.offsetHeight) - 15;
            if (this.size !== size) {
              this.size = size;
              this.setState({});
            }
          }
        },
        onclick: this.clickHandler(),
      },
      [
        this.label(),
        this.isLoading()
          ? h('div.flat-button__loader-container', [
              Loader({
                maxHeight: '2em',
                maxWidth: '2em',
                width: `${this.size}px`,
                height: `${this.size}px`,
                borderWidth: '2px',
              }),
            ])
          : null,
      ]
    );
  }

  private getAriaText() {
    const props = this.getProps();
    if (props.ariaLabel) {
      return `${props.ariaLabel}`;
    } else {
      if (this.isDisabled()) {
        return `${props.label} button. Inactive`;
      } else {
        return `${props.label} button. Active`;
      }
    }
  }

  private label() {
    const { label, labelStyle } = this.getProps();
    return h(
      'div',
      {
        style: labelStyle,
        className: cn('flat-button__label', {
          invisible: this.isLoading(),
        }),
      },
      label instanceof Array ? label : [label]
    );
  }
}
