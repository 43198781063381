import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/distinctUntilKeyChanged';

import { Observable } from 'rxjs/Observable';

const browserPrefixes = ['moz', 'ms', 'o', 'webkit'];

// get the correct attribute name
function getHiddenPropertyName(prefix) {
  return prefix ? prefix + 'Hidden' : 'hidden';
}

// get the correct event name
function getVisibilityEvent(prefix) {
  return (prefix || '') + 'visibilitychange';
}

// get current browser vendor prefix
function getBrowserPrefix() {
  // no vendor prefix needed
  if ('hidden' in document) return null;

  for (let i = 0; i < browserPrefixes.length; i++) {
    if (getHiddenPropertyName(browserPrefixes[i]) in document) {
      // return vendor prefix
      return browserPrefixes[i];
    }
  }

  // no vendor prefix found, fallback to default
  return null;
}

const browserPrefix = getBrowserPrefix();
const hiddenPropertyName = getHiddenPropertyName(browserPrefix);
const visibilityEventName = getVisibilityEvent(browserPrefix);

export interface PageVisibilityEvent {
  isHidden: boolean;
}

const focus$ = Observable.fromEvent(document, 'focus')
  .merge(Observable.fromEvent(window, 'focus'))
  .map<unknown, PageVisibilityEvent>(() => ({ isHidden: false }));

const blur$ = Observable.fromEvent(document, 'blur')
  .merge(Observable.fromEvent(window, 'blur'))
  .map<unknown, PageVisibilityEvent>(() => ({ isHidden: true }));

let isHidden = true;

const visibility$ = Observable.fromEvent(document, visibilityEventName)
  .map<unknown, PageVisibilityEvent>(() => ({ isHidden: document[hiddenPropertyName] }))
  .merge(focus$)
  .merge(blur$)
  .distinctUntilKeyChanged('isHidden')
  .do((e) => (isHidden = e.isHidden));

export const PageVisibility = {
  visibility$,
  get isHidden() {
    return isHidden;
  },
};
