import { h, IComponent } from 'core';

import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import ClassWidget from 'acadly/class/ClassWidget';
import Alert from 'acadly/common/Alert';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import RadioButton from 'acadly/common/RadioButton';
import VirtualList from 'acadly/common/VirtualList';
import courseService from 'acadly/course/service';
import * as datetime from 'acadly/datetime';
import { goBack } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { mb, ml, mt, pad, style, timelineWidgetHeightPx } from 'acadly/styles';
import * as u from 'acadly/utils';

import { Actions } from './actions';
import { Class } from './functions';
// import appService from "acadly/App/service";
// import classService from "acadly/class/service";
interface IMoveActivityDialogProps {
  class: IClass;
  activity: IClassActivity;
  open: boolean;
  oncancel: (event: Event) => any;
  ondone?: (event: Event) => any;
}
interface IMoveActivityDialogState {
  moveDialogToBeDone: 'preClass' | 'inClass' | null;
  moveDialogClassId: string | null;
  isDialogOpen: boolean;
}

export default (props: IMoveActivityDialogProps) => h(MoveActivityDialog, props);

export class MoveActivityDialog extends IComponent<
  IMoveActivityDialogProps,
  IMoveActivityDialogState
> {
  private isAccessible = false;
  private lastFocusedElement: null | Element = null;
  public componentWillMount() {
    this.setState({
      moveDialogToBeDone: null,
      moveDialogClassId: null,
      isDialogOpen: false,
    });
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
  }

  public componentWillReceiveProps(newProps: IMoveActivityDialogProps) {
    if (this.getProps().open !== newProps.open) {
      this.setState({
        moveDialogToBeDone: null,
        moveDialogClassId: null,
      });
    }
  }

  public render() {
    const props = this.getProps();
    const course = courseService.getCurrentCourse();
    if (!course) {
      return null;
    }
    const { oncancel, open } = props;
    const { moveDialogClassId, moveDialogToBeDone } = this.getState();
    const isMobile = getStore().getState().app.isMobile;
    const timeline = getStore().getState().courses.timeline;
    if (!timeline) return null;
    const classes = timeline.items.filter(
      (item) =>
        item.nodeType === 'class' &&
        (item.details.scheStartTime > datetime.unix() || Class.status(item) === 'inSession')
    ) as IClass[];
    return Dialog(
      {
        open,
        title: 'Move activity',
        style: {
          padding: '0px',
          minHeight: '35em',
          overflow: 'hidden',
        },
        bodyStyle: {
          backgroundColor: '#e8e8e8',
          alignItems: 'center',
          padding: '0px',
          height: '100%',
          overflow: 'hidden',
        },
        primaryAction: {
          label: 'MOVE',
          onclick: () => this.moveActivity(),
          mobileLabel: h('i.fa.fa-check'),
          disabled: moveDialogToBeDone === null || moveDialogClassId === null,
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times'),
          onclick: oncancel,
        },
      },
      !open
        ? []
        : [
            VirtualList({
              style: {
                height: isMobile ? '100%' : '35em',
              },
              header: h(
                'div',
                style(['darkBlue', 'large', mb('0.5em'), 'textCenter', mt('0.5em')]),
                'Choose future class'
              ),
              itemHeight: timelineWidgetHeightPx,
              items: classes,
              render: (cls) =>
                ClassWidget({
                  cls: cls,
                  tag: 'none',
                  style: {
                    marginBottom: '1px',
                  },
                  onclick: () => {
                    this.lastFocusedElement = document.activeElement;
                    u.unsetTabIndices();
                    this.setState({
                      moveDialogClassId: cls._id,
                      isDialogOpen: true,
                    });
                  },
                  hideActivityIcons: true,
                  courseRole: null,
                  tabIndex: this.isAccessible ? 0 : undefined,
                }),
            }),
            this.copyAsDialog(),
          ]
    );
  }
  private isPreClassAllowed() {
    const { moveDialogClassId } = this.getState();
    const timeline = getStore().getState().courses.timeline;
    const clsTomove = timeline
      ? timeline.items.filter((i) => i._id === moveDialogClassId)[0]
      : null;
    if (!clsTomove) {
      return false;
    }
    if (clsTomove) {
      return clsTomove.nodeType === 'class' && clsTomove.details.status === 'open';
    }
    return true;
  }

  private copyAsDialog() {
    const { moveDialogToBeDone } = this.getState();
    const renderTile = (text: string, selected: boolean, onclick: (e: Event) => any) => {
      return h(
        'div',
        style(
          ['blue', pad('0.5em 1em'), 'flex', 'alignCenter', 'pointer', 'fullWidth', 'borderBox'],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick,
          }
        ),
        [
          RadioButton({
            selected,
          }),
          h('span', style([ml('0.5rem')]), text),
        ]
      );
    };
    return Alert(
      {
        title: 'Move activity as',
        open: this.getState().isDialogOpen,
        style: {
          width: '35em',
        },
        actions: [
          FlatButton('Cancel', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                isDialogOpen: false,
              }).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              }),
          }),
          FlatButton('Move', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.moveActivity(),
          }),
        ],
      },
      [
        this.isPreClassAllowed()
          ? renderTile('Pre-class activity', moveDialogToBeDone === 'preClass', () =>
              this.setState({
                moveDialogToBeDone: 'preClass',
              })
            )
          : null,
        renderTile('In-class activity', moveDialogToBeDone === 'inClass', () =>
          this.setState({
            moveDialogToBeDone: 'inClass',
          })
        ),
      ]
    );
  }

  private async moveActivity() {
    const { moveDialogToBeDone, moveDialogClassId } = this.getState();
    if (!moveDialogToBeDone || !moveDialogClassId) return;

    const props = this.getProps();
    await dispatch(
      Actions.moveClassActivity(
        {
          classId: props.class._id,
          activityId: this.getProps().activity._id,
          type: this.getProps().activity.nodeType as any,
          subType: this.getProps().activity.details.toBeDone as any,
          newSubType: moveDialogToBeDone,
          newClassId: moveDialogClassId,
        },
        () => {
          goBack();
          // this.navigateToCurrentClass(props.class._id);
        }
      )
    );
    const activity = this.getProps().activity;
    googleAnalytics.activityMoved(
      activity.nodeType,
      activity.details.toBeDone === 'preClass' ? 'pre-class' : 'in-class'
    );
  }

  // private async navigateToCurrentClass(classId: string) {
  //     const courseId = courseService.getCurrentCourseId();
  //     const courseShortId = courseService.getShortIdFromCourseId(courseId as any);
  //     const classShortId = classService.getShortIdFromClassId(classId);
  //     await Routes.classActivities.navigate({
  //         classShortId,
  //         courseShortId,
  //         univSlug: appService.getUniversitySlug()
  //     });
  // }
}
