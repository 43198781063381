import { CSS, h, IComponent } from 'core';
import * as http from 'core/http';

import { api } from 'acadly/api';
import * as appApi from 'acadly/app/api';
import { googleAnalytics, IRichTextSubContext } from 'acadly/app/GoogleAnalytics';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Dialog, { IDialogProps } from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Paper from 'acadly/common/Paper';
import RadioButton from 'acadly/common/RadioButton';
import UploadButton from 'acadly/common/UploadButton';
import { getStore } from 'acadly/store';
import { colors, margin, mb, ml, mt, pad, style } from 'acadly/styles';
import { upload } from 'acadly/upload';
import * as u from 'acadly/utils';

import { generateMarkup, parseMarkup } from './lib';

export default (props: IRawEditorProps) => h(RawEditor, props);

export interface IRawEditorProps {
  value: string;
  subContext: IRichTextSubContext;
  placeholder?: string;
  tabIndex?: number;
  attachments?: {
    uploadUrl: string;
    activityId?: string;
    activityType: 'assignments' | 'announcements' | 'quizzes' | 'discussions' | 'polls' | 'queries';
    files: IRequestAttachment[];
  };
  oninput: (value: string, attachments: IRequestAttachment[]) => any;
  onenter?: (value: string, attachments: IRequestAttachment[]) => any;
  textAreaStyle?: CSS;

  enableTextFormatting?: boolean;
  enableFormulaInput?: boolean;
  enableImageInput?: boolean;
  enableFileAttachments?: boolean;
  key?: string;
  style?: CSS;
  disabled?: boolean;
  placeholderStyle?: CSS;
  getInstance?: (editor: RawEditor<IRawEditorProps>) => any;
  onImageDialogClose?: () => any;
}

export interface IRawEditorState {
  isFocused: boolean;
  isImageDialogOpen: boolean;
  imageDialogImage: {
    url: string;
    id: string;
    acw: number;
    ach: number;
  } | null;
  formulaDialog: {
    display: IMathJaxDisplay;
    language: IMathJaxLanguage;
    inputField: string;
  } | null;
  formulaOutput: HTMLElement | null;
  formatAndDisplayDialog: IFormatAndDisplayDialogState | null;
  attachments: IAttachment[];
  isCorrectFileTypeError: boolean;
}

type IMathJaxDisplay = 'inline' | 'block';
type IMathJaxLanguage = 'tex' | 'am' | 'mml';

interface IFormatAndDisplayDialogState {
  display: IMathJaxDisplay;
  language: IMathJaxLanguage;
}

export class RawEditor<Props> extends IComponent<IRawEditorProps & Props, IRawEditorState> {
  private savedRange: any;
  public editor: HTMLElement;
  private isEditorLocked = false;
  public isAccessible: boolean;
  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    const props = this.getProps();
    const initialState: IRawEditorState = {
      isFocused: false,
      isImageDialogOpen: false,
      imageDialogImage: null,
      formulaDialog: null,
      formulaOutput: null,
      formatAndDisplayDialog: null,
      attachments: props.attachments ? props.attachments.files : [],
      isCorrectFileTypeError: false,
    };
    this.setState(initialState);
    if (props.getInstance) {
      props.getInstance(this);
    }
  }
  public componentDidMount() {
    // this.editor.focus();
    this.getCaretToEnd(this.editor);
  }

  public componentWillReceiveProps(nextProps: any) {
    if (this.isEditorLocked) {
      return;
    }
    if (nextProps.value !== this.getSource()) {
      if (this.editor) {
        this.updateEditor(this.editor, nextProps.value);
      }
    }
  }

  private getSource() {
    if (this.editor) {
      return generateMarkup(this.editor);
    } else {
      return '';
    }
  }

  private updateEditor(editor: HTMLElement, markup: string) {
    editor.innerText = '';
    const parsedMarkup = this.parseMarkup(markup);
    if (parsedMarkup != null) {
      editor.appendChild(parsedMarkup);
    }
    if ((<any>window).MathJax) {
      MathJax.Hub.Queue(['Typeset', MathJax.Hub, editor], function () {});
      // MathJax.Hub.Register.MessageHook("Math Processing Error", function () {
      // });
    }
  }

  private parseMarkup(markup: string) {
    return parseMarkup(markup, {
      onClickImageContainer: (event) => this.onClickImage(event),
    });
  }

  /**
   * Whenever an image container is clicked, the cursor
   * should be placed next to the image so that pressing
   * backspace/delete removes the image.
   * If only image is present in the editor, there's no way
   * to show a cursor, so we prepend/append the image with
   * a newline and place the cursor on that line.
   * @param event Click event
   */
  private onClickImage(event: MouseEvent) {
    const target = <HTMLElement>event.target;
    const targetHeight = target.clientHeight;
    const clickOffsetY = event.offsetY;
    const imageContainer =
      target.tagName.toLocaleLowerCase() === 'img' ? target.parentElement! : target;
    if (clickOffsetY < targetHeight / 2) {
      this.addNewlineAndSelect('before', imageContainer);
    } else {
      this.addNewlineAndSelect('after', imageContainer);
    }
  }

  private addNewlineAndSelect(position: 'before' | 'after', elem: HTMLElement) {
    const selection = document.getSelection();
    selection && selection.removeAllRanges();
    const range = document.createRange();
    const br = document.createElement('br');
    if (position === 'before') {
      elem.insertAdjacentElement('beforebegin', br);
      range.setStartBefore(br);
      range.setEndBefore(br);
    } else {
      elem.insertAdjacentElement('afterend', br);
      range.setStartAfter(br);
      range.setEndAfter(br);
    }
    selection && selection.addRange(range);
  }

  private getCaretToEnd(editor: any) {
    const range = document.createRange();
    const lastNode = u.getLastNode(editor);
    if (lastNode === editor) {
      range.setStart(editor, 0);
      range.collapse(true);
    } else {
      range.selectNodeContents(editor);
      range.setEndAfter(lastNode);
      range.collapse(false);
    }
    const sel = window.getSelection();
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  public render() {
    const props = this.getProps();
    const placeholderStyle: CSS = {
      color: colors.lightGrey,
      top: 0,
      left: 0,
      position: 'absolute',
      ...(props.placeholderStyle || ({} as any)),
    };
    const state = this.getState();
    return h(
      'div.rich-text-editor-textbox-container',
      {
        key: this.getProps().key,
        style: {
          position: 'relative',
          ...(props.style || ({} as any)),
        },
      },
      [
        this.getProps().placeholder && this.getProps().value.length === 0
          ? h(
              'div.rich-editor-placeholder',
              {
                style: placeholderStyle,
                onclick: () => this.editor.focus(),
              },
              this.getProps().placeholder
            )
          : null,
        h('div.rich-text-editor-textbox', {
          style: {
            backgroundColor: 'white',
            minHeight: '15em',
            maxHeight: '30em',
            outline: 'none',
            overflowY: 'auto',
            transition: 'borderBottom 1s ease-in-out',
            ...(props.textAreaStyle as any),
          },
          tabIndex: this.getProps().tabIndex,
          oninput: (e: KeyboardEvent) => {
            this.saveSelection();
            if (this.getProps().onenter && e.keyCode === 13) {
              return;
            }
            this.inputHandler();
          },
          onclick: () => this.saveSelection(),
          onkeydown: (e: KeyboardEvent) => {
            if (props.onenter && e.keyCode === 13) {
              e.preventDefault();
              const attachments = this.getProps().attachments;
              this.editor.blur();
              props.onenter(this.getSource(), attachments ? attachments.files : []);
            } else {
              this.keyDownHandler(e);
            }
          },
          onfocus: (event: FocusEvent) => this.onFocus(event),
          onblur: () => this.setState({ isFocused: false }),
          onpaste: this.onPaste,
          ref: (editor?: HTMLElement) => {
            if (editor && this.editor !== editor) {
              this.editor = editor;
              this.updateEditor(this.editor, this.getProps().value);
            }
          },
          contenteditable: !props.disabled,
        }),
        state.attachments && state.attachments.length > 0
          ? h('div', style([]), [
              h('div', style([mt('0.5em'), mb('0.5em')]), 'Attachments'),
              ...state.attachments.map((file, index) =>
                AttachmentViewer({
                  attachment: file,
                  key: file.name,
                  disabled: true,
                  downloadUrl: '',
                  downloadRequest: {
                    fileName: file.name,
                  },
                  hideDownloadIcon: true,
                  actionButton: h('i.fa.fa-times.ripple', {
                    style: style([
                      'red',
                      'large',
                      ml('auto'),
                      pad('0.75rem 1rem'),
                      {
                        height: '100%',
                        transform: 'translateX(0.7rem)',
                        boxSizing: 'border-box',
                      },
                    ]).style,
                    onclick: () => this.removeFile(index),
                  }),
                })
              ),
            ])
          : null,
        ...this.dialogs(),
        this.imageTypeErrorAlert(),
      ]
    );
  }

  private isElementInEditor(node: Node): boolean {
    if (node === this.editor) {
      return true;
    } else if (node.parentElement) {
      return this.isElementInEditor(node.parentElement);
    } else {
      return false;
    }
  }

  /*
     We strip the content pasted in editor of all the styles
    */
  private onPaste = (e: ClipboardEvent) => {
    e.preventDefault();
    const dom = document.createElement('div');
    dom.innerHTML = e.clipboardData?.getData('Text') || '';
    const markup = dom.innerText;
    this.pasteHtmlAtCaret(markup);
    this.getProps().oninput(this.getSource(), this.getState().attachments);
  };

  private async onFocus(_: FocusEvent) {
    /**
     * When the editor only contains an image
     * and nothing else, the cursor becomes invisible
     * and nothing is selectable. In this case,
     * when editor is focused, the selection range's
     * startContainer would either be an element outside the
     * editor, or getRangeAt(0) will throw an exception.
     * In each of these cases, we can append a newline to
     * the editor and place the cursor there.
     */
    try {
      if (this.containsImage()) {
        const selection = document.getSelection();
        const range = selection && selection.getRangeAt(0);
        if (range) {
          if (!this.isElementInEditor(range.startContainer)) {
            this.appendNewlineAndSelect();
          }
        }
      }
    } catch {
      if (this.containsImage()) {
        this.appendNewlineAndSelect();
      }
    } finally {
      await this.setState({ isFocused: true });
      this.saveSelection();
    }
  }

  private appendNewlineAndSelect() {
    if (this.editor && this.editor.firstChild) {
      const selection = document.getSelection();
      const br = document.createElement('br');
      this.editor.firstChild.appendChild(br);
      selection && selection.removeAllRanges();
      const range = document.createRange();
      range.setStartAfter(br);
      range.setEndAfter(br);
      selection && selection.addRange(range);
    }
  }

  protected uploadFile(file: File) {
    const props = this.getProps();
    const { name, extension } = u.splitFileName(file.name);
    if (!props.attachments) return;
    const { promise, progress$ } = upload(
      props.attachments.uploadUrl,
      {
        originalFileName: name,
        fileType: extension,
        activityId: props.attachments.activityId,
        activityType: props.attachments.activityType,
      },
      file
    );
    const resultPromise = promise.then(async (response) => {
      const attachment: IAttachment = {
        name: response.name,
        originalName: name,
        extension: extension,
      };
      googleAnalytics.fileUploaded(this.getAppContext(), this.getProps().subContext);
      await this.setState({
        attachments: [...this.getState().attachments, attachment],
      });
      await this.inputHandler();
    });
    return {
      promise: resultPromise,
      progress$,
    };
  }

  private getAppContext() {
    return getStore().getState().app.context;
  }

  protected lastFocusedElement: Element | null = null;

  private async removeFile(index: number) {
    const attachments = this.getState().attachments;
    await this.setState({
      attachments: [...attachments.slice(0, index), ...attachments.slice(index + 1)],
    });
    this.inputHandler();
  }

  protected dialogs() {
    return [
      this.getProps().enableImageInput ? this.imageDialog() : null,
      this.getProps().enableFormulaInput ? this.formulaDialog() : null,
    ];
  }

  public async showImageDialog() {
    if (this.isAccessible) {
      this.lastFocusedElement = this.editor;
      u.unsetTabIndices(this.editor);
    }
    this.editor.focus();
    this.saveSelection();
    this.editor.blur();
    await this.setState({
      isImageDialogOpen: true,
    });
  }

  protected async closeImageDialog() {
    if (this.isAccessible) {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    }
    const props = this.getProps();
    await this.setState({
      imageDialogImage: null,
      isImageDialogOpen: false,
    });
    if (props.onImageDialogClose) {
      await props.onImageDialogClose();
    }
  }

  public focus() {
    this.editor.focus();
  }

  protected formulaDialog() {
    const state = this.getState();
    if (!this.getProps().enableFormulaInput) return null;
    const primaryAction = {
      label: 'USE',
      mobileLabel: h('i.fa.fa-check', []),
      disabled: !state.formulaDialog || state.formulaDialog.inputField.length < 1,
      onclick: () => this.addFormula(),
    };

    const secondaryAction = {
      label: 'CANCEL',
      mobileLabel: h('i.fa.fa-times', []),
      onclick: () =>
        this.setState({
          formulaDialog: null,
          formulaOutput: null,
        }),
    };

    const inputTextArea = (styles?: CSS) =>
      !state.formulaDialog
        ? null
        : h(
            'textarea',
            style(
              [
                mt('0.5em'),
                {
                  width: '100%',
                  height: '7em',
                  border: 'none',
                  outline: 'none',
                  borderBottom: `1px solid ${colors.lightGrey}`,
                  resize: 'none',
                  fontSize: '1em',
                },
                styles || {},
              ],
              {},
              {
                value: state.formulaDialog.inputField,
                oninput: (e: any) => this.onFormulaInput(e.target.value),
                placeholder: `Enter ${
                  {
                    tex: 'TeX',
                    mml: 'MathML',
                    am: 'AsciiMath',
                  }[state.formulaDialog.language]
                } here`,
              }
            )
          );

    const formulaOutput = (styles?: CSS) =>
      !state.formulaDialog
        ? null
        : h(
            'div.formula-output',
            style([
              mt('0.5em'),
              'lightGrey',
              'borderBox',
              pad('auto'),
              {
                border: `1px solid ${colors.lightGrey}`,
                borderRadius: '0.3em',
                ...styles,
              },
            ]),
            [
              state.formulaDialog.inputField.length < 1
                ? h(
                    'div',
                    style([margin('auto'), 'lightGrey', 'small', 'textCenter', pad('1rem')]),
                    [
                      'As you type the expression in the input box below, ' +
                        'the formatted output will appear here.',
                    ]
                  )
                : null,
              h('div.formula-target', {
                style: {
                  display: state.formulaDialog.inputField.length < 1 ? 'none' : 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  color: 'black',
                },
                ref: (elem?: HTMLElement) => this.onFormulaOutputRef(elem),
                dangerouslySetInnerHTML: {
                  __html: this.formulaInputMarkup(),
                },
              }),
            ]
          );

    const dialogProps: IDialogProps = {
      open: !!state.formulaDialog,
      title: 'Inserting math expression',
      primaryAction: primaryAction,
      secondaryAction: secondaryAction,
    };

    return Dialog(
      {
        ...dialogProps,
        bodyStyle: {
          padding: '0px',
        },
      },
      !state.formulaDialog
        ? []
        : [
            formulaOutput({
              border: 'none',
              height: '6em',
              borderBottom: '1px solid lightGrey',
            }),

            h(
              'div',
              style([
                pad('0.5em 1em'),
                'flex',
                'alignCenter',
                {
                  borderBottom: '1px solid lightGrey',
                },
              ]),
              [
                h('span', style(['large']), 'Format and display'),
                h('span', style([ml('auto'), 'flex', 'alignCenter', 'grey']), [
                  h(
                    'span',
                    style(['small']),
                    `${this.showFormulaInputFormat(state.formulaDialog.language)}, ${u.capitalize(
                      state.formulaDialog.display
                    )}`
                  ),
                  h('i.fa.fa-pencil.ripple', {
                    style: style([pad('0.5rem'), ml('0.5rem')]).style,
                    onclick: async () => {
                      if (!state.formulaDialog) {
                        return;
                      }
                      await this.setState({
                        formatAndDisplayDialog: {
                          display: state.formulaDialog.display,
                          language: state.formulaDialog.language,
                        },
                      });
                    },
                  }),
                ]),
              ]
            ),

            h(
              'div',
              style([
                pad('0.5em 1em'),
                {
                  borderBottom: `1px solid ${colors.lighterGrey}`,
                },
              ]),
              [
                h('div', 'Input'),
                inputTextArea({
                  borderBottom: 'none',
                }),
              ]
            ),
            this.formatAndDisplayDialog(),
          ]
    );
  }

  private formulaOutput?: HTMLElement;
  private onFormulaOutputRef(elem?: HTMLElement) {
    if (elem !== this.formulaOutput) {
      this.formulaOutput = elem;
      if (MathJax) {
        MathJax.Hub.Queue(['Typeset', MathJax.Hub, elem], function () {
          console.log('typesetting done');
        });
      }
    }
  }

  private formatAndDisplayDialog() {
    const state = this.getState();
    const heading = (label: string, otherStyles: CSS = {}) =>
      h('div', style(['bold', 'green', 'large', mb('0.5rem'), otherStyles]), label);

    const radioButton = <K extends 'language' | 'display'>(
      key: K,
      value: { language: IMathJaxLanguage; display: IMathJaxDisplay }[K]
    ) =>
      state.formatAndDisplayDialog
        ? h(
            'div',
            style(
              [
                'flex',
                'alignCenter',
                pad('1rem 0rem'),
                {
                  color: state.formatAndDisplayDialog[key] === value ? colors.blue : undefined,
                  borderBottom: '1px solid lightGrey',
                },
              ],
              {},
              {
                onclick: async () => {
                  const state = this.getState();
                  if (!state.formatAndDisplayDialog) {
                    return;
                  }
                  await this.setState({
                    formatAndDisplayDialog: {
                      ...state.formatAndDisplayDialog,
                      [key]: value,
                    },
                  });
                },
              }
            ),
            [
              RadioButton({
                selected:
                  state.formatAndDisplayDialog && state.formatAndDisplayDialog[key] === value,
                style: {
                  fontSize: '1.4em',
                  marginRight: '0.5rem',
                },
              }),
              h(
                'div',
                key === 'language'
                  ? this.showFormulaInputFormat(<IMathJaxLanguage>value)
                  : u.capitalize(value)
              ),
            ]
          )
        : null;

    return Alert(
      {
        open: !!state.formatAndDisplayDialog,
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('CANCEL', {
            type: 'secondary',
            onclick: () =>
              this.setState({
                formatAndDisplayDialog: null,
              }),
          }),
          FlatButton('DONE', {
            onclick: async () => {
              const formulaDialog = state.formulaDialog;
              if (!formulaDialog) {
                return;
              }

              await this.setState({
                formulaDialog: {
                  ...formulaDialog,
                  ...state.formatAndDisplayDialog,
                },
                formatAndDisplayDialog: null,
              });
            },
          }),
        ],
      },
      state.formatAndDisplayDialog
        ? [
            heading('Input format'),
            radioButton('language', 'tex'),
            radioButton('language', 'am'),
            radioButton('language', 'mml'),

            heading('Output display style', mt('1rem')),
            radioButton('display', 'inline'),
            radioButton('display', 'block'),
          ]
        : []
    );
  }

  private showFormulaInputFormat(format: 'am' | 'mml' | 'tex') {
    return {
      tex: 'TeX',
      am: 'AsciiMath',
      mml: 'MathML',
    }[format];
  }

  private async onFormulaInput(text: string) {
    const { formulaDialog } = this.getState();
    if (!formulaDialog) {
      return;
    }
    await this.setState({
      formulaDialog: {
        ...formulaDialog,
        inputField: text,
      },
    });
    if (MathJax) {
      MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.formulaOutput], function (...args: any[]) {
        console.log('typeset', args);
      });
      // MathJax.Hub.Register.StartupHook("TeX noUndefined Ready", function () {
      //     var TEX = MathJax.Hub.InputJax;
      //     var formatError = TEX.formatError;
      //     console.log("format", formatError);
      // });
      // MathJax.Hub.Register.MessageHook("Math Processing Error", function (error) {
      //     console.log("wat????", error);
      // });
      MathJax.Hub.Register.MessageHook('error', () => {
        console.log('here we are');
      });
    }
  }

  private formulaInputMarkup(): string {
    const formulaDialog = this.getState().formulaDialog;
    if (!formulaDialog) return '';
    let html = '';
    if (formulaDialog.language === 'mml') {
      try {
        html = formulaDialog.inputField;
        const div = document.createElement('div');
        div.innerHTML = html;
        div.children[0].setAttribute('display', formulaDialog.display);
        html = div.innerHTML.toString();
      } catch {
        return '';
      }
    } else if (formulaDialog.language === 'tex') {
      html = '\\( ' + formulaDialog.inputField + ' \\)';
    } else {
      html = '` ' + formulaDialog.inputField + ' `';
    }
    return html;
  }

  private imageTypeErrorAlert() {
    const isError = this.getState().isCorrectFileTypeError;
    return Alert(
      {
        style: {
          width: '20em',
        },
        title: 'Warning',
        open: isError,
        actions: [
          FlatButton('Ok', {
            type: 'secondary',
            onclick: () =>
              this.setState({
                isCorrectFileTypeError: false,
              }),
          }),
        ],
      },
      [h('div', {}, `Please upload a file with the correct extension: png, jpg, jpeg`)]
    );
  }

  private async checkFileType(file: File) {
    const type = u.splitFileName(file.name).extension.toLowerCase();
    const fileTypes = ['jpg', 'jpeg', 'png'];
    console.log('tpye', type);
    if (fileTypes.indexOf(type) > -1) {
      await this.setState({
        isCorrectFileTypeError: false,
      });
    } else {
      await this.setState({
        isCorrectFileTypeError: true,
      });
    }
  }

  private async addFormula() {
    this.isEditorLocked = true;
    const { formulaDialog } = this.getState();
    if (!formulaDialog) return;
    const response = await appApi.formulaAdd({
      format: formulaDialog.language,
      math: formulaDialog.inputField,
      display: formulaDialog.display,
    });
    googleAnalytics.formulaCreated(this.getAppContext(), this.getProps().subContext);
    await this.setState({
      formulaDialog: null,
    });
    const formula = response.data;
    this.editor.focus();
    this.restoreSelection();
    let html;
    if (formulaDialog.language === 'mml') {
      const div: any = document.createElement('div');
      div.innerHTML = formulaDialog.inputField;
      div.childNodes[0].setAttribute('display', formulaDialog.display);
      html = div.innerHTML;
    } else if (formulaDialog.language === 'am') {
      html = '` ' + formulaDialog.inputField + ' `';
      if (formulaDialog.display === 'block') {
        html =
          "<div style='display:flex;justify-content:center;align-items:center;width:100%'>" +
          html +
          '</div>';
      }
    } else {
      if (formulaDialog.display === 'inline') {
        html = '\\(' + formulaDialog.inputField + '\\)';
      } else {
        html = '$$' + formulaDialog.inputField + '$$';
      }
    }
    const elem = document.createElement('formula');
    elem.setAttribute('contenteditable', 'false');
    elem.setAttribute('display', formulaDialog.display);
    elem.setAttribute('format', formulaDialog.language);
    elem.setAttribute('id', formula._id);
    elem.setAttribute('acw', formula.acw.toString());
    elem.setAttribute('ach', formula.ach.toString());
    elem.setAttribute('url', formula.url);

    elem.innerHTML = html;
    if (formulaDialog.display === 'inline') {
      this.pasteHtmlAtCaret(elem.outerHTML + '&nbsp;');
    } else {
      this.pasteHtmlAtCaret(elem.outerHTML + '<br>');
    }
    if (MathJax) {
      MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.editor], () => {
        this.inputHandler();
        this.isEditorLocked = false;
      });
    }
  }

  public containsImage() {
    if (this.editor) {
      return this.editor.getElementsByTagName('img').length > 0;
    } else {
      return false;
    }
  }

  public containsText() {
    if (this.editor) {
      return (
        this.editor.textContent &&
        this.editor.textContent.length > 0 &&
        !/^\s*$/.test(this.editor.textContent)
      );
    } else {
      return false;
    }
  }

  protected imageDialog() {
    const image = this.getState().imageDialogImage;
    const uploadButton = UploadButton({
      accept: 'image/png,image/jpg,image/jpeg',
      upload: (file) => this.handleImageUpload(file),
      view: Paper(
        '.raw-editor__upload-button.ripple',
        {
          role: 'button',
          ariaLabel: 'Choose Image Button',
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        'CHOOSE IMAGE'
      ),
    });
    return Alert(
      {
        open: this.getState().isImageDialogOpen,
        title: h('div', style(['green']), 'Upload an image'),
        style: {
          width: '25rem',
        },
        actions: [
          FlatButton('CANCEL', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.closeImageDialog(),
          }),
          FlatButton('UPLOAD', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.addImage(),
            disabled: !image,
          }),
        ],
      },
      [
        image !== null
          ? h(
              'div',
              style([
                'flex',
                'fullWidth',
                'justifyCenter',
                margin('0.5em 0'),
                {
                  maxHeight: '15em',
                  maxWidth: '100%',
                },
              ]),
              [
                h('img', {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  alt: 'uploaded image',
                  src: image.url,
                  style: {
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  },
                }),
              ]
            )
          : null,
        uploadButton,
      ]
    );
  }

  private handleImageUpload(file: File) {
    console.log('uploading image');
    this.checkFileType(file);
    const isCorrectError = this.getState().isCorrectFileTypeError;
    if (isCorrectError) {
      return;
    }
    const url = api().imageUpload;
    const match = file.name.match(/\.([^.]+)$/);
    const fileType = match ? match[1] : '';
    const data = {
      file,
      originalFileName: file.name.replace(/\.[^.]+$/, ''),
      fileType,
    };
    const { promise, progress$ } = http.upload(url, {
      method: 'POST',
      data,
    });
    this.setState({
      imageDialogImage: null,
    }).then(() =>
      promise.then((response) => {
        googleAnalytics.imageUploaded(this.getAppContext(), this.getProps().subContext);
        const data = JSON.parse(response.data);
        this.setState({
          imageDialogImage: data,
        });
      })
    );
    return { promise, progress$ };
  }

  protected async addImage() {
    const image = this.getState().imageDialogImage;
    if (!image) return;
    this.editor.focus();
    this.restoreSelection();
    // encoding removed because it's already a url
    const parsedMarkup = this.parseMarkup(
      "<img src='" +
        image.url +
        "' ach='" +
        encodeURI(image.ach.toString()) +
        "' acw='" +
        encodeURI(image.acw.toString()) +
        "'><br>"
    );
    if (parsedMarkup != null) {
      this.pasteHtmlAtCaret(parsedMarkup.outerHTML);
    }
    this.saveSelection();
    await this.inputHandler();
    await this.closeImageDialog();
  }

  private pasteHtmlAtCaret(html: string) {
    this.editor.focus();
    this.restoreSelection();
    let sel, range;
    if (window.getSelection) {
      // IE9 and non-IE
      sel = window.getSelection();
      if (sel && sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();

        // Range.createContextualFragment() would be useful here but is
        // non-standard and not supported in all browsers (IE9, for one)
        const el = document.createElement('div');
        el.innerHTML = html;
        const frag = document.createDocumentFragment();
        let node: any, lastNode: any;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    } else if ((document as any).selection && (document as any).selection.type !== 'Control') {
      // IE < 9
      (document as any).selection.createRange().pasteHTML(html);
    }
  }

  private async inputHandler() {
    const attachments = this.getState().attachments;
    await this.getProps().oninput(this.getSource(), attachments ? attachments : []);
    this.saveSelection();
  }

  public async showFormulaDialog() {
    if (!this.editor) {
      return;
    }
    this.restoreSelection();
    await this.setState({
      formulaDialog: {
        inputField: '',
        language: 'tex',
        display: 'inline',
      },
    });
  }

  private keyDownHandler(event: KeyboardEvent) {
    function inFormula(node: any): any {
      if (node.nodeName === '#text') {
        return inFormula(node.parentNode!);
      } else if (node.classList.contains('rich-text-editor-textbox-container')) {
        return null;
      } else if (node.tagName.toUpperCase() === 'FORMULA') {
        return node;
      } else {
        return inFormula(node.parentElement);
      }
    }

    /**
     * Get node before current node
     * If node is first child of parent, then recursively get parent's
     * preceeding node.
     * Return null when editor node is reached
     * @param {*} node
     */
    function getPrecedingNode(node: any): any {
      if (
        node.nodeName !== '#text' &&
        node.classList &&
        node.classList.contains('rich-text-editor-textbox-container')
      ) {
        return null;
      }
      const childNum = Array.prototype.indexOf.bind(node.parentElement.childNodes, node)();
      if (childNum === 0) {
        // first node of parent?
        return getPrecedingNode(node.parentElement);
      } else {
        return node.previousSibling;
      }
    }

    if (window.getSelection && event.which === 8) {
      // backspace
      try {
        // fix backspace bug in FF
        // https://bugzilla.mozilla.org/show_bug.cgi?id=685445
        const selection = window.getSelection();
        if (!selection?.isCollapsed || !selection.rangeCount) {
          this.inputHandler();
          return;
        }

        let curRange = selection.getRangeAt(selection.rangeCount - 1);
        if (curRange.collapsed) {
          const elem = curRange.startContainer.childNodes[curRange.startOffset - 1];
          if (
            elem &&
            elem instanceof HTMLElement &&
            elem.getAttribute('contenteditable') === 'false' &&
            elem.parentElement
          ) {
            event.preventDefault();
            elem.parentElement.removeChild(elem);
            this.inputHandler();
            return;
          }
        }

        const formula = inFormula(selection.anchorNode);

        if (formula) {
          event.preventDefault();
          formula.outerHTML = '';
          this.inputHandler();
          return;
        }

        let previousNode = getPrecedingNode(selection.getRangeAt(0).startContainer);
        let previousTag: string | undefined = undefined;
        if (previousNode) previousTag = previousNode.nodeName.toUpperCase();
        // delete previous formula or image node if selection is at beginning of
        // current node
        if (previousNode && curRange.startOffset === 0) {
          if (previousTag === 'SPAN' && previousNode.classList.contains('acadly-img-container')) {
            const newRange = curRange.cloneRange();
            newRange.selectNode(previousNode);
            newRange.deleteContents();
            event.preventDefault();
            this.inputHandler();
            return;
          }
          if (previousTag === 'FORMULA' || previousTag === 'IMG') {
            const newRange = curRange.cloneRange();
            newRange.selectNode(previousNode);
            newRange.deleteContents();
            event.preventDefault();
            this.inputHandler();
            return;
          }
        }

        curRange = selection.getRangeAt(selection.rangeCount - 1);
        if (curRange.commonAncestorContainer.nodeType === 3 && curRange.startOffset > 0) {
          // we are in child selection. The characters of the text node is being deleted
          this.inputHandler();
          return;
        }

        const range = document.createRange();
        if (selection.anchorNode && selection.anchorNode !== this.editor) {
          // selection is in character mode. expand it to the whole editable field
          range.selectNodeContents(this.editor);
          range.setEndBefore(selection.anchorNode);
        } else if (selection.anchorOffset > 0) {
          range.setEnd(this.editor, selection.anchorOffset);
        } else {
          // reached the beginning of editable field
          this.inputHandler();
          return;
        }

        previousNode = range.cloneContents().lastChild;
        if (previousNode && previousNode.contentEditable === 'false') {
          // this is some rich content, e.g. smile. We should help the user to delete it
          range.deleteContents();
          event.preventDefault();
        }
        this.inputHandler();
      } catch (e) {
        this.inputHandler();
        return;
      }
    }
    this.inputHandler();
  }

  private saveSelection() {
    try {
      if (window.getSelection) {
        // non IE Browsers
        this.savedRange = window.getSelection()?.getRangeAt(0);
      } else if ((document as any).selection) {
        // IE
        this.savedRange = (document as any).selection.createRange();
      }
    } catch (e) {
      this.appendNewlineAndSelect();
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        this.savedRange = selection.getRangeAt(0);
      }
    }
  }

  protected isBold() {
    return this.getState().isFocused && document.queryCommandState('Bold');
  }

  protected isItalic() {
    return this.getState().isFocused && document.queryCommandState('Italic');
  }

  protected isUnderlined() {
    return this.getState().isFocused && document.queryCommandState('Underline');
  }

  protected clickBold() {
    this.restoreSelection();
    document.execCommand('bold', false);
  }

  protected clickItalic() {
    this.restoreSelection();
    document.execCommand('italic', false);
  }

  protected clickUnderline() {
    this.restoreSelection();
    document.execCommand('underline', false);
  }

  private restoreSelection() {
    this.editor.focus();
    if (this.savedRange) {
      if (window.getSelection) {
        // non IE and there is already a selection
        const s = window.getSelection();
        if (s && s.rangeCount > 0) s.removeAllRanges();
        s?.addRange(this.savedRange);
      } else if (document.createRange) {
        // non IE and no selection
        window.getSelection().addRange(this.savedRange);
      } else if ((document as any).selection) {
        // IE
        this.savedRange.select();
      }
    }
  }
}

// function stripStyle(elem: Element) {
//     if (elem.tagName.toUpperCase() === "FORMULA"
//             || elem.tagName.toUpperCase() === "IMG" ) {
//         return;
//     }
//     else {
//         elem.removeAttribute("style");
//         for (let i = 0; i < elem.children.length; i++) {
//             stripStyle(elem.children[i]);
//         }
//     }
// }
