export interface FlowSubscriber<VM> {
  (state: DeepReadonly<VM>): any;
}
export abstract class UIFlow {
  private subscriber: FlowSubscriber<this>;

  protected async commit() {
    await this.subscriber(this.getViewModel());
  }

  protected async update(f: () => any) {
    await f();
    await this.commit();
  }

  public subscribe(subscriber: FlowSubscriber<this>) {
    this.subscriber = subscriber;
    this.commit();
  }

  protected getViewModel(): DeepReadonly<this> {
    return <any>this;
  }
}
