import { CSS, h, IComponent } from 'core';

import { colors, ml, mt, style } from 'acadly/styles';
import IsMobile from 'acadly/utils/IsMobile';

export interface ITextFieldProps {
  type?: 'number' | 'password' | 'email';
  id?: string;
  placeholder?: string;
  floatingLabelText?: string;
  fullWidth?: boolean;
  value: string;
  ariaRequired?: boolean;
  ariaLabel?: string;
  ariaDescribedBy?: string;
  ariaLabelledBy?: string;
  autofocus?: boolean;
  selectTextOnFocus?: boolean;
  oninput?(event: Event & { target: EventTarget & { value: string } }): void;
  onenter?(event: KeyboardEvent): void;
  onkeyup?(event: KeyboardEvent): void;
  onkeydown?(event: KeyboardEvent): void;
  onfocus?(event: FocusEvent): void;
  hasError?: boolean;
  errorText?: string | null;
  style?: CSS;
  inputStyle?: CSS;
  noHintOrError?: boolean;
  raw?: boolean;
  min?: number;
  max?: number;
  showRemainingLength?: boolean;
  maxLength?: number;
  borderStyle?: {
    focused?: CSS;
    blurred?: CSS;
  };
  placeholderStyle?: CSS;
  floatingLabelStyle?: CSS;
  center?: boolean;
  focusOnMount?: boolean;
  allowedPattern?: RegExp;
  hideUnderline?: boolean;
  className?: string;
}

export class TextFieldC extends IComponent<
  ITextFieldProps,
  {
    isFocused: boolean;
    focusedOnMount: boolean;
  }
> {
  public componentWillMount() {
    this.setState({
      isFocused: false,
      focusedOnMount: false,
    });
  }
  public render() {
    const props = this.getProps();
    const errorRed = 'rgb(244, 67, 54)';
    const isCentered =
      props.center || (props.inputStyle && props.inputStyle.textAlign === 'center');
    const isRightAligned = props.inputStyle && props.inputStyle.textAlign === 'right';
    if (props.raw) {
      return h('input', {
        style: { ...props.style },
        type: props.type,
        oninput: props.oninput,
        value: props.value,
        id: props.id ? props.id : undefined,
        className: props.className ? props.className : undefined,
      });
    }
    return h(
      'div.text-field',
      {
        style: {
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          ...props.style,
        },
      },
      [
        props.noHintOrError
          ? null
          : h(
              'span',
              {
                style: {
                  position: 'relative',
                },
              },
              [
                h('span', { style: { visibility: 'hidden' } }, 'label'),
                h(
                  'span.floating-label-text',
                  {
                    style: {
                      position: 'absolute',
                      paddingBottom: '0.5rem',
                      top:
                        props.errorText || (this.getState().isFocused && props.floatingLabelText)
                          ? 0
                          : '100%',
                      left: props.center ? '50%' : 0,
                      transform: props.center ? 'translateX(-50%)' : undefined,
                      transition: 'top 0.2s ease-in-out',
                      fontSize: '0.8em',
                      color: props.errorText ? colors.errorRed : colors.grey,
                      visibility:
                        props.errorText || (this.getState().isFocused && props.floatingLabelText)
                          ? 'visible'
                          : 'hidden',
                      ...(props.errorText ? {} : props.floatingLabelStyle),
                    },
                  },
                  props.errorText ? props.errorText : props.floatingLabelText
                ),
              ]
            ),
        h(`input.text-field-input${props.errorText ? '.error' : ''}`, {
          type: props.type,
          ref: this.onRef,
          id: props.id,
          autofocus: this.getProps().autofocus || false,
          style: {
            zIndex: '1',
            fontSize: '1.1em',
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            width: '100%',
            paddingTop: '0.5em',
            paddingBottom: '0.25em',
            textAlign: props.center ? 'center' : undefined,
            ...props.inputStyle,
          },
          className: props.className ? props.className : undefined,
          'aria-label': props.ariaLabel ? props.ariaLabel : props.placeholder || undefined,
          'aria-required': props.ariaRequired,
          'aria-describedby': props.ariaDescribedBy,
          'aria-labelledby': props.ariaLabelledBy,
          onfocus: (event) => {
            this.setState({ isFocused: true });

            if (props.selectTextOnFocus) {
              setTimeout(() => {
                if (this.getState().isFocused) {
                  (event.target as HTMLInputElement).select();
                }
              }, 20);
            }

            if (props.onfocus) {
              props.onfocus(event);
            }
          },
          onblur: () => this.setState({ isFocused: false }),
          oninput: (event: any) => this.handleInput(event),
          onkeyup: (event: any) => {
            if (props.onkeyup) {
              props.onkeyup(event);
            } else if (props.type === 'number') {
              this.handleInput(event);
            }
          },
          onkeydown: props.onenter
            ? (event: KeyboardEvent) => {
                if (event.keyCode === 13) {
                  event.preventDefault();
                  props.onenter!(event);
                  if (this.elem) {
                    this.elem.blur();
                  }
                }
              }
            : props.onkeydown ||
              ((e: KeyboardEvent) => {
                e.stopPropagation();
              }),
          value: props.value,
        }),
        h(
          'div.input-field-border',
          {
            style: {
              position: 'relative',
              display: 'flex',
            },
          },
          [
            !props.hideUnderline
              ? h(
                  'div',
                  {
                    style: {
                      height: '2px',
                      width:
                        props.errorText || props.hasError || this.getState().isFocused
                          ? '100%'
                          : '0%',
                      transition: 'width 0.2s ease-in-out',
                      backgroundColor:
                        props.errorText || props.hasError
                          ? errorRed
                          : this.getState().isFocused
                          ? colors.blue
                          : colors.lightGrey,
                      ...(props.borderStyle ? props.borderStyle.focused : {}),
                    },
                  },
                  ' '
                )
              : null,
            !props.hideUnderline
              ? h('div', {
                  style: {
                    height: '2px',
                    width:
                      !props.errorText && !props.hasError && !this.getState().isFocused
                        ? '100%'
                        : '0%',
                    marginLeft: 'auto',
                    transition: 'width 0.2s ease-in-out',
                    backgroundColor: colors.lightGrey,
                    ...(props.borderStyle ? props.borderStyle.blurred : {}),
                  },
                })
              : null,
            !props.value && props.placeholder && !this.getState().isFocused
              ? h(
                  'div.input-placeholder',
                  {
                    style: {
                      position: 'absolute',
                      zIndex: 0,
                      fontSize: '1.1em',
                      bottom: '0.3em',
                      left: isCentered ? '50%' : isRightAligned ? undefined : '0',
                      right: isRightAligned ? '0' : undefined,
                      transform: isCentered ? 'translateX(-50%)' : undefined,
                      color: colors.lightGrey,
                      ...props.placeholderStyle,
                    },
                  },
                  props.placeholder
                )
              : null,
          ]
        ),
        props.maxLength && props.showRemainingLength
          ? h('div', style(['lightGrey', ml('auto'), mt('0.3rem'), 'small']), [
              `${props.value.length}/${props.maxLength}`,
            ])
          : null,
      ]
    );
  }

  private elem?: HTMLElement;
  private onRef = (elem?: HTMLElement) => {
    if (!elem) return;
    if (this.elem === elem) {
      return;
    }
    this.elem = elem;
    if (!IsMobile.iOS() && this.getProps().focusOnMount) {
      elem.focus();
      this.setState({ focusedOnMount: true });
    }
  };

  public handleInput(event: any) {
    const props = this.getProps();
    if (props.oninput === undefined) {
      return;
    }
    if (event.target.value !== '' && props.allowedPattern !== undefined) {
      if (!props.allowedPattern.test(event.target.value)) {
        props.oninput({
          ...event,
          target: {
            ...event.target,
            value: props.value,
          },
        });
        return;
      }
    }
    if (props.type === 'number') {
      const value = parseInt(event.target.value);
      if (props.min !== undefined) {
        if (value < props.min) {
          props.oninput({
            ...event,
            target: {
              ...event.target,
              value: props.min.toString(),
            },
          });
          return;
        }
      }
      if (props.max !== undefined) {
        if (value > props.max) {
          props.oninput({
            ...event,
            target: {
              ...event.target,
              value: props.max.toString(),
            },
          });
          return;
        }
      }
    }
    if (props.maxLength) {
      if (event.target.value.length <= props.maxLength) {
        props.oninput(event);
      } else {
        props.oninput({
          ...event,
          target: {
            ...event.target,
            value: event.target.value.slice(0, props.maxLength),
          },
        });
        return;
      }
    } else {
      props.oninput(event);
    }
  }
}
export const TextField = (props: ITextFieldProps) => h(TextFieldC, props);
export default TextField;
