import { h, HTMLAttrs } from 'core';

interface SvgIconProps extends HTMLAttrs {
  icon: string;
  noFill?: boolean;
  /** valid html tag */
  component?: string;
  disabled?: boolean;
}

const SvgIcon = ({ component = 'span', icon, noFill = false, ...props }: SvgIconProps) => {
  return h(`${component}.svg-icon${noFill ? '.no-fill' : ''}`, {
    'aria-hidden': component === 'span',
    dangerouslySetInnerHTML: {
      __html: icon,
    },
    ...props,
  });
};

export default SvgIcon;
