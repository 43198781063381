import { h, IComponent } from 'core';

import { api as urls } from 'acadly/api';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Hover from 'acadly/common/Hover';
import Icon from 'acadly/common/Icon';
import Paper from 'acadly/common/Paper';
import TextField from 'acadly/common/TextField';
import UploadButton from 'acadly/common/UploadButton';
import { unix } from 'acadly/datetime';
import icons from 'acadly/icons';
import { dispatch, getStore } from 'acadly/store';
import { colors, mb, ml, mr, mt, pad, pb, pl, pr, pt, style, tabHeight } from 'acadly/styles';
import * as utils from 'acadly/utils';
import * as u from 'acadly/utils';

import { Actions } from './actions';
import * as api from './api';
import EditBook, { IEditBookFields } from './EditBook';
import { getTeamMember, getTopicById } from './functions';
import courseService from './service';

export interface ISyllabusProps {
  topics: ITopic[];
  file?: IAttachment;
}
export interface ISyllabusState {
  isEditTopicDialogOpen: boolean;
  editingTopicId: string | null;
  editTopicParentId: string | null;
  editTopicTitleError: boolean;
  editTopicBooksField: ITopicBook[];
  editTopicLinksField: ILink[];
  editTopicTitleField: string;
  isAddBookDialogOpen: boolean;

  isViewingTopicId: string | null;
  isDeleteTopicOverlayVisibleForId: string | null;

  isEditBookDialogOpen: boolean;
  isEditingBook: ITopicBook | null;
  viewTopicBooks: ITopicBook[];
  viewTopicLinks: ILink[];

  isDeleteBookDialogVisibleFor: ITopicBook | null;

  isAddLinkDialogOpen: boolean;
  addLinkTitleField: string;
  addLinkURLField: string;
  addLinkTitleError: boolean;
  addLinkURLError: boolean;

  file: IAttachment;

  isDeleteLinkDialogVisibleFor: ILink | null;
}
const initialState: ISyllabusState = {
  isEditTopicDialogOpen: false,
  editingTopicId: null,
  editTopicTitleError: false,
  editTopicParentId: null,
  editTopicBooksField: [],
  editTopicLinksField: [],
  editTopicTitleField: '',

  isViewingTopicId: null,
  isDeleteTopicOverlayVisibleForId: null,

  isAddBookDialogOpen: false,
  isEditBookDialogOpen: false,
  isEditingBook: null,

  viewTopicBooks: [],
  viewTopicLinks: [],

  isDeleteBookDialogVisibleFor: null,

  isAddLinkDialogOpen: false,
  isDeleteLinkDialogVisibleFor: null,
  addLinkTitleField: '',
  addLinkTitleError: false,
  addLinkURLField: '',
  addLinkURLError: false,
  file: {
    name: '',
    originalName: '',
    extension: '',
  },
};

export class Syllabus extends IComponent<ISyllabusProps, ISyllabusState> {
  public componentWillMount() {
    this.setState(initialState);
  }
  public componentDidMount() {
    const tabs = document.getElementById('sliding-tabs');
    if (tabs) {
      tabs.focus();
    }
  }

  private isAccessible: boolean | undefined = false;

  public render() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const syllabusFile = getStore().getState().courses.syllabusFile;
    const { topics } = this.getProps();
    return h(
      'div.course-syllabus-screen',
      style([], {
        height: `calc(100% - ${tabHeight()})`,
        overflowY: 'scroll',
        paddingTop: this.tabHeight(),
      }),
      [
        h(
          'div',
          style(['mainPanel'], {
            marginBottom: '1em',
          }),
          [
            h(
              'div#syllabus-details',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                'aria-label': 'Syllabus Details',
              },
              [
                h(
                  'div',
                  style([
                    pad('0.5em 1em'),
                    'bold',
                    {
                      color: '#333',
                    },
                  ]),
                  'Details'
                ),
                this.isEditEnabled() && !syllabusFile
                  ? UploadButton({
                      ariaLabel: 'Upload Syllabus File Button',
                      tabIndex: this.isAccessible ? 0 : undefined,
                      view: h('div', this.editableCardHeaderAttrs(), [
                        h(
                          'div',
                          {
                            ...style(
                              [
                                'flex',
                                'fullWidth',
                                'borderBox',
                                'spaceBetween',
                                'alignCenter',
                                pad('0.5em 1em'),
                                'pointer',
                              ],
                              {
                                marginTop: '0.5em',
                                backgroundColor: 'white',
                              }
                            ),
                          },
                          [
                            h('div', style(['grey']), 'Upload Syllabus File'),
                            Icon(icons.plus, style(['blue'])),
                          ]
                        ),
                      ]),
                      upload: (file) => {
                        const { promise, progress$ } = api.syllabusFileUpload(file);
                        return {
                          promise: promise.then((attachment) => {
                            dispatch(Actions.uploadSyllabusFileSuccess(attachment));
                          }),
                          progress$,
                        };
                      },
                    })
                  : null,
                syllabusFile
                  ? this.fileView()
                  : h(
                      'div',
                      style([pad('0.5em 1em'), { color: '#666' }]),
                      'The course admin has not added any syllabus details yet'
                    ),
              ]
            ),
            h(
              'div',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                'aria-label': 'Syllabus Topics',
              },
              [
                h(
                  'div',
                  style([
                    pad('0.5em 1em'),
                    'bold',
                    {
                      color: '#333',
                    },
                  ]),
                  'Topics'
                ),
                this.isEditEnabled() && topics.length === 0
                  ? h(
                      'div',
                      style(['grey'], {
                        marginTop: '2em',
                        marginBottom: '2em',
                        padding: '0 3em',
                        width: '100%',
                        boxSizing: 'border-box',
                        textAlign: 'center',
                      }),
                      [
                        'Please use this space to outline Course Topics. ' +
                          'Each Topic can have its own subtopics to give your ' +
                          'course a modular structure. Once added, you can specify ' +
                          'books and links for each topic/subtopic that can help ' +
                          'a student understand it in-depth.',
                      ]
                    )
                  : null,
                this.topicsList(),
              ]
            ),

            this.isEditEnabled()
              ? h(
                  'div',
                  {
                    style: style(
                      [
                        'flex',
                        'alignCenter',
                        'spaceBetween',
                        'borderBox',
                        pad('0.5em 1em'),
                        'pointer',
                      ],
                      {
                        marginTop: '0.5em',
                        backgroundColor: 'white',
                      }
                    ).style,
                    tabIndex: this.isAccessible ? 0 : undefined,
                    'aria-label': 'Add a new Topic',
                    role: 'button',
                    onclick: () => this.openEditTopicDialog(null),
                  },
                  [
                    h('div', style(['grey']), 'Add a new topic'),
                    Icon(icons.plus, style(['blue', 'large'])),
                  ]
                )
              : null,
            this.viewTopicDialog(),
            this.editTopicDialog(),
            this.deleteTopicDialog(),
          ]
        ),
      ]
    );
  }

  private deleteTopicDialog() {
    const topicId = this.getState().isDeleteTopicOverlayVisibleForId;
    const topic = topicId ? this.getTopicById(topicId) : null;
    const deleteWarning = (text: string) => h('div', style([pt('0.5rem'), pb('0.5rem')]), text);
    return Alert(
      {
        open: topic !== null,
        actions: [
          FlatButton('NO', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                isDeleteTopicOverlayVisibleForId: null,
              }).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElementSpare2 as HTMLElement).focus();
              }),
          }),
          FlatButton('YES', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.handleDeleteTopic(topic).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              }),
          }),
        ],
        style: {
          width: '20em',
        },
      },
      [
        h('div', { style: { color: colors.errorRed, fontSize: '1.2em' } }, 'Removing a Topic'),
        topic
          ? h('div', style(['bold', 'grey', pt('0.5rem'), pb('0.5rem')]), topic.details.title)
          : '',
        deleteWarning('Are you sure you want to remove this topic from the syllabus'),
        deleteWarning('All the subtopics and content will be removed irreversibly'),
      ]
    );
  }

  private async handleDeleteTopic(topic: ITopicChild | null) {
    if (!topic) return;
    await dispatch(
      Actions.deleteTopic(
        {
          topicId: topic.topicId,
          type: topic.topicId === topic.parentId ? 'topic' : 'subTopic',
        },
        () =>
          this.setState({
            isDeleteTopicOverlayVisibleForId: null,
            isEditTopicDialogOpen: false,
            isViewingTopicId: null,
            editTopicBooksField: [],
            editTopicTitleField: '',
            editTopicLinksField: [],
            editTopicTitleError: false,
            editTopicParentId: null,
          })
      )
    );
  }

  private topicsList() {
    const { topics } = this.getProps();
    return h(
      'div.topics-list',
      style([], {
        color: '#555',
        width: '100%',
      }),
      topics.length === 0
        ? [
            h(
              'div',
              style([pad('0.5em 1em'), { color: '#666' }]),
              'The course admin has not added any syllabus topics yet'
            ),
          ]
        : topics.map((topic) =>
            h(
              'div.topic',
              style(
                [],
                {
                  marginTop: '0.5em',
                  cursor: 'pointer',
                },
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  'aria-label': 'Topic ' + topic.topics[0].details.title,
                  key: 'parent-' + topic._id,
                }
              ),
              [
                h(
                  'div',
                  style(
                    [
                      {
                        backgroundColor: 'white',
                        padding: '1em 1.5em',
                      },
                    ],
                    {},
                    {
                      key: topic.topics[0].topicId,
                      onclick: () => {
                        this.lastFocusedElement = document.activeElement;
                        u.unsetTabIndices();
                        this.openViewTopicDialog(topic.topics[0]);
                      },
                    }
                  ),
                  topic.topics[0].details.title
                ),
                ...topic.topics.slice(1).map((subtopic) =>
                  h(
                    'div.ripple',
                    style(
                      [
                        pad('1em 2em'),
                        {
                          backgroundColor: 'white',
                          marginTop: '0.1em',
                        },
                      ],
                      {},
                      {
                        tabIndex: this.isAccessible ? 0 : undefined,
                        'aria-label': 'sub-topic ' + subtopic.details.title,
                        key: subtopic.topicId,
                        onclick: () => {
                          this.lastFocusedElement = document.activeElement;
                          u.unsetTabIndices();
                          this.openViewTopicDialog(subtopic);
                        },
                      }
                    ),
                    subtopic.details.title
                  )
                ),
                this.isEditEnabled()
                  ? h(
                      'div.ripple',
                      style(
                        ['flex', 'alignCenter'],
                        {
                          backgroundColor: 'white',
                          marginTop: '0.1em',
                          padding: '1em 1em',
                          paddingLeft: '2em',
                        },
                        {
                          tabIndex: this.isAccessible ? 0 : undefined,
                          id: topic._id,
                          onclick: () => {
                            this.lastFocusedElementSpare = document.activeElement;
                            u.unsetTabIndices();
                            this.openEditTopicDialog(null, topic._id);
                          },
                        }
                      ),
                      [
                        h('span', 'Add a new subtopic'),
                        Icon(icons.plus, style(['x-large', ml('auto'), 'blue', 'large'])),
                      ]
                    )
                  : null,
              ]
            )
          )
    );
  }

  private lastFocusedElement: Element | null = null;
  private lastFocusedElementSpare: Element | null = null;
  private lastFocusedElementSpare2: Element | null = null;

  private async openViewTopicDialog(topic: ITopicChild) {
    this.setState({
      isViewingTopicId: topic.topicId,
      viewTopicBooks: [],
      viewTopicLinks: [],
      editTopicBooksField: [],
      editTopicLinksField: [],
    });
    const bookIds = topic.details.books;
    const linkIds = topic.details.links;
    const getBooksPromise =
      bookIds.length > 0
        ? () => api.fetchBooks(topic.details.books).then((r) => r.data.books)
        : () => Promise.resolve([]);
    const getTopicsPromise =
      linkIds.length > 0
        ? () => api.fetchLinks(topic.details.links).then((r) => r.data.links)
        : () => Promise.resolve([]);
    Promise.all([getBooksPromise(), getTopicsPromise()]).then(([books, links]) =>
      this.setState({
        viewTopicBooks: books,
        viewTopicLinks: links,
      })
    );
    u.unsetTabIndices(document.getElementById('dialog-box'));
  }

  private viewTopicDialog() {
    const { isViewingTopicId } = this.getState();
    const isMobile = getStore().getState().app.isMobile;
    const bookView = (b: ITopicBook) =>
      h(
        'div',
        {
          key: b._id,
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': 'Book :' + b.details.title,
          style: {
            display: 'flex',
            flexDirection: 'row',
            marginLeft: '1em',
            borderBottom: `1px solid ${colors.lighterGrey}`,
            paddinLeft: '0.5em',
            backgroundColor: isMobile ? colors.backgroundColor : colors.white,
          },
        },
        [
          h('div', { style: { display: 'flex', flexDirection: 'column', paddingLeft: '0.5rem' } }, [
            h('div.book-title', style([pr('1rem'), pt('0.5rem')]), `${b.details.title}`),
            h('div.book-author', style([pr('1rem'), 'lightGrey']), `${b.details.author}`),
            h('span', style([pr('1rem'), pb('0.5rem'), 'lightGrey']), `ISBN-- ${b.details.isbn}`),
          ]),
          h('div', style(['flex', 'flexEnd', ml('auto'), mt('auto'), mb('auto'), pr('0.5rem')]), [
            b.recommended
              ? h('div', style(['green']), 'Required')
              : h('div', style(['lightGrey']), 'Suggested'),
          ]),
        ]
      );
    const linkView = (l: ILink) =>
      h(
        'div',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          key: l._id,
          style: {
            display: 'flex',
            flexDirection: 'row',
            marginLeft: '1em',
            borderBottom: `1px solid ${colors.lighterGrey}`,
          },
        },
        [
          h(
            'div',
            {
              style: {
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '0.5rem',
                backgroundColor: colors.white,
                width: '100%',
              },
            },
            [
              h('div.link-title', style([pr('1rem'), pt('0.5rem')]), `${l.details.title}`),
              h(
                'a',
                style(
                  ['mrAuto', 'lightGrey', pr('1rem'), mr('0.5em'), pb('0.5rem')],
                  {},
                  {
                    target: '_blank',
                    href: this.getHref(l.details.url),
                  }
                ),
                l.details.url
              ),
            ]
          ),
        ]
      );
    const body = (topic: ITopicChild) => [
      h(
        'div',
        style(
          ['large', pl('0.5rem'), pt('1rem'), pb('1rem')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': 'Topic Title ' + topic.details.title,
          }
        ),
        `${topic.details.title}`
      ),

      // Book list
      h(
        'div',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          h('div', style(['grey', pl('0.5rem'), mt('0.5em')]), 'Relevant Books'),
          topic.details.books.length === 0
            ? this.isEditEnabled()
              ? h(
                  'div',
                  style([
                    'lightGrey',
                    'textCenter',
                    mr('0.5em'),
                    ml('0.5em'),
                    pt('1rem'),
                    pl('2rem'),
                    pr('2rem'),
                    { fontSize: '1.25em' },
                  ]),
                  'Edit the topic to specify any useful books' +
                    ' that your students could read about this topic'
                )
              : h(
                  'div',
                  style([
                    'lightGrey',
                    'textCenter',
                    pt('1rem'),
                    pl('2rem'),
                    pr('2rem'),
                    { fontSize: '1.25em' },
                  ]),
                  'The course admin has not specified ' + 'any relevant books yet'
                )
            : null,
          topic.details.books.length > this.getState().viewTopicBooks.length
            ? h('div', style([pt('1rem'), pl('1rem')]), 'Loading...')
            : null,
          ...this.getState().viewTopicBooks.map(bookView),
        ]
      ),

      // Links list
      h(
        'div',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          h('div', style(['grey', mt('0.5em'), pl('0.5rem'), pb('0.5rem')]), 'Relevant Links'),
          topic.details.links.length === 0
            ? this.isEditEnabled()
              ? h(
                  'div',
                  style([
                    'lightGrey',
                    'textCenter',
                    pt('1rem'),
                    pl('2rem'),
                    pr('2rem'),
                    { fontSize: '1.25em' },
                  ]),
                  'While editing, you can also provide links' + ' to interesting articles or papers'
                )
              : h(
                  'div',
                  style([
                    'lightGrey',
                    'textCenter',
                    pt('1rem'),
                    pl('2rem'),
                    pr('2rem'),
                    { fontSize: '1.25em' },
                  ]),
                  'The course admin has not specified ' + 'any relevant links yet'
                )
            : null,
          h(
            'div',
            {
              style: {
                backgroundColor: colors.white,
              },
            },
            this.getState().viewTopicLinks.map(linkView)
          ),
        ]
      ),
    ];
    const topic = this.getTopicById(isViewingTopicId);
    return Dialog(
      {
        style: { backgroundColor: isMobile ? colors.backgroundColor : colors.white },
        open: isViewingTopicId !== null,
        title: 'Topic',
        thinHeader: true,
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-arrow-left', []),
          onclick: () => this.closeViewTopicDialog(),
        },
        primaryAction: this.isEditEnabled()
          ? {
              label: 'EDIT',
              mobileLabel: h('i.fa.fa-pencil', []),
              onclick: () => {
                this.lastFocusedElementSpare = document.activeElement;
                u.unsetTabIndices();
                this.openEditTopicDialog(isViewingTopicId);
              },
            }
          : undefined,
      },
      topic ? body(topic) : []
    );
  }

  private async closeViewTopicDialog() {
    return this.setState({
      isViewingTopicId: null,
      viewTopicBooks: [],
      viewTopicLinks: [],
    }).then(() => {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    });
  }

  private getHref(url: string) {
    return utils.convertLinkToURL(url);
  }

  private async openEditTopicDialog(topicId: string | null, parentId?: string) {
    const topic = this.getTopicById(topicId);
    await this.setState({
      isEditTopicDialogOpen: true,
      editTopicTitleError: false,
      editTopicTitleField: topic ? topic.details.title : '',
      editingTopicId: topicId,
      editTopicParentId: parentId,
      ...(!topicId
        ? {
            editTopicBooksField: [],
            editTopicLinksField: [],
          }
        : {
            editTopicBooksField: this.getState().viewTopicBooks,
            editTopicLinksField: this.getState().viewTopicLinks,
          }),
    });
  }

  private getTopicById(id: string | null): ITopicChild | null {
    if (!id) return null;
    return getTopicById(getStore().getState().courses, id);
  }

  private editTopicDialog() {
    const {
      isEditTopicDialogOpen,
      editingTopicId,
      editTopicTitleError,
      editTopicTitleField,
      editTopicBooksField,
      editTopicLinksField,
      editTopicParentId,
      isDeleteBookDialogVisibleFor,
      isEditingBook,
    } = this.getState();
    const isMobile = getStore().getState().app.isMobile;
    const sectionTitleAttrs: any = style(
      ['bold', pad('0.5rem')],
      {},
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      }
    );
    const bookView = (b: ITopicBook) =>
      h(
        'div.book',
        {
          key: b._id,
          tabIndex: this.isAccessible ? 0 : undefined,
          style: {
            display: 'flex',
            flexDirection: 'row',
            margin: '0 1em',
            borderBottom: `1px solid ${colors.lighterGrey}`,
          },
        },
        [
          h('div', style(['flex', 'column']), [
            h(
              'div.book-title',
              style([pl('0.5rem'), pr('1rem'), pt('0.5rem')]),
              `${b.details.title}`
            ),
            h(
              'div.book-author',
              style([pl('0.5rem'), pr('1rem'), 'lightGrey']),
              `${b.details.author}`
            ),
            h(
              'span',
              style([pl('0.5rem'), pr('1rem'), pb('0.5rem'), 'lightGrey']),
              `ISBN-- ${b.details.isbn}`
            ),
          ]),
          h('div', style(['flex', 'flexEnd', ml('auto'), mt('auto'), mb('auto')]), [
            Hover(
              {
                cursor: 'pointer',
                color: colors.blue,
              },
              h('i.fa.fa-pencil', {
                style: {
                  color: colors.lightGrey,
                  fontSize: '1.2em',
                  marginTop: '0.35em',
                  marginLeft: '0.5rem',
                  maginRight: '0.5em',
                },
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () => this.openEditBookDialog(b),
              })
            ),
          ]),
        ]
      );
    const linkView = (l: ILink) =>
      h(
        'div',
        {
          key: l._id,
          tabIndex: this.isAccessible ? 0 : undefined,
          style: {
            display: 'flex',
            marginLeft: '1em',
            borderBottom: `1px solid ${colors.lighterGrey}`,
          },
        },
        [
          h('div', { style: { display: 'flex', flexDirection: 'column' } }, [
            h(
              'div.link-title',
              style([pl('0.5rem'), pr('1rem'), pt('0.5rem')]),
              `${l.details.title}`
            ),
            h(
              'a',
              style(
                ['mrAuto', 'lightGrey', pr('1rem'), mr('0.5em'), pb('0.5rem'), pl('0.5rem')],
                {},
                {
                  target: '_blank',
                  href: this.getHref(l.details.url),
                }
              ),
              l.details.url
            ),
          ]),
          h(
            'div',
            style([
              'flex',
              'flexEnd',
              ml('auto'),
              mt('auto'),
              mb('auto'),
              mr('0.75em'),
              pr('0.25rem'),
            ]),
            [
              h(
                'i.fa.fa-times',
                {
                  style: {
                    cursor: 'pointer',
                    color: colors.red,
                    fontSize: '1.2em',
                    marginLeft: 'auto',
                  },
                  onclick: () => this.showDeleteLinkDialogFor(l),
                },
                []
              ),
            ]
          ),
        ]
      );
    const addButtonStyle = style([
      'whiteBackground',
      'borderBox',
      'fullWidth',
      'spaceBetween',
      pad('0.5rem 1rem'),
      'large',
      'flex',
      'alignCenter',
    ]).style;

    const body = [
      h('div.title-editor-container', style([pad('0.5rem 1rem'), 'whiteBackground', 'borderBox']), [
        TextField({
          value: editTopicTitleField,
          errorText: editTopicTitleError ? 'Please enter a title' : null,
          floatingLabelText: 'Title',
          placeholder: 'Title',
          maxLength: 140,
          showRemainingLength: true,
          oninput: (event) =>
            this.setState({
              editTopicTitleField: event.target.value,
              editTopicTitleError: false,
            }),
          onenter: () => this.handleEditTopicDone(),
        }),
      ]),

      h('div', sectionTitleAttrs, 'Relevant Books'),
      h('div', { style: { backgroundColor: colors.white } }, editTopicBooksField.map(bookView)),
      Alert(
        {
          open: isDeleteBookDialogVisibleFor !== null,
          overlayStyle: {
            backgroundColor: colors.overlayOrange,
          },
          style: {
            width: '25em',
          },
          actions: [
            FlatButton('NO', {
              type: 'secondary',
              onclick: () =>
                this.setState({
                  isDeleteBookDialogVisibleFor: null,
                }),
            }),
            FlatButton('YES', {
              onclick: () => this.handleDeleteBook(),
            }),
          ],
        },
        [
          'Are you sure you want to delete the book ',
          h(
            'span',
            style(['bold']),
            isDeleteBookDialogVisibleFor ? `"${isDeleteBookDialogVisibleFor.details.title}"?` : '?'
          ),
        ]
      ),
      isMobile
        ? h(
            'div.ripple.add-book-button',
            {
              style: addButtonStyle,
              onclick: () => {
                this.setState({
                  isAddBookDialogOpen: true,
                  isEditBookDialogOpen: true,
                });
              },
            },
            [h('span', 'Add a new book'), h('i.fa.fa-plus', style(['blue', ml('auto')]))]
          )
        : Hover(
            {
              cursor: 'pointer',
            },
            Paper('.syllabus__add-button', {}, [
              h(
                'div.ripple.add-book-button',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  style: addButtonStyle,
                  onclick: () => {
                    this.lastFocusedElementSpare2 = document.activeElement;
                    u.unsetTabIndices();
                    this.setState({
                      isAddBookDialogOpen: true,
                      isEditBookDialogOpen: true,
                    });
                  },
                },
                [h('span', 'Add a new book'), h('i.fa.fa-plus', style(['blue', ml('auto')]))]
              ),
            ])
          ),
      EditBook({
        open: this.getState().isEditBookDialogOpen,
        book: isEditingBook
          ? {
              title: isEditingBook.details.title,
              isbn: isEditingBook.details.isbn,
              recommended: isEditingBook.recommended,
              author: isEditingBook.details.author,
            }
          : undefined,
        oncancel: () =>
          this.setState({
            isEditBookDialogOpen: false,
            isEditingBook: null,
          }).then(() => {
            u.resetTabIndices();
            (this.lastFocusedElementSpare2 as HTMLElement).focus();
          }),
        ondelete: () =>
          this.setState({
            isDeleteBookDialogVisibleFor: this.getState().isEditingBook,
          }),
        onsave: (_book) =>
          this.getState().isEditingBook
            ? this.editBookHandler(_book).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElementSpare2 as HTMLElement).focus();
              })
            : this.addBookHandler(_book).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElementSpare2 as HTMLElement).focus();
              }),
      }),

      h('div', sectionTitleAttrs, 'Relevant Links'),
      h('div', { style: { backgroundColor: colors.white } }, editTopicLinksField.map(linkView)),
      this.deleteLinkDialog(),
      isMobile
        ? h(
            'div.add-link-button',
            {
              style: {
                ...addButtonStyle,
                marginBottom: '0.5rem',
              },
              onclick: () => this.openAddLinkDialog(),
            },
            [h('span', 'Add new link'), h('i.fa.fa-plus', style([ml('auto'), 'blue']))]
          )
        : Hover(
            {
              cursor: 'pointer',
            },
            Paper('.syllabus__add-button', {}, [
              h(
                'div.add-link-button',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  style: {
                    ...addButtonStyle,
                    marginBottom: '0.5rem',
                  },
                  onclick: () => this.openAddLinkDialog(),
                },
                [h('span', 'Add new link'), h('i.fa.fa-plus', style([ml('auto'), 'blue']))]
              ),
            ])
          ),
      this.addLinkDialog(),
    ];
    return Dialog(
      isMobile
        ? {
            open: isEditTopicDialogOpen,
            title: editingTopicId
              ? 'Editing topic'
              : editTopicParentId
              ? 'Adding sub topic'
              : 'Adding topic',
            secondaryAction: {
              label: 'BACK',
              mobileLabel: h('i.fa.fa-times', []),
              onclick: () =>
                this.setState({
                  isEditTopicDialogOpen: false,
                  editingTopicId: null,
                }),
            },
            style: {
              width: '40em',
              padding: '0em',
              backgroundColor: colors.backgroundColor,
            },
            bodyStyle: {
              paddingRight: '0em',
              paddingLeft: '0em',
            },
            primaryAction: {
              type: 'view',
              view: h('div', style(['flex', 'row', 'alignCenter', 'spaceBetween'], mr('0.5em')), [
                h(
                  'i.fa.fa-times',
                  {
                    style: {
                      cursor: 'pointer',
                      paddingRight: '1rem',
                    },
                    onclick: () =>
                      this.setState({
                        isDeleteTopicOverlayVisibleForId: editingTopicId,
                      }),
                  },
                  []
                ),
                h(
                  'i.fa.fa-check',
                  {
                    onclick: () => this.handleEditTopicDone(),
                  },
                  []
                ),
              ]),
            },
          }
        : {
            open: isEditTopicDialogOpen,
            title: editingTopicId
              ? 'Editing topic'
              : editTopicParentId
              ? 'Adding sub topic'
              : 'Adding topic',
            style: {
              width: '40em',
              padding: '0em',
              backgroundColor: colors.backgroundColor,
            },
            bodyStyle: {
              paddingRight: '0em',
              paddingLeft: '0em',
            },
            primaryAction: {
              type: 'view',
              view: h(
                'div',
                style(['flex', 'row', 'alignCenter', 'spaceAround', 'fullWidth', mr('0.5em')]),
                [
                  FlatButton('BACK', {
                    tabIndex: this.isAccessible ? 0 : undefined,
                    type: 'secondary',
                    onclick: () =>
                      this.setState({
                        isEditTopicDialogOpen: false,
                        editingTopicId: null,
                      }).then(() => {
                        if (editTopicParentId) {
                          u.resetTabIndices();
                        } else {
                          u.resetTabIndices(document.getElementById('dialog-box'));
                        }
                        (this.lastFocusedElementSpare as HTMLElement).focus();
                      }),
                  }),
                  this.getState().editingTopicId
                    ? FlatButton('DELETE', {
                        tabIndex: this.isAccessible ? 0 : undefined,
                        type: 'secondary',
                        onclick: () => {
                          this.lastFocusedElementSpare2 = document.activeElement;
                          u.unsetTabIndices();
                          this.setState({
                            isDeleteTopicOverlayVisibleForId: editingTopicId,
                          });
                        },
                        style: {
                          color: colors.red,
                        },
                      })
                    : null,
                  FlatButton('SAVE', {
                    tabIndex: this.isAccessible ? 0 : undefined,
                    type: 'primary',
                    onclick: () =>
                      this.handleEditTopicDone().then(() => {
                        if (editTopicParentId) {
                          u.resetTabIndices();
                        } else {
                          u.resetTabIndices(document.getElementById('dialog-box'));
                        }
                        (this.lastFocusedElementSpare as HTMLElement).focus();
                      }),
                  }),
                ]
              ),
            },
          },
      body
    );
  }

  private async showDeleteLinkDialogFor(link: ILink) {
    await this.setState({
      isDeleteLinkDialogVisibleFor: link,
    });
  }

  private deleteLinkDialog() {
    const { isDeleteLinkDialogVisibleFor } = this.getState();
    const link = isDeleteLinkDialogVisibleFor;
    return Alert(
      {
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        style: {
          width: '25em',
        },
        open: !!isDeleteLinkDialogVisibleFor,
        actions: [
          FlatButton('NO', {
            type: 'secondary',
            onclick: () =>
              this.setState({
                isDeleteLinkDialogVisibleFor: null,
              }),
          }),
          FlatButton('YES', {
            onclick: () => this.deleteLinkHandler(link!),
          }),
        ],
      },
      [
        'Are you sure you want to delete the link ',
        link ? h('span', style(['bold']), `"${link.details.title}"`) : null,
        '?',
      ]
    );
  }

  private async deleteLinkHandler(link: ILink) {
    await this.setState({
      editTopicLinksField: this.getState().editTopicLinksField.filter(
        (fieldLink) => fieldLink._id !== link._id
      ),
      isDeleteLinkDialogVisibleFor: null,
    });
  }

  private async addLinkHandler() {
    const { addLinkTitleField, addLinkURLField, editTopicLinksField } = this.getState();
    const newState: Partial<ISyllabusState> = {};
    let hasError = false;
    if (addLinkTitleField.length < 1) {
      hasError = true;
      newState.addLinkTitleError = true;
    }
    if (addLinkURLField.length < 1) {
      hasError = true;
      newState.addLinkURLError = true;
    }
    if (hasError) {
      await this.setState(newState);
      return;
    }
    const teamMember = getTeamMember(getStore().getState());
    if (!teamMember) {
      console.warn('Non course team member tried to add syllabus link');
    } else {
      const response = await api.addLink({
        title: addLinkTitleField,
        url: addLinkURLField,
        parent: 'topic',
      });
      await this.setState({
        editTopicLinksField: [
          ...editTopicLinksField,
          {
            _id: response.data.linkId,
            details: {
              title: addLinkTitleField,
              url: addLinkURLField,
            },
            createdOn: unix(),
            createdBy: {
              name: teamMember.name,
              avatar: teamMember.avatar,
              userId: teamMember.userId,
            },
          },
        ],
      });
    }
    await this.closeAddLinkDialog();
  }

  private addLinkDialog() {
    const textFieldStyle = {
      flex: 3,
    };
    const { addLinkTitleField, addLinkTitleError, addLinkURLField, addLinkURLError } =
      this.getState();

    return Dialog(
      {
        open: this.getState().isAddLinkDialogOpen,
        title: 'Add Link',
        primaryAction: {
          label: 'DONE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.addLinkHandler(),
          disabled: !this.isFormValid(),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => this.closeAddLinkDialog(),
        },
      },
      [
        h('div', style(['flex', pad('0.5rem 1rem')]), [
          TextField({
            placeholder: 'Title',
            floatingLabelText: 'Title',
            maxLength: 140,
            showRemainingLength: true,
            value: addLinkTitleField,
            errorText: addLinkTitleError ? 'Please enter a title' : null,
            style: textFieldStyle,
            oninput: (e) =>
              this.setState({
                addLinkTitleField: e.target.value,
                addLinkTitleError: false,
              }),
            onenter: this.isFormValid() ? () => this.addLinkHandler() : () => {},
          }),
        ]),
        h(
          'div',
          style(['flex', pad('0.5rem 1rem')], {
            marginTop: '0.5em',
          }),
          [
            TextField({
              placeholder: 'URL',
              floatingLabelText: 'URL',
              value: addLinkURLField,
              errorText: addLinkURLError ? 'Please enter a URL' : null,
              style: textFieldStyle,
              oninput: (e) =>
                this.setState({
                  addLinkURLField: e.target.value,
                  addLinkURLError: false,
                }),
              onenter: this.isFormValid() ? () => this.addLinkHandler() : () => {},
            }),
          ]
        ),
        h('div', style(['small', 'lightGrey', pad('0.5rem 1rem')]), [
          'Please provide a URL beginning with https:// or http://',
        ]),
      ]
    );
  }

  private isFormValid() {
    const state = this.getState();
    const urlField = state.addLinkURLField.trim();
    const isURLValid = urlField.startsWith('http://') || urlField.startsWith('https://');

    const urlRegex =
      // eslint-disable-next-line no-useless-escape
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[^/?%.\s]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const isValid = urlRegex.test(urlField);
    const isTitleValid = state.addLinkTitleField.trim().length > 0;

    return isTitleValid && isURLValid && isValid;
  }

  private async closeAddLinkDialog() {
    await this.setState({
      isAddLinkDialogOpen: false,
      addLinkTitleError: false,
      addLinkTitleField: '',
      addLinkURLField: '',
      addLinkURLError: false,
    });
  }

  private async openAddLinkDialog() {
    await this.setState({
      isAddLinkDialogOpen: true,
      addLinkTitleError: false,
      addLinkTitleField: '',
      addLinkURLField: '',
      addLinkURLError: false,
    });
  }

  private fileView() {
    const syllabusFile = getStore().getState().courses.syllabusFile;
    if (!syllabusFile) {
      return null;
    }
    return h(
      'div.syllabus-file',
      style(['fullWidth', pad('0.5rem 1rem'), 'borderBox', 'whiteBackground']),
      [
        AttachmentViewer({
          style: {
            width: '100%',
          },
          attachment: syllabusFile,
          downloadUrl: urls().syllabusFileDownload,
          downloadRequest: {
            fileName: syllabusFile.name,
          },
          actionButton: UploadButton({
            disabled: !this.isEditEnabled(),
            view: Icon(
              icons.upload,
              style(
                ['green', 'large', 'pointer', pad('0.5rem')],
                {},
                {
                  ariaLabel: 'Upload Button',
                  tabIndex: this.isAccessible ? 0 : undefined,
                }
              )
            ),
            upload: (file) => {
              const { promise, progress$ } = api.syllabusFileUpload(file);
              return {
                promise: promise.then((attachment) => {
                  dispatch(Actions.uploadSyllabusFileSuccess(attachment));
                  const btn = document.getElementById('syllabus-details');
                  btn ? btn.focus() : null;
                }),
                progress$,
              };
            },
          }),
        }),
      ]
    );
  }

  private async handleDeleteBook() {
    const book = this.getState().isDeleteBookDialogVisibleFor;
    if (!book) return;
    await this.setState({
      isDeleteBookDialogVisibleFor: null,
      isEditBookDialogOpen: false,
      isEditingBook: null,
      editTopicBooksField: this.getState().editTopicBooksField.filter(
        (fieldBook) => fieldBook._id !== book._id
      ),
    });
  }

  private async openEditBookDialog(book: ITopicBook) {
    await this.setState({
      isEditBookDialogOpen: true,
      isEditingBook: book,
    });
  }

  private async closeEditBookDialog() {
    await this.setState({
      isEditBookDialogOpen: false,
      isEditingBook: null,
    });
  }

  private editableCardHeaderAttrs(): any {
    return style(['flex', 'spaceBetween', 'large']);
  }

  private async addBookHandler(bookRequest: IEditBookFields) {
    const response = await api.addBook({
      ...bookRequest,
      parent: 'topic',
    });
    const creator = getTeamMember(getStore().getState());
    if (!creator) {
      await this.closeEditBookDialog();
      return;
    }
    const book: ITopicBook = {
      _id: response.data.bookId,
      details: {
        title: bookRequest.title,
        author: bookRequest.author,
        isbn: bookRequest.isbn,
      },
      recommended: bookRequest.recommended,
      createdOn: unix(),
      createdBy: {
        name: creator.name,
        avatar: creator.avatar,
        userId: creator.userId,
      },
    };
    await this.setState({
      editTopicBooksField: this.getState().editTopicBooksField.concat([book]),
      isEditBookDialogOpen: false,
    });
  }

  private async editBookHandler(fields: IEditBookFields) {
    const book = this.getState().isEditingBook;
    if (!book) return;
    await api.editBook({
      bookId: book._id,
      title: fields.title,
      author: fields.author,
      recommended: fields.recommended,
      isbn: fields.isbn,
    });
    const updateBook = (old: ITopicBook) => ({
      ...old,
      details: {
        title: fields.title,
        author: fields.author,
        isbn: fields.isbn,
      },
      recommended: fields.recommended,
    });
    const updatedBooks = utils.replaceWhere(
      this.getState().editTopicBooksField,
      updateBook,
      (old) => old._id === book._id
    );
    await this.setState({
      isEditBookDialogOpen: false,
      isEditingBook: null,
      viewTopicBooks: updatedBooks,
      editTopicBooksField: updatedBooks,
    });
  }

  private async handleEditTopicDone() {
    const {
      editTopicTitleField,
      editTopicBooksField,
      editTopicLinksField,
      editTopicParentId,
      editingTopicId,
    } = this.getState();
    const store = getStore().getState();
    if (editTopicTitleField.length === 0) {
      this.setState({
        editTopicTitleError: true,
      });
      return;
    }
    const course = courseService.getCurrentCourse();
    const userId = store.getIn.session!.userId;
    if (!course) return;
    const user = course.team.filter((m) => m.userId === userId)[0];
    const data = {
      title: editTopicTitleField,
      books: editTopicBooksField.map((b) => b._id),
      links: editTopicLinksField.map((l) => l._id),
    };
    if (editingTopicId) {
      await dispatch(
        Actions.editTopic({
          ...data,
          topicId: editingTopicId,
        })
      );
    } else {
      await dispatch(
        Actions.addTopic(user, {
          ...data,
          parentId: editTopicParentId || 'self',
        })
      );
    }
    await this.setState({
      isEditTopicDialogOpen: false,
      editTopicTitleError: false,
      editTopicParentId: null,
      editingTopicId: null,
      editTopicLinksField: [],
      editTopicBooksField: [],
      editTopicTitleField: '',
      ...(editingTopicId
        ? {
            viewTopicBooks: editTopicBooksField,
            viewTopicLinks: editTopicLinksField,
          }
        : {}),
    });
  }

  private isEditEnabled() {
    const course = courseService.getCurrentCourse();
    if (!course) return false;
    return !course.isArchived && courseService.getRole() === 'admin';
  }

  private tabHeight() {
    return tabHeight();
  }
}

export default (props: ISyllabusProps) => h(Syllabus, props);
