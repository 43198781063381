import cn from 'classnames';

import { h } from 'core';

interface CheckboxProps {
  isChecked: boolean;
  onClick?: (isChecked: boolean) => any;
}

const CheckBox = (props: CheckboxProps) => {
  return h('div.join-course-payment__checkbox', {
    tabIndex: 0,
    className: cn({ checked: props.isChecked }),
    onclick: () => props.onClick && props.onClick(!props.isChecked),
  });
};

export default CheckBox;
