import { countries } from 'lib/country-list';

import { h, IComponent } from 'core';

import CtaButton from 'acadly/common/CtaButton';
import { fullScreenLoader } from 'acadly/common/Loader';
import courseService from 'acadly/course/service';
import { logger } from 'acadly/logger';

import { getPurchaseIntent, getUSDToINRConversionRate, updateProPurchaseStatus } from '../api';
import CheckBox from './CheckBox';

const CARD_INPUT_ID = 'join-course-purchase-card-input';

const InputField = (props: {
  label: string;
  value: string;
  placeholder: string;
  isRequired?: boolean;
  onChange: (e: KeyboardEvent) => any;
}) => {
  return h('label', [
    h('span', props.label),
    h('input.no-focus', {
      required: props.isRequired,
      value: props.value,
      onchange: props.onChange,
      placeholder: props.placeholder,
    }),
  ]);
};

interface IPaymentStepProps {
  onCancel: () => any;
  onNext: (error?: string) => any;
}

interface IPaymentStepState {
  isCreatingPurchaseIntent: boolean;
  isMakingPayment: boolean;
  conversionRate: null | number;
  clientKey: string;
  purchaseId: string;
  paymentError: string;
  stripePaymentResponse: any;
  termsOfPurchase: boolean;
  termsOfPurchaseError: boolean;
  customer: {
    name: string;
    address: {
      line1: string;
      city: string;
      state: string;
      zip: string;
      country: string;
    };
  };
}

export default class PaymentStep extends IComponent<IPaymentStepProps, IPaymentStepState> {
  public static getTitle(): string {
    return 'Payment';
  }

  private stripe: any;
  private card: any;

  private initStripe() {
    this.stripe = Stripe(process.env.STRIPE_KEY);
    const elements = this.stripe.elements();

    const style = {
      base: {
        color: '#333333',
        '::placeholder': {
          color: '#333333',
        },
      },
      invalid: {
        color: '#cc0a05',
        iconColor: '#cc0a05',
      },
    };

    this.card = elements.create('card', { style, hidePostalCode: true });
    // Stripe injects an iframe into the DOM
    this.card.mount(`#${CARD_INPUT_ID}`);
  }

  private async init() {
    const initialState: IPaymentStepState = {
      isCreatingPurchaseIntent: false,
      isMakingPayment: false,
      conversionRate: null,
      clientKey: '',
      purchaseId: '',
      paymentError: '',
      stripePaymentResponse: null,
      termsOfPurchase: false,
      termsOfPurchaseError: false,
      customer: {
        name: '',
        address: {
          line1: '',
          city: '',
          state: '',
          zip: '',
          country: 'US',
        },
      },
    };
    this.setState(initialState);
  }

  public componentWillMount() {
    this.init();
  }

  public componentDidMount() {
    this.initStripe();
  }

  private formatCurrency(amount: number, currency = 'usd') {
    switch (currency.toLowerCase()) {
      case 'inr':
        return `₹${(amount / 100).toFixed(2)}`;
      default:
        return `$${(amount / 100).toFixed(2)}`;
    }
  }

  public handleCancel = () => {
    const { onCancel: onBack } = this.getProps();
    onBack();
  };

  private async createPurchaseIntent() {
    const { customer } = this.getState();

    this.setState({ isCreatingPurchaseIntent: true });

    const response = await getPurchaseIntent({
      customer,
      purchaseType: 'course',
    });

    const { clientKey, purchaseId } = response.data;

    this.setState({
      clientKey,
      purchaseId,
      isCreatingPurchaseIntent: false,
    });
  }

  private async makePayment() {
    const { isMakingPayment, clientKey, purchaseId } = this.getState();

    if (isMakingPayment || !this.stripe || !this.card) {
      return;
    }

    this.setState({ isMakingPayment: true });

    const result = await this.stripe.confirmCardPayment(clientKey, {
      payment_method: {
        card: this.card,
      },
    });

    await updateProPurchaseStatus({
      purchaseId,
      status: result.error ? 'failed' : 'success',
      errorMessage: result.error ? result.error.message : undefined,
    });

    this.setState({
      isMakingPayment: false,
      paymentError: result.error ? result.error.message : '',
      stripePaymentResponse: result,
    });
  }

  public handleNext = async (e: Event) => {
    const { onNext } = this.getProps();

    const { termsOfPurchase } = this.getState();

    e.preventDefault();

    if (!termsOfPurchase) {
      this.setState({ termsOfPurchaseError: true });
      return;
    }

    try {
      await this.createPurchaseIntent();
      await this.makePayment();
      onNext();
    } catch (error) {
      const { paymentError } = this.getState();
      logger.error(error);
      onNext(paymentError || 'Something went wrong please try again later.');
    }
  };

  public render() {
    const course = courseService.getCurrentCourse();
    const {
      customer,
      conversionRate,
      isCreatingPurchaseIntent,
      isMakingPayment,
      termsOfPurchase,
      termsOfPurchaseError,
    } = this.getState();

    if (!course) {
      return fullScreenLoader;
    }

    return h('form.join-course-payment__body', { onsubmit: this.handleNext }, [
      h('div.join-course-payment__form-cell', [
        h('span', 'TOTAL AMOUNT TO PAY'),
        h('span', this.formatCurrency(course.cost)),
      ]),
      h('div.join-course-payment__form-cell', [h('span', 'BILLING INFORMATION')]),
      h('fieldset.join-course-payment__payment-fieldset', [
        InputField({
          label: 'Name',
          isRequired: true,
          value: customer.name,
          placeholder: 'Jenny Rosen',
          onChange: (e) => {
            this.setState({
              customer: {
                ...customer,
                name: (e.target as HTMLInputElement).value,
              },
            });
          },
        }),
        h('label', [
          h('span', 'Country'),
          h('div.select-country', [
            h('span.flag', {
              className: `flag-${customer.address.country.toLowerCase()}`,
            }),
            h(
              'select.no-focus',
              {
                tabIndex: 0,
                value: customer.address.country,
                onchange: async (e) => {
                  const country = (e.target as HTMLSelectElement).value;

                  this.setState({
                    customer: {
                      ...customer,
                      address: {
                        ...customer.address,
                        country,
                      },
                    },
                  });

                  if (country === 'IN' && !conversionRate) {
                    const response = await getUSDToINRConversionRate();
                    this.setState({
                      conversionRate: response.data.conversionRate,
                    });
                  }
                },
              },
              countries.map((country) =>
                h(
                  'option',
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    value: country.code,
                  },
                  country.name
                )
              )
            ),
          ]),
        ]),
        InputField({
          label: 'Address',
          isRequired: true,
          value: customer.address.line1,
          placeholder: '185 Berry Street Suite 550',
          onChange: (e) => {
            const line1 = (e.target as HTMLInputElement).value;
            this.setState({
              customer: {
                ...customer,
                address: {
                  ...customer.address,
                  line1,
                },
              },
            });
          },
        }),
        InputField({
          label: 'City',
          value: customer.address.city,
          placeholder: 'San Francisco',
          onChange: (e) => {
            const city = (e.target as HTMLInputElement).value;
            this.setState({
              customer: {
                ...customer,
                address: {
                  ...customer.address,
                  city,
                },
              },
            });
          },
        }),
        InputField({
          label: 'State',
          value: customer.address.state,
          placeholder: 'CA',
          onChange: (e) => {
            const state = (e.target as HTMLInputElement).value;
            this.setState({
              customer: {
                ...customer,
                address: {
                  ...customer.address,
                  state,
                },
              },
            });
          },
        }),
        InputField({
          label: 'ZIP',
          isRequired: true,
          value: customer.address.zip,
          placeholder: 'ZIP or Postal code, example: 94103',
          onChange: (e) => {
            const zip = (e.target as HTMLInputElement).value;
            this.setState({
              customer: {
                ...customer,
                address: {
                  ...customer.address,
                  zip,
                },
              },
            });
          },
        }),
      ]),
      h('div.join-course-payment__form-cell', [h('span', 'PAYMENT INFORMATION')]),
      h('fieldset.join-course-payment__payment-fieldset', [
        h('label', [h('span', 'Card'), h('div.card-input', { id: CARD_INPUT_ID })]),
      ]),
      customer.address.country === 'IN' && conversionRate
        ? h(
            'div.join-course-payment__inr-note',
            `You will be charged ${this.formatCurrency(
              course.cost * conversionRate,
              'inr'
            )}. Please use indian cards only.`
          )
        : null,
      h('div.join-course-payment__terms', [
        CheckBox({
          isChecked: termsOfPurchase,
          onClick: () => {
            this.setState({ termsOfPurchase: !termsOfPurchase });
          },
        }),
        h('div', [
          h(
            'span',
            {
              style: { marginRight: '0.25rem' },
              onclick: () => {
                this.setState({
                  termsOfPurchase: !termsOfPurchase,
                });
              },
            },
            'I have read and agree with the'
          ),
          h(
            'a',
            {
              // tslint:disable-next-line
              href: 'https://s3.amazonaws.com/static.acad.ly/pro/Acadly-Pro-Terms.pdf',
              target: '_blank',
            },
            'Terms of Purchase'
          ),
        ]),
      ]),
      termsOfPurchaseError
        ? h('div.join-course-payment__terms-error', 'Please accept terms of purchase')
        : null,
      CtaButton({
        type: 'submit',
        variant: 'blue',
        label: 'MAKE PAYMENT',
        isLoading: isCreatingPurchaseIntent || isMakingPayment,
      }),
      CtaButton({
        type: 'button',
        variant: 'grey',
        label: 'CANCEL PAYMENT',
        disabled: isCreatingPurchaseIntent || isMakingPayment,
        onClick: this.handleCancel,
      }),
    ]);
  }
}
