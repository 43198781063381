import { omit } from 'lodash';
import { Subscription } from 'rxjs/Subscription';

import { h, IComponent, View } from 'core';
import history, { UnregisterCallback } from 'core/history';

import * as EmbedIcon from 'assets/launch.svg';
import * as PiPIcon from 'assets/picture_in_picture.svg';

import AcadlyEvents, { IAcadlyEvent } from 'acadly/AcadlyEvents';
import { Actions as AppActions } from 'acadly/app/actions';
import { VisibleVCallState } from 'acadly/app/IAppState';
import appService from 'acadly/app/service';
import { Actions as ClassActions, IPusherAttendanceData } from 'acadly/class/actions';
import { vCallPlaceholderSelector } from 'acadly/class/Activities';
import * as classApi from 'acadly/class/api';
import { Class as ClassFunctions } from 'acadly/class/functions';
import classService from 'acadly/class/service';
import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import portal from 'acadly/common/Portal';
import SvgIcon from 'acadly/common/SvgIcon';
import ToastManager from 'acadly/common/Toast';
import User from 'acadly/common/User';
import { Actions as CourseActions } from 'acadly/course/actions';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import icons from 'acadly/icons';
import NotificationWorker from 'acadly/NotificationWorker';
import { PusherEvent, pusherService } from 'acadly/pusher';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import * as utils from 'acadly/utils';
import loggly from 'acadly/utils/loggly';

import { ZoomMeetingEvent } from './MeetingEvents';
import PipContainer, {
  PipContainer as PipContainerClass,
  PipContainerMode,
  PipControl,
} from './PipContainer';

const FRAME_ORIGIN = process.env.VCALL_FRAME_ORIGIN!;
const AVAILABLE_ASPECT_RATIO = [16 / 9, 4 / 3];

interface IVCallHostFrameState {
  mode: PipContainerMode;
  autoEmbedOnClassPage: boolean;
  showInfoBar: boolean;
  showStartClassAlert: boolean;
  isRaisedHandOverlayVisible: boolean;
  showPermissionAlert: boolean;

  showBroadcastingStatus: boolean;

  /**
   * student name, null means no student is being addressed
   */
  studentBeingAddressed: string | null;

  attendanceId: string;
  attendanceResponders: Set<string>; // set of studentIds
  isRecordingAttendance: boolean;

  /**
   * to show attendance alert for non in-charge users
   */
  showRemoteAttendanceInProgressAlert: boolean;
  remoteAttendanceInProgressText: string;
  remoteAttendanceInProgressActionLabel: string;

  showRemoteAttendanceMarkedAlert: boolean;

  showClassroomAttendanceInProgressAlert: boolean;
}

@portal(document.getElementById('vcall-wrapper'))
export class ZoomMeeting extends IComponent<never, IVCallHostFrameState> {
  private iframe?: HTMLIFrameElement;
  private pipContainer?: PipContainerClass;

  private pusherEventSubscription: Subscription;
  private acadlyEventSubscription: Subscription;
  private unregisterRouteChangeListener: UnregisterCallback;

  private isIframeLoaded = false;
  private isInMeeting = false;
  private isMeetingEnded = false;
  private currentParticipant: IZoomParticipant | null = null;

  public currentAspectRatioIndex = 0;
  public aspectRatio = AVAILABLE_ASPECT_RATIO[this.currentAspectRatioIndex];

  public componentWillMount() {
    const initialState: IVCallHostFrameState = {
      mode: 'pip',
      autoEmbedOnClassPage: true,
      showInfoBar: true,
      showStartClassAlert: false,
      isRaisedHandOverlayVisible: false,
      showPermissionAlert: false,
      showBroadcastingStatus: false,
      studentBeingAddressed: null,
      attendanceId: '',
      attendanceResponders: new Set(),
      isRecordingAttendance: false,
      showRemoteAttendanceInProgressAlert: false,
      remoteAttendanceInProgressText:
        `Please click "Yes, I'm Present" ` + 'button to mark yourself present.',
      remoteAttendanceInProgressActionLabel: "Yes, I'm Present",
      showRemoteAttendanceMarkedAlert: false,
      showClassroomAttendanceInProgressAlert: false,
    };

    this.setState(initialState);

    window.addEventListener('message', this.eventReceived, false);
    window.addEventListener('beforeunload', this.beforeClosingWindow);

    this.pusherEventSubscription = pusherService.events.subscribe(this.handlePusherEvents);

    this.acadlyEventSubscription = AcadlyEvents.subscribe(this.handleAcadlyEvents);

    this.unregisterRouteChangeListener = history.listen(() => {
      this.udpateFrameMode();
    });
  }

  public componentWillUnmount() {
    this.iframe = undefined;

    window.removeEventListener('message', this.eventReceived);
    window.removeEventListener('beforeunload', this.beforeClosingWindow);

    this.pusherEventSubscription.unsubscribe();
    this.acadlyEventSubscription.unsubscribe();
    this.unregisterRouteChangeListener();
  }

  private isAccessible() {
    return getStore().getState().app.acc.web.turnOff === 0;
  }

  private get vCallState() {
    return getStore().getState().app.vCallFrame;
  }

  private getContext(): Omit<VisibleVCallState, 'isVisible'> | null {
    const vCallFrame = getStore().getState().app.vCallFrame;
    if (vCallFrame.isVisible) {
      return omit(vCallFrame, 'isVisible');
    }
    return null;
  }

  private onWindowClosed() {
    const context = this.getContext();
    const server = getStore().getState().getIn.server;

    if (
      !navigator ||
      !context ||
      !server ||
      !context.closeUrl ||
      (context.role === 'in-charge' && !context.isBroadcasting) ||
      (context.role !== 'in-charge' && !this.isInMeeting)
    ) {
      return;
    }

    const payload = {
      zoomUserId: this.currentParticipant ? this.currentParticipant.zoomUserId : 0,
    };

    const blob = new Blob([JSON.stringify(payload)], {
      type: 'application/json',
    });

    navigator.sendBeacon(server + context.closeUrl, blob);
  }

  private beforeClosingWindow = () => {
    this.onWindowClosed();
    return;
  };

  private udpateFrameMode() {
    const context = this.getContext();
    const cls = classService.getCurrentClass();
    const { autoEmbedOnClassPage } = this.getState();

    if (!context || !this.pipContainer) return;

    if (cls && cls._id === context.classId) {
      // inside class route
      if (autoEmbedOnClassPage && Routes.classActivities.isActive(true)) {
        this.pipContainer.tryEmbeddedMode(vCallPlaceholderSelector(cls._id));
      } else {
        this.pipContainer.setPipMode();
      }
    } else if (context.role === 'in-charge' || Routes.course.isActive()) {
      this.pipContainer.setPipMode();
    } else {
      this.leaveMeeting();
    }
  }

  private hide() {
    const context = this.getContext();
    if (!context) return;
    dispatch(AppActions.hideVcallFrame(undefined));
    this.setState({ showInfoBar: true });
  }

  private eventReceived = async (e: MessageEvent) => {
    if (e.origin !== FRAME_ORIGIN || !e.data) return;

    const data = e.data as ZoomMeetingEvent;

    const context = this.getContext();
    if (!context) return;

    switch (data.type) {
      case '@vcall/unhandled_error':
        loggly.push(data as unknown as Record<string, unknown>);
        break;
      case '@vcall/ready':
        this.udpateFrameMode();
        this.joinMeeting();
        break;
      case '@vcall/set_aspect_ratio':
        if (this.pipContainer) {
          this.pipContainer.setAspectRatio(data.ratio);
        }
        break;
      case '@vcall/joined_meeting':
        this.currentParticipant = omit(data, 'type');
        if (context.role !== 'in-charge') {
          const response = await classApi.vCallParticipantJoined({
            classId: context.classId,
            joinId: context.joinId,
            zoomUserId: data.zoomUserId,
            meetingId: context.meetingId,
            device: 'web',
          });

          if (response.data.attendanceRecorded) {
            this.setState({
              showRemoteAttendanceMarkedAlert: true,
            });
          } else {
            this.setState({
              attendanceId: response.data.attendanceId,
              showRemoteAttendanceInProgressAlert: !!response.data.attendanceInProgress,
            });
          }
        }
        this.isInMeeting = true;
        NotificationWorker.register(context.courseId, context.classId);
        dispatch(AppActions.showNotificationPermissionAlert());
        break;
      case '@vcall/left_meeting':
        if (!this.currentParticipant) return;

        this.hide();
        if (context.role !== 'in-charge' && !this.isMeetingEnded) {
          await classApi.vCallParticipantLeft(context.courseId, {
            classId: context.classId,
            meetingId: context.meetingId,
            zoomUserId: this.currentParticipant.zoomUserId,
          });
        }

        this.isInMeeting = false;
        this.currentParticipant = null;
        break;
      case '@vcall/notification_click':
        this.pipContainer?.exitFullScreen();
        AcadlyEvents.next({
          type: '@app/vcall_notification_click',
          notificationId: data.notificationId,
        });
        break;
      default:
        console.warn('unhandled vcall event:', data);
        break;
    }
  };

  private async startVCall(courseId: string, classId: string) {
    const context = this.getContext();
    const cls = classService.getCurrentClass();

    if (!cls || context) return;
    if (classId === cls._id) {
      await dispatch(ClassActions.getOnlineDetails(cls._id));

      const courseRole = courseService.getRole(courseId);
      const classRole = classService.getRole(classId);
      const classData = getStore().getState().class.data;

      if (!classData || !classData.onlineDetails || !classData.onlineDetails.meetingId) {
        return;
      }

      if (courseRole !== 'student' || ClassFunctions.isCheckedIn(cls)) {
        const { joinId, meetingId, meetingPassword, meetingInProgress, beingBroadcast } =
          classData.onlineDetails;
        dispatch(
          AppActions.showVCallFrame({
            joinId,
            classId,
            courseId,
            meetingId,
            meetingPassword,
            meetingInProgress,
            role: classRole,
            beingBroadcast,
          })
        );
      }
    }
  }

  private addressingHand() {
    this.sendEvent({ type: '@vcall/enable_camera_mic' });
  }

  private handLowered() {
    this.sendEvent({ type: '@vcall/disable_camera_mic' });
  }

  private addStudentAttendanceResponse(studentId: string) {
    this.setState({
      attendanceResponders: new Set([...this.getState().attendanceResponders, studentId]),
    });
  }

  private handlePusherEvents = async (e: PusherEvent) => {
    const context = this.getContext();
    const user = appService.getCurrentUser();

    switch (e.event) {
      case 'readyToBroadcast':
        this.startVCall(e.payload.courseId, e.payload.classId);
        break;
      case 'broadcasting':
        if (context && !context.isBroadcasting) {
          this.leaveMeeting();
        }
        this.setState({ showBroadcastingStatus: true });
        break;
      case 'broadcastStopped':
      case 'meetingDestroyed':
        if (context) {
          this.leaveMeeting(true);
        }
        this.setState({ showBroadcastingStatus: false });
        break;
      case 'meetingStarted': {
        const role = classService.getRole(e.payload.classId);
        if (role === 'in-charge' || !this.vCallState.canAutoJoin) return;
        this.startVCall(e.payload.courseId, e.payload.classId);
        break;
      }
      case 'meetingEnded': {
        const role = classService.getRole(e.payload.classId);
        if (role === 'in-charge') return;
        if (context) {
          this.isMeetingEnded = true;
          this.leaveMeeting(true);
        }
        break;
      }
      // case "classStarted": {
      //     this.startVCall(e.payload.courseId, e.payload.classId);
      //     break;
      // }
      case 'classEnded':
        if (!context) return;
        if (e.payload.classId === context.classId) {
          this.isMeetingEnded = true;
          this.leaveMeeting();
        }
        break;
      case 'participantJoined': {
        if (!context) return;
        const { isRecordingAttendance } = this.getState();

        if (isRecordingAttendance) {
          // mark student present if joined when attendance is in progress
          this.addStudentAttendanceResponse(e.payload.userId);
        }

        this.sendEvent({
          type: '@vcall/participant_joined',
          mustExpel: e.payload.mustExpel,
          zoomUserId: e.payload.zoomUserId,
          name: e.payload.name,
          joinId: e.payload.joinId,
          toLeave: e.payload.toLeave,
        });
        break;
      }
      case 'participantLeft':
        if (!context) return;
        this.sendEvent({
          type: '@vcall/participant_left',
          zoomUserId: e.payload.zoomUserId,
          name: e.payload.name,
        });
        break;
      case 'addressingHand':
        if (!context || !user || e.payload.userId !== user.userId) {
          return;
        }
        this.addressingHand();
        break;
      case 'handLowered':
      case 'handResolved':
        if (!context || !user) {
          return;
        }

        if (e.payload.userId === user.userId) {
          this.handLowered();
        } else if (e.payload.nextUser && e.payload.nextUser.userId === user.userId) {
          this.addressingHand();
        }
        break;
      case 'web-attendanceWarning': {
        const payload: IPusherAttendanceData = e.payload;

        const { classId, isProxy } = payload;

        if (!context || context.role !== 'none' || context.classId !== classId || isProxy !== 1) {
          return;
        }

        if (payload.action === 'hideWarning') return this.hideClassroomAttendanceInProgressAlert();

        if (payload.action === 'showWarning') return this.showClassroomAttendanceInProgressAlert();
        break;
      }
      case 'remoteAttendanceStarted': {
        if (!context) return;

        const { classId, meetingId, attendanceId, displayMessages } = e.payload;

        if (context.classId !== classId || context.meetingId !== meetingId) {
          return;
        }

        const { subtitle, buttonText } =
          displayMessages[utils.getRandomInt(0, displayMessages.length - 1)];

        const courseRole = courseService.getRole(context.courseId);

        if (courseRole === 'student' && this.isInMeeting) {
          this.setState({
            attendanceId: attendanceId,
            showRemoteAttendanceInProgressAlert: true,
            remoteAttendanceInProgressText: subtitle,
            remoteAttendanceInProgressActionLabel: buttonText,
          });
        }
        break;
      }
      case 'remoteAttendanceStopped': {
        if (!context) return;

        const { classId, meetingId, attendanceId } = e.payload;

        if (
          context.classId !== classId ||
          context.meetingId !== meetingId ||
          this.getState().attendanceId !== attendanceId
        ) {
          return;
        }

        this.resetAttendanceState();
        break;
      }
      case 'remoteAttendanceResponse': {
        if (!context) return;

        const { classId, meetingId, attendanceId, sender } = e.payload;

        if (
          context.classId !== classId ||
          context.meetingId !== meetingId ||
          this.getState().attendanceId !== attendanceId
        ) {
          return;
        }

        if (context.role === 'in-charge') {
          this.addStudentAttendanceResponse(sender.userId);
        }
        break;
      }
      default:
        break;
    }
  };

  private handleAcadlyEvents = (event: IAcadlyEvent) => {
    const context = this.getContext();
    if (!context) return;
    switch (event.type) {
      case '@vcall/update_frame_mode':
        this.udpateFrameMode();
        break;
      case '@app/vcall_show_notification': {
        const { mode } = this.getState();
        if (mode === 'full_screen') {
          this.sendEvent({
            type: '@vcall/show_notification',
            payload: event.payload,
          });
        }
        break;
      }
      case '@app/notification_dismissed':
        this.pipContainer?.resetPipModeDimentions();
        break;
      default:
        break;
    }
  };

  private sendEvent(event: ZoomMeetingEvent) {
    if (!this.iframe || !this.iframe.contentWindow || !this.isIframeLoaded) {
      console.warn('vcall iframe is not loaded!', event);
      return;
    }
    this.iframe.contentWindow.postMessage(event, FRAME_ORIGIN);
  }

  public async joinMeeting() {
    let context = this.getContext();
    if (!context) return;

    const { data } = await classApi.getVCallSignature(context.classId, context.meetingId);

    context = {
      ...context,
      joinId: data.joinId,
      closeUrl: data.closeUrl,
      meetingId: data.meetingId,
      meetingPassword: data.meetingPassword,
    };

    dispatch(AppActions.updateVCallDetails(context));
    this.sendEvent({
      ...context,
      type: '@vcall/join_meeting',
      signature: data.signature,
      disableAudio: false,
    });
  }

  public leaveMeeting(forced = false) {
    if (forced || !this.isInMeeting) {
      this.hide();
      this.isInMeeting = false;
      this.currentParticipant = null;
    } else {
      this.sendEvent({ type: '@vcall/leave_meeting' });
    }
    // reset attendance state when meeting is stopped automatically (like pusher events)
    this.resetAttendanceState();
    NotificationWorker.unregister();
  }

  private popInControl() {
    const context = this.getContext();
    const { mode } = this.getState();
    const cls = classService.getCurrentClass();

    if (!context || mode !== 'pip' || !Routes.classActivities.isActive(true)) {
      return null;
    }

    if (cls && cls._id !== context.classId) {
      return null;
    }

    return SvgIcon({
      icon: EmbedIcon,
      role: 'button',
      className: 'pip-container__button',
      title: 'Pop In',
      onmousedown: (e) => e.stopPropagation(),
      onclick: () => {
        if (this.pipContainer) {
          this.pipContainer.tryEmbeddedMode(vCallPlaceholderSelector(context.classId));
          this.setState({
            autoEmbedOnClassPage: true,
          });
        }
      },
    });
  }

  private popOutControl() {
    const { mode } = this.getState();

    if (mode !== 'embedded') return null;

    return SvgIcon({
      icon: PiPIcon,
      role: 'button',
      className: 'pip-container__button',
      title: 'Pop Out Video',
      onmousedown: (e) => e.stopPropagation(),
      onclick: () => {
        if (this.pipContainer) {
          this.pipContainer.setPipMode();
          this.setState({
            autoEmbedOnClassPage: false,
          });
        }
      },
    });
  }

  public render() {
    const context = this.getContext();

    if (!context) return null;

    return PipContainer({
      containerId: 'zoom-meeting',
      className: context.isBroadcasting ? 'broadcasting zoom-meeting' : 'zoom-meeting',
      onModeChange: (mode) => {
        this.setState({ mode });
        this.sendEvent({ type: '@vcall/mode_change_event', mode });
      },
      leftControls: [
        {
          type: 'preset',
          name: PipControl.MOVE,
        },
        {
          type: 'custom',
          view: this.popInControl(),
        },
        {
          type: 'custom',
          view: this.popOutControl(),
        },
        {
          type: 'preset',
          name: PipControl.MINIMIZE,
        },
        {
          type: 'preset',
          name: PipControl.TOGGLE_FULLSCREEN,
        },
      ],
      rightControls: [],
      body: [
        this.infoBar(),
        h('iframe.zoom-meeting__iframe', {
          src: `${FRAME_ORIGIN}/vcall-app`,
          allow:
            process.env.ENV === 'development'
              ? "screen-wake-lock; camera; microphone; display-capture; fullscreen 'none'"
              : "screen-wake-lock 'self'; camera 'self'; microphone 'self'; display-capture 'self'; fullscreen 'none'",
          ref: (el) => {
            this.iframe = el as HTMLIFrameElement;
          },
          onload: () => {
            this.isIframeLoaded = true;
          },
        }),
        this.permissionAlert(),
        this.startClassAlert(),
        this.queriesOverlay(),
        this.remoteAttendanceInProgressAlert(),
        this.remoteAttendanceMarkedAlert(),
        this.remoteAttendanceInProgressOverlay(),
        this.classroomAttendanceInProgressAlert(),
        this.classroomAttendanceInProgressOverlay(),
      ],
      footer: this.footer(),
      getInstance: (ref) => {
        this.pipContainer = ref;
      },
    });
  }

  private infoBar() {
    const context = this.getContext();
    const { showInfoBar } = this.getState();

    if (!context || context.role !== 'in-charge' || !showInfoBar) {
      return null;
    }

    return h('div.zoom-meeting__info-bar', [
      h('span.zoom-meeting__info-bar-message', [
        `This is a representation of the student view. `,
        `Meeting audio and video is still being routed via the Zoom app. `,
        `To start the meeting on Acadly, click on "Start Broadcast"`,
      ]),
      Icon(icons.cross, {
        className: 'zoom-meeting__info-bar-close',
        onclick: () => this.setState({ showInfoBar: false }),
      }),
    ]);
  }

  private permissionAlert() {
    const context = this.getContext();
    const { mode } = this.getState();
    const { showNotificationPermissionAlert } = getStore().getState().app;

    if (!context || !showNotificationPermissionAlert || mode !== 'full_screen') return null;

    const title = h('div.fc-orange', 'Allow desktop notifications');

    const message = h('div', [
      'Acadly will send desktop notifications only while an online meeting is in progress',
    ]);

    const actions = [
      FlatButton('Cancel', {
        type: 'secondary',
        ariaLabel: 'cancel, button',
        tabIndex: this.isAccessible() ? 0 : undefined,
        onclick: () => {
          dispatch(AppActions.toggleNotificationPermissionAlert(false));
        },
      }),
      FlatButton('Okay', {
        type: 'primary',
        ariaLabel: 'okay, button',
        tabIndex: this.isAccessible() ? 0 : undefined,
        onclick: async () => {
          await Notification.requestPermission();
          dispatch(AppActions.toggleNotificationPermissionAlert(false));
        },
      }),
    ];

    return this.zmAlert(title, message, actions);
  }

  private startClassAlert() {
    return Alert(
      {
        open: this.getState().showStartClassAlert,
        title: h('div.fc-orange', 'Class is not in session'),
        style: { width: '25rem' },
        actions: [
          FlatButton('Okay', {
            type: 'primary',
            ariaLabel: 'okay, button',
            tabIndex: this.isAccessible() ? 0 : undefined,
            onclick: () => {
              this.setState({ showStartClassAlert: false });
            },
          }),
        ],
      },
      [
        h('div', [
          'The broadcast can be started after the class is started on Acadly. The ',
          'option to start the class is available only during the scheduled class hours',
        ]),
      ]
    );
  }

  private remoteAttendanceMarkedAlert() {
    const { showRemoteAttendanceMarkedAlert } = this.getState();

    return Alert(
      {
        open: showRemoteAttendanceMarkedAlert,
        title: h('div.fc-orange', 'Attentiveness check'),
        style: { width: '25rem' },
        actions: [
          FlatButton('Okay', {
            onclick: () => {
              this.setState({
                showRemoteAttendanceMarkedAlert: false,
              });
            },
          }),
        ],
      },
      [
        h('div', [
          'You turned up when the the instructor was checking the ',
          'attentiveness of the class. We have let them know you’re here.',
        ]),
      ]
    );
  }

  private remoteAttendanceInProgressAlert() {
    const context = this.getContext();
    const {
      showRemoteAttendanceInProgressAlert,
      remoteAttendanceInProgressText,
      remoteAttendanceInProgressActionLabel,
    } = this.getState();

    if (!context || context.role === 'in-charge') {
      return null;
    }

    return Alert(
      {
        open: showRemoteAttendanceInProgressAlert,
        title: h('div.fc-orange', 'Attendance is in progress'),
        style: { width: '25rem' },
        actions: [
          FlatButton(remoteAttendanceInProgressActionLabel, {
            type: 'primary',
            ariaLabel: `${remoteAttendanceInProgressActionLabel}, button`,
            tabIndex: this.isAccessible() ? 0 : undefined,
            onclick: async () => {
              await classApi.markRemoteAttendance({
                classId: context.classId,
                meetingId: context.meetingId,
                attendanceId: this.getState().attendanceId,
              });
              this.setState({
                showRemoteAttendanceInProgressAlert: false,
              });
            },
          }),
        ],
      },
      [h('div', remoteAttendanceInProgressText)]
    );
  }

  private zmAlert(title: View, body: View, footer: View[]) {
    return h(
      'div.zm-alert',
      {
        tabIndex: this.isAccessible() ? 0 : undefined,
      },
      [
        h('div.zm-alert__title', title),
        h('div.zm-alert__body', body),
        h('div.zm-alert__footer', footer),
      ]
    );
  }

  private remoteAttendanceInProgressOverlay() {
    const context = this.getContext();
    const {
      mode,
      showRemoteAttendanceInProgressAlert,
      remoteAttendanceInProgressText,
      remoteAttendanceInProgressActionLabel,
    } = this.getState();

    if (
      !context ||
      context.role === 'in-charge' ||
      mode !== 'full_screen' ||
      !showRemoteAttendanceInProgressAlert
    ) {
      return null;
    }

    return this.zmAlert('Attendance is in progress', remoteAttendanceInProgressText, [
      FlatButton(remoteAttendanceInProgressActionLabel, {
        type: 'primary',
        ariaLabel: `${remoteAttendanceInProgressActionLabel}, button`,
        tabIndex: this.isAccessible() ? 0 : undefined,
        onclick: async () => {
          await classApi.markRemoteAttendance({
            classId: context.classId,
            meetingId: context.meetingId,
            attendanceId: this.getState().attendanceId,
          });
          this.setState({
            showRemoteAttendanceInProgressAlert: false,
          });
        },
      }),
    ]);
  }

  private showClassroomAttendanceInProgressAlert = () => {
    this.setState({
      showClassroomAttendanceInProgressAlert: true,
    });
  };

  private hideClassroomAttendanceInProgressAlert = () => {
    this.setState({
      showClassroomAttendanceInProgressAlert: false,
    });
  };

  private handleAttendingRemotelyClick = async () => {
    const context = this.getContext();
    if (!context) return;
    await classApi.attendingRemotely(context.courseId, context.classId);
    this.hideClassroomAttendanceInProgressAlert();
  };

  private handleAttendingInPersonClick = () => {
    this.leaveMeeting();
    this.hideClassroomAttendanceInProgressAlert();
  };

  private classroomAttendanceInProgressTitle = () => {
    return 'Classroom attendance is being recorded';
  };

  private classroomAttendanceInProgressBody = () => {
    const { attendanceData } = getStore().getState().app;

    if (!attendanceData) return null;

    return h('div', [
      `${attendanceData.taker.name} is recording presence of students attending`,
      `the ongoing lecture in-person.`,
      h('br'),
      `Are you attending the lecture in-person or remotely?`,
    ]);
  };

  private classroomAttendanceInProgressActions = () => {
    return [
      FlatButton('ATTENDING REMOTELY', {
        type: 'primary',
        ariaLabel: `attending remotely, button`,
        onclick: this.handleAttendingRemotelyClick,
      }),
      FlatButton('ATTENDING IN-PERSON', {
        type: 'primary',
        ariaLabel: `attending in-person, button`,
        onclick: this.handleAttendingInPersonClick,
      }),
    ];
  };

  private classroomAttendanceInProgressAlert() {
    const context = this.getContext();
    const { attendanceData } = getStore().getState().app;
    const { showClassroomAttendanceInProgressAlert } = this.getState();

    if (!context || !attendanceData || context.role === 'in-charge') {
      return null;
    }

    return Alert(
      {
        open: showClassroomAttendanceInProgressAlert,
        title: this.classroomAttendanceInProgressTitle(),
        style: { width: '25rem' },
        actions: this.classroomAttendanceInProgressActions(),
      },
      [this.classroomAttendanceInProgressBody()]
    );
  }

  private classroomAttendanceInProgressOverlay() {
    const context = this.getContext();
    const { attendanceData } = getStore().getState().app;
    const { mode, showClassroomAttendanceInProgressAlert } = this.getState();

    if (
      !context ||
      !attendanceData ||
      context.role === 'in-charge' ||
      mode !== 'full_screen' ||
      !showClassroomAttendanceInProgressAlert
    ) {
      return null;
    }

    return this.zmAlert(
      this.classroomAttendanceInProgressTitle(),
      this.classroomAttendanceInProgressBody(),
      this.classroomAttendanceInProgressActions()
    );
  }

  private queriesOverlay() {
    const context = this.getContext();
    const { isRaisedHandOverlayVisible, isRecordingAttendance } = this.getState();

    if (!isRaisedHandOverlayVisible || isRecordingAttendance || !context) {
      return null;
    }

    const participants = getStore().getState().app.vCallParticipants;
    const students = Object.keys(participants)
      .map((studentId) => participants[studentId])
      .filter((s) => s.hasRaisedHand && !s.beingAddressed)
      .sort((a, b) => a.handRaisedAt - b.handRaisedAt)
      .map((s) => {
        return User(
          {
            avatar: {
              url: s.avatar,
              creator: s.name,
            },
            title: s.name,
            titleClassNames: ['fc-green'],
            subtitle: dt.fromNow(dt.fromUnix(s.handRaisedAt)),
            subtitleClassNames: ['fc-blue'],
          },
          {
            className: 'student',
            key: s.userId,
            onclick: async () => {
              dispatch(AppActions.addressHand(context.classId, s.userId));
              this.setState({
                isRaisedHandOverlayVisible: false,
                studentBeingAddressed: s.name,
              });
            },
          }
        );
      });

    return h('div.queries-overlay', [
      h('div.queries-overlay__header', [
        h('div', [
          h('div.fc-black', 'Raised hands'),
          h('div.fc-grey', 'Click on a student to allow them to speak'),
        ]),
        Icon(icons.cross, {
          className: 'queries-overlay__close',
          onclick: () => {
            this.setState({
              isRaisedHandOverlayVisible: false,
            });
          },
        }),
      ]),
      h('div.queries-overlay__body', students),
    ]);
  }

  private getAddressingStudentId() {
    const participants = getStore().getState().app.vCallParticipants;
    const addressingStudentId = Object.keys(participants).find((studentId) => {
      return participants[studentId].beingAddressed;
    });
    return addressingStudentId;
  }

  private getQueriesCount() {
    const participants = getStore().getState().app.vCallParticipants;
    const raisedHands = Object.keys(participants).filter((studentId) => {
      return participants[studentId].hasRaisedHand && !participants[studentId].beingAddressed;
    });
    return raisedHands.length;
  }

  private getStatusBarText() {
    const context = this.getContext();

    if (!context) return h('span.zoom-meeting__status', '');

    const isIncharge = context.role === 'in-charge';

    if (!isIncharge) {
      return h(
        'span.zoom-meeting__status.fc-blue',
        this.isBeingAddressed() ? 'Being addressed' : 'In meeting'
      );
    }

    const {
      isRecordingAttendance,
      studentBeingAddressed,
      showBroadcastingStatus,
      attendanceResponders,
    } = this.getState();

    if (isRecordingAttendance) {
      return h(
        'span.zoom-meeting__status.fc-blue',
        `${attendanceResponders.size} ${utils.pluralize(
          attendanceResponders.size,
          'student is',
          'students are'
        )} here`
      );
    }

    if (studentBeingAddressed) {
      return h('span.zoom-meeting__status', [
        h('span.fc-blue', 'Addressing '),
        h('span', studentBeingAddressed),
      ]);
    }

    if (showBroadcastingStatus) {
      return h('span.zoom-meeting__status.fc-blue', 'Broadcasting...');
    }

    return h('span.zoom-meeting__status.fc-red', 'Not broadcasting');
  }

  private resetAttendanceState() {
    this.setState({
      attendanceId: '',
      isRecordingAttendance: false,
      attendanceResponders: new Set(),
      showRemoteAttendanceInProgressAlert: false,
    });
  }

  private footer() {
    const context = this.getContext();
    const cls = classService.getCurrentClass();

    if (!context) return null;

    const Wrapper = (body: View[]) => {
      return h('div.zoom-meeting__footer', [this.getStatusBarText(), ...body]);
    };

    if (!cls || cls._id !== context.classId || !Routes.classActivities.isActive(true)) {
      return Wrapper([
        FlatButton('GO TO CLASS', {
          type: 'primary',
          key: 'go-to-class',
          classNames: ['footer-action', 'blue'],
          ariaLabel: 'go to class, button',
          tabIndex: this.isAccessible() ? 0 : undefined,
          onclick: () => this.goToClass(),
        }),
      ]);
    }

    if (context.role !== 'in-charge') {
      return Wrapper([
        this.raiseOrLowerHandButton(),
        !this.isBeingAddressed()
          ? FlatButton('LEAVE MEETING', {
              type: 'secondary',
              key: 'leave-meeting',
              classNames: ['footer-action', 'red'],
              ariaLabel: 'Leave meeting, button',
              tabIndex: this.isAccessible() ? 0 : undefined,
              onclick: async () => {
                await dispatch(AppActions.toggleAutoJoinVCall(false));
                return this.leaveMeeting();
              },
            })
          : null,
      ]);
    }

    if (!context.isBroadcasting) {
      return Wrapper([
        FlatButton('START BROADCAST', {
          type: 'primary',
          key: 'start-broadcast',
          tabIndex: this.isAccessible() ? 0 : undefined,
          ariaLabel: 'start broadcast, button',
          classNames: ['footer-action', 'green'],
          onclick: () => {
            return dispatch(ClassActions.startVCallBroadcast(context.classId, context.meetingId));
          },
        }),
      ]);
    }

    if (this.getState().isRecordingAttendance) {
      return Wrapper([
        FlatButton('Stop recording attendance', {
          type: 'primary',
          key: 'stop-attendance',
          tabIndex: this.isAccessible() ? 0 : undefined,
          ariaLabel: 'stop recording attendance, button',
          classNames: ['footer-action', 'red'],
          onclick: async () => {
            await classApi.stopRemoteAttendance({
              classId: cls._id,
              meetingId: context.meetingId,
              attendanceId: this.getState().attendanceId,
            });
            this.resetAttendanceState();
          },
        }),
      ]);
    }

    const RecordAttendanceButton = FlatButton('Record attendance', {
      type: 'primary',
      key: 'record-attendance',
      ariaLabel: 'record attendance, button',
      tabIndex: this.isAccessible() ? 0 : undefined,
      classNames: ['footer-action', 'blue'],
      onclick: async () => {
        const response = await classApi.startRemoteAttendance({
          classId: cls._id,
          meetingId: context.meetingId,
        });
        this.setState({
          isRecordingAttendance: true,
          attendanceId: response.data.attendanceId,
        });
        ToastManager.show('Started recording attendance. Process auto stops in 3 minutes');
      },
    });

    const queryCount = this.getQueriesCount();
    const QueriesToggleButton = queryCount
      ? FlatButton(`${queryCount} RAISED ${utils.pluralize(queryCount, 'HAND', 'HANDS')}`, {
          type: 'primary',
          key: 'raised-hands',
          ariaLabel: 'view raised hands, button',
          tabIndex: this.isAccessible() ? 0 : undefined,
          classNames: ['footer-action', 'blue'],
          onclick: () => {
            this.setState({
              isRaisedHandOverlayVisible: !this.getState().isRaisedHandOverlayVisible,
            });
          },
        })
      : null;

    const addressingStudentId = this.getAddressingStudentId();
    if (addressingStudentId) {
      return Wrapper([
        FlatButton('RESOLVE', {
          type: 'primary',
          key: 'resolve-query',
          ariaLabel: 'Resolve query, button',
          tabIndex: this.isAccessible() ? 0 : undefined,
          classNames: ['footer-action', 'blue'],
          onclick: async () => {
            await dispatch(AppActions.resolveHand(context.classId, addressingStudentId, 'resolve'));
            this.setState({ studentBeingAddressed: null });
          },
        }),
        queryCount > 0
          ? FlatButton('NEXT', {
              type: 'primary',
              key: 'resolve-and-next-query',
              ariaLabel: 'Resolve and next query, button',
              tabIndex: this.isAccessible() ? 0 : undefined,
              classNames: ['footer-action', 'blue'],
              onclick: async () => {
                const result = await dispatch(
                  AppActions.resolveHand(context.classId, addressingStudentId, 'next')
                );
                this.setState({
                  studentBeingAddressed: result.nextUser ? result.nextUser.name : null,
                });
              },
            })
          : null,
      ]);
    }

    return Wrapper([
      QueriesToggleButton,
      RecordAttendanceButton,
      FlatButton('END', {
        type: 'secondary',
        key: 'stop-broadcast',
        classNames: ['footer-action', 'red'],
        ariaLabel: 'Stop broadcast, button',
        tabIndex: this.isAccessible() ? 0 : undefined,
        onclick: async () => {
          await dispatch(ClassActions.endVCallBroadcast(context.classId, context.meetingId));
          this.leaveMeeting();
        },
      }),
    ]);
  }

  private async goToClass() {
    const context = this.getContext();
    if (!context) return;

    const currentCourseId = getStore().getState().courses.currentCourseId;

    if (currentCourseId && currentCourseId !== context.courseId) {
      await dispatch(CourseActions.closeCoursePage(undefined));
    }

    Routes.classActivities.navigate({
      classShortId: classService.getShortIdFromClassId(context.classId),
      courseShortId: courseService.getShortIdFromCourseId(context.courseId),
      univSlug: appService.getUniversitySlug(),
    });
  }

  private isBeingAddressed() {
    const user = appService.getCurrentUser();
    const participants = getStore().getState().app.vCallParticipants;

    return !!user && participants[user.userId] && participants[user.userId].beingAddressed;
  }

  private hasRaisedHand() {
    const user = appService.getCurrentUser();
    const participants = getStore().getState().app.vCallParticipants;

    if (!user || !participants[user.userId]) return false;

    return participants[user.userId].hasRaisedHand;
  }

  private raiseOrLowerHandButton() {
    const context = this.getContext();
    const user = appService.getCurrentUser();

    if (!user || !context || context.role !== 'none' || this.isBeingAddressed()) {
      return null;
    }

    const isRaisedHand = this.hasRaisedHand();

    return FlatButton(isRaisedHand ? 'Lower Hand' : 'Raise Hand', {
      key: isRaisedHand ? 'lower-hand' : 'raise-hand',
      classNames: ['footer-action', 'blue', isRaisedHand ? 'raised' : ''],
      tabIndex: this.isAccessible() ? 0 : undefined,
      ariaLabel: isRaisedHand ? 'Raise hand in meeting, button' : 'Lower your hand, button',
      onclick: async () => {
        if (isRaisedHand) {
          await dispatch(AppActions.lowerHand(context.classId, user.userId));
        } else {
          await dispatch(AppActions.raiseHand(context.classId, user.userId));
        }
      },
    });
  }
}

export default () => h(ZoomMeeting);
