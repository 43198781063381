import { IRootState } from 'acadly/IRootState';

import { IDiscussionState } from './IDiscussionState';

export const responseDiscussionToDiscussion = (
  userData: IResponseDiscussionUserData | undefined,
  classId: string,
  courseRole: ICourseRole,
  d: IResponseDiscussion
): IDiscussion => {
  return {
    ...d,
    identifiers: {
      classId,
    },
    details: {
      ...d.details,
      attachments: d.details.attachments || [],
      title: d.details.title || '',
    },
    userData: userData
      ? {
          firstAccessedOn:
            courseRole === 'student' ? userData.firstAccessedOn : d.details.createdOn,
          numCommentsSeen: userData.numCommentsSeen || 0,
          submitted: userData.submitted,
          subscribed: userData.subscribed,
          lastAccessedOn:
            courseRole === 'student' ? userData.lastAccessedOn : userData.firstAccessedOn,
        }
      : courseRole !== 'student'
      ? {
          firstAccessedOn: d.details.createdOn,
          numCommentsSeen: 0,
          submitted: 1,
          subscribed: 0,
        }
      : undefined,
  };
};

export function updateDiscussion(
  state: IDiscussionState,
  _id: string,
  f: (d: IDiscussion) => IDiscussion
): IDiscussionState {
  const discussion = state.byId[_id];
  if (!discussion) return state;
  return {
    ...state,
    byId: {
      ...state.byId,
      [_id]: f(discussion),
    },
  };
}

export function updateDiscussionInRootState(
  state: IRootState,
  _id: string,
  f: (d: IDiscussion) => IDiscussion
): IRootState {
  return {
    ...state,
    discussions: updateDiscussion(state.discussions, _id, f),
  };
}

export function getDiscussion(state: IRootState, _id: string): IDiscussion | null {
  return state.discussions.byId[_id] || null;
}
