import { h, HTMLAttrs } from 'core';

import { getHTMLTagSelector } from 'acadly/utils';

import { getStore } from '../store';

export default (avatar?: string, creator?: string, isSelected?: boolean, attrs: HTMLAttrs = {}) => {
  let avatarUrl = avatar;
  const defaultUrl = 'https://s3.amazonaws.com/acadly-test/i/public/default.png';
  if (!avatar) avatarUrl = defaultUrl;
  if (avatar && !avatar.startsWith('https://')) {
    avatarUrl = getStore().getState().getIn.avatars[avatar] || defaultUrl;
  }
  return h(getHTMLTagSelector('div', ['selectable-avatar', isSelected ? 'selected' : '']), [
    h('img.selectable-avatar__image', {
      ...attrs,
      alt: `${creator}'s avatar`,
      src: avatarUrl,
    }),
    isSelected ? h('i.fa.fa-check.selectable-avatar__check') : null,
  ]);
};
