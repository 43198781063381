import { getStore } from 'acadly/store';

import IService from '../IService';
import { Routes } from '../routes';
import * as assignmentFunctions from './functions';

class AssignmentService extends IService {
  public getCurrentAssignmentId(): string | null {
    const params = Routes.courseAssignment.getMatch() || Routes.filteredAssignment.getMatch();
    if (params) {
      return this.getAssignmentIdFromShortId(params.assignmentShortId);
    } else {
      return null;
    }
  }

  public getAssignmentNumFromId(assignmentId: string) {
    const timeline = getStore().getState().courses.timeline;
    const assignment = timeline
      ? (timeline!.items.filter((item) => item._id === assignmentId)[0] as IAssignment)
      : null;
    if (assignment) {
      return assignment.details.num;
    } else return 0;
  }
  public getAssignmentIdFromNum(num: string) {
    const timeline = getStore().getState().courses.timeline;
    const assignment = timeline
      ? (timeline!.items.filter(
          (item) => item.nodeType === 'assignment' && item.details.num === Number(num)
        )[0] as IAssignment)
      : null;
    if (assignment) {
      return assignment._id;
    } else {
      return null;
    }
  }

  public getAssignmentIdFromShortId(shortId: string) {
    const timeline = getStore().getState().courses.timeline;
    const assignment = timeline
      ? (timeline!.items.filter((item) => item._id.slice(18) === shortId)[0] as IAssignment)
      : null;
    if (assignment) {
      return assignment._id;
    } else {
      return null;
    }
  }
  public getShortIdFromAssignmentId(assignmentId: string) {
    return assignmentId.slice(18);
  }

  public getCurrentAssignment(): IAssignment | undefined {
    const assignmentId = this.getCurrentAssignmentId();
    if (assignmentId) {
      const timeline = this.state.courses.timeline;
      if (!timeline) return undefined;
      return timeline.items.filter((item) => item._id === assignmentId)[0] as IAssignment;
    } else {
      return undefined;
    }
  }

  constructor() {
    super();
  }

  public get contextId(): string {
    return this.getCurrentAssignmentId() || '';
  }

  public get totalComments(): number {
    const assignment = this.getCurrentAssignment();
    if (!assignment) return 0;
    return assignmentFunctions.getTotalComments(assignment);
  }

  public get seenComments(): number {
    const assignment = this.getCurrentAssignment();
    if (!assignment) return 0;
    return this.totalComments - assignmentFunctions.getUnseenComments(assignment);
  }

  public get preSubComments(): number {
    const assignment = this.getCurrentAssignment();
    if (!assignment) return 0;
    return assignment.activities.numCommentsPreSub;
  }

  public get postSubComments(): number {
    const assignment = this.getCurrentAssignment();
    if (!assignment) return 0;
    return assignment.activities.numCommentsPostSub;
  }
}

export const assignmentService = new AssignmentService();
export default assignmentService;
