import { CSS } from 'core';

import { colors } from 'acadly/styles';

export const headerHeight = '60px';
export const contentPadding = '18px';
export const cellPadding = `14px ${contentPadding}`;
export const formControlIconStye: CSS = { fontSize: '20px' };

export const courseDetailStyle: CSS = {
  margin: `12px ${contentPadding}`,
  color: colors.grey,
  fontWeight: 'bold',
  fontSize: '13px',
};

export const row: CSS = {
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: cellPadding,
  color: colors.grey,
  fontSize: '16px',
  marginBottom: '2px',
};

export const formLabelStyle: CSS = {
  padding: `${contentPadding} ${contentPadding} 8px`,
  color: colors.grey,
  fontWeight: 'bold',
  fontSize: '12px',
};

export const scheduleClassStyle: CSS = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
  marginBottom: '2px',
  color: colors.lightGrey,
  padding: cellPadding,
  backgroundColor: 'white',
};

export const formControlNote: CSS = {
  margin: `8px ${contentPadding}`,
  color: colors.lightBlue,
  fontSize: '10px',
};

export const formControlExplanation: CSS = {
  margin: `8px ${contentPadding} 52px`,
  color: colors.lightGrey,
  fontSize: '12px',
};

export const separator: CSS = {
  backgroundColor: colors.lightestGrey,
  padding: '0 0.5em',
  margin: '0 0.5em',
  border: `1px solid ${colors.lighterGrey}`,
  borderRadius: '4px',
};
