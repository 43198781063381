import { h, View } from 'core';

import { getHTMLTagSelector } from 'acadly/utils';

import { BasicButton, IBasicButtonProps, IBasicButtonState } from './BasicButton';

type IRaisedButtonProps = IBasicButtonProps;
export default (label: string | View[], props: IRaisedButtonProps) => {
  return h(RaisedButton, {
    ...props,
    label,
  });
};
export class RaisedButton extends BasicButton<
  IRaisedButtonProps & { label: string | View[] },
  IBasicButtonState
> {
  public render() {
    const props = this.getProps();
    const label = this.getProps().label;

    return h(
      getHTMLTagSelector(
        'div',
        ['raised-button', this.isDisabled() ? 'disabled' : 'ripple', ...(props.classNames || [])],
        props.id
      ),
      {
        style: props.style,
        'aria-label': props.ariaLabel,
        role: 'button',
        tabIndex: props.tabIndex,
        onclick: this.clickHandler(),
      },
      label instanceof Array ? label : [label]
    );
  }
}
