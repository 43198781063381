import 'video.js/dist/video-js.min.css';

import videojs, { VideoJsPlayer } from 'video.js';

import { CSS, h, IComponent } from 'core';

import * as CloseIcon from 'assets/close.svg';

import SvgIcon from 'acadly/common/SvgIcon';
import PipContainer, { PipContainer as PipContainerClass } from 'acadly/pip-container/PipContainer';
import { randomString } from 'acadly/utils';

interface PiPToggleButton {
  enable(): void;
  disable(): void;
}

export interface IVideoJSPlayerSource {
  src: string;
  type?: string;
}

interface IVideoJSPlayerProps {
  id?: string;
  className?: string;
  style?: CSS;
  /** element selector for embedded mode of pip-container */
  embedTargetSelector: string;
  /** video sources */
  sources: IVideoJSPlayerSource[];
  /** if false, pip-container will be unmounted */
  show: boolean;
  onClose?: (e: MouseEvent) => void;
  getPipContainerRef?: (pipContainer: PipContainerClass) => void;
}

interface IVideoJSPlayerState {
  id: string;
}

class VideoJSPlayer extends IComponent<IVideoJSPlayerProps, IVideoJSPlayerState> {
  private video?: VideoJsPlayer;

  private get pipToggleButton(): PiPToggleButton | undefined {
    if (!this.video || !this.video.controlBar) return undefined;
    return this.video.controlBar.getChild('PictureInPictureToggle') as any;
  }

  private handleLoadedMetadata = () => {
    if (!this.video) return;

    const videoWidth = this.video.videoWidth();
    const videoHeight = this.video.videoHeight();

    this.pipContainer.setAspectRatio(videoWidth / videoHeight);

    if (this.pipToggleButton) {
      this.pipToggleButton.disable();
    }
  };

  private init() {
    const { id } = this.getState();

    this.video = videojs(id, {
      fluid: true,
      responsive: true,
      preload: 'metadata',
      playbackRates: [0.5, 1, 1.5, 2],
    });

    if (this.video) {
      this.video.play();
      this.video.on('loadedmetadata', this.handleLoadedMetadata);
      this.video.on('enterpictureinpicture', this.handleEnterPiP);
      this.video.on('leavepictureinpicture', this.handleLeavePiP);
    }
  }

  private cleanup() {
    if (this.video) {
      this.video.off('loadedmetadata', this.handleLoadedMetadata);
      this.video.off('enterpictureinpicture', this.handleEnterPiP);
      this.video.off('leavepictureinpicture', this.handleLeavePiP);
      this.video.dispose();
      this.video = undefined;
    }
  }

  public componentWillMount() {
    const { id } = this.getProps();

    const initialState: IVideoJSPlayerState = {
      id: id || `video-${randomString()}`,
    };

    this.setState(initialState);
  }

  public componentDidMount() {
    const { show } = this.getProps();
    if (show) {
      this.init();
    }
  }

  public componentWillUpdate(nextProps: IVideoJSPlayerProps) {
    const currentProps = this.getProps();

    if (currentProps.show === true && nextProps.show === false) {
      // about to hide pip-container
      this.cleanup();
    }
  }

  public componentDidUpdate(lastProps: IVideoJSPlayerProps) {
    const currentProps = this.getProps();

    if (currentProps.show === true && lastProps.show === false) {
      // pip-container is mounted
      this.init();
    }
  }

  public componentWillUnmount() {
    this.cleanup();
  }

  private pipContainer: PipContainerClass;

  private handleEnterPiP = () => {
    if (!this.pipContainer) return;
    this.pipContainer.hide();
  };

  private handleLeavePiP = () => {
    if (!this.pipContainer) return;
    this.pipContainer.show();
  };

  public render() {
    const { show, style, onClose, sources, className, getPipContainerRef, embedTargetSelector } =
      this.getProps();

    const { id } = this.getState();

    if (!show) return null;

    return PipContainer({
      lockAspectRatio: true,
      initialAspectRatio: 16 / 9,
      containerId: `pip-container-${id}`,
      initialEmbedModeTargetSelector: embedTargetSelector,
      onModeChange: async (mode) => {
        const doc: any = document;

        if (!this.video || !doc.pictureInPictureEnabled || !this.pipToggleButton) {
          // native-pip is not supported or videojs is not initialized
          return;
        }

        if (mode === 'embedded') {
          if (doc.pictureInPictureElement) {
            await doc.exitPictureInPicture();
          }
          this.pipToggleButton.disable();
        } else {
          this.pipToggleButton.enable();
        }
      },
      getInstance: (pipContainer) => {
        this.pipContainer = pipContainer;
        if (getPipContainerRef) {
          getPipContainerRef(pipContainer);
        }
      },
      rightControls: [
        {
          type: 'custom',
          view: SvgIcon({
            role: 'button',
            icon: CloseIcon,
            className: 'pip-container__button',
            title: 'Close video',
            'aria-label': 'Click to close the video player',
            onclick: onClose,
          }),
        },
      ],
      body: [
        h(
          `video.video-js.vjs-default-skin#${id}`,
          {
            controls: true,
            style,
            className,
          },
          sources.map(({ src, type }) => h('source', { src, type }))
        ),
      ],
    });
  }
}

export default (props: IVideoJSPlayerProps) => h(VideoJSPlayer, props);
