import { getStore } from 'acadly/store';

export const defaultAvatarUrl = 'https://s3.amazonaws.com/acadly-test/i/public/default.png';

export default function getAvatarUrl(avatar?: string) {
  if (avatar && !avatar.startsWith('https://')) {
    return getStore().getState().getIn.avatars[avatar] || defaultAvatarUrl;
  }

  return defaultAvatarUrl;
}
