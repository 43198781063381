import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/repeat';
import 'rxjs/add/operator/skipUntil';
import 'rxjs/add/operator/takeUntil';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { h, IComponent } from 'core';

import { colors } from 'acadly/styles';
import { range } from 'acadly/utils';
import loggly from 'acadly/utils/loggly';

import Slider from './Slider';

export default (props: IImageCropperProps) => h(ImageCropper, props);
export interface IImageCropperProps {
  url: string;
  width?: number;
  height?: number;
  onupdate?: (dataUrl: string) => any;
}

export class ImageCropper extends IComponent<IImageCropperProps, never> {
  private canvas: HTMLCanvasElement;
  private ctx?: CanvasRenderingContext2D;
  private scale$: Subject<number> = new Subject();
  // private move$: Subject<number> =  new Subject();
  private xPos = 0;
  private yPos = 0;
  private xOffset = 0;
  private yOffset = 0;
  private scale = 1;
  private image?: HTMLImageElement;
  private minScale = 0.5;

  public componentWillMount() {
    this.initialize();
  }

  public componentWillUnmount() {
    if (this.dragSubscription) {
      this.dragSubscription.unsubscribe();
    }

    if (this.endSubscription) {
      this.endSubscription.unsubscribe();
    }
  }

  private async initialize() {
    this.scale$.startWith(1).subscribe((scale) => {
      this.scale = scale;
      this.redraw(scale);
    });
  }

  private async initCanvas(url: string) {
    this.image = new Image();
    const img = this.image;
    img.onload = () => {
      this.redraw(this.scale);
    };
    img.src = url;
  }

  public componentWillReceiveProps(nextProps: IImageCropperProps) {
    if (nextProps.url !== this.getProps().url) {
      this.initCanvas(nextProps.url);
    }
  }

  public render() {
    const props = this.getProps();
    return h('div.cropper-container', [
      h('canvas.cropper', {
        style: {
          border: `3px solid ${colors.grey}`,
          boxSizing: 'border-box',
          cursor: 'move',
        },
        width: props.height || 200,
        height: props.width || 200,
        ref: (canvas?: HTMLElement) => {
          if (canvas && !this.canvas && canvas instanceof HTMLCanvasElement) {
            this.canvas = canvas;
            this.ctx = canvas.getContext('2d') || undefined;
            this.initCanvas(this.getProps().url);
            this.initDrag$(canvas);
          }
        },
      }),
      Slider({
        style: {
          marginTop: '0.5em',
        },
        onchange: (progress) => {
          const newScale = (progress - 50) / 100 + 1;
          this.scale$.next(newScale);
        },
      }),
    ]);
  }

  private endSubscription?: Subscription;
  private dragSubscription?: Subscription;
  private initDrag$(canvas: HTMLElement) {
    const mouseDown$ = Observable.fromEvent(canvas, 'mousedown').map(getMousePos);
    const touchStart$ = Observable.fromEvent(canvas, 'touchstart').map(getTouchPos);
    const start$ = mouseDown$.merge(touchStart$);
    const mouseUp$ = Observable.fromEvent(document.body, 'mouseup');
    const end$ = mouseUp$
      .map(getMousePos)
      .merge(Observable.fromEvent(document.body, 'touchend').map(getTouchPos));
    const mouseMove$ = Observable.fromEvent(document.body, 'mousemove').map(getMousePos);
    const touchMove$ = Observable.fromEvent(document.body, 'touchmove').map(getTouchPos);
    const move$ = mouseMove$.merge(touchMove$);

    const singleDrag$ = move$ // start with stream of touchmove events
      .skipUntil(start$) // ignore all events before touchstart
      // combine with latest dragStart event
      .combineLatest(start$, (start, move) => {
        return {
          x: move.x - start.x,
          y: move.y - start.y,
          start: move,
        };
      })
      .takeUntil(end$);
    const dragend$ = end$;
    if (this.endSubscription) {
      this.endSubscription.unsubscribe();
      this.endSubscription = undefined;
    }
    this.endSubscription = dragend$.subscribe(this.onDragEnd);
    const drag$ = singleDrag$
      // repeat forever
      .repeat();

    if (this.dragSubscription) {
      this.dragSubscription.unsubscribe();
      this.dragSubscription = undefined;
    }
    this.dragSubscription = drag$.subscribe(this.onDrag);
  }

  private onDrag = ({ x, y }: { x: number; y: number }) => {
    this.xPos = x;
    this.yPos = y;
    this.redraw(this.scale);
  };

  private onDragEnd = () => {
    this.xOffset += this.xPos;
    this.yOffset += this.yPos;
    this.xPos = 0;
    this.yPos = 0;
    this.redraw(this.scale);
  };

  private redraw(scale: number) {
    if (scale <= this.minScale) return;
    const canvas = this.canvas;
    const ctx = this.ctx;
    if (canvas && ctx) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const img = this.image;
        if (!img) return;
        // ctx.scale(scaleBy, scaleBy);
        ctx.beginPath();
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.clip();
        const height = img.height * scale;
        const width = img.width * scale;

        ctx.drawImage(
          img,
          (canvas.width - width) / 2 - (this.xPos + this.xOffset),
          (canvas.height - height) / 2 - (this.yPos + this.yOffset),
          width,
          height
        );
        const onupdate = this.getProps().onupdate;
        if (onupdate) {
          return onupdate(canvas.toDataURL());
        }
      }
    }
  }
}

const getMousePos = (e: MouseEvent) => {
  if (!e) {
    loggly.push({
      type: 'Unhandled error',
      message: 'No event; ImageCropper',
    });
    return { x: 0, y: 0 };
  }
  return {
    x: e.clientX,
    y: e.clientY,
  };
};
const getTouchPos = (e: TouchEvent) => {
  if (!e) {
    loggly.push({
      type: 'Unhandled error',
      message: 'No event; ImageCropper; Touch',
    });
    return { x: 0, y: 0 };
  }
  let touch: any = null;
  for (const i of range(e.touches.length)) {
    if (e.touches[i]) {
      touch = e.touches[i];
      break;
    }
  }
  if (touch) {
    return {
      x: touch.clientX,
      y: touch.clientY,
    };
  } else {
    return { x: 0, y: 0 };
  }
};
