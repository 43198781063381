export default class UserName {
  public name = '';
  public firstName = '';
  public middleName = '';
  public lastName = '';

  public static compare(a: UserName, b: UserName, sortBy: StudentSortBy) {
    const aFirstName = a.firstName.toLowerCase();
    const aLastName = a.lastName.toLowerCase();

    const bFirstName = b.firstName.toLowerCase();
    const bLastName = b.lastName.toLowerCase();

    switch (sortBy) {
      case 'sortAscFirstName':
        if (aFirstName < bFirstName) return -1;
        if (aFirstName > bFirstName) return 1;
        return 0;
      case 'sortDescFirstName':
        if (aFirstName < bFirstName) return 1;
        if (aFirstName > bFirstName) return -1;
        return 0;
      case 'sortAscLastName':
        if (aLastName < bLastName) return -1;
        if (aLastName > bLastName) return 1;
        return 0;
      case 'sortDescLastName':
        if (aLastName < bLastName) return 1;
        if (aLastName > bLastName) return -1;
        return 0;
      default:
        return 0;
    }
  }

  public static trancate(name: string, length = 20) {
    let result = name.slice(0, length);
    if (result.length !== name.length) {
      const lastSpaceIndex = result.lastIndexOf(' ');
      result = result.slice(0, lastSpaceIndex);
    }
    return result;
  }

  private isSalutation(str: string) {
    const salutations = ['Mr.', 'Ms.', 'Mr', 'Miss', 'Dr.', 'Dr', 'Prof.', 'Prof'];
    return salutations.includes(str);
  }

  constructor(name: string) {
    this.name = name.trim();

    if (name.includes(' ')) {
      const tokens = this.name.split(' ').filter(Boolean);
      this.firstName = tokens.shift()!;

      if (this.isSalutation(this.firstName)) {
        this.firstName += ' ' + tokens.shift()!;
      }

      this.lastName = tokens.pop() || '';

      if (tokens.length) {
        this.middleName = tokens.join(' ');
      }
    } else {
      this.firstName = this.name;
    }
  }

  public truncate(length?: number) {
    return UserName.trancate(this.name, length);
  }
}
