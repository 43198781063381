/**
 * router.ts
 * Usage:
 *   Function 'r' creates a route. You can pass a string to it or
 *   a IRouteComponent created with the 'p' function for route params.
 *   // A route declaration
 *   const classActivities = r(
 *      ["/courses/", p("courseId"), "/timeline/classes/", p("classId"), "/timeline"],
 *      {context: "course"}
 *   );
 *   // translates to /courses/:courseId/timeline/classes/:classId/timeline
 *   ...
 *
 *   // check if route is active
 *   if(classActivities.isActive()) {
 *      ...
 *   }
 *   // go to route
 *   navigate(classActivities, {courseId: "...", classId: "..."})
 *
 *   You can optionally pass a parent property to the second argument of 'r'
 *   which is a function that takes the params of the current route and
 *   returns the url of the parent route. goBack function uses this to
 *   navigate to the given route.
 *
 *   const courseTimeline = ...;
 *
 *   const cls = r(
 *      ["/courses/", p("courseId"), "/timeline/classes/", p("classId")],
 *      {
 *          context: "class",
 *          parent: (params) => [courseTimeline, {courseId: params.courseId}]
 *      }
 *   )
 *
 *   If parent is not specified, goBack function will transition using the history
 *   api to go back to the last page.
 */
import history from './history';
import { CSS, h, IComponent, View, VNode } from './index';
import matchPath from './matchPath';

interface ISwitchOptions {
  animate?: string;
  style?: CSS;
  defaultCallback: () => void;
}
export class SwitchC extends IComponent<
  {
    routes: VNode<IRouteProps<any, any>, any>[];
    options?: ISwitchOptions;
  },
  never
> {
  public render() {
    for (const route of this.getProps().routes) {
      const currentUrl = history.location.pathname;
      const match =
        typeof route.props.route === 'string' && route.props.route === '*'
          ? {}
          : matchPath(currentUrl, {
              path:
                typeof route.props.route === 'string' ? route.props.route : route.props.route.path,
              exact: route.props.exact,
            });
      if (match) {
        return route;
      }
    }
    const props = this.getProps();
    if (props.options && props.options.defaultCallback) {
      // console.trace('default route');
      props.options.defaultCallback();
    }
    return null;
  }
}

export const Switch = (routes: VNode<IRouteProps<any, any>, any>[], options?: ISwitchOptions) =>
  h(SwitchC, { routes, options });

interface IRouteMatch<Params> {
  path: string;
  url: string;
  params: Params;
}

export interface BaseMeta<Params> {
  context: IAppContext;
  pageTitle: string | ((params: Params) => string);
}

interface IRouteProps<Params, Meta extends BaseMeta<Params>> {
  route: IRoute<Params, Meta> | string;
  exact?: boolean;
  render: (match: IRouteMatch<Params>) => View;
}
export class RouteC<Params, Meta extends BaseMeta<Params>> extends IComponent<
  IRouteProps<Params, Meta>,
  never
> {
  public render() {
    const currentUrl = history.location.pathname;
    const props = this.getProps();
    if (typeof props.route === 'string') {
      if (props.route === '*') {
        return props.render({
          path: '*',
          url: history.location.pathname,
          params: {} as Params,
        });
      }
      const match = matchPath(currentUrl, {
        path: props.route,
        exact: props.exact,
      });
      if (match) {
        return this.getProps().render(match);
      }
    } else {
      const match = matchPath(currentUrl, {
        path: props.route.path,
        exact: this.getProps().exact,
      });
      if (match) {
        return this.getProps().render(match);
      }
    }
    return null;
  }
}

export const Route = <Params, Meta extends BaseMeta<Params>>(
  route: IRoute<Params, Meta> | string,
  render: (match: IRouteMatch<Params>) => View,
  options?: { exact?: boolean }
): VNode<any, any> =>
  h(RouteC, {
    route,
    render,
    exact: options ? options.exact : false,
  }) as VNode<any, any>;

export type IRoute<Params, Meta extends BaseMeta<Params>> = {
  getParams(route: { match: { params: Params } }): Params;
  goBack(): void;
  navigate(params: Params, queryParams?: string): void;
  path: string;
  url(params: Params): string;
  getMatch(): Params | undefined;
  isActive(exact?: boolean): boolean;
  meta: Meta;
};

/**
 * Type alias for a type which has given keys.
 * For example,
 * an object of type ObjectWithKeys<'courseId' | 'classId'> will have .classId and .courseId
 * properties of string type.
 * ObjectWithKeys<'quizId'> will have a single property (quizId)
 */
type ObjectWithKeys<K extends string> = {
  [P in K]: string;
};

/**
 * A route component is either a string which matches exactly
 * or an object containing single key 'p' with the name of
 * a route parameter.
 * It needs to be generic because the name of the type
 * parameter has to be known at compile time for type
 * checking and inference to work.
 */
type IRouteComponent<A extends string> = string | { p: A };

interface RouteOptions<Params, Meta extends BaseMeta<Params>> {
  parent?: (params: Params) => void;
  meta: Meta;
}

/**
 * This function creates a type safe route from an array of IRouteComponent(s).
 */
export function r<K extends string, Meta extends BaseMeta<ObjectWithKeys<K>>>(
  components: IRouteComponent<K>[],
  options: RouteOptions<ObjectWithKeys<K>, Meta>
): IRoute<ObjectWithKeys<K>, Meta> {
  const path = components.reduce(
    (prev: string, current) =>
      (prev + (typeof current === 'string' ? current : `:${current.p}`)) as string,
    ''
  ) as string;

  const getUrl = (params: ObjectWithKeys<K>): string =>
    components.reduce(
      (prev: string, current) => prev + (typeof current === 'string' ? current : params[current.p]),
      ''
    ) as string;

  const navigate = (params: ObjectWithKeys<K>, queryParams?: string) => {
    history.push({
      pathname: getUrl(params),
      search: queryParams,
    });
  };

  const isActive = (exact?: boolean) =>
    !!matchPath(history.location.pathname, {
      exact: exact === true,
      path,
    });

  const getMatch = () => {
    const match = matchPath(history.location.pathname, path);
    if (match) {
      return match.params;
    } else {
      return undefined;
    }
  };

  const goBack = () => {
    options && options.parent && getMatch() && options.parent(getMatch());
  };

  return {
    getParams: (route) => route.match.params,
    navigate,
    goBack,
    path,
    isActive,
    getMatch,
    url: getUrl,
    meta: options.meta,
  };
}
/**
 * Creates a route component that matches the given argument as a route param.
 *
 * Necessary because function 'r' used for creating a route needs
 * to know the type of object to be returned (depending on the route params).
 * NOTE: This should only be called with a string literal unless
 * you know what you're doing. Basically,
 * const x = "abc";
 * p(x)
 * This will not work and the route created using this method will
 * not be able to check params.
 * However,
 * const x: "abc" = "abc";
 * p(x)
 * This will work just fine.
 * In short, the exact value of the string argument to this function should be known
 * at compile time.
 */
export function p<K extends string>(a: K): { p: K } {
  return { p: a };
}
