import { jsonRequest } from 'core/http';

import { upload } from 'acadly/upload';
import * as u from 'acadly/utils';

import api from '../api';
import * as datetime from '../datetime';

export interface ICreateAssignmentRequest {
  isNewFromScratch: 0 | 1;
}

export type ICreateAssignmentResponse =
  | {
      hasSuggestedAssignments: 0;
      suggestedAssignments: [];
      newAssignment: ICreatedAssignmentData;
    }
  | {
      hasSuggestedAssignments: 1;
      suggestedAssignments: ISuggestedAssignment[];
      newAssignment: ICreatedAssignmentData;
    };

export const createAssignment = (data: ICreateAssignmentRequest) =>
  jsonRequest<ICreateAssignmentResponse>(api().assignmentCreate, {
    method: 'POST',
    data,
  });

export const deleteAssignment = (assignmentId: string) =>
  jsonRequest<void>(api().assignmentRemove, {
    method: 'DELETE',
    data: { assignmentId },
  });

export interface IEditAssignmentRequest {
  assignmentId: string;
  title: string;
  instructions: string;
  attachments: IAttachment[];
}

export const editAssignment = (data: IEditAssignmentRequest) =>
  jsonRequest<void>(api().assignmentEdit, {
    method: 'POST',
    data,
  });

export type IAddAssignmentQuestionRequest = {
  activityType: 'assignments';
  activityId: string;
  description: {
    text: string;
  };
  submission: 'url' | 'file';
  marks: number;
  attachments: IAttachment[];
  submitExt: string[];
};

export const addAssignmentQuestion = (data: IAddAssignmentQuestionRequest) => {
  return jsonRequest<IAssignmentQuestion>(api().assignmentQueAdd, {
    method: 'POST',
    data:
      data.submission === 'url'
        ? {
            ...data,
            submitExt: ['dummy'],
          }
        : data,
  });
};

export interface IUpdateAssignmentRequest {
  assignmentId: string;
  title: string;
  instructions: string;
  attachments: IAttachment[];
  dueDateTime: string;
  questions?:
    | {
        questionId: string;
        description: {
          text: string;
        };
        marks: number;
        attachments: IAttachment[];
        submission: 'url' | 'file';
        submitExt?: string[];
      }[]
    | IAssignmentQuestion[];
  toNotify: 0 | 1;
}

export interface IUpdateAssignmentRequestLocalData {
  assignmentId: string;
  title: string;
  instructions: string;
  attachments: IAttachment[];
  dueDateTime: number;
  questions?: IAssignmentQuestion[];
}

export interface IEditAssignmentQuestionRequest extends IAddAssignmentQuestionRequest {
  questionId: string;
}
export const editAssignmentQuestion = (data: IEditAssignmentQuestionRequest) =>
  jsonRequest<void>(api().assignmentQueEdit, {
    method: 'POST',
    data:
      data.submission === 'url'
        ? {
            ...data,
            submitExt: ['dummy'],
          }
        : data,
  });

export interface IFetchAssignmentDataResponse {
  questions: IAssignmentQuestion[];
  submission?: IAssignmentSubmission;
}
export const fetchAssignmentData = (assignmentId: string, firstAccess: '0' | '1') =>
  jsonRequest<IFetchAssignmentDataResponse>(
    api().assignmentGet + `?assignmentId=${assignmentId}&firstAccess=${firstAccess}`,
    {
      method: 'GET',
    }
  );

export const deleteAssignmentQuestion = (questionId: string, assignmentId: string) =>
  jsonRequest<void>(api().assignmentQueRemove, {
    method: 'DELETE',
    data: {
      questionId,
      activityId: assignmentId,
      activityType: 'assignments',
    },
  });

export interface IPublishRequest {
  assignmentId: string;
  dueDateTime: number;
  allowLate: boolean;
  subscribeToComments: 0 | 1;
}

export const publishAssignment = (
  assignmentId: string,
  dueDateTime: number,
  allowLate: boolean,
  subscribeToComments: 0 | 1
) =>
  jsonRequest<{ num: number; dueDateTime: number }>(api().assignmentPublish, {
    method: 'POST',
    data: {
      assignmentId,
      dueDateTime: datetime.format(datetime.fromUnix(dueDateTime), 'YYYYMMDDTHH:mm'),
      allowLate: allowLate ? 1 : 0,
      subscribeToComments: subscribeToComments,
    },
  });

export const updateAssignment = (data: IUpdateAssignmentRequest) =>
  jsonRequest<{
    message: 'success';
  }>(api().assignmentUpdate, {
    method: 'PUT',
    data,
  });
export interface IUploadQuestionSubmissionRequest {
  originalFileName: string;
  fileType: string;
  questionId: string;
  activityId: string;
  activityType: string;
}
export interface IUploadQuestionSubmissionArg {
  file: File;
  questionId: string;
  assignmentId: string;
}

export interface IConfirmSubmissionResponse {
  uploadedOn: UnixTimestamp;
  prevSubmission: any;
}

export interface IUploadQuestionSubmissionQuestionResponse extends IConfirmSubmissionResponse {
  name: string;
  type: string;
}
export function uploadQuestionSubmission(data: IUploadQuestionSubmissionArg) {
  const { name, extension } = u.splitFileName(data.file.name);
  const { promise, progress$ } = upload(
    api().assignmentSubGetUploadUrl,
    {
      originalFileName: data.file.name,
      fileType: extension,
      questionId: data.questionId,
      activityId: data.assignmentId,
      activityType: 'assignments',
    },
    data.file
  );
  return {
    progress$: progress$,
    promise: promise.then((response) =>
      jsonRequest<IConfirmSubmissionResponse>(api().assignmentSubUploadConfirm, {
        method: 'POST',
        data: {
          originalFileName: name,
          generatedFileName: response.name,
          fileType: response.type,
          questionId: data.questionId,
          activityId: data.assignmentId,
          activityType: 'assignments',
        },
      }).then((data) => ({
        ...response,
        ...data.data,
      }))
    ),
  };
}

export interface ISubmitRequest {
  assignmentId: string;
  localTime: string; // YYYYMMDDTHH:mm format
}

export interface ISubmitResponse {
  status: 'submitted' | 'late';
  submittedOn: UnixTimestamp;
}

export const submit = (data: ISubmitRequest) =>
  jsonRequest<ISubmitResponse>(api().assignmentSubmit, {
    method: 'POST',
    data,
  });

export interface IRetractRequest {
  assignmentId: string;
}

export const retract = (assignmentId: string) =>
  jsonRequest(api().assignmentRetract, {
    method: 'PUT',
    data: { assignmentId },
  });

export const fetchAnalyticsForTeam = (assignmentId: string) =>
  jsonRequest<ITeamAssignmentAnalytics>(api().analyticsAssignmentGetSubData + assignmentId, {
    method: 'GET',
  });

export interface IFetchSubmissionResponse {
  submission: {
    submission: IAssignmentSubmission;
    identifiers: {
      userId: string;
      name: string;
      avatar: string;
      role: ICourseRole;
    };
  };
}
export const fetchSubmission = (assignmentId: string, studentId: string) =>
  jsonRequest<IFetchSubmissionResponse>(api().assignmentFetchSubmission(assignmentId, studentId), {
    method: 'GET',
  });

export interface IGradesSaveRequest {
  assignmentId: string;
  studentId: string;
  grades: {
    questionId: string;
    maxMarks: number;
    marks: number;
    comments: { content: string };
  }[];
}
export const gradesSave = (data: IGradesSaveRequest) =>
  jsonRequest(api().assignmentGradesSave, {
    method: 'POST',
    data,
  });

export const gradesSubmit = (data: IGradesSaveRequest) =>
  jsonRequest(api().assignmentGradesSubmit, {
    method: 'POST',
    data,
  });

export interface ISubmissionURLSaveRequest {
  url: string;
  questionId: string;
  activityId: string; // assignmentId
  activityType: 'assignments';
}

export interface ISubmissionURLSaveResponse {
  uploadedOn: UnixTimestamp;
  previousSubmission: null | {
    url: null | string;
    submittedOn: UnixTimestamp;
  };
}

export const submissionURLSave = (data: ISubmissionURLSaveRequest) =>
  jsonRequest<ISubmissionURLSaveResponse>(api().assignmentSubUrlSave, {
    method: 'POST',
    data,
  });
