/* tslint:disable */
export const faq = {
  sections: [
    {
      title: 'Course Creation',
      questions: [
        {
          question: 'How do I create a course?',
          answer:
            'If you __are__ an Acadly verified instructor, you can create a course by using the <span class="glyph-icon" data-icon="&#xe91a"></span> button at the bottom right of the Home Screen.\n\nOnce you provide a course code (unique in your university) and the title, your course will be created.',
        },
        {
          question: "Why can't I create a course?",
          answer:
            'Even though you may be Course Instructor in a course being run on Acadly, you may not have the privilege to create a course. To maintain the integrity of the system only the users that are bona fide instructors in a university may create a course. In case you want to create a course, please write to us at contact@acadly.com and mention your email address registered with Acadly. After verifying your identity, we will grant you the rights to create a course on Acadly.',
        },
        {
          question: 'How do I add students to a course?',
          answer:
            'Only a Course Admin can add students to a course. After creating the course you need to complete the following steps before you can add students- \n\n* Set Course Timezone\n* Publish Course Info\n* Publish Course Schedule\n* Curate Course Timeline and Go Live\n\nOnce you have accomplished these tasks, you can add students by using the Analytics <span class="glyph-icon" data-icon="&#xe908"></span> icon at the top right of your course page. You can either enter the email addresses of the students manually or you can upload the roster file (.csv or .txt format) containing the email addresses of all the students.',
        },
        {
          question: 'Can I add other instructors and Teaching Assistants to my course on Acadly?',
          answer:
            'Acadly is collaborative in nature, especially for Instructors. A Course Admin can add any number of co-instructors or teaching assistants to the Course Team.\n\nWhile editing the Course Info, the Course Admin has the option to add members to the Course Team using their email addresses.',
        },
        {
          question: 'I have not been able to add an instructor or a TA to a course. Why?',
          answer:
            'Any person can be a Course Team member ONLY if they have an institute email addresses. That is, if the course creator (XYZ) is from ABC Example University (registered with xyz@abcuni.edu), then the prospective course team members MUST have an email address ending in the same domain (abcuni.edu). So, an example user, Ms. PQR, can be a course team member only if she owns an email address pqr@abcuni.edu',
        },
        {
          question: 'How do I delete a course?',
          answer:
            'If you have created a course on Acadly and want to remove it, you can find the Delete Courses option in the Settings <span class="glyph-icon" data-icon="&#xe902"></span> panel. There you will see a list of all the courses that you have created. From that list, you can delete a course.\n\n**Important:** You can only delete the courses in which you\'re the Course Admin. Once deleted, all the data related to that course will be lost. This action cannot be undone, so tread carefully.',
        },
      ],
    },
    {
      title: 'Course Team Roles and Privileges',
      questions: [
        {
          question: 'What are the different roles a course member can have on Acadly?',
          answer:
            'A course member on Acadly can have one the following roles for their course - \n\n* Course Admin (necessary) - Course Team Member, the course creator is by default the Course Admin and a Course Instructor\n* Course Instructors (optional) - Course Team Members, in case a course has more than one instructor\n* Course Teaching Assistants (TAs, optional) - Course Team Members\n* Students - the people who learn the most',
        },
        {
          question: 'Who is the Course Admin?',
          answer:
            'The user who creates a course becomes the Course Admin for that course. As of now, a course can have only one Course Admin.',
        },
        {
          question: 'Can I change the Course Admin?',
          answer: 'As of now, the Course Admin cannot be changed or replaced.',
        },
        {
          question: 'What privileges do the Course Instructors have?',
          answer:
            "A Course Instructor can - \n\n* Be selected as the class in-charge for a lecture (Course Admin selects the class in-charge)\n* Have access to all the unpublished activities and assignments\n* Moderate comments in any discussion\n* Create and grade assignments\n* Create announcements\n* Access students' performance stats\n\nAt the same time, a Course Instructor CANNOT - \n\n* Add extra lectures\n* Add or remove students to the course\n* Add or remove members to the course team\n* Set or unset a class in-charge\n* Delete the course",
        },
        {
          question: 'What privileges do the Course Teaching Assistants have?',
          answer:
            "A Course Teaching Assistant (TA) **can** -\n\n* Have access to all the unpublished activities and assignments\n* Grade assignments\n* Access students' performance stats\n\nA Course TA **cannot** -\n\n* Create activities\n* Add extra lectures\n* Add or remove students to the course\n* Add or remove members to the course team\n* Set or unset a class in-charge\n* Delete the course",
        },
      ],
    },
    {
      title: 'Classes on Acadly',
      questions: [
        {
          question: 'How are classes created on Acadly?',
          answer:
            'While creating a course, the Course Admin has to define a course schedule and the corresponding weekly class schedule. Once the Course Admin publishes the Course Schedule, classes are automatically created and shown on the timeline.\n\nThe Course Admin can then remove unnecessary classes or can add more classes that don\'t fit the weekly schedule before the course can "Go Live".\n\nOnce the course goes live scheduled classes cannot be removed but can be cancelled. Extra classes though can be added at any time during the course duration.',
        },
        {
          question: 'What kinds of classes can a course have on Acadly?',
          answer:
            'A course on Acadly, has the following types of classes - \n\n* Lectures\n* Tutorials\n* Seminars\n* Labs\n* Office Hours\n\nAs of now, only classes of the type "Lecture" can have activities attached to them. All the other types of classes are there to give only a fair idea of the course schedule and timeline.',
        },
        {
          question: 'Who is a Class In-charge?',
          answer:
            'If there is only one instructor in the course (i.e. the Course Admin), all the classes of the type Lecture have the Course Admin as the default Class In-charge.\n\nIf the course has more than one instructor, Course Admin can assign lectures to various instructors, who then become the Class In-charge.\n\nOnly a Class In-charge can add, remove, and publish activities for a lecture.\n\nThe Class In-charge can be changed any time before the lecture begins. The new Class In-charge will then have all the privileges that the previous class in-charge had. If the previous Class In-Charge had added any activities to a lecture, the new Class In-charge can edit, remove or publish any of those activities attached to the class.',
        },
        {
          question: 'How do I change a Class In-charge?',
          answer:
            'Only the Course Admin can assign a new Class In-charge. If you are a Class In-charge but you won\'t be able to host a particular lecture, you can talk to the Course Admin to assign the lecture to another course instructor.\n\nTo change a Class In-charge - \n* On the Course Timeline, navigate to the lecture whose in-charge you need to change\n* On the Class Page, navigate to the Agenda tab\n* Use the "Edit" link adjacent to the Class Team section to select a new Class In-charge\n\nOnly a registered course team member (instructor) can be made a Class In-charge.',
        },
        {
          question: 'How are attendance records maintained on Acadly?',
          answer:
            'Once a Class In-charge starts a lecture using Acadly, every student is notified that the lecture has begun. The students can then "Check In" to the lecture.\n\nOnce a student checks in to a lecture, their name shows up on the Class Analytics page. The Class In-charge can then do a manual role call during the lecture to see which of the checked in students are actually present at the venue. Using the same list, the Class In-charge can mark the students as "Present".\n\nAcadly works on the idea of capturing the intent of a student to attend a lecture, hence allows the students to check-in to a class even if they are not physically present at the venue.',
        },
        {
          question: 'What if a class in-charge forgets to use Acadly during a lecture?',
          answer:
            'For best results, Acadly should be used for during every lecture but in case the Class In-charge forgets to start a lecture using Acadly, it will automatically be marked as a "No Record" once the scheduled class end time passes by.',
        },
      ],
    },
    {
      title: 'Class Scheduling',
      questions: [
        {
          question: 'How can I change a lecture venue?',
          answer:
            'If you are the in-charge of a lecture and want to change its venue, you can do so by navigating to the class page and clicking on the "Venue" bar.\n\nAll the students will be automatically notified of the venue change.',
        },
        {
          question: 'What if I have to cancel a class?',
          answer:
            "If you're the Class In-charge of the class you need to cancel, it can be done any time before the scheduled start time.\n\nIf you want to host another lecture to make up for the cancelled class, you can pick a schedule for the make up lecture while you're cancelling the scheduled lecture. All the activities (published or unpublished) will adjust themselves according to the new schedule so that your flow is not disturbed.\n\nHowever, if you choose to not host a make up lecture, the activities will not be adjusted and the cancelled class will just be marked as cancelled.\n\nGoes without saying, all the students will be automatically notified of the change in the schedule.",
        },
        {
          question: 'What if I need to add an extra class?',
          answer:
            "Only the Course Admin can add an Extra Unscheduled Lecture to the course. Please note that this is different from cancelling a class and hosting a make up lecture.\n\nIf you need an extra lecture and you're not the Course Admin, please get in touch with the Course Admin to create a new class.",
        },
      ],
    },
    {
      title: 'Class Activities',
      questions: [
        {
          question: 'What are Pre-class Activities?',
          answer:
            'To facilitate Flipped Classroom pedagogy, Acadly allows attaching Pre-class activities to a lecture. The students need to complete these activities 15 minutes before the lecture begins.\n\nPre-class Activities can be very useful for students to prepare for the upcoming lecture, and thanks to the Acadly analytics the instructors have a good idea of the preparedness of the lecture attendees. These are ideal pre-requisites for dynamic and differentiated instruction.\n\nA Class In-charge can publish a Pre-class activity till 30 minutes before the concerned lecture is scheduled to begin.',
        },
        {
          question: 'What are In-class Activities?',
          answer:
            "Just like Pre-class Activities, a Class In-charge can attach In-class activities with their lecture. In-class activities can be created by the Class In-charge anytime before or during the lecture but **they can be published only when the lecture has been started** (the lecture is In-session).\n\nThe idea of In-class activities is to increase student activity during a lecture. An in-class poll, for example, can be a good way to measure to students' understanding of a topic being discussed during a lecture.\n\nIn-class activities allow for Pop quizzes, discussions in which each student can participate and essential topic related resources that can be shared during a lecture.",
        },
        {
          question: 'Who can create activities?',
          answer:
            'Activities, Pre-class or In-class, are by default attached with a lecture. Hence a Class In-charge of a lecture can add, remove, edit, or publish activities.',
        },
        {
          question:
            "Why can't I publish a pre-class activity even though the class is yet to start?",
          answer:
            'The Class In-charge for a lecture, can publish a Pre-class activity only till 30 minutes before the scheduled lecture start time. The idea of this limit is to allow students ample time to attempt the Pre-class activity.',
        },
        {
          question: 'How can I add an activity?',
          answer:
            'If you are the Class In-charge of the lecture in which you want to add an activity, you can add an activity by using the <span class="glyph-icon" data-icon="&#xe91a"></span> button on the bottom right of the class page.',
        },
        {
          question: 'How can I set the deadline for a pre-class activity?',
          answer:
            'Deadlines for Pre-class activities **CANNOT** be set manually. When a Pre-class activity is published, the deadline is automatically set as the time 15 minutes before the scheduled start time of the lecture to which it is attached.',
        },
        {
          question: 'What is the deadline for in-class activities?',
          answer:
            'When a Class In-charge publishes an In-class activity, they are asked to specify a deadline for completing the activity. Any time between the time the activity is published and the scheduled end time of the lecture can be picked as the deadline for the activity.',
        },
        {
          question: 'How can I move an activity from one class to another?',
          answer:
            'The Class In-charge can move an unpublished activity from a lecture only if the lecture status is "Closed", "Cancelled", or "No Record".\n\nOnce an activity has been published, it is hard-linked to the lecture and cannot be moved.\n\nUnpublished class activities cannot be moved if the scheduled end time for a lecture is in the future.\n\nIn all the other cases, class activities can be moved to any other future lecture as Pre-class or In-class activities.\n\nOnce the lecture, to which the activity is attached, is in the past, the Class In-charge will see a "Move" option instead of a "Publish" option. The Class In-charge can then select the lecture to which the activity should be moved.\n\n**Important:** A Class In-charge can move activities only to the lectures in which they are the Class In-charge',
        },
        {
          question: 'Quizzes: What kind of quiz questions does Acadly support?',
          answer:
            'As of now, Acadly supports autograded quizzes that can only have questions with multiple options for answers. There can be two kinds of questions - \n* Multiple Choice Questions (MCQs) - 4 options, with more than 1 possible answers\n* True/False Questions - 2 options, True and False. Student has to pick the option which holds true for the question statement.',
        },
        {
          question: 'Quizzes: Why are there three different scoring schemes?',
          answer:
            'This is an experiment to see what sort of scoring scheme encourages the most participation from the students. How likely are students to attempt a question if there is no penalty for picking an incorrect answer? Or how likely are the students to solve a question if not picking incorrect answers is rewarded.\n\nAcadly was made with the aim to understand student behavior and how reward systems work. Scoring schemes is our first attempt at understanding the learners and the teachers.',
        },
        {
          question: 'Resources: How can I share a video that is not hosted on YouTube?',
          answer:
            'Acadly, due to lack of resources currently, does not allow uploading massive videos as resources. Hence we support sharing YouTube links.\n\nIf you have a small video (less than 10MB in size), you can upload it as a Resource File rather than a video.\n\nIf your video is hosted on some other site (and not YouTube), you can share the link to the site as a Link Resource.\n\nGoing ahead, we would support more video players and would allow uploading videos.',
        },
        {
          question: 'Queries: Why are some query icons blue?',
          answer:
            'Query icons are blue if the query is still open. If the asker is not satisfied with the answer, they can choose to keep the query discussion open. Or may be the asker forgot to close the query even though it has been satisfactorily answered. Please encourage the students to close the queries once you think the course team has answered it.',
        },
        {
          question: 'Queries: What if a student posts an inappropriate query?',
          answer:
            "Course Instructors can hide an inappropriate query even if they're not the Class In-charge. Giving all the course instructors the authority to do that minimizes the damage than an appropriate query can cause.",
        },
        {
          question: 'Queries: Who can close a query?',
          answer: 'Only the asker has the privilege to close the query.',
        },
      ],
    },
    {
      title: 'Discussions',
      questions: [
        {
          question: 'Where are the forums?',
          answer:
            'While building Acadly, we wanted to increase student participation in the forums and statistical data from various Learning Management Systems has shown us that the traditional design for forums doesn\'t work because forums and discussions are 3-4 clicks (or steps) away from the context. If a student has to discuss a quiz question, they must first navigate to the forums section, create a new thread and then post the question.\n\nAcadly, on the other hand, has contextual, auto-created discussions that work like an instant messaging service. On Acadly, if a student has to discuss any activity, they can use the persistent <span class="glyph-icon" data-icon="&#xe909"></span> Discussion icon to straigtaway start talking.\n\nAn instant messaging interface is more familiar and user friendly for students than a traditional forum design.',
        },
        {
          question: 'How do course members discuss an activity or a class?',
          answer:
            'To discuss any course node (course, lectures, assignments, quizzes, polls, resources etc), a course member can simply navigate to the contextual chat room by using the <span class="glyph-icon" data-icon="&#xe909"></span> Discussion Icon persistent on every page. The chat room will automatically load all the conversations relevant to that node.',
        },
        {
          question: 'What is the difference between "Liking" and "Thanking" a discussion comment?',
          answer:
            '\'Liking" a comment means the effort is appreciated. "Thanking" means the thanker appreciates the help.\n\nThis little distinction allows us to identify the nature of a student\'s interaction with other members of the course. Whether one is more vibrant or helpful or both.\n\nOnce a Course Instructor likes or gives thanks on a comment, the comment becomes an "Approved" comment.',
        },
        {
          question: 'Who can read the chats?',
          answer:
            'All the members registered on a course on Acadly have access to all the chat areas.',
        },
        {
          question: "Why doesn't Acadly allow one on one messaging?",
          answer:
            'We believe learning happens best when everyone learns from each other. In this context, one on one messaging can be counter productive especially if the subject is relevant to all the students.\n\nHaving said that, we are currently contemplating allowing one on one conversation between a Course Instructor and a Course Student that can only be initiated by the instructor.\n\nPlease do let us know if you have any thoughts on this.',
        },
      ],
    },
    {
      title: 'Assignments',
      questions: [
        {
          question: 'Who can create a course assignment?',
          answer: 'A Course Instructor can create an assignment for the course.',
        },
        {
          question: "I don't want to grade each answer submission separately, what can I do?",
          answer:
            "Acadly by default allows the students to upload a separate submission for each question. We acknowledge that it can be cumbersome in some cases. If you're facing such a problem, please create just one, all-encompassing, question which has subparts. The students can then submit a zip file of all the answers.",
        },
        {
          question: "Where can I set an assignment's deadline?",
          answer:
            'The Course Instructor who created the assignment, gets to decide a submission deadline at the time of pubishing the assignment. When you publish an assignment, you will be presented with an option to select the due date and time.',
        },
        {
          question: 'How do I view submissions and grade assignments?',
          answer:
            'A course team member can access the assignment submissions by accessing the Assignment Analytics panel. On an Assignment Screen, the course team members will see an <span class="glyph-icon" data-icon="&#xe908"></span> Analytics icon.\n\nUsing that they can get a list of all the students who have submitted the assignment. Clicking on a student name will then take the course team member to the student\'s submission page. The submissions can be downloaded and graded right there.',
        },
        {
          question: 'Who can grade assignments?',
          answer:
            'Any Course Team member (Course Admin, Course Instructor or Course TA) can grade assignment submissions. The person who last graded a submission is shown next to the assignment submission.',
        },
      ],
    },
    {
      title: 'General',
      questions: [
        {
          question:
            'What does Acadly use so many different colors on the course/class timeline? What do they mean?',
          answer:
            'Acadly uses colors to point out the activities you should pay attention to. Colors hold different meaning in a course depending on your role.\n\nIn general, the idea behind colored icons is as follows - \n\n* **Red:** Requires immediate attention\n* **Blue:** Requires attention but is not urgent\n* **Pink:** Future activity that require imminent attention\n* **Grey:** Can be ignored\n* **Green:** Happening now\n\nFor Course Team members specifically, the colors can be explained as follows:\n\n* **Red Activity Icon:** Denotes an unpublished activity type\n* **Grey Activity Icon:** All the activities of this kind have been published\n* **Green Activity Icon:** Activities that have been published in a lecture that is in session',
        },
        {
          question: 'Where can I access all my notifications?',
          answer:
            'A notification area, even though familiar, is redundant if the user is bombarded with multiple kinds of notifications. Hence Acadly facilitates contextual notifications.\n\nFor example, if there is a new activity that you have not accessed, it will appear in blue with a "Star Icon" so you immediately know that the lecture has an activity that you have not accessed.\n\nWe believe this visual aid saves a lot of time and allows for better navigation.',
        },
        {
          question: 'How do I change my avatar?',
          answer:
            'You can change your Avatar by accessing the <span class="glyph-icon" data-icon="&#xe902"></span> Settings section.',
        },
        {
          question: 'How do I change my password?',
          answer:
            'You can change your password by accessing the <span class="glyph-icon" data-icon="&#xe902"></span> Settings section.',
        },
        {
          question:
            'I have a few questions/suggestions/thoughts around Acadly, how do I give them to the Acadly team?',
          answer:
            'Acadly welcomes any user feedback. In case you think something can be made better, please reach out to use using the Feedback form that you can access in the <span class="glyph-icon" data-icon="&#xe902"></span> Settings section.\n\nAll feedback is fed directly to the founders of Acadly right now and any inputs stand to make maximum impact on Acadly\'s future.',
        },
      ],
    },
  ],
};
