import { initialState as app } from 'acadly/app/initialState';
import { initialState as assignment } from 'acadly/assignment/initialState';
import { initialState as cls } from 'acadly/class/initialState';
import { initialState as comments } from 'acadly/comments/initialState';
import { initialState as courses } from 'acadly/course/initialState';
import { initialState as discussions } from 'acadly/discussion/initialState';
import { initialState as getIn } from 'acadly/getin/initialState';
import { IRootState } from 'acadly/IRootState';
import { initialState as pipContainers } from 'acadly/pip-container/initialState';
import { initialState as polls } from 'acadly/poll/initialState';
import { initialState as queries } from 'acadly/query/initialState';
import { initialState as quizzes } from 'acadly/quiz/initialState';
import { initialState as resources } from 'acadly/resource/initialState';

export const initialState: IRootState = {
  app,
  getIn,
  courses,
  assignment,
  comments,
  class: cls,
  quizzes,
  polls,
  discussions,
  queries,
  resources,
  pipContainers,
};
