import { CSS, h, View } from 'core';

import { getHTMLTagSelector } from 'acadly/utils';

import { getStore } from '../store';
import { BasicButton, IBasicButtonProps, IBasicButtonState } from './BasicButton';

interface IFloatingButtonProps extends IBasicButtonProps {
  style?: CSS;
  position: 'bottom-left' | 'bottom-right';
  onclick: (event: Event) => any;
  tabIndex?: number;
}

export default (props: IFloatingButtonProps, children: View[]) =>
  h(FloatingActionButton, {
    ...props,
    children,
  });

export class FloatingActionButton extends BasicButton<
  IFloatingButtonProps & { children: View[] },
  IBasicButtonState
> {
  public render() {
    const children = this.getProps().children;
    const props = this.getProps();

    const isMobile = getStore().getState().app.isMobile;
    const narrowScreen = getStore().getState().app.narrowScreen;

    return h(
      getHTMLTagSelector(
        'div',
        [
          'floating-action-button',
          this.isDisabled() ? 'disabled' : 'ripple',
          props.position,
          isMobile ? 'mobile' : '',
          narrowScreen ? 'narrow-screen' : '',
          ...(props.classNames || []),
        ],
        this.getProps().id
      ),
      {
        tabIndex: props.tabIndex !== undefined ? props.tabIndex : undefined,
        onclick: this.clickHandler(),
        'aria-label': props.ariaLabel,
        style: props.style,
      },
      children
    );
  }
}
