import { h, IComponent } from 'core';

import Markdown from 'acadly/common/Markdown';
import { getStore } from 'acadly/store';
import * as u from 'acadly/utils';

import { faq } from './faq';

export class FAQScreen extends IComponent<
  never,
  {
    isDialogOpen: boolean;
    dialogSection: number;
    dialogQuestion: number;
  }
> {
  public componentWillMount() {
    this.setState({
      isDialogOpen: false,
      dialogQuestion: 0,
      dialogSection: 0,
    });
  }
  private lastFocusedQuestion: HTMLElement | undefined = undefined;

  public render() {
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    return h('div.faq-screen', [
      h(
        'div.faq-screen__content',
        {
          tabIndex: isAccessible ? 0 : undefined,
          'aria-label': 'Frequently Asked Questions list',
        },
        faq.sections.map((section, sectionIndex) =>
          h(
            'ul.faq-screen__section',
            {
              key: sectionIndex.toString(),
              tabIndex: isAccessible ? 0 : undefined,
              role: 'group',
              'aria-label': `FAQ group ${section.title}`,
            },
            [
              h('h4.faq-screen__title', section.title),
              ...section.questions.map((q, questionIndex) =>
                h(
                  'li.faq-screen__question',
                  {
                    role: 'listitem',
                    tabIndex: isAccessible ? 0 : undefined,
                    onclick: () =>
                      this.setState({
                        isDialogOpen: true,
                        dialogSection: sectionIndex,
                        dialogQuestion: questionIndex,
                      }).then(() => {
                        this.lastFocusedQuestion = <HTMLElement>document.activeElement || undefined;
                        if (isAccessible) {
                          setTimeout(() => {
                            const dialogbody = document.getElementById('faq-dialog-body');
                            u.unsetTabIndices(document.getElementById('faq-dialog') || undefined);
                            dialogbody &&
                              dialogbody.setAttribute(
                                'tabIndex',
                                dialogbody.getAttribute('data-tabIndex') || '0'
                              );
                            dialogbody && dialogbody.focus();
                          }, 500);
                        }
                      }),
                  },
                  q.question
                )
              ),
            ]
          )
        )
      ),
      this.dialog(),
    ]);
  }

  private questions() {
    return u.flatten(
      faq.sections.map((s, sIndex) =>
        s.questions.map((q, qIndex) => ({
          sectionTitle: s.title,
          sectionIndex: sIndex,
          questionIndex: qIndex,
          ...q,
        }))
      )
    );
  }

  private getQuestionIndex() {
    const { dialogSection, dialogQuestion } = this.getState();
    let index = 0;
    for (let section = 0; section < faq.sections.length; section++) {
      for (let question = 0; question < faq.sections[section].questions.length; question++) {
        if (section === dialogSection && question === dialogQuestion) {
          return index;
        } else {
          index++;
        }
      }
    }
    return index;
  }

  private dialog() {
    const state = this.getState();
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    const questions = this.questions();
    const questionIndex = this.getQuestionIndex();
    const question = questions[questionIndex];
    return h(
      u.getHTMLTagSelector(
        'div',
        ['faq-screen__dialog', state.isDialogOpen ? 'open' : ''],
        'faq-dialog'
      ),
      {
        tabIndex: isAccessible ? 0 : undefined,
        role: 'dialog',
      },
      [
        h('div.faq-screen__dialog__overlay', {
          onclick: () => {
            u.resetTabIndices();
            this.lastFocusedQuestion && this.lastFocusedQuestion.focus();
            this.setState({
              isDialogOpen: false,
            });
          },
        }),
        h(
          'div.faq-screen__dialog__body#faq-dialog-body',
          {
            tabIndex: isAccessible ? 0 : undefined,
          },
          [
            h('div.faq-screen__dialog__content', [
              h('div.faq-screen__dialog__header', [
                h('span', faq.sections[state.dialogSection].title),
                h(
                  'span.faq-screen__dialog__close',
                  {
                    tabIndex: isAccessible ? 0 : undefined,
                    onclick: () =>
                      this.setState({
                        isDialogOpen: false,
                      }).then(() => {
                        u.resetTabIndices();
                        this.lastFocusedQuestion && this.lastFocusedQuestion.focus();
                      }),
                  },
                  'CLOSE'
                ),
              ]),
              h('div.faq-screen__dialog__header-padding', [
                h('span.faq-dialog-title', faq.sections[state.dialogSection].title),
              ]),
              h('div.faq-screen__dialog__scroller', [
                h(
                  'div.faq-screen__dialog__question',
                  {
                    key: `${question.sectionIndex}-${question.questionIndex}`,
                  },
                  [
                    h(
                      'div.faq-screen__dialog__question-title#faq-dialog-question',
                      question.question
                    ),
                    Markdown(question.answer),
                  ]
                ),
              ]),
              questions[questionIndex + 1]
                ? h(
                    'div.faq-screen__dialog__next-question',
                    {
                      tabIndex: isAccessible ? 0 : undefined,
                      onclick: () => {
                        let dialogQuestion = this.getState().dialogQuestion + 1;
                        let dialogSection = this.getState().dialogSection;
                        if (dialogQuestion > faq.sections[dialogSection].questions.length - 1) {
                          dialogSection += 1;
                          dialogQuestion = 0;
                        }
                        this.setState({
                          dialogQuestion,
                          dialogSection,
                        });
                      },
                    },
                    questions[questionIndex + 1].question
                  )
                : null,
            ]),
          ]
        ),
      ]
    );
  }
}

export default () => h(FAQScreen);
