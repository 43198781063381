import * as dt from 'acadly/datetime';
import { IRootAction } from 'acadly/IRootAction';
import { IRootState } from 'acadly/IRootState';
import * as utils from 'acadly/utils';

import * as Actions from './actions';

export const reducer = (state: IRootState, action: IRootAction): IRootState => {
  switch (action.type) {
    case '@app/SHOW_ERROR':
      return setAppKey(state, 'appError', action.payload);
    case '@app/CLEAR_ERROR':
      return setAppKey(state, 'appError', undefined);
    case '@app/SET_NARROW_SCREEN':
      return setAppKey(state, 'narrowScreen', action.payload);
    case '@app/SET_REFERRALS_ALLOWED':
      return setAppKey(state, 'referralsAllowed', action.payload);
    case '@app/SET_WINDOW_WIDTH':
      return setAppKey(state, 'windowWidth', action.payload);
    case '@app/SET_HEADER_HEIGHT':
      return setAppKey(state, 'headerHeight', action.payload);
    case '@app/SET_CONTEXT':
      return setAppKey(state, 'context', action.payload);
    case '@app/SET_IS_MOBILE':
      return setAppKey(state, 'isMobile', action.payload);
    case '@app/SET_SCROLLBAR_WIDTH':
      return setAppKey(state, 'scrollbarWidth', action.payload);
    case '@app/NOTIFICATION_CLEAR':
      return setAppKey(
        state,
        'notifications',
        state.app.notifications.filter((n) => n.msgId !== action.payload)
      );
    case '@app/NOTIFICATION_SHOW':
      return setAppKey(state, 'notifications', [...state.app.notifications, action.payload]);
    case '@app/SHOW_TOAST':
      return setAppKey(state, 'toasts', [action.payload, ...state.app.toasts]);
    case '@app/HIDE_TOAST':
      return setAppKey(
        state,
        'toasts',
        state.app.toasts.filter((toast) => toast.toastId !== action.payload)
      );
    case '@course/FETCH_MY_COURSES_SUCCESS':
      return utils.update<IRootState>(state, {
        app: {
          integratedWith: action.payload.universityData.integratedWith,
          hasEnterpriseAccount: action.payload.universityData.hasEnterpriseAccount,
        },
      });
    case '@app/TOGGLE_ANALYTICS':
      return utils.update(state, {
        app: {
          isAnalyticsOpen: !state.app.isAnalyticsOpen,
          analyticsBackStack: [],
        },
        polls: state.app.isAnalyticsOpen
          ? {
              ...state.polls,
              analytics: null,
            }
          : state.polls,
        quizzes: state.app.isAnalyticsOpen
          ? {
              ...state.quizzes,
              analytics: null,
            }
          : state.quizzes,
      });
    case '@app/DEMO_COURSE_CREATED':
      return {
        ...state,
        app: {
          ...state.app,
          demoCourseCreated: 1,
        },
      };
    case '@app/INITIALIZE_SUCCESS':
      return {
        ...state,
        getIn: {
          ...state.getIn,
          session: {
            ...state.getIn.session!,
            name: action.payload.name,
            canCreateCourses: action.payload.canCreateCourses,
          },
        },
        app: {
          ...state.app,
          demoCourseCreated: action.payload.demoCourseCreated,
          oldTipObject: {
            ...action.payload.tips,
          },
          updatedTipObject: {
            ...action.payload.tips,
          },
          acc: {
            ...action.payload.acc,
          },
          sortStudentBy: action.payload.sortBy,
        },
      };
    case '@app/SET_FEEDBACK_FORM_DATA':
      return setAppKey(state, 'feedbackForm', action.payload);
    case '@app/SET_FEEDBACK_FORM_QUESTIONS':
      return utils.update(state, {
        app: {
          feedbackForm: {
            questions: action.payload,
          },
        },
      });
    case '@app/SET_SORT_STUDENT_BY':
      return setAppKey(state, 'sortStudentBy', action.payload);
    case '@app/START_TIP':
      return setAppKey(state, 'isTipOpen', action.payload);
    case '@app/STOP_TIP':
      return setAppKey(state, 'isTipOpen', action.payload);
    case '@app/ADD_TIP_ITEM':
      return {
        ...state,
        app: {
          ...state.app,
          visibleElementsList: {
            ...state.app.visibleElementsList,
            [action.payload]: true,
          },
        },
      };
    case '@app/REMOVE_TIP_ITEM': {
      const updatedObject = utils.removeKey(state.app.visibleElementsList, action.payload);
      return {
        ...state,
        app: {
          ...state.app,
          visibleElementsList: {
            ...updatedObject,
          },
        },
      };
    }
    case '@app/UPDATE_OLD_TIPS_OBJECT': {
      return {
        ...state,
        app: {
          ...state.app,
          oldTipObject: action.payload,
        },
      };
    }
    case '@app/UPDATE_ACC_SUCCESS': {
      return {
        ...state,
        app: {
          ...state.app,
          acc: {
            ...state.app.acc,
            web: {
              turnOff: action.payload.turnOff,
            },
          },
        },
      };
    }
    case '@app/UPDATE_TIPS_SUCCESS': {
      let newState = state;
      if (action.payload.turnOff === state.app.updatedTipObject.turnOff && action.payload.tipKey) {
        newState = {
          ...state,
          app: {
            ...state.app,
            updatedTipObject: {
              ...state.app.updatedTipObject,
              status: {
                ...state.app.updatedTipObject.status,
                [action.payload.tipKey]: {
                  ...state.app.updatedTipObject.status[action.payload.tipKey],
                  seen: 1,
                },
              },
            },
          },
        };
      } else if (action.payload.tipKey) {
        newState = {
          ...state,
          app: {
            ...state.app,
            updatedTipObject: {
              ...state.app.updatedTipObject,
              turnOff: action.payload.turnOff,
              status: {
                ...state.app.updatedTipObject.status,
                [action.payload.tipKey]: {
                  ...state.app.updatedTipObject.status[action.payload.tipKey],
                  seen: 1,
                },
              },
            },
          },
        };
      } else {
        newState = {
          ...state,
          app: {
            ...state.app,
            updatedTipObject: {
              ...state.app.updatedTipObject,
              turnOff: action.payload.turnOff,
            },
          },
        };
      }
      return newState;
    }
    case '@app/POP_ANALYTICS_BACK_STACK':
      return popAnalyticsBackStack(state);
    case '@app/PUSH_TO_ANALYTICS_BACK_STACK':
      return pushToAnalyticsBackStack(state, action.payload);
    case '@app/TOGGLE_CONTEXT_PANEL_EXPAND':
      return {
        ...state,
        app: {
          ...state.app,
          isContextPanelExpanded: action.payload,
        },
      };
    case '@app/SET_NEW_FEATURE_UPDATES':
      return setNewFeatureUpdates(state, action.payload);
    case '@app/MARK_FEATURE_SEEN':
      return markFeatureSeen(state, action.payload);
    case '@app/SET_NEW_FEATURES_LAST_ACCESED':
      return updateNewFeaturesLastAccesed(state, action.payload);
    case '@app/SHOW_VCALL_FRAME':
      return showVCallFrame(state, action.payload);
    case '@app/HIDE_VCALL_FRAME':
      return hideVCallFrame(state);
    case '@app/TOGGLE_AUTO_JOIN_VCALL':
      return toggleAutoJoinVCall(state, action.payload);
    case '@app/ZOOM_USER_AUTHORIZED':
      return zoomUserAuthorized(state, action.payload);
    case '@app/UPDATE_VCALL_DETAILS':
      return updateVCallDetails(state, action.payload);
    case '@app/ADD_VCALL_PARTICIPANT':
      return addVCallParticipant(state, action.payload);
    case '@app/REMOVE_VCALL_PARTICIPANT':
      return removeVCallParticipant(state, action.payload);
    case '@app/VCALL_HAND_RAISED':
      return handRaised(state, action.payload);
    case '@app/VCALL_HAND_RAISE_SUCCESS':
      return raiseHandSuccess(state, action.payload);
    case '@app/VCALL_HAND_LOWERED':
      return handLowered(state, action.payload);
    case '@app/VCALL_LOWER_HAND_SUCCESS':
      return lowerHandSuccess(state, action.payload);
    case '@app/VCALL_ADDRESSING_HAND':
      return addressingHand(state, action.payload);
    case '@app/VCALL_HAND_RESOLVED':
      return handResolved(state, action.payload);
    case '@app/TOGGLE_NOTIFICATION_PERMISSION_ALERT':
      return setAppKey(state, 'showNotificationPermissionAlert', action.payload);
    case '@app/TOGGLE_ATTENDANCE_SCHEDULED_TO_START_ALERT':
      return setAppKey(
        state,
        'showAttendanceScheduledToStartAlert',
        !action.payload.show
          ? null
          : { classId: action.payload.classId, courseId: action.payload.courseId }
      );
    default:
      return state;
  }
};

function handRaised(state: IRootState, payload: Actions.IPusherHandRaised): IRootState {
  const student = state.app.vCallParticipants[payload.userId];

  if (!student) return state;

  return {
    ...state,
    app: {
      ...state.app,
      vCallParticipants: {
        ...state.app.vCallParticipants,
        [student.userId]: {
          ...student,
          hasRaisedHand: true,
          beingAddressed: false,
          handRaisedAt: payload.raisedAt,
        },
      },
    },
  };
}

function raiseHandSuccess(state: IRootState, payload: Actions.IHandRaised): IRootState {
  const student = state.app.vCallParticipants[payload.userId];

  if (!student) return state;

  return {
    ...state,
    app: {
      ...state.app,
      vCallParticipants: {
        ...state.app.vCallParticipants,
        [student.userId]: {
          ...student,
          hasRaisedHand: true,
          beingAddressed: false,
          handRaisedAt: dt.unix(),
        },
      },
    },
  };
}

function handLowered(state: IRootState, payload: Actions.IPusherHandLowered): IRootState {
  const student = state.app.vCallParticipants[payload.userId];

  if (!student) return state;

  return {
    ...state,
    app: {
      ...state.app,
      vCallParticipants: {
        ...state.app.vCallParticipants,
        [student.userId]: {
          ...student,
          handRaisedAt: -1,
          hasRaisedHand: false,
          beingAddressed: false,
        },
      },
    },
  };
}

function lowerHandSuccess(state: IRootState, payload: Actions.IHandLowered): IRootState {
  const student = state.app.vCallParticipants[payload.userId];

  if (!student) return state;

  return {
    ...state,
    app: {
      ...state.app,
      vCallParticipants: {
        ...state.app.vCallParticipants,
        [student.userId]: {
          ...student,
          handRaisedAt: -1,
          hasRaisedHand: false,
          beingAddressed: false,
        },
      },
    },
  };
}

function addressingHand(state: IRootState, payload: Actions.IAddressingHand): IRootState {
  const student = state.app.vCallParticipants[payload.userId];

  if (!student) return state;

  return {
    ...state,
    app: {
      ...state.app,
      vCallParticipants: {
        ...state.app.vCallParticipants,
        [student.userId]: {
          ...student,
          beingAddressed: true,
        },
      },
    },
  };
}

function handResolved(state: IRootState, payload: Actions.IHandResolved): IRootState {
  const student = state.app.vCallParticipants[payload.userId];
  const nextStudent =
    payload.action === 'next' ? state.app.vCallParticipants[payload.nextUser.userId] : null;

  const vCallParticipants = {
    ...state.app.vCallParticipants,
  };

  if (student) {
    vCallParticipants[student.userId] = {
      ...student,
      handRaisedAt: -1,
      hasRaisedHand: false,
      beingAddressed: false,
    };
  }

  if (nextStudent) {
    vCallParticipants[nextStudent.userId] = {
      ...nextStudent,
      beingAddressed: true,
    };
  }

  return {
    ...state,
    app: {
      ...state.app,
      vCallParticipants,
    },
  };
}

function removeVCallParticipant(
  state: IRootState,
  payload: Actions.IVCallParticipantLeftPayload
): IRootState {
  const vCallFrame = state.app.vCallFrame;
  if (!vCallFrame.isVisible || vCallFrame.classId !== payload.classId) return state;

  const { [payload.userId]: _removedParticipant, ...participants } = state.app.vCallParticipants;
  return {
    ...state,
    app: {
      ...state.app,
      vCallParticipants: participants,
    },
  };
}

function addVCallParticipant(
  state: IRootState,
  payload: Actions.IVCallParticipantJoinedPayload
): IRootState {
  const vCallFrame = state.app.vCallFrame;

  if (payload.mustExpel || !vCallFrame.isVisible || vCallFrame.classId !== payload.classId)
    return state;

  const { userId, joinId, zoomUserId, role, name, avatar } = payload;

  return {
    ...state,
    app: {
      ...state.app,
      vCallParticipants: {
        ...state.app.vCallParticipants,
        [payload.userId]: {
          userId,
          joinId,
          zoomUserId,
          role,
          name,
          avatar,
          hasRaisedHand: false,
          handRaisedAt: -1,
          beingAddressed: false,
        },
      },
    },
  };
}

function updateVCallDetails(
  state: IRootState,
  payload: Partial<Actions.IVCallFrameContext>
): IRootState {
  if (!state.app.vCallFrame.isVisible) return state;
  return {
    ...state,
    app: {
      ...state.app,
      vCallFrame: {
        ...state.app.vCallFrame,
        ...payload,
      },
    },
  };
}

function showVCallFrame(state: IRootState, payload: Actions.IVCallFrameContext): IRootState {
  return {
    ...state,
    app: {
      ...state.app,
      vCallFrame: {
        ...state.app.vCallFrame,
        ...payload,
        isVisible: true,
        isBroadcasting: false,
      },
    },
  };
}

function hideVCallFrame(state: IRootState): IRootState {
  return {
    ...state,
    app: {
      ...state.app,
      vCallFrame: {
        isVisible: false,
        canAutoJoin: state.app.vCallFrame.canAutoJoin,
      },
      vCallParticipants: {},
    },
  };
}

function toggleAutoJoinVCall(state: IRootState, payload: boolean): IRootState {
  return utils.update(state, {
    app: {
      vCallFrame: {
        canAutoJoin: payload,
      },
    },
  });
}

function zoomUserAuthorized(
  state: IRootState,
  payload: Actions.IPusherZoomUserAuthorizedPayload
): IRootState {
  if (!state.getIn.session || state.getIn.session.userId !== payload.userId) return state;

  const clsData = state.class.data;
  if (!clsData || !clsData.onlineDetails) return state;

  return {
    ...state,
    class: {
      ...state.class,
      data: {
        ...clsData,
        onlineDetails: {
          ...clsData.onlineDetails,
          isUserAuthenticated: 1,
        },
        autoRecordDefaults: payload.autoRecordDefaults,
      },
    },
  };
}

function pushToAnalyticsBackStack(state: IRootState, payload: () => void): IRootState {
  return {
    ...state,
    app: {
      ...state.app,
      analyticsBackStack: [...state.app.analyticsBackStack, payload],
    },
  };
}

function popAnalyticsBackStack(state: IRootState): IRootState {
  return {
    ...state,
    app: {
      ...state.app,
      analyticsBackStack: state.app.analyticsBackStack.slice(
        0,
        state.app.analyticsBackStack.length - 1
      ),
    },
  };
}

function setAppKey<K extends keyof IRootState['app']>(
  state: IRootState,
  key: K,
  value: IRootState['app'][K]
): IRootState {
  return utils.update(state, {
    app: {
      [key as any]: value,
    },
  });
}

function setNewFeatureUpdates(state: IRootState, payload: Actions.INewFeatureUpdates): IRootState {
  return {
    ...state,
    app: {
      ...state.app,
      newFeaturesSeenAt: payload.newFeaturesSeenAt,
      newFeaturesLastUpdatedAt: payload.newFeaturesLastUpdatedAt,
      newFeaturesAvailable: payload.newFeaturesAvailable,
    },
  };
}

function markFeatureSeen(state: IRootState, featureKey: string): IRootState {
  return {
    ...state,
    app: {
      ...state.app,
      newFeaturesAvailable: {
        ...state.app.newFeaturesAvailable,
        [featureKey]: {
          ...state.app.newFeaturesAvailable[featureKey],
          seen: 1,
        },
      },
    },
  };
}

function updateNewFeaturesLastAccesed(state: IRootState, timestamp: UnixTimestamp): IRootState {
  return {
    ...state,
    app: {
      ...state.app,
      newFeaturesSeenAt: timestamp,
    },
  };
}
