import { h, IComponent } from 'core';

import * as BulbIcon from 'assets/bulb.svg';

import QuickStartTips from 'acadly/common/QuickStartTips';
import SvgIcon from 'acadly/common/SvgIcon';

class ClassAttendanceStartTips extends IComponent<never, never> {
  public render() {
    return QuickStartTips({
      qstKey: 'qst-class-attendance',
      children: ({ wrapper }) => wrapper(this.body()),
    });
  }

  private body = () => {
    return h('div.quick-start-tips__body', [
      h('div.quick-start-tips__section-heading', 'attendance'),
      h('p.quick-start-tips__section-content', [
        'This is the Attendance section of a Class - if you choose to record ',
        'attendance for a class, this section will keep a record of how many ',
        'students attended that class.',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'automatic attendance',
      ]),
      h('p.quick-start-tips__section-content', [
        'Using Acadly’s Android and iOS apps, attendance for in-person classrooms ',
        'can be recorded automatically in just 2 minutes during the lecture hours. ',
        'For online lectures, attendance can be recorded from Acadly itself. No more ',
        'paper management or roll calls',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'manual edits',
      ]),
      h('p.quick-start-tips__section-content', [
        'If Acadly is used during a class, the Class In-charge or the Assistant can ',
        'also manually edit the attendance records to mark the student as Present, ',
        'Absent, Late, or Excused any time',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'checked-in vs. present',
      ]),
      h('p.quick-start-tips__section-content', [
        'Students have to “check in” to attempt the activities published during a ',
        'lecture. However this is only to indicate which of the students are ',
        'attempting the activities. A student is actually counted as Present or ',
        'Absent only after attendance has been recorded automatically or manually',
      ]),
    ]);
  };
}

export default () => h(ClassAttendanceStartTips);
