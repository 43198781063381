import { CSS, h } from 'core';

import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Viewer from 'acadly/rich-text/Viewer';
import * as utils from 'acadly/utils';

interface IQuestionProps {
  isAccessible?: boolean;
  poll: ISuggestedPoll;
  question: ISuggestedPollQuestion;
}

const Question = (props: IQuestionProps) => {
  const question = props.question;
  const description = question.details.description;

  return h('div.poll-question', [
    h('div.activity__description', [Viewer(description.text)]),
    description.attachments.length
      ? h(
          'div.activity__attachments',
          description.attachments.map((attachment) =>
            AttachmentViewer({
              attachment,
              hideDownloadIcon: true,
              downloadUrl: '',
              downloadRequest: null,
            })
          )
        )
      : null,
    h(
      'div.activity__section',
      question.details.options.map((o) => {
        return h('div.suggested-poll__option', [
          h('div.suggested-poll__option-header', `OPTION ${utils.indexToAlphabet(o.num - 1)}`),
          Viewer(o.text),
        ]);
      })
    ),
  ]);
};

interface IQuestionsProps {
  isAccessible?: boolean;
  style?: CSS;
  poll: ISuggestedPoll;
  questions: ISuggestedPollQuestion[];
}

export default (props: IQuestionsProps) => {
  const poll = props.poll;
  const questions = props.questions;

  return h(
    'div.poll-questions',
    { style: props.style },
    questions.map((question) =>
      Question({
        poll,
        question,
        isAccessible: props.isAccessible,
      })
    )
  );
};
