import './LongPress';
import 'acadly/utils/loggly';

import { h, mount, redraw, registerErrorHandler } from 'core';
import history from 'core/history';
import * as http from 'core/http';
import { cookies, sessionStorage } from 'core/storage';

import { Actions } from 'acadly/app/actions';
import { appVersion } from 'acadly/appVersion';
import Root from 'acadly/Root';
import { getActiveRoute } from 'acadly/routes';
import { Routes } from 'acadly/routes';
import { getStore } from 'acadly/store';
import * as u from 'acadly/utils';
import { loadScript, retry } from 'acadly/utils';
import logger from 'acadly/utils/loggly';
import { acadlyTime } from 'acadly/utils/timer';

const store = getStore();
(async () => {
  const MAX_ATTEMPTS = 5;
  try {
    await retry(async () => {
      await loadScript(
        // tslint:disable-next-line
        'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js?config=TeX-MML-AM_HTMLorMML'
      );
      if ((<any>window).MathJax) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        MathJax.Hub.Queue(['Typeset', MathJax.Hub], function () {});
      }
    }, MAX_ATTEMPTS);
  } catch {
    logger.push({
      type: 'MathJax loading failed',
      attempts: MAX_ATTEMPTS,
    });
  }
})();

http.setErrorHandler((error) => {
  if (error && !error.status) {
    store.dispatch(
      Actions.showError({
        message: "Our servers didn't respond. Please try after some time.",
      })
    );
    u.unsetTabIndices(document.getElementById('alert-box'));
  }
  throw error;
});

window.addEventListener('error', (e) => {
  const error = e.error || new Error(e.message);
  handleError(error);
});

window.addEventListener('keypress', (e) => {
  if (e.keyCode === 13) {
    if (document.activeElement && document.activeElement.hasAttribute('click')) {
      (document.activeElement as any).click();
    } else if (document.activeElement && document.activeElement.firstElementChild) {
      (document.activeElement.firstElementChild as any).click();
    } else {
      (document.activeElement as any).click();
    }
  }
});

registerErrorHandler((e) => {
  const error = e instanceof Error ? e : new Error(e.toString());
  handleError(error);
});

function handleError(error: Error) {
  const route = getActiveRoute();
  const appVersion = process.env.APP_VERSION;
  logger.push({
    type: 'Unhandled error',
    message: error.message,
    error: error,
    stack: error.stack,
    route: route ? route.route.path : null,
    version: appVersion,
  });
}

function resetSession() {
  localStorage.clear();
  sessionStorage.clear();
  cookies.remove('token');
  cookies.remove('session');
  window.location.href = '/';
}

http.addInterceptors({
  async onResponse(response) {
    const time = response.headers.get('acadlytime');

    if (time) {
      acadlyTime.setTime(parseInt(time));
    }

    if (response.status >= 400) {
      let message = 'Something went wrong.';
      let onclose = () => {
        // no-op
      };
      const state = store.getState();
      const server = state.getIn.server;
      if (response.status === 401) {
        message = "You've been logged out. Please log in again.";
        onclose = resetSession;
      } else {
        if (response.request.headers.get('content-type') === 'application/json') {
          const json: any = await response.data;
          if (json && typeof json === 'object' && 'message' in json && json.message) {
            message = json.message;
          }
        }
        if (
          server &&
          response.request.url.startsWith(server) &&
          response.data &&
          typeof response.data === 'string'
        ) {
          try {
            const json = JSON.parse(response.data);
            if (json.message) {
              message = json.message;
            }
          } catch (_) {
            // swallow error
          }
        }
      }
      store.dispatch(
        Actions.showError({
          message,
          onclose,
        })
      );
      throw response;
    }
    return response;
  },
  transformRequest({ url, ...options }) {
    if (/^http/.test(url)) {
      return { url, ...options };
    }
    const headers = options.headers;
    const state = store.getState();
    const server = state.getIn.server;
    const token = state.getIn.session ? state.getIn.session.token : null;
    const univSlug = state.getIn.session ? state.getIn.session.university.slug : null;
    const courseId = state.courses.currentCourseId;
    const requestHeaders: any = {
      courseId,
      appClient: 'web',
      appVersion: `web-${appVersion}`,
      Authorization: token ? `Bearer ${token}` : undefined,
      univSlug: univSlug ? univSlug : undefined,
      ...(headers || {}),
    };
    for (const key of Object.keys(requestHeaders)) {
      if (!requestHeaders[key]) {
        delete requestHeaders[key];
      }
    }
    return {
      ...options,
      url: server ? server + '/' + url : url,
      headers: requestHeaders,
    };
  },
});

history.listen((location) => {
  sessionStorage.setItem('location', location.pathname);
  redirectFetch(location.pathname);
  redirectHome(location.pathname);
  redraw();
});

function redirectHome(path: string) {
  const session = store.getState().getIn.session;
  if (path === '/login' && session) {
    Routes.home.navigate({});
  }
}

redirectFetch(window.location.pathname);
function redirectFetch(path: string) {
  const regex = /^\/fetch/;
  if (path.match(regex)) {
    history.push(path.replace(regex, ''));
  }
}

function render() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const rootElement = document.getElementById('app')!;
  rootElement.innerHTML = '';
  mount(rootElement, h(Root));
}

render();

// used by hot-loader
window.rerender = function () {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  mount(document.getElementById('app')!, h(Root));
};
