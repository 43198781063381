import { isEqual } from 'lodash';

import { View } from 'core';
import { VCallStatus } from 'core/enums';

import AcadlyEvents from 'acadly/AcadlyEvents';
import * as api from 'acadly/app/api';
import { INewFeatureSummary } from 'acadly/app/api';
import { createAction, Thunk } from 'acadly/createAction';
import { IPusherPayload } from 'acadly/pusher';

export type IAppActionMap = {
  '@app/SET_NARROW_SCREEN': boolean;
  '@app/SET_REFERRALS_ALLOWED': boolean;
  '@app/SHOW_ERROR': IServerError;
  '@app/CLEAR_ERROR': undefined;
  '@app/SET_CONTEXT': IAppContext;
  '@app/SET_WINDOW_WIDTH': number;
  '@app/SET_IS_MOBILE': boolean;
  '@app/TOGGLE_ANALYTICS': undefined;
  '@app/UNKNOWN_ERROR': Error;
  '@app/SET_SCROLLBAR_WIDTH': number;
  '@app/NOTIFICATION_CLEAR': string; // msgId
  '@app/NOTIFICATION_SHOW': INotification;
  '@app/SHOW_TOAST': IToast;
  '@app/HIDE_TOAST': IToast['toastId'];
  '@app/POP_ANALYTICS_BACK_STACK': undefined;
  '@app/PUSH_TO_ANALYTICS_BACK_STACK': () => void;
  '@app/SET_HEADER_HEIGHT': number;
  '@app/TOGGLE_CONTEXT_PANEL_EXPAND': boolean;
  '@app/START_TIP': true;
  '@app/STOP_TIP': false;
  '@app/ADD_TIP_ITEM': string;
  '@app/REMOVE_TIP_ITEM': string;
  '@app/SET_SEEN_FOR_TIP': TipKey;
  '@app/INITIALIZE_SUCCESS': IInitializePayload;
  '@app/DEMO_COURSE_CREATED': undefined;
  '@app/UPDATE_TIPS_SUCCESS': IUpdateTipsSuccessPayload;
  '@app/UPDATE_OLD_TIPS_OBJECT': ITipObject;
  '@app/UPDATE_ACC_SUCCESS': IAccUpdateRequest;
  '@app/SET_NEW_FEATURE_UPDATES': INewFeatureUpdates;
  '@app/MARK_FEATURE_SEEN': string;
  '@app/SET_NEW_FEATURES_LAST_ACCESED': UnixTimestamp;
  '@app/SET_SORT_STUDENT_BY': StudentSortBy;
  '@app/SHOW_VCALL_FRAME': IVCallFrameContext;
  '@app/HIDE_VCALL_FRAME': undefined;
  '@app/TOGGLE_AUTO_JOIN_VCALL': boolean;
  '@app/ZOOM_USER_AUTHORIZED': IPusherZoomUserAuthorizedPayload;
  '@app/UPDATE_VCALL_DETAILS': Partial<IVCallFrameContext>;
  '@app/ADD_VCALL_PARTICIPANT': IVCallParticipantJoinedPayload;
  '@app/REMOVE_VCALL_PARTICIPANT': IVCallParticipantLeftPayload;
  '@app/READY_TO_BROADCAST': IVCallParticipantJoinedPayload;
  '@app/VCALL_HAND_RAISED': IPusherHandRaised;
  '@app/VCALL_HAND_RAISE_SUCCESS': IHandRaised;
  '@app/VCALL_HAND_LOWERED': IPusherHandLowered;
  '@app/VCALL_LOWER_HAND_SUCCESS': IHandLowered;
  '@app/VCALL_ADDRESSING_HAND': IAddressingHand;
  '@app/VCALL_HAND_RESOLVED': IHandResolved;
  '@app/SET_FEEDBACK_FORM_DATA': IFeedbackFormData | undefined;
  '@app/SET_FEEDBACK_FORM_QUESTIONS': IFeedbackQuestion[];
  '@app/TOGGLE_NOTIFICATION_PERMISSION_ALERT': boolean;
  '@app/TOGGLE_ATTENDANCE_SCHEDULED_TO_START_ALERT':
    | { show: false }
    | { show: true; classId: string; courseId: string };
};

interface IAttendanceScheduledToStartData {
  message: string;
  courseId: string;
  classId: string;
  /** userId of the attendance taker */
  taker: string;
  sender: {
    userId: string;
    name: string;
    avatar: string;
    role: 'admin' | 'instructor';
  };
}

export type IPusherAttendanceScheduledToStartPayload =
  IPusherPayload<IAttendanceScheduledToStartData>;

export interface IFeedbackFormData {
  feedbackFormId: string;
  title: string;
  description: string;
  questions: IFeedbackQuestion[];
}

export interface IAddressingHand {
  userId: string;
}

export type IPusherAddressingHand = IPusherPayload<{
  role: 'student';
  courseId: string;
  classId: string;
  message: string;
  zoomUserId: ZoomId;
  userId: string;
  name: string;
  avatar: string;
}>;

interface IHandResolvedCommonPayload {
  userId: string;
}

interface IHandResolvedResolveAction {
  action: 'resolve';
}

interface IHandResolvedNextAction {
  action: 'next';
  nextUser: {
    role: 'student';
    zoomUserId: ZoomId;
    userId: string;
    name: string;
    avatar: string;
  };
}

export type IHandResolved = IHandResolvedCommonPayload &
  (IHandResolvedResolveAction | IHandResolvedNextAction);

interface IPusherHandResolvedCommonPayload {
  role: 'student';
  courseId: string;
  classId: string;
  message: string;
  zoomUserId: ZoomId;
  userId: string;
  name: string;
  avatar: string;
}

export type IPusherHandResolved = IPusherPayload<
  IPusherHandResolvedCommonPayload & (IHandResolvedResolveAction | IHandResolvedNextAction)
>;

export interface IHandRaised {
  userId: string;
}

export type IPusherHandRaised = IPusherPayload<{
  role: 'student';
  courseId: string;
  classId: string;
  message: string;
  raisedAt: UnixTimestamp;
  userId: string;
  name: string;
  avatar: string;
}>;

export interface IHandLowered {
  userId: string;
}

export type IPusherHandLowered = IPusherPayload<{
  role: 'student';
  courseId: string;
  classId: string;
  message: string;
  userId: string;
  name: string;
  avatar: string;
}>;

export type IPusherZoomUserAuthorizedPayload = IPusherPayload<{
  userId: string;
  autoRecordDefaults: {
    canCloudRecord: 0 | 1;
    autoCloudRecord: 0 | 1;
  };
}>;

export interface IVCallFrameContext {
  courseId: string;
  classId: string;
  role: IClassTeamRole;
  joinId: string;
  closeUrl?: string;
  meetingId: ZoomId;
  meetingPassword: string;
  meetingInProgress: VCallStatus;
  isBroadcasting?: boolean; // local flag only
  beingBroadcast: 0 | 1; // received from api response
}

export interface IVCallParticipantJoinedPayload extends IVCallParticipant {
  courseId: string;
  classId: string;
  mustExpel: 0 | 1;
  toLeave: ZoomId[];
}

export interface IVCallParticipantLeftPayload {
  courseId: string;
  classId: string;
  zoomUserId: ZoomId;
  userId: string;
  name: string;
  role: ICourseRole;
}

export type IUpdateTipsSuccessPayload = {
  tipKey?: TipKey;
  turnOff: 0 | 1;
};

export type IInitializePayload = api.IInitializeResponse;
export type ITipsUpdateRequest = api.ITipsUpdateRequest;
export type IAccUpdateRequest = api.IAccUpdateRequest;

export interface ITipObject {
  turnOff: 0 | 1;
  status: ITipItemMap;
}

export type TipKey =
  | 'courseHomeArchive'
  | 'courseHomeFloatingButton'
  | 'courseHomeSetting'
  | 'courseMainChat'
  | 'courseMainAnalytics'
  | 'courseMainFloatingButton'
  | 'classMainFloatingButton'
  | 'classMainQueryAdd'
  | 'classMainChat'
  | 'assignmentMainFloatingButton'
  | 'assignmentMainAttempt'
  | 'assignmentAttemptNavigation'
  | 'assignmentAttemptSave'
  | 'assignmentAttemptSubmit'
  | 'quizMainFloatingButton'
  | 'quizMainAttempt'
  | 'quizMainChat'
  | 'quizAttemptNavigation'
  | 'quizAttemptSave'
  | 'quizAttemptSubmit'
  | 'pollMainFloatingButton'
  | 'pollMainSubmit'
  | 'pollMainChat'
  | 'queryMainApprove'
  | 'queryMainDelete'
  | 'queryMainUpvote'
  | 'queryMainHide'
  | 'queryMainClose'
  | 'discussionMainComment'
  | 'courseAnalyticsAddStudents'
  | 'courseAnalyticsExport'
  | 'activityAnalyticsExport';

export type ITipItemMap = {
  [key in TipKey]?: {
    priority: number;
    seen: 0 | 1;
  };
};

export interface IAccObject {
  web: {
    turnOff: 0 | 1;
  };
}

export interface IServerError {
  message: string | string[] | View[];
  onclose?: () => void;
}

export interface INewFeatureUpdates {
  newFeaturesSeenAt: UnixTimestamp;
  newFeaturesLastUpdatedAt: UnixTimestamp;
  newFeaturesAvailable: ObjectMap<INewFeatureSummary>;
}

export const Actions = {
  setNarrowScreen: createAction('@app/SET_NARROW_SCREEN'),
  setReferralsAllowed: createAction('@app/SET_REFERRALS_ALLOWED'),
  showError: createAction('@app/SHOW_ERROR'),
  clearError: createAction('@app/CLEAR_ERROR'),
  setContext: createAction('@app/SET_CONTEXT'),
  setWindowWidth: createAction('@app/SET_WINDOW_WIDTH'),
  setIsMobile: createAction('@app/SET_IS_MOBILE'),
  analyticsButtonClick: (): Thunk<void> => async (dispatch, getState) => {
    const state = getState();
    if (state.app.analyticsBackStack.length) {
      await state.app.analyticsBackStack[state.app.analyticsBackStack.length - 1]();
      dispatch(Actions.popAnalyticsBackStack(undefined));
    } else {
      dispatch(Actions.toggleAnalytics(undefined));
    }
  },
  pushToAnalyticsBackStack: createAction('@app/PUSH_TO_ANALYTICS_BACK_STACK'),
  toggleAnalytics: createAction('@app/TOGGLE_ANALYTICS'),
  popAnalyticsBackStack: createAction('@app/POP_ANALYTICS_BACK_STACK'),
  setScrollbarWidth: createAction('@app/SET_SCROLLBAR_WIDTH'),
  notificationClear: createAction('@app/NOTIFICATION_CLEAR'),
  notificationShow: createAction('@app/NOTIFICATION_SHOW'),
  showToast: createAction('@app/SHOW_TOAST'),
  hideToast: createAction('@app/HIDE_TOAST'),
  setHeaderHeight: createAction('@app/SET_HEADER_HEIGHT'),
  startTip: createAction('@app/START_TIP'),
  stopTip: createAction('@app/STOP_TIP'),
  addTipItem: createAction('@app/ADD_TIP_ITEM'),
  removeTipItem: createAction('@app/REMOVE_TIP_ITEM'),

  toggleContextPanelExpand: createAction('@app/TOGGLE_CONTEXT_PANEL_EXPAND'),

  setFeedbackFormData: createAction('@app/SET_FEEDBACK_FORM_DATA'),
  setFeedbackFormQuestions: createAction('@app/SET_FEEDBACK_FORM_QUESTIONS'),
  getFeebackForm: (): Thunk<void> => async (dispatch, getState) => {
    const { feedbackForm } = getState().app;
    if (!feedbackForm) return;
    const { questions } = (await api.getFeebackForm(feedbackForm.feedbackFormId)).data;
    dispatch(Actions.setFeedbackFormQuestions(questions));
  },

  demoCourseCreated: createAction('@app/DEMO_COURSE_CREATED'),
  intializeSuccess: createAction('@app/INITIALIZE_SUCCESS'),
  initialize:
    (): Thunk<{
      needConsent: 0 | 1;
      marketing: 0 | 1;
      message: string;
      acc: IAccObject;
      showProPromo: 0 | 1;
    }> =>
    async (dispatch) => {
      const response = await api.initialize();

      dispatch(Actions.intializeSuccess(response.data));

      const { feedbackFormId, feedbackFormDetails } = response.data;

      dispatch(
        Actions.setFeedbackFormData({
          feedbackFormId,
          questions: [],
          title: feedbackFormDetails.title,
          description: feedbackFormDetails.text,
        })
      );

      return {
        needConsent: response.data.needConsent,
        marketing: response.data.marketing,
        message: response.data.message,
        acc: response.data.acc,
        showProPromo: response.data.showProPromo,
      };
    },

  setSortStudentBy: createAction('@app/SET_SORT_STUDENT_BY'),
  saveDefaultSortPref:
    (sortBy: StudentSortBy): Thunk<void> =>
    async (dispatch) => {
      await api.saveDefaultSortPref(sortBy);
      dispatch(Actions.setSortStudentBy(sortBy));
    },

  updateOldTipObject: createAction('@app/UPDATE_OLD_TIPS_OBJECT'),
  updateTipSuccess: createAction('@app/UPDATE_TIPS_SUCCESS'),
  updateAccSuccess: createAction('@app/UPDATE_ACC_SUCCESS'),
  updateAccessibility:
    (data: IAccUpdateRequest): Thunk<void> =>
    async (dispatch) => {
      await api.updateAcc(data);
      await dispatch(Actions.updateAccSuccess(data));
    },
  updateTip:
    (data: ITipsUpdateRequest, key?: TipKey): Thunk<void> =>
    async (dispatch, getStore) => {
      const oldTipsObject = getStore().app.oldTipObject;
      setTimeout(async () => {
        if (!isEqual(oldTipsObject, data.status)) {
          await api.updateTips(data);
          await dispatch(Actions.updateOldTipObject(data));
        }
      }, 10000);
      dispatch(
        Actions.updateTipSuccess({
          tipKey: key,
          turnOff: data.turnOff,
        })
      );
    },

  setNewFeatureUpdates: createAction('@app/SET_NEW_FEATURE_UPDATES'),
  markFeatureSeen: createAction('@app/MARK_FEATURE_SEEN'),
  newFeatures:
    (featureKey?: string): Thunk<api.IGetNewFeatureURLResponse> =>
    async (dispatch) => {
      const response = await api.newFeatures(featureKey);
      if (!response.data.allFeatures && featureKey) {
        dispatch(Actions.markFeatureSeen(featureKey));
      }
      return response.data;
    },

  setNewFeaturesLastAccesed: createAction('@app/SET_NEW_FEATURES_LAST_ACCESED'),
  updateNewFeaturesLastAccesed: (): Thunk<void> => async (dispatch) => {
    const response = await api.updateNewFeaturesLastAccesed();
    dispatch(Actions.setNewFeaturesLastAccesed(response.data.newFeaturesSeenAt));
  },

  showVCallFrame: createAction('@app/SHOW_VCALL_FRAME'),
  hideVcallFrame: createAction('@app/HIDE_VCALL_FRAME'),
  toggleAutoJoinVCall: createAction('@app/TOGGLE_AUTO_JOIN_VCALL'),
  updateVCallDetails: createAction('@app/UPDATE_VCALL_DETAILS'),

  initVCallFrame:
    (context: IVCallFrameContext): Thunk<void> =>
    async (dispatch) => {
      dispatch(Actions.showVCallFrame(context));
      AcadlyEvents.next({ type: '@vcall/update_frame_mode' });
    },

  zoomUserAuthorized: createAction('@app/ZOOM_USER_AUTHORIZED'),
  readyToBroadcast: createAction('@app/READY_TO_BROADCAST'),

  addVCallParticipant: createAction('@app/ADD_VCALL_PARTICIPANT'),
  removeVCallParticipant: createAction('@app/REMOVE_VCALL_PARTICIPANT'),

  handRaised: createAction('@app/VCALL_HAND_RAISED'),
  raiseHandSuccess: createAction('@app/VCALL_HAND_RAISE_SUCCESS'),
  raiseHand:
    (classId: string, userId: string): Thunk<void> =>
    async (dispatch) => {
      await api.raiseHand(classId);
      dispatch(Actions.raiseHandSuccess({ userId }));
    },

  handLowered: createAction('@app/VCALL_HAND_LOWERED'),
  lowerHandSuccess: createAction('@app/VCALL_LOWER_HAND_SUCCESS'),
  lowerHand:
    (classId: string, userId: string): Thunk<void> =>
    async (dispatch) => {
      await api.lowerHand(classId);
      dispatch(Actions.lowerHandSuccess({ userId }));
    },

  addressingHand: createAction('@app/VCALL_ADDRESSING_HAND'),
  addressingHandPusher:
    (payload: IPusherAddressingHand): Thunk<void> =>
    async (dispatch) => {
      dispatch(Actions.addressingHand({ userId: payload.userId }));
    },
  addressHand:
    (classId: string, userId: string): Thunk<void> =>
    async (dispatch) => {
      const response = await api.addressHand(classId, userId);

      if (response.data.isErrored) {
        dispatch(
          Actions.showError({
            message: response.data.errorMessage,
          })
        );
        return;
      }

      dispatch(Actions.addressingHand({ userId }));
    },

  handResolved: createAction('@app/VCALL_HAND_RESOLVED'),
  handResolvedPusher:
    (payload: IPusherHandResolved): Thunk<void> =>
    async (dispatch) => {
      dispatch(Actions.handResolved(payload));
    },
  resolveHand:
    (
      classId: string,
      userId: string,
      action: 'resolve' | 'next'
    ): Thunk<api.IResolveHandResponse> =>
    async (dispatch) => {
      const response = await api.resolveHand(classId, userId, action);

      if (response.data.isErrored) {
        dispatch(
          Actions.showError({
            message: response.data.errorMessage,
          })
        );
        return response.data;
      }

      if (response.data.nextUser) {
        dispatch(
          Actions.handResolved({
            userId,
            action: 'next',
            nextUser: response.data.nextUser,
          })
        );
        return response.data;
      }

      dispatch(
        Actions.handResolved({
          userId,
          action: 'resolve',
        })
      );

      return response.data;
    },

  toggleNotificationPermissionAlert: createAction('@app/TOGGLE_NOTIFICATION_PERMISSION_ALERT'),
  showNotificationPermissionAlert: (): Thunk<void> => async (dispatch) => {
    if (Notification.permission !== 'granted') {
      dispatch(Actions.toggleNotificationPermissionAlert(true));
    }
  },

  toggleAttendanceScheduledToStartAlert: createAction(
    '@app/TOGGLE_ATTENDANCE_SCHEDULED_TO_START_ALERT'
  ),
  attendanceScheduledToStart:
    (data: IPusherAttendanceScheduledToStartPayload): Thunk<void> =>
    async (dispatch) => {
      dispatch(
        Actions.toggleAttendanceScheduledToStartAlert({
          show: true,
          classId: data.classId,
          courseId: data.courseId,
        })
      );
    },
};
