import { IRootState } from 'acadly/IRootState';

import assignmentFunctions from '../assignment/functions';
import classFunctions from '../class/functions';
import CommentsGetter from './CommentsGetter';

type HasComments = IClass | IAssignment;

export const getTotalComments = (item: HasComments) => {
  const getter = commentsGetter(item);
  return getter.getTotalComments(item);
};

export const getSeenComments = (item: HasComments) => commentsGetter(item).getSeenComments(item);

export const getUnseenComments = (item: HasComments) =>
  commentsGetter(item).getUnseenComments(item);

function commentsGetter<Item extends HasComments>(item: Item): CommentsGetter<Item> {
  switch (item.nodeType) {
    case 'class':
      return classFunctions as CommentsGetter<Item>;
    case 'assignment':
      return assignmentFunctions as CommentsGetter<Item>;
  }
}

export function getTotalCommentsForContext(
  state: IRootState,
  context: ICommentsContext,
  subContext: ICommentsSubContext,
  contextId: string
): number {
  if (context === 'course') {
    return state.courses.courses[contextId].activities.numCommentsTotal;
  } else if (context === 'classes') {
    const timeline = state.courses.timeline;
    if (timeline) {
      const cls = timeline.items.find((i) => i._id === contextId) as IClass;
      return cls.activities.numCommentsTotal;
    } else {
      return 0;
    }
  } else if (context === 'assignments') {
    const timeline = state.courses.timeline;
    if (timeline) {
      const assignment = timeline.items.find((i) => i._id === contextId) as IAssignment;
      if (subContext === 'preSub') {
        return assignment.activities.numCommentsPreSub;
      } else {
        return assignment.activities.numCommentsPostSub;
      }
    } else {
      return 0;
    }
  } else {
    const byId = state[context].byId;
    const activity = byId[contextId];
    if (activity) {
      return activity.activities.numCommentsTotal;
    } else {
      return 0;
    }
  }
}

export function getCanShowAwardsInComment(args: {
  context: ICommentsContext;
  contextId: string;
  state: IRootState;
}) {
  const { context, contextId, state } = args;

  if (context !== 'discussions') return true;

  const discussion = state.discussions.byId[contextId];

  return !discussion.details.anonymize || discussion.details.anonymity === 'students';
}

export function getCanRateComment({
  comment,
  courseRole,
  context,
  contextId,
  state,
}: {
  comment: IComment;
  courseRole: ICourseRole;
  context: ICommentsContext;
  contextId: string;
  state: IRootState;
}) {
  const canShowAwardsInComment = getCanShowAwardsInComment({
    context,
    contextId,
    state,
  });

  return (
    canShowAwardsInComment &&
    comment.details.createdBy.role === 'student' &&
    (courseRole === 'admin' || courseRole === 'instructor') &&
    !comment.removed
  );
}
