import { cloneDeep, isEqual } from 'lodash';

import { CSS, h, IComponent, View } from 'core';

import urls from 'acadly/api';
import { Actions as appActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import appService from 'acadly/app/service';
import { assignmentService } from 'acadly/assignment/service';
import ActionBar, { isIpad } from 'acadly/common/ActionBar';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Avatar from 'acadly/common/Avatar';
import { BottomFloatingBar } from 'acadly/common/BottomFloatingBar';
import ContentView from 'acadly/common/ContentView';
import Icon from 'acadly/common/Icon';
import { Loader } from 'acadly/common/Loader';
import { fullScreenLoader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import TimePicker from 'acadly/common/TimePicker';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import Toggle from 'acadly/common/Toggle';
import UploadButton from 'acadly/common/UploadButton';
import CopyActivityDialog from 'acadly/course/CopyActivityDialog';
import { validateActivityPublish, validateActivitySubmit } from 'acadly/course/validations';
import icons from 'acadly/icons';
import { Routes } from 'acadly/routes';
import * as u from 'acadly/utils';

import api from '../api';
import Alert from '../common/Alert';
import DatePicker from '../common/DatePicker';
import Dialog from '../common/Dialog';
import FlatButton from '../common/FlatButton';
import FloatingActionButton from '../common/FloatingActionButton';
import FloatingMenu from '../common/FloatingMenu';
import Paper from '../common/Paper';
import RaisedButton from '../common/RaisedButton';
import TextField from '../common/TextField';
import courseService from '../course/service';
import * as datetime from '../datetime';
import Editor from '../rich-text/Editor';
import Viewer from '../rich-text/Viewer';
import { goBack } from '../routes';
import { dispatch, getStore } from '../store';
import { backgroundColor, colors, margin, mb, ml, mr, mt, pad, pb, pr, style } from '../styles';
import * as Actions from './actions';
import { IUpdateAssignmentRequest, IUpdateAssignmentRequestLocalData } from './api';

interface IAssignmentProps {
  assignment: IAssignment;
  course: ICourse;
}
interface IAssignmentState {
  isRetractDialogOpen: boolean;
  isDeleteConfirmationVisible: boolean;
  isLoading: boolean;
  isFloatingMenuOpen: boolean;

  // edit title dialog state
  isEditingTitle: boolean;
  titleField: string;
  titleFieldError: boolean;

  // edit instructions dialog state
  isInstructionsDialogVisible: boolean;
  instructionsField: string;
  instructionsAttachmentsField: IAttachment[];

  // add/edit question dialog state
  isEditQuestionDialogVisible: boolean;
  editingQuestionId: string | null;
  editingQuestionDescriptionField: string;
  editingQuestionMarksField: string;
  editingQuestionFormatsField: string;
  editingQuestionSubmissionField: 'url' | 'file';
  editingQuestionSubmission: 'url' | 'file';
  editingQuestionAttachmentsField: IAttachment[];
  editingQuestionDescriptionError: boolean;
  editingQuestionMarksError: boolean;
  editingQuestionFormatsError: boolean;
  editingQuestionIsSubmissionFormatDialogOpen: boolean;

  feedbackCommentDialogOpenFor: string | null;
  isFeedbackCommentDialogOpen: boolean;

  isDeleteQuestionDialogVisible: boolean;
  isPublishDialogVisible: boolean;
  dueDateField?: Date;
  dueTimeField: ITime;
  dueDateFieldError: boolean;
  lateSubmissionsField: boolean;
  isEditingSubmissionUrlFor: string | null; // questionId
  submissionUrlField: string;

  subscribeField: boolean;

  isAttempting: boolean;
  isAttemptSubmissionDialogOpen: boolean;
  attemptQuestionIndex: number;

  isCopyDialogOpen: boolean;
  isWrongExtensionAlertOpen: boolean;
  // edit assignment state
  editAssignmentDialog: {
    iseditAssigmentPublishDialogVisible: boolean;
    isEditing: boolean;
    questions: IAssignmentQuestion[];
    title: string;
    isEditingTitle: boolean;
    instructions: string;
    isEditingInstructions: boolean;
    instructionsAttachments: IAttachment[];
    titleField: string;
    titleFieldError: boolean;
    instructionsField: string;
    instructionsAttachmentsField: IAttachment[];
    isDeleteQuestionDialogVisible: boolean;
    toNotify: 0 | 1;
    dueDateTime: number;
    dueDateField: Date;
    dueTimeField: ITime;

    editingQuestionId: string | null;
    isEditQuestionDialogVisible: boolean;
    editingQuestionDescriptionField: string;
    editingQuestionMarksField: string;
    editingQuestionFormatsField: string;
    editingQuestionSubmissionField: 'url' | 'file';
    editingQuestionSubmission: 'url' | 'file';
    editingQuestionAttachmentsField: IAttachment[];
    editingQuestionDescriptionError: boolean;
    editingQuestionMarksError: boolean;
    editingQuestionFormatsError: boolean;
    editingQuestionIsSubmissionFormatDialogOpen: boolean;
  };
}
export default (props: IAssignmentProps) => h(Assignment, props);
const defaultInstructions =
  'There are no special instructions. Just attempt the assignment. Best of luck!';
export class Assignment extends IComponent<IAssignmentProps, IAssignmentState> {
  public componentWillMount() {
    const assignment = this.getProps().assignment;
    const [dueHrs, dueMins] = datetime.format(assignment.details.dueDateTime, 'HH:mm').split(':');
    dispatch(appActions.startTip(true));
    const initialState: IAssignmentState = {
      isRetractDialogOpen: false,
      isLoading: false,
      isDeleteConfirmationVisible: false,
      isEditingTitle: false,
      isInstructionsDialogVisible: false,
      titleField: '',
      titleFieldError: false,
      instructionsField: assignment.details.instructions,
      instructionsAttachmentsField: assignment.details.attachments,
      isFloatingMenuOpen: false,

      isEditQuestionDialogVisible: false,
      editingQuestionId: null,
      editingQuestionSubmissionField: 'url',
      editingQuestionSubmission: 'url',
      editingQuestionDescriptionField: '',
      editingQuestionFormatsField: '',
      editingQuestionAttachmentsField: [],
      editingQuestionDescriptionError: false,
      editingQuestionMarksField: '',
      editingQuestionMarksError: false,
      editingQuestionFormatsError: false,
      editingQuestionIsSubmissionFormatDialogOpen: false,
      subscribeField: true,

      feedbackCommentDialogOpenFor: null,
      isFeedbackCommentDialogOpen: false,

      isCopyDialogOpen: false,

      isDeleteQuestionDialogVisible: false,
      isPublishDialogVisible: false,
      dueDateFieldError: false,
      dueTimeField: {
        hours: 23,
        minutes: 59,
      },
      lateSubmissionsField: true,
      submissionUrlField: '',
      isEditingSubmissionUrlFor: null,

      isAttempting: false,
      isAttemptSubmissionDialogOpen: false,
      attemptQuestionIndex: 0,

      isWrongExtensionAlertOpen: false,

      // edit assignment state
      editAssignmentDialog: {
        iseditAssigmentPublishDialogVisible: false,
        title: assignment.details.title,
        isEditingTitle: false,
        instructions: assignment.details.instructions,
        isEditingInstructions: false,
        instructionsAttachments: assignment.details.attachments,
        instructionsAttachmentsField: assignment.details.attachments,
        titleField: assignment.details.title !== 'Untitled' ? assignment.details.title : '',
        titleFieldError: false,
        instructionsField: assignment.details.instructions,
        isEditing: false,
        questions: [],
        isDeleteQuestionDialogVisible: false,
        toNotify: 1,
        dueDateTime: assignment.details.dueDateTime,
        dueDateField: datetime.startOfDay(assignment.details.dueDateTime),
        dueTimeField: {
          hours: parseInt(dueHrs),
          minutes: parseInt(dueMins),
        },

        isEditQuestionDialogVisible: false,
        editingQuestionId: null,
        editingQuestionSubmissionField: 'url',
        editingQuestionSubmission: 'url',
        editingQuestionDescriptionField: '',
        editingQuestionFormatsField: '',
        editingQuestionAttachmentsField: [],
        editingQuestionDescriptionError: false,
        editingQuestionMarksField: '',
        editingQuestionMarksError: false,
        editingQuestionFormatsError: false,
        editingQuestionIsSubmissionFormatDialogOpen: false,
      },
    };
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    this.setState(initialState)
      .then(async () => {
        if (this.canSeeQuestions()) {
          await dispatch(Actions.fetchAssignmentData(assignment, false)).then(() => {
            this.setState({
              editAssignmentDialog: {
                ...this.getState().editAssignmentDialog,
                questions: cloneDeep(this.questions()),
              },
            });
          });
        }
      })
      .then(() => {
        this.setState({ isLoading: false });
        const actionbar = document.getElementById('action-bar');
        actionbar ? actionbar.focus() : null;
      });
  }
  private isLocalStateUpdated = false;

  public componentWillReceiveProps(nextProps: IAssignmentProps) {
    const { assignment } = nextProps;
    if (assignment.details.published && !this.isLocalStateUpdated) {
      const [dueHrs, dueMins] = datetime.format(assignment.details.dueDateTime, 'HH:mm').split(':');
      this.isLocalStateUpdated = true;
      this.setState({
        ...this.getState(),
        editAssignmentDialog: {
          iseditAssigmentPublishDialogVisible: false,
          title: assignment.details.title,
          isEditingTitle: false,
          instructions: assignment.details.instructions,
          isEditingInstructions: false,
          instructionsAttachments: assignment.details.attachments,
          instructionsAttachmentsField: assignment.details.attachments,
          titleField: assignment.details.title !== 'Untitled' ? assignment.details.title : '',
          titleFieldError: false,
          instructionsField: assignment.details.instructions,
          isEditing: false,
          questions: cloneDeep(this.questions()),
          isDeleteQuestionDialogVisible: false,
          toNotify: 1,
          dueDateTime: assignment.details.dueDateTime,
          dueDateField: datetime.startOfDay(assignment.details.dueDateTime),
          dueTimeField: {
            hours: parseInt(dueHrs),
            minutes: parseInt(dueMins),
          },

          isEditQuestionDialogVisible: false,
          editingQuestionId: null,
          editingQuestionSubmissionField: 'url',
          editingQuestionSubmission: 'url',
          editingQuestionDescriptionField: '',
          editingQuestionFormatsField: '',
          editingQuestionAttachmentsField: [],
          editingQuestionDescriptionError: false,
          editingQuestionMarksField: '',
          editingQuestionMarksError: false,
          editingQuestionFormatsError: false,
          editingQuestionIsSubmissionFormatDialogOpen: false,
        },
      });
    }
  }

  private async updatePublishedAssignment() {
    const state = this.getState().editAssignmentDialog;
    const { assignment } = this.getProps();
    let dueDateTime;
    if (state.dueDateField) {
      let canEditDueDate;
      const course = this.getProps().course;
      if (course.dates) {
        canEditDueDate = course.dates.endDate >= datetime.unix();
      }
      const dayStart = datetime.startOfDay(datetime.fromUnix(state.dueDateTime));
      dueDateTime = canEditDueDate
        ? datetime.toUnix(
            datetime.addMinutes(
              datetime.addHours(dayStart, state.dueTimeField.hours),
              state.dueTimeField.minutes
            )
          )
        : state.dueDateTime;
      let data: IUpdateAssignmentRequest = {
        assignmentId: assignment._id,
        title: state.title,
        instructions: state.instructions,
        attachments: state.instructionsAttachments,
        dueDateTime: datetime.format(dueDateTime, 'YYYYMMDDTHH:mm'),
        toNotify: state.toNotify,
      };
      let localData: IUpdateAssignmentRequestLocalData = {
        assignmentId: assignment._id,
        title: state.title,
        instructions: state.instructions,
        attachments: state.instructionsAttachments,
        dueDateTime: dueDateTime,
      };
      if (!isEqual(state.questions, this.questions())) {
        const questions = state.questions.map((q) => ({
          questionId: q._id,
          description: q.details.description,
          marks: q.details.marks,
          attachments: q.details.attachments,
          submission: q.details.submission,
          submitExt: q.details.submitExt,
        }));
        data = {
          ...data,
          questions,
        };
        localData = {
          ...localData,
          questions: state.questions,
        };
      }
      await dispatch(Actions.updateAssignment(data, localData));
      this.setState({
        editAssignmentDialog: {
          ...this.getState().editAssignmentDialog,
          iseditAssigmentPublishDialogVisible: false,
          isEditing: false,
        },
      });
    }
  }

  private updatePublishedAssignmentDialog() {
    return Alert(
      {
        open: this.getState().editAssignmentDialog.iseditAssigmentPublishDialogVisible,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('NO', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                editAssignmentDialog: {
                  ...this.getState().editAssignmentDialog,
                  iseditAssigmentPublishDialogVisible: false,
                },
              }),
          }),
          FlatButton('YES', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.updatePublishedAssignment(),
          }),
        ],
      },
      [
        h('div', [
          h('p', 'Are you sure you want to publish these changes?'),
          h(
            'div',
            style(['flex', 'fullWidth', pad('1rem 0 0'), 'whiteBackground', 'spaceBetween']),
            [
              h('span', 'Send notifications to students'),
              Toggle({
                selected: this.getState().editAssignmentDialog.toNotify === 1,
                ontoggle: () =>
                  this.setState({
                    editAssignmentDialog: {
                      ...this.getState().editAssignmentDialog,
                      toNotify: this.getState().editAssignmentDialog.toNotify === 0 ? 1 : 0,
                    },
                  }),
              }),
            ]
          ),
        ]),
      ]
    );
  }

  public componentWillUnmount() {
    dispatch(Actions.leaveAssignmentPage(undefined));
  }

  private canSeeQuestions() {
    const props = this.getProps();
    return (
      this.getCourseRole() !== 'student' ||
      this.isSubmitted() ||
      (!props.assignment.details.allowLate &&
        props.assignment.details.dueDateTime < datetime.unix())
    );
  }

  public render() {
    const isMobile = getStore().getState().app.isMobile;
    const state = this.getState();
    if (state.isEditQuestionDialogVisible) {
      return this.editQuestionDialog();
    }
    if (!this.canSeeQuestions() && state.isAttempting) return this.attemptScreen();
    return h(
      u.getHTMLTagSelector('div', [
        'activity',
        'assignment',
        this.isActionBarVisible() ? 'with-action-bar' : '',
        state.isAttempting ? 'attempting' : '',
      ]),
      [
        isMobile || isIpad ? this.actionBar() : null,
        ContentView(
          h('div.activity__content', [
            isMobile || isIpad ? null : this.actionBar(),
            ...this.floatingButtons(),
            this.info(),
            this.title(),
            this.instructions(),
            this.loader(),
            this.questionsView(),
            this.creator(),
            this.prePublishWarnings(),

            this.attemptButton(),

            this.copyDialog(),
            this.deleteConfirmationAlert(),
            this.retractConfirmationDialog(),
            this.publishDialog(),
            this.editAssignmentDialog(),
          ])
        ),
        // this.attemptButton() && status !== "inProgress"
        //     ? TipOverlayWrapper({
        //         targetElement: "assignment-attempt-button",
        //         tip: {
        //             tipPosition: "bottom",
        //             tipText: "To attempt the assignment questions, use this button." +
        //                     " You can quit anytime and your progress will be saved"
        //         },
        //         tipKey: "assignmentMainAttempt",
        //         isNextAvailable: false,
        //         isSpecial: true
        //     })
        //     : null,
        this.submissionStatusBar(),
      ]
    );
  }
  private isAccessible = false;

  private actionBar() {
    if (!this.isActionBarVisible()) {
      return null;
    }
    return ActionBar(
      [
        {
          label: 'Delete',
          onclick: () => {
            this.lastFocusedElement = document.activeElement;
            u.unsetTabIndices();
            this.setState({
              isDeleteConfirmationVisible: true,
            });
          },
          disabled: !this.isDeletionEnabled(),
          classNames: ['red'],
          icon: {
            type: 'view',
            view: Icon(icons.trash),
          },
        },
        {
          label: 'Copy',
          onclick: () => this.openCopyDialog(),
          disabled: !this.isCopyEnabled(),
          classNames: ['blue'],
          icon: {
            type: 'view',
            view: Icon(icons.copy),
          },
        },
        {
          label: 'Move',
          onclick: () => {},
          disabled: !this.isMoveEnabled(),
          classNames: ['blue'],
          icon: {
            type: 'view',
            view: Icon(icons.move),
          },
        },
        {
          label: this.canEditAssignment() ? 'Edit' : 'Publish',
          disabled: !(this.canEditAssignment() || this.isPublishButtonEnabled()),
          onclick: () =>
            this.canEditAssignment() ? this.openEditAssignmentDialog() : this.openPublishDialog(),
          classNames: ['orange'],
          icon: {
            type: 'view',
            view: this.canEditAssignment() ? Icon(icons.pencil) : Icon(icons.publish),
          },
        },
      ],
      this.isAccessible
    );
  }
  private canEditAssignment() {
    const { course, assignment } = this.getProps();
    return !course.isArchived && this.isCreator() && assignment.details.published;
  }

  private openEditAssignmentDialog() {
    this.setState({
      editAssignmentDialog: {
        ...this.getState().editAssignmentDialog,
        isEditing: true,
        questions: cloneDeep(this.questions()),
      },
    });
  }
  private canUpdateAssignment() {
    const newstate = this.getState().editAssignmentDialog;
    const assignment = this.getProps().assignment;
    const oldQuestions = this.questions();
    const newDueDatetime = newstate.dueDateTime;
    return (
      newstate.title === assignment.details.title &&
      newstate.instructions === assignment.details.instructions &&
      newstate.instructionsAttachments === assignment.details.attachments &&
      isEqual(oldQuestions, newstate.questions) &&
      newDueDatetime === assignment.details.dueDateTime
    );
  }

  private editAssignmentDialog() {
    const state = this.getState().editAssignmentDialog;
    return Dialog(
      {
        open: state.isEditing,
        title: `Editing Assignment`,
        secondaryAction: {
          id: 'assignment-save-button',
          label: 'BACK',
          mobileLabel: h('i.fa.fa-arrow-left', []),
          onclick: () =>
            this.setState({
              editAssignmentDialog: {
                ...state,
                isEditing: false,
              },
            }),
        },
        thinHeader: true,
        bodyStyle: {
          height: '100%',
          position: 'relative',
        },
        style: {
          maxWidth: '36em',
          backgroundColor: colors.backgroundColor,
        },
        primaryAction: {
          id: 'assignment-submit-button-mobile',
          label: 'UPDATE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () =>
            this.setState({
              editAssignmentDialog: {
                ...state,
                iseditAssigmentPublishDialogVisible: true,
              },
            }),
          disabled: this.canUpdateAssignment(),
        },
      },
      [
        h(
          'div',
          {
            style: {
              padding: '0 0.5rem',
            },
          },
          [
            this.editAssignmentDueDate(),
            this.editAssignmentTitle(),
            this.editAssignmentInstructions(),
            this.editAssignmentQuestionsView(),
            this.editAssignmentEditQuestionDialog(),
            this.updatePublishedAssignmentDialog(),
          ]
        ),
      ]
    );
  }

  private editAssignmentDueDate() {
    const state = this.getState().editAssignmentDialog;
    let canEditDueDate;
    const course = this.getProps().course;
    if (course.dates) {
      canEditDueDate = course.dates.endDate >= datetime.unix();
    }

    return h(
      'div',
      canEditDueDate
        ? [
            h(
              'div',
              {
                style: {
                  // boxShadow: "#999 1px 1px 5px 0px",
                  backgroundColor: 'white',
                  marginTop: '0.5rem',
                },
              },
              [
                h(
                  'div',
                  style(
                    [
                      pad('0.5rem 1rem'),
                      canEditDueDate ? 'whiteBackground' : 'greyBackground',
                      'flex',
                      'spaceBetween',
                      'large',
                    ],
                    {},
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                    }
                  ),
                  [
                    h('span', 'Due date'),
                    DatePicker({
                      minDate: new Date(),
                      maxDate: course.dates ? datetime.fromUnix(course.dates.endDate) : undefined,
                      value: datetime.fromUnix(state.dueDateTime),
                      onchange: (date) => {
                        const state = this.getState().editAssignmentDialog;
                        const dueDateTime = datetime.addMinutes(
                          datetime.addHours(date, state.dueTimeField.hours),
                          state.dueTimeField.minutes
                        );
                        this.setState({
                          editAssignmentDialog: {
                            ...this.getState().editAssignmentDialog,
                            dueDateField: datetime.startOfDay(date),
                            dueDateTime: datetime.toUnix(dueDateTime),
                          },
                        });
                      },
                    }),
                  ]
                ),
                h(
                  'div',
                  style(
                    [pad('0.5rem 1rem'), 'whiteBackground', 'flex', 'spaceBetween', 'large'],
                    {},
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                    }
                  ),
                  [
                    h('span', 'Due time'),
                    TimePicker({
                      time: state.dueTimeField,
                      onChange: (time) => {
                        const dayStart = datetime.startOfDay(datetime.fromUnix(state.dueDateTime));
                        const afterhours = datetime.addHours(dayStart, time.hours);
                        const dueDateTime = datetime.addMinutes(afterhours, time.minutes);
                        this.setState({
                          editAssignmentDialog: {
                            ...state,
                            dueTimeField: time,
                            dueDateTime: datetime.toUnix(dueDateTime),
                          },
                        });
                      },
                      icon: h('i.fa.fa-pencil', style(['lightGrey'])),
                    }),
                  ]
                ),
              ]
            ),
          ]
        : [
            h(
              'div',
              style(
                [pad('0.5rem 0.5rem'), 'greyBackground', 'flex', 'spaceBetween', 'large'],
                {},
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                }
              ),
              [h('span', 'Due on'), datetime.format(state.dueDateTime, 'hh:mm A, MMM DD, YYYY')]
            ),
          ]
    );
  }

  private isPublishButtonEnabled() {
    const { course, assignment } = this.getProps();
    return !course.isArchived && this.isCreator() && !assignment.details.published;
  }

  private info() {
    const props = this.getProps();
    const assignment = props.assignment;

    if (!assignment.details.published) return null;

    return h('div.activity__section', [
      h(
        'div.cell',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          h('span.cell__label', 'Due on'),
          h(
            'span.cell__value',
            datetime.format(assignment.details.dueDateTime, 'hh:mm A, MMM DD, YYYY')
          ),
        ]
      ),
      h(
        'div.cell',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          h('span.cell__label', 'Late submissions'),
          h('span.cell__value', assignment.details.allowLate ? 'Allowed' : 'Not allowed'),
        ]
      ),
    ]);
  }

  private editTitleDialog() {
    const { isEditingTitle, titleField, titleFieldError } = this.getState();
    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Assignment Title',
        open: isEditingTitle,
        hasInput: true,
        actions: [
          FlatButton('Discard', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.setState({
                isEditingTitle: false,
              }).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              });
            },
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.saveTitleHandler(),
          }),
        ],
      },
      [
        h('div.textfield-container', style(['flex', 'alignCenter', pad('0.5rem')]), [
          TextField({
            value: titleField,
            focusOnMount: true,
            errorText: titleFieldError ? 'Please enter a title' : undefined,
            placeholder: 'Enter title here',
            maxLength: 140,
            oninput: (e) =>
              this.setState({
                titleField: e.target.value,
                titleFieldError: false,
              }),
            onenter: () => this.saveTitleHandler(),
          }),
        ]),
      ]
    );
  }
  private editAssignmentEditTitleDialog() {
    const { isEditingTitle, titleField, titleFieldError } = this.getState().editAssignmentDialog;
    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Assignment Title',
        open: isEditingTitle,
        hasInput: true,
        actions: [
          FlatButton('Discard', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.setState({
                editAssignmentDialog: {
                  ...this.getState().editAssignmentDialog,
                  isEditingTitle: false,
                },
              });
              // .then(() => {
              //     u.resetTabIndices();
              //     (this.lastFocusedElement as HTMLElement).focus();
              // });
            },
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.saveTitleHandler(true),
          }),
        ],
      },
      [
        h('div.textfield-container', style(['flex', 'alignCenter', pad('0.5rem')]), [
          TextField({
            value: titleField,
            focusOnMount: true,
            errorText: titleFieldError ? 'Please enter a title' : undefined,
            placeholder: 'Enter title here',
            maxLength: 140,
            oninput: (e) =>
              this.setState({
                editAssignmentDialog: {
                  ...this.getState().editAssignmentDialog,
                  titleField: e.target.value,
                  titleFieldError: false,
                },
              }),
            onenter: () => this.saveTitleHandler(true),
          }),
        ]),
      ]
    );
  }

  private title() {
    const { assignment } = this.getProps();
    if (this.isEditEnabled()) {
      return h('div.panel', this.accessibilityAttrs(), [
        h('div.panel__header', this.accessibilityAttrs(), [
          h('span', 'Title'),
          h('i.fa.fa-pencil', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.editTitleHandler(),
          }),
        ]),
        this.editTitleDialog(),
        h('div.panel__content', [
          assignment.details.title && assignment.details.title !== 'Untitled'
            ? h(
                'div',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                },
                assignment.details.title
              )
            : h('div.fc-light-grey', [
                "The assignment doesn't have a title yet. Click on the ",
                h('i.fa.fa-pencil'),
                ' icon to add.',
              ]),
        ]),
      ]);
    } else {
      return h('div.activity__title', this.accessibilityAttrs(), [
        assignment.details.title || 'Untitled',
      ]);
    }
  }

  private editAssignmentTitle() {
    const { title } = this.getState().editAssignmentDialog;
    return h('div.panel', this.accessibilityAttrs(), [
      h('div.panel__header', this.accessibilityAttrs(), [
        h('span', 'Title'),
        h('i.fa.fa-pencil', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => this.editTitleHandler(true),
        }),
      ]),
      this.editAssignmentEditTitleDialog(),
      h('div.panel__content', [
        title && title !== 'Untitled'
          ? h(
              'div',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              title
            )
          : h('div.fc-light-grey', [
              "The assignment doesn't have a title yet. Click on the ",
              h('i.fa.fa-pencil'),
              ' icon to add.',
            ]),
      ]),
    ]);
  }

  private accessibilityAttrs(): any {
    return { tabIndex: this.isAccessible ? 0 : undefined };
  }

  private instructions() {
    const { assignment } = this.getProps();

    const stylePublished: CSS = {
      display: 'border-box',
      marginLeft: '1em',
      marginRight: '1em',
      borderBottom: `1px solid ${colors.lighterGrey}`,
      paddingBottom: '0.5rem',
    };

    const styleUnpublished: CSS = { paddingBottom: '0.5rem' };

    const hasAttachments = assignment.details.attachments.length > 0;

    if (this.isEditEnabled()) {
      return h('div.panel', this.accessibilityAttrs(), [
        h('div.panel__header', this.accessibilityAttrs(), [
          h('span', 'Instructions'),
          h('i.fa.fa-pencil', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.openEditInstructionsDialog(),
          }),
        ]),
        h('div.panel__content', [
          assignment.details.instructions
            ? Viewer(assignment.details.instructions, {
                tabIndex: this.isAccessible ? 0 : undefined,
              })
            : h(
                'div.fc-light-grey',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                },
                [
                  "The assignment doesn't have instructions yet. Tap on the ",
                  h('i.fa.fa-pencil'),
                  ' to edit general instructions.',
                ]
              ),
          hasAttachments
            ? assignment.details.published
              ? this.attachments(stylePublished)
              : this.attachments(styleUnpublished)
            : null,
        ]),
        this.editInstructionsDialog(),
      ]);
    } else {
      return h('div', [
        h('div.activity__description', [
          assignment.details.instructions
            ? Viewer(assignment.details.instructions, {
                tabIndex: this.isAccessible ? 0 : undefined,
              })
            : h('div', { tabIndex: this.isAccessible ? 0 : undefined }, [
                'There are no special instructions.',
              ]),
        ]),
        hasAttachments ? this.attachments({ paddingBottom: '0.5rem' }) : null,
      ]);
    }
  }

  private editAssignmentInstructions() {
    const { instructions, instructionsAttachments } = this.getState().editAssignmentDialog;
    const styleUnpublished: CSS = { paddingBottom: '0.5rem' };
    const hasAttachments = instructionsAttachments.length > 0;
    return h('div.panel', this.accessibilityAttrs(), [
      h('div.panel__header', this.accessibilityAttrs(), [
        h('span', 'Instructions'),
        h('i.fa.fa-pencil', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => this.openEditInstructionsDialog(true),
        }),
      ]),
      h('div.panel__content', [
        instructions
          ? Viewer(instructions, {
              tabIndex: this.isAccessible ? 0 : undefined,
            })
          : h(
              'div.fc-light-grey',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              [
                "The assignment doesn't have instructions yet. Tap on the ",
                h('i.fa.fa-pencil'),
                ' to edit general instructions.',
              ]
            ),
        hasAttachments ? this.attachments(styleUnpublished, true, instructionsAttachments) : null,
      ]),
      this.editAssignmentEditInstructionsDialog(),
    ]);
  }

  private attachments(styles?: CSS, hideDownload?: boolean, attachments?: IAttachment[]) {
    if (attachments) {
      if (attachments.length === 0) {
        return null;
      }
      return h('div', { style: { ...styles } }, [
        this.headings('Attachments'),
        h('div.attachments', {}, [
          ...attachments.map((a) =>
            AttachmentViewer({
              attachment: a,
              style: {
                paddingRight: '0.5rem',
                marginTop: '0.5rem',
              },
              hideDownloadIcon: hideDownload ? hideDownload : undefined,
              downloadUrl: urls().assignmentDownQueAttachment,
              downloadRequest: {
                type: 'ia',
                assignmentId: this.getProps().assignment._id,
                fileName: a.name,
              },
            })
          ),
        ]),
      ]);
    }
    const { assignment } = this.getProps();
    if (assignment.details.attachments.length < 1) return null;
    return h('div', { style: { ...styles } }, [
      this.headings('Attachments'),
      h('div.attachments', {}, [
        ...assignment.details.attachments.map((a) =>
          AttachmentViewer({
            attachment: a,
            style: {
              paddingRight: '0.5rem',
              marginTop: '0.5rem',
            },
            hideDownloadIcon: hideDownload ? hideDownload : undefined,
            downloadUrl: urls().assignmentDownQueAttachment,
            downloadRequest: {
              type: 'ia',
              assignmentId: assignment._id,
              fileName: a.name,
            },
          })
        ),
      ]),
    ]);
    // return h("div.assignment-instructions-attachments",
    //     style([mt("0.5rem"), "borderBox", {
    //     backgroundColor: "white",
    //     width: "100%",
    //     ...styles
    // }]), assignment.details.attachments.map(a => AttachmentViewer({
    //     attachment: a,
    //     style: {
    //         padding: "0em",
    //         marginTop: "0.5rem"
    //     },
    //     downloadUrl: urls().assignmentDownQueAttachment,
    //     downloadRequest: {
    //         type: "ia",
    //         assignmentId: assignment._id,
    //         fileName: a.name
    //     }
    // })));
  }

  private loader() {
    if (this.getState().isLoading) {
      return Paper('.assignment__loader', {}, [Loader()]);
    } else {
      return null;
    }
  }

  private creator() {
    const { assignment } = this.getProps();
    const creator = assignment.details.createdBy;
    const status = assignment.details.published ? 'Published' : 'Created';
    const time = assignment.details.published
      ? assignment.details.publishedOn
      : assignment.details.createdOn;

    return h(
      'div.user',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        Avatar(creator.avatar, creator.name, {
          className: 'user__avatar',
        }),
        h('div.user__details.fc-light-grey', [
          h('div.user__title', `${status} by ${creator.name}`),
          h('div.user__subtitle', datetime.format(time, 'MMM DD, YYYY [at] hh:mm A')),
        ]),
      ]
    );
  }

  private prePublishWarnings() {
    const { assignment } = this.getProps();

    if (assignment.details.published) return null;
    return h('div.activity__section', { tabIndex: this.isAccessible ? 0 : undefined }, [
      this.warning([
        'The assignment must be published by the creator for ',
        'the students to be able to access it.',
      ]),
    ]);
  }

  private attemptButton() {
    if (!this.canAttemptOrRetract()) return null;
    if (this.getCourseRole() !== 'student') return null;
    const { assignment } = this.getProps();
    if (
      assignment.userData &&
      assignment.userData.studentUserData &&
      assignment.userData.studentUserData.graded
    )
      return null;
    let label = 'ATTEMPT ASSIGNMENT';
    const status = this.getSubmissionStatus();
    if (status === 'inProgress') {
      label = 'RESUME ASSIGNMENT';
    }
    if (status === 'submitted' || status === 'late') {
      label = 'RETRACT ASSIGNMENT';
    }
    return RaisedButton(label, {
      classNames: ['assignment__raised-button'],
      tabIndex: this.isAccessible ? 0 : undefined,
      onclick: ['late', 'submitted'].includes(status || '')
        ? () => this.toggleIsRetractDialogOpen()
        : () => this.attempt(),
    });
  }

  private canAttemptOrRetract() {
    const { assignment } = this.getProps();
    const role = this.getCourseRole();
    if (role !== 'student') return false;
    if (assignment.details.dueDateTime < datetime.unix() && !assignment.details.allowLate) {
      return false;
    }
    if (assignment.userData.studentUserData && assignment.userData.studentUserData.graded) {
      return false;
    }
    return true;
  }

  private lastFocusedElement: Element | null = null;
  private lastFocusedElementSpare: Element | null = null;
  private lastFocusedElementId = '';

  private attemptScreen() {
    const questions = this.questions();
    const { assignment } = this.getProps();
    const attemptUploadedFiles = this.getUploadedFilesFromStore();
    const submission = this.getSubmission();
    const headerStyle: CSS = {
      marginTop: '1em',
      marginBottom: '1em',
      color: '#7a7a7a',
      fontSize: '0.8em',
      fontWeight: '700',
      textTransform: 'uppercase',
    };
    const uploadButton = (q: IAssignmentQuestion, containerStyle?: any, styles?: any) =>
      UploadButton({
        ariaLabel: 'Upload a file Button',
        containerStyle: containerStyle,
        accept: q.details.submitExt
          .map((x) => (x === 'jpg' || x === 'jpeg' ? '.jpg,.jpeg' : `.${x.toLowerCase()}`))
          .join(','),
        view: h(
          'div.ripple',
          style(
            [
              'whiteBackground',
              'flex',
              'alignCenter',
              'large',
              'spaceBetween',
              pad('0.5rem 1rem'),
              'pointer',
              { ...styles },
            ],
            {},
            {
              onclick: () => {
                this.lastFocusedElement = document.activeElement;
                // u.unsetTabIndices();
              },
              tabIndex: this.isAccessible ? 0 : undefined,
              'aria-label': 'Upload a file',
            }
          ),
          [h('span', 'Upload a file'), h('i.fa.fa-upload', [])]
        ),
        upload: (file) => this.uploadSubmission(q, file),
        oncomplete: () => {
          u.resetTabIndices();
          (document.getElementById('uploaded-files') as HTMLElement).focus();
        },
      });
    const renderQuestion = (q: IAssignmentQuestion, i: number, styles: CSS = {}) => {
      const questionSub = submission ? submission[q._id] : null;
      return h(
        'div.assignment-question',
        style(
          [
            mb('1rem'),
            ml('1rem'),
            mr('1rem'),
            {
              ...styles,
            },
          ],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            key: q._id,
          }
        ),
        [
          h(
            'div.assignment-question-header',
            style(
              [pad('0.5rem 0.5rem'), 'flex', 'spaceBetween', 'thick'],
              {},
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              }
            ),
            [
              h('span', `QUESTION ${i + 1} of ${questions.length}`),
              h('span', `Max marks: ${q.details.marks}`),
            ]
          ),
          h('div.assignment-question-body', style([pad('0.5em 0.5em'), 'flex', 'column']), [
            Viewer(q.details.description.text, {
              tabIndex: this.isAccessible ? 0 : undefined,
            }),
            q.details.attachments.length > 0
              ? h('div', {}, [
                  h('div', { style: { ...headerStyle } }, 'Attachments'),
                  h(
                    'div.attachments',
                    {},
                    q.details.attachments.map((file) =>
                      AttachmentViewer({
                        attachment: file,
                        style: {
                          padding: '0em',
                          marginTop: '0.5rem',
                        },
                        downloadUrl: urls().assignmentDownQueAttachment,
                        downloadRequest: {
                          type: 'qa',
                          questionId: q._id,
                          assignmentId: assignment._id,
                          fileName: file.name,
                        },
                      })
                    )
                  ),
                ])
              : null,
          ]),
          h(
            'div',
            style(
              [headerStyle, { paddingLeft: '0.5rem', paddingRight: '0.5rem' }],
              {},
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              }
            ),
            'Submission'
          ),
          // h("div", style([
          //     "small", "mediumGrey", "thick", "flex", "spaceBetween", pad("0.5rem 1rem")
          // ]), [
          //     "SUBMISSION"
          // ]),
          q.details.submission === 'url'
            ? h('div.url', style([ml('0.5rem'), mr('0.5rem')]), [
                h(
                  'div.url-submission-editor',
                  style(
                    [
                      'whiteBackground',
                      'large',
                      'spaceBetween',
                      'flex',
                      pad('0.5rem 1rem'),
                      'alignCenter',
                    ],
                    {},
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                      onclick: () => {
                        this.lastFocusedElement = document.activeElement;
                        u.unsetTabIndices();
                        this.setState({
                          isEditingSubmissionUrlFor: q._id,
                          submissionUrlField:
                            questionSub && questionSub.url ? questionSub.url.url || '' : '',
                        });
                      },
                    }
                  ),
                  [
                    h('span', style([mr('0.5rem')]), 'URL'),
                    h('i.fa.fa-pencil', style(['lightGrey']), []),
                  ]
                ),
                questionSub && questionSub.url
                  ? h(
                      'div',
                      {
                        style: {
                          padding: '0.5rem 1rem',
                          backgroundColor: 'white',
                        },
                      },
                      [
                        h(
                          'a',
                          {
                            target: '_blank',
                            href: u.convertLinkToURL(questionSub.url.url),
                            style: { wordBreak: 'break-all' },
                          },
                          questionSub.url.url
                        ),
                      ]
                    )
                  : null,
              ])
            : h('div', { style: { paddingLeft: '0.5rem' } }, [
                uploadButton(q),

                attemptUploadedFiles[q._id]
                  ? h(
                      'div#uploaded-files',
                      {
                        tabIndex: this.isAccessible ? 0 : undefined,
                      },
                      [
                        h('div', { style: { ...headerStyle } }, 'you uploaded'),
                        AttachmentViewer({
                          attachment: attemptUploadedFiles[q._id]!,
                          downloadRequest: {
                            assignmentId: assignment._id,
                            questionId: q._id,
                            fileName: attemptUploadedFiles[q._id]!.name,
                          },
                          downloadUrl: urls().assignmentDownOwnSub,
                          style: {
                            alignSelf: 'center',
                          },
                        }),
                      ]
                    )
                  : null,

                h(
                  'div',
                  style(
                    [pad('0.5rem 1rem'), 'small', 'lightGrey'],
                    {},
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                    }
                  ),
                  ['You can upload ONE file in any of the following formats']
                ),

                h(
                  'div',
                  style(
                    [
                      'flex',
                      'small',
                      'lightGrey',
                      mt('0.5rem'),
                      pad('0.5rem 1rem'),
                      {
                        flexWrap: 'wrap',
                      },
                    ],
                    {},
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                    }
                  ),
                  q.details.submitExt.map((exn) =>
                    h('div', style([pad('0.5em 1em')], {}, { key: exn }), exn.toUpperCase())
                  )
                ),
              ]),
          this.wrongExtensionAlert(),
        ]
      );
    };

    const isMobile = getStore().getState().app.isMobile;
    if (isMobile) {
      const { attemptQuestionIndex } = this.getState();
      return Dialog(
        {
          open: this.getState().isAttempting,
          title: `Assignment ${assignment.details.num}`,
          secondaryAction: {
            id: 'assignment-save-button',
            label: 'BACK',
            mobileLabel: h('i.fa.fa-arrow-left', []),
            onclick: () =>
              this.setState({
                isAttempting: false,
              }),
          },
          thinHeader: true,
          bodyStyle: {
            height: '100%',
            position: 'relative',
          },
          style: {
            backgroundColor: colors.backgroundColor,
          },
          primaryAction: {
            id: 'assignment-submit-button-mobile',
            label: 'SUBMIT',
            mobileLabel: h('i.fa.fa-check', []),
            onclick: () => this.openSubmissionDialog(),
            disabled: this.getNumAttempted() < 1,
          },
        },
        [
          this.mobileAttemptHeader(),
          questions.length === 0
            ? fullScreenLoader
            : renderQuestion(questions[attemptQuestionIndex], attemptQuestionIndex, {
                marginTop: '4.5em',
                marginBottom: '3em',
              }),
          this.questionsHaveBeenEditedByInstructorDialog(),
          this.submitUrlEditDialog(),
          this.submitDialog(),
          this.mobileAttemptFooter(),

          questions.length !== 0
            ? TipOverlayWrapper({
                targetElement: 'assignment-submit-button-mobile',
                tip: {
                  tipPosition: 'bottom',
                  tipText:
                    'Whenever you’re satisfied with your submissions, you can' +
                    ' use this button to Submit the assignment for grading.' +
                    ' Please be careful of not missing the deadline',
                },
                tipKey: 'assignmentAttemptSubmit',
                isNextAvailable: false,
              })
            : null,
        ]
      );
    }

    if (!this.getState().isAttempting) return null;
    return h(
      'div',
      style([
        {
          zIndex: '1',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          backgroundColor,
        },
      ]),
      [
        questions.length === 0
          ? fullScreenLoader
          : ContentView(
              h(
                'div',
                questions.map((q, i) => renderQuestion(q, i, {}))
              )
            ),
        this.getNumAttempted() > 0
          ? FloatingActionButton(
              {
                id: 'assignment-submit-button',
                position: 'bottom-right',
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () => this.openSubmissionDialog(),
              },
              [h('i.fa.fa-check', {})]
            )
          : null,

        this.questionsHaveBeenEditedByInstructorDialog(),
        this.submitUrlEditDialog(),
        this.submitDialog(),
        this.getNumAttempted() > 0
          ? TipOverlayWrapper({
              targetElement: 'assignment-submit-button',
              tip: {
                tipPosition: 'left',
                tipText:
                  'Whenever you’re satisfied with your submissions, you can' +
                  ' use this button to Submit the assignment for grading.' +
                  ' Please be careful of not missing the deadline',
              },
              tipKey: 'assignmentAttemptSubmit',
              isNextAvailable: false,
            })
          : null,
      ]
    );
  }

  private submitUrlEditDialog() {
    const state = this.getState();
    return Alert(
      {
        open: state.isEditingSubmissionUrlFor !== null,
        center: true,
        hasInput: true,
        style: {
          width: '25em',
        },
        actions: [
          FlatButton('Cancel', {
            type: 'secondary',
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              this.setState({
                isEditingSubmissionUrlFor: null,
              });
            },
          }),
          FlatButton('Save', {
            disabled: !this.isURLValid(),
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              this.saveSubmissionUrl(state.isEditingSubmissionUrlFor);
            },
          }),
        ],
      },
      state.isEditingSubmissionUrlFor
        ? [
            TextField({
              value: state.submissionUrlField,
              noHintOrError: true,
              placeholder: 'Enter or paste URL here',
              oninput: (e) =>
                this.setState({
                  submissionUrlField: e.target.value,
                }),
              onenter: this.isURLValid()
                ? () => this.saveSubmissionUrl(state.isEditingSubmissionUrlFor)
                : () => {},
            }),
            // h("div", style(["small", "lightGrey", pad("0.5rem 1rem")]), [
            //     "Please provide a url beginning with https:// or http://"
            // ])
          ]
        : []
    );
  }

  private questionsHaveBeenEditedByInstructorDialog() {
    const areQuestionsEdited = getStore().getState().assignment.areQuestionsEdited;
    return Alert(
      {
        open: Boolean(areQuestionsEdited),
        center: true,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('Ok', {
            onclick: () => {
              dispatch(Actions.leaveAssignmentPage(undefined));
              setTimeout(() => this.setState({ isLoading: false, isAttempting: false }));
            },
          }),
        ],
      },
      ['This assignment has been updated. Click Okay to load the changes.']
    );
  }

  private isURLValid() {
    const state = this.getState();
    let urlField = state.submissionUrlField.trim();
    const isURLValid = urlField.startsWith('http://') || urlField.startsWith('https://');
    if (!isURLValid) {
      urlField = `http://${urlField}`;
    }
    const urlRegex =
      // eslint-disable-next-line no-useless-escape
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[^/?%.\s]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const isValid = urlRegex.test(urlField);

    return isValid;
  }

  private async saveSubmissionUrl(questionId: string | null) {
    if (!questionId) return;
    const props = this.getProps();
    const state = this.getState();
    await dispatch(
      Actions.submissionURLSave({
        activityId: props.assignment._id,
        activityType: 'assignments',
        url: state.submissionUrlField,
        questionId: questionId,
      })
    );
    await this.setState({
      isEditingSubmissionUrlFor: null,
    });
  }

  private mobileAttemptFooter() {
    const questionIndex = this.getState().attemptQuestionIndex;
    const questionLength = this.questions().length;
    if (questionLength < 2) return null;
    return h('div', [
      Paper(`#assignment-attempt-navigation.assignment__attempt.raised-button`, {}, [
        this.questions().length > 1
          ? h(
              'div.previous',
              style(
                ['medium'],
                {
                  visibility: questionIndex === 0 ? 'hidden' : undefined,
                },
                {
                  onclick: () =>
                    this.setState({
                      attemptQuestionIndex: Math.max(this.getState().attemptQuestionIndex - 1, 0),
                    }),
                }
              ),
              [
                h(
                  'i.fa.fa-chevron-left',
                  style(
                    [
                      'lightGrey',
                      'pointer',
                      {
                        marginRight: 'auto',
                        paddingRight: '1em',
                        paddingLeft: '1em',
                        color: colors.blue,
                      },
                    ],
                    {}
                  )
                ),
                h('span', style(['blue']), 'Previous'),
              ]
            )
          : null,
        questionLength > 1
          ? h(
              'div.next',
              style(
                ['medium', ml('auto')],
                {
                  visibility: questionIndex === questionLength - 1 ? 'hidden' : undefined,
                },
                {
                  onclick: () =>
                    this.setState({
                      attemptQuestionIndex: Math.min(
                        this.getState().attemptQuestionIndex + 1,
                        this.questions().length - 1
                      ),
                    }),
                }
              ),
              [
                h('span', style(['blue']), 'Next'),
                h(
                  'i.fa.fa-chevron-right',
                  style(
                    ['lightGrey', 'pointer'],
                    {
                      marginLeft: 'auto',
                      paddingLeft: '1em',
                      paddingRight: '1em',
                      color: colors.blue,
                    },
                    {}
                  )
                ),
              ]
            )
          : null,
      ]),
      this.questions().length > 1
        ? TipOverlayWrapper({
            targetElement: 'assignment-attempt-navigation',
            tip: {
              tipPosition: 'top',
              tipText:
                'To move between the questions, you can use the ' +
                'Previous, Next buttons or the question number' +
                ' scroll at the top',
            },
            tipKey: 'assignmentAttemptNavigation',
            isNextAvailable: true,
            // isSpecial: true
          })
        : null,
      RaisedButton('', {
        classNames: ['assignment__raised-button', 'invisible'],
      }), // hidden button for padding
    ]);
  }

  private mobileAttemptHeader() {
    return Paper('.assignment__attempt-header', {}, [
      h(
        'div',
        style([
          'fullWidth',
          {
            overflowX: 'hidden',
          },
        ]),
        [
          h(
            'div',
            style([
              'flex',
              {
                marginLeft: 'calc(50% - 1em)',
                transform: `translateX(-${this.getState().attemptQuestionIndex * 2.5}em)`,
                transition: 'transform 0.3s',
              },
            ]),
            Array.from(u.range(1, this.questions().length + 1)).map((i) =>
              h(
                'span',
                style(
                  [
                    'flex',
                    'alignCenter',
                    'justifyCenter',
                    {
                      backgroundColor:
                        this.getState().attemptQuestionIndex + 1 === i
                          ? colors.blue
                          : colors.lightGrey,
                      width: '2em',
                      height: '2em',
                      marginRight: '0.5em',
                      color: 'white',
                      flexShrink: 0,
                      borderRadius: '100%',
                      cursor: 'pointer',
                    },
                  ],
                  {},
                  {
                    onclick: () =>
                      this.setState({
                        attemptQuestionIndex: i - 1,
                      }),
                  }
                ),
                i.toString()
              )
            )
          ),
        ]
      ),
    ]);
  }

  private async openSubmissionDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isAttemptSubmissionDialogOpen: true,
    });
  }

  private getNumAttempted() {
    const questions = this.questions();
    const submission = this.getSubmission();
    const uploadedFiles = this.getUploadedFilesFromStore();
    let numAttempted = 0;
    for (const q of questions) {
      if (q.details.submission === 'file') {
        if (uploadedFiles[q._id]) {
          numAttempted++;
        }
      } else if (q.details.submission === 'url') {
        if (submission && submission[q._id] && submission[q._id]!.url) {
          numAttempted++;
        }
      }
    }
    return numAttempted;
  }

  private submitDialog() {
    const numAttempted = this.getNumAttempted();
    const numTotal = this.questions().length;
    const submission = this.getSubmission();
    return Alert(
      {
        open: this.getState().isAttemptSubmissionDialogOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGreen,
        },
        style: {
          width: '20em',
        },
        bodyStyle: {
          paddingBottom: '0',
        },
        actions: [
          FlatButton('Cancel', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              this.setState({
                isAttemptSubmissionDialogOpen: false,
              });
            },
          }),
          FlatButton('Submit', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              return this.submit();
            },
          }),
        ],
      },
      [
        h(
          'div',
          { tabIndex: this.isAccessible ? 0 : undefined },
          'You can retract your submission till it is graded.'
        ),
        h(
          'div',
          style(
            [mt('0.5em')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          `You have attempted ${numAttempted} of ${numTotal} questions.`
        ),

        ...this.questions().map((q, i) => {
          const questionSub = submission && submission[q._id];
          const isAttempted = questionSub && (questionSub.file || questionSub.url);
          return h(
            'div',
            style(
              ['flex', 'spaceBetween', mt('0.5rem'), pr('1rem')],
              {},
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              }
            ),
            [
              h('span', `Question ${i + 1}`),
              isAttempted
                ? h('span', style(['green']), 'Attempted')
                : h('span', style(['red']), 'Not attempted'),
            ]
          );
        }),
      ]
    );
  }

  private async submit() {
    const { assignment } = this.getProps();
    if (
      !validateActivitySubmit({
        allowLate: assignment.details.allowLate,
        dueDateTime: assignment.details.dueDateTime,
        serverUnix: datetime.unix(),
      })
    ) {
      await this.setState({
        isAttemptSubmissionDialogOpen: false,
        isAttempting: false,
      });
      return;
    } else {
      await dispatch(
        Actions.submit({
          assignmentId: assignment._id,
          localTime: datetime.format(datetime.now(), 'YYYYMMDDTHH:mm'),
        })
      );
      googleAnalytics.activitySubmission('assignment', 'course');
      await this.setState({
        isAttemptSubmissionDialogOpen: false,
        isAttempting: false,
      });
    }
  }

  // private uploadClickHandler(q: IAssignmentQuestion, file: File) {
  //     const { extension } = u.splitFileName(file.name);
  //     const acceptedExtensions = q.details.submitExt;
  //     const isAcceptable = acceptedExtensions.filter( a => a === extension).length > 0
  //                                                     ? true
  //                                                     : false;
  //     if (isAcceptable) {
  //         this.uploadSubmission(q, file)
  //     }
  //     else {
  //         this.openAlert
  //     }

  // }

  private uploadSubmission(q: IAssignmentQuestion, file: File) {
    const { assignment } = this.getProps();
    const { extension } = u.splitFileName(file.name);
    const acceptedExtensions = q.details.submitExt;
    const isAcceptable =
      acceptedExtensions.filter((a) => a === extension.toLowerCase()).length > 0 ? true : false;
    if (isAcceptable) {
      const progress$ = dispatch(
        Actions.submissionUpload({
          assignmentId: assignment._id,
          questionId: q._id,
          file: file,
        })
      );
      return { progress$ };
    } else {
      this.openAlert();
      return;
    }
  }

  private async openAlert() {
    await this.setState({
      isWrongExtensionAlertOpen: true,
    });
  }

  private wrongExtensionAlert() {
    const isOpen = this.getState().isWrongExtensionAlertOpen;
    return Alert(
      {
        open: isOpen,
        title: h('span', style(['red']), 'Wrong Extension'),
        actions: [
          FlatButton('OKAY', {
            onclick: () =>
              this.setState({
                isWrongExtensionAlertOpen: false,
              }),
          }),
        ],
      },
      [h('div', 'Please upload a file according to the acceptable formats')]
    );
  }

  private submissionStatusBar() {
    const { assignment } = this.getProps();
    const role = this.getCourseRole();
    if (role !== 'student') {
      return null;
    }

    if (
      !this.isSubmitted() &&
      !assignment.details.allowLate &&
      assignment.details.dueDateTime < datetime.unix()
    ) {
      return BottomFloatingBar({
        text: 'Unsubmitted. You missed the deadline.',
        color: colors.red,
      });
    } else {
      return null;
    }
  }

  private async attempt() {
    const { assignment } = this.getProps();
    // const serverUnix = getStore().getState().app.timeDiff + datetime.unix();

    if (
      !validateActivitySubmit({
        allowLate: assignment.details.allowLate,
        dueDateTime: assignment.details.dueDateTime,
        serverUnix: datetime.unix(),
      })
    ) {
      return;
    }
    await this.setState({
      isAttempting: true,
      attemptQuestionIndex: 0,
      isLoading: true,
    });
    await dispatch(
      Actions.fetchAssignmentData(assignment, assignment.userData.studentUserData ? false : true)
    );
    await this.setState({
      isLoading: false,
    });
  }

  private getUploadedFilesFromStore() {
    const submission = this.getSubmission();
    return submission
      ? u.mapValues(submission, (s) =>
          s.file
            ? {
                name: s.file.name,
                originalName: s.file.originalName,
                extension: s.file.extension,
                prevSub: s.prevSub ? s.prevSub.filter((prev) => prev.file !== null) : undefined,
              }
            : undefined
        )
      : {};
  }

  private getSubmission() {
    return getStore().getState().assignment.submission;
  }

  private async toggleIsRetractDialogOpen() {
    const isOpen = this.getState().isRetractDialogOpen;
    if (isOpen) {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    } else {
      this.lastFocusedElement = document.activeElement;
      u.unsetTabIndices();
    }
    await this.setState({
      isRetractDialogOpen: !isOpen,
    });
  }

  private retractConfirmationDialog() {
    const isOpen = this.getState().isRetractDialogOpen;
    return Alert(
      {
        open: isOpen,
        title: 'Retracting Submission',
        style: {
          width: '25em',
        },
        actions: [
          FlatButton('CANCEL', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.toggleIsRetractDialogOpen(),
          }),
          FlatButton('RETRACT', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.yesClickHandler(),
          }),
        ],
      },
      [
        h(
          'div',
          {
            style: {
              margin: '3px',
            },
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h(
              'div',
              style([]),
              'Once you retract your submission, the professor and course ' +
                'team members will no longer see it.'
            ),
            h(
              'div',
              style(['thick', 'black']),
              'If the deadline has passed, please ' + 'check if late submissions are allowed.'
            ),
            h('div', {}, 'Are you sure you want to retract this submission?'),
          ]
        ),
      ]
    );
  }

  private async yesClickHandler() {
    await this.retract();
    await this.toggleIsRetractDialogOpen();
  }

  private async retract() {
    await dispatch(Actions.retract(this.getProps().assignment._id));
  }

  private getSubmissionStatus() {
    const { assignment } = this.getProps();
    return (
      (assignment.userData.studentUserData && assignment.userData.studentUserData.status) || null
    );
  }

  private getCourseRole() {
    return courseService.getRole();
  }

  private questions() {
    return getStore().getState().assignment.currentAssignmentQuestions || [];
  }
  private editQuestionHandler(question: IAssignmentQuestion, index: number) {
    this.lastFocusedElementId = `assignment-pencil-${index + 1}`;
    u.unsetTabIndices();
    this.setState({
      editingQuestionId: question._id,
      isEditQuestionDialogVisible: true,
      editingQuestionDescriptionField: question.details.description.text,
      editingQuestionAttachmentsField: question.details.attachments,
      editingQuestionFormatsField:
        question.details.submission === 'file'
          ? question.details.submitExt.reduce((a, b) => a + ', ' + b)
          : '',
      editingQuestionMarksField: question.details.marks.toString(),
      editingQuestionDescriptionError: false,
      editingQuestionSubmission: question.details.submission,
      editingQuestionSubmissionField: question.details.submission,
      editingQuestionMarksError: false,
      editingQuestionFormatsError: false,
    });
  }

  private editAssignmentEditQuestionHandler(question: IAssignmentQuestion, index: number) {
    this.lastFocusedElementId = `assignment-pencil-${index + 1}`;
    u.unsetTabIndices();
    this.setState({
      editAssignmentDialog: {
        ...this.getState().editAssignmentDialog,
        editingQuestionId: question._id,
        isEditQuestionDialogVisible: true,
        editingQuestionDescriptionField: question.details.description.text,
        editingQuestionAttachmentsField: question.details.attachments,
        editingQuestionFormatsField:
          question.details.submission === 'file'
            ? question.details.submitExt.reduce((a, b) => a + ', ' + b)
            : '',
        editingQuestionMarksField: question.details.marks.toString(),
        editingQuestionDescriptionError: false,
        editingQuestionSubmission: question.details.submission,
        editingQuestionSubmissionField: question.details.submission,
        editingQuestionMarksError: false,
        editingQuestionFormatsError: false,
      },
    });
  }

  private questionsView() {
    const questions = this.questions();
    const role = this.getCourseRole();
    const { assignment } = this.getProps();
    const submission = getStore().getState().assignment.submission;
    const extensionIcon = (ext: string) =>
      h(
        'span',
        style(
          [pad('0.5em 1em'), 'x-small', 'blue', 'bold'],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          }
        ),
        [ext.toUpperCase()]
      );
    if (!this.canSeeQuestions() || this.getState().isLoading) {
      return null;
    }
    return h('div.assignment__questions', [
      h(
        'div.activity__subtitle',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        'QUESTIONS'
      ),
      questions.length === 0
        ? h(
            'div.assignment__questions__no-questions',
            {
              'aria-label': `No questions added yet.
                    ${this.isEditEnabled() ? 'Tap on the Plus Icon to add a question ' : ''}`,
              tabIndex: this.isAccessible ? 0 : undefined,
            },
            [
              'No questions added yet. ',
              ...(this.isEditEnabled()
                ? ['Tap on the ', Icon(icons.plus, {}), ' icon to add a question.']
                : []),
            ]
          )
        : null,
      ...questions.map((question, index) =>
        h(
          'div.panel',
          {
            key: question._id,
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h(
              'div.panel__header',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              [
                h('span', `Question ${index + 1} (Max marks: ${question.details.marks})`),

                this.isEditEnabled()
                  ? h(`i.fa.fa-pencil#assignment-pencil-${index + 1}`, {
                      tabIndex: this.isAccessible ? 0 : undefined,
                      onclick: () => this.editQuestionHandler(question, index),
                    })
                  : null,

                role === 'student' &&
                submission &&
                submission[question._id] &&
                assignment.userData &&
                assignment.userData.studentUserData &&
                assignment.userData.studentUserData.graded
                  ? h(
                      'div.assignment__question__award',
                      {
                        tabIndex: this.isAccessible ? 0 : undefined,
                        'aria-label':
                          ` marks awarded:
                        ${submission[question._id]!.marks.toString()}` +
                          `up on ${question.details.marks}`,
                      },
                      [
                        h(
                          'span',
                          `${submission[question._id]!.marks.toString()}` +
                            `/${question.details.marks}`
                        ),
                        submission[question._id]!.comments.content.length > 0
                          ? Icon(icons.comments, {
                              className: 'assignment__question__comment',
                              onclick: () => this.onClickHandler(question),
                            })
                          : null,
                      ]
                    )
                  : role === 'student'
                  ? h('span.fc-red', style(['red']), 'UNGRADED')
                  : null,
              ]
            ),
            h('div.panel__content', [
              Viewer(question.details.description.text, {
                tabIndex: this.isAccessible ? 0 : undefined,
              }),
              question.details.attachments.length > 0
                ? h('div', { tabIndex: this.isAccessible ? 0 : undefined }, [
                    this.headings('Attachments'),
                    h(
                      'div.attachments',
                      {},
                      question.details.attachments.map((file) =>
                        AttachmentViewer({
                          attachment: file,
                          style: {
                            // paddingRight: "0.5rem",
                            marginTop: '0.5rem',
                          },
                          downloadUrl: urls().assignmentDownQueAttachment,
                          downloadRequest: {
                            type: 'qa',
                            questionId: question._id,
                            assignmentId: assignment._id,
                            fileName: file.name,
                          },
                        })
                      )
                    ),
                  ])
                : null,

              this.isSubmitted() ? this.questionCardSubmission(question) : null,
              !this.isSubmitted()
                ? h('div', [
                    h(
                      'div.assignment__question__format-title',
                      {
                        tabIndex: this.isAccessible ? 0 : undefined,
                      },
                      'Acceptable Submission Formats'
                    ),
                    h(
                      'div.assignment__question__formats',
                      question.details.submission === 'file'
                        ? question.details.submitExt.map(extensionIcon)
                        : ['URL'].map(extensionIcon)
                    ),
                  ])
                : null,
            ]),
          ]
        )
      ),

      this.feedbackCommentDialog(),
    ]);
  }

  private warning(text: string | View[]) {
    return h('div.activity__note', { tabIndex: this.isAccessible ? 0 : undefined }, [
      h('i.fa.fa-exclamation-triangle'),
      h('div', text),
    ]);
  }

  private editAssignmentQuestionsView() {
    const questions = this.getState().editAssignmentDialog.questions;
    const role = this.getCourseRole();
    const { assignment } = this.getProps();
    const submission = getStore().getState().assignment.submission;
    const extensionIcon = (ext: string) =>
      h(
        'span',
        style(
          [pad('0.5em 1em'), 'x-small', 'blue', 'bold'],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          }
        ),
        [ext.toUpperCase()]
      );
    if (!this.canSeeQuestions() || this.getState().isLoading) {
      return null;
    }
    if (assignment.stats && assignment.stats.numSubmitted) {
      return h('div.activity__section', { tabIndex: this.isAccessible ? 0 : undefined }, [
        this.warning([
          'You cannot edit assignment questions after',
          ' some students have made submissions',
        ]),
      ]);
    }
    return h('div.assignment__questions', [
      h(
        'div.activity__subtitle',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        'QUESTIONS'
      ),
      questions.length === 0
        ? h(
            'div.assignment__questions__no-questions',
            {
              'aria-label': `No questions added yet.
                ${this.isEditEnabled() ? 'Tap on the Plus Icon to add a question ' : ''}`,
              tabIndex: this.isAccessible ? 0 : undefined,
            },
            [
              'No questions added yet.',
              ...(this.isEditEnabled()
                ? ['Tap on the ', h('i.fa.fa-plus', []), ' icon to add a question.']
                : []),
            ]
          )
        : null,
      ...questions.map((question, index) =>
        h(
          'div.panel',
          {
            key: question._id,
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h(
              '.panel__header',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              [
                `Question ${index + 1} (Max marks: ${question.details.marks})`,
                h(`i.fa.fa-pencil#assignment-pencil-${index + 1}`, {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  onclick: () => this.editAssignmentEditQuestionHandler(question, index),
                }),

                role === 'student' &&
                submission &&
                submission[question._id] &&
                assignment.userData &&
                assignment.userData.studentUserData &&
                assignment.userData.studentUserData.graded
                  ? h(
                      'div.assignment__question__award',
                      {
                        tabIndex: this.isAccessible ? 0 : undefined,
                        'aria-label': ` marks awarded: ${submission[question._id]!.marks.toString()}
                            up on ${question.details.marks}`,
                      },
                      [
                        h(
                          'span',
                          `${submission[question._id]!.marks.toString()}` +
                            `/${question.details.marks}`
                        ),
                        submission[question._id]!.comments.content.length > 0
                          ? Icon(icons.comments, {
                              className: 'assignment__question__comment',
                              onclick: () => this.onClickHandler(question),
                            })
                          : null,
                      ]
                    )
                  : role === 'student'
                  ? h('span.fc-red', 'UNGRADED')
                  : null,
              ]
            ),
            h('div.panel__content', {}, [
              Viewer(question.details.description.text, {
                tabIndex: this.isAccessible ? 0 : undefined,
              }),
              question.details.attachments.length > 0
                ? h('div', { tabIndex: this.isAccessible ? 0 : undefined }, [
                    this.headings('Attachments'),
                    h(
                      'div.attachments',
                      {},
                      question.details.attachments.map((file) =>
                        AttachmentViewer({
                          attachment: file,
                          style: {
                            // paddingRight: "0.5rem",
                            marginTop: '0.5rem',
                          },
                          downloadUrl: urls().assignmentDownQueAttachment,
                          downloadRequest: {
                            type: 'qa',
                            questionId: question._id,
                            assignmentId: assignment._id,
                            fileName: file.name,
                          },
                        })
                      )
                    ),
                  ])
                : null,

              this.isSubmitted() ? this.questionCardSubmission(question) : null,
              !this.isSubmitted()
                ? h('div', [
                    h(
                      'div.assignment__question__format-title',
                      {
                        tabIndex: this.isAccessible ? 0 : undefined,
                      },
                      'Acceptable Submission Formats'
                    ),
                    h(
                      'div.assignment__question__formats',
                      question.details.submission === 'file'
                        ? question.details.submitExt.map(extensionIcon)
                        : ['URL'].map(extensionIcon)
                    ),
                  ])
                : null,
            ]),
          ]
        )
      ),
      RaisedButton('Add New Question', {
        tabIndex: this.isAccessible ? 0 : undefined,
        classNames: ['assignment__raised-button'],
        onclick: () => this.addQuestionHandler(true),
      }),
    ]);
  }

  private async onClickHandler(question?: IAssignmentQuestion) {
    const currentState = this.getState().isFeedbackCommentDialogOpen;
    const questionId = question ? question._id : null;
    await this.setState({
      isFeedbackCommentDialogOpen: !currentState,
      feedbackCommentDialogOpenFor: questionId,
    });
  }

  private feedbackCommentDialog(): View {
    const submission = getStore().getState().assignment.submission;
    const questionId = this.getState().feedbackCommentDialogOpenFor;
    if (!submission) {
      return null;
    }
    if (questionId === null) {
      return null;
    }
    const comment = submission[questionId]!.comments;
    const graderAvatar = submission[questionId]!.comments.author.avatar;
    const graderName = submission[questionId]!.comments.author.name;

    const avatar: string | { props: any; state: any; key?: string | undefined } | null = Avatar(
      graderAvatar,
      graderName,
      { className: 'assignment__avatar--lg' }
    );

    return Alert(
      {
        open: this.getState().isFeedbackCommentDialogOpen,
        style: {
          width: '25em',
        },
        actions: [
          FlatButton('Ok, got it!', {
            onclick: () => this.onClickHandler(),
          }),
        ],
      },
      [
        h('div', style(['flex', 'column']), [
          h(
            'span',
            style(['flex', pad('0.5rem 0.5rem 0.5rem 0rem')]),
            `Marks Awarded: ${submission[questionId]!.marks.toString()}`
          ),
          h(
            'div',
            style(
              ['flex', mb('0.3em')],
              {},
              {
                key: questionId,
              }
            ),
            [
              avatar,

              h('div', style(['flex', 'column']), [
                h('div.name', style(['green', pb('0.5rem')]), comment.author.name),
                h(
                  'div',
                  {
                    style: {
                      whiteSpace: 'pre-line',
                    },
                  },
                  comment.content
                ),
              ]),
            ]
          ),
        ]),
      ]
    );
  }

  private headings(label: string) {
    return h(
      'div',
      style([
        'mediumGrey',
        'thick',
        'x-small',
        {
          marginBottom: '0.57rem',
          marginTop: '0.5rem',
          textTransform: 'uppercase',
        },
      ]),
      label
    );
  }

  private questionCardSubmission(question: IAssignmentQuestion) {
    const { assignment } = this.getProps();
    const uploadedFiles = this.getUploadedFilesFromStore();
    const questionFiles = uploadedFiles[question._id];
    const submission = this.getSubmission();
    const questionSub = submission ? submission[question._id] : null;
    return h('div', [
      h(
        'div',
        style(
          ['small', 'lightGrey', mt('0.5rem')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          }
        ),
        'SUBMISSION'
      ),
      question.details.submission === 'file' && uploadedFiles[question._id]
        ? h('div', [
            AttachmentViewer({
              attachment: uploadedFiles[question._id]!,
              downloadUrl: urls().assignmentDownOwnSub,
              downloadRequest: {
                assignmentId: assignment._id,
                questionId: question._id,
                fileName: uploadedFiles[question._id]!.name,
              },
              style: {
                marginTop: '0.5em',
              },
            }),
            questionFiles && questionFiles.prevSub && questionFiles.prevSub.length > 0
              ? h('div.retracted-submissions', [
                  h(
                    'div',
                    style(['small', 'uppercase', 'lightGrey', mt('0.5em')]),
                    'Retracted submissions'
                  ),
                  ...questionFiles.prevSub.map((sub) =>
                    sub.file
                      ? h('div', [
                          AttachmentViewer({
                            attachment: sub.file,
                            downloadUrl: urls().assignmentDownOwnSub,
                            downloadRequest: {
                              assignmentId: assignment._id,
                              questionId: question._id,
                              fileName: uploadedFiles[question._id]!.name,
                            },
                            style: {
                              marginTop: '0.5em',
                            },
                          }),
                        ])
                      : null
                  ),
                ])
              : null,
          ])
        : null,

      question.details.submission === 'url' && questionSub && questionSub.url
        ? h(
            'a.link',
            {
              style: style([
                pad('0.5rem 0rem'),
                margin('0rem 0.5rem'),
                'flex',
                {
                  textDecoration: 'none',
                },
              ]).style,
              href: u.convertLinkToURL(questionSub.url.url),
              target: '_blank',
            },
            [
              h('div.link-info', [
                h(
                  'div',
                  style(['blue', 'large'], {
                    wordBreak: 'break-all',
                  }),
                  questionSub.url.url
                ),
              ]),
            ]
          )
        : // ? h("div", style([mt("0.5rem")]), [questionSub.url.url])
          null,

      questionSub &&
      question.details.submission === 'url' &&
      questionSub.prevSub &&
      questionSub.prevSub.length > 0
        ? h('div.retracted-submissions', [
            h(
              'div',
              style(['small', 'uppercase', 'lightGrey', mt('0.5em')]),
              'Retracted submissions'
            ),
            ...questionSub.prevSub.map((sub) =>
              sub.url
                ? h(
                    'a.link',
                    {
                      style: style([
                        pad('0.5rem 0rem'),
                        margin('0rem 0.5rem'),
                        'flex',
                        {
                          textDecoration: 'none',
                        },
                      ]).style,
                      href: u.convertLinkToURL(sub.url),
                      target: '_blank',
                    },
                    [h('div.link-info', [h('div', style(['blue', 'large']), sub.url)])]
                  )
                : null
            ),
          ])
        : null,

      !questionSub ? h('div', style(['lightGrey', mt('0.5rem'), 'small']), 'NOT SUBMITTED') : null,
    ]);
  }

  private isSubmitted() {
    const { assignment } = this.getProps();
    const role = this.getCourseRole();
    if (role !== 'student') return false;
    if (!assignment.userData) return false;
    if (!assignment.userData.studentUserData) return false;
    if (assignment.userData.studentUserData.status === 'inProgress') return false;
    return true;
  }
  private async editTitleHandler(isEditingAssignment?: boolean) {
    if (isEditingAssignment) {
      this.setState({
        editAssignmentDialog: {
          ...this.getState().editAssignmentDialog,
          isEditingTitle: true,
          titleFieldError: false,
        },
      });
      return;
    }
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isEditingTitle: true,
      titleFieldError: false,
    });
  }

  private publishDialog() {
    if (!this.isPublishingEnabled()) return null;
    const state = this.getState();
    const { course } = this.getProps();
    // const sectionHeaderAttrs = style(["lightGrey", "small", pad("0.5rem 1rem")]);
    const sectionHeaderAttrs = style(
      ['x-small', 'lightGrey', 'fullWidth', pad('0.5rem')],
      {},
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        role: 'heading',
        'aria-level': 1,
      }
    );
    const rowAttrs: any = style(
      [pad('0.5rem 1rem'), 'whiteBackground', 'flex', 'spaceBetween', 'large'],
      {},
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      }
    );

    return Dialog(
      {
        open: this.getState().isPublishDialogVisible,
        title: 'Publishing assignment',
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        style: {
          maxWidth: '30em',
          color: colors.darkBlue,
          backgroundColor: colors.backgroundColor,
        },
        secondaryAction: {
          label: 'Cancel',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElement as HTMLElement).focus();
            this.setState({
              isPublishDialogVisible: false,
              dueDateField: undefined,
              dueDateFieldError: false,
            });
          },
        },
        primaryAction: {
          label: 'Publish',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.publishAssignment(),
        },
      },
      [
        h('div', sectionHeaderAttrs, 'DETAILS'),
        h('div', rowAttrs, [
          h('span', 'Due date'),
          DatePicker({
            style: {
              paddingBottom: state.dueDateFieldError ? '0.5rem' : undefined,
              borderBottom: state.dueDateFieldError ? '2px solid ' + colors.errorRed : undefined,
            },
            minDate: datetime.startOfDay(datetime.now()),
            maxDate: course.dates ? datetime.fromUnix(course.dates.endDate) : undefined,
            value: state.dueDateField,
            onchange: (date) =>
              this.setState({
                dueDateFieldError: false,
                dueDateField: datetime.startOfDay(date),
              }),
          }),
        ]),
        state.dueDateFieldError
          ? h(
              'div',
              style(['whiteBackground', 'fullWidth', 'errorRed', 'textRight', pad('0 1rem')]),
              'Please select a due date'
            )
          : null,
        h('div', rowAttrs, [
          h('span', 'Due time'),
          TimePicker({
            time: state.dueTimeField,
            onChange: (time) =>
              this.setState({
                dueTimeField: time,
              }),
            icon: h('i.fa.fa-pencil', style(['lightGrey'])),
          }),
        ]),

        h('div', sectionHeaderAttrs, 'OPTIONS'),
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': `Note : Students will not be able to make submissions once the
                deadline is over. You won't be able to give students partial credit.`,
            style: {
              ...rowAttrs.style,
              padding: '0.3rem 1rem',
            },
          },
          [
            h('span', 'Allow late submissions'),
            Toggle({
              selected: state.lateSubmissionsField,
              ontoggle: () =>
                this.setState({
                  lateSubmissionsField: !state.lateSubmissionsField,
                }),
            }),
          ]
        ),
        h('div', style([pad('0.5rem 1rem'), 'small', 'lightGrey']), [
          'Students will not be able to make submissions once the deadline is over.' +
            " You won't be able to give students partial credit.",
        ]),

        h('div', sectionHeaderAttrs, 'ACTIVITY DISCUSSION'),
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': `If you subscribe, you will receive a notification whenever someone
                 adds a comment to this activity's discussion`,
            style: {
              ...rowAttrs.style,
              padding: '0.3rem 1rem',
            },
          },
          [
            h('span', 'Subscribe to comment notifications'),
            Toggle({
              selected: state.subscribeField,
              ontoggle: () =>
                this.setState({
                  subscribeField: !state.subscribeField,
                }),
            }),
          ]
        ),
        h('div', style([pad('0.5rem 1rem'), 'small', 'lightGrey']), [
          'If you subscribe, you will receive a notification whenever someone adds' +
            " a comment to this activity's discussion",
        ]),

        h('div.assignment__publish-subtitle', 'WARNINGS'),
        h(
          'div.assignment__publish-warning',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h('i.fa.fa-exclamation-triangle'),
            h('div', [
              'The course participants will be notified instantly via ',
              'email and push notifications.',
            ]),
          ]
        ),
        h(
          'div.assignment__publish-warning',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h('i.fa.fa-exclamation-triangle'),
            h('div', [
              'Once published, the activity cannot be modified, deleted, ',
              'or moved to another class.',
            ]),
          ]
        ),
      ]
    );
  }

  private async publishAssignment() {
    const state = this.getState();
    const { assignment, course } = this.getProps();
    if (!state.dueDateField) {
      await this.setState({
        dueDateFieldError: true,
      });
      return;
    }
    const dueDateTime = datetime.addMinutes(
      datetime.addHours(state.dueDateField, state.dueTimeField.hours),
      state.dueTimeField.minutes
    );
    await dispatch(
      Actions.publishAssignment({
        assignmentId: assignment._id,
        dueDateTime: datetime.toUnix(dueDateTime),
        allowLate: state.lateSubmissionsField,
        subscribeToComments: state.subscribeField === true ? 1 : 0,
      })
    );
    await this.setState({
      isPublishDialogVisible: false,
      dueDateField: undefined,
      dueDateFieldError: false,
    }).then(() => {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    });

    await Routes.courseAssignment.navigate({
      courseShortId: courseService.getShortIdFromCourseId(course._id),
      assignmentShortId: assignmentService.getShortIdFromAssignmentId(assignment._id),
      univSlug: appService.getUniversitySlug(),
    });
  }

  private editInstructionsDialog() {
    const state = this.getState();
    return Dialog(
      {
        open: state.isInstructionsDialogVisible,
        title: 'Instructions',
        bodyStyle: {
          padding: '0em',
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.saveInstructions(),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElement as HTMLElement).focus();
            this.setState({ isInstructionsDialogVisible: false });
          },
        },
      },
      [
        Editor({
          value: state.instructionsField,
          subContext: 'instruction',
          title: 'Instructions',
          attachments: {
            uploadUrl: api().assignmentGetUploadUrl,
            activityId: this.getProps().assignment._id,
            activityType: 'assignments',
            files: state.instructionsAttachmentsField,
          },
          oninput: (instructions, attachments) =>
            this.setState({
              instructionsField: instructions,
              instructionsAttachmentsField: attachments,
            }),
          enableTextFormatting: true,
          enableImageInput: true,
          enableFormulaInput: true,
          enableFileAttachments: true,
        }),
      ]
    );
  }

  private editAssignmentEditInstructionsDialog() {
    const state = this.getState().editAssignmentDialog;
    return Dialog(
      {
        open: state.isEditingInstructions,
        title: 'Instructions',
        bodyStyle: {
          padding: '0em',
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.saveInstructions(true),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => {
            // u.resetTabIndices();
            // (this.lastFocusedElement as HTMLElement).focus();
            this.setState({
              editAssignmentDialog: {
                ...this.getState().editAssignmentDialog,
                isEditingInstructions: false,
              },
            });
          },
        },
      },
      [
        Editor({
          value: state.instructionsField,
          subContext: 'instruction',
          title: 'Instructions',
          attachments: {
            uploadUrl: api().assignmentGetUploadUrl,
            activityId: this.getProps().assignment._id,
            activityType: 'assignments',
            files: state.instructionsAttachments,
          },
          oninput: (instructions, attachments) =>
            this.setState({
              editAssignmentDialog: {
                ...this.getState().editAssignmentDialog,
                instructionsField: instructions,
                instructionsAttachmentsField: attachments,
              },
            }),
          enableTextFormatting: true,
          enableImageInput: true,
          enableFormulaInput: true,
          enableFileAttachments: true,
        }),
      ]
    );
  }

  private deleteConfirmationAlert() {
    if (!this.isEditEnabled()) return null;
    return Alert(
      {
        open: this.getState().isDeleteConfirmationVisible,
        overlayStyle: { backgroundColor: colors.overlayOrange },
        style: {
          width: '25em',
          maxWidth: '100%',
        },
        actions: [
          FlatButton('No', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              this.setState({ isDeleteConfirmationVisible: false });
            },
          }),
          FlatButton('Yes', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.deleteAssignment(),
          }),
        ],
      },
      ['Are you sure you want to delete this assignment?']
    );
  }

  private floatingButtons() {
    const state = this.getState();
    return this.isEditEnabled()
      ? [
          FloatingActionButton(
            {
              id: 'add-question-button',
              position: 'bottom-right',
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => {
                this.lastFocusedElement = document.activeElement;
                u.unsetTabIndices();
                this.setState({ isFloatingMenuOpen: !state.isFloatingMenuOpen });
              },
            },
            [
              Icon(icons.plus, {
                className: `assignment__fab-icon ${state.isFloatingMenuOpen ? 'open' : ''}`,
              }),
            ]
          ),
          TipOverlayWrapper({
            targetElement: 'add-question-button',
            tip: {
              tipPosition: 'left',
              tipText:
                'Using this button, you can attach multiple questions to each' +
                ' assignment. Each question can have a different acceptable submission format',
            },
            tipKey: 'assignmentMainFloatingButton',
            isNextAvailable: false,
          }),
          FloatingMenu(
            {
              isOpen: state.isFloatingMenuOpen,
              toggleHandler: () => {
                u.resetTabIndices();
                const btn = document.getElementById('add-question-button');
                this.setState({ isFloatingMenuOpen: !state.isFloatingMenuOpen }).then(() => {
                  btn ? btn.focus() : null;
                });
              },
            },
            [
              {
                label: 'Add question',
                tabIndex: this.isAccessible ? 0 : undefined,
                onClick: () => this.addQuestionHandler(),
              },
            ]
          ),
        ]
      : [];
  }

  private editQuestionDialog() {
    const state = this.getState();
    const contents = [
      this.editQuestionDialogEditor(),
      h('div.submission-type-heading', style(['small', 'lightGrey', pad('0.5rem')]), 'SUBMISSION'),
      h(
        'div.submission-type-picker',
        style(
          [
            pad('0.5rem'),
            'large',
            'flex',
            'pointer',
            {
              backgroundColor: 'white',
            },
          ],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.lastFocusedElementSpare = document.activeElement;
              u.unsetTabIndices();
              this.setState({
                editingQuestionIsSubmissionFormatDialogOpen: true,
                editingQuestionSubmissionField: state.editingQuestionSubmission,
              });
            },
          }
        ),
        [
          h('span', 'Students submit a'),
          h('span', style(['lightGrey', ml('auto')]), [
            {
              file: 'File',
              url: 'URL',
            }[state.editingQuestionSubmission],
            h(
              'i.fa.fa-pencil',
              style(
                [ml('0.5rem')],
                {},
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                }
              )
            ),
          ]),
          this.submissionFormatDialog(),
        ]
      ),
      state.editingQuestionSubmission === 'url'
        ? h('div', style([pad('0.5rem 1rem'), 'small', 'lightGrey']), [
            'Students can submit ONE web link.',
          ])
        : null,
      state.editingQuestionSubmission === 'file'
        ? h('div', style([pad('0.5rem 1rem'), 'small', 'lightGrey']), [
            h('span', 'Students can submit ONE file with any of the following formats'),
            h(
              'span',
              style([
                'flex',
                {
                  flexWrap: 'wrap',
                },
              ]),
              this.parseAcceptableFormats(state.editingQuestionFormatsField).map((extn) =>
                h('span', style(['lightGrey', 'small', pad('0.5em 1em')]), [extn.toUpperCase()])
              )
            ),
          ])
        : null,

      h(
        'div',
        style([
          pad('0.5rem 1rem'),
          'large',
          'flex',
          'alignCenter',
          'spaceBetween',
          {
            backgroundColor: 'white',
          },
        ]),
        [
          h('span', 'Max score'),
          TextField({
            value: state.editingQuestionMarksField,
            noHintOrError: state.editingQuestionMarksError ? false : true,
            type: 'number',
            style: {
              width: '25%',
            },
            inputStyle: {
              textAlign: 'right',
            },
            errorText: state.editingQuestionMarksError ? 'Please fill this field' : null,
            oninput: (event) =>
              this.setState({
                editingQuestionMarksField: event.target.value,
                editingQuestionMarksError: false,
              }),
          }),
        ]
      ),

      state.editingQuestionId && this.isEditEnabled()
        ? RaisedButton('Delete Question', {
            tabIndex: this.isAccessible ? 0 : undefined,
            classNames: ['assignment__raised-button', 'danger'],
            onclick: () => {
              this.lastFocusedElementSpare = document.activeElement;
              u.unsetTabIndices();
              this.deleteQuestionHandler();
            },
          })
        : null,

      this.deleteQuestionDialog(),
    ];
    const backButton = {
      label: 'CANCEL',
      mobileLabel: h('i.fa.fa-times', []),
      onclick: () => {
        this.setState({
          isEditQuestionDialogVisible: false,
        }).then(() => {
          setTimeout(() => {
            u.resetTabIndices();
            const btn = document.getElementById(this.lastFocusedElementId);
            btn ? btn.focus() : null;
          }, 0);
        });
      },
    };
    return Dialog(
      {
        open: state.isEditQuestionDialogVisible,
        title: 'Assignment Question',
        fullScreen: true,
        backButton: backButton,
        style: {
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          paddingLeft: '0em',
          paddingRight: '0em',
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.editQuestionDoneHandler(),
        },
      },
      contents
    );
  }

  private editAssignmentEditQuestionDialog() {
    const state = this.getState().editAssignmentDialog;
    const contents = [
      this.editAssignmentEditQuestionDialogEditor(),
      h('div.submission-type-heading', style(['small', 'lightGrey', pad('0.5rem')]), 'SUBMISSION'),
      h(
        'div.submission-type-picker',
        style(
          [
            pad('0.5rem'),
            'large',
            'flex',
            'pointer',
            {
              backgroundColor: 'white',
            },
          ],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              // this.lastFocusedElementSpare = document.activeElement;
              // u.unsetTabIndices();
              this.setState({
                editAssignmentDialog: {
                  ...state,
                  editingQuestionIsSubmissionFormatDialogOpen: true,
                  editingQuestionSubmissionField: state.editingQuestionSubmission,
                },
              });
            },
          }
        ),
        [
          h('span', 'Students submit a'),
          h('span', style(['lightGrey', ml('auto')]), [
            {
              file: 'File',
              url: 'URL',
            }[state.editingQuestionSubmission],
            h(
              'i.fa.fa-pencil',
              style(
                [ml('0.5rem')],
                {},
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                }
              )
            ),
          ]),
          this.editAssignmentSubmissionFormatDialog(),
        ]
      ),
      state.editingQuestionSubmission === 'url'
        ? h('div', style([pad('0.5rem 1rem'), 'small', 'lightGrey']), [
            'Students can submit ONE web link.',
          ])
        : null,
      state.editingQuestionSubmission === 'file'
        ? h('div', style([pad('0.5rem 1rem'), 'small', 'lightGrey']), [
            h('span', 'Students can submit ONE file with any of the following formats'),
            h(
              'span',
              style([
                'flex',
                {
                  flexWrap: 'wrap',
                },
              ]),
              this.parseAcceptableFormats(state.editingQuestionFormatsField).map((extn) =>
                h('span', style(['lightGrey', 'small', pad('0.5em 1em')]), [extn.toUpperCase()])
              )
            ),
          ])
        : null,

      h(
        'div',
        style([
          pad('0.5rem 1rem'),
          'large',
          'flex',
          'alignCenter',
          'spaceBetween',
          {
            backgroundColor: 'white',
          },
        ]),
        [
          h('span', 'Max score'),
          TextField({
            value: state.editingQuestionMarksField,
            noHintOrError: state.editingQuestionMarksError ? false : true,
            type: 'number',
            style: {
              width: '25%',
            },
            inputStyle: {
              textAlign: 'right',
            },
            errorText: state.editingQuestionMarksError ? 'Please fill this field' : null,
            oninput: (event) =>
              this.setState({
                editAssignmentDialog: {
                  ...state,
                  editingQuestionMarksField: event.target.value,
                  editingQuestionMarksError: false,
                },
              }),
          }),
        ]
      ),

      state.editingQuestionId && this.canEditAssignment()
        ? RaisedButton('Delete Question', {
            tabIndex: this.isAccessible ? 0 : undefined,
            classNames: ['assignment__raised-button', 'danger'],
            onclick: () => {
              // this.lastFocusedElementSpare = document.activeElement;
              // u.unsetTabIndices();
              this.deleteQuestionHandler(true);
            },
          })
        : null,

      this.editAssignmentDeleteQuestionDialog(),
    ];
    const backButton = {
      label: 'CANCEL',
      mobileLabel: h('i.fa.fa-times', []),
      onclick: () => {
        this.setState({
          editAssignmentDialog: {
            ...this.getState().editAssignmentDialog,
            isEditQuestionDialogVisible: false,
          },
        });
        // .then(() => {
        //     setTimeout(() => {
        //         u.resetTabIndices();
        //         let btn = document.getElementById(this.lastFocusedElementId);
        //         btn ? btn.focus() : null;
        //     }, 0);
        // });
      },
    };
    return Dialog(
      {
        open: state.isEditQuestionDialogVisible,
        title: 'Assignment Question',
        fullScreen: true,
        backButton: backButton,
        style: {
          backgroundColor: colors.backgroundColor,
        },
        overlayStyle: {
          zIndex: 3000,
        },
        bodyStyle: {
          paddingLeft: '0em',
          paddingRight: '0em',
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.editQuestionDoneHandler(true),
        },
      },
      contents
    );
  }

  private submissionFormatDialog() {
    const state = this.getState();
    const selectorWrapperAttrs = (format: 'url' | 'file') =>
      style(
        [
          pad('0.5rem 1rem'),
          'large',
          'flex',
          {
            backgroundColor: 'white',
          },
        ],
        {},
        {
          onclick: () =>
            this.setState({
              editingQuestionSubmissionField: format,
            }),
        }
      );

    const radioStyle = {
      marginRight: '0.5rem',
    };

    const formatDescription = (text: string) =>
      h(
        'div',
        style(
          ['small', 'lightGrey', pad('0.5rem 1rem'), mb('0.5rem')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          }
        ),
        [text]
      );

    const radioButton = (format: 'url' | 'file') =>
      h('div', selectorWrapperAttrs(format), [
        RadioButton({
          selected: state.editingQuestionSubmissionField === format,
          style: radioStyle,
        }),
        h(
          'span',
          {
            url: 'URL',
            file: 'File',
          }[format]
        ),
      ]);

    const parsedExtensions = this.parseAcceptableFormats(state.editingQuestionFormatsField);
    return Dialog(
      {
        open: state.editingQuestionIsSubmissionFormatDialogOpen,
        title: 'Submission format',
        style: {
          backgroundColor: colors.backgroundColor,
          padding: '0em',
        },
        bodyStyle: {
          padding: '0em',
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.submissionFormatSaveHandler(),
        },
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElementSpare as HTMLElement).focus();
            this.setState({
              editingQuestionIsSubmissionFormatDialogOpen: false,
            });
          },
        },
      },
      [
        h(
          'div',
          style(
            ['lightGrey', 'small', pad('0.5rem 1rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          ['Acadly allows you to choose one of the following formats']
        ),
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': 'Students provide a valid web link as their submission',
          },
          [
            radioButton('url'),
            formatDescription('Students provide a valid web link as their submission'),
          ]
        ),
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': 'Students upload a file with specified formats',
          },
          [
            radioButton('file'),
            state.editingQuestionSubmissionField === 'file'
              ? h('div', [
                  h(
                    'div',
                    style([
                      'small',
                      'lightGrey',
                      pad('0.5rem 1rem'),
                      {
                        backgroundColor: 'white',
                      },
                    ]),
                    [
                      this.allowedExtensionsEditor(),
                      state.editingQuestionFormatsError
                        ? h(
                            'div',
                            {
                              tabIndex: this.isAccessible ? 0 : undefined,
                              style: {
                                color: colors.red,
                              },
                            },
                            'Please fill this field'
                          )
                        : null,
                    ]
                  ),

                  h(
                    'div',
                    style(
                      ['small', 'lightGrey', pad('1rem')],
                      {},
                      {
                        tabIndex: this.isAccessible ? 0 : undefined,
                      }
                    ),
                    ['ACCEPTABLE FORMATS']
                  ),

                  parsedExtensions.length === 0
                    ? h(
                        'div',
                        style(
                          [pad('1rem'), 'lightGrey', 'large', 'textCenter'],
                          {},
                          {
                            tabIndex: this.isAccessible ? 0 : undefined,
                          }
                        ),
                        ['No formats specified yet']
                      )
                    : h(
                        'div',
                        style([
                          'flex',
                          pad('0.5rem 1rem'),
                          {
                            flexWrap: 'wrap',
                          },
                        ]),
                        parsedExtensions.map((extn) =>
                          h(
                            'span',
                            style(
                              ['lightGrey', pad('0.5em 1em'), 'small'],
                              {},
                              { tabIndex: this.isAccessible ? 0 : undefined }
                            ),
                            extn.toUpperCase()
                          )
                        )
                      ),
                ])
              : formatDescription('Students upload a file with specified formats'),
          ]
        ),
      ]
    );
  }

  private editAssignmentSubmissionFormatDialog() {
    const state = this.getState().editAssignmentDialog;
    const selectorWrapperAttrs = (format: 'url' | 'file') =>
      style(
        [
          pad('0.5rem 1rem'),
          'large',
          'flex',
          {
            backgroundColor: 'white',
          },
        ],
        {},
        {
          onclick: () =>
            this.setState({
              editAssignmentDialog: {
                ...this.getState().editAssignmentDialog,
                editingQuestionSubmissionField: format,
              },
            }),
        }
      );

    const radioStyle = {
      marginRight: '0.5rem',
    };

    const formatDescription = (text: string) =>
      h(
        'div',
        style(
          ['small', 'lightGrey', pad('0.5rem 1rem'), mb('0.5rem')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          }
        ),
        [text]
      );

    const radioButton = (format: 'url' | 'file') =>
      h('div', selectorWrapperAttrs(format), [
        RadioButton({
          selected: state.editingQuestionSubmissionField === format,
          style: radioStyle,
        }),
        h(
          'span',
          {
            url: 'URL',
            file: 'File',
          }[format]
        ),
      ]);

    const parsedExtensions = this.parseAcceptableFormats(state.editingQuestionFormatsField);
    return Dialog(
      {
        open: state.editingQuestionIsSubmissionFormatDialogOpen,
        title: 'Submission format',
        style: {
          backgroundColor: colors.backgroundColor,
          padding: '0em',
        },
        bodyStyle: {
          padding: '0em',
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.submissionFormatSaveHandler(true),
        },
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => {
            // u.resetTabIndices();
            // (this.lastFocusedElementSpare as HTMLElement).focus();
            this.setState({
              editAssignmentDialog: {
                ...state,
                editingQuestionIsSubmissionFormatDialogOpen: false,
              },
            });
          },
        },
      },
      [
        h(
          'div',
          style(
            ['lightGrey', 'small', pad('0.5rem 1rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          ['Acadly allows you to choose one of the following formats']
        ),
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': 'Students provide a valid web link as their submission',
          },
          [
            radioButton('url'),
            formatDescription('Students provide a valid web link as their submission'),
          ]
        ),
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': 'Students upload a file with specified formats',
          },
          [
            radioButton('file'),
            state.editingQuestionSubmissionField === 'file'
              ? h('div', [
                  h(
                    'div',
                    style([
                      'small',
                      'lightGrey',
                      pad('0.5rem 1rem'),
                      {
                        backgroundColor: 'white',
                      },
                    ]),
                    [
                      this.editAssignmentAllowedExtensionsEditor(),
                      state.editingQuestionFormatsError
                        ? h(
                            'div',
                            {
                              tabIndex: this.isAccessible ? 0 : undefined,
                              style: {
                                color: colors.red,
                              },
                            },
                            'Please fill this field'
                          )
                        : null,
                    ]
                  ),

                  h(
                    'div',
                    style(
                      ['small', 'lightGrey', pad('1rem')],
                      {},
                      {
                        tabIndex: this.isAccessible ? 0 : undefined,
                      }
                    ),
                    ['ACCEPTABLE FORMATS']
                  ),

                  parsedExtensions.length === 0
                    ? h(
                        'div',
                        style(
                          [pad('1rem'), 'lightGrey', 'large', 'textCenter'],
                          {},
                          {
                            tabIndex: this.isAccessible ? 0 : undefined,
                          }
                        ),
                        ['No formats specified yet']
                      )
                    : h(
                        'div',
                        style([
                          'flex',
                          pad('0.5rem 1rem'),
                          {
                            flexWrap: 'wrap',
                          },
                        ]),
                        parsedExtensions.map((extn) =>
                          h(
                            'span',
                            style(
                              ['lightGrey', pad('0.5em 1em'), 'small'],
                              {},
                              { tabIndex: this.isAccessible ? 0 : undefined }
                            ),
                            extn.toUpperCase()
                          )
                        )
                      ),
                ])
              : formatDescription('Students upload a file with specified formats'),
          ]
        ),
      ]
    );
  }

  private async submissionFormatSaveHandler(isEditingAssignment?: boolean) {
    if (isEditingAssignment) {
      const state = this.getState().editAssignmentDialog;
      if (state.editingQuestionSubmissionField === 'file') {
        const allowedExtensions = this.parseAcceptableFormats(state.editingQuestionFormatsField);
        if (allowedExtensions.length < 1) {
          await this.setState({
            editAssignmentDialog: {
              ...state,
              editingQuestionFormatsError: true,
            },
          });
          return;
        }
      }
      await this.setState({
        editAssignmentDialog: {
          ...state,
          editingQuestionIsSubmissionFormatDialogOpen: false,
          editingQuestionSubmission: state.editingQuestionSubmissionField,
        },
      });
      return;
    }
    const state = this.getState();
    if (state.editingQuestionSubmissionField === 'file') {
      const allowedExtensions = this.parseAcceptableFormats(state.editingQuestionFormatsField);
      if (allowedExtensions.length < 1) {
        await this.setState({
          editingQuestionFormatsError: true,
        });
        return;
      }
    }
    await this.setState({
      editingQuestionIsSubmissionFormatDialogOpen: false,
      editingQuestionSubmission: state.editingQuestionSubmissionField,
    }).then(() => {
      u.resetTabIndices();
      (this.lastFocusedElementSpare as HTMLElement).focus();
    });
  }

  private allowedExtensionsEditor() {
    const state = this.getState();
    return h('textarea', {
      oninput: (event: any) =>
        this.setState({
          editingQuestionFormatsField: event.target.value,
          editingQuestionFormatsError: false,
        }),
      style: {
        width: '100%',
        background: 'none',
        resize: 'none',
        fontSize: state.editingQuestionFormatsField ? '0.8rem' : undefined,
        border: `1px solid ${
          state.editingQuestionFormatsError ? colors.errorRed : colors.lightGrey
        }`,
        padding: '0.5em',
        boxSizing: 'border-box',
        outline: 'none',
      },
      placeholder: 'Type acceptable file formats here seperated by commas (e.g. txt, png)',
      autogrow: '',
      rows: '2',
      value: state.editingQuestionFormatsField,
      'max-lines': 4,
    });
  }
  private editAssignmentAllowedExtensionsEditor() {
    const state = this.getState().editAssignmentDialog;
    return h('textarea', {
      oninput: (event: any) =>
        this.setState({
          editAssignmentDialog: {
            ...this.getState().editAssignmentDialog,
            editingQuestionFormatsField: event.target.value,
            editingQuestionFormatsError: false,
          },
        }),
      style: {
        width: '100%',
        background: 'none',
        resize: 'none',
        fontSize: state.editingQuestionFormatsField ? '0.8rem' : undefined,
        border: `1px solid ${
          state.editingQuestionFormatsError ? colors.errorRed : colors.lightGrey
        }`,
        padding: '0.5em',
        boxSizing: 'border-box',
        outline: 'none',
      },
      placeholder: 'Type acceptable file formats here seperated by commas (e.g. txt, png)',
      autogrow: '',
      rows: '2',
      value: state.editingQuestionFormatsField,
      'max-lines': 4,
    });
  }

  private parseAcceptableFormats(str: string) {
    return str
      .replace(/\./g, '')
      .split(/[,;\s]+/)
      .filter((e) => e.length > 0);
  }

  private deleteQuestionDialog() {
    const state = this.getState();
    return Alert(
      {
        open: state.isDeleteQuestionDialogVisible,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('No', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElementSpare as HTMLElement).focus();
              this.setState({
                isDeleteQuestionDialogVisible: false,
              });
            },
          }),
          FlatButton('Yes', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.deleteQuestion(state.editingQuestionId!),
          }),
        ],
      },
      ['Are you sure you want to delete this question?']
    );
  }

  private editAssignmentDeleteQuestionDialog() {
    const state = this.getState().editAssignmentDialog;
    return Alert(
      {
        open: state.isDeleteQuestionDialogVisible,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('No', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => {
              // u.resetTabIndices();
              // (this.lastFocusedElementSpare as HTMLElement).focus();
              this.setState({
                editAssignmentDialog: {
                  ...this.getState().editAssignmentDialog,
                  isDeleteQuestionDialogVisible: false,
                },
              });
            },
          }),
          FlatButton('Yes', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.deleteQuestion(state.editingQuestionId!, true),
          }),
        ],
      },
      ['Are you sure you want to delete this question?']
    );
  }

  private editQuestionDialogEditor() {
    const state = this.getState();
    return Editor({
      value: state.editingQuestionDescriptionField,
      subContext: 'question',
      oninput: (value, attachments) =>
        this.setState({
          editingQuestionDescriptionField: value,
          editingQuestionAttachmentsField: attachments,
          editingQuestionDescriptionError: false,
        }),
      attachments: {
        uploadUrl: api().assignmentGetUploadUrl,
        files: state.editingQuestionAttachmentsField,
        activityId: this.getProps().assignment._id,
        activityType: 'assignments',
      },
      enableFileAttachments: true,
      enableTextFormatting: true,
      enableImageInput: true,
      enableFormulaInput: true,
      errorText: state.editingQuestionDescriptionError ? 'Please fill this field' : undefined,
    });
  }
  private editAssignmentEditQuestionDialogEditor() {
    const state = this.getState().editAssignmentDialog;
    return Editor({
      value: state.editingQuestionDescriptionField,
      subContext: 'question',
      oninput: (value, attachments) =>
        this.setState({
          editAssignmentDialog: {
            ...this.getState().editAssignmentDialog,
            editingQuestionDescriptionField: value,
            editingQuestionAttachmentsField: attachments,
            editingQuestionDescriptionError: false,
          },
        }),
      attachments: {
        uploadUrl: api().assignmentGetUploadUrl,
        files: state.editingQuestionAttachmentsField,
        activityId: this.getProps().assignment._id,
        activityType: 'assignments',
      },
      enableFileAttachments: true,
      enableTextFormatting: true,
      enableImageInput: true,
      enableFormulaInput: true,
      errorText: state.editingQuestionDescriptionError ? 'Please fill this field' : undefined,
    });
  }

  // private assignmentQuestionErrorDialog() {
  //     if (!this.isEditEnabled()) return null;
  //     return Alert({
  //         open: this.getState().editingQuestionDescriptionError,
  //         overlayStyle: {backgroundColor: colors.overlayOrange},
  //         style: {
  //             width: "25em",
  //             maxWidth: "100%"
  //         },
  //         actions: [
  //             FlatButton("OK", {
  //                 onclick: () => this.setState({
  //                     editingQuestionDescriptionError: false
  //                 })
  //             })
  //         ]
  //     }, ["Question cannot be Empty.Please enter a title"]);
  // }

  private async deleteQuestion(questionId: string, isEditingAssignment?: boolean) {
    if (isEditingAssignment) {
      const state = this.getState().editAssignmentDialog;
      await this.setState({
        editAssignmentDialog: {
          ...state,
          questions: state.questions.filter((q) => q._id !== state.editingQuestionId),
          isDeleteQuestionDialogVisible: false,
          editingQuestionId: null,
          isEditQuestionDialogVisible: false,
        },
      });
      return;
    }
    this.lastFocusedElementId = 'add-question-button';
    u.resetTabIndices();
    const { assignment } = this.getProps();
    await dispatch(Actions.deleteAssignmentQuestion(questionId, assignment._id));
    await this.setState({
      isDeleteQuestionDialogVisible: false,
      editingQuestionId: null,
      isEditQuestionDialogVisible: false,
    }).then(() => {
      setTimeout(() => {
        u.resetTabIndices();
        const btn = document.getElementById(this.lastFocusedElementId);
        btn ? btn.focus() : null;
      }, 0);
    });
  }

  private async deleteQuestionHandler(isEditingAssignment?: boolean) {
    if (isEditingAssignment) {
      this.setState({
        editAssignmentDialog: {
          ...this.getState().editAssignmentDialog,
          isDeleteQuestionDialogVisible: true,
        },
      });
      return;
    }
    await this.setState({
      isDeleteQuestionDialogVisible: true,
    });
  }

  private async addQuestionHandler(isEditingAssignment?: boolean) {
    if (isEditingAssignment) {
      this.setState({
        editAssignmentDialog: {
          ...this.getState().editAssignmentDialog,
          isEditQuestionDialogVisible: true,
          editingQuestionId: null,
          editingQuestionDescriptionField: '',
          editingQuestionAttachmentsField: [],
          editingQuestionFormatsField: '',
          editingQuestionMarksField: '',
          editingQuestionSubmission: 'url',
          editingQuestionSubmissionField: 'url',
          editingQuestionDescriptionError: false,
          editingQuestionMarksError: false,
          editingQuestionFormatsError: false,
        },
      });
      return;
    }
    this.lastFocusedElementId = 'add-question-button';
    u.unsetTabIndices();
    await this.setState({
      isEditQuestionDialogVisible: true,
      editingQuestionId: null,
      editingQuestionDescriptionField: '',
      editingQuestionAttachmentsField: [],
      editingQuestionFormatsField: '',
      editingQuestionMarksField: '',
      editingQuestionSubmission: 'url',
      editingQuestionSubmissionField: 'url',
      editingQuestionDescriptionError: false,
      editingQuestionMarksError: false,
      editingQuestionFormatsError: false,
    });
  }

  private async editQuestionDoneHandler(isEditingAssignment?: boolean) {
    if (isEditingAssignment) {
      const state = this.getState().editAssignmentDialog;
      const newState = { ...state };
      const {
        editingQuestionId,
        editingQuestionDescriptionField,
        editingQuestionAttachmentsField,
        editingQuestionSubmission,
        editingQuestionFormatsField,
        editingQuestionMarksField,
      } = state;
      let hasError = false;
      if (state.editingQuestionDescriptionField.trim().length < 1) {
        hasError = true;
        newState.editingQuestionDescriptionError = true;
      }
      if (state.editingQuestionMarksField.length < 1) {
        hasError = true;
        newState.editingQuestionMarksError = true;
      }
      if (hasError) {
        this.setState({
          editAssignmentDialog: newState,
        });
        return;
      }
      if (state.editingQuestionId) {
        const newQuestions = this.getState().editAssignmentDialog.questions.map((q) => {
          if (q._id === editingQuestionId) {
            q.details.description.text = editingQuestionDescriptionField;
            q.details.attachments = editingQuestionAttachmentsField;
            q.details.submission = editingQuestionSubmission;
            q.details.submitExt =
              q.details.submission === 'url'
                ? ['dummy']
                : this.parseAcceptableFormats(editingQuestionFormatsField);
            q.details.marks = parseInt(editingQuestionMarksField);
          }
          return q;
        });
        this.setState({
          editAssignmentDialog: {
            ...state,
            questions: newQuestions,
            isEditQuestionDialogVisible: false,
          },
        });
      } else {
        const { _id: assignmentId } = this.getProps().assignment;
        const requestData = {
          activityType: 'assignments' as const,
          submission: state.editingQuestionSubmissionField,
          activityId: assignmentId,
          description: {
            text: state.editingQuestionDescriptionField,
          },
          attachments: state.editingQuestionAttachmentsField,
          marks: parseInt(state.editingQuestionMarksField),
          submitExt: this.parseAcceptableFormats(state.editingQuestionFormatsField),
        };

        const newQuestion: IAssignmentQuestion | undefined = await dispatch(
          Actions.addAssignmentQuestion(assignmentId, requestData, true)
        );
        let newQuestions = [...this.getState().editAssignmentDialog.questions];
        if (newQuestion) {
          newQuestions = [...newQuestions, newQuestion];
        }
        this.setState({
          editAssignmentDialog: {
            ...state,
            questions: newQuestions,
            isEditQuestionDialogVisible: false,
          },
        });
      }

      return;
    }
    const state = this.getState();
    const newState: Partial<IAssignmentState> = {};
    let hasError = false;
    if (state.editingQuestionDescriptionField.trim().length < 1) {
      hasError = true;
      newState.editingQuestionDescriptionError = true;
    }
    if (state.editingQuestionMarksField.length < 1) {
      hasError = true;
      newState.editingQuestionMarksError = true;
    }
    if (hasError) {
      await this.setState(newState);
      return;
    }
    const { assignment } = this.getProps();
    const requestData = {
      // <as "assignments"> required because the inferred type is string
      // but request requires property of type "assignments"
      activityType: 'assignments' as const,
      submission: state.editingQuestionSubmissionField,
      activityId: assignment._id,
      description: {
        text: state.editingQuestionDescriptionField,
      },
      attachments: state.editingQuestionAttachmentsField,
      marks: parseInt(state.editingQuestionMarksField),
      submitExt: this.parseAcceptableFormats(state.editingQuestionFormatsField),
    };
    if (state.editingQuestionId) {
      await dispatch(
        Actions.editAssignmentQuestion({
          questionId: state.editingQuestionId,
          ...requestData,
        })
      ).then(() => {
        setTimeout(() => {
          const focusTarget = this.lastFocusedElementId
            ? document.getElementById(this.lastFocusedElementId)
            : undefined;
          focusTarget ? focusTarget.focus() : null;
        }, 0);
      });
    } else {
      await dispatch(Actions.addAssignmentQuestion(assignment._id, requestData)).then(() => {
        setTimeout(() => {
          u.resetTabIndices();
          const btn = document.getElementById(this.lastFocusedElementId);
          btn ? btn.focus() : null;
        }, 0);
      });
      googleAnalytics.questionsAdded('assignment', 'assignment');
    }
    await this.setState({
      isEditQuestionDialogVisible: false,
      editingQuestionId: null,
    });
  }

  private async saveTitleHandler(isEditingAssignment?: boolean) {
    if (isEditingAssignment) {
      const state = this.getState().editAssignmentDialog;
      if (state.titleField.trim().length < 1) {
        await this.setState({
          editAssignmentDialog: {
            ...this.getState().editAssignmentDialog,
            titleFieldError: true,
          },
        });
        return;
      }
      await this.setState({
        editAssignmentDialog: {
          ...this.getState().editAssignmentDialog,
          isEditingTitle: false,
          title: state.titleField,
        },
      });
      // .then(() => {
      //     u.resetTabIndices();
      //     (this.lastFocusedElement as HTMLElement).focus();
      // });
      return;
    }
    const state = this.getState();
    const assignment = this.getProps().assignment;
    if (state.titleField.trim().length < 1) {
      await this.setState({ titleFieldError: true });
      return;
    }
    await dispatch(
      Actions.editAssignment({
        assignmentId: assignment._id,
        title: state.titleField,
        instructions: assignment.details.instructions || defaultInstructions,
        attachments: assignment.details.attachments,
      })
    );
    await this.setState({
      isEditingTitle: false,
    }).then(() => {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    });
  }

  private async saveInstructions(isEditingAssignment?: boolean) {
    if (isEditingAssignment) {
      const state = this.getState().editAssignmentDialog;
      await this.setState({
        editAssignmentDialog: {
          ...this.getState().editAssignmentDialog,
          isEditingInstructions: false,
          instructionsAttachments: state.instructionsAttachmentsField,
          instructions: state.instructionsField,
        },
      });
      // .then(() => {
      //     u.resetTabIndices();
      //     (this.lastFocusedElement as HTMLElement).focus();
      // });
      return;
    }
    const state = this.getState();
    const assignment = this.getProps().assignment;
    await dispatch(
      Actions.editAssignment({
        assignmentId: assignment._id,
        title: assignment.details.title,
        instructions: state.instructionsField || defaultInstructions,
        attachments: state.instructionsAttachmentsField,
      })
    );
    await this.setState({
      isInstructionsDialogVisible: false,
    }).then(() => {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    });
  }

  private async deleteAssignment() {
    goBack();
    await dispatch(Actions.deleteAssignment(this.getProps().assignment._id));
    googleAnalytics.activityDeleted('assignment', 'course');
  }

  private isEditEnabled() {
    const { assignment, course } = this.getProps();
    return (
      !course.isArchived &&
      !assignment.details.published &&
      assignment.details.createdBy.userId === getStore().getState().getIn.session!.userId
    );
  }

  private copyDialog() {
    const props = this.getProps();
    return CopyActivityDialog({
      open: this.getState().isCopyDialogOpen,
      classId: '',
      activityId: props.assignment._id,
      activityType: 'assignments',
      onDone: () => this.closeCopyDialog(),
      onCancel: () => this.closeCopyDialog(),
    });
  }

  private async closeCopyDialog() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isCopyDialogOpen: false,
    });
  }

  private async openCopyDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isCopyDialogOpen: true,
    });
  }

  private isActionBarVisible() {
    return (
      this.isDeletionEnabled() ||
      this.isCopyEnabled() ||
      this.isMoveEnabled() ||
      this.isPublishingEnabled()
    );
  }

  private isPublishingEnabled() {
    const { course, assignment } = this.getProps();
    if (course.isArchived) return false;
    if (assignment.details.published) return false;
    if (assignment.details.numQuestions < 1) return false;
    return this.isCreator();
  }

  private isDeletionEnabled() {
    const { course, assignment } = this.getProps();
    return !course.isArchived && !assignment.details.published && this.isCreator();
  }

  private isCopyEnabled() {
    const role = courseService.getRole();
    return role === 'admin' || role === 'instructor';
  }

  private isMoveEnabled() {
    return false;
  }

  private isCreator() {
    const { assignment } = this.getProps();
    return assignment.details.createdBy.userId === getStore().getState().getIn.session!.userId;
  }

  private async openPublishDialog() {
    const { course, assignment } = this.getProps();
    if (
      !validateActivityPublish({
        activityType: 'assignment',
        activity: assignment,
        course,
      })
    ) {
      return;
    }
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isPublishDialogVisible: true,
      dueTimeField: {
        hours: 11,
        minutes: 59,
      },
      lateSubmissionsField: true,
    });
  }

  private async openEditInstructionsDialog(isEditingAssignment?: boolean) {
    const { assignment } = this.getProps();
    if (isEditingAssignment) {
      const state = this.getState().editAssignmentDialog;
      await this.setState({
        editAssignmentDialog: {
          ...this.getState().editAssignmentDialog,
          isEditingInstructions: true,
          instructionsAttachmentsField: state.instructionsAttachments,
          instructionsField: state.instructions,
        },
      });
      return;
    }
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isInstructionsDialogVisible: true,
      instructionsAttachmentsField: assignment.details.attachments,
      instructionsField: assignment.details.instructions,
    });
  }
}
