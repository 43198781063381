import { CSS, h, IComponent, VNode } from 'core';
interface IHoverProps {
  hoverStyle: any;
  child: VNode<any, any>;
  key?: string;
  isHoveredCallback?: (isHovered: boolean) => any;
}
export default (
  style: CSS & { key?: string },
  child: VNode<any, any>,
  callback?: (isHovered: boolean) => any
) =>
  h(Hover, {
    hoverStyle: style,
    child,
    key: style.key,
    isHoveredCallback: callback,
  });
class Hover extends IComponent<
  IHoverProps,
  {
    isHovered: boolean;
  }
> {
  public componentWillMount() {
    this.setState({
      isHovered: false,
    }).then(() => this.isHoveredCallback(false));
  }

  private isHoveredCallback(value: boolean) {
    const callback = this.getProps().isHoveredCallback;
    if (callback) {
      callback(value);
    }
  }

  public render() {
    const child = this.getProps().child;
    if (!this.getState().isHovered) {
      return {
        ...child,
        props: {
          ...child.props,
          onmouseenter: () => {
            this.setState({ isHovered: true });
            this.isHoveredCallback(true);
          },
          onmouseleave: () => {
            this.setState({ isHovered: false });
            this.isHoveredCallback(false);
          },
        },
      };
    } else {
      return {
        ...child,
        props: {
          ...child.props,
          style: {
            ...child.props.style,
            ...this.getProps().hoverStyle,
          },
          onmouseenter: () => {
            this.setState({ isHovered: true });
            this.isHoveredCallback(true);
          },
          onmouseleave: () => {
            this.setState({ isHovered: false });
            this.isHoveredCallback(false);
          },
        },
      };
    }
  }
}
