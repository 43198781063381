import { h, IComponent } from 'core';

import { Actions as AppActions } from 'acadly/app/actions';
import { createBasicCourseDemo, createProCourseDemo } from 'acadly/app/api';
import Dialog from 'acadly/common/Dialog';
import { fullScreenLoader } from 'acadly/common/Loader';
import Select, { Option, SelectType } from 'acadly/common/Select';
import { Actions as CourseActions } from 'acadly/course/actions';
import { fetchTimezones, ITimezone } from 'acadly/course/api';
import * as datetime from 'acadly/datetime';
import { dispatch } from 'acadly/store';
import { colors } from 'acadly/styles';

export enum DemoCourseType {
  BASIC = 'basic',
  PRO = 'pro',
}

interface ICreateDemoCourseDialogProps {
  courseType: DemoCourseType;
  open: boolean;
  onClose: () => void;
}

interface ICreateDemoCourseDialogState {
  selectTimezoneError: string;
  timezones: null | ITimezone[];
  isSelectTimezoneDropdownOpen: boolean;
  selectedTimezone: undefined | Option<string>;
}

class CreateDemoCourseDialog extends IComponent<
  ICreateDemoCourseDialogProps,
  ICreateDemoCourseDialogState
> {
  public componentWillMount() {
    const initialState: ICreateDemoCourseDialogState = {
      timezones: null,
      selectTimezoneError: '',
      selectedTimezone: undefined,
      isSelectTimezoneDropdownOpen: false,
    };

    this.setState(initialState);

    fetchTimezones().then((response) => {
      this.setState({
        timezones: response.data.timezones.universityTimezones,
      });
    });
  }

  private reset() {
    this.setState({
      selectedTimezone: undefined,
      selectTimezoneError: '',
      isSelectTimezoneDropdownOpen: false,
    });
  }

  private handleCancel = () => {
    const { onClose } = this.getProps();
    this.reset();
    onClose();
  };

  private handleCreateDemoCourse = async () => {
    const { courseType, onClose } = this.getProps();
    const { selectedTimezone } = this.getState();

    if (!selectedTimezone) {
      this.setState({
        selectTimezoneError: 'Please select timezone',
      });
      return;
    }

    if (courseType === DemoCourseType.PRO) {
      await createProCourseDemo(selectedTimezone.id);
    } else {
      await createBasicCourseDemo(selectedTimezone.id);
    }

    dispatch(AppActions.demoCourseCreated(undefined));
    dispatch(CourseActions.fetchMyCourses());

    this.reset();
    onClose();
  };

  public render() {
    const { open } = this.getProps();

    return Dialog(
      {
        open,
        title: this.getTitle(),
        style: {
          padding: '0px',
          backgroundColor: colors.backgroundColor,
        },
        primaryAction: {
          type: 'secondary',
          label: 'CREATE',
          onclick: this.handleCreateDemoCourse,
        },
        secondaryAction: {
          type: 'primary',
          label: 'CANCEL',
          onclick: this.handleCancel,
        },
      },
      [this.getBody()]
    );
  }

  private getTitle() {
    const { courseType } = this.getProps();
    return courseType === DemoCourseType.BASIC
      ? 'Acadly Basic - Create demo course'
      : 'Acadly Pro - Create free trial course';
  }

  private getMessage() {
    const { courseType } = this.getProps();

    if (courseType === DemoCourseType.BASIC) {
      return `
                Using a 7 day long demo course, you'll be able to see how activities are
                added to a course and how students respond. You will also be able to
                record attendance using Acadly’s mobile apps
            `;
    }

    return `
            With an Acadly Pro course, you can conduct remote lectures using your
            existing Zoom account with Acadly. Once you're verified, you'll be able to
            create Pro courses after purchasing slots
        `;
  }

  private getBody() {
    const { timezones, selectedTimezone, selectTimezoneError, isSelectTimezoneDropdownOpen } =
      this.getState();

    if (!timezones) {
      return fullScreenLoader;
    }

    const format = 'MMM D, YYYY';
    const beginDate = datetime.format(datetime.now(), format);
    const endDate = datetime.format(datetime.addDays(datetime.now(), 8), format);

    return h('div.join-course-payment__body', [
      h('p.join-course-payment__description', this.getMessage()),
      h('div.join-course-payment__form-cell', [h('span', 'COURSE BEGINS'), h('span', beginDate)]),
      h('div.join-course-payment__form-cell', [h('span', 'COURSE ENDS'), h('span', endDate)]),
      h('div.join-course-payment__form-cell', [
        h('span', 'COURSE TIMEZONE'),
        h('span.join-course-payment__dropdown-wrapper', [
          Select({
            type: SelectType.SINGLE,
            noFloatingLabel: true,
            classes: {
              select: 'join-course-payment__dropdown',
              optionContainer: 'join-course-payment__dropdown-options',
            },
            value: selectedTimezone,
            placeholder: 'Select timezone',
            isOpen: isSelectTimezoneDropdownOpen,
            hasError: !!selectTimezoneError,
            errorText: selectTimezoneError,
            options: timezones.map((t) => ({
              id: t.timezoneName,
              title: t.readable,
            })),
            onToogleDropDown: (isOpen) => {
              this.setState({
                isSelectTimezoneDropdownOpen: isOpen,
              });
            },
            onChange: (value) => {
              this.setState({
                selectedTimezone: value,
              });
            },
          }),
        ]),
      ]),
    ]);
  }
}

export default (props: ICreateDemoCourseDialogProps) => h(CreateDemoCourseDialog, props);
