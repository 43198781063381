import { VCallStatus } from 'core/enums';
import { JSONPromise, jsonRequest } from 'core/http';

import {
  IExportAllGradesSuccessRequest,
  IExportGradesSuccessRequest,
  IExportGradesSuccessResponse,
} from 'acadly/class/actions';

import api from '../api';

export interface ISetClassInchargeRequest {
  classId: string;
  inCharge: {
    userId: string;
    avatar: string;
    name: string;
    role: 'admin' | 'instructor';
  };
  multiple: 0 | 1;
}

export const setClassInchargeV2 = (data: ISetClassInchargeRequest) =>
  jsonRequest<{ classIds: string[] }>(api().classInchargeSetV2, {
    method: 'POST',
    data,
  });

export interface IClassOnlineDetails {
  meetingType: 'zoom';
  meetingInProgress: VCallStatus;
  meetingId: ZoomId;
  meetingPassword: string;
  isUserAuthenticated?: 0 | 1; // present only for class incharge
  authUrl?: string; // present only for class incharge if isUserAuthenticated === 0
  joinId: string;
  beingBroadcast: 0 | 1;
}

export interface IFetchClassActivitiesResponse {
  details: IClassDetails;
  discussions: IResponseDiscussion[];
  info: IClassInfo;
  polls: IResponsePoll[];
  queries: IResponseQuery[];
  quizzes: IResponseQuiz[];
  resources: IResponseResource[];
  userData: IResponseClassPageUserData;
  /** Auto schedule class attendance config */
  autoSchedule: AutoScheduleAttendanceConfig;
  attendance?: {
    /** set only when class attendance is in-progress */
    attendanceTime?: number;
    inProgress: 0 | 1;
    isProxy: 0 | 1;
    wasScheduled: 0 | 1;
    /** present only if attendance.inProgress === 1 */
    numEnrolled: number;
    /** present only if attendance.inProgress === 1 */
    numAvailable: number;
    /** present only if attendance.inProgress === 1 */
    numPresent: number;
    coarse: 0 | 1;
    fine: 0 | 1;
    /** will be available when inProgress === 1 */
    taker?: {
      userId: string;
      name: string;
      avatar: string;
      role: ICourseRole;
    };
  };
  showSuggestedActivities: 0 | 1;
  suggestedActivities: ISuggestedActivity[];
  onlineDetails?: IClassOnlineDetails;
  recordings?: {
    recordingsAvailable: number;
    published: 0 | 1;
    status: 'inProgress' | 'done';
  };
  autoRecordDefaults?: {
    // available to class in-charge only
    canCloudRecord: 0 | 1;
    autoCloudRecord: 0 | 1;
  };
}

export const fetchClassActivities = (classId: string) =>
  jsonRequest<IFetchClassActivitiesResponse>(api().classData(classId), {
    method: 'GET',
  });

export interface IGetOnlineDetailsResponse extends IClassOnlineDetails {
  message: string;
}

export const getOnlineDetails = (classId: string) => {
  return jsonRequest<IGetOnlineDetailsResponse>(api().getOnlineDetails, {
    method: 'GET',
    data: { classId },
  });
};

export interface ICreateOnlineMeetingPayload {
  classId: string;
  autoCloudRecord: 0 | 1;
  updateAutoCloudRecordDefault: 0 | 1;
}

export interface ICreateOnlineMeetingResponse extends IGetOnlineDetailsResponse {
  autoCloudRecord: 0 | 1;
}

export const createOnlineMeeting = (data: ICreateOnlineMeetingPayload) => {
  return jsonRequest<ICreateOnlineMeetingResponse>(api().createOnlineMeeting, {
    method: 'POST',
    data,
  });
};

export const zoomMeetingStatus = (classId: string, meetingId: ZoomId) => {
  return jsonRequest<void>(api().zoomMeetingStatus, {
    method: 'GET',
    data: { classId, meetingId },
    skipErrorHandlers: true,
  });
};

interface IStartBroadcastResponse {
  message: string;
}

export const startBroadcast = (classId: string, meetingId: ZoomId) => {
  return jsonRequest<IStartBroadcastResponse>(api().startBroadcast, {
    method: 'PUT',
    data: { classId, meetingId },
  });
};

interface IEndBroadcastResponse {
  message: string;
}

export const endBroadcast = (classId: string, meetingId: ZoomId) => {
  return jsonRequest<IEndBroadcastResponse>(api().endBroadcast, {
    method: 'PUT',
    data: { classId, meetingId },
  });
};

interface IGetVCallSignatureResponse {
  message: string;
  signature: string;
  meetingId: ZoomId;
  meetingPassword: string;
  joinId: string;
  role: 0 | 1;
  closeUrl: string;
}

export const getVCallSignature = (classId: string, meetingId: ZoomId) => {
  return jsonRequest<IGetVCallSignatureResponse>(api().generateVCallSignature, {
    method: 'GET',
    data: { classId, meetingId },
  });
};

interface IVCallParticipantJoinedRequest {
  classId: string;
  joinId: string;
  zoomUserId: ZoomId;
  meetingId: ZoomId;
  device: 'web';
}

interface IVCallParticipantJoinedResponse {
  message: string;
  unixTimeStamp: UnixTimestamp;
  attendanceInProgress: 0 | 1; // will be 1 ONLY IF user = student AND attendance is in progress
  attendanceId: string; // will be non-zero length ONLY IF attendanceInProgress = 1
  attendanceRecorded: 0 | 1;
}

export const vCallParticipantJoined = (data: IVCallParticipantJoinedRequest) => {
  return jsonRequest<IVCallParticipantJoinedResponse>(api().vCallParticipantJoined, {
    method: 'PUT',
    data: data,
  });
};

interface IVCallParticipantLeftRequest {
  classId: string;
  zoomUserId: ZoomId;
  meetingId: ZoomId;
}

interface IVCallParticipantLeftResponse {
  message: string;
  unixTimeStamp: UnixTimestamp;
}

export const vCallParticipantLeft = (courseId: string, data: IVCallParticipantLeftRequest) => {
  return jsonRequest<IVCallParticipantLeftResponse>(api().vCallParticipantLeft, {
    method: 'PUT',
    headers: { courseId },
    data: data,
  });
};

export type IScheduleAttendanceRequest = ScheduledAttendance & { classId: string };

export interface IScheduleAttendanceResponse {
  message: 'success';
  autoSchedule: ScheduledAttendance & { isScheduled: 1 };
}

export const scheduleAttendance = (data: IScheduleAttendanceRequest) => {
  return jsonRequest<IScheduleAttendanceResponse>(api().scheduleAttendance, {
    method: 'POST',
    data,
  });
};

export type IEditScheduleAttendanceRequest = { classId: string } & FollowForFutureClasses &
  ({ remove: 1; scheduleRule: ''; time: '' } | ({ remove: 0 } & ScheduleAttendanceRule));

export const editScheduleAttendance = (data: IEditScheduleAttendanceRequest) => {
  return jsonRequest<IScheduleAttendanceResponse>(api().editScheduleAttendance, {
    method: 'PUT',
    data,
  });
};

interface IStartRemoteAttendanceRequest {
  classId: string;
  meetingId: ZoomId;
}

interface IStartRemoteAttendanceResponse {
  attendanceId: string;
}

export const startRemoteAttendance = (data: IStartRemoteAttendanceRequest) => {
  return jsonRequest<IStartRemoteAttendanceResponse>(api().startRemoteAttendance, {
    method: 'POST',
    data,
  });
};

interface IStopRemoteAttendanceRequest {
  classId: string;
  meetingId: ZoomId;
  attendanceId: string;
}

interface IStopRemoteAttendanceResponse {
  message: string;
  responders: number;
  inPersonAuto: 0 | 1;
  remoteAuto: 0 | 1;
  remoteAutoInstances: number;
}

export const stopRemoteAttendance = (data: IStopRemoteAttendanceRequest) => {
  return jsonRequest<IStopRemoteAttendanceResponse>(api().stopRemoteAttendance, {
    method: 'POST',
    data,
  });
};

interface IAcknowledgeAttendanceStartedRequest {
  courseId: string;
  classId: string;
  device: 'browser';
  event: 'attendanceStarted';
  /** attendance start time */
  startTime: UnixTimestamp;
  notification: 'pusher';
}

export const acknowledgeAttendanceStarted = (data: IAcknowledgeAttendanceStartedRequest) => {
  return jsonRequest(api().acknowledgeAttendanceStarted, {
    method: 'POST',
    data,
  });
};

interface IMarkRemoteAttendanceRequest {
  classId: string;
  meetingId: ZoomId;
  attendanceId: string;
}

interface IMarkRemoteAttendanceResponse {
  message: string;
}

export const markRemoteAttendance = (data: IMarkRemoteAttendanceRequest) => {
  return jsonRequest<IMarkRemoteAttendanceResponse>(api().markRemoteAttendance, {
    method: 'POST',
    data,
  });
};

interface IGetZoomRecordings {
  recordings: IZoomRecording[];
}

export const getZoomRecordings = (classId: string) => {
  return jsonRequest<IGetZoomRecordings>(api().getZoomRecordings(classId), {
    method: 'GET',
  });
};

export interface IDeleteZoomRecordingPayload {
  classId: string;
  recordingId: string;
  fileNamesToDelete: string[];
}

interface IDeleteZoomRecordingResponse {
  message: string;
}

export const deleteZoomRecording = ({
  classId,
  recordingId,
  fileNamesToDelete,
}: IDeleteZoomRecordingPayload) => {
  return jsonRequest<IDeleteZoomRecordingResponse>(api().deleteZoomRecording, {
    method: 'PUT',
    data: { classId, recordingId, filenamesToDelete: fileNamesToDelete },
  });
};

interface IPublishZoomRecordingResponse {
  message: string;
}

export const publishZoomRecording = (classId: string) => {
  return jsonRequest<IPublishZoomRecordingResponse>(api().publishZoomRecording, {
    method: 'PUT',
    data: { classId },
  });
};

interface IFetchSuggestedActivitiesResponse {
  message: string;
  activities: ISuggestedActivity[];
}

export const fetchSuggestedActivities = () =>
  jsonRequest<IFetchSuggestedActivitiesResponse>(api().suggestedActivities, {
    method: 'GET',
  });

type ISuggestedActivityDetailsResponse =
  | {
      _id: string;
      message: string;
      nodeType: 'quiz';
      identifiers: ISuggestedActivityIdentifiers;
      questions: ISuggestedQuizQuestion[];
    }
  | {
      _id: string;
      message: string;
      nodeType: 'poll';
      identifiers: ISuggestedActivityIdentifiers;
      questions: ISuggestedPollQuestion[];
    }
  | {
      _id: string;
      message: string;
      nodeType: 'assignment';
      identifiers: ISuggestedAssignmentIdentifiers;
      questions: ISuggestedAssignmentQuestion[];
    };

export const getSuggestedActivityDetails = (
  activityType: 'quiz' | 'poll' | 'assignment',
  activityId: string
) =>
  jsonRequest<ISuggestedActivityDetailsResponse>(api().suggestedActivityDetails, {
    method: 'GET',
    data: {
      activityType,
      activityId,
    },
  });

export type IUseSuggestedActivityRequest =
  | {
      activityId: string;
      activityType: 'quizzes' | 'polls' | 'resources' | 'discussions';
      copyToClass: string;
      toBeDone: 'preClass' | 'inClass';
    }
  | {
      activityId: string;
      activityType: 'assignments';
      copyToClass: ''; // will remain empty
      toBeDone: ''; // will remain empty
    };

export interface IUseSuggestedActivityResponse {
  message: string;
}

export const useSuggestedActivity = (params: IUseSuggestedActivityRequest) =>
  jsonRequest<IUseSuggestedActivityResponse>(api().useSuggestedActivity, {
    method: 'POST',
    data: params,
  });

export interface IHideSuggestedActivityRequest {
  activityId: string;
  activityType: 'quizzes' | 'polls' | 'resources' | 'discussions';
}

export interface IHideSuggestedActivityResponse {
  message: string;
}

export const hideSuggestedActivity = (params: IHideSuggestedActivityRequest) =>
  jsonRequest<IHideSuggestedActivityResponse>(api().hideSuggestedActivity, {
    method: 'PUT',
    data: params,
  });

export interface IMoveClassActivityRequest {
  classId: string;
  activityId: string;
  type: 'quiz' | 'discussion' | 'poll' | 'resource';
  subType: 'preClass' | 'inClass';
  newSubType: 'preClass' | 'inClass';
  newClassId: string;
}
export const moveClassActivity = (data: IMoveClassActivityRequest) =>
  jsonRequest<void>(api().classActivityMove, {
    method: 'POST',
    data,
  });

export interface ICheckInResponse {
  checkInTime: UnixTimestamp;
  isLate: 0 | 1;
}

// export const checkIn = (classId: string) =>
//     jsonRequest<ICheckInResponse>(api().classCheckIn + classId, {
//         method: "GET"
//     });

export const checkInV2 = (data: ICheckInRequest) =>
  jsonRequest<ICheckInResponse>(api().classCheckInV2, {
    method: 'PUT',
    data,
  });

export interface ICheckInRequest {
  classId: string;
  agent: 'web';
  locationAvailable: 0;
}
export interface IAddWeeklyClassRequest {
  day: IWeekDay[];
  startTime: string;
  endTime: string;
  type: IClassType;
  venue: string;
  isOnlineMeeting: 0 | 1;
}

export const addWeeklyClassV2 = (newClass: IAddWeeklyClassRequest) =>
  jsonRequest<void>(api().courseWeeklyClassAdd, {
    method: 'POST',
    data: { ...newClass, itemType: undefined },
  });

export interface IRemoveWeeklyClassRequest {
  type: IClassType;
  day: IWeekDay;
  startTime: string;
}

export const removeWeeklyClass = (cls: IRemoveWeeklyClassRequest) =>
  jsonRequest(api().courseWeeklyClassRemove, {
    method: 'DELETE',
    data: cls,
  });

export interface IAddClassRequest {
  dates: {
    // YYYY-MM-DDTHH:mm
    scheStartTime: string;
    scheEndTime: string;
  }[];
  type: IClassType;
  venue: string;
  isOnlineMeeting: 0 | 1;
}

export type IAddClassResponse =
  | {
      message: 'success';
      insertedClasses: IClassResponse[];
    }
  | {
      message: 'clash';
      clashingClasses: {
        date: number; // int in YYYYMMDD format ¯\(°_o)/¯
        endTime: UnixTimestamp;
        startTime: UnixTimestamp;
        type: IClassType;
      }[];
    };

export const addClass = (data: IAddClassRequest) =>
  jsonRequest<IAddClassResponse>(api().courseClassAdd, {
    method: 'POST',
    data: data,
  });

export const deleteClass = (classId: string) =>
  jsonRequest<void>(api().classRemove + classId, {
    method: 'DELETE',
  });

export type IClassCancelRequest = {
  classId: string;
  mark: 'holiday' | 'canceled';
  venue: string;
  announce: 0 | 1;
  maintain: 0 | 1;
  remove: 0 | 1;
} & (
  | {
      hasMakeUp: 1;
      newClassStartTime: string;
      newClassEndTime: string;
    }
  | {
      hasMakeUp: 0;
    }
);

export const cancelClass = (data: IClassCancelRequest) =>
  jsonRequest(api().classCancel, {
    method: 'POST',
    data,
  });

export type IRescheduleRequest = {
  classId: string;
  isOnlineMeeting: 0 | 1;
  venue: string;
  announce: 0 | 1;
  maintain: 0 | 1;
  hasMakeUp: 1;
  newClassStartTime: string; // YYYYMMDDTHH:mm
  newClassEndTime: string; // YYYYMMDTHH:mm

  // these have no significance; Backend ignores them
  // but they're still required fields at backend in
  // validation
  mark: 'canceled';
  remove: 0;
};
export const rescheduleClass = (data: IRescheduleRequest) =>
  jsonRequest(api().classCancel, {
    method: 'POST',
    data,
  });

export interface IVenueEditRequest {
  classId: string;
  isOnlineMeeting: 0 | 1;
  newVenue: string;
  multiple: 0 | 1;
  notify: 0 | 1;
}
export const venueEdit = (data: IVenueEditRequest) => {
  return jsonRequest<{ message: 'success' }>(api().classChangeVenue, {
    method: 'POST',
    data,
  });
};

export interface IAgendaEditRequest {
  classId: string;
  agenda: string;
}
export const agendaEdit = (data: IAgendaEditRequest) =>
  jsonRequest(api().classAgendaEdit, {
    method: 'POST',
    data,
  });

export interface IEditClassTeamRequest {
  classId: string;
  inCharge: IClassTeamMember;
  assistants: IClassTeamMember[];
  multiple: ['inCharge'?, 'assistants'?];
}

export interface IEditClassTeamResponse {
  message: string;
  status: string;
}
export const editClassTeam = (data: IEditClassTeamRequest) => {
  return jsonRequest<IEditClassTeamResponse>(api().classTeamEdit, {
    method: 'PUT',
    data,
  });
};

export interface ITopicsEditRequest {
  classId: string;
  topicIds: string[];
}

export const topicsEdit = (data: ITopicsEditRequest) =>
  jsonRequest(api().classTopicsEdit, {
    method: 'POST',
    data,
  });

export interface ISummaryFetchResponse {
  description: string;
  attachments?: IAttachment[];
  links: {
    linkId: string;
    title: string;
    url: string;
  }[];
}

export const summaryFetch = (classId: string) =>
  jsonRequest<ISummaryFetchResponse>(api().classSummaryGet(classId), {
    method: 'GET',
  });

export interface ISummarySaveRequest {
  classId: string;
  attachments: IAttachment[];
  description: string;
  links: {
    linkId: string;
    title: string;
    url: string;
  }[];
}

export const summarySave = (data: ISummarySaveRequest) =>
  jsonRequest(api().classSummarySave, {
    method: 'POST',
    data,
  });

export interface ISummaryLinkAddRequest {
  title: string;
  url: string;
  parent: 'class';
}

export interface ISummaryLinkAddResponse {
  linkId: string;
}
export const summaryLinkAdd = (data: ISummaryLinkAddRequest) =>
  jsonRequest<ISummaryLinkAddResponse>(api().classSummaryAddLinks, {
    method: 'POST',
    data,
  });

export interface IClassTitleEditRequest {
  classId: string;
  title: string;
}

export const classTitleEdit = (data: IClassTitleEditRequest) =>
  jsonRequest<{ message: 'success' }>(api().classTitleEdit, {
    method: 'POST',
    data,
  });

interface StartProxyAttendanceResponse {
  message: 'success';
  attendanceTime: UnixTimestamp;
  /** available only if it is instructor initiated attendance */
  attendanceCode?: string;
  numEnrolled: number;
  numAvailable: number;
  numPresent: number;
}

export const startProxyAttendance = (classId: string) => {
  return jsonRequest<StartProxyAttendanceResponse>(api().startProxyAttendance, {
    method: 'POST',
    data: { classId },
  });
};

interface StopProxyAttendanceResponse {
  message: 'success';
}

export const stopProxyAttendance = (courseId: string, classId: string) => {
  return jsonRequest<StopProxyAttendanceResponse>(api().stopProxyAttendance, {
    method: 'POST',
    data: { classId },
    headers: { courseId },
  });
};

export const attendingRemotely = (courseId: string, classId: string) => {
  return jsonRequest(api().attendingRemotely, {
    method: 'POST',
    data: { classId },
    headers: { courseId },
  });
};

export interface IGetAttendanceRespondersResponse {
  numUnresponsive: number;
  numNotified: number;
}

export const getAttendanceResponders = (courseId: string, classId: string) => {
  return jsonRequest<IGetAttendanceRespondersResponse>(api().attendanceResponders, {
    method: 'GET',
    data: { classId },
    headers: { courseId },
  });
};

export interface IAttendanceFetchResponse {
  status: 'inSession' | 'closed';
  inPersonAuto: 0 | 1;
  remoteAuto: 0 | 1;
  remoteAutoInstances: number;
  attendance: {
    checkedIn: IAttendanceTabStudent[];
    instructor: {
      userId: string;
      name: string;
      avatar: string;
    };
    present: string[]; // old api; don't use
    autoAttendance?: number;
    attendanceCode?: number;
    inProgress?: 0 | 1;
  };
}

export type IAttendanceFetchMineResponse =
  | {
      checkedIn: 0 | 1;
      status: 'present' | 'absent' | 'notPresent';
      visibleStatus?: 'present' | 'absent' | 'late' | 'excused';
      attendance: 1;
      attendanceTime: UnixTimestamp;
    }
  | {
      attendance: 0;
      attendanceTime: null;
      checkedIn: 0 | 1;
      visibleStatus?: 'present' | 'absent' | 'late' | 'excused';
    };

export const attendanceFetchMine = (classId: string) =>
  jsonRequest<IAttendanceFetchMineResponse>(api().classAttendanceMineGet(classId), {
    method: 'GET',
  });

export const attendanceFetch = (classId: string) => {
  return jsonRequest<IAttendanceFetchResponse>(api().classAttendanceGet(classId), {
    method: 'GET',
  });
};

export interface IAttendanceSetRequest {
  classId: string;
  present: string[];
  absent: string[];
  late: string[];
  excused: string[];
}
export const attendanceSet = (
  data: IAttendanceSetRequest,
  headers: {
    courseId: string;
  }
) =>
  jsonRequest(api().classAttendanceSet, {
    method: 'POST',
    data,
    headers,
    skipInterceptors: true,
  });
export interface IClassParticipationRequest {
  classId: string;
  studentIds: string[];
  points: number;
  label: string;
}

export interface IGetClassParticipationResponse {
  studentData: IParticipationStudentData[];
}

export const getClassParticipation = (clsId: string) => {
  return jsonRequest<IGetClassParticipationResponse>(api().getClassParticipationStats(clsId), {
    method: 'GET',
  });
};

export interface IClassParticipationResponse {
  message: string;
  newLabel: 0 | 1;
}

export const setClassParticipation = (data: IClassParticipationRequest) => {
  return jsonRequest<IClassParticipationResponse>(api().setClassParticipationScores, {
    method: 'POST',
    data,
  });
};

export interface IClashCheckRequest {
  dates: {
    // YYYY-MM-DDTHH:mm
    scheStartTime: string;
    scheEndTime: string;
  }[];
}

export type IClashCheckResponse =
  | {
      message: 'clash';
      clashingClasses: {
        startTime: UnixTimestamp;
        endTime: UnixTimestamp;
        date: UnixTimestamp;
        type: 'lecture' | 'tutorial' | 'seminar' | 'lab';
      }[];
    }
  | {
      message: 'success';
    };

export const exportGrades = (
  data: IExportGradesSuccessRequest
): JSONPromise<IExportGradesSuccessResponse> => {
  return jsonRequest(
    api().exportActivityGrades +
      `?activityType=${data.activityType}&activityId=${data.activityId}` +
      `&email=${data.email}`,
    {
      method: 'GET',
    }
  );
};

export const exportAllGrades = (
  data: IExportAllGradesSuccessRequest
): JSONPromise<IExportGradesSuccessResponse> => {
  return jsonRequest(
    api().exportAllGrades + `?activityType=${data.activityType}` + `&email=${data.email}`,
    {
      method: 'GET',
    }
  );
};
export interface ITimingsEditRequest {
  classId: string;
  newStartTime: string; // HH:mm
  newEndTime: string; // HH:mm
  multiple: 0 | 1;
  notify: 0 | 1;
}

export type ITimingsEditResponse =
  | {
      message: 'success';
    }
  | {
      message: 'clash';
      clashingClasses: {
        startTime: UnixTimestamp;
        endTime: UnixTimestamp;
        date: UnixTimestamp;
        type: 'lecture' | 'tutorial' | 'seminar' | 'lab';
      }[];
    };
export const timingsEdit = (data: ITimingsEditRequest) =>
  jsonRequest<ITimingsEditResponse>(api().classTimingsEdit, {
    method: 'POST',
    data: data,
  });

interface IActivityPublishPrefsBase {
  classId: string;
  activityId: string;
  saveAsDefault: 0 | 1;
}

interface IQuizPublishPrefsPayload extends IActivityPublishPrefsBase {
  activityType: 'quiz';
  quizPref: IQuizPublishPrefs;
}

interface IPollPublishPrefsPayload extends IActivityPublishPrefsBase {
  activityType: 'poll';
  pollPref: IPollPublishPrefs;
}

interface IDiscussionPublishPrefsPayload extends IActivityPublishPrefsBase {
  activityType: 'discussion';
  discussionPref: IDiscussionPublishPrefs;
}

export type IActivityPublishPrefs =
  | IQuizPublishPrefsPayload
  | IPollPublishPrefsPayload
  | IDiscussionPublishPrefsPayload;

type ISaveActivityRequest<T extends 'quiz' | 'poll' | 'discussion'> = Extract<
  IActivityPublishPrefs,
  { activityType: T }
>;

type ISaveActivityResponse<T extends 'quiz' | 'poll' | 'discussion'> = {
  message: 'success';
  publishDefaults: ISaveActivityRequest<T> & { activityType: 'preClass' | 'inClass'; saved: 0 | 1 };
};

export const saveActivityPublishPrefs = <T extends 'quiz' | 'poll' | 'discussion'>(
  data: ISaveActivityRequest<T>
) => {
  return jsonRequest<ISaveActivityResponse<T>>(api().publishPrefs, {
    method: 'POST',
    data,
  });
};
