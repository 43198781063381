import 'video.js/dist/video-js.min.css';

import videojs, { VideoJsPlayer } from 'video.js';

import { CSS, h, IComponent } from 'core';

import * as CloseIcon from 'assets/close.svg';

import SvgIcon from 'acadly/common/SvgIcon';
import PipContainer, { PipContainer as PipContainerClass } from 'acadly/pip-container/PipContainer';
import { randomString } from 'acadly/utils';

interface IAudioPlayerProps {
  id?: string;
  className?: string;
  style?: CSS;
  /** audio sources */
  sources: {
    src: string;
    type?: string;
  }[];
  /** element selector for embedded mode of pip-container */
  embedTargetSelector: string;
  /** if false, pip-container will be unmounted */
  show: boolean;
  onClose: () => void;
  getPipContainerRef?: (pipContainer: PipContainerClass) => void;
}
interface IAudioPlayerState {
  id: string;
}

class AudioPlayer extends IComponent<IAudioPlayerProps, IAudioPlayerState> {
  private audio?: VideoJsPlayer;

  private init() {
    const { id } = this.getState();
    const options = {
      loop: false,
      fluid: true,
      responsive: true,
      preload: 'metadata',
      poster: 'https://s3.amazonaws.com/static.acad.ly/img/audio-icon.png',
      playbackRates: [0.5, 1, 1.5, 2],
    };

    this.audio = videojs(id, options);

    if (this.audio) {
      this.audio.play();
    }
  }

  private cleanup() {
    if (this.audio) {
      this.audio.dispose();
      this.audio = undefined;
    }
  }

  public componentWillMount() {
    const { id } = this.getProps();

    const initialState: IAudioPlayerState = {
      id: id || `audio-${randomString()}`,
    };

    this.setState(initialState);
  }

  public componentDidMount() {
    const { show } = this.getProps();
    if (show) {
      this.init();
    }
  }

  public componentWillUpdate(nextProps: IAudioPlayerProps) {
    const currentProps = this.getProps();

    if (currentProps.show === true && nextProps.show === false) {
      // about to hide pip-container
      this.cleanup();
    }
  }

  public componentDidUpdate(lastProps: IAudioPlayerProps) {
    const currentProps = this.getProps();

    if (currentProps.show === true && lastProps.show === false) {
      // pip-container is mounted
      this.init();
    }
  }

  public componentWillUnmount() {
    this.cleanup();
  }

  public render() {
    const { className, embedTargetSelector, show, style, onClose, sources, getPipContainerRef } =
      this.getProps();
    const { id } = this.getState();

    if (!show) return null;

    return PipContainer({
      containerId: `pip-container-${id}`,
      lockAspectRatio: true,
      initialAspectRatio: 16 / 9,
      initialEmbedModeTargetSelector: embedTargetSelector,
      getInstance: (pipContainer) => {
        if (getPipContainerRef) {
          getPipContainerRef(pipContainer);
        }
      },
      rightControls: [
        {
          type: 'custom',
          view: SvgIcon({
            role: 'button',
            icon: CloseIcon,
            className: 'pip-container__button',
            title: 'Close audio',
            'aria-label': 'Click to close the audio player',
            onclick: onClose,
          }),
        },
      ],
      body: [
        h(
          'audio.video-js.vjs-default-skin',
          {
            id,
            style,
            className,
            controls: true,
            preload: 'metadata',
          },
          sources.map(({ src, type }) => h('source', { src, type }))
        ),
      ],
    });
  }
}

export default (props: IAudioPlayerProps) => h(AudioPlayer, props);
