import { h, IComponent } from 'core';

import CtaButton from 'acadly/common/CtaButton';
import Icon from 'acadly/common/Icon';
import icons from 'acadly/icons';
import links from 'acadly/utils/links';

interface IPaymentFailedStepProps {
  error: string;
  onRetry: () => any;
  onClose: () => any;
}

export default class PaymentFailedStep extends IComponent<IPaymentFailedStepProps, unknown> {
  public static getTitle(): string {
    return 'Payment failed';
  }

  private init() {
    this.setState({});
  }

  public componentWillMount() {
    this.init();
  }

  private handleClose = () => {
    const { onClose } = this.getProps();
    onClose();
  };

  private handleRetry = () => {
    const { onRetry } = this.getProps();
    onRetry();
  };

  public render() {
    const { error } = this.getProps();
    const message = links.detect(error).map((token) => {
      switch (token.type) {
        case 'string':
          return token.value;
        case 'link':
          return h('a', { href: token.value, target: '_blank' }, token.value);
        default:
          return '';
      }
    });
    return h('div.join-course-payment__body', [
      h('div.join-course-payment__payment-error', message),
      h('div.join-course-payment__check.failed', [Icon(icons.cross)]),
      CtaButton({
        variant: 'blue',
        label: 'RETRY PAYMENT',
        onClick: this.handleRetry,
      }),
      CtaButton({
        variant: 'grey',
        label: 'CLOSE',
        onClick: this.handleClose,
      }),
    ]);
  }
}
