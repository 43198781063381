import { h } from 'core';

import Icon from 'acadly/common/Icon';
import { wordCloudIcon } from 'acadly/discussion/DiscussionWordCloud';
import icons from 'acadly/icons';
import { getStore } from 'acadly/store';

export const AnalyticsHeader = (props: { label: string; isOpen?: boolean }) => {
  const context = getStore().getState().app.context;
  return h(
    'div.analytics-header',
    {
      tabIndex: props.isOpen ? 0 : undefined,
      'aria-label': props.label,
    },
    [
      h('div.analytics-header__title', [
        context === 'discussion'
          ? wordCloudIcon()
          : Icon(icons.analytics, {
              className: 'analytics-header__icon',
              ariaLabel: props.label,
            }),
        h('span', [props.label]),
      ]),
    ]
  );
};
