import classNames from 'classnames';

import { h, IComponent } from 'core';

import * as CloseIcon from 'assets/close.svg';
import * as DoneIcon from 'assets/done.svg';

import Alert from 'acadly/common/Alert';
import CheckBox from 'acadly/common/CheckBox';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import RadioButton from 'acadly/common/RadioButton';
import RangeSlider from 'acadly/common/RangeSlider';
import SvgIcon from 'acadly/common/SvgIcon';
import TextArea from 'acadly/common/TextArea';
import ToastManager from 'acadly/common/Toast';
import { dispatch, getStore } from 'acadly/store';
import { withTabIndex } from 'acadly/utils';

import { Actions } from '../actions';
import { denyFeedbackForm, submitFeedbackForm } from '../api';

interface IFeedbackFormProps {
  open: boolean;
  onClose?: () => void;
}

interface IFeedbackFormState {
  hasContinued: boolean;
  /** question-id and selected response option-id/text map */
  responseMap: Record<string, string | undefined>;
  /** question-id and selected rating map */
  ratingMap: Record<string, number | undefined>;
  isResponseSubmittedAlertOpen: boolean;
}

class FeedbackForm extends IComponent<IFeedbackFormProps, IFeedbackFormState> {
  private get isAccessible() {
    return getStore().getState().app.acc.web.turnOff === 0;
  }

  private init() {
    const initialState: IFeedbackFormState = {
      responseMap: {},
      ratingMap: {},
      hasContinued: false,
      isResponseSubmittedAlertOpen: false,
    };
    this.setState(initialState);
  }

  public componentWillMount() {
    this.init();
  }

  public componentWillReceiveProps(nextProps: IFeedbackFormProps) {
    const currentProps = this.getProps();
    if (currentProps.open === false && nextProps.open === true) {
      // opening
      this.init();
    }
  }

  private closeFeedbackForm = () => {
    const { onClose } = this.getProps();
    // clear feedback form data, this will hide feedback form
    dispatch(Actions.setFeedbackFormData(undefined));
    if (onClose) {
      onClose();
    }
  };

  private handleLaterClick = async () => {
    const { feedbackForm } = getStore().getState().app;

    if (!feedbackForm) return;

    await denyFeedbackForm({
      denyType: 'delay',
      feedbackFormId: feedbackForm.feedbackFormId,
    });

    this.closeFeedbackForm();
  };

  private handleContinue = async () => {
    await dispatch(Actions.getFeebackForm());
    this.setState({ hasContinued: true });
  };

  private isValidFormData() {
    const { feedbackForm } = getStore().getState().app;
    const { responseMap, ratingMap } = this.getState();

    if (!feedbackForm) return true;

    for (const question of feedbackForm.questions) {
      if (question.optional) continue;
      switch (question.type) {
        case 'text':
        case 'pickOne':
        case 'pickMany':
          if (!responseMap[question._id]) return false;
          break;
        case 'rate':
          if (!ratingMap[question._id]) return false;
          break;
      }
    }

    return true;
  }

  private getFormData() {
    const { feedbackForm } = getStore().getState().app;
    const { responseMap, ratingMap } = this.getState();

    if (!feedbackForm) return [];

    const responses: IFeedbackQuestionResponse[] = [];

    for (const question of feedbackForm.questions) {
      switch (question.type) {
        case 'text':
        case 'pickOne':
        case 'pickMany':
          if (responseMap[question._id]) {
            responses.push({
              questionId: question._id,
              questionType: question.type,
              response: responseMap[question._id],
            });
          }
          break;
        case 'rate':
          if (ratingMap[question._id]) {
            responses.push({
              questionId: question._id,
              questionType: question.type,
              response: ratingMap[question._id],
            });
          }
          break;
      }
    }

    return responses;
  }

  private handleFeedbackFormSubmit = async () => {
    const { feedbackForm } = getStore().getState().app;

    if (!feedbackForm) return;

    if (!this.isValidFormData()) {
      return ToastManager.show('Please respond to all required questions');
    }

    await submitFeedbackForm({
      feedbackFormId: feedbackForm.feedbackFormId,
      responses: this.getFormData(),
    });

    // show confirmation of submission
    this.setState({
      isResponseSubmittedAlertOpen: true,
    });
  };

  public render() {
    const { open } = this.getProps();
    const { hasContinued } = this.getState();
    const { feedbackForm, isMobile } = getStore().getState().app;

    if (!feedbackForm?.feedbackFormId || !open) return null;

    if (hasContinued && isMobile) {
      return this.fullScreenView();
    }

    return this.alertView();
  }

  private renderPickOneOption(
    question: IMultipleChoiceFeedbackQuestion,
    option: IMultipleChoiceFeedbackQuestionOption
  ) {
    const { responseMap } = this.getState();

    return h(
      'div.ripple.feedback-form__option',
      withTabIndex({
        key: option.id,
        onclick: () => {
          this.setState({
            responseMap: {
              ...responseMap,
              [question._id]: option.id,
            },
          });
        },
      }),
      [
        RadioButton({
          selected: responseMap[question._id] === option.id,
          className: 'feedback-form__option-selector',
        }),
        h('div', option.text),
      ]
    );
  }

  private renderPickManyOption(
    question: IMultipleChoiceFeedbackQuestion,
    option: IMultipleChoiceFeedbackQuestionOption
  ) {
    const { responseMap } = this.getState();

    const response = responseMap[question._id] || '';
    const selectedOptions = response.split(',');
    const isSelected = selectedOptions.includes(option.id);

    return h(
      'div.ripple.feedback-form__option',
      withTabIndex({
        key: option.id,
        onclick: () => {
          const response = new Set(selectedOptions);

          if (isSelected) {
            response.delete(option.id);
          } else {
            response.add(option.id);
          }

          this.setState({
            responseMap: {
              ...responseMap,
              [question._id]: Array.from(response).join(),
            },
          });
        },
      }),
      [
        CheckBox({
          selected: isSelected,
          className: 'feedback-form__option-selector',
        }),
        h('div', option.text),
      ]
    );
  }

  private renderRateInput(question: IFeedbackQuestion, ratingScale: number) {
    const { ratingMap } = this.getState();
    const rating = ratingMap[question._id] || 0;
    return [
      RangeSlider({
        step: 1,
        min: 0,
        max: ratingScale,
        value: rating,
        isAccessible: this.isAccessible,
        oninput: async (event) => {
          this.setState({
            ratingMap: {
              ...ratingMap,
              [question._id]: event.target.valueAsNumber,
            },
          });
        },
      }),
      h('div.feedback-form__rating-text', `RATING: ${ratingMap[question._id] || '--'}`),
    ];
  }

  private renderTextInput(question: IFeedbackQuestion, textLimit: number) {
    const { responseMap } = this.getState();
    const response = responseMap[question._id] || '';

    return [
      TextArea({
        value: response,
        showTextLength: true,
        maxLength: textLimit,
        placeholder: 'Type your response here',
        onChange: (value) => {
          this.setState({
            responseMap: {
              ...responseMap,
              [question._id]: value,
            },
          });
        },
      }),
    ];
  }

  private renderOptions(question: IFeedbackQuestion) {
    switch (question.type) {
      case 'pickOne':
        return question.options.map((option) => this.renderPickOneOption(question, option));
      case 'pickMany':
        return question.options.map((option) => this.renderPickManyOption(question, option));
      case 'rate':
        return this.renderRateInput(question, question.ratingScale);
      case 'text':
        return this.renderTextInput(question, question.textLimit);
    }
    return [];
  }

  private renderQuestion = (question: IFeedbackQuestion, index: number) => {
    return h(
      'div.feedback-form__question',
      {
        key: question._id,
      },
      [
        h('div.feedback-form__question-title', [
          h('span', `Question ${index + 1}`),
          !question.optional ? h('span.feedback-form__required', `Required`) : null,
        ]),
        h('div.feedback-form__question-description', question.questionText),
        ...this.renderOptions(question),
        h('div.feedback-form__divider'),
      ]
    );
  };

  private body() {
    const { hasContinued } = this.getState();
    const { feedbackForm } = getStore().getState().app;

    if (!feedbackForm) return [];

    const attr = {
      className: classNames({
        continued: hasContinued,
      }),
    };

    return [
      h('div.feedback-form', attr, [
        h('div.feedback-form__description', feedbackForm.description),
        ...(hasContinued ? feedbackForm.questions : []).map(this.renderQuestion),
        this.responseSubmittedAlert(),
      ]),
    ];
  }

  /**
   * Used in mobile screens
   */
  private fullScreenView() {
    const { hasContinued } = this.getState();
    const { feedbackForm } = getStore().getState().app;

    if (!feedbackForm) return null;

    return Dialog(
      {
        open: true,
        title: feedbackForm.title,
        primaryAction: {
          type: 'primary',
          label: hasContinued ? 'Submit' : 'Continue',
          mobileLabel: SvgIcon({ icon: DoneIcon }),
          onclick: hasContinued ? this.handleFeedbackFormSubmit : this.handleContinue,
        },
        secondaryAction: {
          type: 'secondary',
          label: 'Later',
          mobileLabel: SvgIcon({ icon: CloseIcon }),
          onclick: this.handleLaterClick,
        },
      },
      this.body()
    );
  }

  /**
   * Used in tablet/desktop screens
   */
  private alertView() {
    const { hasContinued } = this.getState();
    const { feedbackForm } = getStore().getState().app;

    if (!feedbackForm) return null;

    return Alert(
      {
        open: true,
        isAccessible: this.isAccessible,
        title: h('div.feedback-form__title', feedbackForm.title),
        style: {
          padding: 0,
          width: '35rem',
        },
        bodyStyle: {
          padding: 0,
        },
        actionsStyle: {
          padding: '0.75rem 0.5rem',
        },
        actions: [
          FlatButton('LATER', {
            type: 'secondary',
            onclick: this.handleLaterClick,
          }),
          FlatButton(hasContinued ? 'SUBMIT' : 'CONTINUE', {
            type: 'primary',
            onclick: hasContinued ? this.handleFeedbackFormSubmit : this.handleContinue,
          }),
        ],
      },
      this.body()
    );
  }

  private responseSubmittedAlert() {
    const { isResponseSubmittedAlertOpen: isResponseSubmittedAlertVisible } = this.getState();
    return Alert(
      {
        open: isResponseSubmittedAlertVisible,
        isAccessible: this.isAccessible,
        title: 'Response submitted',
        style: { width: '30rem' },
        bodyStyle: { padding: '0.5rem 0 0' },
        actions: [
          FlatButton('Okay', {
            type: 'primary',
            onclick: this.closeFeedbackForm,
          }),
        ],
      },
      ['Thank you for helping us improve Acadly!']
    );
  }
}

export default (props: IFeedbackFormProps) => h(FeedbackForm, props);
