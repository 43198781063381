import { h, IComponent } from 'core';

import { Actions as AppActions } from 'acadly/app/actions';
import { Actions as appActions } from 'acadly/app/actions';
import { AnalyticsHeader } from 'acadly/app/AnalyticsHeader';
import { Actions as ClassActions, IExportGradesSuccessResponse } from 'acadly/class/actions';
import Alert from 'acadly/common/Alert';
import Avatar from 'acadly/common/Avatar';
import ContentView from 'acadly/common/ContentView';
import FlatButton from 'acadly/common/FlatButton';
import { fullScreenLoader, Loader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import RaisedButton from 'acadly/common/RaisedButton';
import StudentList, { getStudentListData } from 'acadly/common/StudentList';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import User from 'acadly/common/User';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import { dispatch, getStore } from 'acadly/store';
import { backgroundColor, colors, ml, mt, pad, pb, style } from 'acadly/styles';
import * as u from 'acadly/utils';

import Icon from '../common/Icon';
import icons from '../icons';
import { Actions } from './actions';
import * as api from './api';
import Questions from './Questions';

export interface IQuizAnalyticsState {
  loadedSubmission: api.IAnalyticsIndividualFetchResponse['submission'] | null;
  screen: 'main' | 'submission';
  isExportDialogOpen: boolean;
  exportDownloadLink: string;
  exportRequestIsEmail: 0 | 1;
  sendEmail: boolean;
  exportFileName: string;
  isDownloadAlertOpen: boolean;
  searchField: string;
  sortStudentBy: StudentSortBy;
}

export interface IQuizAnalyticsProps {
  quiz: IQuiz;
}
export class QuizAnalytics extends IComponent<IQuizAnalyticsProps, IQuizAnalyticsState> {
  private isAccessible: boolean;
  public componentWillMount() {
    const state = getStore().getState();
    this.isAccessible = state.app.acc.web.turnOff === 0 ? true : false;
    const quiz = this.getProps().quiz;
    dispatch(Actions.analyticsFetch(quiz));
    this.setState({
      loadedSubmission: null,
      screen: 'main',
      isExportDialogOpen: false,
      exportDownloadLink: '',
      exportRequestIsEmail: 0,
      sendEmail: false,
      exportFileName: '',
      isDownloadAlertOpen: false,
      searchField: '',
      sortStudentBy: state.app.sortStudentBy,
    });
    dispatch(appActions.startTip(true));
  }

  public componentWillUpdate(_: IQuizAnalyticsProps, nextState: IQuizAnalyticsState) {
    const state = this.getState();
    if (state.screen !== 'submission' && nextState.screen === 'submission') {
      setTimeout(() => dispatch(AppActions.pushToAnalyticsBackStack(() => this.closeSubmission())));
    } else if (state.screen === 'submission' && nextState.screen !== 'submission') {
      setTimeout(() => dispatch(AppActions.popAnalyticsBackStack(undefined)));
    }
  }

  public componentWillUnmount() {
    if (this.getState().screen === 'submission') {
      dispatch(AppActions.popAnalyticsBackStack(undefined));
    }
  }

  public render() {
    const role = courseService.getRole();
    return ContentView(h('div.analytics__content', role !== 'student' ? this.body() : []));
  }

  private body() {
    const quiz = this.getProps().quiz;
    const { searchField, sortStudentBy } = this.getState();
    const analytics = getStore().getState().quizzes.analytics;

    const renderSubmission = (s: IQuizAnalyticsSubmission) => {
      return User(
        {
          avatar: {
            url: s.identifiers.avatar,
            creator: s.identifiers.name,
          },
          title: s.identifiers.name,
          titleClassNames: ['fc-green'],
          subtitle: `Score: ${s.status.score[quiz.details.scoring]}/${
            quiz.details.numQuestions * 4
          } | ${s.status.status === 'submitted' ? 'On time' : 'Late'}`,
          subtitleClassNames: [s.status.status === 'submitted' ? 'fc-orange' : 'fc-red'],
        },
        {
          key: s.identifiers.userId,
          className: 'student',
          onclick: () => this.loadSubmission(s.identifiers.userId),
        }
      );
    };

    if (!analytics) {
      return [h('div', style(['fullWidth', mt('3em')]), [fullScreenLoader])];
    }

    const infoCell = (label: string, value?: string) =>
      h(
        'div.cell.cell--full-width',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [h('span.fs-lg.fc-dark-blue', label), h('span.fc-light-grey.capitalize', value)]
      );

    return [
      infoCell('Total Submissions', analytics ? analytics.submissions.length.toString() : '0'),
      infoCell('Scoring scheme', quiz.details.scoring),
      infoCell('Average score', this.getAverage().toString() + '%'),

      !analytics || analytics.submissions.length === 0
        ? null
        : RaisedButton(
            [
              Icon(icons.export2, { className: 'analytics__export__icon' }),
              h('span.analytics__export__label', 'Export quiz data as CSV'),
            ],
            {
              tabIndex: 0,
              id: 'export-quiz-button',
              ariaLabel: 'Export quiz data as CSV',
              onclick: () => this.openExportDialog(),
              classNames: ['analytics__export'],
            }
          ),

      h('div.analytics__header', 'Submissions'),
      h('div.analytics__sub-header', `As of ${dt.format(dt.now(), 'MMM Do YYYY')}`),

      !analytics || analytics.submissions.length === 0
        ? h('div.fc-light-grey', [
            'Once the students start submitting ',
            'the quiz, you will be able to see ',
            "the averages and each student's responses here.",
          ])
        : analytics
        ? StudentList({
            sortBy: sortStudentBy,
            style: { paddingBottom: '2rem' },
            children: getStudentListData(
              analytics.submissions,
              searchField,
              sortStudentBy,
              (s) => s.identifiers.name
            ).map(renderSubmission),
            onSearch: (term) => {
              this.setState({
                searchField: term,
              });
            },
            onSort: (sortBy) => {
              this.setState({
                sortStudentBy: sortBy,
              });
            },
          })
        : Loader(),

      this.loadedSubmissionView(),
      this.exportDialog(),
      this.downloadAlert(),
      !analytics || analytics.submissions.length === 0
        ? null
        : TipOverlayWrapper({
            targetElement: 'export-quiz-button',
            tip: {
              tipPosition: 'bottom',
              tipText:
                'You can export the students’ scores and participation' +
                ' in this activity, in a CSV format by using this button',
            },
            tipKey: 'activityAnalyticsExport',
            isNextAvailable: false,
          }),
    ];
  }

  private async loadSubmission(userId: string) {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices(document.getElementById('header'));
    await this.setState({
      screen: 'submission',
    });
    const response = await api.analyticsIndividualFetch(this.getProps().quiz._id, userId);
    await this.setState({
      loadedSubmission: response.data.submission,
    });
  }

  private loadedSubmissionView() {
    const { screen, loadedSubmission } = this.getState();
    const { quiz } = this.getProps();
    if (screen === 'main') return null;
    const analytics = getStore().getState().quizzes.analytics;
    const student =
      loadedSubmission && analytics
        ? analytics.submissions.find(
            (s) => s.identifiers.userId === loadedSubmission.identifiers.userId
          )
        : null;
    const body = loadedSubmission
      ? [
          h(
            'div.analytics__content',
            {
              tabIndex: this.isAccessible ? 0 : undefined,
              'aria-label': `${
                loadedSubmission.identifiers.name
              } submitted this Quiz on ${dt.format(
                dt.fromUnix(student ? student.status.submittedOn : 0),
                'MMM Do YYYY'
              )} and scored ${student!.status.score[quiz.details.scoring]} out of ${
                quiz.details.numQuestions * 4
              }`,
            },
            [
              h('div.user.analytics__user', [
                Avatar(loadedSubmission.identifiers.avatar, loadedSubmission.identifiers.name, {
                  className: 'user__avatar',
                }),
                h('div.user__details', [
                  h('div.fc-green', loadedSubmission.identifiers.name),
                  h(
                    'div.fc-light-grey',
                    `Submitted: ${dt.format(
                      dt.fromUnix(student ? student.status.submittedOn : 0),
                      'MMM DD YYYY'
                    )}`
                  ),
                ]),
              ]),
              h('div.cell.cell--full-width', [
                h('span.fc-dark-blue.fs-lg', 'Score'),
                h(
                  'span.fc-light-grey',
                  `${student!.status.score[quiz.details.scoring]}/${quiz.details.numQuestions * 4}`
                ),
              ]),
              h('div.cell.cell--full-width.fc-dark-blue.fs-lg', 'Answers'),
              Questions({
                mode: 'submission',
                isAccessible: this.isAccessible,
                style: { marginBottom: '4rem' },
                quiz: this.getProps().quiz,
                submission: loadedSubmission.submission,
              }),
            ]
          ),
        ]
      : [fullScreenLoader];
    const header = AnalyticsHeader({
      label: 'Student submission',
    });
    return h(
      'div',
      style([
        'absolute',
        'fullWidth',
        {
          height: '97.5%',
          top: 0,
          left: 0,
          zIndex: 1000,
          backgroundColor,
        },
      ]),
      [header, ContentView(h('div', body)), h('div.padding', style([pb('1rem')]))]
    );
  }

  private async closeSubmission() {
    await this.setState({
      loadedSubmission: null,
      screen: 'main',
    });
  }

  private downloadAlert() {
    const isOpen = this.getState().isDownloadAlertOpen;
    const fileName = this.getState().exportFileName;
    const downloadLink = this.getState().exportDownloadLink;
    const styles = {
      display: 'flex',
      width: '20em',
      borderRadius: '4px',
      backgroundColor: 'white',
      alignItems: 'center',
      border: `1px solid ${colors.lightestGrey}`,
      maxWidth: '100%',
      lineHeight: '1.5em',
      boxSizing: 'border-box',
      paddingRight: '0.5rem',
    };

    return Alert(
      {
        title: 'Success',
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('Done', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.backClickHandler(),
          }),
        ],
      },
      [
        h('div', style([pad('0.5rem 0.5rem 0.5rem 0rem')]), 'File Generated'),
        h(
          'div.acadly-attachment',
          {
            style: styles,
          },
          [
            h(
              'div.attachment-icon',
              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '0.5rem',
                  // fontSize: "0.8em",
                  width: '2.5rem',
                  color: 'white',
                  height: '2.5rem',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  backgroundColor: colors.green,
                },
              },
              'CSV'
            ),
            h(
              'div',
              style([
                'thin',
                {
                  flex: 1,
                  color: colors.black,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: '0.5em',
                },
              ]),
              [fileName]
            ),
            h(
              'a',
              {
                style: {
                  color: colors.green,
                },
                target: '_blank',
                href: downloadLink || undefined,
              },
              [Icon(icons.download, style(['blue', 'large', 'pointer', pad('0.5rem')]))]
            ),
          ]
        ),
      ]
    );
  }

  private exportDialog() {
    const isOpen = this.getState().isExportDialogOpen;
    const isEmail = this.getState().exportRequestIsEmail;
    const sendEmail = this.getState().sendEmail;
    const option = (opts: { selected: boolean; label: string; key: string; onclick: () => any }) =>
      h(
        'div',
        style(
          ['flex', 'alignCenter'],
          {},
          {
            onclick: opts.onclick,
            tabIndex: this.isAccessible ? 0 : undefined,
            key: opts.key,
          }
        ),
        [
          RadioButton({
            selected: opts.selected,
            color: colors.teal,
          }),
          h(
            'div',
            style([
              pad('0.5rem 1rem'),
              ml('0.5rem'),
              {
                flex: '1',
                borderBottom: '1px solid lightGrey',
              },
            ]),
            opts.label
          ),
        ]
      );
    return Alert(
      {
        title: !sendEmail ? 'Exporting Quiz Data' : 'Success',
        titleStyle: {
          textAlign: 'center',
        },
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton(!sendEmail ? 'Cancel' : 'Download a copy', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: !sendEmail
              ? () => this.backClickHandler()
              : () => this.downloadACopyClickHandler(),
          }),
          FlatButton('Done', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: !sendEmail ? () => this.exportClickHandler() : () => this.backClickHandler(),
          }),
        ],
      },
      [
        sendEmail
          ? h(
              'div',
              'The exported file has been sent to your registered' +
                " email address. In case you can't spot it, please" +
                ' look into the Spam folder'
            )
          : h('div', {}, [
              option({
                label: 'Download CSV file now',
                selected: isEmail === 0 ? true : false,
                key: 'no-email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 0,
                  }),
              }),
              option({
                label: 'Email file as attachment',
                selected: isEmail === 1 ? true : false,
                key: 'email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 1,
                  }),
              }),
            ]),
      ]
    );
  }

  private async downloadACopyClickHandler() {
    await this.setState({
      isDownloadAlertOpen: true,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }
  private async backClickHandler() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isDownloadAlertOpen: false,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }
  private async exportClickHandler() {
    const email = this.getState().exportRequestIsEmail;
    if (email === 1) {
      this.lastFocusedElement = document.activeElement;
      await this.setState({
        sendEmail: true,
      });
    }
    const response = await dispatch(
      ClassActions.exportGrades({
        activityType: 'quizzes',
        activityId: this.getProps().quiz._id,
        email: email,
      })
    );
    this.setFileData(response);
    if (email === 0) {
      this.lastFocusedElement = document.activeElement;
      u.unsetTabIndices();
      await this.setState({
        isExportDialogOpen: false,
        isDownloadAlertOpen: true,
      });
    }
  }

  private async setFileData(response: IExportGradesSuccessResponse) {
    await this.setState({
      exportDownloadLink: response.url,
      exportFileName: response.filename,
    });
  }
  private lastFocusedElement: Element | null = null;

  private async openExportDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isExportDialogOpen: true,
    });
  }

  private getAverage() {
    const analytics = getStore().getState().quizzes.analytics;
    if (!analytics) return 0;
    if (analytics.submissions.length === 0) return 0;
    const quiz = this.getProps().quiz;
    const max = analytics.submissions.length * quiz.details.numQuestions * 4;
    const score = analytics.submissions.reduce(
      (sum, sub) => sum + sub.status.score[quiz.details.scoring],
      0
    );
    return ((score * 100) / max).toFixed(2);
  }
}

export default (quiz: IQuiz) => h(QuizAnalytics, { quiz });
