import { CSS, h, IComponent, View } from 'core';

import { Actions } from 'acadly/app/actions';
import { dispatch, getStore } from 'acadly/store';
import { colors, margin, mb, ml, pad, style } from 'acadly/styles';
import UserName from 'acadly/utils/UserName';

import Alert from './Alert';
import FlatButton from './FlatButton';
import RadioButton from './RadioButton';
import TextField from './TextField';
import Toggle from './Toggle';

const SEARCH_FIELD_STYLE = style(['whiteBackground', pad('0.5rem 1rem'), 'borderBox'], {
  flex: 1,
}).style;
const SEARCH_FIELD_PLACEHOLDER = 'Search students';

interface StudentListProps {
  style?: CSS;
  sortBy: StudentSortBy;
  children?: View[];
  onSearch?(term: string): void;
  onSort?(sortBy: StudentSortBy): void;
}

interface StudentListState {
  search: string;
  sortBy: StudentSortBy;
  setDefault: boolean;
  isSortDialogVisible: boolean;
}

class StudentList extends IComponent<StudentListProps, StudentListState> {
  private isAccessible: boolean;

  private init() {
    this.setState({
      search: '',
      setDefault: false,
      sortBy: this.getSortBy(),
      isSortDialogVisible: false,
    });
  }
  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    this.init();
  }

  public render() {
    const state = this.getState();
    const { children, onSearch, style: styles } = this.getProps();
    return h('div.student-list', style(['flex', 'column', 'fullWidth'], styles), [
      h(
        'div.student-search',
        style(['flex', 'alignCenter', pad('0.5rem 0'), mb('0.5rem')], {
          backgroundColor: colors.white,
          overflow: 'hidden',
          borderRadius: '4px',
        }),
        [
          TextField({
            value: state.search,
            style: SEARCH_FIELD_STYLE,
            placeholder: SEARCH_FIELD_PLACEHOLDER,
            noHintOrError: true,
            oninput: async (e) => {
              await this.setState({ search: e.target.value });
              if (onSearch) onSearch(e.target.value);
            },
          }),
          FlatButton(
            this.getSortIcon(),
            style(
              [margin('0.5rem')],
              {
                flexShrink: 0,
                border: `1px solid ${colors.blue}`,
              },
              {
                onclick: () => this.showSortDialog(),
              }
            )
          ),
        ]
      ),
      ...(children || []),
      this.sortDialog(),
    ]);
  }

  private getSortIcon() {
    const { sortBy } = this.getState();
    switch (sortBy) {
      case 'sortAscFirstName':
      case 'sortAscLastName':
        return h('i.fa.fa-sort-alpha-asc');
      case 'sortDescFirstName':
      case 'sortDescLastName':
        return h('i.fa.fa-sort-alpha-desc');
      default:
        return null;
    }
  }

  private sortDialog() {
    const { isSortDialogVisible, sortBy, setDefault } = this.getState();
    const onSort = this.getProps().onSort;

    const option = (title: string, _sortBy: StudentSortBy) => {
      return h(
        'div.ripple.sort-option',
        style(
          ['flex', 'alignCenter', 'pointer', pad('0.5rem')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: async () => {
              await this.setState({
                sortBy: _sortBy,
              });
            },
          }
        ),
        [RadioButton({ selected: sortBy === _sortBy }), h('span', style([ml('0.5em')]), title)]
      );
    };

    return Alert(
      {
        open: isSortDialogVisible,
        title: h(
          'div',
          style(['fullWidth', 'orange', 'bold'], {
            display: 'flex',
            alignItems: 'center',
          }),
          'Sort order'
        ),
        style: {
          width: '25rem',
        },
        actions: [
          FlatButton('Cancel', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: async () => {
              await this.setState({
                isSortDialogVisible: false,
              });
            },
          }),
          FlatButton('Okay', {
            type: 'primary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: async () => {
              if (setDefault) this.setDefaultSort(sortBy);
              if (onSort) onSort(sortBy);
              await this.setState({
                isSortDialogVisible: false,
              });
            },
          }),
        ],
      },
      [
        h(
          'div.options',
          style([pad('1rem'), { borderBottom: `1px solid ${colors.lightestGrey}` }]),
          [
            option('First name - Ascending', 'sortAscFirstName'),
            option('First name - Descending', 'sortDescFirstName'),
            option('Last name - Ascending', 'sortAscLastName'),
            option('Last name - Descending', 'sortDescLastName'),
          ]
        ),
        h(
          'div',
          style(
            ['flex', 'spaceBetween', 'alignCenter', pad('1rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          [
            h('span', 'Set as default'),
            Toggle({
              isAccessible: this.isAccessible,
              selected: setDefault,
              ontoggle: async () => {
                await this.setState({
                  setDefault: !setDefault,
                });
              },
            }),
          ]
        ),
      ]
    );
  }

  private getSortBy() {
    return this.getProps().sortBy;
  }

  private async showSortDialog() {
    await this.setState({
      search: '',
      setDefault: false,
      sortBy: this.getSortBy(),
      isSortDialogVisible: true,
    });
  }

  private async setDefaultSort(sortBy: StudentSortBy) {
    await dispatch(Actions.saveDefaultSortPref(sortBy));
  }
}

export default (props: StudentListProps) => h(StudentList, props);

export function getStudentListData<T>(
  list: T[],
  search: string,
  sortBy: StudentSortBy,
  getName: (student: T) => string
) {
  search = search.toLowerCase();
  return list
    .sort((s1, s2) => {
      const s1Name = new UserName(getName(s1));
      const s2Name = new UserName(getName(s2));
      return UserName.compare(s1Name, s2Name, sortBy);
    })
    .filter((s) => {
      return getName(s).toLowerCase().includes(search);
    });
}
