import { getStore } from 'acadly/store';
import * as u from 'acadly/utils';

import IService from '../IService';

class QuizService extends IService {
  public getQuizIdFromShortId(shortId: string) {
    const quizzes = getStore().getState().quizzes.byId;
    const quiz = u.objectValues(quizzes).filter((q) => q._id.slice(18) === shortId)[0];
    if (quiz) {
      return quiz._id;
    } else {
      return null;
    }
  }
  public getShortIdFromQuizId(quizId: string) {
    return quizId.slice(18);
  }
}

export const quizService = new QuizService();
export default quizService;
