import cn from 'classnames';
import { cloneDeep, isEqual } from 'lodash';
import { Subscription } from 'rxjs/Subscription';

import { CSS, h, IComponent, View } from 'core';

import * as EyeCloseIcon from 'assets/eye-close.svg';
import * as EyeOpenIcon from 'assets/eye-open.svg';
import * as ReorderIcon from 'assets/reorder-icon.svg';

import { Actions as appActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import MoveActivityDialog from 'acadly/class/MoveActivityDialog';
import ActionBar, { IActionButton, isIpad } from 'acadly/common/ActionBar';
import Alert from 'acadly/common/Alert';
import Avatar from 'acadly/common/Avatar';
import { BottomFloatingBar } from 'acadly/common/BottomFloatingBar';
import ContentView from 'acadly/common/ContentView';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import FloatingActionButton from 'acadly/common/FloatingActionButton';
import FloatingMenu from 'acadly/common/FloatingMenu';
import { Icon } from 'acadly/common/Icon';
import { Loader } from 'acadly/common/Loader';
import ProgressBar from 'acadly/common/ProgressBar';
import QuestionOptionEditor from 'acadly/common/QuestionOptionEditor';
import RadioButton from 'acadly/common/RadioButton';
import SvgIcon from 'acadly/common/SvgIcon';
import TextField from 'acadly/common/TextField';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import Toggle from 'acadly/common/Toggle';
import Tooltip from 'acadly/common/Tooltip';
import CopyActivityDialog from 'acadly/course/CopyActivityDialog';
import { validateActivityPublish } from 'acadly/course/validations';
import * as datetime from 'acadly/datetime';
import icons from 'acadly/icons';
import { pusherService } from 'acadly/pusher';
import QuizPrefsDialog from 'acadly/quiz/QuizPrefsDialog';
import Editor from 'acadly/rich-text/Editor';
import Viewer from 'acadly/rich-text/Viewer';
import { goBack } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { backgroundColor, colors, mb, ml, mr, mt, pad, pt, style } from 'acadly/styles';
import * as utils from 'acadly/utils';

import { api as urls } from '../api';
import RaisedButton from '../common/RaisedButton';
import { Actions } from './actions';
import * as api from './api';
import { IAnalyticsIndividualQuestionFetchResponse } from './api';
import Attachments from './Attachments';
import AttemptPage, { ActivityStoppedEvent } from './AttemptPage';
import { isQuizSubmitted } from './functions';
import Questions from './Questions';
import ReorderQuestionsDialog from './ReorderQuestionsDialog';
import ScoringSchemeDialog from './ScoringSchemeDialog';

const MIN_REORDER_OPTIONS = 4;
const MAX_REORDER_OPTIONS = 10;

export interface IQuizProps {
  quiz: IQuiz;
  class: IClass;
  course: ICourse;
  courseRole: ICourseRole;
  isClassIncharge: boolean;
}

export interface IQuizState {
  isSavingTitle: boolean;
  isEditingTitle: boolean;
  editTitleField: string;
  editTitleFieldError: boolean;

  isEditInstructionsDialogOpen: boolean;
  editInstructionsField: string;
  editInstructionsAttachments: IAttachment[];

  isEditScoringSchemeDialogOpen: boolean;
  editScoringSchemeField: IQuizScoringScheme;

  isFloatingMenuOpen: boolean;

  isEditingQuestion: IQuizQuestion | null;
  isDeleteQuestionDialogVisible: boolean;

  editQuestionScreen: IQuizQuestionType | null;
  editQuestionField: string;
  editQuestionFieldError: boolean;

  isQuestionAlertOpen: boolean;

  editQuestionTFSelected: 't' | 'f' | null;
  editQuestionTFError: boolean;

  editQuestionMCQOptionsField: string[];
  editQuestionMCQOptionError: boolean[];
  editQuestionMCQOptionsSelected: boolean[];
  editQuestionMCQNoOptionSelectedError: boolean;

  editQuestionRQOptions: {
    value: string;
    orderKey: string;
    hasError: boolean;
  }[];
  /** used as buffer during animation of option swapping */
  editQuestionRQOptionsBuffer: {
    fromIndex: number;
    toIndex: number;
    isOnTop: boolean;
  }[];

  isCopyDialogOpen: boolean;
  isMoveDialogOpen: boolean;

  isReorderDialogOpen: boolean;

  isPublishDialogOpen: boolean;
  isPublishPrefsDialogVisible: boolean;

  isStopDialogOpen: boolean;

  isDeleteDialogOpen: boolean;
  isQuestionAnalyticsDialogOpen: boolean;
  quizAnalyticsForQuestion: IQuizQuestion | null;

  // individual question analytics
  questionAnalytics?: IAnalyticsIndividualQuestionFetchResponse;

  showAnswers: boolean;
  showAttemptPage: boolean;
  editQuizDialog: {
    isEditingQuiz: boolean;
    questions: IQuizQuestion[];
    questionInEditing: string;
    updatedTitle: string;
    quiz: IQuiz;
    isupdateConfirmationOpen: boolean;
    toNotify: 0 | 1;

    isSavingTitle: boolean;
    isEditingTitle: boolean;
    editTitleField: string;
    editTitleFieldError: boolean;

    isEditInstructionsDialogOpen: boolean;
    editInstructionsField: string;
    editInstructionsAttachments: IAttachment[];

    isEditingQuestion: IQuizQuestion | null;
    isDeleteQuestionDialogVisible: boolean;
    editQuestionScreen: IQuizQuestionType | null;
    editQuestionTFSelected: 't' | 'f' | null;
    editQuestionField: string;
    editQuestionFieldError: boolean;
    editQuestionTFError: boolean;
    isQuestionAlertOpen: boolean;
    editQuestionMCQOptionsField: string[];
    editQuestionMCQOptionError: boolean[];
    editQuestionMCQOptionsSelected: boolean[];
    editQuestionMCQNoOptionSelectedError: boolean;

    editQuestionRQOptions: {
      orderKey: string;
      value: string;
      hasError: boolean;
    }[];
    /** used as buffer during animation of option swapping */
    editQuestionRQOptionsBuffer: {
      fromIndex: number;
      toIndex: number;
      isOnTop: boolean;
    }[];
  };
}
export const defaultInstructions =
  'There are no special instructions. Just ' + 'attempt the questions. Best of luck!';
export class Quiz extends IComponent<IQuizProps, IQuizState> {
  private pusherEventSub: Subscription;

  public componentWillMount() {
    const { courseRole, quiz, class: cls } = this.getProps();

    const showAttemptPage =
      courseRole === 'student' &&
      !isQuizSubmitted(quiz) &&
      ((quiz.details.dueDateType === 'manual' && quiz.details.dueDateTime === -1) ||
        datetime.unix() <= quiz.details.dueDateTime ||
        quiz.details.allowLate === 1);

    const initialState: IQuizState = {
      showAttemptPage,
      isEditingTitle: false,
      isSavingTitle: false,
      editTitleField: quiz.details.title,
      editTitleFieldError: false,
      isEditInstructionsDialogOpen: false,
      editInstructionsField: quiz.details.instructions,
      editInstructionsAttachments: quiz.details.attachments,

      isEditScoringSchemeDialogOpen: false,
      editScoringSchemeField: quiz.details.scoring,

      isFloatingMenuOpen: false,

      isEditingQuestion: null,
      isDeleteQuestionDialogVisible: false,

      editQuestionScreen: null,
      editQuestionField: '',
      editQuestionFieldError: false,

      isQuestionAlertOpen: false,

      editQuestionTFSelected: null,
      editQuestionTFError: false,

      editQuestionMCQOptionsField: ['', '', '', ''],
      editQuestionMCQOptionError: [false, false, false, false],
      editQuestionMCQOptionsSelected: [false, false, false, false],
      editQuestionMCQNoOptionSelectedError: false,

      editQuestionRQOptions: [],
      editQuestionRQOptionsBuffer: [],

      isCopyDialogOpen: false,
      isMoveDialogOpen: false,
      isReorderDialogOpen: false,

      isPublishDialogOpen: false,
      isPublishPrefsDialogVisible: false,

      isStopDialogOpen: false,

      isDeleteDialogOpen: false,
      isQuestionAnalyticsDialogOpen: false,
      quizAnalyticsForQuestion: null,
      showAnswers: false,
      editQuizDialog: {
        isEditingQuiz: false,
        questions: this.questions(),
        questionInEditing: '',
        updatedTitle: quiz.details.title,
        quiz: quiz,
        isupdateConfirmationOpen: false,
        toNotify: 1,

        isEditingTitle: false,
        isSavingTitle: false,
        editTitleField: quiz.details.title,
        editTitleFieldError: false,
        isEditInstructionsDialogOpen: false,
        editInstructionsField: quiz.details.instructions,
        editInstructionsAttachments: quiz.details.attachments,

        isEditingQuestion: null,
        isDeleteQuestionDialogVisible: false,
        editQuestionScreen: null,
        editQuestionTFSelected: null,
        editQuestionField: '',
        editQuestionFieldError: false,
        editQuestionTFError: false,
        isQuestionAlertOpen: false,
        editQuestionMCQOptionsField: ['', '', '', ''],
        editQuestionMCQOptionError: [false, false, false, false],
        editQuestionMCQOptionsSelected: [false, false, false, false],
        editQuestionMCQNoOptionSelectedError: false,

        editQuestionRQOptions: [],
        editQuestionRQOptionsBuffer: [],
      },
    };

    this.setState(initialState);

    if (
      courseRole !== 'student' ||
      isQuizSubmitted(quiz) ||
      (quiz.details.dueDateTime < datetime.unix() && !quiz.details.allowLate)
    ) {
      dispatch(Actions.fetchQuizData(quiz._id, cls._id)).then(() => {
        this.setState({
          editQuizDialog: {
            ...this.getState().editQuizDialog,
            questions: this.questions(),
          },
        });
      });
    }
    dispatch(appActions.setContext('quiz'));
    dispatch(appActions.startTip(true));
    setTimeout(() => {
      const actionbar = document.getElementById('action-bar');
      actionbar ? actionbar.focus() : null;
    }, 0);

    this.pusherEventSub = pusherService.events.subscribe((e: ActivityStoppedEvent) => {
      const { showAttemptPage } = this.getState();
      const data = e.payload;

      if (
        !showAttemptPage &&
        e.event === 'activityStopped' &&
        data.activityType === 'quiz' &&
        data.activityId === quiz._id
      ) {
        // refresh quiz data if not on attempt page
        dispatch(Actions.fetchQuizData(quiz._id, cls._id));
      }
    });
  }

  private questions() {
    const current = getStore().getState().quizzes.current;
    if (!current) {
      return [];
    }
    return [...current.questions];
  }

  public componentWillUnmount() {
    this.pusherEventSub.unsubscribe();
    dispatch(Actions.clearQuizData(undefined));
  }

  private isAccessible: boolean | undefined = false;

  public render() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const isMobile = getStore().getState().app.isMobile;
    const { class: cls, quiz, course } = this.getProps();
    const { showAttemptPage } = this.getState();

    if (showAttemptPage) {
      return AttemptPage({
        quiz,
        course,
        cls: this.getProps().class,
        onClose: (shouldRefetchQuizData = false) => {
          this.setState({ showAttemptPage: false });
          if (shouldRefetchQuizData) {
            dispatch(Actions.fetchQuizData(quiz._id, cls._id));
          }
        },
      });
    }

    return h('div.activity__wrapper', [
      isMobile || isIpad ? this.actionBar() : null,
      ContentView(
        h(
          utils.getHTMLTagSelector('div', [
            'activity',
            this.isActionBarVisible() ? 'with-action-bar' : '',
          ]),
          [
            h('div.activity__content', [
              isMobile || isIpad ? null : this.actionBar(),
              ...this.floatingButtons(),
              this.floatingButtonTip(),
              this.editQuestionScreen(),
              this.classTimings(),
              this.isPreClassWarningVisible() ? this.preClassTimeWarning() : null,
              this.prePublishView(),
              this.publishedScreen(),
              this.creatorView(),
              this.prePublishMessages(),
              ...this.dialogs(),
            ]),
            this.submissionStatus(),
          ]
        )
      ),
    ]);
  }

  private submissionStatus() {
    const { courseRole, quiz } = this.getProps();
    if (courseRole !== 'student') {
      return null;
    } else {
      if (isQuizSubmitted(quiz) && quiz.userData && quiz.userData.submittedOn) {
        return this.bottomFloatingBar(
          `Submitted on ${datetime.format(
            quiz.userData.submittedOn,
            'MMM DD, YYYY [at] hh:mm A'
          )} ${quiz.userData.status === 'late' ? '(Late)' : ''}`,
          quiz.userData.status === 'late' ? colors.orange : colors.green
        );
      } else {
        if (quiz.details.dueDateTime < datetime.unix() && !quiz.details.allowLate) {
          return this.bottomFloatingBar('Unsubmitted. You missed the deadline.', colors.red);
        } else {
          return null;
        }
      }
    }
  }

  private bottomFloatingBar(text: string, color: string) {
    return BottomFloatingBar({
      text,
      color,
    });
  }

  private actionButtons(): IActionButton[] {
    return [
      {
        classNames: ['red'],
        label: 'Delete',
        disabled: !this.isDeleteEnabled(),
        onclick: () => this.openDeleteDialog(),
        icon: {
          type: 'view',
          view: Icon(icons.trash),
        },
      },
      {
        classNames: ['blue'],
        label: 'Copy',
        onclick: () => {
          this.lastFocusedElement = document.activeElement;
          utils.unsetTabIndices();
          this.setState({
            isCopyDialogOpen: true,
          });
        },
        disabled: !this.isCopyEnabled(),
        icon: {
          type: 'view',
          view: Icon(icons.copy),
        },
      },
      {
        classNames: ['blue'],
        label: 'Move',
        disabled: !this.isMoveEnabled(),
        onclick: () => this.openMoveDialog(),
        icon: {
          type: 'view',
          view: Icon(icons.move),
        },
      },
      {
        classNames: ['orange'],
        label: this.canEditQuiz() ? 'Edit' : 'Publish',
        disabled: !(this.canEditQuiz() || this.isPublishButtonEnabled()),
        onclick: () => (this.canEditQuiz() ? this.openEditQuizDialog() : this.openPublishDialog()),
        icon: {
          type: 'view',
          view: this.canEditQuiz() ? Icon(icons.pencil) : Icon(icons.publish),
        },
      },
    ];
  }

  private openEditQuizDialog() {
    const questions = cloneDeep(this.questions());
    this.setState({
      editQuizDialog: {
        ...this.getState().editQuizDialog,
        isEditingQuiz: true,
        toNotify: 1,
        questions: questions,
      },
    });
  }

  private isQuizChanged() {
    const { quiz: newQuiz, questions: newQuestions } = this.getState().editQuizDialog;
    const { quiz: oldQuiz } = this.getProps();
    const oldQuestions = this.questions();
    return (
      newQuiz.details.instructions === oldQuiz.details.instructions &&
      newQuiz.details.title === oldQuiz.details.title &&
      (isEqual(newQuestions, oldQuestions) || newQuestions.length === 0)
    );
  }

  private EditQuizDialog() {
    const { editQuizDialog } = this.getState();
    const { isEditingQuiz } = editQuizDialog;

    if (!isEditingQuiz) return null;

    return Dialog(
      {
        open: true,
        title: 'Editing Quiz',
        style: {
          width: '40em',
          padding: '0em',
          paddingBottom: '1em',
          backgroundColor: colors.backgroundColor,
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        bodyStyle: {
          padding: '0em',
          paddingLeft: '0em',
          paddingRight: '0em',
        },
        primaryAction: {
          label: 'UPDATE',
          disabled: this.isQuizChanged(),
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () =>
            this.setState({
              editQuizDialog: {
                ...this.getState().editQuizDialog,
                isupdateConfirmationOpen: true,
              },
            }),
        },
        secondaryAction: {
          label: 'Cancel',
          onclick: () => {
            this.setState({
              editQuizDialog: {
                ...this.getState().editQuizDialog,
                isEditingQuiz: false,
              },
            });
          },
          mobileLabel: h('i.fa.fa-times', []),
        },
      },
      [
        h('div', style([pad('1rem')]), [
          this.editQuizTitleEditView(),
          this.editQuizInstructionsEditView(),
          this.editQuizQuestionsView(),
        ]),
      ]
    );
  }

  private canEditQuiz() {
    const { quiz, isClassIncharge } = this.getProps();
    return quiz.details.published && isClassIncharge;
  }

  private isPublishButtonEnabled() {
    const { course, quiz, isClassIncharge } = this.getProps();
    return !course.isArchived && !quiz.details.published && isClassIncharge;
  }

  private actionBar() {
    if (!this.isActionBarVisible()) return null;
    return ActionBar(this.actionButtons(), this.isAccessible);
  }

  private classTimings() {
    const cls = this.getProps().class;
    const { quiz } = this.getProps();
    const toBeDone = {
      preClass: 'Pre-class',
      inClass: 'In-class',
    }[quiz.details.toBeDone];

    return h(
      'div.cell',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        h('span.cell__label', [
          `${toBeDone} quiz for the class on `,
          datetime.format(cls.details.scheStartTime, 'MMM DD, YYYY [at] hh:mm A'),
        ]),
      ]
    );
  }
  private preClassTimeWarning() {
    const cls = this.getProps().class;
    const timeDiff = datetime.format(
      cls.details.scheStartTime - 30 * 60,
      'MMM DD, YYYY [at] hh:mm A'
    );
    return h('div.cell', [
      h('span.cell__label', [
        `You must publish this activity by ${timeDiff}`,
        ' for students to attempt it before the class',
      ]),
    ]);
  }

  private isPreClassWarningVisible() {
    const cls = this.getProps().class;
    const quiz = this.getProps().quiz;
    return (
      datetime.diff(datetime.fromUnix(cls.details.scheStartTime), datetime.now(), 'minutes') < 60 &&
      quiz.details.toBeDone === 'preClass' &&
      quiz.details.published === 0
    );
  }

  private prePublishView() {
    const { isClassIncharge, quiz } = this.getProps();
    if (quiz.details.published) return null;
    return h('div.activity__section', [
      isClassIncharge ? this.scoringEditView() : null,
      isClassIncharge ? this.publishPrefsEditView() : null,
      isClassIncharge ? this.titleEditView() : null,
      isClassIncharge ? this.instructionsEditView() : null,
      !isClassIncharge ? this.teamMemberPrePublishView() : null,
      this.questionsView(),
    ]);
  }

  private teamMemberPrePublishView() {
    const { quiz } = this.getProps();
    const title = (quiz.details.title || 'Untitled').trim();
    const instructions = quiz.details.instructions.trim() || 'No instructions yet';
    return h('div', [
      h('div.activity__title', title),
      h('div.activity__description', [Viewer(instructions)]),
    ]);
  }

  private scoringEditView() {
    const { quiz } = this.getProps();
    return h(
      'div.panel.panel--inline',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        onclick: () => this.openEditScoringSchemeDialog(),
      },
      [
        h('div.panel__header', [
          h('span', 'Scoring'),
          h('span.pointer.fs-sm.fc-light-grey', [
            utils.capitalize(quiz.details.scoring),
            h('i.fa.fa-pencil'),
          ]),
        ]),
      ]
    );
  }

  private showPublishPrefsDialog(isPublishing: boolean) {
    this.lastFocusedElement = document.activeElement;
    utils.unsetTabIndices();
    this.setState({
      isPublishDialogOpen: isPublishing,
      isPublishPrefsDialogVisible: true,
    });
  }

  private closePublishPrefsDialog = () => {
    this.setState({
      isPublishDialogOpen: false,
      isPublishPrefsDialogVisible: false,
    });
    utils.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
  };

  private afterPublish = () => {
    this.setState({
      editQuizDialog: {
        isEditingQuiz: false,
        questions: this.questions(),
        questionInEditing: '',
        updatedTitle: this.getProps().quiz.details.title,
        quiz: this.getProps().quiz,
        isupdateConfirmationOpen: false,
        toNotify: 1,

        isEditingTitle: false,
        isSavingTitle: false,
        editTitleField: this.getProps().quiz.details.title,
        editTitleFieldError: false,
        isEditInstructionsDialogOpen: false,
        editInstructionsField: this.getProps().quiz.details.instructions,
        editInstructionsAttachments: this.getProps().quiz.details.attachments,

        isEditingQuestion: null,
        isDeleteQuestionDialogVisible: false,
        editQuestionScreen: null,
        editQuestionTFSelected: null,
        editQuestionField: '',
        editQuestionFieldError: false,
        editQuestionTFError: false,
        isQuestionAlertOpen: false,
        editQuestionMCQOptionsField: ['', '', '', ''],
        editQuestionMCQOptionError: [false, false, false, false],
        editQuestionMCQOptionsSelected: [false, false, false, false],
        editQuestionMCQNoOptionSelectedError: false,

        editQuestionRQOptions: [],
        editQuestionRQOptionsBuffer: [],
      },
    });
    this.closePublishPrefsDialog();
  };

  private publishPrefsDialog() {
    const { class: cls, quiz } = this.getProps();
    const { isPublishPrefsDialogVisible, isPublishDialogOpen } = this.getState();

    if (!isPublishDialogOpen && !isPublishPrefsDialogVisible) return null;

    return QuizPrefsDialog({
      cls,
      quiz,
      isPublishing: isPublishDialogOpen,
      open: true,
      onPublish: this.afterPublish,
      onClose: this.closePublishPrefsDialog,
    });
  }

  private publishPrefsEditView() {
    const { quiz } = this.getProps();

    return h('div', [
      h(
        'div.panel.panel--inline',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => this.showPublishPrefsDialog(false),
        },
        [
          h('div.panel__header', [
            h('span', 'Publish preferences'),
            h('span.pointer.fs-sm.fc-light-grey', [h('i.fa.fa-pencil')]),
          ]),
        ]
      ),
      quiz.details.toBeDone === 'inClass'
        ? h('div.fc-orange', style([pad('0 1rem'), mt('-0.5rem'), mb('1rem')]), [
            `Saving "Publish preferences" right now helps you `,
            `save precious time during the lecture`,
          ])
        : null,
    ]);
  }

  private instructionsEditView() {
    const { quiz } = this.getProps();
    if (quiz.details.published) return null;
    const quizInstructions = quiz.details.instructions.trim();
    const hasAttachments = quiz && quiz.details.attachments.length > 0;
    const instructionsView = quizInstructions
      ? [
          Viewer(quizInstructions),
          hasAttachments
            ? h('div', {}, [
                h(
                  'div',
                  style([
                    'grey',
                    {
                      marginTop: '1em',
                      marginBottom: '.57em',
                      color: '#7a7a7a',
                      fontSize: '0.8em',
                      fontWeight: '700',
                      textTransform: 'uppercase',
                    },
                  ]),
                  'Attachments'
                ),
                ...Attachments(quiz),
              ])
            : h('div'),
        ]
      : this.isEditEnabled()
      ? [
          "This quiz doesn't have any instructions yet. ",
          'Click on the ',
          h('i.fa.fa-pencil'),
          ' icon to add instructions',
        ]
      : "This quiz doesn't have any instructions yet.";
    return h('div.panel', this.editableCardAttrs(), [
      h('div.panel__header', [
        h('div.fs-lg', 'Instructions'),
        this.isEditEnabled()
          ? h('i.fa.fa-pencil.ripple', {
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => this.openEditInstructionsDialog(),
            })
          : null,
      ]),
      h(
        utils.getHTMLTagSelector('div', [
          'panel__content',
          quizInstructions ? '' : 'fs-sm',
          quizInstructions ? '' : 'fc-light-grey',
        ]),
        instructionsView
      ),
    ]);
  }

  private editQuizInstructionsEditView() {
    const { quiz } = this.getState().editQuizDialog;
    const quizInstructions = quiz.details.instructions.trim();
    const hasAttachments = quiz && quiz.details.attachments.length > 0;
    const instructionsView = quizInstructions
      ? [
          Viewer(quizInstructions),
          hasAttachments
            ? h('div', {}, [
                h(
                  'div',
                  style([
                    'grey',
                    {
                      marginTop: '1em',
                      marginBottom: '.57em',
                      color: '#7a7a7a',
                      fontSize: '0.8em',
                      fontWeight: '700',
                      textTransform: 'uppercase',
                    },
                  ]),
                  'Attachments'
                ),
                ...Attachments(quiz, true),
              ])
            : h('div'),
        ]
      : [
          "This quiz doesn't have any instructions yet. ",
          'Click on the ',
          h('i.fa.fa-pencil'),
          ' icon to add instructions',
        ];
    return h('div.panel', this.editableCardAttrs(), [
      h('div.panel__header', [
        h('div', 'Instructions'),
        h('i.fa.fa-pencil.ripple', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () =>
            this.setState({
              editQuizDialog: {
                ...this.getState().editQuizDialog,
                isEditInstructionsDialogOpen: true,
                editInstructionsField: quizInstructions,
              },
            }),
        }),
      ]),
      h(
        utils.getHTMLTagSelector('div', [
          'panel__content',
          quizInstructions ? '' : 'fs-sm',
          quizInstructions ? '' : 'fc-light-grey',
        ]),
        instructionsView
      ),
    ]);
  }

  private editTitleDialog() {
    const { isEditingTitle, editTitleField, editTitleFieldError } = this.getState();
    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Quiz Title',
        open: isEditingTitle,
        hasInput: true,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                isEditingTitle: false,
              }).then(() => {
                utils.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              }),
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.saveTitleHandler(),
          }),
        ],
      },
      [
        h('div.textfield-container', style(['flex', 'alignCenter', pad('0.5rem')]), [
          TextField({
            value: editTitleField,
            focusOnMount: true,
            errorText: editTitleFieldError ? 'Please enter a title' : undefined,
            placeholder: 'Enter title here',
            maxLength: 140,
            oninput: (e) =>
              this.setState({
                editTitleField: e.target.value,
                editTitleFieldError: false,
              }),
            onenter: () => this.saveTitleHandler(),
          }),
        ]),
      ]
    );
  }

  private editQuizPublishDialog() {
    const { editQuizDialog } = this.getState();
    const { isupdateConfirmationOpen } = editQuizDialog;

    if (!isupdateConfirmationOpen) return null;

    return Alert(
      {
        open: true,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('NO', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                editQuizDialog: {
                  ...this.getState().editQuizDialog,
                  isupdateConfirmationOpen: false,
                  toNotify: 1,
                },
              }),
          }),
          FlatButton('YES', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.updatePublishedQuiz(),
          }),
        ],
      },
      [
        h('div', [
          h('p', 'Are you sure you want to publish these changes?'),
          h('div', style(['flex', 'fullWidth', pt('1rem'), 'whiteBackground', 'spaceBetween']), [
            h('span', 'Send notifications to students'),
            Toggle({
              selected: this.getState().editQuizDialog.toNotify === 1,
              ontoggle: () =>
                this.setState({
                  editQuizDialog: {
                    ...this.getState().editQuizDialog,
                    toNotify: this.getState().editQuizDialog.toNotify === 0 ? 1 : 0,
                  },
                }),
            }),
          ]),
        ]),
      ]
    );
  }
  private async updatePublishedQuiz() {
    const { editQuizDialog: state } = this.getState();
    const { quiz } = this.getProps();

    const newQuestions = state.questions.map((q) => {
      switch (q.details.type) {
        case 'tf':
          return {
            questionId: q._id,
            type: q.details.type,
            description: {
              text: q.details.description.text,
            },
            answerKey: q.details.answerKey ? q.details.answerKey : '',
          };
        case 'reorder':
          return {
            questionId: q._id,
            type: q.details.type,
            description: {
              text: q.details.description.text,
            },
            options: q.details.options,
          };
        case 'mcq':
        default:
          return {
            questionId: q._id,
            type: q.details.type,
            description: {
              text: q.details.description.text,
            },
            options: q.details.options,
            answerKey: q.details.answerKey ? q.details.answerKey : '',
          };
      }
    });

    let updateQuizData: api.IUpdateQuizRequest = {
      classId: this.getProps().class._id,
      quizId: this.getProps().quiz._id,
      title: state.quiz.details.title,
      instructions: state.quiz.details.instructions,
      attachments: state.quiz.details.attachments,
      toNotify: state.toNotify,
      questionsOrder: state.questions.map((q, index) => ({
        questionId: q._id,
        order: index + 1,
      })),
    };

    if (quiz.stats && quiz.stats.numSubmitted === 0) {
      updateQuizData = {
        ...updateQuizData,
        questions: newQuestions ? newQuestions : [],
      };
    }

    await dispatch(Actions.updateQuiz(updateQuizData, [...state.questions])).then(() => {
      this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          isupdateConfirmationOpen: false,
          isEditingQuiz: false,
          editQuestionScreen: null,
          isEditingQuestion: null,
          editQuestionTFSelected: null,
          editQuestionFieldError: false,
          editQuestionField: '',
          editQuestionTFError: false,
          editQuestionMCQOptionError: [false, false, false, false],
          editQuestionMCQOptionsField: ['', '', '', ''],
          editQuestionMCQOptionsSelected: [],
          editQuestionMCQNoOptionSelectedError: false,
        },
      });
    });
  }

  private editQuizEditTitleDialog() {
    const { isEditingTitle, editTitleField, editTitleFieldError } = this.getState().editQuizDialog;
    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Quiz Title',
        open: isEditingTitle,
        hasInput: true,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                editQuizDialog: {
                  ...this.getState().editQuizDialog,
                  isEditingTitle: false,
                },
              }),
            // .then(() => {
            //     utils.resetTabIndices();
            //     (this.lastFocusedElement as HTMLElement).focus();
            // })
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.saveTitleHandler(true),
          }),
        ],
      },
      [
        h('div.textfield-container', style(['flex', 'alignCenter', pad('0.5rem')]), [
          TextField({
            value: editTitleField,
            focusOnMount: true,
            errorText: editTitleFieldError ? 'Please enter a title' : undefined,
            placeholder: 'Enter title here',
            maxLength: 140,
            oninput: (e) =>
              this.setState({
                editQuizDialog: {
                  ...this.getState().editQuizDialog,
                  editTitleField: e.target.value,
                  editTitleFieldError: false,
                },
              }),
            onenter: () => this.saveTitleHandler(true),
          }),
        ]),
      ]
    );
  }

  private titleEditView() {
    const { quiz } = this.getProps();
    const { isEditingTitle, isSavingTitle } = this.getState();

    if (quiz.details.published) return null;

    const title =
      quiz.details.title.trim() ||
      (this.isEditEnabled()
        ? [
            "This quiz doesn't have a title yet. ",
            'Click on the ',
            h('i.fa.fa-pencil'),
            ' icon to add a title',
          ]
        : "This quiz doesn't have a title yet.");

    return h('div.panel', this.editableCardAttrs(), [
      h('div.panel__header', [
        h('div', 'Title'),
        isSavingTitle
          ? Loader({
              width: '1.1em',
              height: '1.1em',
            })
          : h('i.fa.fa-pencil.ripple#edit-title', {
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick:
                isEditingTitle && !isSavingTitle
                  ? () => this.saveTitleHandler()
                  : () => {
                      this.lastFocusedElement = document.activeElement;
                      utils.unsetTabIndices();
                      this.setState({
                        isEditingTitle: true,
                      });
                    },
            }),
      ]),

      this.editTitleDialog(),
      h(
        utils.getHTMLTagSelector('div', [
          'panel__content',
          quiz.details.title.trim() ? '' : 'fs-sm',
          quiz.details.title.trim() ? '' : 'fc-light-grey',
        ]),
        title
      ),
    ]);
  }

  private editQuizTitleEditView() {
    // const { quiz } = this.getProps();
    const { isEditingTitle, isSavingTitle, updatedTitle } = this.getState().editQuizDialog;
    const title = updatedTitle.trim() || 'Untitled';
    return h('div.panel', this.editableCardAttrs(), [
      h('div.panel__header', [
        h('div', 'Title'),
        isSavingTitle
          ? Loader({
              width: '1.1em',
              height: '1.1em',
            })
          : h('i.fa.fa-pencil.ripple#edit-title', {
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick:
                isEditingTitle && !isSavingTitle
                  ? () => this.saveTitleHandler(true)
                  : () => {
                      // this.lastFocusedElement = document.activeElement;
                      // utils.unsetTabIndices();
                      this.setState({
                        editQuizDialog: {
                          ...this.getState().editQuizDialog,
                          isEditingTitle: true,
                        },
                      });
                    },
            }),
      ]),
      this.editQuizEditTitleDialog(),
      h(
        utils.getHTMLTagSelector('div', [
          'panel__content',
          title.trim() ? '' : 'fs-sm',
          title.trim() ? '' : 'fc-light-grey',
        ]),
        title
      ),
    ]);
  }

  private editableCardAttrs(): any {
    return {
      tabIndex: this.isAccessible ? 0 : undefined,
    };
  }

  private canReorderQuestions() {
    const quizData = getStore().getState().quizzes.current;
    const { isClassIncharge } = this.getProps();
    return quizData.questions.length > 1 && isClassIncharge;
  }

  private reorderQuestionDialog() {
    const quizData = getStore().getState().quizzes.current;
    const { class: cls, quiz } = this.getProps();
    const { editQuizDialog, isReorderDialogOpen } = this.getState();

    if (!isReorderDialogOpen) return null;

    const questions = utils.makeObjectWithKey(editQuizDialog.questions, '_id');

    return ReorderQuestionsDialog({
      classId: cls._id,
      isEditingQuiz: editQuizDialog.isEditingQuiz,
      isOpen: true,
      onClose: (questionsOrder) => {
        this.setState({
          isReorderDialogOpen: false,
          editQuizDialog: {
            ...editQuizDialog,
            questions: !editQuizDialog.isEditingQuiz
              ? editQuizDialog.questions
              : questionsOrder.map(({ questionId, order }) => {
                  const question = questions[questionId];
                  return {
                    ...question,
                    details: { ...question.details, order },
                  };
                }),
          },
        });
      },
      quizId: quiz._id,
      questions: editQuizDialog.isEditingQuiz ? editQuizDialog.questions : quizData.questions,
    });
  }

  private reorderQuesionsButton() {
    const { editQuizDialog } = this.getState();
    const { isEditingQuiz } = editQuizDialog;

    if (!this.canReorderQuestions()) return;

    return SvgIcon({
      role: 'button',
      'data-tooltip': 'Reorder questions',
      className: cn('quiz__subtitle__action', 'icon-24px', 'pointer', 'tooltip', {
        tooltip_left: isEditingQuiz,
      }),
      icon: ReorderIcon,
      onclick: () => {
        this.setState({
          isReorderDialogOpen: true,
        });
      },
      tabIndex: this.isAccessible ? 0 : undefined,
    });
  }

  private showAnswersButton() {
    const quizData = getStore().getState().quizzes.current;
    const { courseRole } = this.getProps();
    const { showAnswers } = this.getState();

    if (courseRole === 'student' || !quizData || !quizData.questions.length) return;

    return SvgIcon({
      role: 'button',
      'data-tooltip': showAnswers ? 'Hide answers' : 'Show answers',
      className: 'quiz__subtitle__action icon-24px tooltip pointer',
      icon: showAnswers ? EyeCloseIcon : EyeOpenIcon,
      onclick: () => this.toggleAnswers(),
      tabIndex: this.isAccessible ? 0 : undefined,
    });
  }

  private questionsView() {
    const quizData = getStore().getState().quizzes.current;
    const { showAnswers } = this.getState();
    const { quiz } = this.getProps();

    return h('div.activity__section', [
      h('div.quiz__subtitle', [
        h(
          'div.activity__subtitle',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          'Questions'
        ),
        h('div', style(['flex', 'alignCenter']), [
          this.showAnswersButton(),
          this.reorderQuesionsButton(),
        ]),
      ]),
      quizData && quizData.questions.length > 0
        ? Questions({
            mode: 'answer',
            hideAnswers: !showAnswers,
            isEditable: this.isEditEnabled(),
            quiz: quiz,
            openEditQuestionScreen: this.openEditQuestionScreen.bind(this),
            isAccessible: this.isAccessible,
          })
        : h('p.fc-light-grey.fs-md', 'No questions added yet'),
      this.analyticsDialog(),
    ]);
  }

  private canAddSortingQuestion() {
    const { course } = this.getProps();
    return course.isPro || course.courseType === 'synced';
  }

  private editQuizQuestionsView() {
    const { questions } = this.getState().editQuizDialog;
    const { quiz } = this.getProps();

    if (quiz.stats && quiz.stats.numSubmitted !== 0) {
      return h('div', style(['orange', 'flex', pad('1rem')]), [
        h('i.fa.fa-exclamation-triangle', style([mr('0.5rem')])),
        h('div', 'You cannot edit quiz questions after a few submissions have been made.'),
      ]);
    }

    return h('div', style([mt('0.5em')]), [
      h('div', style(['flex', 'alignCenter', 'spaceBetween']), [
        h('div.activity__subtitle', 'QUESTIONS'),
        this.reorderQuesionsButton(),
      ]),
      questions.length > 0
        ? Questions({
            mode: 'answer',
            questions: questions,
            hideAnswers: !this.getState().showAnswers,
            isEditable: true,
            quiz: this.getProps().quiz,
            openEditQuestionScreen: this.openEditQuizEditQuestionScreen.bind(this),
            isAccessible: this.isAccessible,
          })
        : h('div', style(['lightGrey', 'large', pad('1rem'), 'textCenter']), [
            'No questions added yet',
          ]),
      RaisedButton('Add a True/False type question', {
        classNames: ['quiz__raised-button'],
        onclick: () => this.openEditQuizEditQuestionScreen('tf'),
      }),
      RaisedButton('Add a Multiple Choice type question', {
        classNames: ['quiz__raised-button'],
        onclick: () => this.openEditQuizEditQuestionScreen('mcq'),
      }),
      Tooltip({
        tooltipStyles: {
          textAlign: 'center',
          transform: 'translateX(-50%)',
          left: '50%',
        },
        text: !this.canAddSortingQuestion() ? "Available only in Acadly's Pro courses" : '',
        targetElement: RaisedButton('Add a Sorting type question', {
          disabled: !this.canAddSortingQuestion(),
          classNames: ['quiz__raised-button'],
          onclick: () => this.openEditQuizEditQuestionScreen('reorder'),
        }),
      }),
    ]);
  }

  private creatorView() {
    const { quiz } = this.getProps();
    const date = quiz.details.published ? quiz.details.publishedOn : quiz.details.createdOn;
    return h('div.user', [
      Avatar(quiz.details.createdBy.avatar, quiz.details.createdBy.name, {
        className: 'user__avatar',
      }),

      h('div.user__details.fc-light-grey', [
        h(
          'div',
          `${quiz.details.published ? 'Published' : 'Created'} by ${quiz.details.createdBy.name}`
        ),
        h('div', datetime.format(date, 'MMM DD, YYYY [at] hh:mm A')),
      ]),
    ]);
  }

  private prePublishMessages() {
    if (this.getProps().quiz.details.published) return null;
    const { isClassIncharge } = this.getProps();
    return h('div.activity__section.fc-orange.fs-sm', [
      h('p', [
        'The activity must be published by the class in-charge ',
        'for the students to be able to access it.',
      ]),
      !isClassIncharge ? h('p', 'Only the class in-charge can edit this activity.') : null,
    ]);
  }

  private canStopManually() {
    const { quiz, isClassIncharge } = this.getProps();
    return (
      isClassIncharge && quiz.details.dueDateType === 'manual' && quiz.details.dueDateTime === -1
    );
  }

  private publishedScreen() {
    const { quiz, courseRole } = this.getProps();
    const showAnswers = this.getState().showAnswers;

    if (!quiz.details.published) return null;
    const hasAttachments = quiz && quiz.details.attachments.length > 0;

    const infoCell = (label: string, value: string) =>
      h(
        'div.cell',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [h('span.cell__label', label), h('span.cell__value', value)]
      );

    const storeState = getStore().getState();
    const submission = storeState.quizzes.current
      ? storeState.quizzes.current.submission
      : undefined;

    const isOpen =
      (quiz.details.dueDateType === 'manual' && quiz.details.dueDateTime === -1) ||
      quiz.details.dueDateTime > datetime.unix();
    const publishDiff = datetime.fromNow(datetime.fromUnix(quiz.details.publishedOn));

    return h('div', [
      infoCell('Scoring', utils.capitalize(quiz.details.scoring)),
      this.canStopManually()
        ? h('div.quiz__manual-stop__wrapper', [
            h(
              'div.ripple.quiz__manual-stop',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                onClick: () =>
                  this.setState({
                    isStopDialogOpen: true,
                  }),
              },
              [h('span', 'Quiz is currently open'), h('span.quiz__manual-stop__button', 'CLOSE')]
            ),
            h(
              'div.quiz__manual-stop__note',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              `Published ${publishDiff}`
            ),
          ])
        : infoCell(
            isOpen ? 'Quiz closes' : 'Quiz closed',
            quiz.details.dueDateType === 'manual' && isOpen
              ? 'when instructor closes'
              : `at ${datetime.format(quiz.details.dueDateTime, 'hh:mm A, MMM DD, YYYY')}`
          ),
      infoCell('Late submissions', quiz.details.allowLate ? 'Allowed' : 'Not allowed'),
      courseRole !== 'student'
        ? infoCell('Total submissions', quiz.stats ? quiz.stats.numSubmitted.toString() : '0')
        : null,

      courseRole === 'student' &&
      quiz.details.deadlineFirst &&
      quiz.details.dueDateTime > datetime.unix() &&
      !(quiz.userData && quiz.userData.score)
        ? infoCell('Score', 'Available after deadline')
        : quiz.userData && quiz.userData.score
        ? infoCell(
            'Score',
            `${quiz.userData.score[quiz.details.scoring].toFixed(2).replace(/\.(00|0)$/, '')}/${
              quiz.userData.score.maxScore
            }`
          )
        : null,

      h(
        'div.activity__title',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        quiz.details.title || 'Untitled'
      ),

      h(
        'div.activity__description',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': quiz.details.instructions,
        },
        [Viewer(quiz.details.instructions)]
      ),

      hasAttachments
        ? h('div', style(['flex', 'column']), [
            h(
              'div',
              style([
                'grey',
                {
                  marginBottom: '.57em',
                  color: '#7a7a7a',
                  fontSize: '0.8em',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                },
              ]),
              'Attachments'
            ),
            ...Attachments(quiz),
          ])
        : null,

      h('div.quiz__subtitle', [
        h(
          'div.activity__subtitle',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          'Questions'
        ),
        this.showAnswersButton(),
      ]),
      courseRole === 'student'
        ? Questions({
            mode: 'submission',
            quiz,
            submission,
          })
        : Questions({
            mode: 'answer',
            quiz,
            onClickAnalytics: (id) => this.getAnalytics(id),
            hideAnswers: !showAnswers,
            isAccessible: this.isAccessible,
          }),
      this.analyticsDialog(),
    ]);
  }

  private toggleAnswers() {
    this.setState({
      showAnswers: !this.getState().showAnswers,
    });
  }

  private async saveTitleHandler(isEditingQuiz?: boolean) {
    if (isEditingQuiz) {
      const { editTitleField } = this.getState().editQuizDialog;
      if (editTitleField.trim().length === 0) {
        await this.setState({
          editQuizDialog: {
            ...this.getState().editQuizDialog,
            editTitleFieldError: true,
          },
        });
        return;
      } else {
        await this.setState({
          isSavingTitle: true,
        });

        await this.setState({
          editQuizDialog: {
            ...this.getState().editQuizDialog,
            isSavingTitle: false,
            isEditingTitle: false,
            updatedTitle: editTitleField,
            quiz: {
              ...this.getState().editQuizDialog.quiz,
              details: {
                ...this.getState().editQuizDialog.quiz.details,
                title: editTitleField,
              },
            },
          },
        });
        // .then(() => {
        //     utils.resetTabIndices();
        //     let btn = document.getElementById("edit-title");
        //     btn ? btn.focus() : null;
        // });
      }
      return;
    }
    const { editTitleField } = this.getState();
    if (editTitleField.trim().length === 0) {
      await this.setState({
        editTitleFieldError: true,
      });
      return;
    } else {
      await this.setState({
        isSavingTitle: true,
      });
      await this.editQuiz({
        title: editTitleField,
      });
      await this.setState({
        isSavingTitle: false,
        isEditingTitle: false,
      }).then(() => {
        utils.resetTabIndices();
        const btn = document.getElementById('edit-title');
        btn ? btn.focus() : null;
      });
    }
  }

  private isActionBarVisible() {
    return (
      this.isDeleteEnabled() ||
      this.isCopyEnabled() ||
      this.isMoveEnabled() ||
      this.isPublishButtonEnabled()
    );
  }

  private isDeleteEnabled() {
    const { course, quiz, isClassIncharge } = this.getProps();
    return !course.isArchived && !quiz.details.published && isClassIncharge;
  }

  private isCopyEnabled() {
    const { courseRole } = this.getProps();
    return courseRole === 'instructor' || courseRole === 'admin';
  }

  private isMoveEnabled() {
    const { course, quiz, isClassIncharge } = this.getProps();
    return !course.isArchived && !quiz.details.published && isClassIncharge;
  }

  private isPublishEnabled() {
    const { quiz, course, isClassIncharge } = this.getProps();
    const cls = this.getProps().class;
    if (quiz.details.toBeDone === 'inClass' && cls.details.status !== 'inSession') {
      return false;
    }
    return (
      !quiz.details.published &&
      !course.isArchived &&
      quiz.details.numQuestions > 0 &&
      isClassIncharge
    );
  }

  private editQuestionScreenTitle(editQuestionScreen: IQuizQuestionType) {
    switch (editQuestionScreen) {
      case 'tf':
        return 'Add True/False question';
      case 'mcq':
        return 'Add multiple choice question';
      case 'reorder':
        return 'Add a sorting type question';
      default:
        return '';
    }
  }

  private editTFQOptionsBody() {
    const { editQuestionTFSelected, editQuestionTFError, isQuestionAlertOpen } = this.getState();

    const TFOption = (option: 't' | 'f') => {
      const isSelected = editQuestionTFSelected === option;
      return h(
        utils.getHTMLTagSelector('div', [
          'quiz__add-question__option',
          isSelected ? 'selected' : '',
        ]),
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () =>
            this.setState({
              editQuestionTFSelected: option,
              editQuestionTFError: false,
            }),
        },
        [
          RadioButton({
            selected: isSelected,
            className: 'quiz__add-question__radio-button',
          }),
          h('span', option === 't' ? 'True' : 'False'),
        ]
      );
    };

    return h('div.quiz__add-question__options', [
      h('div.quiz__add-question__title', 'THE ABOVE STATEMENT IS'),
      TFOption('t'),
      TFOption('f'),
      Alert(
        {
          open: editQuestionTFError && isQuestionAlertOpen,
          style: {
            width: '20em',
          },
          actions: [
            FlatButton('OK', {
              type: 'secondary',
              onclick: () => this.setState({ isQuestionAlertOpen: false }),
            }),
          ],
        },
        [h('div', 'Please select a correct option')]
      ),
    ]);
  }

  private editMCQOptionsBody() {
    const {
      editQuestionMCQOptionsSelected,
      editQuestionMCQOptionsField,
      editQuestionMCQOptionError,
      editQuestionMCQNoOptionSelectedError,
      isQuestionAlertOpen,
    } = this.getState();

    const sectionTitle = (title: string) => h('div.quiz__add-question__title', title);

    return h('div.quiz__add-question__options', [
      sectionTitle('OPTIONS'),
      ...[0, 1, 2, 3]
        .map((index) => editQuestionMCQOptionsSelected[index])
        .map((selected, index) =>
          QuestionOptionEditor({
            value: editQuestionMCQOptionsField[index],
            subContext: 'question',
            selected: selected,
            index: index,
            tabIndex: this.isAccessible ? 0 : undefined,
            error: !!editQuestionMCQOptionError[index],
            oninput: (value) =>
              this.setState({
                editQuestionMCQOptionsField: utils.replaceIndex(
                  editQuestionMCQOptionsField,
                  value,
                  index
                ),
                editQuestionMCQOptionError: utils.replaceIndex(
                  this.getState().editQuestionMCQOptionError,
                  false,
                  index
                ),
              }),
          })
        ),

      sectionTitle('SPECIFY CORRECT OPTIONS'),
      h(
        'div.quiz__add-question__answer',
        ['A', 'B', 'C', 'D'].map((option, index) =>
          h(
            utils.getHTMLTagSelector('div', [
              'quiz__add-question__option-label',
              editQuestionMCQOptionsSelected[index] ? 'selected' : '',
            ]),
            {
              tabIndex: this.isAccessible ? 0 : undefined,
              key: option,
              onclick: () =>
                this.setState({
                  editQuestionMCQOptionsSelected: utils.replaceIndex(
                    editQuestionMCQOptionsSelected,
                    (selected) => !selected,
                    index
                  ),
                  editQuestionMCQNoOptionSelectedError: false,
                }),
            },
            option
          )
        )
      ),

      Alert(
        {
          open: editQuestionMCQNoOptionSelectedError && isQuestionAlertOpen,
          style: {
            width: '20em',
          },
          actions: [
            FlatButton('OK', {
              type: 'secondary',
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => {
                utils.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
                this.setState({
                  isQuestionAlertOpen: false,
                });
              },
            }),
          ],
        },
        [h('div', 'Please select at least one correct option')]
      ),
    ]);
  }

  private editRQOptionsBody() {
    const { editQuestionRQOptions } = this.getState();

    const sectionTitle = (title: string) => h('div.quiz__add-question__title', title);
    const getOptionId = (index: number) => 'question-option-editor-' + index.toString();

    return h('div.quiz__add-question__options', [
      sectionTitle('OPTIONS (IN CORRECT ORDER)'),
      ...editQuestionRQOptions.map((option, index) => {
        const { editQuestionRQOptionsBuffer } = this.getState();

        let style: CSS | undefined = undefined;
        const bufferItem = editQuestionRQOptionsBuffer.find((item) => item.fromIndex === index);

        const TRANSITION_TIMEOUT = 300; // in milliseconds

        if (bufferItem) {
          const targetOption = document.getElementById(getOptionId(bufferItem.toIndex));

          if (!targetOption) return;

          const offset = targetOption.getBoundingClientRect().height;

          style = {
            transform: `translateY(${
              bufferItem.toIndex - bufferItem.fromIndex > 0 ? offset : -offset
            }px)`,
            transition: `${TRANSITION_TIMEOUT}ms ease-in`,
            zIndex: bufferItem.isOnTop ? '1000' : '0',
            opacity: '0.75',
          };
        }

        return QuestionOptionEditor({
          style,
          value: option.value,
          id: getOptionId(index),
          enableImageInput: false,
          subContext: 'question',
          selected: false,
          index: index,
          tabIndex: this.isAccessible ? 0 : undefined,
          error: option.hasError,
          oninput: (value) => {
            this.setState({
              editQuestionRQOptions: utils.replaceIndex(
                editQuestionRQOptions,
                { value, orderKey: option.orderKey, hasError: false },
                index
              ),
            });
          },
          enableMove:
            index === 0 ? 'down' : index + 1 === editQuestionRQOptions.length ? 'up' : 'both',
          onMoveClick: (direction) => {
            if (bufferItem) return; // disable move during animation
            const nextIndex = direction === 'up' ? index - 1 : index + 1;

            setTimeout(() => {
              const { editQuestionRQOptions } = this.getState();
              const newOptions = editQuestionRQOptions.slice(0);
              newOptions.splice(index, 1);
              newOptions.splice(nextIndex, 0, option);
              this.setState({ editQuestionRQOptions: newOptions, editQuestionRQOptionsBuffer: [] });
            }, TRANSITION_TIMEOUT);

            this.setState({
              editQuestionRQOptionsBuffer: [
                {
                  fromIndex: index,
                  toIndex: nextIndex,
                  isOnTop: true,
                },
                {
                  fromIndex: nextIndex,
                  toIndex: index,
                  isOnTop: false,
                },
              ],
            });
          },
          deleteAction:
            editQuestionRQOptions.length > MIN_REORDER_OPTIONS
              ? () => {
                  this.setState({
                    editQuestionRQOptions: editQuestionRQOptions.filter((o) => o !== option),
                  });
                }
              : undefined,
        });
      }),
      RaisedButton([h('i.fa.fa-plus'), h('span', style([ml('0.5rem')]), 'Add another option')], {
        classNames: ['quiz__raised-button'],
        disabled: editQuestionRQOptions.length >= MAX_REORDER_OPTIONS,
        onclick: () => {
          this.setState({
            editQuestionRQOptions: [
              ...editQuestionRQOptions,
              {
                value: '',
                orderKey: '',
                hasError: false,
              },
            ],
          });
        },
      }),
    ]);
  }

  private editQuestionOptionsBody() {
    const { editQuestionScreen } = this.getState();

    switch (editQuestionScreen) {
      case 'tf':
        return this.editTFQOptionsBody();
      case 'mcq':
        return this.editMCQOptionsBody();
      case 'reorder':
        return this.editRQOptionsBody();
      default:
        return null;
    }
  }

  private editQuestionScreen() {
    const isMobile = getStore().getState().app.isMobile;
    const { editQuestionScreen, isEditingQuestion } = this.getState();

    if (!editQuestionScreen) return null;

    const body: View[] = [
      !isMobile
        ? h(
            'div.quiz__add-question__heading',
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            },
            this.editQuestionScreenTitle(editQuestionScreen)
          )
        : null,
      h('div.quiz__add-question__details', [
        Editor({
          value: this.getState().editQuestionField,
          subContext: 'question',
          title: 'Description',
          enableTextFormatting: true,
          enableFormulaInput: true,
          enableImageInput: true,
          oninput: (value) =>
            this.setState({
              editQuestionField: value,
              editQuestionFieldError: false,
            }),
          errorText: this.getState().editQuestionFieldError ? 'Please fill this field' : undefined,
          placeholder: 'Type question text here',
        }),
      ]),

      this.editQuestionOptionsBody(),

      isEditingQuestion && this.isEditEnabled()
        ? RaisedButton('Delete Question', {
            classNames: ['quiz__raised-button', 'danger'],
            onclick: () => this.deleteQuestionHandler(),
          })
        : null,
      this.deleteQuestionDialog(),

      ...(!isMobile
        ? [
            FloatingActionButton(
              {
                position: 'bottom-right',
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () =>
                  this.saveQuestion().then(() => {
                    // utils.resetTabIndices();
                    // (this.lastFocusedElement as HTMLElement).focus();
                  }),
              },
              [h('i.fa.fa-floppy-o', [])]
            ),
            FloatingActionButton(
              {
                position: 'bottom-left',
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: (e) => {
                  e.stopPropagation();
                  utils.resetTabIndices();
                  (this.lastFocusedElement as HTMLElement).focus();
                  this.closeEditQuestionScreen();
                },
                style: {
                  backgroundColor: colors.red,
                },
              },
              [Icon(icons.cross)]
            ),
          ]
        : []),
    ];

    if (!this.isEditEnabled()) return null;

    if (isMobile) {
      return Dialog(
        {
          open: editQuestionScreen !== null,
          title: 'Quiz question',
          bodyStyle: {
            padding: '0',
            height: '100%',
            backgroundColor,
          },
          primaryAction: {
            label: 'SAVE',
            mobileLabel: h('i.fa.fa-check', []),
            onclick: () => this.saveQuestion(),
          },
          secondaryAction: {
            label: 'CANCEL',
            mobileLabel: h('i.fa.fa-times', []),
            onclick: () => this.closeEditQuestionScreen(),
          },
        },
        body
      );
    } else {
      return editQuestionScreen !== null
        ? h('div.quiz__add-question', [ContentView(h('div', body))])
        : null;
    }
  }

  private editQuizEditQuestionScreenTitle() {
    const { editQuestionScreen, isEditingQuestion } = this.getState().editQuizDialog;

    if (!isEditingQuestion) return this.editQuestionScreenTitle(editQuestionScreen);

    switch (editQuestionScreen) {
      case 'tf':
        return 'Edit True/False question';
      case 'mcq':
        return 'Edit multiple choice question';
      case 'reorder':
        return 'Edit a sorting type question';
      default:
        return '';
    }
  }

  private editQuizEditTFQOptionsBody() {
    const { editQuestionTFError, editQuestionTFSelected } = this.getState().editQuizDialog;

    const TFOption = (option: 't' | 'f') => {
      const isSelected = editQuestionTFSelected === option;
      return h(
        utils.getHTMLTagSelector('div', [
          'quiz__add-question__option',
          isSelected ? 'selected' : '',
        ]),
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () =>
            this.setState({
              editQuizDialog: {
                ...this.getState().editQuizDialog,
                editQuestionTFSelected: option,
                editQuestionTFError: false,
              },
            }),
        },
        [
          RadioButton({
            selected: isSelected,
            className: 'quiz__add-question__radio-button',
          }),
          h('span', option === 't' ? 'True' : 'False'),
        ]
      );
    };

    return h('div.quiz__add-question__options', [
      h('div.quiz__add-question__title', 'THE ABOVE STATEMENT IS'),
      TFOption('t'),
      TFOption('f'),
      Alert(
        {
          open: editQuestionTFError,
          style: {
            width: '20em',
          },
          actions: [
            FlatButton('OK', {
              type: 'secondary',
              onclick: () =>
                this.setState({
                  editQuizDialog: {
                    ...this.getState().editQuizDialog,
                    editQuestionTFError: false,
                  },
                }),
            }),
          ],
        },
        [h('div', 'Please select a correct option')]
      ),
    ]);
  }

  private editQuizEditMCQOptionsBody() {
    const {
      isQuestionAlertOpen,
      editQuestionMCQOptionsSelected,
      editQuestionMCQOptionsField,
      editQuestionMCQOptionError,
      editQuestionMCQNoOptionSelectedError,
    } = this.getState().editQuizDialog;

    const sectionTitle = (title: string) => h('div.quiz__add-question__title', title);

    return h('div.quiz__add-question__options', [
      sectionTitle('OPTIONS'),
      ...[0, 1, 2, 3]
        .map((index) => editQuestionMCQOptionsSelected[index])
        .map((selected, index) =>
          QuestionOptionEditor({
            value: editQuestionMCQOptionsField[index],
            subContext: 'question',
            selected: selected,
            index: index,
            tabIndex: this.isAccessible ? 0 : undefined,
            error: !!editQuestionMCQOptionError[index],
            oninput: (value) =>
              this.setState({
                editQuizDialog: {
                  ...this.getState().editQuizDialog,
                  editQuestionMCQOptionsField: utils.replaceIndex(
                    editQuestionMCQOptionsField,
                    value,
                    index
                  ),
                  editQuestionMCQOptionError: utils.replaceIndex(
                    this.getState().editQuizDialog.editQuestionMCQOptionError,
                    false,
                    index
                  ),
                },
              }),
          })
        ),
      sectionTitle('SPECIFY CORRECT OPTIONS'),
      h(
        'div.quiz__add-question__answer',
        ['A', 'B', 'C', 'D'].map((option, index) =>
          h(
            utils.getHTMLTagSelector('div', [
              'quiz__add-question__option-label',
              editQuestionMCQOptionsSelected[index] ? 'selected' : '',
            ]),
            {
              tabIndex: this.isAccessible ? 0 : undefined,
              key: option,
              onclick: () =>
                this.setState({
                  editQuizDialog: {
                    ...this.getState().editQuizDialog,
                    editQuestionMCQOptionsSelected: utils.replaceIndex(
                      editQuestionMCQOptionsSelected,
                      (selected) => !selected,
                      index
                    ),
                    editQuestionMCQNoOptionSelectedError: false,
                  },
                }),
            },
            option
          )
        )
      ),
      Alert(
        {
          open: editQuestionMCQNoOptionSelectedError && isQuestionAlertOpen,
          style: {
            width: '20em',
          },
          actions: [
            FlatButton('OK', {
              type: 'secondary',
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => {
                this.setState({
                  editQuizDialog: {
                    ...this.getState().editQuizDialog,
                    isQuestionAlertOpen: false,
                  },
                });
              },
            }),
          ],
        },
        [h('div', 'Please select at least one correct option')]
      ),
    ]);
  }

  private editQuizEditRQOptionsBody() {
    const { editQuestionRQOptions } = this.getState().editQuizDialog;

    const sectionTitle = (title: string) => h('div.quiz__add-question__title', title);
    const getOptionId = (index: number) => 'edit-quiz-question-option-editor-' + index.toString();

    return h('div.quiz__add-question__options', [
      sectionTitle('OPTIONS (IN CORRECT ORDER)'),
      ...editQuestionRQOptions.map((option, index) => {
        const { editQuestionRQOptionsBuffer } = this.getState().editQuizDialog;

        let style: CSS | undefined = undefined;
        const bufferItem = editQuestionRQOptionsBuffer.find((item) => item.fromIndex === index);

        const TRANSITION_TIMEOUT = 300; // in milliseconds

        if (bufferItem) {
          const targetOption = document.getElementById(getOptionId(bufferItem.toIndex));

          if (!targetOption) return;

          const offset = targetOption.getBoundingClientRect().height;

          style = {
            transform: `translateY(${
              bufferItem.toIndex - bufferItem.fromIndex > 0 ? offset : -offset
            }px)`,
            transition: `${TRANSITION_TIMEOUT}ms ease-in`,
            zIndex: bufferItem.isOnTop ? '1000' : '0',
            opacity: '0.75',
          };
        }

        return QuestionOptionEditor({
          style,
          value: option.value,
          id: getOptionId(index),
          enableImageInput: false,
          subContext: 'question',
          selected: false,
          index: index,
          tabIndex: this.isAccessible ? 0 : undefined,
          error: option.hasError,
          oninput: (value) => {
            this.setState({
              editQuizDialog: {
                ...this.getState().editQuizDialog,
                editQuestionRQOptions: utils.replaceIndex(
                  editQuestionRQOptions,
                  { value, orderKey: option.orderKey, hasError: false },
                  index
                ),
              },
            });
          },
          enableMove:
            index === 0 ? 'down' : index + 1 === editQuestionRQOptions.length ? 'up' : 'both',
          onMoveClick: (direction) => {
            if (bufferItem) return; // disable move during animation
            const nextIndex = direction === 'up' ? index - 1 : index + 1;

            setTimeout(() => {
              const { editQuestionRQOptions } = this.getState().editQuizDialog;
              const newOptions = editQuestionRQOptions.slice(0);
              newOptions.splice(index, 1);
              newOptions.splice(nextIndex, 0, option);
              this.setState({
                editQuizDialog: {
                  ...this.getState().editQuizDialog,
                  editQuestionRQOptions: newOptions,
                  editQuestionRQOptionsBuffer: [],
                },
              });
            }, TRANSITION_TIMEOUT);

            this.setState({
              editQuizDialog: {
                ...this.getState().editQuizDialog,
                editQuestionRQOptionsBuffer: [
                  {
                    fromIndex: index,
                    toIndex: nextIndex,
                    isOnTop: true,
                  },
                  {
                    fromIndex: nextIndex,
                    toIndex: index,
                    isOnTop: false,
                  },
                ],
              },
            });
          },
          deleteAction:
            editQuestionRQOptions.length > MIN_REORDER_OPTIONS
              ? () => {
                  this.setState({
                    editQuizDialog: {
                      ...this.getState().editQuizDialog,
                      editQuestionRQOptions: editQuestionRQOptions.filter((o) => o !== option),
                    },
                  });
                }
              : undefined,
        });
      }),
      RaisedButton([h('i.fa.fa-plus'), h('span', style([ml('0.5rem')]), 'Add another option')], {
        classNames: ['quiz__raised-button'],
        disabled: editQuestionRQOptions.length >= MAX_REORDER_OPTIONS,
        onclick: () => {
          this.setState({
            editQuizDialog: {
              ...this.getState().editQuizDialog,
              editQuestionRQOptions: [
                ...editQuestionRQOptions,
                {
                  value: '',
                  orderKey: '',
                  hasError: false,
                },
              ],
            },
          });
        },
      }),
    ]);
  }

  private editQuizEditQuestionOptionsBody() {
    const { editQuestionScreen } = this.getState().editQuizDialog;

    switch (editQuestionScreen) {
      case 'tf':
        return this.editQuizEditTFQOptionsBody();
      case 'mcq':
        return this.editQuizEditMCQOptionsBody();
      case 'reorder':
        return this.editQuizEditRQOptionsBody();
      default:
        return null;
    }
  }

  private editQuizEditQuestionScreen() {
    const isMobile = getStore().getState().app.isMobile;
    const { isEditingQuestion, editQuestionScreen } = this.getState().editQuizDialog;

    if (!editQuestionScreen) return null;

    const body: View[] = [
      h('div.quiz__add-question__details', [
        Editor({
          value: this.getState().editQuizDialog.editQuestionField,
          subContext: 'question',
          title: 'Description',
          enableTextFormatting: true,
          enableFormulaInput: true,
          enableImageInput: true,
          oninput: (value) =>
            this.setState({
              editQuizDialog: {
                ...this.getState().editQuizDialog,
                editQuestionField: value,
                editQuestionFieldError: false,
              },
            }),
          errorText: this.getState().editQuizDialog.editQuestionFieldError
            ? 'Please fill this field'
            : undefined,
          placeholder: 'Type question text here',
        }),
      ]),

      this.editQuizEditQuestionOptionsBody(),

      isEditingQuestion && this.canEditQuiz()
        ? RaisedButton('Delete Question', {
            classNames: ['quiz__raised-button', 'danger'],
            onclick: () => this.deleteQuestionHandler(true),
          })
        : null,
      this.editQuizDeleteQuestionDialog(),
    ];

    if (isMobile) {
      return Dialog(
        {
          open: editQuestionScreen !== null,
          title: this.editQuizEditQuestionScreenTitle(),
          bodyStyle: {
            padding: '0',
            height: '100%',
            backgroundColor,
          },
          primaryAction: {
            label: 'SAVE',
            mobileLabel: h('i.fa.fa-check', []),
            onclick: () => this.editQuestion(),
          },
          secondaryAction: {
            label: 'CANCEL',
            mobileLabel: h('i.fa.fa-times', []),
            onclick: () => this.closeEditQuestionScreen(true),
          },
        },
        body
      );
    }

    return Dialog(
      {
        open: editQuestionScreen !== null,
        title: this.editQuizEditQuestionScreenTitle(),
        style: {
          maxWidth: '35rem',
        },
        bodyStyle: {
          padding: '0.75rem',
          height: '100%',
          backgroundColor,
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.editQuestion(),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => this.closeEditQuestionScreen(true),
        },
      },
      body
    );
  }

  private async deleteQuestionHandler(isEditingQuiz?: boolean) {
    if (isEditingQuiz) {
      this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          isDeleteQuestionDialogVisible: true,
        },
      });
      return;
    }
    this.setState({
      isDeleteQuestionDialogVisible: true,
    });
  }

  private editQuizDeleteQuestionDialog() {
    const { isDeleteQuestionDialogVisible } = this.getState().editQuizDialog;
    if (!isDeleteQuestionDialogVisible) return null;
    return Alert(
      {
        open: true,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('No', {
            type: 'secondary',
            onclick: () =>
              this.setState({
                editQuizDialog: {
                  ...this.getState().editQuizDialog,
                  isDeleteQuestionDialogVisible: false,
                },
              }),
          }),
          FlatButton('Yes', {
            onclick: () => this.deleteQuestion(true),
          }),
        ],
      },
      ['Are you sure you want to delete this question?']
    );
  }

  private deleteQuestionDialog() {
    const { isDeleteQuestionDialogVisible } = this.getState();
    if (!isDeleteQuestionDialogVisible) return null;
    return Alert(
      {
        open: true,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('No', {
            type: 'secondary',
            onclick: () =>
              this.setState({
                isDeleteQuestionDialogVisible: false,
              }),
          }),
          FlatButton('Yes', {
            onclick: () => this.deleteQuestion(),
          }),
        ],
      },
      ['Are you sure you want to delete this question?']
    );
  }

  private async deleteQuestion(isEditingQuiz?: boolean) {
    if (isEditingQuiz) {
      const { questions, questionInEditing } = this.getState().editQuizDialog;
      const newQuestions = questions.filter((q) => q._id !== questionInEditing);
      await this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          questions: newQuestions,
          isDeleteQuestionDialogVisible: false,
          editQuestionScreen: null,
          isEditingQuestion: null,
          editQuestionTFSelected: null,
          editQuestionFieldError: false,
          editQuestionField: '',
          editQuestionTFError: false,
          editQuestionMCQOptionError: [false, false, false, false],
          editQuestionMCQOptionsField: ['', '', '', ''],
          editQuestionMCQOptionsSelected: [],
          editQuestionMCQNoOptionSelectedError: false,
          editQuestionRQOptions: [],
          editQuestionRQOptionsBuffer: [],
        },
      });
    }
    const { isEditingQuestion } = this.getState();
    const { quiz } = this.getProps();

    if (isEditingQuestion === null) {
      return;
    } else {
      await dispatch(
        Actions.deleteQuizQuestion({
          classId: quiz.identifiers.classId,
          activityId: quiz._id,
          questionId: isEditingQuestion._id,
          activityType: 'quizzes',
        })
      );
    }
    await this.setState({
      isDeleteQuestionDialogVisible: false,
    });
    this.closeEditQuestionScreen();
  }

  private saveTFQuestion = async () => {
    const { quiz } = this.getProps();
    const { isEditingQuestion, editQuestionField, editQuestionTFSelected } = this.getState();

    const newState: Partial<IQuizState> = {};

    let hasError = false;

    if (editQuestionField.length === 0) {
      hasError = true;
      newState.editQuestionFieldError = true;
    }

    if (editQuestionTFSelected === null) {
      hasError = true;
      newState.editQuestionTFError = true;
      newState.isQuestionAlertOpen = true;
    }

    if (hasError) {
      await this.setState(newState);
      return;
    }

    if (isEditingQuestion) {
      await dispatch(
        Actions.editQuestion({
          classId: quiz.identifiers.classId,
          activityId: quiz._id,
          questionId: isEditingQuestion._id,
          type: 'tf',
          description: {
            text: editQuestionField,
          },
          answerKey: editQuestionTFSelected!,
          activityType: 'quizzes',
        })
      );
    } else {
      await dispatch(
        Actions.addQuizQuestion({
          classId: quiz.identifiers.classId,
          activityType: 'quizzes',
          type: 'tf',
          activityId: quiz._id,
          description: {
            text: editQuestionField,
          },
          answerKey: editQuestionTFSelected!,
        })
      );
      googleAnalytics.questionsAdded('quiz', 'tf');
    }

    await this.closeEditQuestionScreen();
  };

  private saveMCQQuestion = async () => {
    const { quiz, class: cls } = this.getProps();

    const {
      isEditingQuestion,
      editQuestionField,
      editQuestionMCQOptionsField,
      editQuestionMCQOptionsSelected,
    } = this.getState();

    const newState: Partial<IQuizState> = {};

    let hasError = false;

    if (editQuestionField.length === 0) {
      hasError = true;
      newState.editQuestionFieldError = true;
    }

    newState.editQuestionMCQOptionError = [false, false, false, false];

    for (const i of utils.range(editQuestionMCQOptionsField.length)) {
      const optionText = editQuestionMCQOptionsField[i];
      if (/^\s*$/.test(optionText)) {
        hasError = true;
        newState.editQuestionMCQOptionError[i] = true;
      }
    }

    if (!editQuestionMCQOptionsSelected.includes(true)) {
      hasError = true;
      newState.editQuestionMCQNoOptionSelectedError = true;
      newState.isQuestionAlertOpen = true;
      this.lastFocusedElement = document.activeElement;
      utils.unsetTabIndices();
    }

    if (hasError) {
      await this.setState(newState);
      return;
    }

    const answerKey = editQuestionMCQOptionsSelected.map((b) => (b ? '1' : '0')).join('');

    const options = editQuestionMCQOptionsField.map((text, index) => ({
      text,
      type: 'text' as const,
      num: index + 1,
    }));

    const description = {
      text: editQuestionField,
    };

    if (isEditingQuestion) {
      await dispatch(
        Actions.editQuestion({
          classId: cls._id,
          activityId: quiz._id,
          questionId: isEditingQuestion._id,
          type: 'mcq',
          description,
          options,
          answerKey,
          activityType: 'quizzes',
        })
      );
    } else {
      await dispatch(
        Actions.addQuizQuestion({
          classId: cls._id,
          activityType: 'quizzes',
          type: 'mcq',
          activityId: quiz._id,
          description,
          options,
          answerKey,
        })
      );
      googleAnalytics.questionsAdded('quiz', 'mcq');
    }

    await this.closeEditQuestionScreen();
  };

  private saveReorderQuestion = async () => {
    const { quiz, class: cls } = this.getProps();

    const { isEditingQuestion, editQuestionField, editQuestionRQOptions } = this.getState();

    const newState: Partial<IQuizState> = {};

    let hasError = false;

    if (editQuestionField.length === 0) {
      hasError = true;
      newState.editQuestionFieldError = true;
    }

    newState.editQuestionRQOptions = editQuestionRQOptions.map(({ value, orderKey }) => ({
      value,
      orderKey,
      hasError: false,
    }));

    for (const option of newState.editQuestionRQOptions) {
      if (/^\s*$/.test(option.value)) {
        hasError = true;
        option.hasError = true;
      }
    }

    if (hasError) {
      await this.setState(newState);
      return;
    }

    const options = editQuestionRQOptions.map(({ value }) => ({
      text: value,
      type: 'text' as const,
    }));

    const description = {
      text: editQuestionField,
    };

    if (isEditingQuestion) {
      await dispatch(
        Actions.editQuestion({
          classId: cls._id,
          activityId: quiz._id,
          questionId: isEditingQuestion._id,
          type: 'reorder',
          description,
          options,
          activityType: 'quizzes',
        })
      );
    } else {
      await dispatch(
        Actions.addQuizQuestion({
          classId: cls._id,
          activityType: 'quizzes',
          type: 'reorder',
          activityId: quiz._id,
          description,
          options,
        })
      );
      googleAnalytics.questionsAdded('quiz', 'reorder');
    }

    await this.closeEditQuestionScreen();
  };

  private async saveQuestion() {
    const { editQuestionScreen } = this.getState();

    if (!editQuestionScreen) return;

    switch (editQuestionScreen) {
      case 'tf':
        await this.saveTFQuestion();
        break;
      case 'mcq':
        await this.saveMCQQuestion();
        break;
      case 'reorder':
        await this.saveReorderQuestion();
        break;
    }
  }

  private closeEditQuizEditQuestionScreen = async (updatedQuestions: IQuizQuestion[]) => {
    await this.setState({
      editQuizDialog: {
        ...this.getState().editQuizDialog,
        questions: updatedQuestions,
        editQuestionScreen: null,
        isEditingQuestion: null,
        editQuestionTFSelected: null,
        editQuestionFieldError: false,
        editQuestionField: '',
        editQuestionTFError: false,
        editQuestionMCQOptionError: [false, false, false, false],
        editQuestionMCQOptionsField: ['', '', '', ''],
        editQuestionMCQOptionsSelected: [],
        editQuestionMCQNoOptionSelectedError: false,
        editQuestionRQOptions: [],
      },
    });
  };

  private editTFQuestion = async () => {
    const { editQuizDialog } = this.getState();

    let { questions } = editQuizDialog;
    const { editQuestionField, questionInEditing, quiz, editQuestionTFSelected } = editQuizDialog;

    if (!editQuestionTFSelected) {
      this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          editQuestionTFError: true,
        },
      });
      return;
    }

    if (!questionInEditing) {
      const response = await api.addQuestion({
        classId: quiz.identifiers.classId,
        activityType: 'quizzes',
        type: 'tf',
        activityId: quiz._id,
        description: {
          text: editQuestionField,
        },
        answerKey: editQuestionTFSelected!,
      });

      questions = [...questions, response.data];
    }

    const newQuestions = questions.map((q) => {
      if (q._id === questionInEditing && q.details.type === 'tf') {
        q.details.description.text = editQuestionField;
        q.details.answerKey = editQuestionTFSelected || '';
        q.details.options = [];
      }
      return q;
    });

    await this.closeEditQuizEditQuestionScreen(newQuestions);
  };

  private editMCQQuestion = async () => {
    const { editQuizDialog } = this.getState();

    let { questions } = editQuizDialog;
    const {
      quiz,
      editQuestionField,
      questionInEditing,
      editQuestionMCQOptionsField,
      editQuestionMCQOptionsSelected,
    } = editQuizDialog;

    const fieldsError = [false, false, false, false];

    editQuestionMCQOptionsField.map((f, idx) => {
      if (f.trim() === '') {
        fieldsError[idx] = true;
      }
    });

    if (fieldsError.includes(true)) {
      this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          editQuestionMCQOptionError: fieldsError,
        },
      });
      return;
    }

    if (!editQuestionMCQOptionsSelected.includes(true)) {
      this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          editQuestionMCQNoOptionSelectedError: true,
          isQuestionAlertOpen: true,
        },
      });
      return;
    }

    const answerKey = editQuestionMCQOptionsSelected.map((b) => (b ? '1' : '0')).join('');

    if (!questionInEditing) {
      const response = await api.addQuestion({
        classId: quiz.identifiers.classId,
        activityType: 'quizzes',
        type: 'mcq',
        activityId: quiz._id,
        description: {
          text: editQuestionField,
        },
        options: editQuestionMCQOptionsField.map((opt, idx) => ({
          num: idx + 1,
          text: opt,
          type: 'text' as const,
        })),
        answerKey,
      });

      questions = [...questions, response.data];
    }

    const newQuestions = questions.map((q) => {
      if (q._id === questionInEditing && q.details.type === 'mcq') {
        q.details.description.text = editQuestionField;
        q.details.answerKey = answerKey;
        q.details.options = editQuestionMCQOptionsField.map((opt, idx) => ({
          num: idx + 1,
          text: opt,
          type: 'text' as const,
        }));
      }
      return q;
    });

    await this.closeEditQuizEditQuestionScreen(newQuestions);
  };

  private editReorderQuestion = async () => {
    const { editQuizDialog } = this.getState();

    let { questions } = editQuizDialog;
    const { quiz, editQuestionField, questionInEditing, editQuestionRQOptions } = editQuizDialog;

    let hasError = false;
    const options = editQuestionRQOptions.map(({ value, orderKey }) => ({
      hasError: false,
      value,
      orderKey,
    }));

    for (const option of options) {
      if (option.value.trim() === '') {
        hasError = true;
        option.hasError = true;
      }
    }

    if (hasError) {
      this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          editQuestionRQOptions: options,
        },
      });
      return;
    }

    if (!questionInEditing) {
      const response = await api.addQuestion({
        classId: quiz.identifiers.classId,
        activityType: 'quizzes',
        type: 'reorder',
        activityId: quiz._id,
        description: {
          text: editQuestionField,
        },
        options: options.map((opt) => ({
          text: opt.value,
          type: 'text' as const,
        })),
      });

      questions = [...questions, response.data];
    }

    const newQuestions = questions.map((q) => {
      if (q._id === questionInEditing && q.details.type === 'reorder') {
        q.details.description.text = editQuestionField;
        q.details.answerKey = options.map(({ orderKey }) => orderKey).join('-');
        q.details.type = 'reorder';
        q.details.options = options.map(({ value: text, orderKey }) => ({
          orderKey,
          text,
          type: 'text' as const,
        }));
      }
      return q;
    });

    await this.closeEditQuizEditQuestionScreen(newQuestions);
  };

  private editQuestion = async () => {
    const { editQuestionScreen, editQuestionField } = this.getState().editQuizDialog;

    if (editQuestionField.trim() === '') {
      this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          editQuestionFieldError: true,
        },
      });
      return;
    }

    switch (editQuestionScreen) {
      case 'tf':
        await this.editTFQuestion();
        break;
      case 'mcq':
        await this.editMCQQuestion();
        break;
      case 'reorder':
        await this.editReorderQuestion();
        break;
    }
  };

  private dialogs() {
    return [
      this.isEditEnabled() ? this.editInstructionsDialog() : null,
      this.isEditEnabled() ? this.editScoringSchemeDialog() : null,
      this.isMoveEnabled() ? this.moveDialog() : null,
      this.isEditEnabled() ? this.deleteDialog() : null,
      this.canStopManually() ? this.stopQuizDialog() : null,
      this.isCopyEnabled() ? this.copyDialog() : null,
      this.canEditQuiz() ? this.editQuizEditInstructionsDialog() : null,
      this.canEditQuiz() ? this.editQuizEditQuestionScreen() : null,
      this.canEditQuiz() ? this.editQuizPublishDialog() : null,
      this.isEditEnabled() || this.isPublishEnabled() ? this.publishPrefsDialog() : null,
      this.canReorderQuestions() ? this.reorderQuestionDialog() : null,
      this.canEditQuiz() ? this.EditQuizDialog() : null,
    ];
  }

  private stopQuizDialog() {
    const { isStopDialogOpen } = this.getState();
    const { quiz, class: cls } = this.getProps();

    if (!isStopDialogOpen) return null;

    return Alert(
      {
        open: true,
        title: h('div.fc-orange', 'Stop Activity'),
        actions: [
          FlatButton('Cancel', {
            type: 'primary',
            onclick: () =>
              this.setState({
                isStopDialogOpen: false,
              }),
          }),
          FlatButton('Stop', {
            type: 'secondary',
            onclick: () => {
              dispatch(
                Actions.stopQuiz({
                  activityId: quiz._id,
                  classId: cls._id,
                  activityType: 'quizzes',
                  localTime: datetime.format(new Date(), 'YYYYMMDDTHH:mm'),
                })
              );
              this.setState({
                isStopDialogOpen: false,
              });
            },
          }),
        ],
      },
      [
        h('div', [
          'To avoid confusion and surprised groans, ',
          'please announce to the class that you’re going to stop accepting responses',
        ]),
      ]
    );
  }

  private deleteDialog() {
    const { isDeleteDialogOpen } = this.getState();
    if (!isDeleteDialogOpen) return null;
    return Alert(
      {
        open: true,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('NO', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => this.closeDeleteDialog(),
          }),
          FlatButton('YES', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.deleteQuiz(),
          }),
        ],
      },
      ['Are you sure you want to delete this quiz?']
    );
  }

  private async deleteQuiz() {
    const { quiz } = this.getProps();
    const cls = this.getProps().class;
    await dispatch(
      Actions.deleteQuiz(
        {
          classId: cls._id,
          toBeDone: quiz.details.toBeDone,
          quizId: quiz._id,
        },
        () => {
          goBack();
        }
      )
    );
    googleAnalytics.activityDeleted(
      'quiz',
      quiz.details.toBeDone === 'preClass' ? 'pre-class' : 'in-class'
    );
    await this.closeDeleteDialog();
  }

  private moveDialog() {
    const { isMoveDialogOpen } = this.getState();
    if (!isMoveDialogOpen) return null;
    return MoveActivityDialog({
      open: true,
      class: this.getProps().class,
      activity: this.getProps().quiz,
      oncancel: () => this.closeMoveDialog(),
    });
  }

  private async closeMoveDialog() {
    utils.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isMoveDialogOpen: false,
    });
  }

  private editScoringSchemeDialog() {
    const { isEditScoringSchemeDialogOpen, editScoringSchemeField } = this.getState();
    if (!isEditScoringSchemeDialogOpen) return null;
    return ScoringSchemeDialog({
      open: true,
      initialScheme: editScoringSchemeField,
      onCancel: this.closeEditScoringSchemeDialog,
      onSelect: this.saveScoringScheme,
    });
  }

  private saveScoringScheme = async (scheme: IQuizScoringScheme) => {
    const { quiz } = this.getProps();
    if (scheme !== quiz.details.scoring) {
      await this.editQuiz({
        scoring: scheme,
      });
    }
    await this.closeEditScoringSchemeDialog();
  };

  private editInstructionsDialog() {
    const { isEditInstructionsDialogOpen, editInstructionsAttachments, editInstructionsField } =
      this.getState();

    if (!isEditInstructionsDialogOpen) return null;

    return Dialog(
      {
        open: true,
        title: 'Edit instructions',
        iconActions: true,
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.saveInstructions(),
        },
        bodyStyle: {
          padding: '0em',
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => this.closeEditInstructionsDialog(),
        },
      },
      [
        Editor({
          value: editInstructionsField,
          subContext: 'instruction',
          oninput: (text, attachments) =>
            this.setState({
              editInstructionsField: text,
              editInstructionsAttachments: attachments,
            }),
          attachments: {
            files: editInstructionsAttachments,
            activityId: this.getProps().quiz._id,
            activityType: 'quizzes',
            uploadUrl: urls().quizFileUpload,
          },
          enableTextFormatting: true,
          enableFormulaInput: true,
          enableImageInput: true,
          enableFileAttachments: true,
        }),
      ]
    );
  }

  private editQuizEditInstructionsDialog() {
    const { isEditInstructionsDialogOpen, editInstructionsAttachments, editInstructionsField } =
      this.getState().editQuizDialog;
    if (!isEditInstructionsDialogOpen) return null;
    return Dialog(
      {
        open: true,
        title: 'Edit instructions',
        iconActions: true,
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.saveInstructions(true),
        },
        bodyStyle: {
          padding: '0em',
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => this.closeEditInstructionsDialog(true),
        },
      },
      [
        Editor({
          value: editInstructionsField,
          subContext: 'instruction',
          oninput: (text, attachments) =>
            this.setState({
              editQuizDialog: {
                ...this.getState().editQuizDialog,
                editInstructionsField: text,
                editInstructionsAttachments: attachments,
              },
            }),
          attachments: {
            files: editInstructionsAttachments,
            activityId: this.getProps().quiz._id,
            activityType: 'quizzes',
            uploadUrl: urls().quizFileUpload,
          },
          enableTextFormatting: true,
          enableFormulaInput: true,
          enableImageInput: true,
          enableFileAttachments: true,
        }),
      ]
    );
  }

  private async saveInstructions(isEditingQuiz?: boolean) {
    if (isEditingQuiz) {
      const { editInstructionsAttachments, editInstructionsField, quiz } =
        this.getState().editQuizDialog;

      const newQuiz = {
        ...quiz,
        details: {
          ...quiz.details,
          instructions: editInstructionsField,
          attachments: editInstructionsAttachments,
        },
      };
      this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          quiz: newQuiz,
        },
      });
      await this.closeEditInstructionsDialog(true);
      return;
    }
    const { editInstructionsAttachments, editInstructionsField } = this.getState();

    await this.editQuiz({
      instructions: editInstructionsField,
      attachments: editInstructionsAttachments,
    });
    await this.closeEditInstructionsDialog();
  }

  private async editQuiz(request: Partial<api.IEditQuizRequest>) {
    const { quiz } = this.getProps();
    const data: api.IEditQuizRequest = {
      classId: this.getProps().class._id,
      quizId: quiz._id,
      title: quiz.details.title,
      instructions: quiz.details.instructions,
      attachments: quiz.details.attachments,
      scoring: quiz.details.scoring,
    };
    await dispatch(
      Actions.editQuiz({
        ...data,
        ...request,
      })
    );
  }

  private async openEditInstructionsDialog() {
    this.lastFocusedElement = document.activeElement;
    utils.unsetTabIndices();
    const { quiz } = this.getProps();
    await this.setState({
      isEditInstructionsDialogOpen: true,
      editInstructionsField: quiz.details.instructions,
      editInstructionsAttachments: quiz.details.attachments,
    });
  }

  private async closeEditInstructionsDialog(isEditingQuiz?: boolean) {
    if (isEditingQuiz) {
      await this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          isEditInstructionsDialogOpen: false,
        },
      });
      return;
    }
    const { quiz } = this.getProps();
    utils.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isEditInstructionsDialogOpen: false,
      editInstructionsField: quiz.details.instructions,
      editInstructionsAttachments: quiz.details.attachments,
    });
  }

  private async openEditScoringSchemeDialog() {
    this.lastFocusedElement = document.activeElement;
    utils.unsetTabIndices();
    await this.setState({
      isEditScoringSchemeDialogOpen: true,
      editScoringSchemeField: this.getProps().quiz.details.scoring,
    });
  }

  private closeEditScoringSchemeDialog = async () => {
    utils.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isEditScoringSchemeDialogOpen: false,
      editScoringSchemeField: this.getProps().quiz.details.scoring,
    });
  };

  private floatingButtonTip() {
    if (!this.isEditEnabled()) {
      return null;
    }
    return TipOverlayWrapper({
      targetElement: 'quiz-add-question-button',
      tip: {
        tipPosition: 'left',
        tipText:
          'Use this button to add a question to the quiz. You can choose' +
          ' to add either a Multiple Choice Question or a True/False' +
          ' type question. There is no limit to the number of questions' +
          ' you can add',
      },
      tipKey: 'quizMainFloatingButton',
      isNextAvailable: false,
    });
  }

  private floatingButtons() {
    if (!this.isEditEnabled()) {
      return [];
    }

    return [
      FloatingActionButton(
        {
          id: 'quiz-add-question-button',
          position: 'bottom-right',
          tabIndex: this.isAccessible ? 0 : undefined,
          style: {
            zIndex: 1,
          },
          onclick: (e: Event) => {
            e.stopPropagation();
            this.toggleFloatingMenu();
          },
        },
        [
          Icon(icons.plus, {
            className: `quiz__fab-icon ${this.getState().isFloatingMenuOpen ? 'open' : ''}`,
          }),
        ]
      ),
      FloatingMenu(
        {
          isOpen: this.getState().isFloatingMenuOpen,
          title: 'Add quiz question',
          toggleHandler: () => this.toggleFloatingMenu(),
        },
        [
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'True/False type',
            onClick: () => this.openEditQuestionScreen('tf'),
          },
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'Multiple choice type',
            onClick: () => this.openEditQuestionScreen('mcq'),
          },
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            tooltip: !this.canAddSortingQuestion()
              ? "Available only in Acadly's Pro courses"
              : undefined,
            label: 'Sorting type',
            disabled: !this.canAddSortingQuestion(),
            onClick: () => this.openEditQuestionScreen('reorder'),
          },
        ]
      ),
    ];
  }
  private lastFocusedElement: null | Element = null;
  private async openDeleteDialog() {
    this.lastFocusedElement = document.activeElement;
    utils.unsetTabIndices();
    await this.setState({
      isDeleteDialogOpen: true,
    });
  }

  private async closeDeleteDialog() {
    utils.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isDeleteDialogOpen: false,
    });
  }

  private async openEditQuestionScreen(screen: IQuizQuestionType, question?: IQuizQuestion) {
    this.lastFocusedElement = question
      ? document.activeElement
      : document.getElementById('quiz-add-question-button');

    utils.unsetTabIndices();

    await this.setState({
      editQuestionScreen: screen,
      isEditingQuestion: question || null,
      editQuestionTFSelected: question ? (question.details.answerKey as 't' | 'f') : null,
      editQuestionField: question ? question.details.description.text : '',
      editQuestionFieldError: false,
      editQuestionTFError: false,
      editQuestionMCQOptionError: [false, false, false, false],
      editQuestionMCQOptionsField:
        screen === 'mcq'
          ? question
            ? question.details.options.map((o) => o.text)
            : ['', '', '', '']
          : [],
      editQuestionMCQOptionsSelected: question
        ? question.details.options.map(
            (_, i) => !!question.details.answerKey && question.details.answerKey[i] === '1'
          )
        : [false, false, false, false],
      editQuestionMCQNoOptionSelectedError: false,
      editQuestionRQOptions:
        question?.details.type === 'reorder'
          ? question.details.options.map((o) => ({
              hasError: false,
              value: o.text,
              orderKey: o.orderKey,
            }))
          : Array.from({ length: MIN_REORDER_OPTIONS }, () => ({
              hasError: false,
              value: '',
              orderKey: '',
            })),
    });
  }

  private async openEditQuizEditQuestionScreen(
    screen: IQuizQuestionType,
    question?: IQuizQuestion
  ) {
    this.lastFocusedElement = document.activeElement;
    utils.unsetTabIndices();
    await this.setState({
      editQuizDialog: {
        ...this.getState().editQuizDialog,
        questions: this.getState().editQuizDialog.questions,
        questionInEditing: question ? question._id : '',
        editQuestionScreen: screen,
        isEditingQuestion: question || null,
        editQuestionTFSelected: question ? (question.details.answerKey as 't' | 'f') : null,
        editQuestionField: question ? question.details.description.text : '',
        editQuestionFieldError: false,
        editQuestionTFError: false,
        editQuestionMCQOptionError: [false, false, false, false],
        editQuestionMCQOptionsField:
          screen === 'mcq'
            ? question
              ? question.details.options.map((o) => o.text)
              : ['', '', '', '']
            : [],
        editQuestionMCQOptionsSelected:
          question && question.details.options
            ? question.details.options.map(
                (_, i) => !!question.details.answerKey && question.details.answerKey[i] === '1'
              )
            : [false, false, false, false],
        editQuestionMCQNoOptionSelectedError: false,
        editQuestionRQOptions:
          question?.details.type === 'reorder'
            ? question.details.options.map((o) => ({
                hasError: false,
                value: o.text,
                orderKey: o.orderKey,
              }))
            : Array.from({ length: MIN_REORDER_OPTIONS }, () => ({
                hasError: false,
                value: '',
                orderKey: '',
              })),
      },
    });
  }

  private async closeEditQuestionScreen(isEditingQuiz?: boolean) {
    utils.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();

    if (isEditingQuiz) {
      await this.setState({
        editQuizDialog: {
          ...this.getState().editQuizDialog,
          editQuestionScreen: null,
          isEditingQuestion: null,
          editQuestionTFSelected: null,
          editQuestionFieldError: false,
          editQuestionField: '',
          editQuestionTFError: false,
          editQuestionMCQOptionError: [false, false, false, false],
          editQuestionMCQOptionsField: ['', '', '', ''],
          editQuestionMCQOptionsSelected: [],
          editQuestionMCQNoOptionSelectedError: false,
          editQuestionRQOptions: [],
          editQuestionRQOptionsBuffer: [],
        },
      });
      return;
    }

    await this.setState({
      editQuestionScreen: null,
      isEditingQuestion: null,
      editQuestionTFSelected: null,
      editQuestionFieldError: false,
      editQuestionField: '',
      editQuestionTFError: false,
      editQuestionMCQOptionError: [false, false, false, false],
      editQuestionMCQOptionsField: ['', '', '', ''],
      editQuestionMCQOptionsSelected: [],
      editQuestionMCQNoOptionSelectedError: false,
      editQuestionRQOptions: [],
      editQuestionRQOptionsBuffer: [],
    });
  }

  private async toggleFloatingMenu() {
    const isOpen = this.getState().isFloatingMenuOpen;
    if (isOpen) {
      utils.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    } else {
      this.lastFocusedElement = document.activeElement;
      utils.unsetTabIndices();
    }
    await this.setState({
      isFloatingMenuOpen: !isOpen,
    });
  }

  private isEditEnabled() {
    const { isClassIncharge } = this.getProps();
    return (
      isClassIncharge &&
      !this.getProps().course.isArchived &&
      this.getProps().quiz.details.published === 0
    );
  }

  private async openPublishDialog() {
    const { quiz, course } = this.getProps();

    const valid = validateActivityPublish({
      activityType: 'class',
      course,
      cls: this.getProps().class,
      activity: quiz,
    });

    if (!valid) return;

    this.showPublishPrefsDialog(true);
  }

  private async openMoveDialog() {
    this.lastFocusedElement = document.activeElement;
    utils.unsetTabIndices();
    await this.setState({
      isMoveDialogOpen: true,
    });
  }

  private copyDialog() {
    const props = this.getProps();
    const { isCopyDialogOpen } = this.getState();
    if (!isCopyDialogOpen) return null;
    return CopyActivityDialog({
      open: true,
      classId: props.class._id,
      activityId: props.quiz._id,
      activityType: 'quizzes',
      onDone: () => this.closeCopyDialog(),
      onCancel: () => this.closeCopyDialog(),
    });
  }

  private async closeCopyDialog() {
    utils.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isCopyDialogOpen: false,
    });
  }

  private questionAnalyticsInfoCell({
    label,
    value,
    percentage,
    isCorrect = false,
  }: {
    label: string;
    value: string;
    percentage?: number;
    isCorrect?: boolean;
  }) {
    return h(
      'div',
      style([pad('0.5rem 1rem'), 'flex', 'alignCenter', 'mediumGrey', 'spaceBetween']),
      [
        h('div', style(['thick'], { minWidth: 60 }), label),
        percentage !== undefined
          ? h('div', style(['flex'], { flex: 1 }), [
              ProgressBar(percentage, {
                className: isCorrect ? 'quiz__progress' : 'quiz__progress danger',
              }),
              h('span', style(['mediumGrey', 'thin']), value),
            ])
          : h('div', style(['mediumGrey', 'thin']), value),
      ]
    );
  }

  private tfAnaylticsView() {
    const { questionAnalytics, quizAnalyticsForQuestion: question } = this.getState();

    if (questionAnalytics.type !== 'tf') return [];

    const stats = questionAnalytics.stats;
    const totalAttempts = stats.attempts;

    const isTrueCorrect = question?.details.answerKey === 't';
    const percentage = Math.round((stats.correct * 100) / totalAttempts);
    const wrong = totalAttempts - stats.correct;

    return [
      h('div', [
        this.questionAnalyticsInfoCell({
          label: 'Attempted By',
          value: `${totalAttempts} student${totalAttempts > 1 ? 's' : ''}`,
        }),
        this.questionAnalyticsInfoCell({
          label: 'Correctly attempted by',
          value: `${stats.correct} student${stats.correct > 1 ? 's' : ''}`,
        }),
        totalAttempts > 0
          ? this.questionAnalyticsInfoCell({
              label: 'True',
              value: isTrueCorrect
                ? `${percentage}% (${stats.correct}/${totalAttempts})`
                : `${100 - percentage}% (${wrong}/${totalAttempts})`,
              percentage: isTrueCorrect ? percentage : 100 - percentage,
              isCorrect: isTrueCorrect,
            })
          : null,
        totalAttempts > 0
          ? this.questionAnalyticsInfoCell({
              label: 'False',
              value: isTrueCorrect
                ? `${100 - percentage}% (${wrong}/${totalAttempts})`
                : `${percentage}% (${stats.correct}/${totalAttempts})`,
              percentage: isTrueCorrect ? 100 - percentage : percentage,
              isCorrect: !isTrueCorrect,
            })
          : null,
      ]),
    ];
  }

  private mcqAnalyticsView() {
    const { questionAnalytics, quizAnalyticsForQuestion: question } = this.getState();

    if (questionAnalytics.type !== 'mcq') return [];

    const stats = questionAnalytics.stats;
    const totalAttempts = stats.attempts;

    return [
      h('div', [
        this.questionAnalyticsInfoCell({
          label: 'Attempted By',
          value: `${totalAttempts} student${totalAttempts > 1 ? 's' : ''}`,
        }),
        this.questionAnalyticsInfoCell({
          label: 'Correctly attempted by',
          value: `${stats.correct} student${stats.correct > 1 ? 's' : ''}`,
        }),
        ...[stats.opt1, stats.opt2, stats.opt3, stats.opt4].map((numSelected, index) => {
          const percentage = Math.round(((numSelected || 0) * 100) / totalAttempts);
          return this.questionAnalyticsInfoCell({
            label: `Option ${utils.indexToAlphabet(index)}`,
            value: `${percentage}% (${numSelected}/${totalAttempts})`,
            percentage: percentage,
            isCorrect: question?.details.answerKey?.[index] === '1',
          });
        }),
      ]),
    ];
  }

  private reorderAnalyticsView() {
    const { questionAnalytics } = this.getState();

    if (questionAnalytics.type !== 'reorder') return [];

    const stats = questionAnalytics.stats;
    const totalAttempts = stats.attempts;

    const options: {
      label: string;
      numCorrect: number;
    }[] = [];

    for (const [key, numCorrect] of Object.entries(stats.options)) {
      const lastLetter = key.split('').pop();
      options.push({
        label: `Option ${lastLetter.toUpperCase()}`,
        numCorrect,
      });
    }

    // sort options in alphabetical order
    options.sort((a, b) => (a.label > b.label ? 1 : -1));

    return [
      h('div', [
        this.questionAnalyticsInfoCell({
          label: 'Attempted By',
          value: `${totalAttempts} student${totalAttempts > 1 ? 's' : ''}`,
        }),
        this.questionAnalyticsInfoCell({
          label: 'Correctly attempted by',
          value: `${stats.correct} student${stats.correct > 1 ? 's' : ''}`,
        }),
        ...options.map(({ numCorrect }, index) => {
          const percentage = Math.round(((numCorrect || 0) * 100) / totalAttempts);
          return this.questionAnalyticsInfoCell({
            label: `Option ${utils.indexToAlphabet(index)}`,
            value: `${percentage}% (${numCorrect}/${totalAttempts})`,
            percentage: percentage,
            isCorrect: true,
          });
        }),
      ]),
    ];
  }

  private analyticsDialog() {
    const state = this.getState();
    const { questionAnalytics } = state;

    let body: View[] = [];

    switch (questionAnalytics?.type) {
      case 'tf':
        body = this.tfAnaylticsView();
        break;
      case 'mcq':
        body = this.mcqAnalyticsView();
        break;
      case 'reorder':
        body = this.reorderAnalyticsView();
        break;
    }

    return Alert(
      {
        open: state.isQuestionAnalyticsDialogOpen,
        actions: [
          FlatButton('OK', {
            tabIndex: this.isAccessible ? 0 : undefined,
            style: {
              marginRight: 'auto',
              marginLeft: 'auto',
            },
            onclick: () => this.closeAnalytics(),
          }),
        ],
      },
      [...body]
    );
  }

  private closeAnalytics() {
    utils.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    this.setState({
      isQuestionAnalyticsDialogOpen: false,
      quizAnalyticsForQuestion: null,
    });
  }

  private async getAnalytics(questionId: string) {
    this.lastFocusedElement = document.activeElement;
    utils.unsetTabIndices();
    await this.studentAttendancefetch(questionId);
    this.setState({
      isQuestionAnalyticsDialogOpen: true,
    });
  }
  private async studentAttendancefetch(questionId: string) {
    const { quiz } = this.getProps();
    const response = await dispatch(Actions.analyticsIndividualQuestionFetch(quiz._id, questionId));

    const quizState = getStore().getState().quizzes;
    let quizAnalyticsForQuestion: IQuizQuestion | null = null;

    if (quizState && quizState.current) {
      quizAnalyticsForQuestion =
        quizState.current.questions.find((q) => q._id === questionId) || null;
    }

    await this.setState({
      quizAnalyticsForQuestion,
      questionAnalytics: response,
    });
  }
}

export default (props: IQuizProps) => h(Quiz, props);
