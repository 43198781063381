import { CSS, h, IComponent, View } from 'core';

import Slider from 'acadly/common/Slider';
import { getStore } from 'acadly/store';
import { colors, ml, style } from 'acadly/styles';
// import LoaderButton from "./LoaderButton";
import { padStart } from 'acadly/utils';

export type IVideoOverlayButton = {
  icon: View;
  label: string;
  onclick: (event: Event) => any;
  tabIndex?: number | undefined;
};

export type IVideoPlayerProps = {
  videoId: string;
  style?: CSS;
  buttons?: IVideoOverlayButton[];
};
export class VideoPlayer extends IComponent<
  IVideoPlayerProps,
  {
    elem?: HTMLElement;
    player?: YT.Player;
    isPlaying: boolean;
    position: number;
  }
> {
  public componentWillMount() {
    this.setState({
      isPlaying: false,
      position: 0,
    });
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
  }

  public componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  private isAccessible: boolean;

  public render() {
    const { videoId, buttons } = this.getProps();
    return h(
      'div.video-player',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': 'video player',
        style: {
          ...this.getProps().style,
        },
      },
      [
        h('div.player-video', style(['relative']), [
          this.isPlaying()
            ? null
            : h(
                'div.player-overlay',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  style: {
                    position: 'absolute',
                    top: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: !this.playerPosition()
                      ? 'rgba(0, 0, 0, 1)'
                      : 'rgba(0, 0, 0, 0.3)',
                    zIndex: '1',
                  },
                  onclick: () => {
                    this.togglePlay();
                  },
                },
                [
                  h(
                    'div.player-overlay-controls',
                    style(
                      [
                        'flex',
                        'justifyCenter',
                        'alignCenter',
                        {
                          boxShadow: '#333 1px 1px 5px 0px',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translateX(-50%) translateY(-50%)',
                        },
                      ],
                      {},
                      {}
                    ),
                    [
                      {
                        icon: h('i.fa.fa-play', []),
                        label: 'play',
                        tabIndex: this.isAccessible ? 0 : undefined,
                        onclick: () => this.togglePlay(),
                      },
                      ...(buttons || []),
                    ].map((b, i) => this.overlayControlButton(b, i))
                  ),
                  !this.playerPosition()
                    ? h('img.video-image', {
                        style: {
                          maxWidth: '100%',
                          maxHeight: '100%',
                        },
                        src: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
                      })
                    : null,
                ]
              ),
          h('div.player-wrapper', [
            h('div.player', {
              ref: async (elem: any) => {
                if (elem && !this.getState().elem) {
                  const player: YT.Player = new YT.Player(elem, {
                    width: '100%' as any,
                    height: 300,
                    videoId: videoId,
                    playerVars: {
                      controls: 0,
                      showinfo: 0,
                    },
                    events: {
                      onReady: () => this.onPlayerReady(elem, player),
                    },
                  });
                }
              },
            }),
          ]),
        ]),
        this.bottomControls(),
      ]
    );
  }

  private seekBar?: HTMLElement | null;

  private bottomControls() {
    return h(
      'div.player-bottom-controls',
      style([
        'lighterGrey',
        {
          backgroundColor: '#222',
          width: '100%',
          marginTop: '-0.23em',
        },
      ]),
      [
        h(
          'div.seek-bar',
          style(
            [
              {
                height: '1em',
                width: '100%',
                backgroundColor: 'grey',
              },
            ],
            {},
            {
              ref: (elem?: HTMLElement) => {
                if (this.seekBar !== elem) {
                  this.seekBar = elem;
                }
              },
              onclick: (event: MouseEvent) => this.seek(event),
            }
          ),
          [
            h(
              'div',
              style([
                {
                  backgroundColor: colors.blue,
                  height: '100%',
                  width: `${(this.getState().position * 100) / this.videoLength()}%`,
                },
              ])
            ),
          ]
        ),
        h(
          'div.controls-container',
          style([
            'flex',
            'alignCenter',
            {
              height: '2em',
            },
          ]),
          [
            h(
              'div.play-button',
              style(
                [
                  'flex',
                  'alignCenter',
                  'justifyCenter',
                  {
                    height: '100%',
                    width: '2.5em',
                  },
                ],
                {},
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  'aria-label': `${this.isPlaying() ? `Pause button` : `Play button`}`,
                  role: 'button',
                  onclick: () => this.togglePlay(),
                }
              ),
              [this.isPlaying() ? h('i.fa.fa-pause', []) : h('i.fa.fa-play', [])]
            ),
            this.renderPosition(this.getState().position),
            this.renderVolumeControl(),
          ]
        ),
      ]
    );
  }

  private renderVolumeControl() {
    const player = this.getState().player;
    if (!player) {
      return null;
    }
    const muteIcon = player.isMuted()
      ? 'i.fa.fa-volume-off'
      : player.getVolume() > 50
      ? 'i.fa.fa-volume-up'
      : 'i.fa.fa-volume-down';
    return h('div', style(['flex', 'alignCenter', 'pointer', ml('1em')]), [
      h(muteIcon, {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `${player.isMuted() ? `Unmute` : `Mute`} control`,
        style: style([
          'large',
          {
            width: '1em',
          },
        ]).style,
        onclick: () => this.toggleMute(),
      }),

      Slider({
        style: {
          marginLeft: '0.5rem',
          width: '3rem',
          fontSize: '0.5em',
        },
        onchange: (scale) => this.setVolume(scale),
      }),
    ]);
  }

  private async setVolume(volume: number) {
    const player = this.getState().player;
    if (!player) {
      return;
    }
    player.setVolume(volume);
    this.setState({});
  }

  private async toggleMute() {
    const player = this.getState().player;
    if (!player) {
      return;
    }

    if (player.isMuted()) {
      player.unMute();
    } else {
      player.mute();
    }

    await this.setState({});
  }

  private renderPosition(seconds: number) {
    return h(
      'div',
      style(['white', ml('0.5em')]),
      `${this.renderTime(seconds)}/${this.renderTime(this.videoLength())}`
    );
  }

  private renderTime(_seconds: number) {
    const hours = Math.floor(_seconds / 3600);
    const minutes = Math.floor(_seconds / 60) - hours * 60;
    const seconds = Math.floor(_seconds % 60);
    const withMinutes = `${padStart(minutes.toString(), 2, '0')}:${padStart(
      seconds.toString(),
      2,
      '0'
    )}`;
    if (hours > 0) {
      return `${padStart(hours.toString(), 2, '0')}:` + withMinutes;
    } else {
      return withMinutes;
    }
  }

  private seek(event: MouseEvent) {
    if (!this.seekBar) return;
    const progress = this.videoLength() * (event.offsetX / this.seekBar.clientWidth);
    const player = this.getState().player;
    if (player) {
      player.seekTo(progress, true);
    }
  }

  private overlayControlButton(btn: IVideoOverlayButton, index: number) {
    return h(
      'div.player-overlay-control.play-button.ripple',
      style(
        [
          'flex',
          'alignCenter',
          'borderBox',
          'justifyCenter',
          {
            padding: '1rem',
            width: '3rem',
            height: '3rem',
            fontSize: '2rem',
            backgroundColor: 'white',
            marginLeft: index > 0 ? '0.5rem' : undefined,
            color: colors.blue,
          },
        ],
        {},
        {
          key: btn.label,
          onclick: btn.onclick,
          tabIndex: btn.tabIndex,
        }
      ),
      [btn.icon]
    );
  }

  private async togglePlay() {
    const player = this.getState().player;
    if (!player) return;
    if (!this.isPlaying()) {
      player.playVideo();
    } else {
      player.pauseVideo();
    }
  }

  private interval: any;
  private async onPlayerReady(elem: HTMLElement, player: YT.Player) {
    player.addEventListener('onStateChange', () => {
      this.setState({
        isPlaying: this.playerIsPlaying(),
      });
    });

    this.interval = setInterval(() => {
      this.setState({
        position: this.playerPosition(),
      });
    }, 100);
    await this.setState({
      player: player,
      elem: elem,
    });
  }

  private isPlaying() {
    return this.getState().isPlaying;
  }

  private playerIsPlaying() {
    const player = this.getState().player;
    if (player) {
      return [YT.PlayerState.PLAYING, YT.PlayerState.BUFFERING].includes(player.getPlayerState());
    } else {
      return false;
    }
  }

  private playerPosition() {
    const player = this.getState().player;
    if (!player) {
      return 0;
    } else {
      return player.getCurrentTime();
    }
  }

  private videoLength() {
    const player = this.getState().player;
    if (!player) {
      return 0;
    } else {
      return player.getDuration();
    }
  }
}

export default (props: IVideoPlayerProps) => h(VideoPlayer, props);
