import { flatten } from 'lodash';
import { Subscription } from 'rxjs/Subscription';

import { h, IComponent, View } from 'core';

import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import assignmentService from 'acadly/assignment/service';
import { getCommentsContextService } from 'acadly/comments-context';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Avatar from 'acadly/common/Avatar';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Hover from 'acadly/common/Hover';
import Icon from 'acadly/common/Icon';
import { Loader } from 'acadly/common/Loader';
import RangeSlider from 'acadly/common/RangeSlider';
import Tabs from 'acadly/common/Tabs';
import UploadButton from 'acadly/common/UploadButton';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import { Actions as DiscussionActions } from 'acadly/discussion/actions';
import { getContextActivity } from 'acadly/getContextActivity';
import { Actions as GetInActions } from 'acadly/getin/actions';
import { PusherEvent, pusherService } from 'acadly/pusher';
import * as quizFns from 'acadly/quiz/functions';
import { canSeeQuizComments } from 'acadly/quiz/functions';
import Editor from 'acadly/rich-text/Editor';
import RawEditorC, { RawEditor } from 'acadly/rich-text/RawEditor';
import * as u from 'acadly/utils';

import classService from '../class/service';
import { ICommentsContextService } from '../comments-context';
import icons from '../icons';
import { dispatch, getStore } from '../store';
import { colors, margin, mb, ml, mr, mt, pad, pl, style } from '../styles';
import { Actions } from './actions';
import * as api from './api';
import Comment from './Comment';
import { getCanRateComment } from './functions';
import { groupComments } from './groupComments';

export const contextMap: {
  [key in IAppContext]?: ICommentsContext;
} = {
  course: 'course',
  class: 'classes',
  assignment: 'assignments',
  quiz: 'quizzes',
  poll: 'polls',
  discussion: 'discussions',
  query: 'queries',
  resource: 'resources',
};

const POST_SUB_TAB_LABEL = 'Post-submission';
const POST_SUB_TAB_DISABLE_TOOLTIP =
  'You can view the post-submission discussion only after ' +
  'submitting a response for the given assignment.';

const RAW_EDITOR_TEXT_AREA_STYLE = {
  maxHeight: '5em',
  minHeight: '2.5em',
  padding: '0.5rem 0.3em',
  boxSizing: 'border-box',
};
export interface ICommentsProps {
  context: IAppContext;
  course?: ICourse;
  courseRole: ICourseRole | null;
}

interface ICommentsState {
  isFetchingOld: boolean;
  tabsHovered: boolean;
  subContext: ICommentsSubContext;
  inputField: string;
  attachment: IRequestAttachment;
  isSendingComment: boolean;
  isFloatingMenuOpen: boolean;
  richTextDialog: null | {
    attachmentType: 'image' | 'file' | null;
    file: File | null;
    imageSrc?: string;
  };
  newTagCommentNumber: number;
  isLoading: boolean;
  isDetailsDialogOpenFor: string | null;
  scrollSet: boolean;
  commentLikesDialog: null | {
    commentId: string;
    data: null | api.IFetchLikesResponse;
  };
  isViewingImageSrc: string | null;
  unseenCommentsFirstFetch: number;
  isConfirmDeleteDialogOpenFor: string | null;
  hasImageLoaded: ObjectMap<boolean>;
  originalImageSize: ObjectMap<number>;
  isPointConfirmationDialogOpen: boolean;
  isPointRetractionDialogOpen: boolean;
  commentRating: number;
  hasAwardedPoints: boolean;
  isCorrectImageTypeError: boolean;
  isCorrectFileTypeError: boolean;
  activeComment: string | undefined;
  isLoadMoreCommentsVisible: boolean;
  loadMoreCommentsMessage: string;
  newMessagesCount: number;
  autoScrollToBottom: boolean;
}

const RAW_EDITOR_STYLE = {
  width: '90%',
  height: '90%',
  flex: 1,
  borderRadius: '0.3em',
  boxSizing: 'border-box',
  border: `1px solid ${colors.lightestGrey}`,
  marginRight: '1em',
};
const RAW_EDITOR_PLACEHOLDER_STYLE = {
  alignContent: 'center',
  top: '0.4rem',
  left: '0.5rem',
};
const COMMENT_BOX_PLACEHOLDER = 'Send a response';
const NEW_TAG = h(
  'div.new',
  style([
    'orange',
    'flex',
    'alignCenter',
    {
      // width: mainPanelWidth,
      maxWidth: '100%',
      backgroundColor: 'white',
      padding: '0.5em 0em',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  ]),
  [
    h(
      'span',
      style([
        {
          flex: '1',
          height: '1px',
          backgroundColor: colors.orange,
        },
      ])
    ),
    h('span', style([ml('0.5em'), 'x-small', mr('0.5em'), 'thick']), 'NEW'),
    h(
      'span',
      style([
        {
          width: '1em',
          height: '1px',
          backgroundColor: colors.orange,
        },
      ])
    ),
  ]
);
export class CommentsC extends IComponent<ICommentsProps, ICommentsState> {
  private pusherSub: Subscription;

  private scrollToBottom() {
    if (!this.elem) return;
    this.elem.scrollTop = this.elem.scrollHeight;
  }

  private handlePusherEvents(e: PusherEvent) {
    const { context, course } = this.getProps();
    const { subContext, newMessagesCount, autoScrollToBottom } = this.getState();

    if (
      e.event === 'commentAdded' &&
      course &&
      course._id === e.payload.courseId &&
      e.payload.context === contextMap[context] &&
      (context !== 'assignment' || e.payload.subContext === subContext)
    ) {
      if (autoScrollToBottom) {
        setTimeout(() => this.scrollToBottom(), 10);
      } else {
        this.setState({ newMessagesCount: newMessagesCount + 1 });
      }
    }
  }

  public componentWillMount() {
    this.initialize(this.getProps());
    window.addEventListener('blur', this.onWindowBlur);
    this.pusherSub = pusherService.events.subscribe((e) => this.handlePusherEvents(e));
  }

  private rawEditor?: RawEditor<any>;

  public componentWillUnmount() {
    this.updateCommentsSeenPusherAndResetCount();
    dispatch(Actions.setContext(undefined));
    window.removeEventListener('blur', this.onWindowBlur);
    this.pusherSub.unsubscribe();
  }

  private onWindowBlur = () => {
    this.updateCommentsSeenPusherAndResetCount();
  };

  private isPaymentRequired() {
    const role = courseService.getRole();
    if (role !== 'student') return false;
    const courseData = courseService.getCurrentCourseUserData();
    if (!courseData) return false;
    const { hasPaid, payTill } = courseData;
    const now = dt.unix();
    if (hasPaid) return false;
    return payTill < now;
  }

  /**
   * The last activity for which comments were initialized. Required
   * because on initial page load, the activity won't be present
   * and we need to load comments when the activity is loaded.
   */
  private lastContextActivity: IAssignment | IClassActivity | null = null;
  public componentWillReceiveProps(nextProps: ICommentsProps) {
    if (this.isPaymentRequired()) return;

    const course = this.getProps().course;
    const contextActivity = getContextActivity(nextProps.context);

    // reload comments if
    if (
      // context changes
      this.getProps().context !== nextProps.context ||
      // role changes
      this.getProps().courseRole !== nextProps.courseRole ||
      // course changes
      (course && nextProps.course && course._id !== nextProps.course._id) ||
      // course loads
      (course === undefined && nextProps.course !== undefined) ||
      // activity changes
      (!this.lastContextActivity && contextActivity)
    ) {
      this.updateCommentsSeenPusherAndResetCount();
      this.initialize(nextProps);
    }
  }

  private initialize(props: ICommentsProps, _subContext?: 'preSub' | 'postSub') {
    if (this.isPaymentRequired()) return;

    const context = props.context;
    let subContext: ICommentsSubContext = 'preClass';

    const activity = getContextActivity(props.context);
    this.lastContextActivity = activity;
    if (context === 'class') {
      const cls = classService.getCurrentClass();
      if (cls && cls.details.status === 'open') {
        subContext = 'preClass';
      } else {
        subContext = 'inClass';
      }
    } else if (context === 'assignment') {
      subContext = _subContext || 'preSub';
    } else {
      if (activity) {
        const act = <IClassActivity>activity;
        subContext = act.details.toBeDone;
      }
    }

    const initialState: ICommentsState = {
      inputField: '',
      attachment: {
        originalName: '',
        name: '',
        extension: '',
      },
      tabsHovered: false,
      isFetchingOld: false,
      isSendingComment: false,
      subContext: subContext,
      isFloatingMenuOpen: false,
      richTextDialog: null,

      isLoading: true,
      newTagCommentNumber: 0,
      isDetailsDialogOpenFor: null,
      scrollSet: false,
      commentLikesDialog: null,
      isViewingImageSrc: null,
      unseenCommentsFirstFetch: 0,
      isConfirmDeleteDialogOpenFor: null,
      hasImageLoaded: {},
      originalImageSize: {},
      isPointConfirmationDialogOpen: false,
      isPointRetractionDialogOpen: false,
      commentRating: 0,
      hasAwardedPoints: false,
      isCorrectImageTypeError: false,
      isCorrectFileTypeError: false,
      activeComment: '',
      isLoadMoreCommentsVisible: false,
      loadMoreCommentsMessage: '',
      newMessagesCount: 0,
      autoScrollToBottom: true,
    };
    this.setState(initialState).then(() => {
      this.loadComments(this.getProps().context, this.getState().subContext);
    });
  }

  public render() {
    if (this.isPaymentRequired()) {
      return h('div', style(['grey', pad('1rem')]), 'Payment required.');
    }

    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const { inputField, subContext, newMessagesCount } = this.getState();
    const { context } = this.getProps();
    const assignment = assignmentService.getCurrentAssignment();
    const isStudent = this.getRole() === 'student';
    const isSubmitted =
      assignment &&
      assignment.userData &&
      assignment.userData.studentUserData &&
      assignment.userData.studentUserData.status !== 'inProgress';
    const isPostSubTabEnabled = (isStudent && isSubmitted) || !isStudent;
    const tabs =
      context === 'assignment'
        ? Tabs(
            {
              activeTab: subContext === 'preSub' ? 0 : 1,
              tabActions: [
                () => {
                  this.setState({
                    subContext: 'preSub',
                  }).then(() => {
                    this.loadComments('assignment', 'preSub');
                  });
                },
                () => {
                  if (isPostSubTabEnabled) {
                    this.setState({
                      subContext: 'postSub',
                    }).then(() => {
                      this.loadComments('assignment', 'postSub');
                    });
                  }
                },
              ],
            },
            [
              'Pre-submission',
              isPostSubTabEnabled
                ? POST_SUB_TAB_LABEL
                : Hover({}, h('div', [POST_SUB_TAB_LABEL]), (hovered) =>
                    this.setState({
                      tabsHovered: hovered,
                    })
                  ),
            ]
          )
        : null;

    const hasimageloaded = this.getState().hasImageLoaded;
    const originalImageSize = this.getState().originalImageSize;
    const isViewingImageSrc = this.getState().isViewingImageSrc;
    return h(
      'div.comments',
      {
        onmouseleave: () => this.setState({ activeComment: '' }),
      },
      [
        !isPostSubTabEnabled
          ? h(
              u.getHTMLTagSelector('div', [
                'comments__tooltip',
                this.getState().tabsHovered ? 'show' : '',
              ]),
              POST_SUB_TAB_DISABLE_TOOLTIP
            )
          : null,
        tabs,
        this.commentsList(),

        this.detailsDialog(),
        this.likesDialog(),
        this.imageTypeErrorAlert(),
        this.fileTypeErrorAlert(),
        this.confirmDeletionDialog(),
        isViewingImageSrc
          ? h(
              u.getHTMLTagSelector('div', [
                'comments__image-preview',
                hasimageloaded[isViewingImageSrc] ? '' : 'loading',
              ]),
              {
                onclick: () =>
                  this.setState({
                    isViewingImageSrc: null,
                  }),
              },
              [
                h('div.ripple.comments__image-preview__close', [h('i.fa.fa-times')]),
                h('div.comments__image-preview__loader-wrapper', [
                  h('div.comments__image-preview__loader', [Loader()]),
                  originalImageSize[isViewingImageSrc] / (1024 * 1024) > 1
                    ? h(
                        'span.comments__image-preview__message',
                        `This is a big image (${Math.round(
                          originalImageSize[isViewingImageSrc] / (1024 * 1024)
                        )} MB) and may take some time to load`
                      )
                    : null,
                ]),
                h('img.comments__image-preview__image', {
                  src: isViewingImageSrc,
                  onload: async () => {
                    await this.setImageVisibility(isViewingImageSrc);
                  },
                  alt: 'Full screen image',
                }),
              ]
            )
          : null,
        newMessagesCount > 0
          ? h('div.comments__new-messages-wrapper', [
              h('span.comments__new-messages', [
                h(
                  'button.comments__new-messages-text',
                  {
                    onclick: async () => {
                      await this.setState({
                        newMessagesCount: 0,
                        autoScrollToBottom: true,
                      });
                      this.scrollToBottom();
                    },
                  },
                  `${newMessagesCount} new ${u.pluralize(newMessagesCount, 'message')}`
                ),
                h('button.comments__new-messages-close'),
              ]),
            ])
          : null,
        this.isCommentingEnabled()
          ? h('div.comments__response-box', [
              h(
                'div.comments__sender',
                {
                  ref: this.onRefResponse,
                },
                [
                  RawEditorC({
                    subContext: '' as any,
                    style: RAW_EDITOR_STYLE,
                    textAreaStyle: RAW_EDITOR_TEXT_AREA_STYLE,
                    getInstance: (editor) => {
                      this.rawEditor = editor;
                      this.setState({});
                    },
                    placeholderStyle: RAW_EDITOR_PLACEHOLDER_STYLE,
                    value: this.getState().inputField,
                    oninput: (text) =>
                      this.setState({
                        inputField: text,
                      }),
                    onenter: () => this.sendCommentHandler(),
                    placeholder: COMMENT_BOX_PLACEHOLDER,
                  }),

                  this.getState().isSendingComment
                    ? h('div', style([pad('0.5rem')]), [
                        Loader({
                          width: '1em',
                          height: '1em',
                          borderWidth: '0.15em',
                        }),
                      ])
                    : Icon(icons.commentSend, {
                        className: 'comments__send-button',
                        tabIndex: this.isAccessible ? 0 : undefined,
                        ariaLabel: 'Send Comment',
                        onclick:
                          inputField.length > 0
                            ? () => this.sendCommentHandler()
                            : () =>
                                this.setState({
                                  isFloatingMenuOpen: !this.getState().isFloatingMenuOpen,
                                }),
                      }),
                ]
              ),
              h('div.comments__send-options', [
                Icon(icons.richText, {
                  className: 'comments__send-option',
                  ariaLabel: 'Rich text button',
                  tabIndex: this.isAccessible ? 0 : undefined,
                  onclick: () =>
                    this.setState({
                      richTextDialog: {
                        attachmentType: null,
                        file: null,
                      },
                    }),
                }),
                UploadButton({
                  view: Icon(icons.attachment, {
                    className: 'comments__send-option',
                    tabIndex: this.isAccessible ? 0 : undefined,
                    ariaLabel: 'Upload Attachment Button',
                  }),
                  upload: (file) => this.onFileSelect(file),
                }),
                UploadButton({
                  view: Icon(icons.image, {
                    className: 'comments__send-option',
                    tabIndex: this.isAccessible ? 0 : undefined,
                    ariaLabel: 'Upload Image button',
                  }),
                  upload: (file) => this.onImageSelect(file),
                  accept: 'image/png,image/jpg,image/jpeg',
                }),
              ]),
              this.richTextDialog(),
            ])
          : null,
      ]
    );
  }

  private async updateCommentsSeenPusherAndResetCount() {
    const store = getStore().getState();
    const comments = store.comments;
    const { pusherRequestDetails, toAdd } = comments;
    if (!pusherRequestDetails) {
      return;
    }
    if (toAdd > 0) {
      await api.updateSeenComments(
        <any>{
          context: pusherRequestDetails.context,
          contextId: pusherRequestDetails.contextId,
          subContext: pusherRequestDetails.subContext,
          numCommentsSeen: toAdd,
        },
        pusherRequestDetails.courseId
      );
    }
    await dispatch(Actions.resetNumPusher(undefined));
  }

  private getRole() {
    return this.getProps().courseRole;
  }

  private richTextDialog() {
    const state = this.getState();
    const splitFileName =
      state.richTextDialog && state.richTextDialog.file
        ? u.splitFileName(state.richTextDialog.file.name)
        : null;
    return Dialog(
      {
        open: !!state.richTextDialog,
        title: 'Comment',
        primaryAction: {
          label: 'SEND',
          disabled:
            !!state.richTextDialog &&
            !state.richTextDialog.attachmentType &&
            state.inputField.length === 0,
          onclick: () => this.sendRichTextComment(),
        },
        secondaryAction: {
          label: 'CANCEL',
          onclick: () => this.closeRichTextDialog(),
        },
      },
      state.richTextDialog
        ? [
            state.richTextDialog.attachmentType === 'image'
              ? h('img', {
                  src: state.richTextDialog.imageSrc,
                  style: {
                    padding: '1rem',
                    marginLeft: 'auto',
                    marginTop: '0.3rem',
                    maxWidth: '90%',
                    marginRight: 'auto',
                    objectFit: 'contain',
                    width: 'initial',
                    cursor: 'pointer',
                    display: 'block',
                  },
                  alt: splitFileName ? splitFileName.name : 'image',
                })
              : null,
            Editor({
              subContext: '' as any,
              value: state.inputField,
              oninput: (text) => this.setState({ inputField: text }),
              placeholder: state.richTextDialog.attachmentType
                ? 'Enter an optional comment here'
                : 'Enter comment here',
              enableTextFormatting: true,
              enableFormulaInput: true,
              attachmentView:
                splitFileName && state.richTextDialog.attachmentType === 'file'
                  ? AttachmentViewer({
                      hideNameExtension: true,
                      attachment: {
                        name: splitFileName.name,
                        extension: splitFileName.extension,
                        originalName: splitFileName.name,
                      },
                      downloadRequest: {},
                      downloadUrl: '',
                      style: { marginBottom: '0.5em' },
                      hideDownloadIcon: true,
                    })
                  : undefined,
            }),
          ]
        : []
    );
  }

  private async sendRichTextComment() {
    const { richTextDialog } = this.getState();
    if (!richTextDialog) return;
    if (richTextDialog.attachmentType === 'image' && richTextDialog.file) {
      const response = await api.uploadImage(richTextDialog.file);
      const data = JSON.parse(response.data);
      await this.sendCommentHandler({
        attachmentType: 'image',
        name: '',
        url: data.url,
        originalName: '',
        type: '',
      });
    } else if (richTextDialog.attachmentType === 'file' && richTextDialog.file) {
      const response = await api.uploadFile(richTextDialog.file);
      const { name, extension } = u.splitFileName(richTextDialog.file.name);
      await this.sendCommentHandler({
        attachmentType: 'file',
        name: response.name,
        url: '',
        originalName: name,
        type: extension,
      });
    } else if (!richTextDialog.attachmentType) {
      await this.sendCommentHandler();
    }
  }

  private async closeRichTextDialog() {
    await this.setState({
      richTextDialog: null,
    });
  }

  private fileTypeErrorAlert() {
    const isError = this.getState().isCorrectFileTypeError;
    return Alert(
      {
        style: {
          width: '20em',
        },
        title: 'Warning',
        open: isError,
        actions: [
          FlatButton('Ok', {
            type: 'secondary',
            onclick: () =>
              this.setState({
                isCorrectFileTypeError: false,
              }),
          }),
        ],
      },
      [h('div', {}, `Please upload a file with a valid extension`)]
    );
  }

  private imageTypeErrorAlert() {
    const isError = this.getState().isCorrectImageTypeError;
    return Alert(
      {
        style: {
          width: '20em',
        },
        title: 'Warning',
        open: isError,
        actions: [
          FlatButton('Ok', {
            type: 'secondary',
            onclick: () =>
              this.setState({
                isCorrectImageTypeError: false,
              }),
          }),
        ],
      },
      [h('div', {}, `Please upload a file with the correct extension: png, jpg, jpeg`)]
    );
  }

  private onImageSelect(file: File) {
    this.checkFileType(file);
    const isCorrect = this.getState().isCorrectImageTypeError;
    if (!isCorrect) {
      u.fileToDataURI(file).then((dataURI) => {
        this.setState({
          isFloatingMenuOpen: false,
          richTextDialog: {
            attachmentType: 'image',
            file: file,
            imageSrc: dataURI,
          },
        });
      });
      return {};
    } else {
      return {};
    }
  }

  private async checkFileType(file: File) {
    const type = u.splitFileName(file.name).extension;
    console.log('tpye', type);
    if (type === 'jpg' || type === 'jpeg' || type === 'png') {
      await this.setState({
        isCorrectImageTypeError: false,
      });
    } else {
      await this.setState({
        isCorrectImageTypeError: true,
      });
    }
  }

  private onFileSelect(file: File) {
    const { extension } = u.splitFileName(file.name);
    if (extension.length === 0) {
      console.log('here we are');
      this.setState({
        isCorrectFileTypeError: true,
      });
      return;
    }
    this.setState({
      richTextDialog: {
        attachmentType: 'file',
        file: file,
      },
      isFloatingMenuOpen: false,
    });
    return {};
  }

  private isCommentingEnabled(): boolean {
    const course = this.getProps().course;
    if (!course || course.isArchived) return false;
    const context = this.getProps().context;
    switch (context) {
      case 'course':
      case 'class':
      case 'query': {
        const contextForActivity = getContextActivity(context);
        if (
          contextForActivity &&
          contextForActivity.nodeType === 'query' &&
          contextForActivity.details.status === 'closed'
        ) {
          return false;
        }
        return true;
      }
      case 'quiz':
      case 'poll':
      case 'resource':
      case 'discussion':
      case 'assignment': {
        const contextActivity = getContextActivity(context);
        if (
          contextActivity &&
          contextActivity.nodeType === 'quiz' &&
          this.getRole() === 'student' &&
          !quizFns.isQuizSubmitted(contextActivity)
        ) {
          return false;
        }
        if (contextActivity && contextActivity.details.published === 1) {
          return true;
        }
      }
      default:
        return false;
    }
  }

  private async sendComment(attachment?: {
    attachmentType: 'file' | 'image';
    name: string;
    originalName: string;
    url: string;
    type: string;
  }) {
    const courseRole = this.getRole();
    const { inputField, subContext, isSendingComment } = this.getState();
    if (isSendingComment) return;
    if (!attachment && u.checkEmptyString(inputField).length === 0) {
      await this.setState({
        inputField: '',
        attachment: {
          name: '',
          originalName: '',
          extension: '',
        },
        richTextDialog: null,
      });
      return;
    }
    const context = this.getProps().context;
    const commentsContext = getCommentsContextService(context, subContext || 'preClass');
    const ctx = contextMap[context];
    const classActivity = this.getContextActivity();
    await this.setState({
      isSendingComment: true,
    });
    await dispatch(
      Actions.create({
        context: ctx || 'course',
        classId:
          classActivity && 'classId' in classActivity.identifiers
            ? classActivity.identifiers.classId
            : null,
        contextId: commentsContext.contextId,
        subContext:
          classActivity && classActivity.nodeType !== 'assignment'
            ? classActivity.details.toBeDone
            : this.getState().subContext || 'preClass',
        message: inputField,
        hasAttachment: attachment ? 1 : 0,
        attachmentType: attachment ? attachment.attachmentType : '',
        attachmentName: '',
        attachments: [
          {
            originalName:
              attachment && attachment.attachmentType === 'file' ? attachment.originalName : '',
            name: attachment && attachment.attachmentType === 'file' ? attachment.name : '',
            extension: attachment && attachment.attachmentType === 'file' ? attachment.type : '',
          },
        ],
        imageUrl: attachment && attachment.attachmentType === 'image' ? attachment.url : '',
        localTime: dt.format(dt.now(), 'YYYYMMDDTHH:mm'),
      })
    );
    if (this.elem) {
      this.elem.scrollTop = this.elem.scrollHeight;
    }
    googleAnalytics.commentCreated(context, this.getAnalyticsSubContext());
    await this.setState({
      isSendingComment: false,
      inputField: '',
      attachment: {
        name: '',
        originalName: '',
        extension: '',
      },
      richTextDialog: null,
    });

    if (
      courseRole === 'student' &&
      classActivity &&
      classActivity.nodeType === 'discussion' &&
      classActivity.details.submitFirst &&
      (!classActivity.userData || !classActivity.userData.submitted)
    ) {
      dispatch(
        DiscussionActions.markParticipation({
          discussionId: classActivity._id,
          timestamp: dt.unix(),
        })
      );
      this.loadComments(context, subContext);
      // this.setNewTagNumber(context, subContext);
    }
  }

  private getContextActivity() {
    return getContextActivity(this.getProps().context);
  }

  private getAnalyticsSubContext(): 'course' | 'class' | 'assignment' | 'pre-class' | 'in-class' {
    const context = this.getProps().context;
    const classActivity = this.getContextActivity();
    return (
      ((
        {
          course: 'course',
          class: 'class',
          assignment: 'assignment',
        } as any
      )[context] as any) ||
      (classActivity && classActivity.nodeType !== 'assignment'
        ? {
            preClass: 'pre-class',
            inClass: 'in-class',
            review: 'review',
          }[classActivity.details.toBeDone]
        : 'course')
    );
  }

  private async sendCommentHandler(attachment?: {
    attachmentType: 'file' | 'image';
    name: string;
    originalName: string;
    url: string;
    type: string;
  }) {
    await this.sendComment(attachment);
    if (this.rawEditor) {
      this.rawEditor.focus();
    }
  }
  private isAccessible: boolean | undefined = false;

  private onScroll = (event: Event) => {
    const { autoScrollToBottom } = this.getState();
    const target = event.target as HTMLElement;

    target.scrollTop === 0 && this.unseenComments > document.querySelectorAll('.comment').length
      ? this.fetchOldComments()
      : null;

    if (!autoScrollToBottom && u.isScrolledToBottom(target)) {
      this.setState({
        newMessagesCount: 0,
        autoScrollToBottom: true,
      });
    } else if (autoScrollToBottom && !u.isScrolledToBottom(target)) {
      this.setState({
        autoScrollToBottom: false,
      });
    }
  };

  private commentsList() {
    const { isLoading, isFetchingOld } = this.getState();
    const n = this.getState().newTagCommentNumber;
    const comments = getStore().getState().comments.comments;
    const groupedComments = groupComments(comments || [], n);

    if (isLoading) return h('div.comments__loader', [Loader()]);

    if (!comments || comments.length === 0) {
      return h(
        'div.comments__placeholder',
        {
          key: 'no-comments-placeholder',
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        this.noCommentsMessage()
      );
    }

    const isNewTagVisible =
      groupedComments.unseen.length > 0 &&
      this.unseenComments <= document.querySelectorAll('.comment').length;

    return h(
      'div.comments__list',
      {
        key: 'comments-list',
        onscroll: this.onScroll,
        ref: this.onRef,
      },
      [
        isFetchingOld
          ? h('div.comments__list__loader', [Loader()])
          : this.getState().isLoadMoreCommentsVisible
          ? FlatButton('Load Older Comments', {
              tabIndex: this.isAccessible ? 0 : undefined,
              id: 'load-older-comments-btn',
              classNames: ['comments__load-older'],
              type: 'primary',
              onclick: this.getState().isLoadMoreCommentsVisible
                ? () => this.loadMoreComments(true)
                : undefined,
            })
          : this.getState().loadMoreCommentsMessage !== ''
          ? h('p.comments__no-more-message', this.getState().loadMoreCommentsMessage)
          : null,
        ...(comments.length === 0
          ? this.noCommentsMessage()
          : [
              ...flatten(groupedComments.seen.map(this.renderCommentGroup)),
              isNewTagVisible ? NEW_TAG : null,
              ...flatten(groupedComments.unseen.map(this.renderCommentGroup)),
            ]),
      ]
    );
  }

  private renderCommentGroup = (comments: IComment[]): View[] => {
    if (comments.length === 0) {
      return [];
    }
    return comments.map((c, i) => this.renderComment(i === 0 ? false : true)(c));
  };

  private loadMoreComments = (frombtn?: boolean) => {
    const comments = getStore().getState().comments.comments;
    if (comments) {
      this.getCommentsContextService(this.getProps().context, this.getState().subContext)
        .totalComments > comments.length
        ? this.fetchOldComments(frombtn)
        : this.setState({ isLoadMoreCommentsVisible: false });
    }
  };

  private elem?: HTMLElement;
  private onRef = (elem?: HTMLElement) => {
    if (elem === this.elem) return;
    if (!elem) return;
    if (this.elem === elem) {
      return;
    }
    this.elem = elem;
  };

  private elemResponseBox?: HTMLElement;
  private onRefResponse = (elemResponseBox?: HTMLElement) => {
    if (elemResponseBox === this.elemResponseBox) return;
    if (!elemResponseBox) return;
    if (this.elemResponseBox === elemResponseBox) {
      return;
    }
    this.elemResponseBox = elemResponseBox;
  };

  private lastFocusedElement: Element | null = null;
  private lastFocusedElementSpare: Element | null = null;

  private renderComment(hideUserInfo: boolean) {
    const hasimageloaded = this.getState().hasImageLoaded;
    return (comment: IComment) =>
      Comment({
        comment: comment,
        hideUserInfo,
        activeComment: this.getState().activeComment,
        contextId: this.getContextId(),
        currentUser: getStore().getState().getIn.session!.userId,
        courseRole: this.getRole() || 'student',
        context: this.getCommentsContext(),
        subContext: this.getSubContext(),
        onfocus: () => {
          this.setState({ activeComment: comment._id });
        },
        onblur: () => {
          this.setState({ activeComment: '' });
        },
        onmouseOut: (v?: boolean) => {
          if (v) {
            this.setState({ activeComment: comment._id });
          }
        },
        onClick: async () => {
          this.lastFocusedElement = document.activeElement;
          u.unsetTabIndices();
          this.canRetractOrAward(comment._id);
          await this.setState({
            isDetailsDialogOpenFor: comment._id,
          });
        },
        maxImageWidth: getStore().getState().app.isContextPanelExpanded ? '25em' : '100%',
        onClickLikes: () => {
          this.lastFocusedElementSpare = document.activeElement;
          u.unsetTabIndices();
          this.loadLikes(comment);
        },
        onClickImage: (src, size) =>
          this.setState({
            isViewingImageSrc: src,
            isDetailsDialogOpenFor: null,
            originalImageSize: {
              ...this.getState().originalImageSize,
              [src]: size,
            },
          }),
        isImageVisible:
          comment.details.hasAttachment && comment.details.attachmentType === 'image'
            ? hasimageloaded[comment.details.imageUrl]
            : true,
        onClickDelete: () => this.setDeleteDialogOpenFor(comment._id),
        analyticsSubContext: this.getAnalyticsSubContext(),
      });
  }

  private async setImageVisibility(src: string) {
    await this.setState({
      hasImageLoaded: {
        ...this.getState().hasImageLoaded,
        [src]: true,
      },
    });
  }

  private async loadLikes(comment: IComment) {
    await this.setState({
      commentLikesDialog: {
        commentId: comment._id,
        data: null,
      },
    });
    // condition to send a hit only if there are likes/thanks
    if (comment.stats.numHelps > 0 || comment.stats.numStars > 0) {
      const response = await api.fetchLikes(comment._id);
      await this.setState({
        commentLikesDialog: {
          commentId: comment._id,
          data: response.data,
        },
      });
      dispatch(
        GetInActions.fetchAvatars(
          response.data.approvers
            .map((u) => u.avatar)
            .concat(response.data.approvers.map((u) => u.avatar))
        )
      );
    }
  }

  // private tag(text: string) {
  //     return h("div.tag-container", style([ml("0.8em")], {
  //                     height: "80%",
  //                     paddingLeft: "0.5rem",
  //                     paddingRight: "0.5rem",
  //                     backgroundColor: colors.darkPink,
  //                     textAlign: "center",
  //                     fontWeight: "900",
  //                     borderRadius: "4px"
  //             }), [
  //                 h("div.tag-text", style(["white", "x-small", {
  //                     lineHeight: "1.5em",
  //                 }]), [text])
  //     ]);
  // }

  private likesDialog() {
    const state = this.getState();
    const comments = getStore().getState().comments.comments;
    const commentLikesDialog = state.commentLikesDialog;
    const comment =
      commentLikesDialog && comments
        ? comments.find((c) => c._id === commentLikesDialog.commentId) || null
        : null;
    if (!comment) return null;
    const awards = comment.stats.awards;
    const displayUser = (user: api.IFetchLikesResponse['users'][number]) =>
      h(
        'div',
        style(
          [
            'flex',
            pad('0.5rem 1rem'),
            ml('1rem'),
            'borderBox',
            {
              backgroundColor: 'white',
            },
          ],
          {},
          {
            key: user._id,
            tabIndex: this.isAccessible ? 0 : undefined,
          }
        ),
        [
          Avatar(user.avatar, user.name, {
            className: 'comments__avatar',
          }),
          h('div', style(['flex', 'column', 'spaceBetween', mt('0.5em')]), [
            h('div.created-by', style(['flex']), [
              h('div', style(['green']), user.name),
              // this.tag(user.role)
            ]),
            h('div', style(['lightGrey']), user.liked ? 'Liked' : 'Thanked'),
          ]),
        ]
      );

    if (state.commentLikesDialog && state.commentLikesDialog.data) {
      // console.log("lol", displayUser(state.commentLikesDialog.data.));
    }
    // const displayUserWithoutStatus = (user: api.IFetchLikesResponse["users"][number]) =>
    //     h("div", style(["flex", pad("0.5em 1em"), ml("1rem"), "borderBox", {
    //         backgroundColor: "white"
    //     }]), [
    //         Avatar(user.avatar, {
    //             width: "3em",
    //             height: "3em",
    //             marginRight: "0.5em"
    //         }),
    //         h("div", style(["flex", "column", "spaceBetween", mt("0.5em")]), [
    //             h("div", style(["green"]), user.name),
    //         ])
    //     ]);
    return Dialog(
      {
        open: !!state.commentLikesDialog,
        title: 'Comment stats',
        secondaryAction: {
          label: 'DONE',
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElementSpare as HTMLElement).focus();
            this.setState({
              commentLikesDialog: null,
            });
          },
        },
      },
      state.commentLikesDialog && state.commentLikesDialog.data
        ? [
            awards && awards.length > 0
              ? h(
                  'div',
                  style(
                    [pad('0.5rem 1rem'), 'darkBlue', 'borderBox', 'large', mt('1rem')],
                    {},
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                    }
                  ),
                  'Awards'
                )
              : null,
            ...(awards && awards.length > 0 ? (awards as any).map(this.individualAwardView) : []),

            state.commentLikesDialog.data.users.filter((u) => u.liked).length > 0 ||
            state.commentLikesDialog.data.users.filter((u) => u.thanked).length > 0 ||
            state.commentLikesDialog.data.approvers.length > 0
              ? h(
                  'div',
                  style(
                    [pad('0.5rem 1rem'), 'darkBlue', 'borderBox', 'large', mt('1rem')],
                    {},
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                    }
                  ),
                  'Reactions'
                )
              : null,

            // state.commentLikesDialog.data.users
            // .filter(u => u.liked)
            // .length > 0
            // ? h("div", style([pad("0.5rem 1rem"), "darkBlue",
            //             "borderBox", "large", mt("1rem")]), "Liked by")
            // : null,
            ...state.commentLikesDialog.data.users.filter((u) => u.liked).map(displayUser),

            // state.commentLikesDialog.data.users
            // .filter(u => u.thanked)
            // .length > 0
            // ? h("div", style([pad("0.5rem 1rem"), "darkBlue",
            //             "borderBox", "large", mt("1rem")]), "Thanks")
            // : null,
            ...state.commentLikesDialog.data.users.filter((u) => u.thanked).map(displayUser),

            // state.commentLikesDialog.data.approvers.length > 0
            // ? h("div", style([pad("0.5rem 1rem"), "darkBlue",
            //             "borderBox", "large", mt("1rem")]), "Likes")
            // : null,
            ...state.commentLikesDialog.data.approvers.map(displayUser),
          ]
        : awards && awards.length > 0
        ? [
            h(
              'div',
              style([pad('0.5rem 1rem'), 'darkBlue', 'borderBox', 'large', mt('1rem')]),
              'Awards'
            ),
            ...(awards as any).map(this.individualAwardView),
          ]
        : [
            h('div', style(['textCenter', 'flex', 'fullWidth', 'justifyCenter', mt('2rem')]), [
              Loader(),
            ]),
          ]
    );
  }

  private getContextId() {
    return this.getCommentsContextService(this.getProps().context, this.getSubContext()).contextId;
  }

  private individualAwardView = (a: {
    points: number;
    awardedBy: {
      name: string;
      userId: string;
      avatar: string;
      role: string;
    };
  }) => {
    return h(
      'div.containter',
      style([
        'flex',
        pl('1rem'),
        {
          backgroundColor: 'white',
        },
      ]),
      [
        h('span.approved', { style: {}, 'aria-label': 'Rating Icon' }),
        h(
          'div.award',
          style(
            [pad('0.5rem'), 'grey'],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          `${a.awardedBy.name} awarded ${a.points} points`
        ),
      ]
    );
  };

  private detailsDialog() {
    const state = this.getState();
    const hasimageloaded = this.getState().hasImageLoaded;
    const comments = getStore().getState().comments.comments;
    const courseRole = this.getRole();
    const comment =
      state.isDetailsDialogOpenFor && comments
        ? comments.find((c) => c._id === state.isDetailsDialogOpenFor) || null
        : null;
    if (!comment) return null;
    const awards = comment.stats.awards;
    const contextId = this.getContextId();
    const context = this.getCommentsContext();
    const hasAwarded = this.getState().hasAwardedPoints;
    const points = this.getState().commentRating;
    const subContext = this.getSubContext();
    const commentIndex =
      state.isDetailsDialogOpenFor && comments
        ? comments.findIndex((c) => c._id === state.isDetailsDialogOpenFor) || 0
        : null;
    const nextComment =
      state.isDetailsDialogOpenFor && comments ? comments[(commentIndex || 0) + 1] : null;
    const previousComment =
      state.isDetailsDialogOpenFor && comments ? comments[(commentIndex || 0) - 1] : null;
    const currentUser = getStore().getState().getIn.session!.userId;
    const isMobile = getStore().getState().app.isMobile;
    const markComment = async (mark: 'helpful' | 'unhelpful' | 'starred' | 'unstarred') => {
      if (!comment) return;
      await dispatch(
        Actions.mark(
          {
            commentId: comment._id,
            contextId: contextId,
            context: context,
            subContext: subContext,
            marked: mark,
          },
          this.getProps().context,
          this.getAnalyticsSubContext()
        )
      );
    };

    const canRateComment = getCanRateComment({
      comment,
      courseRole,
      context,
      contextId,
      state: getStore().getState(),
    });

    return Dialog(
      {
        open: state.isDetailsDialogOpenFor !== null && comment !== null,
        title: 'Post',
        bodyStyle: {
          backgroundColor: colors.backgroundColor,
          padding: '0',
        },
        fullScreen: isMobile ? true : false,
        key: `post-dialog-${comment._id}`,
        style: {
          backgroundColor: colors.backgroundColor,
        },
        secondaryAction: isMobile
          ? {
              label: 'BACK',
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () =>
                this.setState({
                  isDetailsDialogOpenFor: null,
                }),
            }
          : {
              // label: "BACK",
              type: 'view',
              view: FlatButton('Back', {
                tabIndex: this.isAccessible ? 0 : undefined,
                style: {
                  marginLeft: 'auto',
                  marginRight: 'auto',
                },
                type: 'secondary',
                onclick: () => {
                  u.resetTabIndices();
                  (this.lastFocusedElement as any).focus();
                  this.setState({
                    isDetailsDialogOpenFor: null,
                  });
                },
              }),
              // view: h("div", style(["flex", "alignCenter", "pointer"], {}, {
              //     onclick: () => this.setState({
              //     isDetailsDialogOpenFor: null
              // })
              // }),
              //         [
              //             h("div.button", style([ml("auto"), mr("auto")]), "BACK")
              //         ])
              // onclick: () => this.setState({
              //     isDetailsDialogOpenFor: null
              // })
            },
        primaryAction:
          comment && isMobile
            ? {
                type: 'view',
                view: h('div', style(['flex', 'alignCenter']), [
                  h(
                    'span',
                    style([
                      'flex',
                      'alignCenter',
                      'justifyCenter',
                      {
                        fontSize: '1.3em',
                        marginRight: '0.3rem',
                      },
                    ]),
                    [
                      h(
                        'i.fa.fa-angle-left',
                        style(
                          [
                            'white',
                            {
                              padding: '0.5rem 0em',
                              marginLeft: '0.5rem',
                              marginRight: '1rem',
                            },
                          ],
                          {},
                          {
                            onclick: previousComment
                              ? async () => {
                                  await this.setState({
                                    isDetailsDialogOpenFor: previousComment._id,
                                  });
                                  await this.canRetractOrAward(previousComment._id);
                                }
                              : undefined,
                          }
                        ),
                        []
                      ),
                      h(
                        'i.fa.fa-angle-right',
                        style(
                          [
                            'white',
                            {
                              padding: '0.5rem 0em',
                              marginLeft: '1rem',
                              marginRight: '0.2rem',
                            },
                          ],
                          {},
                          {
                            onclick: nextComment
                              ? async () => {
                                  await this.setState({
                                    isDetailsDialogOpenFor: nextComment._id,
                                  });
                                  await this.canRetractOrAward(nextComment._id);
                                }
                              : undefined,
                          }
                        ),
                        []
                      ),
                    ]
                  ),
                ]),
              }
            : undefined,
      },
      comment !== null && state.isDetailsDialogOpenFor !== null
        ? [
            Comment({
              comment: comment,
              hideActions: true,
              currentUser: getStore().getState().getIn.session!.userId,
              courseRole: this.getRole() || 'student',
              context: this.getCommentsContext(),
              subContext: this.getSubContext(),
              contextId: this.getContextId(),
              onClickLikes: () => {
                this.lastFocusedElementSpare = document.activeElement;
                u.unsetTabIndices();
                this.loadLikes(comment);
              },
              analyticsSubContext: this.getAnalyticsSubContext(),
              onClickDelete: () => this.setDeleteDialogOpenFor(comment._id),
              onLoad: (src) => this.setImageVisibility(src),
              isImageVisible:
                comment.details.hasAttachment && comment.details.attachmentType === 'image'
                  ? hasimageloaded[comment.details.imageUrl]
                  : true,
            }),
            ...awards.map(this.individualAwardView),
            comment.removed
              ? null
              : h(
                  'div',
                  {
                    style: {
                      display: 'flex',
                      flexDirection: 'column',
                    },
                  },
                  [
                    h(
                      'div.reactions',
                      style(
                        ['grey', 'uppercase', margin('0.5rem 0'), pad('0 0.5rem')],
                        {},
                        {
                          tabIndex: this.isAccessible ? 0 : undefined,
                        }
                      ),
                      'Reactions'
                    ),
                    ...(comment.userData.starred
                      ? []
                      : [
                          // gift icon
                          comment.userData.helpful
                            ? h(
                                'div',
                                style(
                                  [
                                    'flex',
                                    'alignCenter',
                                    'pointer',
                                    {
                                      padding: '0.5rem',
                                      marginBottom: '0.25rem',
                                      backgroundColor: 'white',
                                      borderBottom: '1px solid lightgrey',
                                    },
                                  ],
                                  {},
                                  {
                                    tabIndex: this.isAccessible ? 0 : undefined,
                                    role: 'button',
                                    'aria-label': 'unThank this comment author',
                                    onclick: () => markComment('unhelpful'),
                                  }
                                ),
                                [
                                  h('span.unthank', { style: {}, 'aria-label': 'Thank Icon' }),
                                  h('span.unthank-text', {}, 'Thanked'),
                                ]
                              )
                            : h(
                                'div',
                                style(
                                  [
                                    'flex',
                                    'alignCenter',
                                    'pointer',
                                    {
                                      padding: '0.5rem',
                                      marginBottom: '0.1rem',
                                      borderBottom: '1px solid lightgrey',
                                      backgroundColor: 'white',
                                    },
                                  ],
                                  {},
                                  {
                                    tabIndex: this.isAccessible ? 0 : undefined,
                                    role: 'button',
                                    onclick: () => markComment('helpful'),
                                  }
                                ),
                                [
                                  h('span.thank', { style: {}, 'aria-label': 'Thank Icon' }),
                                  h('span.thank-text', style(['grey']), 'Thank the comment author'),
                                ]
                              ),
                        ]),
                    ...(comment.userData.helpful
                      ? []
                      : [
                          // thumb up/starred
                          comment.userData.starred
                            ? h(
                                'div',
                                style(
                                  [
                                    'flex',
                                    'alignCenter',
                                    'pointer',
                                    {
                                      padding: '0.5rem',
                                      borderBottom: '1px solid lightgrey',
                                      backgroundColor: 'white',
                                    },
                                  ],
                                  {},
                                  {
                                    tabIndex: this.isAccessible ? 0 : undefined,
                                    role: 'button',
                                    'aria-label': 'Liked, click to unlike this comment',
                                    onclick: () => markComment('unstarred'),
                                  }
                                ),
                                [
                                  h('span.unhelpful', { style: {}, 'aria-label': 'Like Icon' }),
                                  h('span.unhelpful-text', {}, 'Liked'),
                                ]
                              )
                            : h(
                                'div',
                                style(
                                  [
                                    'flex',
                                    'alignCenter',
                                    'pointer',
                                    {
                                      padding: '0.5rem',
                                      borderBottom: '1px solid lightgrey',
                                      backgroundColor: 'white',
                                    },
                                  ],
                                  {},
                                  {
                                    tabIndex: this.isAccessible ? 0 : undefined,
                                    role: 'button',
                                    'aria-label': 'Like this comment',
                                    onclick: () => markComment('starred'),
                                  }
                                ),
                                [
                                  h('span.helpful', { style: {}, 'aria-label': 'Like Icon' }),
                                  h('span.helpful-text', style(['grey']), 'Like this comment'),
                                ]
                              ),
                        ]),

                    ...(canRateComment
                      ? [
                          h('div.rating', style(['grey', 'uppercase', pad('0.5rem')]), 'Rating'),
                          h('div.comment-rating', {}, [
                            h(
                              'div.slider-container',
                              style([
                                'flex',
                                pad('0.5rem'),
                                {
                                  backgroundColor: 'white',
                                },
                              ]),
                              [
                                hasAwarded
                                  ? h('span.approved', { style: {}, 'aria-label': 'Rating Icon' })
                                  : h('span.approved', {
                                      style: style([
                                        margin('0.5rem 0'),
                                        pad('0.5rem 1rem'),
                                        isMobile ? mb('0em') : mb('0.5em'),
                                      ]).style,
                                      'aria-label': 'Rating Icon',
                                    }),
                                hasAwarded
                                  ? h(
                                      'div',
                                      style(
                                        [mt('auto'), mb('auto'), ml('0.5rem'), mr('auto')],
                                        {},
                                        {
                                          tabIndex: this.isAccessible ? 0 : undefined,
                                        }
                                      ),
                                      `You awarded ${points} points to this post`
                                    )
                                  : RangeSlider({
                                      step: 1,
                                      min: 0,
                                      max: 10,
                                      value: points,
                                      isAccessible: this.isAccessible,
                                      oninput: async (event) => {
                                        await this.setCommentRating(event.target.valueAsNumber);
                                      },
                                      onchange: (event) => {
                                        if (event.target.valueAsNumber !== 0) {
                                          setTimeout(async () => {
                                            await this.setState({
                                              isPointConfirmationDialogOpen: true,
                                            });
                                          }, 500);
                                        }
                                      },
                                    }),
                                hasAwarded
                                  ? h(
                                      'i.fa.fa-times',
                                      style(
                                        [
                                          'red',
                                          'pointer',
                                          'large',
                                          pad('0.5rem'),
                                          ml('auto'),
                                          mt('auto'),
                                          mb('auto'),
                                        ],
                                        {},
                                        {
                                          tabIndex: this.isAccessible ? 0 : undefined,
                                          onclick: () =>
                                            this.setState({
                                              isPointRetractionDialogOpen: true,
                                            }),
                                        }
                                      )
                                    )
                                  : null,
                              ]
                            ),
                          ]),
                        ]
                      : []),
                    ...(courseRole === 'admin' ||
                    courseRole === 'instructor' ||
                    currentUser === comment.details.createdBy.userId
                      ? [
                          h(
                            'div.delete',
                            {
                              style: {
                                color: colors.grey,
                                padding: '0 0.5rem',
                                margin: '0.5rem 0',
                              },
                              // key: `delete-${comment._id}`
                            },
                            'Delete'
                          ),
                          h(
                            'div',
                            style(
                              [
                                'flex',
                                'alignCenter',
                                {
                                  padding: '0.5rem',
                                  borderBottom: '1px solid lightgrey',
                                  backgroundColor: 'white',
                                  cursor: 'pointer',
                                },
                              ],
                              {},
                              {
                                tabIndex: this.isAccessible ? 0 : undefined,
                                onclick: () => this.setDeleteDialogOpenFor(comment._id),
                              }
                            ),
                            [
                              h(
                                'i.fa.fa-trash',
                                style([
                                  'red',
                                  {
                                    fontSize: '1.25rem',
                                    padding: '0.4rem 0em',
                                    marginLeft: '0.5rem',
                                    marginRight: '0.5rem',
                                  },
                                ])
                              ),
                              h('span.delete-text', style(['grey']), 'Delete this comment'),
                            ]
                          ),
                        ]
                      : []),
                  ]
                ),
            this.ratingRetractDialog(),
            this.ratingConfirmationDialog(),
          ]
        : []
    );
  }

  private async onYesClickHandler() {
    const points = this.getState().commentRating;
    const commentId = this.getState().isDetailsDialogOpenFor;
    if (!commentId) return;
    const currentUserName = getStore().getState().getIn.session!.name;
    const currentUserAvatar = getStore().getState().getIn.session!.avatar;
    const currentUserId = getStore().getState().getIn.session!.userId;
    const currentUserRole = this.getProps().courseRole;
    const request: api.ICommentAwardRatingRequest = {
      points: points,
      action: 'awarding',
      commentId: commentId,
    };
    const instructor = {
      userId: currentUserId,
      avatar: currentUserAvatar,
      name: currentUserName,
      role: currentUserRole,
    };
    await dispatch(Actions.award(request, instructor as any));
    await this.canRetractOrAward(commentId);
    await this.setState({
      isPointConfirmationDialogOpen: false,
    });
  }

  private async onRetractClickHandler() {
    const commentId = this.getState().isDetailsDialogOpenFor;
    if (!commentId) return;
    const currentUserName = getStore().getState().getIn.session!.name;
    const currentUserAvatar = getStore().getState().getIn.session!.avatar;
    const currentUserId = getStore().getState().getIn.session!.userId;
    const currentUserRole = this.getProps().courseRole;
    const request: api.ICommentAwardRatingRequest = {
      points: 0,
      action: 'retracting',
      commentId: commentId,
    };
    const instructor = {
      userId: currentUserId,
      avatar: currentUserAvatar,
      name: currentUserName,
      role: currentUserRole,
    };
    await dispatch(Actions.award(request, instructor as any));
    await this.canRetractOrAward(commentId);
    await this.setState({
      isPointRetractionDialogOpen: false,
    });
  }

  private async canRetractOrAward(currentCommentId: string) {
    const comments = getStore().getState().comments.comments;
    if (!comments) return;
    const currentComment = comments.filter((c) => c._id === currentCommentId);

    if (!currentComment) return;
    const currentUserId = getStore().getState().getIn.session!.userId;
    const commentAwardsPoints = currentComment[0].stats.awards
      .filter((a) => a.awardedBy.userId === currentUserId)
      .map((a) => a.points);

    if (commentAwardsPoints.length > 0) {
      await this.setState({
        hasAwardedPoints: true,
        commentRating: commentAwardsPoints[0],
      });
    } else {
      await this.setState({
        hasAwardedPoints: false,
        commentRating: 0,
      });
    }
  }

  private ratingConfirmationDialog() {
    const points = this.getState().commentRating;
    const isOpen = this.getState().isPointConfirmationDialogOpen;
    return Alert(
      {
        style: {
          width: '20em',
        },
        title: 'Confirmation',
        open: isOpen,
        actions: [
          FlatButton('No', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.setState({
                isPointConfirmationDialogOpen: false,
              }),
          }),
          FlatButton('Yes', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.onYesClickHandler(),
          }),
        ],
      },
      [h('div', {}, `Are you sure you want to award ${points} points to this student's post`)]
    );
  }

  private ratingRetractDialog() {
    const points = this.getState().commentRating;
    const isOpen = this.getState().isPointRetractionDialogOpen;
    return Alert(
      {
        style: {
          width: '20em',
        },
        title: 'Confirmation',
        open: isOpen,
        actions: [
          FlatButton('No', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.setState({
                isPointRetractionDialogOpen: false,
              }),
          }),
          FlatButton('Yes', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.onRetractClickHandler(),
          }),
        ],
      },
      [
        h(
          'div',
          {},
          `Are you sure you want to retract the ${points}` +
            ` points you awarded to this student's post`
        ),
      ]
    );
  }

  private async setCommentRating(value: number) {
    await this.setState({
      commentRating: value,
    });
  }

  private async setDeleteDialogOpenFor(commentId: string) {
    this.lastFocusedElementSpare = (document.activeElement as HTMLElement).closest('.comment');
    u.unsetTabIndices();
    await this.setState({
      isConfirmDeleteDialogOpenFor: commentId,
    });
  }

  private async unsetDeleteDialogOpenFor() {
    u.resetTabIndices();
    this.lastFocusedElementSpare ? (this.lastFocusedElementSpare as HTMLElement).focus() : null;
    await this.setState({
      isConfirmDeleteDialogOpenFor: null,
    });
  }

  private confirmDeletionDialog() {
    const isConfirmDeleteDialogOpenFor = this.getState().isConfirmDeleteDialogOpenFor;
    return Alert(
      {
        style: {
          width: '20em',
        },
        title: 'Warning',
        open: isConfirmDeleteDialogOpenFor !== null ? true : false,
        actions: [
          FlatButton('No', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => {
              this.unsetDeleteDialogOpenFor();
            },
          }),
          FlatButton('Yes', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.deleteComment(),
          }),
        ],
      },
      [h('div', {}, 'Do you want to remove this comment?')]
    );
  }

  private async deleteComment() {
    const state = this.getState();
    const commentId = state.isConfirmDeleteDialogOpenFor;
    if (commentId) {
      await dispatch(
        Actions.remove(commentId, this.getProps().context, this.getAnalyticsSubContext())
      );
    }
    await this.unsetDeleteDialogOpenFor();
  }

  private noCommentsMessage() {
    const { context } = this.getProps();
    const { subContext } = this.getState();
    let msg = '';

    if (context === 'course') {
      msg =
        'This is an informal course discussion area. ' +
        'Any small queries or doubts, if the students ' +
        'have them, can show up here for all to see. ' +
        'In case you have an informal announcement you ' +
        'can make it here. So far, no one has had anything to say.';
    } else if (context === 'class') {
      msg =
        'Any discussions pertaining to this class ' +
        'can be had in this chat area. The idea is ' +
        'for the students to have an informal ' +
        'discussion about the class content before ' +
        'the class has begun, or while it is on, or ' +
        'after it is over.';
    } else if (context === 'assignment') {
      if (subContext === 'preSub') {
        msg =
          'Every student has access to this ' +
          'discussion area, irrespective of whether ' +
          'they have submitted the assignment or not. ' +
          'It was designed to encourage discussions ' +
          'about the assignment before submitting it.';
        if (this.getRole() === 'student') {
          msg =
            'In case you face any difficulties while attempting ' +
            'the assignment, please feel free to use this discussion thread.' +
            `You will be able to access the "Post Submission" discussion ` +
            'thread after you have submitted the assignment';
        }
      } else if (subContext === 'postSub') {
        msg =
          'Only the students who have submitted the ' +
          'assignment will have access to this discussion thread.';
      }
    } else {
      const contextActivity = getContextActivity(context);
      if (contextActivity) {
        if (contextActivity.nodeType === 'query' && contextActivity.details.status === 'closed') {
          msg =
            `You can no longer comment here, since the author of` +
            ` this query has marked it as "Closed"`;
        } else if (!contextActivity.details.published) {
          msg = 'Comments will appear here once this activity is published';
        } else if (contextActivity.nodeType === 'poll') {
          msg =
            'Every course team member has access ' +
            'to this poll discussion thread, before ' +
            'or after responding to the poll.';
        } else if (contextActivity.nodeType === 'quiz') {
          if (this.getRole() === 'student') {
            if (!quizFns.isQuizSubmitted(contextActivity)) {
              msg =
                'You will be able to post doubts, ' +
                'questions and random musings about ' +
                'this quiz once you have submitted the ' +
                'quiz. Do well!';
            } else {
              msg =
                'This is the quiz discussion area. ' +
                'Any doubts or points regarding the ' +
                "quiz can be posted here after you've " +
                'submitted the quiz. Happy discussing!';
            }
          } else {
            msg =
              'Students will be able to post their ' +
              'thoughts on this quiz using this ' +
              'discussion area. To prevent quiz spoilers, ' +
              'the students will be able to access it ' +
              'only once they have submitted the quiz.';
          }
        } else if (contextActivity.nodeType === 'resource') {
          if (this.getRole() === 'student') {
            msg =
              'This is the resource discussion thread. ' +
              'If you have any thoughts on this shared ' +
              'resource material, you can share it here.';
          } else {
            msg =
              'You can encourage students to ' + 'participate in discussing this shared resource.';
          }
        } else if (
          contextActivity.nodeType === 'discussion' &&
          contextActivity.details.submitFirst &&
          this.getRole() === 'student' &&
          (!contextActivity.userData || !contextActivity.userData.submitted)
        ) {
          msg = 'You need to contribute a post before reading ' + 'other posts.';
        } else {
          msg = 'There are no comments yet.';
        }
      }
    }
    return msg;
  }

  private getCommentsContext(): ICommentsContext {
    return (
      {
        course: 'course',
        class: 'classes',
        assignment: 'assignments',
        quiz: 'quizzes',
        poll: 'polls',
        resource: 'resources',
        discussion: 'discussions',
        query: 'queries',
      } as any
    )[this.getProps().context];
  }

  private getSubContext() {
    return this.getState().subContext;
  }

  private unseenComments = 0;

  private async loadComments(context: IAppContext, subContext: ICommentsSubContext) {
    const role = this.getRole();
    if (context === 'getin') return;
    if (context === 'home') return;
    if (context === 'class' && !classService.getCurrentClass()) {
      return;
    }
    if (role === null) return;
    const activity = getContextActivity(context) as IClassActivity;

    const commentsContext = this.getCommentsContext();
    const contextService = this.getCommentsContextService(context, subContext);
    await this.setState({
      isLoading: true,
    });
    dispatch(
      Actions.setContext({
        context: commentsContext,
        subContext: this.getState().subContext,
        contextId: contextService.contextId,
      })
    );
    if (
      (role === 'student' &&
        activity &&
        activity.nodeType === 'discussion' &&
        activity.details.submitFirst &&
        !(activity.userData && activity.userData.submitted)) ||
      (activity &&
        activity.nodeType === 'quiz' &&
        !canSeeQuizComments(getStore().getState(), activity._id, dt.unix()))
    ) {
      dispatch(Actions.clear(undefined));
      await this.setState({
        isLoading: false,
      });
      return;
    }
    const totalComments = contextService.totalComments;
    const seenComments = contextService.seenComments;
    const unseenComments = totalComments - seenComments;
    this.unseenComments = unseenComments;
    await this.setState({
      unseenCommentsFirstFetch: unseenComments,
      newTagCommentNumber: contextService.seenComments,
    });
    await dispatch(
      Actions.fetchComments({
        context: commentsContext,
        contextId: contextService.contextId,
        subContext: this.getState().subContext,
        totalComments: totalComments,
        seenComments: seenComments,
      })
    );
    this.setNewTagNumber(totalComments);
    // const comments = getStore().getState().comments.comments;
    await this.setState({
      isLoading: false,
    });
    if (this.elem) {
      if (this.unseenComments > document.querySelectorAll('.comment').length) {
        this.setState({ isLoadMoreCommentsVisible: false });
        if (this.elem.clientHeight === this.elem.scrollHeight) {
          this.fetchOldComments();
        }
      } else if (totalComments === document.querySelectorAll('.comment').length) {
        this.setState({ isLoadMoreCommentsVisible: false });
      } else {
        this.setState({ isLoadMoreCommentsVisible: true });
      }
      setTimeout(() => {
        if (this.elem && seenComments >= totalComments) {
          this.elem.scrollTop = this.elem.scrollHeight;
        }
      });
    }
  }

  private setNewTagNumber(totalComments: number, addedComments?: number) {
    const newTagCommentNumber = this.getState().newTagCommentNumber;
    const comments = getStore().getState().comments.comments;
    if (addedComments) {
      if (totalComments !== newTagCommentNumber) {
        if (newTagCommentNumber !== 0) {
          this.setState({
            newTagCommentNumber: newTagCommentNumber + addedComments,
          });
        }
      }
    } else {
      if (
        comments &&
        newTagCommentNumber > comments.length &&
        newTagCommentNumber !== totalComments
      ) {
        this.setState({
          newTagCommentNumber: newTagCommentNumber - (totalComments - comments.length),
        });
      }
    }
  }

  private async toggleIsFetchingOldComments() {
    await this.setState({
      isFetchingOld: !this.getState().isFetchingOld,
    });
  }

  private async fetchOldComments(frombtn?: boolean) {
    this.toggleIsFetchingOldComments();
    let scrollHeight = 0;
    const { context } = this.getProps();
    const { subContext } = this.getState();
    const totalComments = this.getCommentsContextService(context, subContext).totalComments;
    const commentsContext = (
      {
        course: 'course',
        class: 'classes',
        assignment: 'assignments',
        quiz: 'quizzes',
        poll: 'polls',
        resource: 'resources',
        discussion: 'discussions',
        query: 'queries',
      } as any
    )[context] as ICommentsContext;
    const contextService = this.getCommentsContextService(context, subContext);
    if (this.elem) {
      scrollHeight = this.elem.scrollHeight;
    }
    const numAdded = await dispatch(
      Actions.fetchOld({
        context: commentsContext,
        contextId: contextService.contextId,
        subContext: this.getState().subContext,
      })
    );
    this.toggleIsFetchingOldComments();
    this.setNewTagNumber(totalComments, numAdded);
    if (totalComments > document.querySelectorAll('.comment').length) {
      if (this.unseenComments <= document.querySelectorAll('.comment').length) {
        this.setState({
          isLoadMoreCommentsVisible: true,
        });
        if (this.elem && frombtn) {
          this.elem.scrollTop = this.elem.scrollHeight - scrollHeight;
        }
        if (this.elem && !frombtn) {
          this.elem.scrollTop = this.elem.scrollHeight;
        }
      } else {
        if (this.elem) {
          this.elem.scrollTop = this.elem.scrollHeight - scrollHeight;
        }
      }
    } else if (totalComments === document.querySelectorAll('.comment').length) {
      this.setState({
        isLoadMoreCommentsVisible: false,
      });
      if (frombtn) {
        this.setState({
          loadMoreCommentsMessage: 'No more comments to show',
        });
      }
    } else {
      if (frombtn) {
        setTimeout(() => {
          (document.getElementById('load-older-comments-btn') as any).focus();
        }, 0);
      }
    }
  }

  private getCommentsContextService(
    context: IAppContext,
    subContext: ICommentsSubContext
  ): ICommentsContextService {
    return getCommentsContextService(context, subContext);
  }
}

export default (props: ICommentsProps) => h(CommentsC, props);
