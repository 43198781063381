import { h, IComponent } from 'core';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const marked = require('marked');

marked.setOptions({
  renderer: new marked.Renderer(),
});

export class Markdown extends IComponent<{ text: string }, never> {
  public render() {
    return h('div.acadly-markdown', {
      dangerouslySetInnerHTML: {
        __html: marked(this.getProps().text),
      },
    });
  }
}

export default (text: string) => h(Markdown, { text });
