import { h } from 'core';

import Icon from 'acadly/common/Icon';

import { Actions as GetInActions } from '../getin/actions';
import icons from '../icons';
import { Routes } from '../routes';
import { dispatch } from '../store';

export default (props: { closeSidebar: () => void; openFeedbackDialog: () => void }) => {
  const SidebarIcon = (props: { icon: string; onclick(e: Event): any; label: string }) => {
    return h(
      'div.sidebar__item.ripple',
      {
        tabIndex: 0,
        ariaLabel: props.label,
        onclick: props.onclick,
      },
      [Icon(props.icon, { className: 'icon' }), h('div', props.label)]
    );
  };

  return h('nav.sidebar', [
    h('div.sidebar__logo', [
      h('img', {
        src: 'https://s3.amazonaws.com/static.acad.ly/img/acadly-web-sidebar-logo.png',
        alt: 'acadly-image',
      }),
    ]),

    SidebarIcon({
      icon: icons.home,
      label: 'Home',
      onclick: () => {
        Routes.home.navigate({});
        props.closeSidebar();
      },
    }),

    SidebarIcon({
      label: 'Settings',
      icon: icons.settings,
      onclick: () => {
        Routes.settings.navigate({});
        props.closeSidebar();
      },
    }),

    SidebarIcon({
      label: 'Feedback',
      icon: icons.feedback,
      onclick: () => {
        props.openFeedbackDialog();
        props.closeSidebar();
      },
    }),

    SidebarIcon({
      label: 'Logout',
      icon: icons.power,
      onclick: dispatch.bind(null, GetInActions.logout()),
    }),
  ]);
};
