import { h, View } from 'core';

import { Actions as AppActions } from 'acadly/app/actions';
import { INewFeatureSummary } from 'acadly/app/api';
import Dialog, { IDialogProps } from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import { Icon } from 'acadly/common/Icon';
import * as dt from 'acadly/datetime';
import icons from 'acadly/icons';
import { dispatch } from 'acadly/store';
import { colors } from 'acadly/styles';

export interface FeatureUpdate extends INewFeatureSummary {
  key: string;
}

const FeatureCell = (feature: FeatureUpdate) => {
  const messages: View[] = [];

  const lines = feature.message.split('\n');
  lines.forEach((line, index) => {
    messages.push(line);
    if (lines.length - 1 !== index) {
      messages.push(h('br'));
    }
  });

  return h(`div.new-feature${feature.seen ? '.seen' : ''}`, [
    h('div.new-feature__release-date', dt.format(feature.releasedOn, 'Do MMM, YYYY')),
    h('div.new-feature__title', feature.title),
    h('p', messages),
    feature.blog === 1 && feature.viewMoreLabel
      ? FlatButton(feature.viewMoreLabel, {
          classNames: ['new-feature__view-more'],
          labelStyle: {
            textTransform: 'none',
          },
          onclick: () => {
            if (!feature.blog) return;
            window.open(feature.redirectUrl, '_blank');

            if (!feature.seen) {
              dispatch(AppActions.newFeatures(feature.key));
            }
          },
        })
      : null,
  ]);
};

interface INewFeaturesDialog {
  open: boolean;
  onClose: () => any;
  title: string;
  features: FeatureUpdate[];
}

export default (props: INewFeaturesDialog) => {
  const { open, title, features, onClose } = props;

  const dialogConfig: IDialogProps = {
    open,
    title,
    bodyStyle: {
      height: '100%',
      position: 'relative',
    },
    style: {
      maxWidth: '36em',
      backgroundColor: colors.backgroundColor,
    },
    secondaryAction: {
      label: 'CLOSE',
      mobileLabel: Icon(icons.cross, { className: 'fs-lg' }),
      onclick: onClose,
    },
  };

  return Dialog(dialogConfig, [h('div.feature-list', features.map(FeatureCell))]);
};
