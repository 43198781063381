import { CSS, h, HTMLAttrs, IComponent, View } from 'core';

import { getStore } from 'acadly/store';

export const Loader = (styles?: CSS) =>
  h('div.spin.loader__spinner', {
    style: styles,
    tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
    role: 'icon',
    'aria-label': 'Content Loading',
  });

export class WithLoader extends IComponent<
  {
    when: boolean | Promise<any>;
    children: View[];
  },
  {
    promiseIsLoading: boolean;
  }
> {
  public componentWillMount() {
    const when = this.getProps().when;
    if (when instanceof Promise) {
      this.setState({
        promiseIsLoading: true,
      })
        .then(() => when)
        .then(() => {
          this.setState({
            promiseIsLoading: false,
          });
        });
    } else {
      this.setState({
        promiseIsLoading: false,
      });
    }
  }

  public render() {
    return h('div.loader__wrapper', [
      ...this.getProps().children,
      loaderWhen(this.getState().promiseIsLoading || this.getProps().when === true),
    ]);
  }
}

export const withLoader = (when: boolean | Promise<any>, children: View | View[]) =>
  h(WithLoader, {
    when,
    children: children ? (children instanceof Array ? children : [children]) : [],
  });

export const loaderWhen = (when: boolean, attrs: HTMLAttrs = {}) =>
  when ? h('div.loader__overlay', attrs, [Loader()]) : null;

export const fullScreenLoader = h('div.loader__full-screen', [Loader()]);
