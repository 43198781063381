import { JSONPromise, jsonRequest } from 'core/http';

import { api } from 'acadly/api';

export interface ICreateRequest {
  classId: string;
  toBeDone: 'preClass' | 'inClass';
}

export type IExportCommentStatsSuccessRequest = {
  context:
    | 'course'
    | 'classes'
    | 'polls'
    | 'quizzes'
    | 'assignments'
    | 'queries'
    | 'discussions'
    | 'resources';
  contextId: string;
  email: 0 | 1;
};

export type IExportCommentStatsSuccessResponse = {
  message: string;
  url: string;
  filename: string;
};

export type ICreateResponse = {
  _id: string;
  nodeType: 'discussion';
  details: {
    toBeDone: 'preClass' | 'inClass';
    createdOn: UnixTimestamp;
    createdBy: ICreatedByWithRole;
    description: string;
    title: string;
    submitFirst: 0 | 1;
    anonymize: 0 | 1;
    anonymity: DiscussionAnonymity;
    hideAwards: 0 | 1;
    trueNum: number;
    attachments: IAttachment[];
    num: number;
  };
  activities: {
    numCommentsTotal: number;
  };
  stats: {
    numSubmitted: number;
  };
  publishDefaults: DiscussionPublishDefaults;
};

export const create = (data: ICreateRequest) =>
  jsonRequest<ICreateResponse>(api().discussionCreate, {
    method: 'POST',
    data,
  });

export interface IEditRequest {
  classId: string;
  discussionId: string;
  title: string;
  description: string;
  attachments: IAttachment[];
}

export const edit = (data: IEditRequest) =>
  jsonRequest(api().discussionEdit, {
    method: 'POST',
    data,
  });

export interface IDeleteRequest {
  classId: string;
  discussionId: string;
  toBeDone: 'preClass' | 'inClass';
}

export const deleteDiscussion = (data: IDeleteRequest) =>
  jsonRequest(api().discussionRemove, {
    method: 'DELETE',
    data,
  });

export interface FetchPublishPrefsRequest {
  discussionId: string;
  classId: string;
}

interface FetchPublishPrefsResponse {
  message: 'success';
  publishPref: DiscussionPublishDefaults;
}

export const fetchPublishPrefs = (data: FetchPublishPrefsRequest) => {
  return jsonRequest<FetchPublishPrefsResponse>(api().discussionPrefs, {
    method: 'GET',
    data,
  });
};

export interface IPublishRequest extends IDiscussionPublishPrefs {
  classId: string;
  discussionId: string;
  toBeDone: 'preClass' | 'inClass';
  saveAsDefault: 0 | 1;
}

export interface IPublishResponse {
  discussionNum: number;
  trueNum: number;
  publishedOn: number;
}

export interface IUpdatePublishedRequest {
  classId: string;
  title: string;
  description: string;
  attachments: IAttachment[];
  discussionId: string;
  toNotify: 0 | 1;
}

export const publish = (data: IPublishRequest) =>
  jsonRequest<IPublishResponse>(api().discussionPublish, {
    method: 'POST',
    data,
  });

export const updatePublished = (data: IUpdatePublishedRequest) =>
  jsonRequest<IPublishResponse>(api().discussionUpdatePublished, {
    method: 'PUT',
    data,
  });

export interface IAnalyticsSetRequest {
  firstAccess: 0 | 1;
  discussionId: string;
  localTime: string; // YYYYMMDDTHH:mm
}

export const analyticsSet = (data: IAnalyticsSetRequest) =>
  jsonRequest(api().analyticsDiscussionSet, {
    method: 'POST',
    data,
  });

export interface IFetchAllResponse {
  activityData: IResponseDiscussionWithClassId[];
  userData: {
    discussions: ObjectMap<IResponseDiscussionUserData | undefined>;
  };
}
export const fetchAll = () =>
  jsonRequest<IFetchAllResponse>(api().archiveGet + '/discussions', {
    method: 'GET',
  });

export const exportCommentStats = (
  data: IExportCommentStatsSuccessRequest
): JSONPromise<IExportCommentStatsSuccessResponse> => {
  return jsonRequest(
    api().exportCommentStats +
      `?` +
      `context=${data.context}` +
      `&contextId=${data.contextId}` +
      `&email=${data.email}`,
    {
      method: 'GET',
    }
  );
};

export const exportAllCommentStats = (
  data: Pick<IExportCommentStatsSuccessRequest, 'email'>
): JSONPromise<IExportCommentStatsSuccessResponse> => {
  return jsonRequest(api().exportAllCommentStats + `?` + `email=${data.email}`, {
    method: 'GET',
  });
};

interface IWordCloudResponse {
  generatedAt: number;
  image: string;
  commentsUsed: number;
  totalComments: number;
  wordList: Word[];
  removedWords: string[];
}

export const fetchWordCloud = (discussionId: string) => {
  return jsonRequest<IWordCloudResponse>(api().wordCloudDetails(discussionId), { method: 'GET' });
};

export const createWordCloud = (discussionId: string) => {
  return jsonRequest<IWordCloudResponse>(api().wordCloudCreate, {
    method: 'PUT',
    skipInterceptors: true,
    data: { discussionId },
  });
};

export const editWordCloud = (discussionId: string, removedWords: string[]) => {
  return jsonRequest<IWordCloudResponse>(api().wordCloudEditWords, {
    method: 'PUT',
    data: { discussionId, removedWords },
  });
};
