import * as cookies from 'js-cookie';

let session: Storage = {} as any;
try {
  session = sessionStorage;
  sessionStorage.setItem('dummy', 'dummy');
  sessionStorage.removeItem('dummy');
} catch (_) {
  let _storage = <any>{};
  session = <Storage>{
    setItem: (key, value) => (_storage[key] = value),
    getItem: (key) => _storage[key] || null,
    removeItem: (key) => delete _storage[key],
    clear: () => {
      _storage = {};
    },
    key: () => null,
    length: 0,
  };
}

export { session as sessionStorage, cookies };

export default {
  sessionStorage: session,
  cookies,
};
