import { pairsToObject } from 'acadly/utils';
/**
 * Get video Id from youtube video URL. Returns null if cant parse
 */
export function parseYoutubeUrl(url: string): null | string {
  const domainMatch = url.match(/^(http(s)?:\/\/)?((w){3}.)?((youtu(be|.be))?(\.com)?)\/(.+)/);
  if (!domainMatch) {
    return null;
  }
  const domain = domainMatch[6];
  const route = domainMatch[9];
  if (domain === 'youtu.be') {
    const videoId = route.split(/[?&]/, 1)[0];
    return videoId || null;
  } else if (domain === 'youtube') {
    const queryMatch = route.match(/^watch\?([^/]+)/);
    if (!queryMatch) {
      return null;
    }
    const queryString = queryMatch[1];
    const pairs: [string, string][] = queryString
      .split('&')
      .map((s) => s.split('=') as [string, string]);
    const queryParams = pairsToObject(pairs);
    if (queryParams.v) {
      return queryParams.v;
    } else {
      return null;
    }
  } else {
    return null;
  }
}
