import { CSS, h, IComponent } from 'core';

import { ml, mr, pl, style } from 'acadly/styles';

interface IRangeSliderProps {
  step: number;
  value: number;
  min: number;
  max: number;
  isAccessible?: boolean;
  containerStyle?: CSS;
  inputStyle?: CSS;
  labelStyle?: CSS;
  oninput?(event: Event & { target: EventTarget & { valueAsNumber: number } }): void;
  onchange?(event: Event & { target: EventTarget & { valueAsNumber: number } }): void;
}

class RangeSliderC extends IComponent<IRangeSliderProps, { isFocused: boolean }> {
  public componentWillMount() {
    this.setFocus(false);
  }

  public render() {
    const {
      min,
      max,
      step,
      value,
      oninput,
      onchange,
      isAccessible,
      labelStyle,
      inputStyle,
      containerStyle,
    } = this.getProps();
    const isFocused = this.getState().isFocused;
    const percentage = (value * 100) / max;

    return h(
      'div.range-slider-container',
      style(['flex', 'alignCenter'], {
        outlineOffset: '-1px',
        outline: isAccessible && isFocused ? '2px solid rgb(77, 144, 254)' : undefined,
        padding: '0.5rem 1rem',
        margin: '0.5rem 0',
        width: '100%',
        ...containerStyle,
      }),
      [
        h(
          'input.range-slider.input-custom.no-focus',
          style(
            ['large', ml('auto')],
            {
              background: `linear-gradient(
                                to right,
                                rgb(8, 129, 228) 0%,
                                rgb(8, 129, 228) ${percentage}%,
                                grey ${percentage}%,
                                grey 100%
                            )`,
              flex: '1',
              ...inputStyle,
            },
            {
              step,
              min,
              max,
              value,
              oninput,
              onchange,
              type: 'range',
              onfocus: () => this.setFocus(true),
              onblur: () => this.setFocus(false),
            }
          )
        ),
        h(
          'div.range-slider-label',
          style([
            mr('auto'),
            pl('0.5rem'),
            {
              flexShrink: '0',
              textAlign: 'right',
              minWidth: '3.5rem',
              ...labelStyle,
            },
          ]),
          `${value}/${max}`
        ),
      ]
    );
  }

  private setFocus(isFocused: boolean) {
    this.setState({ isFocused });
  }
}

export const RangeSlider = (props: IRangeSliderProps) => h(RangeSliderC, props);
export default RangeSlider;
