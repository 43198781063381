import { Subscription } from 'rxjs/Subscription';

import appService from 'acadly/app/service';
import classService from 'acadly/class/service';
import { IPusherCommentAddedPayload } from 'acadly/comments/actions';
import courseService from 'acadly/course/service';
import discussionService from 'acadly/discussion/service';
import { Actions as GetInActions } from 'acadly/getin/actions';
import pollService from 'acadly/poll/service';
import { pusherService } from 'acadly/pusher';
import { IPusherCreatePayload } from 'acadly/query/actions';
import queryService from 'acadly/query/service';
import quizService from 'acadly/quiz/service';
import resourceService from 'acadly/resource/service';
import { Routes } from 'acadly/routes';
import { PageVisibility } from 'acadly/utils/pageVisibility';

import AcadlyEvents from './AcadlyEvents';
import { IPusherAddressingHand, IPusherHandRaised } from './app/actions';
import { dispatch, getStore } from './store';
import getAvatarUrl from './utils/avatar';

const appContextMap: {
  [key in ICommentsContext]?: IAppContext;
} = {
  course: 'course',
  classes: 'class',
  assignments: 'assignment',
  quizzes: 'quiz',
  polls: 'poll',
  discussions: 'discussion',
  queries: 'query',
  resources: 'resource',
};

interface NotificationData {
  id: string;
  body: string;
  icon: string;
  onClick?: () => void;
  playSound?: boolean;
}

class NotificationWorker {
  private static instance?: NotificationWorker;

  public static register(courseId: string, classId: string) {
    if (NotificationWorker.instance) return;
    NotificationWorker.instance = new NotificationWorker(courseId, classId);
  }

  public static unregister() {
    if (!NotificationWorker.instance) return;
    const instance = NotificationWorker.instance;

    instance.unsubscribeEvents();
    instance.notificationMap.clear();

    NotificationWorker.instance = undefined;
  }

  private courseId: string;
  private classId: string;
  private mode: 'in-app' | 'desktop' = 'in-app';

  private notificationSound = new Audio(
    'https://s3.amazonaws.com/static.acad.ly/new/notification.m4a'
  );

  private notificationMap = new Map<string, NotificationData>();

  private constructor(courseId: string, classId: string) {
    this.courseId = courseId;
    this.classId = classId;
    this.subscribeEvents();
  }

  private subscriptions: Subscription[] = [];

  private subscribeEvents = () => {
    const pvSub = PageVisibility.visibility$.subscribe(({ isHidden }) => {
      this.mode = isHidden ? 'desktop' : 'in-app';
    });

    const psSub = pusherService.events.subscribe((e) => {
      if (
        this.courseId !== e.payload.courseId ||
        (this.classId !== e.payload.classId && this.classId !== e.payload.contextId)
      ) {
        // pusher for other course or class
        return;
      }

      switch (e.event) {
        case 'handRaised':
          return this.handleHandRaised(e.payload as IPusherHandRaised);
        case 'addressingHand':
          return this.handleAddressingHand(e.payload as IPusherAddressingHand);
        case 'commentAdded':
          return this.handleNewComment(e.payload as IPusherCommentAddedPayload);
        case 'queryAdded':
          return this.handleNewQuery(e.payload as IPusherCreatePayload);
        default:
          break;
      }
    });

    const aeSub = AcadlyEvents.subscribe((e) => {
      switch (e.type) {
        case '@app/vcall_notification_click':
          if (this.notificationMap.has(e.notificationId)) {
            const { onClick } = this.notificationMap.get(e.notificationId);
            onClick && onClick();
            this.notificationMap.delete(e.notificationId);
          }
          break;
        default:
          break;
      }
    });

    this.subscriptions.push(pvSub, psSub, aeSub);
  };

  private unsubscribeEvents = () => {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  };

  private async showNotification({ id, body, icon, playSound = false, onClick }: NotificationData) {
    await dispatch(GetInActions.fetchAvatars([icon]));

    icon = getAvatarUrl(icon);

    if (this.mode === 'in-app') {
      this.notificationMap.set(id, { id, icon, body, onClick });
      return AcadlyEvents.next({
        type: '@app/vcall_show_notification',
        payload: { id, iconUrl: icon, message: body },
      });
    }

    if (Notification.permission !== 'granted') {
      console.warn('Notification permision not granted!');
      return;
    }

    const notification = new Notification('Acadly', { tag: id, body, icon });

    if (playSound) {
      this.notificationSound.play();
    }

    notification.onclick = function () {
      this.close();
      window.focus();
      onClick();
    };
  }

  private goToClass = () => {
    Routes.classActivities.navigate({
      courseShortId: courseService.getShortIdFromCourseId(this.courseId),
      classShortId: classService.getShortIdFromClassId(this.classId),
      univSlug: appService.getUniversitySlug(),
    });
  };

  private goToQuiz = (quizId: string) => {
    Routes.classQuiz.navigate({
      quizShortId: quizService.getShortIdFromQuizId(quizId),
      courseShortId: courseService.getShortIdFromCourseId(this.courseId),
      classShortId: classService.getShortIdFromClassId(this.classId),
      univSlug: appService.getUniversitySlug(),
    });
  };

  private goToPoll = (pollId: string) => {
    Routes.classPoll.navigate({
      pollShortId: pollService.getShortIdFromPollId(pollId),
      courseShortId: courseService.getShortIdFromCourseId(this.courseId),
      classShortId: classService.getShortIdFromClassId(this.classId),
      univSlug: appService.getUniversitySlug(),
    });
  };

  private goToDiscussion = (discussionId: string) => {
    Routes.classDiscussion.navigate({
      discussionShortId: discussionService.getShortIdFromDiscussionId(discussionId),
      courseShortId: courseService.getShortIdFromCourseId(this.courseId),
      classShortId: classService.getShortIdFromClassId(this.classId),
      univSlug: appService.getUniversitySlug(),
    });
  };

  private goToResource = (resourceId: string) => {
    Routes.classResource.navigate({
      resourceShortId: resourceService.getShortIdFromResourceId(resourceId),
      courseShortId: courseService.getShortIdFromCourseId(this.courseId),
      classShortId: classService.getShortIdFromClassId(this.classId),
      univSlug: appService.getUniversitySlug(),
    });
  };

  private goToQuery = (queryId: string) => {
    Routes.classQuery.navigate({
      queryShortId: queryService.getShortIdFromQueryId(queryId),
      courseShortId: courseService.getShortIdFromCourseId(this.courseId),
      classShortId: classService.getShortIdFromClassId(this.classId),
      univSlug: appService.getUniversitySlug(),
    });
  };

  private getActivityMessage(context: ICommentsContext, studentName: string) {
    return (
      `${studentName} posted a comment on a ${appContextMap[context]} ` +
      `published in the ongoing lecture`
    );
  }

  private handleHandRaised(e: IPusherHandRaised) {
    this.showNotification({
      id: e.sender.userId,
      icon: e.sender.avatar,
      body: e.message,
      playSound: true,
      onClick: this.goToClass,
    });
  }

  private async handleAddressingHand(e: IPusherAddressingHand) {
    const session = getStore().getState().getIn.session!;

    if (e.userId !== session.userId) return;

    this.notificationSound.play();
    this.showNotification({
      id: e.sender.userId,
      icon: e.sender.avatar,
      body: e.message,
      playSound: true,
      onClick: this.goToClass,
    });
  }

  private handleNewComment(e: IPusherCommentAddedPayload) {
    const role = courseService.getRole(e.courseId);

    if (e.shouldTeamIgnore === 1 && role !== 'student') return;

    switch (e.context) {
      case 'assignments':
      case 'course':
        return;
      case 'classes': {
        this.showNotification({
          id: e.commentId,
          icon: e.sender.avatar,
          body: `${e.sender.name} posted a comment in the lecture`,
          onClick: this.goToClass,
        });
        break;
      }
      case 'quizzes': {
        this.showNotification({
          id: e.commentId,
          icon: e.sender.avatar,
          body: this.getActivityMessage(e.context, e.sender.name),
          onClick: () => this.goToQuiz(e.contextId),
        });
        break;
      }
      case 'polls': {
        this.showNotification({
          id: e.commentId,
          icon: e.sender.avatar,
          body: this.getActivityMessage(e.context, e.sender.name),
          onClick: () => this.goToPoll(e.contextId),
        });
        break;
      }
      case 'discussions': {
        this.showNotification({
          id: e.commentId,
          icon: e.sender.avatar,
          body: this.getActivityMessage(e.context, e.sender.name),
          onClick: () => this.goToDiscussion(e.contextId),
        });
        break;
      }
      case 'resources': {
        this.showNotification({
          id: e.commentId,
          icon: e.sender.avatar,
          body: this.getActivityMessage(e.context, e.sender.name),
          onClick: () => this.goToResource(e.contextId),
        });
        break;
      }
      case 'queries': {
        this.showNotification({
          id: e.commentId,
          icon: e.sender.avatar,
          body: this.getActivityMessage(e.context, e.sender.name),
          onClick: () => this.goToQuery(e.contextId),
        });
        break;
      }
      default:
        break;
    }
  }

  private handleNewQuery(e: IPusherCreatePayload) {
    this.showNotification({
      id: e.queryId,
      icon: e.sender.avatar,
      body: e.message!,
      onClick: () => this.goToQuery(e.queryId),
    });
  }
}

export default NotificationWorker;
