import { IRootState } from 'acadly/IRootState';
import * as utils from 'acadly/utils';

export const responseQueryToQuery = (
  userData: IResponseQueryUserData | undefined,
  classId: string,
  q: IResponseQuery
): IQuery => {
  return {
    ...q,
    identifiers: { classId },
    details: {
      ...q.details,
      title: q.details.title || '',
      attachments: q.details.attachments || [],
    },
    userData: userData
      ? {
          numCommentsSeen: userData.numCommentsSeen || 0,
          hasUpvoted: userData.role && userData.role.includes('supporter') ? 1 : 0,
          isAsker: userData.role && userData.role.includes('asker') ? 1 : 0,
          subscribed: userData.subscribed || 0,
        }
      : undefined,
  };
};

export const hideQuery =
  (hiddenBy: string) =>
  (q: IQuery): IQuery =>
    utils.update(q, {
      details: {
        isHidden: 1,
        hiddenBy: {
          name: hiddenBy,
        },
        title: `This query has been hidden by ${hiddenBy}`,
        description: '',
      },
    });

export function updateQueryInRootState(
  state: IRootState,
  queryId: string,
  f: (q: IQuery) => IQuery
): IRootState {
  return {
    ...state,
    queries: updateQuery(state.queries, queryId, f),
  };
}

export function updateQuery(
  state: IRootState['queries'],
  queryId: string,
  f: (q: IQuery) => IQuery
): IRootState['queries'] {
  const query = state.byId[queryId];
  if (!query) return state;
  return {
    ...state,
    byId: {
      ...state.byId,
      [queryId]: f(query),
    },
  };
}
