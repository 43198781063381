import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import * as dt from 'acadly/datetime';
import { IPusherPayload } from 'acadly/pusher';

import { createAction, Thunk } from '../createAction';
import * as api from './api';

export type IAnnouncementActionMap = {
  '@announcement/PUBLISH_SUCCESS': IPublishAnnouncementSuccessPayload;
  '@announcement/analytics/SET_SUCCESS': IAnalyticsSetSuccessPayload;
  '@announcement/pusher/ADD': IPusherAddPayload;
};

export interface IAnalyticsSetSuccessPayload {
  request: api.IAnalyticsSetRequest;
  timestamp: UnixTimestamp;
}

export interface IPublishAnnouncementSuccessPayload {
  response: api.IPublishAnnouncementResponse;
  request: api.IPublishAnnouncementRequest;
}

export type IPusherAddPayload = IPusherPayload<{
  announcementId: string;
  stats: {
    numSeenBy: number;
  };
  details: IAnnouncementDetails;
}>;

export const Actions = {
  publishAnnouncementSuccess: createAction('@announcement/PUBLISH_SUCCESS'),

  analyticsSetSuccess: createAction('@announcement/analytics/SET_SUCCESS'),
  analyticsSet:
    (data: api.IAnalyticsSetRequest): Thunk<void> =>
    async (dispatch) => {
      await api.analyticsSet(data);
      dispatch(
        Actions.analyticsSetSuccess({
          request: data,
          timestamp: dt.unix(),
        })
      );
    },
  publishAnnouncement:
    (request: api.IPublishAnnouncementRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.publishAnnouncement(request);
      googleAnalytics.activityCreated('announcement', 'course');
      googleAnalytics.activityPublished('announcement', 'course');
      await dispatch(
        Actions.publishAnnouncementSuccess({
          request,
          response: response.data,
        })
      );
    },

  pusherAdd: createAction('@announcement/pusher/ADD'),
};
