import { updateClassesInRootState } from 'acadly/class/reducer';
import { ICourseState } from 'acadly/course/ICourseState';
import * as dt from 'acadly/datetime';
import { IRootState } from 'acadly/IRootState';
import { mapValues, reduceObject } from 'acadly/utils';
import * as utils from 'acadly/utils';

export const getClass = (state: ICourseState, classId: string): IClass | null => {
  const timeline = state.timeline;
  if (!timeline) return null;
  const cls = timeline.items.find((item) => item._id === classId);
  if (!cls || cls.nodeType !== 'class') return null;
  return cls;
};

export const isInCharge = (state: IRootState, classId: string): boolean => {
  if (!state.courses || !state.getIn.session) return false;

  const session = state.getIn.session;
  const cls = getClass(state.courses, classId);

  return !!cls && cls.info.team.inCharge.userId === session.userId;
};

export const getTotalComments = (cls: IClass): number => {
  return cls.activities.numCommentsTotal;
};

export const getSeenComments = (cls: IClass) => {
  const seen = cls.userData.numCommentsSeen;
  const total = getTotalComments(cls);
  return seen > total ? total : seen;
};

export const getUnseenComments = (cls: IClass) => getTotalComments(cls) - getSeenComments(cls);
const toBeDone: ('preClass' | 'inClass')[] = ['preClass', 'inClass'];
const activityTypes: ('quizzes' | 'polls' | 'discussions' | 'queries' | 'resources')[] = [
  'quizzes',
  'polls',
  'discussions',
  'queries',
  'resources',
];
export const getActivityComments = (cls: IClass) => {
  let totalComments = 0;
  let seenComments = 0;
  for (const typ of toBeDone) {
    const activities = cls.activities[typ];
    const userData = cls.userData[typ];
    for (const actType of activityTypes) {
      const activity = activities[actType];
      const total = activity.numCommentsTotal;
      const seen = userData[actType].numCommentsSeen;
      totalComments += total;
      seenComments += seen > total ? total : seen;
    }
  }
  const reviewTotal = cls.activities.reviewQueries.numCommentsTotal;
  const reviewSeen = cls.userData.reviewQueries.numCommentsSeen;
  totalComments += reviewTotal;
  seenComments += reviewSeen > reviewTotal ? reviewTotal : reviewSeen;
  return { total: totalComments, seen: seenComments };
};

interface IPartialClass {
  activities: IClass['activities'];
}

function getTotal(o: IClassActivities['preClass']['quizzes' | 'queries']) {
  let total = o.numTotal;
  if ('numPending' in o) {
    total += o.numPending || 0;
  }
  return total;
}

export const getActivityCounts = (cls: IClass | IPartialClass) => {
  const inClass = mapValues(cls.activities.inClass, getTotal);
  const preClass = mapValues(cls.activities.preClass, getTotal);
  const reviewQueries =
    cls.activities.reviewQueries.numTotal + (cls.activities.reviewQueries.numPending || 0);
  return { inClass, preClass, reviewQueries };
};
export const getTotalActivityCount = (cls: IClass | IPartialClass) => {
  const counts = getActivityCounts(cls);
  const sum = (a: number, b: number) => a + b;
  const preClass = reduceObject(counts.preClass, sum, 0);
  const inClass = reduceObject(counts.inClass, sum, 0);
  return preClass + inClass + counts.reviewQueries;
};

export const pluralizeActivityKey = (key: IClassActivity['nodeType']) => {
  const keyMapping: ObjectMap<keyof IClass['activities']['preClass' | 'inClass']> = {
    quiz: 'quizzes',
    resource: 'resources',
    poll: 'polls',
    discussion: 'discussions',
  };
  return keyMapping[key];
};

export function classStatusReadable(status: IClass['details']['status']): string {
  return {
    inSession: 'In session',
    holiday: 'Holiday',
    noRecord: 'No record',
    canceled: 'Canceled',
    open: 'Open',
    closed: 'Closed',
  }[status];
}

const functions = {
  getSeenComments,
  getTotalComments,
  getUnseenComments,
  getActivityComments,
};

export function updateTotalClassActivitiesCount(
  state: ICourseState,
  payload: {
    activityType: 'quizzes' | 'polls' | 'discussions' | 'resources';
    classId: string;
    toBeDone: 'inClass' | 'preClass';
    key: 'numTotal' | 'numPublished';
    num: number;
  }
): ICourseState {
  const timeline = state.timeline;
  if (!timeline) return state;
  const courseId = state.currentCourseId;
  if (!courseId) return state;
  const activityType = payload.activityType;
  const num = payload.num;
  const course = state.courses[courseId];
  return utils.update(state, {
    timeline: updateClass(state, payload.classId, (cls) =>
      utils.update(cls, {
        activities: {
          [payload.toBeDone]: {
            [activityType]: {
              [payload.key]: cls.activities[payload.toBeDone][activityType][payload.key] + num,
            },
          },
        },
      })
    ).timeline,
    courses: {
      [courseId]: {
        activities: {
          [activityType]: {
            [payload.key]: course.activities[activityType][payload.key] + num,
          },
        },
      },
    },
  });
}

export function addNewClassActivity<Type extends ClassActivityKeys>(
  root: IRootState,
  payload: ICreateActivityPayload<Type>
): IRootState {
  const courseSlice = root.courses;
  if (!courseSlice.timeline) return root;
  const cls = courseSlice.timeline.items.find((cls) => cls._id === payload.classId) as IClass;
  if (!cls) return root;
  const courseId = courseSlice.currentCourseId;
  if (!courseId) return root;
  const course = courseSlice.courses[courseId];
  if (!course) return root;
  const userData = cls.userData[payload.activity.details.toBeDone][payload.type];
  const updatedCourseSlice = utils.update(courseSlice, {
    timeline: {
      items: utils.replaceWhere(
        courseSlice.timeline.items,
        (cls: IClass) =>
          utils.update(cls, {
            activities: {
              [payload.activity.details.toBeDone]: {
                [payload.type.toString()]: {
                  numTotal:
                    cls.activities[payload.activity.details.toBeDone][payload.type].numTotal + 1,
                },
              },
            },
            userData: {
              [payload.activity.details.toBeDone]: {
                [payload.type.toString()]: {
                  numSeen: userData.numSeen + 1,
                },
              },
            },
          }),
        (i) => i._id === payload.classId
      ),
    },
    courses: {
      [courseId]: {
        activities: {
          [payload.type.toString()]: {
            numTotal: course.activities[payload.type].numTotal + 1,
          },
        },
      },
    },
  });
  const updatedClassSlice = root.class.data
    ? {
        ...root.class,
        data: {
          ...root.class.data,
          activities: {
            ...root.class.data.activities,
            [payload.type.toString()]: [
              ...root.class.data.activities[payload.type],
              payload.activity._id,
            ],
          },
        },
      }
    : root.class;

  const result: IRootState = {
    ...root,
    class: updatedClassSlice,
    courses: updatedCourseSlice,
  };

  const activitySlice: any = result[payload.type];
  result[payload.type] = {
    ...activitySlice,
    byId: {
      ...activitySlice.byId,
      [payload.activity._id]: payload.activity,
    },
  };
  return result;
}

/**
 * Helper types for addNewClassActivityToCourseSlice function
 */
export interface ICreateActivityPayloadChild<Type> {
  classId: string;
  activity: Type;
}
export type ClassActivityKeys = 'quizzes' | 'polls' | 'discussions' | 'resources';
export type ICreateActivityPayload<Type extends ClassActivityKeys> = {
  quizzes: ICreateActivityPayloadChild<IQuiz>;
  polls: ICreateActivityPayloadChild<IPoll>;
  discussions: ICreateActivityPayloadChild<IDiscussion>;
  resources: ICreateActivityPayloadChild<IResource>;
}[Type] & { type: Type };

export function setActivityCommentsSeen(
  state: IRootState,
  key: ClassActivityKeys,
  activityId: string,
  classId: string,
  toBeDone: 'preClass' | 'inClass',
  setTo: number
): IRootState {
  const byId = state[key].byId;
  const activity = byId[activityId];
  const timeline = state.courses.timeline;
  let existingSeenComments = 0;
  if (activity) {
    activity.userData
      ? (existingSeenComments = activity.userData.numCommentsSeen)
      : (existingSeenComments = 0);
  }
  const numSeenIncrementInClass = setTo - existingSeenComments;
  if (!timeline) return state;
  let result = {
    ...state,
    [key]: activity
      ? {
          ...state[key],
          byId: {
            ...state[key].byId,
            [activityId]: {
              ...activity,

              // note that this will not fill all activity specific fields
              // in case userData is not present (only possible
              // in case of team members). So this means userData
              // will be left in a non type safe state (without non optional
              // fields). These fields aren't being used for team members though
              // so this shouldn't create any problems.
              userData: {
                ...activity.userData,
                numCommentsSeen: setTo,
              },
            },
          },
        }
      : state[key],
  };
  result = updateClassesInRootState({
    in: result,
    where: (c) => c._id === classId,
    update: (c) =>
      Class.updateActivityCounts({
        cls: c,
        where: {
          toBeDone: toBeDone,
          nodeType: SINGULARIZE_NODE_TYPE[key],
        },
        update: (counts) => ({
          ...counts,
          numCommentsSeen: counts.numCommentsSeen + numSeenIncrementInClass,
        }),
      }),
  });
  return result;
}

export function deleteClassActivity(
  state: IRootState,
  key: ClassActivityKeys,
  payload: {
    classId: string;
    toBeDone: 'preClass' | 'inClass';
    activityId: string;
  }
): IRootState {
  let updatedClassSlice = state.class;
  if (state.class.data) {
    const oldActivities = state.class.data.activities[key];
    updatedClassSlice = utils.update(state.class, {
      data: {
        activities: {
          [key]: oldActivities.filter((a) => a !== payload.activityId),
        },
      },
    });
  }

  const updatedCourseSlice = updateTotalClassActivitiesCount(state.courses, {
    key: 'numTotal',
    activityType: key,
    toBeDone: payload.toBeDone,
    classId: payload.classId,
    num: -1,
  });
  const byId: ObjectMap<IClassActivity | undefined> = state[key].byId;
  return {
    ...state,
    class: updatedClassSlice,
    courses: updatedCourseSlice,
    [key]: {
      ...state[key],
      byId: utils.removeKey(byId, payload.activityId),
    },
  };
}

export function incrementNumSeen(
  state: IRootState,
  key: ClassActivityKeys,
  payload: {
    classId: string;
    toBeDone: 'preClass' | 'inClass';
  }
): IRootState {
  if (!state.courses.currentCourseId) return state;
  if (!state.courses.timeline) return state;
  const courseUserData = state.courses.userData[state.courses.currentCourseId];
  const updatedCourseSlice = {
    ...state.courses,
    timeline: updateClass(state.courses, payload.classId, (cls) =>
      utils.update(cls, {
        userData: {
          [payload.toBeDone]: {
            [key]: {
              numSeen: cls.userData[payload.toBeDone][key].numSeen + 1,
            },
          },
        },
      })
    ).timeline,
    userData: {
      ...state.courses.userData,
      [state.courses.currentCourseId]: courseUserData
        ? {
            ...courseUserData,
            activitiesSeen: courseUserData.activitiesSeen + 1,
          }
        : courseUserData,
    },
  };
  return {
    ...state,
    courses: updatedCourseSlice,
  };
}

export function updateClassInRootState(
  state: IRootState,
  classId: string,
  fn: (c: IClass) => IClass
): IRootState {
  return {
    ...state,
    courses: updateClass(state.courses, classId, fn),
  };
}

/**
 * Apply the given update function to a class with given classId.
 * Returns updated state (doesn't mutate original state)
 */
export function updateClass(
  state: ICourseState,
  classId: string,
  fn: (c: IClass) => IClass
): ICourseState {
  if (!state.timeline) return state;
  return utils.update(state, {
    timeline: {
      items: utils.replaceWhere(
        state.timeline.items,
        fn,
        (c) => c._id === classId && c.nodeType === 'class'
      ),
    },
  });
}

type ClassActivityKey = 'quizzes' | 'polls' | 'resources' | 'discussions';
type ClassActivityForKey<K extends ClassActivityKey> = {
  quizzes: IQuiz;
  polls: IPoll;
  resources: IResource;
  discussions: IDiscussion;
}[K];

export function pusherPublish<Key extends ClassActivityKey>(
  state: IRootState,
  key: Key,
  payload: {
    activity: ClassActivityForKey<Key>;
    senderId: string;
  }
): IRootState {
  if (payload.senderId === state.getIn.session!.userId) return state;
  const existingActivity = state[key].byId[payload.activity._id] as ClassActivityForKey<Key>;
  const activity = payload.activity;
  if (existingActivity) {
    if (activity.details.published) {
      return state;
    } else {
      const newState = {
        ...state,
        [key.toString()]: {
          ...(state[key] as any),
          byId: {
            ...state[key].byId,
            [activity._id]: {
              ...state[key].byId[activity._id],
              details: activity.details,
            },
          },
        },
      };
      const updatedCourseSlice = updateTotalClassActivitiesCount(newState.courses, {
        activityType: key,
        classId: activity.identifiers.classId,
        toBeDone: activity.details.toBeDone,
        key: 'numPublished',
        num: 1,
      });
      return {
        ...newState,
        courses: updatedCourseSlice,
      };
    }
  } else {
    const isActivityClassLoaded =
      state.class.data && state.class.data.classId === activity.identifiers.classId;
    const updatedActivitySlice =
      state.class.data && isActivityClassLoaded
        ? {
            ...(state[key] as any),
            byId: {
              ...state[key].byId,
              [activity._id]: activity,
            },
          }
        : state[key];
    const updatedClassSlice = {
      ...state.class,
      data:
        state.class.data && isActivityClassLoaded
          ? {
              ...state.class.data,
              activities: {
                ...state.class.data.activities,
                [key.toString()]: [...state.class.data.activities[key], activity._id],
              },
            }
          : state.class.data,
    };
    let updatedCourseSlice = updateTotalClassActivitiesCount(state.courses, {
      activityType: key,
      classId: activity.identifiers.classId,
      toBeDone: activity.details.toBeDone,
      key: 'numTotal',
      num: 1,
    });
    updatedCourseSlice = updateTotalClassActivitiesCount(updatedCourseSlice, {
      activityType: key,
      classId: activity.identifiers.classId,
      toBeDone: activity.details.toBeDone,
      key: 'numPublished',
      num: 1,
    });
    return {
      ...state,
      [key.toString()]: updatedActivitySlice,
      class: updatedClassSlice,
      courses: updatedCourseSlice,
    };
  }
}

export interface IIsStudentCheckInDialogProps {
  courseRole: ICourseRole;
  scheStartTime: Date;
  scheEndTime: Date;
  classStatus: IClass['details']['status'];
  isCheckedIn: boolean;
  currentTime: Date;
}
export function isStudentCheckInDialogVisible(props: IIsStudentCheckInDialogProps) {
  return (
    props.courseRole === 'student' &&
    dt.diff(props.scheStartTime, props.currentTime, 'minutes') <= 15 &&
    props.scheEndTime > props.currentTime &&
    ['open', 'inSession'].includes(props.classStatus) &&
    !props.isCheckedIn
  );
}

export interface IActivityCounts {
  toBeDone: ToBeDone;
  nodeType: IClassActivity['nodeType'];
  numTotal: number;
  numSeen: number;
  numPending: number;
  numComments: number;
  numCommentsSeen: number;
  numCompleted: number;
  numClosed: number;
  numPublished: number;
}

export const EMPTY_ACTIVITY_COUNTS: IActivityCounts = {
  toBeDone: 'preClass',
  nodeType: 'quiz',
  numTotal: 0,
  numSeen: 0,
  numPending: 0,
  numComments: 0,
  numCommentsSeen: 0,
  numCompleted: 0,
  numClosed: 0,
  numPublished: 0,
};

export function addActivityCounts(a: IActivityCounts, b: IActivityCounts): IActivityCounts {
  return {
    toBeDone: b.toBeDone,
    nodeType: b.nodeType,
    numTotal: a.numTotal + b.numTotal,
    numSeen: a.numSeen + b.numSeen,
    numPending: a.numPending + b.numPending,
    numComments: a.numComments + b.numComments,
    numCommentsSeen: a.numCommentsSeen + b.numCommentsSeen,
    numCompleted: a.numCompleted + b.numCompleted,
    numClosed: a.numClosed + b.numClosed,
    numPublished: a.numPublished + b.numPublished,
  };
}

export interface IClassCounts {
  numComments: number;
  numCommentsSeen: number;
  activities: IActivityCounts[];
}

export const NODE_TYPES: IClassActivity['nodeType'][] = [
  'quiz',
  'poll',
  'resource',
  'query',
  'discussion',
];
export const TO_BE_DONE: ToBeDone[] = ['preClass', 'inClass', 'review'];

export const PLURALIZE_NODE_TYPE: TotalMapping<
  IClassActivity['nodeType'],
  keyof IClassActivities['preClass']
> = {
  quiz: 'quizzes',
  poll: 'polls',
  discussion: 'discussions',
  resource: 'resources',
  query: 'queries',
};
export const SINGULARIZE_NODE_TYPE: TotalMapping<
  keyof IClassActivities['preClass'],
  IClassActivity['nodeType']
> = {
  quizzes: 'quiz',
  polls: 'poll',
  discussions: 'discussion',
  resources: 'resource',
  queries: 'query',
};

export function makeEmptyActivityCounts(nodeType: IClassActivity['nodeType'], toBeDone: ToBeDone) {
  return {
    ...EMPTY_ACTIVITY_COUNTS,
    nodeType,
    toBeDone,
  };
}

export const Class = {
  isCheckedIn(cls: IClass): boolean {
    return (
      cls.userData.attendance !== undefined &&
      cls.userData.attendance.checkInTime !== undefined &&
      cls.userData.attendance.checkInTime > 0
    );
  },
  scheStartTime(cls: IClass) {
    return dt.fromUnix(cls.details.scheStartTime);
  },
  scheEndTime(cls: IClass) {
    return dt.fromUnix(cls.details.scheEndTime);
  },
  status(
    cls: {
      details: {
        scheEndTime: UnixTimestamp;
        scheStartTime: UnixTimestamp;
        status: IClassStatus;
      };
    },
    currentTime: UnixTimestamp = dt.unix()
  ) {
    const { scheEndTime, scheStartTime, status } = cls.details;
    const endTime = dt.toUnix(dt.add(dt.fromUnix(scheEndTime), 30, 'minutes'));

    if (status === 'open' && scheStartTime < currentTime && currentTime < endTime) {
      return 'inSession';
    } else if (status === 'open' && endTime <= currentTime) {
      return 'closed';
    }

    return cls.details.status;
  },
  seenComments: getSeenComments,
  unseenComments: getUnseenComments,
  activityComments: getActivityComments,
  counts(cls: IClass): IClassCounts {
    return {
      numCommentsSeen: Math.min(cls.userData.numCommentsSeen, cls.activities.numCommentsTotal),
      numComments: cls.userData.numCommentsSeen,
      activities: Class.activityCounts(cls),
    };
  },
  activityCounts<
    Cls extends {
      activities: IClass['activities'];
      userData?: IClass['userData'] | undefined;
    }
  >(cls: Cls): IActivityCounts[] {
    const counts: IActivityCounts[] = [];
    //#region review-queries
    counts.push({
      nodeType: 'query',
      toBeDone: 'review',
      numTotal: cls.activities.reviewQueries.numTotal,
      numSeen: cls.userData ? cls.userData.reviewQueries.numSeen : 0,
      numComments: cls.activities.reviewQueries.numCommentsTotal,
      numCommentsSeen: cls.userData ? cls.userData.reviewQueries.numCommentsSeen : 0,
      numPending: cls.activities.reviewQueries.numPending || 0,
      numCompleted: 0,
      numClosed: cls.activities.reviewQueries.numClosed,
      numPublished: cls.activities.reviewQueries.numTotal,
    });
    //#endregion
    for (const toBeDone of TO_BE_DONE) {
      if (toBeDone === 'review') {
        continue;
      }
      for (const nodeType of NODE_TYPES) {
        const key = PLURALIZE_NODE_TYPE[nodeType];
        const act = cls.activities[toBeDone][key];
        const ud = cls.userData
          ? {
              numCompleted: 0,
              ...cls.userData[toBeDone][key],
            }
          : {
              numSeen: 0,
              numCommentsSeen: 0,
              numCompleted: 0,
            };
        if (!act || !ud) {
          continue;
        }
        counts.push({
          nodeType: nodeType,
          toBeDone: toBeDone,
          numTotal: act.numTotal,
          numSeen: ud.numSeen,
          numComments: act.numCommentsTotal,
          numCommentsSeen: ud.numCommentsSeen,
          numPending: 'numPending' in act ? act.numPending || 0 : 0,
          numCompleted: 'numCompleted' in ud ? ud.numCompleted : 0,
          numClosed: 'numClosed' in act ? act.numClosed : 0,
          numPublished: 'numPublished' in act ? act.numPublished : act.numTotal,
        });
      }
    }
    return counts;
  },
  activityCountsFor<
    Cls extends {
      activities: IClass['activities'];
      userData?: IClass['userData'];
    }
  >(
    cls: Cls,
    filter: {
      toBeDone?: ToBeDone;
      nodeType?: IClassActivity['nodeType'];
    } = {}
  ) {
    const allCounts = Class.activityCounts(cls);

    const result = allCounts
      .filter(filter.toBeDone ? (obj) => obj.toBeDone === filter.toBeDone : () => true)
      .filter(filter.nodeType ? (obj) => obj.nodeType === filter.nodeType : () => true)
      .reduce(addActivityCounts, EMPTY_ACTIVITY_COUNTS);
    return result;
  },
  updateActivityCounts(props: {
    cls: IClass;
    where: {
      toBeDone: ToBeDone;
      nodeType: IClassActivity['nodeType'];
    };
    update: (current: IActivityCounts) => IActivityCounts;
  }): IClass {
    const { cls } = props;
    const existingCounts = Class.activityCountsFor(cls, props.where);
    const updatedCounts = props.update(existingCounts);
    if (props.where.toBeDone === 'review') {
      return {
        ...cls,
        activities: {
          ...cls.activities,
          reviewQueries: {
            ...cls.activities.reviewQueries,
            numTotal: updatedCounts.numTotal,
            numClosed: updatedCounts.numClosed,
            numCommentsTotal: updatedCounts.numCommentsSeen,
            numPending: updatedCounts.numPending,
          },
        },
        userData: {
          ...cls.userData,
          reviewQueries: {
            ...cls.userData.reviewQueries,
            numCommentsSeen: updatedCounts.numCommentsSeen,
            numCompleted: updatedCounts.numCompleted,
            numSeen: updatedCounts.numSeen,
          },
        },
      };
    } else {
      const toBeDone = props.where.toBeDone;
      const key = PLURALIZE_NODE_TYPE[props.where.nodeType];
      const act = cls.activities[toBeDone][key];
      const ud = cls.userData[props.where.toBeDone][key];
      const updatedAct: typeof act = {
        ...act,
        numClosed: updatedCounts.numClosed,
        numCommentsTotal: updatedCounts.numComments,
        numPending: updatedCounts.numPending,
        numPublished: updatedCounts.numPublished,
        numTotal: updatedCounts.numTotal,
      };
      const updatedUD: typeof ud = {
        ...ud,
        numCommentsSeen: updatedCounts.numCommentsSeen,
        numCompleted: updatedCounts.numCompleted,
        numSeen: updatedCounts.numSeen,
      };
      if ('numPublished' in updatedAct) {
        updatedAct.numPublished = updatedCounts.numPublished;
      }
      return {
        ...cls,
        activities: {
          ...cls.activities,
          [toBeDone]: {
            ...cls.activities[toBeDone],
            [key]: updatedAct,
          },
        },
        userData: {
          ...cls.userData,
          [toBeDone]: {
            ...cls.userData[toBeDone],
            [key]: updatedUD,
          },
        },
      };
    }
  },
};

export function getFormattedClassTimings(cls: {
  details: {
    status: IClassStatus;
    scheStartTime: UnixTimestamp;
    scheEndTime: UnixTimestamp;
  };
}) {
  const classTimings =
    dt.format(cls.details.scheStartTime, 'MMM Do, HH:mm A') +
    ' - ' +
    dt.format(cls.details.scheEndTime, 'HH:mm A');
  return classTimings;
}

export default functions;
