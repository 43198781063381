import { h, IComponent } from 'core';
import { Route, Switch } from 'core/router';

import * as CloseIcon from 'assets/close.svg';

import { Actions as appActions } from 'acadly/app/actions';
import JoinCoursePayment from 'acadly/app/JoinCoursePayment';
import appService from 'acadly/app/service';
import ActivitiesStartTips from 'acadly/class/ActivitiesStartTips';
import ClassAgendaStartTips from 'acadly/class/ClassAgendaStartTips';
import ClassAnalyticsStartTips from 'acadly/class/ClassAnalyticsStartTips';
import ClassAttendanceStartTips from 'acadly/class/ClassAttendanceStartTips';
import Alert from 'acadly/common/Alert';
import CtaButton from 'acadly/common/CtaButton';
import DatePicker from 'acadly/common/DatePicker';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import { Loader, loaderWhen } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import Tabs from 'acadly/common/SlidingTabs';
import SvgIcon from 'acadly/common/SvgIcon';
import CourseAnalyticsStartTips from 'acadly/course/CourseAnalyticsStartTips';
import CourseInfoStartTips from 'acadly/course/CourseInfoStartTips';
import CourseSyllabusStartTips from 'acadly/course/CourseSyllabusStartTips';
import { CourseVM } from 'acadly/course/CourseVM';
import * as datetime from 'acadly/datetime';
import icons from 'acadly/icons';
import { visibleTo } from 'acadly/permissions';
import { pusherService } from 'acadly/pusher';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { colors, mainPanelWidth, mr, pad, style } from 'acadly/styles';
import * as u from 'acadly/utils';

import { Actions } from './actions';
import { fetchTimezones, ITimezone } from './api';
import AttendanceAlert from './AttendaceAlert';
import Info from './Info';
import courseService from './service';
import Syllabus from './Syllabus';
import Timeline from './Timeline';
import TimelineStartTips from './TimelineStartTips';

export default (courseShortId: string) => h(Course, { courseShortId });

const SHOW_PAYMENT_MESSAGE_TIMESTAMP = 'next_show_payment_message_timestamp';

interface ICourseProps {
  courseShortId: string;
  // _id: string;
}

export class Course extends IComponent<
  ICourseProps,
  {
    isLoading: boolean;
    showPaymentMessage: boolean;
    isJoinCoursePaymentOpen: boolean;
    vm?: CourseVM;
  }
> {
  private isAccessible: boolean;

  private isPaymentRequired() {
    const { courseShortId } = this.getProps();
    const courseId = courseService.getCourseIdFromShortId(courseShortId);
    const courseData = getStore().getState().courses.userData?.[courseId];
    if (!courseData) return false;
    if (courseData.role !== 'student') return false;
    const { hasPaid, payTill } = courseData;
    const now = datetime.unix();
    if (hasPaid) return false;
    return payTill < now;
  }

  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    this.initialize(this.getProps());
  }

  public componentDidMount() {
    setTimeout(() => {
      const tabs = document.getElementById('sliding-tabs');
      if (tabs) {
        tabs.focus();
      }
    }, 0);
  }

  public componentWillReceiveProps(nextProps: ICourseProps) {
    const state = this.getState();
    if (nextProps.courseShortId !== this.getProps().courseShortId) {
      pusherService.disconnectCourseChannel();
      pusherService.disconnectTeamChannel();
      this.initialize(nextProps);
    } else if (state.vm) {
      const vmProps = this.getVMProps();
      if (vmProps !== null) {
        state.vm.propsUpdated(vmProps);
      }
    }
  }

  private async initialize(props: ICourseProps) {
    await this.setState({
      isLoading: true,
      showPaymentMessage: false,
      isJoinCoursePaymentOpen: false,
    });

    const courseId = courseService.getCourseIdFromShortId(props.courseShortId);

    if (this.isPaymentRequired()) {
      dispatch(Actions.setCurrentCourse(courseId));
      this.setState({
        isLoading: false,
      });
      return;
    }

    if (!courseId) {
      dispatch(
        appActions.showError({
          message: 'Course was not found.',
        })
      );
      Routes.home.navigate({});
    } else {
      await dispatch(Actions.setCurrentCourse(courseId));

      await Promise.all([
        dispatch(Actions.fetchTimeline(getStore().getState().getIn.session!.userId)),
        dispatch(Actions.fetchCourseSyllabus()),
      ]);

      pusherService.connectCourseChannel();

      const isStudent = courseService.getRole() === 'student';

      if (!isStudent) {
        pusherService.connectTeamChannel();
      } else {
        const { hasPaid } = courseService.getCurrentCourseUserData();
        const now = datetime.unix();
        const lastShownTimestamp = parseFloat(
          window.localStorage.getItem(SHOW_PAYMENT_MESSAGE_TIMESTAMP) || '-1'
        );
        this.setState({
          showPaymentMessage: hasPaid ? false : lastShownTimestamp < now,
        });
      }

      await this.setState({
        isLoading: false,
      });

      const vmProps = this.getVMProps();
      if (vmProps !== null) {
        await this.setState({
          vm: new CourseVM(vmProps, async () => {
            await this.setState({});
          }),
        });
      }
      setTimeout(() => {
        const tabs = document.getElementById('sliding-tabs');
        if (tabs) {
          tabs.focus();
        }
      }, 1000);
    }
  }

  public getVMProps() {
    const role = courseService.getRole();
    const course = courseService.getCurrentCourse();
    if (role && course && course.dates) {
      return {
        courseRole: role,
        course: {
          isLive: course.status.courseLive === 1,
          isArchived: course.isArchived === 1,
          _id: course._id,
          endDate: course.dates.endDate,
          startDate: course.dates.startDate,
        },
      };
    } else {
      return null;
    }
  }

  public componentWillUnmount() {
    pusherService.disconnectCourseChannel();
    if (courseService.getRole() !== 'student') {
      pusherService.disconnectTeamChannel();
    }
    dispatch(Actions.closeCoursePage(undefined));
  }

  private paymentRequiredScreen() {
    const { isJoinCoursePaymentOpen } = this.getState();
    return h(
      'div',
      style(['flex', 'column', 'justifyCenter', 'alignCenter', 'grey', pad('2rem')]),
      [
        h('p', 'You need to pay to access this course.'),
        CtaButton({
          variant: 'green',
          label: 'Pay now',
          onClick: () => {
            this.setState({
              isJoinCoursePaymentOpen: true,
            });
          },
        }),
        JoinCoursePayment({
          open: isJoinCoursePaymentOpen,
          onClose: async (hasPaid) => {
            this.setState({
              isJoinCoursePaymentOpen: false,
              showPaymentMessage: !hasPaid,
            });
            if (hasPaid) {
              dispatch(Actions.fetchTimeline(getStore().getState().getIn.session!.userId));
            }
          },
        }),
      ]
    );
  }

  public render() {
    const { isLoading } = this.getState();
    const { courses, app } = getStore().getState();
    let activeTab = 0;

    if (Routes.courseTimeline.isActive()) {
      activeTab = 0;
    } else if (Routes.courseInfo.isActive()) {
      activeTab = 1;
    } else if (Routes.courseSyllabus.isActive()) {
      activeTab = 2;
    }

    const courseId = courseService.getCourseIdFromShortId(this.getProps().courseShortId);
    const currentCourse = courses.courses[courseId];

    if (this.isPaymentRequired()) {
      return this.paymentRequiredScreen();
    }

    return h('div.course', [
      loaderWhen(isLoading, { className: 'course__loader' }),
      !isLoading && currentCourse?.isDemo && courseService.getRole() === 'admin'
        ? Switch([
            Route(Routes.class, () => {
              const { isAnalyticsOpen } = getStore().getState().app;
              const { data } = getStore().getState().class;

              if (!data) return null;

              if (isAnalyticsOpen) return ClassAnalyticsStartTips();

              return Switch([
                Route(Routes.classActivities, ActivitiesStartTips),
                Route(Routes.classAgenda, ClassAgendaStartTips),
                Route(Routes.classAttendance, ClassAttendanceStartTips),
              ]);
            }),
            Route(Routes.course, () => {
              const { isAnalyticsOpen } = getStore().getState().app;

              if (isAnalyticsOpen) return CourseAnalyticsStartTips();

              return Switch([
                Route(Routes.courseTimeline, TimelineStartTips, { exact: true }),
                Route(Routes.courseInfo, CourseInfoStartTips),
                Route(Routes.courseSyllabus, CourseSyllabusStartTips),
              ]);
            }),
          ])
        : null,
      currentCourse && courses.timeline
        ? currentCourse.status.timezoneSet
          ? h('div.course__wrapper', [
              Tabs({
                style: {
                  height: '100%',
                },
                hideTabs:
                  app.context !== 'course' ||
                  Routes.newAnnouncement.isActive() ||
                  Routes.courseAnnouncement.isActive() ||
                  Routes.filterActivities.isActive(),
                activeTab: activeTab,
                windowWidth: mainPanelWidth,
                maxWindowWidth: '100%',
                tabs: [
                  {
                    tabIndex: this.isAccessible ? 0 : undefined,
                    title: 'Timeline',
                    alternateText: 'Course Timeline',
                    icon: icons.clock,
                    view: Timeline({
                      timeline: courses.timeline,
                      course: currentCourse,
                      role: courseService.getRole()!,
                      userId: getStore().getState().getIn.session!.userId,
                    }),
                    navigate: () =>
                      Routes.courseTimeline.navigate({
                        courseShortId: courseService.getShortIdFromCourseId(courseId),
                        univSlug: appService.getUniversitySlug(),
                      }),
                  },
                  {
                    tabIndex: this.isAccessible ? 0 : undefined,
                    title: 'Info',
                    alternateText: 'Course Info',
                    icon: icons.info,
                    view: Info(),
                    navigate: () =>
                      Routes.courseInfo.navigate({
                        courseShortId: courseService.getShortIdFromCourseId(courseId),
                        univSlug: appService.getUniversitySlug(),
                      }),
                  },
                  {
                    tabIndex: this.isAccessible ? 0 : undefined,
                    title: 'Syllabus',
                    alternateText: 'Course Syllabus',
                    icon: icons.clipboard,
                    view: Syllabus({
                      topics: getStore().getState().courses.topics,
                      file: getStore().getState().courses.syllabusFile,
                    }),
                    navigate: () =>
                      Routes.courseSyllabus.navigate({
                        courseShortId: courseService.getShortIdFromCourseId(courseId),
                        univSlug: appService.getUniversitySlug(),
                      }),
                  },
                ],
              }),
              this.paymentMessage(),
              this.courseEndMessage(),
            ])
          : h(SelectTimezone, {})
        : null,
      visibleTo(['student'], h(AttendanceAlert, { courseId })),
    ]);
  }

  private paymentMessage() {
    const course = courseService.getCurrentCourse();
    //making a temporary fix for the payment message for archived courses
    const { payTill = 0 } = courseService.getCurrentCourseUserData()
      ? courseService.getCurrentCourseUserData()
      : { payTill: 0 };
    const { isJoinCoursePaymentOpen, showPaymentMessage } = this.getState();

    if (!showPaymentMessage) return null;

    const now = datetime.unix();
    const dollars = (course.cost / 100).toFixed(2);

    const Wrapper = ({ message, canDismiss }: { message: string; canDismiss: boolean }) => {
      return h(
        'div',
        style([
          'flex',
          'alignCenter',
          'spaceBetween',
          'fullWidth',
          pad('1rem'),
          {
            gap: '1rem',
            position: 'absolute',
            bottom: 0,
            zIndex: 1001,
            backgroundColor: colors.white,
            borderTop: `1px solid rgba(0,0,0,0.25)`,
          },
        ]),
        [
          h('div', style([mr('auto')]), message),
          FlatButton('Pay now', {
            type: 'primary',
            style: {
              color: '#fff',
              backgroundColor: colors.blue,
            },
            onclick: () => {
              this.setState({
                isJoinCoursePaymentOpen: true,
              });
            },
          }),
          canDismiss
            ? SvgIcon({
                component: 'button',
                icon: CloseIcon,
                style: { color: 'inherit', width: 24, height: 24 },
                onclick: () => {
                  const nextDayStart = datetime.startOfDay(datetime.addDays(new Date(), 1));
                  const nextDayStartUnix = datetime.toUnix(nextDayStart);
                  window.localStorage.setItem(
                    SHOW_PAYMENT_MESSAGE_TIMESTAMP,
                    nextDayStartUnix.toString()
                  );
                  this.setState({
                    showPaymentMessage: false,
                  });
                },
              })
            : null,
          JoinCoursePayment({
            open: isJoinCoursePaymentOpen,
            onClose: async (hasPaid) => {
              this.setState({
                isJoinCoursePaymentOpen: false,
                showPaymentMessage: !hasPaid,
              });
              if (hasPaid) {
                dispatch(Actions.fetchTimeline(getStore().getState().getIn.session!.userId));
              }
            },
          }),
        ]
      );
    };

    if (now < payTill) {
      return Wrapper({
        message: `This course is on a free trial till ${datetime.format(
          datetime.fromUnix(payTill),
          'Do MMM YYYY'
        )}`,
        canDismiss: true,
      });
    }

    return Wrapper({
      message: `Your free trial of this course is over. You need to pay $${dollars} to access`,
      canDismiss: true,
    });
  }

  private courseEndMessage() {
    const vm = this.getState().vm;
    if (!vm) {
      return null;
    }
    if (!vm.courseOverBar) {
      return null;
    }
    return h('div.course-end-view', [
      h('div.course__floating-bar', [
        h('span', vm.courseOverBar.message),
        vm.courseOverBar.action
          ? h(
              'div.ripple.course__floating-bar__action',
              {
                onclick: vm.courseOverBar.action.onClick || undefined,
              },
              [vm.courseOverBar.action.label]
            )
          : null,
      ]),
      this.courseOverDialog(),
    ]);
  }

  private courseOverDialog() {
    const vm = this.getState().vm;
    if (!vm) {
      return null;
    }

    const mainText = vm.courseEndDialog ? vm.courseEndDialog.mainText : null;
    const subHeading = (text: string) => h('div.course__sub-heading', text);

    const bullet = (text: string, key: string, className?: string) =>
      h('div.course__bullet', { key, className }, text);

    const button = (props: { label: string; onClick: () => Promise<void> }) =>
      h('div.ripple.course__action', { onclick: props.onClick }, props.label);

    return Dialog(
      {
        open: vm.isCourseEndDialogOpen,
        title: 'Course Ended',
        thinHeader: true,
        secondaryAction: {
          label: 'Back',
          mobileLabel: h('i.fa.fa-arrow-left'),
          onclick: vm.onClickCourseOverOptionsBack || (() => {}),
        },
        style: {
          backgroundColor: colors.backgroundColor,
        },
      },
      mainText !== null && vm.courseEndDialog
        ? [
            ...mainText.HEADING_PARAGRAPHS.map((text, index) =>
              h('div.course__end-heading', { key: index.toString() }, text)
            ),
            subHeading(mainText.ARCHIVE_HEADING),

            ...mainText.ARCHIVE_BULLETS.map((label, index) => bullet(label, `archive-${index}`)),
            bullet(mainText.ARCHIVE_WARNING_BULLET, 'archive-warning', 'fc-red'),
            button({
              label: mainText.ARCHIVE_BUTTON_LABEL,
              onClick: vm.courseEndDialog.onClickArchive,
            }),

            subHeading(mainText.EXTEND_HEADING),
            ...mainText.EXTEND_BULLETS.map((label, index) => bullet(label, `extend-${index}`)),
            button({
              label: mainText.EXTEND_BUTTON_LABEL,
              onClick: vm.courseEndDialog.onClickExtend,
            }),
            this.archiveCourseDialog(),
            this.extendCourseDialog(),
          ]
        : []
    );
  }

  private extendCourseDialog() {
    const vm = this.getState().vm;
    if (!vm || !vm.courseEndDialog) {
      return null;
    }
    return Dialog(
      {
        open: vm.courseEndDialog.isChangeEndDateDialogOpen,
        style: {
          backgroundColor: colors.backgroundColor,
        },
        title: vm.courseEndDialog.title,
        thinHeader: true,
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-arrow-left'),
          onclick: vm.courseEndDialog.onClickExtendCancel,
        },
      },
      [
        h('div.course__cell', [
          h('span', 'Start Date'),
          h('span.fc-blue', vm.courseEndDialog.startDateFormatted),
        ]),
        h('div.course__cell.bg-white', [
          h('span', 'End Date'),
          vm.courseEndDialog.isExtendLoaderVisible
            ? Loader({
                width: '1em',
                height: '1em',
                borderWidth: '3%',
              })
            : DatePicker({
                value: vm.courseEndDialog.endDate,
                minDate: new Date(),
                focusedDate: new Date(),
                icon: Icon(icons.calendar),
                style: {
                  color: colors.blue,
                },
                onchange: vm.courseEndDialog.onSelectNewEndDate,
              }),
        ]),
        h('div.course__footer-note', [
          h('i.fa.fa-exclamation-triangle'),
          h('span', vm.courseEndDialog.mainText.EXTEND_WARNING),
        ]),
      ]
    );
  }

  private archiveCourseDialog() {
    const vm = this.getState().vm;
    if (!vm || !vm.courseEndDialog) {
      return null;
    }
    return Alert(
      {
        open: vm.courseEndDialog.isArchiveAlertOpen,
        title: 'Archive Course',
        actions: [
          FlatButton('CANCEL', {
            type: 'secondary',
            onclick: vm.courseEndDialog.onClickArchiveCancel,
          }),
          FlatButton('OK', {
            onclick: vm.courseEndDialog.onClickArchiveOk,
          }),
        ],
      },
      ['Are you sure you want to archive this course?']
    );
  }
}

export class SelectTimezone extends IComponent<
  { onClose?(): void },
  {
    timezones?: ITimezone[];
    selectedTimezoneIndex?: number;
    isSubmitting: boolean;
  }
> {
  private isAccessible: boolean;
  public componentWillMount() {
    this.setState({});
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
  }

  public componentDidMount() {
    fetchTimezones().then((response) =>
      this.setState({
        timezones: response.data.timezones.universityTimezones,
      })
    );
  }

  public render() {
    if (this.getState().timezones) {
      u.unsetTabIndices(document.getElementById('alert-box'));
      return Alert(
        {
          open: true,
          title: 'Select course timezone',
          style: {
            width: '30em',
          },
          actions: [
            FlatButton('CANCEL', {
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => {
                const close = this.getProps().onClose;
                if (close) close();
                Routes.home.navigate({});
              },
            }),
            FlatButton('OK', {
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => {
                const close = this.getProps().onClose;
                if (close) close();
                this.submitTimezone();
              },
            }),
          ],
        },
        [
          ...(this.getState().timezones || []).map((timezone, index) =>
            h(
              u.getHTMLTagSelector('div', [
                'course__timezone-row',
                this.getState().selectedTimezoneIndex === index ? 'fc-blue' : 'fc-grey',
              ]),
              {
                key: timezone.timezoneName,
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () => this.selectTimezone(index),
              },
              [
                RadioButton({
                  selected: this.getState().selectedTimezoneIndex === index,
                }),
                h('span.course__timezone-title', [
                  h('div', `${timezone.campusName} Campus`),
                  h('div.course__timezone-subtitle', timezone.readable),
                ]),
              ]
            )
          ),
        ]
      );
    } else {
      return Loader();
    }
  }

  private selectTimezone(index: number) {
    this.setState({
      selectedTimezoneIndex: index,
    });
  }

  private async submitTimezone() {
    u.resetTabIndices();
    await getStore().dispatch(
      Actions.setTimezone(
        this.getState().timezones![this.getState().selectedTimezoneIndex!].timezoneName
      )
    );
  }
}
