import { CSS, HTMLAttrs } from 'core';

import { getStore } from 'acadly/store';

type Classes = {
  [key: string]: any;
};
export const contextPanelWidthPixels = 350;
export const sidebarWidthPixels = 100;
export const mainPanelWidth = '35em';
export const backgroundColor = 'rgb(239, 239, 239)';
const colors = {
  orange: '#d95916',
  black: 'black',
  green: '#068f68',
  tipGreen: '#032838',
  tipOverlayGreen: '#032838',
  teal: '#047f8c',
  pink: '#ff4981',
  darkPink: '#d11250',
  mediumGrey: '#7a7a7a',
  mediumDarkGrey: '#7d7d7d',
  lightestGrey: '#efefef',
  lightGrey: '#aaa',
  anotherGrey: '#bababa',
  widgetIconGrey: '#bababa',
  lighterGrey: '#b9b9b9',
  red: '#cc0a05',
  cyan: '#5ac8fb',
  blue: '#0b68ad',
  lightBlue: '#0b68ad',
  darkCyan: '#047f8c',
  grey: '#6a6a6a',
  darkBlue: '#1a3e4e',
  white: 'white',
  lightBlack: 'rgba(0, 0, 0, 0.7)',
  overlayTipGreen: 'rgba(5, 18, 25, 0.9)',
  overlayGrey: 'rgba(170, 170, 170, 0.8)',
  overlayOrange: 'rgba(255, 94, 58, 0.85)',
  overlayBlue: 'rgba(11,104,173,.8)',
  overlayGreen: 'rgba(0,115,58,.85)',
  overlayDarkBlue: 'rgba(29,62,78,.85)',
  errorRed: 'rgb(244, 67, 54)',
  backgroundColor: 'rgb(239, 239, 239)',
  yellow: 'rgb(252, 235, 31)',
};

export const timelineWidgetHeightPx = 100;

export const getHeaderHeight = () => (getStore().getState().app.narrowScreen ? '4rem' : '3.5rem');

export function tabHeight(options: { icon: boolean } = { icon: true }) {
  if (getStore().getState().app.isMobile && options.icon) {
    return '5em';
  } else {
    return '3.5em';
  }
}

export function classTabHeight() {
  return tabHeight({ icon: false });
}

export const S = {
  flex: { display: 'flex' },
  alignCenter: { alignItems: 'center' },
  justifyCenter: { justifyContent: 'center' },
  row: { flexDirection: 'row' },
  column: { flexDirection: 'column' },
  fullWidth: { width: '100%' },
  fullHeight: { height: '100%' },
  autoScroll: { overflowY: 'auto' },
  vScroll: { overflowY: 'scroll' },
  flexEnd: { justifyContent: 'flex-end' },
  flexStart: { justifyContent: 'flex-start' },
  spaceAround: { justifyContent: 'space-around' },
  whiteBackground: { backgroundColor: 'white' },
  spaceBetween: { justifyContent: 'space-between' },
  strikethrough: { textDecoration: 'line-through' },
  mainPanel: {
    width: mainPanelWidth,
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  bold: {
    fontWeight: 'bold',
  },
  thick: {
    fontWeight: '700',
  },
  thin: {
    fontWeight: '400',
  },
  italic: { fontStyle: 'italic' },
  underline: { textDecoration: 'underline' },
  'xx-small': {
    fontSize: '0.4em',
  },
  'x-small': {
    fontSize: '0.8em',
  },
  small: {
    fontSize: '0.9em',
  },
  medium: {
    fontSize: '1.1em',
  },
  large: {
    fontSize: '1.2em',
  },
  'x-large': {
    fontSize: '1.5em',
  },
  'xx-large': {
    fontSize: '1.7em',
  },
  textCenter: { textAlign: 'center' },
  textRight: { textAlign: 'right' },
  pointer: { cursor: 'pointer' },

  mlAuto: { marginLeft: 'auto' },
  mrAuto: { marginRight: 'auto' },
  mtAuto: { marginTop: 'auto' },
  mbAuto: { marginBottom: 'auto' },
  borderBox: { boxSizing: 'border-box' },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  uppercase: { textTransform: 'uppercase' },
  capitalize: { textTransform: 'capitalize' },
  absolute: { position: 'absolute' },
  relative: { position: 'relative' },
  fixed: { position: 'fixed' },
  widgetRhs: {
    marginLeft: '0.5em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    width: `calc(100% - 4rem)`,
  },
  widgetFilterActivitiesRhs: {
    paddingLeft: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: `calc(100% - 4rem)`,
  },
  widgetRhsHeading: {},

  widgetRhsTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: colors.mediumDarkGrey,
  },
  widgetFilterActivitiesRhsTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: colors.mediumDarkGrey,
    margin: 'auto 0',
  },

  lightBorder: { borderBottom: `1px solid ${colors.lightestGrey}` },
  standardBorder: { borderBottom: `1px solid ${colors.lightGrey}` },
};

export type IAcadlyStyleClass = keyof typeof S;

const styleSetter =
  (key: keyof CSS) =>
  (value: string): CSS => ({ [key]: value });
export const mt = styleSetter('marginTop');
export const mb = styleSetter('marginBottom');
export const ml = styleSetter('marginLeft');
export const mr = styleSetter('marginRight');
export const margin = styleSetter('margin');
export const pt = styleSetter('paddingTop');
export const pb = styleSetter('paddingBottom');
export const pl = styleSetter('paddingLeft');
export const pr = styleSetter('paddingRight');
export const pad = styleSetter('padding');
export const size = styleSetter('fontSize');

export type IStyle = IAcadlyStyleClass | keyof typeof colors | CSS;

export function style<Attrs extends HTMLAttrs = Record<string, unknown>>(
  classes: IStyle[],
  otherStyles?: CSS,
  otherAttrs?: Attrs
): Attrs & {
  style: CSS;
} {
  // performance.mark(`style-start`);
  const styleObjects = S;
  const attrs: any = Object.assign({}, otherAttrs);
  const styles: any = {};
  attrs.style = styles;
  for (const cls of classes) {
    if (typeof cls === 'object') {
      Object.assign(styles, cls);
    } else if ((colors as any)[cls]) {
      styles.color = (colors as any)[cls];
    } else {
      Object.assign(styles, (<any>styleObjects)[cls]);
    }
  }
  Object.assign(styles, otherStyles);
  if (otherAttrs && (<any>otherAttrs).style) {
    Object.assign(styles, (<any>otherAttrs).style);
  }
  const result = attrs;
  // performance.mark(`style-end`);
  // performance.measure("style", "style-start", "style-end");
  return result;
}

/**
 * @deprecated Use `style` export above and styleClasses declared above
 */
const classes: Classes = {
  mhAuto: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  mvAuto: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  flex: { display: 'flex' },

  col: { flexDirection: 'column' },

  row: { flexDirection: 'row' },

  pullRight: { marginLeft: 'auto' },

  orangeText: { color: colors.orange },

  greenText: { color: colors.green },

  pinkText: { color: colors.pink },

  darkPinkText: { color: colors.darkPink },

  lightGreyText: { color: colors.lightGrey },

  redText: { color: colors.red },

  cyanText: { color: colors.cyan },

  blueText: { color: colors.blue },

  darkCyanText: { color: colors.darkCyan },

  greyText: { color: colors.grey },

  darkBlueText: { color: colors.darkBlue },

  whiteText: { color: colors.white },
  orangeBackground: { backgroundColor: colors.orange },

  greenBackground: { backgroundColor: colors.green },

  pinkBackground: { backgroundColor: colors.pink },

  darkPinkBackground: { backgroundColor: colors.darkPink },

  lightGreyBackground: { backgroundColor: colors.lightGrey },

  redBackground: { backgroundColor: colors.red },

  cyanBackground: { backgroundColor: colors.cyan },

  blueBackground: { backgroundColor: colors.blue },

  darkCyanBackground: { backgroundColor: colors.darkCyan },

  greyBackground: { backgroundColor: colors.grey },

  darkBlueBackground: { backgroundColor: colors.darkBlue },

  whiteBackground: { backgroundColor: colors.white },
};
export { classes, colors };
