import { h, IComponent } from 'core';
import { Route, Switch } from 'core/router';

import Announcement from 'acadly/announcement/Announcement';
import AnnouncementWidget from 'acadly/announcement/AnnouncementWidget';
import { Actions as appActions } from 'acadly/app/actions';
import appService from 'acadly/app/service';
import Assignment from 'acadly/assignment/Assignment';
import AssignmentWidget from 'acadly/assignment/AssignmentWidget';
import { assignmentService } from 'acadly/assignment/service';
import classService from 'acadly/class/service';
import ContentView from 'acadly/common/ContentView';
import { Loader } from 'acadly/common/Loader';
import { Actions as DiscussionActions } from 'acadly/discussion/actions';
import Discussion from 'acadly/discussion/Discussion';
import DiscussionWidget from 'acadly/discussion/DiscussionWidget';
import discussionService from 'acadly/discussion/service';
import { Actions as PollActions } from 'acadly/poll/actions';
import Poll from 'acadly/poll/Poll';
import PollWidget from 'acadly/poll/PollWidget';
import pollService from 'acadly/poll/service';
import { Actions as QueryActions } from 'acadly/query/actions';
import Query from 'acadly/query/Query';
import QueryWidget from 'acadly/query/QueryWidget';
import queryService from 'acadly/query/service';
import { Actions as QuizActions } from 'acadly/quiz/actions';
import Quiz from 'acadly/quiz/Quiz';
import QuizWidget from 'acadly/quiz/QuizWidget';
import quizService from 'acadly/quiz/service';
import { Actions as ResourceActions } from 'acadly/resource/actions';
import Resource from 'acadly/resource/Resource';
import ResourceWidget from 'acadly/resource/ResourceWidget';
import resourceService from 'acadly/resource/service';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import * as u from 'acadly/utils';

import courseService from './service';

export default (props: IFilterActivitiesProps) => h(FilterActivities, props);

export interface IFilterActivitiesState {
  activityIds: string[];
  isLoading: boolean;
}

export type ActivityType =
  | 'announcement'
  | 'assignment'
  | 'quiz'
  | 'poll'
  | 'resource'
  | 'discussion'
  | 'query';

export type ActivityTypePlurals =
  | 'announcements'
  | 'assignments'
  | 'quizzes'
  | 'polls'
  | 'resources'
  | 'discussions'
  | 'queries';

export interface IFilterActivitiesProps {
  type: ActivityTypePlurals;
  timelineActivities: (IAnnouncement | IAssignment)[];
  course: ICourse;
  courseRole: ICourseRole;
}
export class FilterActivities extends IComponent<IFilterActivitiesProps, IFilterActivitiesState> {
  public componentWillMount() {
    this.setState({
      activityIds: [],
      isLoading: false,
    }).then(() => this.fetchActivities());
  }

  public render() {
    const { isLoading } = this.getState();
    if (isLoading) return h('div.filter-activities__loader', [Loader()]);
    return ContentView(h('div', [this.subRoute()]));
  }

  private widgetsList(published: 0 | 1) {
    const { courseRole } = this.getProps();
    const listLength = this.widgets(published).length;
    const isListVisible = (published || courseRole !== 'student') && listLength > 0;
    const tag = published === 1 ? 'Published' : 'Unpublished';
    return isListVisible
      ? h('div.activity-list', [
          h('h2.filter-activities__category', tag),
          ...this.widgets(published),
        ])
      : null;
  }

  private subRoute() {
    // const isSubrouteActive =
    //     Routes.filterActivities.isActive(false) // filter route matches
    //     && !Routes.filterActivities.isActive(true); // filter route doesn't match exactly
    // if (!isSubrouteActive) return null;
    const { courseRole, course } = this.getProps();
    return h('div.filter-activities__wrapper', [
      Switch(
        [
          Route(
            Routes.filterActivities,
            () => {
              return ContentView(
                h('div', [
                  this.heading(),
                  this.widgetsList(1), // For published Activities
                  this.widgetsList(0), // For unpublished Activities
                ])
              );
            },
            {
              exact: true,
            }
          ),
          Route(Routes.filteredAnnouncement, (match) => {
            const announcement = this.getAnnouncements().find(
              (a) => a._id === this.getAnnouncementIdFromShortId(match.params.announcementShortId)
            );
            if (!announcement) {
              Routes.courseTimeline.navigate({
                univSlug: appService.getUniversitySlug(),
                courseShortId: courseService.getShortIdFromCourseId(course._id),
              });
              dispatch(
                appActions.showError({
                  message:
                    'Announcement was not found. It has either been moved, ' +
                    'deleted or not published yet.',
                })
              );
              return null;
            } else {
              return Announcement(announcement as IAnnouncement);
            }
            // if (!announcement) return null;
            // return Announcement(announcement);
          }),
          Route(Routes.filteredAssignment, (match) => {
            const assignment = this.getAssignments().find(
              (a) =>
                a._id ===
                assignmentService.getAssignmentIdFromShortId(match.params.assignmentShortId)
            );
            if (!assignment) {
              Routes.courseTimeline.navigate({
                univSlug: appService.getUniversitySlug(),
                courseShortId: courseService.getShortIdFromCourseId(course._id),
              });
              dispatch(
                appActions.showError({
                  message:
                    'Assignment was not found. It has either been moved,' +
                    ' deleted or not published yet.',
                })
              );
              return null;
            } else {
              return Assignment({
                assignment,
                course,
              });
            }
            // if (!assignment) return null;
            // return Assignment({
            //     assignment,
            //     course: this.getCourse()
            // });
          }),
          Route(Routes.filteredQuiz, (match) => {
            const quizId = quizService.getQuizIdFromShortId(match.params.quizShortId);
            const quiz = this.getStoreState().quizzes.byId[quizId as any];
            if (!quiz) return null;
            const cls = this.getClassById(quiz.identifiers.classId);
            if (!cls) return null;
            const userId = getStore().getState().getIn.session!.userId;
            return Quiz({
              quiz: quiz,
              courseRole,
              course,
              class: cls,
              isClassIncharge: cls.info.team.inCharge.userId === userId,
            });
          }),
          Route(Routes.filteredPoll, (match) => {
            const pollId = pollService.getPollIdFromShortId(match.params.pollShortId);
            const poll = this.getStoreState().polls.byId[pollId as any];
            if (!poll) return null;
            const cls = this.getClassById(poll.identifiers.classId);
            if (!cls) return null;
            const userId = getStore().getState().getIn.session!.userId;
            return Poll({
              poll: poll,
              courseRole,
              course,
              class: cls,
              isClassIncharge: cls.info.team.inCharge.userId === userId,
            });
          }),

          Route(Routes.filteredResource, (match) => {
            const resourceId = resourceService.getResourceIdFromShortId(
              match.params.resourceShortId
            );
            const resource = this.getStoreState().resources.byId[resourceId as any];
            if (!resource) return null;
            const cls = this.getClassById(resource.identifiers.classId);
            if (!cls) return null;
            const userId = getStore().getState().getIn.session!.userId;
            return Resource({
              resource: resource,
              courseRole,
              course,
              userId,
              cls: cls,
              isClassIncharge: cls.info.team.inCharge.userId === userId,
            });
          }),

          Route(Routes.filteredDiscussion, (match) => {
            const discussionId = discussionService.getDiscussionIdFromShortId(
              match.params.discussionShortId
            );
            const discussion = this.getStoreState().discussions.byId[discussionId as any];
            if (!discussion) return null;
            const cls = this.getClassById(discussion.identifiers.classId);
            if (!cls) return null;
            const userId = getStore().getState().getIn.session!.userId;
            return Discussion({
              discussion: discussion,
              courseRole,
              course,
              userId,
              cls: cls,
              isClassIncharge: cls.info.team.inCharge.userId === userId,
            });
          }),

          Route(Routes.filteredQuery, (match) => {
            const queryId = queryService.getQueryIdFromShortId(match.params.queryShortId);
            const query = this.getStoreState().queries.byId[queryId as any];
            if (!query) return null;
            const cls = this.getClassById(query.identifiers.classId);
            if (!cls) return null;
            const userId = getStore().getState().getIn.session!.userId;
            return Query({
              cls: cls,
              query: query,
              isArchived: course.isArchived === 1,
              courseRole,
              userId,
            });
          }),
        ],
        {
          defaultCallback: () => {
            Routes.courseTimeline.navigate({
              univSlug: appService.getUniversitySlug(),
              courseShortId: courseService.getShortIdFromCourseId(course._id),
            });
          },
        }
      ),
    ]);
  }

  private heading() {
    const { type } = this.getProps();
    return h('h1.filter-activities__heading', `Showing all ${type}`);
  }

  private getAnnouncements(): IAnnouncement[] {
    return this.getProps().timelineActivities.filter(
      (item) => item.nodeType === 'announcement'
    ) as IAnnouncement[];
  }

  private getAssignments(): IAssignment[] {
    return this.getProps().timelineActivities.filter(
      (item) => item.nodeType === 'assignment'
    ) as IAssignment[];
  }

  public getAnnouncementIdFromShortId(shortId: string) {
    const timeline = getStore().getState().courses.timeline;
    const announcement = timeline
      ? (timeline!.items.filter((item) => item._id.slice(18) === shortId)[0] as IAnnouncement)
      : null;
    if (announcement) {
      return announcement._id;
    } else {
      return null;
    }
  }
  public getShortIdFromAnnouncementId(announcementId: string) {
    return announcementId.slice(18);
  }

  private widgets(published: 0 | 1) {
    const { type, courseRole, course } = this.getProps();
    const timeline = this.getStoreState().courses.timeline;
    const announcements: IAnnouncement[] = this.getAnnouncements();
    const assignments: IAssignment[] = this.getAssignments();
    const activityIds = this.getState().activityIds;
    switch (type) {
      // Since there is no concept of published in announcement,
      // We return an empty array when published = 0
      case 'announcements':
        return published === 1
          ? announcements.map((a) =>
              AnnouncementWidget(a, {
                onclick: () =>
                  Routes.filteredAnnouncement.navigate({
                    courseShortId: courseService.getShortIdFromCourseId(a.identifiers.courseId),
                    // type: this.getProps().type,
                    announcementShortId: this.getShortIdFromAnnouncementId(a._id),
                    univSlug: appService.getUniversitySlug(),
                  }),
              })
            )
          : [];
      case 'assignments':
        return assignments
          .filter((a) => a.details.published === published)
          .map((a) =>
            AssignmentWidget(a, {
              onclick: () =>
                Routes.filteredAssignment.navigate({
                  courseShortId: courseService.getShortIdFromCourseId(a.identifiers.courseId),
                  // type: this.getProps().type,
                  assignmentShortId: assignmentService.getShortIdFromAssignmentId(a._id),
                  univSlug: appService.getUniversitySlug(),
                }),
            })
          );
      case 'quizzes': {
        const quizzesById = this.getStoreState().quizzes.byId;
        const quizzes = u.filterUndefined(
          activityIds
            .map((id) => quizzesById[id])
            .filter((q) => q && q.details.published === published)
        );
        if (!timeline) return [];
        return quizzes.map((q) => {
          const cls = this.getClassById(q.identifiers.classId);
          if (!cls) return null;
          return QuizWidget({
            quiz: q,
            courseRole,
            onclick: () =>
              Routes.filteredQuiz.navigate({
                courseShortId: courseService.getShortIdFromCourseId(course._id),
                quizShortId: quizService.getShortIdFromQuizId(q._id),
                classShortId: classService.getShortIdFromClassId(q.identifiers.classId),
                univSlug: appService.getUniversitySlug(),
                // type: type
              }),
          });
        });
      }
      case 'polls': {
        const pollsById = this.getStoreState().polls.byId;

        const polls = u.filterUndefined(
          activityIds
            .map((id) => pollsById[id])
            .filter((p) => p && p.details.published === published)
        );
        return polls.map((p) => {
          const cls = this.getClassById(p.identifiers.classId);
          if (!cls) return null;
          return PollWidget({
            style: {
              height: '6em',
            },
            poll: p,
            courseRole,
            onclick: () =>
              Routes.filteredPoll.navigate({
                courseShortId: courseService.getShortIdFromCourseId(course._id),
                pollShortId: pollService.getShortIdFromPollId(p._id),
                classShortId: classService.getShortIdFromClassId(p.identifiers.classId),
                univSlug: appService.getUniversitySlug(),
                // type: type
              }),
          });
        });
      }

      case 'resources': {
        const resourcesById = this.getStoreState().resources.byId;
        const resources = u.filterUndefined(
          activityIds
            .map((id) => resourcesById[id])
            .filter((r) => r && r.details.published === published)
        );
        return resources.map((r) => {
          const cls = this.getClassById(r.identifiers.classId);
          if (!cls) return null;
          return ResourceWidget({
            style: {
              height: '6em',
            },
            resource: r,
            courseRole,
            onclick: () =>
              Routes.filteredResource.navigate({
                courseShortId: courseService.getShortIdFromCourseId(course._id),
                resourceShortId: resourceService.getShortIdFromResourceId(r._id),
                classShortId: classService.getShortIdFromClassId(r.identifiers.classId),
                univSlug: appService.getUniversitySlug(),
              }),
            cls: cls as IClass,
          });
        });
      }
      case 'discussions': {
        const discussionsById = this.getStoreState().discussions.byId;
        const discussions = u.filterUndefined(
          activityIds
            .map((id) => discussionsById[id])
            .filter((d) => d && d.details.published === published)
        );
        return discussions.map((d) => {
          const cls = this.getClassById(d.identifiers.classId);
          if (!cls) return null;
          return DiscussionWidget({
            style: {
              height: '6em',
            },
            discussion: d,
            courseRole,
            onclick: () =>
              Routes.filteredDiscussion.navigate({
                courseShortId: courseService.getShortIdFromCourseId(course._id),
                discussionShortId: discussionService.getShortIdFromDiscussionId(d._id),
                classShortId: classService.getShortIdFromClassId(d.identifiers.classId),
                univSlug: appService.getUniversitySlug(),
              }),
          });
        });
      }
      // Same case as announcements
      case 'queries': {
        const queriesById = this.getStoreState().queries.byId;
        const queries = u.filterUndefined(activityIds.map((id) => queriesById[id]));
        return published === 1
          ? queries.map((q) => {
              const cls = this.getClassById(q.identifiers.classId);
              if (!cls) return null;
              return QueryWidget({
                style: {
                  height: '6em',
                },
                query: q,
                courseRole,
                onclick: () =>
                  Routes.filteredQuery.navigate({
                    courseShortId: courseService.getShortIdFromCourseId(course._id),
                    queryShortId: queryService.getShortIdFromQueryId(q._id),
                    classShortId: classService.getShortIdFromClassId(q.identifiers.classId),
                    univSlug: appService.getUniversitySlug(),
                  }),
              });
            })
          : [];
      }
    }
  }

  private async fetchActivities() {
    const { type, course, courseRole } = this.getProps();
    const numPublished =
      type === 'queries' ? course.activities.numQueries : course.activities[type].numPublished;
    const numActivities =
      type === 'queries'
        ? course.activities.numQueries + (course.activities.numQueriesPending || 0)
        : course.activities[type].numTotal;
    if (type === 'announcements' || type === 'assignments') return;
    if (
      (courseRole !== 'student' && numActivities > 0) ||
      (courseRole === 'student' && numPublished > 0)
    ) {
      const actions = {
        quizzes: QuizActions,
        polls: PollActions,
        resources: ResourceActions,
        queries: QueryActions,
        discussions: DiscussionActions,
      }[type];
      await this.setState({
        isLoading: true,
      });
      const ids = await dispatch(actions.fetchAll());
      await this.setState({
        activityIds: ids,
        isLoading: false,
      });
      return;
    }
  }

  private getStoreState() {
    return getStore().getState();
  }

  private getClassById(classId: string) {
    const timeline = getStore().getState().courses.timeline;
    if (!timeline) return null;
    const cls = timeline.items.find((c) => c._id === classId);
    if (!cls) return null;
    return cls as IClass;
  }
}
