import { CSS, h, IComponent, View } from 'core';
import { jsonRequest } from 'core/http';

import * as DownloadIcon from 'assets/download.svg';

import { Loader } from 'acadly/common/Loader';
import { getStore } from 'acadly/store';
import { colors, style } from 'acadly/styles';
import { randomString } from 'acadly/utils';

import SvgIcon from './SvgIcon';

export default (props: IAttachmentViewerProps) =>
  h(AttachmentViewer, { key: props.attachment.name, ...props });

export interface IAttachmentViewerProps {
  attachment: IAttachment;
  key?: string;
  downloadUrl: string;
  requestMethod?: 'GET' | 'POST';
  requestHeaders?: any;
  downloadRequest: any;
  color?: string;
  style?: CSS;
  icon?: View;
  disabled?: boolean;
  hideDownloadIcon?: boolean;
  hideNameExtension?: boolean;
  actionButton?: View;
}

export interface IAttachmentViewerState {
  downloadLink: string | null;
  isFetchingDownloadLink: boolean;
  runningTimeout: any | null;
  attachmentAnchorId: string;
}
export class AttachmentViewer extends IComponent<IAttachmentViewerProps, IAttachmentViewerState> {
  public componentWillMount() {
    const initialState: IAttachmentViewerState = {
      downloadLink: null,
      isFetchingDownloadLink: false,
      runningTimeout: null,
      attachmentAnchorId: randomString(),
    };
    this.setState(initialState);
  }

  public render() {
    const props = this.getProps();
    const { attachment, hideNameExtension, disabled, actionButton } = props;
    // const { downloadLink } = this.getState();
    const fileName =
      attachment.originalName +
      (attachment.extension && !hideNameExtension ? '.' + attachment.extension : '');
    const styles = {
      display: 'flex',
      width: '20em',
      borderRadius: '4px',
      backgroundColor: 'white',
      alignItems: 'center',
      border: `1px solid ${colors.lightestGrey}`,
      maxWidth: '100%',
      lineHeight: '1.5em',
      boxSizing: 'border-box',
      paddingRight: '0.5rem',
      ...props.style,
    };
    const state = this.getState();
    const color = props.color || colors.green;
    return h(
      'div.acadly-attachment',
      {
        style: styles,
        tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
        'aria-label': `Acadly attachment :  ${fileName}`,
      },
      [
        this.getProps().icon ||
          h(
            'div.attachment-icon',
            {
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '0.5rem',
                // fontSize: "0.8em",
                width: '2.5rem',
                color: 'white',
                height: '2.5rem',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
                backgroundColor: color,
              },
            },
            attachment.extension.toUpperCase()
          ),
        h(
          'div',
          style([
            'thin',
            {
              flex: 1,
              color: props.color || undefined,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              marginRight: '0.5em',
            },
          ]),
          [fileName]
        ),

        ...(state.isFetchingDownloadLink
          ? [
              Loader({
                fontSize: '0.5rem',
              }),
            ]
          : [
              this.getProps().hideDownloadIcon ||
              state.isFetchingDownloadLink ||
              state.downloadLink !== null
                ? null
                : SvgIcon({
                    icon: DownloadIcon,
                    tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                    ariaLabel: 'Download Attachment',
                    role: 'button',
                    style: style(['blue', 'pointer', { width: '2rem' }]).style,
                    onclick: disabled ? undefined : () => this.fetchDownloadUrl(),
                  }),
              this.downloadLink(color),
              actionButton ? actionButton : null,
            ]),
      ]
    );
  }

  protected downloadLink(color?: string) {
    const { attachment, hideNameExtension } = this.getProps();
    const { downloadLink, attachmentAnchorId } = this.getState();
    const fileName =
      attachment.originalName +
      (attachment.extension && !hideNameExtension ? '.' + attachment.extension : '');
    // if (this.canDownloadAutomatically() || downloadLink === null) {
    //     return null;
    // }
    if (downloadLink === null) {
      return null;
    }
    return h(
      `a#download-attachment-${attachmentAnchorId}`,
      {
        style: {
          marginLeft: 'auto',
          color: color || colors.green,
        },
        target: '_blank',
        download: fileName,
        'aria-label': 'download this attachment',
        tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
        href: downloadLink || undefined,
      },
      'Download'
    );
  }

  protected async fetchDownloadUrl() {
    const { runningTimeout, attachmentAnchorId } = this.getState();

    // cancel old timeout if present
    if (runningTimeout) {
      clearTimeout(runningTimeout);
    }
    await this.setState({
      downloadLink: null,
      isFetchingDownloadLink: true,
    });
    const response = await this.fetchDownloadUrlRequest();

    await this.setState({
      isFetchingDownloadLink: false,
    });

    // if (this.canDownloadAutomatically()) {
    //     const elem = document.createElement("a");
    //     elem.innerHTML = "Download";
    //     elem.setAttribute("download", this.getProps().attachment.originalName);
    //     elem.setAttribute("href", response.data.link);
    //     elem.setAttribute("target", "_blank");
    //     elem.style.display = "none";
    //     elem.click();
    //     await this.setState({
    //         downloadLink: null,
    //     });
    // } else {
    // set a timeout of 10 minutes that clears
    // download link; Download link is valid for
    // 15 minutes so user shouldn't see the.
    // state download link
    await this.setState({
      downloadLink: response.data.link,
      runningTimeout: setTimeout(() => {
        this.setState({
          downloadLink: null,
          runningTimeout: null,
        });
      }, 10 * 60 * 1000),
    }).then(() => {
      setTimeout(() => {
        const downloadBtn = document.getElementById(`download-attachment-${attachmentAnchorId}`);
        if (downloadBtn) {
          downloadBtn.focus();
        }
      }, 0);
    });
    // }
  }

  protected async fetchDownloadUrlRequest() {
    const { requestMethod } = this.getProps();
    return await jsonRequest<{ link: string }>(this.getProps().downloadUrl, {
      method: requestMethod || 'POST',
      headers: this.getProps().requestHeaders,
      data: this.getProps().downloadRequest,
    });
  }

  // private canDownloadAutomatically() {
  //     const isSafari =
  //         navigator.userAgent.indexOf("Chrome") === -1 &&
  //         navigator.userAgent.indexOf("Safari") > -1;

  //     const isFirefox =
  //         navigator.userAgent.indexOf("Firefox") > -1;
  //     return !(isSafari || isFirefox);
  // }
}
