type IRefCallback = (elem: HTMLElement) => void;
export function ref(...refCallbacks: IRefCallback[]) {
  return (elem: HTMLElement | undefined | null) => {
    if (elem) {
      for (const callback of refCallbacks) {
        callback(elem);
      }
    }
  };
}
