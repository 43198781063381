import { h, IComponent } from 'core';

import * as WordCloudIcon from 'assets/word-cloud.svg';

import Dialog from 'acadly/common/Dialog';
import Icon from 'acadly/common/Icon';
import { fullScreenLoader, Loader } from 'acadly/common/Loader';
import SvgIcon from 'acadly/common/SvgIcon';
import courseService from 'acadly/course/service';
import * as datetime from 'acadly/datetime';
import icons from 'acadly/icons';
import { dispatch, getStore } from 'acadly/store';
import { colors, margin, mb, pad, style } from 'acadly/styles';

import { Actions } from './actions';

interface IDiscussionWordCloudProps {
  discussion: IDiscussion;
}

interface Word {
  text: string;
  freq?: number;
  isRemoved: boolean;
}

interface IDiscussionWordCloudState {
  isLoading: boolean;
  isRegenerating: boolean;
  isWordDetailDialogVisible: boolean;
  isRemovedWordUpdated: boolean;
  wordList: Word[];
  errorMsg: string;
}

const wcNotAvailableImage = 'https://s3.amazonaws.com/static.acad.ly/img/wc-ph-na.png';
const wcNotGeneratedImage = 'https://s3.amazonaws.com/static.acad.ly/img/wc-ph-ng.png';
const wcGeneratingImage = 'https://s3.amazonaws.com/static.acad.ly/img/wc-ph-g.png';

export class DiscussionWordCloud extends IComponent<
  IDiscussionWordCloudProps,
  IDiscussionWordCloudState
> {
  private updateWordList(wordCloud: IWordCloud) {
    const removedWords = wordCloud.removedWords;
    const wordList: Word[] = wordCloud.wordList.map((w) => ({
      text: w.word,
      freq: w.freq,
      isRemoved: removedWords.indexOf(w.word) > -1,
    }));

    this.setState({ wordList });
  }

  private init(discussion: IDiscussion) {
    const wordCloud = getStore().getState().discussions.wordCloud;
    const initialState: IDiscussionWordCloudState = {
      isLoading: false,
      isRegenerating: false,
      isWordDetailDialogVisible: false,
      isRemovedWordUpdated: false,
      wordList: [],
      errorMsg: '',
    };

    if (!discussion.details.wordCloudGenerated) {
      this.setState(initialState);
    } else if (!wordCloud || wordCloud.discussionId !== discussion._id) {
      const courseRole = courseService.getRole();
      if (
        courseRole === 'student' &&
        discussion.details.submitFirst &&
        (!discussion.userData || !discussion.userData.submitted)
      ) {
        // don't hit server to fetch word cloud data
        this.setState(initialState);
        return;
      }
      this.setState({
        ...initialState,
        isLoading: true,
      });
      dispatch(Actions.fetchWordCloud(discussion._id)).then((wordCloud) => {
        this.setState({ isLoading: false });
        this.updateWordList(wordCloud);
      });
    } else {
      this.setState(initialState);
      this.updateWordList(wordCloud);
    }
  }

  public componentWillMount() {
    this.init(this.getProps().discussion);
  }

  public render() {
    const { isLoading } = this.getState();
    if (isLoading) return fullScreenLoader;
    const courseRole = courseService.getRole();
    return courseRole === 'student' ? this.forStudent() : this.forTeam();
  }

  private wcMessageTemplate(image: string, message: string, canCreateWC = false) {
    const { isMobile, windowWidth } = getStore().getState().app;
    return h(
      'div.discussion-word-cloud-container',
      style(['fullWidth', 'grey'], {
        overflow: 'auto',
        paddingBottom: '4em',
      }),
      [
        h(
          'div.discussion-word-cloud',
          style(['fullWidth', margin('0 auto')], {
            maxWidth: '35em',
          }),
          [
            h('img.word-cloud-container', {
              style: style([
                'fullWidth',
                pad('1em 0'),
                {
                  minHeight: isMobile ? windowWidth + 28 : '37em',
                },
              ]).style,
              src: image,
            }),
            h('div', style([pad('1em'), 'x-large', 'textCenter']), message),
            this.getState().errorMsg
              ? h('div', style([pad('1em'), 'errorRed']), this.getState().errorMsg)
              : null,
            canCreateWC ? this.generateButton('GENERATE WORD CLOUD') : null,
          ]
        ),
      ]
    );
  }

  private forTeam() {
    const { discussion } = this.getProps();

    if (discussion.details.wordCloudGenerated) {
      return this.main();
    }

    if (discussion.details.wordCloudAvailable) {
      return this.wcMessageTemplate(
        this.getState().isRegenerating ? wcGeneratingImage : wcNotGeneratedImage,
        `The word cloud for this discussion is available. Please click
                on "Generate Word Cloud" to generate a new word cloud`,
        true
      );
    }

    return this.wcMessageTemplate(
      wcNotAvailableImage,
      'There is not enough meaningful data in the posts yet to generate a wordcloud'
    );
  }

  private forStudent() {
    const { discussion } = this.getProps();

    if (!discussion.details.wordCloudGenerated) {
      return this.wcMessageTemplate(
        wcNotAvailableImage,
        'The word cloud for this discussion has not been generated yet'
      );
    }

    if (
      discussion.details.submitFirst &&
      (!discussion.userData || !discussion.userData.submitted)
    ) {
      return this.wcMessageTemplate(
        wcNotAvailableImage,
        `You need to participate in this discussion to view the Word Cloud.
                Please post at least one comment and try again.`
      );
    }

    return this.main();
  }

  private main() {
    const discussion = this.getProps().discussion;
    const wordCloud = getStore().getState().discussions.wordCloud!;
    const courseRole = courseService.getRole();
    const { isMobile, windowWidth } = getStore().getState().app;

    const cellStyle = style(['flex', 'spaceBetween', pad('1em')]);
    const buttonStyle = style(
      ['fullWidth', pad('1rem'), margin('0.5em 0 0'), 'pointer', 'blue', 'medium', 'relative'],
      {
        background: colors.white,
        border: 'none',
        height: '4em',
      }
    );

    if (!wordCloud) return null;

    return h(
      'div.discussion-word-cloud-container',
      style(['fullWidth', 'grey'], {
        overflow: 'auto',
        paddingBottom: '4em',
      }),
      [
        h(
          'div.discussion-word-cloud',
          style(['fullWidth', margin('0 auto')], {
            maxWidth: '35em',
          }),
          [
            h('img.word-cloud-container', {
              style: style([
                'fullWidth',
                pad('1em 0'),
                {
                  minHeight: isMobile ? windowWidth + 28 : '37em',
                },
              ]).style,
              src: this.getState().isRegenerating ? wcGeneratingImage : wordCloud.image,
            }),
            this.getState().errorMsg
              ? h('div', style([pad('1em'), 'errorRed']), this.getState().errorMsg)
              : null,
            courseRole !== 'student' ? this.generateButton('GENERATE NEW WORD CLOUD') : null,
            courseRole !== 'student'
              ? h(
                  'button.ripple',
                  style(
                    [cellStyle.style, buttonStyle.style],
                    {},
                    {
                      onclick: () => this.showWordDetailDialog(),
                    }
                  ),
                  [h('span', style(['black']), 'Words list'), h('span', 'Edit')]
                )
              : null,
            h('div', cellStyle, [
              h('span', 'Last generated at'),
              h('span', datetime.format(wordCloud.generatedAt, 'MMM DD, YYYY, hh:mm A')),
            ]),
            courseRole !== 'student'
              ? h('div', cellStyle, [
                  h('span', 'Comments used'),
                  h('span', wordCloud.commentsUsed.toString()),
                ])
              : null,
            courseRole !== 'student'
              ? h('div', cellStyle, [
                  h('span', 'Total Comments'),
                  h('span', discussion.activities.numCommentsTotal.toString()),
                ])
              : null,
            courseRole !== 'student'
              ? h('div', style(['flex', 'spaceBetween', pad('1em')]), [
                  h('span', 'Unique words'),
                  h('span', wordCloud.wordList.length.toString()),
                ])
              : null,
          ]
        ),
        this.wordDetailDialog(),
      ]
    );
  }

  private showWordDetailDialog() {
    const wordCloud = getStore().getState().discussions.wordCloud!;
    this.updateWordList(wordCloud);
    this.setState({
      isWordDetailDialogVisible: true,
    });
  }

  private generateButton(label: string) {
    const isRegenerating = this.getState().isRegenerating;
    const buttonStyle = style(
      ['fullWidth', pad('1rem'), margin('0.5em 0 0'), 'pointer', 'blue', 'medium', 'relative'],
      {
        background: colors.white,
        border: 'none',
        height: '4em',
      }
    );
    return isRegenerating
      ? h('div', style([buttonStyle.style]), [
          Loader({
            marginLeft: 'auto',
            marginRight: 'auto',
          }),
        ])
      : h(
          'button.ripple',
          {
            style: buttonStyle.style,
            onclick: () => this.generateWordCloud(),
          },
          label
        );
  }

  private wordDetailDialog() {
    const wordCloud = getStore().getState().discussions.wordCloud!;

    const wordCell = (word: Word) => {
      return h(
        'div.word-cell',
        style(['flex', 'spaceBetween', 'alignCenter', pad('1em')], {
          background: colors.white,
          color: word.isRemoved ? colors.lightGrey : undefined,
          borderBottom: `1px solid ${colors.lightestGrey}`,
        }),
        [
          h('div', { style: { flex: '1' } }, [
            h('div.word', style([mb('0.5em'), 'large']), word.text),
            word.isRemoved
              ? h('div.word-details', style(['small']), `removed from word cloud`)
              : h(
                  'div.word-details',
                  style(['small']),
                  `Occurs ${word.freq} time${word.freq! > 1 ? 's' : ''}`
                ),
          ]),
          word.isRemoved
            ? Icon(icons.plus, {
                tabIndex: 0,
                style: style(['large', 'blue', 'pointer', pad('0.25em')]).style,
                onclick: () => this.addOrRemoveWord(word, false),
              })
            : Icon(icons.cross, {
                tabIndex: 0,
                style: style(['large', 'red', 'pointer', pad('0.25em')]).style,
                onclick: () => this.addOrRemoveWord(word, true),
              }),
        ]
      );
    };

    return Dialog(
      {
        open: this.getState().isWordDetailDialogVisible,
        title: 'Words Used',
        style: {
          width: '40em',
          padding: '0em',
          paddingBottom: '1em',
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          padding: '0em',
          paddingLeft: '0em',
          paddingRight: '0em',
        },
        primaryAction: {
          label: 'SAVE',
          disabled: !this.getState().isRemovedWordUpdated,
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.saveRemovedWords(),
        },
        secondaryAction: {
          label: 'Cancel',
          onclick: () => this.cancelRemovedWordsUpdate(),
          mobileLabel: h('i.fa.fa-times', []),
        },
      },
      [
        h('div', style(['flex', 'spaceBetween', pad('1em')]), [
          h('span', 'Unique words'),
          h('span', wordCloud.wordList.length.toString()),
        ]),
        h('div', this.getState().wordList.map(wordCell)),
      ]
    );
  }

  private async generateWordCloud() {
    const discussionId = this.getProps().discussion._id;
    await this.setState({
      isRegenerating: true,
      errorMsg: '',
    });
    await dispatch(Actions.generateWordCloud(discussionId)).then((result) => {
      if (result.status === 'failed') {
        this.setState({
          errorMsg: result.message,
          isRegenerating: false,
        });
      } else {
        this.setState({
          isRegenerating: false,
        });
      }
    });
  }

  private addOrRemoveWord(word: Word, isRemoved: boolean) {
    this.setState({
      isRemovedWordUpdated: true,
      wordList: this.getState().wordList.map((w) => (w === word ? { ...w, isRemoved } : w)),
    });
  }

  private async cancelRemovedWordsUpdate() {
    this.init(this.getProps().discussion);
  }

  private async saveRemovedWords() {
    if (this.getState().isRemovedWordUpdated) {
      const discussion = this.getProps().discussion;
      const removedWords = this.getState()
        .wordList.filter((w) => w.isRemoved)
        .map((w) => w.text);
      await dispatch(Actions.editWordCloud(discussion._id, removedWords));
      this.setState({
        isWordDetailDialogVisible: false,
      });
    }
  }
}

export default (discussion: IDiscussion) => h(DiscussionWordCloud, { discussion });

export function wordCloudIcon(highlight = false, size = '1.5rem') {
  return h(
    'span',
    {
      style: {
        display: 'inline-block',
        width: size,
        height: size,
        position: 'relative',
      },
    },
    [
      SvgIcon({ icon: WordCloudIcon }),
      highlight
        ? h(
            'i.fa.fa-star',
            style(['orange', 'x-small', 'absolute'], {
              top: '-6px',
              right: '-6px',
            })
          )
        : null,
    ]
  );
}
