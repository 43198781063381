/**
 * This component is used in timeline items, ,which is one of
 * the biggest performance bottlenecks of the application,
 * so, avoid allocating memory during rendering.
 * Prefer moving objects outside the DateView function and
 * using CSS for styling.
 */
import { h } from 'core';

import { colors, style } from 'acadly/styles';
import * as u from 'acadly/utils';

const _colors = u.exchangeKeyValues(colors);
const MONTH_ATTRS_CACHE = u.mapValues(_colors, (_, color) =>
  style([], {
    backgroundColor: color,
  })
);
const DATE_ATTRS_CACHE = u.mapValues(_colors, (_, color) =>
  style([], {
    color: color,
  })
);
const DAY_ATTRS_CACHE = u.mapValues(_colors, (_, color) =>
  style([], {
    color: color,
  })
);
const DATE_VIEW_SELECTOR = 'div.date-view.date-view.date-view--compact';
const DATE_MONTH_SELECTOR = 'div.date-view__month';
const DATE_DATE_SELECTOR = 'div.date-view__date';
const DATE_DAY_SELECTOR = 'div.date-view__day';

export default (_date: Date, color: string, selector = DATE_VIEW_SELECTOR) => {
  const { date, day, month } = getDateComponents(_date);
  return h(selector, [
    h(DATE_MONTH_SELECTOR, MONTH_ATTRS_CACHE[color], month),
    h(DATE_DATE_SELECTOR, DATE_ATTRS_CACHE[color], date),
    h(DATE_DAY_SELECTOR, DAY_ATTRS_CACHE[color], day),
  ]);
};

const MONTH_NAMES = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const DAY = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

/**
 * Array of padded date strings
 */
const DATE = Array.from(u.range(0, 32)).map((x) => u.padStart(x.toString(), 2, '0'));

/**
 * Get formatted Date, Month and Day for a given date.
 * Intentionally avoided calling date-fns/format for
 * performance reasons.
 */
function getDateComponents(_date: Date) {
  const result = {
    month: MONTH_NAMES[_date.getMonth()],
    day: DAY[_date.getDay()],
    date: DATE[_date.getDate()],
  };
  return result;
}
