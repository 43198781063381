import * as Pusher from 'pusher-js';
import { Channel } from 'pusher-js';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Subject } from 'rxjs/Subject';

import { jsonRequest } from 'core/http';

import { Actions as AnnouncementActions } from 'acadly/announcement/actions';
import { api as urls } from 'acadly/api';
import { Actions as AppActions } from 'acadly/app/actions';
import * as AssignmentActions from 'acadly/assignment/actions';
import { Actions as ClassActions } from 'acadly/class/actions';
import { Actions as CommentActions } from 'acadly/comments/actions';
import { Actions as CourseActions } from 'acadly/course/actions';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import { Actions as DiscussionActions } from 'acadly/discussion/actions';
import { Actions as GetInActions } from 'acadly/getin/actions';
import { logger } from 'acadly/logger';
import { Actions as PollActions } from 'acadly/poll/actions';
import { Actions as QueryActions } from 'acadly/query/actions';
import { Actions as QuizActions } from 'acadly/quiz/actions';
import { Actions as ResourceActions } from 'acadly/resource/actions';
import { dispatch, getStore } from 'acadly/store';
import getAvatarUrl from 'acadly/utils/avatar';

export interface IPusherMessageBase {
  msgId: string;
  courseId: string;
  universityId: string;
  classId?: string;
  activityId?: string;
  goToView?: 'timeline' | 'classPage' | 'poll' | 'quiz' | 'discussion' | 'assignment' | 'resource';
  message?: string;
  timestamp: UnixTimestamp; // added after receiving message in this file
  sender: {
    userId: string;
    name: string;
    avatar: string;
    role: 'student' | 'admin' | 'instructor' | 'ta';
  };
}

export type IPusherPayload<T> = IPusherMessageBase & T;
export interface PusherEvent<E extends string = string, T = any> {
  event: E;
  payload: T;
}

interface PusherBindFnConfig {
  /** show notification */
  notify: boolean;
  /** forward to PusherService.events Subject */
  forward: boolean;
}

export class PusherService {
  public events = new Subject<PusherEvent>();

  private client: Pusher.Pusher;
  private registerPromise?: Promise<any>;

  public start() {
    const storeState = getStore().getState();
    logger.log('Starting pusher connection...');
    if (!(<any>window).Pusher) {
      return;
    }
    const client = new Pusher(storeState.getIn.session!.socket.key, {
      encrypted: true,
      cluster: storeState.getIn.session!.socket.cluster,
      authEndpoint: storeState.getIn.server! + '/pusher/auth',
      auth: {
        headers: {
          Authorization: storeState.getIn.session!.token,
        },
      },
    });
    this.client = client;
    this.registerPromise = jsonRequest(urls().registerUser, {
      method: 'POST',
      data: {
        agent: 'web',
        cloudId: 'abc',
      },
    });
  }

  public async connectUserChannel() {
    const channel = 'private-user-' + getStore().getState().getIn.session!.userId;

    const ch = await this.subscribeToChannel(channel);
    const bind = this.getBindFunctionForChannel(ch);

    bind('attendanceScheduledToStart', AppActions.attendanceScheduledToStart);
    bind('attendeeAvailable', ClassActions.attendeeAvailable);
    bind('attendeeFailure', ClassActions.attendeeFailure);
    bind('attendanceMarked', ClassActions.attendanceMarked);

    bind('attendanceEdited', ClassActions.updateAttendance);
    bind('classInChargeSet', ClassActions.pusherInChargeSet);
    bind('classAssistantsAdded', ClassActions.pusherAssistantsAdded);
    bind('classCheckIn', ClassActions.pusherCheckIn);
    bind('activityUpdated', ClassActions.pusherActivityUpdated);
    bind('participantJoined', AppActions.addVCallParticipant, {
      forward: true,
      notify: false,
    });
    bind('participantLeft', AppActions.removeVCallParticipant, {
      forward: true,
      notify: false,
    });
    bind('zoomAuthenticated', AppActions.zoomUserAuthorized, {
      forward: true,
      notify: false,
    });
    bind('readyToBroadcast', AppActions.readyToBroadcast, {
      forward: true,
      notify: false,
    });
    bind('broadcasting', ClassActions.onlineMeetingBroadcasting, {
      forward: true,
      notify: false,
    });
    bind('broadcastStopped', ClassActions.onlineMeetingBroadcastEnded, {
      forward: true,
      notify: false,
    });
    bind('meetingDestroyed', ClassActions.onlineMeetingDestroyed, {
      forward: true,
      notify: false,
    });
    bind('handRaised', AppActions.handRaised, {
      forward: true,
      notify: true,
    });
    bind('handLowered', AppActions.handLowered, {
      forward: true,
      notify: true,
    });
    bind('studentPurchaseSuccessful', CourseActions.studentPurchaseSuccessfulPusher, {
      forward: false,
      notify: false,
    });
    /**
     * Will be received here only when comment context is discussion and
     * discussionPrefs.hideAwards is set to 1
     */
    bind('commentMarked', CommentActions.pusherMark);
  }

  private async subscribeToChannel(name: string) {
    if (!this.registerPromise) {
      throw new Error('Tried to connect to channel before starting pusher');
    }

    await this.registerPromise;
    logger.log(`Connecting to channel (${name})...`);

    const ch = this.client.subscribe(name);

    ch.bind('pusher:subscription_error', (status: number) => {
      if (status === 403) {
        this.client.disconnect();
        dispatch(
          AppActions.showError({
            message: 'You have been logged out. Please log in again.',
            onclose: () => dispatch(GetInActions.logout(true)),
          })
        );
      }
    });

    return ch;
  }

  public async disconnectUserChannel() {
    if (this.registerPromise) {
      await this.registerPromise;
      const channel = 'private-user-' + getStore().getState().getIn.session!.userId;
      logger.log(`Disconnecting from user channel (${channel})...`);
      this.client.unsubscribe(channel);
    }
  }

  private courseChannelConnected: string | null = null;
  public async connectCourseChannel() {
    if (this.isArchived()) return;
    const channel = 'private-course-' + getStore().getState().courses.currentCourseId!;

    const ch = await this.subscribeToChannel(channel);
    const bind = this.getBindFunctionForChannel(ch);

    bind('web-attendanceWarning', ClassActions.receiveAttendanceData, { forward: true });
    bind('commentAdded', CommentActions.pusherCommentAdded, { forward: true });
    bind('announcementAdded', AnnouncementActions.pusherAdd);
    bind('assignmentPublished', AssignmentActions.pusherPublish);
    bind('classAdded', ClassActions.pusherAdd);
    bind('discussionPublished', DiscussionActions.pusherPublish);
    bind('wordCloudGenerated', DiscussionActions.wordCloudGenerated, {
      notify: false,
    });
    bind('wordCloudAvailable', DiscussionActions.wordCloudAvailable, {
      notify: false,
    });
    bind('quizPublished', QuizActions.pusherPublish);
    bind('pollPublished', PollActions.pusherPublish);
    bind('pollEdited', PollActions.pusherPollEdited);
    bind('classVenueChanged', ClassActions.pusherVenueChanged);
    bind('classTimingsChanged', ClassActions.pusherTimingsChanged);
    bind('resourcePublished', ResourceActions.pusherPublish);
    bind('queryAdded', QueryActions.pusherCreate, {
      forward: true,
    });
    bind('queryApproved', QueryActions.pusherApproved);
    bind('queryUpvoted', QueryActions.pusherUpvote);
    bind('pollSubmitted', PollActions.pusherSubmit);
    bind('commentRemoved', CommentActions.pusherRemove);
    bind('commentMarked', CommentActions.pusherMark);
    bind('activityStopped', ClassActions.activityStopped, {
      notify: false,
      forward: true,
    });
    bind('meetingStarted', ClassActions.onlineMeetingStarted, {
      notify: false,
      forward: true,
    });
    bind('meetingEnded', ClassActions.onlineMeetingEnded, {
      notify: false,
      forward: true,
    });
    bind('addressingHand', AppActions.addressingHandPusher, {
      notify: true,
      forward: true,
    });
    bind('handResolved', AppActions.handResolvedPusher, {
      notify: false,
      forward: true,
    });
    bind('meetingRecordingsPublished', ClassActions.zoomRecordingsPublished);
    bind('remoteAttendanceStarted', ClassActions.remoteAttendanceStarted, {
      notify: false,
      forward: true,
    });
    bind('remoteAttendanceStopped', ClassActions.remoteAttendanceStopped, {
      notify: false,
      forward: true,
    });

    this.courseChannelConnected = channel;
  }

  private getBindFunctionForChannel(ch: Channel) {
    return (
      e: string,
      actionCreator: ActionCreator<any> | ((data: any) => ThunkAction<any, any, any>),
      _config: Partial<PusherBindFnConfig> = {}
    ) => {
      const config: PusherBindFnConfig = {
        forward: false,
        notify: true,
        ..._config,
      };

      ch.bind(e, (data: any) => {
        if (config.forward) {
          this.events.next({ event: e, payload: data });
        }
        console.log('Received pusher message: ', e, data);
        dispatch(
          (<any>actionCreator)({
            ...(<any>data),
            timestamp: dt.unix(),
          })
        );

        const state = getStore().getState();
        const session = state.getIn.session;

        if (!config.notify || (session && data.sender.userId === session.userId)) {
          return; // don't show notification
        }

        this.showInAppNotification(data);
      });
    };
  }

  public async disconnectCourseChannel() {
    if (this.registerPromise) {
      await this.registerPromise;
      const channel = this.courseChannelConnected;
      if (!channel) return;
      logger.log(`Disconnecting from course channel (${channel})...`);
      this.client.unsubscribe(channel);
      this.courseChannelConnected = null;
    }
  }

  public isCourseChannelConnected() {
    return this.courseChannelConnected !== null;
  }

  public isTeamChannelConnected() {
    return this.teamChannelConnected !== null;
  }

  private teamChannelConnected: string | null = null;
  public async connectTeamChannel() {
    if (this.isArchived()) return;
    const channel = 'private-courseTeam-' + getStore().getState().courses.currentCourseId!;
    const ch = await this.subscribeToChannel(channel);
    this.teamChannelConnected = channel;

    const bind = this.getBindFunctionForChannel(ch);
    bind('approveQuery', QueryActions.pusherAnonQueryAdded);
    bind('quizSubmitted', QuizActions.pusherSubmitted);
    bind('assignmentSubmitted', AssignmentActions.pusherSubmitted);
    bind('submissionRetracted', AssignmentActions.pusherRetracted);
    bind('classTeamUpdated', ClassActions.classTeamEdited);
    bind('remoteAttendanceResponse', ClassActions.remoteAttendanceResponse, {
      notify: false,
      forward: true,
    });
    /**
     * Will be received here only when comment context is discussion and
     * discussionPrefs.hideAwards is set to 1
     */
    bind('commentMarked', CommentActions.pusherMark);
    /**
     * Will be received here only when comment context is discussion and
     * discussionPrefs.anonymity is set to 'students'
     */
    bind('commentAdded', CommentActions.pusherCommentAdded, { forward: true });
  }

  public async disconnectTeamChannel() {
    if (this.registerPromise) {
      await this.registerPromise;
      const channel = this.teamChannelConnected;
      if (!channel) return;
      logger.log(`Disconnecting from team channel (${channel})...`);
      this.client.unsubscribe(channel);
      this.teamChannelConnected = null;
    }
  }

  public isArchived() {
    const course = courseService.getCurrentCourse();
    if (course) {
      return course.isArchived;
    }
    return true;
  }

  private async showInAppNotification(data: IPusherMessageBase) {
    if (!data.message) return;

    await dispatch(GetInActions.fetchAvatars([data.sender.avatar]));

    dispatch(
      AppActions.notificationShow({
        msgId: data.msgId,
        message: data.message,
        goToView: data.goToView,
        avatar: data.sender.avatar,
        courseId: data.courseId,
        classId: data.classId,
        activityId: data.activityId,
      })
    );

    setTimeout(() => dispatch(AppActions.notificationClear(data.msgId)), 15e3);
  }

  private notificationSound = new Audio(
    'https://s3.amazonaws.com/static.acad.ly/new/notification.m4a'
  );

  private async showWebNotification(data: IPusherMessageBase) {
    if (!data.message) return;

    await dispatch(GetInActions.fetchAvatars([data.sender.avatar]));

    const notification = new Notification('Acadly', {
      icon: getAvatarUrl(data.sender.avatar),
      body: data.message,
      tag: data.sender.userId,
    });

    this.notificationSound.play();

    notification.onclick = () => {
      window.focus();
      notification.close();
    };

    setTimeout(() => notification.close(), 15e3);
  }
}

export const pusherService = new PusherService();
