import cn from 'classnames';

import { h, HTMLAttrs, View } from 'core';

import { getStore } from 'acadly/store';

import Toggle from './Toggle';

interface IToggleCellProps extends HTMLAttrs {
  label: string;
  disabled?: boolean;
  helperText?: View;
  isSelected: boolean;
  onToggle: (isSelected: boolean) => void;
  variant?: 'danger' | 'info';
}

export default ({
  label,
  disabled,
  helperText,
  isSelected,
  onToggle,
  variant,
  ...attrs
}: IToggleCellProps) => {
  const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
  return h('div', [
    h(
      'div.toggle-cell',
      {
        tabIndex: isAccessible ? 0 : undefined,
        ...attrs,
      },
      [
        h('span', label),
        Toggle({
          disabled,
          isAccessible,
          selected: isSelected,
          ontoggle: onToggle,
        }),
      ]
    ),
    helperText
      ? h(
          'div.toggle-cell__helper-text',
          {
            className: cn({ 'toggle-cell__helper-text_danger': variant === 'danger' }),
            tabIndex: isAccessible ? 0 : undefined,
          },
          [helperText]
        )
      : null,
  ]);
};
