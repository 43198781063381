import { uuid } from './uuid';

interface CreateOptions {
  tag: string;
  logglyKey: string;
  sendConsoleErrors?: boolean;
}

interface Options {
  readonly logglyCollectorDomain: string;
  readonly sendConsoleErrors: boolean;
  readonly tag: string;
  readonly useUtfEncoding: boolean;
  readonly useDomainProxy: boolean;
  readonly logglyKey: string;
  readonly sessionId: string;
}

interface PushData extends Partial<Options> {
  [key: string]: unknown;
}

export class LogglyTracker implements Options {
  private static defaultTracker: LogglyTracker | undefined = undefined;

  private static LOGGLY_INPUT_PREFIX =
    'http' + ('https:' === document.location.protocol ? 's' : '') + '://';

  private static LOGGLY_COLLECTOR_DOMAIN = 'logs-01.loggly.com';
  private static LOGGLY_PROXY_DOMAIN = 'loggly';

  private static LOGGLY_SESSION_KEY = 'logglytrackingsession';
  private static LOGGLY_SESSION_KEY_LENGTH = LogglyTracker.LOGGLY_SESSION_KEY.length + 1;

  public sessionId: string | undefined;
  public inputUrl: string;

  public logglyKey: string | undefined;
  public tag: string | undefined;

  public useDomainProxy: boolean;
  public logglyCollectorDomain: string | undefined;

  public useUtfEncoding: boolean;
  public sendConsoleErrors: boolean;

  public static createDefault({ tag, logglyKey, sendConsoleErrors = true }: CreateOptions) {
    if (!LogglyTracker.defaultTracker) {
      LogglyTracker.defaultTracker = new LogglyTracker();
    }

    LogglyTracker.defaultTracker.push({
      tag,
      logglyKey,
      sendConsoleErrors,
    });

    return LogglyTracker.defaultTracker;
  }

  public constructor() {
    this.logglyKey = undefined;
    this.sendConsoleErrors = false;
    this.tag = 'acadly-web';
    this.useDomainProxy = false;
    this.useUtfEncoding = false;
  }

  private setKey(key: string) {
    this.logglyKey = key;
    this.setSession();
    this.setInputUrl();
  }

  private setTag(tag: string) {
    this.tag = tag;
  }

  private setDomainProxy(useDomainProxy: boolean) {
    this.useDomainProxy = useDomainProxy;
    // refresh inputUrl value
    this.setInputUrl();
  }

  private setUtfEncoding(useUtfEncoding: boolean) {
    this.useUtfEncoding = useUtfEncoding;
  }

  private sendConsoleError: OnErrorEventHandlerNonNull = (msg, url, line, col, err) => {
    const _onerror = window.onerror !== this.sendConsoleError ? window.onerror : undefined;

    this.push({
      category: 'BrowserJsException',
      exception: {
        message: msg,
        url: url,
        lineno: line,
        colno: col,
        stack: err ? err.stack : 'n/a',
      },
    });

    if (_onerror && typeof _onerror === 'function') {
      _onerror.call(window, msg, url, line, col, err);
    }
  };

  private setSendConsoleError(sendConsoleErrors: boolean) {
    this.sendConsoleErrors = sendConsoleErrors;

    if (this.sendConsoleErrors === true) {
      // send console error messages to Loggly
      window.onerror = this.sendConsoleError;
    }
  }

  private setInputUrl() {
    if (this.useDomainProxy == true) {
      this.inputUrl =
        LogglyTracker.LOGGLY_INPUT_PREFIX +
        window.location.host +
        '/' +
        LogglyTracker.LOGGLY_PROXY_DOMAIN +
        '/inputs/' +
        this.logglyKey +
        '/tag/' +
        this.tag;
    } else {
      this.inputUrl =
        LogglyTracker.LOGGLY_INPUT_PREFIX +
        (this.logglyCollectorDomain || LogglyTracker.LOGGLY_COLLECTOR_DOMAIN) +
        '/inputs/' +
        this.logglyKey +
        '/tag/' +
        this.tag;
    }
  }

  private setSession(sessionId?: string) {
    if (sessionId) {
      this.sessionId = sessionId;
      this.setCookie(this.sessionId);
    } else if (!this.sessionId) {
      this.sessionId = this.readCookie();
      if (!this.sessionId) {
        this.sessionId = uuid();
        this.setCookie(this.sessionId);
      }
    }
  }

  public push(data: string | PushData) {
    let trackerData: Record<string, unknown>;

    switch (typeof data) {
      case 'string':
        trackerData = { text: data };
        break;
      case 'object': {
        const {
          sessionId,
          logglyCollectorDomain,
          logglyKey,
          sendConsoleErrors,
          tag,
          useUtfEncoding,
          useDomainProxy,
          ...restData
        } = data;

        if (logglyCollectorDomain) {
          this.logglyCollectorDomain = logglyCollectorDomain;
          return;
        }

        if (sendConsoleErrors !== undefined) {
          this.setSendConsoleError(sendConsoleErrors);
        }

        if (tag) {
          this.setTag(tag);
        }

        if (useUtfEncoding !== undefined) {
          this.setUtfEncoding(useUtfEncoding);
        }

        if (useDomainProxy) {
          this.setDomainProxy(useDomainProxy);
        }

        if (logglyKey) {
          this.setKey(logglyKey);
          return;
        }

        if (sessionId) {
          this.setSession(sessionId);
          return;
        }

        trackerData = restData;
        break;
      }
      default:
        break;
    }

    if (!this.logglyKey) {
      return;
    }

    this.track(trackerData);
  }

  private track(data: Record<string, unknown>) {
    // inject session id
    data.sessionId = this.sessionId;

    // inject user agaent
    data.agent = window.navigator.userAgent;

    // inject app version
    data.version = process.env.APP_VERSION;

    try {
      // creating an asynchronous XMLHttpRequest
      const xmlHttp = new XMLHttpRequest();

      xmlHttp.open('POST', this.inputUrl, true); // true for asynchronous request

      if (this.useUtfEncoding === true) {
        xmlHttp.setRequestHeader('Content-Type', 'text/plain; charset=utf-8');
      } else {
        xmlHttp.setRequestHeader('Content-Type', 'text/plain');
      }

      xmlHttp.send(JSON.stringify(data));
    } catch (ex) {
      if (window && window.console && typeof window.console.log === 'function') {
        console.log('Failed to log to loggly because of this exception:\n' + ex);
        console.log('Failed log data:', data);
      }
    }
  }

  /**
   *  These cookie functions are not a global utilities.  It is for purpose of this tracker only
   */
  private readCookie() {
    const cookie = document.cookie,
      i = cookie.indexOf(LogglyTracker.LOGGLY_SESSION_KEY);
    if (i < 0) {
      return undefined;
    } else {
      let end = cookie.indexOf(';', i + 1);
      end = end < 0 ? cookie.length : end;
      return cookie.slice(i + LogglyTracker.LOGGLY_SESSION_KEY_LENGTH, end);
    }
  }

  private setCookie(value: string) {
    document.cookie = LogglyTracker.LOGGLY_SESSION_KEY + '=' + value;
  }
}

export default LogglyTracker.createDefault({
  logglyKey: '50013aa3-0c5a-4d98-b1b5-83317c459e39',
  sendConsoleErrors: false,
  tag: 'acadly-web-frontend',
});
