import { h } from 'core';

import { style } from 'acadly/styles';

export const CALENDAR_DATE_WIDTH = '2rem';

export function CalendarWeekLabels() {
  return h(
    'div.week-days-header',
    style(['flex', 'small', 'spaceAround']),
    ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((label, i) =>
      h(
        'div',
        style(
          [
            i === 0 ? 'orange' : 'grey',
            'borderBox',
            'textCenter',
            {
              width: CALENDAR_DATE_WIDTH,
              padding: '0.5rem 0rem',
            },
          ],
          {},
          {
            key: `week-label-${i}`,
          }
        ),
        label
      )
    )
  );
}
