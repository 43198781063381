import { h, IComponent } from 'core';

import * as CloseIcon from 'assets/close.svg';

import Alert from 'acadly/common/Alert';
import SvgIcon from 'acadly/common/SvgIcon';
import { pad, style } from 'acadly/styles';

import { CourseTypeStep } from './CourseTypeStep';
import { InstructorPaysStep } from './InstructorPaysStep';
import { InstructorPaysThanksStep } from './InstructorPaysThanksStep';
import { IntroStep } from './IntroStep';
import { StudentPaysStep } from './StudentPaysStep';
import { ICourseType, IPaymentMethod } from './types';

type Steps =
  | 'intro'
  | 'course_type'
  | 'instructor_pays'
  | 'student_pays'
  | 'instructor_pays_thanks';

interface IPreCreateCourseProps {
  open: boolean;
  onClose: (
    /** if `undefined` don't proceed to create course screens  */
    data?: { courseType: ICourseType }
  ) => any;
}

interface IPreCreateCourseState {
  currentStep: Steps;
  courseType: ICourseType;
  paymentMethod: IPaymentMethod;
}

export class PreCreateCourse extends IComponent<IPreCreateCourseProps, IPreCreateCourseState> {
  private introStepRef?: IntroStep;
  private courseTypeStepRef?: CourseTypeStep;
  private instructorPaysStepRef?: InstructorPaysStep;
  private instructorPaysThanksStepRef?: InstructorPaysThanksStep;
  private studentPaysStepRef?: StudentPaysStep;

  private async init() {
    const initialState: IPreCreateCourseState = {
      currentStep: 'intro',
      courseType: 'pro',
      paymentMethod: 'student_pays',
    };
    this.setState(initialState);
  }

  public componentWillMount() {
    this.init();
  }

  public componentDidUpdate(lastProps: IPreCreateCourseProps) {
    const props = this.getProps();

    if (props.open === true && lastProps.open === false) {
      // dialog is opened
      this.init();
    }
  }

  private getTitle() {
    const { currentStep } = this.getState();

    switch (currentStep) {
      case 'intro':
        return IntroStep.getTitle();
      case 'course_type':
        return CourseTypeStep.getTitle();
      case 'instructor_pays':
        return InstructorPaysStep.getTitle();
      case 'instructor_pays_thanks':
        return InstructorPaysThanksStep.getTitle();
      case 'student_pays':
        return StudentPaysStep.getTitle();
      default:
        return 'Create course';
    }
  }

  private getBody() {
    const { onClose } = this.getProps();
    const { currentStep, courseType } = this.getState();

    switch (currentStep) {
      case 'intro':
        return h(IntroStep, {
          getInstance: (ref) => {
            this.introStepRef = ref;
          },
          onNext: () => {
            this.setState({ currentStep: 'course_type' });
          },
        });
      case 'course_type':
        return h(CourseTypeStep, {
          getInstance: (ref) => {
            this.courseTypeStepRef = ref;
          },
          onBack: () => {
            this.setState({ currentStep: 'intro' });
          },
          onNext: ({ courseType, paymentMethod, isFree }) => {
            if (isFree) {
              onClose({ courseType });
              return;
            }

            this.setState({ courseType, paymentMethod });

            if (paymentMethod === 'instructor_pays') {
              this.setState({
                currentStep: 'instructor_pays',
              });
            } else {
              this.setState({
                currentStep: 'student_pays',
              });
            }
          },
        });
      case 'instructor_pays':
        return h(InstructorPaysStep, {
          getInstance: (ref) => {
            this.instructorPaysStepRef = ref;
          },
          onBack: () => {
            this.setState({
              currentStep: 'course_type',
            });
          },
          onNext: () => {
            this.setState({
              currentStep: 'instructor_pays_thanks',
            });
          },
        });
      case 'student_pays':
        return h(StudentPaysStep, {
          getInstance: (ref) => {
            this.studentPaysStepRef = ref;
          },
          onBack: () => {
            this.setState({
              currentStep: 'course_type',
            });
          },
          onNext: () => {
            onClose({ courseType });
          },
        });
      case 'instructor_pays_thanks':
        return h(InstructorPaysThanksStep, {
          getInstance: (ref) => {
            this.instructorPaysThanksStepRef = ref;
          },
          onClose: () => {
            onClose();
          },
        });
      default:
        return undefined;
    }
  }

  public render() {
    const { open, onClose } = this.getProps();

    return Alert(
      {
        open,
        title: h(
          'div',
          style(['flex', 'alignCenter', 'spaceBetween', 'bold', 'fullWidth', pad('0.5rem 1rem')]),
          [
            this.getTitle(),
            SvgIcon({
              component: 'button',
              icon: CloseIcon,
              style: {
                cursor: 'pointer',
                width: 24,
                height: 24,
              },
              onclick: () => {
                onClose();
              },
            }),
          ]
        ),
        titleStyle: {
          borderBottom: '1px solid #bbb',
        },
        style: {
          padding: 0,
          width: '35em',
        },
        bodyStyle: {
          padding: '1rem',
        },
      },
      [h('div.pre-create-course', [this.getBody()])]
    );
  }
}

export default (props: IPreCreateCourseProps) => h(PreCreateCourse, props);
