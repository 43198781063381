import { Actions as ClassActions } from 'acadly/class/actions';
import classService from 'acadly/class/service';
import { Actions as CourseActions } from 'acadly/course/actions';
import { getCourseRole } from 'acadly/course/functions';
import courseService from 'acadly/course/service';
import { createAction, Thunk } from 'acadly/createAction';
import { IPusherPayload } from 'acadly/pusher';
import { Routes } from 'acadly/routes';

import * as api from './api';

export type IQueryActionMap = {
  '@query/ANALYTICS_SET_SUCCESS': IAnalyticsSetPayload;
  '@query/UPVOTE_SUCCESS': IUpvotePayload;
  '@query/CLOSE_SUCCESS': IClosePayload;
  '@query/HIDE_SUCCESS': IHidePayload;
  '@query/CREATE_SUCCESS': ICreatePayload;
  '@query/all/FETCH_SUCCESS': IFetchAllSuccessPayload;
  '@query/pusher/CREATE': IPusherCreatePayload;
  '@query/pusher/UPVOTE': IPusherUpvotePayload;
  '@query/pusher/APPROVED': IPusherCreatePayload;
  '@query/pusher/ANON_QUERY_RAISED': IPusherAnonQueryRaisedPayload;
  '@query/APPROVE_SUCCESS': IApproveSuccessPayload;
  '@query/REMOVE_SUCCESS': IRemoveSuccessPayload;
};

export type IRemoveSuccessPayload = api.IRemoveRequest;

export type IPusherAnonQueryRaisedPayload = IPusherCreatePayload;
export type IApproveSuccessPayload = api.IApproveRequest & {
  classId: string;
  toBeDone: 'preClass' | 'inClass' | 'review';
  status: 'pending' | 'closed' | 'open';
};

export type IPusherUpvotePayload = IPusherPayload<{
  queryId: string;
}>;

export type IPusherCreatePayload = IPusherPayload<{
  classId: string;
  queryId: string;
  details: IResponseQueryDetails;
  stats: { numAskers: number };
  activities: {
    numCommentsTotal: number;
    numCommentsCourseTeam: number;
  };
}>;

export type IFetchAllSuccessPayload = api.IFetchAllResponse;

export type ICreatePayload =
  | {
      isAnon: 0;
      response: api.ICreateResponse<0>;
      classId: string;
    }
  | {
      isAnon: 1;
    };
export type IClosePayload = {
  queryId: string;
  classId: string;
  toBeDone: 'preClass' | 'inClass' | 'review';
};
export type IHidePayload = string;
export type IUpvotePayload = string;

export interface IAnalyticsSetPayload {
  queryId: string;
  classId: string;
  toBeDone: 'preClass' | 'inClass' | 'review';
}

export type ICreateResponse =
  | {
      isAnon: 1;
    }
  | {
      isAnon: 0;
      query: IResponseQuery;
    };

export const Actions = {
  analyticsSetSuccess: createAction('@query/ANALYTICS_SET_SUCCESS'),
  analyticsSet:
    (data: {
      request: api.IAnalyticsSetRequest;
      classId: string;
      toBeDone: 'preClass' | 'inClass' | 'review';
    }): Thunk<void> =>
    async (dispatch) => {
      await api.analyticsSet(data.request);
      dispatch(
        Actions.analyticsSetSuccess({
          queryId: data.request.queryId,
          classId: data.classId,
          toBeDone: data.toBeDone,
        })
      );
    },

  upvoteSuccess: createAction('@query/UPVOTE_SUCCESS'),
  upvote:
    (queryId: string): Thunk<void> =>
    async (dispatch) => {
      await api.upvote(queryId);
      dispatch(Actions.upvoteSuccess(queryId));
    },

  closeSuccess: createAction('@query/CLOSE_SUCCESS'),
  close:
    (payload: IClosePayload): Thunk<void> =>
    async (dispatch) => {
      await api.close(payload.queryId);
      dispatch(Actions.closeSuccess(payload));
    },

  hideSuccess: createAction('@query/HIDE_SUCCESS'),
  hide:
    (queryId: string): Thunk<void> =>
    async (dispatch) => {
      await api.hide(queryId);
      dispatch(Actions.hideSuccess(queryId));
    },

  createSuccess: createAction('@query/CREATE_SUCCESS'),
  create:
    (data: api.ICreateRequest<0 | 1>): Thunk<ICreateResponse> =>
    async (dispatch) => {
      const response = await api.create(data);
      if (data.isAnon) {
        dispatch(
          Actions.createSuccess({
            isAnon: 1,
          })
        );
        return {
          isAnon: 1,
        };
      } else {
        const responseQuery = <api.ICreateResponse<0>>response.data;
        dispatch(
          Actions.createSuccess({
            isAnon: 0,
            response: responseQuery,
            classId: data.classId,
          })
        );
        return {
          isAnon: 0,
          query: responseQuery,
        };
      }
    },

  fetchAllSuccess: createAction('@query/all/FETCH_SUCCESS'),
  fetchAll: (): Thunk<string[]> => async (dispatch) => {
    const response = await api.fetchAll();
    dispatch(Actions.fetchAllSuccess(response.data));
    return response.data.activityData.map((q) => q._id);
  },

  pusherCreate: createAction('@query/pusher/CREATE'),
  pusherAnonQueryAdded: createAction('@query/pusher/ANON_QUERY_RAISED'),
  pusherUpvote: createAction('@query/pusher/UPVOTE'),

  approveSuccess: createAction('@query/APPROVE_SUCCESS'),
  approve:
    (data: IApproveSuccessPayload): Thunk<void> =>
    async (dispatch) => {
      await api.approve({
        queryId: data.queryId,
      });
      dispatch(Actions.approveSuccess(data));
    },

  removeSuccess: createAction('@query/REMOVE_SUCCESS'),
  remove:
    (
      queryId: string,
      options: {
        beforeDispatch?: () => void;
      } = {}
    ): Thunk<void> =>
    async (dispatch) => {
      await api.remove({ queryId });
      if (options.beforeDispatch) {
        await options.beforeDispatch();
      }
      dispatch(Actions.removeSuccess({ queryId }));
    },

  pusherApprovedLocal: createAction('@query/pusher/APPROVED'),
  pusherApproved:
    (data: IPusherCreatePayload): Thunk<void> =>
    async (dispatch, getState) => {
      /**
       * Even though we're re fetching class data in case
       * of students on class page, we still need to
       * add it to current class activities because
       * class needs to have activities for fetching to
       * work
       */
      dispatch(Actions.pusherApprovedLocal(data));

      const classRouteMatch = Routes.classActivities.getMatch();
      const role = getCourseRole(getState());
      const courseShortId = courseService.getShortIdFromCourseId(data.courseId);

      const courseRouteMatch = Routes.course.getMatch();
      if (
        courseRouteMatch &&
        role === 'student' &&
        courseRouteMatch.courseShortId === courseShortId
      ) {
        await dispatch(CourseActions.fetchTimeline());
      }

      /**
       * If current role is student and user is on class
       * page (or a sub page of it), we have to re fetch timeline
       * data. This is because pusher message doesn't contain
       * user data for the newly approved query. So, we have
       * no way of identifying whether this query was asked
       * by the current user. We just re fetch the class
       * page data.
       */
      if (
        classRouteMatch &&
        classRouteMatch.classShortId === classService.getShortIdFromClassId(data.classId) &&
        role === 'student'
      ) {
        console.log('actions', data.classId);
        dispatch(ClassActions.fetchClassActivities(data.classId));
      }
    },
};
