export const subscriptionIcon = (isNotStudent: boolean) => {
  return {
    position: 'absolute',
    right: isNotStudent ? '3rem' : '1rem',
    cursor: 'pointer',
  };
};

export const exportIcon = {
  position: 'absolute',
  right: '1rem',
  cursor: 'pointer',
};
