import { h, IComponent, View } from 'core';

import { api as urls } from 'acadly/api';
import { Actions as appActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import ActionBar, { IActionButton } from 'acadly/common/ActionBar';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Avatar from 'acadly/common/Avatar';
import ContentView from 'acadly/common/ContentView';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import RaisedButton from 'acadly/common/RaisedButton';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import * as dt from 'acadly/datetime';
import icons from 'acadly/icons';
import Viewer from 'acadly/rich-text/Viewer';
import { dispatch, getStore } from 'acadly/store';
import { getHTMLTagSelector } from 'acadly/utils';

import { goBack } from '../routes';
import { Actions, IApproveSuccessPayload } from './actions';

export interface IQueryProps {
  query: IQuery;
  courseRole: ICourseRole;
  isArchived: boolean;
  cls: IClass;
  userId: string;
}

export interface IQueryState {
  isApproveDialogOpen: boolean;
  isHideDialogOpen: boolean;
  isDeleteDialogOpen: boolean;
}

export default (props: IQueryProps) => h(Query, props);
export class Query extends IComponent<IQueryProps, IQueryState> {
  private isAccessible: boolean;
  public componentWillMount() {
    const initialState: IQueryState = {
      isApproveDialogOpen: false,
      isHideDialogOpen: false,
      isDeleteDialogOpen: false,
    };
    this.setState(initialState);
    const { query } = this.getProps();
    const classId = query.identifiers.classId;
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    if (!query.userData) {
      dispatch(
        Actions.analyticsSet({
          classId: classId,
          toBeDone: query.details.toBeDone,
          request: {
            queryId: query._id,
            firstAccess: 1,
            localTime: dt.format(dt.now(), 'YYYYMMDDTHH:mm'),
          },
        })
      );
    }
    dispatch(appActions.setContext('query'));
    dispatch(appActions.startTip(true));
  }

  public render() {
    const body: View[] = [
      this.header(),
      this.actionBar(),
      ...this.actionButtonsTipWrapper(),
      ...this.queryView(),
      this.upvoteNum(),
      this.footerWarning(),

      ...this.upvoteButton(),
      this.closeQueryButton(),

      this.deleteConfirmationDialog(),
      this.hideConfirmationDialog(),
      this.approveQueryConfirmationDialog(),
      // this.isClosingEnabled()
      //     ? TipOverlayWrapper({
      //         targetElement: "query-close-button",
      //         tip: {
      //             tipPosition: "top",
      //             tipText: "Once your query is answered, you must close the query using" +
      //                     " this button to let the course team know that it has been resolved"
      //         },
      //         tipKey: "queryMainClose",
      //         isNextAvailable: false
      //     })
      //     : null
    ];
    return h(
      getHTMLTagSelector('div', ['activity', this.isActionBarVisible() ? 'with-action-bar' : '']),
      [ContentView(h('div.activity__content', body))]
    );
  }

  private header() {
    const { cls, query } = this.getProps();
    const dateStamp = dt.format(cls.details.scheStartTime, 'MMM DD, YYYY [at] hh:mm A');
    return h(
      'div.cell',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        h('span.cell__label', [
          {
            inClass: 'In-class',
            preClass: 'Pre-class',
            review: 'Review',
          }[query.details.toBeDone],
          ` query for the class on ${dateStamp}`,
        ]),
      ]
    );
  }

  private footerWarning(): View {
    const isNarrow = getStore().getState().app.narrowScreen;
    const props = this.getProps();

    let message =
      'To post a comment on this query please tap ' +
      'on the icon on the top right corner of the screen.';

    if (props.courseRole !== 'student' && props.query.details.status === 'pending') {
      message =
        'Students can interact with this query only ' +
        'after it has been approved by a member of the ' +
        'course team';
    }

    return isNarrow
      ? h('div.query__warning', [h('i.fa.fa-exclamation-triangle'), h('span', message)])
      : null;
  }

  private queryView(): View[] {
    const { query } = this.getProps();
    const hasAttachments = query.details.attachments.length > 0;
    return [
      h(
        'div.activity__title',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `Query Title: ${query.details.title}`,
        },
        query.details.title
      ),

      h(
        'div.activity__description',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `Query Description: ${query.details.description || `That's all!`}`,
        },
        [Viewer(query.details.description || "That's all!")]
      ),

      hasAttachments ? this.headings('Attachments') : null,
      hasAttachments ? this.attachmentsView() : null,

      this.creatorView(),
    ];
  }

  private actionButtonsTipWrapper() {
    if (!this.isActionBarVisible()) {
      return [];
    } else {
      return [
        this.isDeletingEnabled()
          ? TipOverlayWrapper({
              targetElement: 'delete-query-button',
              tip: {
                tipPosition: 'bottom',
                tipText:
                  `If this query is inappropriate, use the “Delete” ` +
                  ` button to discard it. Removed queries cannot be retrieved later.`,
              },
              tipKey: 'queryMainDelete',
              isNextAvailable: false,
            })
          : TipOverlayWrapper({
              targetElement: 'hide-query-button',
              tip: {
                tipPosition: 'bottom',
                tipText:
                  'If this query is inappropriate, you can hide it from the' +
                  ' course team by using the “Hide” button',
              },
              tipKey: 'queryMainHide',
              isNextAvailable: false,
            }),
        this.isApprovingEnabled()
          ? TipOverlayWrapper({
              targetElement: 'approve-query-button',
              tip: {
                tipPosition: 'bottom',
                tipText:
                  'If this is an appropriate query, use this button to approve it.' +
                  ' Once approved the course members will be able to access the query',
              },
              tipKey: 'queryMainApprove',
              isNextAvailable: true,
            })
          : null,
      ];
    }
  }

  private actionButtons(): IActionButton[] {
    return [
      this.isDeletingEnabled()
        ? {
            id: 'delete-query-button',
            classNames: ['red'],
            label: 'Delete',
            disabled: !this.isDeletingEnabled(),
            onclick: () => this.openDeleteDialog(),
            icon: {
              type: 'view',
              view: Icon(icons.trash),
            },
          }
        : {
            id: 'hide-query-button',
            classNames: ['red'],
            label: 'Hide',
            disabled: !this.isHidingEnabled(),
            onclick: () => this.openHideDialog(),
            icon: {
              type: 'view',
              view: Icon(icons.eyeShut1),
            },
          },
      {
        id: 'approve-query-button',
        classNames: ['blue'],
        label: 'Approve',
        disabled: !this.isApprovingEnabled(),
        onclick: () => this.openApproveQueryDialog(),
        icon: {
          type: 'view',
          view: Icon(icons.approve),
        },
      },
    ];
  }

  private isDeletingEnabled(): boolean {
    const { query, courseRole } = this.getProps();
    return query.details.status === 'pending' && courseRole !== 'student' && courseRole !== 'ta';
  }

  private async openDeleteDialog() {
    await this.setState({
      isDeleteDialogOpen: true,
    });
  }
  private async openHideDialog() {
    await this.setState({
      isHideDialogOpen: true,
    });
  }

  private async openApproveQueryDialog() {
    await this.setState({
      isApproveDialogOpen: true,
    });
  }

  private deleteConfirmationDialog() {
    const state = this.getState();
    return this.makeConfirmationDialog({
      isOpen: state.isDeleteDialogOpen,
      onClickNo: () => this.setState({ isDeleteDialogOpen: false }),
      onClickYes: () => this.delete(),
      confirmationTitle: 'Delete query',
      confirmationText: 'Are you sure you want to delete this query?',
    });
  }
  private hideConfirmationDialog() {
    const state = this.getState();
    return this.makeConfirmationDialog({
      isOpen: state.isHideDialogOpen,
      onClickNo: () => this.setState({ isHideDialogOpen: false }),
      onClickYes: () => this.hide(),
      confirmationTitle: 'Hide query',
      confirmationText: 'Are you sure you want to hide this query?',
    });
  }

  private approveQueryConfirmationDialog() {
    const state = this.getState();
    const confirmationTitle = 'Approve query';

    const onClickNo = () =>
      this.setState({
        isApproveDialogOpen: false,
      });

    const onClickYes = () => this.approveQuery();
    const confirmationText = 'Are you sure you want to approve this query?';

    const isOpen = state.isApproveDialogOpen;

    const confirmationDialog = this.makeConfirmationDialog({
      isOpen,
      confirmationTitle,
      onClickNo,
      onClickYes,
      confirmationText,
    });
    return confirmationDialog;
  }

  private actionBar() {
    if (!this.isActionBarVisible()) return null;
    return ActionBar(this.actionButtons());
  }

  private isActionBarVisible() {
    return this.isHidingEnabled() || this.isApprovingEnabled();
  }

  private headings(label: string) {
    return h('div.cell', [h('span.cell__label', label)]);
  }

  private attachmentsView() {
    const { query } = this.getProps();

    return h(
      'div.activity__attachments',
      query.details.attachments.map((file) =>
        AttachmentViewer({
          attachment: file,
          downloadUrl: urls().queryFileDownload,
          downloadRequest: {
            queryId: query._id,
            fileName: file.name,
          },
          style: {
            marginBottom: '0.5em',
          },
        })
      )
    );
  }

  private creatorView(): View {
    const { query } = this.getProps();
    return h('div.user', [
      Avatar(query.details.createdBy.avatar, query.details.createdBy.name, {
        className: 'user__avatar',
      }),
      h(
        'div.user__details.fc-light-grey',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `Query created by ${query.details.createdBy.name} on
                    ${dt.fromNow(dt.fromUnix(query.details.createdOn))}`,
        },
        [
          h('div.fc-green', query.details.createdBy.name),
          h('div', dt.fromNow(dt.fromUnix(query.details.createdOn))),
        ]
      ),
    ]);
  }

  private closeQueryButton(): View {
    return this.isClosingEnabled()
      ? RaisedButton('Close this query', {
          classNames: ['query__raised-button', 'danger'],
          onclick: () => this.closeQuery(),
          tabIndex: this.isAccessible ? 0 : undefined,
        })
      : null;
  }

  private upvoteButton() {
    return this.isUpvotingEnabled()
      ? [
          RaisedButton('I have the same query', {
            onclick: () => this.upvoteQuery(),
            tabIndex: this.isAccessible ? 0 : undefined,
            classNames: ['query__raised-button'],
          }),
          // TipOverlayWrapper({
          //     targetElement: "query-upvote-button",
          //     tip: {
          //         tipPosition: "top",
          //         tipText: "You can upvote this query to let the instructor" +
          //                 " know that you have the same question"
          //     },
          //     tipKey: "queryMainUpvote",
          //     isNextAvailable: false

          // })
        ]
      : [];
  }

  private isUpvoteNumVisible() {
    const { courseRole } = this.getProps();
    return (courseRole === 'student' && !this.isUpvotingEnabled()) || courseRole !== 'student';
  }

  private upvoteNum() {
    const { query, courseRole } = this.getProps();
    const numAskers = query.stats.numAskers;
    const { userData } = query;
    const isAsker = userData ? userData.isAsker : undefined;

    if (!this.isUpvoteNumVisible()) return null;

    return courseRole === 'student'
      ? h(
          'div.query__student-count',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          isAsker
            ? numAskers === 1
              ? 'You and 1 other student has the same query'
              : numAskers === 0
              ? `Only you have this query`
              : `You and ${numAskers} other students have the same query`
            : numAskers === 1
            ? '1 other student has the same query'
            : numAskers === 0
            ? 'No other student has the same query'
            : `${numAskers} other students have the same query`
        )
      : h(
          'div..query__student-count',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          numAskers === 0
            ? '1 student has this query'
            : `${numAskers + 1} students have this query `
        );
  }

  private isHidingEnabled(): boolean {
    const { isArchived, query, courseRole } = this.getProps();
    return (
      !isArchived &&
      query.details.status === 'open' &&
      !query.details.isHidden &&
      courseRole !== 'student'
    );
  }

  private async delete() {
    await dispatch(
      Actions.remove(this.getProps().query._id, {
        beforeDispatch: goBack,
      })
    );
    // await this.setState({
    //     isDeleteDialogOpen: false
    // });
  }

  private async hide() {
    await dispatch(Actions.hide(this.getProps().query._id));
    googleAnalytics.queryHidden(
      {
        preClass: 'pre-class',
        inClass: 'in-class',
        review: 'review',
      }[this.getProps().query.details.toBeDone] as any
    );
    await this.setState({
      isHideDialogOpen: false,
    });
  }

  private isClosingEnabled() {
    const { query, isArchived } = this.getProps();
    const { userData } = query;
    const isAsker = userData ? userData.isAsker : undefined;
    return (
      (!query.details.isHidden &&
        query.details.status === 'open' &&
        this.isCreator() &&
        !isArchived) ||
      (isAsker && query.details.status === 'open')
    );
  }

  private isUpvotingEnabled() {
    const { query, courseRole, isArchived } = this.getProps();
    const isStudent = courseRole === 'student';
    const hasUpvoted = query.userData && query.userData.hasUpvoted;
    return (
      !this.isCreator() &&
      query.details.status === 'open' &&
      !query.details.isHidden &&
      !hasUpvoted &&
      isStudent &&
      !this.hasSameQuery() &&
      !isArchived
    );
  }

  private hasSameQuery() {
    const { query } = this.getProps();
    return query.userData && query.userData.isAsker;
  }

  private isCreator() {
    const props = this.getProps();
    const { query, userId } = props;
    return query.details.createdBy.userId === userId;
  }

  private async upvoteQuery() {
    const { query } = this.getProps();
    await dispatch(Actions.upvote(query._id));
  }

  private async closeQuery() {
    const { query } = this.getProps();
    await dispatch(
      Actions.close({
        queryId: query._id,
        toBeDone: query.details.toBeDone,
        classId: query.identifiers.classId,
      })
    );
  }

  private isApprovingEnabled() {
    const { query, courseRole } = this.getProps();
    return query.details.status === 'pending' && courseRole !== 'student' && courseRole !== 'ta';
  }

  private async approveQuery() {
    const { cls, query } = this.getProps();
    const approveRequest: IApproveSuccessPayload = {
      queryId: query._id,
      classId: cls._id,
      toBeDone: query.details.toBeDone,
      status: 'open',
    };
    await dispatch(Actions.approve(approveRequest));
    await this.setState({
      isApproveDialogOpen: false,
    });
  }

  private makeConfirmationDialog(options: {
    isOpen: boolean;
    confirmationTitle: string;
    onClickNo: () => Promise<unknown>;
    onClickYes: () => Promise<void>;
    confirmationText: string;
  }) {
    const { isOpen, confirmationTitle, onClickNo, onClickYes, confirmationText } = options;
    return Alert(
      {
        open: isOpen,
        title: h('div.fc-orange', confirmationTitle),
        style: {
          width: '25em',
        },
        actions: [
          FlatButton('NO', {
            type: 'secondary',
            onclick: onClickNo,
          }),
          FlatButton('YES', {
            onclick: onClickYes,
          }),
        ],
      },
      [confirmationText]
    );
  }
}
