import { IAppState } from './IAppState';
export const initialState: IAppState = {
  avatarUrls: {},
  narrowScreen: false,
  referralsAllowed: false,
  scrollbarWidth: 0,
  context: 'getin',
  windowWidth: 1000,
  isMobile: false,
  isAnalyticsOpen: false,
  analyticsBackStack: [],
  notifications: [],
  toasts: [],
  headerHeight: 0,
  isContextPanelExpanded: false,
  isTipOpen: false,
  visibleElementsList: {},
  oldTipObject: {
    turnOff: 0,
    status: {},
  },
  updatedTipObject: {
    turnOff: 0,
    status: {},
  },
  acc: {
    web: {
      turnOff: 0,
    },
  },
  isCurrentActivitySubscribed: false,
  newFeaturesSeenAt: 0,
  newFeaturesLastUpdatedAt: 0,
  newFeaturesAvailable: {},
  sortStudentBy: 'sortAscFirstName',
  vCallFrame: {
    isVisible: false,
    canAutoJoin: true,
  },
  vCallParticipants: {},
  demoCourseCreated: 0,
  hasEnterpriseAccount: 0,
  integratedWith: '',
  showNotificationPermissionAlert: false,
  showAttendanceScheduledToStartAlert: null,
};
