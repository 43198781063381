import { h, IComponent } from 'core';

import CtaButton from 'acadly/common/CtaButton';
import Icon from 'acadly/common/Icon';
import icons from 'acadly/icons';

interface IPaymentSuccessStepProps {
  onClose: () => any;
}

export default class PaymentSuccessStep extends IComponent<IPaymentSuccessStepProps, unknown> {
  public static getTitle(): string {
    return 'Payment success';
  }

  private init() {
    this.setState({});
  }

  public componentWillMount() {
    this.init();
  }

  private handleClose = () => {
    const { onClose } = this.getProps();
    onClose();
  };

  public render() {
    return h('div.join-course-payment__body', [
      h(
        'div.join-course-payment__payment-success',
        'Thank you for the payment, you can access the course.'
      ),
      h('div.join-course-payment__check', [Icon(icons.done)]),
      CtaButton({
        variant: 'grey',
        label: 'OKAY',
        onClick: this.handleClose,
      }),
    ]);
  }
}
