import { plural } from 'pluralize';

import { h, IComponent } from 'core/index';

import { assignmentService } from 'acadly/assignment/service';
import { IExportGradesSuccessResponse } from 'acadly/class/actions';
import { ICommentsContext } from 'acadly/comments/api';
import { getCommentsContextService, ICommentsContextService } from 'acadly/comments-context';
import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import { Loader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import { Actions as CourseActions } from 'acadly/course/actions';
import * as datetime from 'acadly/datetime';
import { Routes } from 'acadly/routes';
import { dispatch } from 'acadly/store';
import { ml, pad } from 'acadly/styles';
import * as u from 'acadly/utils';

import Comments from '../../comments/Comments';
import Icon from '../../common/Icon';
import courseService from '../../course/service';
import Tutorial from '../../course/Tutorial';
import icons from '../../icons';
import { getStore } from '../../store';
import { colors, style } from '../../styles';
import FAQScreen from '../FAQScreen';
import * as css from './styles';

interface IContextPanelProps {
  toggleContextPanel: () => void;
  toggleExpand: () => void;
  isExpanded: boolean;
  toggleSubscribe: () => void;
}

interface IContextPanelState {
  isExportDialogOpen: boolean;
  exportDownloadLink: string;
  exportRequestIsEmail: 0 | 1;
  sendEmail: boolean;
  exportFileName: string;
  isDownloadAlertOpen: boolean;
  isExportOptionsDialogOpen: boolean;
  exportOption: 0 | 1 | 2 | 3 | 4;
}

export class ContextPanel extends IComponent<IContextPanelProps, IContextPanelState> {
  private isAccessible: boolean;
  private lastFocusedElement: Element | null = null;

  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    this.setState({
      isExportDialogOpen: false,
      exportDownloadLink: '',
      exportRequestIsEmail: 0,
      sendEmail: false,
      exportFileName: '',
      isDownloadAlertOpen: false,
      isExportOptionsDialogOpen: false,
      exportOption: 0,
    });
  }

  public render() {
    const isExpanded = this.getProps().isExpanded;
    return h(u.getHTMLTagSelector('div', ['context-panel', isExpanded ? '.expanded' : '']), [
      this.getHeader(),
      h('div.context-panel__content', [
        this.getContextView(),
        this.exportDialog(),
        this.exportOptionsDialog(),
        this.downloadAlert(),
      ]),
    ]);
  }
  private async downloadACopyClickHandler() {
    await this.setState({
      isDownloadAlertOpen: true,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }
  private async backClickHandler() {
    await this.setState({
      exportOption: 0,
      isExportOptionsDialogOpen: false,
      isDownloadAlertOpen: false,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }

  private async exportClickHandler() {
    const context = this.getContext();
    const contextId = this.getContextId() as string;
    const email = this.getState().exportRequestIsEmail;
    const response = await dispatch(
      CourseActions.exportCommentStats({
        context: (context !== 'course' ? plural(context) : context) as ICommentsContext,
        contextId,
        email,
      })
    );
    this.setFileData(response);
    if (email === 1) {
      await this.setState({
        sendEmail: true,
      });
    } else {
      await this.setState({
        isExportDialogOpen: false,
        isDownloadAlertOpen: true,
      });
    }
  }

  private async setFileData(response: IExportGradesSuccessResponse) {
    await this.setState({
      exportDownloadLink: response.url,
      exportFileName: response.filename,
    });
  }

  private async openExportDialog() {
    await this.setState({
      isExportOptionsDialogOpen: false,
      isExportDialogOpen: true,
    });
  }

  private unsetIndices(nofocusedElem?: boolean, _id?: string) {
    if (!nofocusedElem) {
      this.lastFocusedElement = document.activeElement;
    }
    u.unsetTabIndices(document.getElementsByTagName('header')[0]);
    if (_id) {
      u.resetTabIndices(document.getElementById(_id));
    }
  }

  private resetIndices(_id?: string | boolean) {
    u.resetTabIndices(document.getElementById('analytics-panel'));
    u.resetTabIndices(document.getElementById('context-panel-drawer'));
    let itemToFocus;
    if (_id && typeof _id === 'string') {
      itemToFocus = document.getElementById(_id);
    } else {
      itemToFocus = this.lastFocusedElement;
    }
    if (itemToFocus) {
      (itemToFocus as HTMLElement).focus();
    }
  }

  private getExportDialogTitle() {
    const context = this.getContext();

    switch (context) {
      case 'course':
        return 'Export students’ posts stats for the course discussion thread as';
      case 'class':
        return 'Export students’ posts stats for the class discussion thread as';
      case 'poll':
        return 'Export students’ posts stats for the poll discussion thread as';
      case 'quiz':
        return 'Export students’ posts stats for the quiz discussion thread as';
      case 'resource':
        return 'Export students’ posts stats for resource course discussion thread as';
      case 'query':
        return 'Export students’ posts stats for the student query thread as';
      case 'discussion':
        return 'Export students’ posts stats for the discussion thread as';
      case 'assignment':
        return 'Export students’ posts stats for the assignment discussion thread as';
    }
    return 'Export as';
  }

  private exportDialog() {
    const state = this.getState();
    if (!state) {
      return null;
    }
    const isOpen = state.isExportDialogOpen;
    const isEmail = state.exportRequestIsEmail;
    const sendEmail = state.sendEmail;
    const option = (opts: { selected: boolean; label: string; key: string; onclick: () => any }) =>
      h(
        'div',
        style(
          ['flex', 'alignCenter'],
          {},
          {
            onclick: opts.onclick,
            key: opts.key,
            tabIndex: 0,
          }
        ),
        [
          RadioButton({
            selected: opts.selected,
            color: colors.teal,
          }),
          h(
            'div',
            style([
              pad('1rem 1rem'),
              ml('0.5rem'),
              {
                flex: '1',
                borderBottom: '1px solid #efefef',
              },
            ]),
            opts.label
          ),
        ]
      );
    return Alert(
      {
        title: !sendEmail ? this.getExportDialogTitle() : 'Success',
        titleStyle: {},
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton(!sendEmail ? 'Cancel' : 'Download a copy', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: !sendEmail
              ? () => {
                  this.backClickHandler();
                  this.resetIndices();
                }
              : () => {
                  this.downloadACopyClickHandler();
                  this.resetIndices();
                },
          }),
          FlatButton('Done', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: !sendEmail
              ? () => this.exportClickHandler()
              : () => {
                  this.backClickHandler();
                  this.resetIndices();
                },
          }),
        ],
      },
      [
        sendEmail
          ? h(
              'div',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              'The exported file has been sent to your registered' +
                " email address. In case you can't spot it, please" +
                ' look into the Spam folder'
            )
          : h('div', {}, [
              option({
                label: 'CSV download',
                selected: isEmail === 0,
                key: 'no-email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 0,
                  }),
              }),
              option({
                label: 'An Email Attachment',
                selected: isEmail === 1,
                key: 'email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 1,
                  }),
              }),
            ]),
      ]
    );
  }

  private downloadAlert() {
    const isOpen = this.getState().isDownloadAlertOpen;
    const fileName = this.getState().exportFileName;
    const downloadLink = this.getState().exportDownloadLink;
    const styles = {
      display: 'flex',
      width: '20em',
      borderRadius: '4px',
      backgroundColor: 'white',
      alignItems: 'center',
      border: `1px solid ${colors.lightestGrey}`,
      maxWidth: '100%',
      lineHeight: '1.5em',
      boxSizing: 'border-box',
      paddingRight: '0.5rem',
    };

    return Alert(
      {
        title: 'Success',
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('Done', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.resetIndices();
              this.backClickHandler();
            },
          }),
        ],
      },
      [
        h('div', style([pad('0.5rem 0.5rem 0.5rem 0rem')]), 'File Generated'),
        h(
          'div.acadly-attachment',
          {
            style: styles,
          },
          [
            h(
              'div.attachment-icon',
              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '0.5rem',
                  // fontSize: "0.8em",
                  width: '2.5rem',
                  color: 'white',
                  height: '2.5rem',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  backgroundColor: colors.green,
                },
              },
              'CSV'
            ),
            h(
              'div',
              style([
                'thin',
                {
                  flex: 1,
                  color: colors.black,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: '0.5em',
                },
              ]),
              [fileName]
            ),
            h(
              'a',
              {
                style: {
                  color: colors.green,
                },
                target: '_blank',
                href: downloadLink || undefined,
              },
              [Icon(icons.download, style(['blue', 'large', 'pointer', pad('0.5rem')]))]
            ),
          ]
        ),
      ]
    );
  }

  private exportOptionsDialog() {
    const isOpen = this.getState().isExportOptionsDialogOpen;
    const exportOption = this.getState().exportOption;
    const option = (opts: { selected: boolean; label: string; key: string; onclick: () => any }) =>
      h(
        'div',
        style(
          ['flex', 'alignCenter'],
          {},
          {
            onclick: opts.onclick,
            keys: opts.key,
            tabIndex: 0,
          }
        ),
        [
          RadioButton({
            selected: opts.selected,
            color: colors.teal,
          }),
          h(
            'div',
            style([
              pad('1rem 1rem'),
              ml('0.5rem'),
              {
                flex: '1',
                borderBottom: '1px solid #efefef',
              },
            ]),
            opts.label
          ),
        ]
      );
    return Alert(
      {
        title: 'Select data to be exported',
        titleStyle: {
          textAlign: 'center',
        },
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('Cancel', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => {
              this.resetIndices();
              this.backClickHandler();
            },
          }),
          FlatButton('Next', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.unsetIndices(true);
              this.openExportDialog();
            },
          }),
        ],
      },
      [
        h('div', {}, [
          option({
            label: 'Attendance Data',
            selected: exportOption === 0,
            key: 'attendance',
            onclick: () =>
              this.setState({
                exportOption: 0,
              }),
          }),
          option({
            label: "Quizzes' Score",
            selected: exportOption === 1,
            key: 'quizzes',
            onclick: () =>
              this.setState({
                exportOption: 1,
              }),
          }),
          option({
            label: "Polls' Data",
            selected: exportOption === 2,
            key: 'polls',
            onclick: () =>
              this.setState({
                exportOption: 2,
              }),
          }),
          option({
            label: "Assignments' Score",
            selected: exportOption === 3,
            key: 'assignments',
            onclick: () =>
              this.setState({
                exportOption: 3,
              }),
          }),
          option({
            label: 'Participation Score',
            selected: exportOption === 4,
            key: 'participation',
            onclick: () =>
              this.setState({
                exportOption: 4,
              }),
          }),
        ]),
      ]
    );
  }

  private isPaymentRequired() {
    const role = courseService.getRole();
    if (role !== 'student') return false;
    const courseData = courseService.getCurrentCourseUserData();
    if (!courseData) return false;
    const { hasPaid, payTill } = courseData;
    const now = datetime.unix();
    if (hasPaid) return false;
    return payTill < now;
  }

  private getContextView() {
    const context = this.getContext();
    const storeState = getStore().getState();
    if (context === 'getin') {
      return null;
    }
    if (context === 'home' || Routes.refer.isActive()) {
      return FAQScreen();
    }
    if (context === 'course') {
      if (!this.isPaymentRequired() && !storeState.courses.timeline) {
        return h(
          'div',
          style([
            'flex',
            'alignCenter',
            'justifyCenter',
            {
              width: '100%',
              height: '100%',
            },
          ]),
          [Loader()]
        );
      } else {
        if (this.isCourseTutorialVisible()) {
          return Tutorial();
        } else {
          return Comments({
            context,
            courseRole: courseService.getRole(),
            course: courseService.getCurrentCourse(),
          });
        }
      }
    } else {
      return Comments({
        context,
        courseRole: courseService.getRole(),
        course: courseService.getCurrentCourse(),
      });
    }
  }

  private getContext() {
    return getStore().getState().app.context;
  }

  private isCourseTutorialVisible() {
    const course = courseService.getCurrentCourse();
    const context = this.getContext();
    return (
      course &&
      context === 'course' &&
      !(course.status.studentsEnrolled && course.status.courseLive)
    );
  }

  private getHeader() {
    const context = this.getContext();
    const isMobile = getStore().getState().app.isMobile;
    const isSubscribed = this.getSubscribedStatus();
    const isNotStudent = !!(courseService.getRole() && courseService.getRole() !== 'student');
    return h('div.context-panel__header', [
      !isMobile
        ? h(
            'span.context-panel__toggle#expand-context-panel-button',
            {
              tabindex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
              onclick: this.toggleExpand,
            },
            [
              Icon(icons.commentsExpand, {
                ariaLabel: 'Expand Comments',
              }),
            ]
          )
        : null,
      isMobile
        ? Icon(icons.cross, {
            className: 'ripple context-panel__close',
            ariaLabel: 'Close Comments',
            tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
            onclick: () => this.getProps().toggleContextPanel(),
          })
        : null,
      context === 'home' ||
      (context === 'course' && this.isCourseTutorialVisible()) ||
      Routes.refer.isActive()
        ? Icon(icons.help, {
            className: 'context-panel__title-icon',
            ariaLabel: 'Help',
          })
        : Icon(icons.comments, {
            className: 'context-panel__title-icon',
            ariaLabel: this.getTitle(),
          }),
      h('div.context-panel__title', this.getTitle()),
      // isMobile
      //     ? Icon(icons.cross, {
      //         onclick: () => this.getProps().toggleContextPanel(),
      //         style: {
      //             fontSize: "1.1em",
      //             position: "absolute",
      //             top: "50%",
      //             transform: "translateY(-50%)",
      //             right: "0.3em",
      //             cursor: "pointer"
      //         }
      //     })
      //     : null,
      // Icon for subscribe
      // if unsubscribed show subscribe icon
      context !== 'home' && context !== 'getin' && !this.isCourseTutorialVisible()
        ? isSubscribed === 1
          ? Icon(icons.unsubscribe, {
              onclick: () => this.getProps().toggleSubscribe(),
              tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
              style: css.subscriptionIcon(isNotStudent),
              ariaLabel: 'Unsubscribe',
            })
          : Icon(icons.subscribe, {
              onclick: () => this.getProps().toggleSubscribe(),
              style: css.subscriptionIcon(isNotStudent),
              tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
              ariaLabel: 'Subscribe',
            })
        : null,
      isNotStudent
        ? Icon(icons.export2, {
            onclick: () => {
              this.openExportDialog();
            },
            style: css.exportIcon,
            ariaLabel: 'Export Stats',
          })
        : null,
    ]);
  }

  private toggleExpand = this.getProps().toggleExpand;

  private getCommentsContextService(
    context: IAppContext,
    subContext?: ICommentsSubContext
  ): ICommentsContextService {
    return getCommentsContextService(context, subContext);
  }

  private getContextId() {
    const context = this.getContext();
    if (context === 'assignment') {
      return assignmentService.getCurrentAssignmentId();
    } else {
      return this.getCommentsContextService(context).contextId;
    }
  }

  private getSubscribedStatus() {
    const context = this.getContext();
    const contextId = this.getContextId();
    const timeline = getStore().getState().courses.timeline;
    if (!timeline) return 0;

    if (context === 'course') {
      const userData = timeline.userData;
      if (!userData) return 0;
      return userData.subscribed || 0;
    } else if (context === 'class' && contextId) {
      const userData = timeline.items
        .filter((item) => item._id === contextId && item.nodeType === 'class')
        .map((item: IClass) => item.userData)[0];
      return (userData && userData.subscribed) || 0;
    } else if (context === 'quiz' && contextId) {
      const quizzes = getStore().getState().quizzes.byId[contextId];
      return (quizzes && quizzes.userData && quizzes.userData.subscribed) || 0;
    } else if (context === 'resource' && contextId) {
      const resource = getStore().getState().resources.byId[contextId];
      return (resource && resource.userData && resource.userData.subscribed) || 0;
    } else if (context === 'poll' && contextId) {
      const polls = getStore().getState().polls.byId[contextId];
      return (polls && polls.userData && polls.userData.subscribed) || 0;
    } else if (context === 'discussion' && contextId) {
      const discussion = getStore().getState().discussions.byId[contextId];
      return (discussion && discussion.userData && discussion.userData.subscribed) || 0;
    } else if (context === 'query' && contextId) {
      const query = getStore().getState().queries.byId[contextId];
      return (query && query.userData && query.userData.subscribed) || 0;
    } else if (context === 'assignment' && contextId) {
      const userData = timeline.items
        .filter((item) => item._id === contextId)
        .map((item: IAssignment) => item.userData)[0];
      return (userData && userData.subscribed) || 0;
    } else return 0;
  }

  private getTitle() {
    const context = this.getContext();
    const titles = {
      course: this.isCourseTutorialVisible() ? 'Help' : 'Course discussion',
      assignment: 'Assignment discussion',
      class: 'Class discussion',
      quiz: 'Quiz discussion',
      poll: 'Poll discussion',
      resource: 'Resource discussion',
      query: 'Query discussion',
      discussion: 'Discussion comments',
      home: 'FAQ',
      getin: '',
    };

    const title = titles[context];

    if (!title) {
      if (Routes.refer.isActive()) return titles.home;
    }
    return title || 'Comments';
  }
}

export default (props: IContextPanelProps) => h(ContextPanel, props);
