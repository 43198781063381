import { h, IComponent, View } from 'core';

import FlatButton from 'acadly/common/FlatButton';
import RadioButton from 'acadly/common/RadioButton';
import { mb, pad, style } from 'acadly/styles';

import { getCourseSlotsAndPrices, IGetCourseSlotsAndPricesResponse } from '../api';
import { ICourseType, IPaymentMethod } from './types';

interface ICourseTypeStepProps {
  getInstance?: (ref: CourseTypeStep) => any;
  onBack?: () => any;
  onNext?: (data: {
    courseType: ICourseType;
    paymentMethod: IPaymentMethod;
    isFree: boolean;
  }) => any;
}

interface ICourseTypeStepState {
  courseType: ICourseType;
  paymentMethod: IPaymentMethod;
  proStatusReponse: IGetCourseSlotsAndPricesResponse | null;
}

export class CourseTypeStep extends IComponent<ICourseTypeStepProps, ICourseTypeStepState> {
  public static getTitle(): string {
    return 'Choose course type';
  }

  private async init() {
    const initialState: ICourseTypeStepState = {
      courseType: 'pro',
      paymentMethod: 'student_pays',
      proStatusReponse: null,
    };
    this.setState(initialState);
    const response = await getCourseSlotsAndPrices();
    this.setState({
      proStatusReponse: response.data,
    });
  }

  public componentWillMount() {
    const { getInstance } = this.getProps();
    this.init();
    getInstance?.(this);
  }

  public handleBack = () => {
    const { onBack } = this.getProps();
    onBack();
  };

  public handleNext = async () => {
    const { onNext } = this.getProps();
    const { courseType, paymentMethod } = this.getState();
    onNext({
      courseType,
      paymentMethod,
      isFree: this.getIsFreeCourseSelected(),
    });
  };

  private getIsBasicCourseFree() {
    const { proStatusReponse } = this.getState();
    if (!proStatusReponse) return false;
    return proStatusReponse.costs.perStudent.basic === 0;
  }

  private getIsProCourseFree() {
    const { proStatusReponse } = this.getState();
    if (!proStatusReponse) return false;
    return proStatusReponse.costs.perStudent.pro === 0;
  }

  private getIsFreeCourseSelected() {
    const { proStatusReponse, courseType } = this.getState();
    if (!proStatusReponse) return false;
    if (courseType === 'pro') return this.getIsProCourseFree();
    return this.getIsBasicCourseFree();
  }

  private getInfoText(): string {
    const { proStatusReponse, courseType } = this.getState();

    if (!proStatusReponse) return '';

    if (courseType === 'basic' && this.getIsBasicCourseFree()) {
      return proStatusReponse.freeMessage.basic;
    }

    if (courseType === 'pro' && this.getIsProCourseFree()) {
      return proStatusReponse.freeMessage.pro;
    }

    return '';
  }

  private formatCurrency(amount: number, currency = 'usd') {
    switch (currency.toLowerCase()) {
      case 'inr':
        return `₹${amount / 100}`;
      default:
        return `$${amount / 100}`;
    }
  }

  private getStudentPaysHelperText() {
    const { proStatusReponse, courseType } = this.getState();

    if (!proStatusReponse) return undefined;

    const {
      costs: { perStudent },
    } = proStatusReponse;

    const ACTUAL_PRICE = courseType === 'pro' ? 1000 : 500;
    const offerPrice = courseType === 'pro' ? perStudent.pro : perStudent.basic;

    if (this.getIsFreeCourseSelected()) {
      return h('div', [
        h('span', style(['strikethrough']), [
          this.formatCurrency(ACTUAL_PRICE),
          ' per student per course',
        ]),
        h('span', [' FREE. No credit card required.']),
      ]);
    }

    return h('div', [
      h('span', style(['strikethrough']), this.formatCurrency(ACTUAL_PRICE)),
      h('span', [' ', this.formatCurrency(offerPrice), ' ']),
      h(
        'span',
        ' per student per course. Your students will be able to sign up and access your course for free for 14 days.'
      ),
    ]);
  }

  private getInstructorPaysHelperText() {
    if (this.getIsFreeCourseSelected()) {
      return 'FREE. No credit card required.';
    }

    return h('div', [
      h('span', 'Select this option and click'),
      h('span', style(['bold']), ' continue'),
      h('span', ' to send us an inquiry for the instructor-pays price.'),
    ]);
  }

  private radioCell({
    label,
    isFree = false,
    helperText,
    disabled,
    isSelected,
    onClick,
  }: {
    label: string;
    isFree?: boolean;
    helperText?: View | View[];
    isSelected: boolean;
    disabled?: boolean;
    onClick: (e: Event) => any;
  }) {
    return h('div', style([mb('1rem'), { opacity: disabled ? 0.5 : 1 }]), [
      h(
        'div',
        style(
          ['flex', 'bold', pad('0.5rem 0'), { gap: '1rem' }],
          {},
          {
            className: disabled ? undefined : 'ripple',
            onclick: disabled ? undefined : onClick,
          }
        ),
        [
          RadioButton({ selected: isSelected, disabled }),
          h('div', style(['flex', 'alignCenter', { gap: '0.5rem' }]), [
            label,
            isFree ? h('span.tag.green', 'FREE') : null,
          ]),
        ]
      ),
      h('div', style([isSelected ? 'black' : 'grey']), [
        Array.isArray(helperText) ? h('div', helperText) : helperText,
      ]),
    ]);
  }

  private info() {
    const text = this.getInfoText();
    if (!text) return null;
    return h('div', style(['flex', 'alignCenter', 'green', pad('1rem 0'), { gap: '1rem' }]), [
      h('i.fa.fa-info-circle', style(['flex', 'justifyCenter', 'x-large', { minWidth: '20px' }])),
      h('div', text),
    ]);
  }

  private getIsCreateBasicCourseDisabled() {
    const { proStatusReponse } = this.getState();
    if (!proStatusReponse) return true;
    return proStatusReponse.slots.basic < 0;
  }

  private getIsCreateProCourseDisabled() {
    const { proStatusReponse } = this.getState();
    if (!proStatusReponse) return true;
    return proStatusReponse.slots.pro < 0;
  }

  public render() {
    const { courseType, paymentMethod } = this.getState();

    return h('form', [
      h('div', [
        this.radioCell({
          label: 'Create an Acadly Pro course',
          isFree: this.getIsProCourseFree(),
          disabled: this.getIsCreateProCourseDisabled(),
          isSelected: courseType === 'pro',
          onClick: () => {
            this.setState({
              courseType: 'pro',
            });
          },
        }),
        this.radioCell({
          label: 'Create an Acadly Basic course',
          isFree: this.getIsBasicCourseFree(),
          isSelected: courseType === 'basic',
          disabled: this.getIsCreateBasicCourseDisabled(),
          onClick: () => {
            this.setState({
              courseType: 'basic',
            });
          },
        }),
      ]),
      h('div', [
        h('div', style(['textCenter', 'large', 'bold', pad('1rem')]), 'Choose payment method'),
        this.radioCell({
          label: 'Students pay (free for instructor)',
          isSelected: paymentMethod === 'student_pays',
          helperText: this.getStudentPaysHelperText(),
          onClick: () => {
            this.setState({
              paymentMethod: 'student_pays',
            });
          },
        }),
        this.radioCell({
          label: 'Instructor pays (free for students)',
          isSelected: paymentMethod === 'instructor_pays',
          helperText: this.getInstructorPaysHelperText(),
          onClick: () => {
            this.setState({
              paymentMethod: 'instructor_pays',
            });
          },
        }),
      ]),
      this.info(),
      h('div', style(['flex', 'spaceBetween']), [
        FlatButton('Go back', {
          onclick: this.handleBack,
        }),
        FlatButton('Continue', {
          onclick: this.handleNext,
        }),
      ]),
    ]);
  }
}
