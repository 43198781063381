import { Actions as appActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import { Actions as classActions } from 'acadly/class/actions';
import { getCourseRole } from 'acadly/course/functions';
import { createAction, Thunk } from 'acadly/createAction';
import * as datetime from 'acadly/datetime';
import { Actions as getInActions } from 'acadly/getin/actions';
import { IRootActionObject } from 'acadly/IRootAction';
import { IPusherPayload } from 'acadly/pusher';
import { Routes } from 'acadly/routes';

import * as api from './api';
import { ReorderQuestionPayload } from './api';
import { isQuizSeen, responseQuizToQuiz } from './functions';
import quizService from './service';

export type IQuizActionMap = {
  '@quiz/CREATE_SUCCESS': ICreateSuccessPayload;
  '@quiz/EDIT_SUCCESS': IEditSuccessPayload;
  '@quiz/FETCH_DATA_REQUEST': string;
  '@quiz/FETCH_DATA_SUCCESS': IFetchDataPayload;
  '@quiz/CLEAR_DATA': undefined;
  '@quiz/question/ADD_SUCCESS': IQuestionAddPayload;
  '@quiz/DELETE_SUCCESS': IDeleteSuccessPayload;
  '@quiz/question/EDIT_SUCCESS': IQuestionEditSuccessPayload;
  '@quiz/question/REMOVE_SUCCESS': IQuestionRemoveSuccessPayload;
  '@quiz/PUBLISH_SUCCESS': IPublishSuccessPayload;
  '@quiz/STOP_SUCCESS': IStopQuizSuccessPayload;
  '@quiz/UPDATE_PUBLISHED_QUIZ_SUCCESS': {
    data: api.IUpdateQuizRequest;
    questions?: IQuizQuestion[];
  };
  '@quiz/SAVE_SUBMISSION_SUCCESS': ISaveSubmissionPayload;
  '@quiz/SUBMIT_SUCCESS': ISubmitSuccessPayload;
  '@quiz/all/FETCH_SUCCESS': IFetchAllSuccessPayload;
  '@quiz/pusher/PUBLISH': IPusherPublishPayload;
  '@quiz/analytics/FETCH_SUCCESS': IAnalyticsFetchSuccessPayload;
  '@quiz/analytics/question/FETCH_SUCCESS': IAnalyticsIndividualQuestionFetchSuccessPayload;
  '@quiz/pusher/SUBMITTED': IPusherSubmittedPayload;
  '@quiz/questions/RE_ORDER_SUCCESS': ReorderQuestionPayload;
};

export type IAnalyticsIndividualQuestionFetchSuccessPayload =
  api.IAnalyticsIndividualQuestionFetchResponse;
export type IAnalyticsFetchSuccessPayload = api.IAnalyticsFetchResponse;

export type IQuestionRemoveSuccessPayload = api.IRemoveQuestionRequest;

export type IPusherPublishPayload = IPusherPayload<{
  classId: string;
  classType: 'lecture';
  classSubType: IClassSubType;
  quizId: string;
  quizNum: number;
  dueDateTime: UnixTimestamp;
  dueDateTimeL: string;
  publishedOn: UnixTimestamp;
  details: IResponseQuizDetails;
  stats: {
    numSubmitted: number;
    totalScore: {
      incentivizing: number;
      penalizing: number;
      neutral: number;
    };
  };
  activities: {
    numCommentsTotal: number;
  };
}>;
export type IQuizActionType = keyof IQuizActionMap;

export type IFetchAllSuccessPayload = api.IFetchAllQuizzesResponse;

export type IQuizAction = IRootActionObject<IQuizActionType>;

export type ISaveSubmissionPayload = api.ISaveSubmissionRequest;
export type ISubmitSuccessPayload = {
  request: api.ISubmitRequest;
  response: api.ISubmitResponse;
  quiz: IQuiz;
};

export interface IFetchDataPayload extends api.IFetchQuizDataResponse {
  quizId: string;
  timestamp: UnixTimestamp;
  isStudent: boolean;
  isFirstAccess: boolean;
}
export interface ICreateSuccessPayload {
  classId: string;
  quiz: IQuiz;
}
export type IEditSuccessPayload = api.IEditQuizRequest;
export type IQuestionAddPayload = {
  request: api.IAddQuestionRequest;
  response: api.IAddQuestionResponse;
};
export type IDeleteSuccessPayload = api.IDeleteQuizRequest;
export type IQuestionEditSuccessPayload = api.IEditQuestionResponse;
export type IPublishSuccessPayload = {
  request: api.IPublishQuizRequest;
  response: api.IPublishQuizResponse;
};
export type IStopQuizSuccessPayload = {
  request: api.IStopQuizRequest;
  response: api.IStopQuizResponse;
};

export type IPusherSubmittedPayload = IPusherPayload<{
  classId: string;
  quizId: string;
  toBeDone: 'preClass' | 'inClass';
  score: {
    incentivizing: number;
    neutral: number;
    penalizing: number;
  };
  submittedOn: UnixTimestamp;
}>;

export type IUpdateQuizRequest = api.IUpdateQuizRequest;

export const Actions = {
  createQuizSuccess: createAction('@quiz/CREATE_SUCCESS'),
  createQuiz:
    (data: api.ICreateQuizRequest): Thunk<IQuiz> =>
    async (dispatch) => {
      const response = await api.createQuiz(data);
      googleAnalytics.activityCreated('quiz', data.toBeDone);
      const quiz = responseQuizToQuiz(undefined, data.classId, response.data);
      dispatch(
        Actions.createQuizSuccess({
          classId: data.classId,
          quiz,
        })
      );
      return quiz;
    },

  editQuizSuccess: createAction('@quiz/EDIT_SUCCESS'),
  editQuiz:
    (data: api.IEditQuizRequest): Thunk<void> =>
    async (dispatch) => {
      await api.editQuiz(data);
      dispatch(Actions.editQuizSuccess(data));
    },

  fetchQuizDataRequest: createAction('@quiz/FETCH_DATA_REQUEST'),
  fetchQuizDataSuccess: createAction('@quiz/FETCH_DATA_SUCCESS'),
  fetchQuizData:
    (quizId: string, classId: string): Thunk<IQuizSubmission | undefined> =>
    async (dispatch, getState) => {
      dispatch(Actions.fetchQuizDataRequest(quizId));

      const state = getState();
      const { session } = state.getIn;
      const quiz = state.quizzes.byId[quizId];
      if (!session || !quiz) return;
      const courseRole = getCourseRole(state);
      let firstAccess: 0 | 1 = 0;
      if (courseRole === 'student' && !isQuizSeen(quiz)) {
        firstAccess = 1;
      }
      const response = await api.fetchQuizDetails({
        quizId,
        firstAccess,
      });
      if (firstAccess) {
        dispatch(
          classActions.incrementClassSeenActivities({
            classId: classId,
            toBeDone: quiz.details.toBeDone,
            activityKey: 'quizzes',
          })
        );
      }
      const data = response.data;
      dispatch(
        Actions.fetchQuizDataSuccess({
          ...data,
          timestamp: datetime.unix(),
          quizId,
          isFirstAccess: firstAccess === 1,
          isStudent: true,
        })
      );
      return response.data.submission;
    },

  clearQuizData: createAction('@quiz/CLEAR_DATA'),

  addQuizQuestionSuccess: createAction('@quiz/question/ADD_SUCCESS'),
  addQuizQuestion:
    (data: api.IAddQuestionRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.addQuestion(data);
      dispatch(
        Actions.addQuizQuestionSuccess({
          request: data,
          response: response.data,
        })
      );
    },

  deleteQuizSuccess: createAction('@quiz/DELETE_SUCCESS'),
  deleteQuiz:
    (data: api.IDeleteQuizRequest, afterDelete?: () => any): Thunk<void> =>
    async (dispatch) => {
      await api.deleteQuiz(data);
      if (afterDelete) {
        await afterDelete();
      }
      dispatch(Actions.deleteQuizSuccess(data));
    },

  editQuestionSuccess: createAction('@quiz/question/EDIT_SUCCESS'),
  editQuestion:
    (data: api.IEditQuestionRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.editQuestion(data);
      dispatch(Actions.editQuestionSuccess(response.data));
    },

  deleteQuizQuestionSuccess: createAction('@quiz/question/REMOVE_SUCCESS'),
  deleteQuizQuestion:
    (data: api.IRemoveQuestionRequest): Thunk<void> =>
    async (dispatch: any) => {
      await api.removeQuestion(data);
      return dispatch(Actions.deleteQuizQuestionSuccess(data));
    },

  publishQuizSuccess: createAction('@quiz/PUBLISH_SUCCESS'),
  publishQuiz:
    (data: api.IPublishQuizRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.publishQuiz(data);
      googleAnalytics.activityPublished('quiz', data.toBeDone);
      dispatch(
        Actions.publishQuizSuccess({
          request: data,
          response: response.data,
        })
      );
    },

  stopQuizSuccess: createAction('@quiz/STOP_SUCCESS'),
  stopQuiz:
    (data: api.IStopQuizRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.stopQuiz(data);
      dispatch(
        Actions.stopQuizSuccess({
          request: data,
          response: response.data,
        })
      );
    },

  updateQuizSuccess: createAction('@quiz/UPDATE_PUBLISHED_QUIZ_SUCCESS'),
  updateQuiz:
    (data: api.IUpdateQuizRequest, questions?: IQuizQuestion[]): Thunk<void> =>
    async (dispatch) => {
      await api.updatePublishedQuiz(data);
      // refresh quiz data
      await dispatch(Actions.fetchQuizData(data.quizId, data.classId));
      dispatch(
        Actions.updateQuizSuccess({
          data,
          questions: questions ? questions : undefined,
        })
      );
    },

  saveQuizSubmissionSuccess: createAction('@quiz/SAVE_SUBMISSION_SUCCESS'),
  saveQuizSubmission:
    (courseId: string, data: api.ISaveSubmissionRequest): Thunk<void> =>
    async (dispatch) => {
      await api.saveSubmission(courseId, data);
      dispatch(Actions.saveQuizSubmissionSuccess(data));
    },

  submitQuizSuccess: createAction('@quiz/SUBMIT_SUCCESS'),
  submitQuiz:
    (quiz: IQuiz, data: api.ISubmitRequest, beforeDispatch?: () => any): Thunk<void> =>
    async (dispatch) => {
      const response = await api.submit(data);
      if (beforeDispatch) {
        beforeDispatch();
      }
      await dispatch(
        Actions.submitQuizSuccess({
          request: data,
          response: response.data,
          quiz,
        })
      );
      await dispatch(appActions.setContext('quiz'));
    },

  fetchAllSuccess: createAction('@quiz/all/FETCH_SUCCESS'),

  // fetch all quizzes and return an array of quiz ids fetched
  // Stored in IRootState["quizzes"]["byId"]
  fetchAll: (): Thunk<string[]> => async (dispatch) => {
    const response = await api.fetchAllQuizzes();
    dispatch(Actions.fetchAllSuccess(response.data));
    return response.data.activityData.map((q) => q._id);
  },

  pusherPublish: createAction('@quiz/pusher/PUBLISH'),

  analyticsFetchSuccess: createAction('@quiz/analytics/FETCH_SUCCESS'),
  analyticsFetch:
    (quiz: IQuiz): Thunk<void> =>
    async (dispatch) => {
      if (quiz.stats && quiz.stats.numSubmitted > 0) {
        const response = await api.analyticsFetch(quiz._id);
        const avatars = response.data.submittedBy.map((s) => s.identifiers.avatar);
        dispatch(getInActions.fetchAvatars(avatars));
        dispatch(Actions.analyticsFetchSuccess(response.data));
      } else {
        dispatch(
          Actions.analyticsFetchSuccess({
            submittedBy: [],
          })
        );
      }
    },

  analyticsFetchIndividualQuestionSuccess: createAction('@quiz/analytics/question/FETCH_SUCCESS'),
  analyticsIndividualQuestionFetch:
    (quizId: string, questionId: string): Thunk<api.IAnalyticsIndividualQuestionFetchResponse> =>
    async (dispatch) => {
      const response = await api.analyticsFetchIndividualQuestion(quizId, questionId);
      dispatch(Actions.analyticsFetchIndividualQuestionSuccess(response.data));
      return response.data;
    },

  pusherSubmittedLocal: createAction('@quiz/pusher/SUBMITTED'),
  pusherSubmitted:
    (data: IPusherSubmittedPayload): Thunk<void> =>
    async (dispatch, getState) => {
      dispatch(Actions.pusherSubmittedLocal(data));

      const state = getState();
      const quizRouteMatch = Routes.classQuiz.getMatch() || Routes.filteredQuiz.getMatch();

      const quiz = state.quizzes.byId[data.quizId];
      if (
        quizRouteMatch &&
        quizService.getQuizIdFromShortId(quizRouteMatch.quizShortId) === data.quizId &&
        state.quizzes.analytics &&
        quiz
      ) {
        dispatch(Actions.analyticsFetch(quiz));
      }
    },

  reorderQuestionSuccess: createAction('@quiz/questions/RE_ORDER_SUCCESS'),
  reorderQuestion:
    (data: ReorderQuestionPayload): Thunk<void> =>
    async (dispatch) => {
      await api.reorderQuestion(data);
      dispatch(Actions.reorderQuestionSuccess(data));
    },
};
