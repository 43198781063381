import 'rxjs/add/operator/groupBy';
import 'rxjs/add/operator/mergeMap';

import classNames from 'classnames';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { CSS, h, IComponent } from 'core';

import * as MoveDownIcon from 'assets/move_down.svg';
import * as MoveUpIcon from 'assets/move_up.svg';

import { Actions as AppActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import Alert from 'acadly/common/Alert';
import AutoSubmitDialog from 'acadly/common/AutoSubmitDialog';
import Avatar from 'acadly/common/Avatar';
import CheckBox from 'acadly/common/CheckBox';
import ContentView from 'acadly/common/ContentView';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import FloatingActionBar from 'acadly/common/FloatingActionBar';
import { loaderWhen } from 'acadly/common/Loader';
import Paper from 'acadly/common/Paper';
import RadioButton from 'acadly/common/RadioButton';
import RaisedButton from 'acadly/common/RaisedButton';
import SvgIcon from 'acadly/common/SvgIcon';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import ToastManager from 'acadly/common/Toast';
import { validateActivitySubmit } from 'acadly/course/validations';
import * as datetime from 'acadly/datetime';
import { PusherEvent, pusherService } from 'acadly/pusher';
import Viewer from 'acadly/rich-text/Viewer';
import { dispatch, getStore } from 'acadly/store';
import {
  backgroundColor,
  colors,
  getHeaderHeight,
  mb,
  ml,
  mt,
  pad,
  pb,
  pl,
  pr,
  pt,
  style,
} from 'acadly/styles';
import * as u from 'acadly/utils';
import * as utils from 'acadly/utils';
import { PageVisibility, PageVisibilityEvent } from 'acadly/utils/pageVisibility';

import { Actions } from './actions';
import { saveResponse } from './api';
import Attachments from './Attachments';
import ScoringSchemeDescription from './ScoringSchemeDescription';

export interface IAttemptPageProps {
  quiz: IQuiz;
  course: ICourse;
  cls: IClass;
  onClose(shouldRefetchQuizData?: boolean): void;
}

export type ActivityStoppedEvent = PusherEvent<
  'activityStopped',
  {
    courseId: string;
    classId: string;
    activityId: string;
    activityType: 'poll' | 'quiz';
    dueDateTime: number;
    dueDateTimeL: string;
  }
>;

export interface IAttemptPageState {
  isFetchingQuizData: boolean;
  isAttempting: boolean;
  answerField: ObjectMap<string | undefined>; // map of selected answerKeys by question-id
  /** map of options by question-id */
  reorderOptionsByQuestionId: ObjectMap<IReorderOption[]>;
  /** used to store temporary state during animation of options */
  reorderOptionsBuffer: {
    questionId: string;
    fromIndex: number;
    toIndex: number;
    isOnTop: boolean;
  }[];
  submittedAnswerField: ObjectMap<string | undefined>;
  isResponseChanged: boolean;
  isSubmitDialogOpen: boolean;
  questionIndex: number;
  timerText: string;
  highlightTimer: boolean;
  showAutoSubmitDialog: boolean;
  lastModifiedResponseQuestionId: string | undefined;
}
export default (props: IAttemptPageProps) => h(AttemptPage, props);

export const isIpad = /iPad/.test(navigator.userAgent);
const DEADLINE_OVER = 'Deadline over';

export class AttemptPage extends IComponent<IAttemptPageProps, IAttemptPageState> {
  private isAccessible = false;
  private lastFocusedElement: null | Element = null;

  private subscriptions: Subscription[] = [];

  private studentResponsePub = new Subject<string>();

  private beforeClosingWindow = (e: BeforeUnloadEvent | void) => {
    const { isAttempting, isResponseChanged } = this.getState();
    console.log(isAttempting, isResponseChanged);

    if (isAttempting && isResponseChanged) {
      const warning =
        'Your responses to this quiz are unsaved. ' +
        'Please save the progress before navigating away';

      e = e || window.event;

      if (e) {
        // Gecko + IE
        e.returnValue = warning;
      }

      // Webkit, Safari, Chrome etc.
      return warning;
    }

    return undefined;
  };

  private onTabSwitch = (e: PageVisibilityEvent) => {
    const { isAttempting, isResponseChanged } = this.getState();
    if (e.isHidden && isAttempting && isResponseChanged) {
      // if browser tabs is de-activated then sync quiz response
      this.syncStudentResponse();
    }
  };

  /**
   * Saves the response of last question attempted by student
   */
  private saveQuestionResponse = async (currentQuestionId: string) => {
    const { quiz } = this.getProps();
    const { answerField, lastModifiedResponseQuestionId } = this.getState();

    if (lastModifiedResponseQuestionId === currentQuestionId) {
      return;
    }

    this.setState({
      lastModifiedResponseQuestionId: currentQuestionId,
    });

    if (!lastModifiedResponseQuestionId) {
      /**
       * currentQuestionId is the first question attempted by student
       * so skip saving it. When student goes to next question then it
       * will save the response of first question.
       */
      return;
    }

    const responseKey = answerField[lastModifiedResponseQuestionId];

    await saveResponse({
      quizId: quiz._id,
      questionId: lastModifiedResponseQuestionId,
      responseKey: responseKey || '0000',
    });
  };

  public componentWillMount() {
    const initialState: IAttemptPageState = {
      isFetchingQuizData: false,
      isAttempting: false,
      answerField: {},
      reorderOptionsByQuestionId: {},
      reorderOptionsBuffer: [],
      submittedAnswerField: {},
      isResponseChanged: false,
      isSubmitDialogOpen: false,
      questionIndex: 0,
      timerText: 'Loading...',
      highlightTimer: false,
      showAutoSubmitDialog: false,
      lastModifiedResponseQuestionId: undefined,
    };

    this.setState(initialState);
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;

    const { quiz, onClose } = this.getProps();

    if (quiz.details.dueDateType !== 'manual') {
      this.initializeTimer(quiz.details.dueDateTime);
    }

    dispatch(AppActions.startTip(true));

    const pvSub = PageVisibility.visibility$.subscribe(this.onTabSwitch);

    const psSub = pusherService.events.subscribe((e: ActivityStoppedEvent) => {
      const data = e.payload;
      if (
        e.event === 'activityStopped' &&
        data.activityType === 'quiz' &&
        data.activityId === quiz._id
      ) {
        const { isAttempting } = this.getState();
        if (isAttempting) {
          this.showAutoSubmitDialog();
        } else if (!quiz.details.allowLate) {
          onClose();
        }
      }
    });

    const srSub = this.studentResponsePub
      .groupBy((questionId) => questionId)
      .mergeMap((group$) => group$.debounceTime(1000))
      .subscribe((questionId) => this.saveQuestionResponse(questionId));

    this.subscriptions.push(pvSub, psSub, srSub);

    window.addEventListener('beforeunload', this.beforeClosingWindow);
  }

  public componentWillUnmount() {
    if (this.runningInterval !== undefined) {
      clearInterval(this.runningInterval);
      this.runningInterval = undefined;
    }

    this.subscriptions.forEach((sub) => sub.unsubscribe());

    window.removeEventListener('beforeunload', this.beforeClosingWindow);

    const { isAttempting, isResponseChanged } = this.getState();
    if (isAttempting && isResponseChanged) {
      // save changes if user closes or navigates away from attempt page
      this.saveHandler().then(() => {
        ToastManager.show('Your quiz progress has been saved');
      });
    }
  }

  public render() {
    const { quiz } = this.getProps();

    if (this.getState().isAttempting) return this.attemptScreen();

    const isAccessible = this.isAccessible;
    const isOpen =
      (quiz.details.dueDateType === 'manual' && quiz.details.dueDateTime === -1) ||
      quiz.details.dueDateTime > datetime.unix();

    return ContentView(
      h('div.quiz-page', [
        this.classDetails(),
        detailCell('Scoring', utils.capitalize(quiz.details.scoring), isAccessible),
        detailCell(
          isOpen ? 'Quiz closes' : 'Quiz closed',
          quiz.details.dueDateType === 'manual' && isOpen
            ? 'when instructor closes'
            : `at ${datetime.format(quiz.details.dueDateTime, 'hh:mm A, MMM DD, YYYY')}`,
          isAccessible
        ),
        detailCell(
          'Late submissions',
          quiz.details.allowLate ? 'Allowed' : 'Not allowed',
          isAccessible
        ),
        detailCell('Score', 'Quiz not submitted', isAccessible),

        this.title(),

        ...this.instructions(),

        ...this.scoring(),

        this.creator(),

        this.attemptButton(),
        !quiz.userData
          ? TipOverlayWrapper({
              targetElement: 'quiz-attempt-button',
              tip: {
                tipPosition: 'top',
                tipText:
                  'To attempt the quiz questions, use this button. You' +
                  ' can quit anytime and your quiz progress will be saved',
              },
              tipKey: 'quizMainAttempt',
              isNextAvailable: false,
              isSpecial: true,
            })
          : null,
      ])
    );
  }

  private contentsHaveBeenEditedByInstructorDialog() {
    const quizzes = getStore().getState().quizzes;
    if (!quizzes.current) return '';
    const areQuestionsEdited = quizzes.current.areQuestionsEdited;

    return Alert(
      {
        open: Boolean(areQuestionsEdited),
        center: true,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('Okay', {
            onclick: this.initAttemptScreen,
          }),
        ],
      },
      ['This quiz has been updated. Click Okay to reload the changes.']
    );
  }

  private creator() {
    const { quiz } = this.getProps();
    const publishedOn = datetime.format(quiz.details.publishedOn, 'MMM DD, YYYY [at] hh:mm A');
    return h(
      'div',
      style([
        pad('0.5rem 1rem'),
        mb('77px'), // for attempt button
        'flex',
        'alignCenter',
        'lightGrey',
      ]),
      [
        Avatar(quiz.details.createdBy.avatar, quiz.details.createdBy.name, {
          className: 'attempt-page__avatar',
        }),
        h('div', style(['small']), [
          h(
            'div',
            { tabIndex: this.isAccessible ? 0 : undefined },
            `Published by ${quiz.details.createdBy.name}`
          ),
          h(
            'div',
            {
              tabIndex: this.isAccessible ? 0 : undefined,
              'Aria-label': `published on: ${publishedOn}`,
            },
            publishedOn
          ),
        ]),
      ]
    );
  }

  private classDetails() {
    const { quiz, cls } = this.getProps();
    return h(
      'div',
      style(
        [pad('0.5rem 1rem'), 'mediumGrey', 'thick'],
        {},
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        }
      ),
      `${
        {
          preClass: 'Pre-class',
          inClass: 'In-class',
        }[quiz.details.toBeDone]
      } quiz for the class on ${datetime.format(
        cls.details.scheStartTime,
        'MMM DD, YYYY [at] hh:mm A'
      )}`
    );
  }

  private title() {
    const { quiz } = this.getProps();
    return h(
      'div.title',
      style(
        [
          'borderBox',
          ml('1rem'),
          pt('1rem'),
          mt('1rem'),
          pb('1rem'),
          pr('1rem'),
          'x-large',
          {
            // borderBottom: `1px solid ${colors.lightGrey}`,
            wordBreak: 'break-word',
            lineHeight: '1.5rem',
          },
        ],
        {},
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `Quiz Title: ${quiz.details.title || `Untitled`}`,
        }
      ),
      [quiz.details.title || 'Untitled']
    );
  }

  private instructions() {
    const { quiz } = this.getProps();
    const hasAttachments = quiz && quiz.details.attachments.length > 0;
    return [
      Viewer(quiz.details.instructions, {
        tabIndex: this.isAccessible ? 0 : undefined,
        ariaLabel: 'Quiz Instructions:',
        style: {
          marginLeft: '1rem',
          marginRight: '1rem',
          marginBottom: '0.75em',
        },
      }),
      hasAttachments
        ? h(
            'div',
            style([
              'flex',
              'column',
              {
                marginLeft: '1rem',
                marginRight: '1rem',
                paddingBottom: '0.75rem',
                borderBottom: `1px solid ${colors.lighterGrey}`,
              },
            ]),
            [h('div', [this.headings('Attachments'), ...Attachments(quiz)])]
          )
        : null,
    ];
  }

  private headings(label: string) {
    return h(
      'div',
      style([
        'mediumGrey',
        'thick',
        'x-small',
        {
          marginBottom: '0.57rem',
          marginTop: '1rem',
          textTransform: 'uppercase',
        },
      ]),
      label
    );
  }

  private scoring() {
    const { quiz } = this.getProps();
    return [
      h(
        'div',
        style(['small', 'mediumGrey', 'thick'], pad('0.5rem 1rem'), {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': 'SCORING SCHEME',
        }),
        'SCORING SCHEME'
      ),
      h(
        'div.scoring-scheme',
        style(
          ['flex', 'column', ml('0.5rem'), pl('1rem'), pr('1rem'), pb('1rem')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          }
        ),
        [
          h('div', style(['lightGrey', mb('0.5em')]), 'For each attempted question'),
          ScoringSchemeDescription(quiz.details.scoring),
          h(
            'div',
            style(['lightGrey', mt('0.5em')]),
            'For each unattempted question, you score 0 points'
          ),
        ]
      ),
    ];
  }

  private attemptButton() {
    const isMobile = getStore().getState().app.isMobile;
    // const scrollbarWidth = getStore().getState().app.scrollbarWidth;
    const { quiz } = this.getProps();
    const { timerText, highlightTimer, isFetchingQuizData } = this.getState();

    return h(
      'div',
      style([
        'flex',
        'justifyCenter',
        {
          position: isMobile ? 'fixed' : 'absolute',
          width: '100%',
          bottom: 0,
          left: 0,
          color: timerText === DEADLINE_OVER ? colors.errorRed : colors.blue,
        },
      ]),
      [
        quiz.details.dueDateType !== 'manual' ? h('div.attempt-page__timer', timerText) : null,
        h(
          'div#quiz-attempt-button.attempt-page__attempt-button',
          {
            className: classNames({
              ripple: !isFetchingQuizData,
              disabled: isFetchingQuizData,
              'highlight-submit': highlightTimer,
              'manual-stop': quiz.details.dueDateType === 'manual',
            }),
            tabIndex: this.isAccessible ? 0 : undefined,
            role: 'button',
            'aria-label': quiz.userData ? 'Resume Quiz' : 'Attempt Quiz',
            // style: {
            //     transform: `translateX(-${scrollbarWidth}px)`
            // },
            onclick: this.initAttemptScreen,
          },
          isFetchingQuizData
            ? 'Fetching quiz data...'
            : quiz.userData
            ? 'Resume Quiz'
            : 'Attempt Quiz'
        ),
      ]
    );
  }

  private submitAlert() {
    const quizData = getStore().getState().quizzes.current;
    if (!quizData) return null;
    const numAttempted = this.numQuestionsAttempted();
    const numTotal = quizData.questions.length;
    const answerFields = this.getState().answerField;
    const questions = quizData.questions;
    return Alert(
      {
        open: this.getState().isSubmitDialogOpen,
        title: 'Submitting quiz',
        style: {
          width: '25em',
        },
        bodyStyle: {
          paddingBottom: '0',
        },
        actions: [
          FlatButton('CANCEL', {
            type: 'secondary',
            ariaLabel: 'cancel submitting quiz',
            role: 'button',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              this.setState({
                isSubmitDialogOpen: false,
              });
            },
          }),
          FlatButton('SUBMIT', {
            ariaLabel: 'submit quiz',
            role: 'button',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.submit('manual');
              u.resetTabIndices();
            },
          }),
        ],
      },
      [
        h(
          'div',
          style(
            ['orange', mb('1rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          "You won't be able to undo this submission."
        ),
        h(
          'div',
          style([mb('1rem')], {}, { tabIndex: this.isAccessible ? 0 : undefined }),
          `You have attempted ${numAttempted} of ${numTotal} questions.`
        ),
        ...Array.from(utils.range(0, numTotal)).map((index) => {
          const answerField = answerFields[questions[index]._id];
          const attempted = answerField && answerField !== '0000';
          return h(
            'div',
            style(
              ['flex', 'spaceBetween', mb('1rem'), pr('1rem')],
              {},
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                key: index.toString(),
              }
            ),
            [
              h('span', `Question ${index + 1}`),
              attempted
                ? h('span', style(['green']), 'Attempted')
                : h('span', style(['red']), 'Not attempted'),
            ]
          );
        }),
      ]
    );
  }

  private runningInterval?: NodeJS.Timer;

  private initializeTimer(endTime: number) {
    // always clear any existing timer before starting
    // a new one
    if (this.runningInterval !== undefined) {
      clearInterval(this.runningInterval);
      this.runningInterval = undefined;
    }
    this.runningInterval = setInterval(() => {
      const diff: number = endTime - datetime.unix();

      let hours: number | string = (diff / (60 * 60)) | 0;
      let minutes: number | string = (diff / 60) | 0;
      let seconds: number | string = diff % 60 | 0;

      if (minutes > 60) {
        minutes = minutes - hours * 60;
      }

      const highlightTimer = hours === 0 && minutes === 0 && seconds <= 10 && seconds >= 0;

      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      if (diff < 1 || this.runningInterval === undefined) {
        this.setTimerText(DEADLINE_OVER);

        if (highlightTimer) {
          this.showAutoSubmitDialog();
        }

        if (this.runningInterval !== undefined) {
          clearInterval(this.runningInterval);
          this.runningInterval = undefined;
        }
      } else {
        this.setTimerText(`Quiz closes in ${hours}:${minutes}:${seconds}`, highlightTimer);
      }
    }, 1000);
  }

  private async setTimerText(timerText: string, highlightTimer = false) {
    await this.setState({ timerText, highlightTimer });
  }

  private showAutoSubmitDialog() {
    const { quiz } = this.getProps();

    if (!document.hasFocus()) return;
    if (quiz.userData && quiz.userData.status !== 'inProgress') return;

    this.setState({ showAutoSubmitDialog: true });
  }

  private autoSubmitDialog() {
    const { showAutoSubmitDialog } = this.getState();
    const { quiz, onClose } = this.getProps();
    const submissions = this.getSubmission();

    if (quiz.userData && quiz.userData.status !== 'inProgress') return null;

    return AutoSubmitDialog({
      initialCounter: 5,
      activityType: 'quiz',
      open: showAutoSubmitDialog,
      allowLate: quiz.details.allowLate,
      hasAttempted: !!submissions.length,
      onCancel: async () => {
        await this.syncStudentResponse();
        this.setState({ showAutoSubmitDialog: false });

        if (!quiz.details.allowLate) {
          // re-fetch quiz data
          onClose(true);
        }
      },
      onSubmit: () => this.submit('auto'),
    });
  }

  private timerView() {
    const isMobile = getStore().getState().app.isMobile;
    const timerText = this.getState().timerText;
    const quizData = getStore().getState().quizzes.current;
    const questions = quizData ? quizData.questions : [];
    const scrollbarWidth = getStore().getState().app.scrollbarWidth;
    const questionIndex = this.getState().questionIndex;

    const { quiz } = this.getProps();

    if (
      quiz.details.dueDateType === 'manual' &&
      quiz.details.dueDateTime === -1 &&
      (questions.length < 2 || !isMobile)
    ) {
      return null;
    }

    const selectors = ['#quiz-attempt-navigation', 'attempt-page__attempt-navigation'];

    if (isMobile) {
      selectors.push('mobile');
    }

    if (isIpad) {
      selectors.push('ipad');
    }

    if (this.getState().highlightTimer) {
      selectors.push('highlight-submit');
    }

    return h('div', [
      Paper(
        selectors.join('.'),
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          isMobile && questions.length > 1
            ? h(
                'div.previous',
                style(
                  ['medium'],
                  {
                    visibility: questionIndex === 0 ? 'hidden' : undefined,
                  },
                  {
                    onclick: () =>
                      this.setState({
                        questionIndex: Math.max(questionIndex - 1, 0),
                      }),
                  }
                ),
                [
                  h(
                    'i.fa.fa-chevron-left',
                    style(
                      [
                        'lightGrey',
                        'pointer',
                        {
                          marginRight: 'auto',
                          paddingRight: '1em',
                          paddingLeft: '1em',
                          color: colors.blue,
                        },
                      ],
                      {}
                    )
                  ),
                  h('span', style(['blue']), 'Previous'),
                ]
              )
            : null,
          h(
            'span.timer',
            {
              style: {
                transform: `translateX(-${scrollbarWidth}px)`,
                marginRight: 'auto',
                marginLeft: 'auto',
                color: 'inherit',
                opacity:
                  quiz.details.dueDateType !== 'manual' ||
                  (quiz.details.dueDateTime !== -1 && quiz.details.allowLate)
                    ? 1
                    : 0,
              },
              ref: this.onRef,
            },
            [quiz.details.dueDateType === 'manual' ? DEADLINE_OVER : timerText]
          ),
          isMobile && questions.length > 1
            ? h(
                'div.next',
                style(
                  ['medium'],
                  {
                    visibility: questionIndex === questions.length - 1 ? 'hidden' : undefined,
                  },
                  {
                    onclick: () =>
                      this.setState({
                        questionIndex: Math.min(questionIndex + 1, questions.length - 1),
                      }),
                  }
                ),
                [
                  h('span', style(['blue']), 'Next'),
                  h(
                    'i.fa.fa-chevron-right',
                    style(
                      ['lightGrey', 'pointer'],
                      {
                        marginLeft: 'auto',
                        paddingLeft: '1em',
                        paddingRight: '1em',
                        color: colors.blue,
                      },
                      {}
                    )
                  ),
                ]
              )
            : null,
        ]
      ),
      isMobile && questions.length > 1
        ? TipOverlayWrapper({
            targetElement: 'quiz-attempt-navigation',
            tip: {
              tipPosition: 'top',
              tipText:
                'To move between the questions, you can use the Previous,' +
                ' Next buttons or the question number scroll at the top',
            },
            tipKey: 'quizAttemptNavigation',
            isNextAvailable: true,
          })
        : null,
      RaisedButton('', {
        classNames: ['attempt-page__raised-button'],
      }), // hidden button for padding
    ]);
  }

  private elem?: HTMLElement;
  private onRef = (elem?: HTMLElement) => {
    if (elem === this.elem) return;
    if (!elem) return;
    if (this.elem === elem) {
      return;
    }
    this.elem = elem;
  };

  private async submit(submissionType: 'auto' | 'manual') {
    const { quiz, onClose } = this.getProps();

    if (
      submissionType === 'manual' &&
      !validateActivitySubmit({
        allowLate: quiz.details.allowLate,
        dueDateTime: quiz.details.dueDateTime,
        dueDateType: quiz.details.dueDateType,
        serverUnix: datetime.unix(),
      })
    ) {
      return;
    }

    console.log('quiz due time:', quiz.details.dueDateTime);
    console.log('request sent at:', datetime.unix());
    await dispatch(
      Actions.submitQuiz(
        quiz,
        {
          quizId: quiz._id,
          classId: quiz.identifiers.classId,
          localTime: datetime.format(datetime.now(), 'YYYYMMDDTHH:mm'),
          submission: this.getSubmission(),
          submissionType,
        },
        () => {
          const updatedState: Partial<IAttemptPageState> = {
            isResponseChanged: false,
          };

          if (submissionType === 'manual') {
            updatedState.isSubmitDialogOpen = false;
          }

          this.setState(updatedState);
        }
      )
    );

    googleAnalytics.activitySubmission(
      'quiz',
      quiz.details.toBeDone === 'preClass' ? 'pre-class' : 'in-class'
    );

    // hackish way to force comments to load after
    // submission. Basically, comments reload
    // every time context changes to something other
    // than the current context. So, if we change
    // it to home and quiz again, it will load
    // comments. Honestly, it's not that bad and
    // works pretty well.
    await dispatch(AppActions.setContext('home'));
    await dispatch(AppActions.setContext('quiz'));

    // close attempt page and refetch quiz data
    onClose();
  }

  private initAttemptScreen = async () => {
    const { isFetchingQuizData } = this.getState();
    const quiz = this.getProps().quiz;

    if (isFetchingQuizData) return;

    if (
      !validateActivitySubmit({
        allowLate: quiz.details.allowLate,
        dueDateTime: quiz.details.dueDateTime,
        dueDateType: quiz.details.dueDateType,
        serverUnix: datetime.unix(),
      })
    ) {
      return;
    }

    this.setState({ isFetchingQuizData: true, questionIndex: 0 });

    const fetchedSubmission = await dispatch(
      Actions.fetchQuizData(quiz._id, quiz.identifiers.classId)
    );

    const quizData = getStore().getState().quizzes.current;

    const submission = fetchedSubmission || {};
    const answerField: ObjectMap<string | undefined> = {};
    const submittedAnswerField: ObjectMap<string | undefined> = {};

    const reorderOptionsByQuestionId: ObjectMap<IReorderOption[]> = {};

    for (const questionId of Object.keys(submission)) {
      answerField[questionId] = submission[questionId].answerString;
      submittedAnswerField[questionId] = submission[questionId].answerString;
    }

    for (const question of quizData.questions) {
      if (question.details.type !== 'reorder') continue;

      const options: ObjectMap<IReorderOption> = {};
      const answerKey = answerField[question._id];

      for (const option of question.details.options) {
        options[option.orderKey] = option;
      }

      reorderOptionsByQuestionId[question._id] =
        !answerKey || answerKey === '0000'
          ? question.details.options
          : answerKey.split('-').map((orderKey) => options[orderKey]);
    }

    await this.setState({
      answerField,
      reorderOptionsByQuestionId,
      reorderOptionsBuffer: [],
      submittedAnswerField,
      isResponseChanged: false,
    });

    /**
     * NOTE: Setting isAttempting to true after above state changes is
     * intentionally done to fix a weird inferno bug.
     * DO NOT merge these two setState() calls.
     */
    await this.setState({
      isAttempting: true,
      isFetchingQuizData: false,
      isSubmitDialogOpen: false,
      questionIndex: 0,
    });

    setTimeout(() => {
      const firstQuestion = document.querySelectorAll('div.question-header')[0];
      if (firstQuestion) {
        (firstQuestion as any).focus();
      }
    }, 300);
  };

  private attemptScreen() {
    const quizData = getStore().getState().quizzes.current;
    const isMobile = getStore().getState().app.isMobile;
    const questions = quizData ? quizData.questions : [];
    const { questionIndex } = this.getState();

    const TFOption = ({ question: q, option }: { question: IQuizQuestion; option: 't' | 'f' }) => {
      const picked = this.getState().answerField[q._id] === option;
      const color = picked ? colors.blue : colors.lightGrey;
      return h(
        'div',
        style(
          [pad('1rem'), 'pointer', 'standardBorder', { color }],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.pickTFOption(q._id, option),
          }
        ),
        [
          h('div', style(['flex', 'alignCenter']), [
            RadioButton({
              selected: picked,
            }),
            h('span', style([ml('0.5rem')]), option === 't' ? 'True' : 'False'),
          ]),
        ]
      );
    };

    const McqOption = ({
      question: q,
      option,
      optionIndex,
    }: {
      question: IQuizQuestion;
      option: IMCQOption;
      optionIndex: number;
    }) => {
      const answerKey = this.getState().answerField[q._id];
      const picked = answerKey ? answerKey[option.num - 1] === '1' : false;
      const color = picked ? colors.blue : undefined;

      return h(
        'div.mcq-option',
        style(
          [pad('1rem'), 'pointer', 'standardBorder'],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            key: option.num.toString(),
            onclick: () => this.toggleMCQOption(q._id, option),
          }
        ),
        [
          h('div.mcq-option-header', style([mb('0.5em'), 'flex', 'alignCenter', { color }]), [
            CheckBox({
              selected: picked,
            }),
            h(
              'span',
              style([ml('1em'), 'mediumGrey', { fontWeight: '700px', lineHeight: '20px' }]),
              `OPTION ${utils.indexToAlphabet(optionIndex)}`
            ),
          ]),
          Viewer(option.text, style([pb('0.5rem')])),
        ]
      );
    };

    const getReorderOptionId = (questionId: string, optionIndex: number) =>
      'attempt-reorder-option-' + questionId + optionIndex.toString();

    const getReorderOptions = (question: IQuizQuestion) => {
      if (question.details.type !== 'reorder') return [];
      return this.getState().reorderOptionsByQuestionId[question._id];
    };

    const ReorderOption = ({
      style: styles,
      question: q,
      option,
      optionIndex,
      onMoveClick,
    }: {
      style: CSS;
      question: IQuizQuestion;
      option: IReorderOption;
      optionIndex: number;
      onMoveClick: (direction: 'up' | 'down', e: MouseEvent) => any;
    }) => {
      const isFirstOption = optionIndex === 0;
      const isLastOption = optionIndex + 1 === q.details.options.length;

      return h(
        'div.reorder-option',
        style([pad('1rem'), 'pointer', 'standardBorder'], styles, {
          id: getReorderOptionId(q._id, optionIndex),
          tabIndex: this.isAccessible ? 0 : undefined,
          key: option.orderKey.toString(),
        }),
        [
          h(
            'div.reorder-option-header',
            style([mb('0.5em'), 'flex', 'alignCenter', 'spaceBetween', { color: colors.blue }]),
            [
              h(
                'span',
                style(['mediumGrey', { fontWeight: '700px', lineHeight: '20px' }]),
                `OPTION ${utils.indexToAlphabet(optionIndex)}`
              ),
              h('div.reorder-option__move', [
                SvgIcon({
                  component: 'button',
                  disabled: isFirstOption,
                  title: !isFirstOption ? 'Click to move this option up' : undefined,
                  icon: MoveUpIcon,
                  className: 'up',
                  onclick: (e) => onMoveClick('up', e),
                }),
                SvgIcon({
                  component: 'button',
                  disabled: isLastOption,
                  title: !isLastOption ? 'Click to move this option down' : undefined,
                  icon: MoveDownIcon,
                  className: 'down',
                  onclick: (e) => onMoveClick('down', e),
                }),
              ]),
            ]
          ),
          Viewer(option.text, style([pb('0.5rem')])),
        ]
      );
    };

    const Question = (question: IQuizQuestion, questionIndex: number) => {
      if (!question) return null;

      const { answerField } = this.getState();
      const answerKey = answerField[question._id];

      return h(
        'div.question',
        style(
          [mb('0.5em'), 'borderBox', pt('1rem'), pb('0.5rem')],
          { borderBottom: `1px solid ${colors.lighterGrey}` },
          { key: question._id }
        ),
        [
          h(
            'div.question-header',
            style(
              [pb('0.5rem'), pl('0.5rem'), 'thick', 'flex', 'alignCenter', 'spaceBetween'],
              {},
              { tabIndex: this.isAccessible ? 0 : undefined }
            ),
            [
              h('div', `QUESTION ${questionIndex + 1} OF ${questions.length}`),
              question.details.type === 'reorder'
                ? !answerKey || answerKey === '0000'
                  ? 'Unattempted'
                  : FlatButton('Clear', {
                      onclick: () => {
                        const { answerField, reorderOptionsByQuestionId } = this.getState();
                        this.setState({
                          answerField: {
                            ...answerField,
                            [question._id]: '0000',
                          },
                          reorderOptionsByQuestionId: {
                            ...reorderOptionsByQuestionId,
                            [question._id]: question.details.options as IReorderOption[],
                          },
                        });
                        this.checkResponseDiff();
                        this.studentResponsePub.next(question._id);
                      },
                    })
                : null,
            ]
          ),
          Viewer(question.details.description.text, {
            style: {
              paddingLeft: '0.5rem',
            },
            tabIndex: this.isAccessible ? 0 : undefined,
          }),
          question.details.type === 'mcq'
            ? h(
                'div',
                style(
                  ['mediumGrey', 'small', mt('0.5rem'), pl('0.5rem')],
                  {},
                  {
                    tabIndex: this.isAccessible ? 0 : undefined,
                    'aria-label': 'SELECT ALL CORRECT OPTIONS',
                  }
                ),
                ['SELECT ALL CORRECT OPTIONS']
              )
            : null,

          question.details.type === 'reorder'
            ? h(
                'div',
                style(
                  ['mediumGrey', 'small', mt('0.5rem'), pl('0.5rem')],
                  {},
                  {
                    tabIndex: this.isAccessible ? 0 : undefined,
                    'aria-label': 'USE ARROW BUTTONS TO REORDER OPTIONS',
                  }
                ),
                ['USE ARROW BUTTONS TO REORDER OPTIONS']
              )
            : null,

          question.details.type === 'tf'
            ? Paper('.attempt-page__options', {}, [
                TFOption({ question, option: 't' }),
                TFOption({ question, option: 'f' }),
              ])
            : question.details.type === 'mcq'
            ? Paper('.attempt-page__options', {}, [
                ...question.details.options.map((option, optionIndex) =>
                  McqOption({ question, option, optionIndex })
                ),
              ])
            : question.details.type === 'reorder'
            ? Paper(
                '.attempt-page__options',
                {},
                getReorderOptions(question).map((option, optionIndex, options) => {
                  const { reorderOptionsBuffer } = this.getState();

                  let style: CSS | undefined = undefined;
                  const bufferItem = reorderOptionsBuffer.find(
                    (item) => item.fromIndex === optionIndex && question._id === item.questionId
                  );

                  const TRANSITION_TIMEOUT = 300; // in milliseconds

                  if (bufferItem) {
                    const targetOption = document.getElementById(
                      getReorderOptionId(question._id, bufferItem.toIndex)
                    );

                    if (!targetOption) return;

                    const offset = targetOption.getBoundingClientRect().height;

                    style = {
                      transform: `translateY(${
                        bufferItem.toIndex - bufferItem.fromIndex > 0 ? offset : -offset
                      }px)`,
                      transition: `${TRANSITION_TIMEOUT}ms ease-in`,
                      zIndex: bufferItem.isOnTop ? '1000' : '0',
                      opacity: '0.75',
                    };
                  }

                  return ReorderOption({
                    style,
                    question,
                    option,
                    optionIndex,
                    onMoveClick: (direction) => {
                      if (bufferItem) return; // disable move during animation
                      const nextIndex = direction === 'up' ? optionIndex - 1 : optionIndex + 1;

                      setTimeout(() => {
                        const { answerField, reorderOptionsByQuestionId } = this.getState();
                        const newOptions = options.slice(0);
                        newOptions.splice(optionIndex, 1);
                        newOptions.splice(nextIndex, 0, option);

                        this.setState({
                          reorderOptionsBuffer: [],
                          reorderOptionsByQuestionId: {
                            ...reorderOptionsByQuestionId,
                            [question._id]: newOptions,
                          },
                          answerField: {
                            ...answerField,
                            [question._id]: newOptions.map((o) => o.orderKey).join('-'),
                          },
                        });

                        this.checkResponseDiff();
                        this.studentResponsePub.next(question._id);
                      }, TRANSITION_TIMEOUT);

                      this.setState({
                        reorderOptionsBuffer: [
                          {
                            questionId: question._id,
                            fromIndex: optionIndex,
                            toIndex: nextIndex,
                            isOnTop: true,
                          },
                          {
                            questionId: question._id,
                            fromIndex: nextIndex,
                            toIndex: optionIndex,
                            isOnTop: false,
                          },
                        ],
                      });
                    },
                  });
                })
              )
            : null,
        ]
      );
    };

    const body = [
      loaderWhen(!quizData),
      isMobile
        ? quizData
          ? h('div.mobile-attempt-screen', [
              Paper('.attempt-page__attempt-header.mobile', {}, [
                h(
                  'div',
                  style([
                    'fullWidth',
                    {
                      overflowX: 'hidden',
                    },
                  ]),
                  [
                    h(
                      'div',
                      style([
                        'flex',
                        {
                          marginLeft: 'calc(50% - 1em)',
                          transform: `translateX(-${this.getState().questionIndex * 2.5}em)`,
                          transition: 'transform 0.3s',
                        },
                      ]),
                      Array.from(utils.range(1, questions.length + 1)).map((i) =>
                        h(
                          'span',
                          style(
                            [
                              'flex',
                              'alignCenter',
                              'justifyCenter',
                              {
                                backgroundColor:
                                  this.getState().questionIndex + 1 === i
                                    ? colors.blue
                                    : colors.lightGrey,
                                width: '2em',
                                height: '2em',
                                marginRight: '0.5em',
                                color: 'white',
                                flexShrink: 0,
                                borderRadius: '100%',
                                cursor: 'pointer',
                              },
                            ],
                            {},
                            {
                              tabIndex: this.isAccessible ? 0 : undefined,
                              onclick: () =>
                                this.setState({
                                  questionIndex: i - 1,
                                }),
                            }
                          ),
                          i.toString()
                        )
                      )
                    ),
                  ]
                ),
              ]),
              Question(quizData.questions[questionIndex], questionIndex),
              this.timerView(),
              TipOverlayWrapper({
                targetElement: 'quiz-save-button',
                tip: {
                  tipPosition: isMobile ? 'bottom' : 'left',
                  tipText:
                    'You can save and your progress in the quiz by ' +
                    'using this button. This will NOT submit the quiz',
                },
                tipKey: 'quizAttemptSave',
                isNextAvailable: true,
              }),
              TipOverlayWrapper({
                targetElement: 'quiz-submit-button',
                tip: {
                  tipPosition: isMobile ? 'bottom' : 'left',
                  tipText:
                    'Whenever you’re satisfied with your answers to the ' +
                    'questions, you can use this button to Submit the quiz.' +
                    ' Please be careful of not missing the deadline',
                },
                tipKey: 'quizAttemptSubmit',
                isNextAvailable: false,
              }),
            ])
          : null
        : quizData
        ? h('div.quiz-attempt-screen', [
            ...quizData.questions.map(Question),
            FloatingActionBar(
              {
                id: 'quiz-save-button',
                position: 'bottom-right',
                style: {
                  transform: 'translateY(calc(-100% - 0.5em))',
                },
                tabIndex: this.isAccessible ? 0 : undefined,
                ariaLabel: 'Save changes',
                disabled: this.numQuestionsAttempted() === 0 || !this.getState().isResponseChanged,
                onclick: () => this.syncStudentResponse(),
              },
              [
                h(
                  'div',
                  { style: { fontSize: '0.85rem' } },
                  // show SAVE label when nothing is attempted, SAVED will be wrong!
                  this.getState().isResponseChanged || this.numQuestionsAttempted() === 0
                    ? 'SAVE'
                    : 'SAVED'
                ),
              ]
            ),
            FloatingActionBar(
              {
                id: 'quiz-submit-button',
                ariaLabel: 'Submit',
                tabIndex: this.isAccessible ? 0 : undefined,
                position: 'bottom-right',
                disabled: this.numQuestionsAttempted() === 0,
                onclick: () => this.submitHandler(),
              },
              [h('div', { style: { fontSize: '0.85rem' } }, 'SUBMIT')]
            ),
            TipOverlayWrapper({
              targetElement: 'quiz-save-button',
              tip: {
                tipPosition: isMobile ? 'bottom' : 'left',
                tipText:
                  'You can save your progress in the quiz by ' +
                  'using this button. This will NOT submit the quiz',
              },
              tipKey: 'quizAttemptSave',
              isNextAvailable: true,
            }),
            TipOverlayWrapper({
              targetElement: 'quiz-submit-button',
              tip: {
                tipPosition: isMobile ? 'bottom' : 'left',
                tipText:
                  'Whenever you’re satisfied with your answers to the ' +
                  'questions, you can use this button to Submit the quiz.' +
                  ' Please be careful of not missing the deadline',
              },
              tipKey: 'quizAttemptSubmit',
              isNextAvailable: false,
            }),
          ])
        : null,
      this.submitAlert(),
      this.timerView(),
      this.autoSubmitDialog(),
      this.contentsHaveBeenEditedByInstructorDialog(),
    ];
    if (isMobile) {
      return Dialog(
        {
          open: true,
          title: 'Quiz',
          thinHeader: true,
          style: {
            backgroundColor,
          },
          bodyStyle: {
            height: '100%',
            paddingLeft: '0',
            paddingRight: '0',
            paddingTop: getHeaderHeight(),
          },
          primaryAction: {
            id: 'quiz-submit-button',
            label: 'SUBMIT',
            mobileLabel: h('i.fa.fa-check', []),
            disabled: this.numQuestionsAttempted() === 0,
            onclick: () => this.submitHandler(),
          },
          secondaryAction: {
            id: 'quiz-save-button',
            label: 'SAVE',
            mobileLabel: h('i.fa.fa-arrow-left', []),
            onclick: async () => {
              await this.saveHandler();
              this.closeAttemptScreen();
            },
          },
        },
        body
      );
    } else {
      return ContentView(h('div', body));
    }
  }

  private async submitHandler() {
    const alertBox = document.getElementById('alert-box');
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices(alertBox);
    await this.setState({
      isSubmitDialogOpen: true,
    }).then(() => {
      alertBox && alertBox.focus();
    });
  }

  private numQuestionsAttempted() {
    const quizData = getStore().getState().quizzes.current;
    if (!quizData) return 0;
    const answerKeys = this.getState().answerField;
    return quizData.questions
      .map((q) => q._id)
      .map((id) => answerKeys[id])
      .filter((key) => key !== undefined)
      .filter((key) => key !== '0000').length;
  }

  private getSubmission() {
    const answerField = this.getState().answerField;
    const submission = Object.keys(answerField)
      .map((id) => ({
        questionId: id,
        answerString: answerField[id]!,
      }))
      .filter((o) => o.answerString !== undefined)
      .filter((o) => o.answerString !== '0000');
    return submission;
  }

  private async closeAttemptScreen() {
    await this.setState({ isAttempting: false });
    const resumeBtn = document.getElementById('quiz-attempt-button');
    resumeBtn && resumeBtn.focus();
    dispatch(Actions.clearQuizData(undefined));
  }

  private async saveHandler() {
    /**
     * WARNING: Do not set state in this function as this
     * function is being called in componentWillUnmount()
     */
    const { quiz, course } = this.getProps();
    const quizId = quiz._id;
    const classId = quiz.identifiers.classId;
    const submission = this.getSubmission();

    if (submission.length === 0) return;

    await dispatch(
      Actions.saveQuizSubmission(course._id, {
        classId,
        quizId,
        submission,
      })
    );
  }

  private async toggleMCQOption(questionId: string, option: IMCQOption) {
    const optionalAnswerKey = this.getState().answerField[questionId];
    const optionIndex = option.num - 1;
    const toggle = (c: string) => (c === '0' ? '1' : '0');
    const answerKey = optionalAnswerKey ? optionalAnswerKey : '0000';
    const newAnswerKey = utils.replaceIndex(answerKey.split(''), toggle, optionIndex).join(''); // invert the answer char at given option index in answerKey
    await this.setState({
      answerField: {
        ...this.getState().answerField,
        [questionId]: newAnswerKey,
      },
    });
    this.checkResponseDiff();
    this.studentResponsePub.next(questionId);
  }

  private async pickTFOption(questionId: string, option: 't' | 'f') {
    const answerField = this.getState().answerField;
    if (answerField[questionId] === option) {
      const newAnswerField = utils.removeKey(answerField, questionId);
      await this.setState({
        answerField: {
          ...newAnswerField,
        },
      });
    } else {
      await this.setState({
        answerField: {
          ...this.getState().answerField,
          [questionId]: option,
        },
      });
    }
    this.checkResponseDiff();
    this.studentResponsePub.next(questionId);
  }

  private updateSubmittedAnswerField() {
    const submittedAnswerField: ObjectMap<string | undefined> = {};
    const submission = this.getSubmission();

    for (const { questionId, answerString } of submission) {
      submittedAnswerField[questionId] = answerString;
    }

    this.setState({
      isResponseChanged: false,
      submittedAnswerField,
    });
  }

  private async syncStudentResponse() {
    await this.saveHandler();
    this.updateSubmittedAnswerField();
  }

  private checkResponseDiff() {
    const { answerField, submittedAnswerField } = this.getState();

    const questionIds = new Set([
      ...Object.keys(answerField),
      ...Object.keys(submittedAnswerField),
    ]);

    for (const questionId of questionIds) {
      if (submittedAnswerField[questionId] !== answerField[questionId]) {
        this.setState({ isResponseChanged: true });
        return;
      }
    }

    this.setState({ isResponseChanged: false });
    return;
  }
}

function detailCell(key: string, value: string, isAccessible: boolean) {
  const rowKeyAttrs: any = style(['mediumGrey'], { fontWeight: '700' });
  const rowValueAttrs: any = style(['mediumGrey'], { fontWeight: '400' });
  return h(
    'div',
    style(
      [pad('0.5rem 1rem'), 'flex', 'alignCenter', 'mediumGrey', 'spaceBetween'],
      {},
      { tabIndex: isAccessible ? 0 : undefined }
    ),
    [h('span', rowKeyAttrs, key), h('span', rowValueAttrs, value)]
  );
}
