type GroupedComments = {
  unseen: IComment[][];
  seen: IComment[][];
};
let cachedArgs: [ReadonlyArray<IComment>, number] | null = null;
let cachedResult: GroupedComments | null = null;

/**
 * Group an array of comments into two lists of comment groups based
 * on the number of seen comments.
 * @param comments
 * @param seen
 */
export function groupComments(comments: ReadonlyArray<IComment>, seen: number): GroupedComments {
  if (cachedArgs && cachedArgs[0] === comments && cachedArgs[1] === seen && cachedResult) {
    return cachedResult;
  }

  const seenSlice = comments.slice(0, seen);
  const unseenSlice = comments.slice(seen);
  const result = {
    seen: groupCommentsHelper(seenSlice),
    unseen: groupCommentsHelper(unseenSlice),
  };

  cachedArgs = [comments, seen];
  cachedResult = result;

  return result;
}

/**
 * group an array of comments into a nested array of comments.
 * Each group has a common heading. Comments in same group are
 * consecutive comments that have the same commenter and have been
 * made within 1 hour of each other.
 */
function groupCommentsHelper(comments: ReadonlyArray<IComment>): IComment[][] {
  const result: IComment[][] = [];
  let currentGroup: {
    head: IComment;
    array: IComment[];
  } | null = null;
  for (const comment of comments) {
    if (
      currentGroup &&
      currentGroup.head &&
      currentGroup.head.details.createdBy.userId === comment.details.createdBy.userId &&
      comment.details.createdOn - currentGroup.head.details.createdOn < 3600
    ) {
      currentGroup.array.push(comment);
    } else {
      currentGroup = {
        head: comment,
        array: [comment],
      };
      result.push(currentGroup.array);
    }
  }
  return result;
}
