import { JSONPromise, jsonRequest as _jsonRequest } from 'core/http';

import api from 'acadly/api';
import { INewFeatureUpdates } from 'acadly/app/actions';
import { upload } from 'acadly/upload';
import * as u from 'acadly/utils';

let jsonRequest = _jsonRequest;

export interface IMyCoursesResponse {
  courseData: IMyCoursesResponseCourse[];
  userData: {
    courses?: ObjectMap<IMyCoursesResponseUserData>;
  };
  archivedCoursesAvailable: 0 | 1;
  referralsAllowed: 0 | 1;
}

export interface IMyCoursesResponseUserData {
  role: ICourseRole;
  activitiesSeen?: number;
  courseStatus?: 'active';
  lastActive?: number;
  numCommentsSeen?: number;
  queriesSeen?: number;
  subscribed?: 0 | 1;
  hasPaid: 0 | 1;
  payTill: UnixTimestamp;
}

export type IMyCoursesResponseCourse = ICourseBase<{
  announcements: NumTotal & NumPublished;
  assignments: NumTotal & NumPublished;
  classes: NumTotal & NumPublished;
  discussions: NumTotal & NumPublished;
  numCommentsTotal?: number;
  numQueries: number;
  numQueriesPending: number;
  polls: NumTotal & NumPublished;
  quizzes: NumTotal & NumPublished;
  resources: NumTotal & NumPublished;
}>;

interface IUniversityData {
  universityData: {
    hasEnterpriseAccount: 0 | 1;
    /** empty when hasEnterpriseAccount=0 */
    integratedWith: '' | 'Canvas' | 'Blackboard';
  };
}

export type IFetchMyCoursesResponse = IMyCoursesResponse & INewFeatureUpdates & IUniversityData;

export const fetchMyCourses = () => {
  return jsonRequest<IFetchMyCoursesResponse>(api().allCourses, { method: 'GET' });
};

export const joinCourseByJoinCode = (joinCode: string) => {
  return jsonRequest<IMyCoursesResponse>(api().joinCourseByJoinCode, {
    method: 'POST',
    data: { joinCode },
  });
};

export interface IFetchTimelineResponse {
  courseStatus: ICourseStatus;
  courseTeam: ICourseTeamMember[];
  enrolmentType: ICourseEnrollmentType;
  joinCode: string;
  courseDates: {
    startDate: number;
    startDateL: string;
    endDate: number;
    endDateL: string;
    schedule: ICourseScheduleItem[];
  };
  courseData?: ICourseTimelineItemResponse[];
  userData?: {
    announcements?: ObjectMap<
      | {
          firstAccessedOn?: number;
          timesFetched?: number;
        }
      | undefined
    >;
    assignments?: ObjectMap<
      | {
          firstAccessedOn?: number;
          numCommentsSeen?: number;
          numCommentsSeenPreSub?: number;
          numCommentsSeenPostSub?: number;
          subscribed: 0 | 1;
          // Student specific properties
          retracted?: 0 | 1;
          submittedOn?: number;
          status?: 'late' | 'inProgress' | 'submitted';
          timesStarted?: number;
          graded?: {
            status: 0 | 1;
            score: number;
            on: number;
            lastGradedBy: ICreatedByWithRole;
          };
          lastAccessedOn?: number;
        }
      | undefined
    >;
    classes?: ObjectMap<IResponseCourseClassUserData | undefined>;
    numCommentsSeen?: number;
    subscribed?: 0 | 1;
  };
}
export interface IResponseCourseClassUserData {
  reviewQueries?: {
    numCompleted: number;
    numSeen: number;
    numCommentsSeen?: number;
  };
  inClass?: IClassActivityUserDataResponse;
  preClass?: IClassActivityUserDataResponse;
  numCommentsSeen?: number;
  subscribed: 0 | 1;
  attendance?: {
    auto: boolean;
    checkInTime?: UnixTimestamp;
    checkInTimeL?: string;
    isLate: 0 | 1;
    isCheckedIn: 0 | 1;
    status: 'present' | 'absent' | 'checkedIn';
    visibleStatus: 'present' | 'absent' | 'late' | 'excused';
  };
  summaryAccessedOn: UnixTimestamp;
}

export interface IClassActivityUserDataResponse {
  queries?: {
    numCompleted: number;
    numSeen?: number;
    numCommentsSeen?: number;
  };
  discussions?: {
    numCommentsSeen?: number;
    numSeen?: number;
  };
  quizzes?: {
    numCommentsSeen?: number;
    numSeen?: number;
    numCompleted?: number;
  };
  polls?: {
    numCommentsSeen?: number;
    numSeen?: number;
    numCompleted?: number;
  };
  resources?: {
    numCommentsSeen?: number;
    numSeen?: number;
  };
}
export const fetchTimeline = () =>
  jsonRequest<IFetchTimelineResponse>(api().getCourseDetails, { method: 'GET' });

export interface ICreateCourseResponse {
  courseId: string;
  canStream: 0 | 1;
  /** can start proxy attendance or not */
  canProxy: 0 | 1;
  isPro: 0 | 1;
  status: ICourseStatus;
  details: {
    title: string;
    code: string;
    canStream: 0 | 1;
  };
  team: [ICourseTeamMember];
  totalActivities: 0;
  activities: {
    classes: {
      numTotal: 0;
      numPublished: 0;
    };
    assignments: {
      numTotal: 0;
      numPublished: 0;
    };
    announcements: {
      numTotal: 0;
      numPublished: 0;
    };
    quizzes: {
      numTotal: 0;
      numPublished: 0;
    };
    polls: {
      numTotal: 0;
      numPublished: 0;
    };
    resources: {
      numTotal: 0;
      numPublished: 0;
    };
    discussions: {
      numTotal: 0;
      numPublished: 0;
    };
    numQueries: 0;
    numQueriesPending: 0;
  };
}

export const createCourse = (code: string, title: string, isProCourse = false) => {
  const url = isProCourse ? api().createProCourse : api().courseCreate;
  return jsonRequest<ICreateCourseResponse>(url, {
    method: 'POST',
    data: { code, title },
  });
};

interface IFetchBlueprintCourseResponse {
  message: string;
  blueprintCourses: IBlueprintCourse[];
}

export const fetchBlueprintCourse = () =>
  jsonRequest<IFetchBlueprintCourseResponse>(api().getBlueprintCourses, { method: 'GET' });

export interface IBlueprintCoursePreviewResponse {
  message: string;
  preview: IBlueprintCoursePreview;
}

export const getBlueprintCoursePreview = (courseId: string) =>
  jsonRequest<IBlueprintCoursePreviewResponse>(api().getBlueprintCoursePreview(courseId), {
    method: 'GET',
  });

export interface ICourseInitializeRequest {
  isCopied: 0 | 1;
  copyOf: string; // source course id
  toCopy: {
    description: 0 | 1;
    readingList: 0 | 1;
    topics: 0 | 1;
    syllabus: 0 | 1;
  };
}

export interface ICourseInitializeResponse {
  message: string;
  initialized: 0 | 1;
}

export const initializeCourse = (params: ICourseInitializeRequest) =>
  jsonRequest<ICourseInitializeResponse>(api().courseInitialize, {
    method: 'PUT',
    data: params,
  });

export interface ITimezone {
  campusName: string;
  readable: string;
  timezoneName: string;
}

export interface IFetchTimezones {
  timezones: {
    countryTimezones: ITimezone[];
    universityTimezones: ITimezone[];
  };
}

export const fetchTimezones = () =>
  jsonRequest<IFetchTimezones>(api().courseTimezoneGet, {
    method: 'GET',
  });

export const setCourseTimezone = (timezoneName: string) =>
  jsonRequest<void>(api().courseTimezoneSet, {
    method: 'POST',
    data: {
      timezone: timezoneName,
    },
  });

export interface IFetchCourseInfoResponse {
  courseId: string;
  description: string;
  readingList: {
    books: ICourseBook[];
    links: ICourseLink[];
    files: ICourseFile[];
  };
  status: ICourseStatus;
  team: ICourseTeamMember[];
  officeHours?: {
    day: 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';
    startTime: string; // HH:mm
    endTime: string; // HH:mm
    venue: string;
  }[];
}
export const fetchCourseInfo = (): JSONPromise<IFetchCourseInfoResponse> =>
  jsonRequest(api().courseInfo, {
    method: 'GET',
  });

export const setCourseDescription = (description: string): JSONPromise<any> =>
  jsonRequest(api().courseDescEdit, {
    method: 'POST',
    data: {
      description,
    },
  });

export interface IAddTeamMemberResponse {
  userId: string;
  avatar: string;
  name: string;
  emailId: string;
  role: 'instructor' | 'ta';
  status: 'active' | 'invited';
  message: string;
}

export const addTeamMember = (
  emailId: string,
  role: 'instructor' | 'ta'
): JSONPromise<IAddTeamMemberResponse> =>
  jsonRequest(api().courseTeamMemberAdd, {
    method: 'POST',
    data: {
      role,
      emailId,
    },
  });

export const removeTeamMember = (memberId: string): JSONPromise<any> =>
  jsonRequest(api().courseTeamMemberRemove, {
    method: 'POST',
    data: {
      memberId,
    },
  });

export interface IAddBookRequest {
  title: string;
  author: string;
  isbn: string;
  recommended: 0 | 1;
  parent: 'course' | 'topic';
}
export const addBook = (book: IAddBookRequest): JSONPromise<{ bookId: string }> =>
  jsonRequest(api().syllabusAddBook, {
    method: 'POST',
    data: book,
  });

export const fetchBooks = (bookIds: string[]): JSONPromise<{ books: ITopicBook[] }> =>
  jsonRequest(api().syllabusGetBooksById, {
    method: 'POST',
    data: {
      bookIds,
    },
  });

export const fetchLinks = (linkIds: string[]): JSONPromise<{ links: ILink[] }> =>
  jsonRequest(api().syllabusGetLinksById, {
    method: 'POST',
    data: { linkIds },
  });

export interface IEditBookRequest {
  bookId: string;
  title: string;
  author: string;
  isbn: string;
  recommended: 0 | 1;
}
export const editBook = (book: IEditBookRequest): JSONPromise<any> =>
  jsonRequest(api().syllabusEditBook, {
    method: 'POST',
    data: book,
  });

export const removeBook = (bookId: string): JSONPromise<any> =>
  jsonRequest(api().syllabusRemoveBook, {
    method: 'DELETE',
    data: {
      bookId,
    },
  });

export const publishInfo = (): JSONPromise<any> =>
  jsonRequest(api().courseInfoPublish, {
    method: 'GET',
  });

/**
 * @param date Start date in YYYY-MM-DD format
 */
export const setStartDate = (
  date: string
): JSONPromise<{
  startDate: number;
  startDateL: string;
}> =>
  jsonRequest(api().courseStartDateSet, {
    method: 'POST',
    data: {
      startDate: date,
    },
  });

/**
 * @param date End date in YYYY-MM-DD format
 */
export const setEndDate = (
  date: string
): JSONPromise<{
  endDate: number;
  endDateL: string;
}> =>
  jsonRequest(api().courseEndDateSet, {
    method: 'POST',
    data: {
      endDate: date,
    },
  });

export interface ICheckNumSessionResponse {
  message: string;
  numSessions: number;
}

export const checkNumSessions = () =>
  jsonRequest<ICheckNumSessionResponse>(api().checkNumSessions, { method: 'GET' });

export interface IPublishScheduleRequest {
  enrolment: ICourseEnrollmentType;
}
export interface IPublishScheduleResponse {
  courseData: ICourseTimelineItemResponse[];
  courseStatus: ICourseStatus;
  enrolmentType: ICourseEnrollmentType;
  joinCode: string;
}
export const publishSchedule = (
  data: IPublishScheduleRequest
): JSONPromise<IPublishScheduleResponse> =>
  jsonRequest(api().courseSchedulePublish, {
    method: 'PUT',
    data,
  });

export const goLive = () => jsonRequest<{ joinCode: string }>(api().courseLive, { method: 'GET' });

export const fetchStudentsAnalytics = (): JSONPromise<Partial<IAnalyticsCourseStudents>> =>
  jsonRequest(api().analyticsStudentsData, { method: 'GET' });

export interface ISyllabusFetchResponse {
  syllabus: IAttachment | { name: undefined };
  topics: ITopic[];
}
export const fetchCourseSyllabus = (): JSONPromise<ISyllabusFetchResponse> =>
  jsonRequest(api().syllabusGetTopic, {
    method: 'GET',
  });

export interface IAddTopicRequest {
  parentId: string;
  title: string;
  books: string[];
  links: string[];
}
export const addTopic = (data: IAddTopicRequest): JSONPromise<{ topicId: string }> =>
  jsonRequest(api().syllabusAddTopic, {
    method: 'POST',
    data,
  });

export interface IDeleteTopicRequest {
  topicId: string;
  type: 'topic' | 'subTopic';
}
export const deleteTopic = (data: IDeleteTopicRequest) =>
  jsonRequest<void>(api().syllabusRemoveTopic, {
    method: 'DELETE',
    data,
  });

export interface IEditTopicRequest {
  topicId: string;
  title: string;
  books: string[];
  links: string[];
}
export const editTopic = (data: IEditTopicRequest) =>
  jsonRequest<void>(api().syllabusEditTopic, {
    method: 'POST',
    data,
  });

export interface IAddStudentsResponseStudent<Status> {
  userId: string;
  name: string;
  avatar: string;
  emailId: string;
  role: 'student';
  status: Status;
}
export interface IAddStudentsResponse {
  added: IAddStudentsResponseStudent<'active'>[];
  invited: IAddStudentsResponseStudent<'invited'>[];
  preEnrolled: IAddStudentsResponseStudent<'added' | 'invited'>[];
  removed: IAddStudentsResponseStudent<'added' | 'invited'>[];
  invalidEmails: {
    message: string;
    emailIds: string[];
  };
}
export const addStudents = (emails: string[]): JSONPromise<IAddStudentsResponse> =>
  jsonRequest(api().courseStudentsAdd, {
    method: 'POST',
    data: {
      role: 'student',
      emailIds: emails,
    },
  });

export const reinstateStudent = (userId: string) =>
  jsonRequest<void>(api().studentReinstate, {
    method: 'POST',
    data: {
      memberId: userId,
    },
  });

export const resendInvite = () =>
  jsonRequest(api().studentResendInvite, {
    method: 'POST',
  });

export interface IAddLinkRequest {
  title: string;
  url: string;
  parent: 'topic' | 'course';
}

export interface IAddLinkResponse {
  linkId: string;
}
export const addLink = (data: IAddLinkRequest): JSONPromise<IAddLinkResponse> =>
  jsonRequest(api().syllabusAddLink, {
    method: 'POST',
    data: data,
  });

export interface IRemoveLinkRequest {
  linkId: string;
}
export const removeLink = (data: IRemoveLinkRequest) =>
  jsonRequest(api().courseInfoLinkRemove, {
    method: 'DELETE',
    data,
  });

export interface IAnalyticsAveragesFetchData {
  attendance: {
    recordedClasses: number;
    checkIns: number;
    lateCheckIns: number;
    presents: number;
  };
  scores: {
    overall: {
      max: number;
      attained: number;
      onTimeInstances: number;
      lateInstances: number;
    };
    assignments: {
      max: number;
      attained: number;
      onTimeInstances: number;
      lateInstances: number;
    };
    quizzes: {
      max: number;
      attained: number;
      onTimeInstances: number;
      lateInstances: number;
    };
    exams: {
      max: number;
      attained: number;
      instances: number;
    };
  };
}

export interface IAnalyticsAveragesFetchResponse {
  averages: IAnalyticsAveragesFetchData;
}

export const analyticsAveragesFetch = () =>
  jsonRequest<IAnalyticsAveragesFetchResponse>(api().analyticsAverage, { method: 'GET' });

export interface IAnalyticsSelfResponse {
  courseAverages: {
    attendance: {
      recordedClasses: number;
      checkIns: number;
      lateCheckIns: number;
      presents: number;
    };
    scores: {
      overall: {
        max: number;
        attained: number;
        onTimeInstances: number;
        lateInstances: number;
      };
      assignments: {
        max: number;
        attained: number;
        graded: number;
        onTimeInstances: number;
        lateInstances: number;
      };
      quizzes: {
        max: number;
        attained: number;
        onTimeInstances: number;
        lateInstances: number;
      };
      exams: {
        max: number;
        attained: number;
        instances: number;
      };
    };
  };
  studentAverages: IStudentAverages;
}

export interface IStudentAverages {
  classes: {
    /**
     * the total number of check-ins not just on time ones.
     */
    onTime: number;
    late: number;
    present: number;
    participation: {
      instances: number;
      totalPoints: number;
    };
  };
  assignments: {
    onTime: number;
    late: number;
    score: number;
    sumMaxScore: number;
    numPublished: number;
    numGraded: number;
    publishedTotalScore: number;
  };
  quizzes: {
    onTime: number;
    late: number;
    score: number;
    sumMaxScore: number;
    numPublished: number;
    publishedTotalScore: number;
  };
  polls: {
    onTime: number;
    late: number;
    numPublished: number;
  };
  discussions: {
    participatedIn: number;
  };
  queries: {
    initiated: number;
    contributedTo: number;
  };
}

export const analyticsSelfFetch = () =>
  jsonRequest<IAnalyticsSelfResponse>(api().analyticsSelfDataV2, {
    method: 'GET',
  });

export interface IIndividualStudentAnalyticsFetchResponse {
  averages: IStudentAverages;
}
export const individualStudentAnalyticsFetch = (studentId: string) =>
  jsonRequest<IIndividualStudentAnalyticsFetchResponse>(
    api().analyticsIndvStudentDataV2(studentId),
    {
      method: 'GET',
    }
  );

export const studentRemove = (memberId: string) =>
  jsonRequest(api().courseStudentRemove, {
    method: 'POST',
    data: { memberId },
  });

export const courseArchive = () =>
  jsonRequest(api().archiveCourse, {
    method: 'PUT',
  });

export interface IArchivedCoursesFetchResponse {
  courseData: {
    _id: string;
    details: IMyCoursesResponseCourse['details'];
    dates: {
      startDate: UnixTimestamp;
      endDate: UnixTimestamp;
    };
    team: ICourseTeamMember[];
    lastUpdated: UnixTimestamp;
    totalActivities: IMyCoursesResponseCourse['totalActivities'];
    activities: IMyCoursesResponseCourse['activities'];
    courseTimezone: string;
  }[];
}
export const archivedCoursesFetch = () =>
  jsonRequest<IArchivedCoursesFetchResponse>(api().getArchivedCourses, {
    method: 'GET',
  });

export interface ICopyActivityCandidateCoursesFetchResponse {
  courses: {
    _id: string;
    courseTimezone: string;
    details: ICourse['details'];
    status: ICourse['status'];
    team: ICourse['team'];
    dates: ICourse['dates'];
  }[];
}
export const copyActivityCandidateCoursesFetch = () =>
  jsonRequest<ICopyActivityCandidateCoursesFetchResponse>(api().copyActivityCandidateCourses, {
    method: 'GET',
  });

export interface ICopyActivityCandidateClassesFetchResponse {
  classes: {
    _id: string;
    activities: IClass['activities'];
    details: IClass['details'];
    info: IClass['info'];
  }[];
  message: 'success';
}
export const copyActivityCandidateClassesFetch = (courseId: string) =>
  jsonRequest<ICopyActivityCandidateClassesFetchResponse>(api().classActivityCopyCandidates, {
    method: 'GET',
    headers: {
      courseId: courseId,
    },
  });

export type ICopyActivityRequest =
  | {
      activityType: 'polls' | 'quizzes' | 'resources' | 'discussions';
      activityId: string;
      copyToClass: string;
      copyToCourse: string;
      toBeDone: 'preClass' | 'inClass';
    }
  | {
      activityType: 'assignments';
      activityId: string;
      copyToCourse: string;
    };

export const copyActivity = (data: ICopyActivityRequest) =>
  jsonRequest(api().copyActivity, {
    method: 'POST',
    data,
  });

export interface IAdminCoursesFetchResponse {
  courseData: (IMyCoursesResponse['courseData'][number] & {
    students: number;
  })[];
}
export const adminCoursesFetch = () =>
  jsonRequest<IAdminCoursesFetchResponse>(api().getAdminCourses, {
    method: 'GET',
  });

export const remove = (courseId: string) =>
  jsonRequest(api().courseRemove, {
    method: 'DELETE',
    headers: {
      courseId: courseId,
    },
  });

export interface INoRecordRequest {
  classes: string[];
}

export const classStatusUpdate = (data: INoRecordRequest) => {
  console.log('classStatusUpdate');
  jsonRequest(api().classNoRecord, {
    method: 'POST',
    data,
  });
};

// export const infoFileUpload = (file: File) => {
//     const { name, extension } = u.splitFileName(file.name);
//     const { progress$, promise } = upload(
//         api().courseInfoFileUpload,
//         {
//             originalFileName: name,
//             fileType: extension
//         },
//         file
//     );
//     return {
//         progress$,
//         promise: promise.then(response => {
//             return infoFileUploadConfirm({
//                 originalFileName: name,
//                 generatedFileName: response.name,
//                 fileType: extension
//             });
//         })
//     };
// };

export interface IInfoFileUploadConfirmRequest {
  originalFileName: string;
  generatedFileName: string;
  fileType: string;
}

export interface IInfoFileUploadConfirmResponse {
  message: 'success';
  _id: string;
}
export const infoFileUploadConfirm = (data: IInfoFileUploadConfirmRequest) =>
  jsonRequest<IInfoFileUploadConfirmResponse>(api().courseInfoFileUploadConfirm, {
    method: 'POST',
    data,
  });

export const infoFileRemove = (fileId: string) =>
  jsonRequest(api().courseInfoFileRemove, {
    method: 'DELETE',
    data: {
      fileId: fileId,
    },
  });

export interface ISyllabusFileUploadRequest {
  originalFileName: string;
  fileType: string;
}

export const syllabusFileUpload = (file: File) => {
  const { name, extension } = u.splitFileName(file.name);
  const { progress$, promise } = upload(
    api().syllabusFileUpload,
    {
      originalFileName: name,
      fileType: extension,
    },
    file
  );
  const resultPromise: Promise<IAttachment> = promise.then((response) => {
    return jsonRequest(api().syllabusFileUploadConfirm, {
      method: 'POST',
      data: {
        originalFileName: name,
        fileType: extension,
        generatedFileName: response.name,
      },
    }).then(
      (): IAttachment => ({
        originalName: name,
        extension: extension,
        name: response.name,
      })
    );
  });
  return {
    progress$,
    promise: resultPromise,
  };
};

export const courseInfoFileUpload = (file: File) => {
  const { name, extension } = u.splitFileName(file.name);
  const { progress$, promise } = upload(
    api().courseInfoFileUpload,
    {
      originalFileName: name,
      fileType: extension,
    },
    file
  );
  const resultPromise: Promise<ICourseFile> = promise.then((response) => {
    return jsonRequest<IInfoFileUploadConfirmResponse>(api().courseInfoFileUploadConfirm, {
      method: 'POST',
      data: {
        originalFileName: name,
        fileType: extension,
        generatedFileName: response.name,
      },
    }).then(
      (confirmationResponse): ICourseFile => ({
        _id: confirmationResponse.data._id,
        originalName: name,
        extension: extension,
        name: response.name,
      })
    );
  });
  return {
    progress$,
    promise: resultPromise,
  };
};

export type IOfficeHoursSaveRequest = {
  officeHours: {
    day: IWeekDay;
    startTime: string; // HH:mm
    endTime: string; // HH:mm
    venue: string;
  }[];
};

export const officeHoursSave = (data: IOfficeHoursSaveRequest) =>
  jsonRequest(api().courseInfoOfficeHoursSave, {
    method: 'POST',
    data,
  });

export function inject(dependencies: { jsonRequest: typeof _jsonRequest }) {
  jsonRequest = dependencies.jsonRequest;
}
