import { CSS, h } from 'core';

import CheckBox from 'acadly/common/CheckBox';
import RadioButton from 'acadly/common/RadioButton';
import Viewer from 'acadly/rich-text/Viewer';
import { colors, mb, mt, pad, style } from 'acadly/styles';
import * as utils from 'acadly/utils';

interface IQuestionProps {
  ariaLabel?: string;
  isAccessible?: boolean;
  hideAnswers?: boolean;
  quiz: ISuggestedQuiz;
  title: string;
  question: ISuggestedQuizQuestion;
}

/**
 * Checks if the option is marked correct or wrong by professor
 * @param question quiz question data
 * @param option "t" or "f" or option position at the time of question creation for "mcq"
 */
function isCorrectOption(question: ISuggestedQuizQuestion, option: string | number) {
  const answerKey = question.details.answerKey;

  if (!answerKey) return false;
  if (question.details.type === 'tf') return answerKey === option;

  const positionIndex = (option as number) - 1;
  return answerKey[positionIndex] === '1';
}

/**
 * Calculate the color of the option label
 */
function getOptionColor(args: {
  question: ISuggestedQuizQuestion; // quiz question data
  option: string | number; // "t" or "f" or original option position for "mcq"
  hideAnswers?: boolean; // hide answers or not
}): string {
  const { question, option, hideAnswers } = args;

  if (hideAnswers) return colors.mediumGrey;

  return isCorrectOption(question, option) ? colors.blue : colors.mediumGrey;
}

const TFOption = (props: IQuestionProps, option: 't' | 'f') => {
  const { question } = props;
  const hideAnswers = props.hideAnswers;

  const labelColor = getOptionColor({
    option,
    question,
    hideAnswers,
  });

  return h(
    'div.tf-question-option',
    style([
      'flex',
      'alignCenter',
      pad('0.5rem'),
      {
        color: labelColor,
      },
    ]),
    [
      RadioButton({
        selected: isCorrectOption(question, option),
        color: labelColor,
        style: {
          marginRight: '1em',
        },
      }),
      h('span', style(['thick']), option === 't' ? 'True' : 'False'),
    ]
  );
};

const MCQOption = (props: IQuestionProps, index: number) => {
  const { question, isAccessible } = props;
  const option = question.details.options[index];
  const position = option.num; // original option position while question was created

  const hideAnswers = props.hideAnswers;

  const labelColor = getOptionColor({
    question,
    hideAnswers,
    option: position,
  });

  return h(
    'div.mcq-question-option',
    style(
      [
        'borderBox',
        pad('0.75rem 0.5rem'),
        {
          borderBottom:
            question.details.options.length !== index + 1
              ? `1px solid ${colors.lightestGrey}`
              : undefined,
        },
      ],
      {},
      {
        key: `${question._id}-option-${index}`,
        tabIndex: isAccessible ? 0 : undefined,
      }
    ),
    [
      h(
        'div.option-label',
        style([
          'flex',
          'alignCenter',
          mb('0.5rem'),
          {
            color: labelColor,
          },
        ]),
        [
          CheckBox({
            selected: isCorrectOption(question, position),
            color: labelColor,
            style: {
              marginRight: '1em',
            },
          }),
          h('span', style(['thick']), `OPTION ${utils.indexToAlphabet(index)}`),
        ]
      ),
      Viewer(option.text),
    ]
  );
};

const Question = (props: IQuestionProps) => {
  const { ariaLabel, isAccessible, question, title } = props;

  return h(
    '.quiz-question',
    style(
      [
        'flex',
        'column',
        pad('1rem'),
        mt('1rem'),
        {
          backgroundColor: 'white',
        },
      ],
      {},
      {
        ariaLabel,
        key: question._id,
        tabIndex: isAccessible ? 0 : undefined,
      }
    ),
    [
      h(
        'div.quiz-question-title',
        style(['flex', 'alignCenter', 'spaceBetween', mb('0.75rem'), 'large', 'thick']),
        title
      ),
      h('div.quiz-question-body', style(['flex', 'column', 'borderBox']), [
        Viewer(
          question.details.description.text,
          style(
            [mb('0.5rem')],
            {},
            {
              tabIndex: isAccessible ? 0 : undefined,
            }
          )
        ),

        question.details.type === 'tf'
          ? h('div.tf-question-options', [TFOption(props, 't'), TFOption(props, 'f')])
          : h(
              'div.mcq-question-options',
              question.details.options.map((_option, index) => MCQOption(props, index))
            ),
      ]),
    ]
  );
};

interface IQuestionsProps {
  style?: CSS;
  isAccessible?: boolean;
  quiz: ISuggestedQuiz;
  hideAnswers?: boolean;
  questions: ISuggestedQuizQuestion[];
}

export default (props: IQuestionsProps) => {
  const questions = props.questions;

  return h(
    'div.quiz-questions',
    { style: props.style },
    questions.map((question, index) =>
      Question({
        question,
        quiz: props.quiz,
        hideAnswers: props.hideAnswers,
        title: `Question ${index + 1}`,
        isAccessible: props.isAccessible,
        ariaLabel: `Question ${index + 1}`,
      })
    )
  );
};
