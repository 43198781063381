import { CSS, h } from 'core';

import CheckBox from 'acadly/common/CheckBox';
import Hover from 'acadly/common/Hover';
import Icon from 'acadly/common/Icon';
import { withLoader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import * as dt from 'acadly/datetime';
import icons from 'acadly/icons';
import Viewer from 'acadly/rich-text/Viewer';
import { getStore } from 'acadly/store';
import { colors, mb, mt, pad, style } from 'acadly/styles';
import * as utils from 'acadly/utils';

type OptionSubmissionResult = -1 | 0 | 1;

interface IQuestionPropsMixin {
  ariaLabel?: string;
  isAccessible?: boolean;
  quiz: IQuiz;
  question: IQuizQuestion;
  title: string;
}

interface ISubmissionModeQuestionProps extends IQuestionPropsMixin {
  mode: 'submission';
  submission?: IQuizQuestionSubmission;
}

interface IAnswerModeQuestionProps extends IQuestionPropsMixin {
  mode: 'answer';
  isEditable: boolean;
  hideAnswers?: boolean;
  showAnalytics: boolean;
  onClickAnalytics?: (questionId: string) => any;
  onClickEdit?: (type: IQuizQuestionType, question: IQuizQuestion) => any;
}

type IQuestionProps = ISubmissionModeQuestionProps | IAnswerModeQuestionProps;

/**
 * Checks if the option is marked correct or wrong by professor
 * @param question quiz question data
 * @param option "t" | "f" for "tf", option num for "mcq"
 */
function isCorrectOption(question: IQuizQuestion, option: string | number) {
  const answerKey = question.details.answerKey;

  if (!answerKey) return false;

  switch (question.details.type) {
    case 'tf':
      return answerKey === option;
    case 'mcq':
      return answerKey[(option as number) - 1] === '1';
    default:
      return false;
  }
}

/**
 * Checks if the option marked by student is correct or not.
 * Returns 0 for neutral result, 1/-1 for correct/wrong answer
 * @param question quiz question data
 * @param option "t" | "f" for "tf", option num for "mcq", orderKey for "reorder"
 * @param submission student's submission data
 */
function getOptionSubmissionResult(
  question: IQuizQuestion,
  option: string | number,
  submission?: IQuizQuestionSubmission
): OptionSubmissionResult {
  const answerKey = question.details.answerKey;
  if (!answerKey) {
    // neutral, because answers are hidden
    return 0;
  }

  const shouldBeSelected = isCorrectOption(question, option);

  if (!submission || submission.answerString === '0000') {
    // If not question attempted then mark correct option as wrong answer
    return shouldBeSelected ? -1 : 0;
  }

  switch (question.details.type) {
    case 'tf': {
      const isSelected = submission.answerString === option;

      if (shouldBeSelected) {
        // correct if selected, otherwise neutral
        return isSelected ? 1 : 0;
      }

      // wrong if selected, otherwise neutral
      return isSelected ? -1 : 0;
    }
    case 'mcq': {
      const optionIndex = (option as number) - 1;
      const isSelected = submission.answerString[optionIndex] === '1';

      if (shouldBeSelected) {
        // correct if selected, otherwise wrong
        return isSelected ? 1 : -1;
      }

      // wrong if selected, otherwise neutral
      return isSelected ? -1 : 0;
    }
    case 'reorder': {
      const selectedOptionIndex = submission.answerString
        .split('-')
        .findIndex((orderKey) => orderKey === option);

      const correctOptionIndex = answerKey.split('-').findIndex((orderKey) => orderKey === option);

      if (correctOptionIndex < 0) return 0;

      // correct if option is in right position otherwise wrong
      return selectedOptionIndex === correctOptionIndex ? 1 : -1;
    }
    default:
      return 0;
  }
}

/**
 * Checks if quiz is unsubmitted
 * @param quiz quiz data
 */
function isQuizUnsubmitted(quiz: IQuiz) {
  return (
    quiz.details.dueDateTime < dt.unix() &&
    quiz.details.allowLate === 0 &&
    (!quiz.userData || quiz.userData.status === 'inProgress')
  );
}

/**
 * Checks if options is selected or not
 */
function isOptionSelected(args: {
  mode: IQuestionProps['mode']; // question view mode
  quiz: IQuiz; // quiz data
  question: IQuizQuestion; // quiz question data
  option: string | number; // "t" | "f" for "tf", option num for "mcq"
  submission?: IQuizQuestionSubmission; // student's submission data
  hideAnswers?: boolean; // hide answers or not
}): boolean {
  const { mode, quiz, question, option, submission, hideAnswers } = args;

  if (hideAnswers) return false;

  if (mode === 'answer') {
    // show correct option as selected in answer mode
    return isCorrectOption(question, option);
  }

  if (isQuizUnsubmitted(quiz) || !submission) {
    // quiz not attempted
    return false;
  }

  switch (question.details.type) {
    case 'tf':
      return submission.answerString === option;
    case 'mcq':
      return submission.answerString[(option as number) - 1] === '1';
    default:
      return false;
  }
}

/**
 * Calculate the color of the option label
 */
function getOptionColor(args: {
  mode: IQuestionProps['mode']; // question view mode
  quiz: IQuiz; // quiz data
  question: IQuizQuestion; // quiz question data
  option: string | number; // "t" | "f" for "tf", option num for "mcq", orderKey for "reorder"
  submission?: IQuizQuestionSubmission; // student's submission data
  hideAnswers?: boolean; // hide answers or not
}): string {
  const { mode, quiz, question, option, submission, hideAnswers } = args;

  if (hideAnswers) return colors.mediumGrey;

  if (mode === 'answer') {
    return question.details.type !== 'reorder' && isCorrectOption(question, option)
      ? colors.blue
      : colors.mediumGrey;
  }

  if (isQuizUnsubmitted(quiz)) {
    return question.details.type === 'reorder' || isCorrectOption(question, option)
      ? colors.red
      : colors.mediumGrey;
  }

  const optionSubmissionResult = getOptionSubmissionResult(question, option, submission);

  switch (optionSubmissionResult) {
    case 1:
      return colors.blue;
    case 0:
      return colors.mediumGrey;
    case -1:
      return colors.red;
    default:
      return colors.mediumGrey;
  }
}

/**
 * Calculates question submission marks.
 * Returns null if there is no submission
 * @param quiz quiz data
 * @param submission student's submission data
 */
function getScore(quiz: IQuiz, submission?: IQuizQuestionSubmission) {
  return submission && submission.score ? submission.score[quiz.details.scoring] : null;
}

const OptionTitleAction = (props: IQuestionProps) => {
  const { question, quiz, isAccessible, title } = props;
  if (props.mode === 'submission') {
    const score = getScore(quiz, props.submission);

    if (
      !props.question.details.answerKey &&
      props.submission?.answerString &&
      props.submission?.answerString !== '0000'
    ) {
      return h('span.tag.blue', 'Attempted');
    }

    return score !== null
      ? h(
          'div',
          style(
            ['blue', 'mlAuto'],
            {},
            {
              tabIndex: isAccessible ? 0 : undefined,
              'aria-label': `scored ${score}/4 for this question`,
            }
          ),
          `${score}/4`
        )
      : null;
  } else {
    if (props.isEditable) {
      return Hover(
        {
          color: colors.blue,
        },
        h(
          'i.fa.fa-pencil',
          style(
            ['pointer', 'mlAuto', 'lightGrey'],
            {},
            {
              role: 'button',
              ariaLabel: `Edit ${title}`,
              tabIndex: isAccessible ? 0 : undefined,
              onclick: () =>
                props.onClickEdit ? props.onClickEdit(question.details.type, question) : null,
            }
          )
        )
      );
    } else if (props.showAnalytics) {
      return Icon(icons.analytics, {
        tabIndex: isAccessible ? 0 : undefined,
        style: {
          cursor: 'pointer',
          marginLeft: 'auto',
          color: colors.blue,
          marginRight: '0.5rem',
          fontSize: '1.4em',
        },
        role: 'button',
        ariaLabel: `Question analytics`,
        onclick: () => (props.onClickAnalytics ? props.onClickAnalytics(question._id) : null),
      });
    }
  }
  return null;
};

const TFOption = (props: IQuestionProps, option: 't' | 'f') => {
  const { question, quiz } = props;

  const submission = props.mode === 'submission' ? props.submission : undefined;

  const hideAnswers = props.mode === 'answer' ? props.hideAnswers : undefined;

  const labelColor = getOptionColor({
    quiz,
    option,
    question,
    submission,
    hideAnswers,
    mode: props.mode,
  });

  const isSelected = isOptionSelected({
    quiz,
    option,
    question,
    submission,
    hideAnswers,
    mode: props.mode,
  });

  return h(
    'div.tf-question-option',
    style([
      'flex',
      'alignCenter',
      pad('0.5rem'),
      {
        color: labelColor,
      },
    ]),
    [
      RadioButton({
        selected: isSelected,
        color: labelColor,
        style: {
          marginRight: '1em',
        },
      }),
      h('span', style(['thick']), option === 't' ? 'True' : 'False'),
    ]
  );
};

const MCQOption = (props: IQuestionProps, index: number) => {
  const { question, isAccessible, quiz } = props;
  if (question.details.type !== 'mcq') return null;

  const option = question.details.options[index];
  const position = option.num; // original option position while question was created

  const submission = props.mode === 'submission' ? props.submission : undefined;

  const hideAnswers = props.mode === 'answer' ? props.hideAnswers : undefined;

  const labelColor = getOptionColor({
    quiz,
    question,
    submission,
    hideAnswers,
    option: position,
    mode: props.mode,
  });

  const isSelected = isOptionSelected({
    quiz,
    question,
    submission,
    hideAnswers,
    option: position,
    mode: props.mode,
  });

  return h(
    'div.mcq-question-option',
    style(
      [
        'borderBox',
        pad('0.75rem 0.5rem'),
        {
          borderBottom:
            question.details.options.length !== index + 1
              ? `1px solid ${colors.lightestGrey}`
              : undefined,
        },
      ],
      {},
      {
        key: `${question._id}-option-${index}`,
        tabIndex: isAccessible ? 0 : undefined,
      }
    ),
    [
      h(
        'div.option-label',
        style([
          'flex',
          'alignCenter',
          mb('0.5rem'),
          {
            color: labelColor,
          },
        ]),
        [
          CheckBox({
            selected: isSelected,
            color: labelColor,
            style: {
              marginRight: '1em',
            },
          }),
          h('span', style(['thick']), `OPTION ${utils.indexToAlphabet(index)}`),
        ]
      ),
      Viewer(option.text),
    ]
  );
};

const ReorderQuestionOption = (props: IQuestionProps, index: number) => {
  const { quiz, question, isAccessible } = props;
  if (question.details.type !== 'reorder') return null;

  if (props.mode === 'answer' && props.hideAnswers) return null;

  const option = question.details.options[index];
  const submission = props.mode === 'submission' ? props.submission : undefined;
  const hideAnswers = props.mode === 'answer' ? props.hideAnswers : undefined;

  const labelColor = getOptionColor({
    quiz,
    option: option.orderKey,
    question,
    submission,
    hideAnswers,
    mode: props.mode,
  });

  const selectedOptionIndex = question.details.options.findIndex(
    (o) => o.orderKey === submission?.answerString.split('-')[index]
  );

  return h(
    'div.reorder-question-option',
    style(
      [
        'borderBox',
        pad('0.75rem 0.5rem'),
        {
          borderBottom:
            question.details.options.length !== index + 1
              ? `1px solid ${colors.lightestGrey}`
              : undefined,
        },
      ],
      {},
      {
        key: `${question._id}-option-${index}`,
        tabIndex: isAccessible ? 0 : undefined,
      }
    ),
    [
      h(
        'div.option-label',
        style([
          'flex',
          'alignCenter',
          mb('0.5rem'),
          {
            color: labelColor,
          },
        ]),
        [
          h('span', style(['thick']), [
            `OPTION ${utils.indexToAlphabet(index)}`,
            props.mode === 'submission' && selectedOptionIndex >= 0 && question.details.answerKey
              ? ` (Picked: ${utils.indexToAlphabet(selectedOptionIndex)})`
              : null,
          ]),
        ]
      ),
      Viewer(option.text),
    ]
  );
};

const Question = (props: IQuestionProps) => {
  const { ariaLabel, isAccessible, question, title } = props;

  return h(
    'div.quiz-question',
    style(
      [
        'flex',
        'column',
        pad('1rem'),
        mt('1rem'),
        {
          backgroundColor: 'white',
        },
      ],
      {},
      {
        ariaLabel,
        key: question._id,
        tabIndex: isAccessible ? 0 : undefined,
      }
    ),
    [
      h(
        'div.quiz-question-title',
        style(['flex', 'alignCenter', 'spaceBetween', mb('0.75rem'), 'large']),
        [h('span.question-num', style(['thick']), title), OptionTitleAction(props)]
      ),
      h('div.quiz-question-body', style(['flex', 'column', 'borderBox']), [
        Viewer(
          question.details.description.text,
          style(
            [mb('0.5rem')],
            {},
            {
              tabIndex: isAccessible ? 0 : undefined,
            }
          )
        ),

        question.details.type === 'tf'
          ? h('div.tf-question-options', [TFOption(props, 't'), TFOption(props, 'f')])
          : question.details.type === 'mcq'
          ? h(
              'div.mcq-question-options',
              question.details.options.map((_option, index) => MCQOption(props, index))
            )
          : question.details.type === 'reorder'
          ? h(
              'div.mcq-question-options',
              question.details.options.map((_option, index) => ReorderQuestionOption(props, index))
            )
          : null,
      ]),
    ]
  );
};

interface IQuestionsPropsMixin {
  style?: CSS;
  isAccessible?: boolean;
  quiz: IQuiz;
  questions?: IQuizQuestion[];
}

interface IAnswerModeQuestionsProps extends IQuestionsPropsMixin {
  mode: 'answer';
  hideAnswers?: boolean;
  isEditable?: boolean;
  onClickAnalytics?(questionId: string): any;
  openEditQuestionScreen?: (type: IQuizQuestionType, question: IQuizQuestion) => any;
}

interface ISubmissionModeQuestionsProps extends IQuestionsPropsMixin {
  mode: 'submission';
  submission?: IQuizSubmission;
}

type IQuestionsProps = IAnswerModeQuestionsProps | ISubmissionModeQuestionsProps;

export default (props: IQuestionsProps) => {
  const quizState = getStore().getState().quizzes;
  const questions = props.questions
    ? props.questions
    : quizState && quizState.current
    ? quizState.current.questions
    : null;

  if (props.mode === 'submission') {
    return withLoader(
      questions === null,
      h(
        'div.quiz-questions',
        { style: props.style },
        (questions || []).map((question, index) =>
          Question({
            question,
            mode: props.mode,
            quiz: props.quiz,
            title: `Question ${index + 1}`,
            isAccessible: props.isAccessible,
            ariaLabel: `Question ${index + 1}`,
            submission: (props.submission || {})[question._id],
          })
        )
      )
    );
  }

  return withLoader(
    questions === null,
    h(
      'div.quiz-questions',
      { style: props.style },
      (questions || []).map((question, index) =>
        Question({
          question,
          mode: props.mode,
          quiz: props.quiz,
          hideAnswers: props.hideAnswers,
          title: `Question ${index + 1}`,
          isAccessible: props.isAccessible,
          ariaLabel: `Question ${index + 1}`,
          isEditable: !!props.isEditable,
          showAnalytics: !!props.onClickAnalytics,
          onClickAnalytics: props.onClickAnalytics,
          onClickEdit: props.openEditQuestionScreen,
        })
      )
    )
  );
};
