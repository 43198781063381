import { h, IComponent } from 'core';
import { Route, Switch } from 'core/router';

import { Actions as AppActions } from 'acadly/app/actions';
import appService from 'acadly/app/service';
import assignmentService from 'acadly/assignment/service';
import { getCommentsContextService } from 'acadly/comments-context';
import Icon from 'acadly/common/Icon';
import IconButton from 'acadly/common/IconButton';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import courseService from 'acadly/course/service';
import { wordCloudIcon } from 'acadly/discussion/DiscussionWordCloud';
import discussionService from 'acadly/discussion/service';
import icons from 'acadly/icons';
import pollService from 'acadly/poll/service';
import queryService from 'acadly/query/service';
import quizService from 'acadly/quiz/service';
import resourceService from 'acadly/resource/service';
import { goBack, Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { colors, ml, style } from 'acadly/styles';
import * as u from 'acadly/utils';
import { throttle } from 'acadly/utils';

import { Actions } from './actions';

interface IHeaderProps {
  toggleSidebar: () => void;
  toggleContextPanel: () => void;
}

export class Header extends IComponent<IHeaderProps, never> {
  public componentWillMount() {
    // make sure this listener is removed in componentWillUnmount
    // or else this will leak memory.
    window.addEventListener('resize', this.resizeListener);
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }
  public render() {
    const storeState = getStore().getState();
    const isNarrow = storeState.app.narrowScreen;
    const isAnalyticsOpen = storeState.app.isAnalyticsOpen;
    const showBackButton = isNarrow || Routes.refer.isActive();

    return h(
      u.getHTMLTagSelector(
        'header',
        ['app-header', isAnalyticsOpen ? 'for-analytics' : ''],
        'header'
      ),
      { ref: this.onRef },
      [
        isNarrow ? null : Breadcrumbs(),

        showBackButton
          ? Routes.home.isActive() || Routes.settings.isActive()
            ? IconButton(
                {
                  className: 'app-header__nav-toggle',
                  onclick: () => this.getProps().toggleSidebar(),
                },
                [h('i.fa.fa-bars')]
              )
            : IconButton(
                {
                  className: 'app-header__back',
                  tabIndex: storeState.app.acc.web.turnOff === 0 ? 0 : undefined,
                  onclick: () => {
                    if (isAnalyticsOpen) {
                      dispatch(Actions.toggleAnalytics(undefined));
                    } else {
                      goBack();
                    }
                  },
                },
                [h('i.fa.fa-arrow-left')]
              )
          : null,

        this.title(),
        this.headerRightButtons(),
      ]
    );
  }

  public resizeListener = throttle(() => {
    this.setHeaderHeight();
  }, 250);

  private elem: HTMLElement | undefined;
  private onRef = (e?: HTMLElement) => {
    if (this.elem !== e) {
      this.elem = e;
      this.setHeaderHeight();
    }
  };

  /**
   * Accessing e.clientHeight is a relatively expensive so
   * this method should only be called when necessary.
   * Right now, this is called when the DOM element corresponding
   * to the header is changed (this.onRef) and when component is
   * mounted.
   */
  private setHeaderHeight() {
    const e = this.elem;
    if (!e) {
      return;
    }
    const store = getStore();
    if (store.getState().app.headerHeight === e.clientHeight) return;
    if (e) {
      store.dispatch(AppActions.setHeaderHeight(e.clientHeight));
    }
  }

  private makeTipOverlayObjectForChatButton() {
    const appContext = getStore().getState().app.context;
    const courseRole = courseService.getRole();
    const courseKey = 'courseMainChat';
    const classKey = 'classMainChat';
    const quizKey = 'quizMainChat';
    const pollKey = 'pollMainChat';
    const discussionKey = 'discussionMainComment';

    const courseChatTipText =
      'Tap here to access the course discussion thread which will be' +
      ' active throughout the duration of the course.' +
      ' Any comments about the course in general can be posted here';
    const classChatTipText =
      'Tap here to access the class discussion thread. Students and' +
      ' instructors can post comments and questions about the class' +
      ' content in an informal setting';
    const quizChatTipText =
      courseRole === 'student'
        ? 'Once you’ve submitted the quiz, you’ll be able to access ' +
          ' discussions and clarify doubts about this' +
          ' quiz using this section.'
        : 'You and the students can conduct any discussions around a' +
          ' quiz using the discussion section. Only those students' +
          ' who’ve submitted the quiz can participate ';
    const pollChatTipText =
      courseRole === 'student'
        ? 'Tap here to discuss this poll with the class.'
        : 'In case the course members need to discuss the poll' +
          ' options and outcomes, they can use the poll discussion' +
          ' section that can be accessed through here';
    const discussionChatTipText =
      'The posts about a particular discussion topic can be made' +
      ' and accessed by using this icon';
    const tipObject = {
      targetElement: 'chat-button',
      tip: {
        tipPosition: 'bottom-left',
      },
    };

    if (appContext === 'course') {
      return {
        ...tipObject,
        tip: {
          ...tipObject.tip,
          tipText: courseChatTipText,
        },
        tipKey: courseKey,
        isNextAvailable: false,
      };
    } else if (appContext === 'class') {
      return {
        ...tipObject,
        tip: {
          ...tipObject.tip,
          tipText: classChatTipText,
        },
        tipKey: classKey,
        isNextAvailable: false,
      };
    } else if (appContext === 'quiz') {
      return {
        ...tipObject,
        tip: {
          ...tipObject.tip,
          tipText: quizChatTipText,
        },
        tipKey: quizKey,
        isNextAvailable: false,
        isSpecial: true,
      };
    } else if (appContext === 'poll') {
      return {
        ...tipObject,
        tip: {
          ...tipObject.tip,
          tipText: pollChatTipText,
        },
        tipKey: pollKey,
        isNextAvailable: false,
      };
    } else if (appContext === 'discussion') {
      return {
        ...tipObject,
        tip: {
          ...tipObject.tip,
          tipText: discussionChatTipText,
        },
        tipKey: discussionKey,
        isNextAvailable: false,
      };
    } else return undefined;
  }

  private isAnalyticsButtonVisible() {
    const appContext = getStore().getState().app.context;

    const courseRole = courseService.getRole();
    if (appContext === 'class' && courseRole !== 'student') {
      return true;
    }
    if (appContext === 'poll' && courseRole !== 'student') {
      return true;
    }
    if (appContext === 'resource' && courseRole !== 'student') {
      const match = Routes.classResource.getMatch() || Routes.filteredResource.getMatch();
      if (match) {
        const resourceId = resourceService.getResourceIdFromShortId(match.resourceShortId);
        const resource = getStore().getState().resources.byId[resourceId as any];
        return resource && resource.details.published;
      } else {
        return false;
      }
    }
    if (appContext === 'quiz' && courseRole !== 'student') {
      const match = Routes.classQuiz.getMatch() || Routes.filteredQuiz.getMatch();
      if (match) {
        const quizId = quizService.getQuizIdFromShortId(match.quizShortId);
        const currentQuiz = getStore().getState().quizzes.byId[quizId as any];
        return currentQuiz && currentQuiz.details.published;
      }
      return false;
    }
    if (appContext === 'assignment' && courseRole !== 'student') {
      const match = Routes.courseAssignment.getMatch() || Routes.filteredAssignment.getMatch();
      if (match) {
        const timeline = getStore().getState().courses.timeline;
        if (!timeline) return false;
        const assignment = timeline.items.find(
          (a) =>
            a.nodeType === 'assignment' &&
            a._id === assignmentService.getAssignmentIdFromShortId(match.assignmentShortId)
        );
        if (assignment && assignment.nodeType === 'assignment') {
          return !!assignment.details.published;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (appContext === 'discussion') {
      const match = Routes.classDiscussion.getMatch();
      if (!match) return false;
      const discussionId = discussionService.getDiscussionIdFromShortId(match.discussionShortId);
      const discussion = getStore().getState().discussions.byId[discussionId as any];
      return discussion && discussion.details.published;
    }

    return appContext === 'course';
  }

  private getAnalyticsTipText() {
    const isMobile = getStore().getState().app.isMobile;
    const courseRole = courseService.getRole();
    const TapOrClick = isMobile ? 'Tap' : 'Click';

    if (courseRole === 'student') {
      return `${TapOrClick} here to access your performance analytics for this course`;
    } else {
      return (
        `${TapOrClick} here to add students to the course or access the consolidated` +
        ` course analytics,including overall student scores and an individual ` +
        `student’s engagement with the course`
      );
    }
  }

  private headerRightButtons() {
    const isNarrow = getStore().getState().app.narrowScreen;
    const course = courseService.getCurrentCourse();
    const tipOverlayObject = this.makeTipOverlayObjectForChatButton();
    return h('div.app-header__right-buttons', [
      this.isAnalyticsButtonVisible() && course && course.status.courseLive === 1
        ? TipOverlayWrapper({
            targetElement: 'analytics-button',
            tip: {
              tipPosition: 'bottom-left',
              tipText: this.getAnalyticsTipText(),
            },
            tipKey: 'courseMainAnalytics',
            isNextAvailable: false,
          })
        : null,
      this.isAnalyticsButtonVisible() ? this.analyticsButton() : null,
      isNarrow &&
      ['course', 'class', 'assignment', 'quiz', 'poll', 'discussion', 'resource', 'query'].indexOf(
        getStore().getState().app.context
      ) > -1
        ? this.chatButton()
        : null,
      isNarrow &&
      ['course', 'class', 'quiz', 'poll', 'discussion'].indexOf(getStore().getState().app.context) >
        -1 &&
      tipOverlayObject !== undefined &&
      this.isTipObjectVisible() &&
      this.chatButton() !== null &&
      course &&
      course.status.courseLive === 1
        ? TipOverlayWrapper(tipOverlayObject as any)
        : null,
    ]);
  }

  private isTipObjectVisible() {
    const appContext = getStore().getState().app.context;
    const courseRole = courseService.getRole();

    if (appContext === 'poll') {
      const match = Routes.classPoll.getMatch() || Routes.filteredPoll.getMatch();
      if (match) {
        const pollId = pollService.getPollIdFromShortId(match.pollShortId);
        const currentPoll = getStore().getState().polls.byId[pollId as any];
        if (courseRole === 'student') {
          return (
            currentPoll &&
            currentPoll.details.published &&
            currentPoll.userData &&
            currentPoll.userData.status !== 'inProgress'
          );
        } else {
          return currentPoll && currentPoll.details.published;
        }
      }
      return false;
    }

    if (appContext === 'quiz') {
      const match = Routes.classQuiz.getMatch() || Routes.filteredQuiz.getMatch();
      if (match) {
        const quizId = quizService.getQuizIdFromShortId(match.quizShortId);
        const currentQuiz = getStore().getState().quizzes.byId[quizId as any];
        if (courseRole === 'student') {
          return (
            currentQuiz &&
            currentQuiz.details.published &&
            currentQuiz.userData &&
            currentQuiz.userData.status !== 'inProgress'
          );
        } else {
          return currentQuiz && currentQuiz.details.published;
        }
      }
      return false;
    }

    if (appContext === 'discussion') {
      const discussion = this.getCurrentDiscussion();
      return discussion && discussion.details.published;
    }
    return true;
  }

  private getCurrentDiscussion() {
    const match = Routes.classDiscussion.getMatch() || Routes.filteredDiscussion.getMatch();
    if (match) {
      const discussionId = discussionService.getDiscussionIdFromShortId(match.discussionShortId);
      const currentDiscussion = getStore().getState().discussions.byId[discussionId as any];
      return currentDiscussion || null;
    }
    return null;
  }

  private chatButton() {
    if (getStore().getState().app.isAnalyticsOpen) {
      return null;
    }
    const service = getCommentsContextService(getStore().getState().app.context, '');
    const numUnseen =
      getStore().getState().app.context === 'assignment'
        ? Math.max(0, assignmentService.totalComments - assignmentService.seenComments)
        : service.unseenComments;
    return h('div.app-header__chat-button-wrapper', [
      Icon(
        icons.comments,
        {
          className: 'app-header__chat-button',
          tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
          ariaLabel: 'Chat Button',
          onclick: () => this.getProps().toggleContextPanel(),
        },
        '#chat-button'
      ),
      numUnseen > 0
        ? h('div.badge.app-header__chat-count', [Math.min(numUnseen, 99).toString()])
        : null,
    ]);
  }

  private analyticsButton() {
    const isMobile = getStore().getState().app.isMobile;
    const isAnalyticsPanelOpen = getStore().getState().app.isAnalyticsOpen;
    const context = getStore().getState().app.context;

    const courseRole = courseService.getRole();
    const discussion = this.getCurrentDiscussion();
    let highlight = false;

    if (
      discussion &&
      courseRole !== 'student' &&
      discussion.details.wordCloudAvailable &&
      !discussion.details.wordCloudGenerated
    ) {
      highlight = true;
    }

    if (isMobile && isAnalyticsPanelOpen) return null;
    const icon = isAnalyticsPanelOpen ? icons.cross : icons.analytics;

    return h(
      'span.app-header__analytic-button#analytics-button',
      {
        tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
        ariaLabel: 'Analytics Button',
        onclick: () => {
          if (isAnalyticsPanelOpen) {
            u.resetTabIndices(document.getElementById('analytics-panel'));
          } else {
            u.unsetTabIndices(document.getElementById('header'));
            u.resetTabIndices(document.getElementById('context-panel-drawer'));
          }
          dispatch(Actions.analyticsButtonClick());
        },
      },
      [context === 'discussion' && !isAnalyticsPanelOpen ? wordCloudIcon(highlight) : Icon(icon)]
    );
  }

  private title() {
    const storeState = getStore().getState();
    const isMobile = storeState.app.isMobile;
    const titleImage = h('img', {
      src: storeState.app.narrowScreen
        ? // tslint:disable-next-line
          'https://s3.amazonaws.com/static.acad.ly/img/acadly-mobile-header-logo-light.png'
        : 'https://s3.amazonaws.com/static.acad.ly/img/header-logo.png',
      style: {
        maxHeight: '80%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
      },
      alt: 'Acadly logo',
    });
    if (Routes.home.isActive() || Routes.settings.isActive() || Routes.refer.isActive()) {
      return titleImage;
    }
    const getCourseCode = () => {
      const course = courseService.getCurrentCourse();
      if (!course) return '';
      return course.details.code;
    };

    const getClassTitle = () => 'Class';

    const UNPUBLISHED_TAG = {
      label: 'UNPUBLISHED',
      color: colors.red,
    };

    const getAssignmentTitle = (shortId: string) => {
      const assignmentId = assignmentService.getAssignmentIdFromShortId(shortId);
      const timeline = getStore().getState().courses.timeline;
      const assignment = timeline
        ? (timeline!.items.filter((item) => item._id === assignmentId)[0] as IAssignment)
        : null;
      if (assignment) {
        const num = assignment.details.num;
        if (assignment.details.published) {
          return `Assignment${num ? ' ' + num.toString() : ''}`;
        } else {
          return titleWithTag(
            'Assignment',
            assignment.details.published ? UNPUBLISHED_TAG : undefined
          );
        }
      } else {
        return 'Assignment';
      }
    };
    const titleWithTag = (
      label: string,
      tag?: {
        label: string;
        color: string;
      }
    ) =>
      h('div', [
        h('span', label),
        tag
          ? h(
              'span',
              style([
                'white',
                'x-small',
                ml('0.5em'),
                {
                  borderRadius: '0.2em',
                  backgroundColor: tag.color,
                  padding: '0.5em',
                },
              ]),
              tag.label
            )
          : null,
      ]);
    const getQuizTitle = (quizShortId: string) => {
      const quizId = quizService.getQuizIdFromShortId(quizShortId);
      const quiz = getStore().getState().quizzes.byId[quizId as any];
      return titleWithTag('Quiz', quiz && !quiz.details.published ? UNPUBLISHED_TAG : undefined);
    };

    const getDiscussionTitle = (discussionShortId: string) => {
      const discussionId = discussionService.getDiscussionIdFromShortId(discussionShortId);
      const discussion = getStore().getState().discussions.byId[discussionId as any];
      return titleWithTag(
        'Discussion',
        discussion && !discussion.details.published ? UNPUBLISHED_TAG : undefined
      );
    };

    const getResourceTitle = (resourceShortId: string) => {
      const resourceId = resourceService.getResourceIdFromShortId(resourceShortId);
      const resource = getStore().getState().resources.byId[resourceId as any];
      return titleWithTag(
        'Resource',
        resource && !resource.details.published ? UNPUBLISHED_TAG : undefined
      );
    };

    const getPollTitle = (pollShortId: string) => {
      const pollId = pollService.getPollIdFromShortId(pollShortId);
      const poll = getStore().getState().polls.byId[pollId as any];
      return titleWithTag('Poll', poll && !poll.details.published ? UNPUBLISHED_TAG : undefined);
    };

    const getQueryTitle = (queryShortId: string) => {
      const queryId = queryService.getQueryIdFromShortId(queryShortId);
      const query = getStore().getState().queries.byId[queryId as any];
      const isPending = query && query.details.status === 'pending';
      const isClosed = query && query.details.status === 'closed';
      if (isClosed) {
        return titleWithTag('Query', {
          label: 'CLOSED',
          color: colors.grey,
        });
      } else {
        return titleWithTag(
          'Query',
          isPending
            ? {
                label: 'PENDING',
                color: colors.orange,
              }
            : undefined
        );
      }
    };

    const exact = { exact: true };

    return h(
      'div.header-title-text',
      {
        style: {
          fontWeight: '900',
          fontSize: '1.1em',
          lineHeight: '1.5em',
          textTransform: 'uppercase',
          position: 'absolute',
          left: isMobile ? '4em' : '50%',
          top: '50%',
          transform: isMobile ? 'translateY(-50%)' : 'translateY(-50%) translateX(-50%)',
        },
      },
      [
        Switch(
          [
            Route(Routes.home, () => null, exact),
            Route(Routes.archivedCourses, () => 'Archived Courses', exact),
            Route(Routes.courseSyllabus, getCourseCode, exact),
            Route(Routes.courseTimeline, getCourseCode, exact),
            Route(Routes.courseInfo, getCourseCode, exact),
            Route(Routes.classActivities, () => getClassTitle(), exact),
            Route(Routes.classAgenda, () => getClassTitle(), exact),
            Route(Routes.classAttendance, () => getClassTitle(), exact),
            Route(Routes.newAnnouncement, () => 'New Announcement', exact),
            Route(Routes.courseAnnouncement, () => 'Announcement', exact),
            Route(Routes.filteredAnnouncement, () => 'Announcement', exact),
            Route(
              Routes.courseAssignment,
              (match) => getAssignmentTitle(match.params.assignmentShortId),
              exact
            ),
            Route(
              Routes.filteredAssignment,
              (match) => getAssignmentTitle(match.params.assignmentShortId),
              exact
            ),
            Route(Routes.classQuiz, (match) => getQuizTitle(match.params.quizShortId), exact),
            Route(Routes.filteredQuiz, (match) => getQuizTitle(match.params.quizShortId), exact),
            Route(Routes.classPoll, (match) => getPollTitle(match.params.pollShortId), exact),

            Route(Routes.filteredPoll, (match) => getPollTitle(match.params.pollShortId), exact),

            Route(
              Routes.classResource,
              // () => "Resource",
              // exact
              (match) => getResourceTitle(match.params.resourceShortId),
              exact
            ),
            Route(
              Routes.filteredResource,
              // () => "Resource",
              // exact
              (match) => getResourceTitle(match.params.resourceShortId),
              exact
            ),
            Route(
              Routes.classDiscussion,
              (match) => getDiscussionTitle(match.params.discussionShortId),
              exact
            ),
            Route(
              Routes.filteredDiscussion,
              (match) => getDiscussionTitle(match.params.discussionShortId),
              exact
            ),
            Route(Routes.newQuery, () => 'Query', exact),
            Route(Routes.classQuery, (match) => getQueryTitle(match.params.queryShortId), exact),
            Route(Routes.filteredQuery, (match) => getQueryTitle(match.params.queryShortId), exact),
            Route(Routes.filterActivities, () => 'Activities', exact),
            Route('*', () => null, exact),
            // Route(Routes.home, () => null, {exact: false}) // Handle everything else
          ],
          {
            defaultCallback: () => {
              // Routes.home.navigate({});
            },
          }
        ),
      ]
    );
  }
}

export default function (props: IHeaderProps) {
  return h(Header, props);
}

function Breadcrumbs() {
  const course = courseService.getCurrentCourse();
  const indexIncrement = course && course.isArchived ? 1 : 0;
  const currentCourseCode = courseService.getCurrentCourse()
    ? courseService.getCurrentCourse()!.details.code
    : '';
  const getClassCrumb = (match: { params: { classShortId: string; courseShortId: string } }) => {
    const { courseShortId, classShortId } = match.params;
    return Breadcrumb(
      {
        name: 'Class',
        selector: 'class-breadcrumb',
        navigate: () =>
          Routes.classActivities.navigate({
            courseShortId,
            classShortId,
            univSlug: appService.getUniversitySlug(),
          }),
      },
      2 + indexIncrement
    );
  };
  // const getFilterCrumb = (courseCode: string, label: string) =>
  //     Breadcrumb({
  //         name: label,
  //         navigate: () =>
  //             Routes.filterActivities.navigate({
  //                 courseCode: courseCode,
  //                 type: label.toLowerCase(),
  //                 univSlug: appService.getUniversitySlug()
  //             })
  //     }, 2 + indexIncrement);
  return h(
    'nav.breadcrumbs',
    {
      style: {
        display: 'flex',
        height: '100%',
        zIndex: 2,
      },
    },
    [
      Route(Routes.archivedCourses, () => {
        return Breadcrumb(
          {
            name: 'home',
            navigate: () => Routes.home.navigate({}),
          },
          0
        );
      }),
      Route(Routes.course, () => {
        return Breadcrumb(
          {
            name: 'home',
            navigate: () => Routes.home.navigate({}),
          },
          0
        );
      }),
      Route(Routes.course, () => {
        if (course && course.isArchived) {
          return Breadcrumb(
            {
              name: 'Archived',
              navigate: () =>
                Routes.archivedCourses.navigate({
                  univSlug: appService.getUniversitySlug(),
                }),
            },
            1
          );
        } else {
          return null;
        }
      }),
      Route(Routes.newAnnouncement, (match) =>
        Breadcrumb(
          {
            name: currentCourseCode,
            navigate: () =>
              Routes.courseTimeline.navigate({
                courseShortId: match.params.courseShortId,
                univSlug: appService.getUniversitySlug(),
              }),
          },
          1 + indexIncrement
        )
      ),
      Route(Routes.courseAnnouncement, (match) =>
        Breadcrumb(
          {
            name: currentCourseCode,
            navigate: () =>
              Routes.courseTimeline.navigate({
                courseShortId: match.params.courseShortId,
                univSlug: appService.getUniversitySlug(),
              }),
          },
          1 + indexIncrement
        )
      ),
      Route(Routes.courseAssignment, (match) =>
        Breadcrumb(
          {
            name: currentCourseCode,
            navigate: () =>
              Routes.courseTimeline.navigate({
                courseShortId: match.params.courseShortId,
                univSlug: appService.getUniversitySlug(),
              }),
          },
          1 + indexIncrement
        )
      ),
      Route(Routes.class, (match) => {
        const { courseShortId } = match.params;
        // const courseCode = courseService.getCourseCodeFromId(courseId);
        return Breadcrumb(
          {
            name: currentCourseCode,
            navigate: () =>
              Routes.courseTimeline.navigate({
                courseShortId,
                univSlug: appService.getUniversitySlug(),
              }),
          },
          1 + indexIncrement
        );
      }),
      Route(Routes.classQuiz, getClassCrumb),
      Route(Routes.classPoll, getClassCrumb),
      Route(Routes.classDiscussion, getClassCrumb),
      Route(Routes.classResource, getClassCrumb),
      Route(Routes.newQuery, getClassCrumb),
      Route(Routes.classQuery, getClassCrumb),
      Route(Routes.filterActivities, ({ params }) =>
        Breadcrumb(
          {
            name: currentCourseCode,
            navigate: () =>
              Routes.courseTimeline.navigate({
                courseShortId: params.courseShortId,
                univSlug: appService.getUniversitySlug(),
              }),
          },
          1 + indexIncrement
        )
      ),
      // Route(Routes.filteredAnnouncement, (match) =>
      //     getFilterCrumb(match.params.courseCode, "Announcements")),
      // Route(Routes.filteredAssignment, (match) =>
      //     getFilterCrumb(match.params.courseCode, "Assignments")),
      // Route(Routes.filteredQuiz, (match) =>
      //     getFilterCrumb(match.params.courseCode, "Quizzes")),
      // Route(Routes.filteredPoll, (match) =>
      //     getFilterCrumb(match.params.courseCode, "Polls")),
      // Route(Routes.filteredResource, (match) =>
      //     getFilterCrumb(match.params.courseCode, "Resources")),
      // Route(Routes.filteredDiscussion, (match) =>
      //     getFilterCrumb(match.params.courseCode, "Discussions")),
      // Route(Routes.filteredQuery, (match) =>
      //     getFilterCrumb(match.params.courseCode, "Queries")),
    ]
  );
}

function Breadcrumb(obj: { name: string; selector?: string; navigate: () => void }, index: number) {
  return h(
    `span.breadcrumb`,
    {
      id: obj.selector ? obj.selector : undefined,
      style: {
        fontSize: '0.8em',
        fontWeight: '700',
        color: colors.anotherGrey,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
      },
    },
    [
      index !== 0 ? h('i.fa.fa-chevron-right', style([ml('0.5rem')])) : null,
      h(
        'span',
        {
          'aria-label': `Go back to ${obj.name}`,
          tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
          style: {
            height: '100%',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: index === 0 ? '0.5em' : '1em',
            paddingRight: '0.3em',
          },
          onclick: () => obj.navigate(),
        },
        [
          index === 0
            ? h('span.glyph-icon', {
                'data-icon': icons.home,
                style: {
                  fontSize: '1.2rem',
                },
              })
            : obj.name,
        ]
      ),
    ]
  );
}
