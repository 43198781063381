import { h, IComponent } from 'core';

import * as BulbIcon from 'assets/bulb.svg';

import QuickStartTips from 'acadly/common/QuickStartTips';
import SvgIcon from 'acadly/common/SvgIcon';

class CourseSyllabusStartTips extends IComponent<never, never> {
  public render() {
    return QuickStartTips({
      qstKey: 'qst-course-syllabus',
      children: ({ wrapper }) => wrapper(this.body()),
    });
  }

  private body = () => {
    return h('div.quick-start-tips__body', [
      h('div.quick-start-tips__section-heading', 'Syllabus'),
      h('p.quick-start-tips__section-content', [
        'The Course Syllabus section allows you to share a PDF of the syllabus. ',
        'You can also add “Topics” that will be covered in the course.',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'Topic reading list',
      ]),
      h('p.quick-start-tips__section-content', [
        'Each topic can have its own “Reading List” and you can share links ',
        'relevant to the topic and also the textbooks that should be used to ',
        'read a particular topic',
      ]),
    ]);
  };
}

export default () => h(CourseSyllabusStartTips);
