import { h, IComponent } from 'core';

import { useSuggestedAssignment } from 'acadly/assignment/actions';
import * as api from 'acadly/class/api';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Dialog, { IDialogProps } from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import { fullScreenLoader } from 'acadly/common/Loader';
import User from 'acadly/common/User';
import * as datetime from 'acadly/datetime';
import Viewer from 'acadly/rich-text/Viewer';
import { dispatch } from 'acadly/store';
import { colors } from 'acadly/styles';

interface ISuggestedAssignmentPreviewProps {
  open: boolean;
  onClose: (used: boolean) => any;
  isAccessible?: boolean;
  assignment: ISuggestedAssignment | null;
}

interface ISuggestedAssignmentPreviewState {
  isLoading: boolean;
  assignmentQuestions: ISuggestedAssignmentQuestion[];
}

class SuggestedAssignmentPreview extends IComponent<
  ISuggestedAssignmentPreviewProps,
  ISuggestedAssignmentPreviewState
> {
  private async init(assignment: ISuggestedAssignment | null) {
    const initialState: ISuggestedAssignmentPreviewState = {
      isLoading: false,
      assignmentQuestions: [],
    };

    await this.setState(initialState);

    if (assignment) {
      this.setState({ isLoading: true });
      const response = await api.getSuggestedActivityDetails(assignment.nodeType, assignment._id);

      if (response.data.nodeType === 'assignment') {
        this.setState({
          isLoading: false,
          assignmentQuestions: response.data.questions,
        });
      }
    }
  }

  public componentWillMount() {
    const { assignment } = this.getProps();
    this.init(assignment);
  }

  public componentWillReceiveProps(newProps: ISuggestedAssignmentPreviewProps) {
    const currentProps = this.getProps();

    if (newProps.open && newProps.open !== currentProps.open) {
      this.init(newProps.assignment);
    }
  }

  public render() {
    const { open, onClose } = this.getProps();
    const { isLoading } = this.getState();

    const centerAlign = {
      display: 'flex',
      alignItems: 'center',
    };

    const dialogOptions: IDialogProps = {
      open,
      title: 'Assignment preview',
      style: {
        minHeight: '80%',
      },
      bodyStyle: {
        backgroundColor: colors.backgroundColor,
        flex: 1,
        ...(isLoading ? centerAlign : {}),
      },
      secondaryAction: {
        label: 'Back',
        mobileLabel: h('i.fa.fa-arrow-left'),
        onclick: () => onClose(false),
      },
    };

    return Dialog(dialogOptions, this.body());
  }

  private body() {
    const { assignment } = this.getProps();
    const { isLoading } = this.getState();

    if (!assignment) return [];

    if (isLoading) return [fullScreenLoader];
    const createdBy = assignment.details.createdBy;

    return [
      h('div.assignment-preview', [
        h('div.assignment-preview__action-bar', [
          FlatButton('Use Assignment', {
            type: 'primary',
            onclick: async () => {
              await dispatch(
                useSuggestedAssignment({
                  activityType: 'assignments',
                  activityId: assignment._id,
                  copyToClass: '',
                  toBeDone: '',
                })
              );
              this.getProps().onClose(true);
            },
          }),
        ]),
        h('div.assignment-preview__content.activity', [
          h('div.assignment-preview__info', [
            h('div.cell__label', [
              `Assignment published on `,
              `${assignment.details.publishedOnDate} in `,
              `${assignment.identifiers.courseCode}: `,
              assignment.identifiers.courseTitle,
            ]),
          ]),
          h('div.activity__title', assignment.details.title || 'Untitled'),
          assignment.details.instructions
            ? h('div.activity__description', [Viewer(assignment.details.instructions)])
            : null,
          assignment.details.attachments.length
            ? h(
                'div.activity__attachments',
                assignment.details.attachments.map((attachment) =>
                  AttachmentViewer({
                    attachment,
                    hideDownloadIcon: true,
                    downloadUrl: '',
                    downloadRequest: null,
                  })
                )
              )
            : null,
          this.questionsView(),
          User(
            {
              title: `Created by ${createdBy.name}`,
              titleClassNames: ['fc-medium-grey'],
              subtitle: datetime.format(assignment.details.createdOn, 'MMM DD, YYYY [at] hh:mm A'),
              subtitleClassNames: ['fc-medium-grey'],
              avatar: {
                url: createdBy.avatar,
                creator: createdBy.name,
              },
            },
            {
              className: 'activity__creator',
            }
          ),
        ]),
      ]),
    ];
  }

  private questionsView() {
    const questions = this.getState().assignmentQuestions;

    const ExtensionIcon = (ext: string) => {
      return h('span.assignment-preview__extension-icon', ext);
    };

    return h('div', [
      h('div', 'QUESTIONS'),
      questions.length === 0
        ? h('div.assignment__questions__no-questions', ['No questions added to this assignment.'])
        : null,
      ...questions.map((question, index) =>
        h(
          'div.panel',
          {
            key: question._id,
          },
          [
            h('div.panel__header', [
              h('span', `Question ${index + 1} (Max marks: ${question.details.marks})`),
            ]),
            h('div.panel__content', [
              Viewer(question.details.description.text),
              question.details.attachments.length > 0
                ? h('div', [
                    h('div.activity__subtitle', 'Attachments'),
                    h(
                      'div.attachments',
                      question.details.attachments.map((file) =>
                        AttachmentViewer({
                          attachment: file,
                          style: {
                            marginTop: '0.5rem',
                          },
                          downloadUrl: '',
                          downloadRequest: null,
                        })
                      )
                    ),
                  ])
                : null,

              h('div', [
                h('div.assignment__question__format-title', 'Acceptable Submission Formats'),
                h(
                  'div.assignment__question__formats',
                  question.details.submission === 'file'
                    ? question.details.submitExt.map(ExtensionIcon)
                    : ['URL'].map(ExtensionIcon)
                ),
              ]),
            ]),
          ]
        )
      ),
    ]);
  }
}

export default (props: ISuggestedAssignmentPreviewProps) => h(SuggestedAssignmentPreview, props);
