import { getStore } from 'acadly/store';
import * as u from 'acadly/utils';

import IService from '../IService';

class ResourceService extends IService {
  public getResourceIdFromShortId(shortId: string) {
    const resources = getStore().getState().resources.byId;
    const resource = u.objectValues(resources).filter((q) => q._id.slice(18) === shortId)[0];
    if (resource) {
      return resource._id;
    } else {
      return null;
    }
  }
  public getShortIdFromResourceId(resourceId: string) {
    return resourceId.slice(18);
  }
}

export const resourceService = new ResourceService();
export default resourceService;
