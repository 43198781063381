import { ThunkAction } from 'redux-thunk';

import { IRootActionObject, IRootActionPayload, IRootActionType } from './IRootAction';
import { IRootState } from './IRootState';

export function createAction<Type extends IRootActionType>(type: Type) {
  return (payload: IRootActionPayload<Type>): IRootActionObject<Type> => ({ type, payload });
}

export type Thunk<Result> = ValueThunk<Promise<Result>>;
export type ValueThunk<Result> = ThunkAction<Result, IRootState, void>;
