/* eslint-disable @typescript-eslint/no-unused-vars */
export import addDays = require('date-fns/add_days');
export import addHours = require('date-fns/add_hours');
export import addMinutes = require('date-fns/add_minutes');
export import addMonths = require('date-fns/add_months');
export import addSeconds = require('date-fns/add_seconds');
export import addWeeks = require('date-fns/add_weeks');
export import differenceInMonths = require('date-fns/difference_in_months');
export import differenceInSeconds = require('date-fns/difference_in_seconds');
export import differenceInYears = require('date-fns/difference_in_years');
export import distanceInWordsStrict = require('date-fns/distance_in_words_strict');
export import distanceInWordsToNow = require('date-fns/distance_in_words_to_now');
export import endOfDay = require('date-fns/end_of_day');
import _format = require('date-fns/format');
export import getDaysInMonth = require('date-fns/get_days_in_month');
export import getTime = require('date-fns/get_time');
export import isBefore = require('date-fns/is_before');
export import isSameDay = require('date-fns/is_same_day');
export import isSameMonth = require('date-fns/is_same_month');
export import isToday = require('date-fns/is_today');
export import isTomorrow = require('date-fns/is_tomorrow');
export import max = require('date-fns/max');
export import startOfDay = require('date-fns/start_of_day');
export import startOfMonth = require('date-fns/start_of_month');
import * as _isPast from 'date-fns/is_past';
export const isPast = _isPast;
import * as _isFuture from 'date-fns/is_future';

import { acadlyTime } from './utils/timer';
export const isFuture = _isFuture;
export const format = (date: Date | number, format?: string): string => {
  if (typeof date === 'number') {
    return _format(new Date(date * 1000), format);
  } else {
    return _format(date, format);
  }
};

export const fromUnix = (t: number) => new Date(t * 1000);
export const toUnix = (date: string | number | Date) => Math.floor(getTime(date) / 1000);
export const now = () => new Date(acadlyTime.now() * 1000);
export const unix = acadlyTime.now;

export type TimeUnit =
  | 'day'
  | 'days'
  | 'second'
  | 'seconds'
  | 'hour'
  | 'hours'
  | 'minute'
  | 'minutes';

export const add = (date: Date, amount: number, unit: TimeUnit) => {
  const addFn = {
    day: addDays,
    days: addDays,
    second: addSeconds,
    seconds: addSeconds,
    minute: addMinutes,
    minutes: addMinutes,
    hour: addHours,
    hours: addHours,
  }[unit];
  return addFn(date, amount);
};
export const diff = (date1: Date, date2: Date, unit: TimeUnit): number => {
  const diffSeconds = differenceInSeconds(date1, date2);
  const diffHours = diffSeconds / 3600;
  const diffDays = diffHours / 24;
  const diffMinutes = diffSeconds / 60;
  return {
    second: diffSeconds,
    seconds: diffSeconds,
    minutes: diffMinutes,
    minute: diffMinutes,
    hour: diffHours,
    hours: diffHours,
    day: diffDays,
    days: diffDays,
  }[unit];
};
export const fromNow = (date: Date) => {
  const currentDate = now();
  const diffSeconds = differenceInSeconds(currentDate, date);
  const diffMinutes = diffSeconds / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;
  const diffMonths = differenceInMonths(currentDate, date);
  if (diffSeconds < 45) {
    return 'A few moments ago';
  } else if (diffMinutes < 45) {
    const diff = Math.round(diffMinutes);
    return `${diff === 1 ? 'a' : diff} minute${diff !== 1 ? 's' : ''} ago`;
  } else if (diffHours < 23) {
    const diff = Math.round(diffHours);
    return `${diff === 1 ? 'a' : diff} hour${diff !== 1 ? 's' : ''} ago`;
  } else if (diffDays <= 26) {
    const diff = Math.round(diffDays);
    return `${diff === 1 ? 'a' : diff} day${diff !== 1 ? 's' : ''} ago`;
  } else if (diffMonths <= 11) {
    const diff = diffMonths < 1 ? 1 : diffMonths;
    return `${diff === 1 ? 'a' : diff} month${diff !== 1 ? 's' : ''} ago`;
  } else {
    const diffYears = differenceInYears(currentDate, date);
    const diff = diffYears < 1 ? 1 : diffYears;
    return `${diff === 1 ? 'a' : diff} year${diff !== 1 ? 's' : ''} ago`;
  }
};

export function isGreaterThanBy(date1: Date, date2: Date, amount: number, unit: TimeUnit) {
  return diff(date1, date2, unit) > amount;
}

export function isInFuture(date: Date, amount = 0, unit: TimeUnit = 'seconds') {
  return isGreaterThanBy(date, now(), amount, unit);
}
