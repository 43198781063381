import { h, View } from 'core';

import DivButton from 'acadly/common/DivButton';
import Paper from 'acadly/common/Paper';

import { getStore } from '../store';

export interface IActionButton {
  disabled?: boolean;
  label: string;
  icon:
    | {
        type: 'fontawesome';
        value: string;
      }
    | {
        type: 'view';
        view: View;
      };
  onclick?: () => any;
  id?: string; // do not prefix with #(hash)
  classNames?: string[]; // do not prefix with .(dot)
}
export const isIpad = /iPad/.test(navigator.userAgent);
export const ACTION_BAR_HEIGHT = '4em';
export default function ActionBar(actionButtons: IActionButton[], tabIndex?: boolean) {
  const isMobile = getStore().getState().app.isMobile;
  return Paper(
    `#action-bar.action-bar${isMobile ? '.mobile' : ' '}`,
    {
      tabIndex: tabIndex ? 0 : undefined,
    },
    actionButtons.map(ActionButton)
  );
}

export function ActionButton(btn: IActionButton) {
  return DivButton({
    key: btn.label,
    id: btn.id,
    classNames: ['action-bar__buttons', ...(btn.classNames || [])],
    onclick: btn.onclick,
    disabled: btn.disabled,
    tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
    ariaLabel: btn.label,
    role: 'button',
    children: [
      h('div.action-bar__icon', [
        btn.icon.type === 'fontawesome' ? h(`i.fa.${btn.icon.value}`) : btn.icon.view,
      ]),
      h('div.action-bar__label', btn.label),
    ],
  });
}
