import { CSS, h, IComponent, View } from 'core';

import { getStore } from '../store';
import {
  colors,
  contextPanelWidthPixels,
  mb,
  ml,
  pad,
  pl,
  sidebarWidthPixels,
  style,
} from '../styles';
import ContentView from './ContentView';
import FlatButton from './FlatButton';
import Paper from './Paper';
import portal from './Portal';

export type IDialogAction =
  | {
      id?: string;
      label: View | View[];
      mobileLabel?: View | View[];
      type?: 'primary' | 'secondary' | undefined;
      onclick: (event: Event) => void;
      key?: string;
      disabled?: boolean;
      tabIndex?: number;
      mobileStyle?: CSS;
      'aria-label'?: string;
      role?: string;
    }
  | {
      type: 'view';
      view: View;
    };

export interface IDialogProps {
  open: boolean;
  thinHeader?: boolean;
  fullScreen?: boolean;
  subHeader?: View;
  primaryAction?: IDialogAction | IDialogAction[];
  secondaryAction?: IDialogAction;
  backButton?: IDialogAction;
  title?: View;
  style?: CSS;
  bodyStyle?: CSS;
  overlayStyle?: CSS;
  _children?: View[];
  iconActions?: boolean;
  key?: string;
  headerType?: 'blue' | 'white';
  onOpen?: () => void;
  onClose?: () => void;
  role?: string;
}
export interface IDialogState {
  openState: 'opening' | 'open' | 'closing' | 'closed';
  headerHeight: number;
}

@portal(document.getElementById('dialog-container'))
export class Dialog extends IComponent<IDialogProps, IDialogState> {
  private isAccessible: boolean;
  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    const props = this.getProps();
    if (props.open) {
      this.setState({
        openState: 'opening',
      }).then(() =>
        this.setState({
          openState: 'open',
        })
      );
    } else {
      this.setState({
        openState: 'closed',
      });
    }
    if (props.onOpen && props.open) {
      props.onOpen();
    }
  }

  public shouldComponentUpdate(_: IDialogProps, nextState: IDialogState) {
    if (nextState.openState === 'closed' && this.getState().openState === 'closed') {
      return false;
    } else {
      return true;
    }
  }

  public componentWillUnmount() {
    if (!this.firstChildElem) {
      this.getFirstElem();
    }
    if (!this.lastChildElem) {
      this.getLastElem();
    }
    this.cleanupDialog(this.firstChildElem as any, this.lastChildElem as any);
  }

  public componentDidMount() {
    // this.addEventListenersForTabbable();
  }

  public componentWillReceiveProps(nextProps: IDialogProps) {
    if (this.getState().openState === 'open' && !nextProps.open) {
      this.setState({
        openState: 'closing',
      }).then(() =>
        setTimeout(() => {
          this.setState({
            openState: 'closed',
          });
        }, 1000)
      );
    } else if (this.getState().openState === 'closed' && nextProps.open) {
      this.setState({
        openState: 'opening',
      }).then(() => {
        setTimeout(() =>
          this.setState({
            openState: 'open',
          })
        );
        console.log('inside component will receive props');
        // this.addEventListenersForTabbable();
      });
    }
    const props = this.getProps();
    if (props.onOpen && props.open === false && nextProps.open === true) {
      /**
       * Set timeout is important here because we don't
       * want to run headerResizeHandler immediately.
       * Running it immediately won't wait for the
       * actual rendered header to redraw, thus giving
       * us previous header height
       */
      setTimeout(() => this.headerResizeHandler());
      props.onOpen();
    }
    if (props.onClose && props.open === true && nextProps.open === false) {
      props.onClose();
    }
    // console.log("1-inside component did props");
    // this.addEventListenersForTabbable();
  }

  public render() {
    const options = this.getProps();
    const isMobile = getStore().getState().app.isMobile;
    const narrowScreen = getStore().getState().app.narrowScreen;
    const openState = this.getState().openState;
    const overlayStyle = {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      ...options.overlayStyle,
    };
    const fullScreenDesktop = options.fullScreen && !narrowScreen;
    const dialogBoxClassNames = ['.dialog__box', openState];

    if (isMobile) {
      dialogBoxClassNames.push('mobile');
    }

    if (options.fullScreen) {
      dialogBoxClassNames.push('full-screen');
    }

    return this.getState().openState !== 'closed'
      ? h(
          `div.dialog-overlay`,
          {
            key: options.key,
            style: {
              position: 'fixed',
              zIndex: fullScreenDesktop ? 1000 : 3000,
              left: fullScreenDesktop ? `${sidebarWidthPixels}px` : 0,
              right: fullScreenDesktop ? `${contextPanelWidthPixels}px` : 0,
              width: fullScreenDesktop
                ? `calc(100% - ${sidebarWidthPixels}px - ${contextPanelWidthPixels}px)`
                : '100%',
              top: 0,
              height: '100%',
              ...options.overlayStyle,
              transition: 'background-color 0.5s ease-in',
              backgroundColor: {
                open: overlayStyle.backgroundColor,
                closed: 'rgba(0, 0, 0, 0)',
                closing: 'rgba(0, 0, 0, 0)',
                opening: 'rgba(0, 0, 0, 0)',
              }[openState],
            },
          },
          [
            h(
              'div',
              {
                style: {
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
              [
                Paper(
                  `${dialogBoxClassNames.join('.')}#dialog-box`,
                  {
                    role: options.role ? options.role : undefined,
                    style: options.style,
                    'aria-labelledy': 'dialog-header',
                  },
                  [
                    this.header(),
                    this.body(),
                    (options.primaryAction || options.secondaryAction) && !isMobile
                      ? h(
                          'div',
                          {
                            style: {
                              backgroundColor: 'white',
                              position: 'absolute',
                              bottom: '3px',
                              right: 0,
                              marginTop: 'auto',
                              display: 'flex',
                              justifyContent: 'space-around',
                              borderTop: `1px solid lightGrey`,
                              paddingTop: '0.3em',
                              width: '100%',
                            },
                          },
                          [
                            options.secondaryAction
                              ? options.secondaryAction.type === 'view'
                                ? options.secondaryAction.view
                                : FlatButton(options.secondaryAction.label, {
                                    id: options.secondaryAction.id,
                                    key: 'dialog-secondary-action',
                                    type: options.secondaryAction.type || 'secondary',
                                    tabIndex: this.isAccessible ? 0 : undefined,
                                    onclick: options.secondaryAction.onclick,
                                    disabled: options.secondaryAction.disabled,
                                  })
                              : null,

                            ...this.getPrimaryActions().map((action, index) => {
                              if (action.type === 'view') {
                                return Object.assign({}, action.view, {
                                  key: `dialog-action-button-${index}`,
                                });
                              } else {
                                return FlatButton(action.label, {
                                  id: action.id,
                                  tabIndex: action.tabIndex !== undefined ? 0 : -1,
                                  key: `dialog-action-button-${index}`,
                                  onclick: action.onclick,
                                  disabled: action.disabled,
                                  role: action.role,
                                  type: action.type,
                                });
                              }
                            }),
                          ]
                        )
                      : null,
                  ]
                ),
              ]
            ),
          ]
        )
      : null;
  }

  private getPrimaryActions() {
    const props = this.getProps();
    let primaryAction: IDialogAction[] = [];
    if (props.primaryAction instanceof Array) {
      primaryAction = props.primaryAction;
    } else if (props.primaryAction) {
      primaryAction = [props.primaryAction];
    }
    return primaryAction;
  }

  private body() {
    const options = this.getProps();
    const children = options._children;
    const headerHeight = this.getHeaderHeight();
    const isMobile = getStore().getState().app.isMobile;
    let result: View;
    if (options.fullScreen) {
      result = ContentView(
        h(
          'div',
          style(
            [
              {
                marginTop: options.title ? headerHeight : undefined,
                marginBottom:
                  (options.primaryAction || options.secondaryAction) && !isMobile
                    ? '2.5em'
                    : undefined,
                ...options.bodyStyle,
              },
            ],
            {},
            {
              className: 'dialog-body-mobile',
              key: 'dialog-body-mobile',
            }
          ),
          children
        )
      );
    } else {
      result = h(
        'div',
        {
          className: 'dialog-body-desktop',
          key: 'dialog-body-desktop',
          style: {
            position: 'relative',
            // height: "100%", // Not sure if this breaks anything
            paddingBottom: !isMobile ? '1em' : undefined,
            marginTop: options.title ? headerHeight : undefined,
            marginBottom:
              (options.primaryAction || options.secondaryAction) && !isMobile ? '3em' : undefined,
            overflowY: 'auto',
            ...this.getProps().bodyStyle,
          },
        },
        children
      );
    }
    return result;
  }

  private getHeaderHeight() {
    const { headerHeight } = this.getState();
    return headerHeight;
  }

  private header() {
    const options = this.getProps();
    if (!options.title) return null;
    const secondaryAction = options.secondaryAction || options.backButton;
    const primaryActions = this.getPrimaryActions();
    if (getStore().getState().app.isMobile) {
      return h(
        'div.dialog-header',
        style(
          [
            'white',
            'borderBox',
            'absolute',
            'fullWidth',
            {
              top: 0,
              left: 0,
              zIndex: 1,
              backgroundColor: colors.darkBlue,
            },
          ],
          {},
          {
            ref: this.headerRef,
          }
        ),
        [
          h('div.dialog-actions', style(['flex', 'spaceBetween', 'alignCenter']), [
            secondaryAction && secondaryAction.type === 'view' ? secondaryAction.view : null,

            secondaryAction && secondaryAction.type !== 'view'
              ? FlatButton(secondaryAction.mobileLabel || secondaryAction.label, {
                  id: secondaryAction.id,
                  onclick: secondaryAction.onclick,
                  disabled: secondaryAction.disabled,
                  style: {
                    color: 'white',
                  },
                  labelStyle: {
                    padding: '1rem',
                  },
                  disabledStyle: {
                    color: 'grey',
                  },
                })
              : null,

            options.thinHeader
              ? h('div', style(['large', 'bold', pad('1rem'), pl('0.5rem')]), [options.title])
              : null,

            primaryActions.length > 0
              ? h(
                  'div',
                  style([ml('auto'), 'flex']),
                  primaryActions.map((action, index) => {
                    if (action.type === 'view') {
                      return h('div.dialog-action', { key: index.toString() }, [action.view]);
                    } else {
                      return FlatButton(action.mobileLabel || action.label, {
                        id: action.id,
                        onclick: action.onclick,
                        key: index.toString(),
                        disabled: action.disabled,
                        labelStyle: {
                          padding: '1rem',
                        },
                        style: {
                          color: 'white',
                          ...action.mobileStyle,
                        },
                        disabledStyle: {
                          color: 'grey',
                        },
                      });
                    }
                  })
                )
              : null,
          ]),
          !options.thinHeader
            ? h('div', style([ml('1rem'), 'large', mb('1em')]), [options.title])
            : null,

          options.subHeader ? options.subHeader : null,
        ]
      );
    } else {
      return h(
        'div.dialog-header#dialog-header',
        {
          style: {
            width: '100%',
            left: 0,
            top: 0,
            borderTopLeftRadius: options.fullScreen ? undefined : '0.3em',
            borderTopRightRadius: options.fullScreen ? undefined : '0.3em',
            position: 'absolute',
            zIndex: 1,
            borderBottom: `1px solid lightGrey`,
            color: options.fullScreen ? colors.white : 'black',
            backgroundColor: options.fullScreen ? colors.darkBlue : 'white',
          },
          tabIndex: this.isAccessible ? 0 : undefined,
          ref: this.headerRef,
        },
        [
          options.backButton
            ? h(
                'div.ripple',
                style(
                  [
                    'absolute',
                    'flex',
                    'alignCenter',
                    'justifyCenter',
                    'large',
                    {
                      marginTop: '0.5rem',
                      left: '0.5em',
                      padding: '0.5rem',
                    },
                  ],
                  {},
                  {
                    tabIndex: this.isAccessible ? 0 : undefined,
                    'aria-label': (options.backButton as any)['aria-label']
                      ? (options.backButton as any)['aria-label']
                      : undefined,
                    onclick: (options.backButton as any).onclick,
                  }
                ),
                [h('i.fa.fa-arrow-left', [])]
              )
            : null,
          h(
            'div',
            {
              style: {
                padding: '1rem',
                fontSize: options.fullScreen ? '1.2em' : '1.5em',
                textAlign: 'center',
              },
            },
            [options.title]
          ),

          options.subHeader
            ? h(
                'div',
                style(
                  ['fullWidth'],
                  {},
                  {
                    ref: this.headerResizeHandler,
                  }
                ),
                [options.subHeader]
              )
            : null,
        ]
      );
    }
  }

  private headerResizeHandler = () => {
    if (this.headerElem) {
      this.setHeaderHeight(this.headerElem);
    }
  };

  private headerRef = (elem?: HTMLElement | null) => {
    if (elem === null) {
      this.headerElem = undefined;
    }
    if (elem) {
      if (this.headerElem !== elem) {
        this.headerElem = elem;
        this.setHeaderHeight(elem);
      }
    }
  };

  private headerElem?: HTMLElement;
  private setHeaderHeight(elem: HTMLElement) {
    setTimeout(() => {
      const state = this.getState();
      if (!this.getProps().open) {
        return;
      }
      this.headerElem = elem;
      if (elem.clientHeight !== state.headerHeight) {
        this.setState({
          headerHeight: elem.clientHeight,
        });
      }
    });
  }

  private cleanupDialog(firstElem: HTMLElement, lastElem: HTMLElement) {
    this.cleanupFirstElem(firstElem);
    this.cleanupLastElem(lastElem);
  }

  private cleanupLastElem(elem?: HTMLElement) {
    if (elem) {
      elem.removeEventListener('keydown', this.onTabPress);
      // elem.removeEventListener("keydown", this.onTabAndShiftPress);
    }
  }

  private cleanupFirstElem(elem?: HTMLElement) {
    if (elem) {
      elem.removeEventListener('keydown', this.onTabAndShiftPress);
      // elem.removeEventListener("keydown", this.onTabAndShiftPress);
    }
  }

  private onTabPress = (e: KeyboardEvent) => {
    this.takeFocusToFirstElement(e);
  };

  private onTabAndShiftPress = (e: KeyboardEvent) => {
    this.takeFocustoLastElement(e);
  };

  private getLastElem() {
    // this.lastChildElem = document.getElementById("dialog-header") as HTMLElement;
    const dialogElem = document.getElementById('dialog-box');
    const lastChild = dialogElem && dialogElem.lastChild;
    if (lastChild) {
      if (lastChild.hasChildNodes()) {
        const theRealLastChild = lastChild.lastChild;
        if (theRealLastChild) {
          this.lastChildElem = theRealLastChild as HTMLElement;
        }
      }
    }
    return;
  }

  private getFirstElem() {
    this.firstChildElem = document.getElementById('dialog-header') as HTMLElement;
    return;
  }

  private takeFocustoLastElement(e: KeyboardEvent) {
    if (!this.lastChildElem) {
      // this.getLastElem();
      const dialogElem = document.getElementById('dialog-box');
      const lastChild = dialogElem && dialogElem.lastChild;
      if (lastChild) {
        if (lastChild.hasChildNodes()) {
          const theRealLastChild = lastChild.lastChild;
          if (theRealLastChild) {
            this.lastChildElem = theRealLastChild as HTMLElement;
          }
        }
      }
    }
    if (e.shiftKey && e.keyCode === 9) {
      e.preventDefault();
      e.stopPropagation();
      if (this.lastChildElem) {
        this.lastChildElem.focus();
        this.lastChildElem.focus();
      }
    }
  }

  private takeFocusToFirstElement(e: KeyboardEvent) {
    if (!this.firstChildElem) {
      // this.getFirstElem();
      this.firstChildElem = document.getElementById('dialog-header') as HTMLElement;
    }
    if (e.shiftKey && e.keyCode === 9) {
      return;
    }
    if (e.keyCode === 9) {
      e.preventDefault();
      e.stopPropagation();
      console.log('2-weeeee got here', this.firstChildElem);
      if (this.firstChildElem) {
        console.log('2-firstChild', this.firstChildElem);
        if (document.activeElement !== this.firstChildElem) {
          this.firstChildElem.focus();
        }
      }
    }
  }

  private firstChildElem?: HTMLElement;
  private lastChildElem?: HTMLElement;

  // private addEventListenersForTabbable() {
  //     const dialogElem = document.getElementById("dialog-box");
  //     const props = this.getProps();
  //     console.log("props inside event listener", props);
  //     if (dialogElem) {

  //         // dialogElem.addEventListener("keydown", (e: KeyboardEvent) => {
  //         //     if (e.keyCode === 27) {
  //         //         console.log("escape key has been pressed");
  //         //         // find if secondary or primary action exists
  //         //         // then click the close button
  //         //         // or pass close alag se
  //         //         e.preventDefault();
  //         //     }
  //         // });
  //         const lastChild = dialogElem.lastChild;
  //         this.firstChildElem = document.getElementById("dialog-header") as HTMLElement;
  //         if (lastChild) {
  //             if (lastChild.hasChildNodes) {
  //                 const theRealLastChild = lastChild.lastChild;
  //                 // console.log("the last", theRealLastChild);
  //                 if (theRealLastChild) {
  //                     this.lastChildElem = theRealLastChild as HTMLElement;
  //                     // console.log("lastChild", this.lastChildElem);
  //                     // theRealLastChild.addEventListener("keydown", (e: KeyboardEvent) => {
  //                     //     if (e.keyCode === 9) {
  //                     //         e.preventDefault();
  //                     //         // e.stopPropagation();
  //                     //         console.log("2-weeeee got here", this.firstChildElem);
  //                     //         if (this.firstChildElem) {
  //                     //             console.log("2-firstChild", this.firstChildElem);
  //                     //             if (document.activeElement !== this.firstChildElem) {
  //                     //                 (this.firstChildElem).focus();
  //                     //             }
  //                     //         }
  //                     //     }
  //                     // });
  //                     // this.firstChildElem.addEventListener("keydown",
  //                     // this.takeFocusToFirstElement);
  //                     // this.lastChildElem.addEventListener("keydown",
  //                     //                             this.takeFocusToFirstElement);
  //                 }
  //             }
  //             if (this.firstChildElem) {
  //                 this.firstChildElem.focus();
  //                 this.firstChildElem.addEventListener("keydown",
  //                                                 this.takeFocustoLastElement);
  //                 // this.firstChildElem.addEventListener("keydown", (e: KeyboardEvent) => {
  //                 //     if (e.shiftKey && e.keyCode === 9) {
  //                 //         e.preventDefault();
  //                 //         e.stopPropagation();
  //                 //         if (this.lastChildElem) {
  //                 //             console.log("last child", this.lastChildElem);
  //                 //             this.lastChildElem.focus();
  //                 //         }
  //                 //     }
  //                 // });
  //             }
  //         }
  //         }
  // }
}
export default (options: IDialogProps, children: View[]) => {
  const props = {
    ...options,
    _children: children,
  };
  return h(Dialog, props as any);
};
