import { h, HTMLAttrs } from 'core';

/**
 * Takes a progress in percentages
 * @param progress Percentage completion
 */
export default (progress: number, attrs: HTMLAttrs = {}) => {
  return h('div.progress-bar__container', attrs, [
    h('div.progress-bar', {
      style: { width: `${progress}%` },
    }),
  ]);
};
