export default class IsMobile {
  public static Android() {
    return navigator.userAgent.match(/Android/i);
  }

  public static BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  }

  public static iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  public static Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  }

  public static Windows() {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  }

  public static any() {
    return (
      IsMobile.Android() ||
      IsMobile.iOS() ||
      IsMobile.BlackBerry() ||
      IsMobile.Opera() ||
      IsMobile.Windows()
    );
  }
}
