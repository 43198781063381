import { h } from 'core';

import { Icon } from 'acadly/common/Icon';
import icons from 'acadly/icons';
import { getHTMLTagSelector, padStart } from 'acadly/utils';

export interface ISuggestedActivityWidgetProps {
  key?: string;
  isAccessible?: boolean;
  noSuggestedTag?: boolean;
  activity: ISuggestedActivity;
  onclick?: (event: Event) => any;
}

function getActivityIcons(activity: ISuggestedActivity) {
  switch (activity.nodeType) {
    case 'quiz':
      return icons.quiz;
    case 'poll':
      return icons.poll;
    case 'discussion':
      return icons.discussion;
    case 'resource':
      if (activity.details.resourceType === 'text') {
        return icons.resourceText;
      } else if (activity.details.resourceType === 'file') {
        return icons.resourceFile;
      } else if (activity.details.resourceType === 'video') {
        return icons.resourceVideo;
      }
      return icons.resourceURL;
    default:
      return icons.quiz;
  }
}

function getActivityStatus(activity: ISuggestedActivity) {
  return activity.used ? 'used' : activity.hidden ? 'hidden' : 'suggested';
}

function getActivityTitle(activity: ISuggestedActivity, showSuggestedTag = true) {
  let title = '';
  switch (activity.nodeType) {
    case 'quiz':
      title = 'Quiz';
      break;
    case 'poll':
      title = 'Poll';
      break;
    case 'discussion':
      title = 'Discussion';
      break;
    case 'resource':
      title = 'Resource';
      break;
    default:
      title = 'Quiz';
      break;
  }

  const tag = getActivityStatus(activity);

  return h('div', [
    h('span', `${title} ${padStart(activity.details.trueNum.toString(), 2, '0')}`),
    tag !== 'suggested' || (tag === 'suggested' && showSuggestedTag)
      ? h(getHTMLTagSelector('span', ['tag', 'uppercase']), tag)
      : null,
  ]);
}

export default (props: ISuggestedActivityWidgetProps) => {
  const { activity, noSuggestedTag } = props;

  return h(
    getHTMLTagSelector('div', ['suggested-activity-widget', getActivityStatus(activity)]),
    {
      key: props.key,
      tabIndex: props.isAccessible ? 0 : undefined,
      onclick: props.onclick,
    },
    [
      Icon(getActivityIcons(activity), {
        className: 'suggested-activity-widget__icon',
      }),
      h('div.suggested-activity-widget__details', [
        h('div.suggested-activity-widget__header', [
          getActivityTitle(activity, !noSuggestedTag),
          h(
            'span.suggested-activity-widget__rhs',
            activity.details.toBeDone === 'inClass' ? 'In-class' : 'Pre-class'
          ),
        ]),
        h('div.suggested-activity-widget__title', activity.details.title || 'Untitled'),
      ]),
    ]
  );
};
