import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import { getCourseRole } from 'acadly/course/functions';
import { createAction, Thunk } from 'acadly/createAction';
import { Actions as GetInActions } from 'acadly/getin/actions';
import { IRootState } from 'acadly/IRootState';
import { IPusherPayload } from 'acadly/pusher';

import * as datetime from '../datetime';
import * as api from './api';
const _api = api;

export type ICommentsActionMap = {
  '@comments/SET_CONTEXT': ISetContextPayload;
  '@comments/FETCH_SUCCESS': IFetchSuccessPayload;
  '@comments/FETCH_OLD_SUCCESS': IFetchOldSuccessPayload;
  '@comments/CREATE_SUCCESS': ICreateSuccessPayload;
  '@comments/pusher/commentAdded': IPusherCommentAddedPayload;
  '@comments/MARK_NUM_SEEN': IIncrementNumSeenPayload;
  '@comments/REMOVE_SUCCESS': IRemoveSuccessPayload;
  '@comments/pusher/REMOVED': IPusherRemovePayload;
  '@comments/MARK_SUCCESS': IMarkSuccessPayload;
  '@comments/pusher/MARK': IPusherMarkPayload;
  '@comments/CLEAR': undefined;
  '@comments/SET_TO_SKIP': number | null;
  '@comments/FETCH_NEW_SUCCESS': IFetchNewSuccessPayload;
  '@comments/INCREMENT_NUM_PUSHER': number;
  '@comments/RESET_NUM_PUSHER': undefined;
  '@comments/SAVE_REQUEST_PUSHER': ISavePusherRequestPayload;
  '@comments/AWARD_RATING': IAwardRatingPayload;
  '@comments/RETRACT_RATING': IRetractRatingPayload;
  '@comments/SUBSCRIBE_SUCCESS': ISubscribeSuccessPayload;
};

export type ISubscribeSuccessPayload = api.ICommentSubscribeRequest & {
  timestamp: number; // UnixTimeStamp
};
export type ISavePusherRequestPayload = IPusherCommentAddedPayload;
export type IPusherMarkPayload = IPusherPayload<{
  commentId: string;
  marked: 'helpful' | 'starred' | 'awarded';
  action?: 'awarding';
  points?: number;
  instructorApproved: true | false;
}>;
export type IAwardRatingPayload = {
  request: api.ICommentAwardRatingRequest;
  instructor: {
    userId: string;
    avatar: string;
    name: string;
    role: 'admin' | 'instructor';
  };
};
export type IRetractRatingPayload = IAwardRatingPayload;

export type IFetchNewSuccessPayload = IFetchSuccessPayload;

export type IMarkSuccessPayload = api.IMarkRequest<ICommentsContext>;

export type IPusherRemovePayload = IPusherPayload<{
  commentId: string;
  context: ICommentsContext;
  contextId: string;
  subContext: string;
}>;

export type IRemoveSuccessPayload = {
  commentId: string;
};

export type IIncrementNumSeenPayload = {
  context: ICommentsContext;
  subContext: ICommentsSubContext;
  contextId: string;
  classId: string;
  setTo: number;
  toBeDone: 'preClass' | 'inClass' | 'review';
};

export type ISetContextPayload =
  | {
      context: ICommentsContext;
      subContext: ICommentsSubContext;
      contextId: string;
    }
  | undefined;

export type ICreateSuccessPayload = {
  request: api.ICreateRequest;
  response: IResponseComment;
  classId: string;
};
export type IFetchSuccessPayload = api.IGetCommentsResponse;
export type IFetchOldSuccessPayload = api.IGetCommentsResponse;
export type IPusherCommentAddedPayload = IPusherPayload<{
  commentId: string;
  context: ICommentsContext;
  contextId: string;

  // obviously won't be present for assignments and courses
  // not marking it as optional
  classId: string;
  subContext: ICommentsSubContext;
  details: ICommentDetails;
  /** ignore this event, if current user is in course-team and shouldTeamIgnore is set to 1 */
  shouldTeamIgnore: 0 | 1;
  stats: {
    numStars: number;
    numHelps: number;
    approved: number;
    awards: ICommentAwards[];
  };
}>;

export const Actions = {
  fetchCommentsSuccess: createAction('@comments/FETCH_SUCCESS'),

  fetchComments:
    <Context extends api.ICommentsContext>(
      data: {
        context: Context;
        contextId: string;
        subContext: api.ICommentsSubContext<Context>;
        totalComments: number;
        seenComments: number;
      },
      api = _api
    ): Thunk<void> =>
    async (dispatch, getState) => {
      const state = getState();
      if (data.totalComments < 1) {
        dispatch(
          Actions.fetchCommentsSuccess({
            commentsData: [],
            userData: {},
            userSubscribed: 0, // Check out
          })
        );
        return;
      }
      const response = await api.getCommentsV2({
        context: data.context,
        contextId: data.contextId,
        subContext: data.subContext,
      });

      dispatch(
        GetInActions.fetchAvatars(response.data.commentsData.map((c) => c.details.createdBy.avatar))
      );
      dispatch(Actions.fetchCommentsSuccess(response.data));
      const subContext = data.subContext;
      let totalCommentsSeen = response.data.totalCommentsSeen;
      if (totalCommentsSeen === undefined) {
        totalCommentsSeen = data.totalComments;
      }
      dispatch(
        Actions.markNumSeen({
          context: data.context,
          contextId: data.contextId,
          subContext: data.subContext,
          classId: getClassIdForContext(state, data.context, data.contextId),
          setTo: totalCommentsSeen,
          toBeDone:
            subContext === 'preClass' || subContext === 'inClass' || subContext === 'review'
              ? subContext
              : (null as any),
        })
      );
    },

  createSuccess: createAction('@comments/CREATE_SUCCESS'),
  create:
    (data: api.ICreateRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.createComment(data);
      dispatch(
        Actions.createSuccess({
          request: data,
          response: response.data,
          classId: data.classId || '',
        })
      );
    },

  setContext: createAction('@comments/SET_CONTEXT'),

  incrementNumPusher: createAction('@comments/INCREMENT_NUM_PUSHER'),
  resetNumPusher: createAction('@comments/RESET_NUM_PUSHER'),
  savePusherRequest: createAction('@comments/SAVE_REQUEST_PUSHER'),

  pusherCommentAddedSuccess: createAction('@comments/pusher/commentAdded'),
  pusherCommentAdded:
    (data: IPusherCommentAddedPayload): Thunk<void> =>
    async (dispatch, getState) => {
      const state = getState();
      const role = getCourseRole(state);

      if (data.shouldTeamIgnore === 1 && role !== 'student') return;

      dispatch(GetInActions.fetchAvatars([data.details.createdBy.avatar]));
      dispatch(Actions.pusherCommentAddedSuccess(data));

      if (
        state.getIn.session &&
        state.getIn.session.userId !== data.sender.userId &&
        state.comments.loadedContext &&
        state.comments.loadedContext.context === data.context &&
        state.comments.loadedContext.contextId === data.contextId &&
        ((data.context === 'assignments' &&
          data.subContext === state.comments.loadedContext.subContext) ||
          data.context !== 'assignments')
      ) {
        await dispatch(Actions.savePusherRequest(data));
        dispatch(Actions.incrementNumPusher(1));
      }
    },

  markNumSeen: createAction('@comments/MARK_NUM_SEEN'),

  fetchOldSuccess: createAction('@comments/FETCH_OLD_SUCCESS'),
  fetchOld:
    (data: {
      context: ICommentsContext;
      contextId: string;
      subContext: ICommentsSubContext;
    }): Thunk<number> =>
    async (dispatch, getState) => {
      const state = getState();
      const comments = state.comments.comments;
      const lastCommentId = comments && comments.length > 0 ? comments[0]._id : null;
      if (lastCommentId === null) {
        const response = await api.getCommentsV2({
          context: data.context,
          contextId: data.contextId,
          subContext: data.subContext,
        });
        dispatch(Actions.fetchOldSuccess(response.data));
        return response.data.commentsData.length;
      } else {
        const response = await api.getCommentsV2({
          context: data.context,
          contextId: data.contextId,
          subContext: data.subContext,
          lid: lastCommentId,
        });
        dispatch(Actions.fetchOldSuccess(response.data));
        return response.data.commentsData.length;
      }
    },

  removeSuccess: createAction('@comments/REMOVE_SUCCESS'),
  remove:
    (commentId: string, context: IAppContext, subContext: any): Thunk<void> =>
    async (dispatch) => {
      await api.remove(commentId);
      googleAnalytics.commentRemoved(context, subContext);
      dispatch(
        Actions.removeSuccess({
          commentId,
        })
      );
    },
  pusherRemove: createAction('@comments/pusher/REMOVED'),

  markSuccess: createAction('@comments/MARK_SUCCESS'),
  mark:
    <Context extends ICommentsContext>(
      data: api.IMarkRequest<Context>,
      context: IAppContext,
      subContext: any
    ): Thunk<void> =>
    async (dispatch) => {
      await api.mark(data);
      if (data.marked === 'starred') {
        googleAnalytics.commentLiked(context, subContext);
      }
      if (data.marked === 'helpful') {
        googleAnalytics.commentThanked(context, subContext);
      }
      dispatch(Actions.markSuccess(data));
    },

  subscribeSuccess: createAction('@comments/SUBSCRIBE_SUCCESS'),
  subscribe:
    (data: api.ICommentSubscribeRequest): Thunk<void> =>
    async (dispatch) => {
      await api.commentSubscribe(data);
      const currentTime = datetime.unix();
      const payload = {
        ...data,
        timestamp: currentTime,
      };
      dispatch(Actions.subscribeSuccess(payload));
    },

  awardSuccess: createAction('@comments/AWARD_RATING'),
  retractSuccess: createAction('@comments/RETRACT_RATING'),
  award:
    (
      data: api.ICommentAwardRatingRequest,
      instructor: {
        userId: string;
        avatar: string;
        name: string;
        role: 'admin' | 'instructor';
      }
    ): Thunk<void> =>
    async (dispatch) => {
      await api.awardRating(data);
      if (data.action === 'awarding') {
        dispatch(Actions.awardSuccess({ request: data, instructor }));
      } else {
        dispatch(Actions.retractSuccess({ request: data, instructor }));
      }
    },

  pusherMark: createAction('@comments/pusher/MARK'),

  clear: createAction('@comments/CLEAR'),
};

function getClassIdForContext(state: IRootState, context: ICommentsContext, contextId: string) {
  if (context === 'classes') {
    return contextId;
  } else if (context === 'assignments') {
    return '';
  } else if (context === 'course') {
    return '';
  } else if (
    context === 'resources' ||
    context === 'quizzes' ||
    context === 'polls' ||
    context === 'discussions' ||
    context === 'queries'
  ) {
    const activity = state[context].byId[contextId]!;
    return activity.identifiers.classId;
  } else return '';
}
