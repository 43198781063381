import { View } from 'core';

import * as CloseIcon from 'assets/close.svg';
import * as DoneIcon from 'assets/done.svg';

import { colors } from 'acadly/styles';

import Dialog from './Dialog';
import SvgIcon from './SvgIcon';

interface IActivityPrefsDialogProps {
  open: boolean;
  title: string;
  body: View[];
  isPublishing: boolean;
  onClose: () => void;
  onSave: () => void | Promise<void>;
  onPublish: () => void | Promise<void>;
}

export default (props: IActivityPrefsDialogProps) => {
  const options = {
    open: props.open,
    title: props.title,
    style: {
      width: '30em',
      padding: '0em',
      backgroundColor: colors.backgroundColor,
    },
    bodyStyle: {
      padding: '0em',
      paddingLeft: '0em',
      paddingRight: '0em',
    },
    primaryAction: {
      label: props.isPublishing ? 'Publish' : 'Save',
      mobileLabel: SvgIcon({
        icon: DoneIcon,
        className: 'activity-prefs__icon',
      }),
      onclick: props.isPublishing ? props.onPublish : props.onSave,
    },
    secondaryAction: {
      label: 'Cancel',
      mobileLabel: SvgIcon({
        icon: CloseIcon,
        className: 'activity-prefs__icon',
      }),
      onclick: props.onClose,
    },
  };

  return Dialog(options, props.body);
};
