import { jsonRequest } from 'core/http';

import api from 'acadly/api';

export interface ICreatePollRequest {
  classId: string;
  toBeDone: 'inClass' | 'preClass';
}
export type ICreatePollResponse = IResponsePoll;
export const createPoll = (data: ICreatePollRequest) =>
  jsonRequest<ICreatePollResponse>(api().pollCreate, {
    method: 'POST',
    data,
  });

export interface IDeletePollRequest {
  pollId: string;
  classId: string;
  toBeDone: 'preClass' | 'inClass';
}
export const deletePoll = (data: IDeletePollRequest) =>
  jsonRequest<void>(api().pollRemove, {
    method: 'DELETE',
    data,
  });

export interface IEditPollRequest {
  classId: string;
  pollId: string;
  title: string;
  isAnon: 0 | 1;
}
export const editPoll = (data: IEditPollRequest) =>
  jsonRequest<void>(api().pollEdit, {
    method: 'POST',
    data,
  });

export interface IAddQuestionRequest {
  classId: string;
  activityType: 'polls';
  activityId: string;
  description: {
    text: string;
    attachments: IAttachment[];
  };
  options: {
    text: string;
    type: 'text';
  }[];
}
export type IAddQuestionResponse = IPollQuestion;
export const questionAdd = (data: IAddQuestionRequest) =>
  jsonRequest<IAddQuestionResponse>(api().pollQueAdd, {
    method: 'POST',
    data,
  });

interface IPollFetchCommonPayload {
  publishDefaults?: PollPublishDefaults;
  submission?: ObjectMap<{ optionSelected: number }>;
}

interface IPollFetchWithQuestionsPayload extends IPollFetchCommonPayload {
  questionSet: 1;
  questions: IResponsePollQuestion;
}

interface IPollFetchEmptyQuestionsPayload extends IPollFetchCommonPayload {
  questionSet: 0;
  questions: unknown;
}

export type IPollFetchResponse = IPollFetchWithQuestionsPayload | IPollFetchEmptyQuestionsPayload;

export interface IPollFetchRequest {
  pollId: string;
  firstAccess: 0 | 1;
}
export const fetchPoll = (data: IPollFetchRequest) => {
  return jsonRequest<IPollFetchResponse>(
    api().pollGet + `?pollId=${data.pollId}&firstAccess=${data.firstAccess}`,
    {
      method: 'GET',
    }
  );
};

export interface IQuestionEditRequest {
  classId: string;
  activityType: 'polls';
  activityId: string;
  questionId: string;
  description: {
    text: string;
    attachments: IAttachment[];
  };
  options: {
    text: string;
    type: 'text';
  }[];
}

export const questionEdit = (data: IQuestionEditRequest) =>
  jsonRequest(api().pollQueEdit, {
    method: 'POST',
    data,
  });

export interface IPublishRequest {
  classId: string;
  pollId: string;
  toBeDone: 'inClass' | 'preClass';
  dueDateType: ActivityDueDateType;
  /** @deprecated */
  dueDateTime: string; // "YYYYMMDDTHH:mm" format, no longer used in back-end
  deadlineFirst: 0 | 1;
  allowLate: 0 | 1;
  duration: ActivityDuration; // -1, when dueDateType = manual
  subscribeToComments: 0 | 1;
  isAnon: 0 | 1;
  saveAsDefault: 0 | 1;
}
export interface IPublishResponse {
  pollNum: number;
  trueNum: number;
  publishedOn: UnixTimestamp;
  dueDateTime: UnixTimestamp;
  dueDateType: ActivityDueDateType;
  dueDateTimeL: string;
}

export interface IUpdatePublishedPollRequest {
  classId: string;
  pollId: string;
  title: string;
  questionId: string;
  description?: {
    text: string;
    attachments: IAttachment[];
  };
  options?: {
    text: string;
    type: 'text';
  }[];
  toNotify: 0 | 1;
}
export interface IUpdatePublishedPollResponse {
  message: string;
}

export const publish = (data: IPublishRequest) => {
  return jsonRequest<IPublishResponse>(api().pollPublish, {
    method: 'POST',
    data,
  });
};

export interface IStopPollRequest {
  classId: string;
  activityType: 'polls';
  activityId: string;
  localTime: string; // format YYYYMMDDTHH:mm
}

export interface IStopPollResponse {
  message: string;
  dueDateTime: number;
}
export const stopPoll = (data: IStopPollRequest) =>
  jsonRequest<IStopPollResponse>(api().pollStop, {
    method: 'POST',
    data,
  });

export const updatePollPublished = (data: IUpdatePublishedPollRequest) =>
  jsonRequest<IUpdatePublishedPollResponse>(api().updatePollPublished, {
    method: 'PUT',
    data,
  });

export interface ISubmitRequest {
  pollId: string;
  classId: string;
  submission: [
    {
      questionId: string;
      optionSelected: number;
    }
  ];
  localTime: string; // YYYYMMDDTHH:mm
  submissionType: 'auto' | 'manual';
}

export interface ISubmitResponse {
  submission: ObjectMap<{
    optionSelected: number;
  }>;
  submittedOn: UnixTimestamp;
  stats: {
    numAttempted: number;
    optionSelection: [{ num: number; numSelected: number }];
  };
}

export const submit = (data: ISubmitRequest) =>
  jsonRequest<ISubmitResponse>(api().pollSubmit, {
    method: 'POST',
    data,
  });

export interface IFetchAllResponse {
  activityData: IResponsePollWithClassId[];
  userData: {
    polls: ObjectMap<IResponsePollUserData | undefined>;
  };
}
export const fetchAll = () =>
  jsonRequest<IFetchAllResponse>(api().archiveGet + '/polls', {
    method: 'GET',
  });

export interface IAnalyticsFetchResponse {
  students: IPollAnalyticsStudent[];
}
export const analyticsFetch = (pollId: string) =>
  jsonRequest<IAnalyticsFetchResponse>(api().pollAnalyticsFetch(pollId), {
    method: 'GET',
  });
