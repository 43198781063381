import anchorme from 'anchorme';

import { h, IComponent } from 'core';

import { api as urls } from 'acadly/api';
import { Actions as appActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import MoveActivityDialog from 'acadly/class/MoveActivityDialog';
import ActionBar, { isIpad } from 'acadly/common/ActionBar';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Avatar from 'acadly/common/Avatar';
import ContentView from 'acadly/common/ContentView';
import Dialog from 'acadly/common/Dialog';
import DivButton from 'acadly/common/DivButton';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import ProgressBar from 'acadly/common/ProgressBar';
import TextField from 'acadly/common/TextField';
import Toggle from 'acadly/common/Toggle';
import UploadButton from 'acadly/common/UploadButton';
import VideoPlayer from 'acadly/common/VideoPlayer';
import CopyActivityDialog from 'acadly/course/CopyActivityDialog';
import { validateActivityPublish } from 'acadly/course/validations';
import * as dt from 'acadly/datetime';
import icons from 'acadly/icons';
import Editor from 'acadly/rich-text/Editor';
import Viewer from 'acadly/rich-text/Viewer';
import { goBack } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { colors, ml, mr, pad, pt, style } from 'acadly/styles';
import { upload } from 'acadly/upload';
import * as u from 'acadly/utils';

import { Actions } from './actions';
import * as api from './api';

type Child<T, C extends keyof T> = {
  [P in keyof T[C]]: T[C][P];
};

export interface IResourceProps {
  resource: IResource;
  cls: IClass;
  courseRole: ICourseRole;
  course: ICourse;
  userId: string;
  isClassIncharge: boolean;
}

export type ResourceType = 'text' | 'file' | 'video' | 'link';

export interface IResourceState {
  selectedResourceType: ResourceType;
  isEditingTitle: boolean;
  editTitleField: string;
  editTitleError: boolean;

  isEditingUrl: boolean;
  editUrlField: string;
  editUrlError: string | null;

  isEditDescriptionDialogOpen: boolean;
  editDescriptionField: string;

  isEditingVideoURL: boolean;
  editVideoField: string;
  editVideoError: string | null;

  isUploadingFile: boolean;
  uploadProgress: number | null;

  editFileField: {
    originalFileName: string;
    fileName: string;
  } | null;
  editFileError: boolean;

  isMoveDialogOpen: boolean;
  isDeleteDialogOpen: boolean;
  isPublishDialogOpen: boolean;
  publishDialogSubscribeField: boolean;
  isCopyDialogOpen: boolean;
  updateResourceConfirmation: boolean;
  editResourceDialog: {
    resource: IResource;
    isEditing: boolean;
    selectedResourceType: ResourceType;
    isEditingTitle: boolean;
    title: string;
    description: string;
    url: string;
    editTitleField: string;
    editTitleError: boolean;

    isEditingUrl: boolean;
    editUrlField: string;
    editUrlError: string | null;

    isEditDescriptionDialogOpen: boolean;
    editDescriptionField: string;

    isEditingVideoURL: boolean;
    editVideoField: string;
    editVideoError: string | null;

    isUploadingFile: boolean;
    uploadProgress: number | null;

    editFileField: {
      originalFileName: string;
      fileName: string;
    };
    editFileError: boolean;
    toNotify: 0 | 1;
  };
}

export class Resource extends IComponent<IResourceProps, IResourceState> {
  public componentWillMount() {
    const { resource, cls, courseRole } = this.getProps();
    const initialState: IResourceState = {
      selectedResourceType: resource.details.resourceType || 'text',

      isEditingTitle: false,
      editTitleField: resource.details.title || '',
      editTitleError: false,

      isEditDescriptionDialogOpen: false,
      editDescriptionField: resource.details.description || '',

      isEditingUrl: false,
      editUrlField: resource.details.url,
      editUrlError: null,

      isEditingVideoURL: false,
      editVideoError: null,
      editVideoField: resource.details.url,

      isUploadingFile: false,
      uploadProgress: null,
      editFileField: resource.details.fileName
        ? {
            fileName: resource.details.fileName,
            originalFileName: resource.details.originalFileName,
          }
        : null,
      editFileError: false,

      isMoveDialogOpen: false,

      isDeleteDialogOpen: false,

      publishDialogSubscribeField: true,
      isPublishDialogOpen: false,
      isCopyDialogOpen: false,
      updateResourceConfirmation: false,
      editResourceDialog: {
        resource: resource,
        isEditing: false,
        selectedResourceType: resource.details.resourceType || 'text',

        isEditingTitle: false,
        title: resource.details.title,
        description: resource.details.description,
        url: resource.details.url,
        editTitleField: resource.details.title || '',
        editTitleError: false,

        isEditDescriptionDialogOpen: false,
        editDescriptionField: resource.details.description || '',

        isEditingUrl: false,
        editUrlField: resource.details.url,
        editUrlError: null,

        isEditingVideoURL: false,
        editVideoError: null,
        editVideoField: resource.details.url,

        isUploadingFile: false,
        uploadProgress: null,
        editFileField: {
          fileName: resource.details.fileName,
          originalFileName: resource.details.originalFileName,
        },
        editFileError: false,
        toNotify: 1,
      },
    };
    this.setState(initialState).then(() => {
      if (courseRole === 'student') {
        dispatch(
          Actions.analyticsSet({
            request: {
              resourceId: resource._id,
              firstAccess: resource.userData ? 0 : 1,
              localTime: dt.format(dt.now(), 'YYYYMMDDTHH:mm'),
            },
            classId: cls._id,
            toBeDone: resource.details.toBeDone,
          })
        );
      }

      dispatch(appActions.setContext('resource'));
      dispatch(appActions.startTip(true));
      setTimeout(() => {
        const actionbar = document.getElementById('action-bar');
        actionbar ? actionbar.focus() : null;
      }, 0);
    });
  }

  private async updatePublishedResource() {
    const { resource, editFileField, editVideoField, url, toNotify } =
      this.getState().editResourceDialog;
    let data: api.IPublishPublishedResourceRequest = {
      resourceId: resource._id,
      classId: this.getProps().cls._id,
      resourceType:
        resource.details.resourceType !== '' ? resource.details.resourceType : ('text' as const),
      toNotify: toNotify,
      title: resource.details.title,
      description: resource.details.description,
    };
    if (resource.details.resourceType === 'file' && editFileField) {
      data = {
        ...data,
        originalFileName: editFileField.originalFileName,
        fileName: editFileField.fileName,
      };
    } else if (resource.details.resourceType === 'video' && editVideoField) {
      data = {
        ...data,
        videoId: editVideoField,
      };
    } else if (resource.details.resourceType === 'link' && url) {
      data = {
        ...data,
        url: resource.details.url,
      };
    }
    await dispatch(Actions.updatePublishedResource(data)).then(() => {
      this.setState({
        updateResourceConfirmation: false,
        editResourceDialog: {
          ...this.getState().editResourceDialog,
          isEditing: false,
        },
      });
    });
  }
  private isAccessible: boolean | undefined = false;

  private editResourceConfirmationDialog() {
    return Alert(
      {
        open: this.getState().updateResourceConfirmation,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('NO', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                updateResourceConfirmation: false,
              }),
          }),
          FlatButton('YES', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.updatePublishedResource(),
          }),
        ],
      },
      [
        h('div', [
          h('p', 'Are you sure you want to publish these changes?'),
          h('div', style(['flex', 'fullWidth', pt('1rem'), 'whiteBackground', 'spaceBetween']), [
            h('span', 'Send notifications to students'),
            Toggle({
              selected: this.getState().editResourceDialog.toNotify === 1,
              ontoggle: () =>
                this.setState({
                  editResourceDialog: {
                    ...this.getState().editResourceDialog,
                    toNotify: this.getState().editResourceDialog.toNotify === 0 ? 1 : 0,
                  },
                }),
            }),
          ]),
        ]),
      ]
    );
  }

  private areChangesPresentInUpdate() {
    const { resource } = this.getProps();
    const { title, description, url, videoId } =
      this.getState().editResourceDialog.resource.details;
    const { editFileField, isEditingUrl, isEditingVideoURL, isUploadingFile } =
      this.getState().editResourceDialog;
    return (
      (title === resource.details.title &&
        description === resource.details.description &&
        editFileField.originalFileName === resource.details.originalFileName &&
        editFileField.fileName === resource.details.fileName &&
        url === resource.details.url &&
        videoId === resource.details.videoId) ||
      isUploadingFile ||
      isEditingUrl ||
      isEditingVideoURL ||
      (resource.details.resourceType === 'file' &&
        (editFileField.originalFileName === '' || editFileField.fileName === ''))
    );
  }

  private EditResourceDialog() {
    return Dialog(
      {
        open: this.getState().editResourceDialog.isEditing,
        title: 'Editing Resource',
        style: {
          maxWidth: '40em',
          padding: '0em',
          paddingBottom: '1em',
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          padding: '0em',
          paddingLeft: '0em',
          paddingRight: '0em',
        },
        primaryAction: {
          label: 'UPDATE',
          disabled: this.areChangesPresentInUpdate(),
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () =>
            this.setState({
              updateResourceConfirmation: true,
            }),
        },
        secondaryAction: {
          label: 'Cancel',
          onclick: () =>
            this.setState({
              editResourceDialog: {
                ...this.getState().editResourceDialog,
                isEditing: false,
              },
            }),
          mobileLabel: h('i.fa.fa-times', []),
        },
      },
      [h('div', style([pad('1rem')]), [this.editResourceDetailsSection()])]
    );
  }

  public render() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const isMobile = getStore().getState().app.isMobile;
    return h(
      u.getHTMLTagSelector('div', ['activity', this.isActionBarVisible() ? 'with-action-bar' : '']),
      [
        isMobile || isIpad ? this.actionBar() : null,
        ContentView(
          h('div.activity__content', [
            isMobile || isIpad ? null : this.actionBar(),
            this.classDetails(),
            this.isPreClassWarningVisible() ? this.preClassTimeWarning() : null,
            this.detailsSection(),
            this.creator(),
            this.moveDialog(),
            this.deleteDialog(),
            this.publishDialog(),
            this.copyDialog(),
            this.canEditResource() ? this.EditResourceDialog() : null,
            this.canEditResource() ? this.editResourceConfirmationDialog() : null,
          ])
        ),
      ]
    );
  }

  private actionBar() {
    if (!this.isActionBarVisible()) return null;
    return ActionBar(
      [
        {
          label: 'Delete',
          onclick: () => this.deleteHandler(),
          classNames: ['red'],
          icon: {
            type: 'view',
            view: Icon(icons.trash),
          },
          disabled: !this.isDeleteEnabled(),
        },
        {
          label: 'Copy',
          onclick: () => this.openCopyDialog(),
          classNames: ['blue'],
          icon: {
            type: 'view',
            view: Icon(icons.copy),
          },
          disabled: !this.isCopyEnabled(),
        },
        {
          label: 'Move',
          onclick: () => this.moveHandler(),
          classNames: ['blue'],
          icon: {
            type: 'view',
            view: Icon(icons.move),
          },
          disabled: !this.isMoveEnabled(),
        },
        {
          label: this.canEditResource() ? 'Edit' : 'Publish',
          onclick: () =>
            this.canEditResource()
              ? this.setState({
                  editResourceDialog: {
                    ...this.getState().editResourceDialog,
                    resource: this.getProps().resource,
                    isEditing: true,
                    editFileField: {
                      fileName: this.getProps().resource.details.fileName,
                      originalFileName: this.getProps().resource.details.originalFileName,
                    },
                    editUrlField: this.getProps().resource.details.url,
                    isEditingVideoURL: false,
                    editVideoField: this.getProps().resource.details.videoId,
                  },
                })
              : this.openPublishDialog(),
          classNames: ['orange'],
          icon: {
            type: 'view',
            view: this.canEditResource() ? Icon(icons.pencil) : Icon(icons.publish),
          },
          disabled: !(this.canEditResource() || this.isPublishButtonEnabled()),
        },
      ],
      this.isAccessible
    );
  }

  private isPublishButtonEnabled() {
    const { course, resource, isClassIncharge } = this.getProps();
    return !course.isArchived && !resource.details.published && isClassIncharge;
  }

  private classDetails() {
    const { resource, cls } = this.getProps();
    const toBeDone = {
      preClass: 'Pre-class',
      inClass: 'In-class',
    }[resource.details.toBeDone];

    return h(
      'div.cell',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        h('span.cell__label', [
          `${toBeDone} resource for class on `,
          dt.format(cls.details.scheStartTime, 'MMM DD, YYYY [at] hh:mm A'),
        ]),
      ]
    );
  }

  private preClassTimeWarning() {
    const cls = this.getProps().cls;
    const timeDiff = dt.format(cls.details.scheStartTime - 30 * 60, 'MMM DD, YYYY [at] hh:mm A');

    return h('div.cell', [
      h('span.cell__label', [
        `You must publish this activity by ${timeDiff}`,
        ' for students to attempt it before the class',
      ]),
    ]);
  }

  private isPreClassWarningVisible() {
    const cls = this.getProps().cls;
    const resource = this.getProps().resource;
    return (
      dt.diff(dt.fromUnix(cls.details.scheStartTime), dt.now(), 'minutes') < 60 &&
      resource.details.toBeDone === 'preClass' &&
      resource.details.published === 0
    );
  }

  private resourceTypeSelector() {
    if (!this.isEditingEnabled()) return null;

    const { selectedResourceType } = this.getState();

    const resourceTypeIcon = (resourceType: ResourceType) =>
      ({
        text: icons.resourceText,
        file: icons.file,
        video: icons.resourceVideo,
        link: icons.resourceURL,
      }[resourceType]);

    const resourceTypeButton = (resourceType: ResourceType) =>
      h(
        u.getHTMLTagSelector('div', [
          'resource__type-button',
          'ripple',
          selectedResourceType === resourceType ? 'selected' : '',
        ]),
        {
          role: 'button',
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `${resourceType} type resource`,
          onclick: () =>
            this.setState({
              selectedResourceType: resourceType,
            }),
        },
        [
          h('div.fs-xxlg', [Icon(resourceTypeIcon(resourceType))]),
          h('div.fs-sm', u.capitalize(resourceType)),
        ]
      );

    return h(
      'div.activity__section',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'arial-label': 'select resource type',
      },
      [
        this.sectionLabel('Select resource type'),
        h('div.resource__types', [
          resourceTypeButton('text'),
          resourceTypeButton('file'),
          resourceTypeButton('video'),
          resourceTypeButton('link'),
        ]),
      ]
    );
  }

  private detailsSection() {
    const { selectedResourceType } = this.getState();
    const { resource } = this.getProps();
    const hasAttachment = resource.details.resourceType === 'file' && resource.details.fileName;

    if (this.isEditingEnabled()) {
      return h('div.resource-details-section-editable', [
        this.titleEditableCard(),
        this.descriptionEditableCard(),
        this.resourceTypeSelector(),

        selectedResourceType === 'file' ? this.fileEditableCard() : null,

        selectedResourceType === 'video' ? this.videoEditableCard() : null,

        selectedResourceType === 'link' ? this.linkEditableCard() : null,
      ]);
    } else {
      return h('div.resource-details-section', [
        h(
          'div.activity__title',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': `Resource Title: ${resource.details.title || `Untitled`}`,
          },
          [resource.details.title || 'Untitled']
        ),

        h(
          'div.activity__description',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': `Resource Description: ${resource.details.description}`,
          },
          [Viewer(resource.details.description)]
        ),

        h(
          'div.activity__section',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': `resource type: ${resource.details.resourceType}`,
          },
          [
            resource.details.resourceType === 'link'
              ? h(
                  'a.fc-blue',
                  {
                    tabIndex: this.isAccessible ? 0 : undefined,
                    href: u.convertLinkToURL(resource.details.url),
                    target: '_blank',
                  },
                  resource.details.url
                )
              : null,

            hasAttachment
              ? AttachmentViewer({
                  disabled: !resource.details.fileName,
                  attachment: {
                    originalName:
                      u.splitFileName(resource.details.originalFileName).name ||
                      'No file added yet',
                    extension: u.splitFileName(resource.details.originalFileName).extension,
                    name: resource.details.fileName,
                  },
                  downloadUrl: urls().resourceFileDownload,
                  downloadRequest: {
                    resourceId: resource._id,
                    fileName: resource.details.fileName,
                  },
                })
              : null,

            resource.details.resourceType === 'video'
              ? VideoPlayer({
                  videoId: resource.details.videoId,
                })
              : null,
          ]
        ),
      ]);
    }
  }

  private editResourceDetailsSection() {
    const { resourceType } = this.getState().editResourceDialog.resource.details;
    return h('div.resource-details-section-editable', [
      // this.editResourceFileEditableCard(),
      // this.editResourceVideoEditableCard(),
      // this.editResourceLinkEditableCard(),
      this.editResourceTitleEditableCard(),
      this.editResourceDescriptionEditableCard(),

      resourceType !== 'text'
        ? h(
            'div',
            style(
              ['orange', pad('0.5rem')],
              {},
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              }
            ),
            [
              h('i.fa.fa-exclamation-triangle', style([mr('0.5rem')])),
              'You cannot edit the resource type',
            ]
          )
        : null,

      resourceType === 'file'
        ? this.editResourceFileEditableCard()
        : resourceType === 'video'
        ? this.editResourceVideoEditableCard()
        : resourceType === 'link'
        ? this.editResourceLinkEditableCard()
        : null,
    ]);
  }

  private fileEditableCard() {
    const { resource } = this.getProps();
    const { isUploadingFile } = this.getState();
    return h('div.activity__section', this.accessibilityAttrs(), [
      this.uploadProgressDialog(),

      resource.details.fileName
        ? AttachmentViewer({
            attachment: {
              originalName: u.splitFileName(resource.details.originalFileName).name,
              extension: u.splitFileName(resource.details.originalFileName).extension,
              name: resource.details.fileName,
            },
            downloadUrl: urls().resourceFileDownload,
            style: {
              width: '100%',
            },
            downloadRequest: {
              resourceId: resource._id,
              fileName: resource.details.fileName,
            },
            actionButton: Icon(icons.cross, {
              className: 'resource__delete-button ripple',
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => this.fileRemoveHandler(),
            }),
          })
        : UploadButton({
            tabIndex: this.isAccessible ? 0 : undefined,
            ariaLabel: 'Upload a file',
            view: h('div.panel.panel--inline', [
              h(
                'div.panel__header',
                isUploadingFile ? 'Uploading...' : [h('span', 'Upload a file'), h('i.fa.fa-upload')]
              ),
            ]),
            upload: (file) => this.fileUploadHandler(file),
          }),
    ]);
  }
  private editResourceFileRemoveHandler() {
    this.setState({
      editResourceDialog: {
        ...this.getState().editResourceDialog,
        editFileField: {
          originalFileName: '',
          fileName: '',
        },
      },
    });
  }

  private editResourceFileEditableCard() {
    const { editFileField, editFileError, resource } = this.getState().editResourceDialog;

    if (!editFileField) return null;

    return h('div.activity__section', this.accessibilityAttrs(), [
      this.uploadProgressDialog(),

      editFileField.fileName
        ? AttachmentViewer({
            attachment: {
              originalName: u.splitFileName(editFileField.originalFileName).name,
              extension: u.splitFileName(editFileField.fileName).extension,
              name: editFileField.fileName,
            },
            hideDownloadIcon: true,
            downloadUrl: urls().resourceFileDownload,
            style: {
              width: '100%',
            },
            downloadRequest: {
              resourceId: resource._id,
              fileName: resource.details.fileName,
            },
            actionButton: Icon(icons.cross, {
              className: 'resource__delete-button ripple',
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => this.editResourceFileRemoveHandler(),
            }),
          })
        : UploadButton({
            tabIndex: this.isAccessible ? 0 : undefined,
            ariaLabel: 'Upload a file',
            view: h('div.panel.panel--inline', [
              h('div.panel__header', [h('span', 'Upload a file'), h('i.fa.fa-upload')]),
            ]),
            upload: (file) => this.fileUploadHandler(file, true),
          }),
      editFileError ? h('p.fc-red.fs-sm', 'Please upload a file and try again') : null,
    ]);
  }

  private uploadProgressDialog() {
    const { uploadProgress, isUploadingFile } = this.getState();
    return Alert(
      {
        open: isUploadingFile,
        bodyStyle: {
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      [
        h('div', 'Uploading file'),
        h('div', style(['flex', 'alignCenter', 'spaceBetween', 'fullWidth']), [
          ProgressBar(uploadProgress || 0),
          h(
            'span',
            style([
              'borderBox',
              ml('0.5rem'),
              {
                flex: '1',
              },
            ]),
            `${Math.round(uploadProgress || 0)}%`
          ),
        ]),
      ]
    );
  }

  private getResourceState(isEditing: boolean) {
    return isEditing ? this.getState().editResourceDialog : this.getState();
  }

  private setResourceState(
    isEditing: boolean,
    state: Partial<IResourceState> | Partial<Child<IResourceState, 'editResourceDialog'>>
  ) {
    if (isEditing) {
      const updates = state as Partial<Child<IResourceState, 'editResourceDialog'>>;
      return this.setState({
        editResourceDialog: {
          ...this.getState().editResourceDialog,
          ...updates,
        },
      });
    }
    return this.setState(state);
  }

  private editVideoURLDialog(isEditingResource = false) {
    const { isEditingVideoURL, editVideoField, editVideoError } =
      this.getResourceState(isEditingResource);

    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'YouTube video link',
        open: isEditingVideoURL,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setResourceState(isEditingResource, {
                isEditingVideoURL: false,
              }).then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-video');
                btn ? btn.focus() : null;
              }),
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.editVideoHandler(isEditingResource).then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-video');
                btn ? btn.focus() : null;
              }),
          }),
        ],
      },
      [
        TextField({
          value: editVideoField,
          focusOnMount: true,
          errorText: editVideoError,
          placeholder: 'YouTube video link',
          oninput: (e) =>
            this.setResourceState(isEditingResource, {
              editVideoField: e.target.value,
              editVideoError: null,
            }),
          onenter: () => this.editVideoHandler(isEditingResource),
        }),
      ]
    );
  }

  private videoEditableCard() {
    const { resource } = this.getProps();

    return h('div.panel', this.accessibilityAttrs(), [
      h('div.panel__header', [
        h('span', 'Provide YouTube video link'),
        h('i.fa.fa-pencil#edit-video', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () =>
            this.setState({
              isEditingVideoURL: true,
              editVideoField: resource.details.videoId || '',
            }),
        }),
      ]),
      h('div.panel__content', [
        resource.details.videoId
          ? VideoPlayer({
              videoId: resource.details.videoId,
              buttons: [
                {
                  label: 'delete',
                  icon: h('i.fa.fa-trash.fc-red'),
                  tabIndex: this.isAccessible ? 0 : undefined,
                  onclick: () => this.videoRemoveHandler(),
                },
              ],
            })
          : h('span.fs-sm.fc-light-grey', [
              "This resource doesn't have a video URL yet. ",
              'Click on the ',
              h('i.fa.fa-pencil'),
              ' icon to add a video URL.',
            ]),
      ]),
      this.editVideoURLDialog(),
    ]);
  }

  private editResourceVideoEditableCard() {
    const { resource } = this.getState().editResourceDialog;
    return h('div.panel', this.accessibilityAttrs(), [
      h('div.panel__header', [
        h('span', 'Provide YouTube video link'),
        h('i.fa.fa-pencil#edit-video', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () =>
            this.setState({
              editResourceDialog: {
                ...this.getState().editResourceDialog,
                isEditingVideoURL: true,
                editVideoField: resource.details.videoId || '',
              },
            }),
        }),
      ]),
      h('div.panel__content', [
        resource.details.videoId
          ? VideoPlayer({
              videoId: resource.details.videoId,
              buttons: [
                {
                  label: 'delete',
                  icon: h('i.fa.fa-trash.fc-red'),
                  tabIndex: this.isAccessible ? 0 : undefined,
                  onclick: () => this.videoRemoveHandler(),
                },
              ],
            })
          : h('span.fs-sm.fc-light-grey', [
              "This resource doesn't have a video URL yet. ",
              'Click on the ',
              h('i.fa.fa-pencil'),
              ' icon to add a video URL.',
            ]),
      ]),
      this.editVideoURLDialog(true),
    ]);
  }

  private editLinkDialog(isEditingResource = false) {
    const { isEditingUrl, editUrlError, editUrlField } = this.getResourceState(isEditingResource);

    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Provide a URL',
        open: isEditingUrl,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setResourceState(isEditingResource, {
                isEditingUrl: false,
              }).then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-link');
                btn ? btn.focus() : null;
              }),
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.editLinkHandler(isEditingResource).then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-link');
                btn ? btn.focus() : null;
              }),
          }),
        ],
      },
      [
        TextField({
          value: editUrlField,
          focusOnMount: true,
          errorText: editUrlError,
          placeholder: 'Enter link here',
          onenter: () => this.editLinkHandler(isEditingResource),
          oninput: (e) =>
            this.setResourceState(isEditingResource, {
              editUrlError: null,
              editUrlField: e.target.value,
            }),
        }),
      ]
    );
  }

  private linkEditableCard() {
    const { resource } = this.getProps();
    return h('div.panel', this.accessibilityAttrs(), [
      h('div.panel__header', [
        h('span', 'Provide a URL'),
        h('i.fa.fa-pencil#edit-link', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () =>
            this.setState({
              isEditingUrl: true,
              editUrlField: resource.details.url,
              editUrlError: null,
            }),
        }),
      ]),
      h('div.panel__content', [
        resource.details.url
          ? h(
              'a.fc-blue',
              {
                href: u.convertLinkToURL(resource.details.url),
                target: '_blank',
              },
              resource.details.url
            )
          : h('span.fc-light-grey', [
              "This resource doesn't have a URL yet.",
              'Click on the ',
              h('i.fa.fa-pencil'),
              ' icon to add a URL.',
            ]),
      ]),
      this.editLinkDialog(),
    ]);
  }

  private editResourceLinkEditableCard() {
    const { resource, selectedResourceType } = this.getState().editResourceDialog;

    if (selectedResourceType !== 'link') {
      return null;
    }

    return h('div.panel', this.accessibilityAttrs(), [
      h('div.panel__header', [
        h('span', 'Provide a URL'),
        h('i.fa.fa-pencil#edit-link', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () =>
            this.setState({
              editResourceDialog: {
                ...this.getState().editResourceDialog,
                isEditingUrl: true,
                editUrlError: null,
              },
            }),
        }),
      ]),
      h('div.panel__content', [
        resource.details.url
          ? h(
              'a.fc-blue',
              {
                href: u.convertLinkToURL(resource.details.url),
                target: '_blank',
              },
              resource.details.url
            )
          : h('span.fc-light-grey', [
              "This resource doesn't have a URL yet.",
              'Click on the ',
              h('i.fa.fa-pencil'),
              ' icon to add a URL.',
            ]),
      ]),
      this.editLinkDialog(true),
    ]);
  }

  private editTitleDialog() {
    const { isEditingTitle, editTitleField, editTitleError } = this.getState();
    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Resource Title',
        open: isEditingTitle,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                isEditingTitle: false,
              }).then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-title');
                btn ? btn.focus() : null;
              }),
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.editTitleHandler().then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-title');
                btn ? btn.focus() : null;
              }),
          }),
        ],
      },
      [
        TextField({
          value: editTitleField,
          focusOnMount: true,
          errorText: editTitleError ? 'Please enter a title' : undefined,
          placeholder: 'Enter title here',
          maxLength: 140,
          oninput: (e) =>
            this.setState({
              editTitleField: e.target.value,
              editTitleError: false,
            }),
          onenter: () => this.editTitleHandler(),
        }),
      ]
    );
  }

  private editResourceEditTitleDialog() {
    const { isEditingTitle, editTitleField, editTitleError } = this.getState().editResourceDialog;
    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Resource Title',
        open: isEditingTitle,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                editResourceDialog: {
                  ...this.getState().editResourceDialog,
                  isEditingTitle: false,
                },
              }),
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.editTitleHandler(true),
          }),
        ],
      },
      [
        TextField({
          value: editTitleField,
          focusOnMount: true,
          errorText: editTitleError ? 'Please enter a title' : undefined,
          placeholder: 'Enter title here',
          maxLength: 140,
          oninput: (e) =>
            this.setState({
              editResourceDialog: {
                ...this.getState().editResourceDialog,
                editTitleField: e.target.value,
                editTitleError: false,
              },
            }),
          onenter: () => this.editTitleHandler(true),
        }),
      ]
    );
  }

  private titleEditableCard() {
    const { resource } = this.getProps();

    return h('div.panel', this.accessibilityAttrs(), [
      h('div.panel__header', [
        h('span', 'Title'),
        h('i.fa.fa-pencil#edit-title', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            u.unsetTabIndices();
            this.setState({
              isEditingTitle: true,
              editTitleField: resource.details.title,
              editTitleError: false,
            });
          },
        }),
      ]),
      this.editTitleDialog(),
      h(
        'div.panel__content.fs-sm',
        resource.details.title || [
          h('span.fc-light-grey', [
            "The announcement doesn't have a title yet. ",
            'Tap on the ',
            h('i.fa.fa-pencil'),
            ' icon to add a title.',
          ]),
        ]
      ),
    ]);
  }

  private editResourceTitleEditableCard() {
    const { resource } = this.getState().editResourceDialog;

    return h('div.panel', this.accessibilityAttrs(), [
      h('div.panel__header', [
        h('span', 'Title'),
        h('i.fa.fa-pencil#edit-title', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            u.unsetTabIndices();
            this.setState({
              editResourceDialog: {
                ...this.getState().editResourceDialog,
                isEditingTitle: true,
                editTitleField: resource.details.title,
                editTitleError: false,
              },
            });
          },
        }),
      ]),
      this.editResourceEditTitleDialog(),
      h(
        'div.panel__content.fs-sm',
        resource.details.title || [
          h('span.fc-light-grey', [
            "The announcement doesn't have a title yet. ",
            'Tap on the ',
            h('i.fa.fa-pencil'),
            ' icon to add a title.',
          ]),
        ]
      ),
    ]);
  }

  private descriptionEditableCard() {
    const { resource } = this.getProps();
    return h('div.panel', this.accessibilityAttrs(), [
      h('div.panel__header', [
        h('span', 'Description'),
        h('i.fa.fa-pencil#edit-description', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            u.unsetTabIndices();
            this.setState({
              isEditDescriptionDialogOpen: true,
              editDescriptionField: resource.details.description,
            });
          },
        }),
      ]),
      h('div.panel__content.fs-sm', [
        resource.details.description
          ? Viewer(resource.details.description)
          : h('span.fc-light-grey', [
              'To add rich text description, tap on the ',
              h('i.fa.fa-pencil'),
              ' icon.',
            ]),
      ]),
      this.editDescriptionDialog(),
    ]);
  }

  private editResourceDescriptionEditableCard() {
    const { resource } = this.getState().editResourceDialog;

    return h('div.panel', this.accessibilityAttrs(), [
      h('div.panel__header', [
        h('span', 'Description'),
        h('i.fa.fa-pencil#edit-description', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            u.unsetTabIndices();
            this.setState({
              editResourceDialog: {
                ...this.getState().editResourceDialog,
                isEditDescriptionDialogOpen: true,
                editDescriptionField: resource.details.description,
                isEditingUrl: false,
                isEditingVideoURL: false,
              },
            });
          },
        }),
      ]),
      h('div.panel__content.fs-sm', [
        resource.details.description
          ? Viewer(resource.details.description)
          : h('span.fc-light-grey', [
              'To add rich text description, tap on the ',
              h('i.fa.fa-pencil'),
              ' icon.',
            ]),
      ]),
      this.editResourceEditDescriptionDialog(),
    ]);
  }

  private editResourceEditDescriptionDialog() {
    const { isEditDescriptionDialogOpen, editDescriptionField } =
      this.getState().editResourceDialog;
    return Dialog(
      {
        open: isEditDescriptionDialogOpen,
        title: 'Editing description',
        style: {
          padding: '0em',
        },
        bodyStyle: {
          padding: '0em',
          backgroundColor: colors.backgroundColor,
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.editDescriptionHandler(true),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              editResourceDialog: {
                ...this.getState().editResourceDialog,
                isEditDescriptionDialogOpen: false,
              },
            }),
        },
      },
      isEditDescriptionDialogOpen
        ? [
            Editor({
              value: editDescriptionField,
              subContext: 'description',
              oninput: (text) =>
                this.setState({
                  editResourceDialog: {
                    ...this.getState().editResourceDialog,
                    editDescriptionField: text,
                  },
                }),
              enableFormulaInput: true,
              enableImageInput: true,
              enableTextFormatting: true,
            }),
          ]
        : []
    );
  }

  private editDescriptionDialog() {
    const { isEditDescriptionDialogOpen, editDescriptionField } = this.getState();
    return Dialog(
      {
        open: isEditDescriptionDialogOpen,
        title: 'Editing description',
        style: {
          padding: '0em',
        },
        bodyStyle: {
          padding: '0em',
          backgroundColor: colors.backgroundColor,
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () =>
            this.editDescriptionHandler().then(() => {
              u.resetTabIndices();
              const btn = document.getElementById('edit-description');
              btn ? btn.focus() : null;
            }),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              isEditDescriptionDialogOpen: false,
            }).then(() => {
              u.resetTabIndices();
              const btn = document.getElementById('edit-description');
              btn ? btn.focus() : null;
            }),
        },
      },
      isEditDescriptionDialogOpen
        ? [
            Editor({
              value: editDescriptionField,
              subContext: 'description',
              oninput: (text) =>
                this.setState({
                  editDescriptionField: text,
                }),
              enableFormulaInput: true,
              enableImageInput: true,
              enableTextFormatting: true,
            }),
          ]
        : []
    );
  }

  private creator() {
    const { resource } = this.getProps();
    let status: string;
    let time: UnixTimestamp;
    if (resource.details.published) {
      status = 'Published';
      time = resource.details.publishedOn;
    } else {
      status = 'Created';
      time = resource.details.createdOn;
    }
    return h(
      'div.user',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `${status} by ${resource.details.createdBy.name}
                on ${dt.format(time, 'MMM DD, YYYY [at] hh:mm A')}`,
      },
      [
        Avatar(resource.details.createdBy.avatar, resource.details.createdBy.name, {
          className: 'user__avatar',
        }),
        h('div.user__details.fc-light-grey', [
          h('div', `${status} by ${resource.details.createdBy.name}`),
          h('div', dt.format(time, 'MMM DD, YYYY [at] hh:mm A')),
        ]),
      ]
    );
  }

  private async fileRemoveHandler() {
    return this.editResource({
      fileName: '',
      originalFileName: '',
    });
  }

  private fileUploadHandler(file: File, isEditingResource?: boolean) {
    const { cls, resource } = this.getProps();

    const { name, extension } = u.splitFileName(file.name);
    const { promise, progress$ } = upload(
      urls().resourceFileUpload,
      {
        originalFileName: name,
        fileType: extension,
        activityType: 'resources',
        classId: cls._id,
        activityId: resource._id,
      },
      file
    );

    if (isEditingResource) {
      return {
        promise: promise.then((response) => {
          this.setState({
            editResourceDialog: {
              ...this.getState().editResourceDialog,
              editFileField: {
                fileName: response.name,
                originalFileName: file.name,
              },
              editFileError: false,
            },
          });
        }),
        progress$,
      };
    }

    return {
      promise: promise.then((response) => {
        this.setState({
          editFileField: {
            fileName: response.name,
            originalFileName: file.name,
          },
        }).then(() =>
          this.editResource({
            originalFileName: file.name,
            fileName: response.name,
          })
        );
      }),
      progress$,
    };
  }

  private async editVideoHandler(isEditingResource?: boolean) {
    if (isEditingResource) {
      const { editVideoField } = this.getState().editResourceDialog;
      const videoId = u.parseYoutubeUrl(editVideoField);
      if (!videoId || editVideoField.trim() === '') {
        await this.setState({
          editResourceDialog: {
            ...this.getState().editResourceDialog,
            editVideoError: 'Please enter a valid YouTube video URL',
          },
        });
        return;
      } else {
        await this.setState({
          editResourceDialog: {
            ...this.getState().editResourceDialog,
            isEditingVideoURL: false,
            editVideoField: videoId,
            resource: {
              ...this.getState().editResourceDialog.resource,
              details: {
                ...this.getState().editResourceDialog.resource.details,
                videoId: videoId,
              },
            },
          },
        });
      }
      return;
    }
    const { editVideoField } = this.getState();
    const videoId = u.parseYoutubeUrl(editVideoField);
    if (!videoId) {
      await this.setState({
        editVideoError: 'Please enter a valid YouTube video URL',
      });
      return;
    } else {
      await this.editResource({
        videoId: videoId,
      });
      await this.setState({
        isEditingVideoURL: false,
      });
    }
  }

  private async editLinkHandler(isEditingResource?: boolean) {
    if (isEditingResource) {
      const { editUrlField } = this.getState().editResourceDialog;
      if (!editUrlField.trim() || !anchorme.validate.url(editUrlField)) {
        await this.setState({
          editResourceDialog: {
            ...this.getState().editResourceDialog,
            editUrlError: 'Please enter a valid link',
          },
        });
        return;
      }
      const url = u.convertLinkToURL(editUrlField);
      await this.setState({
        editResourceDialog: {
          ...this.getState().editResourceDialog,
          isEditingUrl: false,
          resource: {
            ...this.getState().editResourceDialog.resource,
            details: {
              ...this.getState().editResourceDialog.resource.details,
              url: url,
            },
          },
        },
      });
      return;
    }
    const { editUrlField } = this.getState();
    if (!editUrlField.trim() || !anchorme.validate.url(editUrlField)) {
      await this.setState({
        editUrlError: 'Please enter a valid link',
      });
      return;
    }
    const url = u.convertLinkToURL(editUrlField);
    await this.editResource({
      url: url,
    });
    await this.setState({
      isEditingUrl: false,
    });
  }

  private async editDescriptionHandler(isEditingResource?: boolean) {
    if (isEditingResource) {
      const { editDescriptionField } = this.getState().editResourceDialog;
      await this.setState({
        editResourceDialog: {
          ...this.getState().editResourceDialog,
          isEditDescriptionDialogOpen: false,
          resource: {
            ...this.getState().editResourceDialog.resource,
            details: {
              ...this.getState().editResourceDialog.resource.details,
              description: editDescriptionField,
            },
          },
        },
      });
      return;
    }
    const { editDescriptionField } = this.getState();
    await this.editResource({
      description: editDescriptionField,
    });
    await this.setState({
      isEditDescriptionDialogOpen: false,
    });
  }

  private accessibilityAttrs(): any {
    return {
      tabIndex: this.isAccessible ? 0 : undefined,
    };
  }

  private async editTitleHandler(isEditingResource?: boolean) {
    if (isEditingResource) {
      const { editTitleField } = this.getState().editResourceDialog;
      if (!editTitleField.trim()) {
        await this.setState({
          editResourceDialog: {
            ...this.getState().editResourceDialog,
            editTitleError: true,
          },
        });
        return;
      } else {
        // await this.editResource({
        //     title: editTitleField,
        // });
        await this.setState({
          editResourceDialog: {
            ...this.getState().editResourceDialog,
            isEditingTitle: false,
            resource: {
              ...this.getState().editResourceDialog.resource,
              details: {
                ...this.getState().editResourceDialog.resource.details,
                title: editTitleField,
              },
            },
          },
        });
      }
      return;
    }
    const { editTitleField } = this.getState();
    if (!editTitleField.trim()) {
      await this.setState({
        editTitleError: true,
      });
      return;
    } else {
      await this.editResource({
        title: editTitleField,
      });
      await this.setState({
        isEditingTitle: false,
      });
    }
  }

  private async editResource(partial: Partial<api.IEditRequest>) {
    const { selectedResourceType } = this.getState();
    const { resource, cls } = this.getProps();
    await dispatch(
      Actions.edit({
        resourceId: resource._id,
        classId: cls._id,
        resourceType: selectedResourceType,
        description: resource.details.description,
        title: resource.details.title,
        url: resource.details.url,
        videoId: resource.details.videoId,
        originalFileName: resource.details.originalFileName,
        fileName: resource.details.fileName,
        ...partial,
      })
    );
  }

  private sectionLabel(label: string) {
    return h('div.cell', [h('span.cell__label', label)]);
  }

  private isActionBarVisible() {
    return (
      this.isPublishEnabled() ||
      this.isDeleteEnabled() ||
      this.isMoveEnabled() ||
      this.isCopyEnabled()
    );
  }

  private isPublishEnabled() {
    const { resource, course, cls } = this.getProps();
    if (resource.details.published) return false;
    if (course.isArchived) return false;
    if (!this.isCreator()) return false;
    if (resource.details.resourceType === '') return false;
    if (resource.details.resourceType === 'text' && !resource.details.description) return false;
    if (resource.details.toBeDone === 'preClass') {
      return dt.diff(dt.fromUnix(cls.details.scheStartTime), dt.now(), 'minutes') >= 30;
    } else {
      return cls.details.status === 'inSession';
    }
  }

  private isMoveEnabled() {
    const { course, resource, isClassIncharge } = this.getProps();
    return !course.isArchived && !resource.details.published && isClassIncharge;
  }

  private isCopyEnabled() {
    const { courseRole } = this.getProps();
    return courseRole === 'instructor' || courseRole === 'admin';
  }

  private isDeleteEnabled() {
    return this.isEditingEnabled();
  }

  private async openPublishDialog() {
    const { course, resource, cls } = this.getProps();
    resource.details.resourceType = this.getState().selectedResourceType;
    if (resource.details.resourceType === 'link') {
      resource.details.url = this.getState().editUrlField;
      this.editLinkHandler();
    }
    if (resource.details.resourceType === 'video') {
      if (this.getState().editVideoField !== '') {
        this.editVideoHandler();
        const videoId = u.parseYoutubeUrl(this.getState().editVideoField);
        if (!videoId) {
          return;
        }
        resource.details.videoId = videoId;
      }
    }
    const valid = validateActivityPublish({
      activityType: 'class',
      activity: resource,
      cls,
      course,
    });
    if (!valid) {
      return;
    } else {
      this.lastFocusedElement = document.activeElement;
      u.unsetTabIndices();
      await this.setState({
        isPublishDialogOpen: true,
      });
    }
  }
  private canEditResource() {
    const { resource, isClassIncharge } = this.getProps();
    return resource.details.published && isClassIncharge;
  }

  private publishDialog() {
    const { resource, cls } = this.getProps();
    const isMobile = getStore().getState().app.isMobile;
    const headingAttr = style(
      ['small', 'lightGrey', pad('0.5em')],
      {},
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      }
    );
    const rowAttr = style(
      [pad('0.5rem'), 'flex', 'spaceBetween', 'large'],
      {},
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      }
    );
    const toggleRowAttr = style(
      [
        'large',
        pad('0.5em 1em'),
        'flex',
        'spaceBetween',
        'alignCenter',
        {
          backgroundColor: 'white',
        },
      ],
      {},
      { tabIndex: this.isAccessible ? 0 : undefined }
    );
    return Dialog(
      {
        open: this.getState().isPublishDialogOpen,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        title: 'Publishing Resource',
        style: {
          width: '30em',
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          padding: '0',
        },
        primaryAction: isMobile
          ? {
              type: 'view',
              view: DivButton({
                onclick: () => this.publish(),
                style: style([pad('0.5rem')]).style,
                children: [h('i.fa.fa-check.ripple', [])],
              }),
            }
          : {
              label: 'Publish',
              onclick: () => this.publish(),
            },
        secondaryAction: isMobile
          ? {
              type: 'view',
              view: DivButton({
                onclick: () =>
                  this.setState({
                    isPublishDialogOpen: false,
                  }),
                style: style([pad('0.5rem')]).style,
                children: [h('i.fa.fa-times.ripple', {})],
              }),
            }
          : {
              label: 'Cancel',
              onclick: () =>
                this.setState({
                  isPublishDialogOpen: false,
                }).then(() => {
                  u.resetTabIndices();
                  (this.lastFocusedElement as HTMLElement).focus();
                }),
            },
      },
      [
        h('div', headingAttr, 'DETAILS'),
        h('div', rowAttr, [
          h('span', 'Type'),
          h(
            'span',
            {
              preClass: 'Pre-class',
              inClass: 'In-class',
            }[resource.details.toBeDone]
          ),
        ]),
        h('div', rowAttr, [
          h('span', 'Class'),
          dt.format(dt.fromUnix(cls.details.scheStartTime), 'hh:mm A, MMM DD, YYYY'),
        ]),

        h('div', headingAttr, 'ACTIVITY DISCUSSION'),
        h('div', toggleRowAttr, [
          h('span', 'Subscribe to comment notifications'),
          Toggle({
            selected: this.getState().publishDialogSubscribeField,
            ontoggle: () =>
              this.setState({
                publishDialogSubscribeField: !this.getState().publishDialogSubscribeField,
              }),
          }),
        ]),
        h(
          'div',
          style(
            ['small', 'lightGrey', pad('1rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          [
            'If you subscribe, you will receive a notification whenever someone adds' +
              " a comment to this activity's discussion",
          ]
        ),

        h('div', headingAttr, 'WARNINGS'),

        h(
          'div',
          style(
            ['orange', 'small', pad('0.5rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          [
            h('i.fa.fa-exclamation-triangle', style([mr('0.5rem')])),
            'The course participants will be notified instantly via ' +
              'email and push notifications',
          ]
        ),
        h(
          'div',
          style(
            ['orange', 'small', pad('0.5rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          [
            h('i.fa.fa-exclamation-triangle', style([mr('0.5rem')])),
            "You won't be able to change the text description once " +
              'the resource has been published',
          ]
        ),
        h(
          'div',
          style(
            ['orange', 'small', pad('0.5rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          [
            h('i.fa.fa-exclamation-triangle', style([mr('0.5rem')])),
            'Once published, the activity cannot be deleted',
          ]
        ),
      ]
    );
  }

  private async videoRemoveHandler() {
    await this.editResource({
      videoId: '',
    });
  }

  private async publish() {
    const { cls, resource } = this.getProps();
    const { selectedResourceType } = this.getState();
    const subscribeToComments = this.getState().publishDialogSubscribeField;
    u.resetTabIndices();
    await dispatch(
      Actions.publish({
        resourceId: resource._id,
        classId: cls._id,
        toBeDone: resource.details.toBeDone,
        resourceType: selectedResourceType,
        description: resource.details.description,
        title: resource.details.title,
        url: resource.details.url,
        videoId: resource.details.videoId,
        originalFileName: resource.details.originalFileName,
        fileName: resource.details.fileName,
        subscribeToComments: subscribeToComments === true ? 1 : 0,
      })
    ).then(() => {
      const resource = this.getProps().resource;
      this.setState({
        editResourceDialog: {
          resource: resource,
          isEditing: false,
          selectedResourceType: selectedResourceType,

          isEditingTitle: false,
          title: resource.details.title,
          description: resource.details.description,
          url: resource.details.url,
          editTitleField: resource.details.title || '',
          editTitleError: false,

          isEditDescriptionDialogOpen: false,
          editDescriptionField: resource.details.description || '',

          isEditingUrl: false,
          editUrlField: resource.details.url,
          editUrlError: null,

          isEditingVideoURL: false,
          editVideoError: null,
          editVideoField: resource.details.url,

          isUploadingFile: false,
          uploadProgress: null,
          editFileField: {
            fileName: resource.details.fileName,
            originalFileName: resource.details.originalFileName,
          },
          editFileError: false,
          toNotify: 1,
        },
      });
    });
    googleAnalytics.resourcePublished(
      {
        link: 'url',
        file: 'file',
        video: 'video',
        text: 'text',

        // never happens because can't publish
        // without setting resource type
        // kept here so that typechecker
        // stops complaining about unhandled
        // case
        '': 'file',
      }[resource.details.resourceType] as any,
      resource.details.toBeDone === 'preClass' ? 'pre-class' : 'in-class'
    );
    await this.setState({
      isPublishDialogOpen: false,
    });
  }

  private deleteDialog() {
    return Alert(
      {
        open: this.getState().isDeleteDialogOpen,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('NO', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.setState({
                isDeleteDialogOpen: false,
              }).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              }),
          }),
          FlatButton('YES', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.deleteResource().then(() => {
                u.resetTabIndices();
              }),
          }),
        ],
      },
      ['Are you sure you want to delete this resource?']
    );
  }

  private copyDialog() {
    if (!this.isCopyEnabled()) {
      return null;
    }
    const props = this.getProps();
    return CopyActivityDialog({
      open: this.getState().isCopyDialogOpen,
      classId: props.cls._id,
      activityId: props.resource._id,
      activityType: 'resources',
      onDone: () => this.closeCopyDialog(),
      onCancel: () => this.closeCopyDialog(),
    });
  }

  private moveDialog() {
    if (!this.isMoveEnabled()) {
      return null;
    }
    return MoveActivityDialog({
      open: this.getState().isMoveDialogOpen,
      class: this.getProps().cls,
      activity: this.getProps().resource,
      ondone: () =>
        this.setState({
          isMoveDialogOpen: false,
        }).then(() => {
          u.resetTabIndices();
          (this.lastFocusedElement as HTMLElement).focus();
        }),
      oncancel: () =>
        this.setState({
          isMoveDialogOpen: false,
        }).then(() => {
          u.resetTabIndices();
          (this.lastFocusedElement as HTMLElement).focus();
        }),
    });
  }
  private lastFocusedElement: Element | null = null;
  private async deleteHandler() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isDeleteDialogOpen: true,
    });
  }

  private async deleteResource() {
    const { cls, resource } = this.getProps();
    await dispatch(
      Actions.deleteResource(
        {
          classId: cls._id,
          resourceId: resource._id,
          toBeDone: resource.details.toBeDone,
        },
        goBack
      )
    );
    googleAnalytics.activityDeleted(
      'resource',
      resource.details.toBeDone === 'preClass' ? 'pre-class' : 'in-class'
    );
    await this.setState({
      isDeleteDialogOpen: false,
    });
  }

  private async moveHandler() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isMoveDialogOpen: true,
    });
  }

  private isEditingEnabled() {
    const { course, resource } = this.getProps();
    return !course.isArchived && !resource.details.published && this.isCreator();
  }

  private isCreator() {
    const { userId, resource } = this.getProps();
    return userId === resource.details.createdBy.userId;
  }

  private async closeCopyDialog() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isCopyDialogOpen: false,
    });
  }

  private async openCopyDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isCopyDialogOpen: true,
    });
  }
}

export default (props: IResourceProps) => h(Resource, props);
