import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import * as http from 'core/http';

export interface IGetSignedUrlResponse {
  message: 'success';
  url: string;
  name: string;
  type: string;
  contentType: string;
}

export interface IUploadResult {
  promise: Promise<{
    name: string;
    type: string;
    url: string;
  }>;
  progress$: Observable<ProgressEvent>;
}
export function upload(uploadUrl: string, data: Record<string, any>, file: File): IUploadResult {
  // create a new stream of numbers that represent
  // upload progress
  const uploadProgress$ = new Subject<ProgressEvent>();
  const promise = http
    .jsonRequest<IGetSignedUrlResponse>(uploadUrl, {
      // make the first request to get signed upload URL from acadly
      // api server
      method: 'POST',
      data,
    })
    .then((response) => {
      // upload url received from acadly api server; begin
      // uploading file to the url
      const { promise, progress$ } = http.upload(response.data.url, {
        method: 'PUT',
        headers: {
          ContentType: response.data.contentType,
        },
        data: file,
      });

      // on each progress event, from upload progress stream,
      // send a new value to the resulting stream that indicates
      // the fraction of upload complete.
      progress$.subscribe((e) => uploadProgress$.next(e));

      // when upload promise resolves, return file name and type
      return promise
        .then((result) => {
          return result;
        })
        .then(() => ({
          url: response.data.url,
          name: response.data.name,
          type: response.data.type,
        }));
    })
    .catch((e) => {
      // cancel upload progress stream on error
      uploadProgress$.error(e);
      throw e;
    })
    .then((data) => {
      // when the entire upload completes, close the uploadProgress$ stream
      uploadProgress$.complete();
      return data;
    });
  return {
    progress$: uploadProgress$,
    promise: promise,
  };
}
