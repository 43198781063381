import { CSS, h, View } from 'core';

import { BasicButton, IBasicButtonProps, IBasicButtonState } from 'acadly/common/BasicButton';
import { getHTMLTagSelector } from 'acadly/utils';

export interface IDivButtonProps extends IBasicButtonProps {
  children: View[];
  key?: string;
  disabledStyle?: CSS;
  tabIndex?: number;
  role?: string;
}
export class DivButton extends BasicButton<IDivButtonProps, IBasicButtonState> {
  public render() {
    const styles = this.isDisabled()
      ? {
          ...this.getProps().style,
          ...this.getProps().disabledStyle,
        }
      : this.getProps().style;

    return h(
      getHTMLTagSelector(
        'div',
        [
          'div-button',
          this.isDisabled() ? 'disabled' : 'ripple',
          ...(this.getProps().classNames || []),
        ],
        this.getProps().id
      ),
      {
        role: this.getProps().role !== undefined ? this.getProps().role : undefined,
        tabIndex: this.getProps().tabIndex !== undefined ? this.getProps().tabIndex : undefined,
        'aria-label': this.getProps().ariaLabel,
        key: this.getProps().key,
        onclick: this.clickHandler(),
        style: styles,
      },
      this.getProps().children
    );
  }
}

export default (props: IDivButtonProps) => h(DivButton, props);
