import { getStore } from '../store';
class AppService {
  public getCurrentUserId() {
    return getStore().getState().getIn.session!.userId;
  }

  public getCurrentUser() {
    const session = getStore().getState().getIn.session;
    if (!session) return null;
    const { userId, name, email, avatar } = session;
    return { userId, name, email, avatar };
  }

  public getUniversity() {
    return getStore().getState().getIn.session!.university;
  }

  public getUniversitySlug() {
    const session = getStore().getState().getIn.session;
    const univSlug = session && session.university.slug;
    return univSlug || 'noSlug';
  }
}

export default new AppService();
