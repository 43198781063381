import { jsonRequest } from 'core/http';

import { api as urls } from 'acadly/api';
import { IAccObject, ITipItemMap, ITipObject } from 'acadly/app/actions';
import { CreateCoursePermission } from 'acadly/utils/constants';

export interface IFormulaAddRequest {
  display: 'inline' | 'block';
  format: 'am' | 'tex' | 'mml';
  math: string;
}

export interface IFormulaAddResponse {
  ach: number;
  acw: number;
  url: string;
  _id: string;
}

export const formulaAdd = (data: IFormulaAddRequest) =>
  jsonRequest<IFormulaAddResponse>(urls().addMathExp, {
    method: 'POST',
    data,
  });

export interface INotificationPreference {
  cloud: 0 | 1;
  mail: 0 | 1;
}
export type INotificationPreferenceName =
  | 'newActivity'
  | 'newAnnouncement'
  | 'newAssignment'
  | 'newQuery'
  | 'queryResponse'
  | 'scheduleUpdate';

export type INotificationPreferences = {
  [k in INotificationPreferenceName]: INotificationPreference;
};
export interface INotificationPreferencesFetchResponse {
  notifPref: INotificationPreferences;
}
export const notificationPreferencesFetch = () =>
  jsonRequest<INotificationPreferencesFetchResponse>(urls().getNotificationPreferences, {
    method: 'GET',
  });

export const notificationPreferencesSave = (data: INotificationPreferences) =>
  jsonRequest(urls().setNotificationPreferences, {
    method: 'PUT',
    data,
  });

export type ITipsUpdateRequest = {
  turnOff: 0 | 1;
  agent: 'web';
  status: ITipItemMap;
};

export type IAccUpdateRequest = {
  turnOff: 0 | 1;
  agent: 'web';
};

export type IInitializeResponse = {
  needConsent: 0 | 1;
  marketing: 0 | 1;
  message: string;
  tips: ITipObject;
  acc: IAccObject;
  sortBy: StudentSortBy;
  showProPromo: 0 | 1;
  demoCourseCreated: 0 | 1;
  /** empty when case no feedback form is available */
  feedbackFormId: string;
  feedbackFormDetails: {
    title: string;
    text: string;
  };
  name: string;
  canCreateCourses: CreateCoursePermission;
  avatar: string;
  socket: {
    key: string;
    cluster: string;
  };
  allCourses: {
    courseCode: string;
    courseId: string;
    role: string;
    status: 'active' | 'invited';
    univSlug: string;
    universityId: string;
  }[];
};
export interface IMarketing {
  marketing: 0 | 1;
}

export const initialize = () =>
  jsonRequest<IInitializeResponse>(urls().initialize, {
    method: 'GET',
  });

interface IGetFeedbackFormResponse {
  message: 'success';
  questions: IFeedbackQuestion[];
}

export const getFeebackForm = (feedbackFormId: string) => {
  return jsonRequest<IGetFeedbackFormResponse>(urls().getFeedbackForm(feedbackFormId), {
    method: 'GET',
  });
};

interface IDenyFeedbackFormResponse {
  message: 'success';
}

interface IDenyFeedbackFormPayload {
  denyType: 'delay' | 'never';
  feedbackFormId: string;
}

export const denyFeedbackForm = (data: IDenyFeedbackFormPayload) => {
  return jsonRequest<IDenyFeedbackFormResponse>(urls().denyFeedbackForm, {
    method: 'POST',
    data,
  });
};

interface ISubmitFeedbackFormPayload {
  feedbackFormId: string;
  responses: IFeedbackQuestionResponse[];
}

interface ISubmitFeedbackFormResponse {
  message: 'success';
}

export const submitFeedbackForm = (data: ISubmitFeedbackFormPayload) => {
  return jsonRequest<ISubmitFeedbackFormResponse>(urls().submitFeedbackForm, {
    method: 'POST',
    data,
  });
};

export const promoSeen = (feature: 'pro') => {
  return jsonRequest<{ message: string }>(urls().promoSeen, {
    method: 'PUT',
    data: { promoSeen: feature },
  });
};

interface ISetDefaultSortPrefResponse {
  message: string;
  sortBy: StudentSortBy;
}
export const saveDefaultSortPref = (sortBy: StudentSortBy) => {
  return jsonRequest<ISetDefaultSortPrefResponse>(urls().sortPref, {
    method: 'PUT',
    data: { sortBy },
  });
};

export const consent = (data: IMarketing) =>
  jsonRequest(urls().consent, {
    method: 'POST',
    data,
  });

export const updateTips = (data: ITipsUpdateRequest) =>
  jsonRequest(urls().updateTips, {
    method: 'POST',
    data,
  });

export const updateAcc = (data: IAccUpdateRequest) =>
  jsonRequest(urls().updateAcc, {
    method: 'POST',
    data,
  });

export interface INewFeatureSummary {
  showTo: 'admin' | 'instructors' | 'ta' | 'team' | 'student' | 'members';
  title: string;
  message: string;
  releasedOn: UnixTimestamp;
  viewMoreLabel: string;
  seen: 0 | 1;
  blog: 0 | 1;
  redirectUrl: string;
}

export type IGetNewFeatureURLResponse =
  | {
      message: string;
      allFeatures: 0;
      redirectUrl: string;
    }
  | {
      message: string;
      allFeatures: 1;
      featureList: ObjectMap<INewFeatureSummary>;
    };

export const newFeatures = (key?: string) => {
  return jsonRequest<IGetNewFeatureURLResponse>(urls().newFeatures, {
    method: 'GET',
    data: key ? { featureSeen: key } : undefined,
  });
};

interface INewFeaturesLastAccesedResponse {
  message: string;
  newFeaturesSeenAt: UnixTimestamp;
}
export const updateNewFeaturesLastAccesed = () => {
  return jsonRequest<INewFeaturesLastAccesedResponse>(urls().newFeaturesLastAccesed, {
    method: 'PUT',
  });
};

interface IAuthorizeZoomInstallResponse {
  message: 'success';
  isAllowed: 0 | 1;
  notAllowedMessage: string; // non zero length only if isAllowed === 0
  isAuthorized: 0 | 1;
  authorizationUrl: string; // non zero length only if isAllowed === 1 && isAuthorized === 0
  revokeUrl: string; // non zero length only if isAllowed === 1 && isAuthorized === 1
  hasProCourses: 0 | 1;
}

export const authorizeZoomInstall = () => {
  return jsonRequest<IAuthorizeZoomInstallResponse>(urls().authorizeZoomInstall, {
    method: 'GET',
  });
};

interface IRaiseHandResponse {
  message: 'success';
  raisedAt: UnixTimestamp;
  queuePosition: number;
}

export const raiseHand = (classId: string) => {
  return jsonRequest<IRaiseHandResponse>(urls().raiseHand, {
    method: 'POST',
    data: { classId },
  });
};

interface ILowerHandResponse {
  message: string;
}

export const lowerHand = (classId: string) => {
  return jsonRequest<ILowerHandResponse>(urls().lowerHand, {
    method: 'POST',
    data: { classId },
  });
};

interface IAddressHandResponse {
  message: 'success';
  isErrored: 0 | 1;
  errorMessage: string; // non-zero length only if isErrored === 1
}

export const addressHand = (classId: string, userId: string) => {
  return jsonRequest<IAddressHandResponse>(urls().addressHand, {
    method: 'POST',
    data: { classId, userId },
  });
};

export interface IResolveHandResponse {
  message: 'success';
  isErrored: 0 | 1;
  errorMessage: string; // non-zero length only if isErrored === 1
  nextUser?: {
    // key available only if request sent had "next" as action
    role: 'student';
    zoomUserId: ZoomId;
    userId: string;
    name: string;
    avatar: string;
  };
}

export const resolveHand = (classId: string, userId: string, action: 'resolve' | 'next') => {
  return jsonRequest<IResolveHandResponse>(urls().resolveHand, {
    method: 'POST',
    data: { classId, userId, action },
  });
};

export interface IGetCourseSlotsAndPricesResponse {
  slots: {
    basic: number;
    pro: number;
  };
  costs: {
    perStudent: {
      /** 100, 200, 300, divide by 100 to get cost in dollars */
      basic: number;
      /** 100, 200, 300, divide by 100 to get cost in dollars */
      pro: number;
    };
  };
  freeMessage: {
    /** empty in case cost is not 0 */
    basic: string;
    /** empty in case cost is not 0 */
    pro: string;
  };
}

export const getCourseSlotsAndPrices = () => {
  return jsonRequest<IGetCourseSlotsAndPricesResponse>(urls().getProStatus, {
    method: 'GET',
  });
};

export const createProCourseDemo = (courseTimezone: string) => {
  return jsonRequest<void>(urls().createProCourseDemo, {
    method: 'POST',
    data: { courseTimezone },
  });
};

export const createBasicCourseDemo = (courseTimezone: string) => {
  return jsonRequest<void>(urls().createBasicCourseDemo, {
    method: 'POST',
    data: { courseTimezone },
  });
};

export interface IGetProCourseCostPayload {
  /**
   * 0-10 range
   */
  slots: number;
  discount: {
    available: 0 | 1;
    type: 'credit' | 'code' | '';
    code: string;
  };
}

export interface IGetProCourseCostResponse {
  /**
   * 0-10 range
   */
  slots: number;
  undiscountedCost: number;
  discount: {
    hasError: 0 | 1;
    error: string;
    amount: number;
  };
  toPay: number;
  currency: 'usd';
}

export const getProCourseCost = (data: IGetProCourseCostPayload) => {
  return jsonRequest<IGetProCourseCostResponse>(urls().getProCourseCost, {
    method: 'PUT',
    data: data,
  });
};

interface IGetPurchaseIntentPayload {
  purchaseType: 'course';
  customer: {
    name: string;
    address: {
      line1: string;
      city: string;
      state: string;
      zip: string;
      country: string;
    };
  };
}

interface IGetPurchaseIntentResponse {
  message: 'success';
  courseId: string;
  undiscountedCost: number;
  discount: {
    hasError: 0 | 1;
    error: string;
    amount: number;
  };
  /** 300, 400 etc */
  toPay: number;
  currency: 'usd';
  clientKey: string;
  purchaseId: string;
}

export const getPurchaseIntent = (data: IGetPurchaseIntentPayload) => {
  return jsonRequest<IGetPurchaseIntentResponse>(urls().getProCoursePurchaseIntent, {
    method: 'POST',
    data: data,
  });
};

interface IUpdateProPurchaseStatusPayload {
  status: 'success' | 'failed';
  purchaseId: string;
  errorMessage?: string;
}

export interface IUpdateProPurchaseStatus {
  message: 'success';
  courseId: string;
}

export const updateProPurchaseStatus = (data: IUpdateProPurchaseStatusPayload) => {
  return jsonRequest<IUpdateProPurchaseStatus>(urls().updateProPurchaseStatus, {
    method: 'POST',
    data,
  });
};

interface IGetUSDToINRConversionRateResponse {
  conversionRate: number;
}

export const getUSDToINRConversionRate = () => {
  return jsonRequest<IGetUSDToINRConversionRateResponse>(urls().getUSDToINRConversionRate, {
    method: 'GET',
  });
};

export interface IMakeFreePurchaseResponse {
  message: string;
  availableSlots: number;
  creditsAvailable: number;
}

export const makeFreePurchase = (slots: number) => {
  return jsonRequest<IMakeFreePurchaseResponse>(urls().makeFreePurchase, {
    method: 'POST',
    data: { slots },
  });
};

interface ICancelFacultyAccountVerification {
  message: 'success';
  canCreateCourse: 0;
  courses: [];
}

export const cancelFacultyAccountVerification = () => {
  return jsonRequest<ICancelFacultyAccountVerification>(urls().cancelFacultyAccountVerification, {
    method: 'PUT',
  });
};
