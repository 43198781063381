import { ComponentClass, mount } from 'core';
/**
 * Decorator that modifies a given component class so that it renders to
 * the given element instead of the usual parent node.
 * This is useful for dialogs where nested dialogs should be simply appended
 * to a single div outside the rest of the app so that absolutely positioned
 * divs inside dialogs don't take the parent's size. Essentially, this has an
 * effect of making the component a direct child of a fixed div. But it still
 * behaves as if it was placed where it was called so component lifecycle methods
 * (componentWillMont, etc) work properly.
 * Obviously, this is only practical for elements whose position doesn't depend
 * on their parent's position. Usually fixed position elements like dialogs
 * and alerts are the primary use case of this.
 *
 * In this app, "acadly/common/Alert" and "acadly/common/Dialog" have this
 * decorator currently. You don't have to understand the inner working of
 * this decorator to work with them. They just work as expected.
 *
 * It takes a DOM node, a Component class and returns a new class that inherits
 * from it by overriding it's render method to return a null node (blocking
 * its rendering in the main app). Before returning, render mounts the result
 * of the base class's render method on a new child of the portal DOM node.
 *
 * It also overrides the component unmount hook (componentWillUnmount) so that
 * it cleans up the portal mounted node when the actual component dismounts
 * from the main app.
 *
 * @example
 *
 * class App extends IComponent<any, any> {
 *   render() {
 *     if (this.getState().dialogOpen) {
 *       return h(Dialog, {
 *         title: "Outer",
 *         children: [
 *           h(Dialog, {
 *             title: "Inner",
 *             children: [
 *               "nested dialog"
 *             ]
 *           })
 *         ]
 *       });
 *     } else {
 *       return h("div", "No dialog");
 *     }
 *   }
 * }
 *
 * @portal(document.getElementById("dialog-container"))
 * class Dialog extends IComponent<any, any> {
 *   render() {
 *     return h("div.dialog", [
 *       this.getProps().title,
 *       ...this.getProps().children
 *     ]);
 *   }
 * }
 *
 * mount(document.getElementById("app"), App)
 *
 * This will be rendered as
 * <body>
 *   <div id="app">
 *   </div>
 *   <div id="dialog-container">
 *     <div>
 *       <div class="dialog">
 *         Outer
 *       </div>
 *     </div>
 *     <div>
 *       <div class="dialog">
 *         Inner
 *       </div>
 *     </div>
 *   </div>
 * </body>
 *
 * It should be clear from the example that nested dialogs are flattened out
 * and rendered as siblings onto the "dialog-container" element whereas the
 * place at which they would normally be rendered is empty.
 */
const portal: any =
  <Props, State>(element: HTMLElement | null) =>
  (compClass: ComponentClass<Props, State>) => {
    return class extends compClass {
      public componentWillMount() {
        const parent = element!;
        const toElement = document.createElement('div');
        parent.appendChild(toElement);
        this.setState({
          ___container: toElement,
        } as any).then(() => {
          super.componentWillMount();
        });
      }

      public componentWillUnmount() {
        super.componentWillUnmount();
        const container = (this.getState() as any).___container;
        if (container && element) {
          // mount null clears the rendered element
          // simply removing it the element from the rendered
          // element won't be enough because nested dialogs
          // won't get removed. Moreover, they'll be left
          // without a parent component so it can't be removed.
          // calling mount instead goes throught the
          // component lifecycle methods of the nested child
          // components so they are removed as well.
          mount(container, null as any);

          // remove the container div after a while
          // waiting for 500 ms for rendering to complete
          setTimeout(() => (container.outerHTML = ''), 500);
        }
      }

      public render() {
        const view = super.render();
        const toElement = (this.getState() as any).___container;
        if (toElement) {
          // render to the portal
          mount(toElement, view as any);
        }

        // this will stop the element from rendering as a child
        // of it's parent.
        return null;
      }
    };
  };
export default portal;
