import { h } from 'core';
import { Route, Switch } from 'core/router';

import Referral from 'acadly/app/Referral';
import Course from 'acadly/course/Course';
import { Routes } from 'acadly/routes';

import ArchivedCoursesScreen from './ArchivedCoursesScreen';
import Home from './Home/index';
import SettingsScreen from './SettingsScreen';

export default () => {
  return h(
    'div.main',
    {
      style: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#efefef',
      },
    },
    [
      Switch(
        [
          Route(Routes.home, Home),
          Route(Routes.refer, () => h(Referral)),
          Route(Routes.course, (match) => Course(match.params.courseShortId)),
          Route(Routes.archivedCourses, ArchivedCoursesScreen),
          Route(Routes.settings, SettingsScreen),
          // Route("*", Home)
        ],
        {
          defaultCallback: () => {
            Routes.home.navigate({});
          },
        }
      ),
    ]
  );
};
