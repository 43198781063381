import { Component, CSS, HTMLAttrs } from './adapter-interface';
import {
  Component as ImplComponent,
  h,
  mount,
  redraw,
  registerErrorHandler,
  setDefaultClasses,
  View,
  VNode,
} from './inferno-adapter';
import { ref } from './ref';

abstract class Constructor<Props, State> extends Component<VNode<any, any>, Props, State> {}

const IComponent: typeof Constructor = ImplComponent as any;
type IComponent<Props, State> = Component<unknown, Props, State>;
type ComponentClass<Props, State> = {
  new (...args: any[]): Constructor<Props, State>;
};
export {
  h,
  redraw,
  mount,
  IComponent,
  VNode,
  View,
  CSS,
  setDefaultClasses,
  registerErrorHandler,
  ComponentClass,
  HTMLAttrs,
  ref,
};

import * as http from './http';
import storage from './storage';
export default {
  http,
  h,
  redraw,
  mount,
  IComponent,
  ref,
  storage,
};
