import { cookies, sessionStorage } from 'core/storage';

import { createAction, Thunk } from 'acadly/createAction';
import { fetchAvatars } from 'acadly/fetch-avatars';
import { GetInScreen } from 'acadly/getin/Flow';
import { CreateCoursePermission } from 'acadly/utils/constants';

import { logout } from './api';
import { IGetInState } from './IGetInState';

export type IGetInActionMap = {
  '@getin/CLEAR_SERVER': undefined;
  '@getin/SET_SERVER': string;
  '@getin/FETCH_AVATARS_SUCCESS': IFetchAvatarsSuccessPayload;
  '@getin/SET_AVATAR': string;
  '@getin/SET_SESSION': IGetInState['session'];
  '@getin/SET_CURRENT_SCREEN': GetInScreen;
  '@getin/ROUTE_ACCESS_WITHOUT_LOGIN': string | undefined;
  '@getin/CHANGE_COURSE_CREATION_PERMISSION': CreateCoursePermission;
};

export interface IFetchAvatarsSuccessPayload {
  links: ObjectMap<string>;
  avatarURL?: string;
}

export const Actions = {
  setAvatar: createAction('@getin/SET_AVATAR'),
  clearServer: createAction('@getin/CLEAR_SERVER'),
  fetchAvatarsSuccess: createAction('@getin/FETCH_AVATARS_SUCCESS'),
  fetchAvatars:
    (_ids: (string | null)[], baseURL?: string, options?: { update: boolean }): Thunk<void> =>
    async (dispatch, getState) => {
      const existingAvatars = getState().getIn.avatars;
      const ids = (
        options && options.update
          ? (_ids as string[])
          : (_ids.filter((id) => id && !existingAvatars[id]) as string[])
      ).filter((id) => id.length === 24);
      if (ids.length === 0) {
        await dispatch(
          Actions.fetchAvatarsSuccess({
            links: {},
            avatarURL: baseURL,
          })
        );
      } else {
        const response = await fetchAvatars(ids);
        await dispatch(
          Actions.fetchAvatarsSuccess({
            links: response.data.links,
            avatarURL: baseURL,
          })
        );
      }
    },

  logout:
    (skipRequest?: boolean, queryParams?: string): Thunk<void> =>
    async () => {
      cookies.remove('session');
      cookies.remove('token');
      localStorage.clear();
      sessionStorage.clear();
      if (!skipRequest) {
        await logout();
      }
      location.href = `/login${queryParams || ''}`;
    },

  setServer: createAction('@getin/SET_SERVER'),
  setSession: createAction('@getin/SET_SESSION'),
  setCurrentScreen: createAction('@getin/SET_CURRENT_SCREEN'),
  setRouteAccessed: createAction('@getin/ROUTE_ACCESS_WITHOUT_LOGIN'),
  changeCourseCreationPermission: createAction('@getin/CHANGE_COURSE_CREATION_PERMISSION'),
};
