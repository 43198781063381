import * as api from 'acadly/course/api';
import * as actions from 'acadly/course/ICourseActionMap';
import { ICourseState } from 'acadly/course/ICourseState';
import { createReducer } from 'acadly/createReducer';
import { IRootAction } from 'acadly/IRootAction';
import { IRootState } from 'acadly/IRootState';
import { range, removeIndex, replaceIndex, replaceWhere } from 'acadly/utils';
export function reducer(state: IRootState, action: IRootAction): IRootState {
  return {
    ...state,
    courses: courseSliceReducer(state.courses, action),
  };
}

export const courseSliceReducer = createReducer({
  '@course/syllabus/FETCH_SUCCESS': fetchTopicsSuccess,
  '@course/syllabus/EDIT_TOPIC_SUCCESS': editTopic,
  '@course/syllabus/ADD_TOPIC_SUCCESS': addTopic,
  '@course/syllabus/DELETE_TOPIC_SUCCESS': deleteTopic,
  '@course/syllabus/UPLOAD_SUCCESS': fileUploadSuccess,
});

export function deleteTopic(state: ICourseState, topicId: string) {
  const indices = findIndexOfTopic(state.topics, topicId);
  if (indices) {
    const { parentIndex, childIndex } = indices;
    if (childIndex === 0) {
      return {
        ...state,
        topics: removeIndex(state.topics, parentIndex),
      };
    } else {
      return {
        ...state,
        topics: replaceIndex(
          state.topics,
          (topic) => ({
            ...topic,
            topics: removeIndex(topic.topics, childIndex),
          }),
          parentIndex
        ),
      };
    }
  } else {
    return state;
  }
}

export function fileUploadSuccess(
  state: ICourseState,
  payload: actions.ISyllabusUploadSuccessPayload
) {
  return {
    ...state,
    syllabusFile: payload,
  };
}

export function fetchTopicsSuccess(
  state: ICourseState,
  payload: actions.ISyllabusFetchSuccessPayload
) {
  return {
    ...state,
    topics: payload.topics,
    syllabusFile: payload.syllabus.name ? (payload.syllabus as IAttachment) : undefined,
  };
}

export function addTopic(state: ICourseState, childTopic: ITopicChild): ICourseState {
  if (childTopic.parentId === 'self') {
    return {
      ...state,
      topics: [
        ...state.topics,
        {
          _id: childTopic.topicId,
          topics: [
            {
              ...childTopic,
              parentId: childTopic.topicId,
            },
          ],
        },
      ],
    };
  } else {
    return {
      ...state,
      topics: replaceWhere(
        state.topics,
        (parent) => ({
          ...parent,
          topics: [...parent.topics, childTopic],
        }),
        (parent) => parent._id === childTopic.parentId
      ),
    };
  }
}

export function findIndexOfTopic(topics: ITopic[], topicId: string) {
  for (const parentIndex of range(topics.length)) {
    const parent = topics[parentIndex];
    for (const childIndex of range(parent.topics.length)) {
      const child = parent.topics[childIndex];
      if (child.topicId === topicId) {
        return { parentIndex, childIndex };
      }
    }
  }
  return null;
}

export function editTopic(state: ICourseState, updates: api.IEditTopicRequest): ICourseState {
  const indices = findIndexOfTopic(state.topics, updates.topicId);
  if (!indices) return state;
  const { parentIndex, childIndex } = indices;
  const topics = state.topics;
  const parent = topics[parentIndex];
  const children = parent.topics;
  const child = children[childIndex];
  return {
    ...state,
    topics: replaceIndex(
      topics,
      {
        ...parent,
        topics: replaceIndex(
          children,
          {
            ...child,
            details: {
              ...child.details,
              title: updates.title,
              books: updates.books,
              links: updates.links,
            },
          },
          childIndex
        ),
      },
      parentIndex
    ),
  };
}
