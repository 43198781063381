import { CSS, h, IComponent, View } from 'core';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const TinyDatePicker = require('tiny-date-picker');
import { Icon } from 'acadly/common/Icon';
import icons from 'acadly/icons';
import { getStore } from 'acadly/store';
import { colors, ml, style } from 'acadly/styles';

import * as datetime from '../datetime';

interface IDatePickerProps {
  value?: Date;
  minDate?: Date;
  focusedDate?: Date;
  maxDate?: Date;
  style?: CSS;
  onclick?: () => any;
  onchange?: (date: Date) => void;
  dateFormat?: string;
  render?: (date?: Date) => View;
  icon?: View;
}

interface IDatePickerState {
  isOpen: boolean;
  instance?: {
    open: () => void;
    close: () => void;
  };
}
export default (props: IDatePickerProps) => h(DatePicker, props as any);
export class DatePicker extends IComponent<IDatePickerProps, IDatePickerState> {
  public componentWillMount() {
    this.setState({
      isOpen: false,
    });
  }
  // private makeAccessible() {
  //     const cal = document.querySelector(".dp-modal");
  //     cal && cal.addEventListener("click", ( e: MouseEvent) => {
  //     });
  // }

  public render() {
    const isAccessible = getStore().getState().app.acc.web.turnOff;
    const calendarIconStyle = {
      marginLeft: '1rem',
      fontSize: '1.3em',
      color: colors.blue,
    };
    const props = this.getProps();
    return h(
      'div.date-picker',
      {
        tabIndex: isAccessible ? 0 : undefined,
        role: 'button',
        style: {
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...props.style,
        },
      },
      [
        h('div', {
          style: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            top: 0,
            left: 0,
            cursor: 'pointer',
          },

          onclick: () => {
            const instance = this.getState().instance;
            if (!instance) return;
            if (this.getState().isOpen) {
              instance.close();
            } else {
              props.onclick ? props.onclick() : null;
              instance.open();
              // this.makeAccessible();
            }
          },
          ref: (elem: any) => {
            if (this.getState().instance || !elem) return;
            const dp = TinyDatePicker(elem, {
              min: props.minDate ? datetime.startOfDay(props.minDate) : undefined,
              max: props.maxDate,
              hilightedDate: props.focusedDate ? props.focusedDate : props.value || new Date(),
              mode: 'dp-modal',
              lang: {
                close: 'CANCEL',
              },
            });
            dp.setState({
              selectedDate: props.focusedDate ? props.focusedDate : props.value || new Date(),
            });
            if (props.onchange) {
              dp.on('select', (_: Event, dp: any) => {
                try {
                  props.onchange!(dp.state.selectedDate);
                } catch (_) {
                  // swallow error
                }
                dp.close();
              });
              // dp.on("open", () => {
              //     const dpmodal = document.querySelector(".dp-modal") as HTMLElement;
              //     dpmodal && u.unsetTabIndices(dpmodal);
              //     document.querySelectorAll(".dp a[tabindex]").forEach (a => {
              //         a.setAttribute("tabindex", "0");
              //     });
              //     (document.querySelector(".dp .dp-next") as HTMLElement)
              //     const cal = document.querySelector(".dp-modal .dp-cal");
              //     const calheader = document.querySelector(".dp-modal .dp-cal-header");
              //     cal && cal.addEventListener("click", () => {
              //         document.querySelectorAll(".dp a[tabindex]").forEach (a => {
              //             a.setAttribute("tabindex", "0");
              //         });
              //     });
              //     cal && cal.addEventListener("blur", (e: any) => {
              //         e.preventDefault();
              //     });
              //     cal && cal.addEventListener("keydown", (e: KeyboardEvent) => {
              //         if (e.keyCode === 13) {
              //             e.stopPropagation();
              //             e.preventDefault();
              //         }
              //     });
              //     calheader &&
              //     calheader.addEventListener("keydown", () => {
              //         document.querySelectorAll(".dp a[tabindex]").forEach (a => {
              //             a.setAttribute("tabindex", "0");
              //         });
              //     });
              // });
            }
            setTimeout(() =>
              this.setState({
                instance: dp,
              })
            );
          },
        }),
        props.render
          ? props.render(props.value)
          : props.value
          ? datetime.format(props.value, props.dateFormat || 'MMM D, YYYY')
          : h('div', style(['flex', 'alignCenter']), [
              'Tap to select',
              props.icon ? null : Icon(icons.calendar, { style: calendarIconStyle }),
            ]),
        props.icon ? h('span', style([ml('0.5rem')]), [props.icon]) : null,
      ]
    );
  }
}
