import { updateNumActivities } from 'acadly/course/functions';
import { createReducer } from 'acadly/createReducer';
import { IRootState } from 'acadly/IRootState';
import * as u from 'acadly/utils';

import * as actions from './actions';

export const reducer = createReducer({
  '@announcement/PUBLISH_SUCCESS': publishSuccess,
  '@announcement/analytics/SET_SUCCESS': analyticsSetSuccess,
  '@announcement/pusher/ADD': pusherAdd,
});

export function analyticsSetSuccess(
  state: IRootState,
  payload: actions.IAnalyticsSetSuccessPayload
): IRootState {
  if (!payload.request.firstAccess) {
    return state;
  }
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  return {
    ...state,
    courses: {
      ...state.courses,
      timeline: {
        ...timeline,
        items: u.replaceWhere(
          timeline.items,
          (a: IAnnouncement) => ({
            ...a,
            userData: {
              ...a.userData,
              firstAccessedOn: payload.timestamp,
              timesFetched: 1,
            },
          }),
          (a) => a._id === payload.request.announcementId
        ),
      },
    },
  };
}

export function pusherAdd(state: IRootState, payload: actions.IPusherAddPayload): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const alreadyExists = timeline.items.find((a) => a._id === payload.announcementId);
  if (alreadyExists) return state;
  const splitIndex = u.findIndex(
    timeline.items,
    (i) => i.details.dueDateTime > payload.details.dueDateTime
  )!;
  const isCreator = state.getIn.session!.userId === payload.details.createdBy.userId;
  const announcement: IAnnouncement = {
    _id: payload.announcementId,
    nodeType: 'announcement',
    identifiers: {
      universityId: state.getIn.session!.university.slug,
      courseId: state.courses.currentCourseId!,
    },
    details: payload.details,
    removed: 0,
    userData: {
      firstAccessedOn: isCreator ? payload.details.createdOn : undefined,
      timesFetched: isCreator ? 1 : 0,
    },
  };
  return {
    ...state,
    courses: {
      ...state.courses,
      timeline: {
        ...timeline,
        items: [
          ...timeline.items.slice(0, splitIndex),
          announcement,
          ...timeline.items.slice(splitIndex),
        ],
      },
    },
  };
}

export function publishSuccess(
  state: IRootState,
  payload: actions.IPublishAnnouncementSuccessPayload
): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;

  // skip if assignment is already there (in case pusher message is received first)
  if (timeline.items.find((a) => a._id === payload.response._id)) return state;
  if (!state.courses.currentCourseId) return state;
  const course = state.courses.courses[state.courses.currentCourseId];
  const response = payload.response;
  const request = payload.request;
  const newAnnouncement: IAnnouncement = {
    nodeType: 'announcement',
    _id: response._id,
    identifiers: {
      universityId: '',
      courseId: course._id,
    },
    details: {
      attachments: request.attachments,
      createdOn: response.createdOn,
      createdBy: response.createdBy,
      dueDateTime: response.dueDateTime,
      description: request.description,
      title: request.title,
      type: 'manual',
    },
    userData: {
      firstAccessedOn: response.createdOn,
      timesFetched: 1,
    },
    removed: 0,
  };
  const updatedCoursesSlice = {
    ...state.courses,
    courses: {
      ...state.courses.courses,
      [state.courses.currentCourseId]: {
        ...updateNumActivities(course, 'announcements', 'numPublished', 1),
        ...updateNumActivities(course, 'announcements', 'numTotal', 1),
      },
    },
    timeline: {
      ...timeline,
      items: [
        ...timeline.items.filter((item) => item.details.dueDateTime <= response.dueDateTime),
        newAnnouncement,
        ...timeline.items.filter((item) => item.details.dueDateTime > response.dueDateTime),
      ],
    },
  };
  return {
    ...state,
    courses: updatedCoursesSlice,
  };
}
