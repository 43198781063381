import { getCourseRole } from 'acadly/course/functions';
import { IRootState } from 'acadly/IRootState';

import { IQuizState } from './IQuizState';
/**
 * Function that takes an optional IResponseQuizUserData,
 * a class Id and returns an IQuiz by attaching user data and
 * identifiers, and converting details fields that are optional
 * in the response to default values
 */
export const responseQuizToQuiz = (
  userData: IResponseQuizUserData | undefined,
  classId: string,
  q: IResponseQuiz | IResponseQuizWithClassId
): IQuiz => ({
  identifiers: {
    classId: classId,
  },
  ...q,
  details: {
    ...q.details,
    attachments: q.details.attachments || [],
    title: q.details.title || '',
  },
  userData: userData
    ? {
        ...userData,
        numCommentsSeen: userData.numCommentsSeen || 0,
      }
    : undefined,
});

export function isQuizSeen(quiz: IQuiz): boolean {
  return !!quiz.userData;
}

export function isQuizSubmitted(quiz: IQuiz): boolean {
  if (!quiz.userData) return false;
  return quiz.userData.status === 'submitted' || quiz.userData.status === 'late';
}

export function updateQuiz(
  quizzesSlice: IQuizState,
  quizId: string,
  f: (q: IQuiz) => IQuiz
): IQuizState {
  const quiz = quizzesSlice.byId[quizId];
  if (!quiz) return quizzesSlice;
  return {
    ...quizzesSlice,
    byId: {
      ...quizzesSlice.byId,
      [quizId]: f(quiz),
    },
  };
}

export function canSeeQuizComments(
  state: IRootState,
  quizId: string,
  timestamp: UnixTimestamp
): boolean {
  const quiz = state.quizzes.byId[quizId];
  const role = getCourseRole(state);
  if (role === 'student') {
    if (quiz && quiz.details.allowLate) {
      return isQuizSubmitted(quiz);
    } else {
      return (!!quiz && quiz.details.dueDateTime < timestamp) || (!!quiz && isQuizSubmitted(quiz));
    }
  } else {
    return true;
  }
}
