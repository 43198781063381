import { h, HTMLAttrs, IComponent, View } from 'core';

import * as FullScreenIcon from 'assets/fullscreen.svg';
import * as ExitFullScreenIcon from 'assets/fullscreen_exit.svg';
import * as MinimizeIcon from 'assets/minimize.svg';
import * as MoveIcon from 'assets/move.svg';

import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import portal from 'acadly/common/Portal';
import SvgIcon from 'acadly/common/SvgIcon';
import { getMousePosition, getTouchPosition } from 'acadly/utils/drag';

const MIN_HEIGHT = 100;
const MIN_WIDTH = 200;

const DEFAULT_WIDTH = 360;
const DEFAULT_ASPECT_RATIO = 49 / 40;
const DEFAULT_FOOTER_HEIGHT = 48;

export type PipContainerMode = 'pip' | 'embedded' | 'full_screen';

export enum PipControl {
  /**
   * Drag handle to move pip-container in pip mode. This control is
   * hidden in mobile devices.
   */
  MOVE = 'move',
  /**
   * Resets pip-container to small size and puts container
   * in bottom-left corner. This control is visible in pip mode.
   */
  MINIMIZE = 'minimize',
  /**
   * Toggles fullscreen mode of pip-container.
   */
  TOGGLE_FULLSCREEN = 'toggle_fullscreen',
}

interface PipControlProps {
  onClick?: (e: MouseEvent) => any;
}

interface PresetPipControl {
  type: 'preset';
  name: PipControl;
  controlProps?: PipControlProps;
}

interface CustomPipControl {
  type: 'custom';
  view: View;
  controlProps?: PipControlProps;
}

type PipControlOrder = PresetPipControl | CustomPipControl;

interface Position {
  x: number;
  y: number;
}

interface PaintFrameParams {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  lockAspectRatio: boolean;
  containerRef: HTMLDivElement;
  initialContainerBounds: ClientRect | DOMRect;
}

interface PaintFrameResult {
  newHeight: number;
  newWidth: number;
  newTop: number;
  newLeft: number;
}

type PaintFrameCallback = (params: PaintFrameParams) => PaintFrameResult;

interface IPipContainerProps extends HTMLAttrs {
  containerId: string;
  body: View[];
  disableResize?: boolean;
  footer?: View;
  /**
   * pip-controls to be shown on top-left corner.
   */
  leftControls?: PipControlOrder[];
  rightControls?: PipControlOrder[];
  footerHeight?: number;
  /** if set then pip container will try to open in embedded mode */
  initialEmbedModeTargetSelector?: string;
  /**
   * Locks aspect-ratio while resizing using mouse-drag
   * i.e. if height is changed then width will adjust
   * to maintain aspect-ratio and vice-versa.
   *
   * **NOTE:** You can still change aspect ratio using `PipContainer.setAspectRatio()`
   */
  lockAspectRatio?: boolean;
  initialAspectRatio?: number;
  getInstance?: (ref: PipContainer) => void;
  onModeChange?: (mode: PipContainerMode) => void;
}

interface IPipContainerState {
  mode: 'pip' | 'embedded';
  isFullScreenNotSupportedAlertOpen: boolean;
}

@portal(document.getElementById('pip-wrapper'))
export class PipContainer extends IComponent<IPipContainerProps, IPipContainerState> {
  private bodyRef?: HTMLDivElement;
  private containerRef?: HTMLDivElement;

  private width = this.getPipModeWidth();

  private _pipAspectRatio = DEFAULT_ASPECT_RATIO;
  private _embeddedAspectRatio = DEFAULT_ASPECT_RATIO;

  private get aspectRatio() {
    const { mode } = this.getState() || { mode: 'embedded' };
    return mode === 'embedded' ? this._embeddedAspectRatio : this._pipAspectRatio;
  }

  private set aspectRatio(aspectRatio: number) {
    const { mode } = this.getState() || { mode: 'embedded' };
    if (mode === 'embedded') {
      this._embeddedAspectRatio = aspectRatio;
    } else {
      this._pipAspectRatio = aspectRatio;
    }
  }

  private embedTarget?: HTMLElement;
  private hasScrollListener = false;

  private static instanceMap: Record<string, PipContainer | undefined> = {};

  public static getInstance(id: string) {
    return PipContainer.instanceMap[id];
  }

  private getWindowSize() {
    const width =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return { width, height };
  }

  public componentWillMount() {
    const { containerId, getInstance, initialEmbedModeTargetSelector, initialAspectRatio } =
      this.getProps();
    const initialState: IPipContainerState = {
      mode: initialEmbedModeTargetSelector ? 'embedded' : 'pip',
      isFullScreenNotSupportedAlertOpen: false,
    };

    this.setState(initialState);

    if (initialAspectRatio) {
      this._pipAspectRatio = initialAspectRatio;
      this._embeddedAspectRatio = initialAspectRatio;
    } else {
      const savedAspectRatio = window.localStorage.getItem(`${containerId}:pip_aspect_ratio`);
      this._pipAspectRatio = parseFloat(savedAspectRatio) || DEFAULT_ASPECT_RATIO;
    }

    if (initialEmbedModeTargetSelector) {
      this.tryEmbeddedMode(initialEmbedModeTargetSelector);
    } else {
      this.setPipMode();
    }

    window.addEventListener('resize', this.resize, false);

    document.addEventListener('fullscreenchange', this.onToggleFullScreen, false);

    window.addEventListener('orientationchange', this.resize, false);

    if (getInstance) {
      getInstance(this);
    }

    PipContainer.instanceMap[containerId] = this;
  }

  public componentWillUnmount() {
    this.resetEmbedTargetHeight();

    this.bodyRef = undefined;
    this.containerRef = undefined;

    window.removeEventListener('resize', this.resize);
    window.removeEventListener('fullscreenchange', this.onToggleFullScreen);
    window.removeEventListener('orientationchange', this.resize);

    const { containerId } = this.getProps();
    delete PipContainer.instanceMap[containerId];
  }

  private resize = () => {
    const { lockAspectRatio } = this.getProps();
    const { mode } = this.getState();
    if (mode === 'pip') {
      this.setDimentionsByAspectRatio(this.getPipModeWidth());
      const bounds = this.getBounds();
      this.setCoordinates(0, bounds.maxY);
    } else if (this.embedTarget) {
      const { top, left, width } = this.embedTarget.getBoundingClientRect();

      if (lockAspectRatio) {
        this.setDimentionsByAspectRatio(width);
      } else {
        this.setDimentionsByAspectRatio(width, DEFAULT_ASPECT_RATIO);
      }

      this.embedTarget.style.height = `${this.getHeight(width)}px`;
      this.setCoordinates(left, top);
    }
  };

  private onModeChange(mode: PipContainerMode) {
    const { onModeChange } = this.getProps();
    if (onModeChange) {
      onModeChange(mode);
    }
  }

  private onToggleFullScreen = () => {
    if (!document.fullscreenElement) {
      this.onFullScreenExit();
    }
  };

  private onFullScreenExit() {
    const { mode } = this.getState();
    if (!this.bodyRef) return;

    this.bodyRef.style.height = `100%`;
    this.onModeChange(mode);
  }

  public exitFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      this.onFullScreenExit();
    }
  }

  private fullScreenNotSupported() {
    const { isFullScreenNotSupportedAlertOpen } = this.getState();

    if (!isFullScreenNotSupportedAlertOpen) return null;

    return Alert(
      {
        open: true,
        style: { width: '35em' },
        actions: [
          FlatButton('Okay', {
            onclick: () => {
              this.setState({
                isFullScreenNotSupportedAlertOpen: false,
              });
            },
          }),
        ],
      },
      ['Fullscreen feature is either not supported or disabled in your browser.']
    );
  }

  private toggleFullScreen() {
    if (!this.containerRef || !this.bodyRef) return;

    if (!document.fullscreenEnabled) {
      this.setState({
        isFullScreenNotSupportedAlertOpen: true,
      });
      return;
    }

    if (!document.fullscreenElement) {
      this.containerRef.requestFullscreen();
      const width = this.getWindowSize().width;
      this.bodyRef.style.height = `${this.getBodyHeight(width)}px`;
      this.onModeChange('full_screen');
    } else {
      this.exitFullScreen();
    }
  }

  private scrollListener = () => {
    let ticking = false;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (!this.embedTarget) return;
        const { top, left } = this.embedTarget.getBoundingClientRect();
        this.setCoordinates(left, top);
        ticking = false;
      });

      ticking = true;
    }
  };

  private resetEmbedTargetHeight() {
    if (this.embedTarget) {
      this.embedTarget.style.height = '';
    }
  }

  public setPipMode() {
    this.setState({ mode: 'pip' });

    this.resetEmbedTargetHeight();
    this.embedTarget = undefined;

    if (this.hasScrollListener) {
      this.hasScrollListener = false;
      document.removeEventListener('scroll', this.scrollListener);
    }

    this.resize();
    this.onModeChange('pip');

    if (this.containerRef) {
      this.containerRef.classList.remove('embedded');
      this.containerRef.classList.add('pip');
    }
  }

  public tryEmbeddedMode(targetElementSelector: string) {
    const MAX_RETRIES = 50;
    let attempts = 0;
    const timer = setInterval(() => {
      const el = document.querySelector<HTMLDivElement>(targetElementSelector);

      if (el) {
        this.setEmbeddedMode(el);
        clearInterval(timer);
        return;
      }

      if (attempts > MAX_RETRIES) {
        clearInterval(timer);
        console.error(`${targetElementSelector} not found!`);
        return;
      }

      attempts++;
    }, 500);
  }

  private setEmbeddedMode(el: HTMLDivElement) {
    this.embedTarget = el;
    this.setState({ mode: 'embedded' });
    this.lastVisibleCoordinates = null;

    if (!this.hasScrollListener) {
      this.hasScrollListener = true;
      document.addEventListener('scroll', this.scrollListener, true);
    }

    this.resize();
    this.onModeChange('embedded');

    if (this.containerRef) {
      this.containerRef.classList.remove('pip');
      this.containerRef.classList.add('embedded');
    }
  }

  private lastVisibleCoordinates: null | {
    top: number;
    left: number;
  } = null;

  /**
   * Shows back the hidden pip-container
   * by moving it back to visible region.
   *
   * It is intended to use in pip-mode only.
   */
  public show() {
    const { mode } = this.getState();

    if (mode !== 'pip' || this.lastVisibleCoordinates == null) {
      return;
    }

    const { left, top } = this.lastVisibleCoordinates;
    this.setCoordinates(left, top);
    this.lastVisibleCoordinates = null;
  }

  /**
   * Hides pip-container by moving it
   * outside the visible region.
   *
   * It is intended to use in pip-mode only.
   */
  public hide() {
    const { mode } = this.getState();

    if (mode !== 'pip' || this.lastVisibleCoordinates || !this.containerRef) {
      return;
    }

    const win = this.getWindowSize();
    const { left, top } = this.containerRef.getBoundingClientRect();
    this.lastVisibleCoordinates = { left, top };
    this.setCoordinates(left, win.height * 2);
  }

  private getPipModeWidth() {
    const win = this.getWindowSize();
    const { containerId, footer, footerHeight = DEFAULT_FOOTER_HEIGHT } = this.getProps();

    let defaultWidth = parseInt(
      window.localStorage.getItem(`${containerId}:pip_default_width`) || DEFAULT_WIDTH.toString(),
      10
    );

    const height = this.getHeight(defaultWidth);

    if (height >= win.height) {
      // reverse calculate height to fit into max window height
      const maxAllowedHeight = footer ? win.height - footerHeight : win.height;
      defaultWidth = this.aspectRatio * maxAllowedHeight;
      this.width = defaultWidth;
    }

    return Math.min(defaultWidth, win.width);
  }

  private getBodyHeight(width = this.width) {
    return width / this.aspectRatio;
  }

  private getHeight(width = this.width) {
    const { footerHeight = DEFAULT_FOOTER_HEIGHT } = this.getProps();
    const bodyHeight = this.getBodyHeight(width);

    return this.getProps().footer ? bodyHeight + footerHeight : bodyHeight;
  }

  private getBounds() {
    const win = this.getWindowSize();

    return {
      minX: 0,
      midX: win.width / 2,
      maxX: win.width - this.width,
      minY: 0,
      maxY: win.height - this.getHeight(),
    };
  }

  private setDimentionsByAspectRatio(width: number, aspectRatio = this.aspectRatio) {
    this.width = width;
    this.aspectRatio = aspectRatio;

    if (!this.containerRef) return;

    this.containerRef.style.width = `${width}px`;
    this.containerRef.style.height = `${this.getHeight(width)}px`;
  }

  private setCoordinates(left: number, top: number) {
    if (!this.containerRef) return;
    this.containerRef.style.left = `${left}px`;
    this.containerRef.style.top = `${top}px`;
  }

  public setAspectRatio(ratio: number) {
    const { mode } = this.getState();
    this.setDimentionsByAspectRatio(this.width, ratio);

    if (mode === 'pip') {
      const bounds = this.getBounds();
      this.setCoordinates(bounds.minX, bounds.maxY);
    } else if (this.embedTarget) {
      this.embedTarget.style.height = `${this.getHeight()}px`;
    }
  }

  public static defaultLeftControls: PipControlOrder[] = [
    {
      type: 'preset',
      name: PipControl.MOVE,
    },
    {
      type: 'preset',
      name: PipControl.MINIMIZE,
    },
  ];

  private moveControl = () => {
    const { mode } = this.getState();

    if (mode !== 'pip' || document.fullscreenElement) return null;

    return SvgIcon({
      role: 'button',
      icon: MoveIcon,
      className: 'pip-container__button draggable',
      title: 'Move',
      onmousedown: (e) => {
        const { mode } = this.getState();
        if (mode === 'pip' && !document.fullscreenElement) {
          this.handleContainerDrag(e);
        }
      },
      ontouchstart: (e) => {
        const { mode } = this.getState();
        if (mode === 'pip' && !document.fullscreenElement) {
          this.handleContainerDrag(e);
        }
      },
    });
  };

  private minimizeControl = () => {
    const { mode } = this.getState();

    if (mode !== 'pip' || document.fullscreenElement) return null;

    return SvgIcon({
      role: 'button',
      icon: MinimizeIcon,
      className: 'pip-container__button',
      title: 'Collapse to Corner',
      onclick: () => {
        this.resetPipModeDimentions();
      },
    });
  };

  private toggleFullScreenControl = (props: PipControlProps = {}) => {
    return SvgIcon({
      role: 'button',
      className: 'pip-container__button',
      title: document.fullscreenElement ? 'Exit Full Screen' : 'Full Screen',
      ariaLabel: 'toggle full screen button',
      onclick: (e) => {
        this.toggleFullScreen();
        if (props.onClick) {
          props.onClick(e);
        }
      },
      icon: document.fullscreenElement ? ExitFullScreenIcon : FullScreenIcon,
    });
  };

  private readonly controlsMap: {
    [controlName in PipControl]: (props?: PipControlProps) => View;
  } = {
    [PipControl.MOVE]: this.moveControl,
    [PipControl.MINIMIZE]: this.minimizeControl,
    [PipControl.TOGGLE_FULLSCREEN]: this.toggleFullScreenControl,
  };

  private getLeftControls() {
    let { leftControls } = this.getProps();

    if (!leftControls) {
      leftControls = [
        {
          type: 'preset',
          name: PipControl.MOVE,
        },
      ];
    }

    return h(
      'div.pip-container__controls.pip-container__controls--left',
      leftControls.map((control) => {
        if (control.type === 'custom') return control.view;
        return this.controlsMap[control.name](control.controlProps);
      })
    );
  }

  private getRightControls() {
    let { rightControls } = this.getProps();

    if (!rightControls) {
      rightControls = [
        {
          type: 'preset',
          name: PipControl.MINIMIZE,
        },
        {
          type: 'preset',
          name: PipControl.TOGGLE_FULLSCREEN,
        },
      ];
    }

    return h(
      'div.pip-container__controls.pip-container__controls--right',
      rightControls.map((control) => {
        if (control.type === 'custom') return control.view;
        return this.controlsMap[control.name](control.controlProps);
      })
    );
  }

  public render() {
    const { mode } = this.getState();
    const { containerId, body, footer, className, style } = this.getProps();

    return h(
      `div#${containerId}.pip-container.${mode}`,
      {
        className,
        style: {
          ...style,
          height: this.getHeight(),
        },
        ref: (el) => {
          this.containerRef = el as HTMLDivElement;
        },
      },
      [
        this.getLeftControls(),
        this.getRightControls(),
        h(
          'div.pip-container__body',
          {
            ref: (el) => {
              this.bodyRef = el as HTMLDivElement;
            },
          },
          body
        ),
        footer ? h('div.pip-container__footer', [footer]) : null,
        ...this.resizeBorders(),
        this.fullScreenNotSupported(),
      ]
    );
  }

  private saveAspectRatio(ratio: number) {
    const { containerId } = this.getProps();
    window.localStorage.setItem(`${containerId}:pip_aspect_ratio`, ratio.toString());
  }

  private saveDefaultWidth(width: number) {
    const { containerId } = this.getProps();
    window.localStorage.setItem(`${containerId}:pip_default_width`, width.toString());
  }

  public resetPipModeDimentions() {
    const { lockAspectRatio } = this.getProps();

    this.width = DEFAULT_WIDTH;
    this.saveDefaultWidth(this.width);

    if (!lockAspectRatio) {
      this.setAspectRatio(DEFAULT_ASPECT_RATIO);
      this.saveAspectRatio(DEFAULT_ASPECT_RATIO);
    } else {
      this.resize();
    }
  }

  private attachment = {
    left: true,
    right: false,
    top: false,
    bottom: true,
  };

  private handleDragStart = (position: Position, getNextBounds: PaintFrameCallback) => {
    if (!this.containerRef) return;

    const startX = position.x;
    const startY = position.y;

    const containerRef = this.containerRef;
    const initialContainerBounds = containerRef.getBoundingClientRect();
    const { lockAspectRatio = false } = this.getProps();
    const { width: MAX_WIDTH, height: MAX_HEIGHT } = this.getWindowSize();

    containerRef.classList.add('moving');
    document.body.classList.add('disable-scroll');

    const handleDrag = (e: MouseEvent | TouchEvent) => {
      let ticking = false;

      const end = e instanceof MouseEvent ? getMousePosition(e) : getTouchPosition(e);

      if (!ticking) {
        window.requestAnimationFrame(() => {
          ticking = false;

          const result = getNextBounds({
            startX,
            startY,
            containerRef,
            lockAspectRatio,
            endX: end.x,
            endY: end.y,
            initialContainerBounds,
          });

          const isValidWidth = result.newWidth >= MIN_WIDTH && result.newWidth <= MAX_WIDTH;

          const isValidHeight = result.newHeight >= MIN_HEIGHT && result.newHeight <= MAX_HEIGHT;

          let newWidth = initialContainerBounds.width;
          let newHeight = initialContainerBounds.height;

          if (!lockAspectRatio && isValidWidth) {
            newWidth = result.newWidth;
            this.width = newWidth;
            containerRef.style.left = `${result.newLeft}px`;
            containerRef.style.width = `${newWidth}px`;
          }

          if (!lockAspectRatio && isValidHeight) {
            newHeight = result.newHeight;
            containerRef.style.top = `${result.newTop}px`;
            containerRef.style.height = `${newHeight}px`;
          }

          if (lockAspectRatio && isValidWidth && isValidHeight) {
            newWidth = result.newWidth;
            this.width = newWidth;
            newHeight = result.newHeight;
            containerRef.style.top = `${result.newTop}px`;
            containerRef.style.left = `${result.newLeft}px`;
            containerRef.style.height = `${newHeight}px`;
            containerRef.style.width = `${newWidth}px`;
          }

          this.aspectRatio = this.calculateAspectRatio(newWidth, newHeight);
        });

        ticking = true;
      }
    };

    const handleDragEnd = () => {
      containerRef.classList.remove('moving');
      document.body.classList.remove('disable-scroll');

      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchend', handleDragEnd);

      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('touchmove', handleDrag);

      /**
       * move container to visible area and stick to
       * left or right side based of mid position
       */

      const { height, left, top, width } = containerRef.getBoundingClientRect();
      const result = { left, top };

      const midX = result.left + width / 2;
      const bounds = this.getBounds();

      if (result.top < bounds.minY) {
        result.top = bounds.minY;
        this.attachment.top = true;
      } else if (result.top > bounds.maxY) {
        result.top = bounds.maxY;
        this.attachment.bottom = true;
      } else {
        this.attachment.top = false;
        this.attachment.bottom = false;
      }

      if (midX < bounds.midX) {
        result.left = bounds.minX;
        this.attachment.left = true;
      } else {
        result.left = bounds.maxX;
        this.attachment.right = true;
      }

      containerRef.style.left = `${result.left}px`;
      containerRef.style.top = `${result.top}px`;

      this.saveDefaultWidth(width);
      this.aspectRatio = this.calculateAspectRatio(width, height);
      this.saveAspectRatio(this.aspectRatio);
    };

    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchend', handleDragEnd);

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('touchmove', handleDrag);
  };

  private createDragHandler = (getNextBounds: PaintFrameCallback) => {
    return (e: MouseEvent | TouchEvent) => {
      e.stopPropagation();
      const position = e instanceof MouseEvent ? getMousePosition(e) : getTouchPosition(e);
      return this.handleDragStart(position, getNextBounds);
    };
  };

  private calculateAspectRatio(width: number, height: number) {
    const { footer, footerHeight = DEFAULT_FOOTER_HEIGHT } = this.getProps();

    const bodyHeight = footer ? height - footerHeight : height;

    return width / bodyHeight;
  }

  private handleContainerDrag = this.createDragHandler(
    ({ startX, endX, startY, endY, initialContainerBounds }) => {
      const { left, top, width, height } = initialContainerBounds;

      return {
        newLeft: left + endX - startX,
        newTop: top + endY - startY,
        newWidth: width,
        newHeight: height,
      };
    }
  );

  private handleTopEdgeResize = this.createDragHandler(
    ({ startY, endY, lockAspectRatio, initialContainerBounds }) => {
      const { height, left, top, width } = initialContainerBounds;

      const deltaY = startY - endY;
      const newHeight = height + deltaY;
      const newTop = top - deltaY;

      const newWidth = lockAspectRatio ? newHeight * this.aspectRatio : width;

      const newLeft = lockAspectRatio && this.attachment.right ? left + width - newWidth : left;

      return {
        newWidth,
        newHeight,
        newTop,
        newLeft,
      };
    }
  );

  private handleBottomEdgeResize = this.createDragHandler(
    ({ startY, endY, lockAspectRatio, initialContainerBounds }) => {
      const { height, width, left, top } = initialContainerBounds;

      const deltaY = endY - startY;
      const newHeight = height + deltaY;

      const newWidth = lockAspectRatio ? newHeight * this.aspectRatio : width;

      const newLeft = lockAspectRatio && this.attachment.right ? left + width - newWidth : left;

      return {
        newWidth,
        newHeight,
        newLeft,
        newTop: top,
      };
    }
  );

  private handleLeftEdgeResize = this.createDragHandler(
    ({ startX, endX, lockAspectRatio, initialContainerBounds }) => {
      const { height, left, top, width } = initialContainerBounds;

      const deltaX = startX - endX;
      const newWidth = width + deltaX;
      const newLeft = left - deltaX;

      const newHeight = lockAspectRatio ? this.getHeight(newWidth) : height;

      const newTop =
        lockAspectRatio && this.attachment.bottom
          ? top + height - newHeight
          : lockAspectRatio && !this.attachment.top
          ? top + (height - newHeight) / 2
          : top;

      return {
        newWidth,
        newLeft,
        newHeight,
        newTop,
      };
    }
  );

  private handleRightEdgeResize = this.createDragHandler(
    ({ startX, endX, lockAspectRatio, initialContainerBounds }) => {
      const { height, width, left, top } = initialContainerBounds;

      const deltaX = endX - startX;
      const newWidth = width + deltaX;

      const newHeight = lockAspectRatio ? this.getHeight(newWidth) : height;

      const newTop =
        lockAspectRatio && this.attachment.bottom
          ? top + height - newHeight
          : lockAspectRatio && !this.attachment.top
          ? top + (height - newHeight) / 2
          : top;

      return {
        newWidth,
        newHeight,
        newTop,
        newLeft: left,
      };
    }
  );

  private handleTopLeftCornerResize = this.createDragHandler(
    ({ startX, endX, startY, endY, lockAspectRatio, initialContainerBounds }) => {
      const { left, height, top, width } = initialContainerBounds;

      const deltaX = startX - endX;
      const newWidth = width + deltaX;
      const newLeft = left - deltaX;

      const deltaY = startY - endY;
      const newHeight = lockAspectRatio ? this.getHeight(newWidth) : height + deltaY;
      const newTop = lockAspectRatio ? top + height - newHeight : top - deltaY;

      return {
        newWidth,
        newHeight,
        newTop,
        newLeft,
      };
    }
  );

  private handleTopRightCornerResize = this.createDragHandler(
    ({ startX, endX, startY, endY, lockAspectRatio, initialContainerBounds }) => {
      const { height, top, width, left } = initialContainerBounds;

      const deltaX = endX - startX;
      const newWidth = width + deltaX;

      const deltaY = startY - endY;
      const newHeight = lockAspectRatio ? this.getHeight(newWidth) : height + deltaY;
      const newTop = lockAspectRatio ? top + height - newHeight : top - deltaY;

      return {
        newWidth,
        newHeight,
        newTop,
        newLeft: left,
      };
    }
  );

  private handleBottomLeftCornerResize = this.createDragHandler(
    ({ startX, endX, startY, endY, lockAspectRatio, initialContainerBounds }) => {
      const { height, left, width, top } = initialContainerBounds;

      const deltaX = startX - endX;
      const newWidth = width + deltaX;
      const newLeft = left - deltaX;

      const deltaY = endY - startY;
      const newHeight = lockAspectRatio ? this.getHeight(newWidth) : height + deltaY;

      return {
        newWidth,
        newHeight,
        newLeft,
        newTop: top,
      };
    }
  );

  private handleBottomRightCornerResize = this.createDragHandler(
    ({ startX, endX, startY, endY, lockAspectRatio, initialContainerBounds }) => {
      const { height, width, left, top } = initialContainerBounds;

      const deltaX = endX - startX;
      const newWidth = width + deltaX;

      const deltaY = endY - startY;
      const newHeight = lockAspectRatio ? this.getHeight(newWidth) : height + deltaY;

      return {
        newWidth,
        newHeight,
        newLeft: left,
        newTop: top,
      };
    }
  );

  private resizeBorders() {
    const { disableResize = false } = this.getProps();
    const { mode } = this.getState();

    if (disableResize || mode !== 'pip' || document.fullscreenElement) return [];

    return [
      // edges
      h('div.pip-container__top-edge', {
        onmousedown: this.handleTopEdgeResize,
        ontouchstart: this.handleTopEdgeResize,
      }),
      h('div.pip-container__bottom-edge', {
        onmousedown: this.handleBottomEdgeResize,
        ontouchstart: this.handleBottomEdgeResize,
      }),
      h('div.pip-container__left-edge', {
        onmousedown: this.handleLeftEdgeResize,
        ontouchstart: this.handleLeftEdgeResize,
      }),
      h('div.pip-container__right-edge', {
        onmousedown: this.handleRightEdgeResize,
        ontouchstart: this.handleRightEdgeResize,
      }),

      // corners
      h('div.pip-container__top-left-corner', {
        onmousedown: this.handleTopLeftCornerResize,
        ontouchstart: this.handleTopLeftCornerResize,
      }),
      h('div.pip-container__top-right-corner', {
        onmousedown: this.handleTopRightCornerResize,
        ontouchstart: this.handleTopRightCornerResize,
      }),
      h('div.pip-container__bottom-left-corner', {
        onmousedown: this.handleBottomLeftCornerResize,
        ontouchstart: this.handleBottomLeftCornerResize,
      }),
      h('div.pip-container__bottom-right-corner', {
        onmousedown: this.handleBottomRightCornerResize,
        ontouchstart: this.handleBottomRightCornerResize,
      }),
    ];
  }
}

export default (props: IPipContainerProps) => h(PipContainer, props);
