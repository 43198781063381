import { h, IComponent } from 'core';

import * as EmbedIcon from 'assets/launch.svg';

import AppService from 'acadly/app/service';
import { Actions as ClassActions } from 'acadly/class/actions';
import ClassService from 'acadly/class/service';
import FlatButton from 'acadly/common/FlatButton';
import SvgIcon from 'acadly/common/SvgIcon';
import CourseService from 'acadly/course/service';
import PipContainer from 'acadly/pip-container/PipContainer';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { pt, style } from 'acadly/styles';
import { pluralize } from 'acadly/utils';

import { AttendanceTimer, getAttendanceTime } from './functions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProxyAttendancePipProps {}

interface ProxyAttendancePipState {
  secondsLeft: number;
}

export default class ProxyAttendancePip extends IComponent<
  ProxyAttendancePipProps,
  ProxyAttendancePipState
> {
  public static get isVisible() {
    const rootState = getStore().getState();
    const classData = rootState.class.data;
    const { attendanceData } = rootState.app;
    const currentUserId = rootState.getIn.session!.userId;

    const isProxy = attendanceData?.isProxy ?? classData?.attendance?.isProxy ?? 0;

    if (isProxy !== 1) return false;

    const taker = attendanceData.taker ?? classData?.attendance?.taker;

    if (taker?.userId !== currentUserId) {
      // should be visible to attendance taker only
      return false;
    }

    const match = Routes.classAttendance.getMatch();

    if (!match) return true; // not on class attendance page

    const { classId, courseId } = attendanceData;

    return !courseId.endsWith(match.courseShortId) || !classId.endsWith(match.classShortId); // on different class attendance page
  }

  private attendanceTimer = AttendanceTimer({
    attendanceTime: getAttendanceTime,
    onTick: (secondsLeft) => this.setState({ secondsLeft }),
    onTimeout: () => dispatch(ClassActions.stopProxyAttendance()),
  });

  private beforeWindowUnload = (e: BeforeUnloadEvent) => {
    if (this.attendanceTimer.isRunning) {
      e.preventDefault();
      return (e.returnValue = 'Attendance is in progress. Are you sure you want to exit?');
    }
    return undefined;
  };

  public componentWillMount() {
    const initialState: ProxyAttendancePipState = { secondsLeft: 0 };
    this.setState(initialState);
    this.attendanceTimer.start();
    window.addEventListener('beforeunload', this.beforeWindowUnload, { capture: true });
  }

  public componentWillUnmount() {
    this.attendanceTimer.stop();
    window.removeEventListener('beforeunload', this.beforeWindowUnload, { capture: true });
  }

  private goToAttendancePage = () => {
    const { attendanceData } = getStore().getState().app;
    if (!attendanceData) return;
    const { classId, courseId } = attendanceData;
    Routes.classAttendance.navigate({
      courseShortId: CourseService.getShortIdFromCourseId(courseId),
      classShortId: ClassService.getShortIdFromClassId(classId),
      univSlug: AppService.getUniversitySlug(),
    });
  };

  private stopAttendance = async () => {
    await dispatch(ClassActions.stopProxyAttendance());
    this.attendanceTimer.stop();
  };

  private getPresetStudents() {
    const { attendanceData } = getStore().getState().app;
    const present = attendanceData?.numPresent ?? 0;
    return present;
  }

  public render() {
    const { secondsLeft } = this.getState();

    const present = this.getPresetStudents();

    return PipContainer({
      disableResize: true,
      containerId: 'proxy-attendance-pip',
      initialAspectRatio: 16 / 9,
      lockAspectRatio: true,
      rightControls: [
        {
          type: 'custom',
          view: SvgIcon({
            icon: EmbedIcon,
            role: 'button',
            className: 'pip-container__button',
            title: 'Go to attendance page',
            onmousedown: (e) => e.stopPropagation(),
            onclick: this.goToAttendancePage,
          }),
        },
      ],
      body: [
        h('div', style([pt('40px'), 'textCenter', 'whiteBackground', 'fullHeight', 'fullWidth']), [
          h('div', style(['large']), 'Attendance in progress...'),
          h('div.attendance-gif', { height: '4rem' }),
          h(
            'div.fc-orange.fw-bold',
            style(['textCenter']),
            `${secondsLeft < 0 ? '--' : secondsLeft} sec`
          ),
          h('div', `${Math.max(0, present)} ${pluralize(present, 'student')} marked present`),
        ]),
      ],
      footer: h('div', style(['flex', 'alignCenter', 'flexEnd', 'fullWidth', { gap: '1rem ' }]), [
        FlatButton('Stop', {
          type: 'secondary',
          onclick: this.stopAttendance,
        }),
      ]),
    });
  }
}
