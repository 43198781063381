import { Subject } from 'rxjs/Subject';

interface IUpdateVCallFrameMode {
  type: '@vcall/update_frame_mode';
}

interface IShowVCallNotificationEvent {
  type: '@app/vcall_show_notification';
  payload: {
    id: string;
    iconUrl: string;
    message: string;
  };
}

interface IVCallNotificationClickEvent {
  type: '@app/vcall_notification_click';
  notificationId: string;
}

interface IAppNotificationDismissed {
  type: '@app/notification_dismissed';
  payload: INotification;
}

export type IAcadlyEvent =
  | IUpdateVCallFrameMode
  | IShowVCallNotificationEvent
  | IVCallNotificationClickEvent
  | IAppNotificationDismissed;

const AcadlyEvents = new Subject<IAcadlyEvent>();

if (process.env.ENV === 'development' || process.env.ENV === 'sbst') {
  (window as any).AcadlyEvents = AcadlyEvents;
  AcadlyEvents.subscribe((e) => {
    console.log(e);
  });
}

export default AcadlyEvents;
