import { jsonRequest } from 'core/http';

import api from 'acadly/api';

export interface ICreateQuizRequest {
  classId: string;
  toBeDone: 'preClass' | 'inClass';
}

export const createQuiz = (data: ICreateQuizRequest) =>
  jsonRequest<IQuiz>(api().quizCreate, {
    method: 'POST',
    data,
  });

export interface IEditQuizRequest {
  classId: string;
  quizId: string;
  scoring: 'incentivizing' | 'neutral' | 'penalizing';
  title: string;
  instructions: string | null;
  attachments: IAttachment[];
}
export const editQuiz = (data: IEditQuizRequest) =>
  jsonRequest<void>(api().quizEdit, {
    method: 'POST',
    data,
  });

export interface IFetchQuizDataRequest {
  quizId: string;
  firstAccess: 0 | 1;
}
export interface IFetchQuizDataResponse {
  questions: IQuizQuestion[];
  publishDefaults?: QuizPublishDefaults; // for class in-charge
  submission?: IQuizSubmission;
}
export const fetchQuizDetails = (data: IFetchQuizDataRequest) => {
  return jsonRequest<IFetchQuizDataResponse>(
    api().quizGet + `?quizId=${data.quizId}&firstAccess=${data.firstAccess}`,
    {
      method: 'GET',
    }
  );
};

export type IAddQuestionRequest = {
  classId: string;
  activityType: 'quizzes';
  activityId: string;
  description: {
    text: string;
  };
} & (
  | {
      // tf
      type: 'tf';
      answerKey: 't' | 'f';
    }
  | {
      // mcq
      type: 'mcq';
      answerKey: string;
      options: {
        text: string;
        num: number;
        type: 'text';
      }[];
    }
  | {
      // reorder
      type: 'reorder';
      options: {
        text: string;
        type: 'text';
      }[];
    }
);
export type IAddQuestionResponse = IQuizQuestion;
export const addQuestion = (data: IAddQuestionRequest) =>
  jsonRequest<IAddQuestionResponse>(api().quizQueAdd, {
    method: 'POST',
    data,
  });

export interface QuestionOrder {
  questionId: string;
  order: number;
}

export interface ReorderQuestionPayload {
  classId: string;
  quizId: string;
  questionsOrder: QuestionOrder[];
}

interface ReorderQuestionResponse {
  message: 'success';
}

export const reorderQuestion = (data: ReorderQuestionPayload) => {
  return jsonRequest<ReorderQuestionResponse>(api().quizQueReorder, {
    method: 'PUT',
    data,
  });
};

export type IDeleteQuizRequest = {
  classId: string;
  quizId: string;
  toBeDone: 'inClass' | 'preClass';
};
export const deleteQuiz = (data: IDeleteQuizRequest) => {
  return jsonRequest<void>(api().quizRemove, {
    method: 'DELETE',
    data,
  });
};

export type IEditQuestionRequest = {
  classId: string;
  activityId: string; // quiz id
  description: {
    text: string;
  };
  questionId: string;
  activityType: 'quizzes';
} & (
  | {
      // tf
      answerKey: 't' | 'f';
      type: 'tf';
    }
  | {
      // mcq
      type: 'mcq';
      answerKey: string;
      options: {
        text: string;
        num: number;
        type: 'text';
      }[];
    }
  | {
      type: 'reorder';
      options: {
        text: string;
        type: 'text';
      }[];
    }
);

export type IEditQuestionResponse = {
  message: 'success';
  /** question id */
  _id: string;
  details: IBaseQuizQuestionDetails &
    (
      | RequiredKeys<ITrueFalseQuestion, 'answerKey'>
      | RequiredKeys<IMultipleChoiceQuestion, 'answerKey'>
      | RequiredKeys<IReorderQuestion, 'answerKey'>
    );
};

export const editQuestion = (data: IEditQuestionRequest) =>
  jsonRequest<IEditQuestionResponse>(api().quizQueEdit, {
    method: 'POST',
    data,
  });

export type IRemoveQuestionRequest = {
  classId: string;
  questionId: string;
  activityId: string; // quiz id
  activityType: 'quizzes';
};

export const removeQuestion = (data: IRemoveQuestionRequest) =>
  jsonRequest<void>(api().quizQueRemove, {
    method: 'DELETE',
    data,
  });
export interface IPublishQuizRequest {
  classId: string;
  quizId: string;
  toBeDone: 'inClass' | 'preClass';
  /** @deprecated */
  dueDateTime: string; // "YYYYMMDDTHH:mm" format, no longer used in back-end
  deadlineFirst: 0 | 1;
  allowLate: 0 | 1;
  randomOpt: 0 | 1;
  randomQues: 0 | 1;
  dueDateType: ActivityDueDateType;
  duration: ActivityDuration; // -1, when dueDateType = manual
  subscribeToComments: 0 | 1;
  saveAsDefault: 0 | 1;
  scoring: IQuizScoringScheme;
}

export interface IUpdateQuizRequest {
  classId: string;
  quizId: string;
  title: string;
  instructions: string;
  attachments: IAttachment[];
  toNotify: 0 | 1;
  scoring?: 'incentivizing' | 'neutral' | 'penalizing';
  questions?: (
    | {
        questionId: string;
        type: 'tf';
        description: {
          text: string;
        };
        answerKey: string;
        options?: undefined;
      }
    | {
        questionId: string;
        type: 'mcq';
        description: {
          text: string;
        };
        options: {
          text: string;
          num: number;
          type: 'text';
        }[];
        answerKey: string;
      }
    | {
        questionId: string;
        type: 'reorder';
        description: {
          text: string;
        };
        options: {
          text: string;
          type: 'text';
        }[];
      }
  )[];
  questionsOrder: QuestionOrder[];
}

export interface IPublishQuizResponse {
  quizNum: number;
  trueNum: number;
  publishedOn: number;
  dueDateTime: number;
  dueDateType: ActivityDueDateType;
  dueDateTimeL: string; // "YYYY-MM-DDTHH:mmZ"
}
export const publishQuiz = (data: IPublishQuizRequest) => {
  return jsonRequest<IPublishQuizResponse>(api().quizPublish, {
    method: 'POST',
    data,
  });
};

export interface IStopQuizRequest {
  classId: string;
  activityType: 'quizzes';
  activityId: string;
  localTime: string; // format YYYYMMDDTHH:mm
}

export interface IStopQuizResponse {
  message: string;
  dueDateTime: number;
}
export const stopQuiz = (data: IStopQuizRequest) =>
  jsonRequest<IStopQuizResponse>(api().quizStop, {
    method: 'POST',
    data,
  });

export const updatePublishedQuiz = (data: IUpdateQuizRequest) =>
  jsonRequest<IPublishQuizResponse>(api().updatePublishedQuiz, {
    method: 'PUT',
    data,
  });

interface ISaveResponseRequest {
  quizId: string;
  questionId: string;
  /**
   * responseKey will be `'t' | 'f'` for tf questions,
   * `regex(/^[0-1]{4}$/)` for MCQ, hyphenated string for reorder,
   * `'0000'` for unattempted
   */
  responseKey: string;
}

export const saveResponse = (data: ISaveResponseRequest) => {
  return jsonRequest<{ message: string }>(api().quizSaveResponse, {
    method: 'POST',
    data,
  });
};

export interface ISaveSubmissionRequest {
  quizId: string;
  classId: string;
  submission: {
    questionId: string;
    /**
     * answerString will be `'t' | 'f'` for tf questions,
     * `regex(/^[0-1]{4}$/)` for MCQ, hyphenated string for reorder,
     * `'0000'` for unattempted
     */
    answerString: string;
  }[];
}

export interface ISaveSubmissionResponse {
  message: 'success';
  submissionObject: ObjectMap<{
    /**
     * answerString will be `'t' | 'f'` for tf questions,
     * `regex(/^[0-1]{4}$/)` for MCQ, hyphenated string for reorder,
     * `'0000'` for unattempted
     */
    answerString: string;
  }>;
}

export const saveSubmission = (courseId: string, data: ISaveSubmissionRequest) => {
  return jsonRequest<ISaveSubmissionResponse>(api().quizSave, {
    method: 'POST',
    headers: { courseId },
    data,
  });
};

export interface ISubmitRequest {
  quizId: string;
  classId: string;
  submission: {
    questionId: string;
    /**
     * answerString will be `'t' | 'f'` for tf questions,
     * `regex(/^[0-1]{4}$/)` for MCQ, hyphenated string for reorder,
     * `'0000'` for unattempted
     */
    answerString: string;
  }[];
  localTime: string; // "YYYYMMDDTHH:mm"
  submissionType: 'auto' | 'manual';
}
export interface ISubmitResponse {
  message: 'success';
  score?: {
    incentivizing: number;
    neutral: number;
    penalizing: number;
  };
  answerKeys?: {
    _id: string;
    details: {
      answerKey: string;
      type: IQuizQuestionType;
    };
  }[];
  /** submission data by question-id */
  submission: ObjectMap<{
    /**
     * answerString will be `'t' | 'f'` for tf questions,
     * `regex(/^[0-1]{4}$/)` for MCQ, hyphenated string for reorder,
     * `'0000'` for unattempted
     */
    answerString: string;
    score?: {
      incentivizing: number;
      neutral: number;
      penalizing: number;
    };
  }>;
  submittedOn: UnixTimestamp;
}
export const submit = (data: ISubmitRequest) =>
  jsonRequest<ISubmitResponse>(api().quizSubmit, {
    method: 'POST',
    data,
  });

export interface IFetchAllQuizzesResponse {
  activityData: IResponseQuizWithClassId[];
  userData: {
    quizzes: ObjectMap<IResponseQuizUserData | undefined>;
  };
}
export const fetchAllQuizzes = () =>
  jsonRequest<IFetchAllQuizzesResponse>(api().archiveGet + '/quizzes', {
    method: 'GET',
  });

export interface IAnalyticsFetchResponse {
  submittedBy: IQuizAnalyticsSubmission[];
}
export const analyticsFetch = (quizId: string) =>
  jsonRequest<IAnalyticsFetchResponse>(api().quizAnalyticsFetch(quizId), {
    method: 'GET',
  });

export interface IAnalyticsIndividualFetchResponse {
  submission: {
    identifiers: {
      userId: string;
      name: string;
      avatar: string;
      role: ICourseRole;
    };
    submission: IQuizSubmission;
  };
}

export const analyticsIndividualFetch = (quizId: string, studentId: string) =>
  jsonRequest<IAnalyticsIndividualFetchResponse>(
    api().quizAnalyticsFetchIndividual(quizId, studentId),
    {
      method: 'GET',
    }
  );

export type IAnalyticsIndividualQuestionFetchResponse =
  | {
      type: 'mcq';
      _id: string;
      stats: {
        opt1?: number;
        opt2?: number;
        opt3?: number;
        opt4?: number;
        correct: number;
        attempts: number;
      };
    }
  | {
      type: 'tf';
      _id: string;
      stats: {
        correct: number;
        attempts: number;
      };
    }
  | {
      type: 'reorder';
      _id: string;
      stats: {
        correct: number;
        attempts: number;
        options: {
          /**
           * optionKey format will be `option${option-letter}`, e.g. `optionA`
           * and value will be number of students who put that option in correct order
           */
          [optionKey: string]: number;
        };
      };
    };

export const analyticsFetchIndividualQuestion = (quizId: string, questionId: string) =>
  jsonRequest<IAnalyticsIndividualQuestionFetchResponse>(
    api().quizAnalyticsFetchIndividualQuestion(quizId, questionId),
    {
      method: 'GET',
    }
  );
