import * as intlTelInput from 'intl-tel-input';

import { CSS, h, IComponent } from 'core';

interface IPhoneInputProps {
  style?: CSS;
  value: string;
  placeholder?: string;
  onChange: (value: string, dialCode: string) => any;
}

class PhoneInput extends IComponent<IPhoneInputProps, unknown> {
  private inputRef: HTMLInputElement | null;
  private iti: intlTelInput.Plugin | null = null;

  public componentDidMount() {
    if (!this.inputRef) {
      console.log('[PhoneInput] input could not be initialized');
      return;
    }

    this.iti = intlTelInput(this.inputRef, {
      preferredCountries: ['us', 'ca', 'in'],
      separateDialCode: true,
      dropdownContainer: document.body,
      // any initialisation options go here
    });
  }

  public componentWillUnmount() {
    if (!this.iti) return;
    this.iti.destroy();
  }

  public render() {
    const { style, placeholder, value, onChange } = this.getProps();
    return h('div', { style }, [
      h('input', {
        type: 'tel',
        style: {
          border: 'none',
          height: '42px',
          borderBottom: '2px solid #aaa',
        },
        ref: (el: HTMLInputElement) => (this.inputRef = el),
        value,
        placeholder,
        onchange: (e: Event) => {
          const country = this.iti.getSelectedCountryData();
          onChange((e.target as HTMLInputElement).value, country.dialCode);
        },
      }),
    ]);
  }
}

export default (props: IPhoneInputProps) => h(PhoneInput, props);
