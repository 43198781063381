import { reducer as announcementReducer } from 'acadly/announcement/reducer';
import { reducer as appReducer } from 'acadly/app/reducer';
import { reducer as assignmentReducer } from 'acadly/assignment/reducer';
import { chainReducers } from 'acadly/chainReducers';
import { reducer as classReducer } from 'acadly/class/reducer';
import { reducer as commentsReducer } from 'acadly/comments/reducer';
import { reducer as courseReducer } from 'acadly/course/reducer';
import { reducer as discussionReducer } from 'acadly/discussion/reducer';
import { reducer as getInReducer } from 'acadly/getin/reducer';
import { initialState } from 'acadly/initialState';
import { reducer as pipContainerReducer } from 'acadly/pip-container/reducer';
import { reducer as pollReducer } from 'acadly/poll/reducer';
import { reducer as queryReducer } from 'acadly/query/reducer';
import { reducer as quizReducer } from 'acadly/quiz/reducer';
import { reducer as resourceReducer } from 'acadly/resource/reducer';

export const rootReducer = chainReducers(
  initialState,
  appReducer,
  getInReducer,
  assignmentReducer,
  announcementReducer,
  quizReducer,
  pollReducer,
  discussionReducer,
  resourceReducer,
  queryReducer,
  courseReducer,
  classReducer,
  commentsReducer,
  pipContainerReducer
);
