import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import { Actions as classActions } from 'acadly/class/actions';
import { createAction, Thunk } from 'acadly/createAction';
import { Actions as getInActions } from 'acadly/getin/actions';
import { IPusherPayload } from 'acadly/pusher';

import * as api from './api';
import { responsePollToPoll } from './functions';

export type IPollActionMap = {
  '@poll/CREATE_SUCCESS': ICreatePollSuccessPayload;
  '@poll/DELETE_SUCCESS': IDeletePollSuccessPayload;
  '@poll/EDIT_SUCCESS': IEditPollSuccessPayload;
  '@poll/FETCH_REQUEST': undefined;
  '@poll/FETCH_SUCCESS': IPollFetchSuccessPayload;
  '@poll/question/ADD_SUCCESS': IQuestionAddPayload;
  '@poll/question/EDIT_SUCCESS': IQuestionEditSuccessPayload;
  '@poll/PUBLISH_SUCCESS': IPublishSuccessPayload;
  '@poll/STOP_SUCCESS': IStopPollSuccessPayload;
  '@poll/UPDATE_PUBLISHED_POLL_SUCCESS': api.IUpdatePublishedPollRequest;
  '@poll/CLEAR_DATA': undefined;
  '@poll/SUBMIT_SUCCESS': ISubmitSuccessPayload;
  '@poll/all/FETCH_SUCCESS': IFetchAllSuccessPayload;
  '@poll/pusher/PUBLISH': IPusherPublishPayload;
  '@poll/pushed/POLL_EDITED': IPusherPublishPayload;
  '@poll/analytics/FETCH_SUCCESS': IAnalyticsFetchPayload;
  '@poll/pusher/SUBMIT': IPusherSubmitPayload;
};

export type IPusherSubmitPayload = IPusherPayload<{
  classId: string;
  pollId: string;
  toBeDone: 'preClass' | 'inClass';
  submission: ObjectMap<{
    optionSelected: number;
  }>;
}>;

export type IAnalyticsFetchPayload = {
  response: api.IAnalyticsFetchResponse;
  pollId: string;
};

export type IPusherPublishPayload = IPusherPayload<{
  classId: string;
  classType: 'lecture';
  classSubType: IClassSubType;
  classTypeNum: number;
  pollId: string;
  details: IResponsePollDetails;
  stats: { numSubmitted: number };
  activities: { numCommentsTotal: number };
}>;

export type IFetchAllSuccessPayload = api.IFetchAllResponse;

export type ISubmitSuccessPayload = {
  request: api.ISubmitRequest;
  response: api.ISubmitResponse;
  toBeDone: 'preClass' | 'inClass';
};

export type IPublishSuccessPayload = {
  request: api.IPublishRequest;
  response: api.IPublishResponse;
};

export type IStopPollSuccessPayload = {
  request: api.IStopPollRequest;
  response: api.IStopPollResponse;
};

export interface IUpdatePublishedPollRequest {
  classId: string;
  pollId: string;
  title: string;
  questionId: string;
  description: {
    text: string;
    attachments: IAttachment[];
  };
  options: {
    text: string;
    type: 'text';
  }[];
  toNotify: 0 | 1;
}

export type IQuestionEditSuccessPayload = api.IQuestionEditRequest;

export type IPollFetchSuccessPayload = api.IPollFetchResponse & {
  firstAccess: 0 | 1;
  pollId: string;
};

export type IQuestionAddPayload = {
  request: api.IAddQuestionRequest;
  response: api.IAddQuestionResponse;
};

export type IDeletePollSuccessPayload = api.IDeletePollRequest;

export type ICreatePollSuccessPayload = {
  classId: string;
  poll: IPoll;
};

export type IEditPollSuccessPayload = api.IEditPollRequest;

export const Actions = {
  createPollSuccess: createAction('@poll/CREATE_SUCCESS'),
  createPoll:
    (data: api.ICreatePollRequest): Thunk<IPoll> =>
    async (dispatch) => {
      const response = await api.createPoll(data);
      googleAnalytics.activityCreated('poll', data.toBeDone);
      const poll = responsePollToPoll(undefined, data.classId, response.data);
      dispatch(
        Actions.createPollSuccess({
          classId: data.classId,
          poll: poll,
        })
      );
      return poll;
    },

  deletePollSuccess: createAction('@poll/DELETE_SUCCESS'),
  deletePoll:
    (data: api.IDeletePollRequest, beforeDispatch?: () => any): Thunk<void> =>
    async (dispatch) => {
      await api.deletePoll(data);
      if (beforeDispatch) {
        await beforeDispatch();
      }
      dispatch(Actions.deletePollSuccess(data));
    },

  editPollSuccess: createAction('@poll/EDIT_SUCCESS'),
  editPoll:
    (data: api.IEditPollRequest): Thunk<void> =>
    async (dispatch) => {
      await api.editPoll(data);
      dispatch(Actions.editPollSuccess(data));
    },

  questionAddSuccess: createAction('@poll/question/ADD_SUCCESS'),
  questionAdd:
    (data: api.IAddQuestionRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.questionAdd(data);
      dispatch(
        Actions.questionAddSuccess({
          request: data,
          response: response.data,
        })
      );
    },

  fetchRequest: createAction('@poll/FETCH_REQUEST'),
  fetchSuccess: createAction('@poll/FETCH_SUCCESS'),
  fetch:
    (pollId: string, courseRole: ICourseRole, classId: string): Thunk<void> =>
    async (dispatch, getState) => {
      dispatch(Actions.fetchRequest(undefined));

      const state = getState();
      const poll = state.polls.byId[pollId];

      if (!poll) return;

      const firstAccess = courseRole === 'student' && !poll.userData ? 1 : 0;
      const response = await api.fetchPoll({
        pollId,
        firstAccess,
      });

      if (firstAccess) {
        dispatch(
          classActions.incrementClassSeenActivities({
            classId: classId,
            toBeDone: poll.details.toBeDone,
            activityKey: 'polls',
          })
        );
      }

      dispatch(
        Actions.fetchSuccess({
          ...response.data,
          firstAccess,
          pollId,
        })
      );
    },

  questionEditSuccess: createAction('@poll/question/EDIT_SUCCESS'),
  questionEdit:
    (data: api.IQuestionEditRequest): Thunk<void> =>
    async (dispatch) => {
      await api.questionEdit(data);
      dispatch(Actions.questionEditSuccess(data));
    },

  clearData: createAction('@poll/CLEAR_DATA'),

  publishSuccess: createAction('@poll/PUBLISH_SUCCESS'),
  publish:
    (data: api.IPublishRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.publish(data);
      googleAnalytics.activityPublished('poll', data.toBeDone);
      dispatch(
        Actions.publishSuccess({
          request: data,
          response: response.data,
        })
      );
    },

  stopPollSuccess: createAction('@poll/STOP_SUCCESS'),
  stopPoll:
    (data: api.IStopPollRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.stopPoll(data);
      dispatch(
        Actions.stopPollSuccess({
          request: data,
          response: response.data,
        })
      );
    },

  updatePublishedPollSuccess: createAction('@poll/UPDATE_PUBLISHED_POLL_SUCCESS'),
  updatePublishedPoll:
    (data: api.IUpdatePublishedPollRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.updatePollPublished(data);
      console.log(response, dispatch);
      // googleAnalytics.activityPublished("poll", data.toBeDone);
      dispatch(Actions.updatePublishedPollSuccess(data));
    },

  submitSuccess: createAction('@poll/SUBMIT_SUCCESS'),
  submit:
    (data: api.ISubmitRequest, toBeDone: 'preClass' | 'inClass'): Thunk<void> =>
    async (dispatch) => {
      const response = await api.submit(data);
      dispatch(
        Actions.submitSuccess({
          request: data,
          response: response.data,
          toBeDone,
        })
      );
    },

  fetchAllSuccess: createAction('@poll/all/FETCH_SUCCESS'),
  fetchAll: (): Thunk<string[]> => async (dispatch) => {
    const response = await api.fetchAll();
    dispatch(Actions.fetchAllSuccess(response.data));
    return response.data.activityData.map((p) => p._id);
  },

  pusherPublish: createAction('@poll/pusher/PUBLISH'),
  pusherPollEdited: createAction('@poll/pushed/POLL_EDITED'),

  analyticsFetchSuccess: createAction('@poll/analytics/FETCH_SUCCESS'),
  analyticsFetch:
    (poll: IPoll): Thunk<void> =>
    async (dispatch) => {
      if (poll.stats.numSubmitted > 0) {
        const response = await api.analyticsFetch(poll._id);
        const avatars = response.data.students.map((s) => s.avatar);
        dispatch(getInActions.fetchAvatars(avatars));
        dispatch(
          Actions.analyticsFetchSuccess({
            response: response.data,
            pollId: poll._id,
          })
        );
      } else {
        dispatch(
          Actions.analyticsFetchSuccess({
            pollId: poll._id,
            response: { students: [] },
          })
        );
      }
    },

  pusherSubmit: createAction('@poll/pusher/SUBMIT'),
};
