import { h, IComponent } from 'core';

import * as CloseIcon from 'assets/close.svg';
import * as DoneIcon from 'assets/done.svg';

import Dialog from 'acadly/common/Dialog';
import RadioButton from 'acadly/common/RadioButton';
import SvgIcon from 'acadly/common/SvgIcon';
import { colors, ml, pad, style } from 'acadly/styles';
import { capitalize, withTabIndex } from 'acadly/utils';

interface IScoringSchemeDialogProps {
  open: boolean;
  initialScheme: IQuizScoringScheme;
  onCancel: () => void;
  onSelect: (scheme: IQuizScoringScheme) => void | Promise<void>;
}

interface IScoringSchemeDialogState {
  selectedScheme: IQuizScoringScheme;
}

class ScoringSchemeDialog extends IComponent<IScoringSchemeDialogProps, IScoringSchemeDialogState> {
  private init() {
    const { initialScheme } = this.getProps();
    const initialState: IScoringSchemeDialogState = {
      selectedScheme: initialScheme,
    };
    this.setState(initialState);
  }

  public componentWillMount() {
    this.init();
  }

  public componentDidUpdate(prevProps: IScoringSchemeDialogProps) {
    const currentProps = this.getProps();
    if (currentProps.open && prevProps.open !== currentProps.open) {
      // dialog is opening
      this.init();
    }
  }

  public render() {
    const { open, onCancel, onSelect } = this.getProps();
    const { selectedScheme } = this.getState();

    const SchemeSelectorCell = (scheme: IQuiz['details']['scoring']) => {
      const attr = style(
        [
          'whiteBackground',
          'borderBox',
          'pointer',
          pad('0.5rem 1em'),
          'large',
          'flex',
          'fullWidth',
        ],
        {},
        withTabIndex({
          onclick: () =>
            this.setState({
              selectedScheme: scheme,
            }),
        })
      );

      return h('div', attr, [
        RadioButton({
          selected: selectedScheme === scheme,
        }),
        h('span', style([ml('0.5rem')]), capitalize(scheme)),
      ]);
    };

    const schemeDescriptionAttrs = style([
      pad('0.5em 1em'),
      'grey',
      'fullWidth',
      'borderBox',
      'small',
    ]);

    return Dialog(
      {
        open,
        title: 'Scoring scheme',
        bodyStyle: {
          padding: '0em',
          paddingLeft: '0em',
          paddingRight: '0em',
        },
        style: {
          width: '30em',
          padding: '0em',
          backgroundColor: colors.backgroundColor,
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: SvgIcon({ icon: CloseIcon }),
          onclick: onCancel,
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: SvgIcon({ icon: DoneIcon }),
          onclick: () => onSelect(selectedScheme),
        },
      },
      [
        h('div', style(['grey', 'small', pad('1rem')]), [
          'Acadly allows you to choose one of the three scoring schemes',
        ]),
        SchemeSelectorCell('incentivizing'),
        h('div', schemeDescriptionAttrs, [
          h('div', 'For each attempted question, student gets:'),
          h('div', '+1 for picking each correct option'),
          h('div', '+1 for not picking an incorrect option'),
          h('div', 'For each unattempted question, student gets 0 points'),
          h('div', 'Incentivizes attempts, high possibility of guesswork'),
        ]),
        SchemeSelectorCell('neutral'),
        h('div', schemeDescriptionAttrs, [
          h('div', 'For each attempted question, student gets:'),
          h('div', '+4 for picking all correct options'),
          h('div', '0 otherwise'),
          h('div', 'For each unattempted question, student gets 0 points'),
          h('div', 'High possibility of guesswork'),
        ]),
        SchemeSelectorCell('penalizing'),
        h('div', schemeDescriptionAttrs, [
          h('div', 'For each attempted question, student gets:'),
          h('div', '+4 for picking all correct options'),
          h('div', '-1.33 otherwise'),
          h('div', 'For each unattempted question, student gets 0 points'),
          h('div', 'Possibility of relatively low average scores, curbs guesswork'),
        ]),
      ]
    );
  }
}

export default (props: IScoringSchemeDialogProps) => h(ScoringSchemeDialog, props);
