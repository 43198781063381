import { h, HTMLAttrs, IComponent, View } from 'core';

import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import { getStore } from 'acadly/store';

type Callback = () => void | Promise<any>;

interface Props extends HTMLAttrs {
  open: boolean;
  title?: string;
  activityType: 'quiz' | 'poll';
  allowLate: 0 | 1;
  hasAttempted: boolean;
  initialCounter: number;
  onCancel: Callback;
  onSubmit: Callback;
}

interface State {
  counter: number;
}

class AutoSubmitDialog extends IComponent<Props, State> {
  private timer: NodeJS.Timer | undefined = undefined;

  private get isAccessible() {
    return getStore().getState().app.acc.web.turnOff === 0;
  }

  public componentWillMount() {
    const { initialCounter } = this.getProps();
    const initialState: State = {
      counter: initialCounter,
    };
    this.setState(initialState);
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open && nextProps.hasAttempted && this.getProps().open !== nextProps.open) {
      // start timer when alert shows
      this.startTimer();
    }
  }

  private async startTimer() {
    const { initialCounter, onSubmit } = this.getProps();

    console.log('startTimer');
    await this.setState({ counter: initialCounter });

    this.timer = setInterval(() => {
      const { counter } = this.getState();

      if (counter > 1) {
        this.setState({ counter: counter - 1 });
        console.log('Timer:', counter - 1);
        return;
      }

      this.stopTimer(onSubmit);
    }, 1000);
  }

  private async stopTimer(callback: Callback) {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
    await this.setState({ counter: 0 });
    console.log('stopTimer');
    return callback();
  }

  public render() {
    const { open, hasAttempted, title } = this.getProps();
    return Alert(
      {
        open,
        title: title || (hasAttempted ? 'Last chance!' : 'Time up!'),
        titleClassName: 'fc-orange',
        style: { width: '30em' },
        actions: this.actions(),
      },
      this.body()
    );
  }

  private body() {
    const { counter } = this.getState();
    const { hasAttempted, allowLate, activityType } = this.getProps();

    if (hasAttempted) {
      return [h('div.auto-submit-dialog__counter', counter.toString())];
    }

    const message: View[] = [];

    if (allowLate) {
      message.push(
        `This ${activityType} allows late submissions.`,
        h('br'),
        'You can manually submit later, it will be marked as Late.'
      );
    } else {
      message.push(
        `This ${activityType} DOES NOT allow late submissions.`,
        h('br'),
        'You won’t be able to submit it later.'
      );
    }

    return [h('div', 'You have not picked any options.'), h('br'), h('div', message)];
  }

  private actions() {
    const actions: View[] = [];
    const { hasAttempted, allowLate, onCancel, onSubmit } = this.getProps();

    const secondaryAction = FlatButton(allowLate ? 'Submit later' : "Don't submit", {
      type: 'secondary',
      style: { marginLeft: 0 },
      tabIndex: this.isAccessible ? 0 : undefined,
      onclick: () => this.stopTimer(onCancel),
    });

    const primaryAction = FlatButton('Submit', {
      type: 'primary',
      style: { marginLeft: 'auto' },
      tabIndex: this.isAccessible ? 0 : undefined,
      onclick: () => this.stopTimer(onSubmit),
    });

    const notAttemptedAction = FlatButton('Okay', {
      type: 'primary',
      style: { marginLeft: 'auto' },
      tabIndex: this.isAccessible ? 0 : undefined,
      onclick: () => this.stopTimer(onCancel),
    });

    if (hasAttempted) {
      actions.push(secondaryAction, primaryAction);
    } else {
      actions.push(notAttemptedAction);
    }

    return actions;
  }
}

export default (props: Props) => h(AutoSubmitDialog, props);
