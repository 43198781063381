import classnames from 'classnames';

import { h, IComponent } from 'core';

interface ITextAreaProps {
  value: string;
  maxLength?: number;
  className?: string;
  showTextLength?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
}

class TextArea extends IComponent<ITextAreaProps, never> {
  public render() {
    const { className, value, maxLength, placeholder, onChange, showTextLength } = this.getProps();

    return h('div.textarea', { className: classnames(className) }, [
      h('textarea.textarea__field.no-focus', {
        value,
        placeholder,
        oninput: (e) => {
          const value = (e.target as HTMLTextAreaElement).value;
          if (!maxLength || value.length <= maxLength) {
            onChange(value);
          } else {
            onChange(value.slice(0, maxLength));
          }
        },
      }),
      h('div.textarea__border'),
      maxLength > 0 && showTextLength
        ? h('div.textarea__length', `${value.length}/${maxLength}`)
        : null,
    ]);
  }
}

export default (props: ITextAreaProps) => h(TextArea, props);
