import { h } from 'core';

import { getStore } from 'acadly/store';
import { pad, style } from 'acadly/styles';

export interface IBottomFloatingBarProps {
  text: string;
  color: string;
}
const isIpad = /iPad|iPhone/.test(navigator.userAgent);
export const BottomFloatingBar = ({ text, color }: IBottomFloatingBarProps) => {
  const isMobile = getStore().getState().app.isMobile;
  const actualBar = (hidden?: boolean) =>
    h(
      'div',
      style(
        [
          'fullWidth',
          pad('0.5rem 1rem'),
          'mainPanel',
          'borderBox',
          'flex',
          'alignCenter',
          'justifyCenter',
          'whiteBackground',
          hidden
            ? {
                visibility: 'hidden',
              }
            : isMobile || isIpad
            ? 'fixed'
            : 'absolute',
          {
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            color: color,
          },
        ],
        {},
        {
          tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
          'aria-label': text ? text : undefined,
        }
      ),
      [text]
    );

  return h('div', [actualBar(), actualBar(true)]);
};
