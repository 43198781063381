import { IPollState } from './IPollState';
/**
 * Higher order function that takes a map of poll ids and poll user data
 * and returns a function that converts an IResponsePoll to IPoll
 * by filling in missing fields and attaching user data
 */
export const responsePollToPoll = (
  userData: IResponsePollUserData | undefined,
  classId: string,
  p: IResponsePoll
): IPoll => {
  return {
    ...p,
    identifiers: {
      classId,
    },
    details: {
      ...p.details,
      attachments: p.details.attachments || [],
      title: p.details.title || '',
    },
    userData: userData
      ? {
          ...userData,
          numCommentsSeen: userData.numCommentsSeen || 0,
        }
      : undefined,
  };
};

export function updatePoll(
  state: IPollState,
  pollId: string,
  f: (poll: IPoll) => IPoll
): IPollState {
  const poll = state.byId[pollId];
  if (!poll) return state;
  return {
    ...state,
    byId: {
      ...state.byId,
      [pollId]: f(poll),
    },
  };
}
