import {
  addNewClassActivity,
  deleteClassActivity,
  pusherPublish as classPusherPublish,
  updateTotalClassActivitiesCount,
} from 'acadly/class/functions';
import { getCourseRole } from 'acadly/course/functions';
import { createReducer } from 'acadly/createReducer';
import * as datetime from 'acadly/datetime';
import { IRootState } from 'acadly/IRootState';
import * as utils from 'acadly/utils';

import * as actions from './actions';
import { responsePollToPoll, updatePoll } from './functions';
import { IPollState } from './IPollState';

export const reducer = createReducer({
  '@poll/CREATE_SUCCESS': createSuccess,
  '@poll/DELETE_SUCCESS': deleteSuccess,
  '@poll/EDIT_SUCCESS': editSuccess,
  '@poll/FETCH_REQUEST': fetchRequest,
  '@poll/FETCH_SUCCESS': fetchSuccess,
  '@poll/question/ADD_SUCCESS': questionAddSuccess,
  '@poll/question/EDIT_SUCCESS': questionEditSuccess,
  '@poll/PUBLISH_SUCCESS': publishSuccess,
  '@poll/STOP_SUCCESS': stopSuccess,
  '@poll/UPDATE_PUBLISHED_POLL_SUCCESS': updatePublishedPollSuccess,
  '@poll/CLEAR_DATA': clearData,
  '@poll/SUBMIT_SUCCESS': submitSuccess,
  '@poll/all/FETCH_SUCCESS': fetchAllSuccess,
  '@poll/pusher/PUBLISH': pusherPublish,
  '@poll/analytics/FETCH_SUCCESS': analyticsFetchSuccess,
  '@poll/pusher/SUBMIT': pusherSubmit,
});

export function pusherSubmit(state: IRootState, payload: actions.IPusherSubmitPayload): IRootState {
  const poll = state.polls.byId[payload.pollId];

  if (
    payload.sender.userId === state.getIn.session!.userId ||
    (poll && poll.details.deadlineFirst && getCourseRole(state) === 'student')
  ) {
    // ignore poll submit data if same student or poll results should appear post deadline
    return state;
  }

  const questionId = Object.keys(payload.submission)[0];
  const payloadSub = payload.submission[questionId];

  const student: IPollAnalyticsStudent = {
    name: payload.sender.name,
    avatar: payload.sender.avatar,
    userId: payload.sender.userId,
    stats: {
      firstAccessedOn: payload.timestamp,
      status: poll && payload.timestamp > poll.details.dueDateTime ? 'late' : 'submitted',
      submission: payload.submission,
      submittedOn: 0,
    },
  };
  const updatedAnalytics =
    state.polls.analytics && state.polls.analytics.pollId === payload.pollId
      ? {
          ...state.polls.analytics,
          students: [...state.polls.analytics.students, student],
        }
      : state.polls.analytics;
  const updatedPollSlice = {
    ...state.polls,
    analytics: updatedAnalytics,
    question:
      state.polls.question && state.polls.question._id === questionId
        ? {
            ...state.polls.question,
            stats: state.polls.question.stats
              ? {
                  numAttempted: state.polls.question.stats.numAttempted + 1,
                  optionSelection: utils.replaceWhere(
                    state.polls.question.stats.optionSelection ||
                      state.polls.question.details.options.map((o) => ({
                        num: o.num,
                        numSelected: 0,
                      })),
                    (o) => ({
                      ...o,
                      numSelected: o.numSelected + 1,
                    }),
                    (o) => o.num === payloadSub.optionSelected
                  ),
                }
              : {
                  numAttempted: 1,
                  optionSelection: state.polls.question.details.options.map((o) => ({
                    num: o.num,
                    numSelected: o.num === payloadSub.optionSelected ? 1 : 0,
                  })),
                },
          }
        : state.polls.question,
    byId: poll
      ? {
          ...state.polls.byId,
          [poll._id]: {
            ...poll,
            stats: poll.stats
              ? {
                  ...poll.stats,
                  numSubmitted: poll.stats.numSubmitted + 1,
                }
              : poll.stats,
          },
        }
      : state.polls.byId,
  };
  return {
    ...state,
    polls: updatedPollSlice,
  };
}

export function analyticsFetchSuccess(
  state: IRootState,
  payload: actions.IAnalyticsFetchPayload
): IRootState {
  return {
    ...state,
    polls: {
      ...state.polls,
      analytics: {
        students: payload.response.students,
        pollId: payload.pollId,
      },
    },
  };
}

export function pusherPublish(
  state: IRootState,
  payload: actions.IPusherPublishPayload
): IRootState {
  const poll: IPoll = {
    _id: payload.pollId,
    nodeType: 'poll',
    identifiers: {
      classId: payload.classId,
    },
    details: {
      ...payload.details,
      title: payload.details.title || '',
      attachments: payload.details.attachments || [],
    },
    stats: payload.stats,
    activities: payload.activities,
  };
  return classPusherPublish(state, 'polls', {
    senderId: payload.sender.userId,
    activity: poll,
  });
}

export function submitSuccess(
  state: IRootState,
  payload: actions.ISubmitSuccessPayload
): IRootState {
  const toBeDone = payload.toBeDone;
  const updatedCoursesSlice = state.courses.timeline
    ? utils.update(state.courses, {
        timeline: {
          items: utils.replaceWhere(
            state.courses.timeline.items,
            (c: IClass) =>
              utils.update(c, {
                userData: {
                  [toBeDone]: {
                    polls: {
                      numCompleted: c.userData[toBeDone].polls.numCompleted + 1,
                    },
                  },
                },
              }),
            (c) => c._id === payload.request.classId
          ),
        },
      })
    : state.courses;

  const poll = state.polls.byId[payload.request.pollId];
  const updatedPollSlice =
    state.polls.question && poll
      ? {
          ...state.polls,
          question: {
            ...state.polls.question,
            stats: payload.response.stats,
          },
          optionSelected: payload.request.submission[0].optionSelected,
          byId: {
            ...state.polls.byId,
            [payload.request.pollId]: {
              ...poll,
              userData: {
                firstAccessedOn: payload.response.submittedOn,
                timesStarted: 1,
                numCommentsSeen: 0,
                ...poll.userData,
                submittedOn: payload.response.submittedOn,
                status:
                  payload.response.submittedOn > poll.details.dueDateTime &&
                  poll.details.dueDateTime !== -1
                    ? ('late' as const)
                    : ('submitted' as const),
                subscribed: 0 as 0 | 1,
              },
              stats: poll.stats
                ? {
                    ...poll.stats,
                    numSubmitted: poll.stats.numSubmitted + 1,
                  }
                : {
                    numSubmitted: 1,
                  },
            },
          },
        }
      : state.polls;
  return {
    ...state,
    courses: updatedCoursesSlice,
    polls: updatedPollSlice,
  };
}

export function clearData(state: IRootState): IRootState {
  return {
    ...state,
    polls: {
      ...state.polls,
      question: null,
      optionSelected: null,
    },
  };
}

export function publishSuccess(
  state: IRootState,
  payload: actions.IPublishSuccessPayload
): IRootState {
  const courseId = state.courses.currentCourseId;
  const updatedCourseSlice =
    courseId && state.courses.timeline
      ? updateTotalClassActivitiesCount(state.courses, {
          activityType: 'polls',
          classId: payload.request.classId,
          toBeDone: payload.request.toBeDone,
          key: 'numPublished',
          num: 1,
        })
      : state.courses;
  const updatedPollSlice = updatePoll(state.polls, payload.request.pollId, (p) => ({
    ...p,
    details: {
      ...p.details,
      published: 1,
      dueDateTime: payload.response.dueDateTime,
      dueDateType: payload.response.dueDateType,
      publishedOn: payload.response.publishedOn,
      allowLate: payload.request.allowLate,
      deadlineFirst: payload.request.deadlineFirst,
      trueNum: payload.response.trueNum,
      num: payload.response.pollNum,
    },
  }));
  return {
    ...state,
    courses: updatedCourseSlice,
    polls: updatedPollSlice,
  };
}

export function stopSuccess(
  state: IRootState,
  payload: actions.IStopPollSuccessPayload
): IRootState {
  const updatedPollSlice = updatePoll(state.polls, payload.request.activityId, (q) => ({
    ...q,
    details: {
      ...q.details,
      dueDateTime: payload.response.dueDateTime,
    },
  }));
  return {
    ...state,
    polls: updatedPollSlice,
  };
}

export function updatePublishedPollSuccess(
  state: IRootState,
  payload: actions.IUpdatePublishedPollRequest
): IRootState {
  const updatedPollSlice = updatePoll(state.polls, payload.pollId, (p) => ({
    ...p,
    details: {
      ...p.details,
      title: payload.title,
    },
  }));
  const q: IPollQuestion | null = state.polls.question;
  if (q) {
    const updatedQuestion: IPollQuestion = {
      ...q,
      details: {
        ...q.details,
        description: payload.description || q.details.description,
        options: payload.options
          ? payload.options.map((o, idx) => ({
              ...o,
              num: idx + 1,
            }))
          : q.details.options,
      },
      stats: {
        ...q.stats,
        optionSelection: payload.options
          ? payload.options.map((_, i) => ({
              num: i + 1,
              numSelected: 0,
            }))
          : q.stats.optionSelection,
      },
    };
    return {
      ...state,
      polls: {
        ...updatedPollSlice,
        question: { ...updatedQuestion },
      },
    };
  }
  return state;
}

export function questionEditSuccess(
  state: IRootState,
  payload: actions.IQuestionEditSuccessPayload
): IRootState {
  if (!state.polls.question) return state;
  return {
    ...state,
    polls: {
      ...state.polls,
      question: {
        ...state.polls.question,
        details: {
          ...state.polls.question.details,
          description: payload.description,
          options: payload.options.map((o, i) => ({
            ...o,
            num: i + 1,
          })),
        },
        stats: {
          numAttempted: 0,
          ...state.polls.question.stats,
          optionSelection: payload.options.map((_, i) => ({
            num: i + 1,
            numSelected: 0,
          })),
        },
      },
    },
  };
}

export function fetchRequest(state: IRootState) {
  return {
    ...state,
    polls: {
      ...state.polls,
      areQuestionsEdited: false,
    },
  };
}

export function fetchSuccess(
  state: IRootState,
  payload: actions.IPollFetchSuccessPayload
): IRootState {
  let question: IPollState['question'] = null;

  if (payload.questionSet) {
    const emptyOptionSelection = payload.questions.details.options.map((o) => ({
      num: o.num,
      numSelected: 0,
    }));

    const stats: IPollQuestion['stats'] = payload.questions.stats
      ? {
          ...payload.questions.stats,
          optionSelection: payload.questions.stats.optionSelection || emptyOptionSelection,
        }
      : {
          numAttempted: 0,
          optionSelection: emptyOptionSelection,
        };

    question = {
      ...payload.questions,
      pollId: payload.pollId,
      details: {
        ...payload.questions.details,
        description: {
          ...payload.questions.details.description,
          attachments: payload.questions.details.description.attachments || [],
        },
      },
      stats: stats,
    };
  }

  const updatedPollSlice = payload.firstAccess
    ? updatePoll(state.polls, payload.pollId, (p) => ({
        ...p,
        userData: {
          numCommentsSeen: 0,
          ...p.userData,
          timesStarted: 1,
          firstAccessedOn: 0,
          status: 'inProgress' as const,
          subscribed: 0,
        },
      }))
    : updatePoll(state.polls, payload.pollId, (p) => ({
        ...p,
        userData: p.userData
          ? {
              ...p.userData,
              lastAccessedOn: datetime.unix(),
            }
          : p.userData,
      }));

  const submission =
    payload.questionSet && payload.submission ? payload.submission[payload.questions._id] : null;

  return {
    ...state,
    polls: {
      ...updatedPollSlice,
      publishDefaults: payload.publishDefaults || null,
      question: question,
      optionSelected: submission ? submission.optionSelected : null,
    },
  };
}

export function questionAddSuccess(
  state: IRootState,
  payload: actions.IQuestionAddPayload
): IRootState {
  const updatedPollSlice = updatePoll(state.polls, payload.request.activityId, (p) =>
    utils.update(p, {
      details: {
        questionSet: 1,
      },
    })
  );
  return {
    ...state,
    polls: {
      ...updatedPollSlice,
      question: payload.response,
      optionSelected: null,
    },
  };
}

export function editSuccess(
  state: IRootState,
  payload: actions.IEditPollSuccessPayload
): IRootState {
  return {
    ...state,
    polls: updatePoll(state.polls, payload.pollId, (p) =>
      utils.update(p, {
        details: {
          title: payload.title,
          isAnon: payload.isAnon,
        },
      })
    ),
  };
}

export function createSuccess(
  state: IRootState,
  payload: actions.ICreatePollSuccessPayload
): IRootState {
  return addNewClassActivity(state, {
    type: 'polls',
    classId: payload.classId,
    activity: payload.poll,
  });
}

export function deleteSuccess(
  state: IRootState,
  payload: actions.IDeletePollSuccessPayload
): IRootState {
  return deleteClassActivity(state, 'polls', {
    activityId: payload.pollId,
    classId: payload.classId,
    toBeDone: payload.toBeDone,
  });
}

export function fetchAllSuccess(
  state: IRootState,
  payload: actions.IFetchAllSuccessPayload
): IRootState {
  const polls = payload.activityData.map((p) =>
    responsePollToPoll(payload.userData.polls[p._id], p.identifiers.classId, p)
  );
  const pollsById = utils.makeObjectWithKey(polls, '_id');
  return {
    ...state,
    polls: {
      ...state.polls,
      byId: pollsById,
    },
  };
}
