import { h, IComponent, View } from 'core';

import { api as urls } from 'acadly/api';
import { api as url } from 'acadly/api';
import { Actions as appActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import MoveActivityDialog from 'acadly/class/MoveActivityDialog';
import ActionBar, { isIpad } from 'acadly/common/ActionBar';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Avatar from 'acadly/common/Avatar';
import ContentView from 'acadly/common/ContentView';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import TextField from 'acadly/common/TextField';
import Toggle from 'acadly/common/Toggle';
import CopyActivityDialog from 'acadly/course/CopyActivityDialog';
import { validateActivityPublish } from 'acadly/course/validations';
import * as dt from 'acadly/datetime';
import icons from 'acadly/icons';
import Editor from 'acadly/rich-text/Editor';
import Viewer from 'acadly/rich-text/Viewer';
import { goBack } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { colors, margin, mb, mt, pad, pt, style } from 'acadly/styles';
import * as u from 'acadly/utils';

import { Actions } from './actions';
import lodash = require('lodash');
import DiscussionPrefsDialog from './DiscussionPrefsDialog';

const PrefsWarning = (message: string | null | string[]) => {
  if (!message) return null;
  return h(
    'div',
    style(['orange', 'bold', pad('0.5rem 1rem'), margin('0.75rem 0')], {
      borderRadius: '4px',
      backgroundColor: `rgba(217, 89, 22, 0.1)`,
    }),
    message
  );
};

export interface IDiscussionProps {
  discussion: IDiscussion;
  cls: IClass;
  courseRole: ICourseRole;
  course: ICourse;
  userId: string;
  isClassIncharge: boolean;
}

export interface IDiscussionState {
  isEditingTitle: boolean;
  isEditDescriptionDialogOpen: boolean;
  editTitleField: string;
  editTitleError: boolean;
  editDescriptionField: string;
  editAttachmentsField: IAttachment[];

  notificationElement: HTMLElement | null;

  isMoveDialogOpen: boolean;
  isCopyDialogOpen: boolean;

  isDeleteDialogOpen: boolean;

  isPublishDialogOpen: boolean;
  isPublishPrefsDialogVisible: boolean;

  isSavingTitle: boolean;

  EditDiscussionTitle: string;
  EditDiscussionDescription: string;
  EditDiscussionAttachments: IAttachment[];
  EditDiscussionDialog: {
    isVisible: boolean;
    isConfirmationVisible: boolean;
    EditDiscussionAttachments: IAttachment[];
    isEditingTitle: boolean;
    isEditingDescription: boolean;
    EditDiscussionTitle: string;
    EditDiscussionTitleError: boolean;
    EditDiscussionDescriptionError: boolean;
    EditDiscussionDescription: string;
    toNotify: 0 | 1;
  };
}

export class Discussion extends IComponent<IDiscussionProps, IDiscussionState> {
  public componentWillMount() {
    const { discussion, cls, courseRole, isClassIncharge, course } = this.getProps();
    const initialState: IDiscussionState = {
      isEditDescriptionDialogOpen: false,
      isSavingTitle: false,
      isEditingTitle: false,
      editTitleField: discussion.details.title || '',
      editTitleError: false,
      editDescriptionField: discussion.details.description || '',
      editAttachmentsField: discussion.details.attachments || [],

      notificationElement: null,

      isMoveDialogOpen: false,

      isDeleteDialogOpen: false,

      isPublishDialogOpen: false,
      isPublishPrefsDialogVisible: false,

      isCopyDialogOpen: false,

      EditDiscussionTitle: discussion.details.title || '',
      EditDiscussionDescription: discussion.details.description || '',
      EditDiscussionAttachments: discussion.details.attachments || [],
      EditDiscussionDialog: {
        isVisible: false,
        isConfirmationVisible: false,
        isEditingTitle: false,
        EditDiscussionAttachments: discussion.details.attachments || [],
        isEditingDescription: false,
        EditDiscussionTitle: discussion.details.title || '',
        EditDiscussionTitleError: false,
        EditDiscussionDescriptionError: false,
        EditDiscussionDescription: discussion.details.description || '',
        toNotify: 1,
      },
    };

    if (isClassIncharge && !discussion.details.published && !course.isArchived) {
      dispatch(
        Actions.fetchPublishPrefs({
          classId: cls._id,
          discussionId: discussion._id,
        })
      );
    }

    this.setState(initialState).then(() => {
      if (courseRole === 'student') {
        dispatch(
          Actions.analyticsSet({
            request: {
              discussionId: discussion._id,
              firstAccess: 1,
              localTime: dt.format(dt.now(), 'YYYYMMDDTHH:mm'),
            },
            classId: cls._id,
            toBeDone: discussion.details.toBeDone,
          })
        );
      }
    });
    dispatch(appActions.setContext('discussion'));
    dispatch(appActions.startTip(true));
    setTimeout(() => {
      const actionbar = document.getElementById('action-bar');
      actionbar ? actionbar.focus() : null;
    }, 0);
  }

  private openEditDialog() {
    const { discussion } = this.getProps();
    this.setState({
      EditDiscussionTitle: discussion.details.title || '',
      EditDiscussionDialog: {
        isVisible: true,
        isConfirmationVisible: false,
        isEditingTitle: false,
        isEditingDescription: false,
        EditDiscussionTitleError: false,
        EditDiscussionDescriptionError: false,
        EditDiscussionTitle: discussion.details.title || '',
        EditDiscussionDescription: discussion.details.description || '',
        EditDiscussionAttachments: discussion.details.attachments || [],
        toNotify: 1,
      },
    });
  }

  private isAccessible: boolean | undefined = false;

  public render() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const isMobile = getStore().getState().app.isMobile;
    const { discussion } = this.getProps();
    const hasAttachments = discussion.details.attachments.length > 0;
    return h(
      u.getHTMLTagSelector('div', ['activity', this.isActionBarVisible() ? 'with-action-bar' : '']),
      [
        isMobile || isIpad ? this.actionBar() : null,
        ContentView(
          h('div.activity__content', [
            isMobile || isIpad ? null : this.actionBar(),
            this.classDetails(),
            this.isPreClassWarningVisible() ? this.preClassTimeWarning() : null,
            ...this.prefsWarnings(),
            this.isEditingEnabled() ? this.publishPrefsEditView() : null,
            this.title(),
            this.description(),
            hasAttachments
              ? h('div.activity__attachments', [
                  this.headings('Attachments'),
                  ...this.attachments(),
                ])
              : null,
            this.creator(),
            this.warnings(),

            this.editDescriptionDialog(),
            this.moveDialog(),
            this.deleteDialog(),
            this.copyDialog(),
            this.isEditingEnabled() || this.isPublishEnabled() ? this.publishPrefsDialog() : null,
            this.canEditDiscussion() ? this.editDiscussionDialog() : null,
            this.canEditDiscussion() ? this.editPollPublishDialog() : null,
          ])
        ),
      ]
    );
  }

  private lastFocusedElement: null | Element = null;
  private actionBar() {
    if (!this.isActionBarVisible()) return null;
    return ActionBar(
      [
        {
          label: 'Delete',
          classNames: ['red'],
          icon: {
            type: 'view',
            view: Icon(icons.trash),
          },
          disabled: !this.isDeleteEnabled(),
          onclick: () => {
            this.lastFocusedElement = document.activeElement;
            u.unsetTabIndices();
            this.setState({
              isDeleteDialogOpen: true,
            });
          },
        },
        {
          label: 'Copy',
          classNames: ['blue'],
          icon: {
            type: 'view',
            view: Icon(icons.copy),
          },
          disabled: !this.isCopyEnabled(),
          onclick: () => this.openCopyDialog(),
        },
        {
          label: 'Move',
          classNames: ['blue'],
          id: 'move-button',
          icon: {
            type: 'view',
            view: Icon(icons.move),
          },
          disabled: !this.isMoveEnabled(),
          onclick: () => this.moveHandler(),
        },
        {
          label: this.canEditDiscussion() ? 'Edit' : 'Publish',
          classNames: ['orange'],
          icon: {
            type: 'view',
            view: this.canEditDiscussion() ? Icon(icons.pencil) : Icon(icons.publish),
          },
          disabled: !(this.isPublishButtonEnabled() || this.canEditDiscussion()),
          onclick: () => (this.canEditDiscussion() ? this.openEditDialog() : this.publishHandler()),
        },
      ],
      this.isAccessible
    );
  }

  private headings(label: string) {
    return h('div.cell', [h('span.cell__label', label)]);
  }

  private isPublishButtonEnabled() {
    const { course, isClassIncharge, discussion } = this.getProps();
    return !course.isArchived && isClassIncharge && !discussion.details.published;
  }

  private canEditDiscussion() {
    const { isClassIncharge, discussion } = this.getProps();
    return isClassIncharge && discussion.details.published;
  }

  private classDetails() {
    const { discussion, cls } = this.getProps();
    const toBeDone = {
      preClass: 'Pre-class',
      inClass: 'In-class',
    }[discussion.details.toBeDone];
    return h(
      'div.cell',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        h('span.cell__label', [
          `${toBeDone} discussion for the class on `,
          dt.format(cls.details.scheStartTime, 'MMM DD, YYYY [at] hh:mm A'),
        ]),
      ]
    );
  }
  private preClassTimeWarning() {
    const cls = this.getProps().cls;
    const timeDiff = dt.format(cls.details.scheStartTime - 30 * 60, 'MMM DD, YYYY [at] hh:mm A');
    return h('div.cell', [
      h('span.cell__label', [
        `You must publish this activity by ${timeDiff}`,
        ' for students to attempt it before the class',
      ]),
    ]);
  }

  private isPreClassWarningVisible() {
    const cls = this.getProps().cls;
    const discussion = this.getProps().discussion;
    return (
      dt.diff(dt.fromUnix(cls.details.scheStartTime), dt.now(), 'minutes') < 60 &&
      discussion.details.toBeDone === 'preClass' &&
      discussion.details.published === 0
    );
  }

  private showPublishPrefsDialog(isPublishing: boolean) {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    this.setState({
      isPublishDialogOpen: isPublishing,
      isPublishPrefsDialogVisible: true,
    });
  }

  private closePublishPrefsDialog = () => {
    this.setState({
      isPublishDialogOpen: false,
      isPublishPrefsDialogVisible: false,
    });
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
  };

  private publishPrefsEditView() {
    const { discussion } = this.getProps();

    return h('div', [
      h(
        'div.panel.panel--inline',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => this.showPublishPrefsDialog(false),
        },
        [
          h('div.panel__header', [
            h('span', 'Publish preferences'),
            h('span.pointer.fs-sm.fc-light-grey', [h('i.fa.fa-pencil')]),
          ]),
        ]
      ),
      discussion.details.toBeDone === 'inClass'
        ? h('div.fc-orange', style([pad('0 1rem'), mt('-0.5rem'), mb('1rem')]), [
            `Saving "Publish preferences" right now helps you `,
            `save precious time during the lecture`,
          ])
        : null,
    ]);
  }

  private publishPrefsDialog() {
    const { cls, course, discussion } = this.getProps();
    const { isPublishPrefsDialogVisible, isPublishDialogOpen } = this.getState();
    if (!isPublishDialogOpen && !isPublishPrefsDialogVisible) return null;
    return DiscussionPrefsDialog({
      cls,
      course,
      discussion,
      isPublishing: isPublishDialogOpen,
      open: true,
      onPublish: this.closePublishPrefsDialog,
      onClose: this.closePublishPrefsDialog,
    });
  }

  private getHideAwardsWarningMessage(): null | string | string[] {
    const { courseRole, discussion } = this.getProps();
    const { anonymity, hideAwards, anonymize } = discussion.details;

    if (!hideAwards || courseRole === 'student') return null;

    if (!anonymize) {
      return 'Comment awards will be visible only to the course team and the comment author';
    }

    if (anonymity === 'students') {
      return 'Comment awards will be visible only to the course team';
    }

    return 'Comments cannot be awarded';
  }

  private getAnonymityWarningMessage(): null | string | string[] {
    const { courseRole, discussion } = this.getProps();
    const { anonymity, anonymize } = discussion.details;

    if (!anonymize) return null;

    const isStudent = courseRole === 'student';

    if (anonymity === 'students') {
      return isStudent
        ? [
            "Your fellow students won't be able to identify your posts, but the ",
            'course team members will still be able to see who contributed a particular post',
          ]
        : "Students won't be able to identify other student authors of posts";
    }

    return isStudent
      ? 'All the posts you make will be anonymous'
      : 'None of the course members will be able to identify the student authors of posts';
  }

  private prefsWarnings() {
    return [
      PrefsWarning(this.getAnonymityWarningMessage()),
      PrefsWarning(this.getHideAwardsWarningMessage()),
    ];
  }

  private editTitleDialog() {
    const { isEditingTitle, editTitleField, editTitleError } = this.getState();
    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Discussion Title',
        open: isEditingTitle,
        hasInput: true,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.getTabIndex(),
            type: 'secondary',
            onclick: () =>
              this.setState({
                isEditingTitle: false,
              }).then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-title');
                btn ? btn.focus() : null;
              }),
          }),
          FlatButton('Save', {
            tabIndex: this.getTabIndex(),
            onclick: () =>
              this.saveTitleHandler().then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-title');
                btn ? btn.focus() : null;
              }),
          }),
        ],
      },
      [
        h('div.textfield-container', style(['flex', 'alignCenter', pad('0.5rem')]), [
          TextField({
            value: editTitleField,
            focusOnMount: true,
            errorText: editTitleError ? 'Please enter a title' : undefined,
            placeholder: 'Enter title here',
            maxLength: 140,
            oninput: (e) =>
              this.setState({
                editTitleField: e.target.value,
                editTitleError: false,
              }),
            onenter: () => this.saveTitleHandler(),
          }),
        ]),
      ]
    );
  }

  private title() {
    const { discussion } = this.getProps();
    const NO_TITLE_MESSAGE = h('span.fc-light-grey', [
      "This discussion doesn't have a title yet. ",
      'Click on the ',
      h('i.fa.fa-pencil'),
      ' icon to add a title.',
    ]);

    if (this.isEditingEnabled()) {
      return h(
        'div.panel',
        {
          tabIndex: this.getTabIndex(),
        },
        [
          h('div.panel__header', [
            h('span', 'Title'),
            h('i.fa.fa-pencil#edit-title', {
              tabIndex: this.getTabIndex(),
              onclick: () => {
                u.unsetTabIndices();
                this.setState({
                  isEditingTitle: true,
                  editTitleField: discussion.details.title,
                });
              },
            }),
          ]),

          this.editTitleDialog(),
          h('div.panel__content', discussion.details.title || [NO_TITLE_MESSAGE]),
        ]
      );
    } else {
      return h(
        'div.activity__title',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `discussion title : ${discussion.details.title || `Untitled`}`,
          key: 'discussion-title',
        },
        discussion.details.title || 'Untitled'
      );
    }
  }

  private saveEditDiscussionTitle() {
    const title = this.getState().EditDiscussionDialog.EditDiscussionTitle;
    if (title.trim() === '') {
      this.setState({
        EditDiscussionDialog: {
          ...this.getState().EditDiscussionDialog,
          EditDiscussionTitleError: true,
        },
      });
      return;
    }
    this.setState({
      EditDiscussionTitle: title,
      EditDiscussionDialog: {
        ...this.getState().EditDiscussionDialog,
        EditDiscussionTitle: title,
        EditDiscussionTitleError: false,
        isEditingTitle: false,
      },
    });
  }

  private editDiscussionEditTitleDialog() {
    const dialog = this.getState().EditDiscussionDialog;
    if (!dialog) {
      return null;
    }
    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Discussion Title',
        open: dialog.isEditingTitle,
        hasInput: true,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.getTabIndex(),
            type: 'secondary',
            onclick: () =>
              this.setState({
                EditDiscussionDialog: {
                  ...this.getState().EditDiscussionDialog,
                  isEditingTitle: false,
                },
              }),
          }),
          FlatButton('Save', {
            tabIndex: this.getTabIndex(),
            onclick: () => this.saveEditDiscussionTitle(),
          }),
        ],
      },
      [
        h('div.textfield-container', style(['flex', 'alignCenter', pad('0.5rem')]), [
          TextField({
            value: dialog.EditDiscussionTitle,
            focusOnMount: true,
            errorText: dialog.EditDiscussionTitleError ? 'Please enter a title' : undefined,
            placeholder: 'Enter title here',
            maxLength: 140,
            oninput: (e) =>
              this.setState({
                EditDiscussionDialog: {
                  ...this.getState().EditDiscussionDialog,
                  EditDiscussionTitle: e.target.value,
                  EditDiscussionTitleError: false,
                },
              }),
            onenter: () => this.saveEditDiscussionTitle(),
          }),
        ]),
      ]
    );
  }

  private editDiscussionTitle() {
    const { EditDiscussionTitle } = this.getState();
    return h(
      'div.panel',
      {
        tabIndex: this.getTabIndex(),
      },
      [
        h('div.panel__header', [
          h('span', 'Title'),
          h('i.fa.fa-pencil#edit-title', {
            tabIndex: this.getTabIndex(),
            onclick: () =>
              this.setState({
                ...this.getState(),
                EditDiscussionDialog: {
                  ...this.getState().EditDiscussionDialog,
                  isEditingTitle: true,
                  EditDiscussionTitle: this.getState().EditDiscussionTitle,
                },
              }),
          }),
        ]),
        this.editDiscussionEditTitleDialog(),
        h(
          u.getHTMLTagSelector('div', [
            'panel__content',
            'fs-sm',
            EditDiscussionTitle ? '' : 'fc-light-grey',
          ]),
          EditDiscussionTitle || ''
        ),
      ]
    );
  }

  private editDiscussionDescription() {
    const { EditDiscussionDescription, EditDiscussionAttachments } =
      this.getState().EditDiscussionDialog;
    return h(
      'div.panel',
      {
        tabIndex: this.getTabIndex(),
      },
      [
        h('div.panel__header', [
          h('span', 'Description'),
          h('i.fa.fa-pencil', {
            tabIndex: this.getTabIndex(),
            onclick: () =>
              this.setState({
                EditDiscussionDialog: {
                  ...this.getState().EditDiscussionDialog,
                  isEditingDescription: true,
                },
              }),
          }),
        ]),
        h(
          u.getHTMLTagSelector('div', [
            'panel__content',
            'fs-sm',
            EditDiscussionDescription ? '' : 'fc-light-grey',
          ]),
          [
            Viewer(EditDiscussionDescription),
            EditDiscussionAttachments.length > 0
              ? h('div', style([mt('1rem')]), [
                  this.headings('Attachments'),
                  ...EditDiscussionAttachments.map((attachment) =>
                    AttachmentViewer({
                      key: attachment.name,
                      attachment: attachment,
                      hideDownloadIcon: true,
                      downloadUrl: urls().discussionFileDownload,
                      downloadRequest: {
                        discussionId: this.getProps().discussion._id,
                        fileName: attachment.name,
                      },
                      style: {
                        marginBottom: '0.5rem',
                      },
                    })
                  ),
                ])
              : null,
          ]
        ),
      ]
    );
  }

  private description() {
    const { discussion } = this.getProps();
    if (this.isEditingEnabled()) {
      const NO_DESCRIPTION_MESSAGE = [
        "Discussion doesn't have a description yet. " +
          'A good description can provide a helpful starting point.',
      ];
      return h(
        'div.panel',
        {
          tabIndex: this.getTabIndex(),
        },
        [
          h('div.panel__header', [
            h('span', 'Description'),
            h('i.fa.fa-pencil', {
              tabIndex: this.getTabIndex(),
              onclick: () => this.openEditDescriptionDialog(),
            }),
          ]),
          h(
            u.getHTMLTagSelector('div', [
              'panel__content',
              'fs-sm',
              discussion.details.description ? '' : 'fc-light-grey',
            ]),
            discussion.details.description
              ? [Viewer(discussion.details.description)]
              : NO_DESCRIPTION_MESSAGE
          ),
        ]
      );
    }
    return h(
      'div.activity__description',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `discussion Description: ${discussion.details.description}`,
      },
      [Viewer(discussion.details.description)]
    );
  }

  private attachments() {
    const { discussion } = this.getProps();
    return discussion.details.attachments.map((attachment) =>
      AttachmentViewer({
        key: attachment.name,
        attachment: attachment,
        downloadUrl: urls().discussionFileDownload,
        downloadRequest: {
          discussionId: discussion._id,
          fileName: attachment.name,
        },
        style: {
          marginBottom: '0.5rem',
        },
      })
    );
  }

  private creator() {
    const { discussion } = this.getProps();
    const creator = discussion.details.createdBy;
    const isPublished = discussion.details.published;
    const publishedOn = dt.format(
      isPublished ? discussion.details.publishedOn : discussion.details.createdOn,
      'MMM DD, YYYY'
    );
    return h('div.user', [
      Avatar(creator.avatar, creator.name, {
        className: 'user__avatar',
      }),
      h('div.user__details.fc-light-grey', [
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          `${isPublished ? 'Published' : 'Created'} by ${creator.name}`
        ),
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': `${isPublished ? `published on:` : `created on:`}${publishedOn}`,
          },
          publishedOn
        ),
      ]),
    ]);
  }

  private warning(text: string | View[]) {
    return h('div.activity__note', { tabIndex: this.isAccessible ? 0 : undefined }, [
      h('i.fa.fa-exclamation-triangle'),
      h('div', text),
    ]);
  }

  private warnings() {
    const { discussion } = this.getProps();
    const isNarrow = getStore().getState().app.narrowScreen;
    const isPublished = discussion.details.published;

    return h('div.activity__section', [
      isPublished
        ? isNarrow
          ? this.warning([
              'To post a comment to this discussion, or to read comments ' +
                'from other students, tap on the ',
              Icon(icons.comments),
              ' icon on the top right.',
            ])
          : null
        : this.warning([
            'The activity must be published by the class in-charge for ',
            'the students to be able to access it.',
          ]),
    ]);
  }

  private async saveTitleHandler() {
    const { editTitleField } = this.getState();
    const { discussion, cls } = this.getProps();
    if (!editTitleField) {
      await this.setState({
        editTitleError: true,
      });
      return;
    }

    await dispatch(
      Actions.edit({
        classId: cls._id,
        discussionId: discussion._id,
        title: editTitleField,
        description: discussion.details.description,
        attachments: discussion.details.attachments,
      })
    );
    await this.setState({
      isEditingTitle: false,
    });
  }

  private isActionBarVisible() {
    return (
      this.isDeleteEnabled() ||
      this.isPublishEnabled() ||
      this.isMoveEnabled() ||
      this.isCopyEnabled()
    );
  }

  private isPublishEnabled() {
    const { discussion, course, cls, isClassIncharge } = this.getProps();
    if (course.isArchived) return false;
    if (discussion.details.published) return false;
    if (!isClassIncharge) return false;
    if (!discussion.details.description.trim()) return false;
    if (!discussion.details.title.trim()) return false;
    if (discussion.details.toBeDone === 'preClass') {
      return dt.diff(dt.fromUnix(cls.details.scheStartTime), dt.now(), 'minutes') > 15;
    } else {
      return cls.details.status === 'inSession';
    }
  }

  private isDeleteEnabled() {
    return this.isEditingEnabled();
  }

  private isMoveEnabled() {
    const { course, discussion, isClassIncharge } = this.getProps();
    return !course.isArchived && !discussion.details.published && isClassIncharge;
  }

  private isCopyEnabled() {
    const { courseRole } = this.getProps();
    return courseRole === 'instructor' || courseRole === 'admin';
  }

  private getTabIndex() {
    return this.isAccessible ? 0 : undefined;
  }

  private deleteDialog() {
    return Alert(
      {
        open: this.getState().isDeleteDialogOpen,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('NO', {
            tabIndex: this.getTabIndex(),
            type: 'secondary',
            onclick: () =>
              this.setState({
                isDeleteDialogOpen: false,
              }).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              }),
          }),
          FlatButton('YES', {
            tabIndex: this.getTabIndex(),
            onclick: () => this.deleteDiscussion(),
          }),
        ],
      },
      ['Are you sure you want to delete this discussion?']
    );
  }

  private async deleteDiscussion() {
    const { cls, discussion } = this.getProps();
    await dispatch(
      Actions.deleteDiscussion(
        {
          classId: cls._id,
          discussionId: discussion._id,
          toBeDone: discussion.details.toBeDone,
        },
        goBack
      )
    );
    googleAnalytics.activityDeleted(
      'discussion',
      discussion.details.toBeDone === 'preClass' ? 'pre-class' : 'in-class'
    );
    await this.setState({
      isDeleteDialogOpen: false,
    });
  }

  private moveDialog() {
    if (!this.isMoveEnabled()) {
      return null;
    }
    return MoveActivityDialog({
      open: this.getState().isMoveDialogOpen,
      class: this.getProps().cls,
      activity: this.getProps().discussion,
      ondone: () => {
        u.resetTabIndices();
        const btn = document.getElementById('move-button');
        btn ? btn.focus() : null;
        this.setState({
          isMoveDialogOpen: false,
        });
      },
      oncancel: () => {
        u.resetTabIndices();
        const btn = document.getElementById('move-button');
        btn ? btn.focus() : null;
        this.setState({
          isMoveDialogOpen: false,
        });
      },
    });
  }

  private async updatePublishedDiscussion() {
    const data = {
      classId: this.getProps().cls._id,
      title: this.getState().EditDiscussionTitle,
      description: this.getState().EditDiscussionDescription,
      attachments: this.getState().EditDiscussionAttachments,
      discussionId: this.getProps().discussion._id,
      toNotify: this.getState().EditDiscussionDialog.toNotify,
    };
    await dispatch(Actions.updatePublished(data)).then(() => {
      this.setState({
        EditDiscussionDialog: {
          ...this.getState().EditDiscussionDialog,
          isVisible: false,
          isConfirmationVisible: false,
        },
      });
    });
  }

  private editDescriptionDialog() {
    const { editAttachmentsField, editDescriptionField } = this.getState();
    const body = [
      Editor({
        value: editDescriptionField,
        subContext: {
          preClass: 'pre-class',
          inClass: 'in-class',
        }[this.getProps().discussion.details.toBeDone] as any,
        title: 'Description',
        placeholder: 'Type description here',
        oninput: (value, attachments) =>
          this.setState({
            editDescriptionField: value,
            editAttachmentsField: attachments,
          }),
        enableTextFormatting: true,
        enableFileAttachments: true,
        enableImageInput: true,
        enableFormulaInput: true,
        attachments: {
          uploadUrl: url().discussionFileUpload,
          activityId: this.getProps().discussion._id,
          activityType: 'discussions',
          files: editAttachmentsField,
        },
      }),
    ];
    return Dialog(
      {
        open: this.getState().isEditDescriptionDialogOpen,
        title: 'Editing Description',
        style: {
          padding: '0',
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          padding: '0',
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.descriptionSaveHandler(),
        },
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => this.editBack(),
        },
      },
      body
    );
  }

  private editDiscussionDescriptionSaveHandler() {
    const { EditDiscussionDescription, EditDiscussionAttachments } =
      this.getState().EditDiscussionDialog;
    if (EditDiscussionDescription.trim() === '') {
      this.setState({
        EditDiscussionDialog: {
          ...this.getState().EditDiscussionDialog,
          EditDiscussionDescriptionError: true,
        },
      });
      return;
    }
    this.setState({
      EditDiscussionDescription: EditDiscussionDescription,
      EditDiscussionAttachments: EditDiscussionAttachments,
      EditDiscussionDialog: {
        ...this.getState().EditDiscussionDialog,
        isEditingDescription: false,
      },
    });
  }

  private editDiscussioneditDescriptionDialog() {
    const { EditDiscussionDescription, EditDiscussionAttachments, EditDiscussionDescriptionError } =
      this.getState().EditDiscussionDialog;

    const body = [
      Editor({
        value: EditDiscussionDescription,
        subContext: {
          preClass: 'pre-class',
          inClass: 'in-class',
        }[this.getProps().discussion.details.toBeDone] as any,
        title: 'Editing Description',
        placeholder: 'Type description here',
        errorText: EditDiscussionDescriptionError ? 'Description cannot be empty' : undefined,
        oninput: (value, attachments) =>
          this.setState({
            EditDiscussionDialog: {
              ...this.getState().EditDiscussionDialog,
              EditDiscussionDescription: value,
              EditDiscussionAttachments: attachments,
            },
          }),
        enableTextFormatting: true,
        enableFileAttachments: true,
        enableImageInput: true,
        enableFormulaInput: true,
        attachments: {
          uploadUrl: url().discussionFileUpload,
          activityId: this.getProps().discussion._id,
          activityType: 'discussions',
          files: EditDiscussionAttachments,
        },
      }),
    ];
    return Dialog(
      {
        open: this.getState().EditDiscussionDialog.isEditingDescription,
        title: 'Editing Description',
        style: {
          padding: '0',
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          padding: '0',
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.editDiscussionDescriptionSaveHandler(),
        },
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              EditDiscussionDialog: {
                ...this.getState().EditDiscussionDialog,
                isEditingDescription: false,
              },
            }),
        },
      },
      body
    );
  }

  private editPollPublishDialog() {
    return Alert(
      {
        open: this.getState().EditDiscussionDialog.isConfirmationVisible,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('NO', {
            tabIndex: this.getTabIndex(),
            type: 'secondary',
            onclick: () =>
              this.setState({
                EditDiscussionDialog: {
                  ...this.getState().EditDiscussionDialog,
                  isConfirmationVisible: false,
                },
              }),
          }),
          FlatButton('YES', {
            tabIndex: this.getTabIndex(),
            onclick: () => this.updatePublishedDiscussion(),
          }),
        ],
      },
      [
        h('div', [
          h('p', 'Are you sure you want to publish these changes?'),
          h('div', style(['flex', 'fullWidth', pt('1rem'), 'whiteBackground', 'spaceBetween']), [
            h('span', 'Send notifications to students'),
            Toggle({
              selected: this.getState().EditDiscussionDialog.toNotify === 1,
              ontoggle: () =>
                this.setState({
                  EditDiscussionDialog: {
                    ...this.getState().EditDiscussionDialog,
                    toNotify: this.getState().EditDiscussionDialog.toNotify === 0 ? 1 : 0,
                  },
                }),
            }),
          ]),
        ]),
      ]
    );
  }

  private isPublishDisabled() {
    const { discussion } = this.getProps();
    const state = this.getState();
    return (
      discussion.details.title === state.EditDiscussionTitle &&
      discussion.details.description === state.EditDiscussionDescription &&
      lodash.isEqual(discussion.details.attachments, state.EditDiscussionAttachments)
    );
  }

  private editDiscussionDialog() {
    // const {
    //     EditDiscussionDialog
    // } = this.getState();
    const body = [
      h('div.activity', style([pad('1rem')]), [
        this.editDiscussionTitle(),
        this.editDiscussionDescription(),
        this.canEditDiscussion() ? this.editDiscussioneditDescriptionDialog() : null,
      ]),
    ];
    return Dialog(
      {
        open: this.getState().EditDiscussionDialog.isVisible,
        title: 'Editing Discussion',
        style: {
          padding: '0',
          backgroundColor: colors.backgroundColor,
          maxWidth: '35rem',
        },
        bodyStyle: {
          padding: '0',
        },
        primaryAction: {
          label: 'UPDATE',
          disabled: this.isPublishDisabled(),
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () =>
            this.setState({
              EditDiscussionDialog: {
                ...this.getState().EditDiscussionDialog,
                isConfirmationVisible: true,
              },
            }),
        },
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              EditDiscussionDialog: {
                ...this.getState().EditDiscussionDialog,
                isVisible: false,
              },
            }),
        },
      },
      body
    );
  }

  private async editBack() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isEditDescriptionDialogOpen: false,
    });
  }

  private async descriptionSaveHandler() {
    const { editAttachmentsField, editDescriptionField } = this.getState();
    const { discussion, cls } = this.getProps();

    await dispatch(
      Actions.edit({
        classId: cls._id,
        discussionId: discussion._id,
        title: discussion.details.title,
        description: editDescriptionField,
        attachments: editAttachmentsField,
      })
    );
    await this.editBack();
  }

  private async openEditDescriptionDialog() {
    const { discussion } = this.getProps();
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isEditDescriptionDialogOpen: true,
      editDescriptionField: discussion.details.description || '',
      editAttachmentsField: discussion.details.attachments || [],
    });
  }

  private async publishHandler() {
    const { course, cls, discussion } = this.getProps();
    if (
      !validateActivityPublish({
        activityType: 'class',
        course,
        cls,
        activity: discussion,
      })
    ) {
      return;
    }

    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();

    await this.setState({
      isPublishDialogOpen: true,
    });
  }

  private async moveHandler() {
    u.unsetTabIndices();
    await this.setState({
      isMoveDialogOpen: true,
    });
  }

  private isEditingEnabled() {
    const { course, discussion } = this.getProps();
    return !course.isArchived && !discussion.details.published && this.isCreator();
  }

  private isCreator() {
    const { userId, discussion } = this.getProps();
    return userId === discussion.details.createdBy.userId;
  }

  private copyDialog() {
    const props = this.getProps();
    return CopyActivityDialog({
      open: this.getState().isCopyDialogOpen,
      classId: props.cls._id,
      activityId: props.discussion._id,
      activityType: 'discussions',
      onDone: () => this.closeCopyDialog(),
      onCancel: () => this.closeCopyDialog(),
    });
  }

  private async closeCopyDialog() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isCopyDialogOpen: false,
    });
  }

  private async openCopyDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isCopyDialogOpen: true,
    });
  }
}

export default (props: IDiscussionProps) => h(Discussion, props);
