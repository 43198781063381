import { IPipContainerState } from './IPipContainerState';

export const initialState: IPipContainerState = {
  classMediaPlayer: {
    type: 'video',
    show: false,
    courseId: '',
    classId: '',
    title: '',
    fileName: '',
    url: '',
    recordingId: '',
    embedTargetSelector: '',
  },
};
