import cn from 'classnames';

import { h, HTMLAttrs } from 'core';

import { getStore } from 'acadly/store';
import { colors, pad, style } from 'acadly/styles';

import RadioButton from './RadioButton';

interface IRadioCellProps extends HTMLAttrs {
  label: string;
  disabled?: boolean;
  isSelected: boolean;
  onClick: (isSelected: boolean) => void;
}

const RadioCell = ({ label, disabled, isSelected, onClick, ...attrs }: IRadioCellProps) => {
  const isAccessible = getStore().getState().app.acc.web.turnOff === 0;

  return h(
    'div',
    style(
      ['flex', 'whiteBackground', pad('0.5rem 0.875rem')],
      {
        tabIndex: isAccessible ? 0 : undefined,
        ...attrs,
      },
      {
        className: cn({ ripple: !disabled }),
        onclick: disabled ? undefined : () => onClick(isSelected),
      }
    ),
    [
      RadioButton({
        disabled,
        style: { marginRight: '1rem' },
        color: disabled ? colors.grey : undefined,
        selected: isSelected,
      }),
      h('span', label),
    ]
  );
};

export default RadioCell;
