import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/repeat';
import 'rxjs/add/operator/skipUntil';
import 'rxjs/add/operator/takeUntil';

import { Subject, Subscription } from 'rxjs';

import { h, IComponent } from 'core';

import * as CloseIcon from 'assets/close.svg';
import * as DoneIcon from 'assets/done.svg';

import Dialog from 'acadly/common/Dialog';
import SvgIcon from 'acadly/common/SvgIcon';
import { Actions as QuizActions } from 'acadly/quiz/actions';
import { dispatch } from 'acadly/store';
import { colors } from 'acadly/styles';
import { makeObjectWithKey } from 'acadly/utils';

import { QuestionOrder } from './api';
import DraggableQuestion, {
  DragEvent,
  DRAGGABLE_QUESTION_BOTTOM_MARGIN,
  DRAGGABLE_QUESTION_HEIGHT,
  QuestionDragUpdate,
} from './DraggableQuestion';

function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

interface ReorderQuestionsDialogProps {
  classId: string;
  isEditingQuiz: boolean;
  isOpen: boolean;
  onClose(questions: QuestionOrder[]): Promise<void> | void;
  quizId: string;
  questions: IQuizQuestion[];
}

interface ReorderQuestionsDialogState {
  contentHeight: number;
  /** question-id and question map */
  questionMap: Record<string, IQuizQuestion>;
}

class ReorderQuestionsDialog extends IComponent<
  ReorderQuestionsDialogProps,
  ReorderQuestionsDialogState
> {
  private questionIdOrder: string[] = [];

  private placeholderRef: HTMLDivElement;
  private quesitonRefMap: Record<string, HTMLDivElement> = {};

  private questionDrag$ = new Subject<QuestionDragUpdate>();
  private subscriptions: Subscription[] = [];

  private containerRef: HTMLDivElement | null = null;

  private init(props: ReorderQuestionsDialogProps) {
    this.questionIdOrder = props.questions.map((q) => q._id);

    const initialState: ReorderQuestionsDialogState = {
      contentHeight:
        (DRAGGABLE_QUESTION_HEIGHT + DRAGGABLE_QUESTION_BOTTOM_MARGIN) * props.questions.length,
      questionMap: makeObjectWithKey(props.questions, '_id'),
    };

    this.setState(initialState);
  }

  public componentWillMount() {
    this.init(this.getProps());
  }

  public componentWillReceiveProps(nextProps: ReorderQuestionsDialogProps) {
    const currProps = this.getProps();
    if (currProps.isOpen !== nextProps.isOpen || currProps.questions !== nextProps.questions) {
      this.init(nextProps);
    }
  }

  public componentDidMount() {
    const dragStart$ = this.questionDrag$.filter((e) => e.type === DragEvent.START);
    const dragEnd$ = this.questionDrag$.filter((e) => e.type === DragEvent.END);
    const drag$ = this.questionDrag$.skipUntil(dragStart$).takeUntil(dragEnd$).repeat();
    const moveHere$ = drag$.debounceTime(50).takeUntil(dragEnd$).repeat();

    const CELL_HEIGHT = DRAGGABLE_QUESTION_HEIGHT + DRAGGABLE_QUESTION_BOTTOM_MARGIN;

    this.subscriptions.push(
      dragStart$.subscribe(({ question }) => {
        const index = this.questionIdOrder.findIndex((id) => id === question._id);

        this.placeholderRef.style.top = `${CELL_HEIGHT * index}px`;
        this.placeholderRef.style.height = `${DRAGGABLE_QUESTION_HEIGHT}px`;
        this.placeholderRef.style.display = `flex`;
      })
    );

    this.subscriptions.push(
      dragEnd$.subscribe(({ question }) => {
        const index = this.questionIdOrder.findIndex((id) => id === question._id);
        const top = CELL_HEIGHT * index;

        // set new position
        this.quesitonRefMap[question._id].style.top = `${top}px`;

        // wait for animation to complete
        setTimeout(() => {
          this.quesitonRefMap[question._id].classList.remove('dragging');
          // remove placeholder
          this.placeholderRef.style.display = 'none';
        }, 300);
      })
    );

    this.subscriptions.push(
      moveHere$.subscribe(({ question }) => {
        let newIndex = -1;
        const oldIndex = this.questionIdOrder.findIndex((id) => id === question._id);

        const top =
          this.quesitonRefMap[question._id].getBoundingClientRect().top -
          this.containerRef.getBoundingClientRect().top;

        const { questions } = this.getProps();

        while (top > CELL_HEIGHT * (newIndex + 0.5) && newIndex < questions.length - 1) {
          newIndex++;
        }

        if (newIndex === oldIndex) return;

        if (newIndex === -1) {
          newIndex = 0;
        }

        arrayMove(this.questionIdOrder, oldIndex, newIndex);

        this.questionIdOrder.map((qId, index) => {
          if (index === newIndex) {
            this.placeholderRef.style.top = `${CELL_HEIGHT * newIndex}px`;
            return;
          }
          this.quesitonRefMap[qId].style.top = `${CELL_HEIGHT * index}px`;
        });
      })
    );
  }

  public componentWillUnmount() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private getSortedQuestion() {
    const { questionMap } = this.getState();
    return this.questionIdOrder.map((id) => questionMap[id]);
  }

  private handleClose = async () => {
    const { onClose, questions } = this.getProps();
    const questionsOrder = questions.map((question) => ({
      questionId: question._id,
      order: question.details.order,
    }));
    await onClose(questionsOrder);
  };

  private handleSave = async () => {
    const { classId, isEditingQuiz, onClose, quizId } = this.getProps();

    const questionsOrder = this.questionIdOrder.map((questionId, index) => ({
      questionId,
      order: index + 1,
    }));

    if (!isEditingQuiz) {
      await dispatch(
        QuizActions.reorderQuestion({
          classId,
          quizId,
          questionsOrder,
        })
      );
    }

    onClose(questionsOrder);
  };

  public render() {
    const { isOpen } = this.getProps();
    const { contentHeight } = this.getState();
    const questions = this.getSortedQuestion();

    return Dialog(
      {
        open: isOpen,
        title: 'Re-order questions',
        thinHeader: true,
        secondaryAction: {
          label: 'Cancel',
          mobileLabel: SvgIcon({ className: 'icon-24px', icon: CloseIcon }),
          onclick: this.handleClose,
        },
        primaryAction: {
          label: 'Save',
          mobileLabel: SvgIcon({ className: 'icon-24px', icon: DoneIcon }),
          onclick: this.handleSave,
        },
        style: {
          width: '40em',
          padding: '0em',
          backgroundColor: colors.backgroundColor,
        },
        bodyStyle: {
          height: Math.max(500, contentHeight),
        },
      },
      [
        h(
          'div',
          {
            ref: (el: HTMLDivElement) => {
              this.containerRef = el;
            },
            style: { height: contentHeight, position: 'relative' },
          },
          [
            h(
              'div.drop-area',
              {
                ref: (el: HTMLDivElement) => (this.placeholderRef = el),
              },
              'Move here'
            ),
            ...questions.map((question, index) =>
              DraggableQuestion({
                drag$: this.questionDrag$,
                getContainerRef: () => this.containerRef,
                index,
                onRef: (el) => (this.quesitonRefMap[question._id] = el),
                question,
              })
            ),
          ]
        ),
      ]
    );
  }
}

export default (props: ReorderQuestionsDialogProps) => h(ReorderQuestionsDialog, props);
