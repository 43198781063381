/**
 * Component for course info tab
 */

import { CSS, h, IComponent } from 'core';

import { api as urls } from 'acadly/api';
import { Actions as AppActions } from 'acadly/app/actions';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Avatar from 'acadly/common/Avatar';
import ContentView from 'acadly/common/ContentView';
import DatePicker from 'acadly/common/DatePicker';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import { Loader } from 'acadly/common/Loader';
import NotificationBar from 'acadly/common/NotificationBar';
import TextField from 'acadly/common/TextField';
import TimePicker from 'acadly/common/TimePicker';
import UploadButton from 'acadly/common/UploadButton';
import { Actions } from 'acadly/course/actions';
import EditBook from 'acadly/course/EditBook';
import EditLinkDialog from 'acadly/course/EditLinkDialog';
import courseService from 'acadly/course/service';
import * as datetime from 'acadly/datetime';
import icons from 'acadly/icons';
import Editor from 'acadly/rich-text/Editor';
import RichText from 'acadly/rich-text/Viewer';
import { dispatch, getStore } from 'acadly/store';
import { backgroundColor, colors, ml, style, tabHeight } from 'acadly/styles';
import { validateEmail } from 'acadly/utils';
import * as u from 'acadly/utils';

import { courseInfoFileUpload, IAddBookRequest } from './api';

export default () => h(Info);

export interface INewOfficeHoursDialogState {
  selectedDayIndex: number;
  startTimeHour: number;
  startTimeMinute: number;
  endTimeHour: number;
  endTimeMinute: number;
  venue: string;
  venueError: boolean;
  startTimeError: boolean;
  endTimeError: boolean;
}

export interface IInfoState {
  selectedDayIndex: number;
  startTimeHour: number;
  startTimeMinute: number;
  endTimeHour: number;
  endTimeMinute: number;
  venue: string;
  venueError: boolean;
  startTimeError: boolean;
  endTimeError: boolean;
  endTimeGreaterThanStartTimeError: boolean;
  description: string;
  isDescriptionEditDialogOpen: boolean;
  isAddOfficeHoursDialogOpen: boolean;
  isOfficeHoursEditingDialogOpen: boolean;
  isTeamEditDialogOpen: boolean;
  isAddInstructorDialogOpen: boolean;
  isAddTADialogOpen: boolean;
  isCourseReadingListEditDialogOpen: boolean;
  isRemoveBookDialogOpenFor: ICourseBook | null;
  isSaving: boolean;
  isRemoveMemberDialogOpen: boolean;
  addTeamMemberDialog: {
    email: string;
    error?: string;
    isSaving: boolean;
  };
  isRemovingMember: boolean;
  isEditingBook: ICourseBook | null;
  isEditBookDialogOpen: boolean;
  isDeleteLinkDialogOpenForId: string | null;

  isPublishInfoDialogVisible: boolean;
  notificationElement?: HTMLElement;

  isAddLinkDialogOpen: boolean;

  isUploadingFile: boolean;
  isEditCourseDatesDialogDataOpen: boolean;
  EditCourseDatesDialogData: null | {
    courseRole: string;
    course: {
      _id: string;
      endDate: number;
      startDate: number;
      newEndDate: number;
    };
  };
}

/**
 * Component for course info tab
 */
class Info extends IComponent<never, IInfoState> {
  private static days: IWeekDay[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  public componentWillMount() {
    const initialState: IInfoState = {
      selectedDayIndex: 0,
      startTimeHour: 10,
      startTimeMinute: 0,
      endTimeHour: 11,
      endTimeMinute: 0,
      venue: '',
      venueError: false,
      startTimeError: false,
      endTimeError: false,
      endTimeGreaterThanStartTimeError: false,
      description: '',
      isDescriptionEditDialogOpen: false,
      isAddOfficeHoursDialogOpen: false,
      isOfficeHoursEditingDialogOpen: false,
      isTeamEditDialogOpen: false,
      isCourseReadingListEditDialogOpen: false,
      isSaving: false,
      isAddInstructorDialogOpen: false,
      isAddTADialogOpen: false,
      isRemoveMemberDialogOpen: false,
      isRemoveBookDialogOpenFor: null,
      addTeamMemberDialog: {
        email: '',
        error: undefined,
        isSaving: false,
      },
      isPublishInfoDialogVisible: false,
      isRemovingMember: false,

      isEditBookDialogOpen: false,
      isEditingBook: null,

      isAddLinkDialogOpen: false,
      isDeleteLinkDialogOpenForId: null,

      isUploadingFile: false,
      EditCourseDatesDialogData: null,
      isEditCourseDatesDialogDataOpen: false,
    };
    this.setState(initialState)
      .then(() => {
        dispatch(Actions.fetchCourseInfo());
      })
      .then(async () => {
        const dialogProps = this.getEditDatesDialogProps();
        if (dialogProps !== null) {
          await this.setState({
            EditCourseDatesDialogData: dialogProps,
          });
        }
        const tabs = document.getElementById('sliding-tabs');
        if (tabs) {
          tabs.focus();
        }
      });
  }
  public componentDidMount() {
    const tabs = document.getElementById('sliding-tabs');
    if (tabs) {
      tabs.focus();
    }
  }

  private isAccessible: boolean | undefined = false;

  public getEditDatesDialogProps() {
    const role = courseService.getRole();
    const course = courseService.getCurrentCourse();
    if (role && course && course.dates) {
      return {
        courseRole: role,
        course: {
          _id: course._id,
          newEndDate: course.dates.endDate,
          endDate: course.dates.endDate,
          startDate: course.dates.startDate,
        },
      };
    } else {
      return null;
    }
  }

  public render() {
    const storeState = getStore().getState();
    const info = storeState.courses.info;
    this.isAccessible = storeState.app.acc.web.turnOff === 0 ? true : false;
    if (info !== undefined) {
      return h('div.info', [
        this.getNotificationBar(),
        ContentView(
          h('div.info__wrapper', [
            !this.getNotification() ? h('div.info__tab-padding') : null,
            this.courseTitle(),
            this.courseScheduleSection(),
            this.descriptionSection(),
            this.editDescriptionDialog(),
            this.teamSection(),
            this.officeHoursSection(),
            this.readingListCard(),
            this.editTeamDialogNew(),
            this.addTADialog(),
            this.addInstructorDialog(),
            this.removeMemberDialog(),
            this.editReadingListDialog(),
            this.editOfficeHoursDialog(),
            this.extendCourseDialog(),
            this.addOfficeHoursDialog(),
          ])
        ),
      ]);
    } else {
      return h('div', 'Loading...');
    }
  }

  private tabHeight() {
    return tabHeight();
  }

  private courseTitle() {
    const course = courseService.getCurrentCourse();

    if (course === undefined) return null;
    return h(
      'div.info__title',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `Course Title: ${course.details.title}`,
      },
      course.details.title
    );
  }

  private addButton(label: string, onclick?: () => void, id?: string) {
    return h(
      u.getHTMLTagSelector('div', ['info__add-button', 'ripple'], id),
      {
        key: label,
        onclick: () => {
          this.lastFocusedElementSpare = document.activeElement;
          u.unsetTabIndices();
          onclick && onclick();
        },
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [h('span', label), Icon(icons.plus, { className: 'fc-blue info__add-button__action' })]
    );
  }

  private editTeamDialogNew() {
    const admin = this.getAdmin();
    const userId = getStore().getState().getIn.session!.userId;

    const renderNameAndStatus = (name: string, status: string) =>
      h('div.info__team-member__details', [
        h('span', name),
        h('span.info__team-member__status', [status]),
      ]);

    const renderTeamMember = (m: ICourseTeamMember) =>
      h(
        'div.info__team-member.cell',
        {
          key: m.userId,
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          Avatar(m.avatar, m.name, { className: 'info__avatar' }),
          renderNameAndStatus(m.name, `${u.capitalize(m.status)}`),
          Icon(icons.cross, {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': `remove ${m.name} from ${m.role}s`,
            key: `team-member-editable-${m.userId}`,
            className: 'info__team-member__icon ripple',
            onclick: async () => {
              if (m.role !== 'admin' && m.role !== 'student') {
                await this.showRemoveMemberDialog(m.userId, m.role);
              }
            },
          }),
        ]
      );

    const header = (label: string) => h('div.info__card-content', label);

    return Dialog(
      {
        style: {
          backgroundColor: colors.backgroundColor,
        },
        open: this.getState().isTeamEditDialogOpen,
        title: 'Editing Course Team',
        secondaryAction: {
          label: 'DONE',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: async () => {
            u.resetTabIndices();
            (this.lastFocusedElement as HTMLElement).focus();
            this.closeDialogs();
          },
        },
      },
      [
        header('Course Admin'),
        h(
          'div.info__team-member.cell',
          {
            key: admin.userId,
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            Avatar(admin.avatar, admin.name, { className: 'info__avatar' }),
            renderNameAndStatus(
              userId === admin.userId ? 'You' : admin.name,
              u.capitalize(admin.status)
            ),
          ]
        ),

        header('Course Instructors'),
        ...(this.getInstructors().length > 0 ? this.getInstructors().map(renderTeamMember) : []),
        this.addButton('Add course Instructor', () => this.openAddInstructorDialog()),

        header('Teaching Assistants'),
        ...this.getTAs().map(renderTeamMember),
        this.addButton('Add course TA', () => this.openAddTADialog()),
      ]
    );
  }

  private editDescriptionDialog() {
    return Dialog(
      {
        open: this.getState().isDescriptionEditDialogOpen,
        title: 'Editing Description',
        primaryAction: {
          label: 'DONE',
          mobileLabel: h('i.fa.fa-check', []),
          disabled: this.getState().description.length < 1,
          onclick: () => this.saveDescriptionHandler(),
        },
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-arrow-left', []),
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElement as any).focus();
            this.setState({
              isDescriptionEditDialogOpen: false,
            });
          },
        },
      },
      [
        Editor({
          value: this.getState().description,
          subContext: 'description',
          oninput: (newValue) => this.typeDescriptionHandler(newValue),
          title: 'Description',
          placeholder: 'Type course description here',
          enableTextFormatting: true,
          enableFormulaInput: true,
          enableImageInput: true,
          style: {
            marginTop: '0.5em',
          },
        }),
      ]
    );
  }

  public teamSection() {
    const admin = this.getAdmin();
    const currentUser = getStore().getState().getIn.session!.userId;
    const roleMap = {
      admin: 'Admin',
      ta: 'TA',
      instructor: 'Instructor',
      student: 'Student',
    };

    const TeamMember = (m: ICourseTeamMember) =>
      h(
        'div.info__team-member',
        {
          key: m.userId,
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `Course Team Member ${m.name}, ${m.role}`,
        },
        [
          Avatar(m.avatar, m.name, {
            className: 'info__avatar',
          }),
          h('div.info__team-member__details', [
            h('span', currentUser === m.userId ? 'You' : m.name),
            h('span.info__team-member__status', [
              `Course ${roleMap[m.role]} - ${u.capitalize(m.status)}`,
            ]),
          ]),
        ]
      );

    return h(
      'div.info__card',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `Course Team Section :
            ${1 + this.getInstructors().length + this.getTAs().length} members`,
      },
      [
        h('div.info__card-header.course-team-header', [
          h('span', 'Course Team'),
          this.editEnabled()
            ? h('i.fa.fa-pencil', {
                tabIndex: this.isAccessible ? 0 : undefined,
                'aria-label': `Edit team`,
                role: 'button',
                onclick: () => this.editTeamHandler(),
              })
            : null,
        ]),
        h('div.info__card-content.team-members', [
          TeamMember(admin),
          ...this.getInstructors().map(TeamMember),
          ...this.getTAs().map(TeamMember),
        ]),
      ]
    );
  }

  /**
   * Sorts office hours according to week day
   */
  private sortOfficeHours(officeHour: IOfficeHour[]) {
    officeHour.sort((a, b) => {
      const dayA = Info.days.indexOf(a.day);
      const dayB = Info.days.indexOf(b.day);

      return dayA - dayB;
    });

    return officeHour;
  }

  public officeHoursSection() {
    const info = getStore().getState().courses.info;
    const officeHours = this.sortOfficeHours(info.officeHours);
    const officeHourView = (oh: IOfficeHour) =>
      h(
        'div.info__office-hours',
        {
          key: `office-hour-${oh.day}-${oh.startTime}-${oh.endTime}`,
          tabIndex: this.isAccessible ? 0 : undefined,
          'Aria-label': `${this.completeDay(oh.day)} ,
                from ${this.renderOfficeHoursViewTime(
                  oh.startTime
                )} to ${this.renderOfficeHoursViewTime(oh.endTime)} at ${oh.venue}`,
        },
        [
          h('div', [
            h('div', [
              `${this.completeDay(oh.day)} ,  ` +
                `${this.renderOfficeHoursViewTime(oh.startTime)} - ${this.renderOfficeHoursViewTime(
                  oh.endTime
                )}`,
            ]),
            h('div.info__office-hours__venue', `Venue: ${oh.venue}`),
          ]),
        ]
      );

    return h(
      'div.info__card',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': 'Office Hours Section',
      },
      [
        h('div.info__card-header.office-hour-header', [
          h('span', 'Office Hours'),
          this.editEnabled()
            ? h('i.fa.fa-pencil', {
                tabIndex: this.isAccessible ? 0 : undefined,
                'aria-label': 'Edit Office Hours',
                role: 'button',
                onclick: () => {
                  this.lastFocusedElement = document.activeElement;
                  u.unsetTabIndices();
                  this.setState({
                    isOfficeHoursEditingDialogOpen: true,
                  });
                },
              })
            : null,
        ]),

        officeHours.length === 0
          ? h(
              'div.info__card-content.fc-light-grey',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                'aria-label': this.editEnabled()
                  ? 'Edit the section to convey the weekly office hours to the ' + 'students.'
                  : 'No Office Hours added to the course yet.',
              },
              [
                this.editEnabled()
                  ? 'Edit the section to convey the weekly office hours to the ' + 'students.'
                  : 'No Office Hours added to the course yet.',
              ]
            )
          : h('div.info__card-content', officeHours.map(officeHourView)),
      ]
    );
  }

  private extendCourseDialog() {
    const vm = this.getState().EditCourseDatesDialogData;
    if (!vm) {
      return null;
    }
    return Dialog(
      {
        open: this.getState().isEditCourseDatesDialogDataOpen,
        style: {
          backgroundColor: colors.backgroundColor,
        },
        title: 'Editing course dates',
        thinHeader: true,
        primaryAction: {
          label: 'Update',
          disabled:
            datetime.format(vm.course.newEndDate, 'YYYY-MM-DD') ===
            datetime.format(vm.course.endDate, 'YYYY-MM-DD'),
          mobileLabel: h('i.fa.fa-check'),
          onclick: async () => {
            await dispatch(
              Actions.setEndDate(datetime.format(vm.course.newEndDate, 'YYYY-MM-DD'))
            ).then(async () => {
              const dialogProps = Object.assign({}, this.getEditDatesDialogProps());
              dialogProps.course.endDate = dialogProps.course.newEndDate;
              if (dialogProps !== null) {
                await this.setState({
                  EditCourseDatesDialogData: dialogProps,
                  isEditCourseDatesDialogDataOpen: false,
                });
                u.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              }
            });
          },
        },
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-arrow-left'),
          onclick: async () => {
            const dialogProps = this.getEditDatesDialogProps();
            if (dialogProps !== null) {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              await this.setState({
                EditCourseDatesDialogData: dialogProps,
                isEditCourseDatesDialogDataOpen: false,
              });
            }
          },
        },
      },
      [
        h('div.info__extend-dialog__cell.read-only', [
          h('span', 'Start Date'),
          h('span.fc-blue', [datetime.format(vm.course.startDate, 'MMM Do YYYY')]),
        ]),
        h(
          'div.info__extend-dialog__cell',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': `Course end Date currently is
                ${datetime.fromUnix(vm.course.newEndDate)}`,
            role: 'button',
          },
          [
            h('span', 'End Date'),
            vm.course.endDate
              ? DatePicker({
                  value: datetime.fromUnix(vm.course.newEndDate),
                  minDate:
                    new Date() > datetime.fromUnix(vm.course.endDate)
                      ? new Date()
                      : datetime.fromUnix(vm.course.endDate),
                  focusedDate:
                    new Date() > datetime.fromUnix(vm.course.newEndDate)
                      ? new Date()
                      : datetime.fromUnix(vm.course.newEndDate),
                  icon: Icon(icons.calendar),
                  style: {
                    color: colors.blue,
                  },
                  onclick: () => {
                    this.lastFocusedElementSpare = document.activeElement;
                  },
                  onchange: (newDate) => {
                    const data = Object.assign({}, this.getState().EditCourseDatesDialogData);
                    data.course.newEndDate = datetime.toUnix(newDate);
                    this.setState({
                      EditCourseDatesDialogData: data,
                    });
                    (this.lastFocusedElementSpare as HTMLElement).focus();
                  },
                })
              : Loader({
                  width: '1em',
                  height: '1em',
                  borderWidth: '3%',
                }),
          ]
        ),
        h('div.info__extend-dialog__note', [
          h('i.fa.fa-exclamation-triangle'),
          h(
            'div',
            "You won't be able to add classes, activities, announcements etc" +
              'after the course end date; it is advised that you keep a buffer of ' +
              'a few days after the last class date.'
          ),
        ]),
      ]
    );
  }

  private courseScheduleSection() {
    const vm = this.getState().EditCourseDatesDialogData;
    if (vm === undefined || vm === null || !vm.course.endDate || !vm.course.startDate) {
      return null;
    } else {
      // if (vm.courseRole !== "admin") {
      //     return h("div.info__date", [
      //         datetime.format(vm.course.startDate, "MMM Do YYYY"),
      //             "-",
      //         datetime.format(vm.course.endDate, "MMM Do YYYY")
      //     ]);
      // }
      return h(
        'div.info__card',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `Course Dates Section, this course is scheduled from
                    ${datetime.format(vm.course.startDate, 'MMM Do YYYY')} to
                    ${datetime.format(vm.course.endDate, 'MMM Do YYYY')}`,
        },
        [
          h('div.info__card-header.description-header', [
            h('span', 'Course Dates'),
            vm.courseRole === 'admin'
              ? h('i.fa.fa-pencil', {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  'aria-label': `Edit Course dates`,
                  role: 'button',
                  onclick: async () => {
                    const course = courseService.getCurrentCourse();

                    if (course && course.courseType === 'synced') {
                      dispatch(
                        AppActions.showError({
                          message: 'Dates cannot be changed in a synced course',
                        })
                      );
                      return;
                    }

                    this.lastFocusedElement = document.activeElement;
                    u.unsetTabIndices();
                    await this.setState({
                      isEditCourseDatesDialogDataOpen: true,
                    });
                  },
                })
              : null,
          ]),
          h('div.info__card-content', [
            datetime.format(vm.course.startDate, 'MMM Do YYYY'),
            '-',
            datetime.format(vm.course.endDate, 'MMM Do YYYY'),
          ]),
        ]
      );
    }
  }

  private descriptionSection() {
    const info = this.getInfo();

    if (info === undefined) return null;
    return h(
      'div.info__card',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': 'Course Description Section',
      },
      [
        h('div.info__card-header.description-header', [
          h('span', 'Description'),
          this.editEnabled()
            ? h('i.fa.fa-pencil', {
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () => {
                  this.lastFocusedElement = document.activeElement;
                  u.unsetTabIndices();
                  this.openEditDescriptionDialog();
                },
              })
            : null,
        ]),
        h('div.info__card-content.fc-light-grey', [
          info.description.length > 0
            ? RichText(info.description, {
                tabIndex: this.isAccessible ? 0 : undefined,
                ariaLabel: 'Course description: ' + info.description,
              })
            : h(
                'div.fc-light-grey',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                },
                [
                  "This course doesn't have a description yet.",
                  ...(this.editEnabled()
                    ? [
                        ' Click on the ',
                        h('i.fa.fa-pencil'),
                        ' icon to add a rich text description.',
                      ]
                    : []),
                ]
              ),
        ]),
      ]
    );
  }

  private lastFocusedElement: Element | null = null;
  private lastFocusedElementSpare: Element | null = null;
  private lastFocusedElementSpare2: Element | null = null;

  private async openEditDescriptionDialog() {
    const info = this.getInfo();
    await this.setState({
      isDescriptionEditDialogOpen: true,
      description: info.description,
    });
  }

  public async publishInfo() {
    await dispatch(Actions.publishInfo());
  }

  public getNotification() {
    const course = this.getCourse();
    if (courseService.getRole(course._id) !== 'admin') {
      return null;
    } else if (course.status.infoPublished === 0) {
      return {
        tabIndex: this.isAccessible ? 0 : undefined,
        actionText: 'OKAY',
        action: () => this.publishInfo(),
        message: `Use this section to manage your course team and share essential info`,
      };
    } else {
      return null;
    }
  }

  public getNotificationBar() {
    const notification = this.getNotification();
    if (notification === null) {
      return null;
    } else {
      return NotificationBar(
        {
          action: notification.action,
          tabIndex: notification.tabIndex,
          actionText: notification.actionText,
          actionStyle: {
            width: '10em',
          },
          containerStyle: {
            overflowY: 'scroll',
            marginTop: this.tabHeight(),
          },
        },
        [notification.message]
      );
    }
  }

  private editReadingListDialog() {
    const bookView = (b: ICourseBook) => {
      const key = `book-${b._id}`;
      return h(
        'div.info__book.cell.ripple',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          key: key,
          onclick: () => this.openEditBookDialog(b),
        },
        [
          h('div.info__book__details', [
            h('div.book-title', b.title),
            h('div.info__book__author', `Author - ${b.author}`),
            b.isbn.length > 0 ? h('div.info__book__isbn', `ISBN - ${b.isbn}`) : null,
          ]),
          h(
            u.getHTMLTagSelector('div', [
              'info__book__remarks',
              b.recommended === 1 ? 'fc-green' : 'fc-light-grey',
            ]),
            [b.recommended === 1 ? 'Required' : 'Suggested']
          ),
          h('i.fa.fa-pencil', { tabIndex: this.isAccessible ? 0 : undefined }),
        ]
      );
    };

    const linkView = (l: ICourseLink) =>
      h(
        'div.info__link.cell',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          key: `link-cell-${l._id}`,
        },
        [
          h('div', [h('div.link-title', l.title), h('div.info__link__url', l.url)]),
          Icon(icons.cross, {
            tabIndex: this.isAccessible ? 0 : undefined,
            className: 'info__link__icon ripple',
            onclick: () => this.openDeleteLinkDialog(l._id),
          }),
        ]
      );

    const heading = (label: string) => h('div.info__reading-list__heading', label);

    const state = this.getState();
    const isUploadingFile = state.isUploadingFile;

    return Dialog(
      {
        style: {
          backgroundColor: colors.backgroundColor,
        },
        open: this.getState().isCourseReadingListEditDialogOpen,
        title: 'Editing Course Reading List',
        secondaryAction: {
          label: 'DONE',
          mobileLabel: h('i.fa.fa-arrow-left', []),
          onclick: () => {
            this.closeDialogs();
            u.resetTabIndices();
            (this.lastFocusedElement as HTMLElement).focus();
          },
        },
      },
      [
        heading('Course Textbooks'),
        ...this.getInfo().readingList.books.map(bookView),
        this.addButton('Add new book', () => this.openAddBookDialog(), 'add-new-book'),

        heading('Links'),
        ...this.getInfo().readingList.links.map(linkView),
        this.addButton('Add new link', () => this.openAddLinkDialog(), 'add-new-link'),

        heading('Files'),
        this.filesEditView(),
        UploadButton({
          ariaLabel: 'Upload a new File Button',
          view: h(
            'div',
            isUploadingFile
              ? 'Uploading...'
              : this.addButton('Add new File', undefined, 'upload-new-file')
          ),
          upload: (file: File) => {
            const { progress$, promise } = courseInfoFileUpload(file);
            return {
              progress$,
              promise: promise.then((attachment) => {
                dispatch(Actions.uploadInfoFileSuccess(attachment));
                u.unsetTabIndices(document.getElementById('dialog-box'));
                const btn = document.getElementById('upload-new-file');
                btn ? btn.focus() : null;
              }),
            };
          },
        }),

        this.removeBookDialog(),
        this.editBookDialog(),
        this.addLinkDialog(),
        this.deleteLinkDialog(),
      ]
    );
  }

  private async openDeleteLinkDialog(linkId: string) {
    this.lastFocusedElementSpare = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isDeleteLinkDialogOpenForId: linkId,
    });
  }

  private deleteLinkDialog() {
    const state = this.getState();
    const link = this.getInfo().readingList.links.find(
      (l) => l._id === state.isDeleteLinkDialogOpenForId
    );
    return Alert(
      {
        open: state.isDeleteLinkDialogOpenForId !== null,
        title: h('div.fc-red', 'Removing a link'),
        actions: [
          FlatButton('CANCEL', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.setState({
                isDeleteLinkDialogOpenForId: null,
              }).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElementSpare as HTMLElement).focus();
              }),
          }),
          FlatButton('REMOVE', {
            tabIndex: this.isAccessible ? 0 : undefined,
            classNames: ['fc-red'],
            onclick: () =>
              this.removeLink(state.isDeleteLinkDialogOpenForId).then(() => {
                u.resetTabIndices();
                (document.getElementById('add-new-link') as HTMLElement).focus();
              }),
          }),
        ],
      },
      link
        ? [
            h(
              'p.fc-light-grey',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              link.title
            ),

            h(
              'p',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              'Are you sure you want to remove this link?'
            ),

            h(
              'p',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              "You won't be able to undo this action."
            ),
          ]
        : []
    );
  }

  private async removeLink(linkId: string | null) {
    if (linkId !== null) {
      await dispatch(
        Actions.linkRemove({
          linkId,
        })
      );
      await this.setState({
        isDeleteLinkDialogOpenForId: null,
      });
    }
  }

  private async openAddLinkDialog() {
    this.lastFocusedElementSpare = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isAddLinkDialogOpen: true,
    });
  }

  private addLinkDialog() {
    const state = this.getState();

    return EditLinkDialog({
      open: state.isAddLinkDialogOpen,
      close: () =>
        this.setState({
          isAddLinkDialogOpen: false,
        }).then(() => {
          u.resetTabIndices(document.getElementById('dialog-box'));
          (this.lastFocusedElementSpare as HTMLElement).focus();
        }),
    });
  }

  private filesEditView() {
    const readingList = this.getInfo().readingList;
    if (!readingList.files || !readingList.files.length) return null;
    return h(
      'div.info__reading-list__file-view',
      readingList.files.map((file) =>
        AttachmentViewer({
          style: {
            width: '100%',
            paddingRight: '0px',
            marginBottom: '0.5rem',
          },
          attachment: file,
          key: file.name,
          disabled: true,
          downloadUrl: urls().courseInfoFileDownload,
          downloadRequest: {
            fileName: file.name,
          },
          hideDownloadIcon: true,
          actionButton: Icon(icons.cross, {
            className: 'info__reading-list__delete ripple',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.removeInfoFile(file._id).then(() => {
                const btn = document.getElementById('upload-new-file');
                btn ? btn.focus() : null;
              }),
          }),
        })
      )
    );
  }

  private filesView() {
    const readingList = this.getInfo().readingList;
    return readingList.files.map((file) =>
      AttachmentViewer({
        style: { margin: '0.5rem 0', width: 'initial' },
        attachment: file,
        key: file.name,
        downloadUrl: urls().courseInfoFileDownload,
        downloadRequest: {
          fileName: file.name,
        },
      })
    );
  }

  private readingListCard() {
    const subHeading = (label: string) => h('div.fc-grey', label);

    const info = this.getInfo();
    const links = info.readingList.links;
    const files = info.readingList.files;

    const renderLink = (link: ICourseLink) =>
      h(
        'a.info__link',
        {
          key: `link-${link._id}`,
          tabIndex: this.isAccessible ? 0 : undefined,
          role: 'listitem',
          href: u.convertLinkToURL(link.url),
          target: '_blank',
        },
        [h('div.link-info', [h('div', link.title), h('div.info__link__url', link.url)])]
      );

    const renderBook = (book: ICourseBook) =>
      h(
        'div.info__book',
        {
          key: book._id,
          tabIndex: this.isAccessible ? 0 : undefined,
          role: 'listitem',
          'aria-label': `${book.title}, this book is tagged
            ${book.recommended === 1 ? `Required` : `suggested`}`,
        },
        [
          h('div.info__book__details', [
            h('div', book.title),
            h('div.info__book__author', book.author),
            book.isbn.length > 0 ? h('div.info__book__isbn', book.isbn) : null,
          ]),
          h('div.info__book__remarks', [
            book.recommended === 1
              ? h('div.fc-green', 'Required')
              : h('div.fc-light-grey', 'Suggested'),
          ]),
        ]
      );

    return h(
      'div.info__card',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': 'Reading List Section',
      },
      [
        h('div.info__card-header', [
          h('span', 'Reading List'),
          this.editEnabled()
            ? h('i.fa.fa-pencil', {
                tabIndex: this.isAccessible ? 0 : undefined,
                'Aria-label': 'Edit Reading List',
                role: 'button',
                onclick: () => this.openReadingListEditDialog(),
              })
            : null,
        ]),
        // course books
        h('div.info__card-content', [
          h(
            'div',
            {
              tabIndex: this.isAccessible ? 0 : undefined,
              'aria-label': `Course Textbooks`,
              role: 'list',
            },
            [
              subHeading('Course Textbooks'),
              ...(this.getInfo().readingList.books.length > 0
                ? this.getInfo().readingList.books.map(renderBook)
                : [
                    h(
                      'div',
                      {
                        tabIndex: this.isAccessible ? 0 : undefined,
                        role: 'listitem',
                      },
                      [
                        this.editEnabled()
                          ? h(
                              'p.fc-light-grey',
                              `
                                Edit the Reading List to specify the book
                                names that your students should follow for the course.`
                            )
                          : h('p.fc-light-grey', ['No books have been added to the course yet']),
                      ]
                    ),
                  ]),
            ]
          ),
          h(
            'div',
            {
              tabIndex: this.isAccessible ? 0 : undefined,
              'aria-label': 'Course Links',
              role: 'list',
            },
            [
              subHeading('Links'),
              links.length > 0
                ? h('div.info__links', links.map(renderLink))
                : h(
                    'div.info__list-item',
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                      role: 'listitem',
                    },
                    ['No links have been added to the course yet.']
                  ),
            ]
          ),
          h(
            'div',
            {
              tabIndex: this.isAccessible ? 0 : undefined,
              'aria-label': 'Course Files',
              role: 'list',
            },
            [
              subHeading('Files'),
              files.length > 0
                ? h('div.info__links', this.filesView())
                : h(
                    'div.info__list-item',
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                      role: 'listitem',
                    },
                    ['No files have been added to the course yet.']
                  ),
            ]
          ),
        ]),
      ]
    );
  }

  private editBookDialog() {
    const state = this.getState();
    const open = state.isEditBookDialogOpen;
    const book = state.isEditingBook;

    return EditBook({
      open: open,
      oncancel: () =>
        this.setState({
          isEditBookDialogOpen: false,
          isEditingBook: null,
        }).then(() => {
          u.resetTabIndices();
          (this.lastFocusedElementSpare as HTMLElement).focus();
        }),
      book: book !== null ? book : undefined,
      onsave: async (_book) => {
        if (book !== null) {
          await this.editBook(book._id, {
            ..._book,
            parent: 'course',
          });
        } else {
          await this.addBook({
            ..._book,
            parent: 'course',
          });
        }

        await this.setState({
          isEditingBook: null,
          isEditBookDialogOpen: false,
        }).then(() => {
          u.resetTabIndices();
          (this.lastFocusedElementSpare as HTMLElement).focus();
        });
      },
      ondelete: book
        ? () => {
            this.lastFocusedElementSpare2 = document.activeElement;
            u.unsetTabIndices();
            this.setState({
              isRemoveBookDialogOpenFor: book,
            });
          }
        : undefined,
    });
  }

  private async addBook(book: IAddBookRequest) {
    await dispatch(Actions.addCourseBook(book));
    await this.setState({
      isEditingBook: null,
      isEditBookDialogOpen: false,
    });
  }

  public async editBook(id: string, book: IAddBookRequest) {
    await dispatch(
      Actions.editCourseBook({
        title: book.title,
        isbn: book.isbn,
        recommended: book.recommended,
        author: book.author,
        _id: id,
      })
    );
    await this.setState({
      isEditingBook: null,
      isEditBookDialogOpen: false,
    });
  }

  public dayPicker(days: IWeekDay[]) {
    const state = this.getState();

    return [
      h('div.info__day-picker__label', [
        h('span', 'Student can visit the office every'),
        h('span.fc-blue', u.capitalize(days[state.selectedDayIndex])),
      ]),
      h(
        'div.info__day-picker',
        days.map((day, i) =>
          h(
            u.getHTMLTagSelector('span', [
              'info__day-picker__option',
              i === this.getState().selectedDayIndex ? 'selected' : '',
            ]),
            {
              key: day,
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () =>
                this.setState({
                  selectedDayIndex: i,
                }),
            },
            day
          )
        )
      ),
    ];
  }

  public addOfficeHoursDialog() {
    if (!this.editEnabled()) return null;

    const state = this.getState();
    const days = Info.days;

    const timePickerStyle: CSS | undefined = style(['blue', ml('auto')]).style;
    const timePickerWarningStyle: CSS | undefined = style(['orange', ml('auto')]).style;
    const timePickerErrorStyle: CSS | undefined = style(['red', ml('auto')]).style;

    const inconvenientTimeWarning = h(
      'div.info__office-hours__inconvenient',
      'Warning: This timing seems a bit inconvenient'
    );

    const endTimeGreaterWarning = h(
      'div.info__office-hours__inconvenient',
      'Warning: Start time is greater than end time'
    );

    return Dialog(
      {
        open: this.getState().isAddOfficeHoursDialogOpen,
        title: 'Adding Office Hours',
        secondaryAction: {
          label: 'Cancel',
          mobileLabel: h('i.fa.fa-times', []),
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElementSpare as HTMLElement).focus();
            this.setState({
              isAddOfficeHoursDialogOpen: false,
            });
          },
        },
        primaryAction: {
          label: 'Done',
          disabled: state.venue.trim().length < 1,
          mobileLabel: h('i.fa.fa-check', []),
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            this.validateOfficeHours();
            this.saveOfficeHoursHandler().then(() => {
              u.resetTabIndices();
              (this.lastFocusedElementSpare as HTMLElement).focus();
            });
          },
        },
      },
      [
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          this.dayPicker(days)
        ),
        h('div.info__office-hours__cell-wrapper', [
          h(
            'div.info__office-hours__cell.no-border',
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            },
            [
              h('span', 'From'),
              TimePicker({
                style: this.isStartTimeInconvenient() ? timePickerWarningStyle : timePickerStyle,
                time: {
                  hours: state.startTimeHour,
                  minutes: state.startTimeMinute,
                },
                onclick: () => {
                  this.lastFocusedElementSpare2 = document.activeElement;
                  u.unsetTabIndices();
                },
                onChange: (time) => {
                  this.setState({
                    startTimeHour: time.hours,
                    startTimeMinute: time.minutes,
                  }).then(() => {
                    u.resetTabIndices();
                    (this.lastFocusedElementSpare2 as HTMLElement).focus();
                  });
                },
              }),
            ]
          ),

          this.isStartTimeInconvenient() ? inconvenientTimeWarning : null,
        ]),
        h('div.info__office-hours__cell-wrapper', [
          h(
            'div.info__office-hours__cell.no-border',
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            },
            [
              h('span', 'Till'),
              TimePicker({
                style: this.isEndTimeGreater()
                  ? timePickerErrorStyle
                  : this.isEndTimeInconvenient()
                  ? timePickerWarningStyle
                  : timePickerStyle,
                time: {
                  hours: state.endTimeHour,
                  minutes: state.endTimeMinute,
                },
                onclick: () => {
                  this.lastFocusedElementSpare2 = document.activeElement;
                  u.unsetTabIndices();
                },
                onChange: (time) => {
                  this.setState({
                    endTimeHour: time.hours,
                    endTimeMinute: time.minutes,
                  }).then(() => {
                    u.resetTabIndices();
                    (this.lastFocusedElementSpare2 as HTMLElement).focus();
                  });
                },
              }),
            ]
          ),
          this.isEndTimeGreater()
            ? endTimeGreaterWarning
            : this.isEndTimeInconvenient()
            ? inconvenientTimeWarning
            : null,
        ]),
        h(
          'div.info__office-hours__venue-wrapper',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h('span,info__office-hours__venue-label', 'Venue'),
            TextField({
              value: this.getState().venue,
              maxLength: 140,
              noHintOrError: true,
              errorText: this.getState().venueError ? 'Please fill this field' : undefined,
              oninput: (event) => this.venueInputHandler(event.target.value),
              placeholder: 'Type venue here',
              style: {
                marginLeft: '1rem',
                flex: 3,
                color: colors.grey,
                textAlign: 'right',
              },
              inputStyle: {
                fontSize: '1em',
              },
              onenter: () => {
                this.validateOfficeHours();
                this.saveOfficeHoursHandler();
              },
            }),
            Icon(icons.location, { className: 'info__office-hours__venue-marker' }),
          ]
        ),
        Alert(
          {
            open: state.endTimeGreaterThanStartTimeError,
            overlayStyle: {
              backgroundColor: colors.overlayOrange,
            },
            actions: [
              FlatButton('Ok', {
                onclick: () =>
                  this.setState({
                    endTimeGreaterThanStartTimeError: false,
                  }),
              }),
            ],
          },
          [
            state.endTimeGreaterThanStartTimeError
              ? h('div', 'The end time should be greater than the start time.')
              : null,
          ]
        ),
      ]
    );
  }
  private removeBookDialog() {
    const book = this.getState().isRemoveBookDialogOpenFor;
    return Alert(
      {
        open: !!book,
        overlayStyle: { backgroundColor: colors.overlayOrange },
        actions: book
          ? [
              FlatButton('No', {
                type: 'secondary',
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () =>
                  this.setState({ isRemoveBookDialogOpenFor: null }).then(() => {
                    u.resetTabIndices();
                    (this.lastFocusedElementSpare2 as HTMLElement).focus();
                  }),
              }),
              FlatButton('Yes', {
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () =>
                  this.removeBook(book._id).then(() => {
                    u.resetTabIndices();
                    (document.getElementById('add-new-book') as HTMLElement).focus();
                  }),
              }),
            ]
          : [],
      },
      book ? [`Are you sure you want to delete the book ${book.title} `, `by ${book.author}`] : []
    );
  }

  private async removeBook(bookId: string) {
    await dispatch(Actions.removeBook(bookId));
    await this.setState({
      isRemoveBookDialogOpenFor: null,
      isEditBookDialogOpen: false,
    });
  }

  private async openReadingListEditDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isCourseReadingListEditDialogOpen: true,
    });
  }

  public async openEditBookDialog(book: ICourseBook) {
    this.lastFocusedElementSpare = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isEditBookDialogOpen: true,
      isEditingBook: book,
    });
  }

  public async openAddBookDialog() {
    this.lastFocusedElementSpare = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isEditBookDialogOpen: true,
      isEditingBook: null,
    });
  }

  private removeMemberId?: string;
  public removeMemberDialog() {
    const userId = this.removeMemberId!;

    return Alert(
      {
        open: this.getState().isRemoveMemberDialogOpen,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('No', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => this.hideRemoveMemberDialog(),
          }),
          FlatButton('Yes', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.removeTeamMember(userId),
          }),
        ],
      },
      ['Are you sure you want to delete this team member?']
    );
  }

  public removeMemberRole: 'instructor' | 'ta' = 'ta';
  public async showRemoveMemberDialog(userId: string, role: 'instructor' | 'ta') {
    this.removeMemberId = userId;
    this.removeMemberRole = role;
    this.lastFocusedElementSpare2 = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isRemoveMemberDialogOpen: true,
    });
  }

  public async hideRemoveMemberDialog() {
    this.removeMemberId = undefined;
    u.resetTabIndices(document.getElementById('dialog-box'));
    (this.lastFocusedElementSpare2 as HTMLElement).focus();
    await this.setState({
      isRemoveMemberDialogOpen: false,
    });
  }

  public async removeTeamMember(userId: string) {
    await this.setState({
      isRemovingMember: true,
    });
    await dispatch(Actions.removeTeamMember(userId, this.removeMemberRole));
    this.removeMemberId = undefined;
    await this.setState({
      isRemoveMemberDialogOpen: false,
      isRemovingMember: false,
    }).then(() => {
      u.resetTabIndices(document.getElementById('dialog-box'));
      // (this.lastFocusedElementSpare2 as HTMLElement).focus();
    });
  }

  private async removeInfoFile(fileId: string) {
    await dispatch(Actions.removeInfoFile(fileId));
  }

  public async saveDescriptionHandler() {
    this.setState({ isSaving: true });
    await dispatch(Actions.setCourseDesciption(this.getState().description));
    this.setState({
      isSaving: false,
      isDescriptionEditDialogOpen: false,
    }).then(() => {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    });
  }

  public async editTeamHandler() {
    const course = courseService.getCurrentCourse();

    if (course && course.courseType === 'synced') {
      dispatch(
        AppActions.showError({
          message: 'Teams cannot be edited in a synced course',
        })
      );
      return;
    }

    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isTeamEditDialogOpen: true,
    });
  }

  public async editOfficeHoursHandler() {
    await this.setState({
      isAddOfficeHoursDialogOpen: true,
    });
  }

  public async closeDialogs() {
    await this.setState({
      isCourseReadingListEditDialogOpen: false,
      isRemoveBookDialogOpenFor: null,
      isAddOfficeHoursDialogOpen: false,
      isDescriptionEditDialogOpen: false,
      isTeamEditDialogOpen: false,
      isAddTADialogOpen: false,
      isAddInstructorDialogOpen: false,
      isPublishInfoDialogVisible: false,
      isEditBookDialogOpen: false,
      isEditingBook: null,
      isOfficeHoursEditingDialogOpen: false,
    });
  }

  public async typeDescriptionHandler(description: string) {
    await this.setState({
      description,
    });
  }

  public async editDescriptionHandler() {
    const info = getStore().getState().courses.info!;
    await this.setState({
      isDescriptionEditDialogOpen: true,
      description: info.description,
    });
  }

  public isInfoUnpublished() {
    const currentCourse = courseService.getCurrentCourse()!;

    return currentCourse.status.infoPublished === 0;
  }

  public getAdmin() {
    const course = courseService.getCurrentCourse()!;

    return course.team.filter((member) => member.role === 'admin')[0];
  }

  private getCourse() {
    const storeState = getStore().getState();

    return storeState.courses.courses[storeState.courses.currentCourseId!]!;
  }

  public getInstructors() {
    return this.getCourse().team.filter((member) => member.role === 'instructor');
  }

  public getTAs() {
    return this.getCourse().team.filter((member) => member.role === 'ta');
  }

  public getInfo() {
    return getStore().getState().courses.info;
  }

  public editEnabled() {
    const role = courseService.getRole(courseService.getCurrentCourseId()!);
    if (this.getCourse().isArchived === 1) {
      return false;
    }
    if (role === 'admin') {
      const timeline = getStore().getState().courses.timeline;
      if (timeline === undefined) {
        return false;
      }

      return (
        this.getCourse().status.schedulePublished === 0 ||
        timeline.courseDates.endDate > datetime.unix()
      );
    } else {
      return false;
    }
  }

  // public addTeamMemberButtonNew(type: "instructor" | "ta") {
  //     const text = {
  //         instructor: "Add course instructor",
  //         ta: "Add teaching assistant"
  //     }[type];
  //     const onclick = {
  //         instructor: () => this.openAddInstructorDialog(),
  //         ta: () => this.openAddTADialog()
  //     }[type];
  //     return this.addButtonNew(text, onclick);
  // }

  // private addButtonNew(text: string, onclick: () => {}) {
  //     return h("div", "flex", [
  //         h("div ", `${text}`),
  //         h("i.fa.fa-plus", {
  //             style: {
  //                 marginLeft: "auto",
  //                 marginRight: "auto",
  //                 color: colors.blue
  //             },
  //             onclick: onclick
  //         })
  //     ]);
  // }

  public addTeamMemberDialog(role: 'instructor' | 'ta') {
    const memberTypeToTitle = {
      instructor: 'Adding a new instructor',
      ta: 'Adding a new teaching assistant',
    };

    const isOpen = {
      instructor: this.getState().isAddInstructorDialogOpen,
      ta: this.getState().isAddTADialogOpen,
    }[role];
    const dialogIsOpenProperty = {
      instructor: 'isAddInstructorDialogOpen',
      ta: 'isAddTADialogOpen',
    }[role];
    const backAction = () =>
      this.setState({
        ...this.getState(),
        [dialogIsOpenProperty]: false,
      });

    const emailOnChange = (text: string) =>
      this.setState({
        addTeamMemberDialog: {
          ...this.getState().addTeamMemberDialog,
          email: text,
          error: undefined,
        },
      });
    const invite = async () => {
      const error = !this.getState().addTeamMemberDialog.email.length
        ? 'This field is required'
        : !validateEmail(this.getState().addTeamMemberDialog.email)
        ? 'Please enter a valid email'
        : undefined;
      if (error) {
        await this.setState({
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            error,
          },
        });
      } else {
        await this.setState({
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            isSaving: true,
          },
        });
        await dispatch(Actions.addTeamMember(this.getState().addTeamMemberDialog.email, role));
        await this.setState({
          ...this.getState(),
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            isSaving: false,
          },
          [dialogIsOpenProperty]: false,
        });
      }
    };
    return Alert(
      {
        open: isOpen,
        actions: [
          FlatButton('Cancel', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => {
              u.resetTabIndices(document.getElementById('dialog-box'));
              (this.lastFocusedElementSpare as HTMLElement).focus();
              backAction();
            },
          }),
          FlatButton('Invite', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: invite,
            type: 'primary',
          }),
        ],
        title: memberTypeToTitle[role],
      },
      [
        TextField({
          placeholder: 'Email ID',
          value: this.getState().addTeamMemberDialog.email,
          errorText: this.getState().addTeamMemberDialog.error,
          floatingLabelText: 'Email ID',
          oninput: (event) => emailOnChange(event.target.value),
          onenter: invite,
        }),
      ]
    );
  }

  public addInstructorDialog() {
    const role = 'instructor';
    const dialogIsOpenProperty = 'isAddInstructorDialogOpen';
    const backAction = () =>
      this.setState({
        ...this.getState(),
        [dialogIsOpenProperty]: false,
      });

    const emailOnChange = (text: string) =>
      this.setState({
        addTeamMemberDialog: {
          ...this.getState().addTeamMemberDialog,
          email: text,
          error: undefined,
        },
      });
    const invite = async () => {
      const error = !this.getState().addTeamMemberDialog.email.length
        ? 'This field is required'
        : !validateEmail(this.getState().addTeamMemberDialog.email)
        ? 'Please enter a valid email'
        : undefined;
      if (error) {
        await this.setState({
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            error,
          },
        });
      } else {
        await this.setState({
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            isSaving: true,
          },
        });
        await dispatch(Actions.addTeamMember(this.getState().addTeamMemberDialog.email, role));
        await this.setState({
          ...this.getState(),
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            isSaving: false,
          },
          [dialogIsOpenProperty]: false,
        }).then(() => {
          u.resetTabIndices(document.getElementById('dialog-box'));
          (this.lastFocusedElementSpare as HTMLElement).focus();
        });
      }
    };
    return Alert(
      {
        open: this.getState().isAddInstructorDialogOpen,
        style: {
          maxWidth: '25em',
        },
        actions: [
          FlatButton('CANCEL', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices(document.getElementById('dialog-box'));
              (this.lastFocusedElementSpare as HTMLElement).focus();
              backAction();
            },
          }),
          FlatButton('ADD', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: invite,
          }),
        ],
      },
      [
        h('div.info__add-member__title', 'Add Course Instructor'),
        h('div.info__add-member__label', 'Instructor email address'),
        TextField({
          placeholder: 'Type Here',
          value: this.getState().addTeamMemberDialog.email.toLowerCase(),
          errorText: this.getState().addTeamMemberDialog.error,
          oninput: (event) => emailOnChange(event.target.value),
          onenter: invite,
        }),
        h('p.info__add-member__note', 'Instructor must have a valid institute email address'),
      ]
    );
  }

  public addTADialog() {
    const role = 'ta';
    const dialogIsOpenProperty = 'isAddTADialogOpen';
    const backAction = () =>
      this.setState({
        ...this.getState(),
        [dialogIsOpenProperty]: false,
      });

    const emailOnChange = (text: string) =>
      this.setState({
        addTeamMemberDialog: {
          ...this.getState().addTeamMemberDialog,
          email: text,
          error: undefined,
        },
      });
    const invite = async () => {
      const error = !this.getState().addTeamMemberDialog.email.length
        ? 'This field is required'
        : !validateEmail(this.getState().addTeamMemberDialog.email)
        ? 'Please enter a valid email'
        : undefined;
      if (error) {
        await this.setState({
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            error,
          },
        });
      } else {
        await this.setState({
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            isSaving: true,
          },
        });
        await dispatch(Actions.addTeamMember(this.getState().addTeamMemberDialog.email, role));
        await this.setState({
          ...this.getState(),
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            isSaving: false,
          },
          [dialogIsOpenProperty]: false,
        }).then(() => {
          u.resetTabIndices(document.getElementById('dialog-box'));
          (this.lastFocusedElementSpare as HTMLElement).focus();
        });
      }
    };
    return Alert(
      {
        open: this.getState().isAddTADialogOpen,
        style: {
          boxSizing: 'border-box',
          maxWidth: '25em',
        },
        actions: [
          FlatButton('CANCEL', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices(document.getElementById('dialog-box'));
              (this.lastFocusedElementSpare as HTMLElement).focus();
              backAction();
            },
          }),
          FlatButton('ADD', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: invite,
          }),
        ],
      },
      [
        h('div.info__add-member__title', 'Add Course TA'),
        h('div.info__add-member__label', 'TA email address'),
        TextField({
          placeholder: 'Type Here',
          value: this.getState().addTeamMemberDialog.email.toLowerCase(),
          errorText: this.getState().addTeamMemberDialog.error,
          oninput: (event) => emailOnChange(event.target.value),
          onenter: invite,
        }),
        h('p.info__add-member__note', 'The TA must have a valid institute email address'),
      ]
    );
  }

  public async openAddInstructorDialog() {
    await this.setState({
      isAddInstructorDialogOpen: true,
      addTeamMemberDialog: this.emptyAddTeamMemberDialogState(),
    });
  }

  public async openAddTADialog() {
    await this.setState({
      isAddTADialogOpen: true,
      addTeamMemberDialog: this.emptyAddTeamMemberDialogState(),
    });
  }

  public emptyAddTeamMemberDialogState() {
    return {
      email: '',
      error: undefined,
      isSaving: false,
    };
  }

  private editOfficeHoursDialog() {
    const heading = (label: string) => h('div.info__card-content', label);

    const info = getStore().getState().courses.info;
    const officeHours = this.sortOfficeHours(info.officeHours);

    const officeHourView = (oh: IOfficeHour) =>
      h(
        'div.info__office-hours__cell',
        {
          key: `office-hour-${oh.day}-${oh.startTime}-${oh.endTime}`,
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          h('div', [
            h('div', [
              `${this.completeDay(oh.day)} ,  ` +
                `${this.renderOfficeHoursViewTime(oh.startTime)} - ${this.renderOfficeHoursViewTime(
                  oh.endTime
                )}`,
            ]),
            h('div.info__office-hours__venue', `Venue: ${oh.venue}`),
          ]),
          Icon(icons.cross, {
            className: 'info__office-hours__delete ripple',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.deleteOfficeHourHandler(oh),
          }),
        ]
      );

    return Dialog(
      {
        open: this.getState().isOfficeHoursEditingDialogOpen,
        title: 'Editing Office Hours',
        style: { backgroundColor },
        secondaryAction: {
          label: 'Done',
          mobileLabel: h('i.fa.fa-arrow-left', []),
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElement as HTMLElement).focus();
            this.closeDialogs();
          },
        },
        // primaryAction: {
        //     label: "Done",
        //     disabled: state.venue.trim().length < 1,
        //     mobileLabel: h("i.fa.fa-check", []),
        //     onclick: () => {
        //         this.validateOfficeHours();
        //         this.saveOfficeHoursHandler();
        //     }
        // }
      },
      [
        heading('Office Hours'),
        h('div', officeHours.map(officeHourView)),
        this.addButton('Add New Office Hour', () => {
          this.lastFocusedElementSpare = document.activeElement;
          u.unsetTabIndices();
          this.setState({
            isAddOfficeHoursDialogOpen: true,
          });
        }),
      ]
    );
  }

  private async deleteOfficeHourHandler(deleteOfficeHour: IOfficeHour) {
    const info = getStore().getState().courses.info;
    let officeHours = info.officeHours;
    officeHours = officeHours.filter((officeHour) => officeHour !== deleteOfficeHour);
    const officeHourRequest = {
      officeHours: [...officeHours],
    };
    await dispatch(Actions.officeHoursSave(officeHourRequest));
    await this.setState({
      isOfficeHoursEditingDialogOpen: true,
    });
  }

  public async venueInputHandler(text: string) {
    await this.setState({
      venue: text,
      venueError: false,
    });
  }

  public async validateTime() {
    const state = this.getState();
    const newState: Partial<IInfoState> = {};
    let failure = false;
    if (!this.isTimeValid(state.startTimeHour, state.startTimeMinute)) {
      failure = true;
      newState.startTimeError = true;
    }
    if (!this.isTimeValid(state.endTimeHour, state.endTimeMinute)) {
      failure = true;
      newState.endTimeError = true;
    }
    if (state.endTimeHour - state.startTimeHour < 0) {
      failure = true;
      newState.endTimeGreaterThanStartTimeError = true;
    } else if (state.endTimeHour - state.startTimeHour === 0) {
      state.startTimeMinute > state.endTimeMinute
        ? [(failure = true), (newState.endTimeGreaterThanStartTimeError = true)]
        : null;
    }

    if (failure) {
      await this.setState(newState);
      return false;
    } else {
      return true;
    }
  }
  private completeDay(day: IWeekDay) {
    return {
      Mon: 'Monday',
      Tue: 'Tuesday',
      Wed: 'Wednesday',
      Thu: 'Thursday',
      Fri: 'Friday',
      Sat: 'Saturday',
      Sun: 'Sunday',
    }[day];
  }

  private getAMPM(hours: number): 'am' | 'pm' {
    if (hours >= 12) {
      return 'pm';
    } else {
      return 'am';
    }
  }
  private gethh(hours: number) {
    if (hours === 0) {
      return 12;
    } else if (hours > 12) {
      return hours - 12;
    } else {
      return hours;
    }
  }

  private renderOfficeHoursViewTime(time: string) {
    const timeArray = time.split(':');
    const hour = Number(timeArray[0]);
    const minute = Number(timeArray[1]);
    const hours = u.padStart(this.gethh(hour).toString(), 2, '0');
    const minutes = u.padStart(minute.toString(), 2, '0');
    const ampm = this.getAMPM(hour).toUpperCase();
    return `${hours}:${minutes} ${ampm}`;
  }

  private isTimeValid(hour: number, minute: number) {
    return hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59;
  }

  private renderTime(hours: number, minutes: number) {
    return u.showTime({ hours, minutes });
  }

  private getTime24Format(hours: number, minutes: number) {
    const finalHours = hours < 10 ? `0${hours}` : hours;
    const finalMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${finalHours}:${finalMinutes}`;
  }

  private validateOfficeHours() {
    let failure = false;
    const state = this.getState();
    const startTime = this.renderTime(state.startTimeHour, state.startTimeMinute);
    const endTime = this.renderTime(state.endTimeHour, state.endTimeMinute);
    const officeHours = getStore().getState().courses.info.officeHours;
    const officeHourFields = {
      day: Info.days[state.selectedDayIndex],
      startTime: startTime,
      endTime: endTime,
      venue: state.venue,
    };

    for (const officeHour of officeHours) {
      if (officeHourFields.day === officeHour.day) {
        const time = officeHour.startTime.split(':');
        if (state.startTimeHour.toString() === time[0]) {
          failure = true;
        }
      }
    }
    return failure;
  }

  private isStartTimeInconvenient() {
    const state = this.getState();
    return this.isTimeInconvenient({
      hours: state.startTimeHour,
      minutes: state.startTimeMinute,
    });
  }
  private isEndTimeInconvenient() {
    const state = this.getState();
    return this.isTimeInconvenient({
      hours: state.endTimeHour,
      minutes: state.endTimeMinute,
    });
  }

  private isTimeInconvenient(time: ITime) {
    const lowThresholdMinutes = 8 * 60;
    const highThresholdMinutes = 20 * 60;
    const minutes = time.hours * 60 + time.minutes;
    return minutes < lowThresholdMinutes || minutes > highThresholdMinutes;
  }

  private isEndTimeGreater() {
    const state = this.getState();
    const startTimeMinutes = state.startTimeHour * 60 + state.startTimeMinute;
    const endTimeMinutes = state.endTimeHour * 60 + state.endTimeMinute;
    return startTimeMinutes >= endTimeMinutes;
  }

  public async saveOfficeHoursHandler() {
    const state = this.getState();
    const info = getStore().getState().courses.info;
    const startTime = this.getTime24Format(state.startTimeHour, state.startTimeMinute);
    const endTime = this.getTime24Format(state.endTimeHour, state.endTimeMinute);
    const officeHours = info.officeHours;
    const officeHourFields = {
      officeHours: [
        {
          day: Info.days[state.selectedDayIndex],
          startTime: startTime,
          endTime: endTime,
          venue: state.venue,
        },
        ...officeHours,
      ],
    };
    const fieldsValid = await this.validateTime();

    const officeHoursValid = this.validateOfficeHours();

    if (!fieldsValid || officeHoursValid) {
      return;
    }
    await dispatch(Actions.officeHoursSave(officeHourFields));
    await this.setState({
      isAddOfficeHoursDialogOpen: false,
      isOfficeHoursEditingDialogOpen: true,
    });
  }
}
