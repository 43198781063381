/* eslint-disable @typescript-eslint/no-var-requires */
const Inferno = require('inferno');
import InfComponent from 'inferno-component';
const hyperscript = require('inferno-hyperscript');
import * as A from './adapter-interface';

const Prefixer = require('inline-style-prefixer');
const prefixer = new Prefixer();

let _classes = '';

export const setDefaultClasses: InfernoAdapter['setDefaultClasses'] = (...classes: string[]) => {
  _classes = classes.reduce((prev, current) => prev + '.' + current, '');
};

// eslint-disable-next-line @typescript-eslint/ban-types
const errorHandlers: Function[] = [];
export function registerErrorHandler(f: (e: any) => void) {
  errorHandlers.push(f);
}

/**
 * Show warning for list elements without keys in dev mode
 */
if (process.env.ENV !== 'production') {
  const oldArrayMap = Array.prototype.map;
  Array.prototype.map = function (callbackFn) {
    const result = oldArrayMap.bind(this)(callbackFn, this);
    if (
      result[0] &&
      result[0].constructor &&
      result[0].constructor.name &&
      result[0].constructor.name === 'VNode' &&
      !result[0].key
    ) {
      console.warn(result[0].type.name || result[0].type, ' should have a unique key');
      console.warn(result[0]);
    }
    return result;
  };
}

const FLEX = ' acadly-core-flex';
const ALIGN_CENTER = ' acadly-core-align-center';
const SPACE_BETWEEN = ' acadly-core-space-between';
const SPACE_AROUND = ' acadly-core-space-around';
const JUSTIFY_CONTENT = ' acadly-core-flex-end';
const JUSTIFY_CENTER = ' acadly-core-justify-center';
const FLEX_COLUMN = ' acadly-core-flex-column';
const COLUMN = 'column';
const CENTER = 'center';
export const h: InfernoAdapter['h'] = function (_selector: any, attrs: any, children: any) {
  let selector = _selector;
  if (typeof selector === 'string') {
    selector += _classes;
  }
  // prefix styles with browser specific names. Eg. flex -> -webkit-flex
  if (attrs && (attrs as any).style) {
    let className = attrs.className || ' ';
    const _attrs: any = attrs;
    const style = prefixer.prefix({ ..._attrs.style });
    if (_attrs.style.display === 'flex' && typeof className === 'string') {
      delete style.display;
      className += FLEX;
      if (_attrs.style.flexDirection === COLUMN) {
        className += FLEX_COLUMN;
      }

      if (_attrs.style.alignItems === CENTER) {
        className += ALIGN_CENTER;
      }

      if (_attrs.style.justifyContent === 'space-between') {
        className += SPACE_BETWEEN;
      }
      if (_attrs.style.justifyContent === 'space-around') {
        className += SPACE_AROUND;
      }

      if (_attrs.style.justifyContent === 'flex-end') {
        className += JUSTIFY_CONTENT;
      }

      if (_attrs.style.justifyContent === CENTER) {
        className += JUSTIFY_CENTER;
      }
    }

    if (_attrs.style.flex) {
      style.WebkitBoxFlex = _attrs.style.flex;
      style.MsFlex = _attrs.style.flex;
    }

    return hyperscript(
      selector,
      {
        ..._attrs,
        className,
        style,
      },
      children
    );
  }
  return hyperscript(selector, attrs, children);
};

export const mount: InfernoAdapter['mount'] = (elem, comp) => {
  Inferno.render(comp, elem);
};

// inferno automatically re renders on any component's
// setState
export const redraw: InfernoAdapter['redraw'] = () => {};

type InfernoAdapter = A.Adapter<VNode<any, any>, InfComponent<any, any>>;
export type VNode<Attrs, State> = {
  props: Attrs;
  state: State;
  key?: string;
};
export type View = A.View<VNode<any, any>>;

export class Component<Props, State>
  extends InfComponent<Props, State>
  implements A.Component<VNode<any, any>, Props, State>
{
  public componentWillMount() {
    super.componentWillMount && super.componentWillMount();
  }
  public componentWillUpdate(nextProps: Props, nextState: State) {
    if (super.componentWillUpdate) {
      super.componentWillUpdate(nextProps, nextState, null);
    }
  }
  public componentWillUnmount() {
    if (super.componentWillUnmount) {
      super.componentWillUnmount();
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (super.componentWillReceiveProps) {
      super.componentWillReceiveProps(nextProps, null);
    }
  }

  public getState() {
    return this.state!;
  }

  public getProps(): Props {
    return this.props;
  }

  public _updateComponent(
    prevState: State,
    nextState: State,
    prevProps: Props & Props,
    nextProps: Props & Props,
    context: any,
    force: boolean,
    fromSetState: boolean
  ) {
    try {
      return super._updateComponent(
        prevState,
        nextState,
        prevProps,
        nextProps,
        context,
        force,
        fromSetState
      );
    } catch (e) {
      for (const f of errorHandlers) {
        f(e);
      }
      throw e;
    }
  }

  public setState(_state: Partial<State>) {
    const previousState = this.state;
    return new Promise((resolve) => {
      super.setState(
        {
          ...(previousState ? previousState : {}),
          ...(_state as any),
        } as any,
        () => resolve({})
      );
    });
  }
}
