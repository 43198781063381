import cn from 'classnames';

import { CSS, h } from 'core';

interface IRadioButtonProps {
  style?: CSS;
  className?: string;
  selected?: boolean;
  disabled?: boolean;
  onclick?: (e: MouseEvent) => any;
  color?: string;
}
export default (props: IRadioButtonProps) => {
  return h(
    'div.radio-button',
    {
      onclick: props.onclick,
      className: cn(props.className, {
        selected: props.selected,
        disabled: props.disabled,
      }),
      style: {
        ...(props.style || {}),
        color: props.color,
      },
    },
    [h('div.radio-button__content')]
  );
};
