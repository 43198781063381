import { Dispatch, Store } from 'redux';

import configureStore from './configureStore';
import { IRootAction } from './IRootAction';
import { IRootState } from './IRootState';

let store: Store<IRootState> = configureStore();

export function getStore(): Store<IRootState> {
  return store;
}
export const dispatch: Dispatch<IRootState> = <A extends IRootAction>(action: A): A =>
  getStore().dispatch(action);

if (!['test', 'production'].includes(process.env.ENV as string)) {
  window.getStore = getStore;
}

export function setStore(_store: Store<IRootState>) {
  store = _store;
  window.store = store;
}
