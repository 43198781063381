import { CourseEndDialogVM, ICourseEndDialogVMProps } from 'acadly/course/CourseEndDialogVM';
import * as dt from 'acadly/datetime';
import { logger } from 'acadly/logger';
import { messages } from 'acadly/messages';
import { ViewModel } from 'acadly/ViewModel';

export interface ICourseVMProps {
  courseRole: ICourseRole;
  course: {
    _id: string;
    isArchived: boolean;
    isLive: boolean;
    startDate: UnixTimestamp;
    endDate: UnixTimestamp;
  };
}
export class CourseVM extends ViewModel<ICourseVMProps> {
  private _isCourseEndDialogOpen = false;

  private _courseEndDialog = this.attach(
    new CourseEndDialogVM(this.courseEndVMProps),
    () => this.courseEndVMProps
  );

  public get courseEndVMProps(): ICourseEndDialogVMProps {
    return {
      courseId: this.props.course._id,
      endDate: dt.fromUnix(this.props.course.endDate),
      startDate: dt.fromUnix(this.props.course.startDate),
      onArchiveSuccess: this.onClickCourseOverOptionsBack,
      onExtendSuccess: this.onClickCourseOverOptionsBack,
    };
  }

  public get courseOverBar() {
    if (this.props.course.endDate > dt.unix()) {
      return null;
    } else if (this.props.course.isArchived) {
      return null;
    } else if (!this.props.course.isLive) {
      return null;
    } else {
      if (this.props.courseRole === 'admin') {
        return {
          message: messages.course.over,
          action: {
            label: 'Options',
            onClick: () => this.onClickCourseOverOptions(),
          },
        };
      } else {
        return {
          message: messages.course.over,
          action: null,
        };
      }
    }
  }

  public get courseEndDialog() {
    if (!this.isCourseEndDialogOpen) {
      return null;
    } else {
      return this._courseEndDialog;
    }
  }

  public get isCourseEndDialogOpen() {
    return this._isCourseEndDialogOpen;
  }

  public onClickCourseOverOptions = () =>
    this.update(async () => {
      this._isCourseEndDialogOpen = true;
    });

  public onClickCourseOverOptionsBack = () =>
    this.update(async () => {
      logger.log('Closing course end dialog...');
      this._isCourseEndDialogOpen = false;
    });
}
