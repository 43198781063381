import gatewayServer from './gateway-server';

export default api;
export function api() {
  return {
    // 	GetIn Flows APIs
    //  these urls are to be found on gateaway server, it makes much sense,
    //  since app has a gateaway so verification must be done there
    gateway: gatewayServer + 'server',
    signup: {
      student: gatewayServer + 'signup/student',
      teacher: gatewayServer + 'signup/v2/teacher',
    },
    ambassador: gatewayServer + 'beambassador',
    getCourseInfoByJoinCode: (code: string) => `${gatewayServer}courses/${code}`,

    //  these urls to be found on cluster server, after passing from
    //  gateaway server

    email: 'email',
    login: 'login',
    verifyTempPass: 'verifytemppass',
    setPassword: 'setpassword',
    resendTempPass: 'resendtemppass',
    forgotPassword: 'forgotpassword',
    profileCreate: 'users/profile/create',
    getAvatars: 'users/profile/avatar/get',
    uploadAvatar: 'users/profile/avatar/create',
    changePassword: 'users/profile/password/change',
    registerUser: 'notifications/register',
    feedback: 'feedback',
    changeNameStatus: 'users/profile/name/change/status',
    changeName: 'users/profile/name/change',
    cancelFacultyAccountVerification: 'users/am_student',

    // Consent & Tips
    initialize: 'initialize',
    promoSeen: 'promos/seen',
    sortPref: 'sortpref/update',
    consent: 'consent',
    updateTips: 'tips/update',
    updateAcc: 'accessibility/update',

    // feedback form
    getFeedbackForm: (feedbackFormId: string) => `feedback/${feedbackFormId}`,
    denyFeedbackForm: 'feedback/form/deny',
    submitFeedbackForm: 'feedback/form/submit',

    // 	Context APIs
    comments: 'http:// private-3ae1e-chatapp6.apiary-mock.com/getComments',

    // 	Course Page Landing Flows APIs

    allCourses: 'courses/v2/mycourses',
    joinCourseByJoinCode: 'courses/v2/join/code',
    getCourseDetails: 'courses/v2/details',
    newFeatures: 'newfeatures',
    newFeaturesLastAccesed: 'newfeatures/lastAccessed',

    getProStatus: 'pro/status/instructor/available_slots',
    createProCourseDemo: 'pro/create_trial',
    createBasicCourseDemo: 'courses/create_trial',
    getProCourseCost: 'pro/get_cost',
    getUSDToINRConversionRate: 'pro/purchase/usd-inr',
    getProCoursePurchaseIntent: 'pro/purchase/student',
    makeFreePurchase: 'pro/purchase/free',
    updateProPurchaseStatus: 'pro/purchase/student/status',
    createProCourse: 'courses/v2/create_pro',
    //   Course timeline pages

    courseCreate: 'courses/v2/create',
    getBlueprintCourses: 'courses/blueprint',
    getBlueprintCoursePreview: (courseId: string) => `courses/bppreview/${courseId}`,
    courseInitialize: 'courses/initialize',
    courseInfo: 'courses/info',
    courseDescEdit: 'courses/info/edit/description',
    courseRemoveMember: 'courseInfo/removeMember',
    courseInfoPublish: 'courses/info/publish',
    courseTimezoneSet: 'courses/timezone/set',
    courseTimezoneGet: 'courses/timezone/get',
    courseInfoFileUpload: 'geturl/courses/info/upload',
    courseInfoFileUploadConfirm: 'confirm/courses/info/upload',
    courseInfoFileRemove: 'courses/info/files/remove',
    courseInfoFileDownload: 'courses/info/files/download',
    courseInfoOfficeHoursSave: 'courses/info/officehours/save',
    courseInfoLinkRemove: 'links/remove',

    courseWeeklyClassAdd: 'courses/v3/schedule/add/weeklyclass',
    courseWeeklyClassRemove: 'courses/schedule/remove/weeklyclass',
    courseStartDateSet: 'courses/schedule/edit/startdate',
    courseEndDateSet: 'courses/schedule/edit/enddate',
    checkNumSessions: 'courses/v2/numsessions',
    courseSchedulePublish: 'courses/v2/schedule/publish',
    courseLive: 'courses/golive',
    courseTeamMemberAdd: 'members/courses/add/team',
    courseTeamMemberRemove: 'courses/members/remove',
    courseStudentRemove: 'courses/members/remove',
    courseStudentsAdd: 'members/v2/courses/add/students',
    courseClassAdd: 'classes/v3/add',
    studentReinstate: 'courses/students/reinstate',
    studentResendInvite: 'members/courses/reinvite/students',
    getAdminCourses: 'courses/amadmin',
    courseRemove: 'courses/remove',
    getArchivedCourses: 'courses/archived',
    archiveCourse: 'courses/archive',

    syllabusView: 'syllabus/',
    syllabusGetTopic: 'topics/course',
    syllabusGetTopicById: 'topics/ids',
    syllabusAddTopic: 'topics/add',
    syllabusEditTopic: 'topics/edit',
    syllabusRemoveTopic: 'topics/remove',
    syllabusAddBook: 'books/add',
    syllabusEditBook: 'books/edit',
    syllabusRemoveBook: 'books/remove',
    syllabusGetBooksById: 'books/id',
    syllabusAddLink: 'links/add',
    syllabusEditLink: 'links/edit',
    syllabusRemoveLink: 'links/remove',
    syllabusGetLinksById: 'links/id',
    syllabusFileUpload: 'geturl/courses/syllabus/upload',
    syllabusFileUploadConfirm: 'confirm/courses/syllabus/upload',
    syllabusFileDownload: 'courses/syllabus/files/download',

    // Archive URLs

    archiveGet: 'archives/get',

    // Assignment URLs
    assignmentCreate: 'assignments/v2/create',
    assignmentGet: 'assignments/get',
    assignmentGetUploadUrl: 'geturl/assignments/files/upload',
    assignmentSubGetUploadUrl: 'geturl/assignments/submissions/upload',
    assignmentEdit: 'assignments/edit',
    assignmentQueAdd: 'assignments/v2/questions/add',
    assignmentQueEdit: 'assignments/v2/questions/edit',
    assignmentQueRemove: 'assignments/questions/remove',
    assignmentQueUpload: 'assignments/files/upload',
    assignmentSubUpload: 'assignments/submissions/upload',
    assignmentSubUploadConfirm: 'confirm/assignments/submissions/upload',
    assignmentSubUrlSave: 'confirm/assignments/submissions/url',
    assignmentSubmit: 'assignments/submit',
    assignmentRetract: 'assignments/retract',
    assignmentRemove: 'assignments/remove',
    assignmentPublish: 'assignments/v2/publish',
    assignmentUpdate: 'assignments/edit/published',
    assignmentDownQueAttachment: 'assignments/files/download',
    assignmentDownOwnSub: 'assignments/submissions/own/download',
    assignmentDownStuSub: 'assignments/submissions/student/download',

    analyticsAssignmentGetSubData: 'assignments/analytics/submissions/all/',
    analyticsAssignmentGetIndvData: 'assignments/analytics/submissions/individual/',

    assignmentFetchSubmission: (assignmentId: string, studentId: string) =>
      `assignments/analytics/submissions/individual/${assignmentId}/${studentId}`,
    assignmentGradesSave: 'assignments/analytics/grades/save',
    assignmentGradesSubmit: 'assignments/analytics/grades/submit',

    announcementCreate: 'announcements/create',
    announcementGetUploadUrl: 'geturl/announcements/files/upload',
    announcementFileUpload: 'announcements/files/upload',
    announcementFileDownload: 'announcements/files/download',
    announcementAnalyticsSet: 'announcements/analytics/set',

    // 	Class Page APIs
    classData: (classId: string) => `classes/${classId}`,
    // classReschedule: "classes/schedule/edit",
    classCancel: 'classes/v3/schedule/cancel',
    classChangeVenue: 'classes/v3/venue/edit',
    classTimingsEdit: 'classes/v2/timings/edit',
    // classHoliday: "classes/schedule/holiday/",
    classInchargeSetV2: 'classes/v2/team/incharge/edit',
    classTeamEdit: 'classes/team/edit',
    classAgendaEdit: 'classes/agenda/edit',
    classTopicsEdit: 'classes/topics/edit',
    // classCheckIn: "classes/checkin/",
    classCheckInV2: 'classes/v2/checkin',
    startProxyAttendance: 'classes/attendance/proxy/start',
    stopProxyAttendance: 'classes/attendance/proxy/stop',
    acknowledgeAttendanceStarted: 'internals/record/notified',
    attendingRemotely: 'classes/attendance/attending-remotely',
    attendanceResponders: 'classes/attendance/responders',
    classAttendanceGet: (classId: string) => `classes/v2/attendance/get/${classId}`,
    classAttendanceSet: 'classes/v2/attendance/set',
    classRemove: 'classes/remove/',
    classNoRecord: 'classes/schedule/norecord',
    classActivityMove: 'classes/activity/move',
    classAttendanceMineGet: (classId: string) => `classes/attendance/mine/${classId}`,

    authorizeZoomInstall: 'applications/authorize/zoom',
    createOnlineMeeting: 'classes/zoom/create-meeting',
    zoomMeetingStatus: 'classes/zoom/meeting/status',
    startBroadcast: 'classes/zoom/broadcast',
    endBroadcast: 'classes/zoom/end_broadcast',
    getOnlineDetails: 'classes/zoom/get-meeting-details',
    generateVCallSignature: 'classes/zoom/generate_signature',
    vCallParticipantJoined: 'classes/zoom/meeting/joined',
    vCallParticipantLeft: 'classes/zoom/meeting/left',

    scheduleAttendance: 'classes/attendance/schedule',
    editScheduleAttendance: 'classes/attendance/schedule/edit',
    startRemoteAttendance: 'classes/zoom/attendance/start',
    stopRemoteAttendance: 'classes/zoom/attendance/stop',
    markRemoteAttendance: 'classes/zoom/attendance/mark',

    getZoomRecordings: (classId: string) => `classes/zoom/recordings/${classId}`,
    deleteZoomRecording: 'classes/v2/zoom/recordings/delete',
    publishZoomRecording: 'classes/zoom/recordings/publish',
    zoomRecordingsDownload: 'classes/v3/zoom/recordings/download',

    raiseHand: 'queries/raise_hand',
    lowerHand: 'queries/lower_hand',
    addressHand: 'queries/address_hand',
    resolveHand: 'queries/resolve_hand',

    suggestedActivities: 'courses/activities/suggested',
    suggestedActivityDetails: 'courses/activities/suggested/details',
    useSuggestedActivity: 'archives/use/suggestedactivity',
    hideSuggestedActivity: 'archives/hide/suggestedactivity',

    copyActivityCandidateCourses: 'archives/get/validtargetcourses',
    classActivityCopyCandidates: 'archives/get/validtargetclasses',
    copyActivity: 'archives/copy/activity',

    classSummaryGet: (clsId: string) => `classes/summary/get?classId=${clsId}`,
    classSummaryFileUpload: 'geturl/classes/summary/files/upload',
    classSummaryFileDownload: 'classes/summary/files/download',
    classSummaryAddLinks: 'links/add',
    classSummarySave: 'classes/summary/save',

    classTitleEdit: 'classes/title/edit',

    getClassParticipationStats: (clsId: string) => `classes/participation/${clsId}`,
    setClassParticipationScores: 'classes/v2/participation',

    // 	Class Activity Page
    publishPrefs: 'activities/save/publish-pref',
    getAttendance: 'attendance/get/',
    quizGet: 'quizzes/get',
    quizCreate: 'quizzes/create',
    quizEdit: 'quizzes/edit',
    quizRemove: 'quizzes/remove',
    quizQueAdd: 'quizzes/v2/questions/add',
    quizQueEdit: 'quizzes/v2/questions/edit',
    quizQueReorder: 'quizzes/questions/reorder',
    quizQueRemove: 'quizzes/questions/remove',
    quizPublish: 'quizzes/v5/publish',
    quizStop: 'quizzes/stop',
    updatePublishedQuiz: 'quizzes/v3/edit/published',
    quizSave: 'quizzes/v2/save',
    quizSaveResponse: 'quizzes/v2/question/response/save',
    quizSubmit: 'quizzes/v3/submit',
    quizFileUpload: 'geturl/quizzes/files/upload',
    quizFileDownload: 'quizzes/files/download',
    // quiz analytics
    quizAnalyticsFetch: (quizId: string) => `quizzes/analytics/submissions/all/${quizId}`,
    quizAnalyticsFetchIndividual: (quizId: string, userId: string) =>
      `quizzes/analytics/submissions/individual/${quizId}/${userId}`,
    quizAnalyticsFetchIndividualQuestion: (quizId: string, questionId: string) =>
      `quizzes/analytics/questions/${quizId}/${questionId}`,

    pollGet: 'polls/get',
    pollCreate: 'polls/create',
    pollEdit: 'polls/edit',
    pollRemove: 'polls/remove',
    pollQueAdd: 'polls/questions/add',
    pollQueEdit: 'polls/questions/edit',
    pollQueRemove: 'polls/questions/remove',
    pollSubmit: 'polls/v2/submit',
    pollPublish: 'polls/v4/publish',
    pollStop: 'polls/stop',
    updatePollPublished: 'polls/edit/published',
    pollFileUpload: 'geturl/polls/files/upload',
    pollFileDownload: 'polls/files/download',
    pollAnalyticsFetch: (pollId: string) => `polls/analytics/get/${pollId}`,

    resourceCreate: 'resources/create',
    resourceEdit: 'resources/edit',
    resourceRemove: 'resources/remove',
    resourcePublish: 'resources/publish',
    resourcePublishV2: 'resources/v2/publish',
    resourceUpdatePublished: 'resources/edit/published',
    resourceFileUpload: 'geturl/resources/files/upload',
    resourceFileDownload: 'resources/files/download',

    discussionCreate: 'discussions/create',
    discussionEdit: 'discussions/edit',
    discussionPrefs: 'discussions/preference',
    discussionPublish: 'discussions/v3/publish',
    discussionUpdatePublished: 'discussions/edit/published',
    discussionRemove: 'discussions/remove',
    discussionFileUpload: 'geturl/discussions/files/upload',
    discussionFileDownload: 'discussions/files/download',

    wordCloudCreate: 'discussions/wordcloud',
    wordCloudEditWords: 'discussions/wordcloud/edit',
    wordCloudDetails: (discussionId: string) => `discussions/wordcloud/${discussionId}`,

    queryCreate: 'queries/create',
    queryUpvote: 'queries/upvote/',
    queryHide: 'queries/hide',
    queryClose: 'queries/close',
    queryFileUpload: 'geturl/queries/files/upload',
    queryFileDownload: 'queries/files/download',
    queryApprove: 'queries/approve',
    queryRemove: 'queries/remove',

    // attendance

    imageUpload: 'images/upload',
    addMathExp: 'math/insert',

    //  course Analytics

    analyticsStudentsData: 'courses/analytics/students',
    analyticsAverage: 'courses/analytics/averages',
    analyticsIndvStudentDataV2: (studentId: string) =>
      `courses/analytics/v2/individual/${studentId}`,
    analyticsSelfDataV2: 'courses/analytics/v2/self',

    // resource analytics

    analyticsResourceSet: 'resources/analytics/set',
    analyticsResourceGet: 'resources/analytics/get/',

    // query analytics

    analyticsQuerySet: 'queries/analytics/set',

    // Discussion analytics
    analyticsDiscussionSet: 'discussions/analytics/set',

    // Export grades for one particular acitivty
    exportActivityGrades: 'export/grades',

    // Export grades for Course
    exportAllGrades: 'export/grades/all',

    // Export comment stats for a specific type of activity
    exportCommentStats: 'export/comments',

    // Export comment stats for all activities
    exportAllCommentStats: 'export/comments/all',

    // comments URLs

    getNewComments: 'comments/get/new',
    getCommentsV2: 'comments/v2/get',
    getOldComments: 'comments/get/old',
    uploadCommentAttachment: 'comments/files/upload',
    downloadCommentAttachment: 'comments/files/download',
    addNewComment: 'comments/add',
    commentRemove: (commentId: string) => `comments/remove?commentId=${commentId}`,
    updateLiveComments: 'comments/seen',
    markComment: 'comments/mark',
    commentGetLikes: (commentId: string) => `comments/get/likes/${commentId}`,
    commentsGetUploadUrl: 'geturl/comments/files/upload',
    commentAward: 'comments/award',
    commentSubscription: 'comments/subscription',

    getNotificationPreferences: 'notifications/get/preferences',
    setNotificationPreferences: 'notifications/manage/preferences',

    // Referrals
    refer: 'refer',

    //  logout
    logout: 'logout',
  };
}
