import classNames from 'classnames';

import { CSS, h, IComponent } from 'core';
import { jsonRequest } from 'core/http';

import * as AudioIcon from 'assets/audio-icon.svg';
import * as CloseIcon from 'assets/close.svg';
import * as DownloadIcon from 'assets/download.svg';
import * as PlayIcon from 'assets/play-arrow.svg';
import * as StopIcon from 'assets/stop-media.svg';
import * as VideoIcon from 'assets/video-icon.svg';

import { Loader } from 'acadly/common/Loader';
import SvgIcon from 'acadly/common/SvgIcon';
import { getStore } from 'acadly/store';
import { randomString } from 'acadly/utils';

interface IMediaCellProps {
  type: 'audio' | 'video';
  title: string;
  className?: string;
  style?: CSS;
  isPlaying: boolean;
  onPlay: (mediaURL: string) => void;
  onStop: () => void;
  canDelete?: boolean;
  onDelete?: () => void;
  canDownload?: boolean;
  /** request parameters to fetch video URL links */
  request: {
    /** @default "POST" */
    method?: 'GET' | 'POST';
    url: string;
    headers?: { [header: string]: string };
    data?: any;
  };
}

interface IMediaCellState {
  attachmentAnchorId: string;
  isFetchingDownloadURL: boolean;
  isFetchingURL: boolean;
  mediaURL: string;
}

class MediaCell extends IComponent<IMediaCellProps, IMediaCellState> {
  private get isAccessible() {
    return getStore().getState().app.acc.web.turnOff === 0;
  }

  public componentWillMount() {
    const initialState: IMediaCellState = {
      attachmentAnchorId: randomString(),
      isFetchingDownloadURL: false,
      isFetchingURL: false,
      mediaURL: '',
    };
    this.setState(initialState);
  }

  public render() {
    const { className, isPlaying, style, title, type } = this.getProps();

    return h(
      'div.media-cell',
      {
        style,
        className,
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `${isPlaying ? 'now playing - ' : ''}${title}`,
      },
      [
        SvgIcon({
          icon: type === 'audio' ? AudioIcon : VideoIcon,
          className: 'media-cell__icon',
        }),
        h('div.media-cell__title', title),
        this.downloadButton(),
        this.playStopButton(),
        this.deleteButton(),
      ]
    );
  }

  private downloadButton() {
    const { canDownload, title } = this.getProps();
    const { attachmentAnchorId, isFetchingDownloadURL, mediaURL } = this.getState();

    if (!canDownload) return null;

    if (isFetchingDownloadURL) return Loader({ margin: '0.5rem', fontSize: '0.5rem' });

    if (mediaURL) {
      return h(
        'a',
        {
          id: `download-attachment-${attachmentAnchorId}`,
          href: mediaURL,
          target: '_blank',
          download: title,
          'aria-label': 'download this attachment',
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        'Download'
      );
    }

    return SvgIcon({
      component: 'button',
      icon: DownloadIcon,
      className: classNames('media-cell__icon', 'ripple'),
      tabIndex: this.isAccessible ? 0 : undefined,
      'aria-label': 'Download Attachment',
      onclick: async () => {
        this.setState({ isFetchingDownloadURL: true });
        await this.fetchURL();
        this.setState({ isFetchingDownloadURL: false });
      },
    });
  }

  private playStopButton() {
    const { isPlaying, onPlay, onStop, type } = this.getProps();
    const { isFetchingURL, mediaURL } = this.getState();

    if (isFetchingURL) return Loader({ margin: '0.5rem', fontSize: '0.5rem' });

    return SvgIcon({
      component: 'button',
      icon: isPlaying ? StopIcon : PlayIcon,
      className: classNames('media-cell__icon', 'ripple', {
        red: isPlaying,
      }),
      tabIndex: this.isAccessible ? 0 : undefined,
      'aria-label': isPlaying ? `click to stop this ${type}` : `click to play this ${type}`,
      onclick: () => {
        if (isPlaying) {
          return onStop();
        }

        if (mediaURL) {
          return onPlay(mediaURL);
        }

        this.fetchUrlAndPlay();
      },
    });
  }

  private deleteButton() {
    const { canDelete, onDelete, type } = this.getProps();

    if (!canDelete || !onDelete) return null;

    return SvgIcon({
      icon: CloseIcon,
      component: 'button',
      'aria-label': `Delete this ${type} file.`,
      tabIndex: this.isAccessible ? 0 : undefined,
      className: 'media-cell__icon ripple red',
      onclick: (e) => {
        // stop propagation because it parent listening click for play/pause
        e.stopPropagation();
        if (onDelete) {
          onDelete();
        }
      },
    });
  }

  private async fetchURL() {
    const { request } = this.getProps();

    const response = await jsonRequest<{ link: string }>(request.url, {
      method: request.method || 'POST',
      headers: request.headers,
      data: request.data,
    });

    this.setState({
      mediaURL: response.data.link,
    });

    return response.data.link;
  }

  private async fetchUrlAndPlay() {
    const { onPlay } = this.getProps();

    this.setState({
      isFetchingURL: true,
    });

    const link = await this.fetchURL();

    this.setState({
      isFetchingURL: false,
    });

    onPlay(link);
  }
}

export default (props: IMediaCellProps) => h(MediaCell, props);
