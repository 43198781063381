import { h, IComponent, View } from 'core';

import CourseWidget from 'acadly/app/CourseWidget';
import { Actions as ClassActions } from 'acadly/class/actions';
import { ClassWidget, IClassWidgetProps } from 'acadly/class/ClassWidget';
import classService from 'acadly/class/service';
import Alert from 'acadly/common/Alert';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import { fullScreenLoader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import { Actions as CourseActions } from 'acadly/course/actions';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import { dispatch, getStore } from 'acadly/store';
import { colors, ml, pad, style } from 'acadly/styles';
import * as u from 'acadly/utils';

import * as _api from './api';

export interface ICopyActivityDialogProps {
  classId: string;
  activityId: string;
  activityType: _api.ICopyActivityRequest['activityType'];
  open: boolean;
  onDone: () => void;
  onCancel: () => void;
}

export interface ICopyActivityDialogState {
  isLoading: boolean;
  screen: 'course' | 'toBeDone' | 'class';
  isCopyAssignmentConfirmationOpen: boolean;
  targetClassId: string | null;
  targetCourseId: string | null;
  toBeDone: 'preClass' | 'inClass' | null;
  candidateCourses: _api.ICopyActivityCandidateCoursesFetchResponse['courses'];
  candidateClasses: IClassWidgetProps[];
}

export abstract class CopyActivityDialogFlow extends IComponent<
  ICopyActivityDialogProps,
  ICopyActivityDialogState
> {
  // cheap ass dependency injection lel
  private api: typeof _api = _api;

  private initialState: ICopyActivityDialogState = {
    screen: 'course',
    isLoading: true,
    candidateCourses: [],
    candidateClasses: [],
    targetClassId: null,
    targetCourseId: null,
    toBeDone: null,
    isCopyAssignmentConfirmationOpen: false,
  };

  public componentWillMount() {
    this.setInitialState();
  }

  protected async onOpen() {
    await this.setInitialState();
    await this.fetchCandidateCourses();
  }

  protected async onClose() {
    await this.setInitialState();
  }

  private async setInitialState() {
    await this.setState({ ...this.initialState });
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
  }

  protected selectedClass() {
    const props = this.getState().candidateClasses.find(
      (c) => c.cls._id === this.getState().targetClassId
    );
    return props && props.cls;
  }

  protected isPreClassEnabled() {
    const cls = this.selectedClass();
    if (!cls) {
      return false;
    } else {
      return dt.isGreaterThanBy(dt.fromUnix(cls.details.scheStartTime), dt.now(), 30, 'minutes');
    }
  }

  protected async closeCopyAssignmentConfirmation() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isCopyAssignmentConfirmationOpen: false,
    });
  }

  protected isToBeDoneEnabled(toBeDone: 'preClass' | 'inClass') {
    if (toBeDone === 'preClass') {
      return this.isPreClassEnabled();
    } else {
      return true;
    }
  }

  private async fetchCandidateCourses() {
    await this.setState({
      isLoading: true,
    });
    const response = await this.api.copyActivityCandidateCoursesFetch();
    if (!this.getProps().open) return;
    await this.setState({
      isLoading: false,
      candidateCourses: response.data.courses,
    });
  }

  protected async onClickCancel() {
    await this.getProps().onCancel();
  }
  protected isAccessible = false;
  private lastFocusedElement: null | Element = null;
  protected async onClickCourse(courseId: string) {
    if (this.getProps().activityType !== 'assignments') {
      await this.setState({
        targetCourseId: courseId,
        screen: 'class',
        isLoading: true,
      });
      const response = await this.fetchCandidateClasses();
      const course = this.getState().candidateCourses.find((c) => c._id === courseId);
      if (!course) {
        return;
      }
      const member = course.team.find(
        (m) => m.userId === getStore().getState().getIn.session!.userId
      );
      const role = member ? member.role : 'student';
      await this.setState({
        isLoading: false,
        candidateClasses: response.data.classes.map<IClassWidgetProps>((cls) => ({
          cls,
          tag: 'none',
          courseRole: role,
          hideActivityIcons: true,
          style: {
            marginBottom: '0.5rem',
          },
          onclick: () => this.onClickClass(cls._id),
          tabIndex: this.isAccessible ? 0 : undefined,
        })),
      });
    } else {
      this.lastFocusedElement = document.activeElement;
      u.unsetTabIndices();
      await this.setState({
        targetCourseId: courseId,
        isCopyAssignmentConfirmationOpen: true,
      });
    }
  }

  protected async copyAssignmentConfirmationOnClickYes() {
    await this.copyActivity();
  }

  protected async copyAssignmentConfirmationOnClickNo() {
    await this.setState({
      isCopyAssignmentConfirmationOpen: false,
    });
  }

  protected async onClickClass(classId: string) {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      targetClassId: classId,
      toBeDone: null,
      screen: 'toBeDone',
    });
  }

  protected async onClickToBeDone(toBeDone: 'preClass' | 'inClass') {
    await this.setState({
      toBeDone: toBeDone,
    });
  }

  protected async toBeDoneDialogOnClickCopy() {
    await this.copyActivity();
  }

  protected async toBeDoneDialogOnClickCancel() {
    await this.setState({
      screen: 'class',
      targetClassId: null,
    }).then(() => {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    });
  }

  protected async copyActivity() {
    const props = this.getProps();
    const state = this.getState();
    if (props.activityType === 'assignments') {
      await this.api.copyActivity({
        activityType: props.activityType,
        activityId: props.activityId,
        copyToCourse: state.targetCourseId!,
      });
    } else {
      await this.api.copyActivity({
        activityType: props.activityType,
        activityId: props.activityId,
        copyToClass: state.targetClassId!,
        copyToCourse: state.targetCourseId!,
        toBeDone: state.toBeDone!,
      });
    }

    if (state.targetCourseId === courseService.getCurrentCourseId()) {
      await dispatch(CourseActions.fetchTimeline());
      // to update the class activities if copied in the same class
      if (
        props.activityType !== 'assignments' &&
        classService.getCurrentClassId() === state.targetClassId
      ) {
        await dispatch(ClassActions.fetchClassActivities(state.targetClassId!));
      }
    }
    await this.getProps().onDone();
  }

  private async fetchCandidateClasses() {
    const targetCourseId = this.getState().targetCourseId;
    if (!targetCourseId) {
      throw new Error('Tried to fetch candidate classes before selecting course');
    }
    return await this.api.copyActivityCandidateClassesFetch(targetCourseId);
  }
}

export class CopyActivityDialog extends CopyActivityDialogFlow {
  public render() {
    const state = this.getState();
    return Dialog(
      {
        open: this.getProps().open,
        thinHeader: true,
        title: {
          course: 'Copy to course',
          class: 'Copy to class',
          toBeDone: 'Copy to class',
        }[state.screen],
        style: {
          width: '40em',
          backgroundColor: colors.backgroundColor,
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-arrow-left'),
          onclick: () => this.onClickCancel(),
        },
        onOpen: () => this.onOpen(),
        onClose: () => this.onClose(),
      },
      this.getProps().open ? this.body() : []
    );
  }

  private body(): View[] {
    const state = this.getState();
    if (state.isLoading) return [h('div', style([pad('10%')]), [fullScreenLoader])];
    switch (state.screen) {
      case 'course':
        return [...this.selectCourseView(), this.copyAssignmentConfirmationDialog()];
      case 'class':
        return this.selectClassView();
      case 'toBeDone':
        return [...this.selectClassView(), ...this.selectToBeDoneView()];
    }
  }

  private copyAssignmentConfirmationDialog() {
    const props = this.getProps();
    if (props.activityType !== 'assignments') {
      return null;
    }
    const state = this.getState();
    return Alert(
      {
        open: state.isCopyAssignmentConfirmationOpen,
        title: 'Copy assignment',
        style: {
          width: '25em',
        },
        actions: [
          FlatButton('No', {
            type: 'secondary',
            onclick: () => this.closeCopyAssignmentConfirmation(),
          }),
          FlatButton('COPY', {
            onclick: () => this.copyActivity(),
          }),
        ],
      },
      ['Are you sure you want to copy this assignment?']
    );
  }

  private selectCourseView(): View[] {
    const { candidateCourses } = this.getState();
    return [
      h(
        'div.courses',
        style([pad('0.5rem 1rem'), 'flex', 'column', 'alignCenter']),
        candidateCourses
          .map((course) => ({
            course,
            onClick: () => this.onClickCourse(course._id),
          }))
          .map(CourseWidget)
      ),
    ];
  }

  private selectClassView() {
    const state = this.getState();
    return [
      h(
        'div',
        style(['flex', 'column', 'alignCenter']),
        state.candidateClasses.length > 0
          ? [...state.candidateClasses.map(ClassWidget)]
          : [
              h(
                'div',
                style(['flex', 'x-large', 'alignCenter', pad('1rem 0 0')]),
                'There are no available classes to copy this activity into'
              ),
            ]
      ),
    ];
  }

  private selectToBeDoneView() {
    const state = this.getState();
    return [
      Alert(
        {
          open: state.screen === 'toBeDone',
          title: h('div', style(['green']), 'Copy as'),
          style: {
            width: '20rem',
          },
          actions: [
            FlatButton('Cancel', {
              tabIndex: this.isAccessible ? 0 : undefined,
              type: 'secondary',
              onclick: () => this.toBeDoneDialogOnClickCancel(),
            }),
            FlatButton('Copy', {
              tabIndex: this.isAccessible ? 0 : undefined,
              disabled: !state.toBeDone,
              onclick: () => this.toBeDoneDialogOnClickCopy(),
            }),
          ],
        },
        [
          ...(<('preClass' | 'inClass')[]>['preClass', 'inClass']).map((toBeDone) =>
            h(
              'div',
              {
                key: toBeDone,
                tabIndex: this.isAccessible ? 0 : undefined,
                style: style([
                  'flex',
                  'alignCenter',
                  pad('0.5rem 1rem'),
                  {
                    cursor: this.isToBeDoneEnabled(toBeDone) ? 'pointer' : undefined,
                    color: this.isToBeDoneEnabled(toBeDone) ? colors.blue : colors.lightGrey,
                  },
                ]).style,
                onclick: this.isToBeDoneEnabled(toBeDone)
                  ? () => this.onClickToBeDone(toBeDone)
                  : undefined,
              },
              [
                RadioButton({
                  selected: state.toBeDone === toBeDone,
                  disabled: !this.isToBeDoneEnabled(toBeDone),
                  color: this.isToBeDoneEnabled(toBeDone) ? colors.blue : colors.lightGrey,
                }),
                h(
                  'span',
                  style([ml('0.5em')]),
                  {
                    preClass: 'Pre-class',
                    inClass: 'In-class',
                  }[toBeDone]
                ),
              ]
            )
          ),
        ]
      ),
    ];
  }
}

export default (props: ICopyActivityDialogProps) => h(CopyActivityDialog, props);
