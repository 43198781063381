import { h, IComponent } from 'core';

import { Actions as ClassActions } from 'acadly/class/actions';
import ActivityDurationMenu, { getActivityDurationLabel } from 'acadly/common/ActivityDurationMenu';
import ActivityPrefsDialog from 'acadly/common/ActivityPrefsDialog';
import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import ToggleCell from 'acadly/common/ToggleCell';
import * as datetime from 'acadly/datetime';
import { Actions as QuizActions } from 'acadly/quiz/actions';
import { dispatch, getStore } from 'acadly/store';
import { resetTabIndices, toNumericBoolean, unsetTabIndices, withTabIndex } from 'acadly/utils';

import ScoringSchemeDialog from './ScoringSchemeDialog';

interface IQuizPrefsDialogProps {
  open: boolean;
  cls: IClass;
  quiz: IQuiz;
  isPublishing: boolean;
  onClose: () => void;
  onPublish: () => Promise<void> | void;
}

interface IQuizPrefsDialogState {
  duration: ActivityDuration | null;
  scoring: IQuizScoringScheme;
  allowLate: boolean;
  randomQues: boolean;
  randomOpt: boolean;
  deadlineFirst: boolean;
  subscribeToComments: boolean;
  saveAsDefault: boolean;
  isDurationMenuOpen: boolean;
  isDurationNotSetAlertOpen: boolean;
  isScoringDialogOpen: boolean;
}

const DATE_FORMAT = 'hh:mm A, MMM DD, YYYY';

class QuizPrefsDialog extends IComponent<IQuizPrefsDialogProps, IQuizPrefsDialogState> {
  private lastFocusedElement: null | Element = null;

  private get isAccessible() {
    return getStore().getState().app.acc.web.turnOff === 0;
  }

  private get publishDefaults() {
    return getStore().getState().quizzes.publishDefaults;
  }

  private init() {
    const { cls, quiz } = this.getProps();
    const publishDefaults = this.publishDefaults;

    const initialState: IQuizPrefsDialogState = {
      duration: null,
      scoring: 'neutral',
      allowLate: true,
      deadlineFirst: true,
      isDurationMenuOpen: false,
      isDurationNotSetAlertOpen: false,
      randomOpt: false,
      randomQues: false,
      subscribeToComments: false,
      saveAsDefault: false,
      isScoringDialogOpen: false,
    };

    if (publishDefaults) {
      if (publishDefaults.duration > 0) {
        const isDurationPastClassEndTime =
          datetime.toUnix(datetime.addMinutes(datetime.now(), publishDefaults.duration)) >
          cls.details.scheEndTime;
        initialState.duration = isDurationPastClassEndTime
          ? -2 // set duration to class end time
          : publishDefaults.duration;
      } else {
        initialState.duration = publishDefaults.duration;
      }

      initialState.scoring = quiz.details.scoring;
      initialState.allowLate = Boolean(publishDefaults.allowLate);
      initialState.deadlineFirst = Boolean(publishDefaults.deadlineFirst);
      initialState.randomOpt = Boolean(publishDefaults.randomOpt);
      initialState.randomQues = Boolean(publishDefaults.randomQues);
      initialState.subscribeToComments = Boolean(publishDefaults.subscribeToComments);
    }

    this.setState(initialState);
  }

  public componentWillMount() {
    this.init();
  }

  public componentDidUpdate(lastProps: IQuizPrefsDialogProps) {
    const currentProps = this.getProps();

    if (!lastProps.open && currentProps.open) {
      this.init();
    }
  }

  private showDurationMenu = () => {
    this.lastFocusedElement = document.activeElement;
    unsetTabIndices();
    this.setState({ isDurationMenuOpen: true });
  };

  private closeDurationMenu = () => {
    resetTabIndices(document.getElementById('dialog-box'));
    (this.lastFocusedElement as HTMLElement).focus();
    this.setState({ isDurationMenuOpen: false });
  };

  private handleDurationSelection = (duration: ActivityDuration) => {
    this.setState({ duration });
    this.closeDurationMenu();
  };

  private getPublishPrefs(): IQuizPublishPrefs {
    const {
      duration,
      scoring,
      deadlineFirst,
      allowLate,
      randomOpt,
      randomQues,
      subscribeToComments,
    } = this.getState();

    return {
      scoring,
      duration: duration || 0,
      deadlineFirst: toNumericBoolean(deadlineFirst),
      allowLate: toNumericBoolean(allowLate),
      randomOpt: toNumericBoolean(randomOpt),
      randomQues: toNumericBoolean(randomQues),
      dueDateType: duration === -1 ? 'manual' : 'auto',
      subscribeToComments: toNumericBoolean(subscribeToComments),
    };
  }

  /**
   * return true if all values are valid
   */
  private validate() {
    const { quiz } = this.getProps();
    const { duration } = this.getState();

    if (!duration && quiz.details.toBeDone === 'inClass') {
      this.setState({
        isDurationNotSetAlertOpen: true,
      });
      return false;
    }

    return true;
  }

  private handleSave = async () => {
    const { quiz, cls, onClose } = this.getProps();
    const { saveAsDefault } = this.getState();

    if (!this.validate()) return;

    const quizPref = this.getPublishPrefs();

    await dispatch(
      ClassActions.saveActivityPublishPrefs({
        quizPref,
        activityType: 'quiz',
        classId: cls._id,
        activityId: quiz._id,
        saveAsDefault: toNumericBoolean(saveAsDefault),
      })
    );

    onClose();
  };

  private handlePublish = async () => {
    const { quiz, cls, onPublish } = this.getProps();
    const { saveAsDefault } = this.getState();

    if (!this.validate()) return;

    const publishPref = this.getPublishPrefs();

    await dispatch(
      QuizActions.publishQuiz({
        classId: cls._id,
        toBeDone: quiz.details.toBeDone,
        quizId: quiz._id,
        dueDateTime: datetime.format(new Date(), 'YYYYMMDDTHH:mm'), // no longer used
        saveAsDefault: toNumericBoolean(saveAsDefault),
        ...publishPref,
      })
    );

    onPublish();
  };

  private durationNotSelectedAlert() {
    const { isDurationNotSetAlertOpen } = this.getState();
    return Alert(
      {
        open: isDurationNotSetAlertOpen,
        title: h('span.fc-red', 'Duration not selected'),
        actions: [
          FlatButton('OKAY', {
            onclick: () =>
              this.setState({
                isDurationNotSetAlertOpen: false,
              }),
          }),
        ],
      },
      [h('div', 'Please select a duration for the Quiz')]
    );
  }

  private showScoringSchemeDialog = () => {
    this.lastFocusedElement = document.activeElement;
    unsetTabIndices();
    this.setState({ isScoringDialogOpen: true });
  };

  private hideScoringSchemeDialog = () => {
    resetTabIndices(document.getElementById('dialog-box'));
    (this.lastFocusedElement as HTMLElement).focus();
    this.setState({ isScoringDialogOpen: false });
  };

  private scoringScheme() {
    const { scoring, isScoringDialogOpen } = this.getState();
    return h(
      'div.activity-prefs__editable-cell',
      withTabIndex({
        onclick: this.showScoringSchemeDialog,
      }),
      [
        h('span', 'Scoring'),
        h('span.activity-prefs__editable-cell-value', [
          h('span.capitalize', scoring),
          h('i.fa.fa-pencil', {
            style: { marginLeft: '0.5rem' },
          }),
        ]),
        ScoringSchemeDialog({
          open: isScoringDialogOpen,
          initialScheme: scoring,
          onCancel: this.hideScoringSchemeDialog,
          onSelect: (scoring) => {
            this.setState({ scoring });
            this.hideScoringSchemeDialog();
          },
        }),
      ]
    );
  }

  private activityDuration() {
    const { quiz, cls, isPublishing } = this.getProps();
    const { duration, isDurationMenuOpen } = this.getState();

    if (quiz.details.toBeDone === 'preClass') {
      return h('div.cell', withTabIndex(), [
        h('span', 'Deadline'),
        h(
          'span',
          datetime.format(
            datetime.addMinutes(datetime.fromUnix(cls.details.scheStartTime), -15),
            DATE_FORMAT
          )
        ),
      ]);
    }

    return h('div', [
      h(
        'div.activity-prefs__editable-cell',
        withTabIndex({
          onclick: () => this.showDurationMenu(),
        }),
        [
          h('span', 'Quiz closes'),
          h('span.activity-prefs__editable-cell-value', [
            h('span', getActivityDurationLabel(duration, isPublishing)),
            h('i.fa.fa-pencil', {
              style: { marginLeft: '0.5rem' },
            }),
          ]),
          ActivityDurationMenu({
            cls,
            duration,
            isPublishing,
            title: 'Quiz closes',
            open: isDurationMenuOpen,
            onClose: this.closeDurationMenu,
            onSelect: this.handleDurationSelection,
          }),
        ]
      ),
      duration === -1
        ? h('div.activity-prefs__helper-text', withTabIndex(), [
            'If not stopped manually, this activity will be closed ',
            'when class is completed',
          ])
        : null,
    ]);
  }

  public warnings() {
    const { isPublishing } = this.getProps();

    if (!isPublishing) return null;

    return h('div', [
      h('div.activity-prefs__title', withTabIndex(), 'WARNINGS'),
      h('div.activity-prefs__warning', withTabIndex(), [
        h('i.fa.fa-exclamation-triangle'),
        h('div', [
          'The course participants will be notified instantly via ',
          'email and push notifications.',
        ]),
      ]),
      h('div.activity-prefs__warning', withTabIndex(), [
        h('i.fa.fa-exclamation-triangle'),
        h('div', 'Once published, the activity cannot be deleted'),
      ]),
    ]);
  }

  private getLateWarningText() {
    const { allowLate } = this.getState();
    return allowLate
      ? `Late submissions will be marked as "Late". Allowing for ` +
          'late submissions can increase participation.'
      : "Students won't be able to submit the quiz after the deadline.";
  }

  private getWitholdResultWarningText() {
    const { deadlineFirst } = this.getState();
    return deadlineFirst
      ? 'Students will see their scores and the quiz answers not upon ' +
          'submitting the quiz but after the quiz deadline is over.'
      : 'Students will see their scores and answers as soon as ' + 'they submit the quiz.';
  }

  private getReorderQuestionWarningText() {
    const { quiz } = this.getProps();
    const { randomQues } = this.getState();
    return quiz.details.reordered === 1 && randomQues
      ? 'You had manually reordered the questions. Randomizing the questions will disregard the order'
      : '';
  }

  public render() {
    const { cls, quiz, open, isPublishing, onClose } = this.getProps();

    const { allowLate, randomQues, randomOpt, deadlineFirst, subscribeToComments, saveAsDefault } =
      this.getState();

    return ActivityPrefsDialog({
      open,
      onClose,
      isPublishing,
      onSave: this.handleSave,
      onPublish: this.handlePublish,
      title: 'Quiz preferences',
      body: [
        h('div.activity-prefs__title', withTabIndex(), 'DETAILS'),
        h('div.cell', withTabIndex(), [
          h('span', 'To be done'),
          h(
            'span',
            {
              preClass: 'Pre-class',
              inClass: 'In-class',
            }[quiz.details.toBeDone]
          ),
        ]),

        h('div.cell', withTabIndex(), [
          h('span', 'Class'),
          h('span', datetime.format(cls.details.scheStartTime, DATE_FORMAT)),
        ]),

        this.scoringScheme(),
        this.activityDuration(),

        h('div.activity-prefs__title', withTabIndex(), 'OPTIONS'),
        ToggleCell({
          label: 'Allow late submissions',
          isSelected: allowLate,
          onToggle: () =>
            this.setState({
              allowLate: !allowLate,
            }),
          helperText: this.getLateWarningText(),
        }),
        ToggleCell({
          label: 'Withhold results till deadline',
          isSelected: deadlineFirst,
          onToggle: () =>
            this.setState({
              deadlineFirst: !deadlineFirst,
            }),
          helperText: this.getWitholdResultWarningText(),
        }),
        ToggleCell({
          label: "Randomize Quiz Questions' Order",
          isSelected: randomQues,
          onToggle: () =>
            this.setState({
              randomQues: !randomQues,
            }),
          helperText: this.getReorderQuestionWarningText(),
          variant: 'danger',
        }),
        ToggleCell({
          label: "Randomize Options' Order",
          isSelected: randomOpt,
          onToggle: () =>
            this.setState({
              randomOpt: !randomOpt,
            }),
        }),

        h('div.activity-prefs__title', withTabIndex(), 'ACTIVITY DISCUSSION'),
        ToggleCell({
          label: 'Subscribe to comment notifications',
          isSelected: subscribeToComments,
          onToggle: () =>
            this.setState({
              subscribeToComments: !subscribeToComments,
            }),
          helperText:
            'If you subscribe, you will receive a notification ' +
            "whenever someone adds a comment to this activity's discussion",
        }),

        ToggleCell({
          label: 'Save as default',
          isSelected: saveAsDefault,
          onToggle: () =>
            this.setState({
              saveAsDefault: !saveAsDefault,
            }),
          helperText: `Use same publish preferences as default for all ${
            {
              preClass: 'pre-class',
              inClass: 'in-class',
            }[quiz.details.toBeDone]
          } quizzes`,
        }),

        this.warnings(),
        this.durationNotSelectedAlert(),
      ],
    });
  }
}

export default (props: IQuizPrefsDialogProps) => h(QuizPrefsDialog, props);
