/**
 *  Since this widget is in a hot loop, we would like
 * to minimize dynamic allocation of objects and
 *  inline styles as much as possible for fast rendering
 * For this reason, the static styles for this div
 * are defined in ClassWidget.css
 */

import { CSS, h, IComponent } from 'core';

import * as NowPlayingIcon from 'assets/now_playing.svg';
import * as VideoCamIcon from 'assets/videocam.svg';

import {
  addActivityCounts,
  Class,
  EMPTY_ACTIVITY_COUNTS,
  getTotalActivityCount,
  IActivityCounts,
  NODE_TYPES,
} from 'acadly/class/functions';
import * as commentsFunctions from 'acadly/comments/functions';
import DateView from 'acadly/common/DateView';
import Icon from 'acadly/common/Icon';
import SvgIcon from 'acadly/common/SvgIcon';
import courseService from 'acadly/course/service';
import * as datetime from 'acadly/datetime';
import * as dt from 'acadly/datetime';
import icons from 'acadly/icons';
import { getStore } from 'acadly/store';
import { colors } from 'acadly/styles';
import { capitalize } from 'acadly/utils';
import * as u from 'acadly/utils';

export interface IClassWidgetClass {
  _id: IClass['_id'];
  details: IClass['details'];
  info: IClass['info'];
  activities: IClass['activities'];
  userData?: IClass['userData'];
}

export interface IClassWidgetProps {
  cls: IClass | IClassWidgetClass;
  tag: IClassTeamRole;
  hideActivityIcons?: boolean;
  courseHasInstructors?: boolean;
  isCourseArchived?: boolean;
  courseRole?: ICourseRole | null;
  onclick?(event: Event): any;
  deleteAction?(event: Event): any;
  style?: CSS;
  tabIndex?: number;
}

export const ClassWidget = (props: IClassWidgetProps) =>
  h(ClassWidgetC, {
    ...props,
    key: props.cls._id,
  });
export default ClassWidget;

const BELL_ICON = Icon(icons.bellLight, {
  className: 'class-widget__bell-icon',
});

const VIDEO_CAM_ICON = SvgIcon({
  icon: VideoCamIcon,
  className: 'class-widget__videocam-icon',
});

const NOW_PLAYING_ICON = SvgIcon({
  noFill: true,
  icon: NowPlayingIcon,
  className: 'class-widget__videocam-icon',
});

const LECTURE_INCHARGE_WARNING = h('div.fc-red', ['Please set a class in-charge']);

export interface ILectureWidgetVM {
  icons: {
    icon: string;
    bubble?: 'comments' | 'star';
    color: string;
  }[];
  widgetColor: string;
}
const IN_SESSION = 'inSession';
export class ClassWidgetC extends IComponent<IClassWidgetProps, { vm: ILectureWidgetVM }> {
  public componentWillMount() {}

  public componentWillReceiveProps({ cls, courseRole }: IClassWidgetProps) {
    if (cls !== this.getProps().cls && courseRole !== this.getProps().courseRole) {
      this.setState({
        vm: makeLectureVM({
          cls: cls,
          courseRole: courseRole || 'student',
        }),
      });
    }
  }

  public render() {
    const props = this.getProps();
    const { cls } = props;
    const typ = cls.details.type;
    switch (typ) {
      case 'lecture':
        return h(LectureWidget, props);
      default:
        return h(TutorialWidget, props);
    }
  }
}

const COMMENT_BUBBLE = h('i.fa.fa-comment.class-widget__comment-bubble');

const deleteButton = (onclick?: (e: Event) => any) =>
  Icon(icons.trash, {
    className: 'class-widget__delete-button',
    onclick: onclick,
  });

const IN_SESSION_TEXT = 'In session';
const CANCELED = 'canceled';
const HOLIDAY = 'holiday';
const HOLIDAY_TEXT = 'Holiday';
const OPEN = 'open';
const CLOSED = 'closed';
const COMPLETED_TEXT = 'Completed';
const NO_RECORD = 'noRecord';
const NO_RECORD_TEXT = 'No record';
class LectureWidget extends IComponent<IClassWidgetProps, never> {
  public render() {
    const props = this.getProps();
    const { cls, tag } = props;
    if (cls.details.type !== 'lecture') throw 'Error';
    const startTime = datetime.fromUnix(cls.details.scheStartTime);
    const endTime = datetime.fromUnix(cls.details.scheEndTime);
    const widgetColor = this.getLectureColor();
    const altText = this.getAltTextForLecture();
    return h(
      'div',
      {
        className: 'class-widget',
        id: `class-widget-${cls._id}`,
        tabIndex: props.tabIndex,
        style: {
          ...props.style,
          color: widgetColor,
        },
        'aria-label': altText,
        key: `class-widget-${cls._id}`,
        onclick: this.getProps().onclick,
      },
      [
        dateCell(startTime, widgetColor, tag),
        h('div.cell-rhs', [
          this.lectureTitleRow(),
          this.lectureTimingsRow(startTime, endTime),
          !props.hideActivityIcons
            ? this.isLectureInchargeNotSetWarningVisible()
              ? this.lectureInchargeWarning()
              : this.getClassActivitiesRow()
            : this.activityCountRow(),
        ]),
        props.deleteAction && props.courseRole === 'admin'
          ? deleteButton(this.onClickDelete)
          : null,
      ]
    );
  }

  private onClickDelete = async (e: Event) => {
    const props = this.getProps();
    if (props.deleteAction) {
      e.stopPropagation();
      props.deleteAction(e);
    }
  };

  private getAltTextForLecture() {
    const { cls } = this.getProps();
    const discussionCount =
      cls.activities.inClass.discussions.numPublished +
      cls.activities.preClass.discussions.numPublished;
    const resourceCount =
      cls.activities.inClass.resources.numPublished +
      cls.activities.preClass.resources.numPublished;
    const pollCount =
      cls.activities.inClass.polls.numPublished + cls.activities.preClass.polls.numPublished;
    const quizCount =
      cls.activities.inClass.quizzes.numPublished + cls.activities.preClass.quizzes.numPublished;
    const queriesCount =
      cls.activities.inClass.queries.numTotal + cls.activities.preClass.queries.numTotal;
    const altTextDate = dt.format(cls.details.scheStartTime, 'ddd, Do MMM [at] HH:mm A');
    const status = cls.details.status;
    const isNoActivityPresent =
      discussionCount === 0 &&
      pollCount === 0 &&
      resourceCount === 0 &&
      quizCount === 0 &&
      queriesCount === 0;
    if (isNoActivityPresent) {
      return (
        `Class on ${altTextDate} ${status === 'canceled' ? 'canceled' : ''}` +
        `Class Title: ${cls.details.title || 'Untitled'}` +
        ` No activities present`
      );
    } else {
      return (
        `Class on ${altTextDate} ${status === 'canceled' ? 'canceled' : ''} ` +
        `Class Title: ${cls.details.title || 'Untitled'} ` +
        `${discussionCount} Discussion${discussionCount !== 1 ? 's' : ''} ` +
        `${resourceCount} Resource${resourceCount !== 1 ? 's' : ''} ` +
        `${pollCount} Poll${pollCount !== 1 ? 's' : ''} ` +
        `${quizCount} Quiz${pollCount !== 1 ? 'zes' : ''} ` +
        `${queriesCount} Quer${queriesCount !== 1 ? 'ies' : 'y'} `
      );
    }
  }

  private lectureTitleRow() {
    const cls = this.getProps().cls;
    return h('div.lecture__title', [
      h('div.lecture__title__text-attr', [
        cls.details.title && cls.details.title.trim().length > 1
          ? `Class - ${cls.details.title}`
          : `Class`,
      ]),
      cls.userData && commentsFunctions.getUnseenComments(<IClass>cls) > 0 ? COMMENT_BUBBLE : null,
    ]);
  }

  private lectureTimingsRow(scheStartTime: Date, scheEndTime: Date) {
    const { cls } = this.getProps();
    const { classMediaPlayer } = getStore().getState().pipContainers;

    const isNowPlaying = classMediaPlayer.show && classMediaPlayer.classId === cls._id;

    const lectureIcon = isNowPlaying
      ? NOW_PLAYING_ICON
      : cls.details.isOnlineMeeting
      ? VIDEO_CAM_ICON
      : BELL_ICON;

    if (cls.details.status === OPEN) {
      return h('div.lecture__timings-row-attrs', [
        lectureIcon,
        `${formatTime(scheStartTime)} - ${formatTime(scheEndTime)}`,
        isNowPlaying ? h('span.tag.green', 'Now playing') : null,
      ]);
    }
    return h('div.lecture__timings-row-attrs', [
      lectureIcon,
      isNowPlaying ? h('span.tag.green', 'Now playing') : null,
      cls.details.status === IN_SESSION ? IN_SESSION_TEXT : null,
      cls.details.status === CANCELED ? CANCELED_TEXT : null,
      cls.details.status === HOLIDAY ? HOLIDAY_TEXT : null,

      cls.details.status === CLOSED ? COMPLETED_TEXT : null,
      cls.details.status === NO_RECORD ? NO_RECORD_TEXT : null,
    ]);
  }

  private getVM() {
    return makeLectureVM({
      cls: this.getProps().cls,
      courseRole: this.getProps().courseRole || 'student',
    });
  }

  private getLectureColor() {
    return this.getVM().widgetColor;
  }

  private activityCountRow() {
    const { cls } = this.getProps();
    return h('div.activity-counts.fc-light-grey', [
      `Total activities: ${getTotalActivityCount(cls)}`,
    ]);
  }

  private lectureInchargeWarning() {
    return LECTURE_INCHARGE_WARNING;
  }

  private getClassActivitiesRow() {
    const props = this.getProps();
    const cls = this.getProps().cls;

    const vm = makeLectureVM({
      cls,
      courseRole: props.courseRole || 'student',
    });
    const hasActivities = vm.icons.length > 0;
    if (!hasActivities) {
      return h('span.class-widget__no-activities', 'No activities');
    } else {
      return h('span.class-widget__activities', [
        h('span.class-widget__activities-title', ['Activities']),
        ...vm.icons.map((i) =>
          h(
            'span.class-widget__activity-icon',
            {
              key: `${cls._id}-${i.icon}`,
              style: { color: i.color },
            },
            [
              Icon(i.icon, { className: 'auto-margin' }),
              i.bubble
                ? i.bubble === 'star'
                  ? h('div.notify.star', {})
                  : h('div.notify', {})
                : null,
            ]
          )
        ),
      ]);
    }
  }

  private isLectureInchargeNotSetWarningVisible() {
    const { cls, courseRole, courseHasInstructors, isCourseArchived } = this.getProps();
    const course = courseService.getCurrentCourse()!;
    return (
      course.status.courseLive &&
      courseHasInstructors &&
      !cls.details.classInchargeSet &&
      courseRole === 'admin' &&
      !isCourseArchived &&
      cls.details.scheEndTime > datetime.unix()
    );
  }
}

class TutorialWidget extends IComponent<IClassWidgetProps, never> {
  public render() {
    const props = this.getProps();
    const { cls, tag } = props;
    const startTime = datetime.fromUnix(cls.details.scheStartTime);
    const endTime = datetime.fromUnix(cls.details.scheEndTime);
    const altTextDate = dt.format(cls.details.scheStartTime, 'HH:mma on Do MMM');
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const color = this.getNonLectureColor(cls);
    return h(
      'div.tutorial-widget',
      {
        style: {
          color: color,
        },
        tabIndex: isAccessible ? 0 : undefined,
        key: cls._id,
        'aria-label': `Tutorial at ${altTextDate}`,
        onclick: this.getProps().onclick,
      },
      [
        dateCell(startTime, color, tag),
        h('div.tutorial-widget__rhs', [
          this.tutorialTitleRow(cls),
          this.tutorialTimeRow(startTime, endTime),
          this.tutorialVenueRow(cls),
        ]),
        props.deleteAction ? deleteButton(props.deleteAction) : null,
      ]
    );
  }

  private tutorialTitleRow(cls: IClass | IClassWidgetClass) {
    return h('div.tutorial-widget__title', [
      capitalize(cls.details.type),
      cls.details.status === 'canceled' ? ' - Cancelled' : '',
    ]);
  }

  private tutorialTimeRow(startTime: Date, endTime: Date) {
    return h('div.tutorial-widget__time', [
      BELL_ICON,
      h('span', formatTime(startTime) + ' - ' + formatTime(endTime)),
    ]);
  }

  private tutorialVenueRow(cls: IClass | IClassWidgetClass) {
    return h('div.class-widget__venue-row', [LOCATION_ICON, h('span', cls.details.venue)]);
  }

  private getNonLectureColor(cls: IClass | IClassWidgetClass) {
    if (
      cls.details.scheEndTime < datetime.unix() ||
      cls.details.status === 'canceled' ||
      cls.details.status === 'holiday'
    ) {
      return '#bababa';
    } else if (
      datetime.diff(datetime.fromUnix(cls.details.scheStartTime), datetime.now(), 'hours') < 24
    ) {
      return colors.pink;
    } else {
      return colors.blue;
    }
  }
}

const CANCELED_TEXT = 'Canceled';

function formatTime(date: Date) {
  // return datetime.format(date, "hh:mm A");
  const hours = date.getHours();
  const hh = hours > 12 ? hours - 12 : hours;
  const mm = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  return `${u.padStart(hh.toString(), 2, '0')}:${u.padStart(mm.toString(), 2, '0')} ${ampm}`;
}

const LOCATION_ICON = Icon(icons.location, {
  className: 'class-widget__location-icon',
});

function dateCell(startTime: Date, widgetColor: string, tag: IClassTeamRole) {
  return h('div.timeline-widget__icon', [
    DateView(startTime, widgetColor),
    tag !== 'none' ? h('div.class-widget__role', tag) : null,
  ]);
}

export const NODE_TYPE_ICON = {
  quiz: icons.quiz,
  poll: icons.poll,
  discussion: icons.discussion,
  resource: icons.resourceURL,
  query: icons.query,
};

export function makeLectureVM({
  cls,
  courseRole,
}: {
  cls: IClassWidgetClass;
  courseRole: ICourseRole;
}): ILectureWidgetVM {
  const actIcons: ILectureWidgetVM['icons'] = [];
  const countsArray = Class.activityCounts(cls);
  const widgetColor = getLectureColor(cls);
  for (const nodeType of NODE_TYPES) {
    const counts = countsArray
      .filter((o) => o.nodeType === nodeType)
      .reduce(addActivityCounts, EMPTY_ACTIVITY_COUNTS);
    const color = getIconColor({
      cls,
      counts,
      courseRole,
      nodeType,
    });
    let total = counts.numTotal;
    if (nodeType === 'query' && courseRole !== 'student') {
      total += counts.numPending;
    }
    if (total > 0) {
      let bubble: 'star' | 'comments' | undefined = undefined;
      if (total > counts.numSeen) {
        bubble = 'star';
      } else if (counts.numComments > counts.numCommentsSeen) {
        bubble = 'comments';
      }
      if (courseRole !== 'student' && nodeType !== 'query' && bubble === 'star') {
        bubble = undefined;
      }
      actIcons.push({
        icon: NODE_TYPE_ICON[nodeType],
        color: color,
        bubble,
      });
    }
  }

  return {
    icons: actIcons,
    widgetColor: widgetColor,
  };
}

function getIconColor({
  cls,
  courseRole,
  counts,
  nodeType,
}: {
  cls: IClassWidgetClass;
  nodeType: IClassActivity['nodeType'];
  counts: IActivityCounts;
  courseRole: ICourseRole;
}): string {
  let color;
  if (cls.details.status === 'inSession') {
    color = colors.green;
  } else {
    if (courseRole === 'student') {
      if (nodeType === 'quiz' || nodeType === 'poll') {
        const allCompleted = counts.numCompleted >= counts.numTotal;
        if (allCompleted) {
          color = colors.widgetIconGrey;
        } else if (isDeadlineNear(cls.details.scheEndTime)) {
          color = colors.pink;
        } else if (isDeadlinePast(cls.details.scheEndTime)) {
          color = colors.red;
        } else {
          color = colors.blue;
        }
      } else {
        const isSeen = counts.numSeen >= counts.numTotal;
        if (isSeen) {
          color = colors.widgetIconGrey;
        } else {
          color = colors.blue;
        }
      }
    } else {
      if (nodeType === 'query') {
        if (counts.numPending > 0) {
          color = colors.orange;
        } else if (counts.numTotal > counts.numSeen) {
          color = colors.blue;
        } else if (counts.numTotal > counts.numClosed) {
          color = colors.blue;
        } else {
          color = colors.widgetIconGrey;
        }
      } else {
        if (counts.numPublished < counts.numTotal) {
          color = colors.red;
        } else {
          color = colors.widgetIconGrey;
        }
      }
    }
  }
  return color;
}

function isDeadlinePast(deadline: UnixTimestamp) {
  return deadline < datetime.unix();
}

function isDeadlineNear(endTimestamp: UnixTimestamp) {
  const now = datetime.now();
  const endTime = datetime.fromUnix(endTimestamp);
  const hoursToEndTime = datetime.diff(endTime, now, 'hours');
  return hoursToEndTime >= 0 && hoursToEndTime <= 24;
}

const LECTURE_GREY = '#bababa';
function getLectureColor(cls: IClassWidgetClass, currentDate = datetime.now()) {
  if (
    ['holiday', 'canceled', 'norecord', 'closed'].indexOf(cls.details.status.toLowerCase()) > -1
  ) {
    return LECTURE_GREY;
  } else if (cls.details.status === 'inSession') {
    return colors.green;
  } else {
    if (cls.details.scheEndTime < datetime.toUnix(currentDate)) {
      return LECTURE_GREY;
    } else if (
      datetime.diff(datetime.fromUnix(cls.details.scheStartTime), currentDate, 'hours') < 24
    ) {
      return colors.pink;
    } else {
      return colors.blue;
    }
  }
}
