import { CSS, h, View } from 'core';

import { getHTMLTagSelector } from 'acadly/utils';

import { getStore } from '../store';
import { BasicButton, IBasicButtonProps, IBasicButtonState } from './BasicButton';

interface IFloatingBarProps extends IBasicButtonProps {
  style?: CSS;
  position: 'bottom-left' | 'bottom-right';
  onclick: (event: Event) => any;
  tabIndex?: number;
  ariaLabel?: string;
}

export default (props: IFloatingBarProps, children: View[]) =>
  h(FloatingActionBar, {
    ...props,
    children,
  });

export class FloatingActionBar extends BasicButton<
  IFloatingBarProps & { children: View[] },
  IBasicButtonState
> {
  public render() {
    const children = this.getProps().children;
    const props = this.getProps();

    const isMobile = getStore().getState().app.isMobile;
    const narrowScreen = getStore().getState().app.narrowScreen;

    return h(
      getHTMLTagSelector(
        'div',
        [
          'floating-action-bar',
          this.isDisabled() ? 'disabled' : 'ripple',
          props.position,
          isMobile ? 'mobile' : '',
          narrowScreen ? 'narrow-screen' : '',
          ...(props.classNames || []),
        ],
        this.getProps().id
      ),
      {
        onclick: this.clickHandler(),
        role: 'button',
        ariaLabel: this.getProps().ariaLabel ? this.getProps().ariaLabel : undefined,
        tabIndex: this.getProps().tabIndex !== null ? this.getProps().tabIndex : undefined,
        style: props.style,
      },
      children
    );
  }
}
