import { h, IComponent } from 'core';
import { VCallStatus } from 'core/enums';

import AcadlyEvents from 'acadly/AcadlyEvents';
import { Actions as AppActions } from 'acadly/app/actions';
import appService from 'acadly/app/service';
import { Class as ClassFunctions } from 'acadly/class/functions';
import Avatar from 'acadly/common/Avatar';
import ContentView from 'acadly/common/ContentView';
import RaisedButton from 'acadly/common/RaisedButton';
import Toggle from 'acadly/common/Toggle';
import courseService from 'acadly/course/service';
import { logger } from 'acadly/logger';
import { requiresRole, visibleTo } from 'acadly/permissions';
import { Actions as QueryActions } from 'acadly/query/actions';
import { dispatch, getStore } from 'acadly/store';
import * as u from 'acadly/utils';

import Tabs from '../common/SlidingTabs';
import * as datetime from '../datetime';
import { Routes } from '../routes';
import { mainPanelWidth } from '../styles';
import { Actions } from './actions';
import Activities from './Activities';
import Agenda from './Agenda';
import Attendance from './Attendance';
import classService from './service';

export default (props: IClassProps) => h(Class, props);
interface IClassProps {
  cls: IClass;
  course: ICourse;
  courseRole: ICourseRole;
  isIncharge: boolean;
}

interface IClassState {
  instructorPicked: IClassTeamMember | null;
  isSettingInstructor: boolean;
  isTrueForSimilarLectures: boolean;
  autoCloudRecord: 0 | 1;
}

class Class extends IComponent<IClassProps, IClassState> {
  public componentWillMount() {
    const props = this.getProps();
    this.initialize(props);
  }

  public componentWillReceiveProps(nextProps: IClassProps) {
    if (nextProps.cls._id !== this.getProps().cls._id) {
      dispatch(Actions.closeClassScreen(undefined));
      this.initialize(nextProps);
    }
  }

  private startClassTimer?: NodeJS.Timer;

  private startClass({ cls }: IClassProps) {
    if (cls.details.status !== 'open') return;

    dispatch(Actions.startClass({ classId: cls._id }));
    console.log('class started');
  }

  private unscheduleStartClassWorker() {
    if (this.startClassTimer) {
      console.log('unscheduling start class worker');
      clearInterval(this.startClassTimer);
      this.startClassTimer = undefined;
    }
  }

  /**
   * Starts timer for auto starting class at its scheduled time
   */
  private scheduleStartClassWorker(props: IClassProps) {
    const { cls } = props;
    const startTime = cls.details.scheStartTime;
    const secondsToStartTime = startTime - datetime.unix();

    if (secondsToStartTime > 60 * 60) {
      // if more than 1 hour is left then do nothing
      return;
    }

    if (secondsToStartTime <= 0) {
      this.startClass(props);
      return;
    }

    // stop previous workers
    this.unscheduleStartClassWorker();

    console.log('scheduling start class worker');

    this.startClassTimer = setInterval(() => {
      const secondsToStartTime = startTime - datetime.unix();
      if (secondsToStartTime <= 0 && this.startClassTimer !== undefined) {
        this.unscheduleStartClassWorker();
        this.startClass(props);
        this.autoCheckInStudent();
      }
    }, 1000);
  }

  private async initialize(props: IClassProps) {
    const { cls } = props;

    const initialState: IClassState = {
      instructorPicked: null,
      isSettingInstructor: false,
      isTrueForSimilarLectures: false,
      autoCloudRecord: 0,
    };

    await this.setState(initialState);

    if (!getStore().getState().comments.comments) {
      /**
       * When page is reloaded on class page,
       * comments can't be loaded because context
       * is set before class object is fetched,
       * so, if comments aren't already loaded,
       * we reset the context to force loading of
       * comments.
       */
      dispatch(AppActions.setContext('course'));
      dispatch(AppActions.setContext('class'));
    }

    await dispatch(Actions.fetchClassActivities(cls));

    // start timer for auto starting class at its scheduled time
    this.scheduleStartClassWorker(props);

    const storeState = getStore().getState();
    const clsData = storeState.class.data;

    // set default sort order for student list
    dispatch(Actions.setSortStudentOrder(storeState.app.sortStudentBy));

    if (clsData) {
      await this.autoCheckInStudent();
      this.setAnalyticsForUnseenHiddenQueries(u.objectValues(storeState.queries.byId));
      this.joinVCallMeeting(props);

      if (clsData.autoRecordDefaults) {
        this.setState({
          autoCloudRecord: clsData.autoRecordDefaults.autoCloudRecord,
        });
      }
    }

    setTimeout(() => {
      const tabs = document.getElementById('sliding-tabs');
      if (tabs) {
        tabs.focus();
      }
    }, 500);
  }

  private async autoCheckInStudent() {
    const { cls, courseRole } = this.getProps();
    if (courseRole !== 'student') return;

    const now = datetime.unix();
    const isCheckedIn = ClassFunctions.isCheckedIn(cls);
    const { scheEndTime, scheStartTime } = cls.details;

    if (!isCheckedIn && scheStartTime < now && now < scheEndTime) {
      await dispatch(Actions.checkIn({ agent: 'web', classId: cls._id, locationAvailable: 0 }));
    }
  }

  private joinVCallMeeting(props: IClassProps) {
    const { cls, course, courseRole, isIncharge } = props;

    const storeState = getStore().getState();
    const clsData = storeState.class.data;

    if (!course.canStream || !clsData) return;

    const onlineDetails = clsData.onlineDetails;

    if (!onlineDetails) return;

    const { joinId, meetingId, meetingPassword, meetingInProgress, beingBroadcast } = onlineDetails;

    const { canAutoJoin } = storeState.app.vCallFrame;
    const isMeetingInProgress = meetingInProgress === VCallStatus.IN_PROGESS;
    const canStudentJoin = canAutoJoin && ClassFunctions.isCheckedIn(cls) && isMeetingInProgress;

    if (
      (courseRole === 'student' && canStudentJoin) ||
      (courseRole !== 'student' && !isIncharge && isMeetingInProgress) ||
      (isIncharge && isMeetingInProgress && !beingBroadcast)
    ) {
      dispatch(
        AppActions.initVCallFrame({
          courseId: course._id,
          classId: cls._id,
          role: classService.getRole(),
          joinId,
          meetingId,
          meetingInProgress,
          meetingPassword,
          beingBroadcast,
        })
      );
    } else if (isIncharge && isMeetingInProgress) {
      AcadlyEvents.next({ type: '@vcall/update_frame_mode' });
    }
  }

  public componentWillUnmount() {
    // stop start class worker
    this.unscheduleStartClassWorker();
    dispatch(Actions.closeClassScreen(undefined));
  }

  private isAccessible: boolean | undefined = false;

  public render() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    return h('div.class-page', [
      this.isClassInchargeScreenVisible()
        ? visibleTo(['admin'], this.classInchargeScreen())
        : this.main(),
    ]);
  }

  private isClassInchargeScreenVisible() {
    const { cls, course, courseRole } = this.getProps();
    return (
      course.status.courseLive === 1 &&
      !cls.details.classInchargeSet &&
      courseRole === 'admin' &&
      cls.details.dueDateTime > datetime.unix() &&
      !course.isArchived &&
      this.getCourseInstructors().length > 0
    );
  }

  private classInchargeScreen() {
    const course = this.getProps().course;
    const admin = course.team.filter((m) => m.role === 'admin')[0];
    const instructors = this.getCourseInstructors();
    const cls = this.getProps().cls;
    const { instructorPicked } = this.getState();
    const pickedUserId = instructorPicked ? instructorPicked.userId : null;
    const day = datetime.format(cls.details.scheStartTime, 'dddd');
    const startTime = datetime.format(cls.details.scheStartTime, 'hh:mm A');
    const setInstructorButtonStatus = pickedUserId !== null && !this.getState().isSettingInstructor;
    return ContentView(
      h('div.class-incharge', [
        h(
          'div.class-incharge__heading',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          'Default class in-charge'
        ),
        h(
          'div.user.user--small.class-incharge__option',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            Avatar(admin.avatar, admin.name, { className: 'user__avatar' }),
            h('span.class-incharge__option__title.user__details', admin.name),
          ]
        ),
        h(
          'div.class-incharge__heading',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          'Active class instructors (you can select from these)'
        ),
        ...[admin].concat(instructors).map((i) =>
          h(
            u.getHTMLTagSelector('div', [
              'user',
              'user--small',
              'class-incharge__option',
              'pointer',
              pickedUserId === i.userId ? 'selected' : '',
            ]),
            {
              key: i.userId,
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => this.setState({ instructorPicked: i }),
            },
            [
              Avatar(i.avatar, i.name, { className: 'user__avatar' }),
              h('div.class-incharge__option__title.user__details', i.name),
              h('i.fa.fa-check-circle-o.class-incharge__option__check'),
            ]
          )
        ),
        h(
          'div.cell.cell--card',
          {
            'aria-label': 'Set for similar Classes toggle',
          },
          [
            h('span.cell__label', 'Set for all similar classes'),
            Toggle({
              className: 'cell__value',
              selected: this.getState().isTrueForSimilarLectures,
              ontoggle: () =>
                this.setState({
                  isTrueForSimilarLectures: !this.getState().isTrueForSimilarLectures,
                }),
            }),
          ]
        ),
        h('div.class-incharge__note', [
          `Sets the selected instructor as the class in-charge for`,
          ` all future classes that happen every ${day} at ${startTime}`,
        ]),
        RaisedButton('SET INSTRUCTOR', {
          tabIndex: this.isAccessible ? 0 : undefined,
          ariaLabel: `Set Instructor Button [${setInstructorButtonStatus ? 'Active' : 'Inactive'}]`,
          disabled: !setInstructorButtonStatus,
          onclick: () => this.setIncharge(),
          classNames: [setInstructorButtonStatus ? 'fc-green' : 'fc-light-grey'],
        }),
      ])
    );
  }

  @requiresRole('admin')
  private async setIncharge() {
    const { instructorPicked, isSettingInstructor, isTrueForSimilarLectures } = this.getState();
    const multiple = isTrueForSimilarLectures ? 1 : 0;
    const { cls } = this.getProps();
    if (isSettingInstructor) return;
    if (!instructorPicked) return;
    if (instructorPicked.role === 'ta') return;
    await this.setState({
      isSettingInstructor: true,
    });
    await dispatch(
      Actions.setClassIncharge({
        classId: cls._id,
        inCharge: {
          userId: instructorPicked.userId,
          name: instructorPicked.name,
          avatar: instructorPicked.avatar,
          role: instructorPicked.role as 'admin' | 'instructor',
        },
        multiple: multiple,
      })
    );
    await this.setState({
      isSettingInstructor: false,
    });
  }

  private getCourseInstructors() {
    return this.getProps()
      .course.team.filter((m) => m.role === 'instructor')
      .filter((m) => m.status === 'active');
  }

  private main() {
    const props = this.getProps();
    const course = props.course;
    const { autoCloudRecord } = this.getState();
    let activeTab: number;
    if (Routes.classActivities.isActive()) {
      activeTab = 0;
    } else if (Routes.classAgenda.isActive()) {
      activeTab = 1;
    } else if (Routes.classAttendance.isActive()) {
      activeTab = 2;
    } else {
      throw new Error('Wrong route');
    }
    const cls = props.cls;
    return Tabs({
      activeTab,
      windowWidth: mainPanelWidth,
      id: 'tabs-container',
      maxWindowWidth: '100%',
      noIcons: true,
      hideTabs:
        !Routes.classActivities.isActive(true) &&
        !Routes.classAttendance.isActive(true) &&
        !Routes.classAgenda.isActive(true),
      tabs: [
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          title: 'Activities',
          alternateText: 'Class Activities',
          view: Activities({
            cls,
            course,
            autoCloudRecord,
            courseRole: props.courseRole,
            isIncharge: props.isIncharge,
            onAutoCloudRecordChange: (autoCloudRecord) => {
              this.setState({ autoCloudRecord });
            },
          }),
          navigate: () =>
            Routes.classActivities.navigate({
              courseShortId: courseService.getShortIdFromCourseId(props.course._id),
              classShortId: classService.getShortIdFromClassId(cls._id),
              univSlug: appService.getUniversitySlug(),
            }),
        },
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          title: 'Agenda',
          alternateText: 'Class Agenda',
          view: Agenda({
            cls: cls,
            isIncharge: props.isIncharge,
            courseRole: props.courseRole,
          }),
          navigate: () =>
            Routes.classAgenda.navigate({
              courseShortId: courseService.getShortIdFromCourseId(props.course._id),
              classShortId: classService.getShortIdFromClassId(cls._id),
              univSlug: appService.getUniversitySlug(),
            }),
        },
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          title: 'Attendance',
          alternateText: 'Class Attendance',
          view: Attendance({
            cls: cls,
            isIncharge: props.isIncharge,
            isTabOpen: activeTab === 2,
            courseRole: props.courseRole,
          }),
          navigate: () =>
            Routes.classAttendance.navigate({
              courseShortId: courseService.getShortIdFromCourseId(props.course._id),
              classShortId: classService.getShortIdFromClassId(cls._id),
              univSlug: appService.getUniversitySlug(),
            }),
        },
      ],
    });
  }

  private setAnalyticsForUnseenHiddenQueries(queries: IQuery[]) {
    const { cls } = this.getProps();
    const unseenHidden = queries.filter((q) => q.details.isHidden).filter((q) => !q.userData);
    Promise.all(
      unseenHidden.map((q) =>
        dispatch(
          QueryActions.analyticsSet({
            request: {
              queryId: q._id,
              firstAccess: 1,
              localTime: datetime.format(datetime.now(), 'YYYYMMDDTHH:mm'),
            },
            classId: cls._id,
            toBeDone: q.details.toBeDone,
          })
        )
      )
    ).catch((e) => logger.error(e));
  }
}
