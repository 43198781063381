import { h, IComponent } from 'core';

import { Actions as appActions } from 'acadly/app/actions';
import { Actions as ClassActions, IExportGradesSuccessResponse } from 'acadly/class/actions';
import classService from 'acadly/class/service';
import Avatar from 'acadly/common/Avatar';
import ContentView from 'acadly/common/ContentView';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import { fullScreenLoader } from 'acadly/common/Loader';
import RangeSlider from 'acadly/common/RangeSlider';
import StudentList, { getStudentListData } from 'acadly/common/StudentList';
import TextField from 'acadly/common/TextField';
import Toggle from 'acadly/common/Toggle';
import User from 'acadly/common/User';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import { logger } from 'acadly/logger';
import { dispatch, getStore } from 'acadly/store';
import { colors, margin, ml, mr, mt, pad, pt, style } from 'acadly/styles';
import * as u from 'acadly/utils';

import Alert from '../common/Alert';
import Icon from '../common/Icon';
import RadioButton from '../common/RadioButton';
import icons from '../icons';
import { Actions } from './actions';
import { getFormattedClassTimings } from './functions';

interface Award {
  label: string;
  points: number;
}

export interface IClassAnalyticsState {
  canMarkMultipleStudents: boolean;
  isParticipationDialogOpen: boolean;
  participationDialogOpenFor: string[];
  participationAward: Award;
  selectedLabel: string;
  newLabelInput: string;
  showMoreLabels: boolean;
  showStudentType: 'present' | 'checkedIn' | 'absent' | 'notCheckedIn' | 'all';
  attendanceForm: ObjectMap<boolean | undefined>; // map of students marked present
  searchField: string;
  isDownloadAlertOpen: boolean;
  isExportDialogOpen: boolean;
  exportRequestIsEmail: 0 | 1;
  sendEmail: boolean;
  exportDownloadLink: string;
  exportFileName: string;
}

const LABEL_STYLE = {
  background: colors.blue,
  color: colors.white,
  borderRadius: '4px',
  padding: '0.25rem 0.5rem',
  margin: '0.25rem',
  cursor: 'pointer',
  userSelect: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  textTransform: 'uppercase',
  fontSize: '0.75rem',
};
// const FILTER_PREDICATE_MAP = {
//     "all": () => true,
//     "present": (s: IAttendanceTabStudent) => s.status === "present",
//     "absent": (s: IAttendanceTabStudent) => s.status === "absent",
//     "checkedIn": (s: IAttendanceTabStudent) => s.isCheckedIn,
//     "notCheckedIn": (s: IAttendanceTabStudent) => !s.isCheckedIn,
// };
export class ClassAnalytics extends IComponent<
  {
    cls: IClass | null;
  },
  IClassAnalyticsState
> {
  private isAccessible: boolean;
  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    this.setState({
      canMarkMultipleStudents: false,
      isParticipationDialogOpen: false,
      participationDialogOpenFor: [],
      participationAward: { label: '', points: 0 },
      selectedLabel: '',
      newLabelInput: '',
      showMoreLabels: false,
      attendanceForm: {},
      searchField: '',
      showStudentType: 'all',
      isDownloadAlertOpen: false,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
      exportDownloadLink: '',
      exportFileName: '',
    });
    this.participationDataFetch();
  }
  public componentWillUnmount() {
    dispatch(appActions.startTip(true));
  }

  private getParticipationMessage(cls: IClass) {
    const { status, scheStartTime } = cls.details;
    const classRole = classService.getRole();
    const currentTime = dt.unix();

    const isOpen = status === 'open' && currentTime < scheStartTime;
    const assistant = cls.info.team.assistants[0];
    const incharge = cls.info.team.inCharge;

    if (isOpen && classRole === 'none' && assistant) {
      return (
        `Only class team members - ${incharge.name}, class in-charge or ` +
        `${assistant.name}, class assistant - can award participation points to ` +
        `the student during the scheduled class hours (${getFormattedClassTimings(cls)})`
      );
    }

    if (isOpen && classRole === 'none') {
      return (
        `${incharge.name}, class in-charge, will be able to award participation points` +
        ` to the students during the scheduled class hours (${getFormattedClassTimings(cls)})`
      );
    }

    if (isOpen) {
      return (
        `You will be able to award participation points to the students during the scheduled ` +
        `class hours (${getFormattedClassTimings(cls)})`
      );
    }

    if (status === 'canceled') {
      return (
        `The class in-charge can award class participation points` +
        ` to students on this page. Since this class has been ` +
        `cancelled, participation points CANNOT be awarded to students here.`
      );
    }

    if (status === 'noRecord') {
      return (
        `Acadly was not used during this class, and no participation` +
        ` points were awarded to any students.`
      );
    }

    return (
      `Acadly was not used during this class, and no participation` +
      ` points were awarded to any students.`
    );
  }

  public render() {
    const cls = this.getProps().cls;
    const participationData = getStore().getState().class.participationData;
    // if (!participationData) return fullScreenLoader;
    if (!cls) return null;
    const message = this.getParticipationMessage(cls);
    if (cls.details.status === 'inSession' || cls.details.status === 'closed') {
      return participationData ? ContentView(this.instructorView()) : fullScreenLoader;
    } else if (
      cls.details.status === 'open' ||
      cls.details.status === 'noRecord' ||
      cls.details.status === 'canceled' ||
      cls.details.status === 'holiday'
    ) {
      return ContentView(
        h('div', style([pad('0.5rem 4rem'), 'lightGrey', 'textCenter']), [message])
      );
    }
    return ContentView(
      h('div', {}, 'Something goes here')
      // this.instructorView(),
    );
  }

  private instructorView() {
    const participationData = getStore().getState().class.participationData;
    const canMark = this.canMark();
    if (!participationData) return fullScreenLoader;
    return h('div.class-analytics__participation#class-participation', [
      this.participationView(canMark),
      this.markParticipationDialog(),
      this.exportDialog(),
      this.downloadAlert(),
    ]);
  }

  private getTopViewMessage() {
    const cls = this.getProps().cls;
    if (!cls) return '';
    const status = cls.details.status;
    const isMobile = getStore().getState().app.isMobile;
    const isInClassTeam = this.isInClassTeam();
    const canMarkMultipleStudents = this.getState().canMarkMultipleStudents;

    if (status === 'inSession' || status === 'closed') {
      if (isInClassTeam) {
        const click = isMobile ? 'Tap' : 'Click';
        return canMarkMultipleStudents
          ? `${click} on a student's name to select.
                       ${click} on Award Participation Points to award them`
          : `${click} on a student's name to award them class participation points.`;
      } else {
        return `Only the class team can award participation points.`;
      }
    } else {
      return `Class participation points can be awarded to students when the class
                is in session, or after it is over, if Acadly was used in class.`;
    }
  }

  private topView() {
    const message = this.getTopViewMessage();

    return h(
      'div',
      {
        style: {
          backgroundColor: 'white',
          padding: '1rem',
          marginBottom: '1rem',
          position: 'sticky',
          top: '0',
          zIndex: 2,
          minHeight: '91px',
          borderBottom: `1px solid #e6e6e6`,
        },
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        h('div.heading', style(['grey', 'thick', 'uppercase']), 'Class Participation'),
        h('div.text', style(['grey', pt('0.5rem')]), message),
      ]
    );
  }

  private async participationDataFetch() {
    const { cls } = this.getProps();
    if (!cls) return;
    if (cls.details.status === 'inSession' || cls.details.status === 'closed') {
      await dispatch(Actions.getClassParticipation(cls._id));
      const participationData = getStore().getState().class.participationData;
      if (!participationData) {
        logger.warn('Participation Data not present in store after fetching');
      } else {
        await this.setState({
          attendanceForm: u.mapValues(u.makeObjectWithKey(participationData, 'userId'), (s) => {
            if (s.attendance === 'present') {
              return true;
            } else if (s.attendance === 'absent') {
              return false;
            } else {
              return undefined;
            }
          }),
        });
      }
    }
  }

  private participationView(canMark: boolean) {
    const participationData = getStore().getState().class.participationData;

    const canMarkMultipleStudents = this.getState().canMarkMultipleStudents;
    const participationDialogOpenFor = this.getState().participationDialogOpenFor;
    const selectedStudentIds = this.getState().participationDialogOpenFor;

    if (!participationData) return fullScreenLoader;

    const state = this.getState();
    const sortStudentBy = getStore().getState().class.sortStudentBy;
    const cls = this.getProps().cls;
    const status = cls && cls.details.status;
    return h('div', style([pad('1rem 1rem')]), [
      this.topView(),
      status === 'closed' && !canMarkMultipleStudents
        ? h(
            'div#export-participation-button',
            style(
              ['fullWidth', 'pointer', 'flex', 'borderBox', pad('0.5rem 1rem'), margin('0.5rem 0')],
              {
                backgroundColor: 'white',
              },
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                role: 'button',
                onclick: () => this.openExportDialog(),
              }
            ),
            [
              Icon(icons.export2, style([pad('0.5rem'), 'x-large'])),
              h(
                'span',
                style([pad('0.5rem'), 'large', 'blue']),
                'Export participation data as CSV'
              ),
            ]
          )
        : null,
      canMarkMultipleStudents
        ? h(
            'div.ripple#award-participation-button',
            style(
              [
                'fullWidth',
                'pointer',
                'flex',
                'alignCenter',
                pad('0.5rem 1rem'),
                margin('0.5rem 0'),
              ],
              {
                backgroundColor: colors.white,
              },
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                role: 'button',
                onclick: () => {
                  if (selectedStudentIds.length) {
                    this.showParticipationDialogFor(selectedStudentIds);
                  }
                },
              }
            ),
            [
              h('span.approved'),
              h('span', style([pad('0.5rem'), 'large', 'blue']), 'Award Participation Points'),
            ]
          )
        : null,
      h('div', style(['flex', 'spaceBetween', pad('0.5rem 1rem')]), [
        h(
          'div',
          style(
            ['small', 'grey'],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          'Students'
        ),
        canMarkMultipleStudents
          ? h(
              'div',
              style(['small', 'grey']),
              `${participationDialogOpenFor.length} students selected`
            )
          : null,
      ]),
      canMark
        ? h('div.mark-multiple', style(['flex', pad('0.5rem 1rem')]), [
            h('span', style([]), 'Select Multiple Students: '),
            h('span', style([ml('auto')]), [
              Toggle({
                isAccessible: this.isAccessible,
                ariaLabel: 'Select Multiple Students',
                selected: canMarkMultipleStudents,
                ontoggle: () => this.toggleCanMarkMultipleStudents(),
              }),
            ]),
          ])
        : null,
      StudentList({
        sortBy: sortStudentBy,
        children: getStudentListData(
          participationData,
          state.searchField,
          sortStudentBy,
          (s) => s.name
        ).map((s) => this.individualStudentView(s)),
        onSearch: (term) => {
          this.setState({
            searchField: term,
          });
        },
        onSort: (sortBy) => {
          dispatch(Actions.setSortStudentOrder(sortBy));
        },
      }),
    ]);
  }

  private async toggleCanMarkMultipleStudents() {
    await this.setState({
      participationDialogOpenFor: [],
      canMarkMultipleStudents: !this.getState().canMarkMultipleStudents,
    });
  }

  private isInClassTeam() {
    const classRole = classService.getRole();
    return classRole !== 'none';
  }

  private showParticipationDialogFor(studentIds: string[]) {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    this.setState({
      participationDialogOpenFor: studentIds,
      isParticipationDialogOpen: true,
    });
  }

  private focusLastFocusedElement() {
    u.resetTabIndices(document.getElementById('class-participation'));
    (this.lastFocusedElement as any).focus();
  }

  private hideParticipationDialog(turnOffMultipleSelection = false) {
    this.focusLastFocusedElement();
    this.setState({
      newLabelInput: '',
      selectedLabel: '',
      canMarkMultipleStudents: turnOffMultipleSelection
        ? false
        : this.getState().canMarkMultipleStudents,
      participationDialogOpenFor: [],
      isParticipationDialogOpen: false,
      participationAward: { points: 0, label: '' },
    });
  }

  private async submitParticipationAward() {
    const currentUserName = getStore().getState().getIn.session!.name;
    const currentUserAvatar = getStore().getState().getIn.session!.avatar;
    const currentUserId = getStore().getState().getIn.session!.userId;
    const currentUserRole = courseService.getRole();
    const clsId = this.getProps().cls!._id;

    if (currentUserRole == null || currentUserRole === 'student') return;

    const instructor = {
      userId: currentUserId,
      name: currentUserName,
      avatar: currentUserAvatar,
      role: currentUserRole,
    };

    const studentIds = this.getState().participationDialogOpenFor;
    const participationAward = this.getState().participationAward;
    const request = {
      classId: clsId,
      studentIds,
      points: participationAward.points,
      label: participationAward.label,
    };

    await dispatch(Actions.setClassParticipation(request, instructor));
    this.hideParticipationDialog(true);
  }

  private getCurrentClassParticipationLabels() {
    const currentClass = classService.getCurrentClass();
    return currentClass ? currentClass.participationLabels : [];
  }

  private getMoreParticipationLabels() {
    const classLabels = new Set(this.getCurrentClassParticipationLabels());
    const allLabels = new Set(getStore().getState().courses.allParticipationLabels);
    const result = new Set(allLabels);

    for (const label of classLabels) {
      result.delete(label);
    }

    return Array.from(result);
  }

  private markParticipationDialog() {
    const isOpen = this.getState().isParticipationDialogOpen;
    const cls = this.getProps().cls;
    if (!cls) return null;
    const selectedStudentIds = this.getState().participationDialogOpenFor;
    if (!selectedStudentIds.length) return null;
    const participationData = getStore().getState().class.participationData;
    if (!participationData) return null;
    const students = participationData.filter((s) => selectedStudentIds.includes(s.userId));
    const previousInstancesOfParticipation = students.map((s) => s.participation);

    // get first student only because for mulitple student we hide it
    const numberOfPreviousInstances = previousInstancesOfParticipation[0].length;
    const canMark = this.canMark();

    const labels = this.getCurrentClassParticipationLabels();
    const selectedLabel = this.getState().selectedLabel;
    const newLabelInput = this.getState().newLabelInput;
    const participationAward = this.getState().participationAward;

    const showMoreLabels = this.getState().showMoreLabels;
    const moreLabels = this.getMoreParticipationLabels();

    const labelHeadingStyle = style(['mediumGrey', pad('0.5rem 1rem'), 'bold']);

    const renderAwardLabels = (label: string) => {
      if (selectedLabel === label) return null;
      return h(
        'span.award-label',
        style(
          [
            {
              ...LABEL_STYLE,
              background: selectedLabel === '' ? colors.blue : colors.lightGrey,
            },
          ],
          {},
          {
            tabIndex: 0,
            onclick: () => {
              this.setState({
                selectedLabel: label,
                participationAward: {
                  points: participationAward.points,
                  label,
                },
              });
            },
          }
        ),
        label
      );
    };

    const instructorView = [
      h('div.slider-heading', labelHeadingStyle, 'Points to be awarded'),
      h(
        'div.slider-container',
        style(['flex'], {
          minHeight: '40px',
          alignItems: 'center',
          padding: '0.5rem 1rem',
        }),
        [
          RangeSlider({
            step: 0.5,
            min: 0,
            max: 10,
            value: participationAward.points,
            isAccessible: this.isAccessible,
            oninput: async (event) => {
              this.setState({
                participationAward: {
                  label: participationAward.label,
                  points: event.target.valueAsNumber,
                },
              });
            },
          }),
        ]
      ),
      h('div.label-heading', labelHeadingStyle, 'Awarded for'),
      selectedLabel
        ? h(
            'span.award-label',
            style(
              [
                {
                  ...LABEL_STYLE,
                  background: colors.green,
                  margin: '0.8125rem 1rem',
                },
              ],
              {},
              {
                tabIndex: 0,
                onclick: () => {
                  this.setState({
                    selectedLabel: '',
                    participationAward: {
                      points: participationAward.points,
                      label: newLabelInput,
                    },
                  });
                },
              }
            ),
            [selectedLabel, Icon(icons.cross, style(['small', ml('0.5rem')]))]
          )
        : TextField({
            value: newLabelInput,
            maxLength: 32,
            showRemainingLength: true,
            noHintOrError: true,
            style: style([pad('0 1rem')]).style,
            placeholder: 'Type the tag here',
            oninput: (e) =>
              this.setState({
                newLabelInput: e.target.value,
                participationAward: {
                  points: participationAward.points,
                  label: e.target.value,
                },
              }),
          }),
      labels.length
        ? h('div.labels', style([pad('0.5rem 1rem 1.5rem')]), [
            h(
              'div.label-heading',
              style(['mediumGrey', mr('0.5rem'), 'bold'], {
                display: 'inline-block',
              }),
              'Previously used tags in the class:'
            ),
            ...labels.map(renderAwardLabels),
          ])
        : null,
      moreLabels.length
        ? h('div.more-labels', style([pad('1rem')]), [
            h(
              'div.more-labels-toggle',
              style(
                ['flex', 'spaceBetween'],
                {},
                {
                  ariaLabel: `click to ${showMoreLabels ? 'show' : 'hide'} more labels`,
                  ariaControls: 'more-labels-container',
                  ariaExpanded: showMoreLabels.toString(),
                  onclick: () => {
                    this.setState({
                      showMoreLabels: !showMoreLabels,
                    });
                  },
                }
              ),
              [
                h('span', style(['mediumGrey', 'bold']), 'Tags used in other classes'),
                Icon(showMoreLabels ? icons.arrowUp : icons.arrowDown),
              ]
            ),
            h(
              'div#more-labels-container',
              style([
                mt('0.5rem'),
                {
                  maxHeight: showMoreLabels ? '1000px' : '0px',
                  transition: 'max-height 0.5s ease-in-out',
                  overflow: 'hidden',
                },
              ]),
              moreLabels.map(renderAwardLabels)
            ),
          ])
        : null,
    ];

    return Dialog(
      {
        title: `Participation score`,
        open: isOpen,
        style: {
          width: '35em',
        },
        secondaryAction: {
          label: canMark ? 'CANCEL' : 'OK',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => this.hideParticipationDialog(),
        },
        primaryAction: canMark
          ? {
              label: 'DONE',
              disabled: participationAward.points === 0,
              mobileLabel: h('i.fa.fa-check', []),
              onclick: () => this.submitParticipationAward(),
            }
          : undefined,
      },
      [
        ...students.map((s) => this.individualStudentView(s, true)),
        ...(canMark ? instructorView : []),
        selectedStudentIds.length < 2
          ? h(
              'div.previous-heading',
              style(
                ['flex', pad('0.5rem 1rem'), 'spaceBetween', 'mediumGrey'],
                {},
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                }
              ),
              [h('div.previous-instances', style(['bold']), 'Participation history')]
            )
          : null,
        selectedStudentIds.length < 2
          ? numberOfPreviousInstances === 0
            ? h(
                'div',
                style(
                  [pad('1rem')],
                  {},
                  {
                    tabIndex: this.isAccessible ? 0 : undefined,
                  }
                ),
                'No participation points awarded'
              )
            : h(
                'div.previous-instances',
                style(['flex', 'column']),
                previousInstancesOfParticipation[0].map(this.previousInstancesView)
              )
          : null,
      ]
    );
  }

  private previousInstancesView = (i: IParticipationData) => {
    return h(
      'div.container',
      style(
        ['flex', pad('0.5rem 1rem')],
        {},
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        }
      ),
      [
        h('div.name-points', style(['flex']), [
          Avatar(i.awardedBy.avatar, i.awardedBy.name, {
            className: 'class-analytics__avatar',
          }),
          h('div', style(['flex', 'column', 'spaceAround']), [
            h(
              'div',
              `${i.awardedBy.name} awarded ${i.points}
                        ${i.points === 0 ? 'point' : 'points'}`
            ),
            i.label
              ? h(
                  'span.award-label',
                  style([
                    {
                      ...LABEL_STYLE,
                      padding: '0.25rem',
                      margin: '0.25rem auto 0 0',
                      fontSize: '0.7rem',
                      cursor: 'default',
                    },
                  ]),
                  i.label
                )
              : null,
          ]),
        ]),
      ]
    );
  };

  private individualStudentView = (s: IParticipationStudentData, readonly = false) => {
    const selectedStudents = this.getState().participationDialogOpenFor;
    const isCurrentStudentSelected = selectedStudents.includes(s.userId);
    const markMultipleStudents = this.getState().canMarkMultipleStudents;
    const isMobile = getStore().getState().app.isMobile;
    const total =
      s.participation && s.participation.map((p) => p.points).reduce((t, n) => t + n, 0);

    const isSelectable = markMultipleStudents && !readonly;

    return User(
      {
        showCheckBox: isSelectable && !isMobile,
        selectable: isSelectable,
        isSelected: isCurrentStudentSelected,
        avatar: {
          url: s.avatar,
          creator: s.name,
        },
        title: s.name,
        titleClassNames: ['fc-green'],
        subtitle:
          s.attendance === 'present' ? 'Present' : s.attendance === 'absent' ? 'Absent' : null,
        subtitleClassNames: [
          s.attendance === 'present' ? 'fc-blue' : s.attendance === 'absent' ? 'fc-red' : '',
        ],
        action:
          total > 0
            ? h('div.class-analytics__total-award', [
                h('span.approved'),
                s.participation.length > 1
                  ? h('span.class-analytics__awards', `x ${s.participation.length}`)
                  : null,
                h('span', `${total}${total > 1 ? 'pts' : 'pt'}`),
              ])
            : null,
      },
      {
        className: 'student',
        ref: this.onRef,
        key: s.userId,
        tabIndex: this.isAccessible ? 0 : undefined,
        onclick: !readonly
          ? markMultipleStudents
            ? () => this.toggleStudentInSelectedList(s.userId)
            : () => this.showParticipationDialogFor([s.userId])
          : undefined,
      }
    );
  };

  private elem?: HTMLElement;
  private onRef = (elem?: HTMLElement) => {
    if (!elem) return;
    if (this.elem === elem) {
      return;
    }
    this.elem = elem;
  };

  private async toggleStudentInSelectedList(_id: string) {
    const selectedStudents = this.getState().participationDialogOpenFor;
    const isStudentSelected = selectedStudents.includes(_id);

    if (isStudentSelected) {
      this.setState({
        participationDialogOpenFor: selectedStudents.filter((s) => s !== _id),
      });
    } else {
      this.setState({
        participationDialogOpenFor: [...selectedStudents, _id],
      });
    }
  }

  private canMark(): boolean {
    const cls = this.getProps().cls;
    return !!(
      cls &&
      (cls.details.status === 'inSession' || cls.details.status === 'closed') &&
      this.isInClassTeam()
    );
  }

  private lastFocusedElement: Element | null = null;

  private exportDialog() {
    const isOpen = this.getState().isExportDialogOpen;
    const isEmail = this.getState().exportRequestIsEmail;
    const sendEmail = this.getState().sendEmail;
    const option = (opts: { selected: boolean; label: string; key: string; onclick: () => any }) =>
      h(
        'div',
        style(
          ['flex', 'alignCenter'],
          {},
          {
            onclick: opts.onclick,
            tabIndex: this.isAccessible ? 0 : undefined,
            key: opts.key,
          }
        ),
        [
          RadioButton({
            selected: opts.selected,
            color: colors.teal,
          }),
          h(
            'div',
            style([
              pad('0.5rem 1rem'),
              ml('0.5rem'),
              {
                flex: '1',
                borderBottom: '1px solid lightGrey',
              },
            ]),
            opts.label
          ),
        ]
      );
    return Alert(
      {
        title: !sendEmail ? 'Exporting Participation Data' : 'Success',
        titleStyle: {
          textAlign: 'center',
        },
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton(!sendEmail ? 'Cancel' : 'Download a copy', {
            type: 'secondary',
            onclick: !sendEmail
              ? () => this.backClickHandler()
              : () => this.downloadACopyClickHandler(),
          }),
          FlatButton('Done', {
            onclick: !sendEmail ? () => this.exportClickHandler() : () => this.backClickHandler(),
          }),
        ],
      },
      [
        sendEmail
          ? h(
              'div',
              'The exported file has been sent to your registered' +
                " email address. In case you can't spot it, please" +
                ' look into the Spam folder'
            )
          : h('div', {}, [
              option({
                label: 'Download CSV file now',
                selected: isEmail === 0 ? true : false,
                key: 'no-email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 0,
                  }),
              }),
              option({
                label: 'Email file as attachment',
                selected: isEmail === 1 ? true : false,
                key: 'email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 1,
                  }),
              }),
            ]),
      ]
    );
  }

  private async downloadACopyClickHandler() {
    await this.setState({
      isDownloadAlertOpen: true,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }
  private async backClickHandler() {
    u.resetTabIndices(document.getElementById('class-participation'));
    (this.lastFocusedElement as any).focus();
    await this.setState({
      isDownloadAlertOpen: false,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }
  private async exportClickHandler() {
    const email = this.getState().exportRequestIsEmail;
    const response = await dispatch(
      ClassActions.exportGrades({
        activityType: 'participation',
        activityId: this.getProps().cls!._id,
        email: email,
      })
    );
    this.setFileData(response);
    if (email === 1) {
      await this.setState({
        sendEmail: true,
      });
    } else {
      await this.setState({
        isExportDialogOpen: false,
        isDownloadAlertOpen: true,
      });
    }
  }
  private downloadAlert() {
    const isOpen = this.getState().isDownloadAlertOpen;
    const fileName = this.getState().exportFileName;
    const downloadLink = this.getState().exportDownloadLink;
    const styles = {
      display: 'flex',
      width: '20em',
      borderRadius: '4px',
      backgroundColor: 'white',
      alignItems: 'center',
      border: `1px solid ${colors.lightestGrey}`,
      maxWidth: '100%',
      lineHeight: '1.5em',
      boxSizing: 'border-box',
      paddingRight: '0.5rem',
    };

    return Alert(
      {
        title: 'Success',
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('Done', {
            onclick: () => this.backClickHandler(),
          }),
        ],
      },
      [
        h('div', style([pad('0.5rem 0.5rem 0.5rem 0rem')]), 'File Generated'),
        h(
          'div.acadly-attachment',
          {
            style: styles,
          },
          [
            h(
              'div.attachment-icon',
              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '0.5rem',
                  // fontSize: "0.8em",
                  width: '2.5rem',
                  color: 'white',
                  height: '2.5rem',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  backgroundColor: colors.green,
                },
              },
              'CSV'
            ),
            h(
              'div',
              style([
                'thin',
                {
                  flex: 1,
                  color: colors.black,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: '0.5em',
                },
              ]),
              [fileName]
            ),
            h(
              'a',
              {
                style: {
                  color: colors.green,
                },
                target: '_blank',
                href: downloadLink || undefined,
              },
              [Icon(icons.download, style(['blue', 'large', 'pointer', pad('0.5rem')]))]
            ),
          ]
        ),
      ]
    );
  }

  private async setFileData(response: IExportGradesSuccessResponse) {
    await this.setState({
      exportDownloadLink: response.url,
      exportFileName: response.filename,
    });
  }

  private async openExportDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isExportDialogOpen: true,
    });
  }
}

export default (cls: IClass | null) => h(ClassAnalytics, { cls });
