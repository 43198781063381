import { h, IComponent, View } from 'core';

import { Actions } from 'acadly/class/actions';
import * as api from 'acadly/class/api';
import SuggestedPollQuestions from 'acadly/class/SuggestedPollQuestions';
import SuggestedQuizQuestions from 'acadly/class/SuggestedQuizQuestions';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Dialog, { IDialogProps } from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import { fullScreenLoader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import User from 'acadly/common/User';
import VideoPlayer from 'acadly/common/VideoPlayer';
import * as datetime from 'acadly/datetime';
import Viewer from 'acadly/rich-text/Viewer';
import { dispatch } from 'acadly/store';
import { colors } from 'acadly/styles';
import * as utils from 'acadly/utils';

interface ISuggestedActivityPreviewProps {
  open: boolean;
  cls: IClass;
  onClose: () => any;
  isAccessible?: boolean;
  activity: ISuggestedActivity | null;
}

interface ISuggestedActivityPreviewState {
  isLoading: boolean;
  quizQuestions: ISuggestedQuizQuestion[];
  pollQuestions: ISuggestedPollQuestion[];
  isHideActivityAlertVisible: boolean;
  isToBeDoneAlertVisible: boolean;
  toBeDone: 'preClass' | 'inClass';
}

class SuggestedActivityPreview extends IComponent<
  ISuggestedActivityPreviewProps,
  ISuggestedActivityPreviewState
> {
  private isAccessible: boolean;

  private async init(activity: ISuggestedActivity | null) {
    const initialState: ISuggestedActivityPreviewState = {
      isLoading: false,
      quizQuestions: [],
      pollQuestions: [],
      isHideActivityAlertVisible: false,
      isToBeDoneAlertVisible: false,
      toBeDone: 'preClass',
    };

    await this.setState(initialState);

    if (activity && (activity.nodeType === 'quiz' || activity.nodeType === 'poll')) {
      this.setState({ isLoading: true });
      const response = await api.getSuggestedActivityDetails(activity.nodeType, activity._id);

      if (response.data.nodeType === 'quiz') {
        this.setState({
          isLoading: false,
          quizQuestions: response.data.questions,
          pollQuestions: [],
        });
      } else if (response.data.nodeType === 'poll') {
        this.setState({
          isLoading: false,
          quizQuestions: [],
          pollQuestions: response.data.questions,
        });
      }
    }
  }

  public componentWillMount() {
    const { isAccessible, activity } = this.getProps();
    this.isAccessible = isAccessible || false;
    this.init(activity);
  }

  public componentWillReceiveProps(newProps: ISuggestedActivityPreviewProps) {
    const currentProps = this.getProps();
    const oldActivityId = currentProps.activity ? currentProps.activity._id : null;
    const newActivityId = newProps.activity ? newProps.activity._id : null;
    if (oldActivityId !== newActivityId) {
      this.init(newProps.activity);
    }
  }

  public render() {
    const { open, onClose } = this.getProps();
    const { isLoading } = this.getState();

    const centerAlign = {
      display: 'flex',
      alignItems: 'center',
    };

    const dialogOptions: IDialogProps = {
      open,
      title: 'Activity preview',
      style: {
        minHeight: '80%',
      },
      bodyStyle: {
        backgroundColor: colors.backgroundColor,
        flex: 1,
        ...(isLoading ? centerAlign : {}),
      },
      secondaryAction: {
        label: 'Close',
        mobileLabel: h('i.fa.fa-arrow-left'),
        onclick: onClose,
      },
    };

    return Dialog(dialogOptions, [
      ...this.body(),
      this.hideActivityAlert(),
      this.useActivityAsAlert(),
    ]);
  }

  private body() {
    const { activity } = this.getProps();
    const { isLoading } = this.getState();

    if (!activity) return [];

    if (isLoading) return [fullScreenLoader];

    switch (activity.nodeType) {
      case 'quiz':
        return [this.quizPreview()];
      case 'poll':
        return [this.pollPreview()];
      case 'resource':
        return [this.resourcePreview()];
      case 'discussion':
        return [this.discussionPreview()];
      default:
        return [h('div', 'Invalid activity')];
    }
  }

  private hideActivityAlert() {
    const activity = this.getProps().activity!;

    return Alert(
      {
        open: this.getState().isHideActivityAlertVisible,
        title: h('div.fc-orange', 'Activity will be hidden'),
        style: {
          width: '25rem',
          maxWidth: '80%',
        },
        actions: [
          FlatButton('Cancel', {
            type: 'secondary',
            onclick: () => {
              this.setState({
                isHideActivityAlertVisible: false,
              });
            },
          }),
          FlatButton('Okay', {
            type: 'primary',
            onclick: async () => {
              await dispatch(
                Actions.hideSuggestedActivity({
                  activityId: activity._id,
                  activityType: this.pluralizeActivityType(activity.nodeType),
                })
              );
              this.getProps().onClose();
            },
          }),
        ],
      },
      [
        'This activity will no longer be suggested in the future. ',
        'You can still select this activity by accessing the list of ',
        'all activities from the source course.',
      ]
    );
  }

  private canAddPreClassActivity() {
    const { cls } = this.getProps();
    return (
      cls.details.status === 'open' &&
      datetime.isInFuture(datetime.fromUnix(cls.details.scheStartTime), 30, 'minutes')
    );
  }

  private useActivityAsAlert() {
    const activity = this.getProps().activity!;
    const cls = this.getProps().cls;

    const Option = (toBeDone: 'preClass' | 'inClass') => {
      const isDisabled = toBeDone === 'preClass' && !this.canAddPreClassActivity();

      return h(
        utils.getHTMLTagSelector('div', [
          'activity-preview__use-option',
          isDisabled ? 'disabled' : '',
        ]),
        {
          onclick: isDisabled ? undefined : () => this.setState({ toBeDone }),
        },
        [
          RadioButton({
            disabled: isDisabled,
            className: 'activity-preview__radio-button',
            selected: this.getState().toBeDone === toBeDone,
          }),
          h('span', toBeDone === 'inClass' ? 'In-class activity' : 'Pre-class activity'),
        ]
      );
    };

    return Alert(
      {
        open: this.getState().isToBeDoneAlertVisible,
        title: h('div.activity-preview__use-title', 'Use activity as '),
        style: {
          width: '25rem',
          maxWidth: '80%',
        },
        actions: [
          FlatButton('Cancel', {
            type: 'secondary',
            onclick: () => {
              this.setState({
                isToBeDoneAlertVisible: false,
              });
            },
          }),
          FlatButton('Okay', {
            type: 'primary',
            onclick: async () => {
              await dispatch(
                Actions.useSuggestedActivity({
                  activityId: activity._id,
                  activityType: this.pluralizeActivityType(activity.nodeType),
                  copyToClass: cls._id,
                  toBeDone: this.getState().toBeDone,
                })
              );
              this.getProps().onClose();
            },
          }),
        ],
      },
      [Option('preClass'), Option('inClass')]
    );
  }

  private pluralizeActivityType(type: ISuggestedActivity['nodeType']) {
    switch (type) {
      case 'quiz':
        return 'quizzes';
      case 'poll':
        return 'polls';
      case 'resource':
        return 'resources';
      case 'discussion':
        return 'discussions';
      default:
        return 'quizzes';
    }
  }

  private getParsedUseAsType(toBeDone: ISuggestedActivity['details']['toBeDone']) {
    return toBeDone === 'inClass' ? 'In-class' : 'Pre-class';
  }

  private activityLayout(content: View[]) {
    const activity = this.getProps().activity!;
    const createdBy = activity.details.createdBy;

    return h('div.activity-preview', [
      h('div.activity-preview__action-bar', [
        FlatButton("Don't suggest", {
          type: 'secondary',
          disabled: activity.hidden === 1,
          classNames: ['fc-orange'],
          onclick: () => {
            this.setState({
              isHideActivityAlertVisible: true,
            });
          },
        }),
        FlatButton('Use', {
          type: 'primary',
          onclick: () => {
            this.setState({
              isToBeDoneAlertVisible: true,
              toBeDone: this.canAddPreClassActivity() ? activity.details.toBeDone : 'inClass',
            });
          },
        }),
      ]),
      h('div.activity-preview__content.activity', [
        h('div.activity-preview__info', [
          h(
            'div.cell__label',
            `${this.getParsedUseAsType(activity.details.toBeDone)} ${
              activity.nodeType
            } published in the class on ${activity.identifiers.classTime} in ${
              activity.identifiers.courseCode
            }: ${activity.identifiers.courseTitle}`
          ),
        ]),
        h('div.activity__title', activity.details.title || 'Untitled'),
        ...content,
        User(
          {
            title: `Created by ${createdBy.name}`,
            titleClassNames: ['fc-medium-grey'],
            subtitle: datetime.format(activity.details.createdOn, 'MMM DD, YYYY [at] hh:mm A'),
            subtitleClassNames: ['fc-medium-grey'],
            avatar: {
              url: createdBy.avatar,
              creator: createdBy.name,
            },
          },
          {
            className: 'activity__creator',
          }
        ),
      ]),
    ]);
  }

  private quizPreview() {
    const { activity: quiz, isAccessible } = this.getProps();
    const questions = this.getState().quizQuestions;

    if (!quiz || quiz.nodeType !== 'quiz') return null;

    return this.activityLayout([
      quiz.details.instructions
        ? h('div.activity__description', [Viewer(quiz.details.instructions)])
        : null,
      quiz.details.attachments.length
        ? h(
            'div.activity__attachments',
            quiz.details.attachments.map((attachment) =>
              AttachmentViewer({
                attachment,
                hideDownloadIcon: true,
                downloadUrl: '',
                downloadRequest: null,
              })
            )
          )
        : null,
      h('div.activity__subtitle', 'Questions'),
      SuggestedQuizQuestions({
        quiz,
        questions,
        isAccessible,
      }),
    ]);
  }

  private pollPreview() {
    const { activity: poll, isAccessible } = this.getProps();
    const { pollQuestions } = this.getState();

    if (!poll || poll.nodeType !== 'poll') return null;

    return this.activityLayout([
      SuggestedPollQuestions({
        poll,
        isAccessible,
        questions: pollQuestions,
      }),
    ]);
  }

  private resourcePreview() {
    const resource = this.getProps().activity;

    if (!resource || resource.nodeType !== 'resource') return null;

    return this.activityLayout([
      resource.details.description
        ? h('div.activity__description', [Viewer(resource.details.description)])
        : null,
      h(
        'div',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `resource type: ${resource.details.resourceType}`,
        },
        [
          resource.details.resourceType === 'link'
            ? h(
                'a.fc-blue',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  href: utils.convertLinkToURL(resource.details.url),
                  target: '_blank',
                },
                resource.details.url
              )
            : null,

          resource.details.resourceType === 'file' && resource.details.fileName
            ? h('div.activity__attachments', [
                AttachmentViewer({
                  attachment: {
                    originalName:
                      utils.splitFileName(resource.details.originalFileName).name ||
                      'No file added yet',
                    extension: utils.splitFileName(resource.details.originalFileName).extension,
                    name: resource.details.fileName,
                  },
                  hideDownloadIcon: true,
                  downloadUrl: '',
                  downloadRequest: null,
                }),
              ])
            : null,

          resource.details.resourceType === 'video'
            ? VideoPlayer({
                videoId: resource.details.videoId,
              })
            : null,
        ]
      ),
    ]);
  }

  private discussionPreview() {
    const discussion = this.getProps().activity;

    if (!discussion || discussion.nodeType !== 'discussion') return null;

    return this.activityLayout([
      discussion.details.description
        ? h('div.activity__description', [Viewer(discussion.details.description)])
        : null,
      discussion.details.attachments.length
        ? h(
            'div.activity__attachments',
            discussion.details.attachments.map((attachment) =>
              AttachmentViewer({
                attachment,
                hideDownloadIcon: true,
                downloadUrl: '',
                downloadRequest: null,
              })
            )
          )
        : null,
    ]);
  }
}

export default (props: ISuggestedActivityPreviewProps) => h(SuggestedActivityPreview, props);
