import { throttle } from 'lodash';
import { Subscription } from 'rxjs/Subscription';

import { PageVisibility } from './pageVisibility';

const performance = window.performance;

/**
 * Converts milli-seconds to seconds
 * @param milliSeconds unixtime in milli-seconds
 * @returns unixtime in seconds
 */
const toUnix = (milliSeconds: number) => milliSeconds / 1000;

/**
 * Time counter, ticks every second
 * @param initTime initial time in seconds
 */
function AcadlyTime(initTime: number) {
  /**
   * Time difference in performance.now() and initTime
   * at the time of starting/resetting timer
   */
  let seedDiff: number;

  /**
   * Stores time in seconds.
   * For performance reasons we are not using milliseconds.
   */
  let time: number;

  let timer: NodeJS.Timer | undefined = undefined;
  let pageVisibilitySub: Subscription | undefined = undefined;

  /**
   * Stops the timer
   */
  const stopTime = () => {
    if (timer !== undefined) {
      // stop current timer
      clearInterval(timer);
      timer = undefined;
    }
  };

  const startTime = (initTime: number) => {
    stopTime();
    time = Math.round(initTime);
    timer = setInterval(() => void time++, 1000);
  };

  /**
   * updates acadly time
   * @param initTime unix time in seconds
   */
  const setTime = throttle((initTime: number) => {
    /**
     * Throttling, so that we can avoid multiple
     * resets of timer in very small time duration
     */
    seedDiff = initTime - toUnix(performance.now());
    startTime(initTime);
  }, 1000);

  // start the timer
  setTime(initTime);

  if (pageVisibilitySub) {
    pageVisibilitySub.unsubscribe();
  }

  pageVisibilitySub = PageVisibility.visibility$.subscribe((e) => {
    if (!e.isHidden) {
      // page is active again so re-sync the timer
      const accurateTime = toUnix(performance.now()) + seedDiff;
      setTime(accurateTime);
    }
  });

  return {
    /**
     * Gets current time in seconds
     */
    now: () => time,
    /**
     * Sets acadly time
     * @param updatedTime updated unix-time in seconds
     */
    setTime,
    /**
     * Warning: Use with caution. It's used in mocha-tests
     * to complete node process, so that it can complete mocha-tests.
     */
    stopTime,
  };
}

export const acadlyTime = AcadlyTime(toUnix(Date.now()));
