import { updateClass, updateClassInRootState } from 'acadly/class/functions';
import { getCourseRole } from 'acadly/course/functions';
import { createReducer } from 'acadly/createReducer';
import { IRootState } from 'acadly/IRootState';
import * as utils from 'acadly/utils';

import * as actions from './actions';
import { hideQuery, responseQueryToQuery, updateQuery, updateQueryInRootState } from './functions';

export const reducer = createReducer({
  '@query/ANALYTICS_SET_SUCCESS': analyticsSetSuccess,
  '@query/UPVOTE_SUCCESS': upvoteSuccess,
  '@query/HIDE_SUCCESS': hideSuccess,
  '@query/CLOSE_SUCCESS': closeSuccess,
  '@query/CREATE_SUCCESS': createSuccess,
  '@query/all/FETCH_SUCCESS': fetchAllSuccess,
  '@query/pusher/CREATE': pusherCreate,
  '@query/pusher/UPVOTE': pusherUpvote,
  '@query/APPROVE_SUCCESS': approveSuccess,
  '@query/pusher/APPROVED': pusherApproved,
  '@query/REMOVE_SUCCESS': removeSuccess,
  '@query/pusher/ANON_QUERY_RAISED': pusherAnonQueryCreated,
});

export function pusherUpvote(state: IRootState, payload: actions.IPusherUpvotePayload): IRootState {
  if (payload.sender.userId === state.getIn.session!.userId) return state;
  return {
    ...state,
    queries: updateQuery(state.queries, payload.queryId, (q) => ({
      ...q,
      stats: {
        numAskers: q.stats.numAskers + 1,
      },
    })),
  };
}

export function pusherCreate(state: IRootState, payload: actions.IPusherCreatePayload): IRootState {
  if (state.getIn.session!.userId === payload.sender.userId) return state;
  const query: IQuery = makeQuery(payload);
  return addQuery(state, {
    query,
    isSeen: false,
    key: 'numTotal',
  });
}

export function pusherAnonQueryCreated(
  state: IRootState,
  payload: actions.IPusherAnonQueryRaisedPayload
): IRootState {
  if (state.getIn.session!.userId === payload.sender.userId) return state;
  const query: IQuery = makeQuery(payload);
  return addQuery(state, {
    query,
    isSeen: false,
    key: 'numPending',
  });
}

function makeQuery(payload: actions.IPusherCreatePayload): IQuery {
  return {
    _id: payload.queryId,
    nodeType: 'query',
    identifiers: {
      classId: payload.classId,
    },
    details: {
      ...payload.details,
      attachments: payload.details.attachments || [],
      title: payload.details.title || '',
    },
    stats: payload.stats,
    activities: payload.activities,
  };
}

export function analyticsSetSuccess(
  state: IRootState,
  payload: actions.IAnalyticsSetPayload
): IRootState {
  if (!state.courses.currentCourseId) return state;
  const courseUserData = state.courses.userData[state.courses.currentCourseId];
  let updatedCourseSlice = utils.update(state.courses, {
    userData: {
      [state.courses.currentCourseId]: courseUserData
        ? {
            queriesSeen: courseUserData.queriesSeen + 1,
          }
        : courseUserData,
    },
  });

  if (state.courses.timeline) {
    updatedCourseSlice = utils.update(updatedCourseSlice, {
      timeline: {
        items: utils.replaceWhere(
          state.courses.timeline.items,
          (c: IClass) => {
            if (payload.toBeDone === 'review') {
              return utils.update(c, {
                userData: {
                  reviewQueries: {
                    numSeen: c.userData.reviewQueries.numSeen + 1,
                  },
                },
              });
            } else {
              return utils.update(c, {
                userData: {
                  [payload.toBeDone]: {
                    queries: {
                      numSeen: c.userData[payload.toBeDone].queries.numSeen + 1,
                    },
                  },
                },
              });
            }
          },
          (c) => c._id === payload.classId
        ),
      },
    });
  }
  const updatedQuerySlice = updateQuery(state.queries, payload.queryId, (q) => ({
    ...q,
    userData: {
      numCommentsSeen: 0,
      hasUpvoted: 0 as const,
      isAsker: 0 as const,
      subscribed: 0 as const,
    },
  }));
  return {
    ...state,
    courses: updatedCourseSlice,
    queries: updatedQuerySlice,
  };
}

export function upvoteSuccess(state: IRootState, payload: actions.IUpvotePayload): IRootState {
  return {
    ...state,
    queries: updateQuery(state.queries, payload, (q) =>
      utils.update(q, {
        stats: {
          numAskers: q.stats.numAskers + 1,
        },
        userData: q.userData
          ? { hasUpvoted: 1, subscribed: 1 }
          : { numCommentsSeen: 0, hasUpvoted: 1, isAsker: 0, subscribed: 1 },
      })
    ),
  };
}

export function hideSuccess(state: IRootState, payload: actions.IHidePayload): IRootState {
  const session = state.getIn.session;
  const courseId = state.courses.currentCourseId;
  if (!courseId || !session) return state;
  const course = state.courses.courses[courseId];
  const teamMember = course.team.find((u) => u.userId === session.userId);
  if (!teamMember) return state;
  return {
    ...state,
    queries: updateQuery(state.queries, payload, hideQuery(teamMember.name)),
  };
}

export function removeSuccess(
  state: IRootState,
  payload: actions.IRemoveSuccessPayload
): IRootState {
  const newQueriesById = utils.removeKey(state.queries.byId, payload.queryId);
  return {
    ...state,
    queries: {
      byId: newQueriesById,
    },
  };
}

export function closeSuccess(state: IRootState, payload: actions.IClosePayload): IRootState {
  const updatedCourseSlice = updateClass(state.courses, payload.classId, (c) => {
    if (payload.toBeDone === 'review') {
      return utils.update(c, {
        activities: {
          reviewQueries: {
            numClosed: c.activities.reviewQueries.numClosed + 1,
          },
        },
      });
    } else {
      return utils.update(c, {
        activities: {
          [payload.toBeDone]: {
            queries: {
              numClosed: c.activities[payload.toBeDone].queries.numClosed + 1,
            },
          },
        },
      });
    }
  });
  const updatedQuerySlice = updateQuery(state.queries, payload.queryId, (q) =>
    utils.update(q, {
      details: {
        status: 'closed',
      },
    })
  );
  return {
    ...state,
    queries: updatedQuerySlice,
    courses: updatedCourseSlice,
  };
}

export function createSuccess(state: IRootState, payload: actions.ICreatePayload): IRootState {
  if (payload.isAnon === 1) {
    return state;
  } else {
    const query = responseQueryToQuery(
      {
        numCommentsSeen: 0,
        subscribed: 1,
        role: ['asker'],
      },
      payload.classId,
      payload.response
    );
    return addQuery(state, {
      query,
      isSeen: true,
      key: 'numTotal',
    });
  }
}

export function addQuery(
  state: IRootState,
  payload: {
    query: IQuery;
    isSeen: boolean;
    key: 'numTotal' | 'numPending';
  }
): IRootState {
  const courseKey = payload.key === 'numTotal' ? 'numQueries' : 'numQueriesPending';
  const { query, isSeen } = payload;
  if (state.queries.byId[payload.query._id]) {
    return state;
  }
  const updatedClassSlice =
    state.class.data && state.class.data.classId === query.identifiers.classId
      ? utils.update(state.class, {
          data: {
            activities: {
              queries: [...state.class.data.activities.queries, query._id],
            },
          },
        })
      : state.class;
  const incrementSeenBy = isSeen ? 1 : 0;
  const classUpdateFn = (c: IClass) => {
    const toBeDone = query.details.toBeDone;
    if (toBeDone === 'review') {
      return utils.update(c, {
        activities: {
          reviewQueries: {
            [payload.key]: (c.activities.reviewQueries[payload.key] || 0) + 1,
          },
        },
        userData: {
          reviewQueries: {
            numSeen: c.userData.reviewQueries.numSeen + incrementSeenBy,
          },
        },
      });
    } else {
      return utils.update(c, {
        activities: {
          [toBeDone]: {
            queries: {
              [payload.key]: (c.activities[toBeDone].queries[payload.key] || 0) + 1,
            },
          },
        },
        userData: {
          [toBeDone]: {
            queries: {
              numSeen: c.userData[toBeDone].queries.numSeen + incrementSeenBy,
            },
          },
        },
      });
    }
  };
  const courseId = state.courses.currentCourseId;
  let updatedCourseSlice: IRootState['courses'] = updateClass(
    state.courses,
    query.identifiers.classId,
    classUpdateFn
  );
  const course = courseId ? updatedCourseSlice.courses[courseId] : null;
  const courseUserData = courseId ? updatedCourseSlice.userData[courseId] : undefined;
  updatedCourseSlice =
    course && courseId
      ? {
          ...updatedCourseSlice,
          courses: {
            ...updatedCourseSlice.courses,
            [courseId]: {
              ...course,
              activities: {
                ...course.activities,
                [courseKey]: (course.activities[courseKey] || 0) + 1,
              },
              totalActivities: course.totalActivities + 1,
            },
          },
          userData: {
            ...updatedCourseSlice.userData,
            [courseId]: courseUserData
              ? {
                  ...courseUserData,
                  activitiesSeen: courseUserData.activitiesSeen + incrementSeenBy,
                  queriesSeen: courseUserData.queriesSeen + incrementSeenBy,
                }
              : courseUserData,
          },
        }
      : updatedCourseSlice;
  const updatedQuerySlice = {
    ...state.queries,
    byId: {
      ...state.queries.byId,
      [query._id]: query,
    },
  };
  return {
    ...state,
    courses: updatedCourseSlice,
    class: updatedClassSlice,
    queries: updatedQuerySlice,
  };
}

export function fetchAllSuccess(
  state: IRootState,
  payload: actions.IFetchAllSuccessPayload
): IRootState {
  const queries = payload.activityData.map((q) =>
    responseQueryToQuery(payload.userData.queries[q._id], q.identifiers.classId, q)
  );
  const byId = utils.makeObjectWithKey(queries, '_id');
  return {
    ...state,
    queries: {
      ...state.queries,
      byId: byId,
    },
  };
}

export function approveSuccess(state: IRootState, payload: actions.IApproveSuccessPayload) {
  let newState = updateQueryInRootState(state, payload.queryId, (q) => ({
    ...q,
    details: {
      ...q.details,
      status: 'open',
    },
  }));
  const existingQuery = state.queries.byId[payload.queryId];
  if (!existingQuery || existingQuery.details.status !== payload.status) {
    newState = updateClassInRootState(newState, payload.classId, (cls) => ({
      ...cls,
      activities: {
        ...cls.activities,
        inClass:
          payload.toBeDone === 'inClass'
            ? {
                ...cls.activities.inClass,
                queries: {
                  ...cls.activities.inClass.queries,
                  numPending: (cls.activities.inClass.queries.numPending || 0) - 1,
                  numTotal: cls.activities.inClass.queries.numTotal + 1,
                },
              }
            : { ...cls.activities.inClass },
        preClass:
          payload.toBeDone === 'preClass'
            ? {
                ...cls.activities.preClass,
                queries: {
                  ...cls.activities.preClass.queries,
                  numPending: (cls.activities.preClass.queries.numPending || 0) - 1,
                  numTotal: cls.activities.preClass.queries.numTotal + 1,
                },
              }
            : { ...cls.activities.preClass },
        reviewQueries:
          payload.toBeDone === 'review'
            ? {
                ...cls.activities.reviewQueries,
                numPending: (cls.activities.reviewQueries.numPending || 0) - 1,
                numTotal: cls.activities.reviewQueries.numTotal + 1,
              }
            : { ...cls.activities.reviewQueries },
      },
    }));
    const courseId = newState.courses.currentCourseId;
    const course = courseId ? newState.courses.courses[courseId] : null;
    console.log(courseId);
    console.log(course);
    if (courseId && course) {
      newState = {
        ...newState,
        courses: {
          ...newState.courses,
          courses: {
            ...newState.courses.courses,
            [courseId]: {
              ...course,
              activities: {
                ...course.activities,
                numQueriesPending: (course.activities.numQueriesPending || 0) - 1,
                numQueries: (course.activities.numQueries || 0) + 1,
              },
            },
          },
        },
      };
    }
    return newState;
  } else {
    return state;
  }
}

export function pusherApproved(state: IRootState, payload: actions.IPusherCreatePayload) {
  const existingQuery = state.queries.byId[payload.queryId];
  if (getCourseRole(state) === 'student') {
    return pusherCreate(state, payload);
  } else if (!existingQuery || existingQuery.details.status !== payload.details.status) {
    let newState = updateQueryInRootState(state, payload.queryId, (q) => ({
      ...q,
      details: {
        ...q.details,
        status: payload.details.status,
      },
    }));
    newState = updateClassInRootState(newState, payload.classId, (cls) => ({
      ...cls,
      activities: {
        ...cls.activities,
        inClass:
          payload.details.toBeDone === 'inClass'
            ? {
                ...cls.activities.inClass,
                queries: {
                  ...cls.activities.inClass.queries,
                  numPending: (cls.activities.inClass.queries.numPending || 0) - 1,
                  numTotal: cls.activities.inClass.queries.numTotal + 1,
                },
              }
            : { ...cls.activities.inClass },
        preClass:
          payload.details.toBeDone === 'preClass'
            ? {
                ...cls.activities.preClass,
                queries: {
                  ...cls.activities.preClass.queries,
                  numPending: (cls.activities.preClass.queries.numPending || 0) - 1,
                  numTotal: cls.activities.preClass.queries.numTotal + 1,
                },
              }
            : { ...cls.activities.preClass },
        reviewQueries:
          payload.details.toBeDone === 'review'
            ? {
                ...cls.activities.reviewQueries,
                numPending: (cls.activities.reviewQueries.numPending || 0) - 1,
                numTotal: cls.activities.reviewQueries.numTotal + 1,
              }
            : { ...cls.activities.reviewQueries },
      },
    }));
    const courseId = newState.courses.currentCourseId;
    const course = courseId ? newState.courses.courses[courseId] : null;
    console.log(courseId);
    console.log(course);
    if (courseId && course) {
      newState = {
        ...newState,
        courses: {
          ...newState.courses,
          courses: {
            ...newState.courses.courses,
            [courseId]: {
              ...course,
              activities: {
                ...course.activities,
                numQueriesPending: (course.activities.numQueriesPending || 0) - 1,
                numQueries: (course.activities.numQueries || 0) + 1,
              },
            },
          },
        },
      };
    }
    return newState;
  } else {
    return state;
  }
}
