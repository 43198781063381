import { createReducer } from 'acadly/createReducer';
import { IRootState } from 'acadly/IRootState';
import { update } from 'acadly/utils';

export const reducer = createReducer<IRootState>({
  '@pip/show_class_media_player': (state, payload) => {
    return update<IRootState>(state, {
      pipContainers: {
        classMediaPlayer: {
          ...payload,
          show: true,
        },
      },
    });
  },
  '@pip/hide_class_media_player': (state, payload) => {
    return update<IRootState>(state, {
      pipContainers: {
        classMediaPlayer: {
          show: payload.show,
          title: '',
          url: '',
          recordingId: '',
          classId: '',
          courseId: '',
        },
      },
    });
  },
});
