import { CSS, h, IComponent } from 'core';

import Dialog from 'acadly/common/Dialog';
import TextField from 'acadly/common/TextField';
import { dispatch } from 'acadly/store';
import { colors, pad, style } from 'acadly/styles';

import { Actions } from './actions';

export interface IEditLinkDialogProps {
  open: boolean;
  link?: ILink;
  close: () => any;
}

interface IEditLinkDialogState {
  titleField: string;
  urlField: string;
}

export class EditLinkDialog extends IComponent<IEditLinkDialogProps, IEditLinkDialogState> {
  public componentWillMount() {
    this.initialize(this.getProps());
  }

  private initialize(props: IEditLinkDialogProps) {
    const initialState: IEditLinkDialogState = {
      titleField: props.link ? props.link.details.title : '',
      urlField: props.link ? props.link.details.title : '',
    };
    this.setState(initialState);
  }

  public componentWillReceiveProps(nextProps: IEditLinkDialogProps) {
    if (nextProps.open && !this.getProps().open) {
      this.initialize(nextProps);
    }
  }

  public render() {
    const props = this.getProps();
    const state = this.getState();
    const floatingLabelStyle: CSS | undefined = style(['blue']).style;

    const saveActionButton = {
      label: 'SAVE',
      mobileLabel: h('i.fa.fa-check', []),
      onclick: () => this.saveHandler(),
      disabled: !this.isFormValid(),
    };

    const deleteActionButton = {
      label: 'DELETE',
      mobileLabel: h('i.fa.fa-trash', []),
      onclick: () => this.deleteHandler(),
    };

    return Dialog(
      {
        open: props.open,
        title: this.inEditMode() ? 'Editing link' : 'Adding new link',
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: props.close,
        },
        primaryAction: this.inEditMode()
          ? [deleteActionButton, saveActionButton]
          : saveActionButton,
        style: {
          backgroundColor: colors.backgroundColor,
        },
      },
      [
        h('div', style(['whiteBackground', pad('0.5rem 1rem')]), [
          TextField({
            value: state.titleField,
            placeholder: 'Title',
            floatingLabelText: 'Title',
            floatingLabelStyle,
            oninput: (e) =>
              this.setState({
                titleField: e.target.value,
              }),
            maxLength: 140,
            style: {
              marginBottom: '1.5rem',
            },
            onenter: () => this.saveHandler(),
          }),
          TextField({
            value: state.urlField,
            placeholder: 'URL',
            floatingLabelText: 'URL',
            floatingLabelStyle,
            oninput: (e) =>
              this.setState({
                urlField: e.target.value,
              }),
            onenter: () => this.saveHandler(),
          }),
        ]),

        h('div', style(['small', 'lightGrey', pad('0.5rem 1rem')]), [
          'Please provide a URL beginning with https:// or http://',
        ]),
      ]
    );
  }

  private async saveHandler() {
    const state = this.getState();
    const props = this.getProps();

    if (!this.isFormValid()) {
      // Don't save invalid link.
      return;
    }

    if (!this.inEditMode()) {
      await dispatch(
        Actions.linkAdd({
          title: state.titleField,
          url: state.urlField,
          parent: 'course',
        })
      );
    }

    await props.close();
  }

  private deleteHandler() {}

  private isFormValid() {
    const state = this.getState();
    const urlField = state.urlField.trim();
    const isURLValid = urlField.startsWith('http://') || urlField.startsWith('https://');
    const urlRegex =
      // eslint-disable-next-line no-useless-escape
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[^/?%.\s]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const isValid = urlRegex.test(urlField);
    const isTitleValid = state.titleField.trim().length > 0;

    return isTitleValid && isURLValid && isValid;
  }

  private inEditMode() {
    return !!this.getProps().link;
  }
}

export default (props: IEditLinkDialogProps) => h(EditLinkDialog, props);
