import { CSS, h, IComponent } from 'core';

import Avatar from 'acadly/common/Avatar';
import { getStore } from 'acadly/store';
import { colors, ml, mr, style } from 'acadly/styles';

import * as dt from '../datetime';

export default (props: IQueryWidgetProps) => h(QueryWidget, props);
export interface IQueryWidgetProps {
  query: IQuery;
  courseRole: ICourseRole;
  onclick?: (e: Event) => any;
  showClassDate?: boolean;
  style?: CSS;
}
export class QueryWidget extends IComponent<IQueryWidgetProps, never> {
  public render() {
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    const { query, onclick, showClassDate } = this.getProps();
    const newComments = query.userData
      ? query.activities.numCommentsTotal - query.userData.numCommentsSeen
      : query.activities.numCommentsTotal;
    // const widgetColor = this.getWidgetColor();
    // const startTime = dt.fromUnix(query.details.publishedOn);
    return h(
      'div',
      {
        style: {
          backgroundColor: 'white',
          width: '100%',
          padding: '0.5em 1em',
          boxSizing: 'border-box',
          height: '100%',
          display: 'flex',
          cursor: query.details.isHidden ? undefined : 'pointer',
          position: 'relative',
          ...this.getProps().style,
        },
        tabIndex: isAccessible ? 0 : undefined,
        'aria-label': `Query asked by ${query.details.createdBy.name}`,
        onclick: query.details.isHidden ? undefined : onclick,
        key: 'query-widget-' + query._id,
      },
      !query.details.isHidden
        ? [
            h(
              'div.query-widget-lhs',
              {
                style: {
                  width: '4em',
                  display: 'flex',
                  position: 'relative',
                  boxSizing: 'border-box',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              },
              [
                query.stats.numAskers > 0
                  ? h(
                      'span',
                      style(
                        [
                          'absolute',
                          'white',
                          'flex',
                          'alignCenter',
                          'justifyCenter',
                          'small',
                          {
                            backgroundColor: colors.blue,
                            top: '0.3rem',
                            right: '0.3rem',
                            borderRadius: '50%',
                            width: '1.3rem',
                            height: '1.3rem',
                          },
                        ],
                        {},
                        {
                          title: 'Upvotes',
                        }
                      ),
                      [query.stats.numAskers.toString()]
                    )
                  : null,
                Avatar(query.details.createdBy.avatar, query.details.createdBy.name, {
                  className: 'query-widget__avatar',
                }),
              ]
            ),

            h(
              'div.query-widget-rhs',
              showClassDate ? style(['widgetFilterActivitiesRhs']) : style(['widgetRhs']),
              [
                h(
                  'div.query-widget-heading',
                  style([
                    'flex',
                    'alignCenter',
                    {
                      height: '1.5em',
                    },
                  ]),
                  [
                    h('span', style(['thick', 'mediumDarkGrey', mr('0.5em')]), 'Query'),

                    !query.userData
                      ? h('span', style(['orange', ml('0.5em'), 'flex', 'alignCenter']), [
                          h('i.fa.fa-star', style([mr('0.3em')])),
                          h('span', style(['x-small'], { fontWeight: '900' }), 'NEW'),
                        ])
                      : null,

                    query.userData && newComments
                      ? h('span', style(['orange', ml('0.5em')]), [
                          h('i.fa.fa-comment', style([mr('0.3em')])),
                          h('span', style(['small', 'bold']), newComments.toString()),
                        ])
                      : null,

                    this.isOpenTagVisible() ? this.tag('OPEN', colors.blue) : null,

                    this.isClosedTagVisible() ? this.tag('CLOSED', colors.grey) : null,

                    this.isPendingTagVisible() ? this.tag('PENDING', colors.orange) : null,

                    this.topRight(),
                  ]
                ),
                h(
                  'div.title-widget',
                  showClassDate
                    ? style(['widgetFilterActivitiesRhsTitle'])
                    : style(['widgetRhsTitle']),
                  query.details.title || 'Untitled'
                ),
              ]
            ),
          ]
        : [
            h(
              'div.query-widget-lhs',
              {
                style: {
                  width: '4em',
                  display: 'flex',
                  position: 'relative',
                  boxSizing: 'border-box',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              },
              [
                Avatar(query.details.createdBy.avatar, query.details.createdBy.name, {
                  className: 'query-widget__avatar',
                }),
              ]
            ),
            h(
              'div.query-widget-rhs',
              showClassDate ? style(['widgetFilterActivitiesRhs']) : style(['widgetRhs']),
              [
                h(
                  'div.query-widget-heading',
                  style([
                    'flex',
                    'alignCenter',
                    {
                      height: '1.5em',
                    },
                  ]),
                  [h('span', style(['thick', 'mediumDarkGrey', mr('0.5em')]), 'Query')]
                ),
                h(
                  'div.title-widget',
                  showClassDate
                    ? style(['widgetFilterActivitiesRhsTitle'])
                    : style(['widgetRhsTitle']),
                  query.details.title || 'Untitled'
                ),
              ]
            ),
          ]
    );
  }

  private topRight() {
    const { query } = this.getProps();
    const context = getStore().getState().app.context;

    if (context === 'course') {
      return h('div', style([ml('auto'), 'lightGrey', 'small']), [
        `Published: ${dt.format(query.details.publishedOn, 'MMM DD, YYYY')}`,
      ]);
    }

    return h('div', style([ml('auto'), 'lightGrey', 'small']), [
      `Responses: ${query.activities.numCommentsTotal}`,
    ]);
  }

  private isOpenTagVisible() {
    const { query } = this.getProps();
    return !query.details.isHidden && query.details.status === 'open';
  }
  private isClosedTagVisible() {
    const { query } = this.getProps();
    return query.details.status === 'closed';
  }

  private isPendingTagVisible() {
    const { query } = this.getProps();
    return query.details.status === 'pending';
  }
  // private getWidgetColor() {
  //     const { query } = this.getProps();
  //     if (this.getProps().courseRole === "student") {
  //         if (query.userData) {
  //             return colors.lightGrey;
  //         } else {
  //             return colors.blue;
  //         }
  //     } else {
  //         if (query.details.published) {
  //             return colors.lightGrey;
  //         } else {
  //             return colors.red;
  //         }
  //     }
  // }

  private tag(text: string, color: string) {
    return h(
      'div.tag-container',
      style([ml('0.8em')], {
        height: '80%',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        backgroundColor: color,
        textAlign: 'center',
        fontWeight: '900',
        borderRadius: '4px',
      }),
      [
        h(
          'div.tag-text',
          style([
            'white',
            'x-small',
            {
              lineHeight: '1.5em',
            },
          ]),
          [text]
        ),
      ]
    );
  }
}
// const DATE_CELL_ATTRS = {
//     style: {
//         display: "flex",
//         flexDirection: "column"
//     }
// };
// const DIV = "div.date-cell";
// function dateCell(startTime: Date, widgetColor: string) {
//     return h(DIV, DATE_CELL_ATTRS, [
//         DateView(startTime, widgetColor)
//     ]);
// }
