import { discussionService } from 'acadly/discussion/service';
import pollService from 'acadly/poll/service';
import queryService from 'acadly/query/service';
import quizService from 'acadly/quiz/service';
import { resourceService } from 'acadly/resource/service';
import { Routes } from 'acadly/routes';
import { getStore } from 'acadly/store';

export function getContextActivity(context: IAppContext): IAssignment | IClassActivity | null {
  const storeState = getStore().getState();
  const timeline = storeState.courses.timeline;
  if (!timeline) return null;
  if (context === 'assignment') {
    const match = Routes.courseAssignment.getMatch() || Routes.filteredAssignment.getMatch();
    if (match) {
      const assignment = timeline.items.find(
        (a) => a.nodeType === 'assignment' && a._id.slice(18) === match.assignmentShortId
      );
      if (assignment) {
        return assignment as IAssignment;
      }
    }
  } else if (context === 'quiz') {
    const match = Routes.classQuiz.getMatch() || Routes.filteredQuiz.getMatch();
    if (match) {
      const quizId = quizService.getQuizIdFromShortId(match.quizShortId);
      return storeState.quizzes.byId[quizId as any] || null;
    }
  } else if (context === 'poll') {
    const match = Routes.classPoll.getMatch() || Routes.filteredPoll.getMatch();
    if (match) {
      const pollId = pollService.getPollIdFromShortId(match.pollShortId);
      return storeState.polls.byId[pollId as any] || null;
    }
  } else if (context === 'resource') {
    const match = Routes.classResource.getMatch() || Routes.filteredResource.getMatch();
    if (match) {
      const resourceId = resourceService.getResourceIdFromShortId(match.resourceShortId);
      return storeState.resources.byId[resourceId as any] || null;
    }
  } else if (context === 'discussion') {
    const match = Routes.classDiscussion.getMatch() || Routes.filteredDiscussion.getMatch();
    if (match) {
      const discussionId = discussionService.getDiscussionIdFromShortId(match.discussionShortId);
      return storeState.discussions.byId[discussionId as any] || null;
    }
  } else if (context === 'query') {
    const match = Routes.classQuery.getMatch() || Routes.filteredQuery.getMatch();
    if (match) {
      const queryId = queryService.getQueryIdFromShortId(match.queryShortId);
      return storeState.queries.byId[queryId as any] || null;
    }
  }
  return null;
}
