import { updateAssignment } from 'acadly/assignment/functions';
import { updateNumActivities } from 'acadly/course/functions';
import { createReducer } from 'acadly/createReducer';
import { unix } from 'acadly/datetime';
import { IRootState } from 'acadly/IRootState';
import * as utils from 'acadly/utils';

import * as Actions from './actions';
export const reducer = createReducer({
  '@assignment/FETCH_DATA_REQUEST': fetchDataRequest,
  '@assignment/FETCH_DATA_SUCCESS': fetchDataSuccess,
  '@assignment/question/ADD_SUCCESS': questionAddSuccess,
  '@assignment/question/EDIT_SUCCESS': questionEditSuccess,
  '@assignment/question/DELETE_SUCCESS': questionDeleteSuccess,
  '@assignment/LEAVE_ASSIGNMENT_PAGE': leaveAssignmentPage,
  '@assignment/CREATE_SUCCESS': createSuccess,
  '@assignment/DELETE_SUCCESS': deleteSuccess,
  '@assignment/EDIT_SUCCESS': editSuccess,
  '@assignment/PUBLISH_SUCCESS': publishSuccess,
  '@assignment/SUBMISSION_UPLOAD_SUCCESS': submissionUploadSuccess,
  '@assignment/SUBMIT_SUCCESS': submitSuccess,
  '@assignment/RETRACT_SUCCESS': retractSuccess,
  '@assignment/analytics/team/FETCH_SUCCESS': teamAnalyticsFetchSuccess,
  '@assignment/grades/SUBMIT_SUCCESS': gradesSubmitSuccess,
  '@assignment/SUBMISSION_URL_SAVE_SUCCESS': submissionURLSaveSucess,
  '@assignment/pusher/SUBMITTED': pusherSubmitted,
  '@assignment/pusher/RETRACTED': pusherRetracted,
  '@assignment/UPDATE_PUBLISHED_ASSIGNMENT_SUCCESS': updatePublishedAssignmentSuccess,
});

export function updatePublishedAssignmentSuccess(
  state: IRootState,
  payload: Actions.IUpdateAssignmentRequestLocalData
): IRootState {
  const newStateWithQuestions = {
    ...state,
    assignment: {
      ...state.assignment,
      currentAssignmentQuestions: payload.questions
        ? [...payload.questions]
        : [...(state.assignment.currentAssignmentQuestions || [])],
    },
  };
  return updateAssignment(newStateWithQuestions, payload.assignmentId, (a) => ({
    ...a,
    details: {
      ...a.details,
      title: payload.title,
      instructions: payload.instructions,
      attachments: payload.attachments,
      dueDateTime: payload.dueDateTime,
    },
  }));
}

export function submissionURLSaveSucess(
  state: IRootState,
  payload: Actions.ISubmissionURLSaveSuccess
): IRootState {
  let newSubmission = state.assignment.submission || {};
  const questionSub = newSubmission[payload.request.questionId];
  const url = {
    url: payload.request.url,
    savedOn: payload.response.uploadedOn,
  };
  if (questionSub) {
    newSubmission = {
      ...newSubmission,
      [payload.request.questionId]: {
        ...questionSub,
        url: url,
      },
    };
  } else {
    newSubmission = {
      ...newSubmission,
      [payload.request.questionId]: {
        url: url,
        comments: {
          content: '',
          author: {
            avatar: '',
            name: '',
            role: '',
            userId: '',
          },
        },
        grade: 0,
        marks: 0,
      },
    };
  }
  return {
    ...state,
    assignment: {
      ...state.assignment,
      submission: newSubmission,
    },
  };
}

export function fetchDataRequest(state: IRootState): IRootState {
  return {
    ...state,
    assignment: {
      currentAssignmentQuestions: null,
    },
  };
}

export function fetchDataSuccess(
  state: IRootState,
  payload: Actions.IFetchDataSuccessPayload
): IRootState {
  const stateWithQuestions = {
    ...state,
    assignment: {
      currentAssignmentQuestions: payload.questions,
      submission: payload.submission,
      areQuestionsEdited: false,
    },
  };
  if (payload.firstAttempt) {
    return updateAssignment(stateWithQuestions, payload.assignmentId, (a) => ({
      ...a,
      userData: {
        ...a.userData,
        studentUserData: {
          status: 'inProgress',
          timesStarted: 1,
          retracted: 0,
        },
      },
    }));
  } else {
    return updateAssignment(stateWithQuestions, payload.assignmentId, (a) => ({
      ...a,
      userData: {
        ...a.userData,
        studentUserData: a.userData.studentUserData
          ? {
              ...a.userData.studentUserData,
              lastAccessedOn: unix(),
            }
          : a.userData.studentUserData,
      },
    }));
    // return stateWithQuestions;
  }
}

export function questionAddSuccess(
  state: IRootState,
  payload: Actions.IQuestionAddSuccessPayload
): IRootState {
  const updatedCoursesSlice = addNumQuestions(state.courses, payload.assignmentId, 1);
  return {
    ...state,
    assignment: {
      currentAssignmentQuestions: [
        ...state.assignment.currentAssignmentQuestions!,
        payload.question,
      ],
    },
    courses: updatedCoursesSlice,
  };
}

function addNumQuestions(courses: IRootState['courses'], assignmentId: string, num: number) {
  if (!courses.timeline) return courses;
  return utils.update(courses, {
    timeline: {
      items: utils.replaceWhere(
        courses.timeline.items,
        (a: IAssignment) =>
          utils.update(a, {
            details: {
              numQuestions: a.details.numQuestions + num,
            },
          }),
        (item) => item._id === assignmentId
      ),
    },
  });
}

export function questionEditSuccess(
  state: IRootState,
  payload: Actions.IQuestionEditSuccessPayload
): IRootState {
  if (!state.assignment.currentAssignmentQuestions) return state;
  return {
    ...state,
    assignment: {
      currentAssignmentQuestions: utils.replaceWhere(
        state.assignment.currentAssignmentQuestions,
        (question) => ({
          ...question,
          details: {
            ...question.details,
            description: payload.description,
            marks: payload.marks,
            submitExt: payload.submitExt,
            submission: payload.submission,
            attachments: payload.attachments,
          },
        }),
        (question) => question._id === payload.questionId
      ),
    },
  };
}

export function leaveAssignmentPage(state: IRootState): IRootState {
  return {
    ...state,
    assignment: {
      currentAssignmentQuestions: null,
    },
  };
}

export function questionDeleteSuccess(
  state: IRootState,
  payload: Actions.IQuestionDeleteSuccessPayload
): IRootState {
  const currentAssignmentQuestions = state.assignment.currentAssignmentQuestions;
  if (!currentAssignmentQuestions) return state;
  const updatedCoursesSlice = addNumQuestions(state.courses, payload.assignmentId, -1);
  return {
    ...state,
    assignment: {
      currentAssignmentQuestions: currentAssignmentQuestions.filter(
        (q) => q._id !== payload.questionId
      ),
    },
    courses: updatedCoursesSlice,
  };
}

export function createSuccess(
  state: IRootState,
  payload: Actions.ICreateSuccessPayload
): IRootState {
  if (payload.response.hasSuggestedAssignments) return state;

  const response = payload.response.newAssignment;
  const timeline = state.courses.timeline;

  if (!timeline) return state;

  const newAssignment: IAssignment = {
    identifiers: {
      courseId: payload.courseId,
      universityId: '',
    },
    ...response,
    details: {
      ...response.details,
      title: 'Untitled',
      maxMarks: 0,
    },
    userData: {
      numCommentsSeen: 0,
      numCommentsSeenPostSub: 0,
      numCommentsSeenPreSub: 0,
      subscribed: 0,
    },
    removed: 0,
  };
  const updatedCourseSlice = {
    ...state.courses,
    ...updateNumAssignments(state.courses, 'numTotal', 1),
    timeline: {
      ...timeline,
      items: [
        ...timeline.items.filter(
          (item) => item.details.dueDateTime <= response.details.dueDateTime
        ),
        newAssignment,
        ...timeline.items.filter((item) => item.details.dueDateTime > response.details.dueDateTime),
      ],
    },
  };
  return {
    ...state,
    courses: updatedCourseSlice,
  };
}

export function deleteSuccess(
  state: IRootState,
  payload: Actions.IDeleteSuccessPayload
): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const updatedCoursesSlice = updateNumAssignments(state.courses, 'numTotal', -1);
  return {
    ...state,
    courses: {
      ...state.courses,
      ...updatedCoursesSlice,
      timeline: {
        ...timeline,
        items: timeline.items.filter((item) => item._id !== payload.assignmentId),
      },
    },
  };
}

function updateNumAssignments(
  courses: IRootState['courses'],
  type: 'numPublished' | 'numTotal',
  num: number
): IRootState['courses'] {
  return courses.currentCourseId
    ? utils.update(courses, {
        courses: {
          [courses.currentCourseId]: updateNumActivities(
            courses.courses[courses.currentCourseId],
            'assignments',
            type,
            num
          ),
        },
      })
    : courses;
}

function editSuccess(state: IRootState, payload: Actions.IEditSuccessPayload): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const updates = payload;
  const updatedCoursesSlice = {
    ...state.courses,
    timeline: {
      ...timeline,
      items: timeline.items.map((item) =>
        item.nodeType === 'assignment' && item._id === payload.assignmentId
          ? {
              ...item,
              details: {
                ...item.details,
                title: updates.title,
                instructions: updates.instructions,
                attachments: updates.attachments,
              },
            }
          : item
      ),
    },
  };
  return {
    ...state,
    courses: updatedCoursesSlice,
  };
}

function publishSuccess(
  state: IRootState,
  payload: Actions.IPublishAssignmentSuccessPayload
): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const maxMarks = getMaxMarks(state);
  const updatedCoursesSlice = {
    ...state.courses,
    ...updateNumAssignments(state.courses, 'numPublished', 1),
    timeline: {
      ...timeline,
      items: utils
        .replaceWhere(
          timeline.items,
          (item: IAssignment) => ({
            ...item,
            details: {
              ...item.details,
              published: 1 as const,
              num: payload.num,
              maxMarks: maxMarks,
              publishedOn: payload.publishedOn,
              dueDateTime: payload.dueDateTime,
              allowLate: (payload.allowLate ? 1 : 0) as 0 | 1,
            },
            userData: {
              numCommentsSeen: 0,
              numCommentsSeenPostSub: 0,
              numCommentsSeenPreSub: 0,
              subscribed: payload.subscribed,
            },
          }),
          (item) => item.nodeType === 'assignment' && item._id === payload.assignmentId
        )
        .sort((a, b) => a.details.dueDateTime - b.details.dueDateTime),
    },
  };
  return {
    ...state,
    courses: updatedCoursesSlice,
  };
}

function getMaxMarks(state: IRootState): number {
  const currentAssignmentQuestions = state.assignment.currentAssignmentQuestions;
  if (!currentAssignmentQuestions) {
    return 0;
  }
  const questionLength = currentAssignmentQuestions.length;
  let maxMarks = 0;
  for (let i = 0; i < questionLength; i++) {
    maxMarks = maxMarks + currentAssignmentQuestions![i].details.marks;
  }
  return maxMarks;
}

function submissionUploadSuccess(
  state: IRootState,
  payload: Actions.ISubmissionUploadSuccessPayload
): IRootState {
  const assignment = state.courses.timeline
    ? (state.courses.timeline.items.find(
        (a) => a._id === payload.request.assignmentId
      ) as IAssignment)
    : null;
  const questionSubmission: IAssignmentSubmission[string] = {
    comments: {
      content: '',
      author: {
        avatar: '',
        role: '',
        name: '',
        userId: '',
      },
    },
    grade: 0,
    marks: 0,
    file: {
      name: payload.response.name,
      extension: payload.response.type,
      originalName: utils.splitFileName(payload.request.file.name).name,
      uploadedOn: payload.response.uploadedOn,
    },
    prevSub:
      assignment &&
      assignment.userData.studentUserData &&
      assignment.userData.studentUserData.retracted &&
      state.assignment.submission &&
      state.assignment.submission[payload.request.questionId] &&
      state.assignment.submission[payload.request.questionId]!.file
        ? (state.assignment.submission[payload.request.questionId]!.prevSub || []).concat([
            {
              submittedOn: assignment.userData.studentUserData.submittedOn || 0,
              file: state.assignment.submission[payload.request.questionId]!.file!,
            },
          ])
        : undefined,
  };
  const submission: IAssignmentSubmission = state.assignment.submission
    ? { ...state.assignment.submission }
    : {};
  submission[payload.request.questionId] = questionSubmission;
  return {
    ...state,
    assignment: {
      ...state.assignment,
      submission: submission,
    },
  };
}

function submitSuccess(state: IRootState, payload: Actions.ISubmitSuccessPayload): IRootState {
  return updateAssignment(state, payload.request.assignmentId, (a) => ({
    ...a,
    userData: {
      ...a.userData,
      studentUserData: {
        timesStarted: 1,
        retracted: 0,
        // ^ default values; overwritten by existing
        // studentUserData values if it exists
        // these should come before ...a.userData.studentUserData
        // line otherwise, they'll overwrite existing student data

        ...a.userData.studentUserData,
        status: payload.response.status,
      },
    },
  }));
}

function retractSuccess(state: IRootState, assignmentId: string): IRootState {
  return updateAssignment(state, assignmentId, (a) => ({
    ...a,
    userData: {
      ...a.userData,
      studentUserData: {
        timesStarted: 1,
        ...a.userData.studentUserData,
        retracted: 1,
        status: 'inProgress',
      },
    },
  }));
}

function teamAnalyticsFetchSuccess(
  state: IRootState,
  payload: Actions.ITeamAnalyticsFetchSuccess
): IRootState {
  return {
    ...state,
    assignment: {
      ...state.assignment,
      teamAnalytics: payload,
    },
  };
}

function gradesSubmitSuccess(state: IRootState, payload: Actions.IGradesSubmitPayload): IRootState {
  const courseSlice = updateAssignment(state, payload.assignmentId, (a) => ({
    ...a,
    stats: a.stats
      ? { ...a.stats, numGraded: a.stats.numGraded + 1 }
      : { numSubmitted: 1, numGraded: 1 },
  })).courses;

  const teamMember = state.courses.courses[state.courses.currentCourseId!].team.find(
    (u) => u.userId === state.getIn.session!.userId
  )!;

  const assignmentSlice = state.assignment.teamAnalytics
    ? {
        ...state.assignment,
        teamAnalytics: {
          ...state.assignment.teamAnalytics,
          submittedBy: utils.replaceWhere(
            state.assignment.teamAnalytics.submittedBy,
            (sub) => ({
              ...sub,
              status: {
                ...sub.status,
                graded: {
                  status: 1 as const,
                  score: payload.grades.reduce((sum, s) => sum + s.marks, 0),
                  on: payload.timestamp,
                  lastGradedBy: teamMember,
                },
              },
            }),
            (sub) => sub.identifiers.userId === payload.studentId
          ),
        },
      }
    : state.assignment;

  return {
    ...state,
    courses: courseSlice,
    assignment: assignmentSlice,
  };
}

export function pusherSubmitted(
  state: IRootState,
  payload: Actions.IPusherSubmittedPayload
): IRootState {
  return updateAssignment(state, payload.assignmentId, (a) => ({
    ...a,
    stats: a.stats
      ? {
          ...a.stats,
          numSubmitted: a.stats.numSubmitted + 1,
        }
      : {
          numGraded: 0,
          numSubmitted: 1,
        },
  }));
}

export function pusherRetracted(
  state: IRootState,
  payload: Actions.IPusherSubmittedPayload
): IRootState {
  return updateAssignment(state, payload.assignmentId, (a) => ({
    ...a,
    stats: a.stats
      ? {
          ...a.stats,
          numSubmitted: a.stats.numSubmitted - 1,
        }
      : {
          numGraded: 0,
          numSubmitted: 0,
        },
  }));
}
