import cn from 'classnames';

import { CSS, h, IComponent, View } from 'core';

import { getStore } from 'acadly/store';

import portal from './Portal';

/*
 * If a Alert has an input field,
 * do remember to pass the key hasInput
 */
export interface IAlertFunctionProps {
  open: boolean;
  isAccessible?: boolean;
  actions?: View[];
  title?: View;
  style?: CSS;
  className?: string;
  rootClassName?: string;
  center?: boolean;
  overlayStyle?: CSS;
  overlayClassName?: string;
  actionsStyle?: CSS;
  actionsClassName?: string;
  onOverlayClick?: () => any;
  verticalActions?: boolean;
  titleStyle?: CSS;
  titleClassName?: string;
  bodyStyle?: CSS;
  bodyClassName?: string;
  toggle?: () => any;
  id?: string;
  hasInput?: boolean;
  role?: string;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
}

export interface IAlertProps extends IAlertFunctionProps {
  children: View[];
}

export type IOpenState = 'opening' | 'open' | 'closed' | 'closing';
export interface IAlertState {
  openState: IOpenState;
  isClosing: boolean;
}

export const timeoutPromise = <A>(resolveWith: A, duration: number) =>
  new Promise((resolve) => setTimeout(() => resolve(resolveWith), duration));

export const isIOS = /iPad|iPhone/.test(navigator.userAgent);
@portal(document.getElementById('dialog-container'))
export class Alert extends IComponent<IAlertProps, IAlertState> {
  private isAccessible: boolean;

  private alert?: HTMLDivElement;
  private firstChildElem?: HTMLElement;
  private lastChildElem?: HTMLElement;

  public componentWillMount() {
    this.setState({ isClosing: false });
    const isAccessible = this.getProps().isAccessible;

    if (isAccessible !== undefined) {
      this.isAccessible = isAccessible;
    } else {
      this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    }
  }

  public componentDidUpdate(prevProps: IAlertProps) {
    const currentProps = this.getProps();

    if (!prevProps.open && currentProps.open) {
      // opening
      timeoutPromise({}, 500)
        .then(() => this.addEventListener())
        .then(() => {
          if (this.isAccessible && this.alert) {
            this.alert.focus();
          }
        });
    }

    if (prevProps.open && !currentProps.open) {
      // closing
      this.setState({ isClosing: true })
        .then(() => timeoutPromise({}, 500))
        .then(() => this.setState({ isClosing: false }))
        .then(() => this.cleanupAlert());
    }
  }

  public render() {
    const props = this.getProps();
    const isMobile = getStore().getState().app.isMobile;
    const children = props.children;
    const state = this.getState();
    const hasInput = this.getProps().hasInput;

    if (!props.open) return null;

    return h(
      'div',
      {
        id: 'alert-box',
        role: 'presentation',
        className: cn('alert__presentation', 'animated', props.rootClassName, {
          fadeOut: state.isClosing,
          fadeIn: !state.isClosing,
        }),
      },
      [
        h('div', {
          'aria-hidden': true,
          style: props.overlayStyle,
          onclick: props.onOverlayClick,
          className: cn('alert__overlay', props.overlayClassName),
        }),
        h(
          'div',
          {
            id: props.id,
            role: props.role || 'alert',
            'aria-labelledby': props.ariaLabelledBy,
            'aria-describedby': props.ariaDescribedBy,
            'aria-label': props.ariaLabel || (typeof props.title === 'string' ? props.title : ''),
            tabIndex: this.isAccessible ? 0 : undefined,
            style: props.style,
            ref: (el) => {
              this.alert = el as HTMLDivElement;
            },
            className: cn('alert', 'animated', props.className, {
              center: props.center,
              fixed: isMobile && isIOS && hasInput,
              bounceInUp: !state.isClosing,
              bounceOutUp: state.isClosing,
            }),
          },
          [
            props.title
              ? h(
                  'div',
                  {
                    style: props.titleStyle,
                    tabindex: this.isAccessible ? 0 : undefined,
                    className: cn('alert__title', props.titleClassName, {
                      center: props.center,
                    }),
                  },
                  [props.title]
                )
              : null,
            h(
              'div',
              {
                style: props.bodyStyle,
                className: cn('alert__body', props.bodyClassName, {
                  'no-title': !props.title,
                }),
              },
              children
            ),
            props.actions
              ? h(
                  'div',
                  {
                    style: props.actionsStyle,
                    className: cn('alert__actions', props.actionsClassName, {
                      block: props.verticalActions,
                      inline: !props.verticalActions,
                    }),
                  },
                  props.actions
                )
              : null,
          ]
        ),
      ]
    );
  }

  private cleanupAlert() {
    this.cleanupFirstElem();
    this.cleanupLastElem();
  }

  private cleanupLastElem() {
    if (this.lastChildElem) {
      this.lastChildElem.removeEventListener('keydown', this.takeFocusToFirstElement);
      this.lastChildElem = undefined;
    }
  }

  private cleanupFirstElem() {
    if (this.firstChildElem) {
      this.firstChildElem.removeEventListener('keydown', this.takeFocustoLastElement);
      this.firstChildElem = undefined;
    }
  }

  private getTabbableElements() {
    if (this.alert) {
      const focusabeSelectors = [
        'a[href]',
        'area[href]',
        'input:not([disabled])',
        'select:not([disabled])',
        'textarea:not([disabled])',
        'button:not([disabled])',
        'iframe',
        'object',
        `[tabindex="0"]`,
        '[contenteditable]',
      ];
      const focusable = this.alert.querySelectorAll(focusabeSelectors.join(', '));
      return focusable;
    }
    return [];
  }

  private setFirstElement() {
    if (!this.alert || this.firstChildElem) return;
    const focusable = this.getTabbableElements();
    this.firstChildElem = focusable[0] as HTMLElement;
  }

  private setLastElement() {
    if (!this.alert || this.lastChildElem) return;
    const focusable = this.getTabbableElements();
    this.lastChildElem = focusable[focusable.length - 1] as HTMLElement;
  }

  private takeFocusToFirstElement = (e: KeyboardEvent) => {
    if (!e.shiftKey && e.code === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      if (this.firstChildElem) {
        if (document.activeElement !== this.firstChildElem) {
          this.firstChildElem.focus();
        }
      }
    }
  };

  private takeFocustoLastElement = (e: KeyboardEvent) => {
    if (e.shiftKey && e.code === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      if (this.lastChildElem) {
        if (document.activeElement !== this.lastChildElem) {
          this.lastChildElem.focus();
        }
      }
    }
  };

  private addEventListener() {
    this.setFirstElement();
    this.setLastElement();

    if (this.lastChildElem) {
      this.lastChildElem.addEventListener('keydown', this.takeFocusToFirstElement);
    }

    if (this.firstChildElem) {
      this.firstChildElem.addEventListener('keydown', this.takeFocustoLastElement);
    }
  }
}

export default (options: IAlertFunctionProps, children: View[]) => {
  return h(Alert, { ...options, children } as any);
};
