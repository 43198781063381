import { CSS, h, IComponent } from 'core';

import DateView from 'acadly/common/DateView';
import Icon from 'acadly/common/Icon';
import icons from 'acadly/icons';
import { getStore } from 'acadly/store';
import { colors, ml, mr, style } from 'acadly/styles';

import * as dt from '../datetime';
import { isQuizSubmitted } from './functions';

export default (props: IQuizWidgetProps) => h(QuizWidget, props);
export interface IQuizWidgetProps {
  quiz: IQuiz;
  courseRole: ICourseRole;
  onclick?: (e: Event) => any;
  showClassDate?: boolean;
  style?: CSS;
}
export class QuizWidget extends IComponent<IQuizWidgetProps, never> {
  public render() {
    const { quiz, courseRole, onclick, showClassDate } = this.getProps();
    const userData = quiz.userData;
    const widgetColor = this.getWidgetColor();
    const seenComments = quiz.userData ? quiz.userData.numCommentsSeen : 0;
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    const unseenComments = quiz.activities.numCommentsTotal - seenComments;
    const isUnseen = this.getProps().courseRole === 'student' && !userData;
    const startTime = dt.fromUnix(quiz.details.publishedOn);
    const status = quiz.userData && quiz.userData.status;
    const statusText = status === 'inProgress' || status === undefined ? 'Due' : 'Completed';

    return h(
      'div',
      {
        style: {
          width: '100%',
          backgroundColor: 'white',
          height: '100%',
          padding: '0.5em 1em',
          display: 'flex',
          cursor: 'pointer',
          boxSizing: 'border-box',
          position: 'relative',
          borderBottom: `1px solid ${colors.lightestGrey}`,
          ...this.getProps().style,
        },
        'aria-label':
          `Quiz ${quiz.details.trueNum} - ${quiz.details.title || 'Untitled'}` +
          `${courseRole === 'student' ? (status ? `Status: ${statusText}` : '') : ''}`,
        onclick: onclick,
        tabIndex: isAccessible ? 0 : undefined,
        key: 'quiz-widget-' + quiz._id,
      },
      [
        h(
          'div.quiz-widget-lhs',
          {
            style: {
              width: '4em',
              color: widgetColor,
              display: 'flex',
              boxSizing: 'border-box',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
          [
            showClassDate
              ? dateCell(startTime, widgetColor)
              : Icon(icons.quiz, {
                  style: {
                    fontSize: '2em',
                  },
                }),
          ]
        ),
        h(
          'div.quiz-widget-rhs',
          showClassDate ? style(['widgetFilterActivitiesRhs']) : style(['widgetRhs']),
          [
            h(
              'div.quiz-widget-heading',
              style([
                'flex',
                {
                  height: '1.5em',
                },
              ]),
              [
                h(
                  'span',
                  style(['thick', 'mediumDarkGrey', mr('0.5em')]),
                  'Quiz ' +
                    (quiz.details.trueNum !== undefined && quiz.details.published
                      ? quiz.details.trueNum.toString().length === 1
                        ? ` 0${quiz.details.trueNum}` // adding 0 before single digits
                        : ` ${quiz.details.trueNum}`
                      : '')
                ),
                !quiz.details.published ? this.tag('UNPUBLISHED', colors.red) : null,

                this.isUrgent() ? this.tag('URGENT', colors.darkPink) : null,

                this.isClosed() ? this.tag('CLOSED', colors.red) : null,

                this.isPastDue() ? this.tag('PAST DUE', colors.red) : null,

                this.isDue() ? this.tag('DUE', colors.green) : null,

                isUnseen
                  ? h('span', style(['orange', 'flex', 'alignCenter', ml('0.5em')]), [
                      h('i.fa.fa-star', style([mr('0.3em')])),
                      h('span', style(['x-small'], { fontWeight: '900' }), 'NEW'),
                    ])
                  : !(
                      // don't show new comment bubble to students who haven't submitted
                      (courseRole === 'student' && !isQuizSubmitted(quiz))
                    )
                  ? unseenComments > 0
                    ? h('span', style(['orange', 'flex', 'alignCenter', ml('0.5em')]), [
                        h('i.fa.fa-comment', style([mr('0.5em')])),
                        h('span', style(['x-small', 'bold']), unseenComments.toString()),
                      ])
                    : null
                  : null,
                this.isUpdatedTagVisible()
                  ? h('span', style(['lightGreyText', ml('0.5em'), 'flex', 'alignCenter']), [
                      h('i.fa.fa-pencil', style([mr('0.3rem')])),
                      h('span', style(['x-small'], { fontWeight: '300' }), 'EDITED'),
                    ])
                  : null,

                this.topRight(),
              ]
            ),
            h(
              'div.title-widget',
              showClassDate ? style(['widgetFilterActivitiesRhsTitle']) : style(['widgetRhsTitle']),
              quiz.details.title || 'Untitled'
            ),
          ]
        ),
      ]
    );
  }

  private topRight() {
    let text = '';
    const context = getStore().getState().app.context;
    const { courseRole, quiz } = this.getProps();
    if (courseRole !== 'student') {
      if (context === 'course') {
        text = quiz.details.published
          ? `Published: ${dt.format(quiz.details.publishedOn, 'MMM DD, YYYY')}`
          : `Created: ${dt.format(quiz.details.createdOn, 'MMM DD, YYYY')}`;
      } else if (quiz.details.published && quiz.stats) {
        text = `Submissions: ${quiz.stats.numSubmitted}`;
      }
    } else {
      if (quiz.userData && quiz.userData.score) {
        text = `Score: ${quiz.userData.score[quiz.details.scoring]
          .toFixed(2)
          .replace(/\.(00|0)$/, '')}/${quiz.userData.score.maxScore}`;
      }
    }
    return h('span', style([ml('auto'), 'lightGrey', 'small']), [text]);
  }

  private tag(text: string, color: string) {
    return h(
      'div.tag-container',
      style([ml('0.8em')], {
        height: '80%',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        backgroundColor: color,
        textAlign: 'center',
        fontWeight: '900',
        borderRadius: '4px',
      }),
      [
        h(
          'div.tag-text',
          style([
            'white',
            'x-small',
            {
              lineHeight: '1.5em',
            },
          ]),
          [text]
        ),
      ]
    );
  }

  private isClosed() {
    const { quiz, courseRole } = this.getProps();
    return (
      courseRole === 'student' &&
      !isQuizSubmitted(quiz) &&
      !quiz.details.allowLate &&
      ((quiz.details.dueDateType === 'manual' && quiz.details.dueDateTime !== -1) ||
        (quiz.details.dueDateType !== 'manual' && dt.unix() > quiz.details.dueDateTime))
    );
  }

  private isPastDue() {
    const { quiz, courseRole } = this.getProps();
    return (
      courseRole === 'student' &&
      !isQuizSubmitted(quiz) &&
      quiz.details.allowLate &&
      ((quiz.details.dueDateType === 'manual' && quiz.details.dueDateTime !== -1) ||
        (quiz.details.dueDateType !== 'manual' && dt.unix() > quiz.details.dueDateTime))
    );
  }

  private isUrgent() {
    const { quiz, courseRole } = this.getProps();
    return (
      quiz.details.toBeDone === 'preClass' &&
      courseRole === 'student' &&
      !isQuizSubmitted(quiz) &&
      dt.unix() < quiz.details.dueDateTime &&
      dt.diff(dt.fromUnix(quiz.details.dueDateTime), dt.now(), 'hours') <= 24
    );
  }

  private isDue() {
    const { quiz, courseRole } = this.getProps();
    return (
      courseRole === 'student' &&
      !isQuizSubmitted(quiz) &&
      !this.isUrgent() &&
      ((quiz.details.dueDateType === 'manual' && quiz.details.dueDateTime === -1) ||
        dt.unix() <= quiz.details.dueDateTime)
    );
  }

  private isUpdatedTagVisible() {
    const { quiz, courseRole } = this.getProps();
    if (quiz.userData && quiz.userData.submittedOn) {
      return false;
    }
    if (quiz.userData && courseRole === 'student' && quiz.details.lastEditedOn) {
      const lastAccessedOn = quiz.userData.lastAccessedOn;
      return lastAccessedOn && lastAccessedOn < quiz.details.lastEditedOn;
    }
    return false;
  }

  private getWidgetColor() {
    const { quiz } = this.getProps();
    if (this.getProps().courseRole === 'student') {
      if (this.isPastDue() || this.isClosed()) {
        return colors.red;
      } else if (this.isUrgent()) {
        return colors.darkPink;
      } else if (quiz.details.toBeDone === 'inClass' && this.isDue()) {
        return colors.green;
      } else if (quiz.details.toBeDone === 'preClass' && this.isDue()) {
        return colors.blue;
      }
      if (!quiz.userData) return colors.blue;
      if (quiz.userData.status === 'inProgress') {
        return colors.blue;
      } else {
        return colors.lightGrey;
      }
    } else {
      if (quiz.details.published) {
        return colors.lightGrey;
      } else {
        return colors.red;
      }
    }
  }
}
const DATE_CELL_ATTRS = {
  style: {
    display: 'flex',
    flexDirection: 'column',
  },
};
const DIV = 'div.date-cell';
function dateCell(startTime: Date, widgetColor: string) {
  return h(DIV, DATE_CELL_ATTRS, [DateView(startTime, widgetColor)]);
}
