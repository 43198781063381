import { h, IComponent } from 'core';

import { AnalyticsHeader } from 'acadly/app/AnalyticsHeader';
import AssignmentAnalytics from 'acadly/assignment/Analytics';
import assignmentService from 'acadly/assignment/service';
import DiscussionWordCloud from 'acadly/discussion/DiscussionWordCloud';
import discussionService from 'acadly/discussion/service';
import PollAnalytics from 'acadly/poll/Analytics';
import QuizAnalytics from 'acadly/quiz/Analytics';
import ResourceAnalytics from 'acadly/resource/Analytics';
import { Routes } from 'acadly/routes';

import ClassAnalytics from '../class/ClassAnalytics';
import classService from '../class/service';
import CourseAnalytics from '../course/CourseAnalytics';
import courseService from '../course/service';
import pollService from '../poll/service';
import quizService from '../quiz/service';
import resourceService from '../resource/service';
import { getStore } from '../store';
import { style } from '../styles';

export default () => h(Analytics);

export class Analytics extends IComponent<never, never> {
  public render() {
    const isMobile = getStore().getState().app.isMobile;
    const isOpen = getStore().getState().app.isAnalyticsOpen;
    return h('div.analytics', [
      !isMobile ? this.renderHeader(isOpen) : null,
      h('div.analytics__body', [
        getStore().getState().app.isAnalyticsOpen ? this.getAnalyticsPage() : null,
      ]),
    ]);
  }

  private renderHeader(isOpen: boolean) {
    return AnalyticsHeader({
      label: this.getTitleText(),
      isOpen,
    });
  }

  private getAnalyticsPage() {
    const context = getStore().getState().app.context;
    const courseRole = courseService.getRole();
    switch (context) {
      case 'course':
        return CourseAnalytics(courseService.getCurrentCourse());
      case 'class':
        return courseRole !== 'student' ? ClassAnalytics(classService.getCurrentClass()) : null;
      case 'assignment': {
        const assignment = assignmentService.getCurrentAssignment();
        if (assignment) {
          return AssignmentAnalytics({
            assignment: assignment,
          });
        } else return null;
      }
      case 'resource': {
        const resourceMatch = Routes.classResource.getMatch() || Routes.filteredResource.getMatch();
        if (!resourceMatch) return null;

        const resourceId = resourceService.getResourceIdFromShortId(resourceMatch.resourceShortId);
        const resources = getStore().getState().resources;
        const resource = resources.byId[resourceId as any];
        if (resource) {
          return ResourceAnalytics(resource);
        } else return null;
      }
      case 'poll': {
        const pollMatch = Routes.classPoll.getMatch() || Routes.filteredPoll.getMatch();
        if (!pollMatch) return null;
        const pollId = pollService.getPollIdFromShortId(pollMatch.pollShortId);
        const polls = getStore().getState().polls.byId;
        const poll = polls[pollId as any];
        if (!poll) return null;
        return PollAnalytics(poll);
      }
      case 'quiz': {
        const quizMatch = Routes.classQuiz.getMatch() || Routes.filteredQuiz.getMatch();
        if (quizMatch) {
          const quizId = quizService.getQuizIdFromShortId(quizMatch.quizShortId);
          const quiz = getStore().getState().quizzes.byId[quizId as any]; // can be null
          if (quiz) {
            return QuizAnalytics(quiz);
          }
        }
        return null;
      }
      case 'discussion': {
        const match = Routes.classDiscussion.getMatch();
        if (!match) return null;
        const discussionId = discussionService.getDiscussionIdFromShortId(match.discussionShortId);
        const discussion = getStore().getState().discussions.byId[discussionId as any];
        if (!discussion) return null;
        return DiscussionWordCloud(discussion);
      }
      default:
        return h(
          'div',
          style(['fullWidth', 'flex', 'alignCenter', 'justifyCenter', 'grey']),
          'No analytics for this view'
        );
    }
  }

  private getTitleText() {
    const context = getStore().getState().app.context;
    const contextTitle: ObjectMap<string | undefined> = {
      course: 'Course Analytics',
      class: 'Class Analytics',
      assignment: 'Assignment Analytics',
      poll: 'Poll Analytics',
      quiz: 'Quiz Analytics',
      discussion: 'Word Cloud',
    };
    return contextTitle[context] || 'Analytics';
  }
}
