import { applyMiddleware, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require('crypto-js');

import { cookies, sessionStorage } from 'core/storage';

import { rootReducer as reducer } from 'acadly/rootReducer';

import { key } from '../acadly/acadly-encrypt';
import { IRootState } from './IRootState';

export default function configureStore(): Store<IRootState> {
  const store = loadStore();
  return store;
}

let composeEnhancers = compose;

if (!['production', 'test'].includes(<string>process.env.ENV)) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

function loadStore(): Store<IRootState> {
  try {
    const localSession = (window as Window).localStorage.getItem('session') as any;
    if (!localSession) {
      sessionStorage.clear();
      cookies.remove('session');
    } else {
      const dec = CryptoJS.AES.decrypt(localSession, key);
      const session = JSON.parse(dec.toString(CryptoJS.enc.Utf8));
      const state = reducer(undefined as any, { type: 'INIT' });
      state.getIn.session = session;
      state.getIn.server = state.getIn.session!.server;
      return createStore<IRootState>(reducer, state, composeEnhancers(applyMiddleware(thunk)));
    }
  } catch (e) {
    if (process.env.ENV !== 'test') {
      console.error(e);
    }
  }
  const store = createStore<IRootState>(reducer, composeEnhancers(applyMiddleware(thunk)));
  return store;
}
