import { Actions } from 'acadly/app/actions';
import classService from 'acadly/class/service';
import courseService from 'acadly/course/service';
import { dispatch, getStore } from 'acadly/store';
export type IPermissionRole = ICourseRole | 'class-incharge' | 'class-ta';
export function visibleTo<A>(roles: IPermissionRole[], view: A): A | null {
  if (validate(roles)) {
    return view;
  } else {
    return null;
  }
}

export const requiresRole =
  (...roles: IPermissionRole[]) =>
  (_: any, __: string, descriptor: PropertyDescriptor) => {
    const method = descriptor.value;
    descriptor.value = function (...args: any[]) {
      if (validate(roles)) {
        return method.apply(this, args);
      } else {
        dispatch(
          Actions.showError({
            message: "You don't have the required permissions to " + 'perform this action.',
          })
        );
        throw new Error("User doesn't have permissions " + roles.join(', '));
      }
    };
  };

export const hideWhenArchived = (_: any, __: string, descriptor: PropertyDescriptor) => {
  const method = descriptor.value;
  descriptor.value = function (...args: any[]) {
    const course = courseService.getCurrentCourse();
    if (course && !course.isArchived) {
      return method.apply(this, args);
    } else {
      return null;
    }
  };
};

function validate(roles: IPermissionRole[]): boolean {
  const course = courseService.getCurrentCourse();
  const session = getStore().getState().getIn.session;

  if (!session || !course) return false;
  const currentUserId = session.userId;
  const member = course.team.filter((m) => m.userId === currentUserId)[0];
  let currentCourseRole = 'student';
  if (member) currentCourseRole = member.role;
  const classRole = classService.getRole();
  for (const role of roles) {
    if (role === 'class-incharge' && classRole === 'in-charge') {
      return true;
    } else if (role === 'class-ta' && classRole === 'assistant') {
      return true;
    } else if (role === currentCourseRole) {
      return true;
    }
  }
  return false;
}
