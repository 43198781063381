import { CSS, h, IComponent } from 'core';

import Avatar from 'acadly/common/Avatar';
import DateView from 'acadly/common/DateView';
import { getStore } from 'acadly/store';

import * as datetime from '../datetime';
import { colors } from '../styles';

export default (announcement: IAnnouncement, props?: IAnnouncementProps) =>
  h(AnnouncementWidget, { announcement, ...props });
export interface IAnnouncementProps {
  onclick?: (e: Event) => any;
  style?: CSS;
}
export class AnnouncementWidget extends IComponent<
  IAnnouncementProps & {
    announcement: IAnnouncement;
  },
  never
> {
  private isAccessible: boolean;
  public render() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const { announcement } = this.getProps();
    const styles = this.getProps().style;
    const widgetColor = this.getWidgetColor(announcement);
    return h(
      'div.announcement-widget',
      {
        style: {
          color: widgetColor,
          ...styles,
        },
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `Announcement ${announcement.details.title}`,
        key: announcement._id,
        onclick: this.getProps().onclick,
      },
      [
        h('div.timeline-widget__icon', [
          DateView(datetime.fromUnix(announcement.details.dueDateTime), widgetColor),
        ]),

        h('div.announcement-widget__content', [
          h('div.announcement-widget__heading', 'Announcement'),
          h('div.announcement-widget__body', [
            Avatar(
              announcement.details.createdBy.avatar || 'default',
              announcement.details.createdBy.name,
              {
                className: 'announcement-widget__avatar',
              }
            ),
            h('span.announcement-widget__title', announcement.details.title),
          ]),
        ]),
      ]
    );
  }

  private getWidgetColor(announcement: IAnnouncement) {
    return announcement.userData.timesFetched ? '#bababa' : colors.blue;
  }
}
