import { h, IComponent } from 'core';
import { Route, Switch } from 'core/router';

import Announcement from 'acadly/announcement/Announcement';
import NewAnnouncement from 'acadly/announcement/NewAnnouncement';
import { Actions as appActions } from 'acadly/app/actions';
import appService from 'acadly/app/service';
import * as AssignmentActions from 'acadly/assignment/actions';
import Assignment from 'acadly/assignment/Assignment';
import { assignmentService } from 'acadly/assignment/service';
import SuggestedAssignmentDialog from 'acadly/assignment/SuggestedAssignmentDialog';
import { Actions as ClassActions } from 'acadly/class/actions';
import Class from 'acadly/class/Class';
import classFunctions from 'acadly/class/functions';
import NewClassDialog from 'acadly/class/NewClassDialog';
import classService from 'acadly/class/service';
import * as commentsFunctions from 'acadly/comments/functions';
import Alert from 'acadly/common/Alert';
import ContentView from 'acadly/common/ContentView';
import DivButton from 'acadly/common/DivButton';
import FlatButton from 'acadly/common/FlatButton';
import FloatingActionButton from 'acadly/common/FloatingActionButton';
import FloatingMenu from 'acadly/common/FloatingMenu';
import Icon from 'acadly/common/Icon';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import VirtualList, { IVirtualListScrollController } from 'acadly/common/VirtualList';
import courseService from 'acadly/course/service';
import * as datetime from 'acadly/datetime';
import icons from 'acadly/icons';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import * as utils from 'acadly/utils';

import NotificationBar from '../common/NotificationBar';
import { Actions } from './actions';
import AddStudentsDialog from './AddStudents';
import FilterActivities from './FilterActivities';
import { ICourseTimeline } from './ICourseState';
import TimelineWidget from './TimelineWidget';

export default (props: ITimelineProps) => h(Timeline, props);
interface ITimelineProps {
  timeline: ICourseTimeline;
  course: ICourse;
  role: ICourseRole;
  userId: string;
}
export interface ITimelineState {
  isAddStudentDialogVisible: boolean;
  isAddClassDialogVisible: boolean;
  isNewAnnouncementDialogVisible: boolean;

  isGoLiveDialogVisible: boolean;
  isFloatingMenuOpen: boolean;
  oldClassesLoaded: boolean;
  isDeleteTutorialDialogVisibleFor: string | null;
  isAboutTutorialsDialogVisibleFor: IClass | null;
  notificationElement?: HTMLElement;
  isFilterActivitiesDialogVisible: boolean;
  isArchiveConfirmationDialogVisible: boolean;

  isDeleteLectureDialogVisibleFor?: string; // classId

  isSuggestedAssignmentDialogOpen: boolean;
  suggestedAssignments: ISuggestedAssignment[];
}

export class Timeline extends IComponent<ITimelineProps, ITimelineState> {
  private isAccessible: boolean;

  public componentWillMount() {
    const course = this.getProps().course;
    const initialState: ITimelineState = {
      isAddStudentDialogVisible: Boolean(
        course.status.courseLive && !course.status.studentsEnrolled
      ),
      isAddClassDialogVisible: false,
      isFloatingMenuOpen: false,
      oldClassesLoaded: false,
      isGoLiveDialogVisible: false,
      isNewAnnouncementDialogVisible: false,
      isDeleteTutorialDialogVisibleFor: null,
      isAboutTutorialsDialogVisibleFor: null,
      isFilterActivitiesDialogVisible: false,
      isArchiveConfirmationDialogVisible: false,
      isDeleteLectureDialogVisibleFor: undefined,

      isSuggestedAssignmentDialogOpen: false,
      suggestedAssignments: [],
    };
    this.setState(initialState).then(() => {
      dispatch(appActions.startTip(true));
    });
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
  }
  public componentDidMount() {
    const tabs = document.getElementById('sliding-tabs');
    if (tabs) {
      tabs.focus();
    }
  }
  public render() {
    const course = this.getProps().course;
    const role = this.getProps().role;
    return h('div.timeline__container', [
      role === 'admin'
        ? AddStudentsDialog({
            open: this.getState().isAddStudentDialogVisible,
            course: this.getProps().course,
            close: () => {
              this.setState({
                isAddStudentDialogVisible: false,
              });
            },
          })
        : null,
      this.courseSubscreen(),
      h(
        utils.getHTMLTagSelector('div', [
          'timeline',
          Routes.courseTimeline.isActive(true) ? '' : 'hidden',
        ]),
        [
          this.getNotificationBar(),
          ['admin', 'instructor'].indexOf(role) > -1 && course.status.schedulePublished
            ? this.addButton()
            : null,
          this.timelineList(),
          this.deleteTutorialWarning(),
          this.aboutTutorialsDialog(),
          this.goLiveWarning(),
          this.deleteLectureDialog(),
          NewClassDialog({
            type: 'new',
            open: this.getState().isAddClassDialogVisible,
            course,
            isAccessible: this.isAccessible,
            userId: this.getProps().userId,
            close: () => {
              utils.resetTabIndices();
              if (this.lastFocusedElement) {
                (this.lastFocusedElement as HTMLElement).focus();
              } else {
                const btn = document.getElementById('add-button');
                if (btn) {
                  btn.focus();
                }
              }
              this.setState({
                isAddClassDialogVisible: false,
              });
            },
          }),
          this.filterActivitiesDialog(),
        ]
      ),
    ]);
  }

  private getAddButtonTipText() {
    const isMobile = getStore().getState().app.isMobile;
    const TapOrClick = isMobile ? 'Tap' : 'Click';
    return (
      `${TapOrClick} here to add extra classes to the timeline, and then select "Publish".
        \nAFTER selecting "Publish", this button can be used to add Assignments and Announcements` +
      ` to the timeline as well.\n
        BEFORE selecting "Publish", it is recommended that you add and delete classes as per your` +
      ` current schedule to avoid notifying students of changes later.`
    );
  }

  private deleteLectureDialog() {
    const { course, role } = this.getProps();
    if (course.status.courseLive || role !== 'admin') {
      return null;
    }
    const state = this.getState();
    return Alert(
      {
        open: !!state.isDeleteLectureDialogVisibleFor,
        className: 'timeline__delete-lecture',
        title: h('div', 'Delete Lecture'),
        titleClassName: 'timeline__delete-lecture-title',
        overlayClassName: 'timeline__delete-lecture-overlay',
        verticalActions: true,
        actions: [
          FlatButton("Don't delete", {
            type: 'secondary',
            onclick: () =>
              this.setState({
                isDeleteLectureDialogVisibleFor: undefined,
              }),
          }),
          FlatButton('Delete this lecture', {
            onclick: () => this.deleteLecture(),
          }),
        ],
      },
      ['Are you sure you want to delete this lecture?']
    );
  }

  private async deleteLecture() {
    const props = this.getProps();
    const state = this.getState();
    const lecture = props.timeline.items.find(
      (item) => item._id === state.isDeleteLectureDialogVisibleFor
    );
    if (!lecture || lecture.nodeType !== 'class') {
      return;
    }
    await dispatch(
      ClassActions.deleteClass(lecture._id, lecture.details.type, lecture.details.subType)
    );
    await this.setState({
      isDeleteLectureDialogVisibleFor: undefined,
    });
  }

  private courseSubscreen() {
    const course = this.getProps().course;
    const timeline = this.getProps().timeline;
    const role = this.getProps().role;
    if (Routes.courseTimeline.isActive(true) || !Routes.courseTimeline.isActive()) {
      return null;
    }
    return h('div.timeline__subscreen', [
      Switch(
        [
          Route(Routes.filterActivities, (match) =>
            FilterActivities({
              type: match.params.type as any,
              course,
              courseRole: role,
              timelineActivities: timeline.items.filter(
                (item) => item.nodeType === 'assignment' || item.nodeType === 'announcement'
              ) as any,
            })
          ),
          Route(Routes.class, (match) => {
            const cls = this.getProps().timeline.items.filter(
              (item) => item._id.slice(18) === match.params.classShortId
            )[0] as IClass;
            if (!cls) {
              Routes.courseTimeline.navigate({
                univSlug: appService.getUniversitySlug(),
                courseShortId: match.params.courseShortId,
              });
              dispatch(
                appActions.showError({
                  message: 'Class was not found.',
                })
              );
              return null;
            } else {
              return Class({
                course,
                cls: cls,
                courseRole: role,
                isIncharge:
                  classService.getRole(
                    classService.getClassIdFromShortId(match.params.classShortId) || undefined
                  ) === 'in-charge',
              });
            }
          }),
          Route(Routes.newAnnouncement, () => NewAnnouncement({ course })),
          Route(Routes.courseAnnouncement, (match) => {
            const allAnnouncements = timeline.items.filter(
              (item) => item.nodeType === 'announcement'
            );
            const announcement = allAnnouncements.find(
              (item) =>
                item._id === this.getAnnouncementIdFromShortId(match.params.announcementShortId)
            );
            if (!announcement) {
              Routes.courseTimeline.navigate({
                univSlug: appService.getUniversitySlug(),
                courseShortId: courseService.getShortIdFromCourseId(course._id),
              });
              dispatch(
                appActions.showError({
                  message:
                    'Announcement was not found. It has either been moved, ' +
                    'deleted or not published yet.',
                })
              );
              return null;
            } else {
              return Announcement(announcement as IAnnouncement);
            }
          }),
          Route(Routes.courseAssignment, (match) => {
            const assignment = timeline.items.filter(
              (item) =>
                item.nodeType === 'assignment' &&
                item._id ===
                  assignmentService.getAssignmentIdFromShortId(match.params.assignmentShortId)
            )[0] as IAssignment;
            if (!assignment) {
              Routes.courseTimeline.navigate({
                univSlug: appService.getUniversitySlug(),
                courseShortId: courseService.getShortIdFromCourseId(course._id),
              });
              dispatch(
                appActions.showError({
                  message:
                    'Assignment was not found. It has either been moved, ' +
                    'deleted or not published yet.',
                })
              );
              return null;
            } else {
              return Assignment({
                assignment,
                course,
              });
            }
          }),
        ],
        {
          defaultCallback: () => {
            Routes.courseTimeline.navigate({
              courseShortId: courseService.getShortIdFromCourseId(course._id),
              univSlug: appService.getUniversitySlug(),
            });
          },
        }
      ),
    ]);
  }

  private aboutTutorialsDialog() {
    const { isAboutTutorialsDialogVisibleFor } = this.getState();
    const courseRole = courseService.getRole();
    const cls = isAboutTutorialsDialogVisibleFor;
    const typeName = cls
      ? {
          lecture: '',
          lab: 'lab',
          officeHour: 'office hour',
          seminar: 'seminar',
          tutorial: 'tutorial',
        }[cls.details.type]
      : '';
    return Alert(
      {
        open: isAboutTutorialsDialogVisibleFor !== null,
        title: `About ${utils.capitalize(typeName)}s`,
        className: 'timeline__tutorial-details',
        overlayClassName: 'timeline__tutorial-details-overlay',
        bodyClassName: 'timeline__tutorial-details-body',
        actionsClassName: 'timeline__tutorial-details-actions',
        verticalActions: courseRole === 'admin',
        actions:
          courseRole === 'admin'
            ? [
                FlatButton(`Cancel this ${typeName}`.toUpperCase(), {
                  type: 'secondary',
                  classNames: ['timeline__tutorial-details-action'],
                  onclick: () => this.cancelTutorial(),
                }),
                FlatButton('GO BACK', {
                  classNames: ['timeline__tutorial-details-action'],
                  onclick: () => {
                    utils.resetTabIndices();
                    (this.lastFocusedElement as HTMLElement).focus();
                    this.setState({
                      isAboutTutorialsDialogVisibleFor: null,
                    });
                  },
                }),
              ]
            : [
                FlatButton('OK', {
                  onclick: () => {
                    utils.resetTabIndices();
                    (this.lastFocusedElement as HTMLElement).focus();
                    this.setState({
                      isAboutTutorialsDialogVisibleFor: null,
                    });
                  },
                }),
              ],
      },
      [
        h(
          'p.timeline__tutorial-details-para',
          `${utils.capitalize(typeName)}s are created to provide completeness
                to the timeline and ${typeName}s are not interactive yet. That is,
                you cannot attach an activity to them like you can with lectures.`
        ),
        ...(courseRole === 'admin'
          ? [
              h(
                'p.timeline__tutorial-details-para',
                `In case you want to plan an activity for ${
                  cls && cls.details.type === 'officeHour' ? 'an' : 'a'
                } ${typeName}, it is best to delete the ${typeName}
                    and create an extra lecture instead.`
              ),
              h(
                'p.timeline__tutorial-details-para',
                `In case you want to cancel this ${typeName}, please select
                    "Cancel this ${typeName}" option.`
              ),
              h(
                'p.timeline__tutorial-details-para',
                `After cancelling, students will be notified but the
                    ${typeName} will still be visible on the timeline as
                    a cancelled event to help students avoid confusion.`
              ),
            ]
          : [
              h(
                'p.timeline__tutorial-details-para',
                `Only the course admin can cancel or add ${typeName}s`
              ),
            ]),
      ]
    );
  }

  private async cancelTutorial() {
    const cls = this.getState().isAboutTutorialsDialogVisibleFor;
    if (!cls) return;

    await dispatch(
      ClassActions.classCancel({
        classId: cls._id,
        mark: 'canceled',
        hasMakeUp: 0,
        venue: cls.details.venue,
        announce: 0,
        maintain: 0,
        remove: 0,
      })
    );

    await this.setState({
      isAboutTutorialsDialogVisibleFor: null,
    });
  }

  private goLiveWarning() {
    const accessibiltyAttr = () => ({
      tabIndex: this.isAccessible ? 0 : undefined,
    });

    const bullet = (text: string, color: string) => {
      return h('div.timeline__bullet-container', accessibiltyAttr(), [
        h(utils.getHTMLTagSelector('div', ['timeline__bullet', `bg-${color}`])),
        h('div.timeline__bullet-text', text),
      ]);
    };

    return Alert(
      {
        open: this.getState().isGoLiveDialogVisible,
        className: 'timeline__go-live',
        overlayClassName: 'timeline__go-live-overlay',
        title: 'Publishing Course',
        titleClassName: 'timeline__go-live-title',
        verticalActions: true,
        actions: [
          FlatButton('No', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: async () => {
              utils.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              await this.setState({
                isGoLiveDialogVisible: false,
              });
            },
          }),
          FlatButton('Yes, I am sure', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.goLive(),
          }),
        ],
      },
      [
        h('p.fw-bold', accessibiltyAttr(), 'After this course is published'),

        h('p.fc-pink', accessibiltyAttr(), "You WON'T be able to"),
        bullet('Delete classes without notifying the students', 'pink'),

        h('p.fc-green', accessibiltyAttr(), 'You WILL be able to'),
        bullet('Enrol students', 'green'),
        bullet('Cancel and reschedule your classes.', 'green'),
        bullet('Add more classes.', 'green'),

        h(
          'p.fw-bold',
          accessibiltyAttr(),
          `
                Please ensure that the timeline accurately reflects
                your schedule, even though you can change things later
                if necessary.
            `
        ),
        h('p.fw-bold', accessibiltyAttr(), 'Are you sure you want to publish?'),
      ]
    );
  }

  private async goLive() {
    utils.resetTabIndices();
    const course = this.getProps().course;
    await dispatch(Actions.goLive(course._id));
    await this.setState({
      isGoLiveDialogVisible: false,
      isAddStudentDialogVisible: true,
    });
  }

  private deleteTutorialWarning() {
    const cls = () =>
      (this.getProps().timeline.items.filter(
        (item) => item._id === this.getState().isDeleteTutorialDialogVisibleFor
      )[0] || { details: { type: 'tutorial' } }) as IClass;
    const type = () =>
      ((
        {
          tutorial: 'Tutorial',
          officehour: 'Office hour',
          seminar: 'Seminar',
          lab: 'Lab',
        } as any
      )[cls().details.type.toLowerCase()]);
    return Alert(
      {
        open: !!this.getState().isDeleteTutorialDialogVisibleFor,
        title: `Delete ${type()}?`,
        className: 'timeline__delete-tutorial',
        overlayClassName: 'timeline__delete-tutorial-overlay',
        actions: [
          FlatButton('No', {
            type: 'secondary',
            onclick: () =>
              this.setState({
                isDeleteTutorialDialogVisibleFor: null,
              }),
          }),
          FlatButton('Yes', {
            onclick: () => this.deleteTutorial(cls()),
          }),
        ],
      },
      [
        h(
          'p',
          `${type()}s are created to provide completeness to` +
            ` the timeline and ${type().toLowerCase()}s are not interactive yet. That ` +
            'is, you cannot attach an activity to them like you can with lectures.'
        ),
        h(
          'p',
          `In case you want to plan an activity for` +
            ` ${type().toLowerCase() === 'officehour' ? 'an' : 'a'} ${type().toLowerCase()}, ` +
            `it is best to delete the ${type().toLowerCase()} and create it as a lecture instead.`
        ),
        h('p', 'Once the course goes live, you can only cancel' + ` ${type().toLowerCase()}s.`),
        h('p', `Are you sure you want to delete this ${type().toLowerCase()}?`),
      ]
    );
  }

  private async deleteTutorial(cls: IClass) {
    await dispatch(ClassActions.deleteClass(cls._id, cls.details.type, cls.details.subType));
    await this.setState({
      isDeleteTutorialDialogVisibleFor: null,
    });
  }

  private oldActivityNewComments(item: ICourseTimelineItem) {
    switch (item.nodeType) {
      case 'class': {
        const classNewComments = commentsFunctions.getUnseenComments(item);
        const { total, seen } = classFunctions.getActivityComments(item);
        return classNewComments + total - seen;
      }
      case 'assignment': {
        return commentsFunctions.getUnseenComments(item);
      }
      default:
        return 0;
    }
  }

  private olderClassesBanner() {
    if (!this.isLoadOldClassesBannerVisible()) return null;
    const timeline = this.getProps().timeline;

    const role = this.getProps().role;
    const today = datetime.toUnix(datetime.startOfDay(datetime.now()));
    const oldItems = timeline.items.filter((item) => item.details.dueDateTime < today);
    const unseenOldComments = oldItems
      .map((item) => this.oldActivityNewComments(item))
      .reduce((a, b) => a + b, 0);

    const oldClasses = oldItems.filter((item) => item.nodeType === 'class') as IClass[];
    const oldUnseenQueries =
      role === 'student'
        ? 0
        : Math.max(
            oldClasses
              .map((cls) =>
                [
                  { ...cls.activities.preClass.queries, ...cls.userData.preClass.queries },
                  { ...cls.activities.inClass.queries, ...cls.userData.inClass.queries },
                  { ...cls.activities.reviewQueries, ...cls.userData.reviewQueries },
                ]
                  .map((counts) => Math.max(counts.numTotal - counts.numSeen, 0))
                  .reduce((a, b) => a + b, 0)
              )
              .reduce((a, b) => a + b, 0),
            0
          );
    const dueActivities =
      role !== 'student'
        ? oldItems
            .map((item) => {
              if (item.nodeType === 'assignment') {
                return item.details.published ? 0 : 1;
              } else if (item.nodeType === 'class' && item.details.type === 'lecture') {
                return utils
                  .objectKeys(item.activities)
                  .map((toBeDone) => {
                    if (toBeDone === 'preClass' || toBeDone === 'inClass') {
                      return utils
                        .objectKeys(item.activities[toBeDone])
                        .map((activityType) => {
                          if (activityType === 'queries') {
                            return 0;
                          } else {
                            return Math.max(
                              item.activities[toBeDone][activityType].numTotal -
                                item.activities[toBeDone][activityType].numPublished
                            );
                          }
                        })
                        .reduce((x, y) => x + y, 0);
                    } else {
                      return 0;
                    }
                  })
                  .reduce((x, y) => x + y, 0);
              } else {
                return 0;
              }
            })
            .reduce((x, y) => x + y, 0)
        : oldItems
            .map((item) => {
              if (item.nodeType === 'assignment') {
                return item.userData.studentUserData &&
                  (item.userData.studentUserData.status === 'submitted' ||
                    item.userData.studentUserData.status === 'late')
                  ? 0
                  : 1;
              } else if (item.nodeType === 'class' && item.details.type === 'lecture') {
                const duePreClassQuizzes = Math.max(
                  0,
                  item.activities.preClass.quizzes.numPublished -
                    item.userData.preClass.quizzes.numCompleted
                );
                const dueInClassQuizzes = Math.max(
                  0,
                  item.activities.inClass.quizzes.numPublished -
                    item.userData.inClass.quizzes.numCompleted
                );
                const duePreClassPolls = Math.max(
                  0,
                  item.activities.preClass.polls.numPublished -
                    item.userData.preClass.polls.numCompleted
                );
                const dueInClassPolls = Math.max(
                  0,
                  item.activities.inClass.polls.numPublished -
                    item.userData.inClass.polls.numCompleted
                );
                return duePreClassQuizzes + dueInClassQuizzes + duePreClassPolls + dueInClassPolls;
              } else {
                return 0;
              }
            })
            .reduce((x, y) => x + y, 0);

    const hasUpdates = unseenOldComments || oldUnseenQueries || dueActivities;
    return DivButton({
      role: 'button',
      id: 'load-old-classes-banner',
      key: 'load-old-classes-button',
      classNames: ['timeline__action'],
      ariaLabel: 'Show Older Classes Button',
      tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
      onclick: () => {
        this.loadEarlierDates();
      },
      children: [
        h('div.timeline__action-title', [Icon(icons.circleArrow), h('span', 'Show older classes')]),
        h('div.timeline__activity-counters', [
          !hasUpdates ? 'All caught up!' : null,
          oldUnseenQueries
            ? h('span.timeline__activity-counter.blue', [
                Icon(icons.query),
                h('div', `${oldUnseenQueries.toString()} queries`),
              ])
            : null,
          dueActivities
            ? h('span.timeline__activity-counter.red', [
                Icon(icons.cube),
                h('span', `${dueActivities} Due`),
              ])
            : null,
          unseenOldComments
            ? h('span.timeline__activity-counter.orange', [
                Icon(icons.comments),
                h('span', unseenOldComments.toString()),
              ])
            : null,
        ]),
      ],
    });
  }

  private async loadEarlierDates() {
    const { timeline } = this.getProps();
    const index =
      timeline.items.findIndex((item) => item.details.dueDateTime > datetime.unix()) - 1;
    await this.setState({ oldClassesLoaded: true });
    if (this.scrollController) {
      if (index > 0) {
        this.scrollController.scrollToIndex(index);
      }
    }
    setTimeout(() => {
      const hideOlderClassesBtn = document.getElementById('hide-older-classes');
      if (hideOlderClassesBtn !== null) {
        hideOlderClassesBtn.focus();
      }
    }, 0);
  }

  private async hideOlderClasses() {
    await this.setState({
      oldClassesLoaded: false,
    });
    if (this.scrollController) {
      this.scrollController.scrollToIndex(0);
    }
    setTimeout(() => {
      const loadOlderClassesBtn = document.getElementById('load-old-classes-banner');
      if (loadOlderClassesBtn !== null) {
        loadOlderClassesBtn.focus();
      }
    }, 0);
  }

  private isLoadOldClassesBannerVisible() {
    const { classMediaPlayer } = getStore().getState().pipContainers;
    const course = this.getProps().course;
    const timeline = this.getProps().timeline;
    const today = datetime.toUnix(datetime.startOfDay(datetime.now()));

    if (classMediaPlayer.show && classMediaPlayer.courseId === course._id) {
      return false;
    }

    if (!timeline.items.length) {
      return false;
    }

    if (!course.status.courseLive) {
      return false;
    }

    const firstItem = timeline.items[0];
    const lastItem = timeline.items[timeline.items.length - 1];
    return (
      lastItem &&
      lastItem.details.dueDateTime >= today &&
      timeline.courseDates.endDate > today &&
      firstItem.details.dueDateTime < today &&
      !this.getState().oldClassesLoaded
    );
  }

  private addButton() {
    const role = this.getProps().role;
    const course = this.getProps().course;
    const timeline = this.getProps().timeline;
    return Routes.courseTimeline.isActive(true) &&
      timeline.courseDates.endDate > datetime.unix() &&
      !course.isArchived
      ? h('div', [
          SuggestedAssignmentDialog({
            course,
            isOpen: this.getState().isSuggestedAssignmentDialogOpen,
            assignments: this.getState().suggestedAssignments,
            onClose: () => {
              this.setState({
                isSuggestedAssignmentDialogOpen: false,
                suggestedAssignments: [],
              });
            },
          }),
          FloatingActionButton(
            {
              id: 'add-button',
              position: 'bottom-right',
              ariaLabel: 'Add Button',
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => {
                utils.unsetTabIndices();
                this.setState({
                  isFloatingMenuOpen: !this.getState().isFloatingMenuOpen,
                });
              },
            },
            [
              Icon(icons.plus, {
                className: `timeline__fab-icon ${this.getState().isFloatingMenuOpen ? 'open' : ''}`,
              }),
            ]
          ),
          FloatingMenu(
            {
              isOpen: this.getState().isFloatingMenuOpen,
              title: 'Add a new',
              classNames: ['timeline__add-new'],
              toggleHandler: () => {
                utils.resetTabIndices();
                const btn = document.getElementById('add-button');
                if (btn) {
                  btn.focus();
                }
                this.setState({ isFloatingMenuOpen: !this.getState().isFloatingMenuOpen });
              },
            },
            [
              ...(role === 'admin'
                ? [
                    {
                      label: 'Class',
                      tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                      onClick: () => {
                        setTimeout(() => {
                          utils.unsetTabIndices(document.getElementById('dialog-box'));
                        }, 0);
                        this.setState({
                          isAddClassDialogVisible: true,
                        });
                      },
                    },
                  ]
                : []),
              ...(this.getProps().course.status.courseLive
                ? [
                    {
                      label: 'Assignment',
                      tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                      onClick: () => this.handleCreateNewAssignement(),
                    },
                    {
                      label: 'Announcement',
                      tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                      onClick: async () => {
                        Routes.newAnnouncement.navigate({
                          courseShortId: courseService.getShortIdFromCourseId(course._id),
                          univSlug: appService.getUniversitySlug(),
                        });
                      },
                    },
                  ]
                : []),
            ]
          ),
          TipOverlayWrapper({
            targetElement: 'add-button',
            tip: {
              tipPosition: 'left',
              tipText: this.getAddButtonTipText(),
            },
            tipKey: 'courseMainFloatingButton',
            isNextAvailable: false,
            isSpecial: true,
          }),
        ])
      : null;
  }

  private async handleCreateNewAssignement() {
    const course = this.getProps().course;
    const response = await dispatch(AssignmentActions.createAssignment(course._id));

    if (response.hasSuggestedAssignments === 1) {
      await this.setState({
        isSuggestedAssignmentDialogOpen: true,
        suggestedAssignments: response.suggestedAssignments,
      });
    } else {
      const assignmentShortId = assignmentService.getShortIdFromAssignmentId(
        response.newAssignment._id
      );
      Routes.courseAssignment.navigate({
        courseShortId: courseService.getShortIdFromCourseId(course._id),
        assignmentShortId,
        univSlug: appService.getUniversitySlug(),
      });
    }
  }

  private getNotification() {
    const course = this.getProps().course;
    const admin = course.team.filter((member) => member.role === 'admin')[0];
    if (!course.status.courseLive) {
      if (courseService.getRole() === 'admin') {
        return {
          message:
            'Please curate the timeline by removing ' + 'or adding classes before going live',
          action: async () => await this.showGoLiveDialog(),
          actionText: 'Publish',
        };
      } else {
        return {
          message: `Course admin ${admin.name} is yet to make this course live`,
          action: undefined,
          actionText: undefined,
        };
      }
    } else {
      return null;
    }
  }

  private async showGoLiveDialog() {
    this.lastFocusedElement = document.activeElement;
    utils.unsetTabIndices();
    await this.setState({
      isGoLiveDialogVisible: true,
    });
  }

  private getNotificationBar(
    {
      hidden,
    }: {
      hidden: boolean;
    } = { hidden: false }
  ) {
    const notification = this.getNotification();
    if (!notification) {
      return null;
    } else {
      return h(
        utils.getHTMLTagSelector('div', ['timeline__notification-wrapper', hidden ? 'hidden' : '']),
        [
          NotificationBar(
            {
              style: {
                transform: `translateX(calc(-50% - ${getStore().getState().app.scrollbarWidth}px))`,
              },
              classNames: ['timeline__notification', hidden ? 'hidden' : ''],
              tabIndex: this.isAccessible ? 0 : undefined,
              action: notification.action,
              actionText: notification.actionText,
              getElem: (elem) => {
                if (this.getState().notificationElement) return;
                this.setState({
                  notificationElement: elem,
                });
              },
            },
            [notification.message]
          ),
        ]
      );
    }
  }

  private isTimelineVisible() {
    const timeline = this.getProps().timeline;
    const course = this.getProps().course;
    return course.status.schedulePublished && timeline.items;
  }

  private scrollController?: IVirtualListScrollController;

  private getEmptyTimelineMessage() {
    const { role, course } = this.getProps();

    if (!course.status.courseLive) {
      return h('div', [
        'You can add classes by clicking on the ',
        Icon(icons.plus, { className: 'timeline__add-icon' }),
        ' icon. It is advisable to add classes before the course is published',
      ]);
    }

    if (role === 'admin') {
      return h('div', [
        'You can add classes by clicking on the ',
        Icon(icons.plus, { className: 'timeline__add-icon' }),
        ' icon. Activities can only be added to the scheduled classes',
      ]);
    }

    if (role === 'instructor') {
      return h('div', [
        'The Course Admin has not added any classes to the course yet. ',
        'Activities can be added only after the classes are added to the course',
      ]);
    }

    if (role === 'ta') {
      return h('div', [
        'The Course Admin has not added any classes to the course yet. Activities can ',
        'be added by the Course Instructors after classes have been added to the course',
      ]);
    }

    return h('div', [
      'The course doesn’t have any scheduled classes yet. You will be notified once ',
      'classes are added to the course',
    ]);
  }

  private timelineList() {
    const timeline = this.getProps().timeline;
    const course = this.getProps().course;
    const now = datetime.toUnix(datetime.startOfDay(datetime.now()));
    if (this.isTimelineVisible()) {
      if (timeline.items.length === 0) {
        return ContentView(
          h('p.timeline__empty-message', [
            this.getNotificationBar({ hidden: true }),
            this.getEmptyTimelineMessage(),
          ])
        );
      }
      const items = timeline.items.filter(
        this.isLoadOldClassesBannerVisible()
          ? (item) => {
              return item.details.dueDateTime > now;
            }
          : () => true
      );

      const classes = items.filter(
        (item) => item.nodeType === 'class' && !!item.details.classInchargeSet
      ) as (ITimelineItemBase & IClass)[];

      const inCharges: ObjectMap<string[]> = {};
      const assistants: ObjectMap<string[]> = {};

      classes.forEach((c) => {
        if (!inCharges[c.info.team.inCharge.userId]) {
          inCharges[c.info.team.inCharge.userId] = [c._id];
        } else {
          inCharges[c.info.team.inCharge.userId].push(c._id);
        }

        const assistant = c.info.team.assistants[0];
        if (!assistant) return;

        if (!assistants[assistant.userId]) {
          assistants[assistant.userId] = [c._id];
        } else {
          assistants[assistant.userId].push(c._id);
        }
      });

      const showInstructorTag = Object.keys(inCharges).length > 1;
      const showAssistantTag =
        Object.keys(assistants).length > 1 ||
        (Object.keys(assistants)[0] &&
          assistants[Object.keys(assistants)[0]].length !== classes.length);

      const firstItemToday = items.findIndex((item) => item.details.dueDateTime > now);
      const courseRole = this.getProps().role;
      const isItemDeletable = (item: ICourseTimelineItem) =>
        item.nodeType === 'class' &&
        item.details.type === 'lecture' &&
        courseRole === 'admin' &&
        !course.status.courseLive;
      return h('div.timeline__list', [
        VirtualList({
          itemHeight: 100,
          items: items,
          key: `timeline-items`,
          classNames: ['timeline__virtual-list'],

          getScrollController: (controller) => {
            this.scrollController = controller;
          },

          // Disable updates when timeline is not visible
          // done for performance reasons
          forceHide: !Routes.courseTimeline.isActive(true),
          showBeforeIndex:
            firstItemToday >= 0
              ? {
                  index: firstItemToday,
                  view: h('div.separator.timeline__now', [
                    h('span.separator__line.small'),
                    h('span.separator__text', 'NOW'),
                    h('span.separator__line.fluid'),
                    this.getState().oldClassesLoaded
                      ? h(
                          utils.getHTMLTagSelector(
                            'span',
                            ['separator__text', 'timeline__hide-classes'],
                            'hide-older-classes'
                          ),
                          {
                            tabIndex:
                              getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                            onclick: () => this.hideOlderClasses(),
                          },
                          'Hide Older classes'
                        )
                      : null,
                    this.getState().oldClassesLoaded ? h('span.separator__line.mini') : null,
                  ]),
                }
              : undefined,
          render: (item) => {
            let tag: IClassTeamRole = 'none';

            if (item.nodeType === 'class' && item.details.classInchargeSet) {
              const classRole = classService.getRole(item._id);
              if (
                (showInstructorTag && classRole === 'in-charge') ||
                (showAssistantTag && classRole === 'assistant')
              ) {
                tag = classRole;
              }
            }

            return TimelineWidget({
              tag,
              item,
              courseTeam: course.team,
              courseRole: courseRole,
              key: `timeline-widget-${item._id}`,
              isCourseArchived: !!course.isArchived,
              deleteAction: isItemDeletable(item)
                ? async () => {
                    await this.setState({
                      isDeleteLectureDialogVisibleFor: item._id,
                    });
                  }
                : item.nodeType === 'class' && !course.status.courseLive && courseRole === 'admin'
                ? () =>
                    this.setState({
                      isDeleteTutorialDialogVisibleFor: item._id,
                    })
                : undefined,
              onclick: () => {
                const courseRole = courseService.getRole();
                if (item.nodeType === 'class' && item.details.type === 'lecture') {
                  const { isOnlineMeeting, scheStartTime, scheEndTime } = item.details;
                  const now = datetime.unix();

                  if (isOnlineMeeting && now > scheStartTime - 300 && now < scheEndTime) {
                    dispatch(appActions.showNotificationPermissionAlert());
                  }

                  Routes.classActivities.navigate({
                    classShortId: classService.getShortIdFromClassId(item._id),
                    courseShortId: courseService.getShortIdFromCourseId(item.identifiers.courseId),
                    univSlug: appService.getUniversitySlug(),
                  });
                } else if (
                  item.nodeType === 'class' &&
                  !course.status.courseLive &&
                  courseRole === 'admin'
                ) {
                  this.setState({
                    isDeleteTutorialDialogVisibleFor: item._id,
                  });
                } else if (
                  item.nodeType === 'class' &&
                  !course.isArchived &&
                  item.details.status === 'open'
                ) {
                  this.lastFocusedElement = document.activeElement;
                  utils.unsetTabIndices();
                  this.setState({
                    isAboutTutorialsDialogVisibleFor: item,
                  });
                } else if (item.nodeType === 'announcement') {
                  Routes.courseAnnouncement.navigate({
                    courseShortId: courseService.getShortIdFromCourseId(item.identifiers.courseId),
                    announcementShortId: this.getShortIdFromAnnouncementId(item._id),
                    univSlug: appService.getUniversitySlug(),
                  });
                } else if (item.nodeType === 'assignment') {
                  Routes.courseAssignment.navigate({
                    courseShortId: courseService.getShortIdFromCourseId(item.identifiers.courseId),
                    assignmentShortId: assignmentService.getShortIdFromAssignmentId(item._id),
                    univSlug: appService.getUniversitySlug(),
                  });
                }
              },
              courseStatus: course.status,
            });
          },
          header: h('div.timeline__header', [
            // hidden notification bar for padding
            this.getNotificationBar({
              hidden: true,
            }),
            this.filterActivitiesButton(),
            this.olderClassesBanner(),
          ]),
          footer:
            timeline.courseDates.endDate < datetime.unix()
              ? h('div.timeline__course-complete', 'This course has run its course!')
              : /**
                 * bottom margin for timeline list
                 * firefox requires some text inside a div
                 * for it to take up space, so some transparent
                 * dummy text is inserted
                 */
                h('div.timeline__footer', 'timeline-bottom-margin'),
        }),
      ]);
    } else {
      return null;
    }
  }

  public getAnnouncementIdFromShortId(shortId: string) {
    const timeline = getStore().getState().courses.timeline;
    const announcement = timeline
      ? (timeline!.items.filter((item) => item._id.slice(18) === shortId)[0] as IAnnouncement)
      : null;
    if (announcement) {
      return announcement._id;
    } else {
      return null;
    }
  }
  public getShortIdFromAnnouncementId(announcementId: string) {
    return announcementId.slice(18);
  }

  private lastFocusedElement: Element | null = null;

  private filterActivitiesButton() {
    const course = this.getProps().course;
    const { timeline } = this.getProps();
    if (!timeline.items.length) return null;
    if (!course.status.courseLive) return null;

    return DivButton({
      role: 'button',
      key: 'filter-activities-button',
      classNames: ['timeline__action'],
      ariaLabel: 'Filter Activities Button',
      tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : -1,
      onclick: () => {
        this.lastFocusedElement = document.activeElement;
        utils.unsetTabIndices();
        this.setState({
          isFilterActivitiesDialogVisible: true,
        });
        this.setFocus();
      },
      children: [
        h('div.timeline__action-title', [Icon(icons.filter), h('span', 'Filter activities')]),
      ],
    });
  }

  private setFocus() {
    const elem = document.getElementById('announcements');
    if (elem) {
      elem.focus();
      // elem.tabIndex = -1;
    }
  }

  private filterActivitiesDialog() {
    if (!this.getState().isFilterActivitiesDialogVisible) return null;

    const menuItem = (type: string) => {
      return h(
        `div.#${type}.timeline__menu`,
        {
          tabindex: this.isAccessible ? 0 : undefined,
          onclick: async () => {
            utils.resetTabIndices();
            await this.setState({
              isFilterActivitiesDialogVisible: false,
            });
            Routes.filterActivities.navigate({
              courseShortId: courseService.getShortIdFromCourseId(this.getProps().course._id),
              type: type,
              univSlug: appService.getUniversitySlug(),
            });
          },
        },
        type
      );
    };

    return Alert(
      {
        open: this.getState().isFilterActivitiesDialogVisible,
        className: 'timeline__filter-activities',
        title: 'Activity type',
        titleClassName: 'timeline__filter-activities-title',
        onOverlayClick: () => {
          utils.resetTabIndices();
          (this.lastFocusedElement as HTMLElement).focus();
          this.setState({
            isFilterActivitiesDialogVisible: false,
          });
        },
        actions: [
          FlatButton('Close', {
            type: 'primary',
            onclick: () => {
              utils.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              this.setState({
                isFilterActivitiesDialogVisible: false,
              });
            },
          }),
        ],
      },
      [
        menuItem('announcements'),
        menuItem('assignments'),
        menuItem('quizzes'),
        menuItem('polls'),
        menuItem('resources'),
        menuItem('discussions'),
        menuItem('queries'),
      ]
    );
  }
}
