import { cloneDeep } from 'lodash';

import {
  addNewClassActivity,
  deleteClassActivity,
  pusherPublish as classPusherPublish,
  updateTotalClassActivitiesCount,
} from 'acadly/class/functions';
import { createReducer } from 'acadly/createReducer';
import * as datetime from 'acadly/datetime';
import { IRootState } from 'acadly/IRootState';
import { update } from 'acadly/utils';
import * as u from 'acadly/utils';

import * as actions from './actions';
import { ReorderQuestionPayload } from './api';
import { responseQuizToQuiz, updateQuiz } from './functions';

export const reducer = createReducer({
  '@quiz/CLEAR_DATA': clearData,
  '@quiz/CREATE_SUCCESS': createSuccess,
  '@quiz/FETCH_DATA_REQUEST': fetchDataRequest,
  '@quiz/FETCH_DATA_SUCCESS': fetchDataSuccess,
  '@quiz/question/ADD_SUCCESS': questionAddSuccess,
  '@quiz/question/EDIT_SUCCESS': questionEditSuccess,
  '@quiz/question/REMOVE_SUCCESS': questionDeleteSuccess,
  '@quiz/SUBMIT_SUCCESS': submitSuccess,
  '@quiz/SAVE_SUBMISSION_SUCCESS': saveSubmissionSuccess,
  '@quiz/EDIT_SUCCESS': editSuccess,
  '@quiz/DELETE_SUCCESS': deleteSuccess,
  '@quiz/PUBLISH_SUCCESS': publishSuccess,
  '@quiz/STOP_SUCCESS': stopSuccess,
  '@quiz/UPDATE_PUBLISHED_QUIZ_SUCCESS': updatePublishedQuizSuccess,
  '@quiz/all/FETCH_SUCCESS': fetchAllSuccess,
  '@quiz/pusher/PUBLISH': pusherPublish,
  '@quiz/analytics/FETCH_SUCCESS': analyticsFetchSuccess,
  '@quiz/pusher/SUBMITTED': pusherSubmitted,
  '@quiz/questions/RE_ORDER_SUCCESS': reorderQuestionsSuccess,
});

export function analyticsFetchSuccess(
  state: IRootState,
  payload: actions.IAnalyticsFetchSuccessPayload
): IRootState {
  return {
    ...state,
    quizzes: {
      ...state.quizzes,
      analytics: {
        submissions: payload.submittedBy,
      },
    },
  };
}

export function pusherPublish(
  state: IRootState,
  payload: actions.IPusherPublishPayload
): IRootState {
  const quiz: IQuiz = {
    _id: payload.quizId,
    nodeType: 'quiz',
    identifiers: {
      classId: payload.classId,
    },
    details: {
      ...payload.details,
      title: payload.details.title || '',
      attachments: payload.details.attachments || [],
    },
    activities: payload.activities,
    stats: {
      numSubmitted: 0,
    },
  };
  return classPusherPublish(state, 'quizzes', {
    senderId: payload.sender.userId,
    activity: quiz,
  });
}

export function deleteSuccess(
  state: IRootState,
  payload: actions.IDeleteSuccessPayload
): IRootState {
  return deleteClassActivity(state, 'quizzes', {
    activityId: payload.quizId,
    classId: payload.classId,
    toBeDone: payload.toBeDone,
  });
}

export function clearData(state: IRootState): IRootState {
  return u.update(state, {
    quizzes: {
      current: null,
    },
  });
}

export function fetchDataRequest(state: IRootState, payload: string) {
  return {
    ...state,
    quizzes: {
      ...state.quizzes,
      current: {
        ...state.quizzes.current,
        areQuestionsEdited: false,
        _id: payload,
        questions: [],
      },
    },
  };
}

export function fetchDataSuccess(
  state: IRootState,
  payload: actions.IFetchDataPayload
): IRootState {
  let updatedQuizSlice: IRootState['quizzes'] = {
    ...state.quizzes,
    publishDefaults: payload.publishDefaults || null,
    current: {
      _id: payload.quizId,
      questions: payload.questions,
      submission:
        payload.submission && Object.keys(payload.submission).length === 0
          ? undefined
          : payload.submission,
    },
  };
  const quiz = state.quizzes.byId[payload.quizId];
  if (!quiz) {
    return state;
  }
  const score = getQuizScore(payload.submission, getMaxScore(quiz));
  updatedQuizSlice = updateQuiz(updatedQuizSlice, payload.quizId, (q) => ({
    ...q,
    userData: q.userData
      ? {
          ...q.userData,
          numCommentsSeen: q.userData.numCommentsSeen || 0,
          timesStarted: q.userData.timesStarted + 1,
          lastAccessedOn: datetime.unix(),
          score: score,
          subscribed: q.userData.subscribed,
        }
      : {
          firstAccessedOn: payload.timestamp,
          lastAccessedOn: datetime.unix(),
          status: 'inProgress' as const,
          timesStarted: 1,
          numCommentsSeen: 0,
          score: score,
          subscribed: 0,
        },
  }));
  return {
    ...state,
    quizzes: updatedQuizSlice,
  };
}

export function getMaxScore(q: IQuiz) {
  return q.details.numQuestions * 4;
}

export function getQuizScore(
  submission: IQuizSubmission | undefined,
  maxScore: number
): IQuizUserData['score'] {
  if (submission) {
    const submissions = u.objectValues(submission);
    const hasScore = submissions.find((s) => !!s.score) !== undefined;
    if (hasScore) {
      const scoreFor = function scoreFor(toBeDone: IQuiz['details']['scoring']) {
        return (
          submissions
            // type coercion used because because this has
            // to be here; hasScore checks that all questions
            // have score
            .map((s) => s.score![toBeDone])
            .reduce((total, current) => total + current, 0)
        );
      };

      return {
        incentivizing: scoreFor('incentivizing'),
        penalizing: scoreFor('penalizing'),
        neutral: scoreFor('neutral'),
        maxScore: maxScore,
      };
    }
  }
  return undefined;
}

export function questionAddSuccess(state: IRootState, payload: actions.IQuestionAddPayload) {
  let updatedQuizSlice = state.quizzes.current
    ? update(state.quizzes, {
        current: {
          questions: [...state.quizzes.current.questions, payload.response],
        },
      })
    : state.quizzes;
  updatedQuizSlice = updateQuiz(updatedQuizSlice, payload.request.activityId, (quiz) =>
    u.update(quiz, {
      details: {
        numQuestions: quiz.details.numQuestions + 1,
      },
    })
  );
  return {
    ...state,
    quizzes: updatedQuizSlice,
  };
}

export function questionDeleteSuccess(
  state: IRootState,
  payload: actions.IQuestionRemoveSuccessPayload
): IRootState {
  let updatedQuizSlice = state.quizzes.current
    ? update(state.quizzes, {
        current: {
          questions: state.quizzes.current.questions.filter((q) => q._id !== payload.questionId),
        },
      })
    : state.quizzes;
  updatedQuizSlice = updateQuiz(updatedQuizSlice, payload.activityId, (quiz) =>
    u.update(quiz, {
      details: {
        numQuestions: quiz.details.numQuestions - 1,
      },
    })
  );
  return {
    ...state,
    quizzes: updatedQuizSlice,
  };
}

export function questionEditSuccess(
  state: IRootState,
  payload: actions.IQuestionEditSuccessPayload
): IRootState {
  const updatedSlice = state.quizzes.current
    ? u.update(state.quizzes, {
        current: {
          questions: u.replaceWhere(
            state.quizzes.current.questions,
            (q) =>
              u.update(q, {
                details: {
                  options: payload.details.options,
                  answerKey: payload.details.answerKey,
                  description: {
                    text: payload.details.description.text,
                  },
                },
              }),
            (q) => q._id === payload._id
          ),
        },
      })
    : state.quizzes;
  return {
    ...state,
    quizzes: updatedSlice,
  };
}

export function saveSubmissionSuccess(
  state: IRootState,
  payload: actions.ISaveSubmissionPayload
): IRootState {
  const submission: IQuizSubmission = {};
  for (const o of payload.submission) {
    submission[o.questionId] = {
      answerString: o.answerString,
    };
  }
  const quiz = state.quizzes.byId[payload.quizId];
  const updatedQuizSlice =
    quiz && quiz.userData
      ? {
          ...state.quizzes,
          byId: {
            ...state.quizzes.byId,
            [payload.quizId]: {
              ...quiz,
              userData: {
                ...quiz.userData,
                submission: submission,
              },
            },
          },
        }
      : state.quizzes;
  return {
    ...state,
    quizzes: updatedQuizSlice,
  };
}

export function submitSuccess(
  state: IRootState,
  payload: actions.ISubmitSuccessPayload
): IRootState {
  const quiz = payload.quiz;
  let updatedQuizSlice: IRootState['quizzes'] = state.quizzes.current
    ? {
        ...state.quizzes,
        current: {
          ...state.quizzes.current,
          questions: state.quizzes.current.questions
            .map((q) => {
              let options = q.details.options.slice(0);
              const answer = payload.response.answerKeys?.find((s) => s._id === q._id);

              if (q.details.type === 'reorder' && answer) {
                const optionsMap: ObjectMap<IReorderOption> = {};

                for (const option of q.details.options) {
                  optionsMap[option.orderKey] = option;
                }

                const answerKey = answer.details.answerKey;
                options = answerKey.split('-').map((orderKey) => optionsMap[orderKey]);
              }

              return {
                ...q,
                details: {
                  ...q.details,
                  options,
                  answerKey: payload.response.answerKeys
                    ? payload.response.answerKeys.find((ans) => ans._id === q._id)!.details
                        .answerKey
                    : '',
                },
              } as IQuizQuestion;
            })
            .sort((x, y) => x.details.createdOn - y.details.createdOn)
            .map((q) => {
              if (q.details.type === 'mcq') {
                return {
                  ...q,
                  details: {
                    ...q.details,
                    options: q.details.options.sort((x, y) => x.num - y.num),
                  },
                };
              } else {
                return q;
              }
            }),
          submission: payload.response.submission,
        },
      }
    : state.quizzes;
  updatedQuizSlice = updateQuiz(updatedQuizSlice, payload.quiz._id, () => ({
    ...quiz,
    userData: {
      timesStarted: quiz.userData ? quiz.userData.timesStarted : 1,
      firstAccessedOn: quiz.userData ? quiz.userData.firstAccessedOn : payload.response.submittedOn,
      status:
        payload.response.submittedOn > quiz.details.dueDateTime && quiz.details.dueDateTime !== -1
          ? ('late' as const)
          : ('submitted' as const),
      submission: payload.response.submission,
      submittedOn: payload.response.submittedOn,
      score: payload.response.score
        ? {
            ...payload.response.score,
            maxScore: quiz.details.numQuestions * 4,
          }
        : undefined,
      numCommentsSeen: quiz.userData ? quiz.userData.numCommentsSeen : 0,
      subscribed: 0,
    },
  }));

  const toBeDone = quiz.details.toBeDone;
  const updatedCourseSlice = state.courses.timeline
    ? u.update(state.courses, {
        timeline: {
          items: u.replaceWhere(
            state.courses.timeline.items,
            (cls: IClass) =>
              u.update(cls, {
                userData: {
                  [toBeDone]: {
                    quizzes: {
                      numCompleted: cls.userData[toBeDone].quizzes.numCompleted + 1,
                    },
                  },
                },
              }),
            (item) => item._id === payload.request.classId && item.nodeType === 'class'
          ),
        },
      })
    : state.courses;
  const updatedCommentsSlice = {
    ...state.comments,
    forceRefresh: true,
  };
  return {
    ...state,
    quizzes: updatedQuizSlice,
    courses: updatedCourseSlice,
    comments: updatedCommentsSlice,
  };
}

export function editSuccess(state: IRootState, payload: actions.IEditSuccessPayload): IRootState {
  if (!state.class.data) return state;
  const quiz = state.quizzes.byId[payload.quizId];
  if (!quiz) {
    console.warn('Called editQuizSuccess when quiz id ' + payload.quizId + ' not present.');
    console.trace();
    return state;
  }
  const updatedQuizSlice = {
    ...state.quizzes,
    byId: {
      ...state.quizzes.byId,
      [quiz._id]: update(quiz, {
        details: {
          title: payload.title,
          instructions:
            payload.instructions === null ? quiz.details.instructions : payload.instructions,
          attachments: payload.attachments,
          scoring: payload.scoring,
        },
      }),
    },
  };
  return {
    ...state,
    quizzes: updatedQuizSlice,
  };
}

export function createSuccess(
  state: IRootState,
  payload: actions.ICreateSuccessPayload
): IRootState {
  return addNewClassActivity(state, {
    type: 'quizzes',
    classId: payload.classId,
    activity: payload.quiz,
  });
}

export function updatePublishedQuizSuccess(
  state: IRootState,
  payload: {
    data: actions.IUpdateQuizRequest;
    questions?: IQuizQuestion[];
  }
) {
  const quiz = cloneDeep(state.quizzes.byId[payload.data.quizId]);
  const currentQuiz = cloneDeep(state.quizzes.current);
  if (quiz) {
    let newState = {
      ...state,
      quizzes: {
        ...state.quizzes,
        byId: {
          ...state.quizzes.byId,
          [payload.data.quizId]: {
            ...quiz,
            details: {
              ...quiz.details,
              title: payload.data.title,
              instructions: payload.data.instructions,
              attachments: payload.data.attachments,
            },
          },
        },
      },
    };
    if (payload.questions) {
      newState = {
        ...newState,
        quizzes: {
          ...newState.quizzes,
          current: {
            ...currentQuiz,
            _id: payload.data.quizId,
            questions: payload.questions,
          },
        },
      };
    }
    return newState;
  }
  return state;
}

export function publishSuccess(
  state: IRootState,
  payload: actions.IPublishSuccessPayload
): IRootState {
  const updatedQuizSlice = updateQuiz(state.quizzes, payload.request.quizId, (q) => ({
    ...q,
    details: {
      ...q.details,
      published: 1,
      dueDateTime: payload.response.dueDateTime,
      dueDateType: payload.response.dueDateType,
      publishedOn: payload.response.publishedOn,
      trueNum: payload.response.trueNum,
      num: payload.response.quizNum,
      allowLate: payload.request.allowLate,
      deadlineFirst: payload.request.deadlineFirst,
    },
    userData: {
      firstAccessedOn: q.details.createdOn,
      numCommentsSeen: 0,

      // these keys are used by students only
      // they have no significance because only
      // team members can publish quizzes
      status: 'submitted',
      timesStarted: 0,
      subscribed: 0,
    },
  }));
  const updatedCourseSlice = updateTotalClassActivitiesCount(state.courses, {
    activityType: 'quizzes',
    classId: payload.request.classId,
    toBeDone: payload.request.toBeDone,
    key: 'numPublished',
    num: 1,
  });
  return {
    ...state,
    quizzes: updatedQuizSlice,
    courses: updatedCourseSlice,
  };
}

export function stopSuccess(
  state: IRootState,
  payload: actions.IStopQuizSuccessPayload
): IRootState {
  const updatedQuizSlice = updateQuiz(state.quizzes, payload.request.activityId, (q) => ({
    ...q,
    details: {
      ...q.details,
      dueDateTime: payload.response.dueDateTime,
    },
  }));
  return {
    ...state,
    quizzes: updatedQuizSlice,
  };
}

export function fetchAllSuccess(
  state: IRootState,
  payload: actions.IFetchAllSuccessPayload
): IRootState {
  const quizzes = payload.activityData.map((q) =>
    responseQuizToQuiz(payload.userData.quizzes[q._id], q.identifiers.classId, q)
  );
  const quizzesById = u.makeObjectWithKey(quizzes, '_id');
  return {
    ...state,
    quizzes: {
      ...state.quizzes,
      byId: quizzesById,
    },
  };
}

export function pusherSubmitted(
  state: IRootState,
  payload: actions.IPusherSubmittedPayload
): IRootState {
  const updatedQuizzesSlice = updateQuiz(state.quizzes, payload.quizId, (q) => ({
    ...q,
    stats: q.stats
      ? {
          ...q.stats,
          numSubmitted: q.stats.numSubmitted + 1,
        }
      : {
          numSubmitted: 1,
        },
  }));
  return {
    ...state,
    quizzes: updatedQuizzesSlice,
  };
}

function reorderQuestionsSuccess(state: IRootState, payload: ReorderQuestionPayload): IRootState {
  const { questionsOrder, quizId } = payload;
  const questions = u.makeObjectWithKey(state.quizzes.current.questions, '_id');

  return update(state, {
    quizzes: {
      byId: {
        [quizId]: {
          details: {
            reordered: 1,
          },
        },
      },
      current: {
        questions: questionsOrder
          .sort((a, b) => a.order - b.order)
          .map(({ questionId }) => questions[questionId]),
      },
    },
  });
}
