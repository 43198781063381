import { colors } from 'acadly/styles';

export const viewWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

export const container = {
  display: 'flex',
  flexDirection: 'column',
  width: '30em',
  maxWidth: '100%',
  height: '100%',
  overflowY: 'auto',
};

export const title = (isMobile: boolean) => {
  return {
    fontSize: '16px',
    lineHeight: '4em',
    padding: isMobile ? '0 1em' : '0',
  };
};

export const contentBody = (isMobile: boolean) => {
  return {
    padding: isMobile ? '0 1em' : '0',
  };
};

export const textBlock = {
  color: colors.black,
  marginBottom: '1em',
};

export const formHeader = (isMobile: boolean) => {
  return {
    color: colors.grey,
    marginBottom: '1em',
    marginTop: '2em',
    padding: isMobile ? '0 1em' : '0',
  };
};

export const formContainer = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
};

export const formContent = {
  backgroundColor: colors.white,
};

export const inputBlock = {
  padding: '0.5em 1em',
};

export const lastInputBlock = {
  paddingBottom: '2em',
};

export const spacer = {
  display: 'flex',
  flexGrow: 1,
};

export const addFieldButton = {
  color: colors.blue,
  padding: '1em 2em',
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: '0',
  border: 'none',
  marginTop: '18px',
};

export const submitButton = (disableSubmit: boolean) => {
  const disabledStyles = disableSubmit
    ? {
        backgroundColor: colors.lighterGrey,
        color: colors.black,
      }
    : {};

  return {
    padding: '1em 2em',
    backgroundColor: colors.blue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: '0',
    color: colors.white,
    textTransform: 'uppercase',
    fontSize: '14px',
    border: 'none',
    ...disabledStyles,
  };
};
