import anchorme from 'anchorme';

import { CSS, h, IComponent } from 'core';

import AttachmentViewer from 'acadly/common/AttachmentViewer';
import { colors, style } from 'acadly/styles';
import { range } from 'acadly/utils';

import { parseMarkup } from './lib';

interface IViewerProps {
  attachments?: {
    files: IAttachment[];
    downloadURL: string;
    downloadRequest: (a: IAttachment) => any;
  };
  style?: CSS;
  className?: string;
  tabIndex?: number;
  ariaLabel?: string;
}
export default (text: string, props?: IViewerProps) => h(Viewer, { text, ...props });
export class Viewer extends IComponent<
  IViewerProps & { text: string },
  {
    isViewingImageSrc: string | null;
  }
> {
  public componentWillMount() {
    this.setState({
      isViewingImageSrc: null,
    });
  }

  public componentWillReceiveProps(nextProps: IViewerProps & { text: string }) {
    if (
      (nextProps.text !== this.getProps().text ||
        nextProps.attachments !== this.getProps().attachments) &&
      this.element
    ) {
      this.initialize(this.element, nextProps);
    }
  }

  private initialize(element: Element, props: IViewerProps & { text: string }) {
    const markup = parseMarkup(
      anchorme(props.text, {
        exclude: () => {
          if (props.text.includes('/math/') && props.text.includes('amazonaws.com')) {
            return true;
          } else return false;
        },
      })
    ); // to replace links with "a" tag

    if (!markup) return;

    const dom = markup;
    this.element.innerText = '';
    element.appendChild(dom);

    const images = dom.getElementsByTagName('img');
    for (const i of range(images.length)) {
      const img = images[i];
      const src = img.src;
      img.addEventListener('click', () => {
        this.setState({
          isViewingImageSrc: src,
        });
      });
    }
    const links = dom.getElementsByTagName('a');
    for (const i of range(links.length)) {
      const elem = links[i];
      const url = elem.href;
      elem.href = '';
      elem.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(url, '_blank');
      });
    }
    if (!(<any>window).MathJax) return;
    if (markup.getElementsByTagName('formula').length > 0) {
      MathJax.Hub.Queue(['Typeset', MathJax.Hub, element]);
    }
  }
  private element: HTMLElement;
  public render() {
    const attachments = this.getProps().attachments;
    const isViewingImage = this.getState().isViewingImageSrc;
    const elem = h('div.rich-text-viewer', {
      style: {
        lineHeight: '1.5em',
      },
      ref: (element?: HTMLElement) => {
        if (!element || element === this.element) return;
        this.element = element;
        this.initialize(element, this.getProps());
      },
    });
    return h(
      'div.rich-text-container',
      {
        className: this.getProps().className,
        tabIndex: this.getProps().tabIndex !== null ? this.getProps().tabIndex : undefined,
        'aria-label': this.getProps().ariaLabel ? this.getProps().ariaLabel : undefined,
        style: {
          color: 'black',
          wordWrap: 'break-word',
          ...this.getProps().style,
        },
      },
      [
        elem,
        isViewingImage
          ? h(
              'div.image-overlay',
              style(
                [
                  'fixed',
                  'fullWidth',
                  'fullHeight',
                  'flex',
                  'alignCenter',
                  'justifyCenter',
                  {
                    top: 0,
                    left: 0,
                    zIndex: 100000,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  },
                ],
                {},
                {
                  onclick: () =>
                    this.setState({
                      isViewingImageSrc: null,
                    }),
                }
              ),
              [
                h(
                  'div.ripple',
                  style([
                    'white',
                    'fixed',
                    'flex',
                    'alignCenter',
                    'justifyCenter',
                    {
                      backgroundColor: colors.blue,
                      width: '2.5rem',
                      height: '2.5rem',
                      top: '1rem',
                      right: '1rem',
                      borderRadius: '50%',
                    },
                  ]),
                  [h('i.fa.fa-times')]
                ),
                h('img', {
                  src: isViewingImage,
                  style: {
                    maxWidth: '100%',
                    maxHeight: '100%',
                  },
                }),
              ]
            )
          : null,
      ].concat(
        attachments
          ? attachments.files.map((f) =>
              AttachmentViewer({
                attachment: f,
                downloadRequest: attachments.downloadRequest(f),
                downloadUrl: attachments.downloadURL,
                style: {
                  marginTop: '0.5rem',
                },
              })
            )
          : []
      )
    );
  }
}
