import { h, IComponent } from 'core';

import FlatButton from 'acadly/common/FlatButton';
import { mb, style } from 'acadly/styles';

interface IInstructorPaysThanksStepProps {
  getInstance?: (ref: InstructorPaysThanksStep) => any;
  onClose?: () => any;
}

export class InstructorPaysThanksStep extends IComponent<IInstructorPaysThanksStepProps, unknown> {
  public static getTitle(): string {
    return 'Thank you';
  }

  public componentWillMount() {
    const { getInstance } = this.getProps();
    getInstance?.(this);
  }

  public handleNext = async () => {
    const { onClose } = this.getProps();
    onClose();
  };

  public render() {
    return h('div', [
      h('p', style([mb('1rem')]), [
        'We will reach out to you with our instructor-pays pricing and payment information via email in the next 24 hours. Please send us an email at ',
        h('a.no-break', { href: 'mailto://support@acadly.com' }, 'support@acadly.com'),
        ' if you need to expedite this process.',
      ]),
      h('div', style(['flex', 'flexEnd']), [
        FlatButton('Okay', {
          onclick: this.handleNext,
        }),
      ]),
    ]);
  }
}
