import { redraw } from 'core';
import history from 'core/history';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require('crypto-js');

import { Actions as _AppActions } from 'acadly/app/actions';
import { Option } from 'acadly/common/Select';
import { Routes as _Routes } from 'acadly/routes';
import { dispatch as _dispatch, getStore } from 'acadly/store';
import { UIFlow } from 'acadly/UIFlow';
import * as u from 'acadly/utils';
import { CreateCoursePermission } from 'acadly/utils/constants';
import { getPasswordError } from 'acadly/utils/password';
import { getQueryParams } from 'acadly/utils/queryParams';
import UserName from 'acadly/utils/UserName';

import { key } from '../acadly-encrypt';
import { Actions as _Actions } from './actions';
import * as _api from './api';
import { IGetInState } from './IGetInState';

interface DropDownProps<T extends string> {
  isOpen: boolean;
  value: Option<T> | undefined;
  error: string;
  options: Option<T>[];
}

export enum GetInElements {
  WRAPPER_ID = 'getin-screen',
  LOGIN_EMAIL_INPUT = 'login-email-input',
  GET_ROLE_STUDENT = 'get-role-student',
  SIGNUP_EMAIL_INPUT = 'signup-email-input',
  GET_PASSWORD_INPUT = 'get-password-input',
  FORGOT_PASSWORD_INPUT = 'forgot-password-input',
  SET_PASSWORD_INPUT = 'set-password-input',
  JOIN_COURSE_INPUT = 'join-course-input',
}

export type GetInScreen =
  | 'LOGIN_EMAIL'
  | 'SIGNUP_EMAIL'
  | 'JOIN_CODE'
  | 'TEACHER_ROLE'
  | 'THANK_YOU'
  | 'PASSWORD'
  | 'FORGOT_PASSWORD'
  | 'SET_PASSWORD'
  | 'GET_ROLE'
  | 'VERIFICATION_AWAITED'
  | 'TEMP_PASSWORD'
  | 'SET_NAME'
  | 'SET_PROFILE';

const USE_CASES_OPTIONS_1: Option<_api.TeacherSignupUseCase>[] = [
  {
    id: 'inPersonCourse',
    title: 'An In-Person Course',
  },
  {
    id: 'liveOnlineCourse',
    title: 'A Synchronous/ Live Online Course',
  },
  {
    id: 'asyncCourse',
    title: 'An Asynchronous/ Self-Paced Course',
  },
  {
    id: 'standaloneEvent',
    title: 'Standalone Seminar/ Event/ Talk',
  },
  {
    id: 'na',
    title: 'None of the above',
  },
];

const USE_CASES_OPTIONS_2: Option<_api.TeacherSignupUseCase>[] = [
  {
    id: 'intOnlineTraining',
    title: 'Internal, online training',
  },
  {
    id: 'intOfflineTraining',
    title: 'Internal, in-person training',
  },
  {
    id: 'custOnlineTraining',
    title: 'Online training for customers',
  },
  {
    id: 'custOfflineTraining',
    title: 'In-person training for customers',
  },
  {
    id: 'standaloneEvent',
    title: 'Standalone seminar/event/talk',
  },
  {
    id: 'na',
    title: 'None of the above',
  },
];

export class GetInFlow extends UIFlow {
  // these are not being used directly so that
  // and being used as instance members so that they
  // can be easily mocked out for testing.
  // Sort of a cheap way of doing dependency injection :|
  // Essentialy the class contains everything it needs
  // as an instance member and isn't dependent on a browser
  //
  // This class can be tested outside a browser without
  // making any network calls.
  constructor(
    private api: typeof _api = _api,
    private Routes: typeof _Routes = _Routes,
    private dispatch: typeof _dispatch = _dispatch,
    private Actions: typeof _Actions = _Actions,
    private AppActions: typeof _AppActions = _AppActions,
    private _ga: any = ga
  ) {
    super();
    if (Routes.login.isActive()) {
      dispatch(Actions.setCurrentScreen('LOGIN_EMAIL'));
    } else if (this.Routes.signupStudent.isActive()) {
      this.userRole = 'student';
    } else if (this.Routes.signupTeacher.isActive()) {
      this.userRole = 'teacher';
    } else if (Routes.signup.isActive()) {
      dispatch(Actions.setCurrentScreen('GET_ROLE'));
    }
  }

  public isLoading = false;

  public emailField = '';
  public email = '';
  public emailError = false;
  public userIdError = false;
  public userExistsError = false;
  public userDoesNotExistsError = false;

  public profileCompleted = false;
  public universityName = '';
  public avatar = '';
  public userName = '';
  public wrongPasswordEntered = false;
  public passwordField = '';
  public passwordError: string | null = null;
  public isPasswordVisible = false;

  public tempPasswordField = '';
  public tempPasswordError: string | null = null;
  public isResettingPassword = false;
  public tempPassResent = false;
  public isTermsDialogVisible = false;
  public termsAcceptChecked = false;

  public userRole: 'student' | 'teacher' | null = null;
  public firstNameField = '';
  public middleNameField = '';
  public lastNameField = '';
  public fullNameField = '';
  public firstNameError: string | null = null;
  public lastNameError: string | null = null;
  public fullNameError: string | null = null;
  public websiteField = '';

  public skinToneSelected = 0;
  public avatarSelected = 0;
  public avatarUploadedFile: File | null = null;
  public avatarUploadedURL = '';
  public croppedAvatarURL = '';

  public joinCodeField = '';
  public joinCodeError: string | null = null;
  public isCourseDetailsDialogOpen = false;
  public isNoJoinCodeDialogOpen = false;
  public isWhichEmailDialogOpen = false;
  public emailPermission = false;
  public courseInfo = {
    code: '',
    title: '',
    admin: '',
    university: '',
  };

  public universityExists: 0 | 1 = 0;

  public roleDD: DropDownProps<_api.TeacherSignupRole> = {
    isOpen: false,
    value: undefined,
    error: '',
    options: [
      {
        id: 'faculty',
        title: 'Faculty',
      },
      {
        id: 'ta',
        title: 'Graduate/Teaching Assistant',
      },
      {
        id: 'staff',
        title: 'Administrative Staff',
      },
      {
        id: 'student',
        title: 'Student',
      },
    ],
  };

  public orgDD: DropDownProps<_api.TeacherSignupOrganization> = {
    isOpen: false,
    value: undefined,
    error: '',
    options: [
      {
        id: 'university',
        title: 'University/College',
      },
      {
        id: 'k12',
        title: 'K-12 School',
      },
      {
        id: 'vocational',
        title: 'Vocational Training School',
      },
      {
        id: 'company',
        title: 'Company or Govt Organization',
      },
      {
        id: 'nonProfit',
        title: 'Non profit',
      },
      {
        id: 'independent',
        title: 'Independent Instructor/Presenter',
      },
    ],
  };

  public useCaseDD: DropDownProps<_api.TeacherSignupUseCase> = {
    isOpen: false,
    value: undefined,
    error: '',
    options: USE_CASES_OPTIONS_1,
  };

  private avatarURL = '';
  private universitySlug = '';
  private canCreateCourses: CreateCoursePermission = CreateCoursePermission.NOT_ALLOWED;
  private userId = '';
  private server = '';

  public get isNextButtonVisible() {
    switch (this.screen) {
      case 'GET_ROLE':
      case 'VERIFICATION_AWAITED':
      case 'THANK_YOU':
        return false;
      default:
        return true;
    }
  }

  public get screen(): GetInScreen | null {
    return getStore().getState().getIn.currentScreen;
  }

  public get isBackButtonVisible() {
    switch (this.screen) {
      case 'LOGIN_EMAIL':
      case 'GET_ROLE':
      case 'SET_PROFILE':
      case 'SET_PASSWORD':
      case 'SET_NAME':
        return false;
      default:
        return true;
    }
  }

  private parseQueryParams() {
    const { action, emailId } = getQueryParams();

    if (action === 'login' && u.validateEmail(emailId)) {
      this.emailField = emailId;
      this.update(async () => {
        this.isLoading = true;
        await this.loginSubmitHandler();
        this.isLoading = false;
      }).then(redraw);
      // redraw();
    }
  }

  public setCurrentScreenByRoute(path: string) {
    const Routes = this.Routes;
    switch (path) {
      case Routes.login.path:
        this.dispatch(this.Actions.setCurrentScreen('LOGIN_EMAIL'));
        this.parseQueryParams();
        break;
      case Routes.signup.path:
        this.dispatch(this.Actions.setCurrentScreen('GET_ROLE'));
        break;
      case Routes.signupStudent.path:
      case Routes.signupTeacher.path:
        this.dispatch(this.Actions.setCurrentScreen('SIGNUP_EMAIL'));
        break;
      default:
        this.dispatch(this.Actions.setCurrentScreen('LOGIN_EMAIL'));
        break;
    }
  }

  public gotoSignup() {
    this.emailError = false;
    this.userExistsError = false;
    this._ga('landing.send', 'pageview', '/1907-signup-started');
    this.dispatch(this.Actions.setCurrentScreen('GET_ROLE'));
    this.Routes.signup.navigate({});
    this.focusElement(GetInElements.GET_ROLE_STUDENT);
  }

  public gotoSignupStudent() {
    this.userRole = 'student';
    this.userExistsError = false;
    this._ga('landing.send', 'pageview', '/1907-signup-started-student');
    this.dispatch(this.Actions.setCurrentScreen('SIGNUP_EMAIL'));
    this.Routes.signupStudent.navigate({});
  }

  public gotoSignupTeacher() {
    this.userRole = 'teacher';
    this.emailError = false;
    this.userExistsError = false;
    this._ga('landing.send', 'pageview', '/1907-signup-started-teacher');
    this.dispatch(this.Actions.setCurrentScreen('SIGNUP_EMAIL'));
    this.Routes.signupTeacher.navigate({});
  }

  public gotoLogin() {
    this.emailError = false;
    this.userDoesNotExistsError = false;
    this.dispatch(this.Actions.setCurrentScreen('LOGIN_EMAIL'));
    this.Routes.login.navigate({});
    this.focusElement(GetInElements.LOGIN_EMAIL_INPUT);
  }

  public onClickNext() {
    if (this.isLoading) return;
    return this.update(async () => {
      switch (this.screen) {
        case 'LOGIN_EMAIL':
          await this.withLoader(this.loginSubmitHandler());
          return;
        case 'PASSWORD':
          await this.withLoader(this.passwordSubmitHandler());
          return;
        case 'TEMP_PASSWORD':
        case 'FORGOT_PASSWORD':
          await this.withLoader(this.tempPasswordSubmitHandler());
          return;
        case 'SET_PASSWORD':
          await this.withLoader(this.setPasswordSubmitHandler());
          return;
        case 'SET_NAME':
          return await this.withLoader(this.setNameSubmitHandler());
        case 'SET_PROFILE':
          return await this.withLoader(this.setProfileSubmitHandler());
        case 'SIGNUP_EMAIL':
          if (this.userRole === 'teacher') {
            await this.withLoader(this.signupTeacherHandler());
          } else if (this.userRole === 'student') {
            await this.withLoader(this.signupStudentHandler());
          }
          return;
        case 'JOIN_CODE':
          return await this.withLoader(this.handleJoinCodeSubmit());
        case 'TEACHER_ROLE':
          return await this.withLoader(this.teacherSignupSubmitHandler());
        default:
          return;
      }
    });
  }

  public onClickBack() {
    return this.update(async () => {
      switch (this.screen) {
        case 'PASSWORD':
          await this.gotoLogin();
          return this.dispatch(this.Actions.clearServer(undefined));
        case 'FORGOT_PASSWORD':
          this.tempPasswordField = '';
          this.tempPasswordError = null;
          this.passwordField = '';
          this.passwordError = null;
          return this.dispatch(this.Actions.setCurrentScreen('PASSWORD'));
        case 'SET_PASSWORD':
          await this.dispatch(this.Actions.clearServer(undefined));
          this.tempPasswordField = '';
          this.tempPasswordError = '';
          this.emailField = '';
          this.passwordField = '';
          this.passwordError = '';
          return this.gotoLogin();
        case 'VERIFICATION_AWAITED':
          return await this.gotoLogin();
        case 'GET_ROLE':
          if (this.userRole === null) {
            await this.dispatch(this.Actions.clearServer(undefined));
            this.universityName = '';
            this.universitySlug = '';
          }
          return await this.gotoLogin();
        case 'SET_PROFILE':
          if (this.avatarUploadedFile) {
            this.avatarUploadedFile = null;
            return;
          }
          await this.dispatch(this.Actions.clearServer(undefined));
          return this.gotoLogin();
        case 'JOIN_CODE':
          return this.gotoSignupStudent();
        case 'SIGNUP_EMAIL':
          return this.gotoSignup();
        case 'TEACHER_ROLE':
          return this.gotoSignupTeacher();
        default:
          await this.dispatch(this.Actions.clearServer(undefined));
          return this.gotoLogin();
      }
    });
  }

  public onSelectRole = (role: 'student' | 'teacher') =>
    this.update(() => {
      if (role === 'teacher') {
        this.firstNameField = '';
        this.middleNameField = '';
        this.lastNameField = '';
        this.firstNameError = null;
        this.lastNameError = null;
        this.websiteField = '';
        return this.gotoSignupTeacher();
      }
      this.gotoSignupStudent();
    });

  public onEmailInput = (email: string) =>
    this.update(() => {
      this.emailField = email;
      this.emailError = false;
      this.userIdError = false;
      this.userDoesNotExistsError = false;
      this.userExistsError = false;
    });

  public onPasswordInput = (password: string) =>
    this.update(() => {
      this.passwordField = password;
      this.passwordError = null;
    });

  public onFirstNameInput = (name: string) =>
    this.update(() => {
      this.firstNameField = name;
      this.firstNameError = null;
    });

  public onMiddleNameInput = (name: string) =>
    this.update(() => {
      this.middleNameField = name;
    });

  public onLastNameInput = (name: string) =>
    this.update(() => {
      this.lastNameField = name;
      this.lastNameError = null;
    });

  public onFullNameInput = (name: string) =>
    this.update(() => {
      this.fullNameField = u.toTitleCase(name);
      this.fullNameError = null;
    });

  public onWebsiteInput = (website: string) =>
    this.update(() => {
      this.websiteField = website;
    });

  public onOneTimePasswordInput = (password: string) =>
    this.update(() => {
      this.tempPasswordError = null;
      this.tempPasswordField = password;
    });

  public async onCourseDetailsClose() {
    await this.update(() => {
      this.isCourseDetailsDialogOpen = false;
      this.focusElement(GetInElements.JOIN_COURSE_INPUT);
    });
  }

  public onJoinCodeInput = (code: string) =>
    this.update(() => {
      this.joinCodeError = null;
      this.joinCodeField = code;
    });

  public async onEmailPermissionChange() {
    await this.update(() => {
      this.emailPermission = !this.emailPermission;
    });
  }

  public async showNoJoinCodeDialog() {
    await this.update(() => {
      this.isNoJoinCodeDialogOpen = true;
    });
  }

  public async closeNoJoinCodeDialog() {
    await this.update(() => {
      this.isNoJoinCodeDialogOpen = false;
      this.focusElement(GetInElements.JOIN_COURSE_INPUT);
    });
  }

  public async showWhichEmailDialog() {
    await this.update(() => {
      this.isWhichEmailDialogOpen = true;
    });
  }

  public async closeWhichEmailDialog() {
    await this.update(() => {
      this.isWhichEmailDialogOpen = false;
      this.focusElement(GetInElements.SIGNUP_EMAIL_INPUT);
    });
  }

  public async onCourseConfirm() {
    const response = await this.api.signupStudent(
      this.joinCodeField,
      this.emailField,
      this.emailPermission ? 1 : 0
    );
    this.email = this.emailField;
    await this.update(() => {
      this.isCourseDetailsDialogOpen = false;
    });
    if (response.data.next === 'changeServer') {
      await this.changeServer(response.data.cluster);
    } else if (response.data.next === 'goToLogin') {
      await this.update(() => {
        this.userExistsError = true;
        this.focusElement(GetInElements.JOIN_COURSE_INPUT);
      });
    }
    return;
  }

  public async handleJoinCodeSubmit() {
    if (this.joinCodeField.length !== 6) {
      return await this.update(() => {
        this.joinCodeError = 'Invalid Join Code';
        this.focusElement(GetInElements.JOIN_COURSE_INPUT);
      });
    }
    const response = await this.api.getCourseByCode(this.joinCodeField);
    const { title, courseCode: code, universityName: university, admin } = response.data;
    this.courseInfo = { code, title, university, admin: admin.name };
    await this.update(() => {
      this.isCourseDetailsDialogOpen = true;
    });
  }

  public onClickForgotPassword() {
    if (this.isLoading) return;
    return this.update(async () => {
      await this.withLoader(this.api.forgotPassword(this.userId));
      this.passwordField = '';
      this.passwordError = null;
      this.tempPasswordError = null;
      this.tempPasswordField = '';
      this.dispatch(this.Actions.setCurrentScreen('FORGOT_PASSWORD'));
      return;
    });
  }

  public onClickResendVerificationCode = () =>
    this.withLoader(
      this.update(async () => {
        await this.api.resendTempPass(this.userId);
        this.tempPassResent = true;
      })
    );

  public togglePasswordVisible = () =>
    this.update(() => {
      this.isPasswordVisible = !this.isPasswordVisible;
    });

  public async onSelectSkinTone(index: number) {
    await this.update(() => {
      this.skinToneSelected = index;
    });
  }

  public getDefaultAvatars() {
    const skinTone = ['', 'p-', 't-', 'd-'][this.skinToneSelected || 0] || '';
    return [
      {
        ariaLabel: 'Female avatar 1',
        url: `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-01.png`,
      },
      {
        ariaLabel: 'Female avatar 2',
        url: `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-02.png`,
      },
      {
        ariaLabel: 'Female avatar 3',
        url: `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-03.png`,
      },
      {
        ariaLabel: 'Female avatar 4',
        url: `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-04.png`,
      },
      {
        ariaLabel: 'Male avatar 1',
        url: `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-05.png`,
      },
      {
        ariaLabel: 'Male avatar 2',
        url: `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-06.png`,
      },
      {
        ariaLabel: 'Male avatar 3',
        url: `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-07.png`,
      },
      {
        ariaLabel: 'Male avatar 4',
        url: `https://s3.amazonaws.com/acadly-test/i/public/${skinTone}default-08.png`,
      },
      {
        ariaLabel: 'Faceless avatar 1',
        url: 'https://s3.amazonaws.com/acadly-test/i/public/default.png',
      },
      {
        ariaLabel: 'Faceless avatar 2',
        url: 'https://s3.amazonaws.com/acadly-test/i/public/default-09.png',
      },
      {
        ariaLabel: 'Faceless avatar 3',
        url: 'https://s3.amazonaws.com/acadly-test/i/public/default-10.png',
      },
      {
        ariaLabel: 'Faceless avatar 4',
        url: 'https://s3.amazonaws.com/acadly-test/i/public/default-11.png',
      },
    ];
  }

  public getSkinToneUrls() {
    return [
      {
        ariaLabel: 'Skin tone: fairest',
        url: 'https://s3.amazonaws.com/acadly-test/i/public/tone.png',
      },
      {
        ariaLabel: 'Skin tone: fair',
        url: 'https://s3.amazonaws.com/acadly-test/i/public/tone-p.png',
      },
      {
        ariaLabel: 'Skin tone: dark',
        url: 'https://s3.amazonaws.com/acadly-test/i/public/tone-t.png',
      },
      {
        ariaLabel: 'Skin tone: darkest',
        url: 'https://s3.amazonaws.com/acadly-test/i/public/tone-d.png',
      },
    ];
  }

  public getSelectedAvatar() {
    return this.getDefaultAvatars()[this.avatarSelected || 0];
  }

  public async chooseFromDefaultAvatars() {
    await this.update(() => {
      this.avatarUploadedFile = null;
    });
  }

  public async onSelectAvatar(index: number) {
    await this.update(() => {
      this.avatarSelected = index;
    });
  }

  public async skipToHome() {
    this.Routes.home.navigate({});
  }

  public onAvatarSelectFromSystem(file: File) {
    return {
      promise: this.update(async () => {
        this.avatarUploadedFile = file;
        const reader = new FileReader();
        const promise = new Promise<void>((resolve) => {
          reader.addEventListener('load', () => {
            this.avatarUploadedURL = <any>reader.result;
            this.croppedAvatarURL = this.avatarUploadedURL;
            resolve();
          });
        });
        reader.readAsDataURL(file);
        await promise;
      }),
    };
  }

  public async onAvatarCrop(dataURL: string) {
    await this.update(async () => {
      this.avatarUploadedFile = u.dataURIToFile(this.avatarUploadedFile!.name, dataURL);
      this.croppedAvatarURL = dataURL;
    });
  }

  private async setNameSubmitHandler() {
    await this.update(async () => {
      if (this.firstNameField.length < 1) {
        this.firstNameError = 'Please enter your first name';
        return;
      }
      await this.createProfile();
    });
  }

  private async createProfile() {
    this.fullNameField = [this.firstNameField, this.middleNameField, this.lastNameField]
      .filter(Boolean)
      .map((n) => n.trim())
      .join(' ');
    await this.withLoader(
      this.update(async () => {
        await this.api.createProfile(this.fullNameField);
        this.skinToneSelected = 0;
        this.avatarSelected = 0;
        this.avatarUploadedFile = null;
        this.setSession(getStore().getState().getIn.session);
        this.dispatch(this.Actions.setCurrentScreen('SET_PROFILE'));
      })
    );
  }

  private getPasswordError(password: string): string | null {
    return getPasswordError(password);
  }

  public async onTermsAccept() {
    await this.update(async () => {
      this.isTermsDialogVisible = false;
      await this.setPasswordSubmitHandler(true);
    });
  }

  public async onTermsAcceptCheckboxClick() {
    await this.update(() => {
      this.termsAcceptChecked = !this.termsAcceptChecked;
    });
  }

  public async onTermsCancel() {
    await this.update(() => {
      this.isTermsDialogVisible = false;
      this.focusElement(GetInElements.SET_PASSWORD_INPUT);
    });
  }

  private async passwordSubmitHandler() {
    const redirectRoute = getStore().getState().getIn.routeAccessedBeforeLogin;
    await this.update(async () => {
      if (this.passwordField.length < 8 || this.passwordField.length > 32) {
        this.passwordError = 'This is an invalid password';
        this.focusElement(GetInElements.GET_PASSWORD_INPUT);
        return;
      }

      const submitPasswordResponse = await this.api.tryLogin(this.userId, this.passwordField);

      if (submitPasswordResponse.status >= 400) {
        // TODO: Fix response type from api utils, so this unknown as bs isn't needed
        const { message } = submitPasswordResponse.data as unknown as { message: string };
        switch (submitPasswordResponse.status) {
          case 409:
            this.passwordError = message;
            this.focusElement(GetInElements.GET_PASSWORD_INPUT);
            return;
          default:
            this.dispatch(this.AppActions.showError({ message }));
            return;
        }
      }
      this.wrongPasswordEntered = false;
      this.passwordField = '';
      if (submitPasswordResponse.data.next === 'landingPage') {
        const session = this.createSession(
          submitPasswordResponse.data.token,
          submitPasswordResponse.data.socket
        );
        this.setSession(session);
        this.dispatch(this.Actions.setSession(session));
        if (redirectRoute) {
          history.push(redirectRoute);
          redraw();
          this.dispatch(this.Actions.setRouteAccessed(undefined));
        } else {
          this.Routes.home.navigate({});
        }
      } else if (submitPasswordResponse.data.next === 'profileNotCompleted') {
        if (this.fullNameField) {
          const name = new UserName(this.fullNameField);
          this.firstNameField = u.toTitleCase(name.firstName);
          this.middleNameField = u.toTitleCase(name.middleName);
          this.lastNameField = u.toTitleCase(name.lastName);
        } else {
          this.firstNameField = '';
          this.middleNameField = '';
          this.lastNameField = '';
        }

        this.firstNameError = null;
        this.lastNameError = null;

        const session = this.createSession(
          submitPasswordResponse.data.token,
          submitPasswordResponse.data.socket
        );
        // this.setSession(session);
        this.dispatch(this.Actions.setSession(session));
        this.dispatch(this.Actions.setCurrentScreen('SET_NAME'));
        this._ga('landing.send', 'pageview', '/1907-signup-student-enter-name');
      }
    });
  }

  private async changeServer(cluster: string, checkUserExists = false) {
    this.dispatch(this.Actions.setServer(cluster));
    const changeServerResponse = await this.api.fetchRegistrationDetails(this.email);
    this.server = cluster;
    this.userId = changeServerResponse.data.userId;
    if (this.userId.length < 2) {
      await this.update(() => (this.userIdError = true));
      return;
    }
    if (checkUserExists && changeServerResponse.data.next === 'getPassword') {
      this.userExistsError = true;
      switch (this.screen) {
        case 'SIGNUP_EMAIL':
          return this.focusElement(GetInElements.SIGNUP_EMAIL_INPUT);
        case 'JOIN_CODE':
          return this.focusElement(GetInElements.JOIN_COURSE_INPUT);
      }
      return;
    }
    if (
      changeServerResponse.data.next === 'getPassword' ||
      changeServerResponse.data.next === 'passwordNotSet'
    ) {
      this.profileCompleted = Boolean(changeServerResponse.data.profileCompleted);
      this.universityName = changeServerResponse.data.university.univName;
      this.universitySlug = changeServerResponse.data.university.univSlug;
      this.avatar = changeServerResponse.data.avatar;
      this.dispatch(this.Actions.fetchAvatars([this.avatar], changeServerResponse.data.avatarURL));
      this.avatarURL = changeServerResponse.data.avatarURL;
      this.passwordField = '';
      this.passwordError = null;
      this.isPasswordVisible = false;
      this.userName = changeServerResponse.data.name;
      this.canCreateCourses = changeServerResponse.data.canCreateCourses;
      if (changeServerResponse.data.next === 'passwordNotSet') {
        this.tempPassResent = false;
        this.isResettingPassword = false;
        this.dispatch(this.Actions.setCurrentScreen('TEMP_PASSWORD'));
      } else if (changeServerResponse.data.next === 'getPassword') {
        this.dispatch(this.Actions.setCurrentScreen('PASSWORD'));
      }
    }
  }

  private async isInvalidEmail() {
    if (!u.validateEmail(this.emailField)) {
      await this.update(() => (this.emailError = true));
      return true;
    }
    return false;
  }

  private focusElement(id: string) {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => element.focus(), 10);
    }
  }

  private async loginSubmitHandler() {
    if (await this.isInvalidEmail()) {
      this.focusElement(GetInElements.LOGIN_EMAIL_INPUT);
      return;
    }

    await this.update(async () => {
      const response = await this.api.fetchServer(this.emailField);
      this.email = this.emailField;
      if (response.data.next === 'changeServer') {
        await this.changeServer(response.data.cluster);
      } else if (response.data.next === 'getRoleUE' || response.data.next === 'getRoleUNE') {
        this.userDoesNotExistsError = true;
        this.focusElement(GetInElements.LOGIN_EMAIL_INPUT);
      } else if (response.data.next === 'verificationAwaited') {
        this.dispatch(this.Actions.setCurrentScreen('VERIFICATION_AWAITED'));
        this._ga('landing.send', 'pageview', '/1907-signup-verification-awaited');
      }
    });
    return;
  }

  private async signupStudentHandler() {
    if (await this.isInvalidEmail()) {
      this.focusElement(GetInElements.SIGNUP_EMAIL_INPUT);
      return;
    }

    await this.update(async () => {
      const response = await this.api.fetchServer(this.emailField);
      this.email = this.emailField;
      if (response.data.next === 'changeServer') {
        await this.changeServer(response.data.cluster, true);
      } else if (response.data.next === 'getRoleUE' || response.data.next === 'getRoleUNE') {
        this.dispatch(this.Actions.setCurrentScreen('JOIN_CODE'));
        this.focusElement(GetInElements.JOIN_COURSE_INPUT);
      }
    });
    return;
  }

  private async signupTeacherHandler() {
    if (await this.isInvalidEmail()) {
      this.focusElement(GetInElements.SIGNUP_EMAIL_INPUT);
      return;
    }

    await this.update(async () => {
      const response = await this.api.fetchServer(this.emailField);
      this.email = this.emailField;
      if (response.data.next === 'changeServer') {
        await this.changeServer(response.data.cluster, true);
      } else if (response.data.next === 'getRoleUE') {
        this.universityName = response.data.univName;
        this.universitySlug = response.data.univSlug;
        this.universityExists = 1;
        this.dispatch(this.Actions.setCurrentScreen('TEACHER_ROLE'));
        this._ga('landing.send', 'pageview', '/1907-signup-teacher-enter-name');
      } else if (response.data.next === 'getRoleUNE') {
        this.universityExists = 0;
        this.dispatch(this.Actions.setCurrentScreen('TEACHER_ROLE'));
        this._ga('landing.send', 'pageview', '/1907-signup-teacher-enter-name');
      } else if (response.data.next === 'verificationAwaited') {
        this.dispatch(this.Actions.setCurrentScreen('VERIFICATION_AWAITED'));
        this._ga('landing.send', 'pageview', '/1907-signup-verification-awaited');
      }
    });
    return;
  }

  private setSession = (session: any) => {
    const output = CryptoJS.AES.encrypt(JSON.stringify(session), key);
    window.localStorage.setItem('session', output);
  };

  private tempPasswordSubmitHandler = () =>
    this.update(async () => {
      if (this.tempPasswordField.length < 1) {
        this.tempPasswordError = 'Please fill this field';
        this.focusElement(GetInElements.FORGOT_PASSWORD_INPUT);
        return;
      }
      if (!/[\S]{8,32}/.test(this.tempPasswordField)) {
        this.tempPasswordError = 'One time password must be between 8 to 32 ' + 'characters long';
        this.focusElement(GetInElements.FORGOT_PASSWORD_INPUT);
        return;
      }

      const response = await this.api.verifyTempPass(this.userId, this.tempPasswordField);

      if (response.status >= 500) {
        this.dispatch(
          this.AppActions.showError({
            message: "Our servers didn't respond. Please try after some time.",
          })
        );
        return;
      } else if (response.status >= 400) {
        const { message } = response.data;
        this.tempPasswordError = message || 'Please enter the correct password';
        this.focusElement(GetInElements.FORGOT_PASSWORD_INPUT);
        return;
      }

      if (response.data.next === 'ResetPassword') {
        this.isResettingPassword = true;
      } else {
        this.isResettingPassword = false;
      }

      this.passwordField = '';
      this.passwordError = null;
      this.isTermsDialogVisible = false;
      this.dispatch(this.Actions.setCurrentScreen('SET_PASSWORD'));
    });

  public onRoleDDValueChange(option: Option<_api.TeacherSignupRole> | undefined) {
    return this.update(() => {
      this.roleDD.error = '';
      this.roleDD.value = option;
    });
  }

  public onToggleRoleDD(isOpen: boolean) {
    return this.update(() => {
      this.roleDD.isOpen = isOpen;
    });
  }

  public onOrgDDValueChange(option: Option<_api.TeacherSignupOrganization> | undefined) {
    return this.update(() => {
      this.orgDD.error = '';
      this.orgDD.value = option;

      if (!option) return;

      switch (option.id) {
        case 'university':
        case 'k12':
        case 'vocational':
          this.useCaseDD.options = USE_CASES_OPTIONS_1;
          this.useCaseDD.value = undefined;
          break;
        case 'company':
        case 'nonProfit':
        case 'independent':
          this.useCaseDD.options = USE_CASES_OPTIONS_2;
          this.useCaseDD.value = undefined;
        default:
          break;
      }
    });
  }

  public onToggleOrgDD(isOpen: boolean) {
    return this.update(() => {
      this.orgDD.isOpen = isOpen;
    });
  }

  public onUseCaseDDValueChange(option: Option<_api.TeacherSignupUseCase> | undefined) {
    return this.update(() => {
      this.useCaseDD.error = '';
      this.useCaseDD.value = option;
    });
  }

  public onToggleUseCaseDD(isOpen: boolean) {
    return this.update(() => {
      this.useCaseDD.isOpen = isOpen;
    });
  }

  private teacherSignupSubmitHandler() {
    return this.update(async () => {
      if (this.fullNameField.length === 0) {
        this.fullNameError = 'Please fill this field';
        return;
      }

      let acuid: string | undefined;

      if (this.universityExists) {
        if (!this.roleDD.value) {
          this.roleDD.error = 'Please select an option';
          return;
        }

        const response = await this.api.teacherSignup({
          universityExists: 1,
          emailId: this.emailField,
          name: this.fullNameField,
          website: this.websiteField,
          marketing: this.emailPermission ? 1 : 0,
          role: this.roleDD.value.id,
        });

        acuid = response.data.acuid;

        if (response.data.next === 'changeServer') {
          this._ga('landing.set', 'dimension3', acuid);
          this._ga('landing.send', 'pageview', '/1907-signup-teacher-complete');
          return await this.changeServer(response.data.cluster);
        }
      } else {
        if (!this.orgDD.value) {
          this.orgDD.error = 'Please select an option';
          return;
        }

        if (!this.useCaseDD.value) {
          this.useCaseDD.error = 'Please select an option';
          return;
        }

        const response = await this.api.teacherSignup({
          universityExists: 0,
          emailId: this.emailField,
          name: this.fullNameField,
          website: this.websiteField,
          marketing: this.emailPermission ? 1 : 0,
          organization: this.orgDD.value.id,
          useCase: this.useCaseDD.value.id,
        });

        acuid = response.data.acuid;
      }

      this.setSession(getStore().getState().getIn.session);
      await this.dispatch(this.Actions.setCurrentScreen('THANK_YOU'));
      setTimeout(() => {
        this._ga('landing.set', 'dimension3', acuid);
        this._ga('landing.send', 'pageview', '/1907-signup-teacher-complete');
      }, 0);
    });
  }

  private setPasswordSubmitHandler = (termsAccepted?: boolean) =>
    this.update(async () => {
      this.passwordError = this.getPasswordError(this.passwordField);

      if (this.passwordError !== null) {
        this.focusElement(GetInElements.SET_PASSWORD_INPUT);
        return;
      }

      if (!this.isResettingPassword && !termsAccepted) {
        this.isTermsDialogVisible = true;
        this.termsAcceptChecked = false;
        return;
      }

      const response = await this.api.setPassword(this.userId, this.passwordField);

      if (response.data.next === 'setPasswordWindowExpired') {
        this.dispatch(
          this.AppActions.showError({
            message: 'The window for setting password has expired. Please ' + 'try again.',
            onclose: () => this.dispatch(this.Actions.setCurrentScreen('LOGIN_EMAIL')),
          })
        );
        return;
      }

      if (response.data.next === 'landingPage') {
        const session = this.createSession(response.data.token, response.data.socket);
        this.setSession(session);
        this.dispatch(this.Actions.setSession(session));
        this.Routes.home.navigate({});
      } else if (response.data.next === 'profileNotCompleted') {
        if (this.fullNameField) {
          const name = new UserName(this.fullNameField);
          this.firstNameField = u.toTitleCase(name.firstName);
          this.middleNameField = u.toTitleCase(name.middleName);
          this.lastNameField = u.toTitleCase(name.lastName);
        } else {
          this.firstNameField = '';
          this.middleNameField = '';
          this.lastNameField = '';
        }
        this.firstNameError = null;
        this.lastNameError = null;
        const session = this.createSession(response.data.token, response.data.socket);
        // this.setSession(session);
        this.dispatch(this.Actions.setSession(session));
        this.dispatch(this.Actions.setCurrentScreen('SET_NAME'));
        this._ga('landing.send', 'pageview', '/1907-signup-student-enter-name');
      }
    });

  private async setProfileSubmitHandler() {
    await this.update(async () => {
      const file = this.avatarUploadedFile;
      if (!file) {
        await this.api.setAvatar({
          imageUploaded: false,
          fileName: this.getSelectedAvatar().url.replace(
            'https://s3.amazonaws.com/acadly-test/i/public/',
            ''
          ),
          fileType: 'png',
        });
        this.Routes.home.navigate({});
      } else {
        await this.api.setAvatar({
          imageUploaded: true,
          file: file,
          fileName: file.name,
          fileType: u.splitFileName(file.name).extension,
        });
        this.Routes.home.navigate({});
      }
    });
  }

  private createSession(token: string, socket: { key: string; cluster: string }) {
    const session: IGetInState['session'] = {
      userId: this.userId,
      avatar: this.avatar,
      avatarURL: this.avatarURL,
      canCreateCourses: this.canCreateCourses,
      email: this.email,
      name: this.userName,
      server: this.server,
      token: token,
      socket: socket,
      university: {
        name: this.universityName,
        slug: this.universitySlug,
      },
    };
    return session;
  }

  private async withLoader<T>(promise: Promise<T>): Promise<T> {
    try {
      await this.update(() => {
        this.isLoading = true;
      });
      return await promise;
    } finally {
      await this.update(() => {
        this.isLoading = false;
      });
    }
  }
}
