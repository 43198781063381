import { CSS, h, IComponent, View } from 'core';

import FlatButton from 'acadly/common/FlatButton';
import { getStore } from 'acadly/store';
import { getHTMLTagSelector } from 'acadly/utils';

interface INotificationBarProps {
  actionText?: string;
  action?: () => any;
  actionStyle?: CSS;
  getElem?: (elem: HTMLElement) => any;
  style?: CSS;
  classNames?: string[];
  containerStyle?: CSS;
  tabIndex?: number | undefined;
}
export default (props: INotificationBarProps, children: View[]) =>
  h('div', [
    h(NotificationBar, {
      ...props,
      children,
    }),
  ]);

class NotificationBar extends IComponent<INotificationBarProps & { children: View[] }, never> {
  public render() {
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    const props = this.getProps();

    const notification = () => {
      return h(
        getHTMLTagSelector('div', ['notification', ...(props.classNames || [])]),
        {
          style: props.style,
          tabIndex: isAccessible ? 0 : undefined,
        },
        [
          h('div.notification__message', props.children),
          props.actionText && props.action
            ? FlatButton(props.actionText, {
                ariaLabel: props.actionText,
                type: 'secondary',
                tabIndex: props.tabIndex,
                classNames: ['notification__action'],
                style: props.actionStyle,
                onclick: props.action,
              })
            : null,
        ]
      );
    };

    return h(
      'div.notification__container',
      {
        ref: (elem: HTMLElement) => {
          if (props.getElem) {
            props.getElem(elem);
          }
        },
        style: props.containerStyle,
      },
      [notification()]
    );
  }
}

export const NotificationBarWithoutSpace = (props: INotificationBarProps, children: View[]) =>
  h(NotificationBar, {
    ...props,
    children,
  });
