import { CSS, h, View } from 'core';

import { BasicButton, IBasicButtonProps, IBasicButtonState } from './BasicButton';
import { Loader } from './Loader';

export class LoaderButton extends BasicButton<IBasicButtonProps, IBasicButtonState> {
  private size = 0;

  public componentWillReceiveProps(nextProps: IBasicButtonProps) {
    const { isLoading } = this.getState();
    if (nextProps.isLoading !== undefined && isLoading !== nextProps.isLoading) {
      this.setState({ isLoading: nextProps.isLoading });
    }
  }

  public render() {
    const props = this.getProps();
    const label = props.label instanceof Array ? props.label : [props.label];
    return h(
      `div.loader-button${this.isLoading() ? '.loading' : ''}`,
      {
        ref: (htmlElement?: HTMLElement) => {
          if (htmlElement) {
            const newSize = Math.min(htmlElement.clientHeight, htmlElement.clientWidth) - 6;
            if (newSize !== this.size) {
              this.size = newSize;
              this.setState({});
            }
          }
        },
        tabIndex: props.tabIndex,
        role: 'button',
        onclick: this.clickHandler(),
        style: props.style,
      },
      [
        h('div.loader-button__label', label as any),
        h('div.loader-button__wrapper', [
          Loader({
            height: `${this.size}px`,
            maxHeight: '2em',
            maxWidth: '2em',
            width: `${this.size}px`,
            borderWidth: '3px',
            borderStyle: 'solid',
          }),
        ]),
      ]
    );
  }
}

export default (view: View, styles?: CSS, attrs?: any) =>
  h(LoaderButton, {
    label: view,
    style: styles,
    key: attrs ? attrs.key : undefined,
    tabIndex: (attrs && attrs.tabIndex) || 0,
    isLoading: attrs && attrs.isLoading,
    onclick:
      view && typeof view !== 'string' && view.props.onclick
        ? view.props.onclick
        : attrs
        ? attrs.onclick
        : undefined,
  });
