import { Observable } from 'rxjs/Observable';

import { CSS, h, IComponent, View } from 'core';

import Alert from 'acadly/common/Alert';
import ProgressBar from 'acadly/common/ProgressBar';
import { style } from 'acadly/styles';

export default (props: IUploadButtonProps) => h(UploadButton, props);

export interface IUploadButtonProps {
  view?: View;
  upload?: (file: File) =>
    | {
        promise?: Promise<any>;
        progress$?: Observable<ProgressEvent>;
      }
    | undefined;
  accept?: string;
  disabled?: boolean;
  ariaLabel?: string;
  tabIndex?: number;
  containerStyle?: CSS;
  oncomplete?: () => any;
}
export class UploadButton extends IComponent<
  IUploadButtonProps,
  {
    input: HTMLElement | null;
    progress: number | null;
  }
> {
  public componentWillMount() {
    this.setState({
      input: null,
      progress: null,
    });
  }

  public render() {
    const props = this.getProps();
    const view = this.getProps().view || h('button', 'Upload');
    const btn = h(
      'div.upload-button',
      {
        style: {
          position: 'relative',
        },
      },
      [
        this.progressDialog(),
        h('input', {
          type: 'file',
          accept: this.getProps().accept,
          ref: (elem: HTMLElement) => {
            if (!this.getState().input) {
              this.setState({ input: elem });
            }
          },
          onchange: (event: any) => {
            const upload = this.getProps().upload;
            const oncomplete = this.getProps().oncomplete;
            if (upload && event.target.files[0]) {
              console.log(upload);
              const uploadResult = upload(event.target.files[0]);
              console.log('file', event.target.files[0].type);
              // Clearing value so that oninput is called again if same file is uploaded
              event.target.value = '';
              if (!uploadResult) return;
              const { progress$ } = uploadResult;
              if (progress$) {
                progress$
                  .map((progressEvent) => (progressEvent.loaded * 100) / progressEvent.total)
                  .startWith(0)
                  .subscribe(
                    (progress) => {
                      this.setState({
                        progress: progress,
                      });
                    },
                    () =>
                      this.setState({
                        progress: null,
                      }),
                    () => {
                      this.setState({
                        progress: null,
                      }).then(() => {
                        if (oncomplete) {
                          oncomplete();
                        }
                      });
                    }
                  );
              }
            }
          },
          style: {
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            outline: 'none',
            visibility: 'hidden',
            left: 0,
          },
        }),
        h(
          'div',
          {
            'aria-label': props.ariaLabel,
            tabindex: props.tabIndex,
            onclick: () => {
              if (this.getProps().disabled) return;
              const input = this.getState().input;
              if (input) {
                input.click();
              }
            },
          },
          [view]
        ),
      ]
    );
    return !this.getProps().disabled
      ? h(
          'div.upload-button-container',
          {
            style: {
              ...this.getProps().containerStyle,
            },
          },
          [btn]
        )
      : null;
  }

  private progressDialog() {
    const state = this.getState();
    return Alert(
      {
        open: state.progress !== null,
        center: true,
        title: 'Uploading',
        bodyStyle: {
          padding: '0.5rem 1rem',
          display: 'flex',
          alignItems: 'center',
        },
        style: {
          width: '20em',
        },
      },
      [
        ProgressBar(state.progress || 0, {
          style: { height: '0.3rem' },
        }),
        h(
          'div.progress-percentage',
          style([
            'textCenter',
            {
              width: '5rem',
            },
          ]),
          `${Math.round((state.progress || 0) * 100) / 100}%`
        ),
      ]
    );
  }
}
