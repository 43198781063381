import { h, IComponent } from 'core';

import { Actions as appActions } from 'acadly/app/actions';
import { Actions as ClassActions, IExportGradesSuccessResponse } from 'acadly/class/actions';
import Alert from 'acadly/common/Alert';
import ContentView from 'acadly/common/ContentView';
import FlatButton from 'acadly/common/FlatButton';
import { Loader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import RaisedButton from 'acadly/common/RaisedButton';
import StudentList, { getStudentListData } from 'acadly/common/StudentList';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import User from 'acadly/common/User';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import { dispatch, getStore } from 'acadly/store';
import { colors, ml, pad, style } from 'acadly/styles';
import * as u from 'acadly/utils';

import Icon from '../common/Icon';
import icons from '../icons';
import { Actions } from './actions';

export interface IPollAnalyticsState {
  isExportDialogOpen: boolean;
  exportDownloadLink: string;
  exportRequestIsEmail: 0 | 1;
  sendEmail: boolean;
  exportFileName: string;
  isDownloadAlertOpen: boolean;
  searchField: string;
  sortStudentBy: StudentSortBy;
}

export interface IPollAnalyticsProps {
  poll: IPoll;
}
export class PollAnalytics extends IComponent<IPollAnalyticsProps, IPollAnalyticsState> {
  private isAccessible: boolean;
  public componentWillMount() {
    const state = getStore().getState();
    const initialState: IPollAnalyticsState = {
      isExportDialogOpen: false,
      exportDownloadLink: '',
      exportRequestIsEmail: 0,
      sendEmail: false,
      exportFileName: '',
      isDownloadAlertOpen: false,
      searchField: '',
      sortStudentBy: state.app.sortStudentBy,
    };
    this.isAccessible = state.app.acc.web.turnOff === 0;
    this.setState(initialState);
    const poll = this.getProps().poll;
    dispatch(Actions.analyticsFetch(poll));
    dispatch(appActions.startTip(true));
  }

  public render() {
    const role = courseService.getRole();
    return ContentView(h('div.analytics__content', role !== 'student' ? this.body() : []));
  }

  private downloadAlert() {
    const isOpen = this.getState().isDownloadAlertOpen;
    const fileName = this.getState().exportFileName;
    const downloadLink = this.getState().exportDownloadLink;
    const styles = {
      display: 'flex',
      width: '20em',
      borderRadius: '4px',
      backgroundColor: 'white',
      alignItems: 'center',
      border: `1px solid ${colors.lightestGrey}`,
      maxWidth: '100%',
      lineHeight: '1.5em',
      boxSizing: 'border-box',
      paddingRight: '0.5rem',
    };

    return Alert(
      {
        title: 'Success',
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('Done', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.backClickHandler(),
          }),
        ],
      },
      [
        h('div', style([pad('0.5rem 0.5rem 0.5rem 0rem')]), 'File Generated'),
        h(
          'div.acadly-attachment',
          {
            style: styles,
          },
          [
            h(
              'div.attachment-icon',
              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '0.5rem',
                  // fontSize: "0.8em",
                  width: '2.5rem',
                  color: 'white',
                  height: '2.5rem',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  backgroundColor: colors.green,
                },
              },
              'CSV'
            ),
            h(
              'div',
              style([
                'thin',
                {
                  flex: 1,
                  color: colors.black,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: '0.5em',
                },
              ]),
              [fileName]
            ),
            h(
              'a',
              {
                style: {
                  color: colors.green,
                },
                target: '_blank',
                href: downloadLink || undefined,
              },
              [Icon(icons.download, style(['blue', 'large', 'pointer', pad('0.5rem')]))]
            ),
          ]
        ),
      ]
    );
  }

  private exportDialog() {
    const isOpen = this.getState().isExportDialogOpen;
    const isEmail = this.getState().exportRequestIsEmail;
    const sendEmail = this.getState().sendEmail;
    const option = (opts: {
      selected: boolean;
      label: string;
      key: string;
      tabIndex?: number | undefined;
      onclick: () => any;
    }) =>
      h(
        'div',
        style(
          ['flex', 'alignCenter'],
          {},
          {
            onclick: opts.onclick,
            key: opts.key,
            tabIndex: opts.tabIndex,
          }
        ),
        [
          RadioButton({
            selected: opts.selected,
            color: colors.teal,
          }),
          h(
            'div',
            style([
              pad('0.5rem 1rem'),
              ml('0.5rem'),
              {
                flex: '1',
                borderBottom: '1px solid lightGrey',
              },
            ]),
            opts.label
          ),
        ]
      );
    return Alert(
      {
        title: !sendEmail ? 'Exporting Poll Data' : 'Success',
        titleStyle: {
          textAlign: 'center',
        },
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton(!sendEmail ? 'Cancel' : 'Download a copy', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: !sendEmail
              ? () => this.backClickHandler()
              : () => this.downloadACopyClickHandler(),
          }),
          FlatButton('Done', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: !sendEmail ? () => this.exportClickHandler() : () => this.backClickHandler(),
          }),
        ],
      },
      [
        sendEmail
          ? h(
              'div',
              { tabIndex: this.isAccessible ? 0 : undefined },
              'The exported file has been sent to your registered' +
                " email address. In case you can't spot it, please" +
                ' look into the Spam folder'
            )
          : h('div', {}, [
              option({
                label: 'Download CSV file now',
                selected: isEmail === 0 ? true : false,
                key: 'no-email',
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 0,
                  }),
              }),
              option({
                label: 'Email file as attachment',
                selected: isEmail === 1 ? true : false,
                key: 'email',
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 1,
                  }),
              }),
            ]),
      ]
    );
  }

  private async downloadACopyClickHandler() {
    await this.setState({
      isDownloadAlertOpen: true,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }
  private async backClickHandler() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    await this.setState({
      isDownloadAlertOpen: false,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }
  private async exportClickHandler() {
    const email = this.getState().exportRequestIsEmail;

    const response = await dispatch(
      ClassActions.exportGrades({
        activityType: 'polls',
        activityId: this.getProps().poll._id,
        email: email,
      })
    );
    this.setFileData(response);
    if (email === 1) {
      await this.setState({
        sendEmail: true,
      });
    } else {
      await this.setState({
        isExportDialogOpen: false,
        isDownloadAlertOpen: true,
      });
    }
  }

  private async setFileData(response: IExportGradesSuccessResponse) {
    await this.setState({
      exportDownloadLink: response.url,
      exportFileName: response.filename,
    });
  }
  private lastFocusedElement: Element | null;
  private async openExportDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isExportDialogOpen: true,
    });
  }

  private body() {
    const { searchField, sortStudentBy } = this.getState();
    const poll = this.getProps().poll;
    const submissions = getStore().getState().polls.analytics;

    const renderStudent = (s: IPollAnalyticsStudent) => {
      if (!s.stats.submission) {
        throw new Error('Student without submission object.');
      }

      const optionSelected = u.indexToAlphabet(
        s.stats.submission[Object.keys(s.stats.submission)[0]].optionSelected - 1
      );

      const status = s.stats.status === 'submitted' ? ' | On Time' : ' | Late';

      return User(
        {
          avatar: {
            url: s.avatar,
            creator: s.name,
          },
          title: s.name,
          titleClassNames: ['fc-green'],
          subtitle: h(
            u.getHTMLTagSelector('div', [
              'poll-analytics__response',
              s.stats.status === 'submitted' ? 'fc-orange' : 'fc-red',
            ]),
            [
              h('span', `Option selected: ${optionSelected}${status}`),
              h(
                'span',
                `First accessed on: ${dt.format(
                  dt.fromUnix(s.stats.firstAccessedOn),
                  'MMM-DD-YYYY'
                )}`
              ),
            ]
          ),
        },
        {
          className: 'student',
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `${s.name} has first accessed this poll on: ${dt.format(
            dt.fromUnix(s.stats.firstAccessedOn),
            'MMM Do YYYY'
          )} and selected option ${optionSelected} ${status.split(' | ')[1]}`,
        }
      );
    };

    const infoCell = (label: string, value?: string) =>
      h(
        'div.cell.cell--full-width',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [h('span.fs-lg.fc-dark-blue', label), h('span.fc-light-grey.capitalize', value)]
      );

    return [
      infoCell('Total Submissions', poll.stats.numSubmitted.toString()),
      infoCell('Poll type', poll.details.isAnon ? 'Anonymous' : 'Non Anonymous'),

      poll.stats.numSubmitted === 0 || poll.details.isAnon
        ? null
        : RaisedButton(
            [
              Icon(icons.export2, { className: 'analytics__export__icon' }),
              h('span.analytics__export__label', 'Export poll data as CSV'),
            ],
            {
              tabIndex: 0,
              id: 'export-poll-button',
              ariaLabel: 'Export poll data as CSV',
              onclick: () => this.openExportDialog(),
              classNames: ['analytics__export'],
            }
          ),

      h('div.analytics__header', 'Participation'),
      h('div.analytics__sub-header', `As of ${dt.format(dt.now(), 'MMM Do YYYY')}`),

      poll.stats.numSubmitted === 0 || poll.details.isAnon
        ? h('div.fc-light-grey', [
            'Once the students respond to the poll, ',
            'the identity of responders and their ',
            'choices will show up here. This data is ',
            "only available for polls that don't allow ",
            'anonymous responses.',
          ])
        : submissions
        ? StudentList({
            sortBy: sortStudentBy,
            style: { paddingBottom: '2rem' },
            children: getStudentListData(
              submissions.students.filter((s) => s.stats.submission),
              searchField,
              sortStudentBy,
              (s) => s.name
            ).map(renderStudent),
            onSearch: (term) => {
              this.setState({
                searchField: term,
              });
            },
            onSort: (sortBy) => {
              this.setState({
                sortStudentBy: sortBy,
              });
            },
          })
        : Loader(),
      this.exportDialog(),
      this.downloadAlert(),
      poll.stats.numSubmitted !== 0 || !poll.details.isAnon
        ? TipOverlayWrapper({
            targetElement: 'export-poll-button',
            tip: {
              tipPosition: 'bottom',
              tipText:
                'You can export the students’ scores and participation' +
                ' in this activity, in a CSV format by using this button',
            },
            tipKey: 'activityAnalyticsExport',
            isNextAvailable: false,
          })
        : null,
    ];
  }
}

export default (poll: IPoll) => h(PollAnalytics, { poll });
