import { toUnix } from 'acadly/datetime';
import { getStore } from 'acadly/store';

export function getAttendanceTime() {
  const state = getStore().getState();
  const { attendanceData } = state.app;
  const classAttendance = state.class.data?.attendance;

  if (attendanceData) return attendanceData.attendanceTime;

  return classAttendance?.attendanceTime ?? 0;
}

interface AttendanceTimerOptions {
  attendanceTime: number | (() => number);
  onTick: (secondsLeft: number) => void;
  /** timer timed out */
  onTimeout?: () => void;
}

export const ATTENDANCE_TIME_OUT = 150; // in seconds

export function AttendanceTimer({ attendanceTime, onTimeout, onTick }: AttendanceTimerOptions) {
  let timer: undefined | NodeJS.Timer = undefined;
  let isRunning = false;

  const getStartTime = typeof attendanceTime === 'function' ? attendanceTime : () => attendanceTime;

  const stop = () => {
    if (timer) {
      clearInterval(timer);
      timer = undefined;
      isRunning = false;
    }
  };

  const getNow = () => toUnix(new Date());
  const getEndTime = () => getStartTime() + ATTENDANCE_TIME_OUT;

  const getSecondsLeft = () => getEndTime() - getNow();

  const start = () => {
    stop();

    const secondsLeft = getSecondsLeft();

    if (secondsLeft < 1) return;

    isRunning = true;
    onTick(secondsLeft);

    timer = setInterval(() => {
      const secondsLeft = getSecondsLeft();

      if (secondsLeft < 0) {
        stop();
        onTimeout?.();
        return;
      }

      onTick(secondsLeft);
    }, 1000);
  };

  return {
    stop,
    start,
    get isRunning() {
      return isRunning;
    },
  };
}
