import { CSS, h, IComponent, View } from 'core';

import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import { Icon } from 'acadly/common/Icon';
import TextField from 'acadly/common/TextField';
import icons from 'acadly/icons';
import { getStore } from 'acadly/store';
import { colors, ml, mr, mt, pad, style } from 'acadly/styles';
import * as u from 'acadly/utils';

export interface ITimePickerProps {
  time: ITime;
  renderTime?: (time: ITime) => View;
  onChange: (time: ITime) => any;
  style?: CSS;
  icon?: View;
  onclick?: () => void;
  onclose?: () => any;
}

export interface ITimePickerState {
  isEditing: boolean;
  hoursField: number;
  minutesField: number;
  ampmField: 'am' | 'pm';
}

export class TimePicker extends IComponent<ITimePickerProps, ITimePickerState> {
  private isAccessible: boolean;
  public componentWillMount() {
    this.setState({
      isEditing: false,
      hoursField: 10,
      minutesField: 0,
      ampmField: 'am',
    });
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
  }

  public render() {
    const clockIconStyle = {
      marginLeft: '1rem',
      fontSize: '1.3em',
      color: colors.blue,
    };
    const props = this.getProps();
    return h(
      'div.timepicker',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        style: {
          ...style(['pointer', 'flex', 'alignCenter']).style,
          ...props.style,
        },
        onclick: () => this.openEditDialog(),
      },
      [
        props.renderTime ? props.renderTime(props.time) : this.renderTime(),
        this.editDialog(),
        props.icon || Icon(icons.clock, { style: clockIconStyle }),
      ]
    );
  }

  private editDialog() {
    const state = this.getState();
    const inputStyle: CSS = {
      color: colors.blue,
      textAlign: 'center',
    };
    const textFieldStyle = {
      width: '2em',
    };

    const arrowAttrs = (onclick?: () => any, otherStyles: CSS = {}) =>
      style(
        [
          'large',
          'blue',
          pad('0.5em'),
          'borderBox',
          'flex',
          'justifyCenter',
          {
            width: '100%',
          },
          otherStyles,
        ],
        {},
        {
          onclick: onclick,
        }
      );
    const editorWrapperAttrs = style([
      'flex',
      'column',
      'alignCenter',
      'spaceBetween',
      {
        height: '8em',
        width: '3em',
        outline: 'none',
      },
    ]);

    const DOWN_ARROW_KEYCODE = 40;
    const UP_ARROW_KEYCODE = 38;

    const validHours = state.hoursField >= 1 && state.hoursField <= 12;
    const validMinutes = state.minutesField >= 0 && state.minutesField <= 59;
    return Alert(
      {
        open: state.isEditing,
        center: true,
        hasInput: true,
        actions: [
          FlatButton('Cancel', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              const onclose = this.getProps().onclose;
              onclose ? onclose() : null;
              this.setState({
                isEditing: false,
              });
            },
          }),
          FlatButton('Ok', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.setTime(),
            disabled: !validHours || !validMinutes,
          }),
        ],
        style: {
          maxWidth: '20em',
        },
        bodyStyle: style(['flex', 'justifyCenter', 'alignCenter', 'blue']).style,
      },
      [
        h(
          'div.hour-editor',
          {
            ...editorWrapperAttrs,
            tabIndex: 1,
            onkeydown: (event: KeyboardEvent) => {
              if (event.keyCode === UP_ARROW_KEYCODE) {
                this.incrementHoursField();
              } else if (event.keyCode === DOWN_ARROW_KEYCODE) {
                this.decrementHoursField();
              }
            },
          },
          [
            h(
              'div.ripple',
              arrowAttrs(() => this.incrementHoursField()),
              [h('i.fa.fa-angle-up', [])]
            ),
            TextField({
              focusOnMount: true,
              value: state.hoursField.toString(),
              type: 'number',
              min: 1,
              max: 12,
              oninput: (e) =>
                this.setState({
                  hoursField: parseInt(e.target.value),
                }),
              noHintOrError: true,
              hideUnderline: true,
              style: textFieldStyle,
              inputStyle: inputStyle,
            }),
            h(
              'div.ripple',
              arrowAttrs(() => this.decrementHoursField()),
              [h('i.fa.fa-angle-down', [])]
            ),
          ]
        ),
        h('div.colon', style([ml('0.5em'), mr('0.5em'), mt('-0.3em')]), ':'),

        h(
          'div.minute-editor',
          {
            ...editorWrapperAttrs,
            tabIndex: 2,
            onkeydown: (event: KeyboardEvent) => {
              if (event.keyCode === UP_ARROW_KEYCODE) {
                this.incrementMinutesField();
              } else if (event.keyCode === DOWN_ARROW_KEYCODE) {
                this.decrementMinutesField();
              }
            },
          },
          [
            h(
              'div.ripple.minute-increment-button',
              arrowAttrs(() => this.incrementMinutesField()),
              [h('i.fa.fa-angle-up', [])]
            ),
            TextField({
              value: state.minutesField.toString(),
              type: 'number',
              min: 0,
              max: 59,
              oninput: (e) =>
                this.setState({
                  minutesField: parseInt(e.target.value),
                }),
              noHintOrError: true,
              style: textFieldStyle,
              inputStyle: inputStyle,
              hideUnderline: true,
            }),
            h(
              'div.ripple.minute-decrement-button',
              arrowAttrs(() => this.decrementMinutesField()),
              [h('i.fa.fa-angle-down', [])]
            ),
          ]
        ),

        h(
          'div.am-pm-editor',
          {
            ...editorWrapperAttrs,
            tabIndex: 3,
            onkeydown: (event: KeyboardEvent) => {
              if (event.keyCode === UP_ARROW_KEYCODE) {
                this.toggleAMPM();
              } else if (event.keyCode === DOWN_ARROW_KEYCODE) {
                this.toggleAMPM();
              }
            },
          },
          [
            h(
              'div.ripple.minute-decrement-button',
              arrowAttrs(() => this.toggleAMPM()),
              [h('i.fa.fa-angle-up', [])]
            ),
            TextField({
              value: state.ampmField.toUpperCase(),
              // oninput: (e) => this.setState({
              //     hoursField: parseInt(e.target.value)
              // }),
              oninput: () => this.toggleAMPM(),
              noHintOrError: true,
              hideUnderline: true,
              style: textFieldStyle,
              inputStyle: inputStyle,
              onkeydown: (event: KeyboardEvent) => {
                if (event.keyCode === UP_ARROW_KEYCODE) {
                  event.stopPropagation();
                  this.toggleAMPM();
                } else if (event.keyCode === DOWN_ARROW_KEYCODE) {
                  event.stopPropagation();
                  this.toggleAMPM();
                }
              },
            }),
            // state.ampmField.toUpperCase(),
            h(
              'div.ripple.minute-decrement-button',
              arrowAttrs(() => this.toggleAMPM()),
              [h('i.fa.fa-angle-down', [])]
            ),
          ]
        ),
      ]
    );
  }

  private async toggleAMPM() {
    const state = this.getState();
    await this.setState({
      ampmField: state.ampmField === 'am' ? 'pm' : 'am',
    });
  }

  private async incrementMinutesField() {
    const state = this.getState();
    let newMinutes = state.minutesField + 1;
    if (newMinutes > 59) {
      newMinutes = 1;
    }
    if (isNaN(newMinutes)) {
      newMinutes = 0;
    }
    await this.setState({
      minutesField: newMinutes,
    });
  }

  private async decrementMinutesField() {
    const state = this.getState();
    let newMinutes = state.minutesField - 1;
    if (newMinutes < 0) {
      newMinutes = 59;
    }
    if (isNaN(newMinutes)) {
      newMinutes = 0;
    }
    await this.setState({
      minutesField: newMinutes,
    });
  }

  private async incrementHoursField() {
    const state = this.getState();
    let newHoursField: number;
    if (state.hoursField === 11) {
      newHoursField = 12;
    } else if (state.hoursField === 12) {
      newHoursField = 1;
    } else {
      newHoursField = state.hoursField + 1;
    }
    if (isNaN(newHoursField)) {
      newHoursField = 12;
    }
    await this.setState({
      hoursField: newHoursField,
    });
  }

  private async decrementHoursField() {
    const state = this.getState();
    let newHours: number;
    if (state.hoursField === 1) {
      newHours = 12;
    } else {
      newHours = state.hoursField - 1;
    }
    if (isNaN(newHours)) {
      newHours = 12;
    }
    await this.setState({
      hoursField: newHours,
    });
  }

  private async setTime() {
    const state = this.getState();
    const minutes = state.minutesField;
    let hours;
    if (state.ampmField === 'am') {
      if (state.hoursField === 12) {
        hours = 0;
      } else {
        hours = state.hoursField;
      }
    } else {
      if (state.hoursField === 12) {
        hours = state.hoursField;
      } else {
        hours = state.hoursField + 12;
      }
    }
    await this.getProps().onChange({
      hours,
      minutes,
    });
    await this.setState({
      isEditing: false,
    });
  }
  private async openEditDialog() {
    const props = this.getProps();
    props.onclick ? props.onclick() : null;
    await this.setState({
      isEditing: true,
      hoursField: this.gethh(props.time.hours),
      minutesField: props.time.minutes,
      ampmField: this.getAMPM(props.time.hours),
    });
  }

  private renderTime() {
    const props = this.getProps();
    return u.showTime(props.time);
  }

  private gethh(hours: number) {
    if (hours === 0) {
      return 12;
    } else if (hours > 12) {
      return hours - 12;
    } else {
      return hours;
    }
  }

  private getAMPM(hours: number): 'am' | 'pm' {
    if (hours >= 12) {
      return 'pm';
    } else {
      return 'am';
    }
  }
}
export default (props: ITimePickerProps) => h(TimePicker, props);
