import IService from '../IService';
import { Routes } from '../routes';
import * as classFunctions from './functions';

class ClassService extends IService {
  public getCurrentClass() {
    const classId = this.getCurrentClassId();
    if (classId) {
      if (!this.state.courses.timeline) return null;
      return this.state.courses.timeline.items.find((item) => item._id === classId) as IClass;
    } else {
      return null;
    }
  }

  public getCurrentClassId(): string | null {
    const match = Routes.class.getMatch();
    if (match) {
      const classId = this.getClassIdFromShortId(match.classShortId);
      return classId;
    } else {
      return null;
    }
  }

  public get contextId() {
    return this.getCurrentClassId()!;
  }

  constructor() {
    super();
  }

  public getClassIdFromShortId(shortId: string) {
    const timeline = this.state.courses.timeline;
    if (!timeline) return null;
    const cls = timeline.items.filter((cls) => cls._id.slice(18) === shortId)[0] as IClass;
    if (cls) {
      return cls._id;
    } else {
      return null;
    }
  }

  public getShortIdFromClassId(classId: string) {
    return classId.slice(18);
  }

  public get totalComments(): number {
    const cls = this.getCurrentClass();
    if (!cls) return 0;
    return classFunctions.getTotalComments(cls);
  }

  public get seenComments(): number {
    const cls = this.getCurrentClass();
    if (!cls) return 0;
    return this.totalComments - classFunctions.getUnseenComments(cls);
  }

  public getClassById(classId: string): IClass | null {
    const timeline = this.state.courses.timeline;
    if (!timeline) return null;
    return (timeline.items.filter((cls) => cls._id === classId)[0] as IClass) || null;
  }

  public getRole(classId?: string): IClassTeamRole {
    const session = this.state.getIn.session;
    const id = classId || this.getCurrentClassId();

    if (!id) return 'none';

    const cls = this.getClassById(id);

    if (!cls || !session) return 'none';

    const team = cls.info.team;
    if (team.inCharge.userId === session.userId) {
      return 'in-charge';
    } else if (team.assistants[0] && team.assistants[0].userId === session.userId) {
      return 'assistant';
    }

    return 'none';
  }
}

export default new ClassService();
