import { h, IComponent } from 'core';

import * as BulbIcon from 'assets/bulb.svg';

import QuickStartTips from 'acadly/common/QuickStartTips';
import SvgIcon from 'acadly/common/SvgIcon';

class CourseInfoStartTips extends IComponent<never, never> {
  public render() {
    return QuickStartTips({
      qstKey: 'qst-course-info',
      children: ({ wrapper }) => wrapper(this.body()),
    });
  }

  private body = () => {
    return h('div.quick-start-tips__body', [
      h('div.quick-start-tips__section-heading', 'Info'),
      h('p.quick-start-tips__section-content', [
        'This is the Course Info section - it shows the necessary course ',
        'details like the course dates, a short description of the course, ',
        'the course team members etc.',
      ]),
      h('p.quick-start-tips__section-content', [
        'You can share the course reading material too using ',
        'the “Reading List” section.',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'Collaborative teaching',
      ]),
      h('p.quick-start-tips__section-content', [
        'You can invite Co-instructors and Teaching Assistants to ',
        'collaborate by using the “Course Team” section.',
      ]),
      h('p.quick-start-tips__section-content', [
        'Co-instructors can be made in-charge of individual ',
        'Classes (lectures) and Teaching Assistants can help ',
        'you record attendance and answer student queries.',
      ]),
    ]);
  };
}

export default () => h(CourseInfoStartTips);
