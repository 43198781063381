import { createAction } from 'acadly/createAction';

interface IShowClassMediaPlayerPayload {
  type: ClassMediaPlayerType;
  embedTargetSelector: string;
  courseId: string;
  classId: string;
  url: string;
  title: string;
  fileName: string;
  recordingId: string;
}

export type IPipContainerActionMap = {
  '@pip/show_class_media_player': IShowClassMediaPlayerPayload;
  '@pip/hide_class_media_player': { show: false };
};

export const PipContainerActions = {
  showClassMediaPlayer: createAction('@pip/show_class_media_player'),
  hideClassMediaPlayer: createAction('@pip/hide_class_media_player'),
};
