import { h, IComponent } from 'core';

import { Actions as ClassActions } from 'acadly/class/actions';
import ActivityPrefsDialog from 'acadly/common/ActivityPrefsDialog';
import RadioCell from 'acadly/common/RadioCell';
import ToggleCell from 'acadly/common/ToggleCell';
import * as datetime from 'acadly/datetime';
import { Actions as DiscussionActions } from 'acadly/discussion/actions';
import { dispatch, getStore } from 'acadly/store';
import { withTabIndex } from 'acadly/utils';

interface IDiscussionPrefsDialogProps {
  open: boolean;
  course: ICourse;
  cls: IClass;
  discussion: IDiscussion;
  isPublishing: boolean;
  onClose: () => void;
  onPublish: () => Promise<void> | void;
}

interface IDiscussionPrefsDialogState extends IDiscussionPublishPrefs {
  saveAsDefault: 0 | 1;
}

const DATE_FORMAT = 'hh:mm A, MMM DD, YYYY';

class DiscussionPrefsDialog extends IComponent<
  IDiscussionPrefsDialogProps,
  IDiscussionPrefsDialogState
> {
  private get publishDefaults() {
    return getStore().getState().discussions.publishDefaults;
  }

  private init() {
    const { course } = this.getProps();
    const publishDefaults = this.publishDefaults;

    const initialState: IDiscussionPrefsDialogState = {
      anonymity: 'all',
      anonymize: 0,
      hideAwards: 0,
      saveAsDefault: 0,
      submitFirst: 0,
      subscribeToComments: 0,
      ...publishDefaults,
    };

    if (!course.isPro) {
      initialState.anonymize = 0;
      initialState.anonymity = 'all';
    }

    this.setState(initialState);
  }

  public componentWillMount() {
    this.init();
  }

  public componentDidUpdate(lastProps: IDiscussionPrefsDialogProps) {
    const currentProps = this.getProps();

    if (!lastProps.open && currentProps.open) {
      this.init();
    }
  }

  private handleSave = async () => {
    const { discussion, cls, onClose } = this.getProps();
    const { saveAsDefault, anonymity, anonymize, hideAwards, submitFirst, subscribeToComments } =
      this.getState();

    await dispatch(
      ClassActions.saveActivityPublishPrefs({
        discussionPref: {
          anonymity,
          anonymize,
          hideAwards,
          submitFirst,
          subscribeToComments,
        },
        activityType: 'discussion',
        classId: cls._id,
        activityId: discussion._id,
        saveAsDefault,
      })
    );

    onClose();
  };

  private handlePublish = async () => {
    const { discussion, cls, onPublish } = this.getProps();
    const { saveAsDefault, anonymity, anonymize, hideAwards, submitFirst, subscribeToComments } =
      this.getState();

    await dispatch(
      DiscussionActions.publish({
        classId: cls._id,
        toBeDone: discussion.details.toBeDone,
        discussionId: discussion._id,
        saveAsDefault,
        anonymity,
        anonymize,
        hideAwards,
        submitFirst,
        subscribeToComments,
      })
    );

    onPublish();
  };

  public warnings() {
    const { isPublishing } = this.getProps();

    if (!isPublishing) return null;

    return h('div', [
      h('div.activity-prefs__title', withTabIndex(), 'WARNINGS'),
      h('div.activity-prefs__warning', withTabIndex(), [
        h('i.fa.fa-exclamation-triangle'),
        h('div', [
          'The course participants will be notified instantly via ',
          'email and push notifications.',
        ]),
      ]),
      h('div.activity-prefs__warning', withTabIndex(), [
        h('i.fa.fa-exclamation-triangle'),
        h('div', 'Once published, the activity cannot be deleted'),
      ]),
    ]);
  }

  private getHideAwardsWarningText() {
    const { hideAwards } = this.getState();
    return hideAwards
      ? `Only course team members and the recipient will see the points rewarded`
      : `Any reward points awarded to a discussion post will be visible to all`;
  }

  public render() {
    const { cls, course, discussion, open, isPublishing, onClose } = this.getProps();

    const { submitFirst, hideAwards, anonymize, anonymity, subscribeToComments, saveAsDefault } =
      this.getState();

    return ActivityPrefsDialog({
      open,
      onClose,
      isPublishing,
      onSave: this.handleSave,
      onPublish: this.handlePublish,
      title: 'Discussion preferences',
      body: [
        h('div.activity-prefs__title', withTabIndex(), 'DETAILS'),
        h('div.cell', withTabIndex(), [
          h('span', 'To be done'),
          h(
            'span',
            {
              preClass: 'Pre-class',
              inClass: 'In-class',
            }[discussion.details.toBeDone]
          ),
        ]),

        h('div.cell', withTabIndex(), [
          h('span', 'Class'),
          h('span', datetime.format(cls.details.scheStartTime, DATE_FORMAT)),
        ]),

        h('div.activity-prefs__title', withTabIndex(), 'OPTIONS'),

        ToggleCell({
          label: 'Contribute to consume',
          isSelected: submitFirst === 1,
          onToggle: () =>
            this.setState({
              submitFirst: submitFirst === 1 ? 0 : 1,
            }),
          helperText:
            'If toggled, students will have to post a comment to the discussion ' +
            'before they can read other posts in the discussion',
        }),

        ToggleCell({
          label: 'Hide awards',
          isSelected: hideAwards === 1,
          onToggle: () =>
            this.setState({
              hideAwards: hideAwards === 1 ? 0 : 1,
            }),
          helperText: this.getHideAwardsWarningText(),
        }),

        h(
          'div',
          {
            className: !course.isPro ? 'fc-grey' : undefined,
          },
          [
            ToggleCell({
              label: 'Anonymize Responses',
              isSelected: anonymize === 1,
              disabled: !course.isPro,
              onToggle: () =>
                this.setState({
                  anonymize: anonymize === 1 ? 0 : 1,
                }),
            }),
            RadioCell({
              label: 'For students only',
              disabled: !course.isPro || anonymize === 0,
              isSelected: anonymize === 1 && anonymity === 'students',
              onClick: () => this.setState({ anonymity: 'students' }),
            }),
            RadioCell({
              label: 'For everyone',
              disabled: !course.isPro || anonymize === 0,
              isSelected: anonymize === 1 && anonymity === 'all',
              onClick: () => this.setState({ anonymity: 'all' }),
            }),
          ]
        ),
        !course.isPro
          ? h('div.toggle-cell__helper-text.toggle-cell__helper-text_danger', [
              'Anonymization is available only for Acadly Pro courses',
            ])
          : anonymize === 0
          ? h('div.toggle-cell__helper-text', "Comment author's identity will be visible to all")
          : anonymity === 'all'
          ? h(
              'div.toggle-cell__helper-text',
              'Neither students nor instructors will be able to identify the author'
            )
          : anonymity === 'students'
          ? h(
              'div.toggle-cell__helper-text',
              "Students won't be able to identify the comment author"
            )
          : null,

        ToggleCell({
          label: 'Subscribe to comment notifications',
          isSelected: subscribeToComments === 1,
          onToggle: () =>
            this.setState({
              subscribeToComments: subscribeToComments === 1 ? 0 : 1,
            }),
          helperText:
            'If you subscribe, you will receive a notification ' +
            "whenever someone adds a comment to this activity's discussion",
        }),

        ToggleCell({
          label: 'Save as default',
          isSelected: saveAsDefault === 1,
          onToggle: () =>
            this.setState({
              saveAsDefault: saveAsDefault === 1 ? 0 : 1,
            }),
          helperText: `Use same publish preferences as default for all ${
            {
              preClass: 'pre-class',
              inClass: 'in-class',
            }[discussion.details.toBeDone]
          } discussions`,
        }),

        this.warnings(),
      ],
    });
  }
}

export default (props: IDiscussionPrefsDialogProps) => h(DiscussionPrefsDialog, props);
