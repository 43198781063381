const PASSWORD_LENGTH_MESSAGE = 'Your password must be between 8 and 32 characters long.';

/**
 * Validates a password. Returns an error message if
 * a password is invalid. Returns null otherwise
 * @param password Password to be validated
 */
export function getPasswordError(password: string) {
  if (password.length >= 8 && password.length <= 32) {
    return null;
  } else {
    return PASSWORD_LENGTH_MESSAGE;
  }
}
