import { h, HTMLAttrs, IComponent } from 'core';

import { Actions as AppActions } from 'acadly/app/actions';
import portal from 'acadly/common/Portal';
import { dispatch, getStore } from 'acadly/store';
import { randomString, sleep } from 'acadly/utils';

const TOAST_HEIGHT = 40;
const TOAST_SPACING = 20;
const ENTER_DELAY = 250;
const LEAVE_DELAY = 250;
const SHOW_DELAY = 6e3;

interface ToastProps extends HTMLAttrs, IToast {
  position: number;
  onClose: (toastId: string) => void;
}

interface ToastState {
  visibility: 'entering' | 'entered' | 'leaving' | 'left';
}

class ToastComponent extends IComponent<ToastProps, ToastState> {
  private async init() {
    const { toastId, onClose } = this.getProps();
    const initialState: ToastState = { visibility: 'entering' };
    this.setState(initialState);
    await sleep(ENTER_DELAY);

    this.setState({ visibility: 'entered' });
    await sleep(SHOW_DELAY);

    this.setState({ visibility: 'leaving' });
    await sleep(LEAVE_DELAY);

    onClose(toastId);
    this.setState({ visibility: 'left' });
  }

  public async componentWillMount() {
    this.init();
  }

  public render() {
    const { message, toastId, position } = this.getProps();
    const { visibility } = this.getState();

    if (visibility === 'left') {
      return null;
    }

    return h(
      'div.toast',
      {
        id: toastId,
        role: 'alert',
        'aria-live': 'assertive',
        'aria-atomic': true,
        className: visibility,
        style: {
          bottom: position * (TOAST_HEIGHT + TOAST_SPACING) + TOAST_SPACING,
        },
      },
      [h('div.toast__message', message)]
    );
  }
}

const Toast = (props: ToastProps) => h(ToastComponent, props);

@portal(document.getElementById('toast-wrapper'))
class ToastWrapper extends IComponent<never, never> {
  public render() {
    const toasts = getStore().getState().app.toasts;
    return h(
      'div',
      toasts.map((t, i) => {
        return Toast({
          ...t,
          position: i,
          key: t.toastId,
          onClose: (toastId) => ToastManager.hide(toastId),
        });
      })
    );
  }
}

const ToastManager = {
  show: (message: string) => {
    const toast: IToast = { toastId: randomString(), message };
    dispatch(AppActions.showToast(toast));
  },
  hide: (toastId: string) => {
    dispatch(AppActions.hideToast(toastId));
  },
  container: () => h(ToastWrapper),
};

export default ToastManager;
