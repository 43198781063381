import { CSS, h } from 'core';

import { colors, style } from 'acadly/styles';

interface ICheckBoxProps {
  style?: CSS;
  selected?: boolean;
  disabled?: boolean;
  onclick?: (e: MouseEvent) => any;
  color?: string;
  className?: string;
}
export default (props: ICheckBoxProps) => {
  const color = props.disabled ? colors.lightGrey : props.color || colors.blue;

  return h(
    'span',
    style(
      [
        {
          border: '2px solid',
          color: color,
          fontSize: '1.2em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '1.25rem',
          height: '1.25rem',
          padding: '2px',
        },
      ],
      props.style,
      {
        className: props.className,
        onclick: props.disabled ? props.onclick : undefined,
      }
    ),
    [
      h(
        'span',
        style([
          {
            transition: 'all 0.2s',
            backgroundColor: 'currentColor',
            width: props.selected ? '100%' : '0px',
            height: props.selected ? '100%' : '0px',
          },
        ])
      ),
    ]
  );
};
