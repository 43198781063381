import { h, IComponent, setDefaultClasses, View } from 'core';
import history from 'core/history';
import { Route, Switch } from 'core/router';
import { cookies, sessionStorage } from 'core/storage';

import { Actions as AppActions } from 'acadly/app/actions';
import App from 'acadly/app/App';
import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import { Actions as GetInActions } from 'acadly/getin/actions';
import GetInScreen from 'acadly/getin/GetInScreen';
import { IRootState } from 'acadly/IRootState';
import { getActiveRoute, Routes, setRouteContext } from 'acadly/routes';
import { getScreenWidth } from 'acadly/screenSize';
import { getStore } from 'acadly/store';
import * as u from 'acadly/utils';
import { throttle } from 'acadly/utils';
import links from 'acadly/utils/links';

function isMobile() {
  const isPortrait = window.innerWidth < window.innerHeight;
  const mobileThreshold = isPortrait ? 5 : 8;
  return getScreenWidth() <= mobileThreshold;
}

export default class Root extends IComponent<
  never,
  {
    storeState: IRootState;
    updating: Promise<any> | null;
  }
> {
  public componentWillUpdate() {
    const appError = getStore().getState().app.appError;
    if (appError) {
      u.unsetTabIndices();
    }
  }
  public componentWillMount() {
    const store = getStore();
    const narrowScreenThreshold = 1125;

    store.dispatch(AppActions.setWindowWidth(window.innerWidth));

    if (!isMobile()) {
      setDefaultClasses('custom-scrollbar');
    } else {
      setDefaultClasses();
    }

    store.dispatch(AppActions.setIsMobile(isMobile()));

    store.dispatch(AppActions.setNarrowScreen(window.innerWidth < narrowScreenThreshold));

    window.addEventListener(
      'resize',
      throttle(() => {
        store.dispatch(AppActions.setWindowWidth(window.innerWidth));
        store.dispatch(AppActions.setIsMobile(isMobile()));
        if (!isMobile()) {
          setDefaultClasses('custom-scrollbar');
        } else {
          setDefaultClasses();
        }
        store.dispatch(AppActions.setNarrowScreen(window.innerWidth < narrowScreenThreshold));
        setTimeout(() => {
          getScrollbarWidth(isMobile()).then((width) => {
            store.dispatch(AppActions.setScrollbarWidth(width));
          });
        }, 1000);
      }, 1000)
    );
    getScrollbarWidth(isMobile()).then((width) => {
      store.dispatch(AppActions.setScrollbarWidth(width));
    });
    this.setState({
      storeState: store.getState(),
    });

    store.subscribe(() => {
      this.setState({
        storeState: store.getState(),
      });
    });
    history.listen(async () => {
      await this.setState({});
      if (store.getState().app.isAnalyticsOpen) {
        // close analytics
        store.dispatch(AppActions.toggleAnalytics(undefined));
      }
    });

    if (!store.getState().getIn.session) {
      cookies.remove('token');
      cookies.remove('session');
      localStorage.clear();
      sessionStorage.clear();
    } else {
      setRouteContext();
      const active = getActiveRoute();
      if (active && active.route.meta.context === 'getin') {
        Routes.home.navigate({}, window.location.search);
      }
    }
  }

  public render() {
    const isMobile = this.getState().storeState.app.isMobile;
    const serverErrorObj = getStore().getState().app.appError;
    const errorAlertButton = FlatButton('OK', {
      tabIndex: 0,
      role: 'button',
      classNames: isMobile ? ['snackbar__action'] : undefined,
      onclick: () => {
        const serverErrorObj = getStore().getState().app.appError;
        if (serverErrorObj && serverErrorObj.onclose) {
          serverErrorObj.onclose();
        }
        this.closeAlert();
        const lastFocusedElement = window.lastFocusedElement as HTMLElement;
        lastFocusedElement && lastFocusedElement.focus();
      },
    });
    let serverErrorMessages: View[] = [];
    if (serverErrorObj) {
      if (typeof serverErrorObj.message === 'string') {
        serverErrorMessages = links.detect(serverErrorObj.message).map((token) => {
          switch (token.type) {
            case 'string':
              return token.value;
            case 'link':
              return h('a', { href: token.value, target: '_blank' }, token.value);
            default:
              return '';
          }
        });
      } else {
        serverErrorMessages = serverErrorObj.message;
      }
    }

    const serverError = isMobile
      ? h(
          `div.snackbar${serverErrorObj ? '.visible' : ''}`,
          serverErrorObj ? [h('span.snackbar__message', serverErrorMessages), errorAlertButton] : []
        )
      : Alert(
          {
            open: !!serverErrorObj,
            role: 'alert',
            isAccessible: !getStore().getState().app.acc.web.turnOff,
            rootClassName: 'server-error',
            actions: [errorAlertButton],
          },
          serverErrorMessages
        );
    return h('div.root', [
      serverError,
      Switch(
        [
          Route(Routes.login, GetInScreen),
          Route(Routes.signup, GetInScreen),
          Route('*', () => {
            const store = getStore();
            if (!store.getState().getIn.session) {
              store.dispatch(GetInActions.setRouteAccessed(location.pathname));
              Routes.login.navigate({});
              return null;
            }
            return App({});
          }),
        ],
        {
          defaultCallback: () => {},
        }
      ),
    ]);
  }

  private closeAlert() {
    getStore().dispatch(AppActions.clearError(undefined));
    u.resetTabIndices();
  }
}

function getScrollbarWidth(isMobile: boolean): Promise<number> {
  if (isMobile) return Promise.resolve(0);
  let dummy = document.createElement('div');
  const alreadyAdded = <HTMLDivElement>document.getElementById('scrollbar-width-dummy');
  if (alreadyAdded) {
    dummy = alreadyAdded;
  } else {
    dummy.id = 'scrollbar-width-dummy';
    document.body.appendChild(dummy);
    if (!isMobile) {
      dummy.classList.add('custom-scrollbar');
    }
    dummy.style.overflowY = 'scroll';
    dummy.style.position = 'fixed';
    dummy.style.top = '0';
    dummy.style.left = '0';
    dummy.style.width = '100%';
    dummy.style.height = '100%';
    dummy.style.visibility = 'hidden';
  }
  return new Promise<number>((resolve) => {
    setTimeout(() => {
      const value = (dummy.offsetWidth - dummy.clientWidth) / 2;
      resolve(value);
    });
  });
}
