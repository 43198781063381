import { JSONResponse } from 'core/http';
import * as http from 'core/http';

import { CreateCoursePermission } from 'acadly/utils/constants';

import api from '../api';

export type IFetchServerResult =
  | { next: 'getRoleUNE'; userExists: 0; universityExists: 0 }
  | { next: 'getRoleUE'; userExists: 0; universityExists: 1; univName: string; univSlug: string }
  | { next: 'changeServer'; cluster: string }
  | { next: 'verificationAwaited'; university: { univName: string; univSlug: string } }
  | { next: 'goToLogin' };

export function fetchServer(emailId: string): Promise<JSONResponse<IFetchServerResult>> {
  return http.jsonRequest(api().gateway, {
    method: 'GET',
    data: { emailId },
  });
}

export type IFetchRegistrationResult =
  | {
      next: 'getPassword';
      userExists: 1;
      userId: string;
      name: string;
      verified: 1;
      canCreateCourses: CreateCoursePermission;
      profileCompleted: 1;
      avatar: string;
      university: {
        univSlug: string;
        univName: string;
      };
      avatarURL: string;
    }
  | {
      next: 'passwordNotSet';
      avatar: string;
      avatarURL: string;
      userExists: 1;
      userId: string;
      name: string;
      canCreateCourses: CreateCoursePermission;
      profileCompleted: 0 | 1;
      university: {
        univSlug: string;
        univName: string;
      };
      verified: 0 | 1;
    };

export function fetchRegistrationDetails(
  emailId: string
): Promise<JSONResponse<IFetchRegistrationResult>> {
  return http.jsonRequest(api().email, {
    method: 'POST',
    data: {
      emailId,
    },
  });
}

export type ILoginResponse = {
  socket: {
    key: string;
    cluster: string;
  };
  token: string;
  userId: string;
  allCourses: {
    courseCode: string;
    courseId: string;
    role: string;
    status: 'active' | 'invited';
    univSlug: string;
    universityId: string;
  }[];
} & (
  | {
      next: 'profileNotCompleted';
      profileCompleted: 0;
    }
  | {
      next: 'landingPage';
      profileCompleted: 1;
    }
);

export function tryLogin(userId: string, password: string) {
  return http.jsonRequest<ILoginResponse>(api().login, {
    method: 'POST',
    skipInterceptors: true,
    data: {
      userId,
      password,
    },
  });
}

export function logout() {
  return http.jsonRequest<void>(api().logout, {
    method: 'GET',
  });
}

export function forgotPassword(userId: string) {
  return http.jsonRequest(api().forgotPassword, {
    method: 'POST',
    data: { userId },
  });
}

export interface IVerifyTempPassResponse {
  message?: string;
  next: 'SetNewPassword' | 'ResetPassword';
}
export function verifyTempPass(userId: string, tempPass: string) {
  return http.jsonRequest<IVerifyTempPassResponse>(api().verifyTempPass, {
    method: 'POST',
    skipInterceptors: true,
    data: { userId, tempPass },
  });
}

export function resendTempPass(userId: string) {
  return http.jsonRequest(api().resendTempPass, {
    method: 'POST',
    data: { userId },
  });
}

export type ISetPasswordResponse =
  | {
      next: 'setPasswordWindowExpired';
    }
  | {
      next: 'landingPage' | 'profileNotCompleted';
      token: string;
      userId: string;
      socket: { key: string; cluster: string };
    };

export function setPassword(userId: string, password: string) {
  return http.jsonRequest<ISetPasswordResponse>(api().setPassword, {
    method: 'POST',
    data: { userId, password },
    skipInterceptors: true,
  });
}

export function changePassword(oldPass: string, newPass: string) {
  return http.jsonRequest(api().changePassword, {
    method: 'POST',
    data: { oldPass, newPass },
  });
}

export function changeName(newName: string) {
  return http.jsonRequest(api().changeName, {
    method: 'POST',
    data: {
      newName: newName,
    },
  });
}

export function changeNameStatus() {
  return http.jsonRequest<{ timesRemaining: number }>(api().changeNameStatus, {
    method: 'GET',
  });
}

export type TeacherSignupRole = 'faculty' | 'staff' | 'ta' | 'student';

export type TeacherSignupOrganization =
  | 'university'
  | 'k12'
  | 'company'
  | 'vocational'
  | 'nonProfit'
  | 'independent';

export type TeacherSignupUseCase =
  | 'inPersonCourse'
  | 'liveOnlineCourse'
  | 'asyncCourse'
  | 'standaloneEvent'
  | 'intOnlineTraining'
  | 'intOfflineTraining'
  | 'custOnlineTraining'
  | 'custOfflineTraining'
  | 'na';

type ITeacherSignupRequest =
  | {
      universityExists: 1;
      emailId: string;
      website?: string;
      name: string;
      marketing: 0 | 1;
      role: TeacherSignupRole;
    }
  | {
      universityExists: 0;
      emailId: string;
      website?: string;
      name: string;
      marketing: 0 | 1;
      organization: TeacherSignupOrganization;
      useCase: TeacherSignupUseCase;
    };

export type ITeacherSignupResponse =
  | {
      next: 'verificationAwaited';
      university: string;
      acuid?: string;
    }
  | {
      next: 'ThankYou';
      acuid?: string;
    }
  | {
      next: 'changeServer';
      cluster: string;
      acuid?: string;
    };

export function teacherSignup(data: ITeacherSignupRequest) {
  return http.jsonRequest<ITeacherSignupResponse>(api().signup.teacher, {
    method: 'POST',
    data,
  });
}

export function createProfile(name: string) {
  return http.jsonRequest<void>(api().profileCreate, {
    method: 'POST',
    data: { name, sex: 'undisclosed' },
  });
}

type ISetAvatarRequest =
  | {
      imageUploaded: true;
      file: File;
      fileName: string;
      fileType: string;
    }
  | {
      imageUploaded: false;
      fileName: string;
      fileType: string;
    };

export function setAvatar(data: ISetAvatarRequest) {
  return http.upload(api().uploadAvatar, {
    method: 'POST',
    data,
  }).promise;
}

export interface IGetCourseByCodeResponse {
  title: string;
  courseCode: string;
  universityName: string;
  admin: {
    name: string;
  };
}

export function getCourseByCode(code: string) {
  return http.jsonRequest<IGetCourseByCodeResponse>(api().getCourseInfoByJoinCode(code), {
    method: 'GET',
  });
}

export function signupStudent(joinCode: string, emailId: string, marketing: 0 | 1) {
  return http.jsonRequest<IFetchServerResult>(api().signup.student, {
    method: 'POST',
    data: {
      joinCode,
      emailId,
      marketing,
    },
  });
}
