/**
 * parses query params and convert them into a dictionary of key-value pair
 */
export function getQueryParams() {
  const query = window.location.search.substring(1);
  const components = query.split('&');
  const result: ObjectMap<string> = {};

  for (let i = 0; i < components.length; i++) {
    const [key, value] = components[i].split('=');
    result[decodeURIComponent(key)] = decodeURIComponent(value);
  }

  return result;
}
