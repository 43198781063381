import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/takeUntil';

import { Observable } from 'rxjs/Observable';

export interface RequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: HeadersInit;
  data?: string | Record<string, any>;
  checkCanceled?: () => boolean;
  skipInterceptors?: boolean;
  skipErrorHandlers?: boolean;
}

interface RequestOptionsWithUrl extends RequestOptions {
  url: string;
}

interface CoreHttpRequest extends RequestOptionsWithUrl {
  headers: Headers;
}

export type JSONResponse<T> = CoreHttpResponse<T>;
export type JSONPromise<T> = Promise<JSONResponse<T>>;

export class CoreHttpResponse<T> {
  public readonly body: ReadableStream | null;
  public readonly status: number;
  public readonly statusText: string;
  public readonly headers: Headers;
  public readonly url: string;
  constructor(
    public readonly request: CoreHttpRequest,
    private rawResponse: Response,
    public readonly data: T
  ) {
    this.body = rawResponse.body;
    this.status = rawResponse.status;
    this.statusText = rawResponse.statusText;
    this.headers = rawResponse.headers;
    this.url = rawResponse.url;
  }

  public json<T>(): Promise<T> {
    return this.rawResponse.json();
  }

  public cloneWithData<R>(data: R) {
    return new CoreHttpResponse(this.request, this.rawResponse, data);
  }
}
export interface Interceptor {
  transformRequest?: (request: RequestOptionsWithUrl) => RequestOptionsWithUrl;
  onResponse?: (
    response: CoreHttpResponse<unknown>
  ) => Promise<CoreHttpResponse<unknown>> | CoreHttpResponse<unknown>;
}

let onError: (e: any) => void = () => {};
export function setErrorHandler(handler: (e: any) => void) {
  onError = handler;
}

const interceptors: Interceptor[] = [];
export function addInterceptors(..._interceptors: Interceptor[]) {
  for (const interceptor of _interceptors) {
    interceptors.push(interceptor);
  }
}

function transformRequest(request: RequestOptionsWithUrl): RequestOptionsWithUrl {
  return interceptors
    .map((i) => i.transformRequest!)
    .filter((t) => !!t)
    .reduce((r, f) => f(r), request);
}

function transformResponse(
  responsePromise: Promise<CoreHttpResponse<any>>
): Promise<CoreHttpResponse<any>> {
  const responseInterceptors = interceptors.filter((i) => i.onResponse).map((i) => i.onResponse!);
  return responseInterceptors.reduce((p, f) => p.then(f), responsePromise);
}

function withErrorHandler<R>(promise: Promise<R>, skipAlert = false): Promise<R> {
  return promise.catch((e) => {
    if (!skipAlert) {
      onError(e);
    }
    throw e;
  });
}

function rawRequest(request: RequestOptionsWithUrl): Promise<CoreHttpResponse<undefined>> {
  const { url, method, data, headers, checkCanceled } = request;

  const requestConfig: RequestInit = {
    method: method,
    body: data as any,
    headers: headers,
  };
  return fetch(url, requestConfig)
    .then((response) => {
      if (checkCanceled && checkCanceled()) {
        throw new Error('Request canceled');
      } else {
        return response;
      }
    })
    .then((response) => {
      return new CoreHttpResponse(
        {
          ...request,
          headers: new Headers(request.headers),
        },
        response,
        undefined
      );
    });
}

export function request(url: string, config: RequestOptions): Promise<CoreHttpResponse<undefined>> {
  const req = transformRequest({
    url,
    ...config,
  });
  return withErrorHandler(transformResponse(rawRequest(req)), config.skipErrorHandlers);
}

export function upload(
  url: string,
  config: RequestOptions & { data: any }
): { promise: Promise<CoreHttpResponse<string>>; progress$: Observable<ProgressEvent> } {
  const req = transformRequest({
    url,
    ...config,
  });
  const xhr = new XMLHttpRequest();
  const headers: any = req.headers || {};
  const promise: Promise<CoreHttpResponse<string>> = new Promise((resolve, reject) => {
    xhr.addEventListener('load', () => {
      const headersArray = xhr.getAllResponseHeaders().split(/[\n\r]+/);
      const response = new Response(xhr.response, {
        status: xhr.status,
        statusText: xhr.statusText,
      });
      const headers = new Headers();
      for (const header of headersArray) {
        if (header === '') continue;
        const splitPoint = header.indexOf(':');
        const key = header.slice(0, splitPoint);
        const value = header.slice(splitPoint + 1);
        headers.set(key, value);
      }
      const coreResponse = new CoreHttpResponse(
        {
          ...req,
          headers,
        },
        response,
        xhr.response
      );
      resolve(coreResponse);
    });
    xhr.addEventListener('error', () => {
      reject(xhr.response);
    });
  });

  const progress$: Observable<ProgressEvent> = Observable.fromEvent(
    xhr.upload,
    'progress'
  ).takeUntil(Observable.fromEvent(xhr.upload, 'load')) as any;
  progress$.subscribe(() => {});
  xhr.open(req.method, req.url, true);
  for (const key of Object.keys(headers)) {
    xhr.setRequestHeader(key, headers[key]);
  }
  const data: any = config.data;
  if (data instanceof File) {
    xhr.send(data);
  } else {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    xhr.send(formData);
  }
  return {
    progress$,
    promise: withErrorHandler(transformResponse(promise)),
  };
}
function makeGetParams(o: any) {
  const queryParams = Object.keys(o).map((key) => {
    const value = o[key];
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  });
  return queryParams.length ? `?${queryParams.join('&')}` : '';
}

export function jsonRequest<T>(_url: string, config: RequestOptions): Promise<JSONResponse<T>> {
  const url =
    config.method === 'GET' && config.data && Object.keys(config.data).length > 0
      ? `${_url}${makeGetParams(config.data)}`
      : _url;
  const req = transformRequest({
    url,
    ...config,
    headers: {
      ...config.headers,
      'Content-type': 'application/json',
      Accept: 'application/json, */*',
    },
    data: config.data && config.method !== 'GET' ? JSON.stringify(config.data) : undefined,
  });
  if (config.skipInterceptors) {
    return withErrorHandler(
      rawRequest(req).then((response) =>
        response.json().then((json: T) => response.cloneWithData<T>(json))
      ) as any,
      config.skipErrorHandlers
    ) as any;
  } else {
    return withErrorHandler(
      transformResponse(
        rawRequest(req).then((response) =>
          response.json().then((json: T) => response.cloneWithData<T>(json))
        ) as any
      ),
      config.skipErrorHandlers
    ) as any;
  }
}
