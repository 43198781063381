/**
 * Utility functions. Most of these are for transforming objects and
 * arrays. Many of these functions are present in lodash but lodash adds
 * 8-10 KB minimum (after gZip) to the build size.
 * Note that all these functions are immutable meaning none of these
 * change the input data structures. So, if you pass in an array to
 * removeIndex, it won't delete anything from the input array. It will
 * return a new array without the element.
 */

/**
 * Test email if it's valid
 */

import { getStore } from 'acadly/store';
export function validateEmail(email: string): boolean {
  return anchorme.validate.email(email);
  // tslint:disable-next-line
  // return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(email);
}

import anchorme from 'anchorme';

import { HTMLAttrs } from 'core';
import { JSONResponse } from 'core/http';

import { parseYoutubeUrl as _parseYoutubeUrl } from 'acadly/utils/parseYoutubeUrl';
export const parseYoutubeUrl = _parseYoutubeUrl;

/**
 * Convert a link into a URL by prepending http:// if not present
 */
export function convertLinkToURL(link: string): string {
  return /^[a-zA-Z][a-zA-Z+-.]*:\/\//.test(link) ? link : 'http://' + link;
}

export interface ISplitFileName {
  name: string;
  extension: string;
}
export function splitFileName(originalName: string): ISplitFileName {
  const parts = originalName.split('.');
  if (parts.length < 2) {
    return {
      name: parts[0],
      extension: '',
    };
  } else {
    return {
      name: parts.slice(0, parts.length - 1).join('.'),
      extension: parts[parts.length - 1],
    };
  }
}

/**
 * Like Array.prototype.reduce but instead works on an object.
 * Takes in an object and a reducer function that takes previously reduced
 * value, current element and key and returns a new reduced value.
 * Whenever you have an object and you want to consecutively apply a function
 * to the elements, accumulating the result, use this.
 * @example
 * // sum of an object of numbers,
 * reduceObject(
 *      {a: 1, b: 2, c: 3},
 *      (accumulated, current) => accumulated + current,
 *      0) // initial value
 * // ==> 6
 */
export function reduceObject<T, R>(
  obj: ObjectMap<T>,
  reducer: (acc: R, current: T, key: string) => R,
  initial: R
) {
  return objectToPairs(obj).reduce((acc, [k, v]: [string, T]) => reducer(acc, v, k), initial);
}

/**
 * Map over values of an object. Useful for transforming the values of an object.
 * Works like Array.prototype.map but instead for objects.
 * Takes an object and a function from value to another value and returns
 * a new object with the function applied to each value.
 * @example
 *  mapValues({a: 1, b: 2, c: 3}, (x) => x + 1) // => {a: 2, b: 3, c: 4}
 */
export function mapValues<K extends string, V, R>(
  obj: TotalMapping<K, V>,
  f: (value: Diff<V, undefined | null>, key: K) => R
): TotalMapping<K, R> {
  return <any>(
    pairsToObject(objectToPairs(<any>obj).map(([k, v]: any) => [k, v ? f(v, k) : undefined] as any))
  );
}

export type Diff<T, U> = T extends U ? never : T;

export function exchangeKeyValues<K extends string, V extends string>(
  obj: TotalMapping<K, V>
): TotalMapping<V, K> {
  const result: TotalMapping<V, K> = <any>{};
  for (const [key, value] of enumerateMap(obj)) {
    result[value] = key;
  }
  return result;
}

/**
 * Convert an object to an array of arrays, each containing two elements,
 * key and value
 * @example
 * objectToPairs({a: 1, b: 2, c: 3}) // => [["a", 1], ["b", 2], ["c", 3]]
 */
export function objectToPairs<V>(obj: ObjectMap<V>): [string, V][] {
  return Object.keys(obj).map((k) => [k, obj[k]] as any);
}

export function* enumerateMap<K extends string, V>(
  obj: TotalMapping<K, V>
): IterableIterator<[K, V]> {
  for (const k of objectKeys(obj)) {
    yield [k, obj[k]];
  }
}

/**
 * Convert an array of array pairs containing keys and values
 * to an object
 * @example
 * pairsToObject([["a", 1], ["b", 2], ["c", 3]], {
 *   a: 1,
 *   b: 2,
 *   c: 3
 * })
 */
export function pairsToObject<V>(pairs: [string, V][]) {
  const result: ObjectMap<V> = {};
  for (const [key, value] of pairs) {
    result[key] = value;
  }
  return result;
}

/**
 * Merge two arrays into an object. Take keys from first object
 * and values from second object. Two arrays must be of the same
 * length
 * @example
 * zipObject(["a", "b", "c"], [1, 2, 3]) // => {a: 1, b: 2, c: 3}
 *
 * @throws keys.length !== values.length
 */
export function zipObject<V>(keys: string[], values: V[]): ObjectMap<V> {
  if (keys.length !== values.length) {
    throw new Error('zipObject called with arrays of different lengths');
  } else {
    return pairsToObject(zip(keys, values));
  }
}

/**
 * Zip two arrays into an array of pairs.
 * Two arrays must be of same length
 * @example
 * zip([1, 2, 3], ["a", "b", "c"]) // => [[1, "a"], [2, "b"], [3, "c"]]
 */
export function zip<A, B>(al: A[], bl: B[]): [A, B][] {
  if (al.length !== bl.length) {
    throw new Error('zip called with arrays of different lengths');
  } else {
    return al.map((a, i) => [a, bl[i]] as any);
  }
}

/**
 * Capitalize first character of a string
 */
export function capitalize(s: string) {
  if (s.length > 0) {
    return s[0].toUpperCase() + s.slice(1);
  } else {
    return s;
  }
}

/**
 * Find the index of the first element in an array which
 * for which the given predicate function returns true
 * Returns null in case no element was found.
 */
export function findIndex<A>(arr: A[], predicate: (a: A, i: number) => boolean): number | null {
  for (const i of range(arr.length)) {
    if (predicate(arr[i], i)) {
      return i;
    }
  }
  return null;
}

/**
 * Find the first element in array for which the given predicate holds.
 * Returns null if no element found.
 */
export function find<A>(arr: A[], predicate: (a: A, i: number) => boolean): A | null {
  const index = findIndex(arr, predicate);
  if (index !== null) {
    return arr[index];
  } else {
    return null;
  }
}

/**
 * Find an element in an array with matching predicate.
 * Traverses the array backwards.
 */
export function findReverse<A>(arr: A[], predicate: (a: A, i: number) => boolean): A | null {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i], i)) {
      return arr[i];
    }
  }
  return null;
}

/**
 * Flatten one level of nesting in an array of arrays.
 * Note that only one level of nesting is removed. This means if
 * inner arrays have arrays, they won't be flattened to the top level.
 * @example
 * flatten([1, 2, 3], [4, 5], [6]) // => [1, 2, 3, 4, 5, 6]
 */
export function flatten<A>(nested: A[][]): A[] {
  return nested.reduce((previous, current) => previous.concat(current), []);
}

/**
 * Return values of an object as an array.
 * Doesn't return values that are not enumerable (for
 * example values in the prototype chain)
 *
 * @see
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/Object/values
 */
export function values<A>(obj: ObjectMap<A>): A[] {
  return Object.keys(obj).map((k) => obj[k]);
}

const START = 'start';
/**
 * Pad the start of a string upto a given max length with given character
 */
export const padStart = (input: string, length: number, character: string) =>
  pad(START, input, length, character);

/**
 * Pad end of string
 */
export const padEnd: (input: string, length: number, character: string) => string = pad.bind(
  null,
  'end'
);

export function pad(where: 'start' | 'end', input: string, length: number, character: string) {
  if (input.length < length) {
    const difference = length - input.length;
    const chars = Array.from(range(difference))
      .map(() => character)
      .join('');
    if (where === 'start') {
      return chars + input;
    } else {
      return input + chars;
    }
  } else {
    return input;
  }
}

/**
 * Generator function that returns a range of values. Useful as a substitute
 * to `for (let i = 0; i < n; i++)` loops.
 * First argument is starting value and second argument is 1 + ending value
 * If no second argument is provided, starting value is 0 and ending value is
 * the first argument (-1 ofcourse).
 *
 * @example
 * for (const i of range(arr.length)) {
 *  console.log(arr[i]);
 * } // prints the elements of arr
 *
 * for (const i of range(1, 11)) {
 *  console.log(i);
 * } // prints 1, 2, ... 10
 *
 * Note that it's a generator function so simply calling it will only fetch
 * the next value (like iterators in other languages).
 * If you want to convert it to an array, use Array.from
 * @example
 * Array.from(range(0, 5)) // => [0, 1, 2, 3, 4]
 */
export function* range(_start: number, _end?: number): IterableIterator<number> {
  const start = _end === undefined ? 0 : _start;
  const end = _end === undefined ? _start : _end;
  for (let i = start; i < end; i++) {
    yield i;
  }
}

/**
 * Return an array of given length containing given element
 * at each index
 * @example
 * repeat("x", 2) // => ["x", "x"]
 */
export function repeat<A>(a: A, length: number): A[] {
  const result: A[] = [];
  for (const _ of range(0, length)) {
    result.push(a);
  }
  return result;
}

/**
 * Update the given object with new keys. Doesn't mutate the argument object.
 * Returns a new object with new properties. Only properties that are in
 * the update object are changed, rest are shallow copies so it is fast for
 * most cases.
 * It doesn't change the structure of the object. The resulting object will
 * have the same keys as the input object. Only the paths in the update object
 * will be changed.
 *
 * @param a Object to be updated
 * @param updates Object containing keys to be changed. Can be nested. Should
 * have the same structure as the first argument
 *
 * @example
 * const a = {
 *   x: {
 *     y: {
 *       z: 1,
 *       p: 2
 *     }
 *   },
 *   q: {
 *     r: 3
 *   }
 * }
 *
 * update(a, {x: {y: {z: 10}}}) // {x: {y: {z: 10, p: 2}}, q: {r: 3}}
 */
export function update<A>(a: A, updates: UpdateObject<A>): A {
  const result: any = Object.assign({}, a);
  for (const key of objectKeys(updates)) {
    if (
      typeof updates[key] === 'object' &&
      !(updates[key] instanceof Array) &&
      updates[key] !== null &&
      <any>updates[key] !== a[key]
    ) {
      result[key] = update(a[key], updates[key] as any);
    } else {
      result[key] = updates[key];
    }
  }
  return result;
}
type UpdateObject<A> = {
  [K in keyof A]?: UpdateObject<A[K]> | ((a: A[K]) => A[K]);
};

/**
 * Replace given index in an array with a given value
 * A function can also be passed instead of a value that transforms
 * the value (takes the current value and returns a new one)
 */
export function replaceIndex<A>(arr: A[], value: A | ((a: A) => A), index: number): A[] {
  return [
    ...arr.slice(0, index),
    value instanceof Function ? value(arr[index]) : value,
    ...arr.slice(index + 1),
  ];
}

/**
 * Replace all values in array for which given predicate returns true.
 * Instead of a new value, a function can be passed that takes a current
 * value and returns an updated value.
 */
export function replaceWhere<A>(
  arr: A[],
  value: A | ((a: A) => A),
  predicate: (a: A) => boolean
): A[] {
  return arr.map((a) => (predicate(a) ? (value instanceof Function ? value(a) : value) : a));
}

/**
 * Remove the given index from an array
 * Doesn't change the original array, instead returns a new one
 */
export function removeIndex<A>(arr: A[], index: number) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function throttle(fn: (...args: any[]) => void, threshhold = 250, scope?: any): any {
  let last: number | undefined;
  let deferTimer: NodeJS.Timeout;

  return function (...args: any[]) {
    const context = scope || window;
    const now = Date.now();

    if (last && now < last + threshhold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}

export function filterDuplicates(arr: string[]): string[] {
  const obj: ObjectMap<boolean> = {};
  for (const elem of arr) {
    obj[elem] = true;
  }
  return Object.keys(obj);
}

export function contains<A>(arr: A[], value: A): boolean {
  for (const val of arr) {
    if (val === value) return true;
  }
  return false;
}

export function isIn<A>(value: A, arr: A[]): boolean {
  return contains(arr, value);
}

export function removeKey<A>(obj: ObjectMap<A>, key: string) {
  const newObject = { ...obj };
  delete newObject[key];
  return newObject;
}

export function indexToAlphabet(num: number) {
  return String.fromCharCode(65 + num);
}

export function makeObjectWithKey<A>(arr: A[], key: keyof A): TotalMapping<string, A> {
  const result: ObjectMap<A> = {};
  for (const a of arr) {
    const _key = (a[key] as any).toString();
    result[_key] = a;
  }
  return result;
}

export function objectValues<A>(obj: ObjectMap<A | undefined>): A[] {
  return Object.keys(obj)
    .map((key) => obj[key])
    .filter((a) => !!a) as A[];
}

export function filterUndefined<A>(arr: (A | undefined)[]): A[] {
  return arr.filter((a) => a !== undefined) as A[];
}

export function fileToDataURI(file: File): Promise<string> {
  return new Promise((resolve) => {
    const filereader = new FileReader();
    filereader.onload = () => {
      resolve((filereader.result && filereader.result.toString()) || '');
    };
    filereader.readAsDataURL(file);
  });
}

export function getImageSizeFromUrl(url: string): Promise<number> {
  return new Promise<number>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', url);
    xhr.addEventListener('error', reject);
    xhr.addEventListener('load', function (data) {
      resolve((data as any).total);
    });
    xhr.send();
  });
}

export function dataURIToFile(name: string, dataURI: string): File {
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });
  const file = new File([blob], name, { type: mimeString });
  return file;
}

export const text = (s: string | TemplateStringsArray) => {
  const str = typeof s === 'string' ? s : s.raw.join('');
  return str.replace(/^\s+/, '').replace(/^s*/gm, '');
};

export function objectKeys<O>(o: O): (keyof O)[] {
  return Object.keys(o) as (keyof O)[];
}

export const timeToHHMM = (time: ITime) =>
  `${padStart(time.hours.toString(), 2, '0')}:${padStart(time.minutes.toString(), 2, '0')}`;

export function showTime(time: ITime) {
  const hours = padStart(gethh(time.hours).toString(), 2, '0');
  const minutes = padStart(time.minutes.toString(), 2, '0');
  const ampm = getAMPM(time.hours).toUpperCase();
  return `${hours}:${minutes} ${ampm}`;
  function gethh(hours: number) {
    if (hours === 0) {
      return 12;
    } else if (hours > 12) {
      return hours - 12;
    } else {
      return hours;
    }
  }

  function getAMPM(hours: number): 'am' | 'pm' {
    if (hours >= 12) {
      return 'pm';
    } else {
      return 'am';
    }
  }
}

export function shuffle<T>(array: T[]) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function profile(cls: any) {
  if (process.env.ENV !== 'production') {
    const P = cls.prototype;

    for (const methodName of Object.getOwnPropertyNames(P)) {
      const method = P[methodName];

      P[methodName] = function (...args: any) {
        performance.mark(`${methodName}-start`);
        const result = method.call(this, ...args);
        performance.mark(`${methodName}-end`);
        performance.measure(`${methodName}`, `${methodName}-start`, `${methodName}-end`);
        return result;
      };
    }
  }
}

export function loadScript(src: string) {
  return new Promise(function (resolve, reject) {
    const s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
}

export function checkEmptyString(src: string) {
  const str = src.split('&nbsp;');
  const sum = str.reduce((sum, s) => {
    return s.trim().length + sum;
  }, 0);
  if (sum === 0) {
    return '';
  }
  return src;
}

/**
 * Retry an async function n times, returning the number of attempts
 * it took to succeed, or throwing an exception if number of attempts
 * exceed n.
 * @param n Number of times to retry.
 */
export async function retry(f: () => Promise<void>, times = 3): Promise<number> {
  let attempts = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      await f();
      return attempts + 1;
    } catch (e) {
      attempts++;
      if (attempts >= times) {
        throw e;
      }
    }
  }
}

/** Get Last node in the node list recursively
 * if node has children, loop through them else return the node
 * if it is a formula node return the node and carry on,
 * no point in traversing inside the formula as its all spans or scripts
 * if is a div (the last empty div attached in the editor),
 * ignore and return its parent/ prev sibling
 *
 * at the end we get a node which is the closest node to the end of editor
 * which is a formula/ empty space/ br/ the editor root but not  the last empty div
 * which will put the new node in next line on the editor.
 */
export function getLastNode(node: Node | HTMLElement): Node {
  let lastNode: Node = node;
  if (
    node.childNodes.length === 0 ||
    (node.nodeName && node.nodeName.toUpperCase() === 'FORMULA')
  ) {
    lastNode = node;
  } else {
    for (let i = 0; i < node.childNodes.length; i++) {
      lastNode = getLastNode(node.childNodes[i]);
    }
  }
  return lastNode;
}
export function pluralize(qty: number, singular: string, plural = `${singular}s`): string {
  return qty === 1 ? singular : plural;
}

export function unsetTabIndices(node?: HTMLElement | null): void {
  if (getStore().getState().app.acc.web.turnOff === 1) {
    return;
  }

  document.querySelectorAll('*[tabIndex]').forEach((elem) => {
    if ((node && !node.contains(elem)) || !node) {
      const tabInd = elem.getAttribute('tabIndex');
      elem.removeAttribute('tabIndex');
      elem.setAttribute('data-tabIndex', `${tabInd}`);
    }
  });

  document.querySelectorAll('a[href]').forEach((elem) => {
    if ((node && !node.contains(elem)) || !node) {
      const href = elem.getAttribute('href');
      elem.removeAttribute('href');
      elem.setAttribute('data-href', `${href}`);
    }
  });

  document.querySelectorAll('div[contenteditable]').forEach((elem) => {
    if ((node && !node.contains(elem)) || !node) {
      elem.setAttribute('contenteditable', 'false');
    }
  });

  document.querySelectorAll('input').forEach((elem) => {
    if ((node && !node.contains(elem)) || !node) {
      elem.setAttribute('disabled', 'disabled');
    }
  });

  document.querySelectorAll('button').forEach((elem) => {
    if ((node && !node.contains(elem)) || !node) {
      elem.setAttribute('disabled', 'disabled');
    }
  });
}
export function resetTabIndices(node?: HTMLElement | null): void {
  if (getStore().getState().app.acc.web.turnOff === 1) {
    return;
  }

  document.querySelectorAll('*[data-tabIndex]').forEach((elem) => {
    if ((node && node.contains(elem)) || node === undefined) {
      const tabInd = elem.getAttribute('data-tabIndex');
      elem.removeAttribute('data-tabIndex');
      elem.setAttribute('tabIndex', `${tabInd}`);
    }
  });

  document.querySelectorAll('a[data-href]').forEach((elem) => {
    if ((node && node.contains(elem)) || node === undefined) {
      const href = elem.getAttribute('data-href');
      elem.removeAttribute('data-href');
      elem.setAttribute('href', `${href}`);
    }
  });

  document
    .querySelectorAll('div[contenteditable]')
    .forEach((elem) => elem.setAttribute('contenteditable', 'true'));

  document.querySelectorAll('input').forEach((elem) => elem.removeAttribute('disabled'));

  document.querySelectorAll('button').forEach((elem) => elem.removeAttribute('disabled'));
}

/**
 * Converts a string to Title Case
 * @param str string to be converted
 */
export function toTitleCase(str: string) {
  return str.replace(/(^|\s)\S/g, (t) => t.toUpperCase());
}

/**
 * Make html tag selector from id and css class names
 * @param tag valid HTML tag
 * @param classNames list of CSS class names
 * @param id tag id for a HTML tag
 */
export function getHTMLTagSelector(tag: string, classNames?: string[], id?: string) {
  let selector = tag;

  if (id) selector += `#${id}`;
  if (classNames && classNames.length) {
    selector = classNames.reduce((prev, current) => {
      if (!current) return prev;
      return `${prev}.${current}`;
    }, selector);
  }

  return selector;
}

/**
 * Generates a random string
 * @see https://stackoverflow.com/a/12502559/9709887
 */
export function randomString() {
  return Math.random().toString(36).slice(2);
}

/**
 * Generates a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 * @param min minimum (inclusive) possible value
 * @param max maximum (inclusive) possible value
 * @see https://stackoverflow.com/a/1527820/9709887
 */
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Returns promise which resolves in provided time
 */
export function sleep(time: number) {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, time);
  });
}

/**
 * Return response in delay milli-seconds
 */
export async function mockResponse<T>(response: T, delay = 1000) {
  await sleep(delay);
  return { data: response } as JSONResponse<T>;
}

/**
 * Checks if contents of an element is scrolled to bottom.
 * Returns true when scrolled to bottom.
 */
export function isScrolledToBottom(el: HTMLElement) {
  return el.scrollHeight - el.scrollTop - el.clientHeight < 1;
}

/**
 * Creates html attributes along with tab-index
 */
export function withTabIndex(attr?: HTMLAttrs) {
  const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
  return {
    ...attr,
    tabIndex: isAccessible ? 0 : undefined,
  };
}

/**
 * Converts boolean to numeric boolean
 */
export function toNumericBoolean(value: boolean) {
  return value ? 1 : 0;
}
