import { h, IComponent } from 'core';

import { Actions as AppActions } from 'acadly/app/actions';
import { sendReferral } from 'acadly/app/Referral/api';
import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import TextField from 'acadly/common/TextField';
import { dispatch, getStore } from 'acadly/store';
import { colors } from 'acadly/styles';

import * as css from './styles';

const ReferralFieldClass = 'referral-form-field';

class LocalTextInput extends IComponent<
  {
    value: string;
    onInput: (e: Event) => any;
  },
  never
> {
  public render() {
    return TextField({
      className: ReferralFieldClass,
      value: this.getProps().value,
      placeholder: 'Email, or Full Name',
      oninput: this.getProps().onInput,
    });
  }
}

interface FormEvent extends Event {
  target: Event['target'] & {
    elements: NodeListOf<HTMLInputElement>;
  };
}

const onSubmit = async (e: FormEvent) => {
  if (!e.target) return;
  const entities = Array.from(e.target.elements)
    .filter((el) => el.classList.contains(ReferralFieldClass))
    .map((el) => el.value)
    .filter(Boolean);
  if (!entities.length) return;
  await sendReferral({ referees: entities });
};

const defaultProps = {
  isSubmitting: false,
  refValues: ['', '', ''],
  submitFlowComplete: false,
};

export default class Referral extends IComponent<
  never,
  {
    isSubmitting: boolean;
    submitFlowComplete: boolean;
    refValues: Array<string>;
  }
> {
  public componentWillMount() {
    this.setState(defaultProps);
  }

  private handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await this.setState({ isSubmitting: true });
      await onSubmit(e);
      await this.setState({ submitFlowComplete: true });
    } catch (e) {
      console.error(e);
    }
    await this.setState({ isSubmitting: false });
  };

  private addField = () => {
    this.setState({ refValues: [...this.getState().refValues, ''] });
  };

  private onInput = ({ target }: { target: HTMLInputElement }, i: number) => {
    if (!target) return;
    const val = target.value;
    const refValues = [...this.getState().refValues];
    refValues[i] = val;

    this.setState({ refValues });
  };

  private successAlert() {
    return Alert(
      {
        open: this.getState().submitFlowComplete,
        actions: [
          FlatButton('No', {
            type: 'secondary',
            onclick: () => {
              dispatch(AppActions.setReferralsAllowed(false));
              history.back();
            },
          }),
          FlatButton('Yes', {
            onclick: () => this.setState(defaultProps),
          }),
        ],
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayGreen,
        },
      },
      ['Success! Do you want to refer more people?']
    );
  }

  public render() {
    const isMobile = getStore().getState().app.isMobile;
    const disableSubmit = this.getState().isSubmitting;
    const refValues = this.getState().refValues;

    return h('div', { style: css.viewWrapper }, [
      h('div', { style: css.container }, [
        h('div', { style: css.title(isMobile) }, ['Help us spread the word']),
        h('div', { style: css.contentBody(isMobile) }, [
          h('div', { style: css.textBlock }, [
            'Hi there! Thank you for using Acadly this semester. If ' +
              'it has been a useful resource for you so far, you can ' +
              'play a big role in helping us grow and improve by sparing ' +
              'a minute of your time.',
          ]),
          h('div', { style: css.textBlock }, [
            "We're still in our early days, and a positive word or " +
              'referral from you could contribute significantly to our ' +
              'growth.',
          ]),
          h('div', { style: css.textBlock }, [
            'If you could share the full names, and/or email addresses ' +
              'of any colleagues from universities who may find Acadly ' +
              'useful, we will reach out to them.',
          ]),
        ]),
        h('div', { style: css.formHeader(isMobile) }, ['Send an invite to:']),
        h('form', { style: css.formContainer, onSubmit: this.handleSubmit }, [
          h(
            'div',
            { style: css.formContent },
            refValues.map((val, i) => {
              const isLast = refValues.length - 1 === i;
              const lastElementStyles = isLast ? css.lastInputBlock : null;
              const style = {
                ...css.inputBlock,
                ...lastElementStyles,
              };
              return h('div', { style }, [
                h(LocalTextInput, {
                  value: val,
                  onInput: (e: any) => this.onInput(e, i),
                }),
              ]);
            })
          ),
          h('div', { style: css.spacer }),
          h(
            'button',
            {
              style: css.addFieldButton,
              type: 'button',
              onClick: this.addField,
            },
            ['Add another field']
          ),
          h(
            'button',
            {
              style: css.submitButton(disableSubmit),
              type: 'submit',
              disabled: disableSubmit,
            },
            ['Submit']
          ),
        ]),
      ]),
      this.successAlert(),
    ]);
  }
}
