import { colors } from 'acadly/styles';

interface AttendanceState {
  iconName: string;
  message1: string;
  message2: string;
  message3: string;
  textColor: string;
  why: boolean;
  iconStyle: {
    [key: string]: string;
  };
}
interface AttendanceStates {
  [key: string]: {
    [key: string]: {
      [key: string]: AttendanceState;
    };
  };
}

export const attendanceStates: AttendanceStates = {
  hasAttendance: {
    isNotCheckedIn: {
      notPresent: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.red, align: 'center' },
        textColor: colors.red,
        message1: '',
        message2: 'You were not marked present',
        message3: `Attendance was last recorded at`,
        why: true,
      },
      present: {
        iconName: 'locationCheck',
        iconStyle: { fontSize: '6em', color: colors.green, align: 'center' },
        textColor: colors.green,
        message1: '',
        message2: 'You were marked present for this class',
        message3: `Attendance was last recorded at`,
        why: false,
      },
      late: {
        iconName: 'locationCheck',
        iconStyle: { fontSize: '6em', color: colors.green, align: 'center' },
        textColor: colors.green,
        message1: '',
        message2: 'You were marked present, but late, for this class',
        message3: `Attendance was last recorded at`,
        why: false,
      },
      absent: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.red, align: 'center' },
        textColor: colors.red,
        message1: '',
        message2: 'You were marked absent for this class',
        message3: `Attendance was last recorded at`,
        why: true,
      },
      excused: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.red, align: 'center' },
        textColor: colors.red,
        message1: '',
        message2: 'You were excused from the attendance process',
        message3: `Attendance was last recorded at`,
        why: true,
      },
    },
    isCheckedIn: {
      notPresent: {
        iconName: 'locationCheck',
        iconStyle: { fontSize: '6em', color: colors.orange, align: 'center' },
        textColor: colors.orange,
        message1: '',
        message2: 'You were not marked present',
        message3: `Attendance was last recorded at`,
        why: true,
      },
      present: {
        iconName: 'locationCheck',
        iconStyle: { fontSize: '6em', color: colors.green, align: 'center' },
        textColor: colors.green,
        message1: '',
        message2: 'You were marked present for this class',
        message3: `Attendance was last recorded at`,
        why: false,
      },
      late: {
        iconName: 'locationCheck',
        iconStyle: { fontSize: '6em', color: colors.green, align: 'center' },
        textColor: colors.green,
        message1: '',
        message2: 'You were marked present, but late, for this class',
        message3: `Attendance was last recorded at`,
        why: false,
      },
      absent: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.red, align: 'center' },
        textColor: colors.red,
        message1: '',
        message2: 'You were marked absent for this class',
        message3: `Attendance was last recorded at`,
        why: true,
      },
      excused: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.red, align: 'center' },
        textColor: colors.red,
        message1: '',
        message2: 'You were excused from the attendance process',
        message3: `Attendance was last recorded at`,
        why: true,
      },
    },
  },
  dontHaveAttendance: {
    isNotCheckedIn: {
      futureClass: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.orange, align: 'center' },
        textColor: colors.grey,
        message1: 'This lecture is yet to start',
        message2: '',
        message3: `If attendance is recorded in the class, your status will show up here`,
        why: false,
      },
      notPresent: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.grey, align: 'center' },
        textColor: colors.grey,
        message1: '',
        message2: '',
        message3: `Attendance was not recorded in this class`,
        why: false,
      },
      present: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.grey, align: 'center' },
        textColor: colors.grey,
        message1: '',
        message2: '',
        message3: `Attendance has not been recorded yet`,
        why: false,
      },
      late: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.grey, align: 'center' },
        textColor: colors.grey,
        message1: '',
        message2: '',
        message3: `Attendance has not been recorded yet`,
        why: false,
      },
      absent: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.grey, align: 'center' },
        textColor: colors.grey,
        message1: '',
        message2: '',
        message3: `Attendance has not been recorded yet`,
        why: false,
      },
      excused: {
        iconName: 'export',
        iconStyle: { fontSize: '6em', color: colors.grey, align: 'center' },
        textColor: colors.grey,
        message1: '',
        message2: '',
        message3: `Attendance has not been recorded yet`,
        why: false,
      },
    },
    isCheckedIn: {
      futureClass: {
        iconStyle: { fontSize: '6em', color: colors.orange, align: 'center' },
        iconName: 'locationCheck',
        textColor: colors.orange,
        message1: '',
        message2: '',
        message3: `Attendance has not been recorded yet`,
        why: false,
      },
      notPresent: {
        iconStyle: { fontSize: '6em', color: colors.orange, align: 'center' },
        iconName: 'locationCheck',
        textColor: colors.orange,
        message1: '',
        message2: '',
        message3: `Attendance has not been recorded yet`,
        why: false,
      },
      present: {
        iconStyle: { fontSize: '6em', color: colors.orange, align: 'center' },
        iconName: 'locationCheck',
        textColor: colors.orange,
        message1: '',
        message2: '',
        message3: `Attendance has not been recorded yet`,
        why: false,
      },
      late: {
        iconStyle: { fontSize: '6em', color: colors.orange, align: 'center' },
        iconName: 'locationCheck',
        textColor: colors.orange,
        message1: '',
        message2: '',
        message3: `Attendance has not been recorded yet`,
        why: false,
      },
      absent: {
        iconStyle: { fontSize: '6em', color: colors.orange, align: 'center' },
        iconName: 'locationCheck',
        textColor: colors.orange,
        message1: '',
        message2: '',
        message3: `Attendance has not been recorded yet`,
        why: false,
      },
      excused: {
        iconStyle: { fontSize: '6em', color: colors.orange, align: 'center' },
        iconName: 'locationCheck',
        textColor: colors.orange,
        message1: '',
        message2: '',
        message3: `Attendance has not been recorded yet`,
        why: false,
      },
    },
  },
};
