import assignmentService from 'acadly/assignment/service';
import classService from 'acadly/class/service';
import courseService from 'acadly/course/service';
import { getContextActivity } from 'acadly/getContextActivity';
import * as quizFns from 'acadly/quiz/functions';

export interface ICommentsContextService {
  contextId: string;
  seenComments: number;
  totalComments: number;
  unseenComments: number;
}

export function getCommentsContextService(
  context: IAppContext,
  subContext?: string
): ICommentsContextService {
  const contextActivity = getContextActivity(context);
  const courseId = courseService.getCurrentCourseId();
  switch (context) {
    case 'course':
      return {
        contextId: courseId || '',
        get unseenComments() {
          return courseService.totalComments - courseService.seenComments;
        },
        get seenComments() {
          return courseService.seenComments;
        },
        get totalComments() {
          return courseService.totalComments;
        },
      };
    case 'class':
      return {
        contextId: classService.getCurrentClassId() || '',
        get seenComments() {
          return classService.seenComments;
        },
        get totalComments() {
          return classService.totalComments;
        },
        get unseenComments() {
          return Math.max(classService.totalComments - classService.seenComments, 0);
        },
      };
    case 'assignment':
      return {
        contextId: assignmentService.getCurrentAssignmentId() || '',
        get seenComments() {
          const assignment = assignmentService.getCurrentAssignment();
          if (!assignment) return 0;
          if (subContext === 'preSub') {
            return assignment.userData.numCommentsSeenPreSub;
          } else {
            return assignment.userData.numCommentsSeenPostSub;
          }
        },
        get totalComments() {
          if (subContext === 'preSub') {
            return assignmentService.preSubComments;
          } else {
            return assignmentService.postSubComments;
          }
        },
        get unseenComments() {
          return Math.max(this.totalComments - this.seenComments, 0);
        },
      };
    case 'quiz':
    case 'poll':
    case 'resource':
    case 'discussion':
    case 'query': {
      const commentsService: ICommentsContextService = {
        contextId: contextActivity ? contextActivity._id : '',
        get unseenComments() {
          if (contextActivity) {
            const total = this.totalComments;
            const seen = this.seenComments;
            return Math.max(total - seen, 0);
          } else {
            return 0;
          }
        },
        get totalComments() {
          if (contextActivity) {
            if (
              contextActivity.nodeType === 'quiz' &&
              courseService.getRole() === 'student' &&
              !quizFns.isQuizSubmitted(contextActivity)
            )
              return 0;
            return contextActivity.activities.numCommentsTotal;
          } else {
            return 0;
          }
        },
        get seenComments() {
          if (contextActivity) {
            if (contextActivity.userData) {
              const seen = contextActivity.userData.numCommentsSeen;
              const total = this.totalComments;
              return seen <= total ? seen : total;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        },
      };
      return commentsService;
    }
    default:
      return {
        contextId: '',
        seenComments: 0,
        totalComments: 0,
        unseenComments: 0,
      };
  }
}
