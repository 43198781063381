import {
  createBrowserHistory,
  createMemoryHistory,
  UnregisterCallback as _UnregisterCallback,
} from 'history';

const history = process.env.ENV === 'test' ? createMemoryHistory() : createBrowserHistory();
// const history = createMemoryHistory();

export type UnregisterCallback = _UnregisterCallback;

export default history;
