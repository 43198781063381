/**
 * Course service
 */
import IService from 'acadly/IService';
import { getStore } from 'acadly/store';
import * as u from 'acadly/utils';

class CourseService extends IService {
  public getCurrentCourse(): ICourse | undefined {
    if (this.state.courses.currentCourseId === undefined) {
      return undefined;
    }

    return this.state.courses.courses[this.state.courses.currentCourseId];
  }

  public getCurrentCourseUserData() {
    if (this.state.courses.currentCourseId === undefined) {
      return undefined;
    }

    return this.state.courses.userData[this.state.courses.currentCourseId];
  }

  public getCurrentCourseId() {
    return this.state.courses.currentCourseId;
  }

  // public getCourseIdFromCode(code: string) {
  //     const courseId = u.objectValues(
  //         getStore().getState().courses.courses
  //     ).filter(c => c.details.code === code)
  //     .map(c => c._id)[0];
  //     return courseId;
  // }

  // public getCourseCodeFromId(_id: string) {
  //     const code = u.objectValues(
  //         getStore().getState().courses.courses
  //     ).filter(c => c._id === _id)
  //     .map(c => c.details.code)[0];
  //     return code;
  // }

  public getCourseIdFromShortId(shortId: string) {
    const courseId = u
      .objectValues(getStore().getState().courses.courses)
      .filter((c) => c._id.slice(18) === shortId)
      .map((c) => c._id)[0];
    return courseId;
  }

  public getShortIdFromCourseId(courseId: string) {
    return courseId.slice(18);
  }

  public getRole(courseId?: string): ICourseRole | null {
    const id = courseId !== undefined ? courseId : this.getCurrentCourseId();
    if (id === undefined) {
      return null;
    }
    const state = this.state;
    if (state.getIn.session === undefined) {
      return null;
    }
    const currentUserId = state.getIn.session.userId;
    const course = state.courses.courses[id];
    if (course === undefined) {
      return null;
    }
    const teamMember = course.team.find((member) => member.userId === currentUserId);
    if (teamMember) {
      return teamMember.role;
    } else {
      return 'student';
    }
  }

  constructor() {
    super();
  }

  public get contextId() {
    return this.getCurrentCourseId()!;
  }

  public get totalComments(): number {
    const currentCourse = this.getCurrentCourse();
    if (currentCourse === undefined) {
      return 0;
    }

    return currentCourse.activities.numCommentsTotal;
  }

  public get seenComments(): number {
    const currentCourseUserData = this.getCurrentCourseUserData();
    if (currentCourseUserData === undefined) {
      return 0;
    }

    return currentCourseUserData.numCommentsSeen;
  }
}

export default new CourseService();
