import { h, View } from 'core';

export default (_selectors: string | any, _props: any, _children?: View[] | string) => {
  const props = typeof _selectors === 'string' ? _props : _selectors;
  const selectors = typeof _selectors === 'string' ? _selectors : '.paper';
  const children = typeof _selectors === 'string' ? _children : _props;

  return h(
    `div${selectors}`,
    {
      ...props,
      style: props.style,
    },
    children
  );
};
