import { getStore } from 'acadly/store';
import * as u from 'acadly/utils';

import IService from '../IService';

class PollService extends IService {
  public getPollIdFromShortId(shortId: string) {
    const polls = getStore().getState().polls.byId;
    const poll = u.objectValues(polls).filter((q) => q._id.slice(18) === shortId)[0];
    if (poll) {
      return poll._id;
    } else {
      return null;
    }
  }
  public getShortIdFromPollId(pollId: string) {
    return pollId.slice(18);
  }
}

export const pollService = new PollService();
export default pollService;
