import { CSS, h, IComponent, View } from 'core';

import { getStore } from 'acadly/store';

export interface IBasicButtonProps {
  onclick?: (event: Event) => Promise<any> | void;
  disabled?: boolean;
  key?: string;
  disabledStyle?: CSS;
  label?: View | View[];
  style?: CSS;
  id?: string; // do not prefix with #(hash)
  className?: string;
  classNames?: string[]; // do not prefix with .(dot)
  tabIndex?: number;
  ariaLabel?: string;
  isLoading?: boolean;
}

export interface IBasicButtonState {
  isLoading: boolean;
}

export default (label: string, props: IBasicButtonProps) => h(BasicButton, { ...props, label });

export class BasicButton<
  Props extends IBasicButtonProps,
  State extends IBasicButtonState
> extends IComponent<Props, State> {
  protected get isAccessible() {
    return getStore().getState().app.acc.web.turnOff === 0;
  }

  public componentWillMount() {
    this.setState({
      isLoading: this.getProps().isLoading || false,
    } as any);
  }

  public render() {
    const props = this.getProps();
    const _style: CSS = (props.style || {}) as any;
    const label: View[] = props.label instanceof Array ? props.label : [props.label || ''];
    const state = this.getState();
    return h(
      'button',
      {
        style: {
          cursor: state.isLoading ? 'default' : 'pointer',
          opacity: state.isLoading ? 0.5 : 1,
          ..._style,
        },
        className: props.classNames ? props.classNames.filter(Boolean).join(' ') : undefined,
        'aria-label': props.ariaLabel,
        disabled: this.isDisabled(),
        onclick: this.clickHandler(),
        tabIndex: props.tabIndex || 0,
      },
      label
    );
  }

  protected isDisabled() {
    return this.getState().isLoading || this.getProps().disabled;
  }

  protected isLoading() {
    return this.getState().isLoading || this.getProps().isLoading;
  }

  protected clickHandler() {
    const props = this.getProps();
    return async (event: Event) => {
      event.stopPropagation();
      if (!props.onclick || this.isDisabled()) return;
      try {
        await this.setState({ isLoading: true } as any);
        await (props.onclick as any)(event);
      } finally {
        await this.setState({
          isLoading: false,
        } as any);
      }
    };
  }
}
