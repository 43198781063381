import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import { createAction, Thunk } from 'acadly/createAction';
import * as dt from 'acadly/datetime';
import { IPusherPayload } from 'acadly/pusher';

import * as api from './api';

export type IResourceActionMap = {
  '@resource/CREATE_SUCCESS': ICreateSuccessPayload;
  '@resource/DELETE_SUCCESS': IDeleteSuccessPayload;
  '@resource/EDIT_SUCCESS': IEditSuccessPayload;
  '@resource/PUBLISH_SUCCESS': IPublishSuccessPayload;
  '@resource/ANALYTICS_SET_SUCCESS': IAnalyticsSetSuccessPayload;
  '@resource/all/FETCH_SUCCESS': IFetchAllSuccessPayload;
  '@resource/pusher/PUBLISH': IPusherPublishPayload;
  '@resource/analytics/FETCH_SUCCESS': IResourceAnalytics;
  '@resource/EDIT_PUBLISHED_RESOURCE_SUCCESS': api.IPublishPublishedResourceRequest;
};

export type IResourceAnalyticsFetchSuccess = IResourceAnalytics;
export type IPusherPublishPayload = IPusherPayload<{
  classId: string;
  classType: 'lecture';
  classSubType: IClassSubType;
  classTypeNum: number;
  resourceId: string;
  details: IResponseResource['details'];
  stats: { numClicks: number };
  activities: { numCommentsTotal: number };
}>;

export type IFetchAllSuccessPayload = api.IFetchAllResponse;

export interface IAnalyticsSetSuccessPayload {
  resourceId: string;
  toBeDone: 'preClass' | 'inClass';
  classId: string;
  firstAccess: 0 | 1;
  firstAccessedOn: UnixTimestamp;
}

export interface IPublishSuccessPayload extends api.IPublishResourceRequest {
  publishedOn: UnixTimestamp;
  trueNum: number;
  num: number;
}

export type IPublishPublishedResourceRequest = api.IPublishPublishedResourceRequest;

export interface ICreateSuccessPayload {
  classId: string;
  resource: IResponseResource;
}

export interface IDeleteSuccessPayload {
  classId: string;
  resourceId: string;
  toBeDone: 'preClass' | 'inClass';
}

export type IEditSuccessPayload = api.IEditRequest;

export const Actions = {
  createSuccess: createAction('@resource/CREATE_SUCCESS'),
  create:
    (data: api.ICreateRequest): Thunk<{ _id: string }> =>
    async (dispatch) => {
      const response = await api.create(data);
      googleAnalytics.activityCreated('resource', data.toBeDone);
      dispatch(
        Actions.createSuccess({
          classId: data.classId,
          resource: response.data,
        })
      );
      return {
        _id: response.data._id,
      };
    },

  deleteSuccess: createAction('@resource/DELETE_SUCCESS'),
  deleteResource:
    (data: api.IDeleteRequest, beforeDispatch?: () => any): Thunk<void> =>
    async (dispatch) => {
      await api.deleteResource(data);
      if (beforeDispatch) {
        await beforeDispatch();
      }
      dispatch(
        Actions.deleteSuccess({
          resourceId: data.resourceId,
          classId: data.classId,
          toBeDone: data.toBeDone,
        })
      );
    },

  editSuccess: createAction('@resource/EDIT_SUCCESS'),
  edit:
    (data: api.IEditRequest): Thunk<void> =>
    async (dispatch) => {
      await api.edit(data);
      dispatch(Actions.editSuccess(data));
    },

  updatePublishedResourceSuccess: createAction('@resource/EDIT_PUBLISHED_RESOURCE_SUCCESS'),
  updatePublishedResource:
    (data: api.IPublishPublishedResourceRequest): Thunk<void> =>
    async (dispatch) => {
      await api.publishPublishedResource(data);
      dispatch(Actions.updatePublishedResourceSuccess(data));
    },

  publishSuccess: createAction('@resource/PUBLISH_SUCCESS'),
  publish:
    (data: api.IPublishResourceRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.publish(data);
      googleAnalytics.activityPublished('resource', data.toBeDone);
      dispatch(
        Actions.publishSuccess({
          ...data,
          publishedOn: response.data.publishedOn,
          num: response.data.resourceNum,
          trueNum: response.data.trueNum,
        })
      );
    },

  analyticsSetSuccess: createAction('@resource/ANALYTICS_SET_SUCCESS'),
  analyticsSet:
    (data: {
      request: api.IAnalyticsSetRequest;
      toBeDone: 'preClass' | 'inClass';
      classId: string;
    }): Thunk<void> =>
    async (dispatch) => {
      await api.analyticsSet(data.request);
      dispatch(
        Actions.analyticsSetSuccess({
          resourceId: data.request.resourceId,
          classId: data.classId,
          toBeDone: data.toBeDone,
          firstAccess: data.request.firstAccess,
          firstAccessedOn: dt.unix(),
        })
      );
    },

  resourceAnalyticsFetchSuccess: createAction('@resource/analytics/FETCH_SUCCESS'),
  resourceAnalyticsFetch:
    (resourceId: string): Thunk<IResourceAnalytics> =>
    async (dispatch) => {
      const response = await api.analyticsResourceFetch(resourceId);
      dispatch(Actions.resourceAnalyticsFetchSuccess(response.data));
      return response.data;
    },

  fetchAllSuccess: createAction('@resource/all/FETCH_SUCCESS'),
  fetchAll: (): Thunk<string[]> => async (dispatch) => {
    const response = await api.fetchAll();
    dispatch(Actions.fetchAllSuccess(response.data));
    return response.data.activityData.map((r) => r._id);
  },

  pusherPublish: createAction('@resource/pusher/PUBLISH'),
};
