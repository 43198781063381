import { h, IComponent, View } from 'core';

import { getStore } from 'acadly/store';

import { colors } from '../styles';
interface ITabsProps {
  activeTab: number;
  tabActions: (() => void)[];
  icons?: any[];
  style?: any;
}
export default (props: ITabsProps, children: View[]) => h(Tabs, { ...props, children });
class Tabs extends IComponent<ITabsProps & { children: View[] }, never> {
  public render() {
    const tabStyle = {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'center',
      height: '100%',
      cursor: 'pointer',
      color: colors.lightGrey,
      borderTop: '3px solid transparent',
      boxSizing: 'border-box',
      backgroundColor: 'white',
      transition: '0.5s color',
    };
    const activeTabStyle = {
      color: colors.blue,
    };
    const activeTab = this.getProps().activeTab;
    const props = this.getProps();
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    const width = props.style && props.style.width ? props.style.width : '100%';
    return h(
      'div.tabs',
      {
        style: {
          width: width,
          display: 'flex',
          minHeight: '3em',
          position: 'relative',
          backgroundColor: 'white',
          boxSizing: 'border-box',
          paddingBottom: '3px',
          boxShadow: '#999 1px 1px 5px 0px',
          alignItems: 'center',
          zIndex: 50,
          ...this.getProps().style,
        },
      },
      [
        ...(props.children instanceof Array
          ? props.children.map((elem, index) =>
              h(
                `div.ripple.tab-${index}`,
                {
                  onclick: () => props.tabActions[index](),
                  tabIndex: isAccessible ? 0 : undefined,
                  style: {
                    ...tabStyle,
                    ...(activeTab === index ? activeTabStyle : {}),
                  },
                },
                [
                  props.icons
                    ? h('span.glyph-icon', {
                        style: {
                          color: activeTab === index ? colors.blue : colors.lightGrey,
                          fontSize: '1.5em',
                        },
                        'data-icon': props.icons[index],
                      })
                    : null,
                  h(
                    'span',
                    {
                      style: {
                        marginLeft: props.icons ? '0.5em' : '0',
                      },
                    },
                    [elem]
                  ),
                ]
              )
            )
          : [props.children]),
        h(
          'div',
          {
            style: {
              display: 'flex',
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: '3px',
              width: '100%',
            },
          },
          [
            h('div', {
              style: {
                backgroundColor: colors.blue,
                height: '3px',
                width: `${100 / props.children.length}%`,
                marginLeft: `calc(${activeTab} * ${100 / props.children.length}%)`,
                transition: '0.3s margin-left ease-out',
              },
            }),
          ]
        ),
      ]
    );
  }
}
