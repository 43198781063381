import { CSS, h, IComponent } from 'core';

import DateView from 'acadly/common/DateView';
import { getStore } from 'acadly/store';

import assignmentFunctions from '../assignment/functions';
import NewCommentsBubble from '../common/NewCommentsBubble';
import courseService from '../course/service';
import * as datetime from '../datetime';
import { colors } from '../styles';

export default (assignment: IAssignment, props?: IAssignmentWidgetProps) =>
  h(AssignmentWidget, { assignment, ...props });
export interface IAssignmentWidgetProps {
  style?: CSS;
  onclick?: (e: Event) => any;
}
class AssignmentWidget extends IComponent<
  IAssignmentWidgetProps & {
    assignment: IAssignment;
  },
  never
> {
  public render() {
    const assignment = this.getProps().assignment;
    // if (assignment.details.num === 31) {
    //     (this.isLate() && !assignment.details.allowLate));
    // }
    const role = courseService.getRole();
    const widgetColor = this.getWidgetColor(assignment);
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const newComments =
      role === 'student' &&
      (!assignment.userData.studentUserData ||
        assignment.userData.studentUserData.status === 'inProgress')
        ? assignment.activities.numCommentsPreSub - assignment.userData.numCommentsSeenPreSub
        : assignmentFunctions.getUnseenComments(assignment);
    const getStatus = () => {
      if (role === 'student') {
        if (!this.isSubmitted() && this.isLate() && !assignment.details.allowLate) {
          return 'Unsubmitted. You missed the deadline.';
        } else if (assignment.userData.studentUserData) {
          if (['submitted', 'late'].indexOf(assignment.userData.studentUserData.status) > -1) {
            if (assignment.userData.studentUserData.graded) {
              return (
                `Submitted | ` +
                `Score: ${assignment.userData.studentUserData.graded.score}/` +
                `${assignment.details.maxMarks}`
              );
            } else {
              return 'Ungraded';
            }
          } else return 'Unsubmitted';
        } else {
          return 'Unattempted';
        }
      } else {
        const numGraded = assignment.stats!.numGraded;
        const numSubmitted = assignment.stats!.numSubmitted;
        const numUngraded = numSubmitted - numGraded;
        if (!assignment.details.published) return 'Unpublished';
        if (numGraded < numSubmitted) {
          return h('span.fc-red', [
            `${numUngraded} ungraded submission${numUngraded === 1 ? '' : 's'}`,
          ]);
        } else {
          return `${numSubmitted} submission${numGraded !== 1 ? 's' : ''}`;
        }
      }
    };
    const dueDate = datetime.fromUnix(assignment.details.dueDateTime);

    return h(
      'div.assignment-widget',
      {
        style: {
          color: widgetColor,
          ...this.getProps().style,
        },
        tabIndex: isAccessible ? 0 : undefined,
        'aria-label': this.getAltText(),
        onclick: this.getProps().onclick,
        key: assignment._id,
      },
      [
        h('div.timeline-widget__icon', [DateView(dueDate, widgetColor)]),

        h('div.assignment-widget__wrapper', [
          h('div.assignment-widget__heading', [
            assignment.details.num > 0 ? `Assignment ${assignment.details.num}` : 'Assignment',
            NewCommentsBubble({ newComments, className: 'assignment-widget__comments' }),
            this.isUpdatedTagVisible()
              ? h('span.assignment-widget__updated-tag', [
                  h('i.fa.fa-pencil.assignment-widget__updated-tag-icon'),
                  h('span.assignment-widget__updated-tag-text', 'EDITED'),
                ])
              : null,
            role === 'student' && !assignment.userData.studentUserData
              ? h('span.star.assignment-widget__star')
              : null,
          ]),
          h('div.assignment-widget__title', assignment.details.title || 'Untitled'),
          h('div.fc-light-grey', ['Status: ', getStatus()]),
        ]),
      ]
    );
  }

  private isUpdatedTagVisible() {
    const { assignment } = this.getProps();
    const role = courseService.getRole();
    if (assignment.userData.studentUserData && assignment.userData.studentUserData.submittedOn) {
      return false;
    }
    if (
      assignment.userData &&
      role === 'student' &&
      assignment.details.lastEditedOn &&
      assignment.userData.studentUserData &&
      assignment.userData.studentUserData.lastAccessedOn
    ) {
      const lastAccessedOn = assignment.userData.studentUserData.lastAccessedOn;
      return lastAccessedOn && lastAccessedOn < assignment.details.lastEditedOn;
    }
    return false;
  }

  private getAltText() {
    const { assignment } = this.getProps();
    const altTextDate = datetime.format(
      assignment.details.dueDateTime,
      'HH:mm A ddd, [on] ddd, Do MMM '
    );
    const status =
      assignment.userData.studentUserData && assignment.userData.studentUserData.status;
    return (
      `Assignment ${assignment.details.num} - ${assignment.details.title} ` +
      `Due on ${altTextDate} ` +
      `${status !== undefined ? status : ''}`
    );
  }

  private isSubmitted() {
    const assignment = this.getProps().assignment;
    return (
      assignment.userData.studentUserData !== undefined &&
      (assignment.userData.studentUserData.status === 'submitted' ||
        assignment.userData.studentUserData.status === 'late')
    );
  }

  private isLate() {
    const { assignment } = this.getProps();
    const diff = datetime.diff(
      datetime.fromUnix(assignment.details.dueDateTime),
      datetime.now(),
      'hours'
    );
    return diff < 0;
  }
  private getWidgetColor(assignment: IAssignment) {
    const role = courseService.getRole();
    if (role === 'student') {
      const diff = datetime.diff(
        datetime.fromUnix(assignment.details.dueDateTime),
        datetime.now(),
        'hours'
      );
      const isDueDateSoon = diff < 24;
      const inPast = diff < 0;
      if (assignment.userData.studentUserData) {
        if (['submitted', 'late'].indexOf(assignment.userData.studentUserData.status) > -1) {
          return '#bababa';
        } else {
          // unsubmitted
          if (inPast) {
            return colors.red;
          }
          if (isDueDateSoon) {
            return colors.pink;
          } else {
            return colors.blue;
          }
        }
      } else {
        // unseen
        if (inPast) return colors.red;
        if (isDueDateSoon) {
          return colors.pink;
        } else {
          return colors.blue;
        }
      }
    } else {
      // for team members
      if (assignment.details.published) {
        return '#bababa';
      } else {
        return colors.red;
      }
    }
  }
}
