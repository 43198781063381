import cn from 'classnames';

import { CSS, h, IComponent } from 'core';

import { Loader } from 'acadly/common/Loader';

interface CtaButtonProps {
  label: string;
  type?: 'submit' | 'button';
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  variant?: 'blue' | 'green' | 'red' | 'grey';
  style?: CSS;
  onClick?: () => any;
}

interface CtaButtonState {
  isLoading: boolean;
}

class CtaButtonC extends IComponent<CtaButtonProps, CtaButtonState> {
  public componentWillMount() {
    const initialState: CtaButtonState = {
      isLoading: false,
    };
    this.setState(initialState);
  }

  public render() {
    const {
      label,
      disabled,
      className,
      type,
      isLoading,
      style,
      variant = 'blue',
    } = this.getProps();
    const { isLoading: isLoadingState } = this.getState();

    return h(
      'button.cta-button',
      {
        type,
        className: cn(className, variant),
        disabled: disabled || isLoading,
        style,
        onClick: () => this.handleClick(),
      },
      [isLoading || isLoadingState ? Loader() : label]
    );
  }

  private async handleClick() {
    const { disabled, onClick } = this.getProps();
    const { isLoading } = this.getState();

    if (disabled || isLoading || !onClick) return;

    this.setState({ isLoading: true });
    await onClick();
    this.setState({ isLoading: false });
  }
}

const CtaButton = (props: CtaButtonProps) => h(CtaButtonC, props);

export default CtaButton;
