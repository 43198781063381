import { h, IComponent } from 'core';

import { api as urls } from 'acadly/api';
import { Actions as AppActions } from 'acadly/app/actions';
import { Actions as appActions } from 'acadly/app/actions';
import { AnalyticsHeader } from 'acadly/app/AnalyticsHeader';
import { Actions as ClassActions, IExportGradesSuccessResponse } from 'acadly/class/actions';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Avatar from 'acadly/common/Avatar';
import ContentView from 'acadly/common/ContentView';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import FloatingActionButton from 'acadly/common/FloatingActionButton';
import { fullScreenLoader, Loader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import RaisedButton from 'acadly/common/RaisedButton';
import StudentList, { getStudentListData } from 'acadly/common/StudentList';
import TextField from 'acadly/common/TextField';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import User from 'acadly/common/User';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import { dispatch, getStore } from 'acadly/store';
import { backgroundColor, colors, margin, mb, ml, mr, mt, pad, style } from 'acadly/styles';
import * as u from 'acadly/utils';

import Icon from '../common/Icon';
import icons from '../icons';
import * as Actions from './actions';
import * as api from './api';

export interface IAssignmentAnalyticsProps {
  assignment: IAssignment;
}
export interface IAssignmentAnalyticsState {
  loadedSubmission?: api.IFetchSubmissionResponse & { submittedOn: Date };
  screen: 'main' | 'submission';
  gradingForm: ObjectMap<{
    points: number;
    comments: string;
    hasError: boolean;
  }>; // per question,
  isGradeSaveConfirmDialogOpen: boolean;
  isGradeSubmitConfirmDialogOpen: boolean;
  isExportDialogOpen: boolean;
  exportDownloadLink: string;
  exportRequestIsEmail: 0 | 1;
  sendEmail: boolean;
  exportFileName: string;
  isDownloadAlertOpen: boolean;
  searchField: string;
  sortStudentBy: StudentSortBy;
}
export class AssignmentAnalytics extends IComponent<
  IAssignmentAnalyticsProps,
  IAssignmentAnalyticsState
> {
  public componentWillMount() {
    const state = getStore().getState();
    const initialState: IAssignmentAnalyticsState = {
      screen: 'main',
      gradingForm: {},
      isGradeSaveConfirmDialogOpen: false,
      isGradeSubmitConfirmDialogOpen: false,
      isExportDialogOpen: false,
      exportDownloadLink: '',
      exportRequestIsEmail: 0,
      sendEmail: false,
      exportFileName: '',
      isDownloadAlertOpen: false,
      searchField: '',
      sortStudentBy: state.app.sortStudentBy,
    };
    this.setState(initialState);
    if (this.numSubmitted() > 0) {
      dispatch(Actions.teamAnalyticsFetch(this.assignment()._id));
    }
    dispatch(appActions.startTip(true));
  }

  public componentWillUnmount() {
    if (this.getState().screen === 'submission') {
      dispatch(AppActions.popAnalyticsBackStack(undefined));
    }
  }

  public componentWillUpdate(_: IAssignmentAnalyticsProps, nextState: IAssignmentAnalyticsState) {
    const state = this.getState();
    if (state.screen === 'submission' && nextState.screen === 'main') {
      setTimeout(() => {
        dispatch(AppActions.popAnalyticsBackStack(undefined));
      });
    } else if (state.screen === 'main' && nextState.screen === 'submission') {
      setTimeout(() => {
        dispatch(AppActions.pushToAnalyticsBackStack(() => this.closeGradingScreen()));
      });
    }
  }

  public render() {
    const role = courseService.getRole();
    if (!role) return fullScreenLoader;
    return ContentView(
      h('div.analytics__content', [role !== 'student' ? this.teamView() : this.studentView()])
    );
  }

  private teamView() {
    const submissions = getStore().getState().assignment.teamAnalytics;
    return h('div', [
      h('div.cell.cell--full-width', [
        h('span.fs-lg.fc-dark-blue', 'Total submissions'),
        h('span.fc-light-grey', this.numSubmitted().toString()),
      ]),
      h('div.cell.cell--full-width', [
        h('span.fs-lg.fc-dark-blue', 'Submissions graded'),
        h('span.fc-light-grey', this.numGraded().toString()),
      ]),
      h('div.cell.cell--full-width', [
        h('span.fs-lg.fc-dark-blue', 'Average score'),
        h('span.fc-light-grey', `${this.getAverageScore()}%`),
      ]),
      this.numSubmitted() === 0
        ? null
        : RaisedButton(
            [
              Icon(icons.export2, { className: 'analytics__export__icon' }),
              h('span.analytics__export__label', 'Export assignment data as CSV'),
            ],
            {
              tabIndex: 0,
              id: 'export-assignment-button',
              ariaLabel: 'Export assignment data as CSV',
              onclick: () => this.openExportDialog(),
              classNames: ['analytics__export'],
            }
          ),
      h('div.analytics__header', 'Submissions'),
      h('div.analytics__sub-header', `As of ${dt.format(dt.now(), 'hh:mm a on Do MMM')}`),
      this.numSubmitted() === 0
        ? h('div.fc-light-grey', [
            'Once the students start submitting the ',
            'assignment, you will be able to access ',
            'and grade their submissions here. TAs can ',
            'grade assignments too.',
          ])
        : h('div', style(['flex']), [
            submissions
              ? this.submissionList(submissions.submittedBy)
              : Loader(style([ml('auto'), mr('auto')]).style),
          ]),
      this.submissionView(),
      this.exportDialog(),
      this.downloadAlert(),
      this.numSubmitted() !== 0
        ? TipOverlayWrapper({
            targetElement: 'export-assignment-button',
            tip: {
              tipPosition: 'bottom',
              tipText:
                'You can export the students’ scores and participation' +
                ' in this activity, in a CSV format by using this button',
            },
            tipKey: 'activityAnalyticsExport',
            isNextAvailable: false,
          })
        : null,
    ]);
  }

  private submissionList(submissions: ITeamAssignmentAnalyticsSubmission[]) {
    const assignment = this.getProps().assignment;
    const { searchField, sortStudentBy } = this.getState();

    const renderSubmission = (sub: ITeamAssignmentAnalyticsSubmission) => {
      const submittedOn = dt.fromUnix(sub.status.submittedOn);
      const maxMarks = assignment.details.maxMarks;

      return User(
        {
          avatar: {
            url: sub.identifiers.avatar,
            creator: sub.identifiers.name,
          },
          title: sub.identifiers.name,
          titleClassNames: ['fc-green'],
          subtitle:
            this.getScore(sub) !== null
              ? `Graded score: ${this.getScore(sub)}/${maxMarks}`
              : `Submitted: ${dt.format(submittedOn, 'MMMM DD, YYYY [at] HH:mm')}`,
          subtitleClassNames: [this.getScore(sub) !== null ? 'fc-orange' : 'fc-green'],
        },
        {
          key: sub.identifiers.userId,
          className: 'student',
          onclick: () => this.loadSubmission(sub.identifiers.userId, submittedOn),
        }
      );
    };

    return StudentList({
      style: { paddingBottom: '2rem' },
      sortBy: sortStudentBy,
      children: getStudentListData(
        submissions,
        searchField,
        sortStudentBy,
        (s) => s.identifiers.name
      ).map(renderSubmission),
      onSearch: (term) => {
        this.setState({
          searchField: term,
        });
      },
      onSort: (sortBy) => {
        this.setState({
          sortStudentBy: sortBy,
        });
      },
    });
  }

  private getScore(sub: ITeamAssignmentAnalyticsSubmission): number | null {
    if (sub.status.graded && sub.status.graded) {
      return sub.status.graded.score;
    } else return null;
  }

  private async loadSubmission(userId: string, submittedOn: Date) {
    await this.setState({
      screen: 'submission',
      isGradeSaveConfirmDialogOpen: false,
      isGradeSubmitConfirmDialogOpen: false,
    });
    const response = await api.fetchSubmission(this.assignment()._id, userId);
    const submission = response.data;
    const questions = this.questions() || [];
    await this.setState({
      loadedSubmission: {
        ...submission,
        submittedOn,
      },
      gradingForm: u.mapValues(u.makeObjectWithKey(questions, '_id'), (q) =>
        submission.submission.submission[q._id]
          ? {
              points: submission.submission.submission[q._id]!.marks,
              comments: submission.submission.submission[q._id]!.comments.content,
              hasError: false,
            }
          : {
              points: 0,
              comments: '',
              hasError: false,
            }
      ),
    });
  }

  private submissionView() {
    const { screen, loadedSubmission, gradingForm } = this.getState();
    const questions = this.questions();
    const submission = this.getSubmission();
    const grades = submission ? submission.status.graded : null;
    const renderQuestion = (q: IAssignmentQuestion, i: number) => {
      if (!loadedSubmission) return null;
      const submission = loadedSubmission.submission.submission[q._id];
      const retractedSubmissions = submission && submission.prevSub ? submission.prevSub : [];
      return h('div.assignment-analytics__question', [
        h('div.cell.cell--full-width.fs-lg.fc-dark-blue', `Question ${i + 1}`),

        h('div.cell.cell--full-width.fs-lg', [
          h('span.fc-dark-blue', 'Submission'),
          submission && (submission.file || submission.url)
            ? null
            : h('span.fc-red', 'Not attempted'),
        ]),
        submission && submission.file
          ? AttachmentViewer({
              hideNameExtension: true,
              attachment: {
                name: submission.file.name,
                extension: submission.file.extension,
                originalName: 'Submitted file',
              },
              downloadRequest: {
                assignmentId: this.assignment()._id,
                questionId: q._id,
                studentId: loadedSubmission.submission.identifiers.userId,
                fileName: submission.file.name,
                prevSubmission: submission.prevSub ? 1 : 0,
                attemptNum: submission.prevSub ? submission.prevSub.length + 1 : undefined,
              },
              downloadUrl: urls().assignmentDownStuSub,
              style: { marginBottom: '0.5em' },
            })
          : submission && submission.url
          ? h(
              'a',
              {
                href: u.convertLinkToURL(submission.url.url),
                target: '_blank',
              },
              submission.url.url
            )
          : null,

        retractedSubmissions.length
          ? h('div.cell.cell--full-width.fs-lg', [
              h('div.fc-dark-blue', 'Retracted submissions'),
              ...retractedSubmissions.map((s, i) =>
                s.file
                  ? h('div', [
                      AttachmentViewer({
                        style: {
                          marginBottom: '0.5em',
                        },
                        attachment: s.file,
                        downloadUrl: urls().assignmentDownStuSub,
                        downloadRequest: {
                          assignmentId: this.assignment()._id,
                          questionId: q._id,
                          studentId: loadedSubmission.submission.identifiers.userId,
                          fileName: s.file.name,
                          attemptNum: retractedSubmissions.length - i,
                          prevSubmission: 1,
                        },
                      }),
                      h(
                        'div',
                        style(['lightGrey', mb('1em')]),
                        `Submitted: ${dt.format(
                          dt.fromUnix(s.submittedOn),
                          'MMM Do YYYY [at] hh:mm A'
                        )}`
                      ),
                    ])
                  : s.url
                  ? h(
                      'a.link',
                      {
                        style: style([
                          pad('0.5rem 0rem'),
                          margin('0rem 0.5rem'),
                          'flex',
                          {
                            textDecoration: 'none',
                          },
                        ]).style,
                        href: u.convertLinkToURL(s.url),
                        target: '_blank',
                      },
                      [h('div.link-info', [h('div', style(['blue', 'large']), s.url)])]
                    )
                  : null
              ),
            ])
          : null,

        h('div.cell.cell--full-width.fs-lg', [
          h('span.fc-dark-blue', 'Points awarded'),
          h('span.fc-light-grey', [
            grades && submission
              ? h('span.marks', `${submission.marks.toString()}`)
              : TextField({
                  value: gradingForm[q._id].points.toString(),
                  type: 'number',
                  min: 0,
                  max: q.details.marks,
                  oninput: (e) =>
                    this.setState({
                      gradingForm: {
                        ...gradingForm,
                        [q._id]: {
                          ...gradingForm[q._id],
                          points: parseInt(e.target.value),
                          hasError: e.target.value.length < 1,
                        },
                      },
                    }),
                  errorText: gradingForm[q._id].hasError ? 'Empty' : undefined,
                  noHintOrError: true,
                  style: {
                    width: '2em',
                    marginRight: '0.3em',
                  },
                  inputStyle: {
                    marginTop: '0.25rem',
                    fontSize: '1em',
                    color: colors.grey,
                    textAlign: 'right',
                    paddingTop: '0rem',
                    paddingBottom: '0.15rem',
                  },
                }),
            `/ ${q.details.marks}`,
          ]),
        ]),
        h('div.cell.cell--full-width.fs-lg.fc-dark-blue', 'Comments'),
        grades
          ? h(
              'div',
              {
                style: {
                  whiteSpace: 'pre-line',
                  color: colors.lightGrey,
                  paddingLeft: '0.5em',
                },
              },
              gradingForm[q._id].comments
            )
          : h(
              'textarea',
              style(
                [
                  {
                    width: '100%',
                    background: 'none',
                    fontSize: '1em',
                    color: 'rgba(0,0,0,.5)',
                    resize: 'none',
                    border: `1px solid ${colors.lightGrey}`,
                    padding: '0.5em',
                    boxSizing: 'border-box',
                    outline: 'none',
                  },
                ],
                {},
                {
                  value: gradingForm[q._id].comments,
                  oninput: (e: any) =>
                    this.setState({
                      gradingForm: {
                        ...gradingForm,
                        [q._id]: {
                          ...gradingForm[q._id],
                          comments: e.target.value,
                        },
                      },
                    }),
                }
              )
            ),
        grades
          ? h('div.user', [
              Avatar(grades.lastGradedBy.avatar, grades.lastGradedBy.name, {
                className: 'user__avatar',
              }),
              h('div.user__details', [
                h('div.fc-green', grades.lastGradedBy.name),
                h('div.fc-light-grey', [
                  'Graded on: ',
                  dt.format(dt.fromUnix(grades.on), 'DD-MMM-YYYY'),
                ]),
              ]),
            ])
          : null,
      ]);
    };
    const body = () =>
      loadedSubmission && questions
        ? h('div.analytics__content', [
            h('div.analytics__user.user', [
              Avatar(
                loadedSubmission.submission.identifiers.avatar,
                loadedSubmission.submission.identifiers.name,
                {
                  className: 'user__avatar',
                }
              ),
              h('div.user__details', [
                h('div.fc-green', loadedSubmission.submission.identifiers.name),
                h(
                  'div',
                  `Submitted: ${dt.format(
                    loadedSubmission.submittedOn,
                    'MMMM DD, YYYY [at] HH:mm'
                  )}`
                ),
              ]),
            ]),
            ...questions.map(renderQuestion),

            Alert(
              {
                open: this.getState().isGradeSubmitConfirmDialogOpen,
                overlayStyle: {
                  backgroundColor: colors.overlayGreen,
                },
                style: {
                  width: '20em',
                },
                actions: [
                  FlatButton('NO', {
                    type: 'secondary',
                    onclick: () =>
                      this.setState({
                        isGradeSubmitConfirmDialogOpen: false,
                      }),
                  }),
                  FlatButton('SUBMIT', {
                    onclick: () => this.submitGrades(),
                  }),
                ],
              },
              [
                h('div', 'Once you submit the grades, you cannot edit them.'),
                h('div', 'Are you sure you want to submit?'),
              ]
            ),
          ])
        : fullScreenLoader;
    const isMobile = getStore().getState().app.isMobile;
    if (isMobile) {
      const submission = this.getSubmission();
      return Dialog(
        {
          open: this.getState().screen === 'submission',
          title: 'Submission',
          style: {
            backgroundColor,
          },
          bodyStyle: {
            padding: '0',
          },
          secondaryAction: {
            label: 'BACK',
            mobileLabel: h('i.fa.fa-arrow-left'),
            onclick: () => this.gradingBack(),
          },
          primaryAction:
            !submission || submission.status.graded
              ? undefined
              : {
                  label: 'SUBMIT',
                  mobileLabel: h('i.fa.fa-check'),
                  onclick: () => this.openSubmitGradesDialog(),
                  disabled: !!u.objectValues(gradingForm).find((g) => g.hasError),
                },
        },
        [
          body(),
          Alert(
            {
              open: this.getState().isGradeSaveConfirmDialogOpen,
              style: {
                width: '20em',
              },
              actions: [
                FlatButton('NO', {
                  type: 'secondary',
                  onclick: () => {
                    this.closeGradingScreen();
                  },
                }),
                FlatButton('YES', {
                  onclick: () => this.saveGrades(),
                }),
              ],
            },
            ['Do you want to save your progress?']
          ),
        ]
      );
    }
    if (screen !== 'submission') return null;
    return h(
      'div.submission-view',
      style(['absolute', 'fullWidth'], {
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor,
      }),
      [
        AnalyticsHeader({
          label: 'Student Submission',
        }),
        !(questions && loadedSubmission) ? fullScreenLoader : null,
        ContentView(
          questions && loadedSubmission
            ? h('div', style([mb('1em'), mt('0.5rem'), mb('3em')]), [
                body(),
                !grades
                  ? FloatingActionButton(
                      {
                        position: 'bottom-right',
                        style: {
                          transform: 'translateY(-120%)',
                        },
                        onclick: () => this.saveGrades(),
                      },
                      [h('i.fa.fa-floppy-o', {})]
                    )
                  : null,
                !grades
                  ? FloatingActionButton(
                      {
                        position: 'bottom-right',
                        onclick: () => this.openSubmitGradesDialog(),
                        disabled: !!u.objectValues(gradingForm).find((g) => g.hasError),
                      },
                      [h('i.fa.fa-check', {})]
                    )
                  : null,
              ])
            : null
        ),
      ]
    );
  }

  private downloadAlert() {
    const isOpen = this.getState().isDownloadAlertOpen;
    const fileName = this.getState().exportFileName;
    const downloadLink = this.getState().exportDownloadLink;
    const styles = {
      display: 'flex',
      width: '20em',
      borderRadius: '4px',
      backgroundColor: 'white',
      alignItems: 'center',
      border: `1px solid ${colors.lightestGrey}`,
      maxWidth: '100%',
      lineHeight: '1.5em',
      boxSizing: 'border-box',
      paddingRight: '0.5rem',
    };

    return Alert(
      {
        title: 'Success',
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('Done', {
            onclick: () => this.backClickHandler(),
          }),
        ],
      },
      [
        h('div', style([pad('0.5rem 0.5rem 0.5rem 0rem')]), 'File Generated'),
        h(
          'div.acadly-attachment',
          {
            style: styles,
          },
          [
            h(
              'div.attachment-icon',
              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '0.5rem',
                  // fontSize: "0.8em",
                  width: '2.5rem',
                  color: 'white',
                  height: '2.5rem',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  backgroundColor: colors.green,
                },
              },
              'CSV'
            ),
            h(
              'div',
              style([
                'thin',
                {
                  flex: 1,
                  color: colors.black,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: '0.5em',
                },
              ]),
              [fileName]
            ),
            h(
              'a',
              {
                style: {
                  color: colors.green,
                },
                target: '_blank',
                href: downloadLink || undefined,
              },
              [
                Icon(icons.download, {
                  style: style(['blue', 'large', 'pointer', pad('0.5rem')]).style,
                  ariaLabel: 'Download',
                }),
              ]
            ),
          ]
        ),
      ]
    );
  }

  private exportDialog() {
    const isOpen = this.getState().isExportDialogOpen;
    const isEmail = this.getState().exportRequestIsEmail;
    const sendEmail = this.getState().sendEmail;
    const option = (opts: { selected: boolean; label: string; key: string; onclick: () => any }) =>
      h(
        'div',
        style(
          ['flex', 'alignCenter'],
          {},
          {
            onclick: opts.onclick,
            key: opts.key,
          }
        ),
        [
          RadioButton({
            selected: opts.selected,
            color: colors.teal,
          }),
          h(
            'div',
            style([
              pad('0.5rem 1rem'),
              ml('0.5rem'),
              {
                flex: '1',
                borderBottom: '1px solid lightGrey',
              },
            ]),
            opts.label
          ),
        ]
      );
    return Alert(
      {
        title: !sendEmail ? 'Exporting Assignment Data' : 'Success',
        titleStyle: {
          textAlign: 'center',
        },
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton(!sendEmail ? 'Cancel' : 'Download a copy', {
            type: 'secondary',
            onclick: !sendEmail
              ? () => this.backClickHandler()
              : () => this.downloadACopyClickHandler(),
          }),
          FlatButton('Done', {
            onclick: !sendEmail ? () => this.exportClickHandler() : () => this.backClickHandler(),
          }),
        ],
      },
      [
        sendEmail
          ? h(
              'div',
              'The exported file has been sent to your registered' +
                " email address. In case you can't spot it, please" +
                ' look into the Spam folder'
            )
          : h('div', {}, [
              option({
                label: 'Download CSV file now',
                selected: isEmail === 0 ? true : false,
                key: 'no-email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 0,
                  }),
              }),
              option({
                label: 'Email file as attachment',
                selected: isEmail === 1 ? true : false,
                key: 'email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 1,
                  }),
              }),
            ]),
      ]
    );
  }

  private async downloadACopyClickHandler() {
    await this.setState({
      isDownloadAlertOpen: true,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }
  private async backClickHandler() {
    await this.setState({
      isDownloadAlertOpen: false,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }
  private async exportClickHandler() {
    const email = this.getState().exportRequestIsEmail;

    const response = await dispatch(
      ClassActions.exportGrades({
        activityType: 'assignments',
        activityId: this.assignment()._id,
        email: email,
      })
    );
    this.setFileData(response);
    if (email === 1) {
      await this.setState({
        sendEmail: true,
      });
    } else {
      await this.setState({
        isExportDialogOpen: false,
        isDownloadAlertOpen: true,
      });
    }
  }

  private async setFileData(response: IExportGradesSuccessResponse) {
    await this.setState({
      exportDownloadLink: response.url,
      exportFileName: response.filename,
    });
  }

  private async openExportDialog() {
    await this.setState({
      isExportDialogOpen: true,
    });
  }

  private getSubmission() {
    const { loadedSubmission } = this.getState();
    const teamAnalytics = getStore().getState().assignment.teamAnalytics;
    return loadedSubmission && teamAnalytics
      ? teamAnalytics.submittedBy.find(
          (s) => s.identifiers.userId === loadedSubmission.submission.identifiers.userId
        )
      : null;
  }

  private async gradingBack() {
    const submission = this.getSubmission();
    if (!submission || submission.status.graded) {
      await this.closeGradingScreen();
    } else {
      await this.setState({
        isGradeSaveConfirmDialogOpen: true,
      });
    }
  }

  private async openSubmitGradesDialog() {
    await this.setState({
      isGradeSubmitConfirmDialogOpen: true,
    });
  }

  private async closeGradingScreen() {
    await this.setState({
      loadedSubmission: undefined,
      isGradeSaveConfirmDialogOpen: false,
      isGradeSubmitConfirmDialogOpen: false,
      screen: 'main',
    });
  }

  private async submitGrades() {
    const { gradingForm, loadedSubmission } = this.getState();
    if (!loadedSubmission) return;
    const questions = this.questions()!;
    await dispatch(
      Actions.gradesSubmit({
        assignmentId: this.assignment()._id,
        studentId: loadedSubmission.submission.identifiers.userId,
        grades: u.objectValues(
          u.mapValues(gradingForm, (grade, qId) => ({
            questionId: qId,
            maxMarks: questions.find((q) => q._id === qId)!.details.marks,
            marks: grade.hasError ? 0 : grade.points,
            comments: {
              content: grade.comments,
            },
          }))
        ),
      })
    );
    await this.closeGradingScreen();
  }

  private async saveGrades() {
    const { gradingForm, loadedSubmission } = this.getState();
    if (!loadedSubmission) return;
    const questions = this.questions();
    if (!questions) {
      throw new Error('Tried to save assignment grades but questions not loaded');
    }
    await dispatch(
      Actions.gradesSave({
        assignmentId: this.assignment()._id,
        studentId: loadedSubmission.submission.identifiers.userId,
        grades: u.objectValues(
          u.mapValues(gradingForm, (grade, qId) => ({
            questionId: qId,
            maxMarks: questions.find((q) => q._id === qId)!.details.marks,
            marks: grade.hasError ? 0 : grade.points,
            comments: {
              content: grade.comments,
            },
          }))
        ),
      })
    );
    await this.closeGradingScreen();
  }

  private getAverageScore() {
    const submissions = getStore().getState().assignment.teamAnalytics;
    if (!submissions) return 0;
    const gradedSubmissions = submissions.submittedBy.filter((sub) => sub.status.graded);
    const totalGraded = gradedSubmissions.length;
    const totalScore = gradedSubmissions.reduce<number>(
      (sum, currentSub) => sum + currentSub.status.graded!.score,
      0
    );
    const questions = this.questions();
    if (!questions) return 0;
    const maxScore = questions.reduce((sum, q) => sum + q.details.marks, 0);
    if (totalGraded > 0) {
      const percentage = (totalScore * 100) / (maxScore * totalGraded);
      return Math.round(percentage * 100) / 100;
    } else {
      return 0;
    }
  }

  private numGraded() {
    const assignment = this.assignment();
    if (!assignment.stats) return 0;
    return assignment.stats.numGraded;
  }

  private numSubmitted() {
    const assignment = this.assignment();
    if (!assignment.stats) return 1;
    return assignment.stats.numSubmitted;
  }

  private studentView() {
    return null;
  }

  private assignment() {
    return this.getProps().assignment;
  }

  private questions() {
    return getStore().getState().assignment.currentAssignmentQuestions;
  }
}

export default (props: IAssignmentAnalyticsProps) => h(AssignmentAnalytics, props);
