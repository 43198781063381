import { h, IComponent } from 'core';
import { jsonRequest } from 'core/http';

import { api as urls } from 'acadly/api';
import { appVersion } from 'acadly/appVersion';
import Dialog from 'acadly/common/Dialog';
import TextField from 'acadly/common/TextField';
import { colors, mt, style } from 'acadly/styles';

export interface IFeedbackDialogProps {
  open: boolean;
  key?: string;
  close: () => void;
}
export class FeedbackDialog extends IComponent<
  IFeedbackDialogProps,
  {
    subjectField: string;
    bodyField: string;
  }
> {
  public componentWillMount() {
    this.setState({
      subjectField: '',
      bodyField: '',
    });
  }

  public componentWillReceiveProps(nextProps: IFeedbackDialogProps) {
    if (this.getProps().open !== nextProps.open) {
      this.setState({
        subjectField: '',
        bodyField: '',
      });
    }
  }

  public render() {
    const props = this.getProps();
    const state = this.getState();
    return Dialog(
      {
        open: props.open,
        title: 'Send a message to the Acadly team',
        style: {
          width: '35em',
        },
        bodyStyle: {
          padding: '0.5rem 1rem',
        },
        primaryAction: {
          label: 'SUBMIT',
          mobileLabel: h('i.fa.fa-check'),
          disabled: state.bodyField.length < 1 || state.subjectField.length < 1,
          onclick: () => this.submit(),
        },
        secondaryAction: {
          label: 'BACK',
          mobileLabel: h('i.fa.fa-times'),
          onclick: () => this.getProps().close(),
        },
      },
      props.open
        ? [
            h('div', style(['flex', mt('0.5em'), 'alignCenter']), [
              h(
                'div',
                style([
                  {
                    flex: '1',
                  },
                ]),
                [
                  h('span', style(['darkBlue', 'large']), 'Subject '),
                  h('span', style(['lightGrey']), [
                    `(${140 - state.subjectField.length} characters left)`,
                  ]),
                ]
              ),
              TextField({
                value: state.subjectField,
                oninput: (e) =>
                  this.setState({
                    subjectField: e.target.value,
                  }),
                ariaLabel: 'Feedback Subject',
                maxLength: 140,
                noHintOrError: true,
                style: { flex: 1.5 },
                onenter: () => {},
              }),
            ]),

            h('div', style([mt('0.5em'), 'darkBlue', 'large']), ['Body']),
            h('textarea', {
              value: state.bodyField,
              oninput: (event: any) =>
                this.setState({
                  bodyField: event.target.value,
                }),
              'aria-label': 'Feedback Body',
              style: {
                marginTop: '0.5em',
                fontSize: '1em',
                width: '100%',
                height: '10em',
                resize: 'none',
                outline: 'none',
                border: 'none',
                borderBottom: `2px solid ${colors.lightGrey}`,
              },
            }),
          ]
        : []
    );
  }

  private async submit() {
    const { bodyField, subjectField } = this.getState();
    if (bodyField.length < 1) return;
    if (subjectField.length < 1) return;
    await jsonRequest(urls().feedback, {
      method: 'POST',
      data: {
        subject: subjectField,
        body: bodyField,
        agent: 'web',
        version: appVersion,
        os: window.navigator.platform,
        browser: window.navigator.userAgent,
      },
    });
    await this.getProps().close();
  }
}

export default (props: IFeedbackDialogProps) => h(FeedbackDialog, props);
