import { h, IComponent } from 'core';

import * as BulbIcon from 'assets/bulb.svg';

import QuickStartTips from 'acadly/common/QuickStartTips';
import SvgIcon from 'acadly/common/SvgIcon';

class ClassAnalyticsStartTips extends IComponent<never, never> {
  public render() {
    return QuickStartTips({
      qstKey: 'qst-class-analytics',
      children: ({ wrapper }) => wrapper(this.body()),
    });
  }

  private body = () => {
    return h('div.quick-start-tips__body', [
      h('div.quick-start-tips__section-heading', 'analytics'),
      h('p.quick-start-tips__section-content', [
        'This is the Class Analytics section - Class In-charge and Assistant ',
        'can award Participation Points to students for offline questions, ',
        'presentations, answers etc using this section',
      ]),
      h('div.quick-start-tips__section-heading', [
        SvgIcon({
          noFill: true,
          icon: BulbIcon,
          className: 'quick-start-tips__icon sm',
          style: { marginRight: '0.5rem' },
        }),
        'tags',
      ]),
      h('p.quick-start-tips__section-content', [
        'Each time participation points are awarded to a student, the awarder ',
        'can choose to provide a tag that explains why the points were awarded. ',
        'These tags are reusable throughout the course',
      ]),
    ]);
  };
}

export default () => h(ClassAnalyticsStartTips);
