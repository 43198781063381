import { CSS, h, IComponent, View } from 'core';

type Position = 'top' | 'right' | 'bottom' | 'left';

interface TooltipProps {
  text: string;
  targetElement: View | View[];
  styles?: CSS;
  tooltipStyles?: CSS;
  position?: Position;
}

interface TooltipState {
  show: boolean;
}

class Tooltip extends IComponent<TooltipProps, TooltipState> {
  public componentWillMount() {
    this.setState({ show: false });
  }

  public render() {
    const {
      targetElement: target,
      text,
      position = 'top',
      styles = {},
      tooltipStyles = {},
    } = this.getProps();
    const { show } = this.getState();
    return h(
      'div',
      {
        onmouseenter: () => this.setState({ show: true }),
        onmouseleave: () => this.setState({ show: false }),
        style: {
          position: 'relative',
          ...styles,
        },
      },
      [
        ...(target instanceof Array ? target : [target]),
        show && text
          ? h(
              'div',
              {
                style: {
                  background: 'rgba(0, 0, 0, 0.9)',
                  color: '#fff',
                  position: 'absolute',
                  borderRadius: '4px',
                  padding: '0.25rem 0.5rem',
                  lineHeight: 1.5,
                  ...tooltipStyles,
                  ...this.getPositionStyle(position),
                },
              },
              text
            )
          : null,
      ]
    );
  }

  private getPositionStyle(position: Position) {
    // TODO: only top is tested, test others also
    switch (position) {
      case 'top':
        return { bottom: 'calc(100% + 0.25rem)' };
      case 'bottom':
        return { top: 'calc(100% + 0.25rem)' };
      case 'left':
        return { right: 'calc(100% + 0.25rem)' };
      case 'right':
        return { left: 'calc(100% + 0.25rem)' };
    }
    return { bottom: 'calc(100% + 0.25rem)' };
  }
}

export default (props: TooltipProps) => h(Tooltip, props);
