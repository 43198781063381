import { getStore } from 'acadly/store';
import * as u from 'acadly/utils';

import IService from '../IService';

class QueryService extends IService {
  public getQueryIdFromShortId(shortId: string) {
    const queries = getStore().getState().queries.byId;
    const query = u.objectValues(queries).filter((q) => q._id.slice(18) === shortId)[0];
    if (query) {
      return query._id;
    } else {
      return null;
    }
  }
  public getShortIdFromQueryId(queryId: string) {
    return queryId.slice(18);
  }
}

export const queryService = new QueryService();
export default queryService;
