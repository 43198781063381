import { h, IComponent, View } from 'core';
import history from 'core/history';

import Alert from 'acadly/common/Alert';
import Avatar from 'acadly/common/Avatar';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import ImageCropper from 'acadly/common/ImageCropper';
import LoaderButton from 'acadly/common/LoaderButton';
import Select, { SelectType } from 'acadly/common/Select';
import TextField from 'acadly/common/TextField';
import UploadButton from 'acadly/common/UploadButton';
import icons from 'acadly/icons';
import { getStore } from 'acadly/store';
import { colors, mb, mr, mt, pad, style } from 'acadly/styles';

import { GetInElements, GetInFlow } from './Flow';

export class GetInScreen extends IComponent<never, Record<string, never>> {
  private vm: GetInFlow = new GetInFlow();

  public componentWillMount() {
    this.vm = new GetInFlow();
    this.vm.subscribe(() => this.setState({}));
    this.vm.setCurrentScreenByRoute(location.pathname);
    history.listen(async (location) => {
      this.vm.setCurrentScreenByRoute(location.pathname);
    });
  }

  public render() {
    const isMobile = getStore().getState().app.isMobile;
    if (isMobile) {
      return h(
        `div#${GetInElements.WRAPPER_ID}`,
        style([
          'flex',
          'column',
          'alignCenter',
          'borderBox',
          pad('1em 1em'),
          {
            width: '100%',
            height: '100%',
          },
        ]),
        this.body()
      );
    } else {
      return h(
        `div#${GetInElements.WRAPPER_ID}`,
        style([
          'flex',
          'justifyCenter',
          'alignCenter',
          {
            width: '100%',
            height: '100%',
            backgroundColor: colors.darkBlue,
          },
        ]),
        [
          h(
            'div#getin-box',
            style([
              'textCenter',
              'flex',
              'column',
              'alignCenter',
              pad('1em'),
              'borderBox',
              'relative',
              {
                backgroundColor: 'white',
                width: '30em',
                maxWidth: '100%',
                maxHeight: '100%',
                minHeight: '30em',
                borderRadius: '0.5em',
                paddingBottom: '1em',
                boxShadow: '#3b404d 1px 1px 5px 0px',
              },
            ]),
            this.body()
          ),
        ]
      );
    }
  }

  private overlay() {
    const { isLoading } = this.vm;
    if (isLoading) {
      return h(
        'div',
        style(
          ['flex', 'justifyCenter', 'alignCenter'],
          {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            transition: '0.5s all ease-in-out',
            zIndex: 10000,
            userSelect: 'none',
          },
          {
            onclick: (e) => e.preventDefault(),
          }
        )
      );
    } else {
      return null;
    }
  }

  private body() {
    return [
      this.overlay(), // To block user activities while loading
      h(
        'div',
        style([
          {
            maxHeight: '100%',
            width: '100%',
            overflowY: 'auto',
          },
        ]),
        [this.getSubScreen()]
      ),
    ];
  }

  private subScreenTemplate(
    title: string,
    subtitle: string | View[],
    content: View,
    secondaryAction?: View,
    primaryAction: View = null
  ) {
    const vm = this.vm;
    const padding = pad('0 1em');

    primaryAction =
      primaryAction ||
      LoaderButton(
        'NEXT',
        style(['pointer'], {
          padding: '1.25em 2em',
          border: 'none',
          borderRadius: '3px',
          background: vm.isLoading ? colors.white : colors.blue,
          color: colors.white,
        }).style,
        {
          isLoading: vm.isLoading,
          onclick: async () => await this.vm.onClickNext(),
        }
      );

    return h('div.getin-container', [
      h(
        'div',
        style(['flex', 'alignCenter'], {
          minHeight: '42px',
        }),
        [
          vm.isBackButtonVisible
            ? h(
                'i.ripple.fa.fa-arrow-left',
                style(
                  ['pointer', pad('1em')],
                  {
                    borderRadius: '50%',
                  },
                  {
                    tabIndex: 0,
                    'aria-label': 'go back',
                    onclick: async () => await vm.onClickBack(),
                  }
                )
              )
            : null,
        ]
      ),
      h('img.getin-logo', {
        src: 'https://s3.amazonaws.com/static.acad.ly/img/header-logo.png',
        style: {
          width: '10em',
          maxWidth: '50%',
          objectFit: 'contain',
          margin: 'auto',
          display: 'block',
          marginBottom: '2em',
          minHeight: '44px',
        },
      }),
      h(
        'div#getin-title.getin-title',
        style(['x-large', 'bold', 'textCenter', mb('1em'), padding]),
        title
      ),
      subtitle.length
        ? h(
            'div#getin-subtitle.getin-sub-title',
            style(['grey', 'large', 'textCenter', mb('1.5em'), padding]),
            subtitle
          )
        : null,
      h('div.getin-content', style(['textCenter', padding]), [content]),
      h(
        'div.getin-actions',
        style(['flex', 'alignCenter', mt('3em'), padding], {
          flexDirection: 'row-reverse',
        }),
        [
          this.vm.isNextButtonVisible ? primaryAction : null,
          h('div.spacer', { style: { flex: '1' } }),
          secondaryAction || null,
        ]
      ),
    ]);
  }

  private getSubScreen(): View {
    const vm = this.vm;
    switch (vm.screen) {
      case 'LOGIN_EMAIL':
        return this.loginEmailScreen();
      case 'PASSWORD':
        return this.passwordScreen();
      case 'VERIFICATION_AWAITED':
        return this.verificationAwaitedScreen();
      case 'FORGOT_PASSWORD':
        return this.forgotPasswordScreen();
      case 'SET_PASSWORD':
        return this.setPasswordScreen();
      case 'GET_ROLE':
        return this.getRoleScreen();
      case 'JOIN_CODE':
        return this.joinCourseScreen();
      case 'SIGNUP_EMAIL':
        return this.signupEmailScreen();
      case 'TEACHER_ROLE':
        return this.teacherRoleScreen();
      case 'THANK_YOU':
        return this.signupThankYouScreen();
      case 'TEMP_PASSWORD':
        return this.tempPasswordScreen();
      case 'SET_NAME':
        return this.setNameScreen();
      case 'SET_PROFILE':
        return this.setProfileScreen();
      default:
        return 'Unimplemented';
    }
  }

  private loginEmailScreen() {
    const vm = this.vm;

    const hasError = vm.userDoesNotExistsError || vm.emailError;
    const getError = () => {
      if (vm.emailError) return [h('span', style(['red']), 'Please enter a valid email address')];
      else if (vm.userDoesNotExistsError)
        return [
          h('span', style(['red', mr('0.5em')]), 'This email address cannot be found.'),
          h(
            'span',
            style(
              ['blue', 'pointer'],
              {},
              {
                tabIndex: 0,
                role: 'link',
                onclick: () => vm.gotoSignup(),
              }
            ),
            'Sign up instead?'
          ),
        ];
      return '';
    };

    return this.subScreenTemplate(
      'Log in',
      'Please enter your registered email address',
      h(
        'form.email-form',
        style(
          [mb('-1em')],
          {},
          {
            onsubmit: async () => await this.vm.onClickNext(),
          }
        ),
        [
          TextField({
            hasError,
            id: GetInElements.LOGIN_EMAIL_INPUT,
            ariaLabelledBy: hasError ? 'getin-error' : 'getin-title getin-subtitle',
            value: vm.emailField,
            type: 'email',
            focusOnMount: true,
            selectTextOnFocus: true,
            center: true,
            placeholder: 'Email address',
            floatingLabelText: 'Email address',
            oninput: (event) => vm.onEmailInput(event.target.value.toLowerCase()),
            onenter: async () => await this.vm.onClickNext(),
          }),
          h(
            'div#getin-error',
            style(
              [mt('1em')],
              {
                visibility: hasError ? 'visible' : 'hidden',
              },
              {
                'aria-live': 'polite',
              }
            ),
            getError()
          ),
        ]
      ),
      h(
        'div',
        style(
          ['textCenter', 'pointer', 'lightBlue'],
          {},
          {
            tabIndex: 0,
            role: 'link',
            onclick: () => vm.gotoSignup(),
          }
        ),
        'Create a new account?'
      )
    );
  }

  private userDetails() {
    const vm = this.vm;
    const name = vm.profileCompleted ? vm.userName : vm.email;
    return h(
      'div',
      style([mb('1em')], {
        textAlign: 'left',
        display: 'inline-flex',
      }),
      [
        Avatar(vm.avatar, vm.userName, {
          className: 'getin-screen__avatar',
        }),
        h('div', style(['flex', 'column', 'spaceBetween']), [
          h('span', style([pad('0.2rem 0 0.5rem')]), name),
          h('span', style(['small', 'grey', pad('0 0 0.5rem')]), vm.universityName),
        ]),
      ]
    );
  }

  private passwordScreen() {
    const vm = this.vm;

    const hasError = vm.passwordError !== null;
    const getError = () => {
      if (vm.passwordError) return [h('span', style(['red']), vm.passwordError)];
      return '';
    };

    return this.subScreenTemplate(
      'Welcome back!',
      "What's the password?",
      h(
        'form.password-form',
        style(
          [mb('-2em')],
          {},
          {
            onsubmit: async () => await this.vm.onClickNext(),
          }
        ),
        [
          this.userDetails(),
          h('div', style(['relative']), [
            TextField({
              hasError,
              id: GetInElements.GET_PASSWORD_INPUT,
              ariaLabelledBy: hasError ? 'getin-error' : 'getin-title getin-subtitle',
              inputStyle: {
                margin: '0 2em',
                width: 'calc(100% - 4em)',
              },
              value: vm.passwordField,
              focusOnMount: true,
              selectTextOnFocus: true,
              type: vm.isPasswordVisible ? undefined : 'password',
              oninput: (event) => vm.onPasswordInput(event.target.value),
              onenter: async () => await this.vm.onClickNext(),
              center: true,
              floatingLabelText: 'Password',
              placeholder: 'Password',
            }),
            h(
              'div.ripple',
              {
                tabIndex: 0,
                role: 'button',
                'aria-live': 'polite',
                'aria-label': `click to ${vm.isPasswordVisible ? 'hide' : 'show'} password`,
                style: {
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  right: 0,
                  bottom: 0,
                  width: '2em',
                  height: '2em',
                  color: colors.lightGrey,
                  borderRadius: '50%',
                  zIndex: 1,
                },
                onclick: vm.togglePasswordVisible,
              },
              [Icon(vm.isPasswordVisible ? icons.eyeShut : icons.eyeOpen, style(['xx-large']))]
            ),
          ]),
          h(
            'div#getin-error',
            style(
              [mt('1em')],
              {
                visibility: hasError ? 'visible' : 'hidden',
              },
              {
                'aria-live': 'polite',
              }
            ),
            getError()
          ),
        ]
      ),
      h(
        'div',
        style(
          ['textCenter', 'pointer', 'lightBlue'],
          {},
          {
            tabIndex: 0,
            role: 'link',
            onclick: async () => await vm.onClickForgotPassword(),
          }
        ),
        'Forgot password?'
      )
    );
  }

  private verificationAwaitedScreen() {
    return this.subScreenTemplate(
      'Verification awaited',
      'We have not been able to verify you yet :(',
      h('div.verification-awaited-screen', [
        h('div', style([mt('1em')]), 'We would love to have you on Acadly as soon as possible.'),
        h(
          'div',
          style([mt('1em')]),
          `Having said that, we just sent a reminder to the
                    verification team, and you can expect a mail on
                    your registered Email ID welcoming you to Acadly shortly.`
        ),
        h('div', style([mt('1em')]), 'Thank you for your patience!'),
      ])
    );
  }

  private forgotPasswordScreen() {
    const vm = this.vm;

    const hasError = vm.tempPasswordError !== null;
    const getError = () => {
      if (vm.tempPasswordError) return [h('span', style(['red']), vm.tempPasswordError)];
      return '';
    };

    return this.subScreenTemplate(
      'Resetting Password',
      `Please enter the verification code emailed to you at ${vm.emailField}`,
      h(
        'form.temp-password-form',
        style(
          [mb('-2em')],
          {},
          {
            onsubmit: async () => await this.vm.onClickNext(),
          }
        ),
        [
          vm.userName ? this.userDetails() : null,
          TextField({
            hasError,
            id: GetInElements.FORGOT_PASSWORD_INPUT,
            ariaLabelledBy: hasError ? 'getin-error' : 'getin-title getin-subtitle',
            value: this.vm.tempPasswordField,
            floatingLabelText: 'Verification code',
            focusOnMount: true,
            selectTextOnFocus: true,
            center: true,
            placeholder: 'Enter verification code',
            oninput: (e) => this.vm.onOneTimePasswordInput(e.target.value),
            onenter: async () => await this.vm.onClickNext(),
          }),
          h(
            'div#getin-error',
            style(
              [mt('1em')],
              {
                visibility: hasError ? 'visible' : 'hidden',
              },
              {
                'aria-live': 'polite',
              }
            ),
            getError()
          ),
        ]
      ),
      h(
        'div',
        style(
          ['textCenter', 'pointer', 'lightBlue'],
          {},
          {
            tabIndex: 0,
            role: 'button',
            onclick: async () => await vm.onClickForgotPassword(),
          }
        ),
        'Resend verification code'
      )
    );
  }

  private setPasswordScreen() {
    const vm = this.vm;

    const hasError = vm.passwordError !== null;
    const getError = () => {
      if (vm.passwordError) return [h('span', style(['red']), vm.passwordError)];
      return '';
    };

    return this.subScreenTemplate(
      vm.isResettingPassword ? 'Resetting Password' : 'Set password',
      'Your password must be 8-32 characters long',
      h(
        'form.set-password-form',
        style(
          [mb('-2em')],
          {},
          {
            onsubmit: async () => await this.vm.onClickNext(),
          }
        ),
        [
          vm.userName ? this.userDetails() : null,
          h('div', style(['relative']), [
            TextField({
              hasError,
              id: GetInElements.SET_PASSWORD_INPUT,
              ariaLabelledBy: hasError ? 'getin-error' : 'getin-title getin-subtitle',
              inputStyle: {
                margin: '0 2em',
                width: 'calc(100% - 4em)',
              },
              value: vm.passwordField,
              focusOnMount: true,
              selectTextOnFocus: true,
              type: vm.isPasswordVisible ? undefined : 'password',
              oninput: (event) => vm.onPasswordInput(event.target.value),
              onenter: async () => await this.vm.onClickNext(),
              center: true,
              floatingLabelText: 'Password',
              placeholder: 'Password',
            }),
            h(
              'div.ripple',
              {
                style: {
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  right: 0,
                  bottom: 0,
                  width: '2em',
                  height: '2em',
                  color: colors.lightGrey,
                  borderRadius: '50%',
                  zIndex: 1,
                },
                tabIndex: 0,
                role: 'button',
                'aria-live': 'polite',
                'aria-label': `click to ${vm.isPasswordVisible ? 'hide' : 'show'} password`,
                onclick: vm.togglePasswordVisible,
              },
              [Icon(vm.isPasswordVisible ? icons.eyeShut : icons.eyeOpen, style(['xx-large']))]
            ),
          ]),
          h(
            'div#getin-error',
            style(
              [mt('1em')],
              {
                visibility: hasError ? 'visible' : 'hidden',
              },
              {
                'aria-live': 'polite',
              }
            ),
            getError()
          ),
          this.termsDialog(),
        ]
      )
    );
  }

  private termsDialog() {
    return Alert(
      {
        open: this.vm.isTermsDialogVisible,
        isAccessible: true,
        role: 'dialog',
        style: {
          width: '25em',
        },
        actions: [
          FlatButton('CANCEL', {
            type: 'secondary',
            tabIndex: 0,
            role: 'button',
            onclick: () => this.vm.onTermsCancel(),
          }),
          FlatButton('NEXT', {
            disabled: !this.vm.termsAcceptChecked,
            tabIndex: 0,
            role: 'button',
            onclick: () => this.vm.onTermsAccept(),
          }),
        ],
      },
      [
        h('div', style(['darkBlue']), [
          h('input', {
            type: 'checkbox',
            // "aria-labelledby": "terms-dialog-checkbox-label",
            style: style([mr('0.5em')]).style,
            checked: this.vm.termsAcceptChecked,
            onclick: () => this.vm.onTermsAcceptCheckboxClick(),
          }),
          'I have read the ',
          h(
            'a',
            {
              href: 'https://www.acadly.com/privacy',
              target: '_blank',
              style: style(['blue']).style,
            },
            'Privacy Policy'
          ),
          ' and agree to the ',
          h(
            'a',
            {
              href: 'https://www.acadly.com/terms',
              target: '_blank',
              style: style(['blue']).style,
            },
            'Terms of Use'
          ),
          // h("span#terms-dialog-checkbox-label", [
          // ])
        ]),
      ]
    );
  }

  private getRoleScreen() {
    const RoleButton = (role: 'student' | 'teacher', styles: any[] = []) => {
      const label = role === 'student' ? 'A STUDENT ACCOUNT' : 'AN INSTRUCTOR ACCOUNT';
      return h(
        'div.ripple',
        style(
          ['flex', 'white', 'justifyCenter', 'pointer', pad('1em'), ...styles],
          {
            backgroundColor: colors.blue,
            width: '80%',
          },
          {
            tabIndex: 0,
            role: 'link',
            id: role === 'student' ? GetInElements.GET_ROLE_STUDENT : undefined,
            'aria-label':
              role === 'student' ? 'Create a student account' : 'Create an instructor account',
            onclick: () => this.vm.onSelectRole(role),
          }
        ),
        label
      );
    };

    return this.subScreenTemplate(
      'Sign up for Acadly',
      'What type of an account do you need?',
      h('div.get-role', style(['fullWidth', 'flex', 'column', 'alignCenter', mt('3em')]), [
        RoleButton('student'),
        RoleButton('teacher', [mt('1em')]),
      ]),
      h(
        'div',
        style(
          ['textCenter', 'fullWidth', 'pointer', 'lightBlue'],
          {},
          {
            tabIndex: 0,
            role: 'link',
            onclick: () => this.vm.gotoLogin(),
          }
        ),
        'Already have an account? Login'
      )
    );
  }

  private joinCourseScreen() {
    const vm = this.vm;

    const hasError = vm.joinCodeError != null || vm.userExistsError;
    const getError = () => {
      if (vm.joinCodeError) {
        return [h('span', style(['red']), vm.joinCodeError)];
      } else if (vm.userExistsError) {
        return [
          h('span', style(['red'], mr('0.5em')), 'Your account is already registered.'),
          h(
            'span',
            style(
              ['blue', 'pointer'],
              {},
              {
                role: 'link',
                tabIndex: 0,
                onclick: () => vm.gotoLogin(),
              }
            ),
            'Log in instead?'
          ),
        ];
      }
      return '';
    };

    return this.subScreenTemplate(
      'Creating a new student account',
      `Please enter the 6 character course "Join Code" shared by your instructor`,
      h(
        'form.join-code-form',
        style(
          [mb('-1em')],
          {},
          {
            onsubmit: async () => await this.vm.onClickNext(),
          }
        ),
        [
          TextField({
            hasError,
            id: GetInElements.JOIN_COURSE_INPUT,
            ariaLabelledBy: hasError ? 'getin-error' : 'getin-title getin-subtitle',
            value: vm.joinCodeField,
            focusOnMount: true,
            selectTextOnFocus: true,
            center: true,
            placeholder: '6 character Join Code',
            floatingLabelText: 'Course Join Code',
            oninput: (event) => vm.onJoinCodeInput(event.target.value),
            onenter: async () => await this.vm.onClickNext(),
          }),
          h(
            'div#getin-error',
            style(
              [mt('1em')],
              {
                visibility: hasError ? 'visible' : 'hidden',
              },
              {
                'aria-live': 'polite',
              }
            ),
            getError()
          ),
          this.courseDialog(),
          this.noJoinCodeDialog(),
        ]
      ),
      h(
        'div',
        style(
          ['textCenter', 'pointer', 'lightBlue'],
          {},
          {
            tabIndex: 0,
            role: 'button',
            onclick: () => vm.showNoJoinCodeDialog(),
          }
        ),
        'I do not have a Join Code'
      )
    );
  }

  private courseDialog() {
    const courseInfo = this.vm.courseInfo;
    return Alert(
      {
        open: this.vm.isCourseDetailsDialogOpen,
        isAccessible: true,
        role: 'dialog',
        ariaLabel: `Course details for Join-Code: ${this.vm.joinCodeField}`,
        ariaDescribedBy: 'course-dialog-body',
        style: {
          width: '30em',
        },
        actions: [
          FlatButton('CANCEL', {
            type: 'secondary',
            tabIndex: 0,
            role: 'button',
            onclick: () => this.vm.onCourseDetailsClose(),
          }),
          FlatButton('CONTINUE', {
            tabIndex: 0,
            role: 'button',
            onclick: () => this.vm.onCourseConfirm(),
          }),
        ],
      },
      [
        h('div', style(['orange', 'large', mb('2em')]), `Join Code: ${this.vm.joinCodeField}`),
        h('div', style(['bold', mb('0.5em')]), `${courseInfo.code}: ${courseInfo.title}`),
        h('div', style(['grey']), courseInfo.university),
        h('div', style(['grey']), courseInfo.admin),
        h('div', style([mt('1em')]), [
          h('div', 'Please press continue to join this course with following email:'),
          h('div', this.vm.email),
        ]),
      ]
    );
  }

  private noJoinCodeDialog() {
    return Alert(
      {
        open: this.vm.isNoJoinCodeDialogOpen,
        isAccessible: true,
        role: 'dialog',
        style: { width: '30em' },
        actions: [
          FlatButton('GO TO LOG IN', {
            type: 'secondary',
            tabIndex: 0,
            role: 'link',
            onclick: () => {
              this.vm.isNoJoinCodeDialogOpen = false;
              this.vm.gotoLogin();
            },
          }),
          FlatButton('OKAY', {
            type: 'primary',
            tabIndex: 0,
            role: 'button',
            onclick: () => this.vm.closeNoJoinCodeDialog(),
          }),
        ],
      },
      [
        h('div', style(['x-large', 'orange', mb('1em')]), 'How to sign up as a student'),
        h(
          'div',
          `
                You can sign up as a student only if you are enrolled in
                a course or have a course Join Code. If you do not have
                a Join Code but have received an email from Acadly that
                you’re now enrolled in a course, please log in or sign up
                using the email address at which you have received the email.
                The email mentions the email address you should use to create the account
            `
        ),
      ]
    );
  }

  private signupEmailScreen() {
    const vm = this.vm;
    const isStudent = vm.userRole === 'student';

    const title = isStudent ? 'Creating a new student account' : 'Signing up';
    const subtitle = isStudent
      ? `Please enter your email address`
      : 'Please enter your official email address to sign up';

    const hasError = vm.userExistsError || vm.emailError;
    const getError = () => {
      if (vm.emailError) return [h('span', style(['red']), 'Please enter a valid email address')];
      else if (vm.userExistsError)
        return [
          h('span', style(['red'], mr('0.5em')), 'This email address already exists.'),
          h(
            'span',
            style(
              ['blue', 'pointer'],
              {},
              {
                role: 'link',
                tabIndex: 0,
                onclick: () => vm.gotoLogin(),
              }
            ),
            'Log in instead?'
          ),
        ];
      return '';
    };

    return this.subScreenTemplate(
      title,
      subtitle,
      h(
        'form.email-form',
        style(
          [mb('-1em')],
          {},
          {
            onsubmit: async () => await this.vm.onClickNext(),
          }
        ),
        [
          TextField({
            hasError,
            id: GetInElements.SIGNUP_EMAIL_INPUT,
            ariaLabelledBy: hasError ? 'getin-error' : 'getin-title getin-subtitle',
            value: vm.emailField,
            type: 'email',
            focusOnMount: true,
            selectTextOnFocus: true,
            center: true,
            placeholder: 'Email address',
            floatingLabelText: 'Email address',
            oninput: (event) => vm.onEmailInput(event.target.value.toLowerCase()),
            onenter: async () => await this.vm.onClickNext(),
          }),
          h(
            'div#getin-error',
            style(
              [mt('1em')],
              {
                visibility: hasError ? 'visible' : 'hidden',
              },
              {
                'aria-live': 'polite',
              }
            ),
            getError()
          ),
          h('div', style(['flex', mt('1em')]), [
            h(
              'input',
              style(
                [mr('0.5em'), mt('4px')],
                {},
                {
                  type: 'checkbox',
                  checked: this.vm.emailPermission,
                  'aria-labelledby': 'i-agree-label',
                  onclick: () => this.vm.onEmailPermissionChange(),
                }
              )
            ),
            h(
              'label#i-agree-label',
              {
                style: { userSelect: 'none', textAlign: 'left' },
                onclick: () => this.vm.onEmailPermissionChange(),
              },
              'I agree to receive product news and updates via email'
            ),
          ]),
          this.whichEmailDialog(isStudent),
        ]
      ),
      h(
        'div',
        style(
          ['textCenter', 'pointer', 'lightBlue'],
          {},
          {
            tabIndex: 0,
            role: 'button',
            onclick: () => vm.showWhichEmailDialog(),
          }
        ),
        'Which email address should I use?'
      )
    );
  }

  private whichEmailDialog(isStudent: boolean) {
    return Alert(
      {
        role: 'dialog',
        open: this.vm.isWhichEmailDialogOpen,
        isAccessible: true,
        style: { width: '30em' },
        actions: [
          FlatButton('OKAY', {
            role: 'button',
            type: 'primary',
            tabIndex: 0,
            onclick: () => this.vm.closeWhichEmailDialog(),
          }),
        ],
      },
      [
        h('div', style(['x-large', 'orange', mb('1em')]), 'Which email address should I use?'),
        isStudent
          ? h('div', [
              h('p', [
                "If you've been invited to join a course, please use the email ",
                'address on which you received the invitation. ',
              ]),
              h('p', style([mt('1em')]), [
                'If you were not invited via email, you should preferably use ',
                'the email address provided by the institute.',
              ]),
            ])
          : h('div', [
              h('p', [
                "If you've been invited by another instructor to join a course, ",
                'please use the email address on which you received the invitation.',
              ]),
              h('p', style([mt('1em')]), [
                'To request a new account, please use the email address provided by ',
                'your institute for quick verification.',
              ]),
            ]),
      ]
    );
  }

  private teacherRoleScreen() {
    return this.subScreenTemplate(
      this.vm.universityExists ? 'Creating Trial Account' : 'Instructor verification required',
      this.vm.universityExists
        ? [
            h(
              'p',
              'Please provide the following details to help us verify ' +
                `your identity as an instructor at ${this.vm.universityName}.`
            ),
            h('p', "We'll verify and change your account status in the next 24 hours"),
          ]
        : `Instructors can use Acadly after being verified.
                   Please provide us with the following details to activate
                   your account within the next 24 hours`,
      h(
        'form.instructor-verification-form',
        style(
          ['fullWidth', 'borderBox'],
          {},
          {
            onsubmit: async () => await this.vm.onClickNext(),
          }
        ),
        [
          TextField({
            value: this.vm.fullNameField,
            oninput: (e) => this.vm.onFullNameInput(e.target.value),
            errorText: this.vm.fullNameError,
            center: true,
            placeholder: 'Enter your full name',
            floatingLabelText: 'Full name',
            style: style([mt('1em')]).style,
            onenter: async () => await this.vm.onClickNext(),
          }),
          TextField({
            value: this.vm.websiteField,
            oninput: (e) => this.vm.onWebsiteInput(e.target.value),
            center: true,
            placeholder: 'Your official webpage',
            floatingLabelText: 'Website (optional)',
            style: style([mt('1em')]).style,
            onenter: async () => await this.vm.onClickNext(),
          }),
          this.vm.universityExists
            ? Select({
                key: 'get-role-dd',
                type: SelectType.SINGLE,
                placeholder: 'Role',
                value: this.vm.roleDD.value,
                isOpen: this.vm.roleDD.isOpen,
                options: this.vm.roleDD.options,
                style: style([mt('1em')]).style,
                errorText: this.vm.roleDD.error,
                hasError: Boolean(this.vm.roleDD.error),
                onChange: (value) => this.vm.onRoleDDValueChange(value),
                onToogleDropDown: (isOpen) => this.vm.onToggleRoleDD(isOpen),
              })
            : null,
          !this.vm.universityExists
            ? Select({
                key: 'get-org-dd',
                type: SelectType.SINGLE,
                placeholder: 'Organization',
                value: this.vm.orgDD.value,
                isOpen: this.vm.orgDD.isOpen,
                options: this.vm.orgDD.options,
                style: style([mt('1em')]).style,
                errorText: this.vm.orgDD.error,
                hasError: Boolean(this.vm.orgDD.error),
                onChange: (value) => this.vm.onOrgDDValueChange(value),
                onToogleDropDown: (isOpen) => this.vm.onToggleOrgDD(isOpen),
              })
            : null,
          !this.vm.universityExists
            ? Select({
                key: 'get-use-case-dd',
                type: SelectType.SINGLE,
                placeholder: 'You want to use Acadly for',
                value: this.vm.useCaseDD.value,
                isOpen: this.vm.useCaseDD.isOpen,
                options: this.vm.useCaseDD.options,
                style: style([mt('1em')]).style,
                errorText: this.vm.useCaseDD.error,
                hasError: Boolean(this.vm.useCaseDD.error),
                onChange: (value) => this.vm.onUseCaseDDValueChange(value),
                onToogleDropDown: (isOpen) => this.vm.onToggleUseCaseDD(isOpen),
              })
            : null,
        ]
      )
    );
  }

  private signupThankYouScreen() {
    const vm = this.vm;
    const hideHereLink =
      vm.universityExists || vm.email.endsWith('.edu') || vm.email.endsWith('.ac.uk');
    return this.subScreenTemplate(
      'Thank you',
      'Thank you for choosing Acadly!',
      h('div.thank-you-screen', [
        h('div', style([mt('2em')]), [
          `We will manually verify if you teach at a higher
                    education institution. Upon successful verification,
                    your free account will be activated and you will be
                    notified via email within the next 24 hours.`,
        ]),
        h('div', style([mt('1em')]), [
          `If you work at a company or non-profit, we will get
                    in touch with you with details on how you can get started with Acadly.`,
        ]),
        hideHereLink
          ? null
          : h(
              'a.thank-you-screen__here',
              {
                target: '_blank',
                // tslint:disable-next-line
                href: 'https://www.acadly.com/here/?utm_source=acadlysignup1&utm_medium=postverifyrequest1&utm_campaign=acadlyhere',
              },
              [
                h('img', {
                  // tslint:disable-next-line
                  src: 'https://s3.amazonaws.com/static.acad.ly/img/here-monogram-small.png',
                  width: '48px',
                  height: '48px',
                  style: { marginRight: '0.5rem' },
                }),
                h('span', [
                  'Click to learn about Acadly Here and ',
                  'automatically record attendance anywhere',
                ]),
              ]
            ),
        h('div', style([mt('1em')]), [
          h(
            'span',
            style([mr('0.25em')]),
            'If you have any questions, please feel free to write to us at'
          ),
          h(
            'a',
            style(
              ['blue', mr('0.25em')],
              {
                wordBreak: 'break-word',
              },
              {
                href: 'mailto:support@acadly.com',
                target: '_blank',
              }
            ),
            'support@acadly.com.'
          ),
          h('span', 'Thanks!'),
        ]),
      ])
    );
  }

  private tempPasswordScreen() {
    const vm = this.vm;

    const hasError = vm.tempPasswordError != null;
    const getError = () => {
      if (vm.tempPasswordError) return [h('span', style(['red']), vm.tempPasswordError)];
      return '';
    };

    return this.subScreenTemplate(
      'Email verification',
      `Please enter the verification code emailed to you at ${this.vm.email}`,
      h('form.email-verification-form', style([mb('-1em')]), [
        this.userDetails(),
        TextField({
          hasError,
          ariaLabelledBy: hasError ? 'getin-error' : 'getin-title getin-subtitle',
          value: this.vm.tempPasswordField,
          center: true,
          focusOnMount: true,
          oninput: (e) => this.vm.onOneTimePasswordInput(e.target.value),
          onenter: async () => await this.vm.onClickNext(),
          placeholder: 'Enter verification code',
          floatingLabelText: 'Verification code',
        }),
        h(
          'div#getin-error',
          style(
            [mt('1em')],
            {
              visibility: hasError ? 'visible' : 'hidden',
            },
            {
              'aria-live': 'polite',
            }
          ),
          getError()
        ),
      ]),
      h(
        'div',
        style(
          ['textCenter', 'pointer', 'lightBlue'],
          {},
          {
            tabIndex: 0,
            role: 'button',
            onclick: this.vm.onClickResendVerificationCode,
          }
        ),
        'Resend verification code'
      )
    );
  }

  private setNameScreen() {
    return this.subScreenTemplate(
      'Complete your profile',
      `Please enter your official full name as it appears in the course roster.
            Watch out for typos and auto-correct.`,
      h('div.student-name-form', [
        TextField({
          ariaLabelledBy: 'getin-title getin-subtitle',
          value: this.vm.firstNameField,
          oninput: (e) => this.vm.onFirstNameInput(e.target.value),
          errorText: this.vm.firstNameError,
          center: true,
          focusOnMount: true,
          placeholder: 'First name',
          floatingLabelText: 'First name',
          onenter: async () => await this.vm.onClickNext(),
          style: style([mt('1em')]).style,
        }),
        TextField({
          value: this.vm.middleNameField,
          oninput: (e) => this.vm.onMiddleNameInput(e.target.value),
          center: true,
          placeholder: 'Middle name (optional)',
          floatingLabelText: 'Middle name (optional)',
          style: style([mt('1em')]).style,
          onenter: async () => await this.vm.onClickNext(),
        }),
        TextField({
          value: this.vm.lastNameField,
          oninput: (e) => this.vm.onLastNameInput(e.target.value),
          errorText: this.vm.lastNameError,
          center: true,
          placeholder: 'Last name',
          floatingLabelText: 'Last name',
          style: style([mt('1em')]).style,
          onenter: async () => await this.vm.onClickNext(),
        }),
      ])
    );
  }

  private setProfileScreen() {
    const skinToneUrls = this.vm.getSkinToneUrls();

    const content = this.vm.avatarUploadedFile
      ? h('div', style(['textCenter', 'flex', 'column', 'alignCenter']), [
          ImageCropper({
            url: this.vm.avatarUploadedURL,
            onupdate: (url) => this.vm.onAvatarCrop(url),
            width: 200,
            height: 200,
          }),
          h('img', {
            src: this.vm.croppedAvatarURL,
            style: {
              width: '4em',
              height: '4em',
              borderRadius: '0.3em',
              marginTop: '0.5em',
              marginBottom: '0.5em',
            },
          }),
          h('div', style([mt('1rem'), mb('1rem')]), 'OR'),
          FlatButton('Choose from default avatars', {
            onclick: () => this.vm.chooseFromDefaultAvatars(),
          }),
        ])
      : h('div', style(['textCenter', 'flex', 'column', 'alignCenter']), [
          h('div', style(['small', 'darkBlue', 'bold']), 'Your current avatar'),
          h('img', {
            style: {
              borderRadius: '50%',
              marginTop: '0.5em',
              width: '4em',
              height: '4em',
            },
            tabIndex: 0,
            src: this.vm.getSelectedAvatar().url,
            'aria-label': `Your current avatar is ${this.vm.getSelectedAvatar().ariaLabel}`,
          }),
          UploadButton({
            ariaLabel: 'Choose from System button',
            accept: 'image/png,image/jpg,image/jpeg',
            view: h('div', style(['blue', mt('0.5em'), 'pointer']), 'CHOOSE FROM SYSTEM'),
            upload: (file) => this.vm.onAvatarSelectFromSystem(file),
          }),
          h('div', style([mt('1em')]), 'or'),
          h('div', style(['blue', mt('1em')]), 'Choose from default avatars'),
          h('div', style([mt('1em')]), 'Select skin tone'),
          h(
            'div',
            style(
              [mt('0.5em')],
              {},
              {
                'aria-label': 'Select skin tone',
                tabIndex: 0,
              }
            ),
            skinToneUrls.map((skinTone, index) =>
              h('img', {
                src: skinTone.url,
                tabIndex: 0,
                'aria-label':
                  index === this.vm.skinToneSelected
                    ? `${skinTone.ariaLabel} selected`
                    : skinTone.ariaLabel,
                style: {
                  width: '2em',
                  height: '2em',
                  marginLeft: index > 0 ? '0.5em' : undefined,
                  cursor: 'pointer',
                  border: `1px dotted ${
                    this.vm.skinToneSelected === index ? colors.blue : 'transparent'
                  }`,
                },
                onclick: () => this.vm.onSelectSkinTone(index),
              })
            )
          ),
          h(
            'div',
            style(
              ['flex', 'spaceBetween', 'fullWidth', 'justifyCenter'],
              {
                flexWrap: 'wrap',
                maxHeight: '12em',
                overflow: 'auto',
              },
              {
                tabIndex: 0,
                'aria-label': 'Choose avatar from following avatars',
              }
            ),
            this.vm.getDefaultAvatars().map((avatar, index) =>
              h('img', {
                src: avatar.url,
                tabIndex: 0,
                'aria-label':
                  index === this.vm.avatarSelected
                    ? `${avatar.ariaLabel} selected`
                    : avatar.ariaLabel,
                style: {
                  margin: '1em',
                  cursor: 'pointer',
                  width: '4em',
                  height: '4em',
                  borderRadius: '0.3em',
                },
                onclick: () => this.vm.onSelectAvatar(index),
              })
            )
          ),
        ]);
    return this.subScreenTemplate(
      'Choose your avatar',
      '',
      content,
      null,
      LoaderButton(
        'DONE',
        style(['pointer'], {
          padding: '1.25em 2em',
          border: 'none',
          borderRadius: '3px',
          background: this.vm.isLoading ? colors.white : colors.blue,
          color: colors.white,
        }).style,
        {
          isLoading: this.vm.isLoading,
          onclick: async () => await this.vm.onClickNext(),
        }
      )
    );
  }
}

export default () => h(GetInScreen);
