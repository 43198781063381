/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Exposes the interfaces that need to be implemented by
 * the underlying framework. The underlying framework needs
 * to implement a Virtual DOM mechanism, stateful components
 * and component lifecycle methods (hooks that are called
 * when component is mounted, etc).
 */
export type View<Vnode> = Vnode | string | null;

/**
 * This is the interface, that the underlying framework needs
 * to implement.
 *
 * Here, Vnode is the type of object returned by the 'h' function.
 *
 * Props is the type of arguments to this component which can be
 * passed as the second argument to 'h' and can be referred to
 * as this.getProps() inside the component.
 *
 * State is the internal state of the component which can be
 * accessed through this.getState() and can be set by
 * this.setState. this.setState takes a partial state as
 * an argument which augments the current state with the
 * values in the argument object.
 * setState must make sure that the application re-renders.
 *
 * Note that I didn't make the methods of this class
 * abstract because Components don't have to implement
 * lifecycle methods, getState, getProps, etc. That's
 * the job of the framework specific adapter. If these
 * methods were abstract, Typescript will complain while
 * implementing a Component that abstract members
 * getProps, setProps, etc are not implemented.
 *
 * If a new framework adapter needs to be implemented,
 * care has to be taken to implement all these methods
 * with the correct types. The compiler won't help you
 * there. Thankfully, it's not often that someone needs
 * to switch the underlying framework with something else.
 */
export abstract class Component<Vnode, Props, State> {
  public getProps(): Readonly<Props> {
    throw new Error('Unimplemented');
  }

  public getState(): Readonly<State> {
    throw new Error('Unimplemented');
  }

  public render(): View<Vnode> {
    throw new Error('Unimplemented');
  }

  public setState(newState: Partial<State>): Promise<{}> {
    throw new Error('Unimplemented');
  }

  public componentWillMount(): void {}

  componentWillUpdate(nextProps: Props, nextState: State): void {}

  componentWillUnmount(): void {}

  componentWillReceiveProps(nextProps: Props): void {}
}

/**
 * Type that represents a component's class (not it's instance).
 * So, if you have something like
 * class SomeComponent extends IComponent<any, any> {
 * ...
 * }
 * Here, the name `SomeComponent` will have type ComponentClass<VNode, any, any>
 */
// tslint:disable-next-line
export interface ComponentClass<Vnode, Props, State> {
  // tslint:disable-next-line
  new (...args: any[]): Component<Vnode, Props, State>;
}
interface EventListenerKeyMap extends ElementEventMap {
  onabort: UIEvent;
  onactivate: UIEvent;
  onbeforeactivate: UIEvent;
  onbeforecopy: ClipboardEvent;
  onbeforecut: ClipboardEvent;
  onbeforedeactivate: UIEvent;
  onbeforepaste: ClipboardEvent;
  onblur: FocusEvent;
  oncanplay: Event;
  oncanplaythrough: Event;
  onchange: Event;
  onclick: MouseEvent;
  oncontextmenu: PointerEvent;
  oncopy: ClipboardEvent;
  oncuechange: Event;
  oncut: ClipboardEvent;
  ondblclick: MouseEvent;
  ondeactivate: UIEvent;
  ondrag: DragEvent;
  ondragend: DragEvent;
  ondragenter: DragEvent;
  ondragleave: DragEvent;
  ondragover: DragEvent;
  ondragstart: DragEvent;
  ondrop: DragEvent;
  ondurationchange: Event;
  onemptied: Event;
  onended: MediaStreamErrorEvent;
  onerror: ErrorEvent;
  onfocus: FocusEvent;
  oninput: Event;
  oninvalid: Event;
  onkeydown: KeyboardEvent;
  onkeypress: KeyboardEvent;
  onkeyup: KeyboardEvent;
  onload: Event;
  onloadeddata: Event;
  onloadedmetadata: Event;
  onloadstart: Event;
  onmousedown: MouseEvent;
  onmouseenter: MouseEvent;
  onmouseleave: MouseEvent;
  onmousemove: MouseEvent;
  onmouseout: MouseEvent;
  onmouseover: MouseEvent;
  onmouseup: MouseEvent;
  onmousewheel: WheelEvent;
  onMSContentZoom: UIEvent;
  onpaste: ClipboardEvent;
  onpause: Event;
  onplay: Event;
  onplaying: Event;
  onprogress: ProgressEvent;
  onratechange: Event;
  onreset: Event;
  onscroll: UIEvent;
  onseeked: Event;
  onseeking: Event;
  onselect: UIEvent;
  onselectstart: Event;
  onstalled: Event;
  onsubmit: Event;
  onsuspend: Event;
  ontimeupdate: Event;
  onvolumechange: Event;
  onwaiting: Event;
  ontouchstart: TouchEvent;
}

export type HTMLAttrs<Element extends HTMLElement = HTMLElement> = Partial<
  {
    key: string | number;
    id: string;
    style: CSS;
    className: string;
    ref(elem: Element): void;
    tabIndex: string | number;
    width: string | number;
    height: string | number;
    dangerouslySetInnerHTML: { __html: string };
    href: string;
    src: string;
    title: string;
    target: string;
    download: string;
    ariaLabel: string;
    'aria-label': string;
    'aria-live': 'off' | 'polite' | 'assertive';
    'aria-hidden': boolean;
    'aria-readonly': boolean;
    'aria-required': boolean;
    'aria-describedby': string;
    'aria-labelledby': string;
    'aria-atomic': boolean;
    'aria-owns': string;
    'aria-selected': boolean;
    'aria-expanded': boolean;
    'aria-multiselectable': boolean;
    'aria-autocomplete': 'none' | 'inline' | 'list' | 'both';
    'aria-activedescendant': string;
    'aria-haspopup': boolean | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog';
    contenteditable: boolean;
    role: string;
    'data-tooltip': string;
  } & {
    [E in keyof EventListenerKeyMap]: (this: Window, event: EventListenerKeyMap[E]) => void;
  }
>;

/**
 * This interface represents the type of the 'h' function used to create
 * a view
 */
export interface H<Vnode> {
  <Attrs, State, A extends Attrs & HTMLAttrs>(
    component: ComponentClass<Vnode, Attrs, State> | string,
    attributes?: A | View<Vnode>[] | string,
    children?: View<Vnode>[] | string
  ): Vnode;
}
type Diff<T, U> = T extends U ? never : T;
type CSSKeys = Diff<keyof CSSStyleDeclaration, 'length'>;

export type CSS = {
  [k in CSSKeys]?: number | undefined | string;
};

/**
 * Apart from the Component class, the underlying framework needs
 * to implement this interface
 */
export interface Adapter<Vnode, BaseClass> {
  /**
   * Mount a given Component to a DOM node.
   * eg,
   * import { IComponent, h } from "./framework";
   * class Root extends IComponent<{}, {}> {
   *    render() {
   *      return h("div", "Hello");
   *    }
   * }
   * mount(document.getElementById("app"), Root);
   */
  mount(element: HTMLElement, view: Vnode): void;

  /**
   * Redraw the application on state change
   */
  redraw(): void;

  /**
   * Function for creating a view.
   * eg. h("div", "asdf") creates <div>asdf</div>
   * h("div#app.some-class", "asdf") creates <div id="app" class="some-class">asdf</div>
   *
   * h(SomeComponent, {arg1: "value1", arg2: "value2"})
   * where SomeComponent extends IComponent
   */
  h: H<Vnode>;

  /**
   * Default classes to be added to all elements
   * created using the H function.
   * The the value passed to the latest call will
   * be used. Implementation of the
   * h function should handle this.
   */
  setDefaultClasses(...classes: string[]): void;

  /**
   * Register an error handler to be called when an
   * exception is thrown during the rendering phase.
   */
  registerErrorHandler(f: (error: any) => void): void;
}
