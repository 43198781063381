import { h, IComponent, View } from 'core';

import { api as urls } from 'acadly/api';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import appService from 'acadly/app/service';
import classService from 'acadly/class/service';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Avatar from 'acadly/common/Avatar';
import TextField from 'acadly/common/TextField';
import courseService from 'acadly/course/service';
import { validateActivityPublish } from 'acadly/course/validations';
import * as dt from 'acadly/datetime';
import queryService from 'acadly/query/service';
import Editor from 'acadly/rich-text/Editor';
import Viewer from 'acadly/rich-text/Viewer';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { colors, mb, style } from 'acadly/styles';
import * as u from 'acadly/utils';

import Alert from '../common/Alert';
import Dialog from '../common/Dialog';
import FlatButton from '../common/FlatButton';
import { goBack } from '../routes';
import { Actions } from './actions';

export interface IQueryProps {
  open: boolean;
  classId: string;
  cls: IClass;
  courseId: string;
  isAnon: boolean;
}

export interface IQueryState {
  isAskQueryOpen: boolean;
  isEditingTitle: boolean;
  title: string;
  isPublishConfirmationVisible: boolean;
  isDescriptionDialogOpen: boolean;
  titleField: string;
  titleError: boolean;
  descriptionField: string;
  descriptionError: boolean;
  attachmentField: IAttachment[];
}

export class NewQuery extends IComponent<IQueryProps, IQueryState> {
  public componentWillMount() {
    const initialState: IQueryState = {
      isAskQueryOpen: true,
      title: '',
      isEditingTitle: false,
      isPublishConfirmationVisible: false,
      isDescriptionDialogOpen: false,
      titleField: '',
      titleError: false,
      descriptionField: '',
      descriptionError: false,
      attachmentField: [],
    };
    this.setState(initialState);
  }
  private isAccessible = false;

  public render() {
    const props = this.getProps();
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;

    return Dialog(
      {
        title: 'New Query',
        open: this.getProps().open,
        fullScreen: true,
        role: 'dialog',
        style: {
          backgroundColor: colors.backgroundColor,
        },
        backButton: {
          label: 'CANCEL',
          'aria-label': 'cancel button',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => {
            goBack();
          },
        },
        bodyStyle: {
          paddingTop: '1rem',
        },
        primaryAction: {
          label: 'ASK Query',
          role: 'button',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.saveHandler(),
        },
      },
      [
        h('div.activity.new-query', [
          this.titleView(),
          this.descriptionView(),
          this.attachmentsView(),
          h('div.activity__section', [props.isAnon ? this.anonymousView() : this.creatorView()]),
          this.warnings(),
        ]),
        this.confirmPublishDialog(),
      ]
    );
  }

  private titleView() {
    const { title } = this.getState();

    return h(
      'div.panel',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `Query Title Section: ${
          title ||
          `This query doesn't have a title yet.
                Tap on the icon to add a title.`
        }`,
      },
      [
        h('div.panel__header', [
          h('span', 'Title'),
          h('i.fa.fa-pencil', {
            tabIndex: this.isAccessible ? 0 : undefined,
            role: 'button',
            'aria-label': 'edit title button',
            onclick: async () => {
              this.lastFocusedElement = document.activeElement;
              u.unsetTabIndices();
              this.setState({
                isEditingTitle: true,
                titleError: false,
                titleField: this.getState().title,
              });
            },
          }),
        ]),
        h(
          'div.panel__content',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': 'Query Title Content',
          },
          [
            title ||
              h('div.fc-light-grey', [
                "This query doesn't have a title yet. Tap on the ",
                h('i.fa.fa-pencil'),
                ' icon to add a title.',
              ]),
          ]
        ),
        this.titleEditDialog(),
      ]
    );
  }

  private lastFocusedElement: Element | null = null;

  private titleEditDialog() {
    const state = this.getState();
    return Alert(
      {
        open: state.isEditingTitle,
        title: 'Query Title',
        hasInput: true,
        style: {
          width: '30em',
        },
        actions: [
          FlatButton('Discard', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElement as any).focus();
              this.setState({
                isEditingTitle: false,
              });
            },
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.editTitleHandler(),
          }),
        ],
      },
      [
        TextField({
          focusOnMount: true,
          value: state.titleField,
          maxLength: 140,
          errorText: state.titleError ? 'Please fill this field' : undefined,
          style: style([mb('0.5em')]).style,
          onenter: () => this.editTitleHandler(),
          oninput: (e) =>
            this.setState({
              titleField: e.target.value,
              titleError: false,
            }),
        }),
      ]
    );
  }

  private async editTitleHandler() {
    const { titleField } = this.getState();
    if (!titleField.trim()) {
      await this.setState({
        titleError: true,
      });
      return;
    } else {
      u.resetTabIndices();
      (this.lastFocusedElement as any).focus();
      await this.setState({
        isEditingTitle: false,
        title: titleField,
      });
    }
  }

  private descriptionEditDialog() {
    const { isDescriptionDialogOpen, descriptionField, attachmentField, descriptionError } =
      this.getState();

    return Dialog(
      {
        title: 'Editing Description',
        open: isDescriptionDialogOpen,
        secondaryAction: {
          label: 'CANCEL',
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElement as any).focus();
            this.setState({
              isDescriptionDialogOpen: false,
              descriptionField: '',
            });
          },
        },
        primaryAction: {
          label: 'SAVE',
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElement as any).focus();
            this.setState({
              isDescriptionDialogOpen: false,
            });
          },
        },
      },
      [
        Editor({
          value: descriptionField,
          subContext: 'description',
          attachments: {
            uploadUrl: urls().queryFileUpload,
            files: attachmentField,
            activityType: 'queries',
          },
          oninput: (value, attachments) =>
            this.setState({
              descriptionField: value,
              descriptionError: false,
              attachmentField: attachments,
            }),
          errorText: descriptionError ? 'Please fill this field' : undefined,
          placeholder: 'Explain your question or doubt here.',
          enableFormulaInput: true,
          enableFileAttachments: true,
          enableTextFormatting: true,
          enableImageInput: true,
        }),
      ]
    );
  }

  private descriptionView() {
    const { descriptionField } = this.getState();

    return h('div.panel', [
      h(
        'div.panel__header',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': `Query Description${
            descriptionField ||
            `To add a rich text description,
                    or attach files,images etc, tap on the icon.`
          }`,
        },
        [
          h('div.description', 'Description'),
          h('i.fa.fa-pencil.ripple', {
            tabIndex: this.isAccessible ? 0 : undefined,
            role: 'button',
            'aria-label': 'Edit Query Description',
            onclick: () => {
              this.lastFocusedElement = document.activeElement;
              u.unsetTabIndices();
              this.setState({ isDescriptionDialogOpen: true });
            },
          }),
        ]
      ),
      h('div.panel__content', [
        descriptionField
          ? Viewer(descriptionField)
          : h(
              'div.fc-light-grey',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                'aria-label': 'Query Description Content',
              },
              [
                'To add a rich text description, or attach files, ',
                'images etc, tap on the ',
                h('i.fa.fa-pencil'),
                ' icon.',
              ]
            ),
      ]),
      this.descriptionEditDialog(),
    ]);
  }

  private confirmPublishDialog() {
    const { isAnon } = this.getProps();
    return Alert(
      {
        open: this.getState().isPublishConfirmationVisible,
        title: 'Posting a new query',
        style: {
          maxWidth: '25em',
        },
        actions: [
          FlatButton('No', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices();
              if (this.lastFocusedElement) {
                (this.lastFocusedElement as any).focus();
              }
              this.setState({ isPublishConfirmationVisible: false });
            },
          }),
          FlatButton("Yes, I'm sure", {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: async () => {
              u.resetTabIndices();
              // if (this.lastFocusedElement){
              //     (this.lastFocusedElement as any).focus();
              // }
              await this.postHandler();
            },
          }),
        ],
      },
      [
        h('p', { tabIndex: this.isAccessible ? 0 : undefined }, [
          'Please make sure your query has an ',
          'appropriate title and description',
        ]),
        h('p', { tabIndex: this.isAccessible ? 0 : undefined }, [
          "You won't be able to ",
          'edit/delete it once it is posted.',
        ]),
        h('p', { tabIndex: this.isAccessible ? 0 : undefined }, [
          'Are you sure you want to ask the ',
          'query in its current form? ',
        ]),
        isAnon
          ? h('div.activity__note', { tabIndex: this.isAccessible ? 0 : undefined }, [
              h('i.fa.fa-exclamation-triangle'),
              h('div', [
                'After your anonymous query is created, it will show up on the class ',
                'page only after a course team member approves it',
              ]),
            ])
          : null,
      ]
    );
  }

  private warnings() {
    const props = this.getProps();

    if (!props.isAnon) return null;

    return h('div.new-query__warning', [
      h('i.fa.fa-exclamation-triangle'),
      h(
        'div',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          role: 'note',
        },
        [
          'Anonymous queries will have to be approved by the course ',
          'instructor first before they show up in the class.',
        ]
      ),
    ]);
  }

  private creatorView(): View {
    const session = getStore().getState().getIn.session!;
    const dateStamp = dt.format(new Date(), 'MMM DD, YYYY [at] hh:mm A');

    return h(
      'div.user',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        Avatar(session.avatar, session.name, {
          className: 'user__avatar',
        }),
        h('div.user__details', [
          h('div.fc-green', session.name),
          h('div.fc-light-grey', dateStamp.toLocaleString()),
        ]),
      ]
    );
  }

  private anonymousView() {
    const dateStamp = dt.format(new Date(), 'MMM DD, YYYY [at] hh:mm A');

    return h(
      'div.user',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        Avatar('', 'anonymous', {
          className: 'user__avatar',
        }),
        h('div.user__details.fc-grey', [
          h('div', 'Asked by Anonymous'),
          h('div', dateStamp.toLocaleString()),
        ]),
      ]
    );
  }

  private headings(label: string) {
    return h('div.cell', h('span.cell__label', label));
  }

  private attachmentsView() {
    const queries = this.getState();

    if (queries.attachmentField.length < 1) return null;

    return h('div.activity__attachments', [
      this.headings('Attachments'),
      ...queries.attachmentField.map((file) =>
        AttachmentViewer({
          attachment: file,
          downloadUrl: urls().queryFileDownload,
          downloadRequest: {},
          disabled: true,
          style: {
            paddingBottom: '0.5rem',
          },
        })
      ),
    ]);
  }

  private async postHandler() {
    const { titleField, descriptionField, attachmentField } = this.getState();
    const { classId, courseId, isAnon } = this.getProps();
    const classShortId = classService.getShortIdFromClassId(classId);
    const courseShortId = courseService.getShortIdFromCourseId(courseId);
    let hasError = false;
    const errorState: Partial<IQueryState> = {};
    if (titleField.length < 1) {
      hasError = true;
      errorState.titleError = true;
    }
    if (descriptionField.length < 1) {
      hasError = true;
      errorState.descriptionError = true;
    }
    if (hasError) {
      await this.setState(errorState);
      return;
    }
    const response = await dispatch(
      Actions.create({
        classId: classId,
        isAnon: isAnon ? 1 : 0,
        description: descriptionField,
        title: titleField,
        attachments: attachmentField,
      })
    );

    if (response.isAnon === 1) {
      googleAnalytics.queryCreated('anonymous');
      Routes.classActivities.navigate({
        classShortId: classService.getShortIdFromClassId(classId),
        courseShortId: courseService.getShortIdFromCourseId(courseId),
        univSlug: appService.getUniversitySlug(),
      });
    } else {
      googleAnalytics.queryCreated(
        {
          preClass: 'pre-class',
          inClass: 'in-class',
          review: 'review',
        }[response.query.details.toBeDone] as any
      );

      const queryShortId = queryService.getShortIdFromQueryId(response.query._id);

      Routes.classQuery.navigate({
        courseShortId,
        classShortId,
        queryShortId,
        univSlug: appService.getUniversitySlug(),
      });
    }
  }

  private async saveHandler() {
    const { titleField, descriptionField } = this.getState();
    // if (titleField.length < 1) {
    //     await this.setState({titleError: true});
    //     return;
    // }
    if (
      !validateActivityPublish({
        activityType: 'query',
        title: titleField,
        description: descriptionField,
      })
    ) {
      return;
    }
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices(document.getElementById('alert-box'));
    await this.setState({ isPublishConfirmationVisible: true });
  }
}

export default (props: IQueryProps) => h(NewQuery, props);
