import { api as urls } from 'acadly/api';
import AttachmentViewer from 'acadly/common/AttachmentViewer';

export default function Attachments(quiz: IQuiz, isEditingQuiz?: boolean) {
  return quiz.details.attachments.map((a, i) =>
    AttachmentViewer({
      attachment: a,
      style: {
        marginTop: i === 0 ? '0.5rem' : undefined,
      },
      hideDownloadIcon: isEditingQuiz ? true : undefined,
      downloadUrl: urls().quizFileDownload,
      downloadRequest: {
        quizId: quiz._id,
        fileName: a.name,
      },
    })
  );
}
