import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/first';

import cn from 'classnames';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { CSS, h, IComponent, View } from 'core';

import * as BackIcon from 'assets/back.svg';
import * as CloseIcon from 'assets/close.svg';
import * as DoneIcon from 'assets/done.svg';
import * as PresentIcon from 'assets/present.svg';
import * as RemotePresentIcon from 'assets/remote-present.svg';

import { ATTENDANCE_TIME_OUT, AttendanceTimer, getAttendanceTime } from 'acadly/app/functions';
import { Actions as ClassActions, IPusherAttendanceData } from 'acadly/class/actions';
import { attendanceStates } from 'acadly/class/attendanceStates';
import Alert from 'acadly/common/Alert';
import ContentView from 'acadly/common/ContentView';
import CtaButton from 'acadly/common/CtaButton';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import { fullScreenLoader, Loader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import StudentList, { getStudentListData } from 'acadly/common/StudentList';
import SvgIcon from 'acadly/common/SvgIcon';
import TimePicker from 'acadly/common/TimePicker';
import User from 'acadly/common/User';
import CourseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import icons from 'acadly/icons';
import { logger } from 'acadly/logger';
import { PusherEvent, pusherService } from 'acadly/pusher';
import { dispatch, getStore } from 'acadly/store';
import { classTabHeight, colors, margin, mb, ml, mr, mt, pad, pt, style } from 'acadly/styles';
import * as u from 'acadly/utils';

import { Actions, IExportGradesSuccessResponse } from './actions';
import { Class } from './functions';
import classService from './service';

const NO_ATTENDANCE_MESSAGE =
  "Acadly doesn't have attendance records for " +
  "this class. Maybe the class didn't " +
  "happen or Acadly wasn't used in the class.";

/* tslint:disable-next-line */
const ATTENDANCE_HELP =
  'https://help.acadly.com/en/articles/4584794-recording-attendance-in-online-classes-automatically';

export default (props: IAttendanceProps) => h(Attendance, props);

export interface IAttendanceProps {
  cls: IClass;
  courseRole: ICourseRole;
  isIncharge: boolean;
  isTabOpen: boolean;
}

type AttendanceStatus = 'present' | 'checkedIn' | 'absent' | 'noRecord' | 'late' | 'excused';

const StudentCell = (props: {
  student: IAttendanceTabStudent;
  status: AttendanceStatus;
  isAccessible: boolean;
  onClick?: () => any;
}) => {
  const attendance = getStore().getState().class.attendance;
  const { student, isAccessible, status } = props;

  const remoteAutoInstances = attendance ? attendance.remoteAutoInstances : 0;

  let finalStatus: View = '';
  const FinalStatus = (props: { label: string; className?: string; style?: CSS }) => {
    const { label, className, style } = props;
    return h(
      'span',
      {
        className,
        style: {
          ...style,
          fontSize: '0.875rem',
          fontWeight: '700',
        },
      },
      label
    );
  };

  switch (status) {
    case 'present':
      finalStatus = FinalStatus({
        style: {
          color: student.finalStatusColor ? `#${student.finalStatusColor}` : undefined,
        },
        className: 'fc-green',
        label: 'PRESENT',
      });
      break;
    case 'late':
      finalStatus = FinalStatus({ className: 'fc-blue', label: 'LATE' });
      break;
    case 'excused':
      finalStatus = FinalStatus({ className: 'fc-red', label: 'EXCUSED' });
      break;
    case 'absent':
      finalStatus = FinalStatus({ className: 'fc-red', label: 'ABSENT' });
      break;
  }

  let systemStatus: View = '';
  const SystemStatus = (props: { icon: View; label: string; style?: CSS; className?: string }) => {
    const { className, icon, label, style } = props;
    return h(
      'div',
      {
        className,
        style: {
          ...style,
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.7rem',
          fontWeight: '900',
          marginTop: '0.25rem',
          lineHeight: '1.8',
        },
      },
      [icon, label]
    );
  };

  const stystemStatusIconAttr = {
    className: 'attendance-help-cell__icon',
  };

  if (student.edited || student.finalStatus !== status) {
    systemStatus = SystemStatus({
      className: 'fc-blue',
      icon: Icon(icons.pencil, stystemStatusIconAttr),
      label: 'EDITED',
    });
  } else {
    switch (student.systemStatus) {
      case 'remote':
        systemStatus = SystemStatus({
          style: {
            color: student.systemStatusColor ? `#${student.systemStatusColor}` : undefined,
          },
          icon: SvgIcon({
            ...stystemStatusIconAttr,
            icon: RemotePresentIcon,
          }),
          label: `${student.remoteResponses} / ${remoteAutoInstances} ${u.pluralize(
            student.remoteResponses,
            'CHECK',
            'CHECKS'
          )}`,
        });
        break;
      case 'present':
        systemStatus = SystemStatus({
          style: {
            color: student.systemStatusColor ? `#${student.systemStatusColor}` : undefined,
          },
          icon: SvgIcon({
            ...stystemStatusIconAttr,
            icon: PresentIcon,
          }),
          label: 'PRESENT',
        });
        break;
      case 'checkedIn':
      case 'noRecord':
        systemStatus = SystemStatus({
          className: 'fc-red',
          icon: Icon(icons.cross, stystemStatusIconAttr),
          label: 'NO RECORD',
        });
        break;
    }
  }

  return User(
    {
      avatar: {
        url: student.avatar,
        creator: student.name,
      },
      title: h(
        'div',
        {
          style: {
            color: '#333',
            fontSize: '1rem',
            fontWeight: '700',
          },
        },
        student.name
      ),
      subtitle: systemStatus,
      action: finalStatus,
    },
    {
      key: `attendance-${student.userId}`,
      className: 'student',
      tabIndex: isAccessible ? 0 : undefined,
      onclick: props.onClick,
    }
  );
};

type EditedAttendanceStatus = 'present' | 'late' | 'excused' | 'absent';

interface StudentRecord {
  status: EditedAttendanceStatus;
  isEdited: boolean;
}

type AttendanceForm = ObjectMap<StudentRecord>;

const getAttendanceForm = (student: IAttendanceTabStudent[]): AttendanceForm => {
  return student.reduce<AttendanceForm>((form, student) => {
    if (student.finalStatus !== 'noRecord') {
      form[student.userId] = {
        status: student.finalStatus,
        isEdited: !!student.edited,
      };
    }
    return form;
  }, {});
};

const TipMessage = ({ message, styles }: { styles?: CSS; message: string | string[] }) => {
  return h('div', style([pad('0.5rem 1rem'), 'mediumGrey', 'textCenter', styles]), message);
};

interface IAttendanceState {
  checkedIn: 0 | 1;
  status: 'present' | 'absent' | 'notPresent';
  attendance: 0 | 1;
  attendanceTime: UnixTimestamp | null;
  isLoading: boolean;
  searchField: string;
  isExportDialogOpen: boolean;
  isAttendanceDialogVisible: boolean;
  isAttendanceHelpDialogVisible: boolean;
  exportDownloadLink: string;
  exportRequestIsEmail: 0 | 1;
  sendEmail: boolean;
  exportFileName: string;
  isDownloadAlertOpen: boolean;
  isAttendaceUpdating: boolean;
  isStartProxyAttendanceDialogOpen: boolean;
  /** time left to auto stop proxy attendance */
  secondsLeft: number;
  scheduleAttendanceData: null | {
    rule: AttendanceScheduleType | 'unset' | null;
    time: {
      hours: number;
      minutes: number;
    };
    timeError: string;
    isConfirmAlertOpen: boolean;
    futureClassType: null | FollowForFutureClasses['futureClassType'];
  };
}

class Attendance extends IComponent<IAttendanceProps, IAttendanceState> {
  // stream of values indicating that attendance has been
  // changed for a student
  private attendanceUpdated$ = new Subject<void>();
  private isAttendanceUpdating: boolean;

  private courseId: string | undefined;
  private message1: string;
  private message2: string;
  private message3: string;
  private why: boolean;
  private iconStyle: Record<string, string | number>;
  private iconName: string;
  private textColor: string;

  private subscriptions: Subscription[] = [];

  private attendanceTimer = AttendanceTimer({
    attendanceTime: getAttendanceTime,
    onTick: (secondsLeft) => {
      this.setState({ secondsLeft });

      const classRole = classService.getRole();
      if (classRole === 'none') return;

      const { attendanceData } = getStore().getState().app;

      if (!attendanceData?.attendanceTime) return;

      const diff = dt.unix() - attendanceData.attendanceTime;
      const currentOffest = Math.floor(diff / 30) * 30;

      if (currentOffest >= ATTENDANCE_TIME_OUT) return;

      const nextSyncTime = currentOffest + attendanceData.attendanceTime;

      if (
        attendanceData.attendanceTime < nextSyncTime &&
        attendanceData.failuresLastSyncedAt < nextSyncTime
      ) {
        dispatch(ClassActions.refreshAttendanceResponders());
      }
    },
    onTimeout: () => {
      if (this.canStopProxyAttendance) {
        dispatch(ClassActions.stopProxyAttendance());
      }
    },
  });

  public componentWillReceiveProps(nextProps: IAttendanceProps) {
    if (nextProps.isTabOpen && !this.getProps().isTabOpen) {
      this.attendanceFetch();
    }
  }

  public componentWillUnmount() {
    // remove listeners to avoid memory leaks
    window.removeEventListener('beforeunload', this.beforeWindowUnload, { capture: true });
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.attendanceTimer.stop();
    if (this.classDataAwaiter) {
      clearInterval(this.classDataAwaiter);
      this.classDataAwaiter = undefined;
    }
  }

  public componentDidMount() {
    const tabs = document.getElementById('sliding-tabs');
    if (tabs) {
      tabs.focus();
    }
  }

  private isAccessible: boolean | undefined = false;

  private beforeWindowUnload = (e: BeforeUnloadEvent) => {
    if (this.isAttendanceUpdating) {
      e.preventDefault();
      return (e.returnValue =
        'Attendance not saved, changes will be lost. Are you sure you want to exit?');
    }

    const rootState = getStore().getState();
    const classData = rootState.class.data;
    const { attendanceData } = rootState.app;
    const currentUserId = rootState.getIn.session!.userId;
    const taker = attendanceData.taker ?? classData?.attendance?.taker;

    if (this.attendanceTimer.isRunning && taker?.userId === currentUserId) {
      e.preventDefault();
      return (e.returnValue = 'Attendance is in progress. Are you sure you want to exit?');
    }

    return undefined;
  };

  public componentWillMount() {
    this.initialize();

    if (document.activeElement && !this.isAccessible) {
      (<HTMLElement>document.activeElement).blur();
    }

    window.addEventListener('beforeunload', this.beforeWindowUnload, { capture: true });

    /**
     * whenever attendance is changed, set a flag so that
     * we can show warning to the user if they try to close the tab.
     * Its set to false after attendance has been updated
     */
    const attendanceUpdatesSubscription = this.attendanceUpdated$.subscribe(() => {
      this.isAttendanceUpdating = true;
    });

    const pusherSubscription = pusherService.events.subscribe((e: PusherEvent) => {
      if (e.event !== 'web-attendanceWarning') return;
      const payload: IPusherAttendanceData = e.payload;

      if (payload.isProxy !== 1) return;

      const isInClassTeam = classService.getRole() !== 'none';
      if (!isInClassTeam) return;

      if (payload.action === 'hideWarning') return this.attendanceTimer.stop();

      if (payload.action === 'showWarning') {
        setTimeout(() => this.attendanceTimer.start(), 500);
        return;
      }
    });

    this.subscriptions.push(attendanceUpdatesSubscription, pusherSubscription);
  }

  private classDataAwaiter?: NodeJS.Timer;

  private async initialize() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;

    const initialState: IAttendanceState = {
      isLoading: true,
      searchField: '',
      status: 'absent',
      attendance: 0,
      checkedIn: 0,
      attendanceTime: null,
      isExportDialogOpen: false,
      isAttendanceDialogVisible: false,
      isAttendanceHelpDialogVisible: false,
      exportDownloadLink: '',
      exportRequestIsEmail: 0,
      sendEmail: false,
      exportFileName: '',
      isDownloadAlertOpen: false,
      isAttendaceUpdating: false,
      isStartProxyAttendanceDialogOpen: false,
      secondsLeft: 0,
      scheduleAttendanceData: null,
    };

    await this.setState(initialState);

    if (this.getProps().isTabOpen) {
      await this.attendanceFetch();

      // wait until class activity data is fetched
      this.classDataAwaiter = setInterval(() => {
        const classData = getStore().getState().class.data;
        if (!classData) return;
        clearInterval(this.classDataAwaiter);
        this.classDataAwaiter = undefined;
        if (this.isProxyAttendanceInProgress) this.attendanceTimer.start();
      }, 100);
    }

    this.courseId = getStore().getState().courses.currentCourseId;
  }

  private async studentAttendancefetch() {
    const { cls } = this.getProps();
    const response = await dispatch(Actions.attendanceFetchMine(cls._id));
    await this.setState({
      checkedIn: response.checkedIn,
      attendance: response.attendance,
      attendanceTime: response.attendanceTime,
      status: response.attendance === 0 ? undefined : response.status,
    });
  }

  private async attendanceFetch() {
    const { cls, courseRole } = this.getProps();
    if (courseRole === 'student') {
      await this.studentAttendancefetch();
      this.setState({
        isLoading: false,
      });
    } else if (Class.status(cls) === 'inSession' || Class.status(cls) === 'closed') {
      await dispatch(Actions.attendanceFetch(cls._id));
      const attendance = getStore().getState().class.attendance;
      if (!attendance) {
        logger.warn('Attendance not present in store after fetching');
      }
    }
  }

  private get isProxyAttendanceInProgress() {
    const classAttendanceData = getStore().getState().class.data?.attendance;
    return classAttendanceData?.inProgress === 1 && classAttendanceData.isProxy === 1;
  }

  private get canStartProxyAttendance() {
    if (this.isProxyAttendanceInProgress) return false;

    const { cls } = this.getProps();
    const isInClassTeam = classService.getRole() !== 'none';
    const course = CourseService.getCurrentCourse();

    return course.canProxy === 1 && isInClassTeam && Class.status(cls) === 'inSession';
  }

  private get canStopProxyAttendance() {
    if (!this.isProxyAttendanceInProgress) return false;

    const rootState = getStore().getState();
    const userId = rootState.getIn.session!.userId;
    const taker = rootState.class.data?.attendance?.taker;

    return taker.userId === userId;
  }

  private noRecordView() {
    return h(
      'div.class-attendance',
      style(['mediumGrey', 'textCenter', pad('5em 1em 4em')]),
      NO_ATTENDANCE_MESSAGE
    );
  }

  private canShowAutoScheduleView() {
    const { cls } = this.getProps();
    const course = CourseService.getCurrentCourse();
    const isInClassTeam = classService.getRole() !== 'none';
    if (!isInClassTeam || !course?.canProxy) return false;
    return Class.status(cls) === 'open' && cls.details.scheStartTime > dt.unix();
  }

  private getIsRemoteAttendanceRecorded() {
    const { attendance } = getStore().getState().class;
    return Boolean(attendance?.remoteAuto);
  }

  private getIsInPersonAttendanceRecorded() {
    const { attendance } = getStore().getState().class;
    return Boolean(attendance?.inPersonAuto);
  }

  private getIsAutoAttendanceScheduled() {
    if (this.getIsInPersonAttendanceRecorded()) return false;

    const { cls } = this.getProps();
    const isScheduled = Boolean(cls.autoSchedule?.isScheduled);

    return isScheduled && cls.details.scheEndTime > dt.unix();
  }

  private getClassAttendanceStatusColor() {
    if (this.getIsInPersonAttendanceRecorded()) return colors.green;
    if (this.getIsAutoAttendanceScheduled()) return colors.blue;
    return colors.red;
  }

  private getClassAttendanceStatus() {
    const isInPersonAttendanceRecorded = this.getIsInPersonAttendanceRecorded();
    if (isInPersonAttendanceRecorded) return 'RECORDED';

    const isAutoAttendanceScheduled = this.getIsAutoAttendanceScheduled();
    if (isAutoAttendanceScheduled) return 'SCHEDULED';

    if (this.canShowAutoScheduleView()) return 'NOT SCHEDULED';

    return 'NOT RECORDED';
  }

  private classRoomAttendanceStatus() {
    const classAttendanceStatusColor = this.getClassAttendanceStatusColor();

    return h('div.fs-md.cell.cell--full-width', [
      h('span', style(['flex', 'alignCenter']), [
        SvgIcon({
          icon: PresentIcon,
          className: cn('attendance-help-cell__icon', 'sm'),
          style: { color: classAttendanceStatusColor },
        }),
        'Classroom attendance status',
      ]),
      h(
        'span.tag',
        { style: { backgroundColor: classAttendanceStatusColor } },
        this.getClassAttendanceStatus()
      ),
    ]);
  }

  private remoteClassAttendanceStatus() {
    const { cls } = this.getProps();

    if (!cls.details.isOnlineMeeting) return null;

    const isRemoteAttendanceRecorded = this.getIsRemoteAttendanceRecorded();

    return h('div.fs-md.cell.cell--full-width', [
      h('span', style(['flex', 'alignCenter']), [
        SvgIcon({
          icon: RemotePresentIcon,
          className: cn('attendance-help-cell__icon', 'sm', {
            'fc-red': !isRemoteAttendanceRecorded,
            'fc-green': isRemoteAttendanceRecorded,
          }),
        }),
        'Remote attendance status',
      ]),
      h(
        'span.tag',
        {
          className: cn({
            red: !isRemoteAttendanceRecorded,
            green: isRemoteAttendanceRecorded,
          }),
        },
        isRemoteAttendanceRecorded ? 'RECORDED' : 'NOT RECORDED'
      ),
    ]);
  }

  private downloadAppSection() {
    const { cls } = this.getProps();
    const course = CourseService.getCurrentCourse();
    const isInClassTeam = classService.getRole() !== 'none';

    const isVisible =
      !course.canProxy &&
      isInClassTeam &&
      (Class.status(cls) === 'open' || Class.status(cls) === 'inSession');

    if (!isVisible) return null;

    return h('div', [
      h(
        'div',
        style(
          ['lightGrey', 'textCenter', pt('1rem')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          }
        ),
        [
          "Did you know Acadly's Android and iOS apps " +
            'can help you mark student attendance with ' +
            'a single tap? Try them out today.',
        ]
      ),
      h('div', style(['flex', 'spaceAround', 'alignCenter', { width: '100%' }]), [
        h(
          'a',
          {
            target: '_blank',
            href: 'https://play.google.com/store/apps/details?id=co.acetone.acadly',
          },
          [
            h('img', {
              style: {
                maxWidth: '100%',
                maxHeight: '6.65em',
              },
              src: 'https://s3.amazonaws.com/static.acad.ly/img/en_badge_web_generic-p-500.png',
            }),
          ]
        ),
        h(
          'a',
          {
            target: '_blank',
            href: 'https://itunes.apple.com/us/app/acadly/id1161073387?mt=8',
          },
          [
            h('img', {
              style: {
                maxWidth: '100%',
                maxHeight: '6em',
              },
              src: 'https://s3.amazonaws.com/static.acad.ly/img/itunes-link-01.png',
            }),
          ]
        ),
      ]),
    ]);
  }

  private getScheduleAttendanceTime() {
    const { cls } = this.getProps();
    // set mid time of the schedule class time as default value
    const midTime = (cls.details.scheStartTime + cls.details.scheEndTime) / 2;
    let time = dt.fromUnix(midTime);

    if (cls.autoSchedule?.isScheduled && cls.autoSchedule.time) {
      const d = dt.now();
      const [hours, minutes] = cls.autoSchedule.time.split(':').map((num) => parseFloat(num));
      d.setHours(hours);
      d.setMinutes(minutes);
      time = d;
    }

    return time;
  }

  private getAutoScheduleMessage() {
    const { cls } = this.getProps();
    const { autoSchedule } = cls;

    if (!autoSchedule?.isScheduled) return null;

    const styles = style(['textCenter', mt('1rem')]);

    switch (autoSchedule.scheduleRule) {
      case 'first10':
        return h('div', styles, 'Scheduled to run during the first 10 minutes of the class');
      case 'last10':
        return h('div', styles, 'Scheduled to run during the last 10 minutes of the class');
      case 'random':
        return h('div', styles, 'Scheduled to run any time during the class');
      case 'timeSet': {
        const isInPersonAttendanceRecorded = this.getIsInPersonAttendanceRecorded();
        if (isInPersonAttendanceRecorded) return null;
        return h(
          'div',
          styles,
          `Scheduled to run at ${dt.format(this.getScheduleAttendanceTime(), 'hh:mm A')}`
        );
      }
      default:
        return null;
    }
  }

  private showScheduleAttendanceDialog = () => {
    const { cls } = this.getProps();

    const time = this.getScheduleAttendanceTime();
    const rule = !cls.autoSchedule?.isScheduled ? null : cls.autoSchedule.scheduleRule;

    this.setState({
      scheduleAttendanceData: {
        rule,
        time: {
          hours: time.getHours(),
          minutes: time.getMinutes(),
        },
        timeError: '',
        futureClassType: null,
        isConfirmAlertOpen: false,
      },
    });
  };

  private hideScheduleAttendanceDialog = () => {
    this.setState({
      scheduleAttendanceData: null,
    });
  };

  private scheduleAttendance = async () => {
    const { cls } = this.getProps();
    const { scheduleAttendanceData } = this.getState();

    if (!scheduleAttendanceData) return;

    const followForFutureClasses: FollowForFutureClasses = scheduleAttendanceData.futureClassType
      ? { followForFutureClasses: 1, futureClassType: scheduleAttendanceData.futureClassType }
      : { followForFutureClasses: 0, futureClassType: '' };

    if (scheduleAttendanceData.rule === 'unset') {
      await dispatch(
        ClassActions.editScheduleAttendance({
          classId: cls._id,
          remove: 1,
          scheduleRule: '',
          time: '',
          ...followForFutureClasses,
        })
      );
      this.hideScheduleAttendanceDialog();
      return;
    }

    const scheduleRule: ScheduleAttendanceRule =
      scheduleAttendanceData.rule === 'timeSet'
        ? { scheduleRule: 'timeSet', time: u.timeToHHMM(scheduleAttendanceData.time) }
        : { scheduleRule: scheduleAttendanceData.rule, time: '' };

    if (cls.autoSchedule?.isScheduled) {
      await dispatch(
        ClassActions.editScheduleAttendance({
          classId: cls._id,
          remove: 0,
          ...scheduleRule,
          ...followForFutureClasses,
        })
      );
    } else {
      await dispatch(
        ClassActions.scheduleAttendance({
          classId: cls._id,
          ...scheduleRule,
          ...followForFutureClasses,
        })
      );
    }

    this.hideScheduleAttendanceDialog();
  };

  private showConfirmScheduleAttendance = () => {
    const { scheduleAttendanceData } = this.getState();
    if (!scheduleAttendanceData) return;
    this.setState({
      scheduleAttendanceData: {
        ...scheduleAttendanceData,
        isConfirmAlertOpen: true,
        futureClassType: null,
      },
    });
  };

  private hideConfirmScheduleAttendance = () => {
    const { scheduleAttendanceData } = this.getState();
    if (!scheduleAttendanceData) return;
    this.setState({
      scheduleAttendanceData: {
        ...scheduleAttendanceData,
        isConfirmAlertOpen: false,
      },
    });
  };

  private confirmScheduleAttendanceAlert() {
    const { cls } = this.getProps();
    const { scheduleAttendanceData } = this.getState();

    if (!scheduleAttendanceData?.isConfirmAlertOpen) return null;

    const startDayAndTime = dt.format(cls.details.scheStartTime, 'ddd, hh:mm A');
    const endTime = dt.format(cls.details.scheEndTime, 'hh:mm A');

    const isRemoving = scheduleAttendanceData.rule === 'unset';

    const isAllOptionVisible =
      scheduleAttendanceData.rule !== 'timeSet' && scheduleAttendanceData.rule !== 'unset';

    const Option = ({
      label,
      helperText,
      isSelected,
      onSelect: onclick,
    }: {
      label: string;
      helperText: string;
      isSelected: boolean;
      onSelect: () => void;
    }) => {
      return h('div', style(['flex', 'row', mb('8px'), 'large', 'pointer'], {}, { onclick }), [
        RadioButton({
          selected: isSelected,
          color: isSelected ? colors.blue : colors.grey,
          style: style([mt('2px'), mr('1rem')]).style,
        }),
        h('div', [
          h('div', style([mb('4px'), isSelected ? 'blue' : 'grey']), label),
          h('div.fs-sm', { style: { minHeight: '24px' } }, helperText),
        ]),
      ]);
    };

    const autoSchedule = cls.autoSchedule;
    const showWarningOnly =
      isRemoving && autoSchedule.isScheduled && autoSchedule.followForFutureClasses === 0;

    return Alert(
      {
        open: true,
        style: { width: showWarningOnly ? '25em' : '20em' },
        title: showWarningOnly ? h('div.fc-orange', 'Warning') : 'Other future classes',
        actions: [
          FlatButton('CANCEL', {
            type: 'secondary',
            onclick: this.hideConfirmScheduleAttendance,
          }),
          FlatButton(isRemoving ? 'REMOVE' : 'CONFIRM', {
            type: 'primary',
            onclick: this.scheduleAttendance,
          }),
        ],
      },
      showWarningOnly
        ? ['Are you sure you want to remove the scheduled attendance?']
        : [
            h(
              'p',
              style([mb('1rem')]),
              isRemoving
                ? 'Would you like to remove this schedule for other future classes?'
                : 'Would you like to replicate this schedule for other future classes?'
            ),
            Option({
              isSelected: scheduleAttendanceData.futureClassType === 'similar',
              label: 'For all similar classes',
              helperText: `${startDayAndTime} - ${endTime} classes`,
              onSelect: () => {
                this.setState({
                  scheduleAttendanceData: {
                    ...scheduleAttendanceData,
                    futureClassType:
                      scheduleAttendanceData.futureClassType === 'similar' ? null : 'similar',
                  },
                });
              },
            }),
            isAllOptionVisible
              ? Option({
                  isSelected: scheduleAttendanceData.futureClassType === 'all',
                  label: 'For all future classes',
                  helperText: 'Any class for this course',
                  onSelect: () => {
                    this.setState({
                      scheduleAttendanceData: {
                        ...scheduleAttendanceData,
                        futureClassType:
                          scheduleAttendanceData.futureClassType === 'all' ? null : 'all',
                      },
                    });
                  },
                })
              : null,
          ]
    );
  }

  private scheduleAttendanceDialog() {
    const { cls } = this.getProps();
    const { isMobile } = getStore().getState().app;
    const { scheduleAttendanceData } = this.getState();

    if (!scheduleAttendanceData) return;

    const isAutoAttendanceScheduled = this.getIsAutoAttendanceScheduled();

    const isInValid = Boolean(
      scheduleAttendanceData.rule !== 'unset' &&
        (!scheduleAttendanceData.rule ||
          (scheduleAttendanceData.rule === 'timeSet' && scheduleAttendanceData.timeError))
    );

    const Option = ({
      label,
      error,
      helperText,
      isSelected,
      onSelect: onclick,
    }: {
      label: string;
      error?: boolean;
      helperText: string;
      isSelected: boolean;
      onSelect: () => void;
    }) => {
      return h('div', style(['flex', 'row', mb('8px'), 'large', 'pointer'], {}, { onclick }), [
        RadioButton({
          selected: isSelected,
          color: error ? colors.errorRed : isSelected ? colors.blue : colors.grey,
          style: style([mt('2px'), mr('1rem')]).style,
        }),
        h('div', [
          h('div', style([mb('4px'), error ? 'errorRed' : isSelected ? 'blue' : 'grey']), label),
          h('div.fs-sm', { style: { minHeight: '24px' } }, isSelected ? helperText : null),
        ]),
      ]);
    };

    const TimeOption = () => {
      const isSelected = scheduleAttendanceData.rule === 'timeSet';
      return h('div', style(['flex', 'row', 'large', 'pointer'], {}, { onclick }), [
        RadioButton({
          selected: isSelected,
          color: isSelected ? colors.blue : colors.grey,
          style: style([mt('2px'), mr('1rem')]).style,
        }),
        h('div', style([{ flex: 1 }]), [
          TimePicker({
            style: style(['fullWidth', mb('4px'), isSelected ? 'blue' : 'grey']).style,
            time: scheduleAttendanceData.time,
            renderTime: (time) => {
              return h(
                'div',
                style([{ flex: 1 }]),
                !isSelected ? 'Set a time' : `At ${u.showTime(time)}`
              );
            },
            onChange: (time) => {
              const { scheStartTime, scheEndTime } = cls.details;

              const d = dt.fromUnix(scheStartTime);
              d.setHours(time.hours, time.minutes, 0, 0);

              const ts = dt.toUnix(d);
              const isWithinClassTiming = scheStartTime < ts && ts < scheEndTime;

              if (isWithinClassTiming) {
                this.setState({
                  scheduleAttendanceData: {
                    ...scheduleAttendanceData,
                    rule: 'timeSet',
                    time,
                    timeError: '',
                  },
                });
              } else {
                const startTime = dt.format(scheStartTime, 'hh:mm A');
                const endTime = dt.format(scheEndTime, 'hh:mm A');
                this.setState({
                  scheduleAttendanceData: {
                    ...scheduleAttendanceData,
                    rule: 'timeSet',
                    time,
                    timeError: `Pick a time between ${startTime} - ${endTime}`,
                  },
                });
              }
            },
          }),
          h(
            'div.fs-sm',
            {
              style: {
                minHeight: '24px',
                color: scheduleAttendanceData.timeError ? colors.errorRed : undefined,
              },
            },
            isSelected ? scheduleAttendanceData.timeError || 'Most predictable' : null
          ),
        ]),
      ]);
    };

    const mobileActionStyles = {
      width: '48px',
      height: '48px',
      padding: '0.75rem',
      margin: '0.5rem 0 1rem',
    };

    return Dialog(
      {
        open: true,
        style: {
          width: '35em',
        },
        bodyStyle: {
          padding: '1rem',
          paddingTop: '2rem',
        },
        secondaryAction: isMobile
          ? {
              type: 'view',

              view: SvgIcon({
                icon: CloseIcon,
                style: mobileActionStyles,
                onclick: this.hideScheduleAttendanceDialog,
              }),
            }
          : null,
        primaryAction: isMobile
          ? {
              type: 'view',
              view: SvgIcon({
                icon: DoneIcon,
                style: {
                  ...mobileActionStyles,
                  opacity: isInValid ? 0.5 : undefined,
                },
                onclick: isInValid ? undefined : this.showConfirmScheduleAttendance,
              }),
            }
          : null,
        title: h('div.fs-xlg', 'Schedule in-person attendance to run'),
      },
      [
        Option({
          isSelected: scheduleAttendanceData.rule === 'random',
          label: 'Anytime during the session',
          helperText: 'Least predictable, most foolproof',
          onSelect: () => {
            this.setState({
              scheduleAttendanceData: {
                ...scheduleAttendanceData,
                rule: 'random',
              },
            });
          },
        }),
        Option({
          isSelected: scheduleAttendanceData.rule === 'first10',
          label: 'During the first 10 minutes',
          helperText: 'Predictable over a number of sessions',
          onSelect: () => {
            this.setState({
              scheduleAttendanceData: {
                ...scheduleAttendanceData,
                rule: 'first10',
              },
            });
          },
        }),
        Option({
          isSelected: scheduleAttendanceData.rule === 'last10',
          label: 'During the last 10 minutes',
          helperText: 'Predictable over a number of sessions',
          onSelect: () => {
            this.setState({
              scheduleAttendanceData: {
                ...scheduleAttendanceData,
                rule: 'last10',
              },
            });
          },
        }),
        TimeOption(),
        isAutoAttendanceScheduled
          ? Option({
              error: true,
              isSelected: scheduleAttendanceData.rule === 'unset',
              label: 'Unset schedule',
              helperText: '',
              onSelect: () => {
                this.setState({
                  scheduleAttendanceData: {
                    ...scheduleAttendanceData,
                    rule: 'unset',
                  },
                });
              },
            })
          : null,
        !isMobile
          ? h('div', style(['flex', 'flexEnd']), [
              FlatButton('Cancel', {
                type: 'primary',
                onclick: this.hideScheduleAttendanceDialog,
              }),
              FlatButton('Save', {
                type: 'secondary',
                disabled: isInValid,
                onclick: this.showConfirmScheduleAttendance,
              }),
            ])
          : null,
        this.confirmScheduleAttendanceAlert(),
      ]
    );
  }

  private openClassView() {
    const isInClassTeam = classService.getRole() !== 'none';

    const isAutoAttendanceScheduled = this.getIsAutoAttendanceScheduled();

    return h('div.class-attendance', style(['mediumGrey', pad('4.5em 1em 4em')]), [
      this.classRoomAttendanceStatus(),
      this.canShowAutoScheduleView()
        ? CtaButton({
            className: 'uppercase',
            variant: 'green',
            label: isAutoAttendanceScheduled
              ? 'EDIT SCHEDULED ATTENDANCE'
              : 'SCHEDULE AUTO ATTENDANCE',
            style: {
              textTransform: 'uppercase',
            },
            onClick: this.showScheduleAttendanceDialog,
          })
        : null,
      this.canShowAutoScheduleView() ? this.scheduleAttendanceDialog() : null,
      this.getAutoScheduleMessage(),
      !isAutoAttendanceScheduled && this.canShowAutoScheduleView()
        ? TipMessage({
            message: [
              'If you choose to schedule recording of attendance ',
              'during the lecture, the process will run automatically ',
              'as per your preferences',
            ],
          })
        : isInClassTeam
        ? TipMessage({
            styles: mt('1rem'),
            message: [
              'Use this tab to manually edit the class attendance ',
              'after the scheduled start time',
            ],
          })
        : TipMessage({
            styles: mt('1rem'),
            message: [
              'If the class in-charge or class assistant records attendance, it will show up here',
            ],
          }),
      this.downloadAppSection(),
    ]);
  }

  private handleStartProxyAttendance = async () => {
    const { cls } = this.getProps();
    if (!this.canStartProxyAttendance) return;
    await dispatch(ClassActions.startProxyAttendance(cls._id));
    this.hideStartProxyAttendanceDialog();
    // start timer
    this.attendanceTimer.start();
  };

  private handleStopProxyAttendance = async () => {
    if (!this.canStopProxyAttendance) return;
    await dispatch(ClassActions.stopProxyAttendance());
    // stop timer
    this.attendanceTimer.stop();
  };

  private showStartProxyAttendanceDialog = () => {
    this.setState({
      isStartProxyAttendanceDialogOpen: true,
    });
  };

  private hideStartProxyAttendanceDialog = () => {
    this.setState({
      isStartProxyAttendanceDialogOpen: false,
    });
  };

  private startProxyAttendanceDialog() {
    if (!this.canStartProxyAttendance) return null;

    const { isMobile } = getStore().getState().app;
    const { isStartProxyAttendanceDialogOpen } = this.getState();

    const BulletPoint = (content: View | View[]) => {
      return h(
        'li',
        style([mb('0.75rem'), { lineHeight: 1.5 }]),
        Array.isArray(content) ? content : [content]
      );
    };

    return Dialog(
      {
        open: isStartProxyAttendanceDialogOpen,
        thinHeader: true,
        style: {
          width: '35em',
        },
        bodyStyle: {
          padding: '1rem',
        },
        title: h('div', style(['flex', 'alignCenter']), [
          isMobile
            ? SvgIcon({
                icon: BackIcon,
                style: {
                  marginRight: '1rem',
                  marginLeft: '0.25rem',
                  width: 24,
                  height: 24,
                },
                onclick: this.hideStartProxyAttendanceDialog,
              })
            : null,
          'Recording in-person attendance',
        ]),
      },
      [
        h('p', 'Before you launch the attendance process, please ensure:'),
        h('ul', [
          BulletPoint([
            "Students have Acadly's iOS or Android apps (NOT the Acadly website) ",
            'open on their mobile devices',
          ]),
          BulletPoint("Students' mobile devices are connected to the internet"),
          BulletPoint('Students have granted Acadly app the necessary permissions'),
        ]),
        h('div', style([mt('2rem')]), [
          CtaButton({
            variant: 'green',
            label: 'TAKE ATTENDANCE',
            onClick: this.handleStartProxyAttendance,
          }),
          CtaButton({
            variant: 'grey',
            label: 'BACK',
            onClick: this.hideStartProxyAttendanceDialog,
          }),
        ]),
      ]
    );
  }

  private getAttendanceErrorMessage(code: AttendanceFailureCode) {
    switch (code) {
      case 'bleNotSupported':
        return "Is using a device that doesn't support Bluetooth";
      case 'noGPS':
        return 'Has not switched on the GPS';
      case 'noPermissions':
      default:
        return 'Has not provided adequate permissions';
    }
  }

  private attendanceFailureErrorCell({
    attendanceTime,
    failure,
  }: {
    attendanceTime: UnixTimestamp;
    failure: AttendanceProcessFailure;
  }) {
    const timeOffset = failure.failedOn - attendanceTime;

    const Wrapper = ({ children, key }: { children: View; key: string }) => {
      return h('div.attendance-error-cell', { key }, [
        h('div.cell', timeOffset + ' ' + u.pluralize(timeOffset, 'second')),
        children,
      ]);
    };

    if (failure.type === 'unresponsive_attendees_failure') {
      return Wrapper({
        key: `attendance-error-cell-${timeOffset}`,
        children: h('div', [
          h('div.cell', style([margin('0 1rem'), { backgroundColor: '#FFCFAD' }]), [
            h('span', `Students with app closed`),
            h('span', failure.unresponsiveAttendees.toString()),
          ]),
          h(
            'div',
            style([pad('0.25rem 1rem 0.5rem'), 'orange']),
            'Please ask them to open the app'
          ),
        ]),
      });
    }

    const { attendee, failureCode } = failure;

    return Wrapper({
      key: `attendance-error-cell-${timeOffset}-${attendee.userId}`,
      children: User(
        {
          avatar: {
            url: attendee.avatar,
            creator: attendee.name,
          },
          title: attendee.name,
          subtitleClassNames: ['fc-red'],
          subtitle: this.getAttendanceErrorMessage(failureCode),
        },
        style([pad('0.5rem 1rem')])
      ),
    });
  }

  private proxyAttendanceInProgressView() {
    if (!this.isProxyAttendanceInProgress) return null;

    const attendanceData = getStore().getState().app.attendanceData || {
      attendanceTime: dt.unix(),
      failures: [] as AttendanceProcessFailure[],
      numEnrolled: 0,
      numPresent: 0,
      numAvailable: 0,
      numMarkedPresentNow: 0,
    };

    const { attendanceTime, failures, numEnrolled, numPresent, numAvailable, numMarkedPresentNow } =
      attendanceData;

    const { secondsLeft } = this.getState();

    const wrapperStyles = style([
      'whiteBackground',
      'black',
      pt('1rem'),
      mt('50px'),
      { borderRadius: 4 },
    ]);

    const separaterStyles = style([
      margin('0.5rem 1rem'),
      { backgroundColor: colors.lighterGrey, height: 1, border: 0 },
    ]);

    return h('div.class-attendance', style(['mediumGrey', 'relative', pad('4.5em 1em 4em')]), [
      this.classRoomAttendanceStatus(),
      this.remoteClassAttendanceStatus(),
      h('div', wrapperStyles, [
        h('div.cell', style(['large', 'bold']), 'Recording in-person attendance'),
        h('div.cell', [
          h('span', 'Unmarked students'),
          h('span', (numEnrolled - numPresent).toString()),
        ]),
        h('div.cell', [h('span', 'Students with app open'), h('span', numAvailable.toString())]),
        h('div.cell', [
          h('div.attendance-gif', style([{ padding: '3rem' }])),
          h('div', style(['textRight']), [
            h('div', style(['bold', mb('0.5rem')]), 'Marked now'),
            h('div', style(['bold', { fontSize: 28 }]), numMarkedPresentNow.toString()),
          ]),
        ]),
        h('div.cell', style([margin('0 1rem 1rem'), { backgroundColor: '#D1EDAD' }]), [
          h('span', 'Total present in this class'),
          h('span', numPresent.toString()),
        ]),
        failures.length < 1 ? null : h('hr', separaterStyles),
        failures.length < 1 ? null : h('div.cell', style(['bold']), 'PROCESS FAILURES'),
        failures.length < 1
          ? null
          : h(
              'div',
              u.flatten(
                failures.map((failure, index) => {
                  const isLast = failures.length - 1 === index;
                  return [
                    this.attendanceFailureErrorCell({ attendanceTime, failure }),
                    isLast ? null : h('hr', separaterStyles),
                  ];
                })
              )
            ),
        h(
          'div.cell',
          style([
            mt('1.5rem'),
            {
              position: 'sticky',
              bottom: 0,
              backgroundColor: colors.white,
              borderTop: `1px solid ${colors.lightGrey}`,
            },
          ]),
          [
            h('span', secondsLeft + ' ' + u.pluralize(secondsLeft, 'second') + ' remaining'),
            FlatButton('STOP ATTENDANCE', {
              disabled: !this.canStopProxyAttendance,
              type: 'secondary',
              label: 'STOP ATTENDANCE',
              onclick: this.handleStopProxyAttendance,
            }),
          ]
        ),
      ]),
    ]);
  }

  private presentMarkedStudents() {
    const attendance = getStore().getState().class.attendance;
    if (!attendance) return 0;
    return attendance.checkedIn.filter(
      (s) => s.finalStatus === 'present' || s.finalStatus === 'late'
    ).length;
  }

  private getStudents() {
    const classState = getStore().getState().class;
    if (!classState.attendance) return [];
    return classState.attendance.checkedIn;
  }

  private openAttendanceHelpDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    this.setState({
      isAttendanceHelpDialogVisible: true,
    });
  }

  private closeAttendanceHelpDialog() {
    u.resetTabIndices();
    (this.lastFocusedElement as any).focus();
    this.setState({
      isAttendanceHelpDialogVisible: false,
    });
  }

  private attendanceHelpDialog() {
    const { courseRole } = this.getProps();
    const { isAttendanceHelpDialogVisible } = this.getState();
    const isMobile = getStore().getState().app.isMobile;

    if (courseRole === 'student') return null;

    const HelpCell = (props: { icon: View; title: View; details: View[] }) => {
      return h('div.attendance-help-cell', [
        h('div.attendance-help-cell__title', [props.icon, props.title]),
        h('div.attendance-help-cell__details', props.details),
      ]);
    };

    return Dialog(
      {
        open: isAttendanceHelpDialogVisible,
        title: "Acadly's Attendance Statuses",
        thinHeader: true,
        // placeholder to center align title in mobile view
        primaryAction: isMobile
          ? { type: 'view', view: h('div') }
          : {
              label: 'Okay',
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => this.closeAttendanceHelpDialog(),
            },
        secondaryAction: isMobile
          ? {
              label: 'Okay',
              tabIndex: this.isAccessible ? 0 : undefined,
              mobileLabel: Icon(icons.cross),
              onclick: () => this.closeAttendanceHelpDialog(),
            }
          : undefined,
      },
      [
        h('div.attendance-help', [
          HelpCell({
            icon: SvgIcon({
              icon: PresentIcon,
              className: 'fc-green attendance-help-cell__icon',
            }),
            title: h('span.fc-green', 'PRESENT'),
            details: [
              `Student was physically present in the instructor's proximity `,
              `and was discovered by the automatic attendance process`,
            ],
          }),
          HelpCell({
            icon: SvgIcon({
              icon: RemotePresentIcon,
              className: 'fc-green attendance-help-cell__icon',
            }),
            title: h('span.fc-green', '4/5 CHECKS'),
            details: [
              `Student was attending the class remotely (in case of online classes) `,
              `and responded to 4 out of 5 attendance checks`,
            ],
          }),
          HelpCell({
            icon: Icon(icons.cross, { className: 'fc-red attendance-help-cell__icon' }),
            title: h('span.fc-red', 'NO RECORD'),
            details: [`Student did not access Acadly during the class`],
          }),
          HelpCell({
            icon: Icon(icons.pencil, { className: 'fc-blue attendance-help-cell__icon' }),
            title: h('span.fc-blue', 'EDITED'),
            details: [
              `Student's attendance record was manually edited by `,
              `either the class in-charge or the class assistant`,
            ],
          }),
          h(
            'a',
            {
              target: '_blank',
              href: ATTENDANCE_HELP,
            },
            'Click here to learn more'
          ),
        ]),
      ]
    );
  }

  private async openEditAttendanceDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isAttendanceDialogVisible: true,
    });
  }

  private async closeEditAttendanceDialog() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    this.isAttendanceUpdating = false;
    await this.setState({ isAttendanceDialogVisible: false });
  }

  private canMarkAttendance() {
    const { cls } = this.getProps();
    const classRole = classService.getRole(cls._id);
    return (
      classRole !== 'none' && (Class.status(cls) === 'closed' || Class.status(cls) === 'inSession')
    );
  }

  private studentList() {
    const { cls } = this.getProps();

    const rootState = getStore().getState();
    const state = this.getState();

    const { attendance, sortStudentBy } = rootState.class;

    const numPresentMarkedStudents = this.presentMarkedStudents();

    if (!attendance) return fullScreenLoader;

    const students = getStudentListData(
      attendance.checkedIn,
      state.searchField,
      sortStudentBy,
      (s) => s.name
    );

    return h('div', [
      h('div.mark-multiple', style(['flex', pad('0.5rem 1rem')])),
      h('div', style(['flex', 'spaceBetween', pad('0.5rem 0rem')]), 'Students'),
      h('div', style(['flex', 'spaceBetween', pad('0.5rem 0rem')]), [
        h('div', style(['small', 'grey']), [`${numPresentMarkedStudents} Marked Present`]),
        h('div', [
          h(
            'a',
            {
              style: { marginLeft: '1rem', cursor: 'pointer' },
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => this.openAttendanceHelpDialog(),
            },
            'Help'
          ),
          this.canMarkAttendance()
            ? h(
                'a',
                {
                  style: { marginLeft: '1rem', cursor: 'pointer' },
                  tabIndex: this.isAccessible ? 0 : undefined,
                  onclick: () => this.openEditAttendanceDialog(),
                },
                'Edit'
              )
            : null,
        ]),
      ]),
      attendance.checkedIn.length > 0
        ? StudentList({
            sortBy: sortStudentBy,
            children: students.map((student) =>
              StudentCell({
                student,
                status: student.finalStatus,
                isAccessible: !!this.isAccessible,
              })
            ),
            onSearch: (term) => {
              this.setState({
                searchField: term,
              });
            },
            onSort: (sortBy) => {
              dispatch(Actions.setSortStudentOrder(sortBy));
            },
          })
        : null,
      h(AttendanceDialog, {
        isVisible: state.isAttendanceDialogVisible,
        classId: cls._id,
        courseId: this.courseId!,
        students: this.getStudents(),
        onClose: () => this.closeEditAttendanceDialog(),
        attendanceUpdateStream$: this.attendanceUpdated$,
      }),
      this.attendanceHelpDialog(),
    ]);
  }

  private inSessionClassView() {
    const course = CourseService.getCurrentCourse();
    const isInClassTeam = classService.getRole() !== 'none';

    if (this.isProxyAttendanceInProgress && isInClassTeam) {
      return this.proxyAttendanceInProgressView();
    }

    return h('div.class-attendance', style(['mediumGrey', pad('4.5em 1em 4em')]), [
      this.classRoomAttendanceStatus(),
      this.remoteClassAttendanceStatus(),
      course.canProxy && isInClassTeam
        ? CtaButton({
            disabled: !this.canStartProxyAttendance,
            className: 'uppercase',
            variant: 'green',
            label: 'Take in-person attendance',
            style: {
              textTransform: 'uppercase',
            },
            onClick: this.showStartProxyAttendanceDialog,
          })
        : null,
      course.canProxy && isInClassTeam ? this.startProxyAttendanceDialog() : null,
      this.getAutoScheduleMessage(),
      TipMessage({
        message: [
          'You will be able to export attendance data to a CSV file ',
          'once the class is over',
        ],
      }),
      this.downloadAppSection(),
      this.studentList(),
    ]);
  }

  private closedClassView() {
    return h('div.class-attendance', style(['mediumGrey', pad('4.5em 1em 4em')]), [
      this.classRoomAttendanceStatus(),
      this.remoteClassAttendanceStatus(),
      h(
        'div',
        style(
          ['pointer', 'flex', mt('0.5rem'), pad('0.5rem 1rem')],
          { backgroundColor: 'white' },
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.openExportDialog(),
          }
        ),
        [
          Icon(icons.export2, style([pad('0.5rem'), 'x-large'])),
          h('span', style([pad('0.5rem'), 'large', 'blue']), 'Export attendance data as CSV'),
        ]
      ),
      this.exportDialog(),
      this.downloadAlert(),
      this.studentList(),
    ]);
  }

  public render() {
    const { courseRole, cls } = this.getProps();

    this.attendanceMessage();

    if (courseRole === 'student') return ContentView(this.studentView());

    switch (Class.status(cls)) {
      case 'noRecord':
      case 'holiday':
      case 'canceled':
        return ContentView(this.noRecordView());
      case 'open':
        return ContentView(this.openClassView());
      case 'inSession':
        return ContentView(this.inSessionClassView());
      case 'closed':
        return ContentView(this.closedClassView());
      default:
        return null;
    }
  }

  private downloadAlert() {
    const isOpen = this.getState().isDownloadAlertOpen;
    const fileName = this.getState().exportFileName;
    const downloadLink = this.getState().exportDownloadLink;
    const styles = {
      display: 'flex',
      width: '20em',
      borderRadius: '4px',
      backgroundColor: 'white',
      alignItems: 'center',
      border: `1px solid ${colors.lightestGrey}`,
      maxWidth: '100%',
      lineHeight: '1.5em',
      boxSizing: 'border-box',
      paddingRight: '0.5rem',
    };

    return Alert(
      {
        title: 'Success',
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('Cancel', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElement as any).focus();
              this.backClickHandler();
            },
          }),
        ],
      },
      [
        h(
          'div',
          style(
            [pad('0.5rem 0.5rem 0.5rem 0rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          'File Generated'
        ),
        h(
          'div.acadly-attachment',
          {
            style: styles,
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h(
              'div.attachment-icon',
              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '0.5rem',
                  width: '2.5rem',
                  color: 'white',
                  height: '2.5rem',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  backgroundColor: colors.green,
                },
              },
              'CSV'
            ),
            h(
              'div',
              style([
                'thin',
                {
                  flex: 1,
                  color: colors.black,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: '0.5em',
                },
              ]),
              fileName
            ),
            h(
              'a',
              {
                style: {
                  color: colors.green,
                },
                target: '_blank',
                href: downloadLink || undefined,
              },
              [Icon(icons.download, style(['blue', 'large', 'pointer', pad('0.5rem')]))]
            ),
          ]
        ),
      ]
    );
  }

  private exportDialog() {
    const isOpen = this.getState().isExportDialogOpen;
    const isEmail = this.getState().exportRequestIsEmail;
    const sendEmail = this.getState().sendEmail;

    const option = (opts: { selected: boolean; label: string; key: string; onclick: () => any }) =>
      h(
        'div',
        style(
          ['flex', 'alignCenter'],
          {},
          {
            onclick: opts.onclick,
            tabIndex: this.isAccessible ? 0 : undefined,
            key: opts.key,
          }
        ),
        [
          RadioButton({
            selected: opts.selected,
            color: colors.teal,
          }),
          h(
            'div',
            style([
              pad('0.5rem 1rem'),
              ml('0.5rem'),
              {
                flex: '1',
                borderBottom: '1px solid lightGrey',
              },
            ]),
            opts.label
          ),
        ]
      );

    return Alert(
      {
        title: !sendEmail ? 'Exporting Attendance Data' : 'Success',
        titleStyle: {
          textAlign: 'center',
        },
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton(!sendEmail ? 'Cancel' : 'Download a copy', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: !sendEmail
              ? () => {
                  u.resetTabIndices();
                  (this.lastFocusedElement as any).focus();
                  this.backClickHandler();
                }
              : () => this.downloadACopyClickHandler(),
          }),
          FlatButton('Done', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: !sendEmail
              ? () => this.exportClickHandler()
              : () => {
                  u.resetTabIndices();
                  (this.lastFocusedElement as any).focus();
                  this.backClickHandler();
                },
          }),
        ],
      },
      [
        sendEmail
          ? h(
              'div',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              [
                'The exported file has been sent to your registered',
                " email address. In case you can't spot it, please",
                ' look into the Spam folder',
              ]
            )
          : h('div', {}, [
              option({
                label: 'Download CSV file now',
                selected: isEmail === 0 ? true : false,
                key: 'no-email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 0,
                  }),
              }),
              option({
                label: 'Email file as attachment',
                selected: isEmail === 1 ? true : false,
                key: 'email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 1,
                  }),
              }),
            ]),
      ]
    );
  }

  private async downloadACopyClickHandler() {
    await this.setState({
      isDownloadAlertOpen: true,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }

  private async backClickHandler() {
    await this.setState({
      isDownloadAlertOpen: false,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }

  private async exportClickHandler() {
    const email = this.getState().exportRequestIsEmail;
    const response = await dispatch(
      ClassActions.exportGrades({
        activityType: 'classes',
        activityId: this.getProps().cls._id,
        email: email,
      })
    );
    this.setFileData(response);
    if (email === 1) {
      await this.setState({
        sendEmail: true,
      });
    } else {
      await this.setState({
        isExportDialogOpen: false,
        isDownloadAlertOpen: true,
      });
    }
  }

  private async setFileData(response: IExportGradesSuccessResponse) {
    await this.setState({
      exportDownloadLink: response.url,
      exportFileName: response.filename,
    });
  }

  private lastFocusedElement: Element | null = null;
  private async openExportDialog() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({
      isExportDialogOpen: true,
    });
  }

  private studentView() {
    const state = this.getState();
    if (state.isLoading) {
      return h(
        'div',
        style([
          'fullWidth',
          'flex',
          'alignCenter',
          'justifyCenter',
          {
            marginTop: '4rem',
            height: '4em',
          },
        ]),
        [fullScreenLoader]
      );
    }
    return h(
      'div.attendance-page',
      style(['flex', 'column', 'alignCenter', 'large', pt(classTabHeight())]),
      [
        Icon(
          icons[this.iconName],
          style(
            [this.iconStyle, mt('0.2em')],
            {},
            {
              ariaLabel: 'Attendance Status Icon',
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          )
        ),
        h(
          'div',
          {
            style: {
              textAlign: 'center',
              marginTop: '2rem',
              color: this.textColor,
            },
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': this.message1,
          },
          this.message1
        ),
        h(
          'div',
          {
            style: {
              textAlign: 'center',
              color: this.textColor,
            },
            tabIndex: this.message2 === '.' ? 0 : undefined,
            'aria-label': this.message2 === '.' ? '' : this.message2,
          },
          this.message2 === '.' ? '' : this.message2
        ),
        h(
          'div',
          {
            style: {
              textAlign: 'center',
              marginTop: '1em',
              color: colors.grey,
              padding: '0 1rem',
            },
            tabIndex: this.isAccessible ? 0 : undefined,
            'Aria-label': this.message3,
          },
          this.message3
        ),
        this.why
          ? h(
              'a.why',
              {
                target: '_blank',
                href:
                  'http://help.acadly.com/attendance/my-attendance-wasnt-' +
                  'recorded-correctly-how-do-i-correct-this',
              },
              'Why was I not marked present?'
            )
          : null,
      ]
    );
  }

  private attendanceMessage() {
    const state = this.getState();
    const userData = this.getProps().cls.userData;
    const attendanceTime = state.attendanceTime
      ? dt.format(dt.fromUnix(state.attendanceTime), 'hh:mm a on MMMM DD, YYYY')
      : null;

    const statusAttendance = state.attendance === 0 ? 'dontHaveAttendance' : 'hasAttendance';
    let statusCheckedIn = state.checkedIn ? 'isCheckedIn' : 'isNotCheckedIn';

    let statusStudent = Class.status(this.getProps().cls) === 'open' ? 'futureClass' : state.status;

    if (userData.attendance) {
      statusCheckedIn =
        userData.attendance.status === 'checkedIn' ? 'isCheckedIn' : statusCheckedIn;
    }

    if (statusAttendance && statusCheckedIn && statusStudent) {
      if (userData.attendance && userData.attendance.visibleStatus === 'late') {
        statusStudent = 'late';
      }
      if (userData.attendance && userData.attendance.visibleStatus === 'excused') {
        statusStudent = 'excused';
      }

      const attendanceState = attendanceStates[statusAttendance][statusCheckedIn][statusStudent];

      this.message1 = attendanceState['message1'];
      this.message2 = attendanceState['message2'];

      this.message3 =
        statusAttendance === 'hasAttendance'
          ? `${attendanceState['message3']} ${attendanceTime}`
          : attendanceState['message3'];

      this.iconName = attendanceState['iconName'];
      this.iconStyle = attendanceState['iconStyle'];
      this.textColor = attendanceState['textColor'];
      this.why = attendanceState['why'];
    }
  }
}

interface IAttendanceDialogProps {
  isVisible: boolean;
  classId: string;
  courseId: string;
  students: IAttendanceTabStudent[];
  onClose: () => Promise<void>;
  attendanceUpdateStream$: Subject<void>;
}

interface IAttendanceDialogState {
  isSubmitting: boolean;
  searchField: string;
  attendanceForm: AttendanceForm;
}

class AttendanceDialog extends IComponent<IAttendanceDialogProps, IAttendanceDialogState> {
  private isAccessible: boolean | undefined = false;

  private initialize(props: IAttendanceDialogProps) {
    const attendanceForm = getAttendanceForm(props.students);
    const initialState: IAttendanceDialogState = {
      isSubmitting: false,
      searchField: '',
      attendanceForm,
    };
    this.setState(initialState);
  }

  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    this.initialize(this.getProps());
  }

  public componentWillReceiveProps(newProps: IAttendanceDialogProps) {
    this.initialize(newProps);
  }

  public render() {
    const props = this.getProps();
    const state = this.getState();

    const { sortStudentBy } = getStore().getState().class;
    const students = getStudentListData(
      props.students,
      state.searchField,
      sortStudentBy,
      (s) => s.name
    );

    return Dialog(
      {
        open: props.isVisible,
        title: 'Edit Attendance',
        style: {
          backgroundColor: colors.backgroundColor,
        },
        thinHeader: true,
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => props.onClose(),
        },
        primaryAction: {
          type: 'primary',
          label: state.isSubmitting
            ? Loader({
                maxHeight: '2em',
                maxWidth: '2em',
                width: `15px`,
                height: `15px`,
              })
            : 'UPDATE',
          mobileLabel: state.isSubmitting
            ? Loader({
                maxHeight: '2em',
                maxWidth: '2em',
                width: `10px`,
                height: `10px`,
              })
            : h('i.fa.fa-check', []),
          onclick: () => this.handleSubmit(),
        },
      },
      [
        h('div', style(['fullWidth']), [
          h('div', style(['grey', pad('1rem 1rem')]), 'Students'),
          h('div', style(['fullWidth']), [
            StudentList({
              style: {
                minHeight: 300,
              },
              children:
                students.length === 0
                  ? [
                      h(
                        'div.student-cell',
                        style(
                          [
                            'fullWidth',
                            'borderBox',
                            'flex',
                            pad('0.5rem 1rem'),
                            mt('0.2em'),
                            'alignCenter',
                          ],
                          {
                            minHeight: '60px',
                            cursor: 'pointer',
                          }
                        ),
                        'No Students found.'
                      ),
                    ]
                  : students.map((student) => {
                      const formData = state.attendanceForm[student.userId];
                      return StudentCell({
                        student,
                        status: formData ? formData.status : student.finalStatus,
                        isAccessible: !!this.isAccessible,
                        onClick: () => this.markAttendance(student.userId),
                      });
                    }),
              sortBy: sortStudentBy,
              onSearch: (term) => {
                this.setState({
                  searchField: term,
                });
              },
              onSort: (sortBy) => {
                dispatch(Actions.setSortStudentOrder(sortBy));
              },
            }),
          ]),
        ]),
      ]
    );
  }

  private nextAttendanceStatus(
    currentStatus: IAttendanceTabStudent['finalStatus']
  ): EditedAttendanceStatus {
    const statusMap: {
      [key in IAttendanceTabStudent['finalStatus']]: EditedAttendanceStatus;
    } = {
      present: 'absent',
      absent: 'late',
      late: 'excused',
      excused: 'present',
      noRecord: 'present',
    };
    return statusMap[currentStatus];
  }

  private markAttendance(userId: string) {
    const state = this.getState();
    const props = this.getProps();

    const student = props.students.find((s) => s.userId === userId)!;

    const attendanceStatus = this.nextAttendanceStatus(
      state.attendanceForm[userId] ? state.attendanceForm[userId].status : student.finalStatus
    );

    // notify parent that attendance has been modified
    this.getProps().attendanceUpdateStream$.next();

    this.setState({
      attendanceForm: {
        ...state.attendanceForm,
        [userId]: {
          status: attendanceStatus,
          isEdited: !!student.edited || attendanceStatus !== student.finalStatus,
        },
      },
    });
  }

  private isAttendanceUpdated(attendanceForm: AttendanceForm): boolean {
    const userIds = Object.keys(attendanceForm);
    const initialForm = getAttendanceForm(this.getProps().students);
    for (let i = 0; i < userIds.length; i++) {
      const userId = userIds[i];
      if (initialForm[userId] !== attendanceForm[userId]) {
        return true;
      }
    }
    return false;
  }

  private getUserIdsFromAttendanceForm(attendanceForm: AttendanceForm) {
    const userIds = Object.keys(attendanceForm);

    const attendanceData: {
      [key in EditedAttendanceStatus]: string[];
    } = {
      present: [],
      absent: [],
      late: [],
      excused: [],
    };

    for (let i = 0; i < userIds.length; i++) {
      const userId = userIds[i];
      if (attendanceForm[userId]) {
        attendanceData[attendanceForm[userId].status].push(userId);
      }
    }

    return attendanceData;
  }

  private async handleSubmit() {
    const state = this.getState();
    const isUpdated = this.isAttendanceUpdated(state.attendanceForm);

    if (isUpdated) {
      const { classId, courseId } = this.getProps();

      await dispatch(
        Actions.attendanceSet(
          {
            classId: classId,
            ...this.getUserIdsFromAttendanceForm(state.attendanceForm),
          },
          {
            courseId: courseId,
          }
        )
      );

      await dispatch(Actions.attendanceFetch(classId));
    }

    this.getProps().onClose();
  }
}
