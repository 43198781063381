interface StringToken {
  type: 'string';
  value: string;
}

interface LinkToken {
  type: 'link';
  value: string;
}

type AST = StringToken | LinkToken;

/**
 * A simple link detector. All links starting with a `https://`
 * and a space are detected. If ending space is not found then
 * whole substring starting from `https://` is assumed as a link.
 */
function detect(str: string): AST[] {
  const result: AST[] = [];
  const httpsPos = str.indexOf('https://');

  if (httpsPos < 0) {
    return [{ type: 'string', value: str }];
  }

  const linkStr = str.substr(httpsPos);
  const nextSpacePos = linkStr.indexOf(' ');

  const startStr = str.substr(0, httpsPos);

  if (startStr) {
    result.push({ type: 'string', value: startStr });
  }

  if (nextSpacePos < 0) {
    result.push({ type: 'link', value: linkStr });
    return result;
  }

  result.push({ type: 'link', value: linkStr.substr(0, nextSpacePos) });

  return result.concat(detect(linkStr.substr(nextSpacePos)));
}

const links = { detect };

export default links;
