import { h } from 'core';

export default (props: any, children: any[]) =>
  h(
    'span.ripple.icon-button',
    {
      style: props.style,
      tabIndex: props.tabIndex,
      onclick: props.onclick,
      className: props.className,
    },
    children
  );
