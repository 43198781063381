import { Actions as AppActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import { createAction, Thunk } from 'acadly/createAction';
import {
  exportAllCommentStats,
  exportCommentStats,
  IExportCommentStatsSuccessRequest,
  IExportCommentStatsSuccessResponse,
} from 'acadly/discussion/api';
import { IPusherPayload } from 'acadly/pusher';

import * as datetime from '../datetime';
import { Actions as GetInActions } from '../getin/actions';
import { flatten } from '../utils';
import {
  fetchCourseInfo,
  fetchMyCourses,
  fetchTimeline,
  IAddBookRequest,
  setCourseTimezone,
} from './api';
import * as api from './api';

export type IPusherStudentPurchaseSuccessful = IPusherPayload<{
  status: 'success';
  courseId: string;
}>;

export const Actions = {
  setCoursesLoading: createAction('@course/SET_COURSES_LOADING'),
  fetchMyCoursesSuccess: createAction('@course/FETCH_MY_COURSES_SUCCESS'),
  fetchMyCourses: (): Thunk<void> => async (dispatch) => {
    dispatch(Actions.setCoursesLoading(true));
    const response = await fetchMyCourses();
    if (response.data.archivedCoursesAvailable) {
      await dispatch(Actions.archivedFetch());
    }
    dispatch(Actions.setCoursesLoading(false));
    dispatch(AppActions.setReferralsAllowed(!!response.data.referralsAllowed));
    dispatch(
      AppActions.setNewFeatureUpdates({
        newFeaturesAvailable: response.data.newFeaturesAvailable,
        newFeaturesLastUpdatedAt: response.data.newFeaturesLastUpdatedAt,
        newFeaturesSeenAt: response.data.newFeaturesSeenAt,
      })
    );
    dispatch(Actions.fetchMyCoursesSuccess(response.data));
    dispatch(
      GetInActions.fetchAvatars(
        flatten(response.data.courseData.map((course) => course.team)).map(
          (member) => member.avatar
        )
      )
    );
  },
  joinCourseSuccess: createAction('@course/JOIN_COURSE_SUCCESS'),
  joinCourse:
    (joinCode: string): Thunk<void> =>
    async (dispatch) => {
      const response = await api.joinCourseByJoinCode(joinCode);
      dispatch(Actions.joinCourseSuccess(response.data));
    },
  createCourse:
    (title: string, code: string, isProCourse = false): Thunk<api.ICreateCourseResponse> =>
    async (dispatch) => {
      const response = await api.createCourse(code, title, isProCourse);
      googleAnalytics.courseCreated();
      dispatch(
        Actions.createCourseSuccess({
          ...response.data,
          timestamp: datetime.unix(),
        })
      );
      return response.data;
    },
  createCourseSuccess: createAction('@course/CREATE_SUCCESS'),
  setCurrentCourse: createAction('@course/SET_CURRENT_COURSE'),

  courseInitializeSuccess: createAction('@course/INITIALIZE_SUCCESS'),
  initializeCourse:
    (courseId: string, params: api.ICourseInitializeRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.initializeCourse(params);
      await dispatch(
        Actions.courseInitializeSuccess({
          courseId,
          initialized: response.data.initialized,
        })
      );
    },

  fetchTimelineSuccess: createAction('@course/FETCH_TIMELINE_SUCCESS'),
  fetchTimeline:
    (userId?: string): Thunk<void> =>
    async (dispatch, getState) => {
      const response = await fetchTimeline();
      const currentTime = datetime.unix();
      const state = getState();
      dispatch(
        Actions.fetchTimelineSuccess({
          userId: userId || state.getIn.session!.userId,
          timelineResponse: response.data,
          timeStamp: currentTime,
          joinCode: response.data.joinCode,
          enrolmentType: response.data.enrolmentType,
        })
      );
    },

  setTimezoneSuccess: createAction('@course/SET_TIMEZONE_SUCCESS'),
  setTimezone:
    (timezone: string): Thunk<void> =>
    async (dispatch) => {
      await setCourseTimezone(timezone);
      dispatch(Actions.setTimezoneSuccess(timezone));
    },

  fetchCourseInfoSuccess: createAction('@course/info/FETCH_SUCCESS'),
  fetchCourseInfo: (): Thunk<void> => async (dispatch) => {
    const response = await fetchCourseInfo();
    dispatch(Actions.fetchCourseInfoSuccess(response.data));
  },

  setCourseDesciptionSuccess: createAction('@course/info/SET_DESCRIPTION_SUCCESS'),

  setCourseDesciption:
    (description: string): Thunk<void> =>
    async (dispatch) => {
      await api.setCourseDescription(description);
      dispatch(Actions.setCourseDesciptionSuccess(description));
    },

  addTeamMemberSuccess: createAction('@course/info/ADD_TEAM_MEMBER_SUCCESS'),
  addTeamMember:
    (email: string, role: 'instructor' | 'ta'): Thunk<void> =>
    async (dispatch) => {
      const response = await api.addTeamMember(email, role);
      googleAnalytics.teamEdited('added', role);
      dispatch(GetInActions.fetchAvatars([response.data.avatar]));
      dispatch(Actions.addTeamMemberSuccess(response.data));
    },

  removeTeamMemberSuccess: createAction('@course/info/REMOVE_TEAM_MEMBER_SUCCESS'),
  removeTeamMember:
    (userId: string, role: 'ta' | 'instructor'): Thunk<void> =>
    async (dispatch) => {
      await api.removeTeamMember(userId);
      googleAnalytics.teamEdited('removed', role);
      dispatch(Actions.removeTeamMemberSuccess(userId));
    },

  addCourseBookSuccess: createAction('@course/info/ADD_BOOK_SUCCESS'),
  addCourseBook:
    (book: IAddBookRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.addBook(book);
      dispatch(
        Actions.addCourseBookSuccess({
          title: book.title,
          author: book.author,
          isbn: book.isbn,
          recommended: book.recommended,
          _id: response.data.bookId,
        })
      );
    },

  removeBookSuccess: createAction('@course/info/REMOVE_BOOK_SUCCESS'),
  removeBook:
    (bookId: string): Thunk<void> =>
    async (dispatch) => {
      await api.removeBook(bookId);
      dispatch(Actions.removeBookSuccess(bookId));
    },

  editCourseBookSuccess: createAction('@course/info/EDIT_BOOK_SUCCESS'),
  editCourseBook:
    (book: ICourseBook): Thunk<void> =>
    async (dispatch) => {
      await api.editBook({
        bookId: book._id,
        title: book.title,
        author: book.author,
        isbn: book.isbn,
        recommended: book.recommended,
      });
      dispatch(Actions.editCourseBookSuccess(book));
    },

  publishInfoSuccess: createAction('@course/info/PUBLISH_SUCCESS'),
  publishInfo: (): Thunk<void> => async (dispatch) => {
    await api.publishInfo();
    dispatch(Actions.publishInfoSuccess(undefined));
  },

  setStartDateSuccess: createAction('@course/SET_START_DATE_SUCCESS'),
  /**
   * @param {string} date Start date in YYYY-MM-DD format
   */
  setStartDate:
    (date: string): Thunk<void> =>
    async (dispatch) => {
      const response = await api.setStartDate(date);
      dispatch(Actions.setStartDateSuccess(response.data.startDate));
    },

  setEndDateSuccess: createAction('@course/SET_END_DATE_SUCCESS'),
  /**
   * @param {string} date Start date in YYYY-MM-DD format
   */
  setEndDate:
    (date: string): Thunk<void> =>
    async (dispatch) => {
      const response = await api.setEndDate(date);
      dispatch(Actions.setEndDateSuccess(response.data.endDate));
    },

  publishCourseScheduleSuccess: createAction('@course/SCHEDULE_PUBLISH_SUCCESS'),
  publishCourseSchedule:
    (enrolmentType: ICourseEnrollmentType): Thunk<string> =>
    async (dispatch) => {
      const response = await api.publishSchedule({
        enrolment: enrolmentType,
      });
      await dispatch(Actions.publishCourseScheduleSuccess(response.data));
      return response.data.joinCode;
    },

  goLiveSuccess: createAction('@course/GO_LIVE_SUCCESS'),

  goLive:
    (courseId: string): Thunk<void> =>
    async (dispatch) => {
      const {
        data: { joinCode },
      } = await api.goLive();
      dispatch(Actions.goLiveSuccess({ courseId, joinCode }));
    },

  fetchCourseStudentsAnalyticsSuccess: createAction('@course/analytics/FETCH_STUDENTS_SUCCESS'),
  fetchCourseStudentsAnalytics:
    (studentsEnrolled: boolean): Thunk<void> =>
    async (dispatch) => {
      if (studentsEnrolled) {
        const response = await api.fetchStudentsAnalytics();
        const enrolledStudents = response.data.enrolledStudents || [];
        const removedStudents = response.data.removedStudents || [];
        const pendingStudents = response.data.pendingStudents || [];
        dispatch(
          Actions.fetchCourseStudentsAnalyticsSuccess({
            enrolledStudents,
            removedStudents,
            pendingStudents,
          })
        );
        dispatch(
          GetInActions.fetchAvatars(
            [...enrolledStudents, ...removedStudents].map((s) => s.identifiers.avatar)
          )
        );
      } else {
        dispatch(
          Actions.fetchCourseStudentsAnalyticsSuccess({
            enrolledStudents: [],
            removedStudents: [],
            pendingStudents: [],
          })
        );
      }
    },

  addStudentsSuccess: createAction('@course/ADD_STUDENTS_SUCCESS'),
  addStudents:
    (emailIds: string[], type: 'file' | 'typed'): Thunk<api.IAddStudentsResponse> =>
    async (dispatch) => {
      const response = await api.addStudents(emailIds);
      dispatch(
        Actions.addStudentsSuccess({
          ...response.data,
          currentTimestamp: datetime.toUnix(datetime.now()),
        })
      );
      googleAnalytics.studentsEdited('added', type);
      const avatars = response.data.added.map((s) => s.avatar);
      dispatch(GetInActions.fetchAvatars(avatars));
      return response.data;
    },

  uploadInfoFileSuccess: createAction('@course/info/ADD_INFO_FILE_SUCCESS'),

  removeInfoFileSuccess: createAction('@course/info/REMOVE_INFO_FILE_SUCCESS'),
  removeInfoFile:
    (fileId: string): Thunk<void> =>
    async (dispatch) => {
      await api.infoFileRemove(fileId);
      dispatch(Actions.removeInfoFileSuccess(fileId));
    },

  uploadSyllabusFileSuccess: createAction('@course/syllabus/UPLOAD_SUCCESS'),

  fetchCourseSyllabusSuccess: createAction('@course/syllabus/FETCH_SUCCESS'),
  fetchCourseSyllabus: (): Thunk<void> => async (dispatch) => {
    const response = await api.fetchCourseSyllabus();
    dispatch(Actions.fetchCourseSyllabusSuccess(response.data));
  },

  addTopicSuccess: createAction('@course/syllabus/ADD_TOPIC_SUCCESS'),
  addTopic:
    (createdBy: ICreatedByWithRole, data: api.IAddTopicRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.addTopic(data);
      dispatch(
        Actions.addTopicSuccess({
          topicId: response.data.topicId,
          parentId: data.parentId,
          createdBy,
          createdOn: datetime.unix(),
          details: {
            title: data.title,
            books: data.books,
            links: data.links,
          },
        })
      );
    },

  editTopicSuccess: createAction('@course/syllabus/EDIT_TOPIC_SUCCESS'),
  editTopic:
    (request: api.IEditTopicRequest): Thunk<void> =>
    async (dispatch) => {
      await api.editTopic(request);
      dispatch(Actions.editTopicSuccess(request));
    },

  deleteTopicSuccess: createAction('@course/syllabus/DELETE_TOPIC_SUCCESS'),
  deleteTopic:
    (data: api.IDeleteTopicRequest, afterDeletion?: () => Promise<unknown>): Thunk<void> =>
    async (dispatch) => {
      await api.deleteTopic(data);
      if (afterDeletion) {
        await afterDeletion();
      }
      dispatch(Actions.deleteTopicSuccess(data.topicId));
    },

  reinstateStudentSuccess: createAction('@course/REINSTATE_STUDENT_SUCCESS'),
  reinstateStudent:
    (id: string): Thunk<void> =>
    async (dispatch) => {
      await api.reinstateStudent(id);
      dispatch(Actions.reinstateStudentSuccess(id));
    },

  clearCourseStudentsAnalytics: createAction('@course/analytics/CLEAR_STUDENTS'),

  closeCoursePage: createAction('@course/CLOSE_COURSE_PAGE'),

  removeStudentSuccess: createAction('@course/REMOVE_STUDENT_SUCCESS'),
  removeStudent:
    (userId: string, isPending: boolean): Thunk<void> =>
    async (dispatch) => {
      await api.studentRemove(userId);
      googleAnalytics.studentsEdited('removed', 'typed');
      dispatch(
        Actions.removeStudentSuccess({
          userId,
          isPending,
        })
      );
    },

  courseArchiveSuccess: createAction('@course/ARCHIVE_SUCCESS'),
  courseArchive:
    (courseId: string): Thunk<void> =>
    async (dispatch) => {
      await api.courseArchive();
      dispatch(Actions.courseArchiveSuccess(courseId));
    },

  archivedFetchSuccess: createAction('@course/FETCH_ARCHIVED_SUCCESS'),
  archivedFetch: (): Thunk<void> => async (dispatch) => {
    const response = await api.archivedCoursesFetch();
    dispatch(Actions.archivedFetchSuccess(response.data));
  },

  deleteSuccess: createAction('@course/DELETE_SUCCESS'),
  delete:
    (courseId: string): Thunk<void> =>
    async (dispatch) => {
      await api.remove(courseId);
      googleAnalytics.courseRemoved();
      dispatch(Actions.deleteSuccess(courseId));
    },

  officeHoursSaveSuccess: createAction('@course/info/OFFICE_HOURS_SAVE_SUCCESS'),
  officeHoursSave:
    (data: api.IOfficeHoursSaveRequest): Thunk<void> =>
    async (dispatch) => {
      await api.officeHoursSave(data);
      dispatch(Actions.officeHoursSaveSuccess(data));
    },

  linkAddSuccess: createAction('@course/info/LINK_ADD_SUCCESS'),
  linkAdd:
    (data: api.IAddLinkRequest): Thunk<void> =>
    async (dispatch, getState) => {
      const state = getState();
      const session = state.getIn.session;
      if (!session) return;
      const createdBy: ICreatedBy = {
        userId: session.userId,
        name: session.name,
        avatar: session.avatar,
      };
      const response = await api.addLink(data);
      dispatch(
        Actions.linkAddSuccess({
          ...data,
          linkId: response.data.linkId,
          timestamp: datetime.unix(),
          createdBy,
        })
      );
    },

  linkRemoveSuccess: createAction('@course/info/LINK_REMOVE_SUCCESS'),
  linkRemove:
    (data: api.IRemoveLinkRequest): Thunk<void> =>
    async (dispatch) => {
      await api.removeLink(data);
      dispatch(
        Actions.linkRemoveSuccess({
          linkId: data.linkId,
        })
      );
    },

  exportCommentStats:
    (request: IExportCommentStatsSuccessRequest): Thunk<IExportCommentStatsSuccessResponse> =>
    async () => {
      const response = await exportCommentStats(request);
      return response.data;
    },

  exportAllCommentStats:
    (
      request: Pick<IExportCommentStatsSuccessRequest, 'email'>
    ): Thunk<IExportCommentStatsSuccessResponse> =>
    async () => {
      const response = await exportAllCommentStats(request);
      return response.data;
    },

  studentPurchaseSuccessful: createAction('@course/STUDENT_PURCHASE_SUCCESSFUL'),

  studentPurchaseSuccessfulPusher:
    (data: IPusherStudentPurchaseSuccessful): Thunk<void> =>
    async (dispatch, getState) => {
      const { session } = getState().getIn;
      const { currentCourseId } = getState().courses;
      if (!session || data.courseId !== currentCourseId) return;
      dispatch(Actions.studentPurchaseSuccessful({ courseId: data.courseId }));
    },
};
