import cn from 'classnames';

import { h, HTMLAttrs, IComponent } from 'core';

import { Actions as AppActions } from 'acadly/app/actions';
import { Actions as appActions } from 'acadly/app/actions';
import { AnalyticsHeader } from 'acadly/app/AnalyticsHeader';
import { Actions as ClassActions, IExportGradesSuccessResponse } from 'acadly/class/actions';
import Alert from 'acadly/common/Alert';
import Avatar from 'acadly/common/Avatar';
import ContentView from 'acadly/common/ContentView';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import { fullScreenLoader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import RaisedButton from 'acadly/common/RaisedButton';
import Tabs from 'acadly/common/SlidingTabs';
import StudentList, { getStudentListData } from 'acadly/common/StudentList';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import User from 'acadly/common/User';
import { Actions as CourseActions } from 'acadly/course/actions';
import { resendInvite } from 'acadly/course/api';
import * as datetime from 'acadly/datetime';
import icons from 'acadly/icons';
import { dispatch, getStore } from 'acadly/store';
import {
  colors,
  contextPanelWidthPixels,
  getHeaderHeight,
  mainPanelWidth,
  ml,
  mt,
  pad,
  sidebarWidthPixels,
  style,
} from 'acadly/styles';
import * as u from 'acadly/utils';

import { Actions } from './actions';
import AddStudentsDialog from './AddStudents';
import * as api from './api';
import courseService from './service';

export default (course: ICourse | undefined) => h(CourseAnalytics, { course });

export class CourseAnalytics extends IComponent<
  {
    course: ICourse | undefined;
  },
  {
    activeTab: number;
  }
> {
  public componentWillMount() {
    this.setState({
      activeTab: 0,
    });
    const courseRole = courseService.getRole();
    if (courseRole === 'student') {
      dispatch(appActions.stopTip(false));
      u.unsetTabIndices(document.getElementsByTagName('header')[0]);
    } else {
      dispatch(appActions.startTip(true));
    }
  }

  public componentWillUnmount() {
    u.resetTabIndices();
    /**
     * This clears students list from store.
     * This should logically be in componentWillUnmount
     * of StudentsTab but that is causing issues on switching
     * tabs for reasons I haven't investigated. Keeping
     * it here for now.
     */
    dispatch(Actions.clearCourseStudentsAnalytics(undefined));
  }

  public render() {
    const { course } = this.getProps();
    if (!course || !courseService.getRole()) return fullScreenLoader;
    if (!course.status.courseLive) {
      return h(
        'div',
        {
          tabIndex: 0,
          style: {
            width: '100%',
            marginTop: '3em',
            textAlign: 'center',
            color: colors.lightGrey,
          },
        },
        'Make course LIVE first only then you would be able to add students'
      );
    }
    if (courseService.getRole() === 'student') {
      return ContentView(h(AnalyticsForStudent, {}));
    }
    return h('div.course-analytics', [
      Tabs({
        windowWidth: mainPanelWidth,
        maxWindowWidth: '100%',
        activeTab: this.getState().activeTab,
        noIcons: true,
        tabs: [
          {
            tabIndex: 0,
            title: 'Students',
            alternateText: 'Students Tab',
            navigate: () => this.setState({ activeTab: 0 }),
            view: () =>
              h(StudentsTab, {
                key: 'course-analytics-students-tab',
                course: this.getProps().course,
              }),
          },
          {
            tabIndex: 0,
            title: 'Averages',
            alternateText: 'Averages Tab',
            navigate: () => this.setState({ activeTab: 1 }),
            view: () =>
              h(AveragesTab, {
                key: 'course-analytics-averages-tab',
              }),
          },
        ],
      }),
    ]);
  }
}

export class AnalyticsForStudent extends IComponent<
  {
    student?: IAnalyticsStudent;
    deleteStudent?: (studentId: string) => any;
  },
  {
    loadedData?: api.IStudentAverages;
    isDeleteDialogOpen: boolean;
  }
> {
  private lastFocusedElement: Element | null = null;
  private isAccessible: boolean;
  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    this.setState({
      isDeleteDialogOpen: false,
    }).then(async () => {
      const student = this.getProps().student;
      if (student) {
        const response = await api.individualStudentAnalyticsFetch(student.identifiers.userId);
        this.setState({
          loadedData: response.data.averages,
        });
      } else {
        const response = await api.analyticsSelfFetch();
        this.setState({
          loadedData: response.data.studentAverages,
        });
      }
    });
  }

  public render() {
    const loadedData = this.getState().loadedData;
    if (!loadedData) return h('div', style([mt('6em')]), [fullScreenLoader]);
    return h('div.fs-md', style([pad('0.5em 1em')]), [
      this.user(),
      ...this.details(),
      this.deleteButton(),
      this.deleteDialog(),
    ]);
  }

  private deleteDialog() {
    if (!this.isEditEnabled()) return null;
    const { student, deleteStudent } = this.getProps();
    if (!student || !deleteStudent) return null;
    const { isDeleteDialogOpen } = this.getState();
    return Alert(
      {
        open: isDeleteDialogOpen,
        style: { width: '25em' },
        overlayStyle: { backgroundColor: colors.overlayOrange },
        actions: [
          FlatButton('NO', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices(document.getElementById('student-view'));
              (this.lastFocusedElement as HTMLElement).focus();
              this.setState({
                isDeleteDialogOpen: false,
              });
            },
          }),
          FlatButton('YES', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => deleteStudent(student.identifiers.userId),
          }),
        ],
      },
      [h('div', 'Are you sure you want to delete this student?')]
    );
  }

  private deleteButton() {
    if (this.isEditEnabled()) {
      return RaisedButton('Delete Student', {
        tabIndex: 0,
        ariaLabel: 'Delete Student',
        classNames: ['analytics__section', 'analytics__button', 'fc-red'],
        onclick: () => {
          const course = courseService.getCurrentCourse();

          if (course && course.courseType === 'synced') {
            dispatch(
              AppActions.showError({
                message: 'Enrolments cannot be edited in a synced course',
              })
            );
            return;
          }

          this.lastFocusedElement = document.activeElement;
          u.unsetTabIndices(document.getElementsByTagName('header')[0]);
          this.setState({
            isDeleteDialogOpen: true,
          });
        },
      });
    } else {
      return null;
    }
  }

  private isEditEnabled() {
    const courseId = getStore().getState().courses.currentCourseId;
    if (!courseId) return false;
    const course = getStore().getState().courses.courses[courseId];
    if (!course) return false;
    if (course.isArchived) return false;
    if (courseService.getRole() === 'admin') return true;
    else return false;
  }

  private details() {
    const { loadedData } = this.getState();
    if (!loadedData) return [];

    const row = (label: string, value: string, ...classes: string[]) =>
      h(
        'div',
        {
          className: cn('cell', 'cell-full-width', classes),
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [h('span.fc-dark-blue', label), h('span.fc-light-grey', value)]
      );

    const heading = (label: string) =>
      h(
        'div.analytics__header',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        label
      );

    return [
      row('Attendance Stats', '', 'fs-lg'),
      row('Classes present in', this.getTotalAttendance()),

      heading('Scores'),
      row('Average assignment score', this.getAverageScore('assignments'), 'fs-lg'),
      row('Submitted on time', this.getSubmittedOnTime('assignments')),
      row('Submitted late', this.getSubmittedLate('assignments')),
      row('Ungraded submissions', this.getUngradedSubmissions()),
      row('Graded submissions average', this.getSubmissionAverageScore('assignments')),

      row('Average quiz score', this.getAverageScore('quizzes'), 'fs-lg', 'analytics__section'),
      row('Submitted on time', this.getSubmittedOnTime('quizzes')),
      row('Submitted late', this.getSubmittedLate('quizzes')),
      row('Submission average', this.getSubmissionAverageScore('quizzes')),

      heading('Participation'),
      row('Polls', '', 'fs-lg'),
      row('Submitted on time', this.getSubmittedOnTime('polls')),
      row('Submitted late', this.getSubmittedLate('polls')),

      row('Discussions and Queries', '', 'fs-lg', 'analytics__section'),
      row('Participated in', this.getParticipatedIn()),
      row('Initiated', this.getInitiated()),

      row('In-class participation', '', 'fs-lg', 'analytics__section'),
      row('Instances', this.getInstances()),
      row('Average points', this.getAverageParticipationScore()),
    ];
  }

  private getTotalAttendance() {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    return loadedData.classes.present.toString();
  }

  private getTotalCheckIns() {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    return loadedData.classes.onTime.toString();
  }

  private formatDecimal(n: number) {
    return n.toFixed(2);
  }

  private getAverageScore(key: 'assignments' | 'quizzes') {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    const attained = loadedData[key].score;
    const max = loadedData[key].publishedTotalScore;
    if (max === 0) return '0%';
    return this.formatDecimal((attained * 100) / max) + '%';
  }

  private getSubmissionAverageScore(key: 'assignments' | 'quizzes') {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    const attained = loadedData[key].score;
    const max = loadedData[key].sumMaxScore;
    if (max === 0) return '0%';
    return this.formatDecimal((attained * 100) / max) + '%';
  }

  private getSubmittedOnTime(key: 'assignments' | 'quizzes' | 'polls') {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    const { onTime, numPublished } = loadedData[key];
    return `${onTime} of ${numPublished}`;
  }

  private getSubmittedLate(key: 'assignments' | 'quizzes' | 'polls') {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    const { late, numPublished } = loadedData[key];
    return `${late} of ${numPublished}`;
  }

  private getUngradedSubmissions() {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    const { late, numGraded, onTime } = loadedData.assignments;
    const totalSubmissions = late + onTime;
    const unGraded = totalSubmissions - numGraded;
    return `${unGraded} of ${totalSubmissions}`;
  }

  private getParticipatedIn() {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    return (loadedData.queries.contributedTo + loadedData.discussions.participatedIn).toString();
  }

  private getInstances() {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    return loadedData.classes.participation.instances.toString();
  }

  private getAverageParticipationScore() {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    const instances = loadedData.classes.participation.instances;
    const totalPoints = loadedData.classes.participation.totalPoints;
    if (totalPoints === 0) return '0';
    return this.formatDecimal(totalPoints / instances);
  }

  private getInitiated() {
    const { loadedData } = this.getState();
    if (!loadedData) return '';
    return loadedData.queries.initiated.toString();
  }

  private user() {
    const session = this.getSession();
    let name = session.name;
    let email = session.email;
    let avatar = session.avatar;
    const { student } = this.getProps();
    if (student) {
      name = student.identifiers.name;
      avatar = student.identifiers.avatar;
      email = student.identifiers.emailId;
    }
    return h(
      'div.user.analytics__user',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        Avatar(avatar, name, {
          className: 'user__avatar',
        }),
        h('div.user__details', [
          h('div.user__title', [h('span', name), h('span', 'Active')]),
          h('div', email),
          student
            ? h(
                'div',
                `Joined on: ${datetime.format(
                  datetime.fromUnix(student.joinedOn),
                  'MMMM DD, YYYY'
                )}`
              )
            : null,
        ]),
      ]
    );
  }

  private getSession() {
    return getStore().getState().getIn.session!;
  }
}

interface IStudentsTabState {
  isAddStudentsDialogOpen: boolean;
  isViewingStudent?: IAnalyticsStudent;
  isDeleteDialogOpenForId: null | string;
  reinstateConfirmationDialog: null | {
    userId: string;
    name: string;
  };
  isExportDialogOpen: boolean;
  exportDownloadLink: string;
  exportRequestIsEmail: 0 | 1;
  sendEmail: boolean;
  exportFileName: string;
  isDownloadAlertOpen: boolean;
  isExportOptionsDialogOpen: boolean;
  exportOption: 0 | 1 | 2 | 3 | 4 | 5;
  isResendInviteDialogOpen: boolean;
  isResendInviteConfirmationOpen: boolean;
  sortStudentsBy: StudentSortBy;
  searchStudent: string;
}

export class StudentsTab extends IComponent<{ course: ICourse }, IStudentsTabState> {
  private isAccessible: boolean;
  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    this.init();
  }

  public async init() {
    const initialState: IStudentsTabState = {
      isAddStudentsDialogOpen: false,
      isDeleteDialogOpenForId: null,
      reinstateConfirmationDialog: null,
      isExportDialogOpen: false,
      exportDownloadLink: '',
      exportRequestIsEmail: 0,
      sendEmail: false,
      exportFileName: '',
      isDownloadAlertOpen: false,
      isExportOptionsDialogOpen: false,
      exportOption: 0,
      isResendInviteDialogOpen: false,
      isResendInviteConfirmationOpen: false,
      sortStudentsBy: getStore().getState().app.sortStudentBy,
      searchStudent: '',
    };
    await this.setState(initialState);
    const course = courseService.getCurrentCourse();
    if (course && course.status.studentsEnrolled) {
      dispatch(Actions.fetchCourseStudentsAnalytics(!!course.status.studentsEnrolled));
    } else {
      dispatch(
        Actions.fetchCourseStudentsAnalyticsSuccess({
          enrolledStudents: [],
          pendingStudents: [],
          removedStudents: [],
        })
      );
    }
  }
  private lastFocusedElement: Element | null = null;

  public componentWillUnmount() {
    if (this.getState().isViewingStudent) {
      dispatch(AppActions.popAnalyticsBackStack(undefined));
    }
  }

  public componentWillUpdate(_: never, nextState: IStudentsTabState) {
    const state = this.getState();
    if (state && nextState && !state.isViewingStudent && nextState.isViewingStudent) {
      setTimeout(() =>
        dispatch(
          AppActions.pushToAnalyticsBackStack(() =>
            this.setState({
              isViewingStudent: undefined,
            })
          )
        )
      );
    } else if (state && nextState && state.isViewingStudent && !nextState.isViewingStudent) {
      this.resetIndices();
    }
  }

  public render() {
    const students = this.getStudents();
    const course = this.getProps().course;
    const sortStudentBy = this.getState().sortStudentsBy;
    return h('div.analytics__tab-content', [
      h(
        'div.analytics__content',
        !students
          ? [h('div', style([mt('6em')]), [fullScreenLoader])]
          : [
              RaisedButton(
                [
                  Icon(icons.export2, { className: 'analytics__export__icon' }),
                  h('span.analytics__export__label', 'Export course data as CSV'),
                ],
                {
                  tabIndex: 0,
                  id: 'export-course-button',
                  ariaLabel: 'Export course data as CSV',
                  onclick: () => this.toggleExportOptionsDialog(),
                  classNames: ['analytics__export'],
                }
              ),
              h(
                'div.cell.cell--full-width.fs-lg',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                },
                [
                  h('span', 'Enrolment Method'),
                  h(
                    'span.cell__value',
                    course.courseType === 'synced'
                      ? 'Synced'
                      : course.enrolmentType === 'enrolmentCode'
                      ? 'By Join Code'
                      : 'By Email Invitation'
                  ),
                ]
              ),
              course.courseType === 'synced'
                ? h('div.fs-sm.fc-orange', [
                    h('i.fa.fa-exclamation-triangle'),
                    'Student added to the synced course will show up here.',
                  ])
                : null,
              course.enrolmentType === 'enrolmentCode'
                ? h(
                    'div.cell.cell--full-width.fs-lg',
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                    },
                    [h('span', 'Join Code'), h('span.fc-green', course.joinCode)]
                  )
                : null,
              ...this.enrolledStudentsCounts(),
              h(
                'div.analytics__header#students-list',
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                },
                'Students'
              ),
              this.addStudentsButton(),
              // Add student button tip
              this.isEditEnabled()
                ? TipOverlayWrapper({
                    targetElement: 'add-students-button',
                    tip: {
                      tipPosition: 'bottom',
                      tipText:
                        'By using this button, you can add students to your' +
                        ' course with their email addresses. Students will be' +
                        ' notified via email to join this course on Acadly',
                    },
                    tipKey: 'courseAnalyticsAddStudents',
                    isNextAvailable: true,
                  })
                : null,

              students.enrolledStudents.length > 0
                ? h(
                    'div',
                    {
                      tabIndex: 0,
                      'aria-label': `${students.enrolledStudents.length} enrolled
                            ${students.enrolledStudents.length === 1 ? 'student' : 'students'}`,
                    },
                    [
                      this.renderEnrolledStudentsHeader(students),
                      StudentList({
                        sortBy: sortStudentBy,
                        children: this.renderEnrolledStudentsList(students),
                        onSort: (sortBy) => {
                          this.setState({
                            sortStudentsBy: sortBy,
                          });
                        },
                        onSearch: (term) => {
                          this.setState({
                            searchStudent: term,
                          });
                        },
                      }),
                    ]
                  )
                : null,

              students.pendingStudents.length > 0
                ? h(
                    'div',
                    {
                      tabIndex: 0,
                      'aria-label': `${students.pendingStudents.length}
                            pending student${students.pendingStudents.length === 1 ? '' : 's'}`,
                    },
                    [
                      this.renderPendingStudentsHeader(students),
                      students.pendingStudents.length > 0 ? this.resendInviteButton() : null,
                      h('div.analytics__section', this.renderPendingStudentsList(students)),
                    ]
                  )
                : null,
              this.resendInviteDialog(),
              this.resendInviteSuccessAlert(students),
              students.removedStudents.length > 0
                ? h(
                    'div',
                    {
                      tabIndex: 0,
                      'aria-label': `${students.removedStudents.length}
                            removed student${students.removedStudents.length === 1 ? '' : 's'}`,
                    },
                    [
                      this.renderRemovedStudentsHeader(students),
                      ...this.renderRemovedStudents(students),
                    ]
                  )
                : null,
              this.exportDialog(),
              this.exportOptionsDialog(),
              this.downloadAlert(),
              this.deleteStudentDialog(),
              this.reinstateConfirmationDialog(),
              this.studentScreen(),
              // Export button Tip
              TipOverlayWrapper({
                targetElement: 'export-course-button',
                tip: {
                  tipPosition: 'bottom',
                  tipText:
                    'To export the consolidated scores of various activities' +
                    ' that were conducted throughout the course in a CSV' +
                    ' format, use this button',
                },
                tipKey: 'courseAnalyticsExport',
                isNextAvailable: false,
              }),
              courseService.getRole() === 'admin'
                ? AddStudentsDialog({
                    open: this.getState().isAddStudentsDialogOpen,
                    course: this.getProps().course,
                    close: () => {
                      this.setState({
                        isAddStudentsDialogOpen: false,
                      });
                    },
                  })
                : null,
            ]
      ),
    ]);
  }

  private addStudentsButton() {
    return this.isEditEnabled()
      ? RaisedButton(
          [h('div.analytics__button', [Icon(icons.plus), h('span', ' Add student(s)')])],
          {
            id: 'add-students-button',
            ariaLabel: 'Add student',
            classNames: ['fc-blue', 'raised-button--sm'],
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.openAddStudentsDialog(),
          }
        )
      : null;
  }

  private renderPendingStudentsHeader(students: IAnalyticsCourseStudents) {
    return students.pendingStudents.length > 0
      ? this.subHeader(
          `${students.pendingStudents.length} pending student${
            students.pendingStudents.length === 1 ? '' : 's'
          }`
        )
      : null;
  }

  private resendInviteButton() {
    const course = courseService.getCurrentCourse();

    if (!course || course.isArchived || courseService.getRole() !== 'admin') return null;

    return RaisedButton('Remind', {
      id: 'resend-invite-button',
      ariaLabel: 'Remind',
      classNames: ['fc-blue', 'raised-button--sm'],
      tabIndex: this.isAccessible ? 0 : undefined,
      onclick: async () => {
        this.unsetIndices();
        this.setState({
          isResendInviteDialogOpen: true,
        });
      },
    });
  }

  private resendInviteDialog() {
    const isOpen = this.getState().isResendInviteDialogOpen;
    return Alert(
      {
        title: 'Sending a reminder email',
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGreen,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('NO', {
            type: 'secondary',
            onclick: async () => {
              this.resetIndices();
              await this.setState({
                isResendInviteDialogOpen: false,
              });
            },
          }),
          FlatButton('YES', {
            onclick: () => {
              this.setState({
                isResendInviteDialogOpen: false,
              });
              this.reinviteStudents().then(() =>
                this.setState({
                  isResendInviteConfirmationOpen: true,
                })
              );
            },
          }),
        ],
      },
      [
        h(
          'div',
          {},
          `Are you sure you want to send a reminder email to all the` +
            ` students who haven't signed up for the course yet? `
        ),
      ]
    );
  }

  private resendInviteSuccessAlert(students: IAnalyticsCourseStudents) {
    const isOpen = this.getState().isResendInviteConfirmationOpen;
    const numPending = students.pendingStudents.length;
    const isAre = students.pendingStudents.length === 1 ? 'is' : 'are';
    const studentPlural = students.pendingStudents.length > 1 ? 'students' : 'student';
    return Alert(
      {
        title: 'Reminder Emails Sent',
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGreen,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('Okay', {
            type: 'primary',
            onclick: async () => {
              this.resetIndices();
              await this.setState({
                isResendInviteConfirmationOpen: false,
              });
            },
          }),
        ],
      },
      [
        h(
          'div',
          {},
          `A reminder email has been sent to ${numPending} ` +
            `${studentPlural} who ${isAre} yet to sign up for Acadly`
        ),
      ]
    );
  }

  private async reinviteStudents() {
    await resendInvite();
  }

  private subHeader(label: string) {
    return h(
      'div.analytics__sub-header',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      label
    );
  }

  private renderRemovedStudentsHeader(students: IAnalyticsCourseStudents) {
    return students.removedStudents.length > 0
      ? this.subHeader(
          `${students.removedStudents.length} removed student${
            students.removedStudents.length === 1 ? '' : 's'
          }`
        )
      : null;
  }

  private renderEnrolledStudentsList(students: IAnalyticsCourseStudents) {
    const { sortStudentsBy, searchStudent } = this.getState();

    const list = getStudentListData(
      students.enrolledStudents,
      searchStudent,
      sortStudentsBy,
      (student) => student.identifiers.name
    );

    return list.map((s) =>
      User(
        {
          avatar: {
            url: s.identifiers.avatar,
            creator: s.identifiers.name,
          },
          title: s.identifiers.name,
          titleClassNames: ['fc-green'],
          subtitle: s.lastActive
            ? `Last active: ${datetime.distanceInWordsToNow(datetime.fromUnix(s.lastActive))} ago`
            : 'Yet to access this course',
          subtitleClassNames: ['fc-light-grey'],
        },
        {
          key: s.identifiers.userId,
          className: 'student',
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => this.loadStudent(s),
        }
      )
    );
  }

  private renderPendingStudentsList(students: IAnalyticsCourseStudents) {
    return students.pendingStudents.map((s) =>
      User(
        {
          avatar: {
            url: 'default',
            creator: 'default',
          },
          title: s.emailId,
          titleClassNames: ['fc-green'],
          subtitle: 'Yet to sign up',
          subtitleClassNames: ['fc-red'],
          action: FlatButton('Delete', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.unsetIndices();
              this.setState({
                isDeleteDialogOpenForId: s._id,
              });
            },
          }),
        },
        {
          'aria-label': `Student with email id: ${s.emailId} is yet to sign up`,
          className: 'student',
          tabIndex: this.isAccessible ? 0 : undefined,
        }
      )
    );
  }

  private renderRemovedStudents(students: IAnalyticsCourseStudents) {
    const { sortStudentsBy, searchStudent } = this.getState();

    const list = getStudentListData(
      students.removedStudents,
      searchStudent,
      sortStudentsBy,
      (student) => student.identifiers.name
    );

    return list.map((s) =>
      User(
        {
          avatar: {
            url: s.identifiers.avatar,
            creator: s.identifiers.name,
          },
          title: s.identifiers.name,
          titleClassNames: ['fc-green'],
          action: FlatButton('Reinstate', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: async () => {
              const course = courseService.getCurrentCourse();

              if (course && course.courseType === 'synced') {
                dispatch(
                  AppActions.showError({
                    message: 'Enrolments cannot be edited in a synced course',
                  })
                );
                return;
              }

              this.unsetIndices();
              await this.setState({
                reinstateConfirmationDialog: {
                  userId: s.identifiers.userId,
                  name: s.identifiers.name,
                },
              });
            },
          }),
        },
        {
          className: 'student',
          tabIndex: this.isAccessible ? 0 : undefined,
        }
      )
    );
  }

  private reinstateConfirmationDialog() {
    const reinstateConfirmationDialog = this.getState().reinstateConfirmationDialog;
    let userId: string, name;
    if (reinstateConfirmationDialog) {
      userId = reinstateConfirmationDialog.userId || '';
      name = reinstateConfirmationDialog.name || '';
    }
    return Alert(
      {
        open: reinstateConfirmationDialog !== null,
        overlayStyle: {
          backgroundColor: colors.overlayGreen,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('NO', {
            type: 'secondary',
            tabIndex: 0,
            onclick: async () => {
              this.resetIndices();
              await this.setState({
                reinstateConfirmationDialog: null,
              });
            },
          }),
          FlatButton('YES', {
            tabIndex: 0,
            onclick: () => {
              this.resetIndices('students-list');
              this.setState({
                reinstateConfirmationDialog: null,
              });
              this.reinstateStudent(userId);
            },
          }),
        ],
      },
      [h('div', {}, `Are you sure you want to reinstate ${name} ? `)]
    );
  }

  private renderEnrolledStudentsHeader(students: IAnalyticsCourseStudents) {
    return students.enrolledStudents.length > 0
      ? this.subHeader(
          `${students.enrolledStudents.length} enrolled ${
            students.enrolledStudents.length === 1 ? 'student' : 'students'
          }`
        )
      : null;
  }

  private deleteStudentDialog() {
    const studentId = this.getState().isDeleteDialogOpenForId;
    return Alert(
      {
        open: !!studentId,
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('NO', {
            type: 'secondary',
            onclick: () => {
              this.resetIndices();
              this.setState({
                isDeleteDialogOpenForId: null,
              });
            },
          }),
          FlatButton('YES', {
            onclick: () => {
              this.resetIndices('students-list');
              this.deleteStudent(studentId!, true);
            },
          }),
        ],
      },
      ['Are you sure you want to delete this student?']
    );
  }

  private studentScreen() {
    const { isViewingStudent } = this.getState();
    const header = AnalyticsHeader({
      label: 'Student Analytics',
    });

    const headerHeight = getHeaderHeight();
    const isNarrowScreen = getStore().getState().app.narrowScreen;
    const width = isNarrowScreen
      ? '100%'
      : `calc(100% - ${sidebarWidthPixels}px - ${contextPanelWidthPixels}px)`;
    const left = isNarrowScreen ? '0' : `${sidebarWidthPixels}px`;

    const body = isViewingStudent
      ? h(AnalyticsForStudent, {
          student: isViewingStudent,
          deleteStudent: () => this.deleteStudent(isViewingStudent.identifiers.userId),
        })
      : null;
    if (getStore().getState().app.isMobile) {
      return Dialog(
        {
          open: isViewingStudent !== undefined,
          title: 'Student Analytics',
          bodyStyle: {
            backgroundColor: colors.backgroundColor,
          },
          secondaryAction: {
            label: 'BACK',
            mobileLabel: h('i.fa.fa-arrow-left'),
            onclick: () => {
              this.setState({
                isViewingStudent: undefined,
              });
            },
          },
        },
        [body]
      );
    } else {
      return h(
        'div.student-view#student-view',
        style([
          {
            position: 'fixed',
            top: 0,

            left: left,
            zIndex: 3,
            width: width,
            boxSizing: 'border-box',

            /**
             * We can't use calc to set height to 100% - headerHeight
             * here because in safari 11+, dynamically setting something
             * to calc doesn't work (it's a safari bug).
             * For now, I'm using 100% height (of body),
             * marginTop of headerHeight
             * so that it doesn't render over header and
             * added a padding bottom to compensate for the
             * header height. Scrollbar bottom will go off screen
             * but we can live with it for now.
             */
            marginTop: headerHeight,
            paddingBottom: isViewingStudent ? headerHeight : undefined,
            height: isViewingStudent ? '100%' : '0%',
            transition: '0.2s height ease-in-out',
          },
          'flex',
          'column',
        ]),
        isViewingStudent
          ? [
              header,
              ContentView(
                body,
                style([
                  {
                    flex: '1',
                    overflow: 'auto',
                    backgroundColor: colors.backgroundColor,
                  },
                ]).style
              ),
            ]
          : []
      );
    }
  }

  private async deleteStudent(userId: string, isPending?: boolean) {
    this.lastFocusedElement = document.getElementById('students-list');
    await dispatch(Actions.removeStudent(userId, isPending || false));
    await this.setState({
      isViewingStudent: undefined,
      isDeleteDialogOpenForId: null,
    });
    setTimeout(() => {
      this.resetIndices(false);
    }, 0);
  }
  private async loadStudent(student: IAnalyticsStudent) {
    this.unsetIndices(true, 'context-panel-drawer');
    await this.setState({
      isViewingStudent: student,
    });
  }

  private async reinstateStudent(userId: string) {
    await dispatch(Actions.reinstateStudent(userId));
  }

  private async openAddStudentsDialog() {
    this.unsetIndices();
    await this.setState({
      isAddStudentsDialogOpen: true,
    });
  }

  private downloadAlert() {
    const isOpen = this.getState().isDownloadAlertOpen;
    const fileName = this.getState().exportFileName;
    const downloadLink = this.getState().exportDownloadLink;
    const styles = {
      display: 'flex',
      width: '20em',
      borderRadius: '4px',
      backgroundColor: 'white',
      alignItems: 'center',
      border: `1px solid ${colors.lightestGrey}`,
      maxWidth: '100%',
      lineHeight: '1.5em',
      boxSizing: 'border-box',
      paddingRight: '0.5rem',
    };

    return Alert(
      {
        title: 'Success',
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('Done', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.resetIndices();
              this.backClickHandler();
            },
          }),
        ],
      },
      [
        h('div', style([pad('0.5rem 0.5rem 0.5rem 0rem')]), 'File Generated'),
        h(
          'div.acadly-attachment',
          {
            style: styles,
          },
          [
            h(
              'div.attachment-icon',
              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '0.5rem',
                  // fontSize: "0.8em",
                  width: '2.5rem',
                  color: 'white',
                  height: '2.5rem',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  backgroundColor: colors.green,
                },
              },
              'CSV'
            ),
            h(
              'div',
              style([
                'thin',
                {
                  flex: 1,
                  color: colors.black,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: '0.5em',
                },
              ]),
              [fileName]
            ),
            h(
              'a',
              {
                style: {
                  color: colors.green,
                },
                target: '_blank',
                href: downloadLink || undefined,
              },
              [Icon(icons.download, style(['blue', 'large', 'pointer', pad('0.5rem')]))]
            ),
          ]
        ),
      ]
    );
  }

  private exportOptionsDialog() {
    const isOpen = this.getState().isExportOptionsDialogOpen;
    const exportOption = this.getState().exportOption;
    const option = (opts: { selected: boolean; label: string; key: string; onclick: () => any }) =>
      h(
        'div',
        style(
          ['flex', 'alignCenter'],
          {},
          {
            onclick: opts.onclick,
            keys: opts.key,
            tabIndex: 0,
          }
        ),
        [
          RadioButton({
            selected: opts.selected,
            color: colors.teal,
          }),
          h(
            'div',
            style([
              pad('1rem 1rem'),
              ml('0.5rem'),
              {
                flex: '1',
                borderBottom: '1px solid #efefef',
              },
            ]),
            opts.label
          ),
        ]
      );
    return Alert(
      {
        title: 'Select data to be exported',
        titleStyle: {
          textAlign: 'center',
        },
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton('Cancel', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => {
              this.resetIndices();
              this.backClickHandler();
            },
          }),
          FlatButton('Next', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.unsetIndices(true);
              this.openExportDialog();
            },
          }),
        ],
      },
      [
        h('div', {}, [
          option({
            label: 'Attendance Data',
            selected: exportOption === 0 ? true : false,
            key: 'attendance',
            onclick: () =>
              this.setState({
                exportOption: 0,
              }),
          }),
          option({
            label: "Quizzes' Score",
            selected: exportOption === 1 ? true : false,
            key: 'quizzes',
            onclick: () =>
              this.setState({
                exportOption: 1,
              }),
          }),
          option({
            label: "Polls' Data",
            selected: exportOption === 2 ? true : false,
            key: 'polls',
            onclick: () =>
              this.setState({
                exportOption: 2,
              }),
          }),
          option({
            label: "Assignments' Score",
            selected: exportOption === 3 ? true : false,
            key: 'assignments',
            onclick: () =>
              this.setState({
                exportOption: 3,
              }),
          }),
          option({
            label: 'Participation Score',
            selected: exportOption === 4 ? true : false,
            key: 'participation',
            onclick: () =>
              this.setState({
                exportOption: 4,
              }),
          }),
          option({
            label: "Consolidated Posts' Stats",
            selected: exportOption === 5,
            key: 'consolidated-stats',
            onclick: () =>
              this.setState({
                exportOption: 5,
              }),
          }),
        ]),
      ]
    );
  }

  private resetIndices(_id?: string | boolean) {
    u.resetTabIndices(document.getElementById('analytics-panel'));
    u.resetTabIndices(document.getElementById('context-panel-drawer'));
    let itemToFocus;
    if (_id && typeof _id === 'string') {
      itemToFocus = document.getElementById(_id);
    } else {
      itemToFocus = this.lastFocusedElement;
    }
    if (itemToFocus) {
      (itemToFocus as HTMLElement).focus();
    }
  }

  private unsetIndices(nofocusedElem?: boolean, _id?: string) {
    if (!nofocusedElem) {
      this.lastFocusedElement = document.activeElement;
    }
    u.unsetTabIndices(document.getElementsByTagName('header')[0]);
    if (_id) {
      u.resetTabIndices(document.getElementById(_id));
    }
  }
  private exportDialog() {
    const state = this.getState();
    if (!state) {
      return null;
    }
    const isOpen = state.isExportDialogOpen;
    const isEmail = state.exportRequestIsEmail;
    const sendEmail = state.sendEmail;
    const option = (opts: { selected: boolean; label: string; key: string; onclick: () => any }) =>
      h(
        'div',
        style(
          ['flex', 'alignCenter'],
          {},
          {
            onclick: opts.onclick,
            key: opts.key,
            tabIndex: 0,
          }
        ),
        [
          RadioButton({
            selected: opts.selected,
            color: colors.teal,
          }),
          h(
            'div',
            style([
              pad('1rem 1rem'),
              ml('0.5rem'),
              {
                flex: '1',
                borderBottom: '1px solid #efefef',
              },
            ]),
            opts.label
          ),
        ]
      );
    return Alert(
      {
        title: !sendEmail ? 'Export As' : 'Success',
        titleStyle: {
          textAlign: 'center',
        },
        open: isOpen,
        overlayStyle: {
          backgroundColor: colors.overlayGrey,
        },
        style: {
          width: '20em',
        },
        actions: [
          FlatButton(!sendEmail ? 'Cancel' : 'Download a copy', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: !sendEmail
              ? () => {
                  this.backClickHandler();
                  this.resetIndices();
                }
              : () => {
                  this.downloadACopyClickHandler();
                  this.resetIndices();
                },
          }),
          FlatButton('Done', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: !sendEmail
              ? () => this.exportClickHandler()
              : () => {
                  this.backClickHandler();
                  this.resetIndices();
                },
          }),
        ],
      },
      [
        sendEmail
          ? h(
              'div',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              'The exported file has been sent to your registered' +
                " email address. In case you can't spot it, please" +
                ' look into the Spam folder'
            )
          : h('div', {}, [
              option({
                label: 'CSV download',
                selected: isEmail === 0 ? true : false,
                key: 'no-email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 0,
                  }),
              }),
              option({
                label: 'An Email Attachment',
                selected: isEmail === 1 ? true : false,
                key: 'email',
                onclick: () =>
                  this.setState({
                    exportRequestIsEmail: 1,
                  }),
              }),
            ]),
      ]
    );
  }

  private async downloadACopyClickHandler() {
    await this.setState({
      isDownloadAlertOpen: true,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }
  private async backClickHandler() {
    await this.setState({
      exportOption: 0,
      isExportOptionsDialogOpen: false,
      isDownloadAlertOpen: false,
      isExportDialogOpen: false,
      exportRequestIsEmail: 0,
      sendEmail: false,
    });
  }

  private async exportClickHandler() {
    const exportOption = this.mapExportOption(this.getState().exportOption);
    const email = this.getState().exportRequestIsEmail;
    const response =
      exportOption !== 'consolidated-stats'
        ? await dispatch(
            ClassActions.exportAllGrades({
              activityType: exportOption,
              email: email,
            })
          )
        : await dispatch(
            CourseActions.exportAllCommentStats({
              email: email,
            })
          );
    this.setFileData(response);
    if (email === 1) {
      await this.setState({
        sendEmail: true,
      });
    } else {
      await this.setState({
        isExportDialogOpen: false,
        isDownloadAlertOpen: true,
      });
    }
  }

  private async setFileData(response: IExportGradesSuccessResponse) {
    await this.setState({
      exportDownloadLink: response.url,
      exportFileName: response.filename,
    });
  }

  private mapExportOption(option: 0 | 1 | 2 | 3 | 4 | 5) {
    return {
      0: 'classes' as const,
      1: 'quizzes' as const,
      2: 'polls' as const,
      3: 'assignments' as const,
      4: 'participation' as const,
      5: 'consolidated-stats' as const,
    }[option];
  }

  private async openExportDialog() {
    await this.setState({
      isExportOptionsDialogOpen: false,
      isExportDialogOpen: true,
    });
  }

  private async toggleExportOptionsDialog() {
    const isOpen = this.getState().isExportOptionsDialogOpen;
    this.unsetIndices();
    await this.setState({
      isExportOptionsDialogOpen: !isOpen,
    });
  }

  private enrolledStudentsCounts() {
    const students = this.getStudents();
    if (!students) return [];

    return [
      h(
        'div.cell.cell--full-width.fs-lg',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          h('div', 'Enrolled Students'),
          h(
            'div.cell__value',
            (
              students.enrolledStudents.length +
              students.pendingStudents.length +
              students.removedStudents.length
            ).toString()
          ),
        ]
      ),
      h(
        'div.cell.cell--full-width.analytics__sub-cell',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [h('div', 'Signed up'), h('div.cell__value', students.enrolledStudents.length.toString())]
      ),
      h('div.cell.cell--full-width.analytics__sub-cell', [
        h('div', 'Sign up awaited'),
        h('div.cell__value', students.pendingStudents.length.toString()),
      ]),
      h('div.cell.cell--full-width.analytics__sub-cell', [
        h('div', 'Removed students'),
        h('div.cell__value', students.removedStudents.length.toString()),
      ]),
    ];
  }

  private getStudents() {
    const students = getStore().getState().courses.analytics.students;
    return students;
  }

  private isEditEnabled() {
    const course = courseService.getCurrentCourse();
    if (!course || course.isArchived || course.courseType === 'synced') return false;
    return courseService.getRole() === 'admin';
  }
}

export class AveragesTab extends IComponent<
  HTMLAttrs,
  {
    loadedAverages?: api.IAnalyticsAveragesFetchData;
  }
> {
  private isAccessible: boolean;

  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;

    this.setState({}).then(async () => {
      const course = courseService.getCurrentCourse();
      if (course && !course.status.studentsEnrolled) {
        await this.setState({
          loadedAverages: {
            attendance: {
              recordedClasses: 0,
              checkIns: 0,
              lateCheckIns: 0,
              presents: 0,
            },
            scores: {
              overall: {
                max: 0,
                attained: 0,
                onTimeInstances: 0,
                lateInstances: 0,
              },
              quizzes: {
                max: 0,
                attained: 0,
                onTimeInstances: 0,
                lateInstances: 0,
              },
              assignments: {
                max: 0,
                attained: 0,
                onTimeInstances: 0,
                lateInstances: 0,
              },
              exams: {
                max: 0,
                attained: 0,
                instances: 0,
              },
            },
          },
        });
      } else {
        const response = await api.analyticsAveragesFetch();
        await this.setState({
          loadedAverages: response.data.averages,
        });
      }
    });
  }

  public render() {
    const { loadedAverages } = this.getState();

    const row = (label: string, value: string, ...classes: string[]) =>
      h(
        u.getHTMLTagSelector('div', ['cell', 'cell--full-width', ...(classes || [])]),
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [h('span.fc-dark-blue', label), h('span.fc-light-grey', value)]
      );

    return ContentView(
      h('div.analytics__tab-content', [
        loadedAverages
          ? h('div.analytics__content', [
              row('Average attendance per class', this.getAverageAttendance(), 'fs-lg'),
              row('Average overall score', this.getAverageTotalScore(), 'fs-lg'),
              row(
                'Average assignment score',
                this.getAverageAssignmentScore(),
                'analytics__sub-cell'
              ),
              row('Average quiz score', this.getAverageQuizScore(), 'analytics__sub-cell'),
            ])
          : h('div', style(['fullWidth', mt('10%')]), [fullScreenLoader]),
      ])
    );
  }

  private getAverageAttendance() {
    const { loadedAverages } = this.getState();
    if (!loadedAverages) return '';
    const totalClasses = loadedAverages.attendance.recordedClasses;
    if (totalClasses === 0) return '0';
    return (loadedAverages.attendance.presents / totalClasses).toPrecision(2).toString();
  }

  private getAverageTotalScore() {
    const { loadedAverages } = this.getState();
    if (!loadedAverages) return '';
    const maxScore = loadedAverages.scores.overall.max;
    if (maxScore === 0) return '0%';
    return (
      ((loadedAverages.scores.overall.attained * 100) / maxScore).toPrecision(4).toString() + '%'
    );
  }

  private getAverageAssignmentScore() {
    const { loadedAverages } = this.getState();
    if (!loadedAverages) return '';
    const maxScore = loadedAverages.scores.assignments.max;
    if (maxScore === 0) return '0%';
    return (
      ((loadedAverages.scores.assignments.attained * 100) / maxScore).toPrecision(4).toString() +
      '%'
    );
  }

  private getAverageQuizScore() {
    const { loadedAverages } = this.getState();
    if (!loadedAverages) return '';
    const maxScore = loadedAverages.scores.quizzes.max;
    if (maxScore === 0) return '0%';
    return (
      ((loadedAverages.scores.quizzes.attained * 100) / maxScore).toPrecision(4).toString() + '%'
    );
  }
}
