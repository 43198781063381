import { Actions as AppActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import appService from 'acadly/app/service';
import { Actions as ClassActions } from 'acadly/class/actions';
import classService from 'acadly/class/service';
import { Actions as CourseActions } from 'acadly/course/actions';
import { getCourseRole } from 'acadly/course/functions';
import { IUpdateActivityBasePayload } from 'acadly/course/ICourseActionMap';
import courseService from 'acadly/course/service';
import { createAction, Thunk } from 'acadly/createAction';
import * as dt from 'acadly/datetime';
import { Actions as DiscussionActions } from 'acadly/discussion/actions';
import { Actions as GetInActions } from 'acadly/getin/actions';
import { Actions as PollActions } from 'acadly/poll/actions';
import { IPusherMessageBase, IPusherPayload } from 'acadly/pusher';
import { Actions as QuizActions } from 'acadly/quiz/actions';
import { Actions as ResourceActions } from 'acadly/resource/actions';
import { getActiveRoute, Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';

import * as api from './api';

export type IClassActionMap = {
  '@class/SET_INCHARGE_SUCCESS': ISetClassInchargeResponse;
  '@class/START': IStartClassPayload;
  '@class/FETCH_ACTIVITIES_SUCCESS': IFetchActivitiesSuccessPayload;
  '@class/CLOSE_CLASS_SCREEN': undefined;
  '@class/MOVE_ACTIVITY_SUCCESS': IMoveActivitySuccessPayload;
  '@class/CHECK_IN_SUCCESS': ICheckInSuccessPayload;
  '@class/INCREMENT_SEEN_ACTIVITIES': IIncrementSeenActivitiesPayload;
  '@class/ADD_WEEKLY_CLASS_SUCCESS': IAddWeeklyClassSuccessPayload;
  '@class/REMOVE_WEEKLY_CLASS_SUCCESS': IRemoveWeeklyClassSuccessPayload;
  '@class/DELETE_SUCCESS': IDeleteSuccessPayload;
  '@class/CANCEL_SUCCESS': ICancelSuccessPayload;
  '@class/pusher/ADD': IPusherAddPayload;
  '@class/TITLE_EDIT_SUCCESS': IClassTitleEDitSuccessPayload;
  '@class/VENUE_EDIT_SUCCESS': IVenueEditSuccessPayload;
  '@class/AGENDA_EDIT_SUCCESS': IAgendaEditSuccessPayload;
  // "@class/ASSISTANTS_EDIT_SUCCESS": IEditClassTeamSuccessPayload;
  '@class/TOPICS_EDIT_SUCCESS': ITopicsEditSuccessPayload;
  '@class/ATTENDANCE_FETCH_SUCCESS': IAttendanceFetchSuccessPayload;
  '@class/ATTENDANCE_CLEAR': undefined;
  '@class/pusher/CHECK_IN': IPusherCheckInPayload;
  '@class/pusher/ACTIVITY_UPDATED': IUpdateActivityBasePayload;
  '@class/ATTENDANCE_SET_SUCCESS': IAttendanceSetSuccessPayload;
  '@class/ATTENDANCE_STARTED': IAttendanceStartedPayload;
  '@class/ATTENDANCE_STOPPED': IAttendanceIdentifiers;
  '@class/ATTENDANCE_DIALOG_DISMISS': IAttendanceIdentifiers;
  '@class/attendance/ATTENDEE_AVAILABLE': IPusherAttendeeAvailablePayload;
  '@class/attendance/ATTENDEE_FAILURE': IPusherAttendeeFailurePayload;
  '@class/attendance/ATTENDANCE_MARKED': IPusherAttendanceMarkedPayload;
  '@class/attendance/SET_FAILURES_LAST_SYNCED_AT': ISetFailuresLastSyncedAtPayload;
  '@class/attendance/SET_RESPONDERS': ISetAttendanceResponders;
  '@class/pusher/INCHARGE_SET': IPusherInChargeSetPayload;
  '@class/pusher/ASSISTANTS_ADDED': IPusherAssistantsAddedPayload;
  '@class/ATTENDANCE_FETCH_MINE_SUCCESS': IAttendanceFetchMineSuccessPayload;
  '@class/EXPORT_GRADES': IExportGradesSuccessPayload;
  '@class/EXPORT_ALL_GRADES': IExportAllGradesSuccessPayload;
  '@class/GET_CLASS_PARTICIPATION_STATS': IGetClassParticipationPayload;
  '@class/SET_CLASS_PARTICIPATION_SCORES': ISetClassParticipationPayload;
  '@class/SET_SUMMARY_ACCESSED_ON': ISetSummaryAccessedOnPayload;
  '@class/SET_SORT_STUDENT_ORDER': StudentSortBy;
  '@class/UPDATE_CLASS_TEAM': IUpdateClassTeamPayload;
  '@class/FETCH_ALL_SUGGESTED_ACTIVITIES_SUCCESS': ISuggestedActivity[];
  '@class/USE_SUGGESTED_ACTIVITY_SUCCESS': api.IUseSuggestedActivityRequest;
  '@class/HIDE_SUGGESTED_ACTIVITY_SUCCESS': api.IHideSuggestedActivityRequest;
  '@class/GET_ONLINE_DETAILS_SUCCESS': api.IGetOnlineDetailsResponse;
  '@class/UPDATE_AUTO_CLOUD_RECORD': { autoCloudRecord: 0 | 1 };
  '@class/ONLINE_MEETING_STARTED': IPusherOnlineMeetingStartedPayload;
  '@class/ONLINE_MEETING_ENDED': IPusherOnlineMeetingEndedPayload;
  '@class/ONLINE_MEETING_BROADCASTING': IPusherOnlineMeetingStartedPayload;
  '@class/ONLINE_MEETING_BROADCAST_ENDED': IPusherOnlineMeetingEndedPayload;
  '@class/ONLINE_MEETING_DESTROYED': IPusherOnlineMeetingEndedPayload;
  '@class/GET_ZOOM_RECORDINGS_SUCCESS': IGetZoomRecordingsResult;
  '@class/PUBLISH_ZOOM_RECORDINGS_SUCCESS': IPublishZoomRecordings;
  '@class/DELETE_ZOOM_RECORDINGS_SUCCESS': api.IDeleteZoomRecordingPayload;
  '@pusher/ZOOM_RECORDINGS_PUBLISHED': IPusherZoomRecordingPublishedPayload;
  '@pusher/REMOTE_ATTENDANCE_STARTED': IPusherRemoteAttendanceStartedPayload;
  '@pusher/REMOTE_ATTENDANCE_STOPPED': IPusherRemoteAttendanceStoppedPayload;
  '@pusher/REMOTE_ATTENDANCE_RESPONSE': IPusherRemoteAttendanceResponsePayload;
  '@class/UPDATE_ACTIVITY_PUBLISH_PREFS': api.IActivityPublishPrefs;
  '@class/SCHEDULE_ATTENDANCE_SUCCESS': IScheduleAttendanceSuccessPayload;
  '@class/EDIT_SCHEDULE_ATTENDANCE_SUCCESS': IEditScheduleAttendanceSuccessPayload;
};

export type IScheduleAttendanceSuccessPayload = api.IScheduleAttendanceResponse & {
  classId: string;
};

export type IEditScheduleAttendanceSuccessPayload = AutoScheduleAttendanceConfig & {
  classId: string;
};

export interface IAttendanceIdentifiers {
  courseId: string;
  classId: string;
  attendanceTime: UnixTimestamp;
}

export interface IAttendanceStartedPayload extends IAttendanceIdentifiers {
  isProxy: 0 | 1;
  scheStartTime: UnixTimestamp;
  numEnrolled: number;
  numAvailable: number;
  numPresent: number;
  classAutoStarted: 0 | 1;
  /** ignore, if classAutoStarted === 0 */
  classStartedAt: UnixTimestamp;
  /** ignore, if classAutoStarted === 0 */
  classTrueNum: number;
  taker: {
    userId: string;
    name: string;
    avatar: string;
    role: ICourseRole;
  };
}

interface IAttendeeAvailableData extends IAttendanceIdentifiers {
  /** number of students who registered for this attendance */
  count: number;
  /** userId of the new student marked */
  attendee: string;
}

export type IPusherAttendeeAvailablePayload = IPusherPayload<IAttendeeAvailableData>;

interface IAttendeeFailureData extends IAttendanceIdentifiers {
  failedAttendee: {
    userId: string;
    name: string;
    avatar: string;
    role: 'student';
    /**
     * `noPermissions` - Has not provided adequate permissions
     * `bleNotSupported` - Is using a device that doesn’t support Bluetooth
     * `noGPS` - Has not switched on the GPS
     */
    failureCode: 'noPermissions' | 'bleNotSupported' | 'noGPS';
  };
}

export type IPusherAttendeeFailurePayload = IPusherPayload<IAttendeeFailureData>;

interface IAttendanceMarkedData extends IAttendanceIdentifiers {
  /** number of students marked present for this attendance */
  count: number;
  /** student id */
  attendee: string;
  /** @deprecated use {@link attendee} instead */
  studentId: string;
}

export type IPusherAttendanceMarkedPayload = IPusherPayload<IAttendanceMarkedData>;

export interface ISetFailuresLastSyncedAtPayload {
  courseId: string;
  classId: string;
  timestamp: UnixTimestamp;
}

export type ISetAttendanceResponders = api.IGetAttendanceRespondersResponse &
  ISetFailuresLastSyncedAtPayload;

export type IPusherAttendanceData = IPusherPayload<{
  action: 'showWarning' | 'hideWarning';
  courseId: string;
  classId: string;
  isProxy: 0 | 1;
  attendanceTime: number;
  wasScheduled: 0 | 1;
  numEnrolled: number;
  numAvailable: number;
  numPresent: number;
  scheStartTime: number;
  classAutoStarted: 0 | 1;
  /** ignore, if classAutoStarted === 0 */
  classStartedAt: UnixTimestamp;
  /** ignore, if classAutoStarted === 0 */
  classTrueNum: number;
  /** @deprecated legacy */
  classType: string;
  /** @deprecated legacy */
  scheStartTimeL: string;
  /** @deprecated legacy */
  method: string;
}>;

export type IPusherRemoteAttendanceStartedPayload = IPusherPayload<{
  classId: string;
  meetingId: ZoomId;
  attendanceId: string;
  displayMessages: {
    subtitle: string;
    buttonText: string;
  }[];
}>;

export type IPusherRemoteAttendanceStoppedPayload = IPusherPayload<{
  classId: string;
  meetingId: ZoomId;
  attendanceId: string;
}>;

export type IPusherRemoteAttendanceResponsePayload = IPusherPayload<{
  classId: string;
  meetingId: ZoomId;
  attendanceId: string;
}>;

export type IPusherZoomRecordingPublishedPayload = IPusherPayload<{
  classId: string;
  recordingsAvailable: number;
  status: 'done';
  published: 1;
}>;

export interface IGetZoomRecordingsResult {
  classId: string;
  recordings: IZoomRecording[];
}

export interface IPublishZoomRecordings {
  classId: string;
}

export type IPusherOnlineMeetingStartedPayload = IPusherPayload<{
  courseId: string;
  classId: string;
  meetingId: ZoomId;
}>;

export type IPusherOnlineMeetingEndedPayload = IPusherPayload<{
  courseId: string;
  classId: string;
  meetingId: ZoomId;
}>;

export type IPusherActivityStoppedPayload = IPusherPayload<{
  courseId: string;
  classId: string;
  activityId: string;
  activityType: 'poll' | 'quiz';
  dueDateTime: number;
  dueDateTimeL: string;
}>;

export interface IUpdateClassTeamPayload {
  classId: string;
  team: IClassInfo['team'];
}

export type ISetSummaryAccessedOnPayload = {
  time: UnixTimestamp;
  classId: string;
};

export type ISetClassInchargeResponse = api.ISetClassInchargeRequest & {
  classIds: string[];
};

export type ISetClassParticipationPayload = {
  request: api.IClassParticipationRequest;
  addNewLabel: boolean;
  instructor: {
    userId: string;
    avatar: string;
    name: string;
    role: 'admin' | 'instructor' | 'ta';
  };
};
export type IGetClassParticipationPayload = IParticipationStudentData[];
export type IExportGradesSuccessRequest = {
  activityType: 'polls' | 'quizzes' | 'assignments' | 'classes' | 'participation';
  activityId: string;
  email: 0 | 1;
};
export type IExportAllGradesSuccessRequest = {
  activityType: 'polls' | 'quizzes' | 'assignments' | 'classes' | 'participation';
  email: 0 | 1;
};

export type IExportAllGradesSuccessPayload = IExportAllGradesSuccessRequest;
export type IExportGradesSuccessPayload = IExportGradesSuccessRequest;

export type IExportGradesSuccessResponse = {
  url: string;
  filename: string;
};

export type IAttendanceFetchMineSuccessPayload = {
  response: api.IAttendanceFetchMineResponse;
  classId: string;
};

export type IPusherAssistantsAddedPayload = IPusherPayload<void>;

export type IPusherInChargeSetPayload = IPusherPayload<{
  inCharge: {
    avatar: string;
    name: string;
    role: ICourseTeamMember['role'];
    userId: string;
  };
  classId: string;
  classType: 'lecture';
  venue: string;
}>;

export type IAttendanceUpdatedPayload = IPusherPayload<{
  classId: string;
  courseId: string;
}>;

export type IAttendanceSetSuccessPayload = api.IAttendanceSetRequest;

export type IPusherCheckInPayload = IPusherPayload<{
  classId: string;
  scheStartTime: string;
  checkInTime: UnixTimestamp;
  isLate: 0 | 1;
  activityType?: string;
  activityId?: string;
}>;

export type IAttendanceFetchSuccessPayload = api.IAttendanceFetchResponse & { classId: string };

export type ITopicsEditSuccessPayload = api.ITopicsEditRequest;

// export type IEditClassTeamSuccessPayload = api.IEditClassTeamRequest;

export type IAgendaEditSuccessPayload = api.IAgendaEditRequest;

export type IClassTitleEDitSuccessPayload = api.IClassTitleEditRequest;

export type IVenueEditSuccessPayload = {
  newVenue: string;
  changedClassIds: string[];
};

export type ICancelSuccessPayload = api.IClassCancelRequest;

export type IPusherAddPayload = IPusherPayload<{
  classId: string;
  classType: IClassType;
  classSubType: 'extra';
  classTypeNum: number;
  scheStartTime: UnixTimestamp;
  scheStartTimeL: string;
  scheEndTime: UnixTimestamp;
  scheEndTimeL: string;
  venue: string;
}>;

export type IRemoveWeeklyClassSuccessPayload = number /* course schedule index */;
export type IAddWeeklyClassSuccessPayload = api.IAddWeeklyClassRequest;

export interface IDeleteSuccessPayload {
  classId: string;
  type: IClassType;
  subType: IClassSubType;
}
export interface ICheckInSuccessPayload {
  classId: string;
  checkInTime: UnixTimestamp;
}

export interface IIncrementSeenActivitiesPayload {
  classId: string;
  toBeDone: 'preClass' | 'inClass';
  activityKey: keyof IClass['userData']['preClass' | 'inClass'];
}

export type ISetInchargeSuccessPayload = api.ISetClassInchargeRequest;
export type IFetchActivitiesSuccessPayload = {
  response: api.IFetchClassActivitiesResponse;
  classId: string;
  currentTime: UnixTimestamp;
};
export type IStartClassPayload = {
  classId: string;
};

export interface IMoveActivitySuccessPayload extends api.IMoveClassActivityRequest {
  activity: IClassActivity;
}

export interface IPusherClassTeamEditedPayload {
  courseId: string;
  classId: string;
  message: string;
  inCharge: ICourseTeamMember;
  assistants: ICourseTeamMember[];
  multiple: 0 | 1;
}

export const Actions = {
  setSummaryAccessedOn: createAction('@class/SET_SUMMARY_ACCESSED_ON'),

  setClassInchargeSuccess: createAction('@class/SET_INCHARGE_SUCCESS'),
  pusherInChargeSet: createAction('@class/pusher/INCHARGE_SET'),
  pusherAssistantsAdded: createAction('@class/pusher/ASSISTANTS_ADDED'),
  setClassIncharge: (data: api.ISetClassInchargeRequest) => async (dispatch: any) => {
    await api.setClassInchargeV2(data);
    googleAnalytics.classInchargeSet();
    await dispatch(CourseActions.fetchTimeline());
    return data;
  },

  startClass: createAction('@class/START'),

  fetchClassActivitiesSuccess: createAction('@class/FETCH_ACTIVITIES_SUCCESS'),
  fetchClassActivities:
    (_cls: string | IClass): Thunk<void> =>
    async (dispatch, getState) => {
      let cls: IClass;
      if (typeof _cls === 'string') {
        const timeline = getState().courses.timeline;
        if (!timeline) {
          return;
        }
        const c = <IClass>timeline.items.find((c) => c._id === _cls);
        if (!c) {
          return;
        } else {
          cls = c;
        }
      } else {
        cls = _cls;
      }

      const response = await api.fetchClassActivities(cls._id);
      const queries = response.data.queries;
      dispatch(
        GetInActions.fetchAvatars(
          queries.map((q) => q.details.createdBy.avatar).filter((id) => id !== 'anonymous')
        )
      );
      dispatch(
        Actions.fetchClassActivitiesSuccess({
          response: response.data,
          classId: cls._id,
          currentTime: dt.unix(),
        })
      );
    },

  closeClassScreen: createAction('@class/CLOSE_CLASS_SCREEN'),

  moveClassActivitySuccess: createAction('@class/MOVE_ACTIVITY_SUCCESS'),
  moveClassActivity:
    (
      data: api.IMoveClassActivityRequest,
      // activity: IClassActivity,
      whenDone?: () => any
    ): Thunk<void> =>
    async (dispatch) => {
      await api.moveClassActivity(data);
      await dispatch(CourseActions.fetchTimeline());
      // if (data.newClassId === classService.getCurrentClassId()) {
      //     // to update the class activities if moved in the same class
      //     await dispatch(ClassActions.fetchClassActivities(data.classId));
      // }
      if (whenDone) {
        await whenDone();
      }
      await dispatch(ClassActions.fetchClassActivities(data.classId));
      // dispatch(Actions.moveClassActivitySuccess({
      //     ...data,
      //     activity
      // }));
    },

  checkInSuccess: createAction('@class/CHECK_IN_SUCCESS'),
  // checkIn: (classId: string): Thunk<void> => async dispatch => {
  //     const response = await api.checkIn(classId);
  //     googleAnalytics.checkIn();
  //     dispatch(Actions.checkInSuccess({
  //         classId,
  //         checkInTime: response.data.checkInTime
  //     }));
  // },
  checkIn:
    (data: api.ICheckInRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.checkInV2(data);
      googleAnalytics.checkIn();
      dispatch(
        Actions.checkInSuccess({
          classId: data.classId,
          checkInTime: response.data.checkInTime,
        })
      );
    },
  incrementClassSeenActivities: createAction('@class/INCREMENT_SEEN_ACTIVITIES'),

  addWeeklyClassSuccess: createAction('@class/ADD_WEEKLY_CLASS_SUCCESS'),
  addWeeklyClass:
    (scheduledClass: api.IAddWeeklyClassRequest): Thunk<void> =>
    async (dispatch) => {
      await api.addWeeklyClassV2(scheduledClass);
      dispatch(Actions.addWeeklyClassSuccess(scheduledClass));
    },

  removeWeeklyClassSuccess: createAction('@class/REMOVE_WEEKLY_CLASS_SUCCESS'),
  removeWeeklyClass:
    (startTime: string, type: IClassType, day: IWeekDay, index: number): Thunk<void> =>
    async (dispatch) => {
      await api.removeWeeklyClass({
        type,
        day,
        startTime,
      });
      dispatch(Actions.removeWeeklyClassSuccess(index));
    },

  deleteClassSuccess: createAction('@class/DELETE_SUCCESS'),
  deleteClass:
    (classId: string, type: IClassType, subType: IClassSubType): Thunk<void> =>
    async (dispatch) => {
      await api.deleteClass(classId);
      dispatch(Actions.deleteClassSuccess({ classId, type, subType }));
    },

  addClass:
    (request: api.IAddClassRequest, userId: string): Thunk<api.IAddClassResponse> =>
    async (dispatch) => {
      const response = await api.addClass(request);
      if (response.data.message !== 'clash') {
        await dispatch(CourseActions.fetchTimeline(userId));
      }
      return response.data;
    },

  pusherAdd: (): Thunk<void> => async (dispatch) => {
    dispatch(CourseActions.fetchTimeline());
  },

  classCancelSuccess: createAction('@class/CANCEL_SUCCESS'),
  classCancel:
    (request: api.IClassCancelRequest, beforeDispatch: () => any = () => {}): Thunk<void> =>
    async (dispatch) => {
      await api.cancelClass(request);
      await beforeDispatch();
      await dispatch(CourseActions.fetchTimeline());
    },

  titleEditSuccess: createAction('@class/TITLE_EDIT_SUCCESS'),
  titleEdit:
    (data: api.IClassTitleEditRequest): Thunk<void> =>
    async (dispatch) => {
      await api.classTitleEdit(data);
      dispatch(Actions.titleEditSuccess(data));
    },

  venueEditSuccess: createAction('@class/VENUE_EDIT_SUCCESS'),
  venueEdit:
    (data: api.IVenueEditRequest): Thunk<void> =>
    async (dispatch) => {
      await api.venueEdit(data);
      await dispatch(CourseActions.fetchTimeline());
    },

  agendaEditSuccess: createAction('@class/AGENDA_EDIT_SUCCESS'),
  agendaEdit:
    (data: api.IAgendaEditRequest): Thunk<void> =>
    async (dispatch) => {
      await api.agendaEdit(data);
      dispatch(Actions.agendaEditSuccess(data));
    },

  editClassTeam:
    (data: api.IEditClassTeamRequest): Thunk<void> =>
    async (dispatch) => {
      await api.editClassTeam(data);
      await dispatch(CourseActions.fetchTimeline());
      // dispatch(Actions.assistantsEditSuccess({
      //     ...data,
      //     classIds: response.data.classIds
      // }));
    },

  updateClassTeam: createAction('@class/UPDATE_CLASS_TEAM'),
  classTeamEdited:
    (data: IPusherClassTeamEditedPayload): Thunk<void> =>
    async (dispatch) => {
      if (data.multiple) {
        // multiple class data has been updated so refresh the timeline data
        await dispatch(CourseActions.fetchTimeline());
      } else {
        // update class team only
        dispatch(
          Actions.updateClassTeam({
            classId: data.classId,
            team: {
              inCharge: data.inCharge,
              assistants: data.assistants,
            },
          })
        );
      }
    },

  topicsEditSuccess: createAction('@class/TOPICS_EDIT_SUCCESS'),
  topicsEdit:
    (data: api.ITopicsEditRequest): Thunk<void> =>
    async (dispatch) => {
      await api.topicsEdit(data);
      dispatch(Actions.topicsEditSuccess(data));
    },
  attendanceFetchMineSuccess: createAction('@class/ATTENDANCE_FETCH_MINE_SUCCESS'),
  attendanceFetchMine:
    (classId: string): Thunk<api.IAttendanceFetchMineResponse> =>
    async (dispatch) => {
      const response = await api.attendanceFetchMine(classId);
      dispatch(
        Actions.attendanceFetchMineSuccess({
          classId,
          response: response.data,
        })
      );
      return response.data;
    },

  attendanceFetchSuccess: createAction('@class/ATTENDANCE_FETCH_SUCCESS'),
  attendanceFetch:
    (classId: string): Thunk<void> =>
    async (dispatch) => {
      const response = await api.attendanceFetch(classId);
      dispatch(GetInActions.fetchAvatars(response.data.attendance.checkedIn.map((s) => s.avatar)));
      dispatch(
        Actions.attendanceFetchSuccess({
          ...response.data,
          classId: classId,
        })
      );
    },

  getClassParticipationSuccess: createAction('@class/GET_CLASS_PARTICIPATION_STATS'),
  getClassParticipation:
    (classId: string): Thunk<void> =>
    async (dispatch) => {
      const response = await api.getClassParticipation(classId);
      const studentData = response.data.studentData;
      dispatch(GetInActions.fetchAvatars(studentData.map((s) => s.avatar)));
      dispatch(Actions.getClassParticipationSuccess(studentData));
    },

  setClassParticipationSuccess: createAction('@class/SET_CLASS_PARTICIPATION_SCORES'),
  setClassParticipation:
    (
      data: api.IClassParticipationRequest,
      instructor: ISetClassParticipationPayload['instructor']
    ): Thunk<void> =>
    async (dispatch) => {
      const response = await api.setClassParticipation(data);
      dispatch(
        Actions.setClassParticipationSuccess({
          request: data,
          instructor,
          addNewLabel: !!response.data.newLabel,
        })
      );
    },

  exportGrades:
    (request: IExportGradesSuccessRequest): Thunk<IExportGradesSuccessResponse> =>
    async () => {
      const response = await api.exportGrades(request);
      return response.data;
    },

  exportAllGrades:
    (request: IExportAllGradesSuccessRequest): Thunk<IExportGradesSuccessResponse> =>
    async () => {
      const response = await api.exportAllGrades(request);
      return response.data;
    },

  attendanceClear: createAction('@class/ATTENDANCE_CLEAR'),

  attendanceSetSuccess: createAction('@class/ATTENDANCE_SET_SUCCESS'),
  attendanceSet:
    (
      data: api.IAttendanceSetRequest,
      headers: {
        courseId: string;
      }
    ): Thunk<void> =>
    async (dispatch) => {
      await api.attendanceSet(data, headers);
      googleAnalytics.attendanceMarked();
      await dispatch(Actions.attendanceSetSuccess(data));
    },

  updateAttendance:
    (payload: IAttendanceUpdatedPayload): Thunk<void> =>
    async (dispatch) => {
      const route = getActiveRoute();
      if (route) {
        const courseShortId = courseService.getShortIdFromCourseId(payload.courseId);
        const classShortId = classService.getShortIdFromClassId(payload.classId);

        const isAttendancePageOpen =
          Routes.classAttendance.isActive() &&
          route.params.courseShortId === courseShortId &&
          route.params.classShortId === classShortId;

        if (isAttendancePageOpen) {
          // reload attendance data
          dispatch(Actions.attendanceFetch(payload.classId));
        }

        const currentUser = appService.getCurrentUser();
        if (isAttendancePageOpen && currentUser && currentUser.userId !== payload.sender.userId) {
          // notify user that attendance has been updated from other device
          dispatch(
            AppActions.notificationShow({
              msgId: payload.msgId,
              message: `Attendance has been updated by ${payload.sender.name}`,
              avatar: payload.sender.avatar,
              courseId: payload.courseId,
              classId: payload.classId,
            })
          );
          setTimeout(() => {
            // clear notification after 5 seconds
            dispatch(AppActions.notificationClear(payload.msgId));
          }, 5000);
        }
      }
    },

  attendanceStarted: createAction('@class/ATTENDANCE_STARTED'),
  attendanceStopped: createAction('@class/ATTENDANCE_STOPPED'),
  attendanceDialogDismiss: createAction('@class/ATTENDANCE_DIALOG_DISMISS'),

  receiveAttendanceData:
    (payload: IPusherAttendanceData): Thunk<void> =>
    async (dispatch, getState) => {
      if (payload.action === 'hideWarning') {
        dispatch(
          Actions.attendanceStopped({
            courseId: payload.courseId,
            classId: payload.classId,
            attendanceTime: payload.attendanceTime,
          })
        );
      } else {
        const { sender: taker, ...rest } = payload;

        dispatch(Actions.attendanceStarted({ ...rest, taker }));

        if (getCourseRole(getState()) === 'student') {
          // used for analytics purpose, how many students are using web-browser
          await api.acknowledgeAttendanceStarted({
            device: 'browser',
            notification: 'pusher',
            event: 'attendanceStarted',
            courseId: payload.courseId,
            classId: payload.classId,
            startTime: payload.attendanceTime,
          });
        }
      }
    },

  pusherCheckIn: createAction('@class/pusher/CHECK_IN'),

  pusherActivityUpdatedAction: createAction('@class/pusher/ACTIVITY_UPDATED'),
  pusherActivityUpdated: (payload: IUpdateActivityBasePayload) => {
    const route = getActiveRoute();
    const state = getStore().getState();
    if (route) {
      switch (state.app.context) {
        case 'resource': {
          // Needs to have the resource property, hence be a resource update event
          if (!Object.keys(payload.resource).length) break;
          const shortId = route.params.resourceShortId;

          const resource = Object.keys(state.resources.byId)
            .map((id: string) => state.resources.byId[id])
            .find((res: IResource) => res._id.endsWith(shortId));

          if (!resource) break;
          dispatch(
            ResourceActions.analyticsSet({
              request: {
                resourceId: resource._id,
                firstAccess: resource.userData ? 0 : 1,
                localTime: dt.format(dt.now(), 'YYYYMMDDTHH:mm'),
              },
              classId: resource.identifiers.classId,
              toBeDone: resource.details.toBeDone,
            })
          );
          break;
        }
        case 'discussion': {
          // Needs to have the discussion property, hence be a discussion update event
          if (!Object.keys(payload.discussion).length) break;
          const shortId = route.params.discussionShortId;

          const discussion = Object.keys(state.discussions.byId)
            .map((id: string) => state.discussions.byId[id])
            .find((res: IDiscussion) => res._id.endsWith(shortId));

          if (!discussion) break;
          dispatch(
            DiscussionActions.analyticsSet({
              request: {
                discussionId: discussion._id,
                firstAccess: discussion.userData ? 0 : 1,
                localTime: dt.format(dt.now(), 'YYYYMMDDTHH:mm'),
              },
              classId: discussion.identifiers.classId,
              toBeDone: discussion.details.toBeDone,
            })
          );
          break;
        }
        default:
          break;
      }
    }

    return Actions.pusherActivityUpdatedAction(payload);
  },

  timingsEdit:
    (data: api.ITimingsEditRequest): Thunk<api.ITimingsEditResponse> =>
    async (dispatch) => {
      const response = await api.timingsEdit(data);
      if (response.data.message === 'clash') {
        await dispatch(CourseActions.fetchTimeline());
      }
      return response.data;
    },

  reschedule:
    (data: api.IRescheduleRequest): Thunk<void> =>
    async (dispatch) => {
      await api.rescheduleClass(data);
      await dispatch(CourseActions.fetchTimeline());
    },

  pusherVenueChanged:
    (data: IPusherMessageBase): Thunk<void> =>
    async (dispatch, getState) => {
      const session = getState().getIn.session;
      if (session && session.userId !== data.sender.userId) {
        await dispatch(CourseActions.fetchTimeline());
      }
    },

  pusherTimingsChanged:
    (data: IPusherMessageBase): Thunk<void> =>
    async (dispatch, getState) => {
      const session = getState().getIn.session;
      if (session && session.userId !== data.sender.userId) {
        await dispatch(CourseActions.fetchTimeline());
      }
    },

  setSortStudentOrder: createAction('@class/SET_SORT_STUDENT_ORDER'),

  fetchAllSuggestedActivitiesSuccess: createAction('@class/FETCH_ALL_SUGGESTED_ACTIVITIES_SUCCESS'),
  fetchAllSuggestedActivities: (): Thunk<void> => async (dispatch) => {
    const response = await api.fetchSuggestedActivities();
    dispatch(Actions.fetchAllSuggestedActivitiesSuccess(response.data.activities));
  },

  useSuggestedActivitySuccess: createAction('@class/USE_SUGGESTED_ACTIVITY_SUCCESS'),
  useSuggestedActivity:
    (params: api.IUseSuggestedActivityRequest): Thunk<void> =>
    async (dispatch) => {
      await api.useSuggestedActivity(params);
      await dispatch(Actions.useSuggestedActivitySuccess(params));
      await dispatch(Actions.fetchClassActivities(params.copyToClass));
    },

  hideSuggestedActivitySuccess: createAction('@class/HIDE_SUGGESTED_ACTIVITY_SUCCESS'),
  hideSuggestedActivity:
    (params: api.IHideSuggestedActivityRequest): Thunk<void> =>
    async (dispatch) => {
      await api.hideSuggestedActivity(params);
      await dispatch(Actions.hideSuggestedActivitySuccess(params));
    },

  activityStopped:
    (data: IPusherActivityStoppedPayload): Thunk<void> =>
    async (dispatch) => {
      if (data.activityType === 'quiz') {
        dispatch(
          QuizActions.stopQuizSuccess({
            request: {
              activityId: data.activityId,
              activityType: 'quizzes',
              classId: data.classId,
              localTime: '',
            },
            response: {
              message: 'success',
              dueDateTime: data.dueDateTime,
            },
          })
        );
      } else if (data.activityType === 'poll') {
        dispatch(
          PollActions.stopPollSuccess({
            request: {
              activityId: data.activityId,
              activityType: 'polls',
              classId: data.classId,
              localTime: '',
            },
            response: {
              message: 'success',
              dueDateTime: data.dueDateTime,
            },
          })
        );
      }
    },

  onlineMeetingStarted: createAction('@class/ONLINE_MEETING_STARTED'),
  onlineMeetingEnded: createAction('@class/ONLINE_MEETING_ENDED'),
  onlineMeetingBroadcasting: createAction('@class/ONLINE_MEETING_BROADCASTING'),
  onlineMeetingBroadcastEnded: createAction('@class/ONLINE_MEETING_BROADCAST_ENDED'),
  onlineMeetingDestroyed: createAction('@class/ONLINE_MEETING_DESTROYED'),

  getOnlineDetailsSuccess: createAction('@class/GET_ONLINE_DETAILS_SUCCESS'),
  getOnlineDetails:
    (classId: string): Thunk<void> =>
    async (dispatch) => {
      const response = await api.getOnlineDetails(classId);
      await dispatch(Actions.getOnlineDetailsSuccess(response.data));
    },
  updateAutoCloudRecord: createAction('@class/UPDATE_AUTO_CLOUD_RECORD'),
  createOnlineMeeting:
    (data: api.ICreateOnlineMeetingPayload): Thunk<void> =>
    async (dispatch) => {
      const response = await api.createOnlineMeeting(data);
      const { autoCloudRecord, ...onlineDetails } = response.data;
      await dispatch(Actions.getOnlineDetailsSuccess(onlineDetails));
      await dispatch(Actions.updateAutoCloudRecord({ autoCloudRecord }));
    },
  startVCallBroadcast:
    (classId: string, meetingId: ZoomId): Thunk<void> =>
    async (dispatch) => {
      await dispatch(
        AppActions.updateVCallDetails({
          isBroadcasting: true,
          beingBroadcast: 1,
        })
      );
      await api.startBroadcast(classId, meetingId);
    },
  endVCallBroadcast:
    (classId: string, meetingId: ZoomId): Thunk<void> =>
    async (dispatch) => {
      await api.endBroadcast(classId, meetingId);
      await dispatch(
        AppActions.updateVCallDetails({
          isBroadcasting: false,
          beingBroadcast: 0,
        })
      );
    },

  getZoomRecordingsSuccess: createAction('@class/GET_ZOOM_RECORDINGS_SUCCESS'),
  getZoomRecordings:
    (classId: string): Thunk<void> =>
    async (dispatch) => {
      const response = await api.getZoomRecordings(classId);
      await dispatch(
        Actions.getZoomRecordingsSuccess({
          classId,
          recordings: response.data.recordings,
        })
      );
    },

  publishZoomRecordingsSuccess: createAction('@class/PUBLISH_ZOOM_RECORDINGS_SUCCESS'),
  zoomRecordingsPublished: createAction('@pusher/ZOOM_RECORDINGS_PUBLISHED'),
  publishZoomRecordings:
    (classId: string): Thunk<void> =>
    async (dispatch) => {
      await api.publishZoomRecording(classId);
      await dispatch(
        Actions.publishZoomRecordingsSuccess({
          classId,
        })
      );
    },

  deleteZoomRecordingSuccess: createAction('@class/DELETE_ZOOM_RECORDINGS_SUCCESS'),
  deleteZoomRecording:
    (data: api.IDeleteZoomRecordingPayload): Thunk<boolean> =>
    async (dispatch, getState) => {
      await api.deleteZoomRecording(data);
      await dispatch(Actions.deleteZoomRecordingSuccess(data));

      const classData = getState().class.data;
      // returns false if no recording is left
      return !!classData && !!classData.recordings;
    },

  remoteAttendanceStarted: createAction('@pusher/REMOTE_ATTENDANCE_STARTED'),
  remoteAttendanceStopped: createAction('@pusher/REMOTE_ATTENDANCE_STOPPED'),
  remoteAttendanceResponse: createAction('@pusher/REMOTE_ATTENDANCE_RESPONSE'),

  updateActivityPublishPrefs: createAction('@class/UPDATE_ACTIVITY_PUBLISH_PREFS'),
  saveActivityPublishPrefs:
    (data: api.IActivityPublishPrefs): Thunk<void> =>
    async (dispatch) => {
      await api.saveActivityPublishPrefs(data);
      dispatch(Actions.updateActivityPublishPrefs(data));
    },

  scheduleAttendanceSuccess: createAction('@class/SCHEDULE_ATTENDANCE_SUCCESS'),
  scheduleAttendance:
    (data: api.IScheduleAttendanceRequest): Thunk<api.IScheduleAttendanceResponse> =>
    async (dispatch) => {
      const response = await api.scheduleAttendance(data);
      dispatch(Actions.scheduleAttendanceSuccess({ ...response.data, classId: data.classId }));
      return response.data;
    },

  editScheduleAttendanceSuccess: createAction('@class/EDIT_SCHEDULE_ATTENDANCE_SUCCESS'),
  editScheduleAttendance:
    (data: api.IEditScheduleAttendanceRequest): Thunk<api.IScheduleAttendanceResponse> =>
    async (dispatch) => {
      const response = await api.editScheduleAttendance(data);
      if (data.remove) {
        dispatch(
          Actions.editScheduleAttendanceSuccess({
            classId: data.classId,
            isScheduled: 0,
          })
        );
      } else {
        dispatch(
          Actions.editScheduleAttendanceSuccess({
            ...response.data.autoSchedule,
            classId: data.classId,
          })
        );
      }
      return response.data;
    },

  attendeeAvailable: createAction('@class/attendance/ATTENDEE_AVAILABLE'),
  attendeeFailure: createAction('@class/attendance/ATTENDEE_FAILURE'),
  attendanceMarked: createAction('@class/attendance/ATTENDANCE_MARKED'),
  setFailuresLastSyncedAt: createAction('@class/attendance/SET_FAILURES_LAST_SYNCED_AT'),
  setAttendanceResponders: createAction('@class/attendance/SET_RESPONDERS'),

  refreshAttendanceResponders: (): Thunk<void> => async (dispatch, getStore) => {
    const { attendanceData } = getStore().app;
    if (!attendanceData) return;

    const { courseId, classId } = attendanceData;

    dispatch(
      Actions.setFailuresLastSyncedAt({
        courseId,
        classId,
        timestamp: dt.unix(),
      })
    );

    const response = await api.getAttendanceResponders(courseId, classId);

    dispatch(
      Actions.setAttendanceResponders({
        ...response.data,
        courseId,
        classId,
        timestamp: dt.unix(),
      })
    );
  },

  startProxyAttendance:
    (classId: string): Thunk<void> =>
    async (dispatch, getState) => {
      const courseId = getState().courses.currentCourseId;
      const cls = classService.getCurrentClass();
      if (!courseId || !cls) return;

      const session = getState().getIn.session!;
      const taker: IAttendanceStartedPayload['taker'] = {
        userId: session.userId,
        name: session.name,
        avatar: session.avatar,
        role: courseService.getRole(courseId),
      };

      const response = await api.startProxyAttendance(classId);
      dispatch(
        Actions.attendanceStarted({
          attendanceTime: response.data.attendanceTime,
          scheStartTime: cls.details.scheStartTime,
          numAvailable: response.data.numAvailable,
          numEnrolled: response.data.numEnrolled,
          numPresent: response.data.numPresent,
          isProxy: 1,
          classId,
          courseId,
          taker,
          classAutoStarted: 0,
          classStartedAt: -1,
          classTrueNum: 0,
        })
      );
    },

  stopProxyAttendance: (): Thunk<void> => async (dispatch, getState) => {
    const attendanceData = getState().app.attendanceData;

    if (!attendanceData) return;

    const { attendanceTime, courseId, classId } = attendanceData;

    try {
      await api.stopProxyAttendance(courseId, classId);
    } finally {
      dispatch(Actions.attendanceStopped({ attendanceTime, courseId, classId }));
      await dispatch(Actions.attendanceFetch(classId));
    }
  },
};
