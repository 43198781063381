import { h, IComponent } from 'core';

import FlatButton from 'acadly/common/FlatButton';
import { mb, style } from 'acadly/styles';

interface IIntroStepProps {
  getInstance?: (ref: IntroStep) => any;
  onNext?: () => any;
}

export class IntroStep extends IComponent<IIntroStepProps, unknown> {
  public static getTitle(): string {
    return 'Create a new course';
  }

  public componentWillMount() {
    const { getInstance } = this.getProps();
    getInstance?.(this);
  }

  public handleNext = async () => {
    const { onNext } = this.getProps();
    onNext();
  };

  public render() {
    return h('div', [
      h(
        'div',
        style(['textCenter', 'large', mb('2rem')]),
        'You can create two kinds of courses in Acadly'
      ),
      h('div', style(['bold', mb('0.5rem')]), 'Acadly Pro course'),
      h('ul', [
        h('li', [
          'Get access to all Acadly features except LMS integration (only available for ',
          h(
            'a',
            { href: 'https://www.acadly.com/use-case-lms-integration', target: '_blank' },
            'Acadly Enterprise'
          ),
          ' customers)',
        ]),
        h(
          'li',
          "Download Excel reports with students' email addresses for easy data imports to the LMS gradebook"
        ),
        h('li', 'Schedule auto-attendance for click-free attendance tracking'),
        h(
          'li',
          "Anonymise students' discussion responses to encourage participation in discussions"
        ),
        h('li', 'Create quizzes with True-False, Multiple-Choice, and Sorting-Ordering questions'),
        h('li', [
          'Get Zoom integration to teach online and hybrid classes. Learn more about our Zoom-enabled features ',
          h(
            'a',
            { href: 'https://www.acadly.com/use-case-online-learning', target: '_blank' },
            'here'
          ),
          '.',
        ]),
      ]),
      h('div', style(['bold', mb('0.5rem')]), 'Acadly Basic course'),
      h('ul', [
        h('li', 'All Acadly Basic features are available in Pro courses as well'),
        h('li', 'Record in-person attendance automatically, at the tap of a button'),
        h(
          'li',
          'Share in-class activities like polls, quizzes, discussions, and word clouds to engage students'
        ),
        h('li', "Share pre-class activities to keep students engaged 'round the clock"),
        h(
          'li',
          "Download Excel reports of participation and attendance (reports do not include students' email addresses)"
        ),
        h('li', [
          'Share quizzes with True-False and Multiple-Choice questions. Sorting-Ordering questions are not included.',
        ]),
      ]),
      h('div', style(['flex', 'spaceBetween']), [
        FlatButton('Learn more', {
          onclick: () => {
            window.open('https://www.acadly.com/acadly-pro', '_blank');
          },
        }),
        FlatButton('Continue', {
          onclick: this.handleNext,
        }),
      ]),
    ]);
  }
}
