import { CSS, h, IComponent, View } from 'core';

import Icon from 'acadly/common/Icon';
import { getHTMLTagSelector } from 'acadly/utils';

import { getStore } from '../store';
// import { Observable } from "rxjs/Observable";
// import "rxjs/add/observable/fromEvent";
// import "rxjs/add/operator/skipUntil";
// import "rxjs/add/operator/takeUntil";
// import "rxjs/add/operator/map";
// import "rxjs/add/operator/filter";
// import "rxjs/add/operator/combineLatest";
// import "rxjs/add/operator/repeat";

interface ISlidingTab {
  view: View | (() => View);
  title: string;
  alternateText: string;
  icon?: string;
  navigate: () => any;
  getHeight?: (height: string) => any;
  tabIndex?: number | undefined;
}

interface ISlidingTabsProps {
  tabs: ISlidingTab[];
  tabsStyle?: CSS;
  hideTabs?: boolean;
  scroll?: boolean;
  noIcons?: boolean;
  activeTab: number;
  windowWidth: string;
  maxWindowWidth?: string;
  style?: CSS;
  id?: string;
}

interface ISlidingTabsState {
  dragOffset: number;
  dragStartTimestamp: number;
  subscribed: boolean;
  tabsWidth: number;
}

export default (props: ISlidingTabsProps) => h(SlidingTabs, props);

export class SlidingTabs extends IComponent<ISlidingTabsProps, ISlidingTabsState> {
  public componentWillMount() {
    this.setState({
      dragOffset: 0,
      subscribed: false,
      tabsWidth: 492,
    });
  }

  public render() {
    const scrollbarWidth = getStore().getState().app.scrollbarWidth;
    const props = this.getProps();
    const SINGLE_TAB_WIDTH = 100 / props.tabs.length;
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    const activeTab = this.getProps().activeTab;
    const activeTabObj = props.tabs[activeTab];

    return h(
      getHTMLTagSelector(
        'div',
        ['sliding-tabs', props.noIcons ? 'no-icons' : 'with-icons'],
        props.id
      ),
      {
        // sliding disabled for now as it causes problems
        // with nested sliding tabs
        ref: (elem?: HTMLElement) => this.onRef(elem),
        style: props.style,
      },
      [
        this.getProps().hideTabs
          ? null
          : h('div.sliding-tabs__wrapper', [
              h(
                getHTMLTagSelector('nav', ['sliding-tabs__tabs', 'no-focus'], 'sliding-tabs'),
                {
                  style: {
                    /**
                     * This was using the absolute pixel width of the
                     * rendered element before.
                     * Getting width from DOM element is slow
                     * so, I put the scrollbarWidth shifting
                     * as negative margin left. This way, we don't
                     * need to use calc (which has bugs in Safari)
                     * or .clientWidth, which is slow.
                     */
                    marginLeft: `${-scrollbarWidth}px`,
                    ...this.getProps().tabsStyle,
                  },
                  tabIndex: isAccessible ? 0 : undefined,
                },
                [
                  ...props.tabs.map((tab, index) =>
                    h(
                      getHTMLTagSelector('div', [
                        'ripple',
                        'sliding-tabs__tab',
                        `tab-${index}`,
                        activeTab === index ? 'active' : '',
                      ]),
                      {
                        tabIndex: tab.tabIndex,
                        onclick: tab.navigate.bind(tab),
                        key: `tab-${index}`,
                        'aria-label': `${tab.alternateText}`,
                        autoFocus: `${activeTab === index}`,
                        style: { width: `${SINGLE_TAB_WIDTH}%` },
                      },
                      [
                        tab.icon
                          ? Icon(
                              tab.icon,
                              {
                                className: 'sliding-tabs__tab-icon',
                              },
                              '',
                              {
                                isDiv: true,
                              }
                            )
                          : null,
                        h('div.sliding-tabs__tab-title', tab.title),
                      ]
                    )
                  ),
                  h('div.sliding-tabs__highlighter-track', [
                    h('div.sliding-tabs__highlighter', {
                      style: {
                        width: `${SINGLE_TAB_WIDTH}%`,
                        marginLeft: `${activeTab * SINGLE_TAB_WIDTH}%`,
                      },
                    }),
                  ]),
                ]
              ),
            ]),
        h(getHTMLTagSelector('div', ['sliding-tabs__tab-view', props.scroll ? 'scrollable' : '']), [
          activeTabObj.view instanceof Function ? activeTabObj.view() : activeTabObj.view,
        ]),
      ]
    );
  }

  private onRef(_?: HTMLElement) {
    // if (!elem) return;
    // if (this.getState().subscribed) return;
    // this.setState({
    //     subscribed: true
    // });
    // const dragStart$ = Observable.fromEvent(elem, "touchstart");
    // const dragEnd$ = Observable.fromEvent(elem, "touchend");
    // const dragPosition$ = Observable.fromEvent(elem, "touchmove");
    // // stream of dragEvents which is an infinite stream of
    // // drag length in pixels from the starting touch position
    // const drag$ = dragPosition$ // start with stream of touchmove events
    //     .skipUntil(dragStart$) // ignore all events before touchstart
    //     // combine with latest dragStart event
    //     .combineLatest(
    //         dragStart$, (drag: TouchEvent, start: TouchEvent) => ({start, drag})
    //     )
    //     // get x and y displacement from start and drag events
    //     .map(({start, drag}: any) => ({
    //         x: start.touches[0].pageX - drag.touches[0].pageX,
    //         y: start.touches[0].pageY - drag.touches[0].pageY,
    //         startTimestamp: start.timeStamp
    //     }))
    //     // filter out events where y displacement is more than x displacement
    //     .filter(({x, y}: any) => Math.abs(x) > Math.abs(y))
    //     // get x displacement
    //     // stop on touchend
    //     .takeUntil(dragEnd$)
    //     // repeat forever
    //     .repeat();
    // // whenever we recieve a drag event, set the dragOffset in state
    // drag$.subscribe((e: any) => this.setState({
    //     dragOffset: e.x,
    //     dragStartTimestamp: e.startTimestamp
    // }));
    // // on every dragEnd event, check if the current offset is greater than
    // // half of the screen width. If it is, then switch active tab.
    // // Set the drag offset back to 0 at the end
    // dragEnd$.subscribe(() => {
    //     const windowWidth = getStore().getState().app.windowWidth;
    //     const { activeTab, tabs } = this.getProps();
    //     if (
    //         activeTab < (tabs.length  - 1) &&
    //         this.getState().dragOffset >= (windowWidth / 2)
    //     ) {
    //         tabs[activeTab + 1].navigate();
    //     } else if (activeTab > 0 && this.getState().dragOffset <= -(windowWidth / 2)) {
    //         tabs[activeTab - 1].navigate();
    //     }
    //     this.setState({dragOffset: 0});
    // });
  }
}
