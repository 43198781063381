import { h, HTMLAttrs } from 'core';

import { colors } from 'acadly/styles';
import getAvatarUrl, { defaultAvatarUrl } from 'acadly/utils/avatar';

/**
 * A fast and simple hash function with decent collision resistance.
 * Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
 * Public domain.
 *
 * @param str string to be hashed
 * @param seed initial seed value
 *
 * @see https://stackoverflow.com/a/52171480/9709887
 */
function cyrb53(str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch: number; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

function getBgColorFromName(name: string) {
  const allowedBgColors = [colors.orange, colors.red, colors.blue, colors.green];
  return allowedBgColors[cyrb53(name) % allowedBgColors.length];
}

export default (avatar?: string, creator?: string, attrs: HTMLAttrs = {}) => {
  let ref: HTMLImageElement | null;

  if (!avatar && creator) {
    const backgroundColor = getBgColorFromName(creator);
    return h(
      'div.avatar.with-letter',
      {
        ...attrs,
        ariaLabel: `${creator}'s avatar`,
        style: {
          backgroundColor,
          color: colors.white,
        },
      },
      creator
        .split(/\s+/)
        .slice(0, 2)
        .map((w) => w[0].toUpperCase())
    );
  }

  return h('img.avatar', {
    ...attrs,
    alt: `${creator}'s avatar`,
    src: getAvatarUrl(avatar),
    ref: (el) => (ref = el as HTMLImageElement),
    onerror: () => {
      if (ref) {
        ref.src = defaultAvatarUrl;
      }
    },
  });
};
