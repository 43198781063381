import { CSS, h, IComponent } from 'core';

import { TipKey } from 'acadly/app/actions';
import { ITipsUpdateRequest } from 'acadly/app/api';
import portal from 'acadly/common/Portal';
import { dispatch } from 'acadly/store';
import * as u from 'acadly/utils';
import { CreateCoursePermission } from 'acadly/utils/constants';

import { Actions } from '../app/actions';
import FlatButton from '../common/FlatButton';
import { getStore } from '../store';
import { colors, ml, mr, pad, sidebarWidthPixels, style } from '../styles';

const PAGES = [
  'courseHome',
  'courseMain',
  'classMain',
  'assignmentMain',
  'quizMain',
  'pollMain',
  'queryMain',
  'discussionMain',
  'courseAnalytics',
  'assignmentAttempt',
  'quizAttempt',
  'activityAnalytics',
];

interface ITipOverlayWrapperProps {
  // style?: CSS;
  targetElement: string;
  tip: {
    tipPosition: 'right' | 'left' | 'top' | 'bottom' | 'bottom-left';
    tipText: string;
  };
  tipKey: TipKey;
  isNextAvailable: boolean;
  isSpecial?: boolean;
}

interface ITipOverlayWrapperState {
  isTipVisible: boolean;
  elemStyle: Partial<CSSStyleDeclaration>;
  elemZIndex: string | null;
  positions: CSS;
  elemOnClick: any;
}

const getTipPosition = (
  position: 'right' | 'left' | 'top' | 'bottom' | 'bottom-left',
  offsetLeft: number,
  offsetTop: number,
  width: number,
  height: number
) => {
  const isMobile = getStore().getState().app.isMobile;
  const isNarrow = getStore().getState().app.narrowScreen;
  const narrowOffsetLeft = !isNarrow ? offsetLeft + sidebarWidthPixels : offsetLeft;
  const tipElem = document.getElementById('tip');
  const tipHeight = tipElem ? tipElem.getBoundingClientRect().height : 0;
  let offsetTopAdjustment = 0;
  if (tipHeight > 200) {
    offsetTopAdjustment = 60;
  }
  let widthMain = 0;
  let heightMain = 0;
  if (width === 0 && height === 0) {
    widthMain = 25;
    heightMain = 25;
  } else {
    widthMain = width;
    heightMain = height;
  }
  if (isMobile) {
    return {
      position: 'fixed',
      left: '0px',
      top:
        position === 'top'
          ? offsetTop - 200 - offsetTopAdjustment
          : position === 'bottom'
          ? offsetTop + 150 + offsetTopAdjustment
          : position === 'left' || (position === 'right' && offsetTop > 500)
          ? offsetTop - 200 - offsetTopAdjustment
          : offsetTop + 200 + offsetTopAdjustment,
    };
  } else {
    return {
      position: 'fixed',
      left:
        position === 'left'
          ? `${offsetLeft - 350}px` // 366px(25em) is the width of the tipview
          : position === 'right'
          ? narrowOffsetLeft + 200
          : position === 'top' || position === 'bottom'
          ? narrowOffsetLeft + widthMain / 2
          : position === 'bottom-left'
          ? `${offsetLeft - widthMain - 350}px`
          : undefined,
      transform:
        position === 'top' || position === 'bottom' ? `translateX(-50%)` : `translateY(-50%)`,
      top:
        position === 'top'
          ? offsetTop - 200
          : position === 'bottom'
          ? offsetTop + 100
          : position === 'left' || position === 'right'
          ? offsetTop - heightMain / 4 - 40
          : position === 'bottom-left'
          ? `${offsetTop + widthMain + 50}px`
          : undefined,
    };
  }
};

export default (props: ITipOverlayWrapperProps) =>
  h(TipOverlayWrapper, {
    key: props.tipKey,
    ...props,
  });

@portal(document.getElementById('tips-container'))
export class TipOverlayWrapper extends IComponent<
  ITipOverlayWrapperProps,
  ITipOverlayWrapperState
> {
  public componentWillMount() {
    const key = this.getProps().tipKey;
    this.setState({
      isTipVisible: true,
      elemStyle: {},
      positions: {},
    });
    this.addTipItem(key);
  }
  private eventsBound = false;

  public componentDidUpate() {
    this.cleanupChild();
  }

  private cyclethroughTips = (e: KeyboardEvent) => {
    const tips = document.getElementById('tips-container');
    const tipsLen = tips ? tips.querySelectorAll('.empty').length : 0;
    if (e.keyCode === 9 && tipsLen > 0) {
      u.unsetTabIndices(document.getElementById('tips-container'));
    }
  };

  public componentWillReceiveProps(nextProps: ITipOverlayWrapperProps) {
    const isToolTipOpen = getStore().getState().app.isTipOpen;
    const visibleItems = getStore().getState().app.visibleElementsList;
    if (isToolTipOpen) {
      this.cleanupChild();
    }
    if (this.getProps().tipKey !== nextProps.tipKey) {
      this.removeTipItem(this.getProps().tipKey);
      this.addTipItem(nextProps.tipKey);
      this.setState({
        isTipVisible: true,
      });
    } else {
      if (!visibleItems[nextProps.tipKey]) {
        this.addTipItem(nextProps.tipKey);
      }
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this.cyclethroughTips);
    const key = this.getProps().tipKey;
    this.setState({
      isTipVisible: false,
    });
    this.cleanupChild();
    this.removeTipItem(key);
  }

  public render() {
    if (!this.getState().elemStyle) {
      return null;
    }
    const rootState = getStore().getState();
    const isThisTipVisible = this.getState().isTipVisible;
    const isTipToolVisible = rootState.app.isTipOpen;
    const props = this.getProps();
    const position = props.tip.tipPosition;
    const offsetLeft = this.getState().positions.left;
    const width = this.getState().positions.width;
    const offsetTop = this.getState().positions.top;
    const height = this.getState().positions.height;
    const isMobile = rootState.app.isMobile;
    const isTurnedOff = rootState.app.updatedTipObject.turnOff;
    const isMaxPriority = this.isMaxPriorityOnCurrentPage();
    const tipText = <string>props.tip.tipText;

    const session = rootState.getIn.session;
    const isProvisionalAccount =
      session && session.canCreateCourses === CreateCoursePermission.PROVISIONAL;
    // console.log(`${this.getProps().tipKey} ====> ${this.getState().isTipVisible}`);
    // console.log(`tiptoolvisible`, isTipToolVisible);
    // console.log("maxpriority", isMaxPriority);
    const overlayStyle = {
      height: '100%',
      width: '100%',
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: '9999',
      cursor: 'pointer',
      pointerEvents: 'auto',
      overflow: 'hidden',
      backgroundColor: 'rgba(5, 18, 25, 0.9)',
      mixBlendMode: 'hard-light',
    };
    const spotlightStyle = this.getStyles();
    if (!this.eventsBound && isTipToolVisible) {
      window.addEventListener('keydown', this.cyclethroughTips);
      this.eventsBound = true;
    }
    return isMaxPriority && !isTurnedOff && isTipToolVisible && !isProvisionalAccount
      ? h(
          'div.empty',
          {
            'aria-label': 'help text overlay',
            tabIndex: 0,
            key: `overlay-wrapper-${this.getProps().tipKey}`,
          },
          [
            h(
              'div.tip-wrapper-1',
              isThisTipVisible
                ? {
                    ref: this.onRef,
                    key: `overlay-tip-wrapper-${this.getProps().tipKey}`,
                    style: overlayStyle,
                    onclick: () => this.closeClickHandler(),
                  }
                : {},
              [
                isThisTipVisible
                  ? h('div.spotlight', {
                      tabIndex: 0,
                      'aria-label': tipText,
                      onclick: this.onClickHandler,
                      key: `spotlight-${this.getProps().tipKey}`,
                      style: spotlightStyle as any,
                    })
                  : null,
              ]
            ),
            isThisTipVisible
              ? h(
                  'div.tip',
                  {
                    id: 'tip',
                    key: `overlay-tip-${this.getProps().tipKey}`,
                    style: {
                      boxSizing: 'border-box',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      width: isMobile ? '100%' : '25em',
                      zIndex: '10000',
                      backgroundColor: colors.tipGreen,
                      ...getTipPosition(
                        position,
                        offsetLeft as any,
                        offsetTop as any,
                        width as any,
                        height as any
                      ),
                    },
                  },
                  [
                    h(
                      'div.text',
                      style([
                        mr('auto'),
                        'white',
                        {
                          padding: '1rem',
                          paddingTop: '0.5rem',
                          borderBottom: '1px solid grey',
                          whiteSpace: 'pre-line',
                        },
                      ]),
                      tipText
                    ),
                    h(
                      'div.button-container',
                      style([
                        'flex',
                        'alignCenter',
                        {
                          paddingTop: '0.5rem',
                        },
                      ]),
                      [
                        FlatButton('Turn Off Help', {
                          tabIndex: 0,
                          role: 'button',
                          onclick: () => this.turnOffTips(),
                          style: style([mr('auto'), 'white']).style,
                        }),
                        this.getProps().isNextAvailable
                          ? FlatButton('Next', {
                              role: 'button',
                              tabIndex: 0,
                              onclick: () => this.nextClickHandler(),
                              style: style([ml('auto'), mr('auto'), pad('0.5rem'), 'white']).style,
                            })
                          : null,
                        FlatButton('Close', {
                          role: 'button',
                          tabIndex: 0,
                          onclick: () => this.closeClickHandler(),
                          style: style([ml('auto'), 'white']).style,
                        }),
                      ]
                    ),
                  ]
                )
              : null,
          ]
        )
      : null;
  }

  private onClickHandler = async (event: Event) => {
    const isSpecial = this.getProps().isSpecial;
    const elemOnClick = this.getState().elemOnClick;
    if (!isSpecial) {
      await this.stopTipTool();
      await elemOnClick(event);
    }
    await this.setSeenForTip();
  };

  private async closeClickHandler() {
    window.removeEventListener('keydown', this.cyclethroughTips);
    const isSpecial = this.getProps().isSpecial;
    u.resetTabIndices();
    if (!isSpecial) {
      await this.stopTipTool();
    }
    await this.setSeenForTip();
  }

  private isMaxPriorityOnCurrentPage() {
    const tipKey = this.getProps().tipKey;
    const tipPage = PAGES.find((page) => tipKey.startsWith(page)); // CAN BE NULL
    const tipsObject = getStore().getState().app.updatedTipObject;
    const visibleElementsList = getStore().getState().app.visibleElementsList;
    // console.log("Visible Item", visibleElementsList);
    const highestPriorityTipItems = u
      .objectKeys(visibleElementsList) // pairs of key, boolean
      .map((k) => ({
        tipKey: k,
        isVisible: visibleElementsList[k],
      }))
      .filter((pair) => pair.isVisible === true) // take visible elems
      .map((pair) => pair.tipKey) // keys of visible items
      .filter((k) => tipsObject.status[k] !== undefined)
      .filter((k) => tipsObject.status[k]!.seen === 0)
      .filter((k) => k.startsWith(tipPage || 'undefined'))
      .map((k) => ({
        tipKey: k,
        priority: tipsObject.status[k]!.priority,
      }))
      .sort((a, b) => a.priority - b.priority);

    if (highestPriorityTipItems.length === 0) {
      return false;
    } else {
      if (tipKey === highestPriorityTipItems[0].tipKey) {
        // console.log("highestPriority", highestPriorityTipItems[0]);
        return true;
      } else {
        return false;
      }
    }
  }

  private getStyles() {
    // const isMobile = getStore().getState().app.isMobile;
    const elemStyle = this.getState().elemStyle;
    const positions = this.getState().positions;
    let sizeStyle = {};
    const keys: string[] = [];
    for (const key in elemStyle) {
      if (key !== 'length' && typeof (elemStyle as any)[key] === 'string') {
        keys.push(key);
      }
    }
    const elemStyleObj = u.pairsToObject(
      <any>keys
        .filter((c) => !/^[\d]+$/.test(c))
        .filter((k) => (<any>elemStyle)[k])
        .map((k) => [k, (<any>elemStyle)[k]])
    );
    if (elemStyleObj['fontFamily'] === 'acadly-icons') {
      sizeStyle = {
        top: positions.top ? `${Number(positions.top) - 6}px` : undefined,
        left: positions.left ? `${Number(positions.left) - 5}px` : undefined,
        width: '35px',
        height: '35px',
      };
    }
    return {
      // ...elemStyleObj,
      width: elemStyleObj['width'],
      height: elemStyleObj['height'],
      borderRadius: elemStyleObj['borderRadius'],
      ...positions,
      ...sizeStyle,
      padding: '0rem 0rem',
      paddingRight: '0px',
      paddingLeft: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      marginTop: '0px',
      marginLeft: '0px',
      marginRight: '0px',
      margin: '0rem 0rem',
      transform: undefined,
      position: 'fixed',
      backgroundColor: 'grey',
      opacity: '1',
      pointerEvents: 'auto',
      transition: 'opacity 0.2s',
    };
  }

  private async nextClickHandler() {
    this.toggleIsVisible();
    await this.setSeenForTip();
  }

  // private async setUnseenForTip() {
  //     const key = this.getProps().tipKey;
  //     dispatch(Actions.setUnseenForTips(key));
  // }

  private async setSeenForTip() {
    const key = this.getProps().tipKey;
    const tipObject = getStore().getState().app.updatedTipObject;
    const updatedTipObject: ITipsUpdateRequest = {
      ...tipObject,
      agent: 'web' as const,
      status: {
        ...tipObject.status,
        [key]: {
          ...tipObject.status[key],
          seen: 1,
        },
      },
    };
    await dispatch(Actions.updateTip(updatedTipObject, key));
  }

  private async turnOffTips() {
    window.removeEventListener('keydown', this.cyclethroughTips);
    u.resetTabIndices();
    const key = this.getProps().tipKey;
    const tipObject = getStore().getState().app.updatedTipObject;
    const updatedTipObject = {
      ...tipObject,
      turnOff: 1 as any,
      agent: 'web' as any,
      status: {
        ...tipObject.status,
        [key]: {
          ...tipObject.status[key],
          seen: 1,
        },
      },
    };
    await dispatch(Actions.updateTip(updatedTipObject, key));
  }

  private async toggleIsVisible() {
    await this.setState({
      isTipVisible: false,
    });
    await this.cleanupChild();
  }

  private async stopTipTool() {
    await dispatch(Actions.stopTip(false));
  }

  private async addTipItem(selector: string) {
    await dispatch(Actions.addTipItem(selector));
  }

  private removeTipItem(selector: string) {
    setTimeout(() => {
      dispatch(Actions.removeTipItem(selector));
    });
  }

  private cleanupChild(elem?: HTMLElement) {
    if (elem) {
      elem.removeEventListener('click', this.eventListenerRef);
    }
  }

  private eventListenerRef: any;
  private childElem?: HTMLElement;
  private onRef = async (_elem: HTMLElement | undefined) => {
    // Set Timeot in order to make sure that the styles get updated
    setTimeout(async () => {
      const newChildElem = document.getElementById(this.getProps().targetElement) || undefined;
      if (newChildElem !== this.childElem) {
        this.cleanupChild(this.childElem);
      }
      this.childElem = newChildElem;

      if (this.childElem) {
        const boundingRect = this.childElem.getBoundingClientRect();
        // console.log("boundingRect", boundingRect);
        await this.setState({
          elemStyle: this.childElem.style,
          positions: {
            left: boundingRect.left,
            top: boundingRect.top,
            width: this.childElem.clientWidth,
            height: this.childElem.clientHeight,
          },
          elemOnClick: this.childElem.onclick,
        });
      }
    });
  };
}
