import { ICourseState } from './ICourseState';
const initialState: ICourseState = {
  isLoadingCourses: false,
  courses: {},
  userData: {},
  topics: [],
  allParticipationLabels: [],
  analytics: {
    students: {
      enrolledStudents: [],
      pendingStudents: [],
      removedStudents: [],
    },
    averages: undefined,
  },
  info: {
    description: '',
    readingList: {
      books: [],
      links: [],
      files: [],
    },
    officeHours: [],
  },
};

export { initialState };
