import { h, HTMLAttrs, View } from 'core';

import { getHTMLTagSelector } from 'acadly/utils';

import Avatar from './Avatar';
import CheckBox from './CheckBox';
import SelectableAvatar from './SelectableAvatar';

interface UserProps {
  classNames?: string[];

  avatar?: {
    url?: string;
    creator?: string;
    attr?: HTMLAttrs;
  };

  title: string | View;
  titleClassNames?: string[];

  subtitle?: string | View;
  subtitleClassNames?: string[];

  action?: string | View;
  actionClassNames?: string[];
  onAction?(): void;

  selectable?: boolean;
  isSelected?: boolean;
  showCheckBox?: boolean;
  onSelect?(): void;
}

export default (props: UserProps, attr?: HTMLAttrs) => {
  let avatarClassName = 'user__avatar';

  if (props.avatar && props.avatar.attr && props.avatar.attr.className) {
    avatarClassName += ` ${props.avatar.attr.className}`;
  }

  const selectableAvatar = props.selectable && !props.showCheckBox;

  return h(
    getHTMLTagSelector('div', [
      'user',
      ...(props.classNames || []),
      attr && attr.onclick ? 'pointer' : '',
      attr && attr.onclick ? 'ripple' : '',
    ]),
    attr,
    [
      props.selectable && props.showCheckBox
        ? CheckBox({
            selected: props.isSelected,
            className: 'user__checkbox fc-blue',
            onclick: () => {
              if (props.onSelect) {
                props.onSelect();
              }
            },
          })
        : h('div'),
      props.avatar && selectableAvatar
        ? SelectableAvatar(props.avatar.url, props.avatar.creator, props.isSelected, {
            ...props.avatar.attr,
            className: avatarClassName,
          })
        : h('div'),
      props.avatar && !selectableAvatar
        ? Avatar(props.avatar.url, props.avatar.creator, {
            ...props.avatar.attr,
            className: avatarClassName,
          })
        : h('div'),
      h('div.user__details', [
        h(getHTMLTagSelector('div', ['user__title', ...(props.titleClassNames || [])]), [
          props.title,
        ]),
        props.subtitle
          ? h(getHTMLTagSelector('div', ['user__subtitle', ...(props.subtitleClassNames || [])]), [
              props.subtitle,
            ])
          : null,
      ]),
      props.action
        ? h(getHTMLTagSelector('div', ['user__action', ...(props.actionClassNames || [])]), [
            props.action,
          ])
        : null,
    ]
  );
};
