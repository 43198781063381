import anchorme from 'anchorme';
import cn from 'classnames';

import { CSS, h, IComponent, View } from 'core';
import { VCallStatus } from 'core/enums';
import { Route, Switch } from 'core/router';

import * as NowPlayingIcon from 'assets/now_playing.svg';
import * as RecordingIcon from 'assets/recording-icon.svg';
import * as TranscriptIcon from 'assets/transcript-icon.svg';
import * as VideoCamIcon from 'assets/videocam.svg';

import { api as urls } from 'acadly/api';
import { Actions as AppActions } from 'acadly/app/actions';
import { Actions as appActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import appService from 'acadly/app/service';
import { Actions } from 'acadly/class/actions';
import { Class } from 'acadly/class/functions';
import classService from 'acadly/class/service';
import SuggestedActivitiyPreview from 'acadly/class/SuggestedActivitiyPreview';
import SuggestedActivityWidget from 'acadly/class/SuggestedActivityWidget';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import BasicButton from 'acadly/common/BasicButton';
import CheckBox from 'acadly/common/CheckBox';
import ContentView from 'acadly/common/ContentView';
import DatePicker from 'acadly/common/DatePicker';
import Dialog from 'acadly/common/Dialog';
import DivButton from 'acadly/common/DivButton';
import FlatButton from 'acadly/common/FlatButton';
import FloatingActionButton from 'acadly/common/FloatingActionButton';
import FloatingMenu from 'acadly/common/FloatingMenu';
import { Icon } from 'acadly/common/Icon';
import { fullScreenLoader, Loader } from 'acadly/common/Loader';
import MediaCell from 'acadly/common/MediaCell';
import Paper from 'acadly/common/Paper';
import RadioButton from 'acadly/common/RadioButton';
import SvgIcon from 'acadly/common/SvgIcon';
import TextField from 'acadly/common/TextField';
import TimePicker from 'acadly/common/TimePicker';
import TipOverlayWrapper from 'acadly/common/TipOverlayWrapper';
import Toggle from 'acadly/common/Toggle';
import ToggleCell from 'acadly/common/ToggleCell';
import Tooltip from 'acadly/common/Tooltip';
import UploadButton from 'acadly/common/UploadButton';
import User from 'acadly/common/User';
import { Actions as CourseActions } from 'acadly/course/actions';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import { Actions as DiscussionActions } from 'acadly/discussion/actions';
import Discussion from 'acadly/discussion/Discussion';
import discussionService from 'acadly/discussion/service';
import icons from 'acadly/icons';
import { hideWhenArchived, visibleTo } from 'acadly/permissions';
import { PipContainerActions } from 'acadly/pip-container/actions';
import { Actions as PollActions } from 'acadly/poll/actions';
import Poll from 'acadly/poll/Poll';
import pollService from 'acadly/poll/service';
import { pusherService } from 'acadly/pusher';
import NewQuery from 'acadly/query/NewQuery';
import Query from 'acadly/query/Query';
import queryService from 'acadly/query/service';
import { Actions as QuizActions } from 'acadly/quiz/actions';
import Quiz from 'acadly/quiz/Quiz';
import quizService from 'acadly/quiz/service';
import { Actions as ResourceActions } from 'acadly/resource/actions';
import Resource from 'acadly/resource/Resource';
import resourceService from 'acadly/resource/service';
import Editor from 'acadly/rich-text/Editor';
import Viewer from 'acadly/rich-text/Viewer';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { backgroundColor, colors, margin, mb, ml, mr, mt, pad, pb, style } from 'acadly/styles';
import { upload } from 'acadly/upload';
import * as u from 'acadly/utils';
import IsMobile from 'acadly/utils/IsMobile';
import links from 'acadly/utils/links';

import ActivityWidget from './ActivityWidget';
import * as api from './api';

export const vCallPlaceholderSelector = (classId: string) => `#vcall-placeholder-${classId}`;

export default (props: IActivitiesProps) => h(Activities, props);

const MEDIA_PLAYER_SELECTOR = '#class-media-player';

interface IActivitiesProps {
  cls: IClass;
  courseRole: ICourseRole;
  course: ICourse;
  isIncharge: boolean;
  autoCloudRecord: 0 | 1;
  onAutoCloudRecordChange: (autoCloudRecord: 0 | 1) => any;
}

interface IActivitiesState {
  isFloatingMenuOpen: boolean;
  floatingMenuLevel: 'toBeDone' | 'preClass' | 'inClass';
  isEditVenueDialogOpen: boolean;
  venueField: string;
  isOnlineMeeting: 0 | 1;
  notifyField: 0 | 1; // notify students field when editing class
  multipleField: 0 | 1; // edit similar classes field when editing class,

  isEditTimingsDialogOpen: boolean;

  isCancelClassDialogVisible: boolean;
  isLectureSummaryDialogOpen: boolean;
  loadedSummary: api.ISummaryFetchResponse | null;
  isEditingSummaryDescription: boolean;
  editSummaryDescriptionDialogField: string;
  isEditingSummary: {
    description: string;
    attachments: IAttachment[];
    links: {
      linkId: string;
      title: string;
      url: string;
    }[];
  } | null;
  addLinkDialog: null | {
    url: string;
    urlError: boolean;
    title: string;
    titleError: boolean;
  };
  startTimeField: ITime;
  endTimeField: ITime;
  rescheduleDateField: Date;
  autoAnnouncementField: boolean;
  maintainSequenceField: boolean;
  isRescheduleConfirmationVisible: boolean;

  isEditScheduleDialogOpen: boolean;
  isEditingSchedule: boolean;

  titleField: string;
  isEditingTitle: boolean;

  clashes:
    | null
    | {
        date: Date;
        startTime: UnixTimestamp;
        endTime: UnixTimestamp;
        type: IClassType;
      }[];

  isPreClassAlertWarningOpen: boolean;

  isClassTeamRoleDialogOpen: boolean;
  editClassTeam: null | {
    selectedAssistantId: string | null;
    selectedInChargeId: string | null;
    changeForSimilarClasses: boolean;
  };

  changeSimilarClassDialog: null | {
    inCharge: boolean;
    assistant: boolean;
  };

  isSuggestedActivityPreviewOpen: boolean;
  suggestedActivity: ISuggestedActivity | null;

  isAllSuggestedActivitiesDialogOpen: boolean;
  isAllSuggestedActivitiesLoading: boolean;

  isWaitingForZoom: boolean;
  isRetryingForZoomStatus: boolean;
  isZoomRecordingsDialogVisible: boolean;
  isLoadingZoomRecordings: boolean;
  deleteZoomRecording: null | {
    recordingId: string;
    deleteAllRelated: boolean;
    fileNameToDelete: string;
  };

  isSaveAutoRecordDefaultsAlertVisible: boolean;
}

const courseRoleMap: { [role in ICourseRole]: string } = {
  admin: 'Course Admin',
  instructor: 'Course Instructor',
  ta: 'Course TA',
  student: '',
};

class Activities extends IComponent<IActivitiesProps, IActivitiesState> {
  private get isAccessible() {
    return getStore().getState().app.acc.web.turnOff === 0;
  }

  public componentWillMount() {
    const { cls } = this.getProps();
    const { scheStartTime: startTime, scheEndTime: endTime } = cls.details;

    const startTimeDate = dt.fromUnix(startTime);
    const endTimeDate = dt.fromUnix(endTime);

    const initialState: IActivitiesState = {
      isFloatingMenuOpen: false,
      isEditVenueDialogOpen: false,
      venueField: cls.details.venue,
      isOnlineMeeting: cls.details.isOnlineMeeting,
      multipleField: 0,
      notifyField: 1,
      isEditTimingsDialogOpen: false,
      floatingMenuLevel: 'toBeDone',
      isCancelClassDialogVisible: false,
      isLectureSummaryDialogOpen: false,
      loadedSummary: null,
      isEditingSummary: null,
      addLinkDialog: null,
      startTimeField: {
        hours: startTimeDate.getHours(),
        minutes: startTimeDate.getMinutes(),
      },
      endTimeField: {
        hours: endTimeDate.getHours(),
        minutes: endTimeDate.getMinutes(),
      },
      isEditingSummaryDescription: false,
      editSummaryDescriptionDialogField: '',
      rescheduleDateField: dt.startOfDay(dt.fromUnix(cls.details.scheStartTime)),
      autoAnnouncementField: true,
      maintainSequenceField: true,
      isRescheduleConfirmationVisible: false,

      isEditScheduleDialogOpen: false,
      isEditingSchedule: false,
      clashes: null,

      titleField: '',
      isEditingTitle: false,
      isPreClassAlertWarningOpen: false,

      isClassTeamRoleDialogOpen: false,
      editClassTeam: null,
      changeSimilarClassDialog: null,

      isSuggestedActivityPreviewOpen: false,
      suggestedActivity: null,

      isAllSuggestedActivitiesDialogOpen: false,
      isAllSuggestedActivitiesLoading: false,

      isWaitingForZoom: false,
      isRetryingForZoomStatus: false,
      isZoomRecordingsDialogVisible: false,
      isLoadingZoomRecordings: false,
      deleteZoomRecording: null,

      isSaveAutoRecordDefaultsAlertVisible: false,
    };

    this.setState(initialState);
    dispatch(appActions.startTip(true));
  }

  public componentDidMount() {
    const tabs = document.getElementById('sliding-tabs');

    if (tabs) {
      tabs.focus();
    }
  }

  private getOnlineDetails() {
    const classState = getStore().getState().class;
    return classState && classState.data && classState.data.onlineDetails;
  }

  private getAutoRecordDefaults() {
    const classState = getStore().getState().class;
    return classState && classState.data && classState.data.autoRecordDefaults;
  }

  private async authorizeZoomUser() {
    const onlineDetails = this.getOnlineDetails();
    if (!onlineDetails) return;

    let tab: Window | null = null;

    const subscription = pusherService.events.subscribe((e) => {
      const userId = getStore().getState().getIn.session!.userId;
      if (e.event === 'zoomAuthenticated' && userId === e.payload.userId) {
        subscription.unsubscribe();
        if (tab) {
          tab.close();
        }
      }
    });

    tab = window.open(onlineDetails.authUrl, '_blank');
  }

  private async createAndJoinOnlineMeeting() {
    const { cls, course, autoCloudRecord } = this.getProps();

    const onlineDetails = this.getOnlineDetails();
    const autoRecordDefaults = this.getAutoRecordDefaults();

    if (!onlineDetails || !autoRecordDefaults) return;

    this.setState({ isWaitingForZoom: false });

    if (!onlineDetails.meetingId) {
      if (
        !autoRecordDefaults.canCloudRecord ||
        autoRecordDefaults.autoCloudRecord === autoCloudRecord
      ) {
        await dispatch(
          Actions.createOnlineMeeting({
            autoCloudRecord,
            classId: cls._id,
            updateAutoCloudRecordDefault: 0,
          })
        );
      } else {
        // ask to save auto record as default
        this.setState({ isSaveAutoRecordDefaultsAlertVisible: true });
      }
    } else {
      // re-join meeting
      await dispatch(
        AppActions.initVCallFrame({
          courseId: course._id,
          classId: cls._id,
          role: classService.getRole(),
          joinId: onlineDetails.joinId,
          meetingId: onlineDetails.meetingId,
          meetingInProgress: onlineDetails.meetingInProgress,
          meetingPassword: onlineDetails.meetingPassword,
          beingBroadcast: 0,
        })
      );
    }
  }

  private getMeetingMessage() {
    const { isIncharge, cls } = this.getProps();
    const storeState = getStore().getState();
    const classData = storeState.class.data;
    const vCallFrame = storeState.app.vCallFrame;

    if (!classData || !cls.details.isOnlineMeeting) return '';

    const onlineDetails = classData.onlineDetails;
    const minutesLeft = dt.diff(dt.fromUnix(cls.details.scheStartTime), dt.now(), 'minutes');

    if (isIncharge) {
      if (cls.details.status === 'open' && minutesLeft > 15) {
        return [
          'You will be able to create a video meeting at least 15 minutes before ',
          'the scheduled start time',
        ];
      }

      if (cls.details.status === 'open' && vCallFrame.isVisible) {
        return 'Meeting is in progress...';
      }

      if (
        onlineDetails &&
        !onlineDetails.meetingId &&
        ((cls.details.status === 'open' && minutesLeft < 15) || cls.details.status === 'inSession')
      ) {
        return onlineDetails.isUserAuthenticated
          ? [`Click below to create Zoom video meeting`]
          : [
              'Please authorize Acadly to create video meeting, you will be redirected ',
              `to Zoom's login page for authorization. Meeting will be started `,
              'automatically after successful authorization.',
            ];
      }

      if (cls.details.status === 'inSession' && onlineDetails) {
        const isMeetingInProgress = onlineDetails.meetingInProgress === VCallStatus.IN_PROGESS;
        const isMeetingEnded = onlineDetails.meetingInProgress === VCallStatus.ENDED;
        const beingBroadcast = onlineDetails.beingBroadcast;

        if (onlineDetails.meetingId && isMeetingEnded && !beingBroadcast) {
          return 'Broadcast has been stopped on Acadly, end the meeting on Zoom.';
        }

        if (isMeetingInProgress && beingBroadcast) {
          return 'This meeting is being broadcast from another instance';
        }

        return 'Meeting is in progress...';
      }

      if (cls.details.status === 'closed') {
        return 'This class is over';
      }
    } else {
      // non class in-charge messages
      if (cls.details.status === 'open') {
        return 'Video meeting will be available once the broadcast begins';
      }

      if (cls.details.status === 'inSession' && !onlineDetails) {
        return 'Video meeting will be available once the broadcast begins';
      }

      if (cls.details.status === 'inSession' && onlineDetails) {
        switch (onlineDetails.meetingInProgress) {
          case VCallStatus.NOT_STARTED:
            return 'Video meeting will be available once the broadcast begins';
          case VCallStatus.IN_PROGESS:
            return 'Meeting is in progress...';
          case VCallStatus.ENDED:
            return 'Meeting has been ended by class in-charge';
        }
      }

      if (cls.details.status === 'closed') {
        return 'The video meeting for this class is over';
      }
    }

    return '';
  }

  private canCreateOnlineMeeting() {
    const { isIncharge, cls } = this.getProps();
    const storeState = getStore().getState();
    const classData = storeState.class.data;
    const vCallFrame = storeState.app.vCallFrame;

    if (!isIncharge || !cls.details.isOnlineMeeting) return false;
    if (!classData || !classData.onlineDetails) return false;
    if (vCallFrame.isVisible) return false;

    const onlineDetails = classData.onlineDetails;

    if (onlineDetails.beingBroadcast) return false;
    if (!onlineDetails.meetingId && cls.details.status === 'inSession') return true;

    const minutesLeft = dt.diff(dt.fromUnix(cls.details.scheStartTime), dt.now(), 'minutes');

    return cls.details.status === 'open' && minutesLeft <= 15;
  }

  private waitForZoomMeetingStatus(classId: string, meetingId: ZoomId) {
    const { isWaitingForZoom } = this.getState();

    if (isWaitingForZoom) return;

    this.setState({
      isWaitingForZoom: true,
      isRetryingForZoomStatus: true,
    });

    let retries = 0;
    let timer: NodeJS.Timer | undefined = undefined;

    const stopPolling = () => {
      if (timer) {
        clearInterval(timer);
      }

      timer = undefined;
      this.setState({ isRetryingForZoomStatus: false });
    };

    timer = setInterval(() => {
      /**
       * Fallback logic if zoom webhooks doesn't work.
       * poll every 15 seconds to check zoom meeting status
       */
      if (retries >= 10) {
        // retry only 10 times
        stopPolling();
        return;
      }
      retries++;
      /**
       * Don't wait for server response. Server will send a pusher
       * event "readyToBroadcast" when zoom meeting is ready
       */
      api.zoomMeetingStatus(classId, meetingId);
    }, 15000);

    const subscription = pusherService.events.subscribe((e) => {
      if (e.event === 'readyToBroadcast') {
        subscription.unsubscribe();
        // stop polling for zoom meeting status
        stopPolling();
      }
    });
  }

  public onlineMeetingDetails() {
    const { cls, course, isIncharge, courseRole, autoCloudRecord, onAutoCloudRecordChange } =
      this.getProps();
    const { isWaitingForZoom, isRetryingForZoomStatus } = this.getState();

    const storeState = getStore().getState();
    const classData = storeState.class.data;

    if (
      !course.canStream ||
      !cls.details.isOnlineMeeting ||
      !classData ||
      (cls.details.status !== 'open' && cls.details.status !== 'inSession')
    ) {
      return null;
    }

    const onlineDetails = classData.onlineDetails;
    const autoRecordDefaults = classData.autoRecordDefaults;
    const vCallFrame = storeState.app.vCallFrame;

    const Wrapper = (title: string, body: View[]) => {
      return h(`div${vCallPlaceholderSelector(cls._id)}.iframe-placeholder`, [
        h('div.iframe-placeholder__heading', title),
        ...body,
      ]);
    };

    if (
      !isIncharge &&
      !vCallFrame.isVisible &&
      onlineDetails &&
      onlineDetails.meetingInProgress === VCallStatus.IN_PROGESS &&
      ((courseRole === 'student' && Class.isCheckedIn(cls)) || courseRole !== 'student')
    ) {
      return Wrapper('You are not in the online meeting', [
        h('p.iframe-placeholder__content', style(['textCenter']), [
          'An online meeting is in progress. Please click on ',
          h('br'),
          'the button below to join',
        ]),
        BasicButton('JOIN MEETING', {
          classNames: ['iframe-placeholder__action'],
          onclick: () => {
            dispatch(
              AppActions.initVCallFrame({
                courseId: course._id,
                classId: cls._id,
                role: classService.getRole(),
                joinId: onlineDetails.joinId,
                meetingId: onlineDetails.meetingId,
                meetingInProgress: onlineDetails.meetingInProgress,
                meetingPassword: onlineDetails.meetingPassword,
                beingBroadcast: onlineDetails.beingBroadcast,
              })
            );
            dispatch(AppActions.toggleAutoJoinVCall(true));
          },
        }),
      ]);
    }

    if (
      isIncharge &&
      onlineDetails &&
      onlineDetails.meetingId &&
      !onlineDetails.beingBroadcast &&
      onlineDetails.meetingInProgress === VCallStatus.NOT_STARTED
    ) {
      const protocol = IsMobile.Android() || IsMobile.iOS() ? 'zoomus' : 'zoommtg';
      const startUrl = `${protocol}://zoom.us/join?confno=${onlineDetails.meetingId}&pwd=${onlineDetails.meetingPassword}`;

      if (isWaitingForZoom) {
        return Wrapper('Waiting for Zoom', [
          h(
            'p.iframe-placeholder__content',
            isRetryingForZoomStatus
              ? [
                  'Checking if meeting started on your Zoom app. It should happen ',
                  'within seconds but may take as many as 2 minutes to detect',
                ]
              : [
                  'We were unable to detect if the Zoom meeting has started. ',
                  'If it hasn’t yet, you can click on Retry Launch or look at the ',
                  h('a', { href: 'https://help.acadly.com' }, 'troubleshooting guide'),
                ]
          ),
          h(
            'a.iframe-placeholder__action',
            {
              href: startUrl,
              className: isRetryingForZoomStatus ? 'disabled' : undefined,
              onclick: () =>
                this.waitForZoomMeetingStatus(classData.classId, onlineDetails.meetingId),
            },
            'RETRY LAUNCH'
          ),
        ]);
      }

      return Wrapper('Start Zoom meeting as host', [
        h('p.iframe-placeholder__content', [
          'To start the meeting, please click on the button below to launch ',
          'the Zoom app and join the meeting as the host. Once the meeting is ',
          'started, you will be able to broadcast',
        ]),
        h(
          'a.iframe-placeholder__action',
          {
            href: startUrl,
            onclick: () =>
              this.waitForZoomMeetingStatus(classData.classId, onlineDetails.meetingId),
          },
          'LAUNCH MEETING VIA ZOOM'
        ),
      ]);
    }

    if (
      isIncharge &&
      onlineDetails &&
      onlineDetails.meetingId &&
      !onlineDetails.beingBroadcast &&
      onlineDetails.meetingInProgress === VCallStatus.ENDED
    ) {
      return Wrapper('Broadcast stopped', [
        h('p.iframe-placeholder__content', [
          'The students now cannot access the meeting using Acadly. However, ',
          'the meeting is still running in the Zoom app. You can either restart ',
          'the broadcast by clicking the button below, or end the meeting using ',
          'the Zoom app',
        ]),
        BasicButton('REJOIN CREATED MEETING', {
          classNames: ['iframe-placeholder__action'],
          onclick: () => this.createAndJoinOnlineMeeting(),
        }),
      ]);
    }

    if (this.canCreateOnlineMeeting() && !onlineDetails!.isUserAuthenticated) {
      return Wrapper('Authorize Acadly to use Zoom', [
        h('p.iframe-placeholder__content', [
          'To create Zoom meetings on your behalf, Acadly needs limited access ',
          'to your Zoom account. Please click on the button below to authorize ',
          "Acadly's access to your Zoom account",
        ]),
        h('img', {
          className: 'iframe-placeholder__add-button',
          src: 'https://s3.amazonaws.com/static.acad.ly/img/add_to_zoom.png',
          onclick: () => this.authorizeZoomUser(),
        }),
      ]);
    }

    if (this.canCreateOnlineMeeting()) {
      return Wrapper('Create a new Zoom meeting', [
        h('p.iframe-placeholder__content', [
          'To start broadcasting your video to students enrolled in Acadly, ',
          'you must first create a new Zoom meeting for this class by clicking ',
          'on the button below',
        ]),
        autoRecordDefaults && autoRecordDefaults.canCloudRecord
          ? h('div.iframe-placeholder__content.cell.cell--full-width', [
              h(
                'span.fw-bold',
                { style: { marginRight: '0.5rem' } },
                'Auto start meeting recording'
              ),
              Toggle({
                selected: !!autoCloudRecord,
                ontoggle: () => {
                  onAutoCloudRecordChange(autoCloudRecord ? 0 : 1);
                },
              }),
            ])
          : null,
        autoRecordDefaults && autoRecordDefaults.canCloudRecord
          ? h(
              'div.iframe-placeholder__content.fc-orange',
              autoCloudRecord
                ? [
                    h('p', [
                      'For Cloud Auto Recording to work, you need to turn on Automatic ',
                      'Recording in Zoom. Please follow the directions ',
                      h(
                        'a',
                        {
                          target: '_blank',
                          // tslint:disable-next-line
                          href: 'https://support.zoom.us/hc/en-us/articles/202921119-Automatic-Recording',
                        },
                        'here'
                      ),
                    ]),
                    h('p', [
                      'Meeting Auto Recording will begin as soon as you launch Zoom, ',
                      'even if you’re not broadcasting on Acadly',
                    ]),
                  ]
                : undefined
            )
          : null,
        BasicButton('CREATE ZOOM MEETING', {
          classNames: ['iframe-placeholder__action'],
          onclick: () => this.createAndJoinOnlineMeeting(),
        }),
        this.saveAutoRecordDefaultsAlert(),
      ]);
    }

    return h(`div${vCallPlaceholderSelector(cls._id)}.iframe-placeholder.center`, [
      h('p.iframe-placeholder__content', this.getMeetingMessage()),
    ]);
  }

  private saveAutoRecordDefaultsAlert() {
    const { cls, autoCloudRecord } = this.getProps();
    const { isSaveAutoRecordDefaultsAlertVisible } = this.getState();

    const handleResponse = (save: boolean) => () => {
      dispatch(
        Actions.createOnlineMeeting({
          autoCloudRecord,
          classId: cls._id,
          updateAutoCloudRecordDefault: save ? 1 : 0,
        })
      );
      this.setState({ isSaveAutoRecordDefaultsAlertVisible: false });
    };

    return Alert(
      {
        open: isSaveAutoRecordDefaultsAlertVisible,
        title: h('div.fc-orange', 'Save as default'),
        style: {
          width: '25rem',
        },
        actions: [
          FlatButton('No', {
            type: 'primary',
            onclick: handleResponse(false),
          }),
          FlatButton('Yes', {
            type: 'secondary',
            onclick: handleResponse(true),
          }),
        ],
      },
      [
        h('div', 'Would you like save this setting'),
        h('div.cell.cell--full-width', [
          h('span.fw-bold', 'Auto start meeting recording'),
          Icon(autoCloudRecord ? icons.done : icons.cross, {
            ariaLabel: autoCloudRecord ? 'is enabled' : 'is disabled',
            className: `${autoCloudRecord ? 'fc-green' : 'fc-orange'}`,
          }),
        ]),
      ]
    );
  }

  private zoomRecordings() {
    const storeState = getStore().getState();
    const classData = storeState.class.data;

    const { classMediaPlayer } = storeState.pipContainers;

    const { isIncharge, cls } = this.getProps();
    const isPlayingMedia = classMediaPlayer.show && classMediaPlayer.classId === cls._id;

    if (!classData || !classData.recordings) return null;

    const isAvailable = !!classData.recordings.recordingsAvailable;
    const isUnseen = !isIncharge && classData.recordings.isNew;
    const inProgress = classData.recordings.status === 'inProgress';
    const isUnpublished = classData.recordings.status === 'done' && !classData.recordings.published;

    if (!isIncharge && !classData.recordings.published) return null;

    let title: View | View[] = 'Meeting Recordings';

    if (isPlayingMedia) {
      title = [h('span.tag.green', 'Now playing'), classMediaPlayer.title];
    } else if (!isAvailable) {
      title = 'Meeting recordings not available';
    } else if (inProgress) {
      title = 'Processing meeting recordings';
    }

    return h(
      'div',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        h('div.class-activities__header', 'MEETING RECORDINGS'),
        h(
          'button.recordings-cell',
          {
            className: !isAvailable
              ? 'recordings-cell--is-read'
              : inProgress
              ? 'recordings-cell--in-progress'
              : isUnpublished
              ? 'recordings-cell--unpublished ripple'
              : isUnseen
              ? 'recordings-cell--is-new ripple'
              : 'recordings-cell--is-read ripple',
            onclick: async () => {
              if (!isAvailable || inProgress) return;

              this.lastFocusedElement = document.activeElement;
              u.unsetTabIndices();

              this.setState({
                isZoomRecordingsDialogVisible: true,
                isLoadingZoomRecordings: true,
              });

              await dispatch(Actions.getZoomRecordings(cls._id));

              this.setState({
                isLoadingZoomRecordings: false,
              });
            },
          },
          [
            isPlayingMedia
              ? SvgIcon({
                  noFill: true,
                  icon: NowPlayingIcon,
                  className: 'recordings-cell__icon',
                })
              : SvgIcon({
                  icon: RecordingIcon,
                  className: 'recordings-cell__icon',
                }),
            h('div.recordings-cell__title', title),
            isAvailable && isUnseen
              ? h('div.new-tag', [h('i.fa.fa-star', { 'aria-hidden': true }), h('span', 'NEW')])
              : null,
            isAvailable && isUnpublished ? h('div.tag.red', 'UNPUBLISHED') : null,
          ]
        ),
        h(`div${MEDIA_PLAYER_SELECTOR}`),
        this.zoomRecordingsDialog(),
      ]
    );
  }

  private closeZoomRecordingDialog() {
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
    this.setState({
      isZoomRecordingsDialogVisible: false,
    });
  }

  private stopMediaPlayer() {
    const { classMediaPlayer } = getStore().getState().pipContainers;

    if (classMediaPlayer.show) {
      dispatch(
        PipContainerActions.hideClassMediaPlayer({
          show: false,
        })
      );
    }
  }

  private startMediaPlayer(args: {
    type: ClassMediaPlayerType;
    recordingId: string;
    file: RecordingFile;
    mediaURL: string;
  }) {
    const { type, recordingId, file, mediaURL } = args;
    const { cls, course } = this.getProps();

    this.stopMediaPlayer();

    dispatch(
      PipContainerActions.showClassMediaPlayer({
        type,
        recordingId,
        classId: cls._id,
        url: mediaURL,
        fileName: file.name,
        courseId: course._id,
        title: file.displayName,
        embedTargetSelector: MEDIA_PLAYER_SELECTOR,
      })
    );

    this.closeZoomRecordingDialog();
  }

  private zoomRecordingsDialog() {
    const { cls, isIncharge, courseRole } = this.getProps();
    const { isZoomRecordingsDialogVisible, isLoadingZoomRecordings } = this.getState();

    const storeState = getStore().getState();
    const isMobile = storeState.app.isMobile;
    const classData = storeState.class.data;

    if (!classData || !classData.recordings) return null;

    const canPublish = isIncharge && !classData.recordings.published;

    const publishRecordings = async () => {
      if (!canPublish) return this.closeZoomRecordingDialog();
      await dispatch(Actions.publishZoomRecordings(cls._id));
      this.closeZoomRecordingDialog();
    };

    const RecordingCell = (recording: IZoomRecording, index: number) => {
      return h(
        'div.recording',
        {
          id: `recording-${recording.recordingId}`,
          key: `recording-${recording.recordingId}`,
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          h(
            'div.recording__header',
            `${u.toTitleCase(recording.meetingName)} - Recording ${index + 1}`
          ),
          ...recording.recordingFiles.map(({ type, name: fileName, displayName }, i) => {
            if (type === 'video' || type === 'audio') {
              const { classMediaPlayer } = getStore().getState().pipContainers;
              const isPlaying =
                classMediaPlayer.show &&
                classMediaPlayer.type === type &&
                classMediaPlayer.fileName === fileName &&
                classMediaPlayer.recordingId === recording.recordingId;

              return MediaCell({
                type,
                isPlaying,
                title: displayName,
                canDelete: isIncharge,
                canDownload: courseRole === 'admin' || courseRole === 'instructor',
                style: { margin: '0.25rem 0.75rem' },
                onDelete: () => {
                  this.setState({
                    deleteZoomRecording: {
                      recordingId: recording.recordingId,
                      fileNameToDelete: fileName,
                      deleteAllRelated: false,
                    },
                  });
                },
                onPlay: (mediaURL) => {
                  this.startMediaPlayer({
                    type,
                    mediaURL,
                    recordingId: recording.recordingId,
                    file: recording.recordingFiles[i],
                  });
                },
                onStop: () => {
                  this.stopMediaPlayer();
                },
                request: {
                  method: 'GET',
                  url: urls().zoomRecordingsDownload,
                  data: {
                    classId: cls._id,
                    filename: fileName,
                    recordingId: recording.recordingId,
                  },
                },
              });
            }

            return AttachmentViewer({
              key: i.toString(),
              icon: SvgIcon({
                icon: TranscriptIcon,
                className: 'recording__thumbnail',
              }),
              style: {
                width: 'calc(100% - 1.5rem)',
                margin: '0.25rem 0.75rem',
              },
              hideNameExtension: true,
              attachment: {
                extension: 'TS',
                name: displayName,
                originalName: displayName,
              },
              requestMethod: 'GET',
              downloadUrl: urls().zoomRecordingsDownload,
              downloadRequest: {
                classId: cls._id,
                filename: fileName,
                recordingId: recording.recordingId,
              },
              actionButton: isIncharge
                ? Icon(icons.cross, {
                    className: 'recording__delete-button ripple',
                    tabIndex: this.isAccessible ? 0 : undefined,
                    onclick: () => {
                      this.setState({
                        deleteZoomRecording: {
                          recordingId: recording.recordingId,
                          fileNameToDelete: fileName,
                          deleteAllRelated: false,
                        },
                      });
                    },
                  })
                : null,
            });
          }),
        ]
      );
    };

    return Dialog(
      {
        open: isZoomRecordingsDialogVisible,
        title: h('div', 'Meeting Recordings'),
        thinHeader: true,
        style: { backgroundColor },
        primaryAction:
          canPublish && !isMobile
            ? {
                label: 'PUBLISH',
                type: 'secondary',
                onclick: publishRecordings,
              }
            : isMobile
            ? { type: 'view', view: h('div') }
            : undefined,
        secondaryAction: {
          label: 'CLOSE',
          mobileLabel: h('i.fa.fa-arrow-left'),
          onclick: this.closeZoomRecordingDialog.bind(this),
        },
      },
      [
        isLoadingZoomRecordings
          ? h('div', style(['flex', 'alignCenter', 'justifyCenter', pad('1em')]), [Loader()])
          : h('div.meeting-recording-dialog', [
              canPublish && !isMobile
                ? h('p', style([pad('0 0.75rem')]), [
                    'Please publish the recordings to make them available ',
                    'for other course members',
                  ])
                : null,
              canPublish && isMobile
                ? h('div.publish-cell', [
                    h('div.publish-cell__title', [
                      'Please publish the recordings to make them available ',
                      'for other course members',
                    ]),
                    FlatButton('PUBLISH', {
                      type: 'secondary',
                      onclick: publishRecordings,
                    }),
                  ])
                : null,
              ...u
                .objectValues(classData.recordings.files)
                .sort((a, b) => a.createdAt - b.createdAt)
                .map(RecordingCell),
            ]),
        this.deleteZoomRecordingAlert(),
      ]
    );
  }

  private deleteZoomRecordingAlert() {
    const { cls, isIncharge } = this.getProps();
    const { deleteZoomRecording } = this.getState();

    const storeState = getStore().getState();
    const classData = storeState.class.data;

    if (!isIncharge || !classData || !classData.recordings || !deleteZoomRecording) {
      return null;
    }

    const recording = classData.recordings.files[deleteZoomRecording.recordingId];
    if (!recording) return null;

    const toggleCheckBox = () => {
      this.setState({
        deleteZoomRecording: {
          ...deleteZoomRecording,
          deleteAllRelated: !deleteZoomRecording.deleteAllRelated,
        },
      });
    };

    const closeAlert = (closeParentDialog: boolean) => {
      this.setState({ deleteZoomRecording: null });

      u.resetTabIndices();
      const recordingCell = document.getElementById(`recording-${recording.recordingId}`);

      if (recordingCell) {
        recordingCell.focus();
      } else if (closeParentDialog) {
        this.closeZoomRecordingDialog();
      }
    };

    return Alert(
      {
        open: true,
        title: h('div.delete-recording__title', 'Delete recording file?'),
        style: {
          width: '25rem',
        },
        actions: [
          FlatButton('Cancel', {
            type: 'primary',
            onclick: () => closeAlert(false),
          }),
          FlatButton('DELETE', {
            type: 'secondary',
            onclick: async () => {
              const fileNamesToDelete = deleteZoomRecording.deleteAllRelated
                ? recording.recordingFiles.map((f) => f.name)
                : [deleteZoomRecording.fileNameToDelete];

              const hasMoreRecordings = await dispatch(
                Actions.deleteZoomRecording({
                  classId: cls._id,
                  recordingId: recording.recordingId,
                  fileNamesToDelete,
                })
              );

              closeAlert(!hasMoreRecordings);
            },
          }),
        ],
      },
      [
        h('div', 'Are you sure you want to delete this recording file?'),
        h(
          'div.delete-recording__option',
          {
            onclick: toggleCheckBox,
          },
          [
            CheckBox({
              selected: deleteZoomRecording.deleteAllRelated,
              className: 'delete-recording__checkbox',
            }),
            h('span', 'Delete all files related to this recording'),
          ]
        ),
      ]
    );
  }

  public render() {
    const { cls, courseRole, course } = this.getProps();

    const storeState = getStore().getState();
    const classData = storeState.class.data;
    const isClassIncharge = this.getProps().isIncharge;

    return h('div.activity__wrapper', [
      ContentView(
        h(
          'div',
          {
            className: cn('class-activities', 'activity', {
              invisible: !Routes.classActivities.isActive(true),
            }),
          },
          [
            this.floatingMenu(),
            this.titleView(),
            this.scheduleSection(),
            this.classTeam(),
            this.suggestedActivities(),
            this.preClassActivities(),
            this.onlineMeetingDetails(),
            this.zoomRecordings(),
            this.inClassActivities(),
            this.reviewSection(),
            this.postClassQueries(),
          ]
        )
      ),
      Routes.classActivities.isActive(true)
        ? h('div', [
            this.editVenueDialog(),
            this.editTimingsDialog(),
            this.editScheduleDialog(),
            this.isEditingLectureScheduleDialog(),
            this.cancelClassDialog(),
            this.clashDialog(),
            this.preClassWarningAlert(),
          ])
        : h(
            'div.class-sub-view',
            style([
              {
                position: 'absolute',
                height: '100%',
                top: 0,
                bottom: 0,
                backgroundColor,
                width: '100%',
              },
            ]),
            [
              classData
                ? Switch(
                    [
                      Route(Routes.classQuiz, ({ params }) => {
                        const quizId = quizService.getQuizIdFromShortId(params.quizShortId);
                        const quiz = storeState.quizzes.byId[quizId as any];
                        if (!quiz) {
                          Routes.classActivities.navigate({
                            classShortId: params.classShortId,
                            courseShortId: params.courseShortId,
                            univSlug: params.univSlug,
                          });
                          dispatch(
                            AppActions.showError({
                              message:
                                'Quiz was not found. It has either been moved, ' +
                                'deleted or not published yet.',
                            })
                          );
                          return null;
                        } else {
                          return Quiz({
                            class: cls,
                            courseRole,
                            quiz,
                            course,
                            isClassIncharge,
                          });
                        }
                      }),
                      Route(Routes.classPoll, ({ params }) => {
                        const pollId = pollService.getPollIdFromShortId(params.pollShortId);
                        const poll = storeState.polls.byId[pollId as any];
                        if (!poll) {
                          Routes.classActivities.navigate({
                            classShortId: params.classShortId,
                            courseShortId: params.courseShortId,
                            univSlug: params.univSlug,
                          });
                          dispatch(
                            AppActions.showError({
                              message:
                                'Poll was not found. It has either been moved, ' +
                                'deleted or not published yet.',
                            })
                          );
                          return null;
                        } else {
                          return Poll({
                            class: cls,
                            courseRole,
                            poll,
                            course,
                            isClassIncharge,
                          });
                        }
                      }),
                      Route(Routes.classDiscussion, ({ params }) => {
                        const discussionId = discussionService.getDiscussionIdFromShortId(
                          params.discussionShortId
                        );
                        const discussion = storeState.discussions.byId[discussionId as any];
                        if (!discussion) {
                          Routes.classActivities.navigate({
                            classShortId: params.classShortId,
                            courseShortId: params.courseShortId,
                            univSlug: params.univSlug,
                          });
                          dispatch(
                            AppActions.showError({
                              message:
                                'Discussion was not found. It has either been moved,' +
                                ' deleted or not published yet.',
                            })
                          );
                          return null;
                        } else {
                          return Discussion({
                            cls,
                            courseRole,
                            isClassIncharge,
                            discussion,
                            course,
                            userId: getStore().getState().getIn.session!.userId,
                          });
                        }
                      }),
                      Route(Routes.classResource, ({ params }) => {
                        const resourceId = resourceService.getResourceIdFromShortId(
                          params.resourceShortId
                        );
                        const resource = storeState.resources.byId[resourceId as any];
                        if (!resource) {
                          Routes.classActivities.navigate({
                            classShortId: params.classShortId,
                            courseShortId: params.courseShortId,
                            univSlug: params.univSlug,
                          });
                          dispatch(
                            AppActions.showError({
                              message:
                                'Resource was not found. It has either been moved,' +
                                ' deleted or not published yet.',
                            })
                          );
                          return null;
                        } else {
                          return Resource({
                            cls,
                            courseRole,
                            isClassIncharge,
                            resource,
                            course,
                            userId: getStore().getState().getIn.session!.userId,
                          });
                        }
                      }),
                      Route(Routes.newQuery, ({ params }) =>
                        NewQuery({
                          open: true,
                          cls: cls,
                          classId: classService.getClassIdFromShortId(params.classShortId) || '',
                          isAnon: params.isAnon === 'true',
                          courseId: courseService.getCourseIdFromShortId(params.courseShortId),
                        })
                      ),
                      Route(Routes.classQuery, ({ params }) => {
                        const queryId = queryService.getQueryIdFromShortId(params.queryShortId);
                        const query = storeState.queries.byId[queryId as any];
                        if (!query) {
                          Routes.classActivities.navigate({
                            classShortId: params.classShortId,
                            courseShortId: params.courseShortId,
                            univSlug: params.univSlug,
                          });
                          dispatch(
                            AppActions.showError({
                              message:
                                'Query was not found. It has either been moved,' +
                                ' deleted or not published yet.',
                            })
                          );
                          return null;
                        } else {
                          return Query({
                            query,
                            cls,
                            isArchived: course.isArchived === 1,
                            courseRole,
                            userId: getStore().getState().getIn.session!.userId,
                          });
                        }
                      }),
                    ],
                    {
                      defaultCallback: () => {
                        Routes.classActivities.navigate({
                          univSlug: appService.getUniversitySlug(),
                          courseShortId: courseService.getShortIdFromCourseId(course._id),
                          classShortId: classService.getShortIdFromClassId(cls._id),
                        });
                      },
                    }
                  )
                : fullScreenLoader,
            ]
          ),
    ]);
  }

  private isUpdatedTagVisible() {
    const cls = this.getProps().cls;
    const courseRole = this.getProps().courseRole;
    const summaryUpdatedOn = cls.summaryUpdatedOn;
    const summaryAccessedOn = cls.userData.summaryAccessedOn;
    if (courseRole !== 'student') {
      return false;
    }
    if (!summaryUpdatedOn) {
      return false;
    }
    if (!summaryAccessedOn) {
      return true;
    }
    if (summaryUpdatedOn > summaryAccessedOn) {
      return true;
    } else {
      return false;
    }
  }

  private updatedTag() {
    return h(
      'div.tag-container',
      style([ml('0.8em')], {
        height: '80%',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        backgroundColor: colors.orange,
        textAlign: 'center',
        fontWeight: '900',
        borderRadius: '4px',
      }),
      [
        h(
          'div.tag-text',
          style([
            'white',
            'x-small',
            'uppercase',
            {
              lineHeight: '1.5em',
            },
          ]),
          'Updated'
        ),
      ]
    );
  }

  private lectureSummary() {
    const props = this.getProps();
    let isClassSummaryClickable: boolean;

    if (props.cls.details.status === 'open' || props.cls.details.status === 'inSession') {
      isClassSummaryClickable = false;
    } else if (props.cls.details.status === 'closed') {
      isClassSummaryClickable = true;
    } else {
      isClassSummaryClickable = props.cls.details.scheEndTime < dt.unix();
    }

    return h(
      'div',
      {
        className: cn('class-activities__summary', {
          ripple: isClassSummaryClickable,
          pointer: isClassSummaryClickable,
        }),
        tabIndex: isClassSummaryClickable && this.isAccessible ? 0 : undefined,
        'aria-label': isClassSummaryClickable ? 'Summary button' : undefined,
        role: isClassSummaryClickable ? 'button' : undefined,
        onclick: isClassSummaryClickable ? () => this.loadClassSummary() : undefined,
      },
      [
        Icon(icons.copy, { className: 'fc-blue' }),
        h('span.class-activities__summary__icon', 'Summary'),
        isClassSummaryClickable ? this.classSummaryDialog() : null,
        this.isUpdatedTagVisible() ? this.updatedTag() : null,
      ]
    );
  }

  private editScheduleDialog() {
    const state = this.getState();
    if (!this.isClassEditable()) return null;
    return Alert(
      {
        open: state.isEditScheduleDialogOpen,
        title: 'Edit class schedule',
        style: {
          width: '25rem',
        },
        center: true,
      },
      [
        h(
          'div',
          { tabIndex: this.isAccessible ? 0 : undefined },
          'You can either change the class date or cancel the ' + 'class.'
        ),
        h('div', 'Please select an option'),

        h(
          'div',
          style(
            ['small', 'orange', 'fullWidth', 'textCenter', mt('1rem'), mb('1rem'), {}],
            {},
            { tabIndex: this.isAccessible ? 0 : undefined }
          ),
          [
            "In case you need to change the class' start and " +
              'and times and not the date, please go back and edit ' +
              'the timings.',
          ]
        ),
        h('div.actions', [
          FlatButton('Change the class date', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.openEditScheduleDialog(),
          }),
          FlatButton('Cancel the class', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.lastFocusedElementSpare = document.activeElement;
              u.unsetTabIndices();
              this.setState({
                isCancelClassDialogVisible: true,
              });
            },
          }),
          FlatButton('Go back', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              this.setState({
                isEditScheduleDialogOpen: false,
              });
            },
          }),
        ]),
      ]
    );
  }

  private editTitleDialog() {
    const { isEditingTitle, titleField } = this.getState();
    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Class Title',
        open: isEditingTitle,
        hasInput: true,
        actions: [
          FlatButton('Discard', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                isEditingTitle: false,
              }).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              }),
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.saveTitleHandler().then(() => {
                u.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              }),
          }),
        ],
      },
      [
        h('div.textfield-container', style(['flex', 'alignCenter', pad('0.5rem')]), [
          TextField({
            value: titleField,
            focusOnMount: true,
            placeholder: 'Enter title here',
            maxLength: 140,
            oninput: (e) =>
              this.setState({
                titleField: e.target.value,
              }),
            onenter: () => this.saveTitleHandler(),
          }),
        ]),
      ]
    );
  }

  private isTitleEditable() {
    const { isIncharge, course } = this.getProps();
    return isIncharge && !course.isArchived;
  }

  private titleView() {
    const { cls } = this.getProps();

    const NO_TITLE_MESSAGE = h('span.fc-light-grey', [
      "This class doesn't have a title yet. ",
      'Click on the ',
      h('i.fa.fa-pencil'),
      ' icon to add a title.',
    ]);

    if (this.isTitleEditable()) {
      return h(
        'div.panel',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          h('div.panel__header', [
            h('span', 'Title'),
            h('i.fa.fa-pencil', {
              alt: 'Edit Icon',
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => {
                this.lastFocusedElement = document.activeElement;
                u.unsetTabIndices();
                this.setState({
                  isEditingTitle: true,
                  titleField: cls.details.title || '',
                });
              },
            }),
          ]),
          this.editTitleDialog(),
          h('div.panel__content.fs-sm', [cls.details.title || NO_TITLE_MESSAGE]),
        ]
      );
    } else {
      return cls.details.title
        ? h('div.activity__title', { key: 'class-title' }, cls.details.title)
        : null;
    }
  }

  private async saveTitleHandler() {
    const { cls } = this.getProps();
    const { titleField } = this.getState();

    await dispatch(
      Actions.titleEdit({
        classId: cls._id,
        title: titleField,
      })
    );
    await this.setState({
      isEditingTitle: false,
    });
  }

  private async openEditScheduleDialog() {
    const props = this.getProps();
    const cls = props.cls;
    const startTime = dt.fromUnix(cls.details.scheStartTime);
    const endTime = dt.fromUnix(cls.details.scheEndTime);
    await this.setState({
      isEditScheduleDialogOpen: false,
      isEditingSchedule: true,
      venueField: cls.details.venue,
      rescheduleDateField: dt.startOfDay(startTime),
      startTimeField: {
        hours: startTime.getHours(),
        minutes: startTime.getMinutes(),
      },
      endTimeField: {
        hours: endTime.getHours(),
        minutes: endTime.getMinutes(),
      },
    });
  }

  private classModality() {
    const { course } = this.getProps();
    const { isOnlineMeeting } = this.getState();

    if (!course.canStream) return null;

    return h('div', [
      h('div.fc-grey', u.withTabIndex(style([pad('0.5rem 1rem')])), 'SELECT CLASSROOM MODALITY'),
      h(
        'div.ripple',
        style(
          ['flex', 'alignCenter', 'whiteBackground', pad('1rem'), mb('1px')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.setState({ isOnlineMeeting: 1 });
            },
          }
        ),
        [
          RadioButton({
            selected: isOnlineMeeting === 1,
            style: { marginRight: '0.5rem' },
          }),
          h('span', 'Online on Acadly (with Zoom plugin)'),
        ]
      ),
      h(
        'div.ripple',
        style(
          ['flex', 'alignCenter', 'whiteBackground', pad('1rem'), mb('1px')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.setState({ isOnlineMeeting: 0 });
            },
          }
        ),
        [
          RadioButton({
            selected: isOnlineMeeting === 0,
            style: { marginRight: '0.5rem' },
          }),
          h('span', 'In-person (or on another online platform)'),
        ]
      ),
    ]);
  }

  private isEditingLectureScheduleDialog() {
    if (!this.isClassEditable()) return null;
    const state = this.getState();
    const props = this.getProps();
    const cls = props.cls;

    const rowAttrs: any = style(
      ['whiteBackground', pad('1rem'), 'flex', 'alignCenter', 'spaceBetween', 'lightBorder'],
      {},
      { tabIndex: this.isAccessible ? 0 : undefined }
    );

    const confirmationAlertRowAttrs = (ariaLabel?: string) =>
      style(
        [
          'flex',
          'spaceBetween',
          'alignCenter',
          {
            height: '3em',
          },
        ],
        {},
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': ariaLabel || '',
        }
      );

    const scheStartTime = dt.fromUnix(cls.details.scheStartTime);
    const scheEndTime = dt.fromUnix(cls.details.scheEndTime);
    if (!props.course.dates) return null;

    const inconvenientTimeWarning = h(
      'div.inconvenient-time-warning',
      style([
        pad('0.5rem 1rem'),
        'orange',
        'fullWidth',
        'borderBox',
        'small',
        {
          textAlign: 'right',
        },
      ]),
      [this.inconvenientTimeMessage()]
    );
    const startTimeGreaterError = h(
      'div.start-time-greater-error',
      style([
        pad('0.5rem 1rem'),
        'red',
        'fullWidth',
        'borderBox',
        'small',
        {
          textAlign: 'right',
        },
      ]),
      [this.startTimeGreaterThanEndTimeMessage()]
    );

    return Dialog(
      {
        open: state.isEditingSchedule,
        title: 'Reschedule class',
        style: {
          backgroundColor: colors.backgroundColor,
          fontSize: '1rem',
        },
        secondaryAction: {
          label: 'Cancel',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElement as HTMLElement).focus();
            this.setState({
              isEditingSchedule: false,
            });
          },
        },
        primaryAction: {
          label: 'SAVE',
          disabled:
            this.isStartTimeGreater() ||
            (!state.isOnlineMeeting && state.venueField.trim().length < 1),
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => {
            this.lastFocusedElementSpare = document.activeElement;
            u.unsetTabIndices(document.getElementById('dialog-box'));
            this.setState({
              isRescheduleConfirmationVisible: true,
            });
          },
        },
      },
      [
        h('div', style(['grey', 'small', pad('0.5rem')]), [
          `RESCHEDULING CLASS FROM ` +
            dt.format(cls.details.scheStartTime, 'ddd, MMM DD, YYYY').toUpperCase(),
        ]),
        h('div', rowAttrs, [
          h('span', 'New date'),
          DatePicker({
            minDate: dt.now(),
            maxDate: dt.fromUnix(props.course.dates.endDate),
            value: state.rescheduleDateField,
            onclick: () => {
              this.lastFocusedElementSpare = document.activeElement;
            },
            onchange: (date) =>
              this.setState({
                rescheduleDateField: date,
              }).then(() => {
                (this.lastFocusedElementSpare as HTMLElement).focus();
              }),
            style: style(['blue']).style,
          }),
        ]),

        h('div', rowAttrs, [
          h('span', 'From'),
          TimePicker({
            time: state.startTimeField,
            onclick: () => {
              this.lastFocusedElementSpare = document.activeElement;
            },
            onclose: () => {
              (this.lastFocusedElementSpare as HTMLElement).focus();
            },
            onChange: (time) =>
              this.setState({
                startTimeField: time,
              }).then(() => {
                (this.lastFocusedElementSpare as HTMLElement).focus();
              }),
            style: style(['blue']).style,
          }),
        ]),
        this.isStartTimeInconvenient() ? inconvenientTimeWarning : null,

        h('div', rowAttrs, [
          h('span', 'Till'),
          TimePicker({
            time: state.endTimeField,
            onclick: () => {
              this.lastFocusedElementSpare = document.activeElement;
            },
            onclose: () => {
              (this.lastFocusedElementSpare as HTMLElement).focus();
            },
            onChange: (time) =>
              this.setState({
                endTimeField: time,
              }).then(() => {
                (this.lastFocusedElementSpare as HTMLElement).focus();
              }),
            style: style(['blue']).style,
          }),
        ]),
        this.isStartTimeGreater()
          ? startTimeGreaterError
          : this.isEndTimeInconvenient()
          ? inconvenientTimeWarning
          : null,

        this.classModality(),
        !state.isOnlineMeeting
          ? h('div', rowAttrs, [
              h('span', 'At (venue/link)'),
              TextField({
                maxLength: 140,
                style: {
                  fontSize: '1rem',
                  maxWidth: '10em',
                },
                inputStyle: {
                  fontSize: '1rem',
                  textAlign: 'right',
                },
                placeholderStyle: {
                  fontSize: '1rem',
                },
                placeholder: 'Venue/link',
                value: state.venueField,
                noHintOrError: true,
                oninput: (e) =>
                  this.setState({
                    venueField: e.target.value,
                  }),
                onenter: () =>
                  this.setState({
                    isRescheduleConfirmationVisible: true,
                  }),
              }),
            ])
          : null,
        ToggleCell({
          style: { marginTop: '1.5rem' },
          label: 'Create automatic announcement',
          isSelected: state.autoAnnouncementField,
          onToggle: () =>
            this.setState({
              autoAnnouncementField: !state.autoAnnouncementField,
            }),
          helperText: state.autoAnnouncementField
            ? 'An announcement will be created on the course timeline.'
            : 'Toggle if you want to create an automatic announcement ' +
              'that will be visible on the course timeline.',
        }),

        h(
          'div',
          style(
            ['grey', 'small', pad('0.5rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          ['CLASS ACTIVITIES']
        ),
        ToggleCell({
          label: 'Maintain activity sequence',
          isSelected: state.maintainSequenceField,
          onToggle: () =>
            this.setState({
              maintainSequenceField: !state.maintainSequenceField,
            }),
          helperText:
            'Shifts all the activities attached to your classes falling ' +
            'between the new date and the old date, by one class to ensure ' +
            'the chronological sequence of teaching is not ' +
            'disrupted.',
        }),

        Alert(
          {
            open: state.isRescheduleConfirmationVisible,
            title: 'Confirm changes',
            actions: [
              FlatButton('Cancel', {
                tabIndex: this.isAccessible ? 0 : undefined,
                type: 'secondary',
                onclick: () => {
                  (this.lastFocusedElementSpare as HTMLElement).focus();
                  this.setState({
                    isRescheduleConfirmationVisible: false,
                  });
                },
              }),
              FlatButton('Save Changes', {
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () => this.rescheduleLecture(),
              }),
            ],
          },
          [
            h(
              'div',
              confirmationAlertRowAttrs(`Old Schedule is
                from ${dt.format(scheStartTime, 'hh:mm A')}
                to ${dt.format(scheEndTime, 'hh:mm A')}
                on ${dt.format(scheStartTime, 'ddd, MMM DD, YYYY')}`),
              [
                h('span', 'Old schedule'),
                h(
                  'span',
                  {
                    style: { textAlign: 'right' },
                  },
                  [
                    h('div', dt.format(scheStartTime, 'ddd, MMM DD, YYYY')),
                    h('div', style(['small', 'grey']), [
                      dt.format(scheStartTime, 'hh:mm A') +
                        ' - ' +
                        dt.format(scheEndTime, 'hh:mm A'),
                    ]),
                  ]
                ),
              ]
            ),
            h(
              'div',
              confirmationAlertRowAttrs(`New Schedule is
                from ${u.showTime(state.startTimeField)}
                to ${u.showTime(state.endTimeField)}
                on ${dt.format(state.rescheduleDateField, 'ddd, MMM DD, YYYY')}`),
              [
                h('span', 'New schedule'),
                h(
                  'span',
                  {
                    style: { textAlign: 'right' },
                  },
                  [
                    h('div', dt.format(state.rescheduleDateField, 'ddd, MMM DD, YYYY')),
                    h('div', style(['small', 'grey']), [
                      u.showTime(state.startTimeField) + ' - ' + u.showTime(state.endTimeField),
                    ]),
                  ]
                ),
              ]
            ),

            state.isOnlineMeeting
              ? h('div', confirmationAlertRowAttrs(`Online on Acadly (with Zoom plugin)`), [
                  h('span', 'Online on Acadly (with Zoom plugin)'),
                  h('span', 'Yes'),
                ])
              : h('div', [
                  h('div', confirmationAlertRowAttrs(`In-person (or on another online platform)`), [
                    h('span', 'In-person (or on another online platform)'),
                    h('span', 'Yes'),
                  ]),
                  h('div', confirmationAlertRowAttrs(`At (venue/link): ${state.venueField}`), [
                    h('span', 'At (venue/link)'),
                    h('span', state.venueField),
                  ]),
                ]),

            h(
              'div',
              confirmationAlertRowAttrs(`auto announcement is
                ${state.autoAnnouncementField ? 'On' : 'Off'}`),
              [
                h('span', 'Auto announcement'),
                h('span', state.autoAnnouncementField ? 'On' : 'Off'),
              ]
            ),
            h(
              'div',
              confirmationAlertRowAttrs(`Activity sequence is to
                ${state.maintainSequenceField ? 'Maintain' : "Don't maintain"}`),
              [
                h('span', 'Activity sequence'),
                h('span', state.maintainSequenceField ? 'Maintain' : "Don't maintain"),
              ]
            ),
          ]
        ),
      ]
    );
  }

  private inconvenientTimeMessage() {
    return 'Warning: This class timing seems a bit inconvenient';
  }
  private startTimeGreaterThanEndTimeMessage() {
    return 'Error: The start time is greater than end time';
  }

  private isStartTimeInconvenient() {
    const state = this.getState();

    return this.isTimeInconvenient(state.startTimeField);
  }

  private isEndTimeInconvenient() {
    const state = this.getState();
    return this.isTimeInconvenient(state.endTimeField);
  }

  private isTimeInconvenient(time: ITime) {
    const lowThresholdMinutes = 8 * 60;
    const highThresholdMinutes = 20 * 60;
    const minutes = time.hours * 60 + time.minutes;
    return minutes < lowThresholdMinutes || minutes > highThresholdMinutes;
  }

  private isStartTimeGreater() {
    const state = this.getState();
    const startTimeMinutes = state.startTimeField.hours * 60 + state.startTimeField.minutes;
    const endTimeMinutes = state.endTimeField.hours * 60 + state.endTimeField.minutes;
    return startTimeMinutes >= endTimeMinutes;
  }

  private async rescheduleLecture() {
    const state = this.getState();
    const props = this.getProps();
    await dispatch(
      Actions.reschedule({
        classId: props.cls._id,
        isOnlineMeeting: state.isOnlineMeeting || 0,
        venue: state.isOnlineMeeting ? '' : state.venueField,
        announce: state.autoAnnouncementField ? 1 : 0,
        maintain: state.maintainSequenceField ? 1 : 0,
        newClassStartTime: dt.format(
          dt.addMinutes(
            dt.addHours(state.rescheduleDateField, state.startTimeField.hours),
            state.startTimeField.minutes
          ),
          'YYYYMMDDTHH:mm'
        ),
        newClassEndTime: dt.format(
          dt.addMinutes(
            dt.addHours(state.rescheduleDateField, state.endTimeField.hours),
            state.endTimeField.minutes
          ),
          'YYYYMMDDTHH:mm'
        ),

        // these fields are required but they're ignored by
        // the server
        remove: 0,
        mark: 'canceled',
        hasMakeUp: 1,
      })
    );
    await this.setState({
      isRescheduleConfirmationVisible: false,
      isEditingSchedule: false,
    });
    await this.closeScheduleDialogs();
    u.resetTabIndices();
    (this.lastFocusedElement as HTMLElement).focus();
  }

  private cancelClassDialog() {
    if (!this.isClassEditable()) return null;
    const state = this.getState();
    const hasActivities = this.getActivities().length > 0;
    return Alert(
      {
        open: state.isCancelClassDialogVisible,
        title: 'Canceling class',
        style: {
          width: '25rem',
        },
        center: true,
      },
      [
        !hasActivities
          ? h('div', { tabIndex: this.isAccessible ? 0 : undefined }, [
              'Since there are no published activities, you can also',
              ' choose to remove the class from the course timeline.',
            ])
          : h('div', { tabIndex: this.isAccessible ? 0 : undefined }, [
              'The published activities will be visible to the course team ',
              'members, but the class status will show "Canceled"',
            ]),
        h(
          'div',
          style(
            [mb('1rem'), mt('1rem')],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
            }
          ),
          ['Are you sure you want to cancel this class?']
        ),
        h('div.actions', [
          FlatButton('Yes, cancel the class', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.cancelLecture(false),
          }),
          !hasActivities
            ? FlatButton('Yes, cancel and remove from timeline', {
                tabIndex: this.isAccessible ? 0 : undefined,
                onclick: () => this.cancelLecture(true),
              })
            : null,
          FlatButton("No, don't cancel class", {
            style: style(['red']).style,
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              u.resetTabIndices(document.getElementById('alert-box'));
              (this.lastFocusedElementSpare as HTMLElement).focus();
              this.setState({
                isCancelClassDialogVisible: false,
              });
            },
          }),
        ]),
      ]
    );
  }

  private async cancelLecture(remove: boolean) {
    const props = this.getProps();
    await dispatch(
      Actions.classCancel(
        {
          classId: props.cls._id,
          mark: 'canceled',
          venue: props.cls.details.venue,
          announce: 1,
          maintain: 1,
          hasMakeUp: 0,
          remove: remove ? 1 : 0,
        },
        remove
          ? () =>
              Routes.courseTimeline.navigate({
                courseShortId: courseService.getShortIdFromCourseId(props.course._id),
                univSlug: appService.getUniversitySlug(),
              })
          : () => {}
      )
    );
    await this.closeScheduleDialogs();
    u.resetTabIndices();
  }

  private async closeScheduleDialogs() {
    await this.setState({
      isEditScheduleDialogOpen: false,
      isCancelClassDialogVisible: false,
      isEditingSchedule: false,
    });
  }

  private warning(text: string | View[]) {
    return h(
      'div.activity__note.with-padding',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [h('i.fa.fa-exclamation-triangle'), h('div', text)]
    );
  }

  private editTimingsDialog() {
    if (!this.isClassEditable()) return null;
    const state = this.getState();
    const props = this.getProps();
    const timePicker = (type: 'start' | 'end') => {
      return h(
        'div',
        style(
          ['whiteBackground', 'flex', 'spaceBetween', pad('0.5rem 1rem')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          }
        ),
        [
          h('span', `New ${type} time`),
          TimePicker({
            time: type === 'start' ? state.startTimeField : state.endTimeField,
            onclick: () => {
              this.lastFocusedElementSpare = document.activeElement;
              u.unsetTabIndices();
            },
            onclose: () => {
              u.resetTabIndices(document.getElementById('dialog-box'));
              (this.lastFocusedElementSpare as HTMLElement).focus();
            },
            onChange: async (time) => {
              if (type === 'start') {
                await this.setState({
                  startTimeField: time,
                }).then(() => {
                  u.resetTabIndices(document.getElementById('dialog-box'));
                  (this.lastFocusedElementSpare as HTMLElement).focus();
                });
              } else {
                await this.setState({
                  endTimeField: time,
                }).then(() => {
                  u.resetTabIndices(document.getElementById('dialog-box'));
                  (this.lastFocusedElementSpare as HTMLElement).focus();
                });
              }
            },
          }),
        ]
      );
    };

    const pickerFooterAttrs: any = style(
      ['grey', 'small', pad('0.5rem 1rem'), pb('1rem')],
      {},
      { tabIndex: this.isAccessible ? 0 : undefined }
    );

    const oldTime = (type: 'start' | 'end') =>
      h('div', pickerFooterAttrs, [
        `Old ${type} time: ` +
          dt.format(
            type === 'start' ? props.cls.details.scheStartTime : props.cls.details.scheEndTime,

            'hh:mm A'
          ),
      ]);
    const startTime = props.cls.details.scheStartTime;
    const endTime = props.cls.details.scheEndTime;
    const inconvenientTimeWarning = h(
      'div',
      style(['orange', 'small', pad('0.5rem 1rem'), pb('0em')]),
      this.inconvenientTimeMessage()
    );
    const startTimeGreaterError = h(
      'div',
      style(['red', 'small', pad('0.5rem 1rem'), pb('0em')]),
      this.startTimeGreaterThanEndTimeMessage()
    );

    return Dialog(
      {
        open: state.isEditTimingsDialogOpen,
        title: 'Class timings',
        style: {
          backgroundColor: colors.backgroundColor,
          fontSize: '1rem',
        },
        primaryAction: {
          label: 'SAVE',
          disabled: this.isStartTimeGreater(),
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.saveLectureTimings(),
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              isEditTimingsDialogOpen: false,
            }).then(() => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
            }),
        },
      },
      [
        this.warning([
          'This will NOT change the class date (',
          dt.format(props.cls.details.scheStartTime, 'MMM DD, YYYY'),
          '). To reschedule the class to another date, go back ',
          'and edit the class date.',
        ]),

        timePicker('start'),
        this.isStartTimeInconvenient() ? inconvenientTimeWarning : null,
        oldTime('start'),
        timePicker('end'),
        this.isStartTimeGreater()
          ? startTimeGreaterError
          : this.isEndTimeInconvenient()
          ? inconvenientTimeWarning
          : null,
        oldTime('end'),

        this.similarLecturesPicker(
          state.multipleField
            ? `Timings of all the classes that happen ${dt.format(startTime, 'dddd')}` +
                ' from ' +
                dt.format(startTime, 'hh:mm A') +
                ' to ' +
                dt.format(endTime, 'hh:mm A') +
                ' will be changed.'
            : 'Timings of only this class will be changed.'
        ),
        this.notifyStudentsPicker(),
      ]
    );
  }

  private async saveLectureTimings() {
    const state = this.getState();
    const props = this.getProps();
    const response = await dispatch(
      Actions.timingsEdit({
        classId: props.cls._id,
        multiple: state.multipleField,
        notify: state.notifyField,
        newStartTime: u.timeToHHMM(state.startTimeField),
        newEndTime: u.timeToHHMM(state.endTimeField),
      })
    );
    if (response.message === 'clash') {
      await this.setState({
        clashes: response.clashingClasses.map((c) => {
          const year = Math.floor(c.date / 10000);
          const month = Math.floor(c.date / 100) % 100;
          const day = c.date % 100;
          const date = new Date();
          date.setFullYear(year);
          date.setDate(day);
          date.setMonth(month - 1);
          return {
            type: c.type,
            date: date,
            startTime: c.startTime,
            endTime: c.endTime,
          };
        }),
      });
    } else {
      await dispatch(CourseActions.fetchTimeline());
      await this.setState({
        isEditTimingsDialogOpen: false,
      }).then(() => {
        u.resetTabIndices();
        (this.lastFocusedElement as HTMLElement).focus();
      });
    }
  }

  private clashDialog() {
    if (!this.isClassEditable()) return null;
    const state = this.getState();
    return Alert(
      {
        open: !!state.clashes,
        title: h('span', style(['red']), 'Clash with existing classes'),
        actions: [
          FlatButton('OKAY', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.setState({
                clashes: null,
              }),
          }),
        ],
      },
      state.clashes
        ? [
            h(
              'div',
              `You can't have a Class from ` +
                'on the following dates because of clashes with the following ' +
                'pre-scheduled classes.'
            ),
            ...state.clashes.map((clash) =>
              h('div', style([mt('0.5rem')]), [
                h('div', style(['red']), dt.format(clash.date, 'ddd, Do MMM, YYYY')),
                h('div', style(['small', 'grey']), [
                  `${u.capitalize(clash.type)}, ${dt.format(
                    clash.startTime,
                    'hh:mm A'
                  )} - ${dt.format(clash.endTime, 'hh:mm A')}`,
                ]),
              ])
            ),
          ]
        : []
    );
  }

  private scheduleSection() {
    const props = this.getProps();
    const { cls } = props;

    let lectureTimingsClickHandler: undefined | (() => void | Promise<any>) = undefined;

    if (this.isClassEditable()) {
      const startTime = dt.fromUnix(cls.details.scheStartTime);
      const endTime = dt.fromUnix(cls.details.scheEndTime);
      lectureTimingsClickHandler = () => {
        this.lastFocusedElement = document.activeElement;
        u.unsetTabIndices();
        this.setState({
          isEditTimingsDialogOpen: true,
          startTimeField: {
            hours: startTime.getHours(),
            minutes: startTime.getMinutes(),
          },
          endTimeField: {
            hours: endTime.getHours(),
            minutes: endTime.getMinutes(),
          },
          notifyField: 1,
          multipleField: 0,
        });
      };
    }

    return h(
      'div',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `This Class scheduled for
                ${dt.format(cls.details.scheStartTime, 'dddd, Do MMMM, YYYY')}
                from ${dt.format(cls.details.scheStartTime, 'h:m A')}
                to ${dt.format(cls.details.scheEndTime, 'h:m A')}
                at the venue ${cls.details.venue}`,
      },
      [
        h('div.class-activities__header', 'SCHEDULE'),
        h(
          'div',
          {
            className: cn('class-activities__schedule', {
              editable: this.isClassEditable(),
            }),
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': this.isClassEditable()
              ? `Edit Date. Current Date:
                        ${dt.format(cls.details.scheStartTime, 'dddd, Do MMM, YYYY')}`
              : undefined,
            onclick: this.isClassEditable()
              ? () => {
                  this.lastFocusedElement = document.activeElement;
                  u.unsetTabIndices();
                  this.setState({
                    isEditScheduleDialogOpen: true,
                  });
                }
              : undefined,
          },
          [
            h('i.fa.fa-calendar.class-activities__schedule__icon'),
            h('span', dt.format(cls.details.scheStartTime, 'ddd, Do MMM, YYYY')),
            this.isClassEditable()
              ? h('i.fa.fa-pencil.class-activities__schedule__action', {
                  'aria-label': 'Edit icon',
                })
              : null,
          ]
        ),
        h(
          'div',
          {
            className: cn('class-activities__schedule', {
              editable: this.isClassEditable(),
              'in-session': cls.details.status === 'inSession',
            }),
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': this.isClassEditable()
              ? `Edit Time. Current Time: ${dt.format(
                  cls.details.scheStartTime,
                  'hh:mm A'
                )} - ${dt.format(cls.details.scheEndTime, 'hh:mm A')}`
              : undefined,
            onclick: lectureTimingsClickHandler,
          },
          [
            h('i.fa.fa-clock-o.class-activities__schedule__icon'),
            h('span', [
              dt.format(cls.details.scheStartTime, 'hh:mm A'),
              ' - ',
              dt.format(cls.details.scheEndTime, 'hh:mm A'),
            ]),

            this.isClassEditable()
              ? h('i.fa.fa-pencil.class-activities__schedule__action', {
                  'aria-label': 'Edit Icon',
                })
              : null,

            cls.details.status === 'inSession'
              ? h('span.class-activities__schedule__action', 'In session')
              : null,
          ]
        ),
        h(
          'div',
          {
            className: cn('class-activities__schedule', {
              editable: this.isClassEditable(),
            }),
            tabIndex: this.isAccessible ? 0 : undefined,
            'aria-label': this.isClassEditable()
              ? cls.details.isOnlineMeeting
                ? `Edit venue. Currently the class meeting is set to online.`
                : `Edit venue. Current Venue: ${cls.details.venue}`
              : undefined,
            onclick: this.isClassEditable()
              ? () => {
                  this.lastFocusedElement = document.activeElement;
                  u.unsetTabIndices();
                  this.setState({
                    isEditVenueDialogOpen: true,
                    venueField: cls.details.venue,
                    notifyField: 1,
                    multipleField: 0,
                  });
                }
              : undefined,
          },
          [
            cls.details.isOnlineMeeting
              ? SvgIcon({
                  icon: VideoCamIcon,
                  className: 'class-activities__schedule__icon',
                })
              : h('i.fa.fa-map-marker.class-activities__schedule__icon'),
            h(
              'span',
              cls.details.isOnlineMeeting
                ? `This class ${
                    ['open', 'inSession'].indexOf(cls.details.status) > -1 ? 'is' : 'was'
                  } online`
                : links.detect(cls.details.venue).map((node) => {
                    if (node.type === 'string') return node.value;
                    return h(
                      'a',
                      {
                        href: node.value,
                        target: '_blank',
                        onclick: (e) => e.stopPropagation(),
                      },
                      node.value
                    );
                  })
            ),
            this.isClassEditable()
              ? h('i.fa.fa-pencil.class-activities__schedule__action', {
                  'aria-label': 'Edit Icon',
                })
              : null,
          ]
        ),
      ]
    );
  }

  private editVenueDialog() {
    if (!this.isClassEditable()) return null;
    const { venueField, isOnlineMeeting, multipleField, isEditVenueDialogOpen } = this.getState();
    const { cls } = this.getProps();

    return Dialog(
      {
        open: isEditVenueDialogOpen,
        title: 'Class Venue',
        style: {
          backgroundColor: colors.backgroundColor,
          fontSize: '1rem',
        },
        secondaryAction: {
          tabIndex: this.isAccessible ? 0 : undefined,
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              isEditVenueDialogOpen: false,
            }).then(() => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
            }),
        },
        primaryAction: {
          tabIndex: this.isAccessible ? 0 : undefined,
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          disabled: !isOnlineMeeting && venueField.trim().length < 1,
          onclick: () => this.editVenue(),
        },
      },
      [
        h('div.cell', [
          h('span', 'CLASS DATE'),
          h('span.uppercase', [
            dt.format(cls.details.scheStartTime, 'ddd, MMM DD, YYYY (hh:mm A - '),
            dt.format(cls.details.scheEndTime, 'hh:mm A)'),
          ]),
        ]),
        this.classModality(),
        !isOnlineMeeting
          ? h('div', [
              h(
                'div.cell',
                u.withTabIndex({
                  style: {
                    backgroundColor: '#fff',
                  },
                }),
                [
                  h('div', { style: { width: '40%' } }, 'At (venue/link)'),
                  TextField({
                    value: venueField,
                    placeholder: 'Venue/link',
                    focusOnMount: true,
                    floatingLabelText: 'Venue/link',
                    oninput: (event) =>
                      this.setState({
                        venueField: event.target.value,
                      }),
                    onenter: () => this.editVenue(),
                  }),
                ]
              ),
              h('div.cell.fc-grey', u.withTabIndex(), [
                h('span', `Old venue: ${cls.details.venue || 'N/A'}`),
              ]),
            ])
          : null,

        this.similarLecturesPicker(
          multipleField === 0
            ? 'Venue of only this class (' +
                dt.format(cls.details.scheStartTime, 'dddd, MMM DD, hh:mm A - ') +
                dt.format(cls.details.scheEndTime, 'hh:mm A') +
                ') will be changed'
            : 'Venue of all the classes that happen on ' +
                dt.format(cls.details.scheStartTime, 'dddd, hh:mm A - ') +
                dt.format(cls.details.scheEndTime, 'hh:mm A') +
                ', will be changed.',
          { marginTop: '1.5rem' }
        ),

        this.notifyStudentsPicker(),
      ]
    );
  }

  private notifyStudentsPicker() {
    const { notifyField } = this.getState();
    return ToggleCell({
      label: 'Send a notification to all students',
      isSelected: notifyField === 1,
      onToggle: () => {
        this.setState({
          notifyField: notifyField === 0 ? 1 : 0,
        });
      },
    });
  }

  private similarLecturesPicker(helperText: string, style?: CSS) {
    const { multipleField } = this.getState();
    return ToggleCell({
      style,
      helperText,
      label: 'Change for all similar classes',
      isSelected: multipleField === 1,
      onToggle: () => {
        this.setState({
          multipleField: multipleField === 0 ? 1 : 0,
        });
      },
    });
  }

  private classTeam() {
    const { cls } = this.getProps();
    const inCharge = cls.info.team.inCharge;
    const assistants = cls.info.team.assistants;
    const currentUser = appService.getCurrentUser()!;

    const teamMember = (member: IClassTeamMember, isIncharge: boolean) => {
      const name = currentUser.userId === member.userId ? 'You' : member.name;
      return User({
        avatar: {
          url: member.avatar,
          creator: member.name,
        },
        title: h('div', [
          h('span', name),
          isIncharge ? h('span.tag.green', 'In-charge') : h('span.tag.orange', 'Assistant'),
        ]),
        subtitle: h('div.fc-grey', courseRoleMap[member.role]),
      });
    };

    if (this.canEditClassTeam()) {
      return h(
        'div.panel',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          h('div.panel__header', [
            h('span', 'Class Team'),
            h('i.fa.fa-pencil', {
              alt: 'Edit Icon',
              tabIndex: this.isAccessible ? 0 : undefined,
              onclick: () => {
                this.lastFocusedElement = document.activeElement;
                u.unsetTabIndices();
                this.showEditClassTeamDialog();
              },
            }),
          ]),
          h('div.panel__content', [
            teamMember(inCharge, true),
            assistants && assistants.length ? teamMember(assistants[0], false) : null,
          ]),
          this.editClassTeamDialog(),
        ]
      );
    }

    return h(
      'div',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `Class in-charge for this class is ${inCharge.name}`,
      },
      [
        h('div.class-activities__header', 'Class Team'),
        teamMember(inCharge, true),
        assistants && assistants.length ? teamMember(assistants[0], false) : null,
      ]
    );
  }

  private setEditClassTeamState(state: Partial<IActivitiesState['editClassTeam']>) {
    const defaultState: IActivitiesState['editClassTeam'] = {
      selectedAssistantId: null,
      selectedInChargeId: null,
      changeForSimilarClasses: false,
    };

    const currentState = this.getState().editClassTeam;

    return this.setState({
      editClassTeam: {
        ...defaultState,
        ...(currentState || {}),
        ...state,
      },
    });
  }

  private showEditClassTeamDialog() {
    const { cls } = this.getProps();
    const team = cls.info.team;
    const selectedInChargeId = team.inCharge.userId;
    const selectedAssistantId = team.assistants[0] ? team.assistants[0].userId : null;
    return this.setEditClassTeamState({
      selectedAssistantId,
      selectedInChargeId,
    });
  }

  private closeEditClassTeamDialog() {
    return this.setState({
      editClassTeam: null,
    });
  }

  private getTeamChanges() {
    const state = this.getState();
    const { cls } = this.getProps();

    if (!state.editClassTeam) {
      return { inCharge: false, assistant: false };
    }

    const editedTeam = state.editClassTeam;
    const currentClassTeam = cls.info.team;

    const currentInChargeUserId = currentClassTeam.inCharge.userId;
    const currentAssistantUserId = currentClassTeam.assistants[0]
      ? currentClassTeam.assistants[0].userId
      : null;

    return {
      inCharge: currentInChargeUserId !== editedTeam.selectedInChargeId,
      assistant: currentAssistantUserId !== editedTeam.selectedAssistantId,
    };
  }

  private async saveClassTeam() {
    const state = this.getState();
    const { cls, course } = this.getProps();
    const courseRole = courseService.getRole();

    if (!state.editClassTeam) return;

    const editedTeam = state.editClassTeam;
    const changes = this.getTeamChanges();

    if (!editedTeam.selectedInChargeId) {
      dispatch(
        AppActions.showError({
          message: 'Please select a class in-charge',
        })
      );
      return;
    }

    const multiple: ['inCharge'?, 'assistants'?] = [];
    if (courseRole === 'instructor' && changes.assistant) {
      multiple.push('assistants');
    } else if (state.changeSimilarClassDialog) {
      if (state.changeSimilarClassDialog.inCharge) {
        multiple.push('inCharge');
      }
      if (state.changeSimilarClassDialog.assistant) {
        multiple.push('assistants');
      }
    }

    const teamMap: ObjectMap<IClassTeamMember> = u.makeObjectWithKey(
      course.team.map((m) => {
        return {
          avatar: m.avatar,
          name: m.name,
          userId: m.userId,
          role: m.role,
        };
      }),
      'userId'
    );

    if (changes.inCharge || changes.assistant) {
      await dispatch(
        Actions.editClassTeam({
          multiple,
          classId: cls._id,
          inCharge: teamMap[editedTeam.selectedInChargeId],
          assistants: editedTeam.selectedAssistantId
            ? [teamMap[editedTeam.selectedAssistantId]]
            : [],
        })
      );
    }

    await this.hideChangeSimilarClassDialog();
    await this.closeEditClassTeamDialog();

    u.resetTabIndices();
    (this.lastFocusedElementSpare as HTMLElement).focus();
  }

  private editClassTeamDialog() {
    const { course, cls } = this.getProps();
    const state = this.getState();
    const courseRole = courseService.getRole();
    const currentUser = appService.getCurrentUser()!;

    if (!state.editClassTeam) return null;

    const { selectedAssistantId, selectedInChargeId, changeForSimilarClasses } =
      state.editClassTeam;

    const day = dt.format(cls.details.scheStartTime, 'dddd');
    const startTime = dt.format(cls.details.scheStartTime, 'hh:mm A');

    const teamMemberCell = (member: ICourseTeamMember) => {
      const isAssistant = member.userId === selectedAssistantId;
      const isInCharge = member.userId === selectedInChargeId;
      const name = currentUser.userId === member.userId ? 'You' : member.name;

      const isMakeAssistantDisabled = !!selectedAssistantId;
      const isMakeInChargeDisabled = !!selectedInChargeId || member.role === 'ta';

      const buttonBorder = '1px solid rgba(185, 185, 185, 0.4)';

      let makeInchargeTooltip = '';
      let makeAssistantTooltip = '';

      if (isMakeAssistantDisabled) {
        makeAssistantTooltip = 'Remove the selected class assistant first';
      }

      if (isMakeInChargeDisabled) {
        if (member.role === 'ta') {
          makeInchargeTooltip = 'Course TAs cannot be made a class in-charge';
        } else if (selectedInChargeId) {
          makeInchargeTooltip = 'Remove the selected class in-charge first';
        }
      }

      return h(
        'div.team-member',
        style([
          {
            background: colors.white,
            overflow: 'hidden',
            borderRadius: '4px',
            margin: '0.5rem 0',
            boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.1)',
          },
        ]),
        [
          User({
            avatar: {
              url: member.avatar,
              creator: member.name,
            },
            title: name,
            subtitle: h('div.fc-grey', courseRoleMap[member.role]),
          }),
          isInCharge
            ? h(
                'div',
                style([
                  'flex',
                  'alignCenter',
                  'spaceBetween',
                  {
                    height: '2rem',
                    padding: '0 0.5rem',
                    backgroundColor: colors.green,
                    color: colors.white,
                  },
                ]),
                [
                  h('span', 'Class In-charge'),
                  Icon(
                    icons.cross,
                    style(
                      ['pointer', pad('0.5rem')],
                      {},
                      {
                        className: 'ripple',
                        onclick: () =>
                          this.setEditClassTeamState({
                            selectedInChargeId: null,
                          }),
                      }
                    )
                  ),
                ]
              )
            : isAssistant
            ? h(
                'div',
                style([
                  'flex',
                  'alignCenter',
                  'spaceBetween',
                  {
                    height: '2rem',
                    padding: '0 0.5rem',
                    backgroundColor: colors.orange,
                    color: colors.white,
                  },
                ]),
                [
                  h('span', 'Class Assistant'),
                  Icon(
                    icons.cross,
                    style(
                      ['pointer', pad('0.5rem')],
                      {},
                      {
                        className: 'ripple',
                        onclick: () =>
                          this.setEditClassTeamState({
                            selectedAssistantId: null,
                          }),
                      }
                    )
                  ),
                ]
              )
            : h(
                'div',
                style([
                  'flex',
                  {
                    height: '2rem',
                    borderTop: buttonBorder,
                  },
                ]),
                [
                  Tooltip({
                    text: makeInchargeTooltip,
                    styles: {
                      width: '50%',
                    },
                    targetElement: DivButton({
                      disabled: isMakeInChargeDisabled,
                      children: [
                        h(
                          'span',
                          style([isMakeInChargeDisabled ? 'grey' : 'green']),
                          'Make In-charge'
                        ),
                      ],
                      onclick: () =>
                        this.setEditClassTeamState({
                          selectedInChargeId: member.userId,
                        }),
                    }),
                  }),
                  Tooltip({
                    text: makeAssistantTooltip,
                    styles: {
                      width: '50%',
                      borderLeft: buttonBorder,
                    },
                    targetElement: DivButton({
                      disabled: isMakeAssistantDisabled,
                      children: [
                        h(
                          'span',
                          style([isMakeAssistantDisabled ? 'grey' : 'orange']),
                          'Make Assistant'
                        ),
                      ],
                      onclick: () =>
                        this.setEditClassTeamState({
                          selectedAssistantId: member.userId,
                        }),
                    }),
                  }),
                ]
              ),
        ]
      );
    };

    return Dialog(
      {
        open: !!state.editClassTeam,
        title: 'Edit class team',
        style: {
          width: '30em',
        },
        bodyStyle: {
          backgroundColor: colors.backgroundColor,
          height: '700px',
          maxHeight: '90%',
        },
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: Icon(icons.cross),
          type: 'secondary',
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            u.resetTabIndices();
            this.lastFocusedElementSpare
              ? (this.lastFocusedElementSpare as HTMLElement).focus()
              : null;
            return this.closeEditClassTeamDialog();
          },
        },
        primaryAction: {
          label: 'DONE',
          type: 'primary',
          mobileLabel: Icon(icons.done),
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            if (changeForSimilarClasses && courseRole === 'admin') {
              return this.showChangeSimilarClassDialog();
            }
            return this.saveClassTeam();
          },
        },
      },
      [
        h(
          'div',
          style([
            'flex',
            'column',
            {
              overflow: 'hidden',
              height: '100%',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          ]),
          [
            h('div.title', style(['flex', 'alignCenter', 'large', pad('1rem 1rem 0.5rem')]), [
              h('span', 'Select a Class In-charge and Assistant'),
              Icon(
                icons.info,
                style(
                  ['blue', 'pointer', pad('0.5rem')],
                  {},
                  {
                    onclick: () =>
                      this.setState({
                        isClassTeamRoleDialogOpen: true,
                      }),
                  }
                )
              ),
            ]),

            h(
              'div.instructor-list',
              style([
                pad('0 1rem'),
                {
                  flex: 1,
                  overflow: 'auto',
                },
              ]),
              course.team
                .filter((m) => m.status !== 'invited' && m.role !== 'student')
                .map(teamMemberCell)
            ),

            h(
              'div.footer',
              style([
                {
                  zIndex: 1,
                  padding: '1rem',
                  backgroundColor: colors.white,
                  boxShadow: '0 -1px 4px rgba(0, 0, 0, 0.4)',
                },
              ]),
              [
                h('div', style(['flex', 'alignCenter', 'spaceBetween']), [
                  h('span', 'Change for all similar classes'),
                  Toggle({
                    selected: changeForSimilarClasses,
                    ontoggle: () => {
                      this.setEditClassTeamState({
                        changeForSimilarClasses: !changeForSimilarClasses,
                      });
                    },
                  }),
                ]),
                h('div', style(['small', 'grey', mt('0.5rem')]), [
                  `Set the class team for future classes that`,
                  ` happen every ${day} at ${startTime}`,
                  courseRole === 'instructor' ? ` where you are the class in-charge` : '',
                ]),
              ]
            ),
          ]
        ),
        this.classTeamRoleDialog(),
        this.changeSimilarClassDialog(),
      ]
    );
  }

  private classTeamRoleDialog() {
    const bullet = (text: string) =>
      h('div', style([mb('0.25rem'), ml('0.75rem')]), [
        h('span', style([mr('0.5rem')]), '-'),
        h('span', text),
      ]);

    const section = (title: string, children: View[]) =>
      h('div', style(['grey', mb('0.75rem')]), [
        h('div', style(['bold', 'large', mb('0.5rem')]), title),
        ...children,
      ]);

    return Alert(
      {
        style: {
          width: '25em',
        },
        title: h('div', style(['orange']), 'Class Team Roles'),
        open: this.getState().isClassTeamRoleDialogOpen,
        actions: [
          FlatButton('Okay', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.setState({
                isClassTeamRoleDialogOpen: false,
              }),
          }),
        ],
      },
      [
        h('div', style([mb('0.5')]), [
          section('A class in-charge can', [
            bullet('Create/Edit/Publish class activities'),
            bullet('Record/Edit attendance'),
            bullet('Award participation points'),
          ]),

          section('A class assistant can', [
            bullet('Record/Edit attendance'),
            bullet('Award participation points'),
          ]),

          h('div', style(['flex', 'alignCenter', 'orange']), [
            h('i.fa.fa-exclamation-triangle', style([mr('0.5rem')])),
            h('span', 'Only course admin and course instructor can be a class in-charge'),
          ]),
        ]),
      ]
    );
  }

  private showChangeSimilarClassDialog() {
    return this.setState({
      changeSimilarClassDialog: this.getTeamChanges(),
    });
  }

  private hideChangeSimilarClassDialog() {
    return this.setState({
      changeSimilarClassDialog: null,
    });
  }

  private changeSimilarClassDialog() {
    const state = this.getState().changeSimilarClassDialog;
    if (!state) return null;

    const { cls } = this.getProps();
    const day = dt.format(cls.details.scheStartTime, 'dddd');
    const startTime = dt.format(cls.details.scheStartTime, 'hh:mm A');

    const checkBox = (label: string, isChecked: boolean, onClick: () => any) => {
      return h(
        'div.checkbox',
        style(
          ['flex', 'alignCenter', 'pointer', pad('0.75rem 0.5rem')],
          {},
          {
            onclick: onClick,
          }
        ),
        [
          Icon(
            isChecked ? icons.squareFilled : icons.square,
            style([
              {
                color: colors.blue,
                fontSize: '1.25rem',
              },
            ])
          ),
          h(
            'span',
            style([
              ml('0.5rem'),
              {
                lineHeight: 1,
              },
            ]),
            label
          ),
        ]
      );
    };

    return Alert(
      {
        style: {
          width: '25em',
        },
        title: h('div', style([mb('1rem')]), 'For future similar classes'),
        open: true,
        actions: [
          FlatButton('Back', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => this.hideChangeSimilarClassDialog(),
          }),
          FlatButton('Done', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.saveClassTeam(),
          }),
        ],
      },
      [
        h('div', style([mb('0.5rem')]), [
          `Please select the roles you want to change for the future classes`,
          ` that happen every ${day} at ${startTime}`,
        ]),
        checkBox('Class in-charge', state.inCharge, () => {
          this.setState({
            changeSimilarClassDialog: {
              ...state,
              inCharge: !state.inCharge,
            },
          });
        }),
        checkBox('Class assistant', state.assistant, () => {
          this.setState({
            changeSimilarClassDialog: {
              ...state,
              assistant: !state.assistant,
            },
          });
        }),
      ]
    );
  }

  private inchargeCandidates() {
    return this.getProps()
      .course.team.filter((m) => m.role === 'instructor' || m.role === 'admin')
      .filter((m) => m.status === 'active');
  }

  private taCandidates() {
    return this.getProps().course.team.filter((m) => m.role !== 'student' && m.status === 'active');
  }

  private canEditClassTeam() {
    const { cls, courseRole, isIncharge } = this.getProps();
    return (
      cls.details.status === 'open' &&
      (courseRole === 'admin' || isIncharge) &&
      (this.inchargeCandidates().length > 1 || this.taCandidates().length > 1)
    );
  }

  private suggestedActivities() {
    const classRole = classService.getRole();
    const classData = getStore().getState().class.data;
    const cls = this.getProps().cls;

    if (!classData || classRole !== 'in-charge') return null;
    if (cls.details.status !== 'inSession' && cls.details.scheEndTime < dt.unix()) return null;

    const { suggestedActivities: activities, showSuggestedActivities } = classData;
    if (!showSuggestedActivities) return null;

    return h(
      'div',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        h('div.class-activities__header', 'SUGGESTED ACTIVITIES FROM SOURCE COURSE'),
        !activities.length
          ? h('div.class-activities__placeholder', [
              'Acadly doesn’t have any intelligent activity suggestions for this class',
            ])
          : null,
        h('div.activity__description', [
          ...activities.map((activity) =>
            SuggestedActivityWidget({
              activity,
              key: activity._id,
              isAccessible: this.isAccessible,
              onclick: () =>
                this.setState({
                  isSuggestedActivityPreviewOpen: true,
                  suggestedActivity: activity,
                }),
            })
          ),
          DivButton({
            tabIndex: this.isAccessible ? 0 : undefined,
            classNames: ['class-activities__more-suggestions'],
            children: ['All activities from source course'],
            onclick: async () => {
              this.setState({
                isAllSuggestedActivitiesDialogOpen: true,
                isAllSuggestedActivitiesLoading: true,
              });
              await dispatch(Actions.fetchAllSuggestedActivities());
              this.setState({ isAllSuggestedActivitiesLoading: false });
            },
          }),
        ]),
        this.allSuggestedActivityDialog(),
        SuggestedActivitiyPreview({
          cls,
          isAccessible: this.isAccessible,
          open: this.getState().isSuggestedActivityPreviewOpen,
          activity: this.getState().suggestedActivity,
          onClose: () =>
            this.setState({
              isSuggestedActivityPreviewOpen: false,
              suggestedActivity: null,
            }),
        }),
      ]
    );
  }

  private allSuggestedActivityDialog() {
    const { isAllSuggestedActivitiesLoading: isLoading } = this.getState();
    const rootState = getStore().getState();
    const isMobile = rootState.app.isMobile;
    const activities = rootState.class.allSuggestedActivities;

    const centerAlign = {
      display: 'flex',
      alignItems: 'center',
    };

    return Dialog(
      {
        open: this.getState().isAllSuggestedActivitiesDialogOpen,
        title: 'All Activities From Source Course',
        style: {
          minHeight: '80%',
        },
        bodyStyle: {
          backgroundColor: colors.backgroundColor,
          flex: 1,
          ...(isLoading ? centerAlign : {}),
        },
        secondaryAction: {
          label: 'Close',
          mobileLabel: h('i.fa.fa-arrow-left'),
          onclick: () => this.setState({ isAllSuggestedActivitiesDialogOpen: false }),
        },
      },
      [
        isLoading
          ? fullScreenLoader
          : h('div.suggested-activities', [
              activities.length > 0
                ? h('div.suggested-activities__header', [
                    h(
                      'div',
                      `These are all the activities that were published in ${activities[0].identifiers.courseCode}: ${activities[0].identifiers.courseTitle}`
                    ),
                    h(
                      'div',
                      `${
                        isMobile ? 'Tap' : 'Click'
                      } on the activity cell to use it in the current class`
                    ),
                  ])
                : null,
              ...activities.map((activity) =>
                SuggestedActivityWidget({
                  activity,
                  key: activity._id,
                  noSuggestedTag: true,
                  isAccessible: this.isAccessible,
                  onclick: () =>
                    this.setState({
                      isSuggestedActivityPreviewOpen: true,
                      suggestedActivity: activity,
                    }),
                })
              ),
            ]),
      ]
    );
  }

  private preClassActivities() {
    const { courseRole } = this.getProps();
    const classData = getStore().getState().class.data;

    if (!classData)
      return h('div', style(['flex', 'alignCenter', margin('1em'), 'justifyCenter']), [Loader()]);

    const activities = this.getActivities().filter((a) => a.details.toBeDone === 'preClass');

    let noActivitiesMessage: View[] = ['This class has no pre-class activities.'];

    if (this.canAddPreClass()) {
      noActivitiesMessage = [
        'Students must complete pre-class activities ',
        '15 minutes before the class begins. ',
        'Click on the ',
        Icon(icons.plusFilled, { className: 'class-activities__inline-icon' }),
        ' icon to add.',
      ];
    } else if (courseRole !== 'student') {
      noActivitiesMessage = [
        'No pre-class activities added. ',
        'Only the class in-charge can add activities.',
      ];
    }

    return h(
      'div',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `PRE-CLASS ACTIVITIES ${
          activities.length === 0
            ? noActivitiesMessage
            : `this class has ${activities.length} pre-class ${u.pluralize(
                activities.length,
                'activity',
                'activities'
              )}`
        }`,
      },
      [
        h('div.class-activities__header', 'PRE-CLASS ACTIVITIES'),

        activities.length === 0
          ? h('div.class-activities__placeholder', noActivitiesMessage)
          : null,

        activities.length > 0
          ? h('div.activity__description', this.renderActivities(activities))
          : null,
      ]
    );
  }

  private inClassActivities() {
    const { cls, courseRole, isIncharge } = this.getProps();
    const classData = getStore().getState().class.data;
    if (!classData) return null;

    const activities = this.getActivities().filter((a) => a.details.toBeDone === 'inClass');

    let noActivitiesMessage: View[] = ['This class has no in-class activities.'];
    if (cls.details.status !== 'closed' && cls.details.status !== 'noRecord' && isIncharge) {
      noActivitiesMessage = [
        'Activities to engage the students better during the class. ',
        'Click on the ',
        Icon(icons.plusFilled, { className: 'class-activities__inline-icon' }),
        ' icon to add.',
      ];
    } else if (courseRole !== 'student') {
      noActivitiesMessage = [
        'No in-class activities added. ',
        'Only the class in-charge can add activities.',
      ];
    }

    const iconRow = (icon: string, label: string, classes: string[] = []) => {
      return h(
        'div',
        {
          className: cn('class-activities__icon-row', ...classes),
          tabIndex: this.isAccessible ? 0 : undefined,
        },
        [
          Icon(icon, { className: 'class-activities__icon-row__icon' }),
          h('span.class-activities__icon-row__label', label),
        ]
      );
    };

    return h(
      'div',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `IN-CLASS ACTIVITIES: ${
          activities.length === 0
            ? noActivitiesMessage
            : `This Class has ${activities.length} in-class ${u.pluralize(
                activities.length,
                `activity`,
                `activities`
              )}`
        } `,
      },
      [
        h('div.class-activities__header', 'IN-CLASS ACTIVITIES'),

        cls.details.status === 'noRecord'
          ? h('div', style(['red', 'textCenter', margin('1em')]), ['Acadly was not used in class'])
          : null,

        activities.length === 0
          ? h('div.class-activities__placeholder', noActivitiesMessage)
          : null,

        activities.length > 0
          ? h('div.activity__description', this.renderActivities(activities))
          : null,

        courseRole === 'student' &&
        cls.userData.attendance &&
        cls.userData.attendance.status === 'present'
          ? iconRow(
              icons.locationCheck,
              cls.userData.attendance.visibleStatus
                ? cls.userData.attendance.visibleStatus
                : cls.userData.attendance.status,
              ['green', 'capitalize']
            )
          : null,

        courseRole === 'student' &&
        cls.userData.attendance &&
        cls.userData.attendance.status === 'absent'
          ? iconRow(
              icons.locationCheck,
              cls.userData.attendance.visibleStatus
                ? cls.userData.attendance.visibleStatus
                : cls.userData.attendance.status,
              ['red', 'capitalize']
            )
          : null,
      ]
    );
  }

  private reviewSection() {
    const updateAvailable = this.isUpdatedTagVisible();
    return h(
      'div',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `CLASS REVIEW ${
          updateAvailable ? `has updates` : `No update`
        } since last you checked`,
      },
      [h('div.class-activities__header', 'CLASS REVIEW'), this.lectureSummary()]
    );
  }
  private postClassQueries() {
    const { courseRole } = this.getProps();
    let noQueriesMessage = '';
    const classData = getStore().getState().class.data;

    if (!classData) return null;

    const queries = this.getActivities()
      .filter((a) => a.nodeType === 'query')
      .filter((a) => a.details.toBeDone === 'review');

    if (courseRole === 'student') {
      noQueriesMessage = 'Queries that you may ask after the ' + 'class will show up here.';
    } else {
      noQueriesMessage = 'Queries that students may raise ' + 'after the class will show up here.';
    }
    return h(
      'div',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
        'aria-label': `POST CLASS QUERIES: ${
          queries.length < 1
            ? noQueriesMessage
            : `there ${u.pluralize(
                queries.length,
                `is ${queries.length} Query`,
                `are ${queries.length} Queries`
              )} in this Class`
        }`,
      },
      [
        h('div.class-activities__header', 'POST CLASS QUERIES'),

        queries.length < 1 ? h('div.class-activities__placeholder', noQueriesMessage) : null,

        h('div.review-queries-list', this.renderActivities(queries)),
      ]
    );
  }

  private renderActivities(activities: IClassActivity[]) {
    const { cls, courseRole } = this.getProps();
    return activities.map((activity) =>
      ActivityWidget({
        activity: activity,
        key: activity._id,
        cls: cls,
        courseRole: courseRole,
        onclick: this.onClickActivity(activity),
      })
    );
  }

  private canAddPreClass() {
    const { cls, isIncharge } = this.getProps();
    return (
      isIncharge &&
      cls.details.status === 'open' &&
      dt.diff(dt.fromUnix(this.getProps().cls.details.scheStartTime), dt.now(), 'minutes') > 0
    );
  }

  private isWarningMessageInvalid() {
    const { cls, isIncharge } = this.getProps();
    return (
      isIncharge &&
      cls.details.status === 'open' &&
      dt.diff(dt.fromUnix(this.getProps().cls.details.scheStartTime), dt.now(), 'minutes') > 30
    );
  }

  private getActivities(): IClassActivity[] {
    const classData = getStore().getState().class.data;
    const storeState = getStore().getState();
    const quizzes = classData
      ? classData.activities.quizzes.map((id) => storeState.quizzes.byId[id])
      : [];
    const polls = classData
      ? classData.activities.polls.map((id) => storeState.polls.byId[id])
      : [];
    const resources = classData
      ? classData.activities.resources.map((id) => storeState.resources.byId[id])
      : [];
    const discussions = classData
      ? classData.activities.discussions.map((id) => storeState.discussions.byId[id])
      : [];
    const queries = classData
      ? classData.activities.queries.map((id) => storeState.queries.byId[id])
      : [];

    const sortFn = (x: IClassActivity, y: IClassActivity) => {
      const publishedOnX = x.details.publishedOn;
      const publishedOnY = y.details.publishedOn;

      if (publishedOnX && publishedOnY) return publishedOnX - publishedOnY;
      if (publishedOnY) return 1;
      if (publishedOnX) return -1;

      return x.details.createdOn - y.details.createdOn;
    };

    return u
      .filterUndefined([...quizzes, ...polls, ...discussions, ...resources, ...queries])
      .sort(sortFn);
  }

  private canEndLecture() {
    const { cls } = this.getProps();
    const classRole = classService.getRole();
    return cls.details.status === 'inSession' && classRole !== 'none';
  }

  private onClickActivity(act: IClassActivity) {
    const { cls, course } = this.getProps();
    return () => {
      switch (act.nodeType) {
        case 'quiz':
          Routes.classQuiz.navigate({
            classShortId: classService.getShortIdFromClassId(cls._id),
            courseShortId: courseService.getShortIdFromCourseId(course._id),
            quizShortId: quizService.getShortIdFromQuizId(act._id),
            univSlug: appService.getUniversitySlug(),
          });
          return;
        case 'poll':
          Routes.classPoll.navigate({
            classShortId: classService.getShortIdFromClassId(cls._id),
            courseShortId: courseService.getShortIdFromCourseId(course._id),
            pollShortId: pollService.getShortIdFromPollId(act._id),
            univSlug: appService.getUniversitySlug(),
          });
          return;
        case 'discussion':
          Routes.classDiscussion.navigate({
            classShortId: classService.getShortIdFromClassId(cls._id),
            courseShortId: courseService.getShortIdFromCourseId(course._id),
            discussionShortId: discussionService.getShortIdFromDiscussionId(act._id),
            univSlug: appService.getUniversitySlug(),
          });
          return;
        case 'resource':
          Routes.classResource.navigate({
            classShortId: classService.getShortIdFromClassId(cls._id),
            courseShortId: courseService.getShortIdFromCourseId(course._id),
            resourceShortId: resourceService.getShortIdFromResourceId(act._id),
            univSlug: appService.getUniversitySlug(),
          });
          return;
        case 'query':
          if (act.details.isHidden) return;
          Routes.classQuery.navigate({
            classShortId: classService.getShortIdFromClassId(cls._id),
            courseShortId: courseService.getShortIdFromCourseId(course._id),
            queryShortId: queryService.getShortIdFromQueryId(act._id),
            univSlug: appService.getUniversitySlug(),
          });
          return;
        default:
          return;
      }
    };
  }

  private classSummaryDialog() {
    const state = this.getState();
    const { isLectureSummaryDialogOpen, loadedSummary, isEditingSummary } = state;
    const { isIncharge, course, cls } = this.getProps();
    const cardHeaderIconAttrs = (attrs: any) =>
      style(['grey', 'large', pad('0.5rem'), ml('auto'), 'pointer'], {}, attrs);
    const attachments =
      loadedSummary && !isEditingSummary
        ? loadedSummary.attachments || []
        : isEditingSummary
        ? isEditingSummary.attachments
        : [];

    const links = isEditingSummary
      ? isEditingSummary.links
      : loadedSummary
      ? loadedSummary.links
      : [];

    const description = state.isEditingSummary
      ? state.isEditingSummary.description
      : loadedSummary
      ? loadedSummary.description
      : '';
    const cardHeaderAttrs = style(
      ['flex', 'alignCenter', 'spaceBetween', pad('0.5rem 1rem'), 'borderBox'],
      {},
      {
        key: 'card-header',
      }
    );
    const cardTitleAttrs = style([]);
    const cardBodyAttrs = style(
      [pad('0.5rem 1rem')],
      {},
      {
        key: 'card-body',
      }
    );
    return Dialog(
      {
        open: isLectureSummaryDialogOpen,
        title: 'Class summary',
        secondaryAction: isEditingSummary
          ? {
              label: 'CANCEL',
              tabIndex: this.isAccessible ? 0 : undefined,
              mobileLabel: h('i.fa.fa-times'),
              onclick: () => {
                u.resetTabIndices();
                this.setState({
                  isEditingSummary: null,
                });
              },
            }
          : {
              label: 'BACK',
              tabIndex: this.isAccessible ? 0 : undefined,
              mobileLabel: h('i.fa.fa-arrow-left'),
              onclick: () =>
                this.setState({
                  isLectureSummaryDialogOpen: false,
                  loadedSummary: null,
                }).then(() => {
                  u.resetTabIndices();
                  (this.lastFocusedElement as HTMLElement).focus();
                }),
            },
        style: {
          backgroundColor: colors.backgroundColor,
        },
        primaryAction:
          isIncharge &&
          !course.isArchived &&
          cls.details.status !== 'open' &&
          cls.details.status !== 'inSession'
            ? {
                label: isEditingSummary ? 'SAVE' : 'EDIT',

                mobileLabel: isEditingSummary ? h('i.fa.fa-check') : h('i.fa.fa-pencil'),
                disabled: isEditingSummary ? undefined : !loadedSummary,
                onclick: isEditingSummary
                  ? () => this.saveSummary()
                  : async () => {
                      if (!loadedSummary) return;
                      await this.setState({
                        isEditingSummary: {
                          description: loadedSummary.description,
                          attachments: loadedSummary.attachments || [],
                          links: loadedSummary.links,
                        },
                      });
                      setTimeout(() => {
                        const dialogHeader = document.getElementById('dialog-header');
                        if (dialogHeader) {
                          dialogHeader.focus();
                        }
                      }, 0);
                    },
              }
            : undefined,
      },
      loadedSummary
        ? [
            Paper(
              '.class-activities__card',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                'aria-label': 'Class Summary Description: ',
              },
              [
                h('div.card-header', cardHeaderAttrs, [
                  h('div.card-title', cardTitleAttrs, 'Description'),
                  isEditingSummary
                    ? h(
                        'i.fa.fa-pencil.ripple',
                        cardHeaderIconAttrs({
                          tabIndex: this.isAccessible ? 0 : undefined,
                          'aria-label': 'Edit Class Summary Description',
                          role: 'button',
                          onclick: () => {
                            this.lastFocusedElementSpare = document.activeElement;
                            u.unsetTabIndices();
                            this.setState({
                              isEditingSummaryDescription: true,
                              editSummaryDescriptionDialogField: isEditingSummary.description,
                            });
                          },
                        })
                      )
                    : null,
                ]),
                h(
                  '.card-body',
                  style(
                    [pad('0.5rem 1rem')],
                    {},
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                      key: 'card-body',
                    }
                  ),
                  [
                    description
                      ? Viewer(description, {
                          style: { marginBottom: '1em' },
                        })
                      : h('div', style(['grey']), [
                          'The class in-charge has not added a summary yet',
                        ]),
                  ]
                ),
              ]
            ),

            Paper(
              '.class-activities__card#attachments-body',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                'aria-label': 'Class Summary Attachments',
              },
              [
                h('div.card-header', cardHeaderAttrs, [
                  h('div.card-header-title', cardTitleAttrs, 'Attachments'),
                ]),
                h('div.card-body', cardBodyAttrs, [
                  attachments.length > 0
                    ? h(
                        'div',
                        attachments.map((a) =>
                          AttachmentViewer({
                            style: {
                              width: '100%',
                              marginTop: '0.5rem',
                            },
                            attachment: a,
                            downloadUrl: urls().classSummaryFileDownload,
                            downloadRequest: {
                              classId: this.getProps().cls._id,
                              fileName: a.name,
                            },
                            hideDownloadIcon: !!isEditingSummary,
                            actionButton: isEditingSummary
                              ? h(
                                  'i.fa.fa-times.ripple',
                                  style(
                                    [pad('0.75rem'), 'pointer', 'red'],
                                    {},
                                    {
                                      tabIndex: this.isAccessible ? 0 : undefined,
                                      onclick: () =>
                                        this.setState({
                                          isEditingSummary: {
                                            ...isEditingSummary,
                                            attachments: isEditingSummary.attachments.filter(
                                              (toRemove) => a.name !== toRemove.name
                                            ),
                                          },
                                        }).then(() => {
                                          const attachmentsBody =
                                            document.getElementById('attachments-body');
                                          if (attachmentsBody) {
                                            attachmentsBody.focus();
                                          }
                                        }),
                                    }
                                  )
                                )
                              : undefined,
                          })
                        )
                      )
                    : h('div', style(['grey']), ['No attachment found']),
                ]),

                this.uploadAttachmentButton(),
              ]
            ),

            Paper(
              '.class-activities__card#attachments-links',
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                'aria-label': 'Class Summary Helpful links: ',
              },
              [
                h('div.card-header', cardHeaderAttrs, [h('div', cardTitleAttrs, 'Helpful links')]),
                h('div.card-body', {}, [
                  links.length > 0
                    ? h(
                        'div',
                        style([mt('0.5em')]),
                        links.map((link) =>
                          h(
                            'div.link-body',
                            {
                              key: link.linkId,
                              tabIndex: this.isAccessible ? 0 : undefined,
                              'aria-label': link.title,
                              style: style([
                                pad('0.5rem 0.5rem 0.5rem 1rem'),
                                'borderBox',
                                'flex',
                                'alignCenter',
                                'lightBorder',
                              ]).style,
                            },
                            [
                              h('div', [
                                h('div', style(['large']), link.title),
                                h('div', [
                                  h(
                                    'a',
                                    style(
                                      ['blue'],
                                      {},
                                      {
                                        target: '_blank',
                                        href: u.convertLinkToURL(link.url),
                                      }
                                    ),
                                    [link.url]
                                  ),
                                ]),
                              ]),
                              isEditingSummary
                                ? h(
                                    'i.fa.fa-times.ripple',
                                    style(
                                      ['red', 'pointer', pad('0.5rem'), ml('auto')],
                                      {},
                                      {
                                        tabIndex: this.isAccessible ? 0 : undefined,
                                        onclick: () =>
                                          this.setState({
                                            isEditingSummary: {
                                              ...isEditingSummary,
                                              links: isEditingSummary.links.filter(
                                                (l) => l.linkId !== link.linkId
                                              ),
                                            },
                                          }),
                                      }
                                    )
                                  )
                                : null,
                            ]
                          )
                        )
                      )
                    : h(
                        'div',
                        style(
                          ['grey', pad('0.5rem 1rem'), 'lightBorder'],
                          {},
                          {
                            tabIndex: this.isAccessible ? 0 : undefined,
                          }
                        ),
                        ['No links found']
                      ),
                ]),
                this.addLinkButton(),
              ]
            ),

            this.descriptionEditDialog(),
            this.addLinkDialog(),
          ]
        : [h('div', style([pad('0.5em 1em'), 'flex', 'justifyCenter', 'borderBox']), [Loader()])]
    );
  }

  private addLinkButton() {
    const state = this.getState();
    if (!state.isEditingSummary) {
      return null;
    }
    return h(
      'div.ripple',
      style(
        ['blue', 'flex', 'alignCenter', 'spaceBetween', pad('1rem'), 'pointer'],
        {},
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            this.lastFocusedElementSpare = document.activeElement;
            u.unsetTabIndices();
            this.setState({
              addLinkDialog: {
                title: '',
                titleError: false,
                url: '',
                urlError: false,
              },
            });
          },
        }
      ),
      [h('div', 'Add a new link'), h('i.fa.fa-plus', style([ml('auto')]))]
    );
  }

  private uploadAttachmentButton() {
    const state = this.getState();
    const { isEditingSummary } = state;
    if (!state.isEditingSummary) {
      return null;
    }

    const attachments = isEditingSummary ? isEditingSummary.attachments : [];
    return UploadButton({
      ariaLabel: 'Upload an attachment button',
      tabIndex: this.isAccessible ? 0 : undefined,
      view: h(
        'div.ripple',
        style([
          'fullWidth',
          pad('1rem'),
          'blue',
          pad('1rem'),
          'pointer',
          'flex',
          'alignCenter',
          'spaceBetween',
          'borderBox',
          {
            borderTop: `1px solid ${colors.lightestGrey}`,
          },
        ]),
        [
          h('div', attachments.length > 0 ? 'Upload another attachment' : 'Upload an attachment'),
          Icon(icons.upload),
        ]
      ),
      upload: (file) => {
        const { cls } = this.getProps();
        const nameSplit = u.splitFileName(file.name);
        const { promise, progress$ } = upload(
          urls().classSummaryFileUpload,
          {
            originalFileName: nameSplit.name,
            fileType: nameSplit.extension,
            activityId: cls._id,
            activityType: 'classes',
          },
          file
        );
        return {
          promise: promise.then(async (attachment) => {
            if (!state.isEditingSummary) {
              return;
            }
            await this.setState({
              isEditingSummary: {
                ...state.isEditingSummary,
                attachments: [
                  ...state.isEditingSummary.attachments,
                  {
                    originalName: nameSplit.name,
                    extension: attachment.type,
                    name: attachment.name,
                  },
                ],
              },
            }).then(() => {
              const attachmentsBody = document.getElementById('attachments-body');
              if (attachmentsBody) {
                attachmentsBody.focus();
              }
            });
          }),
          progress$,
        };
      },
    });
  }

  private descriptionEditDialog() {
    const state = this.getState();
    const { isEditingSummaryDescription, editSummaryDescriptionDialogField } = state;
    return Dialog(
      {
        open: isEditingSummaryDescription,
        title: 'Summary Description',
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times'),
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElementSpare as any).focus();
            this.setState({
              isEditingSummaryDescription: false,
            });
          },
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check'),
          disabled: !editSummaryDescriptionDialogField,
          onclick: () => {
            u.resetTabIndices();
            (this.lastFocusedElementSpare as any).focus();
            this.setState({
              isEditingSummaryDescription: false,
              isEditingSummary: state.isEditingSummary
                ? {
                    ...state.isEditingSummary,
                    description: state.editSummaryDescriptionDialogField,
                  }
                : null,
            });
          },
        },
      },
      [
        Editor({
          title: 'Description',
          subContext: 'summary',
          value: editSummaryDescriptionDialogField,
          enableFormulaInput: true,
          enableTextFormatting: true,
          enableImageInput: true,
          oninput: (value) =>
            this.setState({
              editSummaryDescriptionDialogField: value,
            }),
        }),
      ]
    );
  }

  private async saveSummary() {
    const { isEditingSummary } = this.getState();
    const { cls } = this.getProps();
    if (!isEditingSummary) {
      return;
    }
    if (isEditingSummary.description.trim().length < 1) {
      dispatch(
        AppActions.showError({
          message: 'Please enter a summary description.',
        })
      );
      return;
    }
    await api.summarySave({
      ...isEditingSummary,
      classId: cls._id,
    });
    googleAnalytics.summaryCreated();
    await this.setState({
      isEditingSummary: null,
      loadedSummary: {
        description: isEditingSummary.description,
        attachments: isEditingSummary.attachments,
        links: isEditingSummary.links,
      },
    });
  }

  private addLinkDialog() {
    const { addLinkDialog } = this.getState();
    if (!addLinkDialog) return null;
    return Dialog(
      {
        open: addLinkDialog !== null,
        title: 'Add link',
        secondaryAction: {
          label: 'CANCEL',
          mobileLabel: h('i.fa.fa-times'),
          onclick: () => {
            u.resetTabIndices(document.getElementById('dialog-box'));
            (this.lastFocusedElementSpare as any).focus();
            this.setState({
              addLinkDialog: null,
            });
          },
        },
        style: {
          padding: '0.5rem 1rem',
        },
        primaryAction: {
          label: 'SAVE',
          disabled: !addLinkDialog.title || !addLinkDialog.url || addLinkDialog.urlError,
          mobileLabel: h('i.fa.fa-check'),
          onclick: () => this.addLink(),
        },
      },
      addLinkDialog !== null
        ? [
            h('div', style(['flex', { alignItems: 'flex-end' }]), [
              h('span', style(['large', 'darkBlue', { flex: 1 }]), 'Title'),
              TextField({
                value: addLinkDialog.title,
                errorText: addLinkDialog.titleError ? 'Please fill this field' : undefined,
                oninput: (event) =>
                  this.setState({
                    addLinkDialog: {
                      ...addLinkDialog,
                      title: event.target.value,
                      titleError: false,
                    },
                  }),
                placeholder: 'Type title here',
                style: {
                  flex: 4,
                },
                onenter: () => this.addLink(),
              }),
            ]),
            h('div', style(['flex', mt('1em'), { alignItems: 'flex-end' }]), [
              h('span', style(['darkBlue', 'large', { flex: 1 }]), 'URL'),
              TextField({
                value: addLinkDialog.url,
                placeholder: 'Type the URL here',
                errorText: addLinkDialog.urlError ? 'Please enter a valid URL' : undefined,
                style: {
                  flex: 4,
                },
                oninput: (event) =>
                  this.setState({
                    addLinkDialog: {
                      ...addLinkDialog,
                      url: event.target.value,
                      urlError: false,
                    },
                  }),
                onenter: () => this.addLink(),
              }),
            ]),
          ]
        : []
    );
  }

  private async addLink() {
    const { addLinkDialog, isEditingSummary } = this.getState();

    if (!addLinkDialog) return;
    if (!isEditingSummary) return;
    const { url, title } = addLinkDialog;
    const titleError = title.length < 1;
    const urlError = !anchorme.validate.url(url);
    if (titleError || urlError) {
      await this.setState({
        addLinkDialog: {
          ...addLinkDialog,
          titleError: titleError,
          urlError: urlError,
        },
      });
      return;
    }
    const response = await api.summaryLinkAdd({
      title: title,
      url: url,
      parent: 'class',
    });
    await this.setState({
      addLinkDialog: null,
      isEditingSummary: {
        ...isEditingSummary,
        links: [
          ...isEditingSummary.links,
          {
            title: title,
            url: url,
            linkId: response.data.linkId,
          },
        ],
      },
    }).then(() => {
      const linksBody = document.getElementById('attachments-links');
      u.resetTabIndices(document.getElementById('dialog-box'));
      if (linksBody) {
        linksBody.focus();
      }
    });
  }

  private async editVenue() {
    const cls = this.getProps().cls;
    const state = this.getState();

    await dispatch(
      Actions.venueEdit({
        classId: cls._id,
        isOnlineMeeting: state.isOnlineMeeting,
        newVenue: state.isOnlineMeeting ? '' : state.venueField.trim(),
        multiple: state.multipleField,
        notify: state.notifyField,
      })
    );

    await this.setState({
      isEditVenueDialogOpen: false,
    }).then(() => {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    });
  }

  private isClassEditable() {
    const { isIncharge, cls, course } = this.getProps();
    return (
      isIncharge &&
      !course.isArchived &&
      cls.details.scheStartTime > dt.unix() &&
      cls.details.status === 'open'
    );
  }

  @hideWhenArchived
  private floatingMenu() {
    const isOpen = this.getState().isFloatingMenuOpen;
    const isMobile = getStore().getState().app.isMobile;
    const tapOrClick = isMobile ? 'Tap' : 'Click';
    const floatingMenuLevel = this.getState().floatingMenuLevel;
    const { cls, courseRole, course } = this.getProps();
    if (!course.status.courseLive) return null;
    if (
      courseRole !== 'student' &&
      (cls.details.status === 'closed' ||
        cls.details.status === 'noRecord' ||
        cls.details.status === 'canceled' ||
        cls.details.status === 'holiday')
    ) {
      return null;
    }
    const items =
      this.getState().floatingMenuLevel === 'toBeDone'
        ? [
            this.canAddPreClass()
              ? visibleTo(['class-incharge'], {
                  label: 'Pre-class activity',
                  // onClick: () => this.setState({
                  //     floatingMenuLevel: "preClass"
                  // })
                  tabIndex: this.isAccessible ? (isOpen ? 0 : undefined) : undefined,
                  onClick: this.isWarningMessageInvalid()
                    ? () =>
                        this.setState({
                          floatingMenuLevel: 'preClass',
                        })
                    : () =>
                        this.setState({
                          isPreClassAlertWarningOpen: true,
                        }),
                })
              : null,
            visibleTo(['class-incharge'], {
              label: 'In-class activity',
              tabIndex: this.isAccessible ? (isOpen ? 0 : undefined) : undefined,
              onClick: () =>
                this.setState({
                  floatingMenuLevel: 'inClass',
                }),
            }),
            visibleTo(['student'], {
              label: 'Ask as self',
              tabIndex: this.isAccessible ? (isOpen ? 0 : undefined) : undefined,
              onClick: () => this.newQuery(false),
            }),
            visibleTo(['student'], {
              label: 'Ask Anonymously',
              tabIndex: this.isAccessible ? (isOpen ? 0 : undefined) : undefined,
              onClick: () => this.newQuery(true),
            }),
          ]
        : [
            {
              label: 'Quiz',
              tabIndex: this.isAccessible ? (isOpen ? 0 : undefined) : undefined,
              onClick: () => this.createQuiz(),
            },
            {
              label: 'Poll',
              tabIndex: this.isAccessible ? (isOpen ? 0 : undefined) : undefined,
              onClick: () => this.createPoll(),
            },
            {
              label: 'Discussion',
              tabIndex: this.isAccessible ? (isOpen ? 0 : undefined) : undefined,
              onClick: () => this.createDiscussion(),
            },
            {
              label: 'Resource',
              tabIndex: this.isAccessible ? (isOpen ? 0 : undefined) : undefined,
              onClick: () => this.createResource(),
            },
          ].map((item) => visibleTo(['class-incharge'], item));
    const isStudent = this.getProps().courseRole === 'student';
    if (this.getProps().course.isArchived && isStudent) {
      return null;
    }
    return visibleTo(
      ['student', 'class-incharge'],
      h('div', [
        // adding query option not visible to student after course ends
        courseRole === 'student' && course.dates && course.dates.endDate < dt.unix()
          ? null
          : FloatingActionButton(
              {
                id: 'add-activity-button',
                position: 'bottom-right',
                tabIndex: this.isAccessible ? 0 : undefined,
                ariaLabel: 'Add activity button',
                onclick: () => this.toggleFloatingMenu(),
              },
              [
                isStudent
                  ? Icon(icons.query)
                  : Icon(icons.plus, {
                      className: `class-activities__fab-icon ${isOpen ? 'open' : ''}`,
                    }),
              ]
            ),
        courseRole === 'student' && course.dates && course.dates.endDate < dt.unix()
          ? null
          : TipOverlayWrapper({
              targetElement: 'add-activity-button',
              tip: {
                tipText: isStudent
                  ? 'If you have questions about the topics that will be, are ' +
                    ' being, or were discussed during this class, you can ' +
                    'let the course team know by adding a query'
                  : `${tapOrClick} here to add pre-class or in-class activities`,
                tipPosition: 'left',
              },
              tipKey: isStudent ? 'classMainQueryAdd' : 'classMainFloatingButton',
              isNextAvailable: false,
            }),
        FloatingMenu(
          {
            title: isStudent
              ? 'Ask a query'
              : floatingMenuLevel === 'toBeDone'
              ? 'Add activity'
              : floatingMenuLevel === 'preClass'
              ? 'Pre-class activity type'
              : 'In-class activity type',
            isOpen: isOpen,
            toggleHandler: () =>
              this.getState().floatingMenuLevel === 'toBeDone'
                ? this.toggleFloatingMenu()
                : this.setState({
                    floatingMenuLevel: 'toBeDone',
                  }),
            style: {
              marginBottom: '-1em',
            },
            ariaLabel: isOpen ? 'Ask a query Menu' : '',
            dontCloseOnSelection: true,
          },
          items
        ),
      ])
    );
  }

  private preClassWarningAlert() {
    const isOpen = this.getState().isPreClassAlertWarningOpen;
    return Alert(
      {
        open: isOpen,
        title: 'Not Allowed',
        style: {
          width: '25rem',
        },
        center: true,
        actions: [
          FlatButton('Got it!', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.setState({
                isPreClassAlertWarningOpen: false,
              }),
          }),
        ],
      },
      [
        h(
          'div',
          { tabIndex: this.isAccessible ? 0 : undefined },
          'To give the students enough time, pre-class activities' +
            ' for a class can be created and published till 30 minutes' +
            ' before the class begins. You must create this activity' +
            ' either as an in-class activity for this class or as a ' +
            'pre-class activity for an upcoming class'
        ),
      ]
    );
  }

  private async newQuery(isAnon: boolean) {
    const classId = this.getProps().cls._id;
    const courseId = this.getProps().course._id;
    await this.toggleFloatingMenu();
    Routes.newQuery.navigate({
      classShortId: classService.getShortIdFromClassId(classId),
      courseShortId: courseService.getShortIdFromCourseId(courseId),
      isAnon: isAnon.toString(),
      univSlug: appService.getUniversitySlug(),
    });
  }

  private async createQuiz() {
    const toBeDone = this.getState().floatingMenuLevel;
    if (toBeDone === 'toBeDone') return;
    const newQuiz = await dispatch(
      QuizActions.createQuiz({
        classId: this.getProps().cls._id,
        toBeDone,
      })
    );
    const { course, cls } = this.getProps();
    await this.toggleFloatingMenu();
    u.resetTabIndices();
    Routes.classQuiz.navigate({
      courseShortId: courseService.getShortIdFromCourseId(course._id),
      classShortId: classService.getShortIdFromClassId(cls._id),
      quizShortId: quizService.getShortIdFromQuizId(newQuiz._id),
      univSlug: appService.getUniversitySlug(),
    });
  }

  private async createPoll() {
    const toBeDone = this.getState().floatingMenuLevel;
    if (toBeDone === 'toBeDone') return;
    const newPoll = await dispatch(
      PollActions.createPoll({
        classId: this.getProps().cls._id,
        toBeDone,
      })
    );
    const { course, cls } = this.getProps();
    await this.toggleFloatingMenu();
    u.resetTabIndices();
    Routes.classPoll.navigate({
      courseShortId: courseService.getShortIdFromCourseId(course._id),
      classShortId: classService.getShortIdFromClassId(cls._id),
      pollShortId: pollService.getShortIdFromPollId(newPoll._id),
      univSlug: appService.getUniversitySlug(),
    });
  }

  private async createDiscussion() {
    const toBeDone = this.getState().floatingMenuLevel;
    if (toBeDone === 'toBeDone') return;
    const newDiscussion = await dispatch(
      DiscussionActions.create({
        classId: this.getProps().cls._id,
        toBeDone,
      })
    );
    await this.toggleFloatingMenu();
    u.resetTabIndices();
    const { course, cls } = this.getProps();
    Routes.classDiscussion.navigate({
      courseShortId: courseService.getShortIdFromCourseId(course._id),
      classShortId: classService.getShortIdFromClassId(cls._id),
      discussionShortId: discussionService.getShortIdFromDiscussionId(newDiscussion._id),
      univSlug: appService.getUniversitySlug(),
    });
  }

  private async createResource() {
    const toBeDone = this.getState().floatingMenuLevel;
    if (toBeDone === 'toBeDone') return;
    const newResource = await dispatch(
      ResourceActions.create({
        classId: this.getProps().cls._id,
        toBeDone,
      })
    );
    await this.toggleFloatingMenu();
    u.resetTabIndices();
    const { course, cls } = this.getProps();
    Routes.classResource.navigate({
      courseShortId: courseService.getShortIdFromCourseId(course._id),
      classShortId: classService.getShortIdFromClassId(cls._id),
      resourceShortId: resourceService.getShortIdFromResourceId(newResource._id),
      univSlug: appService.getUniversitySlug(),
    });
  }

  private async toggleFloatingMenu() {
    const isFloatingMenuOpen = this.getState().isFloatingMenuOpen;
    await this.setState({
      isFloatingMenuOpen: !isFloatingMenuOpen,
      floatingMenuLevel: 'toBeDone',
    }).then(() => {
      if (!isFloatingMenuOpen) {
        const container = document.getElementById('item-container');
        if (container !== null && this.isAccessible) {
          u.unsetTabIndices(container);
          container.focus();
        }
      } else {
        const addActivityBtn = document.getElementById('add-activity-button');
        if (addActivityBtn !== null) {
          u.resetTabIndices();
          addActivityBtn.focus();
        }
      }
    });
  }

  private lastFocusedElement: Element | null = null;
  private lastFocusedElementSpare: Element | null = null;
  private async loadClassSummary() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices(document.getElementById('dialog-box'));
    const currentTime = dt.unix();
    await this.setState({
      isLectureSummaryDialogOpen: true,
    });
    const { cls } = this.getProps();
    api.summaryFetch(cls._id).then((response) => {
      this.setState({
        loadedSummary: response.data,
      });
      dispatch(
        Actions.setSummaryAccessedOn({
          time: currentTime,
          classId: cls._id,
        })
      );
    });
  }
}
