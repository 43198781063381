import { h, IComponent } from 'core';

import QuickStartTips from 'acadly/common/QuickStartTips';

class ClassAgendaStartTips extends IComponent<never, never> {
  public render() {
    return QuickStartTips({
      qstKey: 'qst-class-agenda',
      children: ({ wrapper }) => wrapper(this.body()),
    });
  }

  private body = () => {
    return h('div.quick-start-tips__body', [
      h('div.quick-start-tips__section-heading', 'agenda'),
      h('p.quick-start-tips__section-content', [
        'This is the Agenda section meant for the extremely meticulous ',
        'instructors, who want their students to know exactly what ',
        'topics will be covered in a class (lecture)',
      ]),
    ]);
  };
}

export default () => h(ClassAgendaStartTips);
