import { View } from 'core';

import { Actions as AppActions } from 'acadly/app/actions';
import * as dt from 'acadly/datetime';
import { messages } from 'acadly/messages';
import { dispatch } from 'acadly/store';
import * as u from 'acadly/utils';

export type IValidatePublishArg =
  | {
      activityType: 'class';
      activity:
        | IClassActivity
        | {
            nodeType: 'quiz';
            details: {
              numQuestions: number;
              toBeDone: 'preClass' | 'inClass';
            };
          }
        | {
            nodeType: 'poll';
            details: {
              questionSet: 0 | 1;
              toBeDone: 'preClass' | 'inClass';
            };
          }
        | {
            nodeType: 'resource';
            details: {
              toBeDone: 'preClass' | 'inClass';
              resourceType: '' | 'file' | 'link' | 'video';
              url: string;
              fileName: string;
              videoId: string;
              title: string;
            };
          };
      cls:
        | IClass
        | {
            details: {
              status: IClassStatus;
              scheStartTime: UnixTimestamp;
              scheEndTime: UnixTimestamp;
            };
          };
      course:
        | ICourse
        | {
            dates: {
              startDate: UnixTimestamp;
              endDate: UnixTimestamp;
            };
          };
    }
  | {
      activityType: 'assignment';
      activity: IAssignment;
      course: ICourse;
    }
  | {
      activityType: 'announcement';
      title: string;
      course: ICourse;
    }
  | {
      activityType: 'query';
      title: string;
      description: string;
    };

export function validateActivityPublish(arg: IValidatePublishArg) {
  return validate(arg, getActivityPublishValidationMessage);
}

export function getActivityPublishValidationMessage(
  arg: IValidatePublishArg
): string | View[] | null {
  if (arg.activityType === 'query') {
    if (!arg.title.trim()) {
      return messages.query.publish.noTitle;
    } else if (!arg.description.trim()) {
      return messages.query.publish.noDescription;
    } else return null;
  }

  const { course } = arg;

  if (course.dates && course.dates.endDate < dt.unix()) {
    return messages.activity.publish.courseEnded;
  }

  if (arg.activityType === 'class') {
    const cls = arg.cls;
    const activity = arg.activity;

    if (
      u.isIn<IClassStatus>(arg.cls.details.status, ['canceled', 'closed', 'noRecord', 'holiday'])
    ) {
      return messages.activity.publish.nonOngoingOrUpcomingClass;
    }

    if (activity.details.toBeDone === 'preClass') {
      if (!dt.isInFuture(dt.fromUnix(cls.details.scheStartTime), 30, 'minutes')) {
        return messages.activity.publish.preClassTooLate;
      }
    } else {
      if (cls.details.scheStartTime > dt.unix()) {
        return messages.activity.publish.classInFuture(cls);
      } else if (cls.details.status !== 'inSession') {
        return null;
      }
    }

    if (cls.details.scheEndTime < dt.unix()) {
      return messages.activity.publish.classPast;
    }

    if (
      (activity.nodeType === 'poll' || activity.nodeType === 'quiz') &&
      activity.details.toBeDone === 'inClass' &&
      dt.diff(dt.fromUnix(cls.details.scheEndTime), dt.now(), 'minutes') < 2
    ) {
      return messages.activity.publish.classToEndIn2Min;
    }

    if (activity.nodeType === 'poll') {
      if (!activity.details.questionSet) {
        return messages.poll.publish.noQuestion;
      }
    } else if (activity.nodeType === 'quiz') {
      if (activity.details.numQuestions < 1) {
        return messages.quiz.publish.noQuestions;
      }
    } else if (activity.nodeType === 'discussion') {
      if (!activity.details.title.trim()) {
        return messages.discussion.publish.noTitle;
      }
    } else if (activity.nodeType === 'resource') {
      if (!activity.details.title.trim()) {
        return messages.resource.publish.noTitle;
      }
      if (activity.details.resourceType === 'file' && !activity.details.fileName) {
        return messages.resource.publish.noFile;
      } else if (activity.details.resourceType === 'link' && !activity.details.url) {
        return messages.resource.publish.noURL;
      } else if (activity.details.resourceType === 'video' && !activity.details.videoId) {
        return messages.resource.publish.noVideo;
      }
    }
  } else {
    if (arg.activityType === 'announcement') {
      if (!arg.title.trim()) {
        return messages.announcement.publish.noTitle;
      }
    } else if (arg.activityType === 'assignment') {
      const assignment = arg.activity;
      if (assignment.details.numQuestions < 1) {
        return messages.assignment.publish.noQuestions;
      }
    }
  }

  return null;
}

function validate<T>(arg: T, validator: (arg: T) => string | View[] | null): boolean {
  const messages = validator(arg);
  if (messages === null) {
    return true;
  } else {
    showError(messages);
    return false;
  }
}

export interface IValidateSubmitArg {
  allowLate: 0 | 1;
  dueDateTime: UnixTimestamp;
  dueDateType?: ActivityDueDateType;
  serverUnix: UnixTimestamp;
}

export function validateActivitySubmit(arg: IValidateSubmitArg) {
  return validate(arg, getActivitySubmitValidationMessage);
}

export function getActivitySubmitValidationMessage(args: IValidateSubmitArg) {
  if (
    !args.allowLate &&
    ((args.dueDateType === 'manual' && args.dueDateTime !== -1) ||
      (args.dueDateType !== 'manual' && args.dueDateTime < args.serverUnix))
  ) {
    return messages.activity.submit.deadlinePassed;
  } else {
    return null;
  }
}
function showError(messages: string | View[]) {
  window.lastFocusedElement = document.activeElement;
  u.unsetTabIndices();
  dispatch(
    AppActions.showError({
      message: messages,
    })
  );
}
