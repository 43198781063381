import { JSONPromise, jsonRequest } from 'core/http';
import * as http from 'core/http';

import { upload } from 'acadly/upload';
import * as u from 'acadly/utils';

import api from '../api';

export type ICommentsContext =
  | 'course'
  | 'classes'
  | 'assignments'
  | 'quizzes'
  | 'polls'
  | 'discussions'
  | 'queries'
  | 'resources';

export type ICommentsSubContext<Context extends ICommentsContext> = {
  assignments: 'preSub' | 'postSub';
  course: 'preClass';
  classes: 'preClass' | 'inClass';
  quizzes: 'preClass' | 'inClass';
  polls: 'preClass' | 'inClass';
  discussions: 'preClass' | 'inClass';
  queries: 'preClass' | 'inClass' | 'review';
  resources: 'preClass' | 'inClass';
}[Context];

export interface IGetCommentsResponse {
  commentsData: IResponseComment[];
  userData: ObjectMap<Partial<ICommentUserData>>;
  totalCommentsSeen?: number;
  userSubscribed: 0 | 1;
}

export interface IGetNewCommentsRequest {
  context: ICommentsContext;
  contextId: string;
  subContext: ICommentsSubContext<ICommentsContext>;
  lid?: string | null; // last comment id
}

export const getCommentsV2 = (data: IGetNewCommentsRequest): JSONPromise<IGetCommentsResponse> => {
  if (data.lid) {
    return jsonRequest(
      api().getCommentsV2 +
        `?context=${data.context}&contextId=${data.contextId}&subContext=${data.subContext}` +
        `&lid=${data.lid}`,
      {
        method: 'GET',
      }
    );
  } else {
    return jsonRequest(
      api().getCommentsV2 +
        `?context=${data.context}&contextId=${data.contextId}&subContext=${data.subContext}`,
      {
        method: 'GET',
      }
    );
  }
};
export interface ICreateRequest {
  context: ICommentsContext;
  contextId: string;
  classId: string | null;
  subContext: 'preSub' | 'postSub' | 'preClass' | 'inClass' | 'review';
  message: string;
  hasAttachment: 0 | 1;
  attachmentType: 'file' | 'image' | '';
  attachmentName: string;
  attachments: [IAttachment];
  imageUrl: string;
  localTime: string; // YYYYMMDDTHH:mm
}

export const createComment = (data: ICreateRequest) =>
  jsonRequest<IResponseComment>(api().addNewComment, {
    method: 'POST',
    data,
  });

export type IUpdateSeenCommentsRequest = {
  numCommentsSeen: number;
  contextId: string;
} & (
  | {
      context: 'course' | 'classes' | 'quizzes' | 'polls' | 'discussions' | 'queries' | 'resources';
      subContext: null;
    }
  | {
      context: 'assignments';
      subContext: 'preSub' | 'postSub';
    }
);

export const updateSeenComments = (data: IUpdateSeenCommentsRequest, courseId: string) =>
  jsonRequest(api().updateLiveComments, {
    method: 'POST',
    data,
    headers: {
      courseId,
    },
  });

export const uploadFile = async (file: File) => {
  const { name, extension } = u.splitFileName(file.name);
  return await upload(
    api().commentsGetUploadUrl,
    {
      originalFileName: name,
      fileType: extension,
    },
    file
  ).promise;
};

export const uploadImage = async (file: File) => {
  const { name, extension } = u.splitFileName(file.name);
  return await http.upload(api().imageUpload, {
    method: 'POST',
    data: {
      file,
      originalFileName: name,
      fileType: extension,
    },
  }).promise;
};

export const remove = (commentId: string) =>
  jsonRequest(api().commentRemove(commentId), {
    method: 'DELETE',
  });

export interface IMarkRequest<Context extends ICommentsContext> {
  commentId: string;
  contextId: string;
  marked: 'helpful' | 'starred' | 'unhelpful' | 'unstarred';
  context: Context;
  subContext: ICommentsSubContext<Context>;
}

export const mark = <Context extends ICommentsContext>(data: IMarkRequest<Context>) =>
  jsonRequest(api().markComment, {
    method: 'POST',
    data,
  });

export interface IFetchLikesResponse {
  approvers: {
    _id: string;
    name: string;
    avatar: string;
    liked: boolean;
    thanked: boolean;
  }[];
  users: {
    _id: string;
    name: string;
    avatar: string;
    liked: boolean;
    thanked: boolean;
  }[];
}

export interface ICommentAwardRatingRequest {
  commentId: string;
  action: 'awarding' | 'retracting';
  points?: number;
}

export const fetchLikes = (commentId: string) =>
  jsonRequest<IFetchLikesResponse>(api().commentGetLikes(commentId), {
    method: 'GET',
  });

export const awardRating = (data: ICommentAwardRatingRequest) =>
  jsonRequest(api().commentAward, {
    method: 'POST',
    data,
  });

export interface ICommentSubscribeRequest {
  context: ICommentsContext;
  contextId: string;
  classId: string | null;
  subscribeToComments: 0 | 1;
}

export const commentSubscribe = (data: ICommentSubscribeRequest) =>
  jsonRequest(api().commentSubscription, {
    method: 'POST',
    data,
  });
