import { pick } from 'lodash';

import { Actions as AppActions } from 'acadly/app/actions';
import { googleAnalytics } from 'acadly/app/GoogleAnalytics';
import { Actions as classActions } from 'acadly/class/actions';
import { createAction, Thunk } from 'acadly/createAction';
import * as dt from 'acadly/datetime';
import { IPusherPayload } from 'acadly/pusher';
import { Routes } from 'acadly/routes';

import * as api from './api';
import discussionService from './service';

export type IDiscussionActionMap = {
  '@discussion/CREATE_SUCCESS': ICreateSuccessPayload;
  '@discussion/EDIT_SUCCESS': IEditSuccessPayload;
  '@discussion/DELETE_SUCCESS': IDeleteSuccessPayload;
  '@discussion/FETCH_PUBLISH_PREFS_SUCCESS': DiscussionPublishDefaults;
  '@discussion/PUBLISH_SUCCESS': IPublishSuccessPayload;
  '@discussion/UPDATE_PUBLISHED_SUCCESS': api.IUpdatePublishedRequest;
  '@discussion/ANALYTICS_SET_SUCCESS': IAnalyticsSetSuccessPayload;
  '@discussion/all/FETCH_SUCCESS': IFetchAllSuccessPayload;
  '@discussion/pusher/PUBLISH': IPusherPublishPayload;
  '@discussion/MARK_PARTICIPATION': IMarkParticipationPayload;
  '@discussion/UPDATE_WORD_CLOUD': IWordCloud;
  '@discussion/WORD_CLOUD_AVAILABLE': IWordCloudAvailableEventData;
};

export interface IMarkParticipationPayload {
  discussionId: string;
  timestamp: UnixTimestamp;
}

export type IPusherPublishPayload = IPusherPayload<{
  classId: string;
  classNum: number;
  discussionId: string;
  details: IResponseDiscussionDetails;
  stats: {
    numSubmitted: number;
  };
  activities: {
    numCommentsTotal: number;
  };
}>;

export type IFetchAllSuccessPayload = api.IFetchAllResponse;

export type IAnalyticsSetSuccessPayload = {
  classId: string;
  toBeDone: 'preClass' | 'inClass';
  firstAccess: 0 | 1;
  firstAccessedOn: UnixTimestamp;
  discussionId: string;
};

export type IPublishSuccessPayload = api.IPublishRequest & api.IPublishResponse;

export type ICreateSuccessPayload = {
  classId: string;
  discussion: IDiscussion;
  publishDefaults: DiscussionPublishDefaults;
};

export type IDeleteSuccessPayload = api.IDeleteRequest;

export type IEditSuccessPayload = api.IEditRequest;

export type IUpdatePublishedRequest = api.IUpdatePublishedRequest;

type IGenerateWCSuccess = {
  status: 'success';
  data: IWordCloud;
};

type IGenerateWCFailure = {
  status: 'failed';
  message: string;
};

export const Actions = {
  createSuccess: createAction('@discussion/CREATE_SUCCESS'),
  create:
    (data: api.ICreateRequest): Thunk<IDiscussion> =>
    async (dispatch) => {
      const response = await api.create(data);
      googleAnalytics.activityCreated('discussion', data.toBeDone);
      const discussion: IDiscussion = {
        _id: response.data._id,
        identifiers: {
          classId: data.classId,
        },
        nodeType: 'discussion',
        details: {
          ...response.data.details,
          published: 0,
          publishedOn: 0,
        },
        activities: response.data.activities,
        stats: response.data.stats,
      };
      dispatch(
        Actions.createSuccess({
          discussion: discussion,
          classId: data.classId,
          publishDefaults: response.data.publishDefaults,
        })
      );
      return discussion;
    },

  editSuccess: createAction('@discussion/EDIT_SUCCESS'),
  edit:
    (data: api.IEditRequest): Thunk<void> =>
    async (dispatch) => {
      await api.edit(data);
      dispatch(Actions.editSuccess(data));
    },

  deleteSuccess: createAction('@discussion/DELETE_SUCCESS'),
  deleteDiscussion:
    (data: api.IDeleteRequest, beforeDispatch?: () => void): Thunk<void> =>
    async (dispatch) => {
      await api.deleteDiscussion(data);
      if (beforeDispatch) {
        await beforeDispatch();
      }
      dispatch(Actions.deleteSuccess(data));
    },

  fetchPublishPrefsSuccess: createAction('@discussion/FETCH_PUBLISH_PREFS_SUCCESS'),
  fetchPublishPrefs:
    (data: api.FetchPublishPrefsRequest): Thunk<DiscussionPublishDefaults> =>
    async (dispatch) => {
      const response = await api.fetchPublishPrefs(data);
      const { publishPref } = response.data;
      dispatch(Actions.fetchPublishPrefsSuccess(publishPref));
      return publishPref;
    },

  publishSuccess: createAction('@discussion/PUBLISH_SUCCESS'),
  publish:
    (data: api.IPublishRequest): Thunk<void> =>
    async (dispatch) => {
      const response = await api.publish(data);
      googleAnalytics.activityPublished('discussion', data.toBeDone);
      dispatch(
        Actions.publishSuccess({
          ...data,
          ...response.data,
        })
      );
    },

  updatePublishedSuccess: createAction('@discussion/UPDATE_PUBLISHED_SUCCESS'),
  updatePublished:
    (data: api.IUpdatePublishedRequest): Thunk<void> =>
    async (dispatch) => {
      await api.updatePublished(data);
      // googleAnalytics.activityPublished("discussion", data.toBeDone);
      dispatch(Actions.updatePublishedSuccess(data));
    },

  analyticsSetSuccess: createAction('@discussion/ANALYTICS_SET_SUCCESS'),
  analyticsSet:
    (data: {
      request: api.IAnalyticsSetRequest;
      toBeDone: 'preClass' | 'inClass';
      classId: string;
    }): Thunk<void> =>
    async (dispatch) => {
      await api.analyticsSet(data.request);
      dispatch(
        classActions.incrementClassSeenActivities({
          classId: data.classId,
          toBeDone: data.toBeDone,
          activityKey: 'discussions',
        })
      );
      dispatch(
        Actions.analyticsSetSuccess({
          firstAccess: data.request.firstAccess,
          classId: data.classId,
          firstAccessedOn: dt.unix(),
          discussionId: data.request.discussionId,
          toBeDone: data.toBeDone,
        })
      );
    },

  fetchAllSuccess: createAction('@discussion/all/FETCH_SUCCESS'),
  fetchAll: (): Thunk<string[]> => async (dispatch) => {
    const response = await api.fetchAll();
    dispatch(Actions.fetchAllSuccess(response.data));
    return response.data.activityData.map((r) => r._id);
  },

  pusherPublish: createAction('@discussion/pusher/PUBLISH'),

  markParticipation: createAction('@discussion/MARK_PARTICIPATION'),

  updateWordCloud: createAction('@discussion/UPDATE_WORD_CLOUD'),
  generateWordCloud:
    (discussionId: string): Thunk<IGenerateWCSuccess | IGenerateWCFailure> =>
    async (dispatch) => {
      const response = await api.createWordCloud(discussionId);

      if (response.status >= 500) {
        dispatch(
          AppActions.showError({
            message: "Our servers didn't respond. Please try after some time.",
          })
        );
        return {
          status: 'failed',
          message: '',
        };
      } else if (response.status >= 400) {
        return {
          status: 'failed',
          message: (response.data as any).message,
        };
      } else {
        const wordCloud = {
          discussionId,
          ...response.data,
        };
        await dispatch(Actions.updateWordCloud(wordCloud));
        return {
          status: 'success',
          data: wordCloud,
        };
      }
    },

  fetchWordCloud:
    (discussionId: string): Thunk<IWordCloud> =>
    async (dispatch) => {
      const response = await api.fetchWordCloud(discussionId);
      const wordCloud: IWordCloud = {
        discussionId,
        ...response.data,
      };
      await dispatch(Actions.updateWordCloud(wordCloud));
      return wordCloud;
    },

  editWordCloud:
    (discussionId: string, removedWords: string[]): Thunk<void> =>
    async (dispatch) => {
      const response = await api.editWordCloud(discussionId, removedWords);
      await dispatch(
        Actions.updateWordCloud({
          discussionId,
          ...response.data,
        })
      );
    },

  wordCloudAvailable: createAction('@discussion/WORD_CLOUD_AVAILABLE'),
  wordCloudGenerated:
    (eventData: IWordCloudGeneratedEventData): Thunk<void> =>
    async (dispatch) => {
      const match = Routes.classDiscussion.getMatch() || Routes.filteredDiscussion.getMatch();
      if (match) {
        const discussionId = discussionService.getDiscussionIdFromShortId(match.discussionShortId);
        if (discussionId == null || discussionId !== eventData.activityId) {
          // do not update the state if other discussion is active
          return;
        }
      }
      await dispatch(
        Actions.updateWordCloud({
          discussionId: eventData.activityId,
          ...pick(
            eventData,
            'image',
            'generatedAt',
            'commentsUsed',
            'totalComments',
            'wordList',
            'removedWords'
          ),
        })
      );
    },
};
