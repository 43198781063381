import { h, IComponent } from 'core';

import * as CloseIcon from 'assets/close.svg';

import Alert from 'acadly/common/Alert';
import SvgIcon from 'acadly/common/SvgIcon';
import { Actions as CourseActions } from 'acadly/course/actions';
import courseService from 'acadly/course/service';
import { dispatch } from 'acadly/store';
import { pad, style } from 'acadly/styles';

import PaymentFailedStep from './PaymentFailedStep';
import PaymentStep from './PaymentStep';
import PaymentSuccessStep from './PaymentSuccessStep';

type IJoinCoursePaymentStep = 'payment' | 'payment_failed' | 'payment_success';

interface IJoinCoursePaymentProps {
  open: boolean;
  onClose: (reload: boolean) => any;
}

interface IJoinCoursePaymentState {
  currentStep: IJoinCoursePaymentStep;
  paymentError: string;
}

class JoinCoursePayment extends IComponent<IJoinCoursePaymentProps, IJoinCoursePaymentState> {
  private getTitle() {
    const { currentStep } = this.getState();

    switch (currentStep) {
      case 'payment':
        return PaymentStep.getTitle();
      case 'payment_success':
        return PaymentSuccessStep.getTitle();
      case 'payment_failed':
        return PaymentFailedStep.getTitle();
      default:
        return '';
    }
  }

  private init() {
    this.setState({
      currentStep: 'payment',
      paymentError: '',
    });
  }

  public componentWillMount() {
    this.init();
  }

  private getBody() {
    const { onClose } = this.getProps();
    const { currentStep, paymentError } = this.getState();

    switch (currentStep) {
      case 'payment':
        return h(PaymentStep, {
          onCancel: () => {
            onClose(false);
          },
          onNext: (error) => {
            this.setState({ currentStep: error ? 'payment_failed' : 'payment_success' });
          },
        });
      case 'payment_success':
        return h(PaymentSuccessStep, {
          onClose: () => {
            const courseId = courseService.getCurrentCourseId();
            dispatch(CourseActions.studentPurchaseSuccessful({ courseId }));
            onClose(true);
          },
        });
      case 'payment_failed':
        return h(PaymentFailedStep, {
          error: paymentError,
          onClose: () => {
            onClose(false);
          },
          onRetry: () => {
            this.init();
          },
        });
      default:
        return undefined;
    }
  }

  public render() {
    const { open, onClose } = this.getProps();
    const { currentStep } = this.getState();

    if (!open) return null;

    return Alert(
      {
        open,
        title: h(
          'div',
          style(['flex', 'alignCenter', 'spaceBetween', 'bold', 'fullWidth', pad('0.5rem 1rem')]),
          [
            this.getTitle(),
            SvgIcon({
              component: 'button',
              icon: CloseIcon,
              style: {
                cursor: 'pointer',
                width: 24,
                height: 24,
              },
              onclick: () => {
                onClose(currentStep === 'payment_success');
              },
            }),
          ]
        ),
        titleStyle: {
          borderBottom: '1px solid #bbb',
        },
        style: {
          padding: 0,
          width: '35em',
        },
        bodyStyle: {
          padding: '1rem',
        },
      },
      [h('div.join-course-payment', [this.getBody()])]
    );
  }
}

export default (props: IJoinCoursePaymentProps) => h(JoinCoursePayment, props);
