import * as pathToRegexp from 'path-to-regexp';

const patternCache: any = {};
const cacheLimit = 10000;
let cacheCount = 0;

const compilePath = (pattern: any, options: any) => {
  const cacheKey = `${options.end}${options.strict}${options.sensitive}`;
  const cache = patternCache[cacheKey] || (patternCache[cacheKey] = {});

  if (cache[pattern]) return cache[pattern];

  const keys: any = [];
  const re = pathToRegexp(pattern, keys, options);
  const compiledPattern = { re, keys };

  if (cacheCount < cacheLimit) {
    cache[pattern] = compiledPattern;
    cacheCount++;
  }

  return compiledPattern;
};

interface MatchOptions {
  path?: string;
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

/**
 * Public API for matching a URL pathname to a path pattern.
 */
const matchPath = (pathname: any, options: MatchOptions | string = {}) => {
  if (typeof options === 'string') options = { path: options };

  const { path = '/', exact = false, strict = false, sensitive = false } = options;
  const { re, keys } = compilePath(path, { end: exact, strict, sensitive });
  const match = re.exec(pathname);

  if (!match) return null;

  const [url, ...values] = match;
  const isExact = pathname === url;

  if (exact && !isExact) return null;

  return {
    path, // the path pattern used to match
    url: path === '/' && url === '' ? '/' : url, // the matched portion of the URL
    isExact, // whether or not we matched exactly
    params: keys.reduce((memo: any, key: any, index: any) => {
      memo[key.name] = values[index];
      return memo;
    }, {}),
  };
};

export default matchPath;
