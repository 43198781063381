import { h, IComponent } from 'core';

import { Actions } from 'acadly/class/actions';
import ContentView from 'acadly/common/ContentView';
import Dialog from 'acadly/common/Dialog';
import Paper from 'acadly/common/Paper';
import RadioButton from 'acadly/common/RadioButton';
import Editor from 'acadly/rich-text/Editor';
import Viewer from 'acadly/rich-text/Viewer';
import { dispatch, getStore } from 'acadly/store';
import { colors, margin, mt, pad, style } from 'acadly/styles';
import * as u from 'acadly/utils';

export interface IAgendaProps {
  cls: IClass;
  courseRole: ICourseRole;
  isIncharge: boolean;
}

export interface IAgendaState {
  isEditingOutline: boolean;
  isEditingTeam: boolean;
  outlineField: string;
  assistantsField: {
    userId: string;
    avatar: string;
    name: string;
    role: 'ta';
  }[];

  isEditingTopics: boolean;
  topicsField: string[]; // topic ids

  isTrueForSimilarLectures: boolean;
}

export class Agenda extends IComponent<IAgendaProps, IAgendaState> {
  private isAccessible: boolean | undefined = false;
  public componentDidMount() {
    const tabs = document.getElementById('sliding-tabs');
    if (tabs) {
      tabs.focus();
    }
  }

  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const initialState: IAgendaState = {
      isEditingOutline: false,
      isEditingTeam: false,
      outlineField: '',
      assistantsField: [],
      topicsField: [],
      isEditingTopics: false,
      isTrueForSimilarLectures: false,
    };
    this.setState(initialState);
    if (document.activeElement && !this.isAccessible) {
      (<HTMLElement>document.activeElement).blur();
    }
  }
  public render() {
    return ContentView(
      h(
        'div.class-agenda',
        this.isEditingEnabled() ? this.editableScreen() : this.nonEditableScreen()
      )
    );
  }

  private isEditingEnabled() {
    const props = this.getProps();
    return props.cls.details.status === 'open' && props.isIncharge;
  }

  private editableScreen() {
    return [
      this.outlineCard(),
      // this.teachingAssistantsCard(),
      this.topicsCard(),

      this.editOutlineDialog(),
      // this.editTeamDialog()
    ];
  }

  private editOutlineDialog() {
    const state = this.getState();
    return Dialog(
      {
        open: state.isEditingOutline,
        title: 'Editing outline',
        thinHeader: true,
        secondaryAction: {
          label: 'Cancel',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              isEditingOutline: false,
            }).then(() => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
            }),
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          disabled: !state.outlineField.trim(),
          onclick: () => this.editOutlineDialogSave(),
        },
      },
      [
        Editor({
          subContext: 'description',
          value: state.outlineField,
          placeholder: 'Enter an outline for this class here',
          enableFormulaInput: true,
          enableTextFormatting: true,
          enableImageInput: true,
          oninput: (text) =>
            this.setState({
              outlineField: text,
            }),
        }),
      ]
    );
  }

  private async editOutlineDialogSave() {
    const props = this.getProps();
    const state = this.getState();
    await dispatch(
      Actions.agendaEdit({
        classId: props.cls._id,
        agenda: state.outlineField,
      })
    );
    await this.setState({
      isEditingOutline: false,
    }).then(() => {
      u.resetTabIndices();
      (this.lastFocusedElement as HTMLElement).focus();
    });
  }

  private nonEditableScreen() {
    const props = this.getProps();

    const cardHeader = (title: string) =>
      h(
        'div.panel__header',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': title,
        },
        title
      );

    const placeholder = (text: string) =>
      h(
        'div.panel__content.fs-sm.fc-light-grey',
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          'aria-label': text,
        },
        text
      );

    return [
      h('div.panel', [
        cardHeader('Outline'),
        props.cls.info.agenda
          ? h('div.panel__content', [Viewer(props.cls.info.agenda)])
          : placeholder('The class in-charge has not added a class outline yet.'),
      ]),
      Paper(style([mt('0.5rem')]), [
        cardHeader('Topics'),
        props.cls.info.topicIds.length > 0
          ? this.topicsList()
          : placeholder(
              'The class in-charge has not tagged this class ' + 'with any syllabus topics yet.'
            ),
      ]),
    ];
  }

  private outlineCard() {
    const props = this.getProps();
    const outline = props.cls.info.agenda;
    return h('div.panel', this.editableCardAttrs(), [
      h('div.panel__header', [
        h('span', 'Outline'),
        h('i.fa.fa-pencil.ripple', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => this.openOutlineEditor(),
        }),
      ]),
      h('div.panel__content', [
        outline
          ? Viewer(outline)
          : h('div.fc-light-grey.fs-sm', [
              'No outline has been added for this class yet. ',
              'Click the ',
              h('i.fa.fa-pencil'),
              ' button ',
              'to add a class outline.',
            ]),
      ]),
    ]);
  }
  private lastFocusedElement: null | Element = null;
  private async openOutlineEditor() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    const props = this.getProps();
    await this.setState({
      isEditingOutline: true,
      outlineField: props.cls.info.agenda,
    });
  }

  private editableCardAttrs() {
    return { tabIndex: this.isAccessible ? 0 : undefined };
  }

  private topicsCard() {
    const props = this.getProps();
    const cls = props.cls;
    const topicIds = cls.info.topicIds;
    return h('div.panel', this.editableCardAttrs(), [
      h('div.panel__header', [
        h('span', 'Topics'),
        h('i.fa.fa-pencil.ripple', {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => this.openTopicEditor(),
        }),
      ]),
      topicIds.length > 0
        ? this.topicsList()
        : h('div.panel__content.fs-sm.fc-light-grey', [
            'Tag this class with the topics that ',
            'you may have added in the course syllabus.',
          ]),
      this.editTopicsDialog(),
    ]);
  }

  private topicsList() {
    const props = this.getProps();
    const topics = getStore().getState().courses.topics;
    const cls = props.cls;
    return h(
      'div.panel__content',
      topics
        .filter((t) => cls.info.topicIds.includes(t._id))
        .map((rootTopic) =>
          h(
            'div',
            style(
              [margin('0.5rem 1rem')],
              {},
              {
                tabIndex: this.isAccessible ? 0 : undefined,
              }
            ),
            rootTopic.topics
              .filter((sub) => cls.info.topicIds.includes(sub.topicId))
              .map((subTopic) =>
                h(
                  'div',
                  style(
                    [
                      pad('1rem 0rem'),
                      'borderBox',
                      {
                        borderBottom: '1px solid lightGrey',
                        marginLeft: subTopic.topicId === rootTopic._id ? undefined : '0.5rem',
                      },
                    ],
                    {},
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                    }
                  ),
                  subTopic.details.title
                )
              )
          )
        )
    );
  }

  private editTopicsDialog() {
    const state = this.getState();
    const topics = getStore().getState().courses.topics;
    const renderTopic = (parent: ITopic, topic: ITopicChild, index: number) => {
      const selected = state.topicsField.includes(topic.topicId);
      return h(
        'div',
        style(
          ['flex', 'alignCenter'],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            key: `topic-row-${topic.topicId}`,
            onclick: async () => {
              if (selected) {
                const subtopicIds = parent.topics.map((t) => t.topicId);
                await this.setState({
                  topicsField: state.topicsField
                    .filter((id) => topic.topicId !== id)
                    .filter(
                      parent._id === topic.topicId
                        ? // filter out all all child topics if
                          // this is a root topic
                          (topicId) => !subtopicIds.includes(topicId)
                        : // otherwise don't filter out anything
                          () => true
                    ),
                });
              } else {
                await this.setState({
                  topicsField: [...state.topicsField, topic.topicId].concat(
                    state.topicsField.includes(topic.parentId) ? [] : [topic.parentId]
                  ),
                });
              }
            },
          }
        ),
        [
          h(
            'div',
            style([
              'flex',
              'alignCenter',
              'justifyCenter',
              {
                width: '3em',
              },
            ]),
            [
              RadioButton({
                selected: selected,
                color: colors.teal,
                style: {
                  marginLeft: index === 0 ? undefined : '0.5rem',
                },
              }),
            ]
          ),
          h(
            'div',
            style([
              pad('0.5rem 1rem'),
              {
                flex: '1',
                borderBottom: '1px solid lightGrey',
              },
            ]),
            topic.details.title
          ),
        ]
      );
    };

    return Dialog(
      {
        open: state.isEditingTopics,
        title: 'Class topics',
        style: {
          backgroundColor: colors.backgroundColor,
        },
        secondaryAction: {
          label: 'Cancel',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              isEditingTopics: false,
            }).then(() => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
            }),
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () =>
            this.saveTopics().then(() => {
              u.resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
            }),
        },
      },
      [
        h('div', style(['lightGrey', 'small', pad('0.5rem')]), 'Select the class topics'),
        ...topics.map((parent) =>
          h(
            'div',
            style(
              ['whiteBackground'],
              {},
              {
                tabIndex: this.isAccessible ? 0 : undefined,
                key: parent._id,
              }
            ),
            parent.topics.map((topic, index) => renderTopic(parent, topic, index))
          )
        ),
      ]
    );
  }

  private async saveTopics() {
    const props = this.getProps();
    const state = this.getState();
    const topicIds = state.topicsField;
    const topicIdsToBeSent = topicIds.filter((x, i, a) => a.indexOf(x) === i);
    await dispatch(
      Actions.topicsEdit({
        classId: props.cls._id,
        topicIds: topicIdsToBeSent,
      })
    );
    await this.setState({
      isEditingTopics: false,
    });
  }

  private async openTopicEditor() {
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    const props = this.getProps();
    await this.setState({
      isEditingTopics: true,
      topicsField: props.cls.info.topicIds,
    });
  }
}

export default (props: IAgendaProps) => h(Agenda, props);
