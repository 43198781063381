import { h, IComponent, View } from 'core';
import history from 'core/history';

import AcadlyEvents from 'acadly/AcadlyEvents';
import { Actions as appActions } from 'acadly/app/actions';
import { consent, promoSeen } from 'acadly/app/api';
import { assignmentService } from 'acadly/assignment/service';
import classService from 'acadly/class/service';
import { Actions as CommentActions } from 'acadly/comments/actions';
import { Actions as commentActions } from 'acadly/comments/actions';
import { ICommentSubscribeRequest } from 'acadly/comments/api';
import { getCommentsContextService, ICommentsContextService } from 'acadly/comments-context';
import AudioPlayer from 'acadly/common/AudioPlayer';
import Avatar from 'acadly/common/Avatar';
import CtaButton from 'acadly/common/CtaButton';
import { Icon } from 'acadly/common/Icon';
import { fullScreenLoader } from 'acadly/common/Loader';
import Paper from 'acadly/common/Paper';
import ToastManager from 'acadly/common/Toast';
import VideoJSPlayer from 'acadly/common/VideoJSPlayer';
import courseService from 'acadly/course/service';
import discussionService from 'acadly/discussion/service';
import { Actions as GetinActions } from 'acadly/getin/actions';
import icons from 'acadly/icons';
import { PipContainerActions } from 'acadly/pip-container/actions';
import { PipContainer } from 'acadly/pip-container/PipContainer';
import ZoomMeeting from 'acadly/pip-container/ZoomMeeting';
import pollService from 'acadly/poll/service';
import { pusherService } from 'acadly/pusher';
import quizService from 'acadly/quiz/service';
import resourceService from 'acadly/resource/service';
import { Routes } from 'acadly/routes';
import { validateEmail } from 'acadly/utils';
import * as u from 'acadly/utils';
import { getQueryParams } from 'acadly/utils/queryParams';

import Alert from '../common/Alert';
import Drawer from '../common/Drawer';
import FlatButton from '../common/FlatButton';
import { Actions as CourseActions } from '../course/actions';
import { dispatch, getStore } from '../store';
import {
  backgroundColor,
  colors,
  mb,
  ml,
  mt,
  pad,
  pb,
  pl,
  sidebarWidthPixels,
  style,
} from '../styles';
import { Actions } from './actions';
import Analytics from './Analytics';
import ContextPanel from './ContextPanel';
import FeedbackDialog from './FeedbackDialog';
import Header from './Header';
import Main from './Main';
import ProxyAttendancePip from './ProxyAttendancePip';
import appService from './service';
import Sidebar from './Sidebar';

type IAppContainerProps = Record<string, never>;

interface IAppContainerState {
  contextOpen: boolean;
  sidebarOpen: boolean;
  isFeedbackDialogOpen: boolean;
  isConsentDialogOpen: boolean;
  consentMessage: string | undefined;
  isTermsAccepted: boolean;
  isPolicyAccepted: boolean;
  isSubscribeOpen: boolean;
  userSubscribe: boolean;
  hasJoinedVCall: boolean;
  isPromoDialogOpen: boolean;
  isSwitchAccountDialogOpen: boolean;
}

export default (props: IAppContainerProps) => h(App, props);

export class App extends IComponent<IAppContainerProps, IAppContainerState> {
  public componentWillMount() {
    ga('landing.send', 'pageview', '/home');

    const storeState = getStore().getState();
    const narrowScreen = storeState.app.narrowScreen;
    const isSubscribed = storeState.app.isCurrentActivitySubscribed;

    const initialState: IAppContainerState = {
      contextOpen: false,
      sidebarOpen: false,
      isFeedbackDialogOpen: false,
      isConsentDialogOpen: false,
      consentMessage: undefined,
      isTermsAccepted: false,
      isPolicyAccepted: false,
      userSubscribe: isSubscribed,

      isSubscribeOpen: false,
      hasJoinedVCall: false,

      isPromoDialogOpen: false,
      isSwitchAccountDialogOpen: false,
    };

    this.setState(initialState);

    try {
      pusherService.start();
      pusherService.connectUserChannel();
    } catch {
      // swallow error
    }

    this.getConsentStatus();

    dispatch(CourseActions.fetchMyCourses());

    dispatch(Actions.setNarrowScreen(narrowScreen));

    dispatch(GetinActions.fetchAvatars([getStore().getState().getIn.session!.avatar]));

    history.listen(() => {
      const storeState = getStore().getState();
      const cls = classService.getCurrentClass();
      const { classMediaPlayer } = storeState.pipContainers;

      if (!classMediaPlayer.show || !this.classMediaPlayerPip) return;

      const { classId, embedTargetSelector } = classMediaPlayer;

      if (cls && cls._id === classId && Routes.classActivities.isActive(true)) {
        this.classMediaPlayerPip.tryEmbeddedMode(embedTargetSelector);
      } else {
        this.classMediaPlayerPip.setPipMode();
      }
    });

    this.parseQueryParams();
  }

  private parseQueryParams() {
    const { action, emailId } = getQueryParams();
    const session = getStore().getState().getIn.session;

    if (session && session.email !== emailId && action === 'login' && validateEmail(emailId)) {
      this.setState({ isSwitchAccountDialogOpen: true });
    }
  }

  public componentWillUnmount() {
    pusherService.disconnectUserChannel();
  }

  private async getConsentStatus() {
    const response = await dispatch(Actions.initialize());
    if (response.needConsent === 1) {
      await this.setState({
        isConsentDialogOpen: true,
        consentMessage: response.message,
      });
    } else if (response.showProPromo) {
      this.setState({ isPromoDialogOpen: true });
    } else {
      dispatch(appActions.startTip(true));
    }
  }

  public render() {
    const storeState = getStore().getState();

    return h('div.app', [
      h('div', { key: 'app' }, [
        storeState.app.narrowScreen && this.isSidebarOpen()
          ? h('div.app__overlay', {
              onclick: () => this.toggleSidebar(),
            })
          : null,
        Drawer(
          {
            docked: true,
            classNames: ['app__sidebar-wrapper'],
            open: this.isSidebarOpen(),
          },
          [
            Sidebar({
              closeSidebar: () => {
                setTimeout(() => {
                  this.setState({
                    sidebarOpen: false,
                  });
                });
              },
              openFeedbackDialog: () =>
                this.setState({
                  isFeedbackDialogOpen: true,
                }),
            }),
          ]
        ),

        h('div.app__main', { key: 'main-container' }, [
          Header({
            toggleSidebar: this.toggleSidebar.bind(this),
            toggleContextPanel: this.toggleContextPanel.bind(this),
          }),
          storeState.courses.isLoadingCourses
            ? fullScreenLoader
            : h(
                'div.main-wrapper',
                {
                  style: {
                    // height: `${
                    //     document.body.clientHeight -
                    //     getStore().getState().app.headerHeight}px`,
                    position: 'relative',
                    backgroundColor,
                  },
                },
                [Main(), this.analyticsPanel()]
              ),
        ]),

        (storeState.app.narrowScreen || this.isContextPanelExpanded()) && this.isContextPanelOpen()
          ? h('div.app__overlay', {
              onclick: this.isContextPanelExpanded()
                ? () => {
                    u.resetTabIndices();
                    dispatch(Actions.toggleContextPanelExpand(!this.isContextPanelExpanded()));
                  }
                : () => this.toggleContextPanel(),
            })
          : null,

        Drawer(
          {
            docked: true,
            id: 'context-panel-drawer',
            classNames: [
              'app__context-panel-wrapper',
              this.isContextPanelExpanded() ? 'expanded' : '',
            ],
            position: 'right',
            open: this.isContextPanelOpen(),
          },
          [
            ContextPanel({
              toggleContextPanel: this.toggleContextPanel.bind(this),
              isExpanded: this.isContextPanelExpanded(),
              toggleExpand: this.toggleContextPanelExpand,
              toggleSubscribe: this.openSubscribeDialog.bind(this),
            }),
          ]
        ),

        ZoomMeeting(),
        this.promoDialog(),
        this.feedbackDialog(),
        this.notifications(),
        ToastManager.container(),
        this.termsDialog(),
        this.subscribeDialog(),
        this.switchAccountDialog(),
        this.classMediaPlayer(),
        this.notificationPermissionAlert(),
        ProxyAttendancePip.isVisible ? h(ProxyAttendancePip) : null,
        this.attendanceScheduledToStartAlert(),
      ]),
    ]);
  }

  private attendanceScheduledToStartAlert() {
    const { showAttendanceScheduledToStartAlert } = getStore().getState().app;

    if (!showAttendanceScheduledToStartAlert) return null;

    const { classId, courseId } = showAttendanceScheduledToStartAlert;

    const Bullet = (children: View | View[]) => {
      return h('li', style([mb('0.75rem')]), Array.isArray(children) ? children : [children]);
    };

    return Alert(
      {
        open: true,
        title: 'Attendance scheduled to start',
        style: { width: '25rem' },
        actions: [
          FlatButton('Dismiss', {
            type: 'secondary',
            ariaLabel: 'dismiss, button',
            onclick: () => {
              dispatch(appActions.toggleAttendanceScheduledToStartAlert({ show: false }));
            },
          }),
          FlatButton('GO TO CLASS', {
            type: 'primary',
            ariaLabel: 'go to class, button',
            onclick: async () => {
              dispatch(appActions.toggleAttendanceScheduledToStartAlert({ show: false }));
              Routes.classActivities.navigate({
                courseShortId: courseService.getShortIdFromCourseId(courseId),
                classShortId: classService.getShortIdFromClassId(classId),
                univSlug: appService.getUniversitySlug(),
              });
            },
          }),
        ],
      },
      [
        h('div', [
          `Attendance process to discover in-person attendees will start in the `,
          `next couple of minutes, please ensure`,
        ]),
        h('ul', style([mt('1rem'), pad('0 1rem')]), [
          Bullet([
            `Students have `,
            h(
              'a.no-break',
              {
                target: '_blank',
                href: 'https://itunes.apple.com/us/app/acadly/id1161073387?mt=8',
              },
              `Acadly's iOS`
            ),
            ' or ',
            h(
              'a.no-break',
              {
                target: '_blank',
                href: 'https://play.google.com/store/apps/details?id=co.acetone.acadly',
              },
              `Android app`
            ),
            ` (NOT the Acadly website) `,
            `open on their mobile devices`,
          ]),
          Bullet(`Students' mobile devices are connected to the internet`),
          Bullet(`Students have granted Acadly app the necessary permissions`),
        ]),
      ]
    );
  }

  private notificationPermissionAlert() {
    const { showNotificationPermissionAlert, acc } = getStore().getState().app;
    const isAccessible = acc.web.turnOff === 0;

    if (!showNotificationPermissionAlert) return null;

    return Alert(
      {
        open: true,
        title: h('div.fc-orange', 'Allow desktop notifications'),
        style: { width: '25rem' },
        actions: [
          FlatButton('Cancel', {
            type: 'secondary',
            ariaLabel: 'cancel, button',
            tabIndex: isAccessible ? 0 : undefined,
            onclick: () => {
              dispatch(appActions.toggleNotificationPermissionAlert(false));
            },
          }),
          FlatButton('Okay', {
            type: 'primary',
            ariaLabel: 'okay, button',
            tabIndex: isAccessible ? 0 : undefined,
            onclick: async () => {
              await Notification.requestPermission();
              dispatch(appActions.toggleNotificationPermissionAlert(false));
            },
          }),
        ],
      },
      [
        h('div', [
          'Acadly will send desktop notifications only while an online meeting is in progress',
        ]),
      ]
    );
  }

  private classMediaPlayerPip: PipContainer;
  private classMediaPlayer() {
    const pipContainerState = getStore().getState().pipContainers;
    const { url, show, embedTargetSelector, type } = pipContainerState.classMediaPlayer;

    if (type === 'audio') {
      return AudioPlayer({
        show,
        embedTargetSelector,
        style: { width: '100% ' },
        sources: [{ src: url }],
        getPipContainerRef: (pipContainer) => {
          this.classMediaPlayerPip = pipContainer;
        },
        onClose: () => {
          dispatch(PipContainerActions.hideClassMediaPlayer({ show: false }));
        },
      });
    }

    return VideoJSPlayer({
      show,
      embedTargetSelector,
      style: { width: '100% ' },
      sources: [{ src: url }],
      getPipContainerRef: (pipContainer) => {
        this.classMediaPlayerPip = pipContainer;
      },
      onClose: () => {
        dispatch(PipContainerActions.hideClassMediaPlayer({ show: false }));
      },
    });
  }

  private getSubscribedStatus() {
    const context = this.getContext();
    const contextId = this.getContextId();

    if (context === 'course') {
      return getStore().getState().courses.timeline!.userData!.subscribed || 0;
    } else if (context === 'class' && contextId) {
      const userData = getStore()
        .getState()
        .courses.timeline!.items.filter(
          (item) => item._id === contextId && item.nodeType === 'class'
        )
        .map((item: IClass) => item.userData)[0];
      return (userData && userData.subscribed) || 0;
    } else if (context === 'quiz' && contextId) {
      const quizzes = getStore().getState().quizzes.byId[contextId];
      return (quizzes && quizzes.userData && quizzes.userData.subscribed) || 0;
    } else if (context === 'resource' && contextId) {
      const resource = getStore().getState().resources.byId[contextId];
      return (resource && resource.userData && resource.userData.subscribed) || 0;
    } else if (context === 'poll' && contextId) {
      const polls = getStore().getState().polls.byId[contextId];
      return (polls && polls.userData && polls.userData.subscribed) || 0;
    } else if (context === 'discussion' && contextId) {
      const discussion = getStore().getState().discussions.byId[contextId];
      return (discussion && discussion.userData && discussion.userData.subscribed) || 0;
    } else if (context === 'query' && contextId) {
      const query = getStore().getState().queries.byId[contextId];
      return (query && query.userData && query.userData.subscribed) || 0;
    } else if (context === 'assignment' && contextId) {
      const userData = getStore()
        .getState()
        .courses.timeline!.items.filter((item) => item._id === contextId)
        .map((item: IAssignment) => item.userData)[0];
      return (userData && userData.subscribed) || 0;
    } else return 0;
  }

  private async openSubscribeDialog() {
    const isSubscribed = this.getSubscribedStatus() === 1 ? true : false;
    const userSubscribe = this.getSubscribedStatus() === 1 ? 0 : 1;
    await this.sendSubscribeRequest(userSubscribe);
    await this.setState({
      userSubscribe: isSubscribed,
      isSubscribeOpen: true,
    });
    setTimeout(async () => {
      await this.setState({
        isSubscribeOpen: false,
      });
    }, 3000);
  }

  // private async onSaveClickHandler() {
  //     await this.sendSubscribeRequest();
  //     await this.toggleSubscribeDialog();
  // }

  private getCommentsContextService(
    context: IAppContext,
    subContext?: ICommentsSubContext
  ): ICommentsContextService {
    return getCommentsContextService(context, subContext);
  }
  private getContextId() {
    const context = this.getContext();
    if (context === 'assignment') {
      return assignmentService.getCurrentAssignmentId();
    } else {
      return this.getCommentsContextService(context).contextId;
    }
  }

  private getContext() {
    return getStore().getState().app.context;
  }

  private async sendSubscribeRequest(userSubscribe: 0 | 1) {
    const context = this.getContext();
    const contextId = this.getContextId();
    const classId = classService.getCurrentClassId();
    console.log('context', context);
    console.log('contextId', contextId);
    console.log('classId', classId);
    const ctx = (
      {
        course: 'course',
        class: 'classes',
        assignment: 'assignments',
        quiz: 'quizzes',
        poll: 'polls',
        discussion: 'discussions',
        query: 'queries',
        resource: 'resources',
      } as any
    )[context];
    if (contextId === null || context === 'getin' || context === 'home') {
      return;
    }
    const request: ICommentSubscribeRequest = {
      context: ctx,
      contextId: contextId,
      classId: classId,
      subscribeToComments: userSubscribe,
    };
    dispatch(commentActions.subscribe(request));
  }

  private subscribeDialog() {
    const isOpen = this.getState().isSubscribeOpen;
    const isSubscribed = this.getState().userSubscribe;
    const title = !isSubscribed ? 'Subscribed' : 'Unsubscribed';

    const message = !isSubscribed
      ? 'We will send a notification to you whenever' + ' someone posts a comment in this thread'
      : 'You will now NOT receive a notification ' +
        'whenever someone posts a comment in this thread';
    return Alert(
      {
        titleStyle: {
          color: colors.red,
        },
        open: isOpen,
        style: {
          position: 'absolute',
          right: '1rem',
          top: '65px',
          width: '20em',
          background: colors.lightBlack,
          transition: 'all 0.2s ease-out',
        },
        overlayStyle: {
          backgroundColor: 'transparent',
        },
      },
      [h('div.message', style(['white']), [`${title}: ${message}`])]
    );
  }

  private termsDialog() {
    const state = this.getState();
    const { isConsentDialogOpen, isTermsAccepted, isPolicyAccepted, consentMessage } = state;
    return Alert(
      {
        title: h('div', 'Consent'),
        isAccessible: true,
        titleStyle: {
          color: colors.red,
        },
        open: isConsentDialogOpen,
        style: {
          width: '25em',
        },
        actions: [
          FlatButton('DONE', {
            tabIndex: 0,
            style: {
              color: isTermsAccepted && isPolicyAccepted ? colors.green : colors.lightGrey,
            },
            disabled: !(isTermsAccepted && isPolicyAccepted),
            onclick: () => this.termsOkClickHandler(),
          }),
        ],
      },
      [
        h(
          'div.message',
          style([pb('0.5rem')], {
            color: colors.lightGrey,
          }),
          consentMessage
        ),
        h('div.terms-container', style([pad('1rem'), 'lightBorder', 'flex']), [
          h('input', {
            type: 'checkbox',
            style: style(['large', mt('auto'), mb('auto')]).style,
            checked: isTermsAccepted,
            'aria-label': "Acadly's terms of services.",
            onclick: () => this.onTermsAcceptCheckboxClick(),
          }),
          h(
            'label.terms',
            style(
              ['darkBlue', 'large', pl('0.5rem')],
              {},
              {
                'aria-hidden': true,
              }
            ),
            "Acadly's TOS"
          ),
          h(
            'a',
            {
              href: 'https://www.acadly.com/terms',
              target: '_blank',
              'aria-label': "link to Acadly's terms of service",
              style: style([ml('auto'), mt('auto'), mb('auto'), 'darkBlue', 'large']).style,
            },
            [h('i.fa.fa-external-link', {})]
          ),
        ]),
        h('div.policy-container', style([pad('1rem'), 'lightBorder', 'flex']), [
          h('input', {
            type: 'checkbox',
            style: style(['large', mt('auto'), mb('auto')]).style,
            checked: isPolicyAccepted,
            'aria-label': "Acadly's Policy",
            onclick: () => this.onPolicyAcceptCheckboxClick(),
          }),
          h(
            'span.policy',
            style(
              ['darkBlue', 'large', pl('0.5rem')],
              {},
              {
                'aria-hidden': true,
              }
            ),
            "Acadly's Policy"
          ),
          h(
            'a',
            {
              href: 'https://www.acadly.com/privacy',
              target: '_blank',
              'aria-label': "link to Acadly's policy",
              style: style([ml('auto'), mt('auto'), mb('auto'), 'darkBlue', 'large']).style,
            },
            [h('i.fa.fa-external-link', {})]
          ),
        ]),
      ]
    );
  }

  private async termsOkClickHandler() {
    await consent({
      marketing: 1,
    });
    await this.setState({
      isConsentDialogOpen: false,
    });
    await dispatch(appActions.startTip(true));
  }

  private async onTermsAcceptCheckboxClick() {
    const isTermsAccepted = this.getState().isTermsAccepted;
    this.setState({
      isTermsAccepted: !isTermsAccepted,
    });
  }

  private async onPolicyAcceptCheckboxClick() {
    const isPolicyAccepted = this.getState().isPolicyAccepted;
    this.setState({
      isPolicyAccepted: !isPolicyAccepted,
    });
  }
  private escBind = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      this.toggleContextPanelExpand();
    }
  };

  private toggleContextPanelExpand = async () => {
    const isExpanded = getStore().getState().app.isContextPanelExpanded;
    if (!isExpanded) {
      window.addEventListener('keyup', this.escBind);
      u.unsetTabIndices(document.getElementById('context-panel-drawer'));
    } else {
      window.removeEventListener('keyup', this.escBind);
      u.resetTabIndices();
    }
    dispatch(Actions.toggleContextPanelExpand(!getStore().getState().app.isContextPanelExpanded));
  };

  private notifications() {
    const notifications = getStore().getState().app.notifications;
    const renderNotification = (notification: INotification) =>
      Paper(
        '.app__notification.ripple',
        {
          key: `notification-${notification.msgId}`,
          onclick: () => {
            dispatch(Actions.notificationClear(notification.msgId));

            AcadlyEvents.next({
              type: '@app/notification_dismissed',
              payload: notification,
            });

            if (this.getState().contextOpen) {
              this.toggleContextPanel();
            }
            // dispatch(CommentActions.clear)
            if (notification.goToView === 'poll') {
              if (notification.classId && notification.activityId) {
                Routes.classPoll.navigate({
                  classShortId: classService.getShortIdFromClassId(notification.classId),
                  courseShortId: courseService.getShortIdFromCourseId(notification.courseId),
                  pollShortId: pollService.getShortIdFromPollId(notification.activityId),
                  univSlug: appService.getUniversitySlug(),
                });
              }
            }
            if (notification.goToView === 'quiz') {
              if (notification.classId && notification.activityId) {
                Routes.classQuiz.navigate({
                  classShortId: classService.getShortIdFromClassId(notification.classId),
                  courseShortId: courseService.getShortIdFromCourseId(notification.courseId),
                  quizShortId: quizService.getShortIdFromQuizId(notification.activityId),
                  univSlug: appService.getUniversitySlug(),
                });
              }
            }
            if (notification.goToView === 'resource') {
              if (notification.classId && notification.activityId) {
                Routes.classResource.navigate({
                  classShortId: classService.getShortIdFromClassId(notification.classId),
                  courseShortId: courseService.getShortIdFromCourseId(notification.courseId),
                  resourceShortId: resourceService.getShortIdFromResourceId(
                    notification.activityId
                  ),
                  univSlug: appService.getUniversitySlug(),
                });
              }
            }
            if (notification.goToView === 'discussion') {
              if (notification.classId && notification.activityId) {
                Routes.classDiscussion.navigate({
                  classShortId: classService.getShortIdFromClassId(notification.classId),
                  courseShortId: courseService.getShortIdFromCourseId(notification.courseId),
                  discussionShortId: discussionService.getShortIdFromDiscussionId(
                    notification.activityId
                  ),
                  univSlug: appService.getUniversitySlug(),
                });
              }
            }
            if (notification.goToView === 'assignment') {
              if (notification.courseId && notification.activityId) {
                Routes.courseAssignment.navigate({
                  courseShortId: courseService.getShortIdFromCourseId(notification.courseId),
                  assignmentShortId: assignmentService.getShortIdFromAssignmentId(
                    notification.activityId
                  ),
                  univSlug: appService.getUniversitySlug(),
                });
              }
            }

            if (notification.goToView === 'timeline') {
              Routes.courseTimeline.navigate({
                courseShortId: courseService.getShortIdFromCourseId(notification.courseId),
                univSlug: appService.getUniversitySlug(),
              });
            } else if (notification.goToView === 'classPage' && notification.classId) {
              Routes.classActivities.navigate({
                courseShortId: courseService.getShortIdFromCourseId(notification.courseId),
                classShortId: classService.getShortIdFromClassId(notification.classId),
                univSlug: appService.getUniversitySlug(),
              });
            }
          },
        },
        [
          Avatar(notification.avatar, 'notification', {
            className: 'app__notification-avatar',
          }),
          h('span', style(['blue', 'small']), notification.message),
          h(
            'i.fa.fa-times.ripple',
            style(
              [
                'red',
                pad('1rem'),
                ml('auto'),
                {
                  transform: 'translateX(1rem)',
                },
              ],
              {},
              {
                onclick: () => dispatch(Actions.notificationClear(notification.msgId)),
              }
            )
          ),
        ]
      );

    const isMobile = getStore().getState().app.isMobile;
    const isNarrow = getStore().getState().app.narrowScreen;
    let leftOffset = '1em';
    if (!isNarrow) {
      leftOffset = `${sidebarWidthPixels}px`;
    }
    return h(
      'div.notifications-container',
      style(
        [
          'fixed',
          'pointer',
          {
            width: '20em',
            maxWidth: '80%',
            bottom: '0',
            left: !isMobile ? '0' : '50%',
            transform: isMobile
              ? `translateX(-50%)`
              : `translateX(calc(${leftOffset} + 1em)) translateY(-1em)`,
            zIndex: 100000,
            marginBottom: isMobile ? '0.5rem' : undefined,
          },
        ],
        {},
        {
          key: 'notifications-wrapper',
        }
      ),
      notifications.map(renderNotification)
    );
  }

  private feedbackDialog() {
    return FeedbackDialog({
      open: this.getState().isFeedbackDialogOpen,
      key: 'feedback-dialog',
      close: () =>
        this.setState({
          isFeedbackDialogOpen: false,
        }),
    });
  }

  private analyticsPanel() {
    const storeState = getStore().getState();
    const isOpen = storeState.app.isAnalyticsOpen;
    return h(
      u.getHTMLTagSelector('div', ['app__analytics', isOpen ? '.open' : ''], 'analytics-panel'),
      [Analytics()]
    );
  }

  private isContextPanelOpen() {
    const storeState = getStore().getState();
    return storeState.app.narrowScreen ? this.getState().contextOpen : true;
  }

  private isContextPanelExpanded() {
    const storeState = getStore().getState();
    return storeState.app.isContextPanelExpanded;
  }

  private async toggleContextPanel() {
    await this.setState({
      ...this.getState(),
      contextOpen: !this.getState().contextOpen,
    });
    if (!this.getState().contextOpen) {
      dispatch(CommentActions.clear(undefined));
    } else {
      dispatch(Actions.toggleContextPanelExpand(false));
    }
  }

  private async toggleSidebar() {
    await this.setState({
      ...this.getState(),
      sidebarOpen: !this.getState().sidebarOpen,
    });
  }

  private isSidebarOpen() {
    const storeState = getStore().getState();
    return storeState.app.narrowScreen ? this.getState().sidebarOpen : true;
  }

  private promoDialog() {
    const { isPromoDialogOpen } = this.getState();

    if (!Routes.home.isActive(true)) {
      return null;
    }

    return Alert(
      {
        open: isPromoDialogOpen,
        title: h('div.fw-bold', 'Have you tried Acadly Pro yet?'),
        style: {
          width: '35em',
        },
      },
      [
        h('p', [
          'Acadly Pro enables you to conduct remote, hybrid, and hyflex lectures ',
          'from within Acadly itself. Go ahead, take it for a spin!',
        ]),
        h('p.fw-bold', [
          'Click on the ',
          h('span.promo__fab', [Icon(icons.plus)]),
          'on the bottom right and select ',
          `"Create a Pro Course" to start with a 7 day free trial course`,
        ]),
        CtaButton({
          label: 'GOT IT!',
          variant: 'green',
          className: 'promo__action',
          onClick: async () => {
            await promoSeen('pro');
            this.setState({ isPromoDialogOpen: false });
          },
        }),
      ]
    );
  }

  private switchAccountDialog() {
    const { isSwitchAccountDialogOpen } = this.getState();
    const { emailId } = getQueryParams();

    if (!emailId) return null;

    return Alert(
      {
        open: isSwitchAccountDialogOpen,
        title: h('div.fw-bold.fc-orange', 'Switch accounts'),
        style: {
          width: '35em',
        },
        actions: [
          FlatButton('No, Use current account', {
            type: 'primary',
            tabIndex: 0,
            onclick: () => {
              window.history.replaceState(null, document.title, window.location.pathname);
              this.setState({
                isSwitchAccountDialogOpen: false,
              });
            },
          }),
          FlatButton('Yes, Logout', {
            type: 'secondary',
            tabIndex: 0,
            onclick: async () => {
              await dispatch(GetinActions.logout(true, window.location.search));
            },
          }),
        ],
      },
      [
        h('p', [
          'Would you like to login with ',
          h('span.fw-bold', emailId),
          '? You will be logged out from current session.',
        ]),
      ]
    );
  }
}
