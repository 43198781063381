import { createReducer } from 'acadly/createReducer';
import { IRootAction } from 'acadly/IRootAction';
import { IRootState } from 'acadly/IRootState';

import { mapValues } from '../utils';
import { IGetInState } from './IGetInState';

export const reducer = (state: IRootState, action: IRootAction): IRootState => {
  return {
    ...state,
    getIn: getInSliceReducer(state.getIn, action),
  };
};

export const getInSliceReducer = createReducer<IGetInState>({
  '@getin/SET_SESSION': (state, payload) => ({
    ...state,
    session: payload,
  }),

  '@getin/SET_SERVER': (state, payload) => ({
    ...state,
    server: payload,
  }),
  '@getin/CLEAR_SERVER': (state) => ({ ...state, server: undefined }),
  '@getin/SET_AVATAR': (state, avatar) => ({ ...state, avatar }),
  '@getin/FETCH_AVATARS_SUCCESS': (state, payload) => ({
    ...state,
    avatars: {
      ...state.avatars,
      ...mapValues(payload.links, avatarNameToURL(payload.avatarURL || state.session!.avatarURL)),
    },
  }),
  '@getin/SET_CURRENT_SCREEN': (state, payload) => ({
    ...state,
    currentScreen: payload,
  }),
  '@getin/ROUTE_ACCESS_WITHOUT_LOGIN': (state, payload) => ({
    ...state,
    routeAccessedBeforeLogin: payload,
  }),
  '@getin/CHANGE_COURSE_CREATION_PERMISSION': (state, payload) => ({
    ...state,
    session:
      state.session === undefined
        ? undefined
        : {
            ...state.session,
            canCreateCourses: payload,
          },
  }),
});

const avatarNameToURL = (server: string) => (name: string) => `https://${server}${name}`;
