import { CSS, h, IComponent } from 'core';

import * as MoveDownIcon from 'assets/move_down.svg';
import * as MoveUpIcon from 'assets/move_up.svg';

import Icon from 'acadly/common/Icon';
import icons from 'acadly/icons';
import RawEditorC, { IRawEditorProps, RawEditor } from 'acadly/rich-text/RawEditor';
import * as u from 'acadly/utils';

import SvgIcon from './SvgIcon';

interface IQuestionOptionEditorProps extends IRawEditorProps {
  selected: boolean;
  index: number;
  id?: string;
  key?: string;
  style?: CSS;
  error: boolean;
  deleteAction?: ((e: Event) => any) | null;
  enableMove?: 'up' | 'down' | 'both';
  onMoveClick?: (direction: 'up' | 'down', e: MouseEvent) => any;
  tabIndex?: number;
}

interface IQuestionOptionEditorState {
  isEditingText: boolean;
}

export class QuestionOptionEditor extends IComponent<
  IQuestionOptionEditorProps,
  IQuestionOptionEditorState
> {
  public componentWillMount() {
    this.setState({
      isEditingText: false,
    });
  }
  private rawEditor?: RawEditor<any>;

  private getCaretToEnd(editor: any) {
    const range = document.createRange();
    const lastNode = u.getLastNode(editor);
    if (lastNode === editor) {
      range.setStart(editor, 0);
      range.collapse(true);
    } else {
      range.selectNodeContents(editor);
      range.setEndAfter(lastNode);
      range.collapse(false);
    }
    const sel = window.getSelection();
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  public render() {
    const props = this.getProps();
    const rawEditorValue = props.value;
    return h('div.question-option-editor', { style: props.style, id: props.id }, [
      h('div.question-option-editor__header', [
        h('div.question-option-editor__title', [
          props.enableMove
            ? h('div.question-option-editor__move', [
                SvgIcon({
                  component: 'button',
                  disabled: props.enableMove === 'down',
                  title: props.enableMove !== 'down' ? 'Click to move this option up' : undefined,
                  icon: MoveUpIcon,
                  className: 'up',
                  onclick: (e) => props.onMoveClick?.('up', e),
                }),
                SvgIcon({
                  component: 'button',
                  disabled: props.enableMove === 'up',
                  title: props.enableMove !== 'up' ? 'Click to move this option down' : undefined,
                  icon: MoveDownIcon,
                  className: 'down',
                  onclick: (e) => props.onMoveClick?.('down', e),
                }),
              ])
            : null,
          h(
            u.getHTMLTagSelector('span', [props.selected ? 'fc-blue' : '']),
            `Option ${u.indexToAlphabet(props.index)}`
          ),
        ]),

        props.error
          ? h(
              'span.question-option-editor__error',
              {
                tabIndex: props.tabIndex,
              },
              'Please fill this field'
            )
          : null,

        h('span.question-option-editor__actions', [
          this.isTextButtonVisible()
            ? Icon(icons.pencil, {
                className: 'question-option-editor__action',
                tabIndex: props.tabIndex,
                onclick: () => this.editTextClickHandler(),
              })
            : null,

          this.isImageButtonVisible()
            ? Icon(icons.image, {
                className: 'question-option-editor__action',
                tabIndex: props.tabIndex,
                onclick: async () => {
                  if (this.rawEditor) {
                    await this.setState({
                      isEditingText: true,
                    });
                    await this.rawEditor.showImageDialog();
                  }
                },
              })
            : null,

          this.isFormulaButtonVisible()
            ? Icon(icons.formula, {
                className: 'question-option-editor__action',
                tabIndex: props.tabIndex,
                onclick: async () => {
                  if (this.rawEditor) {
                    this.rawEditor.editor.focus();
                    await this.rawEditor.showFormulaDialog();
                  }
                },
              })
            : null,

          this.isClearButtonVisible()
            ? Icon(icons.cross, {
                className: 'question-option-editor__action red',
                tabIndex: props.tabIndex,
                onclick: () => this.clearClickHandler(),
              })
            : null,

          props.deleteAction
            ? Icon(icons.trash, {
                className: 'question-option-editor__action red',
                tabIndex: props.tabIndex,
                onclick: props.deleteAction,
              })
            : null,
        ]),
      ]),
      RawEditorC({
        getInstance: (editor) => {
          this.rawEditor = editor;
          this.setState({});
        },
        disabled: this.isDisabled(),
        value: rawEditorValue,
        enableImageInput: true,
        enableFormulaInput: true,
        textAreaStyle: {
          minHeight: '1.5rem',
          maxHeight: '10em',
        },
        subContext: 'question',
        oninput: props.oninput,
        placeholder: this.isDisabled() ? undefined : 'Type here',
        onImageDialogClose: () => this.doneClickHandler(),
      }),
    ]);
  }

  private isDisabled() {
    return !this.getState().isEditingText || (this.rawEditor && this.rawEditor.containsImage());
  }

  private isFormulaButtonVisible() {
    return this.getState().isEditingText;
  }

  private async doneClickHandler() {
    await this.setState({
      isEditingText: false,
    });
  }

  private async clearClickHandler() {
    this.getProps().oninput('', []);
    await this.doneClickHandler();
  }

  private isClearButtonVisible() {
    return this.rawEditor && (this.rawEditor.containsImage() || this.rawEditor.containsText());
  }

  private async editTextClickHandler() {
    await this.setState({
      isEditingText: true,
    });
    if (this.rawEditor) {
      this.rawEditor.focus();
      this.getCaretToEnd(this.rawEditor.editor);
    }
  }

  private isTextButtonVisible() {
    return !this.getState().isEditingText && this.rawEditor && !this.rawEditor.containsImage();
  }

  private isImageButtonVisible() {
    return (
      this.getProps().enableImageInput !== false &&
      !this.getState().isEditingText &&
      this.rawEditor &&
      !this.rawEditor.containsImage() &&
      !this.rawEditor.containsText()
    );
  }
}

export default (props: IQuestionOptionEditorProps) =>
  h(QuestionOptionEditor, {
    key: 'question-option-editor-' + props.index.toString(),
    ...props,
  } as any);
