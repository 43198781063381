import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/repeat';
import 'rxjs/add/operator/skipUntil';
import 'rxjs/add/operator/takeUntil';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { CSS, h, IComponent } from 'core';

import { colors } from 'acadly/styles';
import { range } from 'acadly/utils';
import loggly from 'acadly/utils/loggly';

export default (props: ISliderProps) => h(Slider, props as any);
export interface ISliderProps {
  onchange?: (scale: number) => any;
  style?: CSS;
  isNumberSlider?: boolean;
}
export class Slider extends IComponent<
  ISliderProps,
  {
    isMounted: boolean;
  }
> {
  public componentWillMount() {
    this.setState({
      isMounted: false,
    });
  }

  private sliderDOM?: SliderDOM;

  public componentWillUnmount() {
    if (this.sliderDOM) {
      this.sliderDOM.dispose();
      this.sliderDOM = undefined;
    }
  }

  public render() {
    return h('div.slider', {
      style: {
        height: '3em',
        width: '100%',
        ...(this.getProps().style || {}),
      },
      ref: (elem?: HTMLElement) => {
        if (elem && !this.getState().isMounted) {
          this.setState({ isMounted: true });
          if (this.sliderDOM) {
            this.sliderDOM.dispose();
          }
          this.sliderDOM = new SliderDOM(elem, (progress) => {
            const onchange = this.getProps().onchange;
            if (onchange) {
              onchange(progress);
            }
          });
        }
      },
    });
  }
}

class SliderDOM {
  private progress = 50;
  constructor(elem: HTMLElement, onchange: (position: number) => any = () => {}) {
    const container = document.createElement('div');
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.position = 'relative';
    elem.appendChild(container);
    const sliderBar = document.createElement('div');
    Object.assign(sliderBar.style, {
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '0',
      transform: 'translateY(-50%)',
      height: '0.25em',
      backgroundColor: colors.lightGrey,
      borderRadius: '0.125em',
    });
    container.appendChild(sliderBar);
    const progressBar = document.createElement('div');
    Object.assign(progressBar.style, {
      width: '50%',
      position: 'absolute',
      height: '0.25em',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: '1',
      left: '0',
      backgroundColor: colors.blue,
      borderRadius: '0.125em',
    });
    container.appendChild(progressBar);
    const dragger = document.createElement('div');
    Object.assign(dragger.style, {
      width: '1.5em',
      cursor: 'pointer',
      zIndex: '1',
      height: '1.5em',
      borderRadius: '50%',
      backgroundColor: 'white',
      position: 'absolute',
      top: '50%',
      left: `${this.progress}%`,
      transform: 'translateY(-50%) translateX(-50%)',
      boxShadow: 'rgb(59, 64, 77) 1px 1px 9px 1px',
    });

    container.appendChild(dragger);
    const mouseDown$: Observable<number> = Observable.fromEvent(container, 'mousedown').map(
      getMouseX
    );
    const touchStart$: Observable<number> = Observable.fromEvent(container, 'touchstart').map(
      getTouchX
    );
    const start$: Observable<number> = mouseDown$.merge(touchStart$);
    const mouseUp$ = Observable.fromEvent(document.body, 'mouseup');
    const end$ = mouseUp$.merge(Observable.fromEvent(document.body, 'touchend'));
    const mouseMove$: Observable<number> = Observable.fromEvent(document.body, 'mousemove').map(
      getMouseX
    );
    const touchMove$: Observable<number> = Observable.fromEvent(document.body, 'touchmove').map(
      getTouchX
    );
    const move$: Observable<number> = mouseMove$.merge(touchMove$);

    const totalWidth = sliderBar.clientWidth;
    const drag$ = move$ // start with stream of touchmove events
      .skipUntil(start$) // ignore all events before touchstart
      // combine with latest dragStart event
      .takeUntil(end$)
      // repeat forever
      .repeat();
    const offset = sliderBar.getBoundingClientRect();

    if (this.dragSubscription) {
      this.dragSubscription.unsubscribe();
      this.dragSubscription = undefined;
    }
    if (this.startSubscription) {
      this.startSubscription.unsubscribe();
      this.startSubscription = undefined;
    }

    const setProgress = (clientX: number) => {
      this.progress = Math.max(Math.min(((clientX - offset.left) * 100) / totalWidth, 100), 0);
      dragger.style.left = `${this.progress}%`;
      progressBar.style.width = `${this.progress}%`;
      onchange(this.progress);
    };

    this.dragSubscription = drag$.subscribe(setProgress);
    this.startSubscription = start$.subscribe(setProgress);
  }

  private dragSubscription?: Subscription;
  private startSubscription?: Subscription;

  public dispose() {
    if (this.dragSubscription) {
      this.dragSubscription.unsubscribe();
    }
    if (this.startSubscription) {
      this.startSubscription.unsubscribe();
    }
  }
}

function getMouseX(e: MouseEvent) {
  if (!e) {
    loggly.push({
      type: 'Unhandled error',
      message: 'No event; Slider',
    });
    return 0;
  }
  return e.clientX;
}

function getTouchX(e: TouchEvent) {
  if (!e) {
    loggly.push({
      type: 'Unhandled error',
      message: 'No event; Slider; touch',
    });
    return 0;
  }
  let touch: any = null;
  for (const i of range(e.touches.length)) {
    if (e.touches[i]) {
      touch = e.touches[i];
      break;
    }
  }
  if (touch) {
    return touch.clientX;
  } else {
    return 0;
  }
}
