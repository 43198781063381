import { h, IComponent } from 'core';

import appService from 'acadly/app/service';
import * as Actions from 'acadly/assignment/actions';
import assignmentService from 'acadly/assignment/service';
import SuggestedAssignmentPreview from 'acadly/assignment/SuggestedAssignmentPreview';
import SuggestedAssignmentWidget from 'acadly/assignment/SuggestedAssignmentWidget';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import RadioButton from 'acadly/common/RadioButton';
import courseService from 'acadly/course/service';
import icons from 'acadly/icons';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { colors } from 'acadly/styles';
import { getHTMLTagSelector } from 'acadly/utils';

interface ISuggestedAssignmentDialogProps {
  isOpen: boolean;
  onClose: () => any;
  course: ICourse;
  assignments: ISuggestedAssignment[];
}

interface ISuggestedAssignmentDialogState {
  selectedAssignmentId: string | null;
  selectedAssignment: ISuggestedAssignment | null;
  isPreviewDialogOpen: boolean;
}

class SuggestedAssignmentDialog extends IComponent<
  ISuggestedAssignmentDialogProps,
  ISuggestedAssignmentDialogState
> {
  public componentWillMount() {
    const initialState: ISuggestedAssignmentDialogState = {
      selectedAssignmentId: null,
      selectedAssignment: null,
      isPreviewDialogOpen: false,
    };
    this.setState(initialState);
  }

  public render() {
    const isMobile = getStore().getState().app.isMobile;
    return isMobile ? this.mobileView() : this.nonMobileView();
  }

  private mobileView() {
    const { isOpen, onClose } = this.getProps();

    return h(
      getHTMLTagSelector('div', ['suggested-assignment-screen', isOpen ? 'open' : '']),
      {
        key: 'suggested-assignment-screen',
      },
      !isOpen
        ? []
        : [
            h('div.suggested-assignment-screen__header', [
              Icon(icons.cross, {
                className: 'suggested-assignment-screen__header-action',
                onclick: onClose,
              }),
              h('span', 'Adding a new assignment'),
            ]),
            h('div.suggested-assignment-screen__body', this.body()),
            h('div.suggested-assignment-screen__footer', [
              FlatButton('Cancel', {
                onclick: onClose,
              }),
              h('div.spacer', { style: { flex: '1' } }),
              FlatButton('Next', {
                onclick: () => this.handleNext(),
              }),
            ]),
          ]
    );
  }

  private nonMobileView() {
    const { isOpen, onClose } = this.getProps();
    return Dialog(
      {
        open: isOpen,
        key: 'suggested-assignment-dialog',
        title: 'Adding a new assignment',
        style: {
          width: '45em',
          height: '45em',
        },
        bodyStyle: {
          padding: `0 1.125rem`,
          backgroundColor: colors.backgroundColor,
          height: '100%',
        },
        secondaryAction: {
          label: 'Close',
          onclick: onClose,
        },
        primaryAction: {
          label: 'Next',
          onclick: () => this.handleNext(),
        },
      },
      isOpen ? this.body() : []
    );
  }

  private body() {
    const assignments = this.getProps().assignments;
    const selectedAssignmentId = this.getState().selectedAssignmentId;

    return [
      h('div.create-assignment__body', [
        h(
          'div.ripple.create-assignment__option.with-padding',
          {
            key: 'create-assignment-from-scratch',
            onclick: () => {
              this.setState({
                selectedAssignmentId: null,
                selectedAssignment: null,
              });
            },
          },
          [
            RadioButton({
              className: 'create-assignment__radio-button',
              selected: selectedAssignmentId == null,
            }),
            h('span.create-assignment__option-title', 'Create assignment from scratch'),
          ]
        ),
        h('div.create-assignment__separator', 'OR'),
        h('div.create-assignment__subtitle', 'Select from suggested assignments'),
        !assignments.length
          ? h('div.create-assignment__separator', 'No suggested assignments')
          : null,
        ...assignments.map((assignment) => {
          return h(
            'div.ripple.create-assignment__option',
            {
              key: `create-assignment__option-${assignment._id}`,
              onclick: () => {
                this.setState({
                  selectedAssignmentId: assignment._id,
                  selectedAssignment: assignment,
                });
              },
            },
            [
              RadioButton({
                className: 'create-assignment__radio-button',
                selected: selectedAssignmentId === assignment._id,
              }),
              SuggestedAssignmentWidget({
                assignment,
                key: assignment._id,
              }),
            ]
          );
        }),
      ]),
      SuggestedAssignmentPreview({
        open: this.getState().isPreviewDialogOpen,
        assignment: this.getState().selectedAssignment,
        onClose: (used) => {
          this.setState({
            isPreviewDialogOpen: false,
          });
          if (used) {
            this.getProps().onClose();
          }
        },
      }),
    ];
  }

  private async handleNext() {
    const course = this.getProps().course;
    const selectedAssignmentId = this.getState().selectedAssignmentId;

    if (!selectedAssignmentId) {
      // creating from scratch
      const response = await dispatch(Actions.createAssignment(course._id, true));
      if (response.hasSuggestedAssignments === 1) return;
      const assignmentShortId = assignmentService.getShortIdFromAssignmentId(
        response.newAssignment._id
      );
      this.getProps().onClose();
      Routes.courseAssignment.navigate({
        courseShortId: courseService.getShortIdFromCourseId(course._id),
        assignmentShortId,
        univSlug: appService.getUniversitySlug(),
      });
    } else {
      this.setState({
        isPreviewDialogOpen: true,
      });
    }
  }
}

export default (props: ISuggestedAssignmentDialogProps) => h(SuggestedAssignmentDialog, props);
