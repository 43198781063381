import { h } from 'core';

import { getFormattedClassTimings } from './class/functions';

export const messages = {
  activity: {
    submit: {
      deadlinePassed:
        "This activity doesn't allow late submissions " + 'and the deadline has passed.',
    },
    publish: {
      courseEnded: [
        h(
          'div',
          'This course is now over, so you cannot ' +
            'publish any more activities in this course. ' +
            'Consider extending the end date for this ' +
            'course to be able to publish activities here.'
        ),

        h(
          'div',
          'You can also Copy or Move this activity to ' + 'a course that is live and on-going.'
        ),
      ],
      nonOngoingOrUpcomingClass:
        'You can only publish an activity in an upcoming or ongoing ' +
        'class (that has not been cancelled). Consider ' +
        'moving or copying this activity to share it with ' +
        'students.',
      preClassTooLate:
        'To give the students enough time, pre-class activities can ' +
        'be published till 30 minutes before the class begins. You' +
        ' can either move the activity to the in-class section of this' +
        ' class or to the pre-class section of an upcoming class.',
      classInFuture: (cls: {
        details: {
          status: IClassStatus;
          scheStartTime: UnixTimestamp;
          scheEndTime: UnixTimestamp;
        };
      }) => {
        return `An in-class activity can only be published during the scheduled class hours ${getFormattedClassTimings(
          cls
        )}`;
      },
      classPast:
        "You can't publish this activity now since " +
        "it's past your class' scheduled end time. " +
        'Consider moving or copying this activity to ' +
        'share it with students.',
      classToEndIn2Min:
        "You can't publish this activity now since " +
        ' this class is about to end in under 2 minutes',
    },
  },
  resource: {
    publish: {
      noTitle: 'Learning resources need to have a title. ' + 'Please add a title and try again.',
      noFile: 'Please upload a file and try again.',
      noURL: 'Please add a URL and try again',
      noVideo: 'Please add a YouTube video link and try again',
    },
  },
  announcement: {
    publish: {
      noTitle: 'Announcements need to have a title. ' + 'Please add a title and try again.',
    },
  },
  query: {
    publish: {
      noTitle: 'Query needs to have a title. ' + 'Please add a title and try again.',
      noDescription:
        'Query needs to have a description. ' + 'Please add a description and try again.',
    },
  },
  assignment: {
    publish: {
      noQuestions:
        'Assignments need to have at least one ' +
        'question. Please add a question(s) and try again.',
    },
  },
  poll: {
    publish: {
      noQuestion: 'Please add a poll question and try again.',
    },
  },
  quiz: {
    publish: {
      noQuestions:
        'Quizzes need to have at least one question. ' + 'Please add a question(s) and try again',
    },
  },
  discussion: {
    publish: {
      noTitle: 'Please add a discussion title and try again.',
    },
  },

  course: {
    over: 'This course is over',
  },
};
