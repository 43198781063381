import { CSS, h, IComponent } from 'core';

import DateView from 'acadly/common/DateView';
import Icon from 'acadly/common/Icon';
import icons from 'acadly/icons';
import { getStore } from 'acadly/store';
import { colors, ml, mr, style } from 'acadly/styles';

import * as dt from '../datetime';

export default (props: IResourceWidgetProps) => h(ResourceWidget, props);
export interface IResourceWidgetProps {
  resource: IResource;
  cls: IClass;
  courseRole: ICourseRole;
  showClassDate?: boolean;
  onclick?: (e: Event) => any;
  style?: CSS;
}
export class ResourceWidget extends IComponent<IResourceWidgetProps, never> {
  public render() {
    const { resource, onclick, showClassDate } = this.getProps();
    const widgetColor = this.getWidgetColor();
    let icon: { url: string } | string = icons.resourceText;
    const startTime = dt.fromUnix(resource.details.publishedOn);
    if (resource.details.resourceType === 'video') {
      icon = icons.resourceVideo;
    } else if (resource.details.resourceType === 'file') {
      icon = icons.resourceFile;
    } else if (resource.details.resourceType === 'link') {
      icon = icons.resourceURL;
    }
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    const newComments = resource.userData
      ? resource.activities.numCommentsTotal - resource.userData.numCommentsSeen
      : resource.activities.numCommentsTotal;
    return h(
      'div',
      {
        style: {
          width: '100%',
          height: '100%',
          display: 'flex',
          backgroundColor: 'white',
          cursor: 'pointer',
          position: 'relative',
          padding: '0.5em 1em',
          boxSizing: 'border-box',
          borderBottom: `1px solid ${colors.lightestGrey}`,
          ...this.getProps().style,
        },
        tabIndex: isAccessible ? 0 : undefined,
        'aria-label':
          `Resource ${resource.details.trueNum} - ` + `${resource.details.title || 'Untitled'}`,
        onclick: onclick,
        key: 'resource-widget-' + resource._id,
      },
      [
        h(
          'div.resource-widget-lhs',
          {
            style: {
              width: '4em',
              display: 'flex',
              boxSizing: 'border-box',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: widgetColor,
            },
          },
          [
            showClassDate
              ? dateCell(startTime, widgetColor)
              : Icon(icon, {
                  style: {
                    fontSize: '2em',
                  },
                }),
          ]
        ),

        h(
          'div.resource-widget-rhs',
          showClassDate ? style(['widgetFilterActivitiesRhs']) : style(['widgetRhs']),
          [
            h(
              'div.resource-widget-heading',
              style([
                'flex',
                'alignCenter',
                {
                  height: '1.5em',
                },
              ]),
              [
                h(
                  'span',
                  style(['thick', 'mediumDarkGrey', mr('0.5em')]),
                  'Resource ' +
                    (resource.details.trueNum !== undefined && resource.details.published
                      ? resource.details.trueNum.toString().length === 1
                        ? ` 0${resource.details.trueNum}`
                        : ` ${resource.details.trueNum}`
                      : '')
                ),
                !resource.details.published ? this.tag('UNPUBLISHED', colors.red) : null,
                this.getProps().courseRole === 'student' && !resource.userData
                  ? h('span', style(['flex', 'alignCenter', ml('0.5em'), 'orange']), [
                      h('i.fa.fa-star', style([mr('0.3em')])),
                      h('span', style(['x-small'], { fontWeight: '900' }), 'NEW'),
                    ])
                  : null,

                newComments > 0
                  ? h('span', style(['orange', 'flex', 'alignCenter', ml('0.5em')]), [
                      h('i.fa.fa-comment', style([mr('0.3em')])),
                      h('span', style(['x-small', 'bold']), [newComments.toString()]),
                    ])
                  : null,

                this.isUpdatedTagVisible()
                  ? h('span', style(['lightGreyText', ml('0.5em'), 'flex', 'alignCenter']), [
                      h('i.fa.fa-pencil', style([mr('0.3rem')])),
                      h('span', style(['x-small'], { fontWeight: '300' }), 'EDITED'),
                    ])
                  : null,

                this.topRight(),
              ]
            ),

            h(
              'div.title-widget',
              showClassDate ? style(['widgetFilterActivitiesRhsTitle']) : style(['widgetRhsTitle']),
              resource.details.title || 'Untitled'
            ),
          ]
        ),
      ]
    );
  }

  private isUpdatedTagVisible() {
    const { resource, courseRole } = this.getProps();
    if (resource.userData && courseRole === 'student' && resource.details.lastEditedOn) {
      const lastAccessedOn = resource.userData.lastAccessedOn;
      return lastAccessedOn && lastAccessedOn < resource.details.lastEditedOn;
    }
    return false;
  }

  private topRight() {
    const { courseRole, resource } = this.getProps();
    if (courseRole === 'student') return null;

    const context = getStore().getState().app.context;

    if (context === 'course') {
      return h(
        'span',
        style(['lightGrey', 'small', ml('auto')]),
        resource.details.published
          ? `Published: ${dt.format(resource.details.publishedOn, 'MMM DD, YYYY')}`
          : `Created: ${dt.format(resource.details.createdOn, 'MMM DD, YYYY')}`
      );
    }

    if (!resource.details.published) return null;

    return h('span', style(['lightGrey', 'small', ml('auto')]), [
      `Accessed by: ${resource.stats.numClicks}`,
    ]);
  }

  private getWidgetColor() {
    const { resource } = this.getProps();
    if (this.getProps().courseRole === 'student') {
      if (resource.userData) {
        return colors.lightGrey;
      } else {
        return colors.blue;
      }
    } else {
      if (resource.details.published) {
        return colors.lightGrey;
      } else {
        return colors.red;
      }
    }
  }
  private tag(text: string, color: string) {
    return h(
      'div.tag-container',
      style([ml('0.8em')], {
        height: '80%',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        backgroundColor: color,
        textAlign: 'center',
        fontWeight: '900',
        borderRadius: '4px',
      }),
      [
        h(
          'div.tag-text',
          style([
            'white',
            'x-small',
            {
              lineHeight: '1.5em',
            },
          ]),
          [text]
        ),
      ]
    );
  }
}
const DATE_CELL_ATTRS = {
  style: {
    display: 'flex',
    flexDirection: 'column',
  },
};
const DIV = 'div.date-cell';
function dateCell(startTime: Date, widgetColor: string) {
  return h(DIV, DATE_CELL_ATTRS, [DateView(startTime, widgetColor)]);
}
