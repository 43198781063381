import {
  IRootAction,
  IRootActionObject,
  IRootActionPayload,
  IRootActionType,
} from 'acadly/IRootAction';

export type ReducerCases<State> = {
  [T in IRootActionType]: (
    state: State,
    payload: IRootActionPayload<T>,
    action: IRootActionObject<T>
  ) => State;
};

export function createReducer<State>(cases: Partial<ReducerCases<State>>) {
  return function (state: State, action: IRootAction): State {
    const fn = cases[action.type];
    if (fn) {
      return (fn as any)(state, action.payload, action);
    } else {
      return state;
    }
  };
}
