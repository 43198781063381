import { h, IComponent } from 'core';

import { api as urls } from 'acadly/api';
import { Actions as appActions } from 'acadly/app/actions';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import ContentView from 'acadly/common/ContentView';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import { dispatch, getStore } from 'acadly/store';

import Avatar from '../common/Avatar';
import * as datetime from '../datetime';
import Viewer from '../rich-text/Viewer';
import { Actions } from './actions';

export class Announcement extends IComponent<IAnnouncement, never> {
  private isAccessible: boolean;
  public componentWillMount() {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
    const announcement = this.getProps();
    dispatch(appActions.startTip(true));
    if (!announcement.userData.firstAccessedOn && courseService.getRole() === 'student') {
      dispatch(
        Actions.analyticsSet({
          announcementId: announcement._id,
          firstAccess: 1,
          localTime: dt.format(dt.now(), 'YYYYMMDDTHH:mm'),
        })
      );
    }
  }

  public render() {
    const announcement = this.getProps();
    const hasAttachments = announcement.details.attachments.length > 0;
    return ContentView(
      h('div.activity.announcement', [
        h(
          'div.activity__title',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          announcement.details.title
        ),
        h('div.activity__description', [
          Viewer(announcement.details.description || '-- No description added --', {
            tabIndex: this.isAccessible ? 0 : undefined,
          }),
        ]),
        hasAttachments
          ? h('div.activity__attachments', [
              h('div.cell', [h('span.cell__label', 'Attachments')]),
              ...this.attachmentsView(),
            ])
          : null,
        this.creatorView(announcement),
      ])
    );
  }

  private attachmentsView() {
    const announcement = this.getProps();
    return announcement.details.attachments.map((file) =>
      AttachmentViewer({
        attachment: file,
        downloadUrl: urls().announcementFileDownload,
        downloadRequest: {
          announcementId: announcement._id,
          fileName: file.name,
        },
      })
    );
  }

  private creatorView(announcement: Readonly<IAnnouncement>): any {
    const creatorName = announcement.details.createdBy.name;
    return h(
      'div.user',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        Avatar(announcement.details.createdBy.avatar || 'default', creatorName, {
          className: 'user__avatar',
        }),
        h('div.user__details.fc-lighter-grey', [
          h('div', `Posted by ${creatorName}`),
          h(
            'div.fs-sm',
            datetime.format(
              datetime.fromUnix(announcement.details.createdOn),
              'MMM Do YYYY [at] hh:mm A'
            )
          ),
        ]),
      ]
    );
  }
}

export default (announcement: IAnnouncement) => h(Announcement, announcement);
