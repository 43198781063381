import cn from 'classnames';

import { CSS, h, IComponent } from 'core';

import { Actions as appActions } from 'acadly/app/actions';
import { IAddWeeklyClassRequest } from 'acadly/class/api';
import Alert from 'acadly/common/Alert';
import { CalendarWeekLabels } from 'acadly/common/Calendar';
import DatePicker from 'acadly/common/DatePicker';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import { Icon } from 'acadly/common/Icon';
import MultiDatePicker from 'acadly/common/MultiDatePicker';
import RadioButton from 'acadly/common/RadioButton';
import TextField from 'acadly/common/TextField';
import TimePicker from 'acadly/common/TimePicker';
import * as datetime from 'acadly/datetime';
import icons from 'acadly/icons';
import { dispatch, getStore } from 'acadly/store';
import { colors, mb, ml, mt, pad, pb, pr, style } from 'acadly/styles';
import * as u from 'acadly/utils';

import { Actions as ClassActions } from './actions';

interface INewClassDialogProps {
  type: 'weekly' | 'new';
  userId: string;
  open: boolean;
  close: () => any;
  course: ICourse;
  isAccessible: boolean;
}
export default function NewClassDialog(props: INewClassDialogProps) {
  return h(NewClassDialogC, props);
}

export interface INewClassDialogState {
  screen: 'main' | 'dates';
  selectedClassTypeIndex: number;
  selectedDate: Date;
  startTimeHour: number;
  startTimeMinute: number;
  endTimeHour: number;
  endTimeMinute: number;
  classModalityError: boolean;
  isOnlineMeeting: 0 | 1 | null;
  venue: string;
  venueError: boolean;
  startTimeError: boolean;
  endTimeError: boolean;
  endTimeGreaterThanStartTimeError: boolean;
  startTimeInPastError: boolean;
  multipleDatesField: Date[];
  isClashDialogOpen: boolean;
  clashes: {
    date: Date;
    startTime: UnixTimestamp;
    endTime: UnixTimestamp;
    type: IClassType;
  }[];
  isRecurringMultipleDaysDialogOpen: boolean;
  selectedDayIndices: number[];
  weekDayError: boolean;
}
export class NewClassDialogC extends IComponent<INewClassDialogProps, INewClassDialogState> {
  private static initialState: INewClassDialogState = {
    screen: 'main',
    selectedClassTypeIndex: 0,
    startTimeHour: 10,
    startTimeMinute: 0,
    endTimeHour: 11,
    endTimeMinute: 0,
    classModalityError: false,
    isOnlineMeeting: null,
    venueError: false,
    venue: '',
    selectedDate: datetime.now(),
    startTimeError: false,
    endTimeError: false,
    endTimeGreaterThanStartTimeError: false,
    startTimeInPastError: false,
    multipleDatesField: [],
    isClashDialogOpen: false,
    clashes: [],
    isRecurringMultipleDaysDialogOpen: false,
    selectedDayIndices: [],
    weekDayError: false,
  };

  private static classTypes: IClassType[] = ['lecture', 'tutorial', 'lab', 'seminar'];

  private static days: IWeekDay[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  private get isAccessible() {
    return getStore().getState().app.acc.web.turnOff === 0;
  }

  public componentWillMount() {
    this.setState(NewClassDialogC.initialState);
  }

  public render() {
    const props = this.getProps();
    const state = this.getState();
    return Dialog(
      {
        open: props.open,
        title: state.screen === 'dates' ? 'Select Dates' : 'Adding a new class',
        style: {
          backgroundColor: colors.backgroundColor,
          fontSize: '1rem',
        },
        thinHeader: state.screen === 'dates',
        subHeader:
          state.screen === 'dates'
            ? h(
                'div',
                style([
                  'whiteBackground',
                  'fullWidth',
                  {
                    borderBottom: '1px solid lightGrey',
                  },
                ]),
                [
                  h('div', style(['lightGrey', pad('0.5rem 1rem')]), [
                    `Class, ${u.showTime({
                      hours: state.startTimeHour,
                      minutes: state.startTimeMinute,
                    })} - ${u.showTime({
                      hours: state.endTimeHour,
                      minutes: state.endTimeMinute,
                    })} at ${state.venue}`,
                  ]),
                  CalendarWeekLabels(),
                ]
              )
            : null,
        secondaryAction:
          state.screen === 'dates'
            ? {
                label: 'BACK',
                mobileLabel: h('i.fa.fa-arrow-left'),
                onclick: () =>
                  this.setState({
                    screen: 'main',
                  }),
              }
            : {
                label: 'CANCEL',
                mobileLabel: h('i.fa.fa-times', []),
                onclick: async () => {
                  u.resetTabIndices();
                  await dispatch(appActions.startTip(true));
                  await this.setState(NewClassDialogC.initialState);
                  await this.getProps().close();
                },
              },
        primaryAction: {
          type: 'primary',
          label: 'SAVE',
          tabIndex: this.isAccessible ? 0 : undefined,
          disabled:
            state.screen === 'dates'
              ? state.multipleDatesField.length > 0
                ? false
                : true
              : undefined,
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () => this.doneClickHandler(),
        },
      },
      [...(state.screen === 'main' ? this.mainScreen() : this.datesScreen()), this.clashDialog()]
    );
  }

  private clashDialog() {
    const state = this.getState();
    const startTime = this.renderTime(state.startTimeHour, state.startTimeMinute);
    const endTime = this.renderTime(state.endTimeHour, state.endTimeMinute);
    return Alert(
      {
        open: state.isClashDialogOpen,
        title: h('span', style(['red']), 'Clash with existing classes'),
        actions: [
          FlatButton('OKAY', {
            tabIndex: this.getProps().isAccessible ? 0 : undefined,
            onclick: () =>
              this.setState({
                isClashDialogOpen: false,
              }),
          }),
        ],
      },
      [
        h(
          'div',
          `You can't have a Class from ${startTime} to ${endTime} ` +
            'on the following dates because of clashes with the following ' +
            'pre-scheduled classes.'
        ),
        state.screen === 'dates'
          ? h('div', 'Please deselect the dates marked in red on the ' + 'calendar to continue.')
          : null,

        ...this.clashes().map((clash) =>
          h('div', style([mt('0.5rem')]), [
            h('div', style(['red']), datetime.format(clash.date, 'DD MMM, YYYY')),
            h('div', style(['small', 'lightGrey']), [
              `${u.capitalize(clash.type)}, ${datetime.format(
                clash.startTime,
                'hh:mm A'
              )} - ${datetime.format(clash.endTime, 'hh:mm A')}`,
            ]),
          ])
        ),
      ]
    );
  }

  private datesScreen() {
    const props = this.getProps();
    const state = this.getState();
    if (state.screen !== 'dates') return [];
    if (!props.course.dates) return [];
    return [
      MultiDatePicker({
        style: {
          width: '100%',
        },
        hideMonthHeaders: true,
        minDate: datetime.now(),
        maxDate: datetime.fromUnix(props.course.dates.endDate),
        clashes: state.clashes.map((c) => c.date),
        selected: state.multipleDatesField,
        onSelect: async (date, selection) => {
          let dates: Date[];
          if (selection === 'select') {
            dates = [date, ...state.multipleDatesField];
          } else {
            dates = state.multipleDatesField.filter((d) => !datetime.isSameDay(date, d));
          }
          await this.setState({
            multipleDatesField: dates,
          });
        },
      }),
    ];
  }

  private renderTime(hours: number, minutes: number) {
    // const ampm = _startHour >= 12 ? "PM" : "AM";
    // const startHour = _startHour > 12 ? _startHour - 12 : _startHour;
    // const endHour = _endHour;
    return u.showTime({
      hours: hours,
      minutes: minutes,
    });
  }

  private warning(classType: IClassType) {
    return h(
      'div',
      style(['flex', 'row', pad('0.5rem 1rem'), 'borderBox', 'orange', 'alignCenter']),
      [
        h('i.fa.fa-exclamation-triangle', style(['x-large']), []),
        h(
          'div',
          style([ml('0.5rem')]),
          `${u.capitalize(classType)}s are allowed only to ` +
            `provide completeness to the timeline. You won't be ` +
            `able to add activities to or take attendance in a ${u.capitalize(classType)}.`
        ),
      ]
    );
  }

  private classModality() {
    const { course } = this.getProps();
    const { isOnlineMeeting, classModalityError } = this.getState();

    if (!course.canStream) return null;

    return h('div', [
      h(
        'div',
        u.withTabIndex(
          style(
            [pad('0.5rem 1rem')],
            {},
            {
              className: cn({
                'fc-grey': !classModalityError,
                'fc-red': classModalityError,
              }),
            }
          )
        ),
        classModalityError
          ? 'Select classroom modality - Please select one option'
          : 'Select classroom modality'
      ),
      h(
        'div.ripple',
        style(
          ['flex', 'alignCenter', 'whiteBackground', pad('1rem'), mb('1px')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.setState({
                classModalityError: false,
                isOnlineMeeting: isOnlineMeeting === 1 ? null : 1,
              });
            },
          }
        ),
        [
          RadioButton({
            selected: isOnlineMeeting === 1,
            style: { marginRight: '0.5rem' },
          }),
          h('span', 'Online on Acadly (with Zoom plugin)'),
        ]
      ),
      h(
        'div.ripple',
        style(
          ['flex', 'alignCenter', 'whiteBackground', pad('1rem'), mb('1px')],
          {},
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.setState({
                classModalityError: false,
                isOnlineMeeting: isOnlineMeeting === 0 ? null : 0,
              });
            },
          }
        ),
        [
          RadioButton({
            selected: isOnlineMeeting === 0,
            style: { marginRight: '0.5rem' },
          }),
          h('span', 'In-person (or on another online platform)'),
        ]
      ),
    ]);
  }

  private mainScreen() {
    const props = this.getProps();
    const classTypes = NewClassDialogC.classTypes;
    const isMobile = getStore().getState().app.isMobile;
    const days = NewClassDialogC.days;
    const pickerStyle = this.pickerStyle();
    const state = this.getState();
    const timePickerStyle: CSS | undefined = style(['blue']).style;
    const timePickerWarningStyle: CSS | undefined = style(['orange', ml('auto')]).style;
    const timePickerErrorStyle: CSS | undefined = style(['red', ml('auto')]).style;
    const timePickerRowStyle = {
      display: 'flex',
      backgroundColor: 'white',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
      boxSizing: 'border-box',
    };

    const inconvenientTimeWarning = h(
      'div',
      style(
        ['orange', 'small', pr('1rem'), pb('0.5rem'), ml('auto')],
        {},
        { tabIndex: this.isAccessible ? 0 : undefined, role: 'warning' }
      ),
      'Warning: This timing seems a bit inconvenient'
    );

    const endTimeGreaterWarning = h(
      'div',
      style(
        ['red', 'small', pr('1rem'), pb('0.5rem'), ml('auto')],
        {},
        { tabIndex: this.isAccessible ? 0 : undefined, role: 'warning' }
      ),
      'Warning: Start time is greater than end time'
    );

    const timePickerWrapperAttrs = style([
      'flex',
      'column',
      {
        backgroundColor: 'white',
        marginBottom: '1px',
      },
    ]);

    return [
      props.course.status.schedulePublished
        ? h(
            'div',
            {
              tabIndex: this.getProps().isAccessible ? 0 : undefined,
              'aria-label': 'Choose when this class is to happen',
              style: {
                display: 'flex',
                marginBottom: '1px',
                alignItems: 'center',
                boxSizing: 'borderBox',
                backgroundColor: 'white',
                padding: '1rem',
              },
            },
            [
              'Class will happen on',
              h('div', { style: { marginLeft: 'auto' } }, [
                DatePicker({
                  value: this.getState().selectedDate,
                  style: {
                    color: colors.grey,
                  },
                  minDate: datetime.now(),
                  onchange: (selectedDate) => this.setState({ selectedDate }),
                }),
              ]),
              h('i.fa.fa-pencil.fc-grey.pointer', {
                style: { marginLeft: '0.5rem' },
              }),
            ]
          )
        : h('div', style(['whiteBackground']), [
            h(
              'div',
              {
                style: {
                  display: 'flex',
                  padding: '1rem',
                  justifyContent: 'space-between',
                },
              },
              [
                h('div', [
                  h('div', style([mb('0.5em')]), 'Class will happen every'),
                  state.weekDayError
                    ? h('div', style(['small', 'errorRed']), 'Please select a day')
                    : h('div', style(['small', 'grey']), 'You can select multiple days'),
                ]),
                h(
                  'div',
                  {
                    style: {
                      color: colors.blue,
                      textAlign: 'right',
                      maxWidth: isMobile ? '9.5em' : undefined,
                    },
                  },
                  [
                    !state.selectedDayIndices.length
                      ? 'No day selected'
                      : state.selectedDayIndices.map((i) => days[i]).join(', '),
                  ]
                ),
              ]
            ),
            h(
              'div.days',
              { style: pickerStyle },
              days.map((day, i) =>
                h(
                  'div.day',
                  {
                    key: day,
                    tabIndex: this.getProps().isAccessible ? 0 : undefined,
                    onclick: () => this.multipleDaysOnClickHandler(i),
                    style: this.multiplePickerElementStyle(i),
                  },
                  [day]
                )
              )
            ),
          ]),
      h('div', timePickerWrapperAttrs, [
        h(
          'div.start-time-row',
          {
            style: timePickerRowStyle,
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h('div', 'From'),
            TimePicker({
              style: this.isStartTimeInconvenient() ? timePickerWarningStyle : timePickerStyle,
              time: {
                hours: state.startTimeHour,
                minutes: state.startTimeMinute,
              },
              onChange: (time) =>
                this.setState({
                  startTimeHour: time.hours,
                  startTimeMinute: time.minutes,
                }),
            }),
          ]
        ),
        this.isStartTimeInconvenient() ? inconvenientTimeWarning : null,
      ]),
      h('div.end-time-wrapper', timePickerWrapperAttrs, [
        h(
          'div.end-time-row',
          {
            style: timePickerRowStyle,
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h('div', 'Till'),
            TimePicker({
              style: this.isEndTimeGreater()
                ? timePickerErrorStyle
                : this.isEndTimeInconvenient()
                ? timePickerWarningStyle
                : timePickerStyle,
              time: {
                hours: state.endTimeHour,
                minutes: state.endTimeMinute,
              },
              onChange: (time) =>
                this.setState({
                  endTimeHour: time.hours,
                  endTimeMinute: time.minutes,
                }),
            }),
          ]
        ),
        this.isEndTimeGreater()
          ? endTimeGreaterWarning
          : this.isEndTimeInconvenient()
          ? inconvenientTimeWarning
          : null,
      ]),
      this.classModality(),
      state.isOnlineMeeting === 0 || !props.course.canStream
        ? h(
            'div',
            {
              tabIndex: this.isAccessible ? 0 : undefined,
              'aria-label': 'set up Venue for this class',
              style: {
                display: 'flex',
                padding: '0em 1rem',
                boxSizing: 'border-box',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                paddingBottom: '0.5rem',
                marginBottom: '1px',
              },
            },
            [
              h(
                'div',
                {
                  style: {
                    width: '25%',
                    paddingTop: '18px',
                  },
                },
                'At (venue/link)'
              ),
              TextField({
                id: 'venue-input',
                focusOnMount: true,
                value: this.getState().venue,
                ariaLabel: this.getState().venueError
                  ? 'Please fill this field'
                  : 'Type venue/link here',
                errorText: this.getState().venueError ? 'Please fill this field' : undefined,
                oninput: (event) => this.venueInputHandler(event.target.value),
                placeholder: 'Type venue/link here',
                style: {
                  marginLeft: '1rem',
                  width: '70%',
                  // flex: 3,
                  color: colors.grey,
                },
                inputStyle: {
                  fontSize: '1em',
                },
                onenter: () => this.doneClickHandler(),
              }),
            ]
          )
        : null,
      props.type === 'new'
        ? h('div', [
            h(
              'div.ripple',
              style(
                [
                  'flex',
                  'pointer',
                  'alignCenter',
                  'spaceBetween',
                  'whiteBackground',
                  mt('1.5rem'),
                  pad('1rem'),
                  'spaceBetween',
                  'borderBox',
                  {
                    backgroundColor: 'white',
                  },
                ],
                {},
                {
                  tabIndex: this.isAccessible ? 0 : undefined,
                  onclick: () => this.recurringClassClickHandler(),
                }
              ),
              [
                h('div', 'This is a recurring class'),
                Icon(
                  icons.calendar,
                  style([
                    'blue',
                    {
                      fontSize: '1.3em',
                    },
                  ])
                ),
              ]
            ),
            h('div', style(['grey', 'small', pad('0.5rem 1rem')]), [
              'If the class repeats on other dates, with the same ' +
                'timings and venue, tap to select repeating dates.',
            ]),
          ])
        : null,
      Alert(
        {
          open: state.endTimeGreaterThanStartTimeError || state.startTimeInPastError,
          overlayStyle: {
            backgroundColor: colors.overlayOrange,
          },
          actions: [
            FlatButton('Ok', {
              onclick: () => {
                u.resetTabIndices(document.getElementById('dialog-box'));
                (this.lastFocusedElement as HTMLElement).focus();
                this.setState({
                  endTimeGreaterThanStartTimeError: false,
                  startTimeInPastError: false,
                });
              },
            }),
          ],
        },
        [
          state.endTimeGreaterThanStartTimeError
            ? h('div', 'The end time should be greater than the start time.')
            : null,
          state.startTimeInPastError ? h('div', 'Start time should be in the future.') : null,
        ]
      ),
      classTypes[state.selectedClassTypeIndex] !== 'lecture'
        ? this.warning(classTypes[state.selectedClassTypeIndex])
        : null,
    ];
  }
  private lastFocusedElement: Element | null = null;
  private async recurringClassClickHandler() {
    const fieldsValid = await this.validateFields();
    if (!fieldsValid) return;
    const state = this.getState();
    await this.setState({
      screen: 'dates',
      multipleDatesField: [state.selectedDate],
    });
  }

  private pickerStyle() {
    const error = this.getState().weekDayError;
    return {
      display: 'flex',
      backgroundColor: 'white',
      boxSizing: 'border-box',
      width: '100%',
      padding: '0.5rem 1rem',
      borderBottom: `2px solid ${error ? colors.errorRed : colors.lighterGrey}`,
      flexWrap: 'wrap',
    };
  }

  private async validateFields() {
    this.lastFocusedElement = document.activeElement;
    const state = this.getState();
    const props = this.getProps();
    const newState: Partial<INewClassDialogState> = {};
    let failure = false;

    if (!state.isOnlineMeeting && !state.venue.length) {
      newState.venueError = true;
      failure = true;
    }

    if (!this.getProps().course.status.schedulePublished && !state.selectedDayIndices.length) {
      newState.weekDayError = true;
      failure = true;
    }

    if (!this.isTimeValid(state.startTimeHour, state.startTimeMinute)) {
      failure = true;
      newState.startTimeError = true;
    }

    if (!this.isTimeValid(state.endTimeHour, state.endTimeMinute)) {
      failure = true;
      newState.endTimeError = true;
    }

    const startTimeMinutes = state.startTimeHour * 60 + state.startTimeMinute;
    const endTimeMinutes = state.endTimeHour * 60 + state.endTimeMinute;

    if (startTimeMinutes >= endTimeMinutes) {
      failure = true;
      newState.endTimeGreaterThanStartTimeError = true;
      this.lastFocusedElement = document.activeElement;
      u.unsetTabIndices();
    }

    const startDate = datetime.addMinutes(
      datetime.startOfDay(state.selectedDate),
      startTimeMinutes
    );

    if (props.type !== 'weekly' && startDate <= datetime.now()) {
      failure = true;
      newState.startTimeInPastError = true;
      this.lastFocusedElement = document.activeElement;
      u.unsetTabIndices();
    }

    if (props.course.canStream && state.isOnlineMeeting == null) {
      failure = true;
      newState.classModalityError = true;
    }

    if (failure) {
      await this.setState(newState);
      return false;
    } else {
      return true;
    }
  }

  private async multipleClassesSubmitHandler() {
    if (this.clashes.length > 0) {
      await this.setState({
        isClashDialogOpen: true,
      });
      return;
    }
    await this.addClasses();
  }

  private clashes() {
    const state = this.getState();
    return state.clashes.filter((clash) =>
      state.multipleDatesField.find((d) => datetime.isSameDay(d, clash.date))
    );
  }

  private async multipleDaysOnClickHandler(index: number) {
    const selectedDays = this.getState().selectedDayIndices;
    const indexOfClickedDay = selectedDays.indexOf(index);
    if (indexOfClickedDay !== -1) {
      await this.setState({
        weekDayError: false,
        selectedDayIndices: selectedDays.filter((i) => i !== index),
      });
    } else {
      const indices = [...selectedDays, index];
      await this.setState({
        weekDayError: false,
        selectedDayIndices: indices.sort(),
      });
    }
  }

  private async doneClickHandler() {
    const state = this.getState();
    const fieldsValid = await this.validateFields();
    if (!fieldsValid) {
      return;
    }
    if (state.screen === 'dates') {
      await this.multipleClassesSubmitHandler();
      return;
    }
    if (this.getProps().course.status.schedulePublished) {
      const date = state.selectedDate;

      await this.setState({
        multipleDatesField: [datetime.startOfDay(date)],
      });
      await this.addClasses();
      return;
    } else {
      const startTime =
        u.padStart(state.startTimeHour.toString(), 2, '0') +
        ':' +
        u.padStart(state.startTimeMinute.toString(), 2, '0');
      const endTime =
        u.padStart(state.endTimeHour.toString(), 2, '0') +
        ':' +
        u.padStart(state.endTimeMinute.toString(), 2, '0');
      const selectedDayIndices = state.selectedDayIndices;
      const days = selectedDayIndices.map((i) => NewClassDialogC.days[i]);

      await this.weeklyClassAddHandler({
        itemType: 'scheduled',
        type: NewClassDialogC.classTypes[state.selectedClassTypeIndex],
        day: days,
        startTime: startTime,
        endTime: endTime,
        venue: state.venue,
        isOnlineMeeting: state.isOnlineMeeting || 0,
      });
    }
    u.unsetTabIndices(document.getElementById('tips-container'));
    await dispatch(appActions.startTip(true));
    await this.setState(NewClassDialogC.initialState);
    await this.getProps().close();
  }

  private getClassTimings(startOfDay: Date) {
    const state = this.getState();
    const date = startOfDay;
    const startTime = datetime.addMinutes(
      datetime.addHours(date, state.startTimeHour),
      state.startTimeMinute
    );

    const endTime = datetime.addMinutes(
      datetime.addHours(date, state.endTimeHour),
      state.endTimeMinute
    );
    return { startTime, endTime };
  }

  private async addClasses() {
    const state = this.getState();
    const response = await dispatch(
      ClassActions.addClass(
        {
          dates: state.multipleDatesField.map(datetime.startOfDay).map((date) => {
            const { startTime, endTime } = this.getClassTimings(date);
            const startTimeFormatted = datetime.format(startTime, 'YYYY-MM-DDTHH:mm');
            const endTimeFormatted = datetime.format(endTime, 'YYYY-MM-DDTHH:mm');
            return {
              scheStartTime: startTimeFormatted,
              scheEndTime: endTimeFormatted,
            };
          }),
          type: NewClassDialogC.classTypes[state.selectedClassTypeIndex],
          venue: state.venue,
          isOnlineMeeting: state.isOnlineMeeting || 0,
        },
        this.getProps().userId
      )
    );
    if (response.message === 'clash') {
      await this.setState({
        isClashDialogOpen: true,
        clashes: response.clashingClasses.map((clash) => {
          const year = Math.floor(clash.date / 10000);
          const month = Math.floor(clash.date / 100) % 100;
          const day = clash.date % 100;
          const date = new Date();
          date.setFullYear(year);
          date.setDate(day);
          date.setMonth(month - 1);
          return {
            date: date,
            type: clash.type,
            startTime: clash.startTime,
            endTime: clash.endTime,
          };
        }),
      });
    } else {
      await this.setState(NewClassDialogC.initialState);
      await this.getProps().close();
    }
  }

  private async weeklyClassAddHandler(
    cls: IAddWeeklyClassRequest & {
      itemType: 'scheduled';
    }
  ) {
    await dispatch(ClassActions.addWeeklyClass(cls));
    await this.getProps().close();
  }

  private venueInputHandler(text: string) {
    this.setState({
      venue: text,
      venueError: false,
    });
  }

  private isTimeValid(hour: number, minute: number) {
    return hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59;
  }

  private multiplePickerElementStyle(i: number) {
    const isSelected = this.getState().selectedDayIndices.indexOf(i) !== -1 ? true : false;
    const color = colors.blue;
    return {
      flex: '1',
      color: isSelected ? 'white' : color,
      backgroundColor: isSelected ? color : 'transparent',
      textAlign: 'center',
      padding: '0.5em',
      cursor: 'pointer',
    };
  }

  private isStartTimeInconvenient() {
    const state = this.getState();

    return this.isTimeInconvenient({
      hours: state.startTimeHour,
      minutes: state.startTimeMinute,
    });
  }

  private isEndTimeGreater() {
    const state = this.getState();
    const startTimeMinutes = state.startTimeHour * 60 + state.startTimeMinute;
    const endTimeMinutes = state.endTimeHour * 60 + state.endTimeMinute;
    return startTimeMinutes >= endTimeMinutes;
  }

  private isEndTimeInconvenient() {
    const state = this.getState();
    return this.isTimeInconvenient({
      hours: state.endTimeHour,
      minutes: state.endTimeMinute,
    });
  }

  private isTimeInconvenient(time: ITime) {
    const lowThresholdMinutes = 8 * 60;
    const highThresholdMinutes = 20 * 60;
    const minutes = time.hours * 60 + time.minutes;
    return minutes < lowThresholdMinutes || minutes > highThresholdMinutes;
  }
}
