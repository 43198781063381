/**
 * VirtualList component renders a scrollable list of components.
 * It only renders a limited number of components at a time.
 * It only renders the number of elements that can be visible on
 * the screen at a time. Invisible items are replaced with an empty
 * div of the same height as the element. This improves performance
 * significantly over a simple list of divs for long lists with
 * complicated render functions.
 *
 * Scroll position of VirtualList can be externally controlled
 * using getScrollController prop.
 * If a getScrollController callback is passed, a
 * IVirtualListScrollController object will be passed to the
 * callback. scrollToIndex method can be called on the object
 * with the index of the virtual list item to scroll to the
 * specified index in the list.
 *
 * @example
 * VirtualList({
 *   items: items,
 *   getScrollController: (controller) => this.scrollController = controller
 * })
 *
 * // somewhere else, in some event listener
 * if (this.scrollController) {
 *   this.scrollContoller.scrollToIndex(10);
 * }
 *
 * This will scroll the virtual list to the 10th item in the
 * list.
 * Note that currently, this doesn't take header height into
 * account so you might want to pass an index that is 1-2 items
 * less than the required item. It's not precice.
 *
 * @todo Add the height of the header prop, if present to
 * jump to the precice location of the element at given index.
 */
import { Subject } from 'rxjs/Subject';

import { CSS, h, IComponent, View } from 'core';

import { flatten, getHTMLTagSelector, repeat } from '../utils';

interface IVirtualListProps<Item> {
  items: Item[];
  key?: string;
  itemHeight: number;
  forceHide?: boolean;
  showBeforeIndex?: {
    index: number;
    view: View;
  };
  render: (item: Item) => View;
  dummyNode?: View;
  footer?: View;
  header?: View;
  style?: CSS;
  classNames?: string[];
  getScrollController?(controller: IVirtualListScrollController): void;
}

export interface IVirtualListScrollController {
  scrollToIndex(index: number): Promise<void>;
}

export class VirtualList<Item> extends IComponent<IVirtualListProps<Item>, Record<string, never>> {
  private scrollTop$ = new Subject<number>();
  private scrollTop = 0;
  private windowHeight = window.innerHeight;
  public componentWillMount() {
    this.scrollTop$.subscribe(this.scrollTopSubscriber);
  }

  private scrollTopSubscriber = (scrollTop: number) => {
    requestAnimationFrame(() => {
      this.scrollTop = scrollTop;
      this.setState({});
    });
  };

  public shouldComponentUpdate(nextProps: IVirtualListProps<Item>) {
    if (nextProps.forceHide) {
      return false;
    } else {
      return true;
    }
  }

  private headerHeight = 0;
  public render() {
    const itemHeight = this.getProps().itemHeight;
    const maxItems = Math.floor(this.windowHeight / itemHeight) + 10;
    const start = this.getStartIndex();
    const dummyRow = h(
      'div.virtual-list__dummy-row',
      {
        style: {
          height: this.getProps().itemHeight,
        },
      },
      'dummy'
    );
    const emptyRow = dummyRow;
    const list = [
      h(
        'div.virtual-list__dummy-row',
        {
          style: {
            height: start * this.getProps().itemHeight,
          },
        },
        'dummy'
      ),
      ...(this.getProps().forceHide
        ? repeat(emptyRow, maxItems)
        : flatten(
            this.getProps()
              .items.slice(start, start + maxItems)
              .map((item) => {
                const itemView = this.getProps().render(item);
                const props = this.getProps();
                if (
                  props.showBeforeIndex &&
                  this.getProps().items[props.showBeforeIndex.index] === item
                ) {
                  return [props.showBeforeIndex.view, itemView];
                } else {
                  return [itemView];
                }
              })
          )),
      h(
        'div.virtual-list__dummy-row',
        {
          style: {
            height:
              (this.getProps().items.length - (maxItems + start)) * this.getProps().itemHeight,
          },
        },
        'dummy'
      ),
    ];
    const props = this.getProps();
    return h(
      getHTMLTagSelector('div', ['virtual-list', ...(props.classNames || [])]),
      {
        key: props.key,
        style: props.style,
        ref: this.onRef,
        onscroll: this.handleScroll,
      },
      [
        props.header
          ? h(
              'div.virtual-list-header',
              {
                // Ideally, we would like to know the exact height
                // of the header so that we can calculate offsets
                // accurately. But this slows down rendering too much.
                // Disabling this.
                // ref: (elem?: HTMLElement) => {
                //     if (elem && elem.offsetHeight !== this.headerHeight) {
                //         this.headerHeight = elem.offsetHeight;
                //         this.setState({});
                //     }
                // }
              },
              [props.header]
            )
          : null,
        ...list,
        this.getProps().footer || h('div', []),
      ]
    );
  }

  private onRef = (elem?: HTMLElement) => {
    const props = this.getProps();
    if (elem && props.getScrollController) {
      props.getScrollController({
        scrollToIndex: async (index) => {
          const scrollTop = index * props.itemHeight - this.headerHeight;
          elem.scrollTo(0, scrollTop);
        },
      });
    }
  };

  private handleScroll = (event: any) => {
    this.scrollTop$.next(event.target.scrollTop);
  };

  private getStartIndex() {
    const itemHeight = this.getProps().itemHeight;
    return Math.max(Math.floor(this.scrollTop / itemHeight) - 5, 0);
  }
}

export default <Item>(props: IVirtualListProps<Item>) => h(VirtualList, props);
