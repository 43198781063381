import { CSS, h, IComponent } from 'core';

import DateView from 'acadly/common/DateView';
import Icon from 'acadly/common/Icon';
import * as dt from 'acadly/datetime';
import icons from 'acadly/icons';
import { getStore } from 'acadly/store';
import { colors, ml, mr, style } from 'acadly/styles';

export default (props: IPollWidgetProps) => h(PollWidget, props);
export interface IPollWidgetProps {
  poll: IPoll;
  courseRole: ICourseRole;
  showClassDate?: boolean;
  style?: CSS;
  onclick?: (e: Event) => void;
}
export class PollWidget extends IComponent<IPollWidgetProps, never> {
  public render() {
    const { poll, showClassDate, courseRole } = this.getProps();
    const userData = poll.userData;
    const widgetColor = this.getWidgetColor();
    const isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    const numCommentsSeen = userData ? userData.numCommentsSeen : 0;
    const newComments = poll.activities.numCommentsTotal - numCommentsSeen;
    const isUnseen = this.getProps().courseRole === 'student' && !userData;
    const startTime = dt.fromUnix(poll.details.publishedOn);
    const status = poll.userData && poll.userData.status;
    const statusText = status === 'inProgress' || status === undefined ? 'Due' : 'Completed';

    return h(
      'div',
      {
        onclick: this.getProps().onclick,
        tabIndex: isAccessible ? 0 : undefined,
        'aria-label': `${isUnseen ? `New` : ``}
                         Poll ${poll.details.trueNum} - ${poll.details.title || 'Untitled'}.
                        ${courseRole === 'student' ? (statusText ? `is ${statusText}` : ``) : ``}`,
        style: {
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
          display: 'flex',
          padding: '0.5em 1em',
          cursor: 'pointer',
          position: 'relative',
          boxSizing: 'border-box',
          borderBottom: `1px solid ${colors.lightestGrey}`,
          ...this.getProps().style,
        },
        key: poll._id,
      },
      [
        h(
          'div.poll-widget-lhs',
          {
            style: {
              width: '4em',
              display: 'flex',
              color: widgetColor,
              boxSizing: 'border-box',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
          [
            showClassDate
              ? dateCell(startTime, widgetColor)
              : Icon(icons.poll, {
                  style: {
                    fontSize: '2em',
                  },
                }),
            // this.getProps().showClassNum
            // ? `C-${cls.details.typeNum}`
            // : null
          ]
        ),

        h(
          'div.poll-widget-rhs',
          showClassDate ? style(['widgetFilterActivitiesRhs']) : style(['widgetRhs']),
          [
            h(
              'div.poll-widget-heading',
              style([
                'flex',
                'alignCenter',
                {
                  height: '1.5em',
                },
              ]),
              [
                h(
                  'span',
                  style(['thick', 'mediumDarkGrey', mr('0.5em')]),
                  'Poll ' +
                    (poll.details.trueNum !== undefined && poll.details.published
                      ? poll.details.trueNum.toString().length === 1
                        ? ` 0${poll.details.trueNum}` // adding 0 before single digits
                        : ` ${poll.details.trueNum}`
                      : '')
                ),
                this.isUnpublished() ? this.tag('UNPUBLISHED', colors.red) : null,

                this.isDue() ? this.tag('DUE', colors.green) : null,

                this.isClosed() ? this.tag('CLOSED', colors.red) : null,

                this.isPastDue() ? this.tag('PAST DUE', colors.red) : null,

                this.isUrgent() ? this.tag('URGENT', colors.darkPink) : null,

                isUnseen
                  ? h('span', style(['orange', ml('0.5em'), 'flex', 'alignCenter']), [
                      h('i.fa.fa-star', style([mr('0.3rem')])),
                      h('span', style(['x-small'], { fontWeight: '900' }), 'NEW'),
                    ])
                  : newComments
                  ? h('span', style(['orange', ml('0.5em')]), [
                      h('i.fa.fa-comment', style([mr('0.3em')])),
                      h('span', style(['x-small', 'bold']), newComments.toString()),
                    ])
                  : null,
                this.isUpdatedTagVisible()
                  ? h('span', style(['lightGreyText', ml('0.5em'), 'flex', 'alignCenter']), [
                      h('i.fa.fa-pencil', style([mr('0.3rem')])),
                      h('span', style(['x-small'], { fontWeight: '300' }), 'EDITED'),
                    ])
                  : null,

                this.topRight(),
              ]
            ),
            h(
              'div.title-widget',
              showClassDate ? style(['widgetFilterActivitiesRhsTitle']) : style(['widgetRhsTitle']),
              poll.details.title || 'Untitled'
            ),
          ]
        ),
      ]
    );
  }

  private topRight() {
    const { courseRole, poll } = this.getProps();
    const context = getStore().getState().app.context;

    if (context === 'course') {
      return h(
        'span',
        style(['lightGrey', 'small', ml('auto')]),
        poll.details.published
          ? `Published: ${dt.format(poll.details.publishedOn, 'MMM DD, YYYY')}`
          : `Created: ${dt.format(poll.details.createdOn, 'MMM DD, YYYY')}`
      );
    }

    if (courseRole !== 'student') {
      return h(
        'span',
        style(['lightGrey', 'small', ml('auto')]),
        `Submissions: ${poll.stats.numSubmitted}`
      );
    }

    return null;
  }

  private isUnpublished() {
    const { courseRole, poll } = this.getProps();
    return courseRole !== 'student' && !poll.details.published;
  }

  private isClosed() {
    const { poll, courseRole } = this.getProps();
    return (
      courseRole === 'student' &&
      !this.isSubmitted() &&
      !poll.details.allowLate &&
      ((poll.details.dueDateType === 'manual' && poll.details.dueDateTime !== -1) ||
        (poll.details.dueDateType !== 'manual' && dt.unix() > poll.details.dueDateTime))
    );
  }

  private isPastDue() {
    const { poll, courseRole } = this.getProps();
    return (
      courseRole === 'student' &&
      !this.isSubmitted() &&
      poll.details.allowLate &&
      ((poll.details.dueDateType === 'manual' && poll.details.dueDateTime !== -1) ||
        (poll.details.dueDateType !== 'manual' && dt.unix() > poll.details.dueDateTime))
    );
  }

  private isSubmitted() {
    const { poll, courseRole } = this.getProps();
    return (
      courseRole === 'student' &&
      poll.userData &&
      (poll.userData.status === 'submitted' || poll.userData.status === 'late')
    );
  }

  private isUrgent() {
    const { poll, courseRole } = this.getProps();
    return (
      poll.details.toBeDone === 'preClass' &&
      courseRole === 'student' &&
      !this.isSubmitted() &&
      dt.unix() < poll.details.dueDateTime &&
      dt.diff(dt.fromUnix(poll.details.dueDateTime), dt.now(), 'hours') <= 24
    );
  }

  private isDue() {
    const { poll, courseRole } = this.getProps();
    return (
      courseRole === 'student' &&
      !this.isSubmitted() &&
      !this.isUrgent() &&
      ((poll.details.dueDateType === 'manual' && poll.details.dueDateTime === -1) ||
        dt.unix() <= poll.details.dueDateTime)
    );
  }

  private isUpdatedTagVisible() {
    const { poll, courseRole } = this.getProps();
    if (poll.userData && poll.userData.submittedOn) {
      return false;
    }
    if (poll.userData && courseRole === 'student' && poll.details.lastEditedOn) {
      const lastAccessedOn = poll.userData.lastAccessedOn;
      return lastAccessedOn && lastAccessedOn < poll.details.lastEditedOn;
    }
    return false;
  }

  private tag(text: string, color: string) {
    return h(
      'div.tag-container',
      style([ml('0.8em')], {
        height: '80%',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        backgroundColor: color,
        textAlign: 'center',
        fontWeight: '900',
        borderRadius: '4px',
      }),
      [
        h(
          'div.tag-text',
          style([
            'white',
            'x-small',
            {
              lineHeight: '1.5em',
            },
          ]),
          [text]
        ),
      ]
    );
  }

  private getWidgetColor() {
    const { poll } = this.getProps();
    if (this.getProps().courseRole === 'student') {
      if (this.isPastDue() || this.isClosed()) {
        return colors.red;
      } else if (this.isUrgent()) {
        return colors.darkPink;
      } else if (poll.details.toBeDone === 'inClass' && this.isDue()) {
        return colors.green;
      } else if (poll.details.toBeDone === 'preClass' && this.isDue()) {
        return colors.blue;
      }
      if (!poll.userData) return colors.blue;
      if (poll.userData.status === 'inProgress') {
        return colors.blue;
      } else {
        return colors.lightGrey;
      }
    } else {
      if (poll.details.published) {
        return colors.lightGrey;
      } else {
        return colors.red;
      }
    }
  }
}
const DATE_CELL_ATTRS = {
  style: {
    display: 'flex',
    flexDirection: 'column',
  },
};
const DIV = 'div.date-cell';
function dateCell(startTime: Date, widgetColor: string) {
  return h(DIV, DATE_CELL_ATTRS, [DateView(startTime, widgetColor)]);
}
