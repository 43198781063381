import { jsonRequest } from 'core/http';

import { api as urls } from 'acadly/api';

export interface IAnalyticsSetRequest {
  queryId: string;
  firstAccess: 1;
  localTime: string; // "YYYYMMDDTHH:mm" format
}

export const analyticsSet = (data: IAnalyticsSetRequest) =>
  jsonRequest(urls().analyticsQuerySet, {
    method: 'POST',
    data,
  });

export const upvote = (queryId: string) =>
  jsonRequest(urls().queryUpvote + queryId, {
    method: 'GET',
  });

export const hide = (queryId: string) =>
  jsonRequest(urls().queryHide, {
    method: 'POST',
    data: { queryId },
  });

export const close = (queryId: string) =>
  jsonRequest(urls().queryClose, {
    method: 'POST',
    data: { queryId },
  });

export interface ICreateRequest<Anon extends 0 | 1> {
  classId: string;
  isAnon: Anon;
  description: string;
  title: string;
  attachments: IAttachment[];
}

export type ICreateResponse<Anon extends 0 | 1> = [IResponseQuery, Record<string, unknown>][Anon];
export const create = <Anon extends 0 | 1>(data: ICreateRequest<Anon>) =>
  jsonRequest<ICreateResponse<Anon>>(urls().queryCreate, {
    method: 'POST',
    data,
  });

export interface IFetchAllResponse {
  activityData: IResponseQueryWithClassId[];
  userData: {
    queries: ObjectMap<IResponseQueryUserData | undefined>;
  };
}
export const fetchAll = () =>
  jsonRequest<IFetchAllResponse>(urls().archiveGet + '/queries', {
    method: 'GET',
  });

export interface IApproveRequest {
  queryId: string;
}
export const approve = (data: IApproveRequest) =>
  jsonRequest<void>(urls().queryApprove, {
    method: 'POST',
    data,
  });

export interface IRemoveRequest {
  queryId: string;
}
export const remove = (data: IRemoveRequest) =>
  jsonRequest<void>(urls().queryRemove, {
    method: 'POST',
    data,
  });
