import { h } from 'core';

import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import RadioButton from 'acadly/common/RadioButton';
import * as datetime from 'acadly/datetime';
import { getStore } from 'acadly/store';
import * as utils from 'acadly/utils';

interface IActivityDurationMenuProps {
  cls: IClass;
  open: boolean;
  title: string;
  isPublishing: boolean;
  duration: ActivityDuration | null;
  onClose: () => void;
  onSelect: (duration: ActivityDuration) => void;
}

export const getActivityDurationLabel = (
  duration: ActivityDuration | null,
  isPublishing: boolean
) => {
  if (duration == null) return 'Select deadline';

  if (isPublishing) {
    switch (duration) {
      case -2:
        return 'By the end of the class';
      case -1:
        return 'Manually';
      case 0.5:
        return 'in 30 seconds';
      default:
        return `in ${duration} ${utils.pluralize(duration, 'minute')}`;
    }
  }

  switch (duration) {
    case -2:
      return 'When class ends';
    case -1:
      return 'Manually';
    case 0.5:
      return '30 seconds after publishing';
    default:
      return `${duration} ${utils.pluralize(duration, 'minute')} after publishing`;
  }
};

export default (props: IActivityDurationMenuProps) => {
  const { cls, title, duration: selectedDuration, isPublishing, open, onClose, onSelect } = props;

  const isAccessible = getStore().getState().app.acc.web.turnOff === 0;

  const durations: ActivityDuration[] = [0.5, 1, 2, 5, 10, 15, 20, 30, 45, 60];

  const availableDurations: ActivityDuration[] = [
    -1,
    ...durations.filter(
      (minutes) =>
        datetime.toUnix(datetime.addMinutes(datetime.now(), minutes)) <= cls.details.scheEndTime
    ),
    -2,
  ];

  return Alert(
    {
      open,
      title,
      style: { width: '25em' },
      actions: [
        FlatButton('CANCEL', {
          type: 'secondary',
          tabIndex: isAccessible ? 0 : undefined,
          onclick: onClose,
        }),
      ],
    },
    availableDurations.map((duration) =>
      h(
        'div.activity-prefs__duration-cell',
        {
          key: duration.toString(),
          tabIndex: isAccessible ? 0 : undefined,
          onclick: () => onSelect(duration),
        },
        [
          RadioButton({
            selected: selectedDuration === duration,
            style: {
              marginRight: '1em',
              width: '1.5em',
              height: '1.5em',
            },
          }),
          getActivityDurationLabel(duration, isPublishing),
        ]
      )
    )
  );
};
