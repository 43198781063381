import cn from 'classnames';

import { h, IComponent, View } from 'core';

import * as CloseIcon from 'assets/close.svg';
import * as FlashIcon from 'assets/flash.svg';

import portal from 'acadly/common/Portal';
import SvgIcon from 'acadly/common/SvgIcon';
import { getStore } from 'acadly/store';

const MINIMISED_HEIGHT = 60;
const INITIAL_TOP = 121;
const INITIAL_LEFT = 116;

type Wrapper = (body: View) => View;

interface ChildrenProps {
  wrapper: Wrapper;
  getState: () => Readonly<IQuickStartTipsState>;
}

interface IQuickStartTipsProps {
  qstKey: string;
  children: (props: ChildrenProps) => View;
}

interface IQuickStartTipsState {
  mount: boolean;
  flash: boolean;
  isCollapsed: boolean;
}

@portal(document.getElementById('tips-container'))
class QuickStartTips extends IComponent<IQuickStartTipsProps, IQuickStartTipsState> {
  private wrapperRef: HTMLElement | null = null;

  private setPersistentState(key: string, value: string) {
    localStorage.setItem(`qst::${key}`, value);
  }

  private getPersistentState<T = string>(
    key: string,
    defaultValue: string,
    parser?: (value: string) => T
  ): T {
    const value = localStorage.getItem(`qst::${key}`) || defaultValue;

    if (parser !== undefined) {
      return parser(value);
    }

    return value as unknown as T;
  }

  private markAsSeen() {
    const { qstKey } = this.getProps();
    this.setPersistentState(qstKey, 'seen');
  }

  private mountTimer?: NodeJS.Timer;

  public componentWillMount() {
    const { qstKey } = this.getProps();

    const isCollapsed = this.getPersistentState(
      'isCollapsed',
      'false',
      (value) => value === 'true'
    );

    const isSeen = this.getPersistentState(qstKey, 'false', (value) => value === 'seen');

    const initialState: IQuickStartTipsState = {
      isCollapsed,
      mount: false,
      flash: isCollapsed && !isSeen,
    };

    this.setState(initialState);

    if (!isCollapsed) {
      this.markAsSeen();
    }

    /**
     * This delay in mount hack is used intentionally,
     * because of the portal's component lifecycle issues
     */
    this.mountTimer = setTimeout(async () => {
      this.mountTimer = undefined;
      await this.setState({ mount: true });

      const { isCollapsed } = this.getState();
      if (!isCollapsed) {
        this.setMaxHeight();
      }
    }, 500);
  }

  public componentWillUnmount() {
    if (this.mountTimer) {
      clearTimeout(this.mountTimer);
    }
  }

  private setMinHeight() {
    if (!this.wrapperRef) return;
    this.wrapperRef.style.height = `${MINIMISED_HEIGHT}px`;
  }

  private setMaxHeight() {
    if (!this.wrapperRef) return;
    const { offsetHeight, clientHeight, scrollHeight } = this.wrapperRef;
    const scrollBarHeight = offsetHeight - clientHeight;
    const height = scrollHeight + scrollBarHeight;
    this.wrapperRef.style.height = `${height}px`;
  }

  private handleMaximise = () => {
    if (!this.wrapperRef) return;
    this.setState({ isCollapsed: false, flash: false });
    this.setPersistentState('isCollapsed', 'false');
    this.markAsSeen();
    this.setMaxHeight();
  };

  private handleMinimise = (e: MouseEvent) => {
    if (!this.wrapperRef) return;
    e.stopPropagation();
    this.setState({ isCollapsed: true });
    this.setPersistentState('isCollapsed', 'true');
    this.setMinHeight();
    // reset position
    this.setCoordinates(INITIAL_LEFT, INITIAL_TOP);
  };

  private setCoordinates(x: number, y: number) {
    if (!this.wrapperRef) return;
    this.wrapperRef.style.left = `${x}px`;
    this.wrapperRef.style.top = `${y}px`;
  }

  private dragStart = {
    mouseX: 0,
    mouseY: 0,
    wrapperX: 0,
    wrapperY: 0,
  };

  private handleMouseDown = (e: MouseEvent) => {
    const { isCollapsed } = this.getState();

    if (!this.wrapperRef) return;

    // do not drag if collapsed
    if (isCollapsed) return;

    const { left, top } = this.wrapperRef.getBoundingClientRect();

    this.dragStart = {
      mouseX: e.clientX,
      mouseY: e.clientY,
      wrapperX: left,
      wrapperY: top,
    };

    this.wrapperRef.style.userSelect = 'none';

    document.addEventListener('mouseup', this.handleMouseUp);
    document.addEventListener('mousemove', this.handleMouseMove);
  };

  private handleMouseMove = (e: MouseEvent) => {
    let isTicking = false;

    if (!isTicking) {
      isTicking = true;
      requestAnimationFrame(() => {
        isTicking = false;

        const { mouseX, mouseY, wrapperX, wrapperY } = this.dragStart;

        const diffX = e.clientX - mouseX;
        const diffY = e.clientY - mouseY;

        this.setCoordinates(wrapperX + diffX, wrapperY + diffY);
      });
    }
  };

  private handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);

    if (this.wrapperRef) {
      this.wrapperRef.style.userSelect = '';
    }
  };

  private wrapper = (body: View) => {
    const { qstKey } = this.getProps();
    const { isCollapsed, flash } = this.getState();
    const { isMobile } = getStore().getState().app;

    if (isMobile) return null;

    return h(
      'div.quick-start-tips',
      {
        key: qstKey,
        id: qstKey,
        ref: (el: HTMLDivElement) => {
          this.wrapperRef = el;
        },
        className: cn({
          flash,
          collapsed: isCollapsed,
          expanded: !isCollapsed,
        }),
        onclick: this.handleMaximise,
        onmousedown: this.handleMouseDown,
      },
      [
        h('div.quick-start-tips__header', [
          SvgIcon({
            noFill: true,
            icon: FlashIcon,
            className: 'quick-start-tips__icon quick-start-tips__bolt-icon',
            style: { marginRight: '0.5rem' },
          }),
          h('div', 'Quick start'),
          SvgIcon({
            icon: CloseIcon,
            className: 'quick-start-tips__close',
            style: { marginLeft: 'auto' },
            onclick: this.handleMinimise,
          }),
        ]),
        body,
      ]
    );
  };

  public render() {
    const { children } = this.getProps();
    const { mount } = this.getState();

    if (!mount) return null;

    return children({
      wrapper: this.wrapper,
      getState: () => this.getState(),
    });
  }
}

export default (props: IQuickStartTipsProps) => h(QuickStartTips, props);
