import { jsonRequest } from 'core/http';

import api from './api';

interface IFetchAvatarsResponse {
  links: ObjectMap<string>;
}
export const fetchAvatars = (ids: string[]) =>
  jsonRequest<IFetchAvatarsResponse>(api().getAvatars, {
    method: 'POST',
    data: {
      avatarIds: ids,
    },
  });
