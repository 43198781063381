import { h, IComponent, View } from 'core';

import AttachmentViewer from 'acadly/common/AttachmentViewer';
import ContentView from 'acadly/common/ContentView';
import { validateActivityPublish } from 'acadly/course/validations';
import Viewer from 'acadly/rich-text/Viewer';
import * as u from 'acadly/utils';

import api from '../api';
import Alert from '../common/Alert';
import Dialog from '../common/Dialog';
import FlatButton from '../common/FlatButton';
import FloatingActionButton from '../common/FloatingActionButton';
import Icon from '../common/Icon';
import TextField from '../common/TextField';
import icons from '../icons';
import Editor from '../rich-text/Editor';
import { goBack } from '../routes';
import { dispatch, getStore } from '../store';
import { colors, pad, style } from '../styles';
import { Actions } from './actions';

interface INewAnnouncementState {
  titleField: string;
  editingTitle: string;
  isEditingTitle: boolean;
  isSavingTitle: boolean;
  isDescriptionDialogOpen: boolean;
  description: string;
  descriptionField: string;
  files: IRequestAttachment[];
  titleFieldError: boolean;
  isDiscardWarningVisible: boolean;
  isPublishConfirmationVisible: boolean;
}

interface INewAnnouncementProps {
  course: ICourse;
}

export class NewAnnouncement extends IComponent<INewAnnouncementProps, INewAnnouncementState> {
  public componentWillMount() {
    const initialState: INewAnnouncementState = {
      isEditingTitle: false,
      isSavingTitle: false,
      isDescriptionDialogOpen: false,
      titleField: '',
      editingTitle: '',
      description: '',
      descriptionField: '',
      titleFieldError: false,
      isDiscardWarningVisible: false,
      isPublishConfirmationVisible: false,
      files: [],
    };
    this.setState(initialState);
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0 ? true : false;
  }

  public render() {
    const isMobile = getStore().getState().app.isMobile;
    const body: View[] = [
      this.titleEditView(),
      this.descriptionEditView(),
      this.attachmentView(),
      this.warnings(),

      this.editDescriptionDialog(),
      this.discardWarningDialog(),
      this.publishConfirmationDialog(),
      ...this.floatingActionButtons(),
    ];
    if (isMobile) {
      return Dialog(
        {
          title: 'New Announcement',
          open: true,
          secondaryAction: {
            label: 'BACK',
            mobileLabel: h('i.fa.fa-times', []),
            onclick: () => this.discardClickHandler(),
          },
          primaryAction: {
            label: 'SAVE',
            mobileLabel: h('i.fa.fa-check', []),
            onclick: () => this.saveHandler(),
          },
          style: {
            backgroundColor: colors.backgroundColor,
          },
        },
        [h('div.activity.new-announcement', body)]
      );
    } else {
      return ContentView(h('.acitivity__container.new-announcement', [h('div.activity', body)]));
    }
  }
  private floatingActionButtons() {
    const isMobile = getStore().getState().app.isMobile;
    if (isMobile) return [];
    return [
      FloatingActionButton(
        {
          tabIndex: this.isAccessible ? 0 : undefined,
          position: 'bottom-right',
          onclick: () => this.saveHandler(),
        },
        [h('span.fa.fa-floppy-o', {})]
      ),
      FloatingActionButton(
        {
          position: 'bottom-left',
          tabIndex: this.isAccessible ? 0 : undefined,
          style: {
            backgroundColor: colors.red,
          },
          onclick: () => this.discardClickHandler(),
        },
        [
          Icon(icons.cross, {
            ariaLabel: 'New Announcement',
          }),
        ]
      ),
    ];
  }
  private editTitleDialog() {
    const { isEditingTitle, editingTitle, titleFieldError } = this.getState();
    return Alert(
      {
        style: {
          width: '30em',
        },
        title: 'Announcement Title',
        open: isEditingTitle,
        hasInput: true,
        actions: [
          FlatButton('Discard', {
            type: 'secondary',
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.setState({
                isEditingTitle: false,
                editingTitle: '',
              }).then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-title');
                btn ? btn.focus() : null;
              }),
          }),
          FlatButton('Save', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () =>
              this.saveTitle().then(() => {
                u.resetTabIndices();
                const btn = document.getElementById('edit-title');
                btn ? btn.focus() : null;
              }),
          }),
        ],
      },
      [
        h('div.textfield-container', style(['flex', 'alignCenter', pad('0.5rem')]), [
          TextField({
            value: editingTitle,
            focusOnMount: true,
            errorText: titleFieldError ? 'Please enter a title' : undefined,
            placeholder: 'Enter title here',
            maxLength: 140,
            oninput: (e) =>
              this.setState({
                editingTitle: e.target.value,
                // titleField: e.target.value,
                titleFieldError: false,
              }),
            onenter: () => this.saveTitle(),
          }),
        ]),
      ]
    );
  }
  private lastFocusedElement: Element | null = null;

  private titleEditView() {
    const { titleField } = this.getState();

    const title =
      titleField.trim() ||
      h('span.fc-light-grey', [
        "This announcement doesn't have a title yet. ",
        'Click on the ',
        h('i.fa.fa-pencil'),
        ' icon to add a title',
      ]);

    return h(
      'div.panel',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        h('div.panel__header', [
          h('span', 'Title'),
          h('i.fa.fa-pencil.ripple#edit-title', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => {
              this.lastFocusedElement = document.activeElement;
              u.unsetTabIndices();
              this.setState({
                editingTitle: titleField,
                isEditingTitle: true,
              });
            },
          }),
        ]),
        this.editTitleDialog(),
        h('div.panel__content', [title]),
      ]
    );
  }

  private descriptionEditView() {
    return h(
      'div.panel',
      {
        tabIndex: this.isAccessible ? 0 : undefined,
      },
      [
        h('div.panel__header', [
          h('div', 'Description'),
          h('i.fa.fa-pencil#edit-description', {
            onclick: () => {
              u.unsetTabIndices();
              this.setState({
                isDescriptionDialogOpen: true,
                descriptionField: this.getState().description,
              });
            },
          }),
        ]),
        !this.getState().description
          ? h('div.panel__content.fc-light-grey', [
              'To add a rich text description, or attach files, ',
              'images etc to the announcement, ',
              'click on the ',
              h('i.fa.fa-pencil'),
              ' icon to add a title',
            ])
          : h('div.panel__content', [Viewer(this.getState().description)]),
      ]
    );
  }

  private attachmentView() {
    const state = this.getState();
    const hasAttachments = state.files.length > 0;

    if (!hasAttachments) return null;

    return h('div.activitiy__section', [
      h('div.cell', [h('span.cell__label', 'Attachments')]),
      h(
        'div.activity__attachments',
        state.files.map((file) =>
          AttachmentViewer({
            attachment: file,
            downloadUrl: '',
            downloadRequest: {},
            disabled: true,
          })
        )
      ),
    ]);
  }

  private warnings() {
    return h('div.activity__section', [
      this.warning([
        'All the course members will be immediately ',
        'notified via mail and push notifications.',
      ]),
      this.warning(['The announcement can not be edited or ', 'deleted once posted.']),
    ]);
  }

  private async saveTitle() {
    const { editingTitle } = this.getState();
    if (editingTitle.trim().length === 0) {
      await this.setState({
        titleFieldError: true,
      });
      return;
    } else {
      await this.setState({
        titleField: editingTitle,
        isSavingTitle: true,
      });

      await this.setState({
        isSavingTitle: false,
        isEditingTitle: false,
      });
    }
  }

  private isAccessible = false;

  private publishConfirmationDialog(): string | { props: any; state: any } | null {
    return Alert(
      {
        open: this.getState().isPublishConfirmationVisible,
        title: 'Posting a New Announcement',
        style: {
          maxWidth: '25em',
        },
        actions: [
          FlatButton('No', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () =>
              this.setState({
                isPublishConfirmationVisible: false,
              }).then(() => {
                u.resetTabIndices();
                (this.lastFocusedElement as HTMLElement).focus();
              }),
          }),
          FlatButton("Yes, I'm sure", {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.publish(),
          }),
        ],
      },
      [
        h(
          'div',
          {
            style: {},
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          'Please make sure your announcement has an appropriate title and description'
        ),
        h(
          'div',
          {
            style: {
              marginTop: '1em',
            },
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          "You won't be able to edit/delete it once it is posted."
        ),
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            style: {
              marginTop: '1em',
            },
          },
          'Are you sure you want to post the Announcement in its current form? '
        ),
      ]
    );
  }

  private discardWarningDialog() {
    return Alert(
      {
        open: this.getState().isDiscardWarningVisible,
        actions: [
          FlatButton('No', {
            type: 'secondary',
            onclick: () => this.setState({ isDiscardWarningVisible: false }),
          }),
          FlatButton('Yes', {
            onclick: goBack,
          }),
        ],
        style: {
          width: '25em',
        },
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
      },
      ['Do you want to discard your announcement?']
    );
  }

  private warning(text: string | View[]) {
    return h('div.activity__note', { tabIndex: this.isAccessible ? 0 : undefined }, [
      h('i.fa.fa-exclamation-triangle'),
      h('div', text),
    ]);
  }

  private editDescriptionDialog() {
    const state = this.getState();
    return Dialog(
      {
        title: 'Editing Description',
        open: this.getState().isDescriptionDialogOpen,
        secondaryAction: {
          label: 'DISCARD',
          mobileLabel: h('i.fa.fa-times', []),
          onclick: () =>
            this.setState({
              isDescriptionDialogOpen: false,
              descriptionField: state.description,
            }).then(() => {
              u.resetTabIndices();
              const btn = document.getElementById('edit-description');
              btn ? btn.focus() : null;
            }),
        },
        primaryAction: {
          label: 'SAVE',
          mobileLabel: h('i.fa.fa-check', []),
          onclick: () =>
            this.setState({
              isDescriptionDialogOpen: false,
              description: state.descriptionField,
            }).then(() => {
              u.resetTabIndices();
              const btn = document.getElementById('edit-description');
              btn ? btn.focus() : null;
            }),
        },
      },
      [
        Editor({
          value: state.descriptionField,
          subContext: 'description',
          enableTextFormatting: true,
          enableImageInput: true,
          enableFormulaInput: true,
          enableFileAttachments: true,
          attachments: {
            uploadUrl: api().announcementGetUploadUrl,
            files: this.getState().files,
            activityType: 'announcements',
          },
          oninput: (value, files) =>
            this.setState({
              descriptionField: value,
              files,
            }),
        }),
      ]
    );
  }

  private async saveHandler() {
    const { course } = this.getProps();
    const { titleField } = this.getState();
    if (
      !validateActivityPublish({
        activityType: 'announcement',
        title: titleField,
        course,
      })
    ) {
      return;
    }
    this.lastFocusedElement = document.activeElement;
    u.unsetTabIndices();
    await this.setState({ isPublishConfirmationVisible: true });
  }

  private async publish() {
    u.resetTabIndices();
    const { titleField, description, files } = this.getState();
    await dispatch(
      Actions.publishAnnouncement({
        title: titleField,
        description: description,
        attachments: files,
      })
    );
    await this.setState({
      isPublishConfirmationVisible: false,
    });
    goBack();
  }

  private async discardClickHandler() {
    const { titleField, description } = this.getState();
    if (titleField.length > 0 || description.length > 0) {
      await this.setState({
        isDiscardWarningVisible: true,
      });
    } else {
      goBack();
    }
  }
}

export default (props: INewAnnouncementProps) => h(NewAnnouncement, props);
