import { h, View } from 'core';

import { getHTMLTagSelector } from 'acadly/utils';

interface IDrawerProps {
  docked?: boolean;
  position?: 'left' | 'right';
  open: boolean;
  id?: string;
  style?: any;
  classNames?: string[];
}

export default (props: IDrawerProps, children: View[]) => {
  const { position = 'left' } = props;
  return h(
    getHTMLTagSelector(
      'div',
      ['drawer', position, props.open ? 'open' : '', ...(props.classNames || [])],
      props.id
    ),
    {
      key: props.id,
      'aria-hidden': !props.open,
    },
    // children
    props.open ? children : [h('div.drawer__placeholder')]
  );
};
