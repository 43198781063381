import { IRootState } from 'acadly/IRootState';
import * as utils from 'acadly/utils';

export const getTotalComments = (assignment: IAssignment) => assignment.activities.numCommentsTotal;

export const getSeenComments = (assignment: IAssignment) => {
  const total = getTotalComments(assignment);
  const seen = assignment.userData.numCommentsSeen;
  return seen <= total ? seen : total;
};

export const getUnseenComments = (assignment: IAssignment) =>
  getTotalComments(assignment) - getSeenComments(assignment);

export default { getTotalComments, getSeenComments, getUnseenComments };

export function updateAssignment(
  state: IRootState,
  assignmentId: string,
  f: (a: IAssignment) => IAssignment
): IRootState {
  const timeline = state.courses.timeline;
  if (!timeline) return state;
  const assignment = timeline.items.find((a) => a._id === assignmentId) as IAssignment;
  if (!assignment) return state;
  return {
    ...state,
    courses: {
      ...state.courses,
      timeline: {
        ...timeline,
        items: utils.replaceWhere(timeline.items, f, (a) => a._id === assignmentId),
      },
    },
  };
}
