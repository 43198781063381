import { h, HTMLAttrs } from 'core';
export const Icon = (
  icon: string | { url: string },
  attrs: HTMLAttrs = {},
  selector = '',
  options: {
    isDiv: boolean;
  } = {
    isDiv: false,
  }
) => {
  const tag = options.isDiv ? 'div' : 'span';
  if (typeof icon === 'string') {
    return h(
      `${tag}.acadly-icon${selector}`,
      {
        ...attrs,
        style: {
          ...attrs.style,
          fontFamily: 'acadly-icons',
        },
        tabIndex: attrs.tabIndex !== undefined ? attrs.tabIndex : undefined,
        'aria-label': attrs.ariaLabel ? attrs.ariaLabel : undefined,
        ariaLabel: undefined,
      },
      icon
    );
  } else {
    return h('img', {
      ...attrs,
      alt: attrs.ariaLabel,
      src: icon.url,
    });
  }
};
export default Icon;
