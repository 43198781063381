import { h, View } from 'core';

import Icon from 'acadly/common/Icon';
import courseService from 'acadly/course/service';
import icons from 'acadly/icons';

function helpTemplate(title: string, details: View | View[]) {
  return h('div.tutorial__help', [
    h('div.tutorial__help__title', title),
    h('div.fc-grey', details instanceof Array ? details : [details]),
  ]);
}

export default () => {
  const course = courseService.getCurrentCourse()!;

  const paragraph = (text: string) => h('p.tutorial__para', text);
  const bullet = (text: string) => h('li.tutorial__bullet', text);
  const line = (sentences: View[]) => {
    return h(
      'div.tutorial__line',
      sentences.map((s) => h('span.tutorial__sentence', [s]))
    );
  };

  const helpBeforePublish = helpTemplate('Publishing the course', [
    paragraph(
      `Please check that the class schedule on the Course Timeline
            accurately reflects your course schedule.`
    ),
    paragraph(
      `You can delete unnecessary classes and add a extra classes
            to prune the Timeline to your liking.`
    ),
    paragraph(`Once you’re done, you need to Publish the course to:`),
    h('ul', [
      course.enrolmentType === 'enrolmentCode'
        ? bullet('Generate a Join Code to share with the students')
        : bullet('Enrol students by adding their email addresses to the course'),
      bullet('Allow course Co-instructors and Teaching Assistants to access the course'),
      bullet('Create and Publish Activities'),
      bullet('Record attendance'),
    ]),
  ]);

  const enrollStudentHelp = [
    line([
      'You can access student enrolments at any time by clicking the',
      h('span.tutorial__sentence.fc-blue', [
        Icon(icons.analytics, { className: 'tutorial__sentence' }),
        'Course Analytics',
      ]),
      'button.',
    ]),
    paragraph('Happy teaching!'),
  ];

  const helpAfterPublishForJoinCode = helpTemplate('To Enrol Students', [
    paragraph(
      `To allow students to join the course, you need to share the following Join Code
            with your students:`
    ),
    h('div.tutorial__join-code', course.joinCode),
    paragraph(
      `If the students already have an Acadly account, they can join the course after
            logging in to Acadly on their devices.`
    ),
    paragraph(
      `If they don’t have an Acadly account yet, they can use the course Join Code to
            create a new student account.`
    ),
    ...enrollStudentHelp,
  ]);

  const helpAfterPublishForInviteStudent = helpTemplate('To Enrol Students', [
    line([
      `Course Admin can enrol students to the course by navigating to the Course Analytics
            section by clicking on the`,
      Icon(icons.analytics, { className: 'tutorial__sentence fc-blue' }),
      'button. Students can then be invited by adding their email addresses.',
    ]),
    paragraph(
      `If the students already have an Acadly account, they will be notified via email
            and the course will automatically show up on their accounts.`
    ),
    paragraph(
      `If they don’t have an Acadly account yet, they will have be notified via email to
            sign up for Acadly to access the course.`
    ),
    line([
      'Course Admin can invite students to the course at any time by clicking the',
      h('span.tutorial__sentence.fc-blue', [
        Icon(icons.analytics, { className: 'tutorial__sentence' }),
        'Course Analytics',
      ]),
      'button.',
    ]),
    paragraph('Happy teaching!'),
  ]);

  return h('div.tutorial__wrapper', [
    h('div.tutorial__acadly', [
      h('img.tutorial__avatar', {
        src: 'https://s3.amazonaws.com/static.acad.ly/img/acadly-web-sidebar-logo.png',
      }),
      h('div.tutorial__acadly__content', [
        h('div.fc-green', 'Acadly Help'),
        h('div.fc-light-grey', 'System | A few moments ago'),
      ]),
    ]),
    h('div.tutorial', [
      !course.status.courseLive && !course.status.studentsEnrolled ? helpBeforePublish : null,
      course.status.courseLive &&
      !course.status.studentsEnrolled &&
      course.enrolmentType === 'enrolmentCode'
        ? helpAfterPublishForJoinCode
        : null,
      course.status.courseLive &&
      !course.status.studentsEnrolled &&
      course.enrolmentType === 'enrolmentInvite'
        ? helpAfterPublishForInviteStudent
        : null,
    ]),
  ]);
};
