import { h, IComponent } from 'core';

import ContentView from 'acadly/common/ContentView';
import { Loader } from 'acadly/common/Loader';
import StudentList, { getStudentListData } from 'acadly/common/StudentList';
import User from 'acadly/common/User';
import * as dt from 'acadly/datetime';
import { dispatch, getStore } from 'acadly/store';

import { Actions } from './actions';

export interface IResourceAnalyticsState {
  resourceAnalytics: IResourceAnalyticsAccessedBy;
  searchField: string;
  sortStudentBy: StudentSortBy;
}

export interface IResourceAnalyticsProps {
  resource: IResource;
}
export class ResourceAnalytics extends IComponent<
  IResourceAnalyticsProps,
  IResourceAnalyticsState
> {
  public componentWillMount() {
    const resource = this.getProps().resource;
    dispatch(Actions.resourceAnalyticsFetch(resource._id));
    this.setState({
      searchField: '',
      sortStudentBy: getStore().getState().app.sortStudentBy,
    });
  }

  public render() {
    return ContentView(h('div.analytics__content.analytics__section', this.body()));
  }

  private totalClicks(resourceAnalytics: IResourceAnalytics) {
    let clicks = 0;
    for (let i = 0; i < resourceAnalytics.accessedBy.length; i++) {
      clicks = clicks + resourceAnalytics.accessedBy[i].status.timesFetched;
    }
    return clicks;
  }

  private body() {
    const resourceAnalytics = getStore().getState().resources.analytics;
    const totalClicks = this.totalClicks(resourceAnalytics);
    const { searchField, sortStudentBy } = this.getState();

    const renderStudent = (a: IResourceAnalyticsAccessedBy) => {
      return User(
        {
          avatar: {
            url: a.identifiers.avatar,
            creator: a.identifiers.name,
          },
          title: a.identifiers.name,
          titleClassNames: ['fc-green'],
          subtitle: `Accessed: ${dt.format(dt.fromUnix(a.status.firstAccessedOn), 'MMM-DD-YYYY')}`,
          subtitleClassNames: ['fc-orange'],
          action:
            a.status.timesFetched > 1
              ? `${a.status.timesFetched} clicks`
              : `${a.status.timesFetched} click`,
          actionClassNames: ['fc-light-grey'],
        },
        {
          className: 'student',
        }
      );
    };

    return [
      h('div.cell.cell--full-width', {}, [
        h('div.fs-lg.fc-dark-blue', 'Total Clicks'),
        h('div.fc-light-grey', totalClicks.toString()),
      ]),

      h('div.analytics__header', 'Accessed By'),
      h('div.analytics__sub-header', `As of ${dt.format(dt.now(), 'MMM Do YYYY')}`),

      resourceAnalytics
        ? StudentList({
            sortBy: sortStudentBy,
            children: getStudentListData(
              resourceAnalytics.accessedBy,
              searchField,
              sortStudentBy,
              (s) => s.identifiers.name
            ).map(renderStudent),
            onSearch: (term) => {
              this.setState({
                searchField: term,
              });
            },
            onSort: (sortBy) => {
              this.setState({
                sortStudentBy: sortBy,
              });
            },
          })
        : Loader(),
    ];
  }
}

export default (resource: IResource) => h(ResourceAnalytics, { resource });
