import { h, IComponent } from 'core';

import Alert from 'acadly/common/Alert';
import FlatButton from 'acadly/common/FlatButton';
import RadioButton from 'acadly/common/RadioButton';
import { mb, pad, style } from 'acadly/styles';
import { withTabIndex } from 'acadly/utils';

interface IResponseTypeMenuProps {
  open: boolean;
  initialIsAnon: 0 | 1;
  onCancel: () => void;
  onSelect: (isAnon: 0 | 1) => void | Promise<void>;
}

interface IResponseTypeMenuState {
  isAnon: 0 | 1;
}

class ResponseTypeMenu extends IComponent<IResponseTypeMenuProps, IResponseTypeMenuState> {
  private init() {
    const { initialIsAnon } = this.getProps();
    const initialState: IResponseTypeMenuState = {
      isAnon: initialIsAnon,
    };
    this.setState(initialState);
  }

  public componentWillMount() {
    this.init();
  }

  public componentDidUpdate(prevProps: IResponseTypeMenuProps) {
    const currentProps = this.getProps();
    if (currentProps.open && prevProps.open !== currentProps.open) {
      // alert is opening
      this.init();
    }
  }

  public render() {
    const { open, onCancel, onSelect } = this.getProps();
    const { isAnon } = this.getState();

    const ResponseTypeCell = (value: 0 | 1) => {
      const attr = style(
        [
          'fullWidth',
          'whiteBackground',
          pad('0.75rem 1rem'),
          mb('1px'),
          'flex',
          'alignCenter',
          'pointer',
        ],
        {},
        withTabIndex({
          onclick: () => this.setState({ isAnon: value }),
        })
      );

      return h('div.ripple', attr, [
        RadioButton({
          selected: isAnon === value,
          style: {
            marginRight: '0.5rem',
          },
        }),
        h('div', style(['grey']), [value === 1 ? 'Anonymous' : 'Not anonymous']),
      ]);
    };

    return Alert(
      {
        open,
        title: 'Student response',
        style: { width: '25em' },
        bodyStyle: { padding: 0 },
        actions: [
          FlatButton('Cancel', {
            type: 'secondary',
            onclick: onCancel,
          }),
          FlatButton('Save', {
            type: 'primary',
            onclick: () => onSelect(isAnon),
          }),
        ],
      },
      [ResponseTypeCell(0), ResponseTypeCell(1)]
    );
  }
}

export default (props: IResponseTypeMenuProps) => h(ResponseTypeMenu, props);
