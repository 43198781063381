import courseService from 'acadly/course/service';
import { getStore } from 'acadly/store';

export type IRichTextSubContext =
  | 'topic'
  | 'summary'
  | 'assignment'
  | 'in-class'
  | 'pre-class'
  | 'description'
  | 'instruction'
  | 'question';
export class GoogleAnalytics {
  public dimension = {
    slug: 'dimension1',
    role: 'dimension2',
    context: 'dimension3',
    subContext: 'dimension4',
  };

  public courseCreated() {
    console.info('AnalyticsFactory.courseCreated...');
    this.sendEvent({
      category: 'course',
      action: 'created',
      role: 'admin',
    });
  }

  public courseRemoved() {
    console.info('AnalyticsFactory.courseRemoved...');
    this.sendEvent({
      category: 'course',
      action: 'removed',
      role: 'admin',
    });
  }

  /**
   * @arg context: "added" | "removed"
   * @arg subContext: "ta" | "instructor"
   */
  public teamEdited(context: 'added' | 'removed', subContext: 'ta' | 'instructor') {
    console.info('AnalyticsFactory.teamEdited...');
    this.sendEvent({
      category: 'course',
      action: 'team_edited',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: "added" | "removed"
   * @arg subContext: "file" | "typed"
   */
  public studentsEdited(context: 'added' | 'removed', subContext: 'file' | 'typed') {
    console.info('AnalyticsFactory.studentsEdited...');
    this.sendEvent({
      category: 'course',
      action: 'students_edited',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: "assignment" | "quiz" | "poll" | "resource" | "discussion" | "announcement"
   * @arg subContext: "preClass" | "inClass" | "course"
   */
  public activityCreated(
    context: 'assignment' | 'quiz' | 'poll' | 'resource' | 'discussion' | 'announcement',
    subContext: 'preClass' | 'inClass' | 'course'
  ) {
    console.info('AnalyticsFactory.activityCreated...');
    this.sendEvent({
      category: 'activity',
      action: 'created',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: "assignment" | "quiz" | "poll" | "resource" | "discussion" | "announcement"
   * @arg subContext: "preClass" | "inClass" | "course"
   */
  public activityPublished(
    context: 'assignment' | 'quiz' | 'poll' | 'resource' | 'discussion' | 'announcement',
    subContext: 'preClass' | 'inClass' | 'course'
  ) {
    console.info('AnalyticsFactory.activityPublished...');
    this.sendEvent({
      category: 'activity',
      action: 'published',
      context: context,
      subContext: subContext,
    });
  }

  public classStarted() {
    console.info('AnalyticsFactory.classStarted...');
    this.sendEvent({
      category: 'class',
      action: 'started',
    });
  }

  /**
   * @arg context: "on-time" | "late"
   */
  public classEnded(context: 'on-time' | 'late') {
    console.info('AnalyticsFactory.classEnded...');
    this.sendEvent({
      category: 'class',
      action: 'ended',
      context: context,
    });
  }

  public attendanceMarked() {
    console.info('AnalyticsFactory.attendanceMarked...');
    this.sendEvent({
      category: 'class',
      action: 'attendance',
    });
  }

  public classInchargeSet() {
    console.info('AnalyticsFactory.classInchargeSet...');
    this.sendEvent({
      category: 'class',
      action: 'set_incharge',
    });
  }

  /**
   * @arg context: "video" | "url" | "file"
   * @arg subContext: "pre-class" | "in-class"
   */
  public resourcePublished(
    context: 'video' | 'url' | 'file',
    subContext: 'pre-class' | 'in-class'
  ) {
    console.info('AnalyticsFactory.resourcePublished...');
    this.sendEvent({
      category: 'activity',
      action: 'resource_published',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: "assignment" | "quiz" | "poll" | "resource" | "discussion" | "announcement"
   * @arg subContext: "course" | "pre-class" | "in-class"
   */
  public activityDeleted(
    context: 'assignment' | 'quiz' | 'poll' | 'resource' | 'discussion',
    subContext: 'course' | 'pre-class' | 'in-class'
  ) {
    console.info('AnalyticsFactory.activityDeleted...');
    this.sendEvent({
      category: 'activity',
      action: 'deleted',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: "assignment" | "quiz" | "poll" | "resource" | "discussion" | "announcement"
   * @arg subContext: "course" | "pre-class" | "in-class"
   */
  public activityMoved(context: IAppContext, subContext: 'course' | 'pre-class' | 'in-class') {
    console.info('AnalyticsFactory.activityMoved...');
    this.sendEvent({
      category: 'activity',
      action: 'moved',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: "assignment" | "quiz" | "poll"
   * @arg subContext: "tf" | "mcq" | "assignment"
   */
  public questionsAdded(
    context: 'assignment' | 'quiz' | 'poll',
    subContext: IQuizQuestionType | 'assignment'
  ) {
    console.info('AnalyticsFactory.questionsAdded...');
    this.sendEvent({
      category: 'activity',
      action: 'question_added',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: "pre-class" | "in-class" | "review"
   */
  public queryHidden(context: 'pre-class' | 'in-class' | 'review') {
    console.info('AnalyticsFactory.queryHidden...');
    this.sendEvent({
      category: 'activity',
      action: 'query_hidden',
      context: context,
    });
  }

  public summaryCreated() {
    console.info('AnalyticsFactory.summaryCreated...');
    this.sendEvent({
      category: 'class',
      action: 'summary_created',
    });
  }

  public checkIn = () => {
    console.info('AnalyticsFactory.checkIn...');
    this.sendEvent({
      category: 'class',
      action: 'check_in',
    });
  };

  public activitySubmission(
    context: 'assignment' | 'quiz' | 'poll',
    subContext: 'course' | 'in-class' | 'pre-class'
  ) {
    console.info('AnalyticsFactory.activitySubmission...');
    this.sendEvent({
      category: 'activity',
      action: 'submission',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: "pre-class" | "in-class" | "review"
   */
  public queryCreated(context: 'pre-class' | 'in-class' | 'review' | 'anonymous') {
    console.info('AnalyticsFactory.queryCreated...');
    this.sendEvent({
      catetory: 'activity',
      action: 'query_created',
      context: context,
    });
  }

  /**
   * @arg context: course, class, assignment,  quiz, poll, resource, discussion
   * @arg subContext: course, class, assignment, in-class, pre-class
   */
  public commentCreated(
    context: IAppContext,
    subContext: 'course' | 'class' | 'assignment' | 'in-class' | 'pre-class'
  ) {
    console.info('AnalyticsFactory.commentCreated...');
    this.sendEvent({
      category: 'comment',
      action: 'created',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: course, class, assignment,  quiz, poll, resource, discussion
   * @arg subContext: course, class, assignment, in-class, pre-class
   */
  public commentRemoved(
    context: IAppContext,
    subContext: 'course' | 'class' | 'assignment' | 'in-class' | 'pre-class'
  ) {
    console.info('AnalyticsFactory.commentRemoved...');
    this.sendEvent({
      category: 'comment',
      action: 'removed',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: course, class, assignment,  quiz, poll, resource, discussion
   * @arg subContext: course, class, assignment, in-class, pre-class
   */
  public commentLiked(
    context: IAppContext,
    subContext: 'course' | 'class' | 'assignment' | 'in-class' | 'pre-class'
  ) {
    console.info('AnalyticsFactory.commentLiked...');
    this.sendEvent({
      category: 'comment',
      action: 'liked',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: course, class, assignment,  quiz, poll, resource, discussion
   * @arg subContext: course, class, assignment, in-class, pre-class
   */
  public commentThanked(
    context: IAppContext,
    subContext: 'course' | 'class' | 'assignment' | 'in-class' | 'pre-class'
  ) {
    console.info('AnalyticsFactory.commentThanked...');
    this.sendEvent({
      category: 'comment',
      action: 'thanked',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: course, class, assignment,  quiz, poll, resource, discussion, announcement
   * @arg subContext: topic, summary, assignment, in-class, pre-class
   */
  public fileUploaded(context: IAppContext, subContext: IRichTextSubContext) {
    console.info('AnalyticsFactory.fileUploaded...');
    this.sendEvent({
      category: 'media',
      action: 'uploaded',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: course, class, assignment,  quiz, poll, resource, discussion, announcement
   * @arg subContext: description, summary, instruction, question, in-class, pre-class
   */
  public formulaCreated(context: IAppContext, subContext: IRichTextSubContext) {
    console.info('AnalyticsFactory.formulaCreated...');
    this.sendEvent({
      category: 'media',
      action: 'formula_inserted',
      context: context,
      subContext: subContext,
    });
  }

  /**
   * @arg context: course, class, assignment,  quiz, poll, resource, discussion, announcement
   * @arg subcontext: description, summary, instruction,
   *      question, in-class, pre-class, announcement
   */
  public imageUploaded(
    context: IAppContext,
    subContext: IRichTextSubContext | 'in-class' | 'pre-class' | 'announcement'
  ) {
    console.info('AnalyticsFactory.imageUploaded...');
    this.sendEvent({
      category: 'media',
      action: 'image_uploaded',
      context: context,
      subContext: subContext,
    });
  }

  private sendEvent(obj: any) {
    if (!ga) return;
    ga('app.set', this.dimension.slug, getStore().getState().getIn.session!.university.slug);
    ga('app.set', this.dimension.role, obj.role || courseService.getRole());
    ga('app.set', this.dimension.context, obj.context);
    ga('app.set', this.dimension.subContext, obj.subContext);
    ga('app.send', {
      hitType: 'event',
      eventCategory: obj.category,
      eventAction: obj.action,
    });
  }
}

export const googleAnalytics = new GoogleAnalytics();
