import { h } from 'core';

import * as NowPlayingIcon from 'assets/now_playing.svg';
import * as ZoomUsIcon from 'assets/zoomus-icon-round.svg';

import appService from 'acadly/app/service';
import Avatar from 'acadly/common/Avatar';
import SvgIcon from 'acadly/common/SvgIcon';
import { IMyCoursesResponseUserData } from 'acadly/course/api';
import courseService from 'acadly/course/service';
import * as dt from 'acadly/datetime';
import { Routes } from 'acadly/routes';
import { getStore } from 'acadly/store';
import * as u from 'acadly/utils';

interface ICourseWidgetCourse {
  _id: ICourse['_id'];
  canStream?: ICourse['canStream'];
  courseTimezone: ICourse['courseTimezone'];
  details: ICourse['details'];
  status: ICourse['status'];
  team: ICourse['team'];
  dates: ICourse['dates'];
  activities?: ICourse['activities'];
  totalActivities?: ICourse['totalActivities'];
}
interface ICourseWidgetProps {
  course: ICourseWidgetCourse | ICourse;
  userData?: IMyCoursesResponseUserData;
  onClick?(): void;
  onShowCreateCourseDialog?(): void;
  onShowCourseNotPublishedDialog?(): void;
}

export const CourseWidget = (props: ICourseWidgetProps) => {
  const course = props.course;
  const newUpdates = getStudentUpdates();
  const newQueries = getUnseenQueries();
  const pendingQueries = getPendingQueries();
  const storeState = getStore().getState();

  const isAccessible = storeState.app.acc.web.turnOff === 0;
  const { classMediaPlayer } = storeState.pipContainers;

  const isNowPlaying = classMediaPlayer.show && classMediaPlayer.courseId === props.course._id;

  const view = h(
    `div.course-widget`,
    {
      key: props.course._id,
      tabIndex: isAccessible ? 0 : undefined,
      'aria-label': isAccessible ? `Course: ${props.course.details.title}` : undefined,
      onclick:
        props.onClick ||
        (() => {
          const role = getRole();
          if (course.status.courseLive || (course.status.schedulePublished && role === 'admin')) {
            Routes.courseTimeline.navigate({
              courseShortId: courseService.getShortIdFromCourseId(course._id),
              univSlug: appService.getUniversitySlug(),
            });
          } else if (props.onShowCreateCourseDialog && role === 'admin') {
            props.onShowCreateCourseDialog();
          } else if (
            !course.status.courseLive &&
            props.onShowCourseNotPublishedDialog &&
            (role === 'instructor' || role === 'ta')
          ) {
            props.onShowCourseNotPublishedDialog();
          }
        }),
    },
    [
      h('div.course-widget__header', [
        h(
          u.getHTMLTagSelector('div', [
            'course-widget__detail',
            getRole() === 'admin' ? 'admin' : '',
          ]),
          [
            isNowPlaying
              ? SvgIcon({
                  noFill: true,
                  icon: NowPlayingIcon,
                  className: 'course-widget__zoomus-icon',
                })
              : course.canStream
              ? SvgIcon({
                  noFill: true,
                  icon: ZoomUsIcon,
                  className: 'course-widget__zoomus-icon tooltip',
                  'data-tooltip': 'Zoom enabled course',
                })
              : null,
            props.course.details.code.toUpperCase(),
          ]
        ),
        h('div.course-widget__updates', [
          isNowPlaying
            ? h('span.tag.green', 'Now Playing')
            : course.status.courseLive && course.activities
            ? getRole() === 'student'
              ? newUpdates
                ? `${newUpdates} new update${newUpdates === 1 ? '' : 's'}`
                : ''
              : pendingQueries > 0
              ? ` ${pendingQueries} pending ${pendingQueries === 1 ? 'query' : 'queries'}`
              : newQueries
              ? ` ${newQueries} new ${newQueries === 1 ? 'query' : 'queries'}`
              : ''
            : !course.status.courseLive
            ? 'Unpublished'
            : '',
        ]),
      ]),
      h('div.course-widget__title', course.details.title),
      h('div.course-widget__admin', [
        h('div.course-widget__avatar', [Avatar(getAdmin().avatar, 'admin')]),
        h('div.course-widget__avatar-details', [
          h('div.course-widget__admin-name', getRole() === 'admin' ? 'You' : getAdmin().name),
          h('div.course-widget__meta', 'Course Admin'),
        ]),
      ]),
      h('div.course-widget__footer', [
        course.dates && course.status.schedulePublished
          ? h('div.course-widget__meta', [
              dt.format(course.dates.startDate, 'MMM DD, YYYY') +
                ' - ' +
                dt.format(course.dates.endDate, 'MMM DD, YYYY'),
            ])
          : null,
        h('div.course-widget__meta', getRole() === 'ta' ? 'TA' : u.capitalize(getRole())),
      ]),
    ]
  );

  function getAdmin() {
    return props.course.team.filter((member) => member.role === 'admin')[0];
  }

  function getPendingQueries() {
    if (!props.course.activities) {
      return 0;
    }
    if (!props.userData) return 0;
    return Math.max(props.course.activities.numQueriesPending, 0);
  }

  function getUnseenQueries() {
    if (!props.course.activities) {
      return 0;
    }
    if (!props.userData) return 0;
    return Math.max(props.course.activities.numQueries - (props.userData.queriesSeen || 0), 0);
  }

  function getStudentUpdates() {
    if (!props.userData) return 0;
    const course = props.course;
    if (course.activities === undefined || course.totalActivities === undefined) {
      return 0;
    }
    const userData = props.userData;
    if (userData.activitiesSeen) {
      const pendingActivities =
        course.totalActivities +
        course.activities.numQueries -
        userData.activitiesSeen -
        (userData.queriesSeen || 0);
      if (pendingActivities < 0) {
        return 0;
      } else {
        return pendingActivities;
      }
    } else {
      return course.totalActivities + course.activities.numQueries;
    }
  }

  function getRole() {
    const member = course.team.find(
      (m) => m.userId === getStore().getState().getIn.session!.userId
    );
    return member ? member.role : 'student';
  }
  return view;
};
export default CourseWidget;
