import {
  addNewClassActivity,
  deleteClassActivity,
  incrementNumSeen,
  pusherPublish as classPusherPublish,
  updateTotalClassActivitiesCount,
} from 'acadly/class/functions';
import { createReducer } from 'acadly/createReducer';
import { IRootState } from 'acadly/IRootState';
import * as utils from 'acadly/utils';

import * as actions from './actions';
import {
  responseDiscussionToDiscussion,
  updateDiscussion,
  updateDiscussionInRootState,
} from './functions';

export const reducer = createReducer({
  '@discussion/CREATE_SUCCESS': createSuccess,
  '@discussion/EDIT_SUCCESS': editSuccess,
  '@discussion/DELETE_SUCCESS': deleteSuccess,
  '@discussion/PUBLISH_SUCCESS': publishSuccess,
  '@discussion/UPDATE_PUBLISHED_SUCCESS': updatePublishedSuccess,
  '@discussion/ANALYTICS_SET_SUCCESS': analyticsSetSuccess,
  '@discussion/all/FETCH_SUCCESS': fetchAllSuccess,
  '@discussion/pusher/PUBLISH': pusherPublish,
  '@discussion/MARK_PARTICIPATION': markParticipation,
  '@discussion/UPDATE_WORD_CLOUD': updateWordCloud,
  '@discussion/WORD_CLOUD_AVAILABLE': wordCloudAvailable,
  '@discussion/FETCH_PUBLISH_PREFS_SUCCESS': fetchPrefsSuccess,
});

function fetchPrefsSuccess(state: IRootState, payload: DiscussionPublishDefaults): IRootState {
  return {
    ...state,
    discussions: {
      ...state.discussions,
      publishDefaults: payload,
    },
  };
}

/**
 * Handle first comment made by student in a
 * compulsory participation discussion.
 * Increment numCommentsTotal for discussion,
 * and set numCommentsSeen to 0.
 * numCommentsSeen is incremented when comment is sent,
 * so it needs to be set to 0 so that comments will be
 * fetched after this.
 */
export function markParticipation(
  state: IRootState,
  { discussionId, timestamp }: actions.IMarkParticipationPayload
) {
  return updateDiscussionInRootState(state, discussionId, (d) => ({
    ...d,
    userData: d.userData
      ? {
          ...d.userData,
          submitted: 1,
        }
      : {
          firstAccessedOn: timestamp,
          numCommentsSeen: 0,
          submitted: 1,
          subscribed: 0,
        },
  }));
}

export function pusherPublish(
  state: IRootState,
  payload: actions.IPusherPublishPayload
): IRootState {
  const details = {
    ...payload.details,
    title: payload.details.title || '',
    attachments: payload.details.attachments || [],
  };
  const discussion: IDiscussion = {
    _id: payload.discussionId,
    nodeType: 'discussion',
    identifiers: {
      classId: payload.classId,
    },
    details: details,
    stats: payload.stats,
    activities: payload.activities,
  };
  return classPusherPublish(state, 'discussions', {
    senderId: payload.sender.userId,
    activity: discussion,
  });
}

export function createSuccess(
  state: IRootState,
  payload: actions.ICreateSuccessPayload
): IRootState {
  const discussion = payload.discussion;

  const updatedState = addNewClassActivity(state, {
    type: 'discussions',
    classId: payload.classId,
    activity: discussion,
  });

  return utils.update(updatedState, {
    discussions: {
      publishDefaults: payload.publishDefaults,
    },
  });
}

export function editSuccess(state: IRootState, payload: actions.IEditSuccessPayload): IRootState {
  const updatedDiscussionSlice = updateDiscussion(state.discussions, payload.discussionId, (d) => ({
    ...d,
    details: {
      ...d.details,
      title: payload.title,
      description: payload.description,
      attachments: payload.attachments,
    },
  }));
  return {
    ...state,
    discussions: updatedDiscussionSlice,
  };
}

export function deleteSuccess(
  state: IRootState,
  payload: actions.IDeleteSuccessPayload
): IRootState {
  return deleteClassActivity(state, 'discussions', {
    activityId: payload.discussionId,
    classId: payload.classId,
    toBeDone: payload.toBeDone,
  });
}
export function updatePublishedSuccess(
  state: IRootState,
  payload: actions.IUpdatePublishedRequest
): IRootState {
  const currentDiscussion = state.discussions.byId[payload.discussionId];
  if (currentDiscussion) {
    const updatedDiscussionSlice = {
      ...currentDiscussion,
      details: {
        ...currentDiscussion.details,
        title: payload.title,
        description: payload.description,
        attachments: payload.attachments,
      },
    };
    return {
      ...state,
      discussions: {
        ...state.discussions,
        byId: {
          ...state.discussions.byId,
          [payload.discussionId]: updatedDiscussionSlice,
        },
      },
    };
  }
  return state;
}

export function publishSuccess(
  state: IRootState,
  payload: actions.IPublishSuccessPayload
): IRootState {
  const courseId = state.courses.currentCourseId;
  const updatedCourseSlice =
    courseId && state.courses.timeline
      ? updateTotalClassActivitiesCount(state.courses, {
          activityType: 'discussions',
          classId: payload.classId,
          toBeDone: payload.toBeDone,
          key: 'numPublished',
          num: 1,
        })
      : state.courses;
  const updatedDiscussionSlice = updateDiscussion(state.discussions, payload.discussionId, (d) => ({
    ...d,
    details: {
      ...d.details,
      published: 1,
      trueNum: payload.trueNum,
      num: payload.discussionNum,
      publishedOn: payload.publishedOn,
      submitFirst: payload.submitFirst,
    },
    userData: {
      firstAccessedOn: d.details.createdOn,
      submitted: 1,
      numCommentsSeen: 0,
      subscribed: payload.subscribeToComments,
    },
  }));
  return {
    ...state,
    courses: updatedCourseSlice,
    discussions: updatedDiscussionSlice,
  };
}

export function analyticsSetSuccess(
  state: IRootState,
  payload: actions.IAnalyticsSetSuccessPayload
): IRootState {
  const updatedDiscussionSlice = updateDiscussion(state.discussions, payload.discussionId, (d) => ({
    ...d,
    userData: {
      firstAccessedOn: payload.firstAccessedOn,
      numCommentsSeen: d.userData ? d.userData.numCommentsSeen : 0,
      submitted: d.userData ? d.userData.submitted : 0,
      subscribed: d.userData ? d.userData.submitted : 0,
    },
  }));
  const updatedCourseSlice = incrementNumSeen(state, 'discussions', {
    classId: payload.classId,
    toBeDone: payload.toBeDone,
  }).courses;
  return {
    ...state,
    courses: updatedCourseSlice,
    discussions: updatedDiscussionSlice,
  };
}

export function fetchAllSuccess(
  state: IRootState,
  payload: actions.IFetchAllSuccessPayload
): IRootState {
  const session = state.getIn.session;
  if (!session) return state;
  const courseId = state.courses.currentCourseId;
  if (!courseId) return state;
  const course = state.courses.courses[courseId];
  if (!course) return state;
  const member = course.team.find((m) => m.userId === session.userId);
  const role = member ? member.role : 'student';
  const discussions = payload.activityData.map((d) =>
    responseDiscussionToDiscussion(
      payload.userData.discussions[d._id],
      d.identifiers.classId,
      role,
      d
    )
  );
  const byId = utils.makeObjectWithKey(discussions, '_id');
  return {
    ...state,
    discussions: {
      ...state.discussions,
      byId: byId,
    },
  };
}

export function updateWordCloud(state: IRootState, payload: IWordCloud): IRootState {
  const discussion = state.discussions.byId[payload.discussionId];

  if (!discussion) return state;

  return {
    ...state,
    discussions: {
      ...state.discussions,
      wordCloud: payload,
      byId: {
        ...state.discussions.byId,
        [discussion._id]: {
          ...discussion,
          details: {
            ...discussion.details,
            wordCloudGenerated: 1,
          },
        },
      },
    },
  };
}

export function wordCloudAvailable(
  state: IRootState,
  payload: IWordCloudAvailableEventData
): IRootState {
  const discussion = state.discussions.byId[payload.activityId];

  if (!discussion) return state;

  return {
    ...state,
    discussions: {
      ...state.discussions,
      byId: {
        ...state.discussions.byId,
        [payload.activityId]: {
          ...discussion,
          details: {
            ...discussion.details,
            wordCloudAvailable: 1,
          },
        },
      },
    },
  };
}
