import { getStore } from 'acadly/store';
import * as u from 'acadly/utils';

import IService from '../IService';

class DiscussionService extends IService {
  public getDiscussionIdFromShortId(shortId: string) {
    const discussions = getStore().getState().discussions.byId;
    const discussion = u.objectValues(discussions).filter((d) => d._id.slice(18) === shortId)[0];
    if (discussion) {
      return discussion._id;
    } else {
      return null;
    }
  }
  public getShortIdFromDiscussionId(discussionId: string) {
    return discussionId.slice(18);
  }
}

export const discussionService = new DiscussionService();
export default discussionService;
