import { IResourceState } from './IResourceState';
export const responseResourceToResource = (
  userData: IResponseResourceUserData | undefined,
  classId: string,
  r: IResponseResource
): IResource => {
  return {
    ...r,
    identifiers: { classId },
    userData: userData
      ? {
          ...userData,
          numCommentsSeen: userData ? userData.numCommentsSeen || 0 : 0,
        }
      : undefined,
  };
};

export function updateResource(
  state: IResourceState,
  resourceId: string,
  f: (r: IResource) => IResource
): IResourceState {
  const resource = state.byId[resourceId];
  if (!resource) return state;
  return {
    ...state,
    byId: {
      ...state.byId,
      [resourceId]: f(resource),
    },
  };
}
