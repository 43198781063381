import { updateAssignment } from 'acadly/assignment/functions';
import {
  setActivityCommentsSeen,
  updateClass,
  updateClassInRootState,
} from 'acadly/class/functions';
import { ICommentsContext } from 'acadly/comments/api';
import { createReducer } from 'acadly/createReducer';
import { updateDiscussion } from 'acadly/discussion/functions';
import { IRootState } from 'acadly/IRootState';
import { updatePoll } from 'acadly/poll/functions';
import { updateQueryInRootState } from 'acadly/query/functions';
import { updateQuery } from 'acadly/query/functions';
import { updateQuiz } from 'acadly/quiz/functions';
import { updateResource } from 'acadly/resource/functions';
import * as u from 'acadly/utils';

import { IFetchSuccessPayload } from './actions';
import * as actions from './actions';

export const reducer = createReducer({
  '@comments/FETCH_SUCCESS': fetchSuccess,
  '@comments/FETCH_OLD_SUCCESS': fetchOldSuccess,
  '@comments/CREATE_SUCCESS': createSuccess,
  '@comments/SET_CONTEXT': setContext,
  '@comments/pusher/commentAdded': pusherCommentAdded,
  '@comments/MARK_NUM_SEEN': markAllCommentsSeen,
  '@comments/REMOVE_SUCCESS': removeSuccess,
  '@comments/pusher/REMOVED': pusherRemoved,
  '@comments/MARK_SUCCESS': markSuccess,
  '@comments/pusher/MARK': pusherMark,
  '@comments/CLEAR': clear,
  '@comments/FETCH_NEW_SUCCESS': fetchNewSuccess,
  '@comments/INCREMENT_NUM_PUSHER': incrementNumPusher,
  '@comments/RESET_NUM_PUSHER': resetNumPusher,
  '@comments/SAVE_REQUEST_PUSHER': savePusherRequest,
  '@comments/AWARD_RATING': awardRating,
  '@comments/RETRACT_RATING': retractRating,
  '@comments/SUBSCRIBE_SUCCESS': subscribeSuccess,
});

export function pusherMark(state: IRootState, payload: actions.IPusherMarkPayload): IRootState {
  const comments = state.comments.comments;
  let newState: IRootState;
  if (!comments) return state;
  if (payload.marked === 'awarded') {
    if (state.getIn.session!.userId === payload.sender.userId) {
      return state;
    }
    if (payload.action === 'awarding') {
      const awardPayload: any = {
        request: {
          commentId: payload.commentId,
          action: payload.action as any,
          points: payload.points,
        },
        instructor: payload.sender,
      };
      newState = awardRating(state, awardPayload);
    } else {
      const retractPayload: any = {
        request: {
          commentId: payload.commentId,
          action: payload.action as any,
          points: payload.points,
        },
        instructor: payload.sender,
      };
      newState = retractRating(state, retractPayload);
    }
    return newState as any;
  }
  return {
    ...state,
    comments: {
      ...state.comments,
      comments: u.replaceWhere(
        comments,
        (c) => ({
          ...c,
          stats: {
            ...c.stats,
            numHelps: payload.marked === 'helpful' ? c.stats.numHelps + 1 : c.stats.numHelps,
            numStars: payload.marked === 'starred' ? c.stats.numStars + 1 : c.stats.numStars,
            approved: payload.instructorApproved ? c.stats.approved + 1 : c.stats.approved,
          },
          userData:
            payload.sender.userId === state.getIn.session!.userId
              ? {
                  ...c.userData,
                  starred: payload.marked === 'starred' ? 1 : c.userData.starred,
                  helpful: payload.marked === 'helpful' ? 1 : c.userData.helpful,
                }
              : c.userData,
        }),
        (c) =>
          c._id === payload.commentId &&
          ((payload.marked === 'helpful' && !c.userData.helpful) ||
            (payload.marked === 'starred' && !c.userData.starred))
      ),
    },
  };
}

export function subscribeSuccess(
  state: IRootState,
  payload: actions.ISubscribeSuccessPayload
): IRootState {
  const context = payload.context;
  if (context === 'quizzes') {
    const updatedQuizSlice = updateQuiz(state.quizzes, payload.contextId, (q) => ({
      ...q,
      userData: q.userData
        ? {
            ...q.userData,
            subscribed: payload.subscribeToComments,
          }
        : {
            firstAccessedOn: payload.timestamp,
            status: 'inProgress' as const,
            timesStarted: 1,
            numCommentsSeen: 0,
            score: undefined, // check
            subscribed: payload.subscribeToComments,
          },
    }));
    return {
      ...state,
      quizzes: updatedQuizSlice,
    };
  } else if (context === 'polls') {
    const updatedPollSlice = updatePoll(state.polls, payload.contextId, (p) => ({
      ...p,
      userData: p.userData
        ? {
            ...p.userData,
            subscribed: payload.subscribeToComments,
          }
        : {
            numCommentsSeen: 0,
            timesStarted: 1,
            firstAccessedOn: 0,
            status: 'inProgress' as const,
            subscribed: payload.subscribeToComments,
          },
    }));
    return {
      ...state,
      polls: updatedPollSlice,
    };
  } else if (context === 'discussions') {
    const updatedDiscussionSlice = updateDiscussion(state.discussions, payload.contextId, (d) => ({
      ...d,
      userData: d.userData
        ? {
            ...d.userData,
            subscribed: payload.subscribeToComments,
          }
        : {
            firstAccessedOn: 0,
            numCommentsSeen: 0,
            submitted: 1,
            subscribed: payload.subscribeToComments,
          },
    }));
    return {
      ...state,
      discussions: updatedDiscussionSlice,
    };
  } else if (context === 'resources') {
    const updatedResourceSlice = updateResource(state.resources, payload.contextId, (r) => ({
      ...r,
      userData: r.userData
        ? {
            ...r.userData,
            subscribed: payload.subscribeToComments,
          }
        : {
            numCommentsSeen: 0,
            firstAccessedOn: 0,
            subscribed: payload.subscribeToComments,
          },
    }));
    return {
      ...state,
      resources: updatedResourceSlice,
    };
  } else if (context === 'queries') {
    const updatedQuerySlice = updateQuery(state.queries, payload.contextId, (q) => ({
      ...q,
      userData: q.userData
        ? {
            ...q.userData,
            subscribed: payload.subscribeToComments,
          }
        : {
            numCommentsSeen: 0,
            hasUpvoted: 0 as const,
            isAsker: 0 as const,
            subscribed: payload.subscribeToComments,
          },
    }));
    return {
      ...state,
      queries: updatedQuerySlice,
    };
  } else if (context === 'assignments') {
    return updateAssignment(state, payload.contextId, (a) => ({
      ...a,
      userData: {
        ...a.userData,
        subscribed: payload.subscribeToComments,
      },
    }));
  } else if (context === 'course') {
    const timeline = state.courses.timeline;
    if (!timeline) return state;
    return {
      ...state,
      courses: {
        ...state.courses,
        timeline: {
          ...timeline,
          userData: {
            ...timeline.userData,
            subscribed: payload.subscribeToComments,
          },
        },
      },
    };
  } else if (context === 'classes') {
    return updateClassInRootState(state, payload.contextId, (a) => ({
      ...a,
      userData: {
        ...a.userData,
        subscribed: payload.subscribeToComments,
      },
    }));
  } else return state;
}

export function awardRating(state: IRootState, payload: actions.IAwardRatingPayload): IRootState {
  const comments = state.comments.comments;
  if (!comments) return state;
  const addedRating: ICommentAwards = {
    points: payload.request.points as number,
    awardedBy: {
      ...payload.instructor,
    },
  };

  return {
    ...state,
    comments: {
      ...state.comments,
      comments: u.replaceWhere(
        comments,
        (c) => ({
          ...c,
          stats: {
            ...c.stats,
            awards: [...(c.stats.awards ? c.stats.awards : []), addedRating],
          },
        }),
        (c) => c._id === payload.request.commentId && !c.removed
      ),
    },
  };
}

export function retractRating(
  state: IRootState,
  payload: actions.IRetractRatingPayload
): IRootState {
  const commentId = payload.request.commentId;
  const comments = state.comments.comments;
  if (!comments) return state;
  const currentComment = comments.find((c) => c._id === commentId);
  if (!currentComment) return state;
  const awardsAfterRetraction = currentComment.stats.awards.filter(
    (a) => a.awardedBy.userId !== payload.instructor.userId
  );

  return {
    ...state,
    comments: {
      ...state.comments,
      comments: u.replaceWhere(
        comments,
        (c) => ({
          ...c,
          stats: {
            ...c.stats,
            awards: awardsAfterRetraction,
          },
        }),
        (c) => c._id === payload.request.commentId && !c.removed
      ),
    },
  };
}

export function markSuccess(state: IRootState, payload: actions.IMarkSuccessPayload): IRootState {
  const currentCourseId = state.courses.currentCourseId;
  if (!currentCourseId) return state;
  const userData = state.courses.userData[currentCourseId];
  if (!userData) return state;
  const role = userData.role;
  const comments = state.comments.comments;
  if (!comments) return state;
  const starred: 0 | 1 | null =
    payload.marked === 'starred' ? 1 : payload.marked === 'unstarred' ? 0 : null;
  const thanked: 0 | 1 | null =
    payload.marked === 'helpful' ? 1 : payload.marked === 'unhelpful' ? 0 : null;
  let incrementNumHelps = 0;
  let incrementNumStars = 0;
  if (payload.marked === 'helpful') {
    incrementNumHelps = 1;
  } else if (payload.marked === 'unhelpful') {
    incrementNumHelps = -1;
  }
  if (payload.marked === 'starred') {
    incrementNumStars = 1;
  } else if (payload.marked === 'unstarred') {
    incrementNumStars = -1;
  }

  let incrementApprovers = 0;
  if (role === 'admin' || role === 'instructor') {
    if (payload.marked === 'starred') {
      incrementApprovers = 1;
    } else if (payload.marked === 'unstarred') {
      incrementApprovers = -1;
    } else if (payload.marked === 'helpful') {
      incrementApprovers = 1;
    } else if (payload.marked === 'unhelpful') {
      incrementApprovers = -1;
    }
  }
  return {
    ...state,
    comments: {
      ...state.comments,
      comments: u.replaceWhere(
        comments,
        (c) => ({
          ...c,
          stats: {
            ...c.stats,
            numHelps: c.stats.numHelps + incrementNumHelps,
            numStars: c.stats.numStars + incrementNumStars,
            approved: c.stats.approved + incrementApprovers,
          },
          userData: {
            ...c.userData,
            starred: starred !== null ? starred : c.userData.starred,
            helpful: thanked !== null ? thanked : c.userData.helpful,
          },
        }),
        (c) =>
          c._id === payload.commentId &&
          !c.removed &&
          !(
            (payload.marked === 'helpful' && !!c.userData.helpful) ||
            (payload.marked === 'unhelpful' && !c.userData.helpful) ||
            (payload.marked === 'starred' && !!c.userData.starred) ||
            (payload.marked === 'unstarred' && !c.userData.starred)
          )
      ),
    },
  };
}

export function pusherRemoved(
  state: IRootState,
  payload: actions.IPusherRemovePayload
): IRootState {
  return removeSuccess(state, {
    commentId: payload.commentId,
  });
}

export function removeSuccess(
  state: IRootState,
  payload: actions.IRemoveSuccessPayload
): IRootState {
  const comments = state.comments.comments;
  if (!comments) return state;
  return {
    ...state,
    comments: {
      ...state.comments,
      comments: u.replaceWhere(
        comments,
        (c) => ({
          ...c,
          details: {
            ...c.details,
            message: 'This comment was removed',
          },
          removed: 1 as const,
        }),
        (c) => c._id === payload.commentId
      ),
    },
  };
}

export function setContext(state: IRootState, payload: actions.ISetContextPayload) {
  return {
    ...state,
    comments: {
      ...state.comments,
      loadedContext: payload,
    },
  };
}
const initialUserData = {
  starred: 0 as const,
  thanked: 0 as const,
  userSubscribed: 0,
};
export function fetchSuccess(state: IRootState, payload: IFetchSuccessPayload): IRootState {
  const newState = {
    ...state,
    app: {
      ...state.app,
      isCurrentActivitySubscribed: payload.userSubscribed === 1 ? true : false,
    },
    comments: {
      ...state.comments,
      forceRefresh: false,
      comments: payload.commentsData
        .map((comment) => ({
          ...comment,
          removed: comment.removed,
          userData: payload.userData
            ? payload.userData[comment._id] || initialUserData
            : initialUserData,
        }))
        .sort((a, b) => a.details.createdOn - b.details.createdOn),
    },
  };
  const stateWithFlagSet = flagComments(newState);
  return stateWithFlagSet;
}

function flagComments(state: IRootState): IRootState {
  if (!state.comments.comments) {
    return state;
  }
  return {
    ...state,
    comments: {
      ...state.comments,
      comments: state.comments.comments,
    },
  };
}

export function fetchOldSuccess(state: IRootState, payload: IFetchSuccessPayload): IRootState {
  let exitingCommentsIds: string[] = [];
  if (state.comments.comments) {
    exitingCommentsIds = state.comments.comments.map((c) => c._id);
    payload.commentsData = payload.commentsData.filter(
      (c) => exitingCommentsIds.indexOf(c._id) === -1
    );
  }
  const oldComments = payload.commentsData
    .map((comment) => ({
      ...comment,
      userData: payload.userData
        ? payload.userData[comment._id] || initialUserData
        : initialUserData,
    }))
    .sort((a, b) => a.details.createdOn - b.details.createdOn);
  const newState = {
    ...state,
    comments: {
      ...state.comments,
      forceRefresh: false,
      comments: state.comments.comments
        ? [...oldComments, ...state.comments.comments]
        : oldComments,
    },
  };
  const stateWithFlagSet = flagComments(newState);
  return stateWithFlagSet;
}

export function createSuccess(
  state: IRootState,
  payload: actions.ICreateSuccessPayload
): IRootState {
  const comments = state.comments.comments;
  if (!comments) return state;

  // pusher message may be received before server response so
  // check if the comment already exists
  if (u.findReverse(comments, (c) => c._id === payload.response._id)) return state;
  const newState = incrementTotalComments(
    {
      ...state,
      comments: {
        ...state.comments,
        comments: [
          ...comments,
          {
            ...payload.response,
            userData: initialUserData,
            removed: payload.response.removed || 0,
          },
        ],
      },
    },
    {
      context: payload.request.context,
      contextId: payload.request.contextId,
      subContext: payload.request.subContext,
      role: payload.response.details.createdBy.role,
      classId: payload.classId,
      bySameUser: true,
    }
  );
  const stateWithFlagSet = flagComments(newState);
  return stateWithFlagSet;
}

export function incrementNumPusher(state: IRootState, payload: number): IRootState {
  const newState = {
    ...state,
    comments: {
      ...state.comments,
      toAdd: state.comments.toAdd ? state.comments.toAdd + payload : payload,
    },
  };
  return newState;
}
export function resetNumPusher(state: IRootState): IRootState {
  const newState = {
    ...state,
    comments: {
      ...state.comments,
      toAdd: 0,
    },
  };
  return newState;
}

export function savePusherRequest(
  state: IRootState,
  payload: actions.ISavePusherRequestPayload
): IRootState {
  const newState = {
    ...state,
    comments: {
      ...state.comments,
      pusherRequestDetails: {
        context: payload.context,
        subContext: payload.subContext,
        contextId: payload.contextId,
        courseId: payload.courseId,
      },
    },
  };
  return newState;
}

export function pusherCommentAdded(
  state: IRootState,
  payload: actions.IPusherCommentAddedPayload
): IRootState {
  if (!state.getIn.session) return state;
  const loadedContext = state.comments.loadedContext;
  const comments = state.comments.comments;
  if (comments && u.findReverse(comments, (c) => c._id === payload.commentId)) return state;
  let newState = state;
  if (
    loadedContext &&
    comments &&
    loadedContext.context === payload.context &&
    loadedContext.contextId === payload.contextId &&
    ((payload.context === 'assignments' && payload.subContext === loadedContext.subContext) ||
      payload.context !== 'assignments')
  ) {
    const details = payload.details;
    newState = {
      ...state,
      comments: {
        ...state.comments,
        comments: [
          ...comments,
          {
            _id: payload.commentId,
            details: details,
            stats: payload.stats,
            removed: 0,
            userData: {
              helpful: 0,
              starred: 0,
            },
          },
        ],
      },
    };
  }
  const newState2 = incrementTotalComments(newState, {
    context: payload.context,
    subContext: payload.subContext,
    contextId: payload.contextId,
    classId: payload.classId,
    role: payload.details.createdBy.role,
    bySameUser: payload.sender.userId === state.getIn.session.userId,
  });
  const stateWithFlagSet = flagComments(newState2);
  return stateWithFlagSet;
}

/**
 * Increments the total comments counts on receiving new comment
 * pusher. Essentially increments numSeen and numTotal counts where appropriate
 * and in case the comments panel is already open for the context and
 * sub context, increments numSeen counts as well.
 * TODO: The nested updates here look totally ridiculous.
 * Need to change the state schema so that the amount of nesting
 * is reduced.
 */
export function incrementTotalComments(
  state: IRootState,
  payload: {
    context: ICommentsContext;
    subContext: 'preSub' | 'postSub' | 'preClass' | 'inClass' | 'review';
    contextId: string;
    role: ICourseRole;
    classId: string;
    bySameUser: boolean;
  }
): IRootState {
  let newState = state;
  const { context, subContext, contextId } = payload;
  if (context === 'course') {
    const course = state.courses.courses[contextId];
    if (course) {
      newState = {
        ...state,
        courses: {
          ...state.courses,
          courses: {
            ...state.courses.courses,
            [contextId]: {
              ...course,
              activities: {
                ...course.activities,
                numCommentsTotal: course.activities.numCommentsTotal + 1,
              },
            },
          },
        },
      };
    }

    // increment seen comments
    if (
      payload.bySameUser ||
      (state.comments.loadedContext &&
        state.comments.loadedContext.context === 'course' &&
        state.comments.loadedContext.contextId === contextId)
    ) {
      const userData = newState.courses.userData[contextId];
      const timeline = newState.courses.timeline;
      // increment seen in timeline user data
      newState = {
        ...newState,
        courses: {
          ...newState.courses,
          timeline: timeline
            ? {
                ...timeline,
                userData: {
                  ...timeline.userData,
                  numCommentsSeen: timeline.userData.numCommentsSeen + 1,
                },
              }
            : undefined,
        },
      };

      // increment seen in course userData
      if (userData) {
        newState = {
          ...newState,
          courses: {
            ...newState.courses,
            userData: {
              ...newState.courses.userData,
              [contextId]: {
                ...userData,
                numCommentsSeen: userData.numCommentsSeen + 1,
              },
            },
          },
        };
      }
    }
  } else if (context === 'classes') {
    if (state.courses.timeline) {
      newState = updateClassInRootState(state, contextId, (c: IClass) => ({
        ...c,
        activities: {
          ...c.activities,
          numCommentsTotal: c.activities.numCommentsTotal + 1,
        },
      }));

      if (
        payload.bySameUser ||
        (state.comments.loadedContext &&
          state.comments.loadedContext.context === 'classes' &&
          state.comments.loadedContext.contextId === contextId)
      ) {
        newState = updateClassInRootState(newState, contextId, (c) => ({
          ...c,
          userData: {
            ...c.userData,
            numCommentsSeen: c.userData.numCommentsSeen + 1,
          },
        }));
      }
    }
  } else if (context === 'assignments') {
    const key = subContext === 'preSub' ? 'numCommentsPreSub' : 'numCommentsPostSub';
    if (state.courses.timeline) {
      newState = updateAssignment(state, contextId, (a: IAssignment) => ({
        ...a,
        activities: {
          ...a.activities,
          numCommentsTotal: a.activities.numCommentsTotal + 1,
          [key]: a.activities[key] + 1,
        },
      }));
      if (
        payload.bySameUser ||
        (state.comments.loadedContext &&
          state.comments.loadedContext.context === context &&
          state.comments.loadedContext.subContext === subContext &&
          state.comments.loadedContext.contextId === contextId)
      ) {
        const key = subContext === 'preSub' ? 'numCommentsSeenPreSub' : 'numCommentsSeenPostSub';
        newState = updateAssignment(newState, contextId, (a) => ({
          ...a,
          userData: {
            ...a.userData,
            numCommentsSeen: a.userData.numCommentsSeen + 1,
            [key]: a.userData[key] + 1,
          },
        }));
      }
    }
  } else if (context === 'queries') {
    const query = state.queries.byId[contextId];
    if (query) {
      newState = updateQueryInRootState(state, contextId, (query) => ({
        ...query,
        activities: {
          ...query.activities,
          numCommentsTotal: query.activities.numCommentsTotal + 1,
          numCommentsCourseTeam:
            payload.role === 'student'
              ? query.activities.numCommentsCourseTeam
              : query.activities.numCommentsCourseTeam + 1,
        },
      }));
    }
    const toBeDone = subContext as 'review' | 'preClass' | 'inClass';
    if (toBeDone === 'review') {
      newState = updateClassInRootState(newState, payload.classId, (cls) => ({
        ...cls,
        activities: {
          ...cls.activities,
          reviewQueries: {
            ...cls.activities.reviewQueries,
            numCommentsTotal: cls.activities.reviewQueries.numCommentsTotal + 1,
          },
        },
      }));
    } else {
      newState = updateClassInRootState(newState, payload.classId, (cls) => ({
        ...cls,
        activities: {
          ...cls.activities,
          [toBeDone]: {
            ...cls.activities[toBeDone],
            queries: {
              ...cls.activities[toBeDone].queries,
              numCommentsTotal: cls.activities[toBeDone].queries.numCommentsTotal + 1,
            },
          },
        },
      }));
    }

    if (
      payload.bySameUser ||
      (state.comments.loadedContext &&
        state.comments.loadedContext.context === context &&
        state.comments.loadedContext.contextId === contextId)
    ) {
      newState = updateQueryInRootState(newState, contextId, (q) => ({
        ...q,
        userData: q.userData
          ? {
              ...q.userData,
              numCommentsSeen: q.userData.numCommentsSeen + 1,
            }
          : {
              numCommentsSeen: q.activities.numCommentsTotal,
              hasUpvoted: 0,
              isAsker: 0,
              subscribed: 0,
            },
      }));
      newState = updateClassInRootState(newState, payload.classId, (c) => {
        if (toBeDone === 'review') {
          return {
            ...c,
            userData: {
              ...c.userData,
              reviewQueries: {
                ...c.userData.reviewQueries,
                numCommentsSeen: c.userData.reviewQueries.numCommentsSeen + 1,
              },
            },
          };
        } else {
          return {
            ...c,
            userData: {
              ...c.userData,
              [toBeDone]: {
                ...c.userData[toBeDone],
                queries: {
                  ...c.userData[toBeDone].queries,
                  numCommentsSeen: c.userData[toBeDone].queries.numCommentsSeen + 1,
                },
              },
            },
          };
        }
      });
    }
  } else {
    const activity = state[context].byId[contextId];
    if (activity) {
      newState = {
        ...state,
        [context]: {
          ...state[context],
          byId: {
            ...state[context].byId,
            [contextId]: {
              ...activity,
              activities: {
                ...activity.activities,
                numCommentsTotal: activity.activities.numCommentsTotal + 1,
              },
            },
          },
        },
      };
    }

    const toBeDone = payload.subContext as 'preClass' | 'inClass';
    newState = updateClassInRootState(newState, payload.classId, (c) => ({
      ...c,
      activities: {
        ...c.activities,
        [toBeDone]: {
          ...c.activities[toBeDone],
          [context]: {
            ...c.activities[toBeDone][context],
            numCommentsTotal: c.activities[toBeDone][context].numCommentsTotal + 1,
          },
        },
      },
    }));
    if (
      payload.bySameUser ||
      (state.comments.loadedContext &&
        state.comments.loadedContext.context === context &&
        state.comments.loadedContext.contextId === contextId)
    ) {
      const newActivity = newState[context].byId[contextId];
      if (newActivity) {
        newState = {
          ...newState,
          [context]: {
            ...newState[context],
            byId: {
              ...newState[context].byId,
              [contextId]: {
                ...newActivity,
                userData: {
                  ...newActivity.userData,
                  numCommentsSeen: newActivity.userData
                    ? newActivity.userData.numCommentsSeen + 1
                    : 1,
                },
              },
            },
          },
        };
      }

      newState = updateClassInRootState(newState, payload.classId, (c) => ({
        ...c,
        userData: {
          ...c.userData,
          [toBeDone]: {
            ...c.userData[toBeDone],
            [context]: {
              ...c.userData[toBeDone][context],
              numCommentsSeen: c.activities[toBeDone][context].numCommentsTotal,
            },
          },
        },
      }));
    }
  }
  return newState;
}

export function markAllCommentsSeen(
  state: IRootState,
  payload: actions.IIncrementNumSeenPayload
): IRootState {
  if (payload.context === 'classes') {
    return updateClassInRootState(state, payload.contextId, (c) => ({
      ...c,
      activities: {
        ...c.activities,
      },
      userData: {
        ...c.userData,
        numCommentsSeen: payload.setTo,
      },
    }));
  } else if (payload.context === 'course') {
    const currentCourseId = state.courses.currentCourseId;
    if (!currentCourseId) return state;
    const course = state.courses.courses[currentCourseId];
    if (!course) return state;
    const timeline = state.courses.timeline;
    if (!timeline) return state;
    const userData = timeline.userData;
    const courseUserData = state.courses.userData[currentCourseId];
    return {
      ...state,
      courses: {
        ...state.courses,
        timeline: {
          ...timeline,
          userData: {
            ...userData,
            numCommentsSeen: payload.setTo,
          },
        },
        userData: {
          ...state.courses.userData,
          [currentCourseId]: courseUserData
            ? {
                ...courseUserData,
                numCommentsSeen: payload.setTo,
              }
            : courseUserData,
        },
      },
    };
  } else if (payload.context === 'assignments') {
    return updateAssignment(state, payload.contextId, (a) => {
      if (payload.subContext === 'preSub') {
        const numNewCommentsSeen = payload.setTo;
        return {
          ...a,
          userData: {
            ...a.userData,
            numCommentsSeenPreSub: numNewCommentsSeen,
            numCommentsSeen:
              a.userData.numCommentsSeen + (numNewCommentsSeen - a.userData.numCommentsSeenPreSub),
          },
        };
      } else {
        const numNewCommentsSeen = payload.setTo;
        return {
          ...a,
          userData: {
            ...a.userData,
            numCommentsSeenPostSub: numNewCommentsSeen,
            numCommentsSeen:
              a.userData.numCommentsSeen + (numNewCommentsSeen - a.userData.numCommentsSeenPostSub),
          },
        };
      }
    });
  } else if (payload.context === 'queries') {
    const query = state.queries.byId[payload.contextId];

    const withNewQuery: IRootState =
      query && query.userData
        ? {
            ...state,
            queries: {
              ...state.queries,
              byId: {
                ...state.queries.byId,
                [payload.contextId]: {
                  ...query,
                  userData: {
                    ...query.userData,
                    numCommentsSeen: payload.setTo,
                  },
                },
              },
            },
          }
        : state;
    let existingCommentsSeen = 0;
    const oldQuery = state.queries.byId[payload.contextId];
    if (oldQuery) {
      oldQuery.userData
        ? (existingCommentsSeen = oldQuery.userData.numCommentsSeen)
        : (existingCommentsSeen = 0);
    }
    return {
      ...withNewQuery,
      courses: updateClass(withNewQuery.courses, payload.classId, (c) => {
        const toBeDone = payload.toBeDone;
        console.log(`toBeDone ${toBeDone}`);
        if (toBeDone === 'review') {
          return {
            ...c,
            userData: {
              ...c.userData,
              reviewQueries: {
                ...c.userData.reviewQueries,
                numCommentsSeen: payload.setTo,
              },
            },
          };
        } else {
          const commentsSeenAfterIncrement =
            c.userData[toBeDone].queries.numCommentsSeen + (payload.setTo - existingCommentsSeen);
          return {
            ...c,
            userData: {
              ...c.userData,
              [toBeDone]: {
                ...c.userData[toBeDone],
                queries: {
                  ...c.userData[toBeDone].queries,
                  numCommentsSeen: commentsSeenAfterIncrement,
                },
              },
            },
          };
        }
      }),
    };
  } else if (
    ['quizzes', 'polls', 'discussions', 'resources', 'queries'].includes(payload.context)
  ) {
    return setActivityCommentsSeen(
      state,
      payload.context,
      payload.contextId,
      payload.classId,
      payload.toBeDone as 'inClass' | 'preClass',
      payload.setTo
    );
  }

  return state;
}

export function clear(state: IRootState): IRootState {
  return {
    ...state,
    comments: {
      ...state.comments,
      comments: undefined,
    },
  };
}

export function fetchNewSuccess(state: IRootState, payload: IFetchSuccessPayload) {
  return {
    ...state,
    comments: {
      ...state.comments,
      forceRefresh: false,
      comments: [
        ...(state.comments.comments || []),
        ...payload.commentsData
          .map((comment) => ({
            ...comment,
            removed: comment.removed,
            userData: payload.userData
              ? payload.userData[comment._id] || initialUserData
              : initialUserData,
          }))
          .sort((a, b) => a.details.createdOn - b.details.createdOn),
      ],
    },
  };
}
