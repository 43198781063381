import { h, IComponent } from 'core';

import Alert from 'acadly/common/Alert';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import TextField from 'acadly/common/TextField';
import Toggle from 'acadly/common/Toggle';
import { colors, mr, pad, style } from 'acadly/styles';

import { getStore } from '../store';

export interface IEditBookFields {
  title: string;
  author: string;
  isbn: string;
  recommended: 0 | 1;
}

interface IEditBookProps {
  open: boolean;
  onsave: (book: IEditBookFields) => any;
  oncancel: () => any;
  book?: IEditBookFields;
  ondelete?: () => any;
}

interface IEditBookState {
  title: string;
  author: string;
  isbn: string;
  recommended: 0 | 1;
  titleError?: boolean;
  authorError?: boolean;
  isbnError?: boolean;
  isSaving: boolean;
  isDeleteBookDialogVisibleFor: ITopicBook | null;
  editTopicBooksField: ITopicBook[];
}
export default (props: IEditBookProps) => h(EditBook, props);
class EditBook extends IComponent<IEditBookProps, IEditBookState> {
  private static initialState: IEditBookState = {
    title: '',
    author: '',
    isbn: '',
    recommended: 0,
    titleError: false,
    authorError: false,
    isbnError: false,
    isSaving: false,
    isDeleteBookDialogVisibleFor: null,
    editTopicBooksField: [],
  };

  public componentWillMount() {
    this.initialize(this.getProps());
  }

  private initialize(props: IEditBookProps) {
    let initialState = EditBook.initialState;
    if (props.book) {
      initialState = {
        ...initialState,
        title: props.book.title,
        author: props.book.author,
        isbn: props.book.isbn,
        recommended: props.book.recommended,
      };
    }
    this.setState(initialState);
  }

  public componentWillReceiveProps(newProps: IEditBookProps) {
    this.initialize(newProps);
  }

  public render() {
    const isMobile = getStore().getState().app.isMobile;
    const inputContainerStyle = {
      display: 'flex',
      marginBottom: '2em',
    };
    const labelStyle = {
      border: 'none',
      borderBottom: '1px solid transparent',
      paddingTop: '0.25em',
      paddingBottom: '0.25em',
      color: colors.darkBlue,
      marginTop: 'auto',
      flex: 1,
    };

    const inputStyle = {
      marginLeft: 'auto',
      flex: 3,
    };
    const REQUIRED_INPUT_ERROR = 'Please fill in this field';
    const ISBN_ERROR = 'Please enter a valid ISBN';
    const props = this.getProps();

    return Dialog(
      isMobile
        ? {
            title: this.getProps().book ? 'Editing Book' : 'Adding A New Book',
            open: this.getProps().open,
            primaryAction: {
              type: 'view',
              view: h('div', style(['flex', 'row', 'alignCenter'], mr('0.5em')), [
                this.getProps().book
                  ? h(
                      'i.fa.fa-trash',
                      {
                        style: {
                          fontSize: '1em',
                          paddingRight: '1rem',
                        },
                        onclick: props.ondelete,
                      },
                      []
                    )
                  : null,
                h(
                  'i.fa.fa-check',
                  {
                    style: {
                      fontSize: '1em',
                    },
                    onclick: () => this.handleSave(),
                  },
                  []
                ),
              ]),
            },
            style: {
              backgroundColor: colors.backgroundColor,
            },
            secondaryAction: {
              label: 'Cancel',
              mobileLabel: h('i.fa.fa-times', []),
              onclick: () => this.getProps().oncancel(),
            },
          }
        : {
            title: this.getProps().book ? 'Editing Book' : 'Adding A New Book',
            open: this.getProps().open,
            primaryAction: {
              type: 'view',
              view: h(
                'div',
                style(['flex', 'row', 'alignCenter', 'spaceAround', 'fullWidth', mr('0.5em')]),
                [
                  FlatButton('CANCEL', {
                    tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                    type: 'secondary',
                    onclick: () => this.getProps().oncancel(),
                  }),
                  this.getProps().book
                    ? FlatButton('DELETE', {
                        tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                        type: 'secondary',
                        onclick: props.ondelete,
                        style: {
                          color: colors.red,
                        },
                      })
                    : null,
                  FlatButton('SAVE', {
                    tabIndex: getStore().getState().app.acc.web.turnOff === 0 ? 0 : undefined,
                    type: 'primary',
                    onclick: () => this.handleSave(),
                  }),
                ]
              ),
            },
            style: {
              backgroundColor: colors.backgroundColor,
            },
          },
      [
        h('div.edit-section', style(['whiteBackground', pad('0.5rem 1rem')]), [
          h(
            'div',
            {
              style: {
                ...inputContainerStyle,
                marginTop: '1em',
              },
            },
            [
              TextField({
                value: this.getState().title,
                placeholder: 'Title',
                floatingLabelText: 'Title',
                oninput: (event) => this.handleTitleChange(event.target.value),
                errorText: this.getState().titleError ? REQUIRED_INPUT_ERROR : undefined,
                style: inputStyle,
                onenter: () => this.handleSave(),
              }),
            ]
          ),

          h('div', { style: inputContainerStyle }, [
            TextField({
              floatingLabelText: 'Author',
              placeholder: 'Author',
              value: this.getState().author,
              oninput: (event) => this.handleAuthorChange(event.target.value),
              errorText: this.getState().authorError ? REQUIRED_INPUT_ERROR : undefined,
              style: inputStyle,
              onenter: () => this.handleSave(),
            }),
          ]),

          h('div', { style: inputContainerStyle }, [
            TextField({
              placeholder: 'ISBN',
              floatingLabelText: 'ISBN',
              value: this.getState().isbn,
              oninput: (event) => this.handleISBNChange(event.target.value),
              errorText: this.getState().isbnError ? ISBN_ERROR : undefined,
              style: inputStyle,
              onenter: () => this.handleSave(),
            }),
          ]),

          h('div', { style: inputContainerStyle }, [
            h('div', { style: labelStyle }, ['Required']),
            Toggle({
              selected: this.getState().recommended ? true : false,
              ontoggle: (checked) => this.handleRequiredToggle(checked),
            }),
          ]),
        ]),
        this.deleteBookConfirmationDialog(),
      ]
    );
  }

  private async handleSave() {
    const errors = this.tryValidate();
    if (errors.authorError || errors.isbnError || errors.titleError) {
      await this.setState(errors);
    } else {
      this.setState({
        isSaving: true,
      });
      await this.getProps().onsave({
        title: this.getState().title,
        author: this.getState().author,
        isbn: this.getState().isbn,
        recommended: this.getState().recommended,
      });
    }
  }

  private async handleDeleteBook() {
    const book = this.getState().isDeleteBookDialogVisibleFor;
    if (!book) return;
    await this.setState({
      isDeleteBookDialogVisibleFor: null,
      editTopicBooksField: this.getState().editTopicBooksField.filter(
        (fieldBook) => fieldBook._id !== book._id
      ),
    });
  }

  private deleteBookConfirmationDialog() {
    const { isDeleteBookDialogVisibleFor } = this.getState();
    return Alert(
      {
        open: isDeleteBookDialogVisibleFor !== null,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        style: {
          width: '25em',
        },
        actions: [
          FlatButton('NO', {
            type: 'secondary',
            onclick: () =>
              this.setState({
                isDeleteBookDialogVisibleFor: null,
              }),
          }),
          FlatButton('YES', {
            onclick: () => this.handleDeleteBook(),
          }),
        ],
      },
      [
        'Are you sure you want to delete the book ',
        h(
          'span',
          style(['bold']),
          isDeleteBookDialogVisibleFor ? `"${isDeleteBookDialogVisibleFor.details.title}"?` : '?'
        ),
      ]
    );
  }

  private handleRequiredToggle(required: boolean) {
    this.setState({
      recommended: required ? 1 : 0,
    });
  }

  private handleTitleChange(input: string) {
    this.setState({
      title: input,
      titleError: false,
    });
  }

  private handleAuthorChange(input: string) {
    this.setState({
      author: input,
      authorError: false,
    });
  }

  private handleISBNChange(input: string) {
    this.setState({
      isbn: input.trim(),
      isbnError: false,
    });
  }

  private tryValidate() {
    const errors = {
      titleError: false,
      authorError: false,
      isbnError: false,
    };
    if (!this.getState().title.length) {
      errors.titleError = true;
    }
    if (!this.getState().author.length) {
      errors.authorError = true;
    }

    const isbn = this.getState().isbn.trim();
    if (isbn.length > 0 && !(/^[^\s]{10}$/.test(isbn) || /^[^\s]{13}$/.test(isbn))) {
      errors.isbnError = true;
    }
    return errors;
  }
}
