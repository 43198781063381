import { h, IComponent } from 'core';

import FlatButton from 'acadly/common/FlatButton';
import { style } from 'acadly/styles';

interface IStudentPaysStepProps {
  getInstance?: (ref: StudentPaysStep) => any;
  onBack?: () => any;
  onNext?: () => any;
}

export class StudentPaysStep extends IComponent<IStudentPaysStepProps, unknown> {
  public static getTitle(): string {
    return 'How the students-pay model works';
  }

  public componentWillMount() {
    const { getInstance } = this.getProps();
    getInstance?.(this);
  }

  public handleBack = () => {
    const { onBack } = this.getProps();
    onBack();
  };

  public handleNext = async () => {
    const { onNext } = this.getProps();
    onNext();
  };

  public render() {
    return h('div', [
      h('ul', style([{ display: 'grid', gap: '0.5rem' }]), [
        h(
          'li',
          'Your students will be able to sign up for Acadly and access your course without entering their credit card information for the first 14 days, counted from the date when they first access the course'
        ),
        h(
          'li',
          'Students will be able to pay in the first 14 days without worrying about accessing your course'
        ),
        h('li', 'After 14 days, students will not be able to access your course without paying'),
        h('li', [
          'Students ',
          h('span', style(['bold']), 'must use the Acadly web app '),
          ' (',
          h('a', { href: 'https://app.acadly.com' }, 'https://app.acadly.com'),
          ') to make payments. This capability is not available on the Android and iOS apps.',
        ]),
        h(
          'li',
          'Students can request a full refund within 14 days of making a payment. Once a refund is issued, the student will be deleted from your course and you will be notified about this via email.'
        ),
      ]),
      h('div', style(['flex', 'spaceBetween']), [
        FlatButton('Go back', {
          onclick: this.handleBack,
        }),
        FlatButton('Create course', {
          onclick: this.handleNext,
        }),
      ]),
    ]);
  }
}
