import { objectKeys } from 'acadly/utils';

export interface ViewModelConstructor<Props, Type extends ViewModel<Props>> {
  new (props: Props, listener?: () => Promise<void>): Type;
}

/**
 * Base class for ViewModels that take Props.
 * The purpose of using a ViewModel is to seperate out application
 * logic from View components so that app logic can be tested
 * without opening the browser.
 */
export abstract class ViewModel<Props> {
  private __attached: [ViewModel<any>, () => any][] = [];
  constructor(protected props: Props, private listener: () => Promise<void> = async () => {}) {
    this.initialize();
  }

  protected attach<OtherProps, T extends ViewModel<OtherProps>>(
    vm: T,
    getProps: () => OtherProps
  ): T {
    vm.listener = this.listener;
    this.__attached.push([vm, getProps]);
    return vm;
  }

  protected initialize() {}

  public async propsUpdated(_nextProps: Props | ((props: Props) => Props)) {
    const nextProps = _nextProps instanceof Function ? _nextProps(this.props) : _nextProps;
    if (this.props !== nextProps) {
      this.props = nextProps;
      await this.listener();
    }
    for (const [vm, getProps] of this.__attached) {
      await vm.propsUpdated(getProps());
    }
  }

  public getProps(): DeepReadonly<Props> {
    return <any>this.props;
  }

  protected async update(f: () => Promise<void>) {
    await f();
    await this.listener();
  }

  public inject(dependencies: Partial<this>) {
    for (const key of objectKeys(dependencies)) {
      const value = dependencies[key];
      this[key] = <this[keyof this]>value;
    }
  }
  public async with(dependencies: Partial<this>, f: (self: this) => Promise<void>) {
    const oldDependencies: Partial<this> = {};
    for (const key of objectKeys(dependencies)) {
      oldDependencies[key] = this[key];
      this[key] = <this[keyof this]>dependencies[key];
    }
    await f(this);
    this.inject(oldDependencies);
  }
}
