import { h, IComponent, View } from 'core';

import { api as urls } from 'acadly/api';
import { Actions as appActions } from 'acadly/app/actions';
import appService from 'acadly/app/service';
import { Actions as ClassActions } from 'acadly/class/actions';
import NewClassDialog from 'acadly/class/NewClassDialog';
import Alert from 'acadly/common/Alert';
import AttachmentViewer from 'acadly/common/AttachmentViewer';
import Avatar from 'acadly/common/Avatar';
import DatePicker from 'acadly/common/DatePicker';
import Dialog from 'acadly/common/Dialog';
import FlatButton from 'acadly/common/FlatButton';
import Icon from 'acadly/common/Icon';
import { fullScreenLoader, Loader } from 'acadly/common/Loader';
import RadioButton from 'acadly/common/RadioButton';
import TextField from 'acadly/common/TextField';
import Toggle from 'acadly/common/Toggle';
import User from 'acadly/common/User';
import { Actions as CourseActions } from 'acadly/course/actions';
import * as api from 'acadly/course/api';
import { SelectTimezone } from 'acadly/course/Course';
import courseService from 'acadly/course/service';
import * as datetime from 'acadly/datetime';
import icons from 'acadly/icons';
import Viewer from 'acadly/rich-text/Viewer';
import { Routes } from 'acadly/routes';
import { dispatch, getStore } from 'acadly/store';
import { backgroundColor, colors, mb, ml, mt, pad, style } from 'acadly/styles';
import {
  capitalize,
  convertLinkToURL,
  getHTMLTagSelector,
  resetTabIndices,
  unsetTabIndices,
  validateEmail,
} from 'acadly/utils';

import * as css from './styles';

export type CreateCourseSteps =
  | 'createCourse'
  | 'copyCourse'
  | 'previewCourse'
  | 'courseSchedule'
  | 'courseTeam'
  | 'enrollmentType';

interface ICourseTopic {
  topicId: string;
  type: 'topic' | 'subTopic';
  parentId: string;
  title: string;
}

export interface ICreateCourseScreenProps {
  open: boolean;
  close: () => any;
  courseId?: string;
  isProCourse?: boolean;
}
export interface ICreateCourseScreenState {
  isLoading: boolean;
  courseId: string | null;
  code: string;
  title: string;
  codeError: boolean;
  titleError: boolean;
  isSaving: boolean;
  timezoneError: boolean;
  courseDatesError: boolean;
  step: CreateCourseSteps;
  errorMessage: string;
  warningMessage: string;
  isWarningDialogVisible: boolean;
  isErrorDialogVisible: boolean;
  isTimezoneDialogVisible: boolean;
  isAddClassDialogVisible: boolean;
  isTABasicDetailsVisible: boolean;
  isRemovingMember: boolean;
  isRemoveMemberDialogOpen: boolean;
  isInstructorBasicDetailsVisible: boolean;
  scheduleClassRemoveDialogVisibleFor: number | null;
  addTeamMemberDialog: {
    email: string;
    error?: string;
    isSaving: boolean;
  };
  isAddTADialogOpen: boolean;
  isAddInstructorDialogOpen: boolean;
  enrolmentType: ICourseEnrollmentType | null;
  isConfirmSubmitCourseDialogOpen: boolean;
  isConfirmEnrollmentTypeDialogOpen: boolean;
  blueprintCourseId: string | null;
  blueprintCourse: IBlueprintCourse | null;
  blueprintCoursePreview: IBlueprintCoursePreview | null;
  blueprintCourses: IBlueprintCourse[];
  toCopy: {
    description: 0 | 1;
    readingList: 0 | 1;
    topics: 0 | 1;
    syllabus: 0 | 1;
  };
  blueprintCourseTopics: ICourseTopic[];
}

interface StepData {
  title: string;
  scrollPosition: number;
  next: CreateCourseSteps | null;
  prev: CreateCourseSteps | null;
  nextTitle: string;
}

export class CreateCourseScreen extends IComponent<
  ICreateCourseScreenProps,
  ICreateCourseScreenState
> {
  private static days: IWeekDay[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  private isAccessible: boolean;
  private lastFocusedElement: Element | null = null;

  private stepDataMap: Record<CreateCourseSteps, StepData> = {
    createCourse: {
      prev: null,
      nextTitle: 'NEXT',
      scrollPosition: 0,
      next: 'courseSchedule',
      title: 'Step 1: Provide basic details',
    },
    copyCourse: {
      prev: null,
      nextTitle: 'NEXT',
      scrollPosition: 0,
      next: 'previewCourse',
      title: 'Step 1.1: Initialize course',
    },
    previewCourse: {
      prev: 'copyCourse',
      nextTitle: 'NEXT',
      scrollPosition: 0,
      next: 'courseSchedule',
      title: 'Step 1.2: Initialize course - Select content to copy',
    },
    courseSchedule: {
      prev: null,
      nextTitle: 'NEXT',
      scrollPosition: 0,
      next: 'courseTeam',
      title: 'Step 2: Define the course schedule',
    },
    courseTeam: {
      nextTitle: 'NEXT',
      scrollPosition: 0,
      prev: 'courseSchedule',
      next: 'enrollmentType',
      title: 'Step 3: Add course team members',
    },
    enrollmentType: {
      nextTitle: 'DONE',
      scrollPosition: 0,
      prev: 'courseTeam',
      next: null,
      title: 'Step 4: Select student enrolment method',
    },
  };

  private initialState: ICreateCourseScreenState = {
    isLoading: false,
    courseId: null,
    code: '',
    title: '',
    codeError: false,
    titleError: false,
    isSaving: false,
    timezoneError: false,
    courseDatesError: false,
    step: 'createCourse',
    errorMessage: '',
    warningMessage: '',
    isWarningDialogVisible: false,
    isErrorDialogVisible: false,
    isTimezoneDialogVisible: false,
    isAddClassDialogVisible: false,
    isTABasicDetailsVisible: false,
    isRemovingMember: false,
    isRemoveMemberDialogOpen: false,
    isInstructorBasicDetailsVisible: false,
    scheduleClassRemoveDialogVisibleFor: null,
    addTeamMemberDialog: {
      email: '',
      error: undefined,
      isSaving: false,
    },
    isAddTADialogOpen: false,
    isAddInstructorDialogOpen: false,
    enrolmentType: null,
    isConfirmSubmitCourseDialogOpen: false,
    isConfirmEnrollmentTypeDialogOpen: false,
    blueprintCourseId: null,
    blueprintCourse: null,
    blueprintCoursePreview: null,
    blueprintCourses: [],
    toCopy: {
      description: 1,
      readingList: 1,
      topics: 1,
      syllabus: 1,
    },
    blueprintCourseTopics: [],
  };

  private async setCourseCreationStep(courseId: string) {
    const courses = getStore().getState().courses.courses;
    const course = courses[courseId];
    if (!course) {
      await this.setState({ step: 'createCourse' });
    } else if (Object.keys(course).length && !course.status.initialized) {
      this.setState({
        isLoading: true,
        step: 'copyCourse',
      });
      // get blueprint courses
      const response = await api.fetchBlueprintCourse();
      await this.setState({
        isLoading: false,
        blueprintCourses: response.data.blueprintCourses,
      });
    } else {
      await this.setState({ step: 'courseSchedule' });
    }
  }

  private async initialize(props: ICreateCourseScreenProps) {
    this.isAccessible = getStore().getState().app.acc.web.turnOff === 0;
    await this.setState({
      ...this.initialState,
      courseId: props.courseId,
    });

    if (props.courseId) {
      await this.setCourseCreationStep(props.courseId);
      await dispatch(CourseActions.setCurrentCourse(props.courseId));
      await dispatch(CourseActions.fetchTimeline(getStore().getState().getIn.session!.userId));
    }
  }

  private getCourse() {
    return getStore().getState().courses.courses[this.getState().courseId!];
  }

  private getInstructors() {
    const course = this.getCourse();
    if (!course) return [];
    return this.getCourse().team.filter((member) => member.role === 'instructor');
  }

  private getTAs() {
    const course = this.getCourse();
    if (!course) return [];
    return this.getCourse().team.filter((member) => member.role === 'ta');
  }

  private getTimline() {
    return getStore().getState().courses.timeline;
  }

  private formatDate(date: number) {
    return datetime.format(date, 'MMMM Do, YYYY');
  }

  private getNextButtonTitle() {
    const step = this.getState().step;
    return this.stepDataMap[step].nextTitle;
  }

  private gotoCoursePage() {
    const course = this.getCourse();
    return Routes.courseTimeline.navigate({
      courseShortId: courseService.getShortIdFromCourseId(course._id),
      univSlug: appService.getUniversitySlug(),
    });
  }

  public async componentWillMount() {
    await this.initialize(this.getProps());
  }

  public componentWillUnmount() {
    dispatch(CourseActions.closeCoursePage(undefined));
  }

  public async componentWillReceiveProps(newProps: ICreateCourseScreenProps) {
    if (this.getProps().open !== newProps.open) {
      await this.initialize(newProps);
    }
  }

  public render() {
    const props = this.getProps();
    const isMobile = getStore().getState().app.isMobile;
    if (isMobile) {
      return h(
        'div.create-course-screen',
        style([
          'fullWidth',
          {
            zIndex: '1000',
            height: '100%',
            backgroundColor,
            position: 'fixed',
            top: props.open ? '0' : '100%',
            transition: 'all 0.3s cubic-bezier(.23,-0.3,.24,1.2)',
          },
        ]),
        !props.open
          ? []
          : [
              h(
                'div.create-course-header',
                {
                  style: {
                    background: colors.darkBlue,
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '20px',
                    height: css.headerHeight,
                  },
                },
                [
                  Icon(icons.cross, style([pad(css.contentPadding)], {}, { onclick: props.close })),
                  h(
                    'span.title',
                    props.isProCourse ? 'Creating an Acadly Pro Course' : 'Adding a new course'
                  ),
                ]
              ),
              h(
                'div.create-course-body',
                style(['flex', 'column'], {
                  height: `calc(100% - ${css.headerHeight})`,
                }),
                this.body()
              ),
            ]
      );
    }

    const step = this.getState().step;

    return Dialog(
      {
        title: props.isProCourse ? 'Creating an Acadly Pro Course' : 'Adding a new course',
        open: this.getProps().open,
        key: 'create-course-screen',
        fullScreen: isMobile,
        style: {
          width: '45em',
          height: '45em',
        },
        bodyStyle: {
          padding: `0 ${css.contentPadding}`,
          backgroundColor,
          height: '100%',
        },
        primaryAction: this.stepDataMap[step].prev
          ? [
              {
                label: 'PREV',
                onclick: () => this.onPrevClick(),
              },
              {
                label: 'Cancel',
                onclick: () => this.onCancelClick(),
              },
              {
                label: this.getNextButtonTitle(),
                onclick: () => this.onNextClick(),
              },
            ]
          : [
              {
                label: 'Cancel',
                onclick: () => this.onCancelClick(),
              },
              {
                label: this.getNextButtonTitle(),
                onclick: () => this.onNextClick(),
              },
            ],
      },
      props.open ? this.body() : []
    );
  }

  private setNewCourseTitle(title: string) {
    this.setState({
      title,
      titleError: false,
    });
  }

  private async onPrevClick() {
    const step = this.getState().step;
    const prevStep = this.stepDataMap[step].prev;
    if (!prevStep) return;
    await this.setState({ step: prevStep });
    this.updateLastScrollPosition();
  }

  private async onCancelClick() {
    await this.getProps().close();
    await dispatch(appActions.startTip(true));
  }

  private async onNextClick() {
    const step = this.getState().step;
    const bodyWrapper = this.getBodyWrapper();
    if (bodyWrapper) {
      // save last scroll position
      this.stepDataMap[step].scrollPosition = bodyWrapper.scrollTop;
    }
    await this.submitHandler();
    this.updateLastScrollPosition();
  }

  private setNewCourseCode(code: string) {
    this.setState({
      code,
      codeError: false,
    });
  }

  private async submitCourse() {
    const { isProCourse } = this.getProps();
    const { title, code } = this.getState();
    try {
      await this.setState({ isSaving: true });
      const { courseId } = await dispatch(CourseActions.createCourse(title, code, isProCourse));
      this.initialize({ ...this.getProps(), courseId });
    } finally {
      await this.setState({
        isSaving: false,
        isConfirmEnrollmentTypeDialogOpen: false,
      });
    }
  }

  private async createCourseSubmitHandler() {
    const { title, code } = this.getState();
    if (!title || !code) {
      this.setState({
        titleError: title === '',
        codeError: code === '',
      });
    } else {
      this.setState({
        isConfirmSubmitCourseDialogOpen: true,
      });
    }
  }

  private async copyCourseSubmitHandler() {
    const { blueprintCourseId, courseId } = this.getState();

    if (!blueprintCourseId) {
      await dispatch(
        CourseActions.initializeCourse(courseId!, {
          isCopied: 0,
          copyOf: '',
          toCopy: {
            description: 0,
            readingList: 0,
            syllabus: 0,
            topics: 0,
          },
        })
      );
      await this.setState({ step: 'courseSchedule' });
    } else {
      const response = await api.getBlueprintCoursePreview(blueprintCourseId);
      await this.setState({
        step: 'previewCourse',
        blueprintCoursePreview: response.data.preview,
        blueprintCourseTopics: this.getBlueprintCourseTopics(response.data.preview.topics),
      });
    }
  }

  private async previewCourseSubmitHandler() {
    const { blueprintCourseId: copyOf, toCopy, courseId } = this.getState();
    await dispatch(
      CourseActions.initializeCourse(courseId!, {
        toCopy,
        isCopied: 1,
        copyOf: copyOf!,
      })
    );
    await this.setState({ step: 'courseSchedule' });
  }

  private async courseScheduleSubmitHandler() {
    const course = getStore().getState().courses.courses[this.getState().courseId!];
    const timeline = getStore().getState().courses.timeline;

    if (!course || !timeline) return;

    if (
      !course.courseTimezone ||
      !timeline.courseDates.startDate ||
      !timeline.courseDates.endDate
    ) {
      this.showErrorDialog();
      this.setState({
        timezoneError: !course.courseTimezone,
        courseDatesError: !timeline.courseDates.startDate || !timeline.courseDates.endDate,
      });
    } else {
      this.setState({ isSaving: true });
      try {
        const response = await api.checkNumSessions();
        if (response.data.message !== 'success') {
          this.setState({
            warningMessage: response.data.message,
            isWarningDialogVisible: true,
          });
        } else {
          this.setState({
            step: this.stepDataMap.courseSchedule.next!,
          });
        }
      } finally {
        this.setState({ isSaving: false });
      }
    }
  }

  private async submitEnrollmentType() {
    const enrolmentType = this.getState().enrolmentType!;
    try {
      await this.setState({ isSaving: true });
      await dispatch(CourseActions.publishCourseSchedule(enrolmentType)).then(() => {
        this.gotoCoursePage();
      });
    } finally {
      await this.setState({
        isSaving: false,
        isConfirmEnrollmentTypeDialogOpen: false,
      });
    }
  }

  private async enrollmentTypeSubmitHandler() {
    const enrolmentType = this.getState().enrolmentType;
    if (!enrolmentType) {
      this.showErrorDialog('Please choose an option for student enrolment method');
    } else {
      this.setState({
        isConfirmEnrollmentTypeDialogOpen: true,
      });
    }
  }

  private getBodyWrapper(): HTMLElement | undefined {
    const isMobile = getStore().getState().app.isMobile;
    // FIXME: following classnames shouldn't be hard-coded
    const ele = document.getElementsByClassName(
      isMobile ? 'content-wrapper' : 'dialog-body-desktop'
    );
    if (ele[0]) {
      return ele[0] as HTMLElement;
    }
    return undefined;
  }

  private updateLastScrollPosition() {
    const bodyWrapper = this.getBodyWrapper();
    if (!bodyWrapper) {
      console.warn('Body wrapper not found! Can not update last scroll position.');
      return;
    }
    const step = this.getState().step;
    bodyWrapper.scrollTop = this.stepDataMap[step].scrollPosition;
  }

  private async submitHandler() {
    const step = this.getState().step;
    switch (step) {
      case 'createCourse':
        return this.createCourseSubmitHandler();
      case 'copyCourse':
        return this.copyCourseSubmitHandler();
      case 'previewCourse':
        return this.previewCourseSubmitHandler();
      case 'courseSchedule':
        return this.courseScheduleSubmitHandler();
      case 'courseTeam':
        return this.setState({
          step: this.stepDataMap.courseTeam.next!,
        });
      case 'enrollmentType':
        return this.enrollmentTypeSubmitHandler();
      default:
        break;
    }
  }

  private createCourseStepScreen() {
    return [
      h(
        'div.form-wrapper',
        {
          style: {
            padding: css.contentPadding,
            backgroundColor: 'white',
          },
        },
        [
          TextField({
            placeholder: 'Course code',
            maxLength: 16,
            floatingLabelText: 'Course code',
            fullWidth: true,
            autofocus: true,
            ariaRequired: true,
            value: this.getState().code,
            ariaLabel: 'Enter Course Code',
            allowedPattern: /^[A-Za-z0-9.\-_]+$/,
            oninput: (event: any) => this.setNewCourseCode(event.target.value),
            errorText: this.getState().codeError ? 'This field is required' : null,
            style: { marginBottom: '0.5em' },
            onenter: () => this.submitHandler(),
          }),
          h(
            'span',
            style([
              'small',
              'flex',
              'spaceBetween',
              {
                color: colors.lightGrey,
                paddingRight: '1rem',
              },
            ]),
            [
              h('span', 'Allowed characters A-Z 0-9.-_'),
              h('span', `${this.getState().code.length}/16`),
            ]
          ),
          TextField({
            placeholder: 'Course title',
            floatingLabelText: 'Course title',
            fullWidth: true,
            maxLength: 140,
            ariaLabel: 'Enter Course Title',
            ariaRequired: true,
            value: this.getState().title,
            oninput: (event: any) => this.setNewCourseTitle(event.target.value),
            errorText: this.getState().titleError ? 'This field is required' : null,
            style: {
              marginTop: '1em',
              marginBottom: '0.5em',
            },
            onenter: () => this.submitHandler(),
          }),
          h(
            'span',
            style([
              'small',
              'flex',
              {
                color: colors.lightGrey,
                paddingRight: '1rem',
              },
            ]),
            [h('span', style([ml('auto')]), `${this.getState().title.length}/140`)]
          ),
        ]
      ),
      this.confirmSubmitCourseDialog(),
    ];
  }

  private copyCourseStepScreen() {
    const { blueprintCourseId, blueprintCourses } = this.getState();

    const formatDate = (unixTime: number) => datetime.format(unixTime, 'MMM DD, YYYY');

    return h('div.copy-course', [
      h(
        'div.ripple.copy-course__option',
        {
          key: 'skip-copy-option',
          onclick: () =>
            this.setState({
              blueprintCourseId: null,
              blueprintCourse: null,
            }),
        },
        [
          RadioButton({
            className: 'copy-course__radio-button',
            selected: blueprintCourseId == null,
          }),
          h('span.copy-course__option-title', 'Create a course from scratch'),
        ]
      ),
      h('div.copy-course__separator', 'OR'),
      h('div.activity__subtitle', 'Select a source course to copy from'),
      !blueprintCourses.length
        ? h('div.copy-course__separator', 'No courses have been completed yet')
        : null,
      ...blueprintCourses.map((course) => {
        const user = appService.getCurrentUser()!;
        const isAdmin = user.userId === course.admin.userId;
        return h(
          'div.ripple.copy-course__option',
          {
            onclick: () =>
              this.setState({
                blueprintCourseId: course.courseId,
                blueprintCourse: course,
              }),
          },
          [
            RadioButton({
              className: 'copy-course__radio-button',
              selected: blueprintCourseId === course.courseId,
            }),
            h(
              getHTMLTagSelector('div', [
                'blueprint-course',
                'copy-course__course-widget',
                isAdmin ? 'is-admin' : 'is-instructor',
              ]),
              [
                h('div.blueprint-course__header', [
                  h('div.blueprint-course__code', course.code),
                  h('div.blueprint-course__activities-count', [
                    `${course.totalActivities || 'No'} Activities`,
                  ]),
                ]),
                h('div.blueprint-course__title', course.title),
                User(
                  {
                    avatar: {
                      url: course.admin.avatar,
                      creator: course.admin.name,
                    },
                    title: isAdmin ? 'You' : course.admin.name,
                    titleClassNames: ['fw-bold', 'fc-black'],
                    subtitle: 'Course Admin',
                  },
                  {
                    className: 'blueprint-course__admin',
                  }
                ),
                h('div.blueprint-course__footer', [
                  h('div.blueprint-course__dates', [
                    `${formatDate(course.startDate)} - ${formatDate(course.endDate)}`,
                    course.archived ? h('span.tag.blue', 'Archived') : null,
                  ]),
                  h('div.blueprint-course__role', course.role),
                ]),
              ]
            ),
          ]
        );
      }),
    ]);
  }

  private previewCourseStepScreen() {
    const { toCopy, blueprintCourse, blueprintCourseTopics, blueprintCoursePreview } =
      this.getState();

    if (!blueprintCourse) {
      return 'Unable to get blueprint course data!';
    }

    const { description, readingList, activities, syllabus } = blueprintCoursePreview!;

    const Section = (
      header: string,
      copyProp: keyof ICreateCourseScreenState['toCopy'],
      isToggleDisabled: boolean,
      content: View[]
    ) => {
      return h('div.panel', [
        h(
          'div.panel__header',
          {
            key: `preview-${copyProp}-section`,
          },
          [
            h('span', header),
            Toggle({
              scale: 0.85,
              disabled: isToggleDisabled,
              selected: !isToggleDisabled && toCopy[copyProp] === 1,
              ontoggle: () => {
                this.setState({
                  toCopy: {
                    ...toCopy,
                    [copyProp]: toCopy[copyProp] === 1 ? 0 : 1,
                  },
                });
              },
            }),
          ]
        ),
        h('div.panel__content', content),
      ]);
    };

    const SubHeading = (label: string) => h('div.fc-grey', label);
    const SubSection = (heading: string, content: View[]) => {
      return h('div.bpc-info', [SubHeading(heading), ...content]);
    };

    const Link = (link: ICourseLink) => {
      return h(
        'a.bpc-info__link',
        {
          key: `link-${link._id}`,
          tabIndex: this.isAccessible ? 0 : undefined,
          role: 'listitem',
          href: convertLinkToURL(link.url),
          target: '_blank',
        },
        link.title
      );
    };

    const Book = (book: ICourseBook) => {
      return h(
        'div.bpc-info__book',
        {
          key: book._id,
          tabIndex: this.isAccessible ? 0 : undefined,
          role: 'listitem',
          'aria-label': `${book.title}, this book is tagged
                        ${book.recommended === 1 ? `Required` : `suggested`}`,
        },
        [
          h('div.bpc-info__book__details', [
            h('div', book.title),
            h('div.bpc-info__book__author', book.author),
            book.isbn.length > 0 ? h('div.bpc-info__book__isbn', book.isbn) : null,
          ]),
          h('div.bpc-info__book__remarks', [
            book.recommended === 1
              ? h('div.fc-green', 'Required')
              : h('div.fc-light-grey', 'Suggested'),
          ]),
        ]
      );
    };

    const File = (downloadUrl: string, file: ICourseFile) => {
      return AttachmentViewer({
        downloadUrl,
        key: file.name,
        attachment: file,
        style: {
          margin: '0.5rem 0',
          width: 'initial',
        },
        requestHeaders: {
          courseId: blueprintCourse.courseId,
        },
        downloadRequest: {
          fileName: file.name,
        },
      });
    };

    const Topic = (topic: ICourseTopic) => {
      return h(
        getHTMLTagSelector('div', ['bpc-info__topic', topic.type === 'subTopic' ? 'sub' : '']),
        topic.title
      );
    };

    const ActivityCountCell = (title: string, count: number) => {
      return h('div.cell.cell--full-width', [
        h('div', title),
        h('div.cell__value', count.toString()),
      ]);
    };

    return h('div', [
      Section('Copy course description', 'description', !description, [
        description
          ? Viewer(description)
          : h('div.fc-light-grey', 'This course has no description to copy'),
      ]),
      Section(
        'Copy reading list',
        'readingList',
        !readingList.books.length && !readingList.links.length && !readingList.files.length,
        [
          SubSection(
            'Course Textbooks',
            !readingList.books.length
              ? [h('div.fc-light-grey', 'This course has no books to copy')]
              : readingList.books.map(Book)
          ),
          SubSection(
            'Links',
            !readingList.links.length
              ? [h('div.fc-light-grey', 'This course has no links to copy')]
              : readingList.links.map(Link)
          ),
          SubSection('Files', [
            !readingList.files.length
              ? h('div.fc-light-grey', 'This course has no files to copy')
              : h(
                  'div.bpc-info__files',
                  readingList.files.map(File.bind(this, urls().courseInfoFileDownload))
                ),
          ]),
        ]
      ),
      Section(
        'Copy course topics',
        'topics',
        !blueprintCourseTopics.length,
        blueprintCourseTopics.map(Topic)
      ),
      Section('Copy course syllabus', 'syllabus', Object.keys(syllabus).length === 0, [
        Object.keys(syllabus).length !== 0
          ? File(urls().syllabusFileDownload, syllabus as ICourseFile)
          : h('div.fc-light-grey', 'This course has no syllabus file to copy'),
      ]),
      h('div.panel', [
        h('div.panel__header', 'Copying course activities'),
        h('div.panel__content', [
          h('div.blueprint-course__info-section', [
            'When a course is copied, Acadly will use its activities to ',
            'intelligently make suggestions for class activities in the new course.',
          ]),
          ActivityCountCell('Published quizzes', activities.quizzes),
          ActivityCountCell('Published polls', activities.polls),
          ActivityCountCell('Published resources', activities.resources),
          ActivityCountCell('Published discussions', activities.discussions),
        ]),
      ]),
    ]);
  }

  private courseScheduleStepScreen() {
    const course = this.getCourse();
    const timeline = this.getTimline();

    const hhmmStringToAMPM = (str: string) => {
      const hour = parseInt(str.slice(0, 2));
      const minute = str.slice(3);
      const ampm = hour > 11 ? 'PM' : 'AM';
      return `${hour > 12 ? hour - 12 : hour}:${minute} ${ampm}`;
    };

    const Required = h('span', style(['bold', ml('1em')], { color: colors.errorRed }), '*Required');

    if (!course) return [];
    if (!timeline) return [fullScreenLoader];

    return [
      h(
        'div.course-details',
        style([css.courseDetailStyle]),
        `${course.details.code}: ${course.details.title}`
      ),
      h('div.form-label', style(['uppercase'], css.formLabelStyle), [
        'Timezone',
        this.getState().timezoneError ? Required : null,
      ]),
      h(
        'div.ripple.form-control',
        style(
          [css.row],
          {
            minHeight: 56,
          },
          {
            tabIndex: this.isAccessible ? 0 : undefined,
            onClick: () => this.showTimezoneDialog(),
          }
        ),
        [
          h('span', course.courseTimezone || 'Select a course timezone'),
          this.getState().isTimezoneDialogVisible
            ? h(SelectTimezone, {
                onClose: () => this.hideTimezoneDialog(),
              })
            : h('span.fa.fa-globe', style([css.formControlIconStye])),
        ]
      ),
      h('div.form-label', style(['uppercase'], css.formLabelStyle), [
        'Course Dates',
        this.getState().courseDatesError ? Required : null,
      ]),
      h('div.form-control', style([css.row]), [
        h('span', 'Start date'),
        h(
          'span',
          {
            style: {
              color: colors.lightGrey,
            },
          },
          [
            !course.courseTimezone
              ? 'Select timezone first'
              : DatePicker({
                  value: timeline.courseDates.startDate
                    ? datetime.fromUnix(timeline.courseDates.startDate)
                    : undefined,
                  onchange: (date) => this.setStartDate(datetime.format(date, 'YYYY-MM-DD')),
                }),
          ]
        ),
      ]),
      h('div.form-control', style([css.row]), [
        h('span', 'End date'),
        h(
          'span',
          {
            style: {
              color: colors.lightGrey,
            },
          },
          [
            !course.courseTimezone
              ? 'Select timezone first'
              : DatePicker({
                  onchange: (date) => this.setEndDate(datetime.format(date, 'YYYY-MM-DD')),
                  value: timeline.courseDates.endDate
                    ? datetime.fromUnix(timeline.courseDates.endDate)
                    : undefined,
                  minDate: timeline.courseDates.startDate
                    ? datetime.max(
                        datetime.addDays(datetime.fromUnix(timeline.courseDates.startDate), 1)
                      )
                    : datetime.max(datetime.addDays(datetime.now(), 1)),
                }),
          ]
        ),
      ]),
      h('div.form-label', style(['uppercase'], css.formLabelStyle), 'Weekly schedule'),
      h(
        'div#weekly-schedule-list',
        timeline.schedule
          .sort((a, b) => {
            const dayA = CreateCourseScreen.days.indexOf(a.day);
            const dayB = CreateCourseScreen.days.indexOf(b.day);
            return dayA - dayB;
          })
          .map((cls, index) =>
            h(
              'div',
              {
                key: `${cls.day}${cls.startTime}`,
                style: css.row,
                tabIndex: this.isAccessible ? 0 : undefined,
              },
              [
                h(
                  'span',
                  style([
                    {
                      maxWidth: '80%',
                    },
                  ]),
                  [
                    h(
                      'div',
                      `Class - ${cls.day}, ` +
                        `${hhmmStringToAMPM(cls.startTime)} - ${hhmmStringToAMPM(cls.endTime)}`
                    ),
                    h(
                      'div',
                      style(['lightGrey', 'ellipsis', { maxWidth: '100%' }]),
                      cls.isOnlineMeeting ? 'This class is online' : cls.venue
                    ),
                  ]
                ),
                Icon(
                  icons.cross,
                  style(
                    ['red', 'pointer'],
                    {},
                    {
                      tabIndex: this.isAccessible ? 0 : undefined,
                      onclick: () => {
                        this.lastFocusedElement = document.activeElement;
                        unsetTabIndices();
                        this.scheduledClassRemoveHandler(index);
                      },
                    }
                  )
                ),
              ]
            )
          )
      ),
      h(
        'div.ripple#add-class-button',
        style([], css.row, {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            this.setState({ isAddClassDialogVisible: true });
          },
        }),
        [
          timeline.schedule.length < 1 ? 'Add a weekly class' : 'Add another weekly class',
          Icon(icons.plus, style(['blue'])),
        ]
      ),
      h(
        'div',
        style([css.stepFooterNote]),
        `A weekly schedule automatically creates your course timeline
                with the scheduled classes. If you do not specify a weekly
                session schedule, you will have to create each class manually.`
      ),
      NewClassDialog({
        type: 'weekly',
        open: this.getState().isAddClassDialogVisible,
        course,
        isAccessible: this.isAccessible,
        userId: getStore().getState().getIn.session!.userId,
        close: () => {
          resetTabIndices();
          if (this.lastFocusedElement) {
            (this.lastFocusedElement as HTMLElement).focus();
          } else {
            const btn = document.getElementById('add-class-button');
            if (btn) {
              btn.focus();
            }
          }
          this.setState({
            isAddClassDialogVisible: false,
          });
        },
      }),
      this.deleteScheduledClassAlert(),
    ];
  }

  private courseTeamStepScreen() {
    const course = this.getCourse();
    if (!course) return [];

    const renderNameAndStatus = (name: string, status: string) =>
      h('div', style(['flex', 'column', 'spaceBetween']), [
        h('span', name),
        h('span', style(['small', 'lightGrey']), [status]),
      ]);

    const teamMemberAttrs = (id: string) =>
      style([css.row], {}, { key: id, tabIndex: this.isAccessible ? 0 : undefined });

    const renderTeamMember = (m: ICourseTeamMember) =>
      h('div', teamMemberAttrs(m.userId), [
        h('div', style(['flex', 'alignCenter']), [
          Avatar(m.avatar, m.name, {
            className: 'create-course-screen__avatar',
          }),
          renderNameAndStatus(m.name, `${capitalize(m.status)}`),
        ]),
        Icon(
          icons.cross,
          style(
            ['red', 'pointer'],
            {},
            {
              tabIndex: this.isAccessible ? 0 : undefined,
              'aria-label': `remove ${m.name} from ${m.role}s`,
              key: `team-member-editable-${m.userId}`,
              onClick: async () => {
                if (m.role !== 'admin' && m.role !== 'student') {
                  await this.showRemoveMemberDialog(m.userId, m.role);
                }
              },
            }
          )
        ),
      ]);

    return [
      h(
        'div.course-details',
        { style: css.courseDetailStyle },
        `${course.details.code}: ${course.details.title}`
      ),
      h(
        'div.course-details',
        { style: css.courseDetailStyle },
        `${this.formatDate(course.dates!.startDate)} - ${this.formatDate(course.dates!.endDate)}`
      ),
      h('div.form-label', style(['uppercase'], css.formLabelStyle), 'Course Instructors'),
      this.getInstructors().map(renderTeamMember),
      h(
        'div.ripple#add-instructor-button',
        style([], css.row, {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => this.openAddInstructorDialog(),
        }),
        ['Add Course Instructor', Icon(icons.plus, style(['blue']))]
      ),
      h(
        'div.note',
        style(['pointer'], css.formControlNote, {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            this.setState({
              isInstructorBasicDetailsVisible: true,
            });
          },
        }),
        'Learn what a Course Instructor can do'
      ),
      h('div.form-label', style(['uppercase'], css.formLabelStyle), 'Course Teaching Assistants'),
      this.getTAs().map(renderTeamMember),
      h(
        'div.ripple#add-instructor-button',
        style([], css.row, {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => this.openAddTADialog(),
        }),
        ['Add Teaching Assistant', Icon(icons.plus, style(['blue']))]
      ),
      h(
        'div.note',
        style(['pointer'], css.formControlNote, {
          tabIndex: this.isAccessible ? 0 : undefined,
          onclick: () => {
            this.setState({
              isTABasicDetailsVisible: true,
            });
          },
        }),
        'Learn what a Course Teaching Assistant can do'
      ),
      h(
        'div',
        style([css.stepFooterNote]),
        `This is an optional step. You can add the team members later as well.`
      ),
      this.intstructorBasicDetailsDialog(),
      this.TABasicDetailsDialog(),
      this.removeMemberDialog(),
      this.addInstructorDialog(),
      this.addTADialog(),
    ];
  }

  private enrollmentTypeStepScreen() {
    const course = this.getCourse();
    if (!course) return [];

    const handleOptionSelection = (type: ICourseEnrollmentType) => {
      this.setState({
        enrolmentType: type,
      });
    };

    return [
      h(
        'div.course-details',
        { style: css.courseDetailStyle },
        `${course.details.code}: ${course.details.title}`
      ),
      h(
        'div.course-details',
        { style: css.courseDetailStyle },
        `${this.formatDate(course.dates!.startDate)} - ${this.formatDate(course.dates!.endDate)}`
      ),
      h(
        'div.ripple.form-control-wrapper',
        style(
          [css.row],
          {
            justifyContent: 'flex-start',
          },
          {
            onclick: () => handleOptionSelection('enrolmentInvite'),
          }
        ),
        [
          RadioButton({
            selected: this.getState().enrolmentType === 'enrolmentInvite',
          }),
          h('span', style([ml('0.5em')]), 'Enrolment by invitation'),
        ]
      ),
      h(
        'div.note',
        style([css.formControlExplanation]),
        `
                Once the course is published, you will be able to enrol students by
                providing their email addresses. Allows for stricter control over who
                can access the course content.
                `
      ),
      h(
        'div.ripple.form-control-wrapper',
        style(
          [css.row],
          {
            justifyContent: 'flex-start',
          },
          {
            onclick: () => handleOptionSelection('enrolmentCode'),
          }
        ),
        [
          RadioButton({
            selected: this.getState().enrolmentType === 'enrolmentCode',
          }),
          h('span', style([ml('0.5em')]), 'Enrolment by code'),
        ]
      ),
      h(
        'div.note',
        style([css.formControlExplanation]),
        `
                After the course is published, a Join Code is generated that can be used
                by students to enrol in the course. However, anyone with the Join Code will
                be able to access the course content as a student
                `
      ),
      this.confirmEnrollmentTypeDialog(),
    ];
  }

  private confirmSubmitCourseDialog() {
    return Alert(
      {
        open: this.getState().isConfirmSubmitCourseDialogOpen,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        bodyStyle: {
          paddingBottom: 0,
        },
        actions: [
          FlatButton('NO', {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'NO',
            type: 'secondary',
            onclick: () => {
              this.setState({
                isConfirmSubmitCourseDialogOpen: false,
              });
            },
          }),
          FlatButton('YES', {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'YES',
            type: 'primary',
            onclick: () => this.submitCourse(),
          }),
        ],
      },
      [
        h('div', style(['orange', 'large', 'bold']), 'Are you sure?'),
        h(
          'p',
          style([mt('1em')]),
          `A course’s name and course code cannot be changed later.
                    Are you sure you want to continue?`
        ),
      ]
    );
  }

  private confirmEnrollmentTypeDialog() {
    return Alert(
      {
        open: this.getState().isConfirmEnrollmentTypeDialogOpen,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        bodyStyle: {
          paddingBottom: 0,
        },
        actions: [
          FlatButton('NO', {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'NO',
            type: 'secondary',
            onclick: () => {
              this.setState({
                isConfirmEnrollmentTypeDialogOpen: false,
              });
            },
          }),
          FlatButton('YES', {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'YES',
            type: 'primary',
            onclick: () => this.submitEnrollmentType(),
          }),
        ],
      },
      [
        h('div', style(['orange', 'large', 'bold']), 'Are you sure?'),
        h(
          'p',
          style([mt('1em')]),
          `
                    The enrolment method you select cannot be changed later.
                    Are you sure you want to continue?
                    `
        ),
      ]
    );
  }

  private intstructorBasicDetailsDialog() {
    return Alert(
      {
        open: this.getState().isInstructorBasicDetailsVisible,
        actions: [
          FlatButton('OKAY', {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'OKAY',
            onclick: () =>
              this.setState({
                isInstructorBasicDetailsVisible: false,
              }),
          }),
        ],
      },
      [
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h(
              'div.title',
              style(['green', 'large', mb('1em')]),
              'Course Instructor - Role Details'
            ),
            h('div.sub-title', style(['medium', mb('0.5em')]), 'A Course Instructor CAN'),
            h('ul', style(['small']), [
              h(
                'li',
                `Be made a "Class In-charge" or
                                "Class Assistant" for class meetings`
              ),
              h('li', 'Create activities, announcements, assignments'),
              h('li', 'Access unpublished activities'),
              h('li', 'Remove comments by students'),
              h('li', 'Take attendance if they are part of the team for a class meeting'),
              h(
                'li',
                `Reward offline class participation points
                                if they are part of the team for a class meeting`
              ),
            ]),
            h('div.sub-title', style(['medium', mb('0.5em')]), 'A Course Instructor CANNOT'),
            h('ul', style(['small']), [
              h('li', 'Add or remove students'),
              h('li', 'Invite course collaborators'),
              h('li', 'Edit course schedule or syllabus topics'),
              h('li', 'Select a Class In-charge'),
            ]),
          ]
        ),
      ]
    );
  }

  private TABasicDetailsDialog() {
    return Alert(
      {
        open: this.getState().isTABasicDetailsVisible,
        actions: [
          FlatButton('OKAY', {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'OKAY',
            onclick: () =>
              this.setState({
                isTABasicDetailsVisible: false,
              }),
          }),
        ],
      },
      [
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          [
            h('div.title', style(['green', 'large', mb('1em')]), 'Course TA - Role Details'),
            h('div.sub-title', style(['medium', mb('0.5em')]), 'A Course TA CAN'),
            h('ul', style(['small']), [
              h('li', 'Access unpublished activities'),
              h('li', 'Grade assignments'),
              h('li', `Be made "Class Assistant" for class meetings`),
              h('li', `Take attendance if they are part of the team for a class meeting`),
              h(
                'li',
                `Reward offline class participation points
                                if they are part of the team for a class meeting`
              ),
            ]),
            h('div.sub-title', style(['medium', mb('0.5em')]), 'A Course TA CANNOT'),
            h('ul', style(['small']), [
              h('li', `Be made "Class In-charge" for class meetings`),
              h('li', 'Create activities, announcement, assignments'),
              h('li', 'Add or remove students'),
              h('li', 'Invite course collaborators'),
              h('li', 'Edit the course schedule or syllabus topics'),
              h('li', 'Select a Class In-Charge'),
            ]),
          ]
        ),
      ]
    );
  }

  private addInstructorDialog() {
    const role = 'instructor';

    const emailOnChange = (text: string) =>
      this.setState({
        addTeamMemberDialog: {
          ...this.getState().addTeamMemberDialog,
          email: text,
          error: undefined,
        },
      });

    const cancel = async () => {
      await this.setState({
        ...this.getState(),
        isAddInstructorDialogOpen: false,
      });
    };

    const invite = async () => {
      const error = !this.getState().addTeamMemberDialog.email.length
        ? 'This field is required'
        : !validateEmail(this.getState().addTeamMemberDialog.email)
        ? 'Please enter a valid email'
        : undefined;
      if (error) {
        await this.setState({
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            error,
          },
        });
      } else {
        await this.setState({
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            isSaving: true,
          },
        });
        await dispatch(
          CourseActions.addTeamMember(this.getState().addTeamMemberDialog.email, role)
        );
        await this.setState({
          ...this.getState(),
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            isSaving: false,
          },
          isAddInstructorDialogOpen: false,
        });
      }
    };
    return Alert(
      {
        open: this.getState().isAddInstructorDialogOpen,
        style: {
          maxWidth: '25em',
        },
        actions: [
          FlatButton('CANCEL', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: cancel,
          }),
          FlatButton('ADD', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: invite,
          }),
        ],
      },
      [
        h('div', style(['large', 'green']), 'Add Course Instructor'),
        h(
          'span',
          {
            style: {
              color: colors.blue,
              marginTop: '1em',
              paddingTop: '1rem',
              fontSize: '0.75em',
            },
          },
          'Instructor email address'
        ),
        TextField({
          placeholder: 'Type Here',
          value: this.getState().addTeamMemberDialog.email.toLowerCase(),
          errorText: this.getState().addTeamMemberDialog.error,
          oninput: (event) => emailOnChange(event.target.value),
          onenter: invite,
        }),
        h(
          'span',
          {
            style: {
              color: colors.lightGrey,
              fontSize: '0.75em',
              // paddingBottom: "1rem"
            },
          },
          'Instructor must have a valid institute email address'
        ),
      ]
    );
  }

  private addTADialog() {
    const role = 'ta';

    const cancel = async () => {
      await this.setState({
        ...this.getState(),
        isAddTADialogOpen: false,
      });
    };

    const emailOnChange = (text: string) =>
      this.setState({
        addTeamMemberDialog: {
          ...this.getState().addTeamMemberDialog,
          email: text,
          error: undefined,
        },
      });

    const invite = async () => {
      const error = !this.getState().addTeamMemberDialog.email.length
        ? 'This field is required'
        : !validateEmail(this.getState().addTeamMemberDialog.email)
        ? 'Please enter a valid email'
        : undefined;
      if (error) {
        await this.setState({
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            error,
          },
        });
      } else {
        await this.setState({
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            isSaving: true,
          },
        });
        await dispatch(
          CourseActions.addTeamMember(this.getState().addTeamMemberDialog.email, role)
        );
        await this.setState({
          ...this.getState(),
          addTeamMemberDialog: {
            ...this.getState().addTeamMemberDialog,
            isSaving: false,
          },
          isAddTADialogOpen: false,
        });
      }
    };
    return Alert(
      {
        open: this.getState().isAddTADialogOpen,
        style: {
          boxSizing: 'border-box',
          maxWidth: '25em',
        },
        actions: [
          FlatButton('CANCEL', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: cancel,
          }),
          FlatButton('ADD', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: invite,
          }),
        ],
      },
      [
        h('div', style(['large', { color: colors.green }]), 'Add Course TA'),
        h(
          'span',
          {
            style: {
              color: colors.blue,
              fontSize: '0.75em',
              paddingTop: '1rem',
            },
          },
          'TA email address'
        ),
        TextField({
          placeholder: 'Type Here',
          value: this.getState().addTeamMemberDialog.email.toLowerCase(),
          errorText: this.getState().addTeamMemberDialog.error,
          oninput: (event) => emailOnChange(event.target.value),
          onenter: invite,
        }),
        h(
          'span',
          {
            style: {
              color: colors.lightGrey,
              fontSize: '0.75em',
              paddingTop: '1rem',
            },
          },
          'The TA must have a valid institute email address'
        ),
      ]
    );
  }

  private removeMemberDialog() {
    const userId = this.removeMemberId!;

    return Alert(
      {
        open: this.getState().isRemoveMemberDialogOpen,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('No', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => this.hideRemoveMemberDialog(),
          }),
          FlatButton('Yes', {
            tabIndex: this.isAccessible ? 0 : undefined,
            onclick: () => this.removeTeamMember(userId),
          }),
        ],
      },
      ['Are you sure you want to delete this team member?']
    );
  }

  private warningDialog() {
    return Alert(
      {
        open: this.getState().isWarningDialogVisible,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        bodyStyle: {
          paddingBottom: 0,
        },
        actions: [
          FlatButton('IGNORE', {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'IGNORE',
            type: 'secondary',
            onclick: () => {
              this.setState({
                isWarningDialogVisible: false,
                step: this.stepDataMap.courseSchedule.next!,
              });
            },
          }),
          FlatButton('RE-PLAN', {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'RE-PLAN',
            type: 'primary',
            onclick: () => {
              this.setState({
                isWarningDialogVisible: false,
              });
            },
          }),
        ],
      },
      [
        h('div', style(['orange', 'large', 'bold']), 'WARNING'),
        h('p', style([mt('1em')]), this.getState().warningMessage),
      ]
    );
  }

  private errorDialog() {
    return Alert(
      {
        open: this.getState().isErrorDialogVisible,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        actions: [
          FlatButton('OK', {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'OK',
            onclick: () => this.hideErrorDialog(),
          }),
        ],
      },
      [
        h(
          'div',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          this.getState().errorMessage
        ),
      ]
    );
  }

  private deleteScheduledClassAlert() {
    return Alert(
      {
        open: this.getState().scheduleClassRemoveDialogVisibleFor !== null,
        overlayStyle: {
          backgroundColor: colors.overlayOrange,
        },
        title: 'Delete Class',
        actions: [
          FlatButton('No', {
            tabIndex: this.isAccessible ? 0 : undefined,
            type: 'secondary',
            onclick: () => {
              resetTabIndices();
              (this.lastFocusedElement as HTMLElement).focus();
              this.setState({
                scheduleClassRemoveDialogVisibleFor: null,
              });
            },
          }),
          FlatButton('Yes', {
            tabIndex: this.isAccessible ? 0 : undefined,
            label: 'Yes',
            onclick: () => this.removeScheduledClass(),
          }),
        ],
      },
      [
        h(
          'span',
          {
            tabIndex: this.isAccessible ? 0 : undefined,
          },
          'Are you sure you want to delete this class?'
        ),
      ]
    );
  }

  private body() {
    const { step, isLoading, isSaving } = this.getState();
    const isMobile = getStore().getState().app.isMobile;

    let stepContent: any;
    const actionButtonStyle = { padding: '1em' };

    if (isLoading) return [fullScreenLoader];

    switch (step) {
      case 'createCourse':
        stepContent = this.createCourseStepScreen();
        break;
      case 'copyCourse':
        stepContent = this.copyCourseStepScreen();
        break;
      case 'previewCourse':
        stepContent = this.previewCourseStepScreen();
        break;
      case 'courseSchedule':
        stepContent = this.courseScheduleStepScreen();
        break;
      case 'courseTeam':
        stepContent = this.courseTeamStepScreen();
        break;
      case 'enrollmentType':
        stepContent = this.enrollmentTypeStepScreen();
        break;
      default:
        stepContent = [];
        break;
    }

    return [
      h(
        'div.step-instructions',
        {
          style: {
            padding: '10px 20px',
            backgroundColor: colors.darkBlue,
            color: 'white',
            flexShrink: '0',
            margin: isMobile ? '0' : `${css.contentPadding} 0`,
          },
        },
        this.stepDataMap[step].title
      ),
      h('div.content-wrapper', { style: { overflow: 'auto', flex: '1' } }, stepContent),
      isMobile
        ? h(
            'div.create-course-footer',
            {
              style: {
                display: 'flex',
                alignItems: 'center',
                flexShrink: '0',
                backgroundColor: 'white',
                height: css.headerHeight,
                boxShadow: '#666 1px 1px 5px 0px',
                zIndex: '1',
              },
            },
            [
              this.stepDataMap[step].prev
                ? FlatButton(
                    [
                      Icon(icons.arrowRight, {
                        style: {
                          fontSize: '10px',
                          marginRight: '4px',
                          display: 'inline-block',
                          transform: 'rotate(180deg)',
                        },
                      }),
                      h('span', 'PREV'),
                    ],
                    {
                      style: actionButtonStyle,
                      onclick: () => this.onPrevClick(),
                    }
                  )
                : null,
              h('div.spacer', { style: { flex: '1' } }),
              FlatButton(
                isSaving
                  ? Loader({ width: '1em', height: '1em' })
                  : [
                      h('span', this.getNextButtonTitle()),
                      Icon(
                        this.stepDataMap[step].nextTitle === 'DONE' ? icons.done : icons.arrowRight,
                        {
                          style: {
                            fontSize: '10px',
                            marginLeft: '4px',
                          },
                        }
                      ),
                    ],
                {
                  style: actionButtonStyle,
                  onclick: () => this.onNextClick(),
                }
              ),
            ]
          )
        : null,
      this.errorDialog(),
      this.warningDialog(),
    ];
  }

  private showTimezoneDialog() {
    this.setState({
      timezoneError: false,
      isTimezoneDialogVisible: true,
    });
  }

  private hideTimezoneDialog() {
    this.setState({
      isTimezoneDialogVisible: false,
    });
  }

  private showErrorDialog(message = 'Please fill all required fields.') {
    this.setState({
      isErrorDialogVisible: true,
      errorMessage: message,
    });
  }

  private hideErrorDialog() {
    this.setState({
      isErrorDialogVisible: false,
    });
  }

  private async setStartDate(date: string) {
    await dispatch(CourseActions.setStartDate(date));
    this.setState({ courseDatesError: false });
  }

  private async setEndDate(date: string) {
    await dispatch(CourseActions.setEndDate(date));
    this.setState({ courseDatesError: false });
  }

  private scheduledClassRemoveHandler(index: number) {
    this.setState({
      scheduleClassRemoveDialogVisibleFor: index,
    });
  }

  private async removeScheduledClass() {
    const index = this.getState().scheduleClassRemoveDialogVisibleFor!;
    const schedule = getStore().getState().courses.timeline!.schedule;
    const cls = schedule[index];
    await dispatch(ClassActions.removeWeeklyClass(cls.startTime, cls.type, cls.day, index));
    resetTabIndices();
    const section = document.getElementById('weekly-schedule-list');
    const addButton = document.getElementById('add-class-button');
    this.setState({
      scheduleClassRemoveDialogVisibleFor: null,
    }).then(() => {
      section && section.childNodes[0]
        ? (section.childNodes[0] as HTMLElement).focus()
        : addButton!.focus();
    });
  }

  private removeMemberId?: string;
  private removeMemberRole: 'instructor' | 'ta' = 'ta';

  private async showRemoveMemberDialog(userId: string, role: 'instructor' | 'ta') {
    this.removeMemberId = userId;
    this.removeMemberRole = role;
    await this.setState({
      isRemoveMemberDialogOpen: true,
    });
  }

  private async hideRemoveMemberDialog() {
    this.removeMemberId = undefined;
    await this.setState({
      isRemoveMemberDialogOpen: false,
    });
  }

  private async removeTeamMember(userId: string) {
    await this.setState({
      isRemovingMember: true,
    });
    await dispatch(CourseActions.removeTeamMember(userId, this.removeMemberRole));
    this.removeMemberId = undefined;
    await this.setState({
      isRemoveMemberDialogOpen: false,
      isRemovingMember: false,
    });
  }

  private async openAddInstructorDialog() {
    await this.setState({
      isAddInstructorDialogOpen: true,
      addTeamMemberDialog: this.emptyAddTeamMemberDialogState(),
    });
  }

  private async openAddTADialog() {
    await this.setState({
      isAddTADialogOpen: true,
      addTeamMemberDialog: this.emptyAddTeamMemberDialogState(),
    });
  }

  private emptyAddTeamMemberDialogState() {
    return {
      email: '',
      error: undefined,
      isSaving: false,
    };
  }

  private getBlueprintCourseTopics(previewTopics: IBlueprintCoursePreview['topics']) {
    const topics: ICourseTopic[] = [];

    for (let i = 0; i < previewTopics.length; i++) {
      const previewTopic = previewTopics[i];

      const topic = previewTopic.topics.find((t) => t.topicId === previewTopic._id);
      const subtopics = previewTopic.topics.filter((t) => t.topicId !== previewTopic._id);

      if (topic) {
        topics.push(topic);
        topics.push(...subtopics);
      }
    }

    return topics;
  }
}

export default (props: ICreateCourseScreenProps) => h(CreateCourseScreen, props);
